import type { IEmailId, IOrganizationId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { CommentTimestamp, Skeleton } from "@archetype/ui";
import React, { useCallback, useState } from "react";

import { UserNameById } from "../user/UserNameById";
import { UserProfileImage } from "../user/UserProfileImage";
import { EmailComponent } from "./EmailComponent";

interface ILoadingEmailExpandableItem {
  organizationId: IOrganizationId;
  emailId: IEmailId;
}

export const LoadingEmailExpandableItem: React.FC<ILoadingEmailExpandableItem> = ({ organizationId, emailId }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = useCallback(() => {
    setExpanded((e) => !e);
  }, [setExpanded]);

  const { data: emailQuery, isLoading: isLoadingEmail } = trpc.email.getEmailById.useQuery({
    emailId,
    organizationId,
  });

  if (isLoadingEmail) {
    return (
      <div
        className="border-accent hover:bg-accent flex w-full cursor-pointer border-b px-4 py-2 align-top"
        onClick={handleToggleExpand}
      >
        <div className="mr-2 shrink-0 overflow-hidden rounded-md">
          <Skeleton className="size-6 rounded" />
        </div>
        <div className="h-fit w-0 grow transition-all">
          {/* Rest outside of the image */}
          <div className="flex flex-row justify-between text-base">
            {/* Title */}
            <Skeleton className="h-4 w-32" />
            {/* timestamp */}
            <Skeleton className="h-3 w-16" />
          </div>
          <Skeleton className="mt-1 h-3 w-24" />
        </div>
      </div>
    );
  }

  const email = emailQuery?.email;

  if (emailQuery == null || !emailQuery.isEmailAuthorized || email == null) {
    return (
      <div className="border-accent flex w-full border-b px-4 py-2 align-top">
        <div className="mr-2 shrink-0 overflow-hidden rounded-md">
          <div className="size-6" />
        </div>
        <div className="h-fit w-0 grow transition-all">
          {/* Rest outside of the image */}
          <div className="flex flex-row justify-between text-base">
            {/* Title and time row */}
            <div className="text-muted-foreground italic">Private Email</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="border-accent hover:bg-accent flex w-full cursor-pointer border-b px-4 py-2 align-top"
      onClick={handleToggleExpand}
    >
      <div className="mr-2 shrink-0 overflow-hidden rounded-md">
        <UserProfileImage className="size-6" id={email.fromUserId} />
      </div>
      <div className="h-fit w-0 grow transition-all">
        {/* Rest outside of the image */}
        <div className="flex flex-row justify-between text-base">
          {/* Title and time row */}
          <div className="flex items-center space-x-1">
            <span>{email.subject}</span>
            {/* {actionLog.executedBy.type === "email" && <Icon className="size-3 text-primary" fill="fill" name="zap" />}
            <span className="flex space-x-2">{actionLog.actionDisplayMetadata.name}</span> */}
          </div>
          <span className="text-muted-foreground text-sm">
            <CommentTimestamp timestamp={email.createdAt} />
          </span>
        </div>
        <UserNameById className="text-muted-foreground text-sm" id={email.fromUserId} />
        {expanded ? <EmailComponent className="bg-paper mt-2" email={email} /> : null}
      </div>
    </div>
  );
};
