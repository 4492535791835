import type { IViewFeature } from "@archetype/dsl";
import type { IFeatureId } from "@archetype/ids";
import { ActionId, ColumnId, EntityTypeId, FeatureId } from "@archetype/ids";

export const dataViewFeatureId: IFeatureId = FeatureId.parse("de4feabf-9e9f-407c-9403-5d0c69342e2c");
export const searchFeatureId: IFeatureId = FeatureId.parse("7f4d5012-bf4c-4951-97e4-69fbbbd2abbf");
export const filterFeatureId: IFeatureId = FeatureId.parse("a5bbf0d0-7fc8-40eb-89eb-4c44e8e391c9");
export const metricFeatureId: IFeatureId = FeatureId.parse("8812dcec-6ea9-4a30-990a-7e1481760fb7");

export const MOCK_ENTITY_TYPE = {
  id: EntityTypeId.parse("95fd617b-d632-435b-9545-2122ac90d07f"),
  firstColumnId: ColumnId.parse("8910ea7a-7b47-4a0c-9356-ef58c71c4433"),
  secondColumnId: ColumnId.parse("7f4d5012-bf4c-4a0c-9356-ef58c71c4433"),
  secondColumnFilterValue: "mock filter value",
  groupByColumnId: ColumnId.parse("1b2ec5cb-b839-4837-b010-ae869b4679e3"),
};

export const MOCK_ACTION = {
  id: ActionId.parse("95fd617b-d632-435b-9545-2122ac90d07f"),
};

export const MOCK_ACTION_SECOND = {
  id: ActionId.parse("028eb90d-250e-48f9-b707-04c354f471fb"),
};

export const searchFeature = {
  id: searchFeatureId,
  type: "searchFeature" as const,
  columns: {
    columnIds: [MOCK_ENTITY_TYPE.firstColumnId],
  },
  parent: dataViewFeatureId,
};

export const dataViewFeature = {
  id: dataViewFeatureId,
  type: "dataView" as const,
  dataModel: MOCK_ENTITY_TYPE.id,
  dataViewType: {
    type: "table" as const,
  },
};

export const dataViewFeatureAsGrouped = {
  id: dataViewFeatureId,
  type: "dataView" as const,
  dataModel: MOCK_ENTITY_TYPE.id,
  dataViewType: {
    type: "groupedTable" as const,
    groupByColumn: MOCK_ENTITY_TYPE.groupByColumnId,
  },
};

export const dataViewFeatureAsBoard = {
  id: dataViewFeatureId,
  type: "dataView" as const,
  dataModel: MOCK_ENTITY_TYPE.id,
  dataViewType: {
    type: "board" as const,
    groupByColumn: MOCK_ENTITY_TYPE.groupByColumnId,
  },
};

export const dataDisplayFeature: IViewFeature = {
  id: FeatureId.parse("8812dcec-6ea9-40eb-89eb-4c44e8e391c9"),
  type: "dataDisplay",
  parent: dataViewFeatureId,
  columns: {
    columnIds: [MOCK_ENTITY_TYPE.firstColumnId, MOCK_ENTITY_TYPE.secondColumnId],
  },
};

export const filterFeature = {
  id: filterFeatureId,
  type: "filterFeature" as const,
  columns: {
    columnIds: [MOCK_ENTITY_TYPE.firstColumnId],
  },
  filterViewType: {
    type: "knownFilterViewType",
    knownFilterViewType: {
      type: "list",
    },
  },
  parent: dataViewFeatureId,
};

export const metricFeature: IViewFeature = {
  id: metricFeatureId,
  type: "metricSubview",
  aggregation: {
    type: "count",
  },
  parent: dataViewFeatureId,
  metricViewType: {
    type: "latest",
  },
  timeAggregation: {
    type: "day",
  },
};

export const dataLoadingFeature: IViewFeature = {
  id: FeatureId.parse("1d90e2ca-0788-37ba-b20d-e0fd9d161699"),
  type: "dataLoading",
  parent: dataViewFeatureId,
  constraints: {
    filters: [
      {
        column: MOCK_ENTITY_TYPE.secondColumnId,
        value: {
          type: "string",
          value: MOCK_ENTITY_TYPE.secondColumnFilterValue,
        },
      },
    ],
    sorts: {
      columnIds: [],
    },
  },
};

export const inlineActionFeature: IViewFeature = {
  id: FeatureId.parse("84fd71bf-0e54-4160-bd6d-2c4928a527be"),
  type: "actionFeature",
  parent: dataViewFeatureId,
  action: MOCK_ACTION.id,
  displayStyle: {
    type: "inline",
  },
};

export const singleActionFeature: IViewFeature = {
  id: FeatureId.parse("a5991e9f-d797-43a7-aba2-3da833127dbf"),
  type: "actionFeature",
  parent: dataViewFeatureId,
  action: MOCK_ACTION_SECOND.id,
  displayStyle: {
    type: "single",
  },
};
