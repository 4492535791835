import type { IVersionType } from "@archetype/dsl";
import type { IActionId, IEntityId, IEntityTypeId, IOrganizationId } from "@archetype/ids";
import type { IBreadcrumbNavItem } from "@archetype/ui";
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from "@archetype/ui";
import { useMemo } from "react";
import React from "react";

import type { IGetActionRoute, IGetEntityRoute, IGetEntityTypeRoute } from "../api";
import { generateActionBreadcrumb } from "../breadcrumbs/generateActionBreadcrumb";
import { generateEntityBreadcrumb } from "../breadcrumbs/generateEntityBreadcrumb";
import { generateEntityTypeBreadcrumb } from "../breadcrumbs/generateEntityTypeBreadcrumb";

export type IWorkspaceBreadcrumb =
  | {
      type: "entity";
      entityTypeId: IEntityTypeId;
      entityId: IEntityId;
      isCurrentPage: boolean;
      enableSearch: boolean;
    }
  | {
      type: "action";
      actionId: IActionId;
      entityTypeId: IEntityTypeId;
      entityId: IEntityId | undefined;
      isCurrentPage: boolean;
      isCreateActionDraft: boolean;
    }
  | {
      type: "entityType";
      entityTypeId: IEntityTypeId;
      isCurrentPage: boolean;
    };

interface IWorkspaceBreadcrumbs {
  organizationId: IOrganizationId;
  versionType: IVersionType;
  breadcrumbs: IWorkspaceBreadcrumb[];
  getActionRoute: IGetActionRoute;
  getEntityRoute: IGetEntityRoute;
  getEntityTypeRoute: IGetEntityTypeRoute;
}

export const WorkspaceBreadcrumbs: React.FC<IWorkspaceBreadcrumbs> = ({
  organizationId,
  versionType,
  breadcrumbs,
  getActionRoute,
  getEntityRoute,
  getEntityTypeRoute,
}) => {
  const breadcrumbItems: IBreadcrumbNavItem[] = useMemo(() => {
    const result: IBreadcrumbNavItem[] = breadcrumbs.map((breadcrumb) => {
      switch (breadcrumb.type) {
        case "entity": {
          return generateEntityBreadcrumb({
            organizationId,
            entityTypeId: breadcrumb.entityTypeId,
            entityId: breadcrumb.entityId,
            isCurrentPage: breadcrumb.isCurrentPage,
            enableSearch: breadcrumb.enableSearch,
            versionType,
            getEntityRoute,
          });
        }

        case "action": {
          return generateActionBreadcrumb({
            actionId: breadcrumb.actionId,
            entityTypeId: breadcrumb.entityTypeId,
            entityId: breadcrumb.entityId,
            isCreateActionDraft: breadcrumb.isCreateActionDraft,
            isCurrentPage: breadcrumb.isCurrentPage,
            versionType,
            getActionRoute,
          });
        }

        case "entityType": {
          return generateEntityTypeBreadcrumb({
            entityTypeId: breadcrumb.entityTypeId,
            isCurrentPage: breadcrumb.isCurrentPage,
            versionType,
            getEntityTypeRoute,
          });
        }
      }
    });

    return result;
  }, [organizationId, versionType, breadcrumbs, getActionRoute, getEntityRoute, getEntityTypeRoute]);

  const renderBreadcrumbItem = (breadcrumb: IBreadcrumbNavItem, idx: number): React.ReactElement => {
    if (breadcrumb.isCurrentPage === true) {
      return (
        <BreadcrumbItem key={idx}>
          <BreadcrumbPage>{breadcrumb.text}</BreadcrumbPage>
        </BreadcrumbItem>
      );
    }

    return (
      <BreadcrumbItem key={idx}>
        {breadcrumb.linkRenderer ? (
          breadcrumb.linkRenderer({
            children: breadcrumb.text,
            className: "no-underline",
          })
        ) : (
          <span className="block truncate">{breadcrumb.text}</span>
        )}
      </BreadcrumbItem>
    );
  };

  if (breadcrumbItems.length === 0) {
    return null;
  }

  return (
    <Breadcrumb>
      <BreadcrumbList>{breadcrumbItems.map(renderBreadcrumbItem)}</BreadcrumbList>
    </Breadcrumb>
  );
};
