import type { IColumnTypeId } from "@archetype/ids";
import { match, P } from "ts-pattern";

import type { IColumnTypeDefinitions } from "../../schemas/dataModel/ColumnType";
import type { IColumnTypeTree } from "./resolveTypeTree";

export const walkTypeTree = <T>(
  tree: IColumnTypeTree,
  onLeaf: (leaf: IColumnTypeTree) => T,
  onNode: (node: IColumnTypeTree) => { result: T[]; stop: boolean },
): T[] => {
  const stack = [tree];
  const out = [];

  while (stack.length > 0) {
    const currentNode = stack.pop();

    if (currentNode?.child != null) {
      const { result, stop } = onNode(currentNode);

      out.push(...result);

      if (!stop) {
        stack.push(currentNode.child);
      }
    } else if (currentNode != null) {
      out.push(onLeaf(currentNode));
    } else {
      throw new Error("Invalid type tree");
    }
  }

  return out;
};

export const findLeaves = (tree: IColumnTypeTree): IColumnTypeTree[] =>
  walkTypeTree(
    tree,
    (leaf) => leaf,
    () => ({ result: [], stop: false }),
  );

export const hasMatchingType = (tree: IColumnTypeTree, predicate: (node: IColumnTypeTree) => boolean): boolean =>
  walkTypeTree(
    tree,
    (leaf) => {
      if (predicate(leaf)) {
        return true;
      }

      return false;
    },
    (node) => {
      if (predicate(node)) {
        return { result: [true], stop: true };
      }

      return { result: [], stop: false };
    },
  ).some((x) => x);

export const getChildColumnForTypeDefinition = (typeDefinition: IColumnTypeDefinitions): IColumnTypeId | null =>
  match(typeDefinition)
    .returnType<IColumnTypeId | null>()
    .with({ child: P.any }, ({ child }) => child)
    .with(
      { type: "array" },
      { type: "boolean" },
      { type: "date" },
      { type: "geolocation" },
      { type: "integer" },
      { type: "number" },
      { type: "string" },
      { type: "timeseries" },
      { type: "timestamp" },
      () => null,
    )
    .exhaustive();
