import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import type { ClassValue } from "clsx";
import * as React from "react";

import { cn } from "../../lib/utils";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

const buttonVariants = cva(
  "relative inline-flex select-none items-center justify-center gap-x-1.5 rounded-md border border-brand text-base font-medium text-ink ring-offset-paper transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:pointer-events-none disabled:border-transparent disabled:bg-muted-background disabled:text-accent-foreground disabled:shadow-none",
  {
    variants: {
      variant: {
        primary: "bg-brand text-paper hover:brightness-110",
        secondary: "border-border bg-paper hover:bg-accent-background",
        ghost:
          "border-transparent bg-transparent hover:bg-accent-background active:bg-muted-background disabled:border-transparent disabled:bg-transparent",
        link: "gap-x-1 border-none bg-transparent !px-0 normal-case tracking-normal underline decoration-secondary-background underline-offset-2 hover:decoration-ink disabled:border-transparent disabled:bg-transparent disabled:text-accent-foreground disabled:no-underline",
      },
      size: {
        xs: "h-6 px-2 text-xs",
        sm: "h-7 px-2.5 text-sm",
        md: "h-8 px-3 text-base",
        lg: "h-9 px-4 text-base",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "sm",
    },
  },
);

export interface IBaseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  iconLeft?: IIconNames;
  iconRight?: IIconNames;
  isLoading?: boolean;
}

export type IButtonPropsWithoutChildren =
  | {
      children?: never;
      title: string;
    }
  | {
      children?: never;
      iconLeft: IIconNames;
    };

export type IButtonPropsWithChildren = {
  children: React.ReactNode;
};

export type IButtonProps = IBaseButtonProps & (IButtonPropsWithoutChildren | IButtonPropsWithChildren);

function getSquareStyles({
  children,
  iconLeft,
  iconRight,
  size,
  variant,
}: {
  children: IBaseButtonProps["children"];
  iconLeft: IBaseButtonProps["iconLeft"];
  iconRight: IBaseButtonProps["iconRight"];
  size: IBaseButtonProps["size"];
  variant: IBaseButtonProps["variant"];
}): ClassValue {
  if (children == null && (iconLeft != null || iconRight != null)) {
    const baseClasses = variant !== "primary" && variant != null ? "text-accent-foreground" : "";

    switch (size) {
      case "xs": {
        return cn("w-6 px-1", baseClasses);
      }
      case "sm": {
        return cn("size-7 px-1", baseClasses);
      }
      case "md": {
        return cn("size-8 px-1", baseClasses);
      }
      case "lg": {
        return cn("size-9 px-1", baseClasses);
      }
      case undefined:
      case null: {
        return cn("size-7 px-1", baseClasses);
      }
    }
  }

  return "";
}

const getGapSize = (size: IBaseButtonProps["size"]): string => {
  switch (size) {
    case "xs":
    case "sm": {
      return "gap-x-1";
    }
    case "md": {
      return "gap-x-1.5";
    }
    case "lg": {
      return "gap-x-1.5";
    }
    case undefined:
    case null: {
      return "gap-x-1";
    }
  }
};

const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  ({ className, variant, size, asChild = false, children, iconLeft, iconRight, isLoading, ...props }, ref) => {
    const ButtonComponent = asChild ? Slot : "button";

    const getIconSize = (buttonSize: typeof size): string => {
      switch (buttonSize) {
        case "xs": {
          return "size-3";
        }
        case "sm": {
          return "size-3.5";
        }
        case "md": {
          return "size-4";
        }
        case "lg": {
          return "size-4";
        }
        case undefined:
        case null: {
          return "size-4";
        }
      }
    };
    const iconSize = getIconSize(size);
    const iconClass = cn(iconSize, "shrink-0");
    const squareStyles = getSquareStyles({ children, iconLeft, iconRight, size, variant });

    const content = (
      <span className={cn("inline-flex items-center justify-center truncate leading-[normal]", getGapSize(size))}>
        {(iconLeft != null || children != null) && (
          <>
            {iconLeft != null && <Icon className={iconClass} name={iconLeft} />}
            {children != null && <span className="truncate">{children}</span>}
          </>
        )}
        {iconRight != null && <Icon className={iconClass} name={iconRight} />}
      </span>
    );

    const buttonComponent = (
      <ButtonComponent
        ref={ref}
        className={cn(buttonVariants({ variant, size, className }), squareStyles, "group", "overflow-hidden")}
        disabled={props.disabled === true || isLoading}
        {...props}
      >
        {content}
        {isLoading === true && variant !== "link" && (
          <div className="absolute inset-x-0 bottom-0 h-0.5">
            <div className={cn("animate-loading-bar bg-brand h-full")} />
          </div>
        )}
      </ButtonComponent>
    );

    const title = props.title;

    if (title == null) {
      return buttonComponent;
    }

    return (
      <Tooltip delayDuration={300}>
        <TooltipTrigger asChild>{buttonComponent}</TooltipTrigger>
        <TooltipContent className="bg-black text-white">{title}</TooltipContent>
      </Tooltip>
    );
  },
);

Button.displayName = "Button";

export { Button, buttonVariants };
