import { ColumnId } from "@archetype/ids";
import { z } from "zod";

import { Column } from "../dataModel/Column";
import { DataLoadingFilterOrOperator } from "../dataModel/dataLoading/PerColumnConditions";

export const LLMColumnFilter = z.object({
  columnId: ColumnId,
  operator: DataLoadingFilterOrOperator,
  value: z.array(z.string()),
});

export const LLMColumnFilterInput = z.object({
  columns: z.array(Column.pick({ id: true, columnType: true, displayMetadata: true })),
  entityTitle: z.string(),
  currentTimestamp: z.string(),
  filterDescription: z.string(),
});

export const LLMColumnFilterOutput = z.object({
  output: z.discriminatedUnion("type", [
    z.object({ type: z.literal("error"), error: z.string().optional() }),
    z.object({ type: z.literal("filters"), filters: LLMColumnFilter.array() }),
  ]),
});

export type ILLMColumnFilter = z.infer<typeof LLMColumnFilter>;
export type ILLMColumnFilterInput = z.infer<typeof LLMColumnFilterInput>;
export type ILLMColumnFilterOutput = z.infer<typeof LLMColumnFilterOutput>;
