import { OrganizationId, UserId } from "@archetype/ids";
import { z } from "zod";

import { OrganizationRole } from "./OrganizationRole";

export const OrganizationUser = z.object({
  userId: UserId,
  organizationId: OrganizationId,
  role: OrganizationRole,
});

export type IOrganizationUser = z.infer<typeof OrganizationUser>;
