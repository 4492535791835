import { Loader, Tooltip, TooltipContent, TooltipTrigger } from "@archetype/ui";

import { type IUseEntitiesDataProps, useEntitiesData } from "./hooks/useEntitiesData";

const MAX_ENTITIES_TO_SHOW = 3;

interface IEntitiesSelectionSummary {
  entities: string[];
  entityName?: string;
}

export const EntitiesSelectionSummary: React.FC<IEntitiesSelectionSummary> = ({ entities, entityName = "Items" }) => {
  if (entities.length === 0) return "...";
  if (entities.length <= MAX_ENTITIES_TO_SHOW) return entities.join(", ");

  return (
    <Tooltip>
      <TooltipTrigger>
        {entities.length} {entityName}
      </TooltipTrigger>
      <TooltipContent side="bottom" sideOffset={10}>
        <div className="flex flex-col gap-1 whitespace-nowrap">
          {entities.map((entity) => (
            <p key={entity}>{entity}</p>
          ))}
        </div>
      </TooltipContent>
    </Tooltip>
  );
};

export const RelatedEntitiesSelectionSummary: React.FC<IUseEntitiesDataProps> = (props) => {
  const { data, isPending } = useEntitiesData(props);

  if (props.entityIds.length === 0) return "...";
  if (isPending) return <Loader size="sm" text="" />;

  const entities = data?.entities ?? [];

  return (
    <EntitiesSelectionSummary
      entities={entities.map((e) => e.displayName)}
      entityName={props.entityType.displayMetadata.pluralName}
    />
  );
};
