import { useEffect, useMemo } from "react";
import { usePrevious } from "react-use";

import type { IDataTableSelectionMode } from "../api";
import type { IDataTableCellIndicator } from "../components/cells/api";

interface IUseTooltipStateProps {
  indicator: IDataTableCellIndicator | undefined;
  isCellSelected: boolean;
  isCellEditing: boolean;
  selectionMode: IDataTableSelectionMode<string, string>["type"];
  onViewLastEditError?: (operationId: string) => void;
}

interface IUseTooltipStateResult {
  isOpen: boolean;
}

export function useTooltipState({
  indicator,
  isCellSelected,
  isCellEditing,
  onViewLastEditError,
}: IUseTooltipStateProps): IUseTooltipStateResult {
  const isTooltipOpen = useMemo(() => {
    // This means the tooltip will never be open with managed state when selection mode is not "cell"
    return isCellSelected || isCellEditing;
  }, [isCellSelected, isCellEditing]);

  const previousIsTooltipOpen = usePrevious(isTooltipOpen);

  useEffect(() => {
    const lastEditError = indicator?.type === "editError" ? indicator.lastEditError : undefined;

    if (previousIsTooltipOpen == null || !previousIsTooltipOpen || lastEditError == null || isTooltipOpen) {
      return;
    }

    onViewLastEditError?.(lastEditError.operationId);
  }, [isTooltipOpen, previousIsTooltipOpen, indicator, onViewLastEditError]);

  return {
    isOpen: isTooltipOpen,
  };
}
