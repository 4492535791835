import type { IVersionType } from "@archetype/dsl";
import type { IActionId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { Skeleton } from "@archetype/ui";
import { skipToken } from "@tanstack/react-query";

interface IActionBreadcrumb {
  actionId?: IActionId;
  versionType: IVersionType;
}

export const ActionBreadcrumb: React.FC<IActionBreadcrumb> = ({ actionId, versionType }) => {
  const { data: actionQuery } = builderTrpc.dataModel.getFullyLoadedActionById.useQuery(
    actionId == null
      ? skipToken
      : {
          versionType,
          actionId,
        },
  );

  const action = actionQuery?.action;

  if (action == null) {
    return (
      <div className="flex items-center space-x-2">
        <Skeleton className="h-6 w-48" />
      </div>
    );
  }

  return (
    <span className="flex items-center space-x-2">
      <span className="truncate">{action.displayMetadata.name}</span>
    </span>
  );
};
