import type { IAuthElement, IDynamicValueConfig, IInputDefaultValue, IStaticValueConfig } from "@archetype/dsl";
import assertNever from "assert-never";

function convertAuthValueToFixedAutofillDynamicValue(value: IAuthElement["value"]): IDynamicValueConfig["value"] {
  switch (value) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- this makes it more extensible
    case "currentUser": {
      return "currentUser";
    }
  }
}

export function convertFixedValueAutofillToInputDefaultValue(
  autofill: IDynamicValueConfig | IStaticValueConfig | undefined,
): IInputDefaultValue | undefined {
  if (autofill == null) {
    return undefined;
  }

  switch (autofill.type) {
    case "dynamic": {
      const dynamicValue = autofill.value;

      if (dynamicValue === "currentUser") {
        return {
          type: "auth",
          value: "currentUser",
        };
      }

      return {
        type: "computed",
        value: dynamicValue,
      };
    }

    case "static": {
      return {
        type: "static",
        value: autofill.value,
      };
    }
    default: {
      assertNever(autofill);
    }
  }
}

export function convertInputDefaultValueToFixedValueAutofill(
  defaultValue: IInputDefaultValue | undefined,
): IDynamicValueConfig | IStaticValueConfig | undefined {
  if (defaultValue == null) {
    return;
  }

  if (defaultValue.type === "static") {
    const staticAutofill: IStaticValueConfig = {
      type: "static",
      value: defaultValue.value,
    };

    return staticAutofill;
  } else if (defaultValue.type === "computed") {
    const computedAutofill: IDynamicValueConfig = {
      type: "dynamic",
      value: defaultValue.value,
    };

    return computedAutofill;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- last type
  } else if (defaultValue.type === "auth") {
    const authAutofill: IDynamicValueConfig = {
      type: "dynamic",
      value: convertAuthValueToFixedAutofillDynamicValue(defaultValue.value),
    };

    return authAutofill;
  }
  assertNever(defaultValue);
}

export function convertInputDefaultValueToFixedValueAutofillEdit(
  defaultValue: IInputDefaultValue | undefined,
): IDynamicValueConfig | IStaticValueConfig | undefined {
  if (defaultValue == null) {
    return;
  }

  switch (defaultValue.type) {
    case "static": {
      return {
        type: "static",
        value: defaultValue.value,
      };
    }
    case "computed": {
      return {
        type: "dynamic",
        value: defaultValue.value,
      };
    }
    case "auth": {
      return {
        type: "dynamic",
        value: convertAuthValueToFixedAutofillDynamicValue(defaultValue.value),
      };
    }
    default: {
      assertNever(defaultValue);
    }
  }
}
