import { Checkbox } from "@archetype/ui";
import React, { useCallback } from "react";

import { useCellClickInteraction } from "../../hooks/useCellClickInteraction";
import type { IDataTableCheckboxCell, IDataTableCommonCellProps } from "./api";

export function CheckboxCell<TRowId extends string, TColId extends string>({
  cell,
  isCellEditing,
  isCellSelected,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTableCheckboxCell<TRowId, TColId>>): React.ReactNode {
  const { value, onChange, readOnly, rowId, colId } = cell;

  const { handleCellClick } = useCellClickInteraction({
    isCellEditing,
    isCellSelected,
    readOnly,
    rowId,
    colId,
  });

  const handleChange = useCallback(
    (checked: boolean): void => {
      if (checked !== value && !readOnly) {
        void onChange?.(checked);
      }
    },
    [onChange, value, readOnly],
  );

  return (
    <div className="flex h-full flex-1 items-center px-2" onClick={handleCellClick}>
      <Checkbox checked={value} onCheckedChange={handleChange} />
    </div>
  );
}
