import type { IRelationCore } from "@archetype/dsl";
import { Column, EntityTypeCore, type IColumn, type IEntityTypeCore, RelationCore } from "@archetype/dsl";
import type { IRelationId } from "@archetype/ids";
import { ColumnId, type IColumnId, RelationId } from "@archetype/ids";
import { z } from "zod";

export const LoadedEntityType = EntityTypeCore.omit({
  columns: true,
}).merge(
  z.object({
    columns: z.record(ColumnId, Column.optional()),
    relations: z.record(RelationId, RelationCore.optional()),
  }),
);

export type ILoadedEntityType = Omit<IEntityTypeCore, "columns"> & {
  columns: Partial<Record<IColumnId, IColumn>>;
  relations: Partial<Record<IRelationId, IRelationCore>>;
};
