import type { IVersionType } from "@archetype/dsl";
import type { IEntityId, IEntityTypeId, IOrganizationId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { Button, cn, useMemoDeepCompare } from "@archetype/ui";
import React, { useCallback, useMemo, useState } from "react";

import { LoadingEmailExpandableItem } from "./LoadingEmailExpandableItem";

interface ILoadingEmailsList {
  className?: string;
  organizationId: IOrganizationId;
  entityTypeId: IEntityTypeId;
  userEntityId: IEntityId;
  versionType: IVersionType;
}

const PAGE_SIZE = 20;

export const LoadingEmailsList: React.FC<ILoadingEmailsList> = ({
  className,
  organizationId,
  entityTypeId,
  userEntityId,
  versionType,
}) => {
  const { data: emailsQuery, isLoading: isLoadingEmails } = trpc.email.getEmailIdsByUserEntityId.useQuery({
    organizationId,
    entityTypeId,
    userEntityId,
    versionType,
  });

  const [visiblePages, setVisiblePages] = useState<number>(1);

  const handleShowMore = useCallback(() => {
    setVisiblePages((currentVisiblePages) => currentVisiblePages + 1);
  }, [setVisiblePages]);

  const visibleEmails = useMemoDeepCompare(
    () => emailsQuery?.emailIds.slice(0, visiblePages * PAGE_SIZE),
    [emailsQuery?.emailIds, visiblePages],
  );
  const hasMorePages = useMemo(
    () => emailsQuery?.emailIds != null && visibleEmails != null && emailsQuery.emailIds.length > visibleEmails.length,
    [emailsQuery?.emailIds, visibleEmails],
  );

  if (isLoadingEmails) {
    return null;
  }

  if (visibleEmails == null || visibleEmails.length === 0) {
    return null;
  }

  return (
    <div className="border-border bg-paper text-ink overflow-hidden rounded border">
      <div className={cn(className, "bg-paper items-stretch")}>
        <div>
          <div className="border-accent border-b px-3 py-4 text-base">Emails</div>
          <div className="flex flex-col">
            {visibleEmails.map((emailId) => (
              <LoadingEmailExpandableItem key={emailId} emailId={emailId} organizationId={organizationId} />
            ))}
            {hasMorePages ? (
              <Button className="w-full" iconLeft="chevron-down" size="xs" variant="ghost" onClick={handleShowMore}>
                Show more
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
