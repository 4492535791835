import { getComponentDefinitionRegistry, getLayoutDefinitionRegistry } from "@archetype/builder-definitions";
import type { IModuleInstance, INonIdVersionIdentifiableFields } from "@archetype/dsl";
import { SlotId } from "@archetype/ids";
import { forEach } from "@archetype/utils";
import type { Result } from "neverthrow";
import { err, ok } from "neverthrow";

import type { ComponentInputsNotCompatibleWithSlot } from "../errors/ComponentInputsNotCompatibleWithSlot";
import type { ComponentNotCompatibleWithSlotSize } from "../errors/ComponentNotCompatibleWithSlotSize";
import { ComponentNotDefinedInRegistry } from "../errors/ComponentNotDefinedInRegistry";
import type { ComponentOutputsNotCompatibleWithSlot } from "../errors/ComponentOutputsNotCompatibleWithSlot";
import { LayoutNotDefinedInRegistry } from "../errors/LayoutNotDefinedInRegistry";
import { SlotNotDefinedError } from "../errors/SlotNotDefined";
import { isComponentCompatibleWithSlot } from "./isComponentCompatibleWithSlot";

export const checkLayoutCompatibility = ({
  moduleInstance,
}: {
  moduleInstance: Omit<IModuleInstance, INonIdVersionIdentifiableFields>;
}): Result<
  true,
  | SlotNotDefinedError
  | ComponentNotDefinedInRegistry
  | ComponentOutputsNotCompatibleWithSlot
  | ComponentNotCompatibleWithSlotSize
  | ComponentInputsNotCompatibleWithSlot
  | LayoutNotDefinedInRegistry
> => {
  const componentRegistry = getComponentDefinitionRegistry();
  const layoutDefinition = getLayoutDefinitionRegistry()[moduleInstance.layoutId];

  if (layoutDefinition == null) {
    return err(new LayoutNotDefinedInRegistry({ layoutId: moduleInstance.layoutId }));
  }

  let results:
    | Result<
        true,
        | SlotNotDefinedError
        | ComponentNotDefinedInRegistry
        | ComponentOutputsNotCompatibleWithSlot
        | ComponentNotCompatibleWithSlotSize
        | ComponentInputsNotCompatibleWithSlot
      >
    | undefined = undefined;

  forEach(moduleInstance.slots, (slot, rawSlotId) => {
    const slotId = SlotId.parse(rawSlotId);

    if (slot === undefined) {
      results = err(new SlotNotDefinedError({ slotId, layoutId: layoutDefinition.id }));

      return false; // break out of foreach
    }

    const slotDef = layoutDefinition.slots[slotId];

    if (slotDef === undefined) {
      results = err(new SlotNotDefinedError({ slotId, layoutId: layoutDefinition.id }));

      return false; // break out of foreach
    }

    const component = componentRegistry[slot.componentDefinitionId];

    if (component === undefined) {
      results = err(new ComponentNotDefinedInRegistry({ componentId: slot.componentDefinitionId }));

      return false; // break out of foreach
    }

    const res = isComponentCompatibleWithSlot({ component, slot: slotDef });

    if (res.isErr()) {
      results = err(res.error);

      return false; // break out of foreach
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- bad type inferrence
  return results ?? ok(true);
};
