import type { IActionCore, IAuthorization, IEntityTypeCore, IRelationCore, IViewField } from "@archetype/dsl";
import { createColumnViewField, createRelationViewField, isColumnViewField } from "@archetype/dsl";
import type { IActionId, IColumnId, IEntityTypeId, IRelationId, IStateId } from "@archetype/ids";
import { keyByNoUndefined, map, mapKeysNoUndefined, mapValues } from "@archetype/utils";

import type { IReadableIdMappings } from "../dataModel/inferrenceTypings";
import { optimisticReadableIdentifierFromString } from "../dataModel/utils";
import type {
  DATA_MODEL_REF_MAPPING_ID_TYPES,
  IIdentifierMapperFunctions,
  IReferencedDataModelIds,
  IStringIdentifierMappings,
} from "../features/dataModelReferencesMapperTypes";
import { mapperFunctionsFromMappings } from "../features/dataModelReferencesMapperTypes";
import { createFileLogger } from "../logger";
import type { IOperationsEditsWithStateMachine } from "./editOperations/operations";

const logger = createFileLogger("mapReferencesInStateMachine");

const throwingOrigToTargColId = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  originEntityTypeIdentifier: IDS["ORIG_ENT_T_ID"],
  columnOriginIdentifier: IDS["ORIG_COL_ID"],
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IDS["TARG_COL_ID"] => {
  const targetColumnIdentifier = idMapperFunctions.origToTargColId(originEntityTypeIdentifier, columnOriginIdentifier);

  if (targetColumnIdentifier == null) {
    const err = `Failed to map column reference ${columnOriginIdentifier} on entity type ${originEntityTypeIdentifier}`;

    logger.error(err);
    throw new Error(err);
  }

  return targetColumnIdentifier;
};

const mapReferencesInViewFieldId = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  originEntityTypeIdentifier: IDS["ORIG_ENT_T_ID"],
  viewField: IViewField,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IViewField =>
  isColumnViewField(viewField)
    ? createColumnViewField(throwingOrigToTargColId(originEntityTypeIdentifier, viewField.columnId, idMapperFunctions))
    : createRelationViewField(idMapperFunctions.origToTargEntityRelationId(viewField.relationId), viewField.direction);

const mapReferencesInEntityType = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  entityType: IEntityTypeCore,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IEntityTypeCore => ({
  id: idMapperFunctions.origToTargEntityTypeId(entityType.id),
  primaryKey: throwingOrigToTargColId(entityType.id, entityType.primaryKey, idMapperFunctions),
  displayNameColumn: throwingOrigToTargColId(entityType.id, entityType.displayNameColumn, idMapperFunctions),
  statusColumn:
    entityType.statusColumn == null
      ? null
      : throwingOrigToTargColId(entityType.id, entityType.statusColumn, idMapperFunctions),
  displayMetadata: entityType.displayMetadata,
  color: entityType.color,
  shape: entityType.shape,
  userEntityTypeInfo: entityType.userEntityTypeInfo,
  columns: entityType.columns.map((column) => ({
    id: throwingOrigToTargColId(entityType.id, column.id, idMapperFunctions),
    displayMetadata: column.displayMetadata,
    columnType:
      column.columnType.type === "statusEnum"
        ? {
            type: "statusEnum",
            allowedValues: column.columnType.allowedValues.map((allowedValue) => ({
              id: idMapperFunctions.origToTargStateId(allowedValue.id),
              readableValue: allowedValue.readableValue,
            })),
            archivedValues: column.columnType.archivedValues,
          }
        : column.columnType,
    validations: column.validations,
    conditionalFormatting:
      column.columnType.type === "statusEnum" && column.conditionalFormatting?.type === "conditionalFormattingEnum"
        ? {
            type: "conditionalFormattingEnum",
            valueToNamedColor: mapKeysNoUndefined(column.conditionalFormatting.valueToNamedColor, (_value, key) =>
              idMapperFunctions.origToTargStateId(key),
            ),
          }
        : column.conditionalFormatting,
    nonNullable: column.nonNullable,
    unique: column.unique,
  })),
  relevantViewFieldsByStateId: mapValues(entityType.relevantViewFieldsByStateId, (viewFields) =>
    viewFields.map((field) => mapReferencesInViewFieldId(entityType.id, field, idMapperFunctions)),
  ),
  authorizedByAnyOf: entityType.authorizedByAnyOf.map((authorization) =>
    mapReferencesInAuthorization(authorization, idMapperFunctions),
  ),
  activityLogAuthorizedByAnyOf: entityType.activityLogAuthorizedByAnyOf.map((authorization) =>
    mapReferencesInAuthorization(authorization, idMapperFunctions),
  ),
  authorizedByAnyOfPerStateId: mapValues(entityType.authorizedByAnyOfPerStateId, (authorizations) =>
    authorizations?.map((authorization) => mapReferencesInAuthorization(authorization, idMapperFunctions)),
  ),
  organizationId: entityType.organizationId,
  targetEntityTypeApplicationGroupId: entityType.targetEntityTypeApplicationGroupId,
  deleteActionId: idMapperFunctions.origToTargActionId(entityType.deleteActionId),
  supportActionsInfo:
    entityType.supportActionsInfo == null
      ? null
      : {
          create: idMapperFunctions.origToTargActionId(entityType.supportActionsInfo.create),
          update: idMapperFunctions.origToTargActionId(entityType.supportActionsInfo.update),
          otherActions: {
            invite: entityType.supportActionsInfo.otherActions.invite,
          },
        },
});

const mapReferencesInAuthorization = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  authorization: IAuthorization,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IAuthorization => {
  return {
    ...authorization,
    authorizedByRelationPath: {
      path: authorization.authorizedByRelationPath.path.map((relation) => ({
        relationId: idMapperFunctions.origToTargEntityRelationId(relation.relationId),
        direction: relation.direction,
      })),
      startingEntityType:
        authorization.authorizedByRelationPath.startingEntityType != null
          ? {
              id: idMapperFunctions.origToTargEntityTypeId(
                authorization.authorizedByRelationPath.startingEntityType.id,
              ),
              filters: authorization.authorizedByRelationPath.startingEntityType.filters,
            }
          : undefined,
      userTypeFilters: authorization.authorizedByRelationPath.userTypeFilters,
    },
  };
};

const mapReferencesInRelation = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  relation: IRelationCore,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IRelationCore => ({
  id: idMapperFunctions.origToTargEntityRelationId(relation.id),
  displayMetadataFromAToB: {
    name: relation.displayMetadataFromAToB.name,
    description: relation.displayMetadataFromAToB.description,
  },
  displayMetadataFromBToA: {
    name: relation.displayMetadataFromBToA.name,
    description: relation.displayMetadataFromBToA.description,
  },
  entityTypeIdA: idMapperFunctions.origToTargEntityTypeId(relation.entityTypeIdA),
  entityTypeIdB: idMapperFunctions.origToTargEntityTypeId(relation.entityTypeIdB),
  config: relation.config,
});

const mapReferencesInAction = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  action: IActionCore,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IActionCore => ({
  id: idMapperFunctions.origToTargActionId(action.id),
  displayMetadata: {
    name: action.displayMetadata.name,
    description: action.displayMetadata.description,
  },
  entityTypeId: idMapperFunctions.origToTargEntityTypeId(action.entityTypeId),
  organizationId: action.organizationId,
  applicationGroupId: action.applicationGroupId,
  actionDefinition: {
    actionType: action.actionDefinition.actionType,
    fromStates: action.actionDefinition.fromStates?.map(idMapperFunctions.origToTargStateId),
    toState:
      action.actionDefinition.toState != null
        ? idMapperFunctions.origToTargStateId(action.actionDefinition.toState)
        : undefined,
    contextualFields: action.actionDefinition.contextualFields.map((c) =>
      mapReferencesInViewFieldId(action.entityTypeId, c, idMapperFunctions),
    ),

    inputs: action.actionDefinition.inputs.map((input) => ({
      required: input.required,
      viewField: mapReferencesInViewFieldId(action.entityTypeId, input.viewField, idMapperFunctions),
      defaultValue: input.defaultValue,
      allowChangingDefault: input.allowChangingDefault,
    })),

    authorizedByAnyOf: action.actionDefinition.authorizedByAnyOf.map((authorization) =>
      mapReferencesInAuthorization(authorization, idMapperFunctions),
    ),
    authorizedForAnyoneWithLink: action.actionDefinition.authorizedForAnyoneWithLink,
    sideEffects:
      action.actionDefinition.sideEffects == null
        ? undefined
        : {
            email: {
              isEnabled: action.actionDefinition.sideEffects.email.isEnabled,
              toPersonRelation:
                action.actionDefinition.sideEffects.email.toPersonRelation == null
                  ? null
                  : {
                      relationId: idMapperFunctions.origToTargEntityRelationId(
                        action.actionDefinition.sideEffects.email.toPersonRelation.relationId,
                      ),
                      direction: action.actionDefinition.sideEffects.email.toPersonRelation.direction,
                    },
              viewFieldsToSend: action.actionDefinition.sideEffects.email.viewFieldsToSend.map((viewField) =>
                mapReferencesInViewFieldId(action.entityTypeId, viewField, idMapperFunctions),
              ),
            },
          },
  },
});

const mapReferencesInStateMachine = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  operationEdits: IOperationsEditsWithStateMachine,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IOperationsEditsWithStateMachine => ({
  organizationId: operationEdits.organizationId,
  stateMachineEdits: mapValues(operationEdits.stateMachineEdits, (stateMachine) => ({
    states: mapKeysNoUndefined(
      mapValues(stateMachine.states, (state) => ({
        ...state,
        id: idMapperFunctions.origToTargStateId(state.id),
      })),
      (_state, stateId) => idMapperFunctions.origToTargStateId(stateId),
    ),

    archivedStates: stateMachine.archivedStates,

    aiAgents: mapKeysNoUndefined(stateMachine.aiAgents, (_aiAgent, stateId) =>
      idMapperFunctions.origToTargStateId(stateId),
    ),

    initialState: {
      state: idMapperFunctions.origToTargStateId(stateMachine.initialState.state),
      actionId: idMapperFunctions.origToTargActionId(stateMachine.initialState.actionId),
    },

    stateTransitions: stateMachine.stateTransitions.map((transition) => ({
      id: transition.id,
      from: idMapperFunctions.origToTargStateId(transition.from),
      to: idMapperFunctions.origToTargStateId(transition.to),
      actionId: idMapperFunctions.origToTargActionId(transition.actionId),
    })),

    happyPath: stateMachine.happyPath.map((state) => idMapperFunctions.origToTargStateId(state)),

    dataModel: {
      targetEntityTypeId: idMapperFunctions.origToTargEntityTypeId(stateMachine.dataModel.targetEntityTypeId),
    },
  })),
  targetEntityType: {
    ...mapReferencesInEntityType(operationEdits.targetEntityType, idMapperFunctions),
    targetEntityTypeApplicationGroupId: operationEdits.targetEntityType.targetEntityTypeApplicationGroupId,
    statusColumn: throwingOrigToTargColId(
      operationEdits.targetEntityType.id,
      operationEdits.targetEntityType.statusColumn,
      idMapperFunctions,
    ),
    relevantViewFieldsByStateId: mapKeysNoUndefined(
      mapValues(operationEdits.targetEntityType.relevantViewFieldsByStateId, (viewFields) =>
        viewFields.map((field) =>
          mapReferencesInViewFieldId(operationEdits.targetEntityType.id, field, idMapperFunctions),
        ),
      ),
      (_viewFields, stateId) => idMapperFunctions.origToTargStateId(stateId),
    ),
  },
  newOrEditedEntityTypes: keyByNoUndefined(
    map(operationEdits.newOrEditedEntityTypes, (entityType) =>
      mapReferencesInEntityType(entityType, idMapperFunctions),
    ),
    (entityType) => entityType.id,
  ),
  newOrEditedRelations: keyByNoUndefined(
    map(operationEdits.newOrEditedRelations, (relation) => mapReferencesInRelation(relation, idMapperFunctions)),
    (relation) => relation.id,
  ),
  deletedRelations: operationEdits.deletedRelations.map((relationId) =>
    idMapperFunctions.origToTargEntityRelationId(relationId),
  ),
  newOrEditedActions: keyByNoUndefined(
    map(operationEdits.newOrEditedActions, (action) => mapReferencesInAction(action, idMapperFunctions)),
    (action) => action.id,
  ),
  deletedActions: operationEdits.deletedActions.map((actionId) => idMapperFunctions.origToTargActionId(actionId)),
});

export const collectReferencesInStateMachine = (
  stateMachine: IOperationsEditsWithStateMachine,
): IReferencedDataModelIds<{
  ORIG_ENT_T_ID: IEntityTypeId;
  TARG_ENT_T_ID: IEntityTypeId;
  ORIG_COL_ID: IColumnId;
  TARG_COL_ID: IColumnId;
  ORIG_ACT_ID: IActionId;
  TARG_ACT_ID: IActionId;
  ORIG_REL_ID: IRelationId;
  TARG_REL_ID: IRelationId;

  ORIG_STATE_ID: IStateId;
  TARG_STATE_ID: IStateId;
}> => {
  const referencedDataModelIds: IReferencedDataModelIds<{
    ORIG_ENT_T_ID: IEntityTypeId;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: IColumnId;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;

    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    newEntityTypes: {},
    preMappedEntityTypes: {},
    newActions: new Set<IActionId>(),
    newEntityRelations: new Set<IRelationId>(),
    newStates: new Set<IStateId>(),
  };

  // Actually a no-op here but side effect of collecting the ids (we could make it cleaner with a forEach but not required)
  const idMapperFunctions: IIdentifierMapperFunctions<{
    ORIG_ENT_T_ID: IEntityTypeId;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: IColumnId;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;

    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    origToTargEntityTypeId: (originEntityTypeId: IEntityTypeId) => {
      if (referencedDataModelIds.newEntityTypes[originEntityTypeId] == null) {
        referencedDataModelIds.newEntityTypes[originEntityTypeId] = new Set<IColumnId>();
      }

      // Noop
      return originEntityTypeId;
    },
    origToTargColId: (originEntityTypeId: IEntityTypeId, origColId: IColumnId) => {
      if (referencedDataModelIds.newEntityTypes[originEntityTypeId] == null) {
        referencedDataModelIds.newEntityTypes[originEntityTypeId] = new Set<IColumnId>();
      }

      referencedDataModelIds.newEntityTypes[originEntityTypeId]?.add(origColId);

      // Noop
      return origColId;
    },
    origToTargColIdOnPreMappedEntityType: (preMappedEntityTypeId: IEntityTypeId, origColId: IColumnId) => {
      if (referencedDataModelIds.preMappedEntityTypes[preMappedEntityTypeId] == null) {
        referencedDataModelIds.preMappedEntityTypes[preMappedEntityTypeId] = new Set<IColumnId>();
      }

      referencedDataModelIds.preMappedEntityTypes[preMappedEntityTypeId]?.add(origColId);

      // Noop
      return origColId;
    },
    origToTargActionId: (originActionId: IActionId) => {
      referencedDataModelIds.newActions.add(originActionId);

      // Noop
      return originActionId;
    },
    origToTargEntityRelationId: (originEntityRelationId: IRelationId) => {
      referencedDataModelIds.newEntityRelations.add(originEntityRelationId);

      // Noop
      return originEntityRelationId;
    },
    origToTargStateId: (originStateId: IStateId) => {
      referencedDataModelIds.newStates.add(originStateId);

      // Noop
      return originStateId;
    },
  };

  mapReferencesInStateMachine(stateMachine, idMapperFunctions);

  return referencedDataModelIds;
};

export const stateMachineWithReadableIdToUuids = (
  stateMachine: IOperationsEditsWithStateMachine,
  readableIdMappings: IReadableIdMappings,
): IOperationsEditsWithStateMachine => {
  const stringIdentifierMappings: IStringIdentifierMappings<{
    ORIG_ENT_T_ID: string;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: string;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;

    ORIG_STATE_ID: string;
    TARG_STATE_ID: IStateId;
  }> = {
    originToTargetIdentifiers: mapValues(readableIdMappings.readableIdsToIds, (entityTypeReadableIds) => ({
      targetIdentifier: entityTypeReadableIds.id,
      columnOriginToTargetIdentifiers: entityTypeReadableIds.columnReadableIdsToIds,
    })),
    preMappedParentColumnMapping: mapValues(
      readableIdMappings.parentExistingEntityReadableIdToIds,
      ({ columnReadableIdsToIds }) => ({
        columnOriginToTargetIdentifiers: columnReadableIdsToIds,
      }),
    ),
    originToTargetActionId: readableIdMappings.actionReadableIdsToIds,
    originToTargetEntityRelationId: readableIdMappings.entityRelationReadableIdsToIds,
    originToTargetStateId: readableIdMappings.stateReadableIdsToIds,
  };

  return mapReferencesInStateMachine(stateMachine, mapperFunctionsFromMappings(stringIdentifierMappings));
};

export const optimisticIdsInStateMachine = (
  /**
   * Should contain readable id, not mapped UUIDs
   */
  stateMachine: IOperationsEditsWithStateMachine,
): IOperationsEditsWithStateMachine => {
  // Actually a no-op here but side effect of collecting the ids (we could make it cleaner with a forEach but not required)
  const idMapperFunctions: IIdentifierMapperFunctions<{
    ORIG_ENT_T_ID: IEntityTypeId;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: IColumnId;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;

    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    origToTargEntityTypeId: (originEntityTypeId: IEntityTypeId) =>
      optimisticReadableIdentifierFromString(originEntityTypeId) as IEntityTypeId,
    origToTargColId: (originEntityTypeId: IEntityTypeId, origColId: IColumnId) =>
      optimisticReadableIdentifierFromString(origColId) as IColumnId,
    origToTargColIdOnPreMappedEntityType: (preMappedEntityTypeId: IEntityTypeId, origColId: IColumnId) =>
      optimisticReadableIdentifierFromString(origColId) as IColumnId,
    origToTargActionId: (originActionId: IActionId) =>
      optimisticReadableIdentifierFromString(originActionId) as IActionId,
    origToTargEntityRelationId: (originEntityRelationId: IRelationId) =>
      optimisticReadableIdentifierFromString(originEntityRelationId) as IRelationId,
    origToTargStateId: (originStateId: IStateId) => optimisticReadableIdentifierFromString(originStateId) as IStateId,
  };

  return mapReferencesInStateMachine(stateMachine, idMapperFunctions);
};
