import type { IComponentDefinitionId, ISlotId } from "@archetype/ids";

import { AbstractError } from "./AbstractError";

export class ComponentOutputsNotCompatibleWithSlot extends AbstractError<"ComponentOutputsNotCompatibleWithSlot"> {
  constructor({ componentId, slotId }: { componentId: IComponentDefinitionId; slotId: ISlotId }) {
    super({
      name: "ComponentOutputsNotCompatibleWithSlot",
      message: `Component ${componentId} outputs are not compatible with slot ${slotId}`,
    });
  }
}
