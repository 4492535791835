import type { IColumn, IViewFieldValue } from "@archetype/dsl";
import { convertEntityColumnValueFromReadableValue } from "@archetype/dsl";
import type { IColumnId } from "@archetype/ids";

type ILlmColumnValue = string | boolean | number | null | string[] | undefined;

export interface IConverterInput {
  value: ILlmColumnValue;
  columnId: IColumnId | undefined;
  columnReadableId?: string;
}

export const convertValueToViewFieldValue = (
  columnsById: Record<IColumnId, IColumn>,
  columnsByReadableId: Record<string, IColumn>,
  input: IConverterInput,
): IViewFieldValue | undefined => {
  let column;

  if (input.columnId != null && input.columnId !== "") {
    column = columnsById[input.columnId];
  } else if (input.columnReadableId != null && input.columnReadableId !== "") {
    column = columnsByReadableId[input.columnReadableId];
  }

  if (!column) {
    return undefined;
  }

  return convertEntityColumnValueFromReadableValue(column.columnType, input.value);
};
