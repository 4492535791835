import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { FilterListComponentDefinitionId } from "@archetype/ids";

export const FilterListComponentIdDeclarations = {
  id: FilterListComponentDefinitionId,
  inputs: ["multiEntityQueryDataInput"],
  outputs: ["filteredMultiEntityQuery"],
  compatibleSemanticSizes: ["sidebar", "actions"],
  validCosmeticOptionalProps: [],
} as const;

export const FilterListComponentDefinition: ITypedComponentDefinitionDeclaration<
  typeof FilterListComponentIdDeclarations
> = {
  constIds: FilterListComponentIdDeclarations,
  displayMetadata: {
    name: "Filter list",
    description: "A list that can output a filtered entity list",
  },
  inputs: {
    multiEntityQueryDataInput: {
      displayMetadata: {
        name: "Filter data",
        description: "Data populate the filter list with",
      },
      optional: false,
    },
  },
  outputs: {
    filteredMultiEntityQuery: {
      displayMetadata: {
        name: "Filtered data",
        description: "The input data, but filtered on the filter list selections",
      },
      optional: false,
    },
  },
};
