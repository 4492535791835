import { z } from "zod";

export const ProcessSeedWorkflowActionSchema = z.object({
  actionId: z.string(),
  title: z.string(),
  description: z.string(),
});

export const ProcessWorkflowSeed = z.object({
  workflowId: z.string().describe("A unique id for this workflow. can be semantic or a uuid"),
  title: z.string(),
  description: z.string(),
  actions: z.array(ProcessSeedWorkflowActionSchema),
});

export const ProcessSeedBusinessConstraintSchema = z.object({
  dataModel: z.string(),
  column: z.string(),
  constraints: z.array(z.string()),
});

export const ProcessSeedDataModelSchema = z.object({
  name: z.string(),
  fields: z.array(
    z.object({
      name: z.string(),
      type: z.string(),
    }),
  ),
  actions: z.array(z.string()),
});

export const ProcessSeedSchema = z.object({
  processId: z.string(),
  title: z.string(),
  description: z.string(),
  workflows: z.array(ProcessWorkflowSeed),
});

export const ProcessSeedPolicySchema = z.object({
  policies: z.array(
    z.object({
      id: z.string().describe("A unique id for this policy. can be semantic or a uuid"),
      title: z.string(),
      description: z.string(),
      selected: z.boolean(),
    }),
  ),
});

export const ProcessDataModelSeedSchema = z.object({
  models: z.array(ProcessSeedDataModelSchema),
  constraints: z.array(ProcessSeedBusinessConstraintSchema),
});

export const WorkflowDocumentationSeedSchema = z.object({
  title: z.string(),
  documentation: z.string(),
});

export type IProcessSeedBusinessConstraintSchema = z.infer<typeof ProcessSeedBusinessConstraintSchema>;
export type IProcessSeedDataModelSchema = z.infer<typeof ProcessSeedDataModelSchema>;
export type IProcessSeedPolicySchema = z.infer<typeof ProcessSeedPolicySchema>;
export type IProcessWorkflowSeed = z.infer<typeof ProcessWorkflowSeed>;
export type IProcessSeedSchema = z.infer<typeof ProcessSeedSchema>;
export type IWorkflowDocumentationSeedSchema = z.infer<typeof WorkflowDocumentationSeedSchema>;
export type IProcessSeedWorkflowActionSchema = z.infer<typeof ProcessSeedWorkflowActionSchema>;
export type IProcessDataModelSeedSchema = z.infer<typeof ProcessDataModelSeedSchema>;
