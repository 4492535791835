import type { IActionInput } from "@archetype/dsl";
import type { IViewFieldId } from "@archetype/ids";
import { clone, findLastIndex } from "lodash";

import type { IDependenciesFieldIdsInfo } from "../dependencies/dependencyUtils";

/**
 * Re-orders the inputs of an action based on the dependencies to ensure a field is always shown right after its dependencies
 */
export function orderActionInputsWithDependencies(
  actionInputs: IActionInput[],
  dependenciesInfo: IDependenciesFieldIdsInfo,
): IActionInput[] {
  // So that we dont unnecesarily check for the presence of absent dependencies
  const allPresentFieldIds = new Set(actionInputs.map((f) => f.viewField.id));

  // We can do it in both directions so that we ensure the fields are right after their dependencies
  // Though for now choosing not to enforce that (and if so should be mindful of only for live fields)
  // const reversedSorted = moveInputsForwardToAfterTheirDependencies(
  //   // Important to clone because reverse mutates the array in place
  //   clone(actionInputs).reverse(),
  //   dependenciesInfo,
  //   allPresentFieldIds,
  // ).reverse();

  const result = moveInputsForwardToAfterTheirDependencies(actionInputs, dependenciesInfo, allPresentFieldIds);

  return result;
}

/**
 * Orders the inputs of an action so that a dependent field is after its dependency, but it will not be right after the dependency
 * if it is currently further as this function will move them only in a single direction
 */
function moveInputsForwardToAfterTheirDependencies(
  actionInputs: IActionInput[],
  dependenciesInfo: IDependenciesFieldIdsInfo,
  allPresentFieldIds: Set<IViewFieldId>,
): IActionInput[] {
  const { dependencies } = dependenciesInfo;

  const newInputs: IActionInput[] = clone(actionInputs);

  // This should contain the fields that have been visited and not moved to a later index, i.e. at a past index
  const strictlyVisitedFields = new Set<IViewFieldId>();

  let i = 0;

  // while loop because we need to be explicit when we move to a next index and can stay at the same index when the current element gets moved to further in the array
  while (i < newInputs.length) {
    const currentField = newInputs[i];

    if (currentField == null) {
      // not possible
      i++;
      continue;
    }

    const currentRemainingDependencies = dependencies[currentField.viewField.id]?.filter(
      (d) => !strictlyVisitedFields.has(d.id) && allPresentFieldIds.has(d.id),
    );

    if (currentRemainingDependencies != null && currentRemainingDependencies.length > 0) {
      const currentRemainingDependenciesSet = new Set(currentRemainingDependencies.map((d) => d.id));

      const lastDependencyIndex = findLastIndex(newInputs, (f) => currentRemainingDependenciesSet.has(f.viewField.id));

      if (lastDependencyIndex > i) {
        // Then we move the current element to the last dependency index
        newInputs.splice(lastDependencyIndex + 1, 0, currentField);

        // And remove it from the current index
        newInputs.splice(i, 1);

        // And we stay at the same index to continue (the previously current element has not been stricly visited yet as we will check it again)
        continue;
      }
    }

    // If we havent found a last dependency after the current index, we can move on and mark the current field as visited
    strictlyVisitedFields.add(currentField.viewField.id);
    i++;
  }

  return newInputs;
}
