import { StateId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

export const LLMState = z.object({
  stateId: StateId,
  name: ReadableString,
  fields: z.array(ReadableString),
  aiAgentInstructions: ReadableString.optional(),
  // TODO: add authorizations once we support that in features
});
export type ILLMState = z.infer<typeof LLMState>;
