import type { ReactNode } from "react";
import React, { useCallback, useMemo } from "react";

import type { IDataTableColumn, IDataTableRow, IDataTableSelectionMode } from "../api";
import { DataTableEditingProvider } from "../context/DataTableEditingContext";
import { DataTableInstanceProvider } from "../context/DataTableInstanceContext";
import { DataTableNavigationProvider } from "../context/DataTableNavigationContext";
import { DataTablePaginationProvider } from "../context/DataTablePaginationContext";
import { DataTableSelectionProvider } from "../context/DataTableSelectionContext";

interface IDataTableProvider<
  TRowId extends string,
  TColId extends string,
  TRowData extends IDataTableRow<TRowId>,
  TCellValue,
> {
  children: ReactNode;
  data: TRowData[];
  columns: IDataTableColumn<TRowId, TColId, TRowData, TCellValue>[];
  rowCount: number;
  selectionMode: IDataTableSelectionMode<TRowId, TColId>;
  rowHeight?: number;
  headerHeight?: number;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  onFetchNextPage?: () => Promise<void>;
}

export function DataTableProvider<
  TRowId extends string,
  TColId extends string,
  TRowData extends IDataTableRow<TRowId>,
  TCellValue,
>({
  children,
  columns,
  data,
  rowCount,
  rowHeight,
  headerHeight,
  hasNextPage = false,
  isFetchingNextPage = false,
  onFetchNextPage,
  selectionMode,
}: IDataTableProvider<TRowId, TColId, TRowData, TCellValue>): React.ReactElement {
  const handleFetchNextPage = useCallback(async () => {
    if (onFetchNextPage) {
      await onFetchNextPage();
    }
  }, [onFetchNextPage]);

  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  return (
    <DataTableInstanceProvider
      columns={memoizedColumns}
      data={memoizedData}
      headerHeight={headerHeight}
      rowCount={rowCount}
      rowHeight={rowHeight}
    >
      <DataTableSelectionProvider selectionMode={selectionMode}>
        <DataTableEditingProvider>
          <DataTableNavigationProvider>
            <DataTablePaginationProvider
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              onFetchNextPage={handleFetchNextPage}
            >
              {children}
            </DataTablePaginationProvider>
          </DataTableNavigationProvider>
        </DataTableEditingProvider>
      </DataTableSelectionProvider>
    </DataTableInstanceProvider>
  );
}
