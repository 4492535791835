"use client";

import { DropdownMenuPortal } from "@radix-ui/react-dropdown-menu";
import pluralize from "pluralize";
import * as React from "react";

import { cn } from "../../lib/utils";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from "./dropdown-menu";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { inputVariants } from "./input";

const MultiSelectDropdown = DropdownMenu;
const MultiSelectDropdownTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuTrigger> & {
    small?: boolean;
    icon?: IIconNames;
    variant?: "default" | "muted";
  }
>(({ className, children, small, icon, variant = "default", ...props }, ref) => (
  <DropdownMenuTrigger
    ref={ref}
    className={cn(
      inputVariants({ size: small === true ? "small" : "default" }),
      "flex items-center justify-between overflow-hidden whitespace-nowrap",
      variant === "muted" && "text-muted-foreground hover:bg-accent-background border-none bg-transparent",
      className,
    )}
    {...props}
  >
    {children}
    {icon != null ? <Icon name={icon} /> : null}
  </DropdownMenuTrigger>
));

MultiSelectDropdownTrigger.displayName = "MultiSelectDropdownTrigger";

const MultiSelectDropdownContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuContent>
>(({ className, children, ...props }, ref) => (
  <DropdownMenuPortal>
    <DropdownMenuContent ref={ref} className={cn("w-64", className)} {...props}>
      {children}
    </DropdownMenuContent>
  </DropdownMenuPortal>
));

MultiSelectDropdownContent.displayName = "MultiSelectDropdownContent";

const MultiSelectDropdownItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuCheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuCheckboxItem> & { small?: boolean }
>(({ className, children, small = false, ...props }, ref) => (
  <DropdownMenuCheckboxItem ref={ref} className={cn("text-lg", small && "text-base", className)} {...props}>
    {children}
  </DropdownMenuCheckboxItem>
));

MultiSelectDropdownItem.displayName = "MultiSelectDropdownItem";

const MultiSelectDropdownValue = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement> & { small?: boolean; label: string; selectedItems: unknown[] }
>(({ small = false, label, selectedItems, ...props }, ref) => {
  const pluralizedLabel = pluralize(label, selectedItems.length);

  return (
    <span ref={ref} className={cn(small && "text-base", "text-muted-foreground")} {...props}>
      {selectedItems.length === 0 ? `Select` : `${selectedItems.length.toFixed()} ${pluralizedLabel}`}
    </span>
  );
});

MultiSelectDropdownValue.displayName = "MultiSelectDropdownValue";

export {
  MultiSelectDropdown,
  MultiSelectDropdownContent,
  MultiSelectDropdownItem,
  MultiSelectDropdownTrigger,
  MultiSelectDropdownValue,
};
