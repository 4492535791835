import { Entity } from "@archetype/dsl";
import { ViewFieldId } from "@archetype/ids";
import * as z from "zod";

const FieldComputationInfo = z.object({
  kind: z.enum(["aiComputed", "derived"]).nullable(),
  status: z.enum(["success", "error", "computing", "recomputing", "outOfDate", "userEdited"]),
});

export type IFieldComputationInfo = z.infer<typeof FieldComputationInfo>;

// enriched version of the entity with all computes in it, maybe no need for another type?
export const LoadedEntity = Entity.merge(
  z.object({
    fieldsComputationStatuses: z.record(ViewFieldId, FieldComputationInfo.optional()),
  }),
);
export type ILoadedEntity = z.infer<typeof LoadedEntity>;
