import { ActionId, RelationId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { RelationDirection } from "../dataModel/RelationBase";
import { LLMActionInput } from "../llm/llmAction";

export const ActionChangeBase = z.object({
  actionId: ActionId,
});
export type IActionChangeBase = z.infer<typeof ActionChangeBase>;

export const ActionNameChange = ActionChangeBase.extend({
  changeActionNameTo: ReadableString,
});
export type IActionNameChange = z.infer<typeof ActionNameChange>;

export const ActionDisableEmailConfigChange = ActionChangeBase.extend({
  shouldDisableEmailConfig: z.enum(["true"]),
});
export type IActionDisableEmailConfigChange = z.infer<typeof ActionDisableEmailConfigChange>;

export const ActionEditEmailConfigChange = ActionChangeBase.extend({
  newEmailConfig: z.object({
    isEnabled: z.boolean(),
    toPersonRelation: z.object({
      relationId: RelationId,
      direction: RelationDirection,
    }),
    viewFieldsToSend: z.array(ReadableString),
  }),
});
export type IActionEditEmailConfigChange = z.infer<typeof ActionEditEmailConfigChange>;

export const ActionAddContextualFieldChange = ActionChangeBase.extend({
  contextualFieldNameToAdd: ReadableString,
});
export type IActionAddContextualFieldChange = z.infer<typeof ActionAddContextualFieldChange>;

export const ActionRemoveContextualFieldChange = ActionChangeBase.extend({
  contextualFieldNameToRemove: ReadableString,
});
export type IActionRemoveContextualFieldChange = z.infer<typeof ActionRemoveContextualFieldChange>;

export const ActionAddInputChange = ActionChangeBase.extend({
  inputFieldToAdd: LLMActionInput,
});
export type IActionAddInputChange = z.infer<typeof ActionAddInputChange>;

export const ActionRemoveInputChange = ActionChangeBase.extend({
  inputFieldNameToRemove: ReadableString,
});
export type IActionRemoveInputChange = z.infer<typeof ActionRemoveInputChange>;

export const ActionEditInputChange = ActionChangeBase.extend({
  inputToEdit: LLMActionInput,
});
export type IActionEditInputChange = z.infer<typeof ActionEditInputChange>;

export const ActionChange = z.union([
  ActionNameChange,
  ActionDisableEmailConfigChange,
  ActionEditEmailConfigChange,
  ActionAddContextualFieldChange,
  ActionRemoveContextualFieldChange,
  ActionAddInputChange,
  ActionRemoveInputChange,
  ActionEditInputChange,
  // TODO: add authorizations once we support that in features
]);
export type IActionChange = z.infer<typeof ActionChange>;

export function isActionNameChange(change: IActionChange): change is IActionNameChange {
  return "changeActionNameTo" in change;
}

export function isActionDisableEmailConfigChange(change: IActionChange): change is IActionDisableEmailConfigChange {
  return "shouldDisableEmailConfig" in change;
}

export function isActionEditEmailConfigChange(change: IActionChange): change is IActionEditEmailConfigChange {
  return "newEmailConfig" in change;
}

export function isActionAddContextualFieldChange(change: IActionChange): change is IActionAddContextualFieldChange {
  return "contextualFieldNameToAdd" in change;
}

export function isActionRemoveContextualFieldChange(
  change: IActionChange,
): change is IActionRemoveContextualFieldChange {
  return "contextualFieldNameToRemove" in change;
}

export function isActionAddInputChange(change: IActionChange): change is IActionAddInputChange {
  return "inputFieldToAdd" in change;
}

export function isActionRemoveInputChange(change: IActionChange): change is IActionRemoveInputChange {
  return "inputFieldNameToRemove" in change;
}

export function isActionEditInputChange(change: IActionChange): change is IActionEditInputChange {
  return "inputToEdit" in change;
}

export function isActionChange(change: object): change is IActionChange {
  return "actionId" in change;
}
