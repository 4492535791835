import type { IEmailId, IOrganizationId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { Skeleton } from "@archetype/ui";
import React from "react";

interface ILoadingEmailTitle {
  organizationId: IOrganizationId;
  emailId: IEmailId;
}

export const LoadingEmailTitle: React.FC<ILoadingEmailTitle> = ({ organizationId, emailId }) => {
  const { data: emailQuery, isLoading: isLoadingEmail } = trpc.email.getEmailById.useQuery({
    emailId,
    organizationId,
  });

  if (isLoadingEmail) {
    // TODO email - make a skeleton for email component
    return <Skeleton className="h-4 w-32" />;
  }

  if (emailQuery == null || !emailQuery.isEmailAuthorized || emailQuery.email == null) {
    return <div className="text-muted-foreground italic">Private Email</div>;
  }

  return <span>{emailQuery.email.subject}</span>;
};
