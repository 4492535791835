import { TransitionId } from "@archetype/ids";

import type { IStateMachine, IStateMachineStateTransition } from "../../schemas/ProcessStateMachine";

export const DEFAULT_CREATE_TRANSITION_ID = TransitionId.parse("initial-create-transition");

export interface IStateTransitionOrCreation extends Omit<IStateMachineStateTransition, "from"> {
  from: IStateMachineStateTransition["from"] | null;
}

export const transitionsWithCreationTransition = (stateMachine: IStateMachine): IStateTransitionOrCreation[] => [
  {
    from: null,
    to: stateMachine.initialState.state,
    id: DEFAULT_CREATE_TRANSITION_ID,
    actionId: stateMachine.initialState.actionId,
  },
  ...stateMachine.stateTransitions,
];
