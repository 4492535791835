import { Checkbox } from "@archetype/ui";
import React, { useCallback, useEffect, useMemo } from "react";

import { useCellClickInteraction } from "../../hooks/useCellClickInteraction";
import type { IDataTableCheckboxWithNumberCell, IDataTableCommonCellProps } from "./api";

export function CheckboxWithNumberCell<TRowId extends string, TColId extends string>({
  cell,
  isCellSelected,
  isCellEditing,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTableCheckboxWithNumberCell<TRowId, TColId>>): React.ReactNode {
  const { value, onChange, readOnly, rowId, colId } = cell;

  const { handleCellClick } = useCellClickInteraction({
    isCellEditing,
    isCellSelected,
    readOnly,
    rowId,
    colId,
  });

  const handleChange = useCallback(
    (checked: boolean): void => {
      onChange?.(checked);
    },
    [onChange],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter" && isCellSelected) {
        onChange?.(!value.checked);
        e.preventDefault();

        return;
      }
    },
    [isCellSelected, onChange, value.checked],
  );

  useEffect(() => {
    if (isCellSelected) {
      document.addEventListener("keydown", handleKeyDown);

      return (): void => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isCellSelected, handleKeyDown]);

  const content = useMemo(
    () => <Checkbox checked={value.checked} onCheckedChange={handleChange} />,
    [value.checked, handleChange],
  );

  if (value.checked || isCellSelected) {
    return (
      <div className="flex h-full flex-1 items-center justify-center" onClick={handleCellClick}>
        {content}
      </div>
    );
  }

  return (
    <div className="flex h-full flex-1 items-center justify-center px-2" onClick={handleCellClick}>
      <div className="hidden h-full items-center self-center group-hover/checkbox-with-number:flex group-hover/row:flex">
        {content}
      </div>
      <div className="text-muted-foreground self-center text-sm group-hover/checkbox-with-number:hidden group-hover/row:hidden">
        {value.number}
      </div>
    </div>
  );
}
