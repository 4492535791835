import { cn, Icon, Tooltip, TooltipContent, TooltipTrigger } from "@archetype/ui";
import React from "react";

import type { IDataTableCommonCellTooltip } from "./api";

interface ICellTooltipProps {
  tooltip: IDataTableCommonCellTooltip;
  cellContent: React.ReactNode;
  /**
   * Uncontrolled if undefined
   */
  isOpen: boolean | undefined;
}

export function CellTooltip({ tooltip, cellContent, isOpen }: ICellTooltipProps): React.ReactElement {
  return (
    <Tooltip open={isOpen}>
      <TooltipTrigger asChild>{cellContent}</TooltipTrigger>
      <TooltipContent
        className={cn(
          "flex cursor-default items-center gap-1.5 px-2 py-0",
          tooltip.variant === "danger" && "text-red-500",
          tooltip.variant === "purple" && "text-purple-500",
        )}
      >
        {tooltip.iconLeft != null ? <Icon className="size-3" name={tooltip.iconLeft} /> : null}
        <span className="py-0.5 text-sm">{tooltip.text}</span>
      </TooltipContent>
    </Tooltip>
  );
}
