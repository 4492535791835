import type { IAuthElement, IDynamicValueConfig } from "@archetype/dsl";
import assertNever from "assert-never";

export const getUserStringForDynamicValue = (value: IDynamicValueConfig["value"]): string => {
  switch (value) {
    case "now": {
      return "Now";
    }
    case "today": {
      return "Today";
    }
    case "currentMonth": {
      return "Current month";
    }
    case "currentYear": {
      return "Current year";
    }
    case "randomUUID": {
      return "Generated ID";
    }
    case "startOfMonth": {
      return "Start of month";
    }
    case "endOfMonth": {
      return "End of month";
    }
    case "startOfYear": {
      return "Start of year";
    }
    case "endOfYear": {
      return "End of year";
    }
    case "currentUser": {
      return "Current user";
    }
    case "startOfDay": {
      return "Start of day";
    }
    case "endOfDay": {
      return "End of day";
    }
    default: {
      assertNever(value);
    }
  }
};

export const getUserStringForDefaultAuthValue = (value: IAuthElement["value"]): string => {
  switch (value) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extensibility
    case "currentUser": {
      return "Current user";
    }
  }
};
