import type { ICrossColumnAndConditions, IEntityTypeCore, IPerColumnAndedFilters } from "@archetype/dsl";
import { useMemoDeepCompare } from "@archetype/ui";
import { useState } from "react";

import { useDebouncedValue } from "../../hooks/useDebouncedValue";

interface IUseRelationFilterSearchResult {
  handleSearch: (query: string) => void;
  filterQueryWithSearch: ICrossColumnAndConditions | undefined;
}

/**
 * Hook to handle search filtering for relation inputs.
 *
 * @param otherEntityType - The entity type being searched/filtered
 * @returns Object containing:
 *   - handleSearch: Function to update the search query
 *   - filterQueryWithSearch: Filter query object based on search, or undefined if empty search
 */
export const useRelationFilterSearch = (otherEntityType: IEntityTypeCore): IUseRelationFilterSearchResult => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);

  const filterQueryWithSearch = useMemoDeepCompare((): ICrossColumnAndConditions | undefined => {
    if (debouncedSearchQuery === "") {
      return undefined;
    }

    const perColumn: IPerColumnAndedFilters = {
      [otherEntityType.displayNameColumn]: {
        type: "or" as const,
        rawOrConditions: {
          regex: {
            type: "regex" as const,
            value: debouncedSearchQuery,
            flags: "gi",
          },
        },
        oredAndConditions: [],
      },
    };

    return {
      type: "and",
      perColumn,
      andedCrossColumnOrConditions: [],
      andedRelatedToFilters: [],
    };
  }, [debouncedSearchQuery, otherEntityType.displayNameColumn]);

  const handleSearch = (query: string): void => {
    setSearchQuery(query);
  };

  return {
    handleSearch,
    filterQueryWithSearch,
  };
};
