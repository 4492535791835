import { RelationId } from "@archetype/ids";
import { z } from "zod";

import { RelationDirection } from "../RelationBase";
import { PerColumnAndedFilters } from "./PerColumnAndedFilters";

const BaseCrossColumnOrConditions = z.object({
  type: z.literal("or"),
});

type ICrossColumnOrConditionsInput = z.input<typeof BaseCrossColumnOrConditions> & {
  oredCrossColumnAndConditions: ICrossColumnAndConditionsInput[];
};

type ICrossColumnOrConditionsOutput = z.output<typeof BaseCrossColumnOrConditions> & {
  oredCrossColumnAndConditions: ICrossColumnAndConditionsOutput[];
};

/**
 * Conditions for ORing across columns.
 *
 * Any conditions inside are ORed. This is equivalent to `any(...oredCrossColumnAndConditions)`.
 *
 * ```
 * {
 *   type: "or",
 *   oredCrossColumnAndConditions: ICrossColumnAndConditions[]
 * }
 * ```
 */
export type ICrossColumnOrConditions = z.infer<typeof CrossColumnOrConditions>;

export const CrossColumnOrConditions: z.ZodType<
  ICrossColumnOrConditionsOutput,
  z.ZodTypeDef,
  ICrossColumnOrConditionsInput
> = BaseCrossColumnOrConditions.extend({
  oredCrossColumnAndConditions: z.lazy(() => z.array(CrossColumnAndConditions)),
});

const BaseRelatedToFilter = z.object({
  type: z.literal("relatedTo"),
  relationId: RelationId,
  direction: RelationDirection,
});

type IRelatedToFilterInput = z.input<typeof BaseRelatedToFilter> & {
  filters?: ICrossColumnAndConditionsInput | undefined;
};
type IRelatedToFilterOutput = z.output<typeof BaseRelatedToFilter> & {
  filters?: ICrossColumnAndConditionsOutput | undefined;
};

/**
 * Conditions for checking if an entity is related to another entity.
 *
 * ```
 * {
 *   type: "relatedTo",
 *   relationId: RelationId,
 *   direction: RelationDirection,
 *   filters: ICrossColumnAndConditions
 * }
 * ```
 */
export type IRelatedToFilter = z.infer<typeof RelatedToFilter>;
export const RelatedToFilter: z.ZodType<IRelatedToFilterOutput, z.ZodTypeDef, IRelatedToFilterInput> =
  BaseRelatedToFilter.extend({
    filters: z.lazy(() => CrossColumnAndConditions.optional()),
  });

// Consistent with current `DataLoadingQueryFilters`, can just use that as root and root is always an AND
const BaseCrossColumnAndConditions = z.object({
  type: z.literal("and"),
  perColumn: PerColumnAndedFilters,
  andedRelatedToFilters: z.array(RelatedToFilter).optional(),
});

type ICrossColumnAndConditionsInput = z.input<typeof BaseCrossColumnAndConditions> & {
  andedCrossColumnOrConditions: ICrossColumnOrConditionsInput[];
};
type ICrossColumnAndConditionsOutput = z.output<typeof BaseCrossColumnAndConditions> & {
  andedCrossColumnOrConditions: ICrossColumnOrConditionsOutput[];
};

/**
 * Conditions for ANDing across columns.
 *
 * All conditions inside are ANDed. This is equivalent to `all(...perColumn, ...andedCrossColumnOrConditions)`.
 *
 * ```
 * {
 *   type: "and",
 *   perColumn: IPerColumnAndedFilters,
 *   andedCrossColumnOrConditions: ICrossColumnOrConditions[]
 * }
 * ```
 */
export type ICrossColumnAndConditions = z.infer<typeof CrossColumnAndConditions>;

export const CrossColumnAndConditions: z.ZodType<
  ICrossColumnAndConditionsOutput,
  z.ZodTypeDef,
  ICrossColumnAndConditionsInput
> = BaseCrossColumnAndConditions.extend({
  andedCrossColumnOrConditions: z.lazy(() => z.array(CrossColumnOrConditions)),
});
