import type { ILoadedViewField } from "@archetype/core";
import type { IEmailExtractedActionInput } from "@archetype/dsl";
import type { IEmailId, IOrganizationId, IViewFieldId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { AutofillIndicator } from "@archetype/ui";
import React from "react";

import type { IEmailAsComposerProps } from "./useEmailAsComposer";
import { useEmailAsComposer } from "./useEmailAsComposer";

interface ILoadingEmailFieldIndicatorProps {
  emailId: IEmailId;
  organizationId: IOrganizationId;
  /**
   * Only used for email annotations so if emailExtractedData is provided
   */
  allFieldsById?: Record<IViewFieldId, ILoadedViewField>;
  emailExtractedData?: Partial<Record<IViewFieldId, IEmailExtractedActionInput>>;
}

export const LoadingEmailFieldIndicator: React.FC<ILoadingEmailFieldIndicatorProps> = ({
  emailId,
  organizationId,
  emailExtractedData,
  allFieldsById,
}) => {
  const { data: emailQuery, isLoading: isLoadingEmail } = trpc.email.getEmailById.useQuery({
    emailId,
    organizationId,
  });

  if (isLoadingEmail) {
    return <AutofillIndicator emailContent={{ type: "loading" }} type="email" />;
  }

  if (emailQuery == null || !emailQuery.isEmailAuthorized || emailQuery.email == null) {
    return <AutofillIndicator emailContent={{ type: "unauthorized" }} type="email" />;
  }

  return (
    <EmailFieldIndicator
      allFieldsById={allFieldsById}
      email={emailQuery.email}
      emailExtractedData={emailExtractedData}
    />
  );
};

const EmailFieldIndicator: React.FC<IEmailAsComposerProps> = (props) => {
  const emailComposerProps = useEmailAsComposer(props);

  return (
    <AutofillIndicator
      emailContent={{
        type: "emailContent",
        email: {
          ...emailComposerProps,
          fromOptions: [],
          toOptions: [],
        },
      }}
      type="email"
    />
  );
};
