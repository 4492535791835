import { cn, Skeleton } from "@archetype/ui";
import React from "react";

export const ActionFieldSkeleton: React.FC<{ className?: string }> = ({ className }) => (
  <div className={cn("space-y-1", className)}>
    <div className="flex h-8 items-center justify-between">
      <Skeleton className="h-6 w-24" />
    </div>
    <Skeleton className="h-10 w-full" />
  </div>
);
