import { SlotId } from "@archetype/ids";
import { z } from "zod";

import { ComponentFunctionalConfig } from "./config/ComponentFunctionalConfig";
import { CosmeticOptionalProps } from "./config/CosmeticOptionalConfig";

// Slot has a single size so no need to select the size to render the Component as
export const LayoutSlotInstance = ComponentFunctionalConfig.and(
  z.object({
    cosmeticConfig: CosmeticOptionalProps,
  }),
);

export const LayoutSlotInstanceMap = z.record(SlotId, LayoutSlotInstance.optional());

export type ILayoutSlotInstance = z.infer<typeof LayoutSlotInstance>;
export type ILayoutSlotInstanceMap = z.infer<typeof LayoutSlotInstanceMap>;
