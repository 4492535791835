import type { ILoadedRelationViewField } from "@archetype/core";
import type {
  IDataLoadingFilterAndOperator,
  IDataLoadingSimpleRelationFilter,
  IEntityTypeCore,
  IVersionType,
} from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";
import { ShapeColorIcon } from "@archetype/ui";
import { isNonNullable } from "@archetype/utils";
import { useCallback, useMemo, useState } from "react";

import { useEntitiesData } from "../hooks/useEntitiesData";
import { useRelationEntities } from "../hooks/useRelationEntities";
import { getRelationViewMetadata } from "../utils/getRelationViewMetadata";
import { ListFilter } from "./ListFilter";
import type { ICommandOption, IListFilterOptionsGroup } from "./types";

interface IRelationFilter {
  allEntityTypes: Record<IEntityTypeId, IEntityTypeCore | undefined>;
  defaultSelection: string[];
  entityType: IEntityTypeCore;
  otherEntityType: IEntityTypeCore;
  operator?: IDataLoadingFilterAndOperator;
  relationView: ILoadedRelationViewField;
  versionType: IVersionType;
  onSubmit: (filter: IDataLoadingSimpleRelationFilter, closePopover?: boolean) => void;
}

export const RelationFilter: React.FC<IRelationFilter> = (props) => {
  const { allEntityTypes, defaultSelection, entityType, onSubmit, otherEntityType, relationView, versionType } = props;

  // We keep this in state so that "initial selection" is a stable list that won't change as the user toggles options
  const [initialSelection] = useState(defaultSelection);

  const metadata = useMemo(() => getRelationViewMetadata(relationView, allEntityTypes), [relationView, allEntityTypes]);

  const inputIcon = useMemo(() => {
    if (!metadata) return null;

    return <ShapeColorIcon className="mr-0.5" color={metadata.iconColor} shape={metadata.iconShape} size="sm" />;
  }, [metadata]);

  const { data: initialSelectionEntities } = useEntitiesData({
    entityIds: initialSelection,
    entityType: otherEntityType,
    versionType,
  });

  const { state: relationEntitiesState } = useRelationEntities({
    entityType,
    otherEntityType,
    relationView,
    versionType,
  });

  const allEntitiesData = relationEntitiesState.data;
  const isLoadingAllEntities = relationEntitiesState.isPending;

  const listGroups = useMemo<IListFilterOptionsGroup[]>(() => {
    if (isLoadingAllEntities) return [];

    const selectedOpts = (initialSelectionEntities?.entities ?? []).map<ICommandOption>((entity) => ({
      id: entity.entityId,
      value: entity.displayName,
    }));

    const otherEntities = (allEntitiesData ?? { pages: [] }).pages
      .flatMap((page) => page.entities)
      .filter((e) => !initialSelection.includes(e.entityId));

    const groups: (IListFilterOptionsGroup | null)[] = [];
    const showGroupTitles = selectedOpts.length > 0 && otherEntities.length > 0;

    if (selectedOpts.length > 0) {
      groups.push({ id: "selected", title: showGroupTitles ? "Initial selection" : undefined, options: selectedOpts });
    }

    if (otherEntities.length > 0) {
      const allOpts = otherEntities.map((e) => ({ id: e.entityId, value: e.entityId, component: e.displayName }));

      groups.push({ id: "all", title: showGroupTitles ? "Other options" : undefined, options: allOpts });
    }

    return groups.filter(isNonNullable);
  }, [allEntitiesData, initialSelection, initialSelectionEntities?.entities, isLoadingAllEntities]);

  const handleSubmit = useCallback(
    (op: IDataLoadingFilterAndOperator, value: string[], closePopover?: boolean) => {
      const filter: IDataLoadingSimpleRelationFilter = {
        op,
        value,
        type: "relation",
        colId: otherEntityType.primaryKey,
        direction: relationView.direction,
        relationId: relationView.relation.id,
      };

      onSubmit(filter, closePopover);
    },
    [onSubmit, otherEntityType.primaryKey, relationView.direction, relationView.relation.id],
  );

  return (
    <ListFilter
      allowMultiSelect={metadata?.cardinality === "many"}
      defaultSelection={initialSelection}
      groups={listGroups}
      icon={inputIcon}
      isLoading={isLoadingAllEntities}
      operator="eq"
      title={metadata?.name ?? "Filter..."}
      onSubmit={handleSubmit}
    />
  );
};
