import type { IAutofill, IRelationDirection } from "@archetype/dsl";
import { isColumnViewField, isRelationViewField, isSupportedRelationAutofill } from "@archetype/dsl";
import type { IColumnId, IEntityTypeId, IRelationId } from "@archetype/ids";
import assertNever from "assert-never";

import { createFileLogger } from "../../logger";
import type { IEditAutofillOperation, IOperationsEdits } from "./operations";

const logger = createFileLogger("applyEditAutofillOperation");

export const applyEditAutofillOperation = (
  operation: IEditAutofillOperation,
  currentInfo: IOperationsEdits,
): IOperationsEdits => {
  const { viewField, autofillConfig, entityTypeId } = operation;

  if (isColumnViewField(viewField)) {
    return applyEditColumnAutofillOperation({
      columnId: viewField.columnId,
      entityTypeId,
      autofillConfig,
      currentInfo,
    });
  }

  if (isRelationViewField(viewField)) {
    if (autofillConfig != null && !isSupportedRelationAutofill(autofillConfig)) {
      logger.error("Cannot edit logic or lookup autofill on relation view field");

      return currentInfo;
    }

    return applyEditRelationAutofillOperation({
      relationId: viewField.relationId,
      direction: viewField.direction,
      autofillConfig,
      currentInfo,
    });
  }

  assertNever(viewField);
};

const applyEditColumnAutofillOperation = ({
  columnId,
  entityTypeId,
  autofillConfig,
  currentInfo,
}: {
  columnId: IColumnId;
  entityTypeId: IEntityTypeId;
  autofillConfig: IAutofill | null;
  currentInfo: IOperationsEdits;
}): IOperationsEdits => {
  const entityType = currentInfo.newOrEditedEntityTypes[entityTypeId];

  if (entityType == null) {
    throw new Error("Entity type not found in loaded entity types");
  }

  const newOrEditedEntityTypes = Object.assign({}, currentInfo.newOrEditedEntityTypes);

  newOrEditedEntityTypes[entityTypeId] = {
    ...entityType,
    columns: entityType.columns.map((column) =>
      column.id === columnId ? { ...column, autofill: autofillConfig ?? undefined } : column,
    ),
  };

  return {
    ...currentInfo,
    newOrEditedEntityTypes,
  };
};

const applyEditRelationAutofillOperation = ({
  relationId,
  direction,
  autofillConfig,
  currentInfo,
}: {
  relationId: IRelationId;
  direction: IRelationDirection;
  autofillConfig: IAutofill | null;
  currentInfo: IOperationsEdits;
}): IOperationsEdits => {
  const relation = currentInfo.newOrEditedRelations[relationId];

  if (relation == null) {
    throw new Error("Relation not found in loaded relations");
  }

  const newOrEditedRelations = Object.assign({}, currentInfo.newOrEditedRelations);

  newOrEditedRelations[relationId] = {
    ...relation,
    autofill:
      autofillConfig == null
        ? undefined
        : {
            autofill: autofillConfig,
            direction,
          },
  };

  return {
    ...currentInfo,
    newOrEditedRelations,
  };
};
