import { match } from "ts-pattern";
import { z } from "zod";

import type { IColumnTypeTree } from "../../utils/dataModel/resolveTypeTree";
import { createNumberTypeValidator } from "../columnTypes/numberValidators";
import { createStringTypeValidator } from "../columnTypes/stringValidators";

const NON_NULLABLE_TYPES = new Set(["nonNullableBoolean", "nonNullableString", "nonNullableNumber"]);

const createBooleanValidator = (): z.ZodBoolean => z.boolean();

export const createColumnTypeValidator = (tree: IColumnTypeTree): z.ZodTypeAny =>
  match(tree.columnType.definition)
    .with({ primitiveType: "number" }, () => createNumberTypeValidator(tree))
    .with({ primitiveType: "boolean" }, () => createBooleanValidator())
    .with({ primitiveType: "string" }, () => createStringTypeValidator(tree))
    .exhaustive();

export function isNullable(tree: IColumnTypeTree): boolean {
  if (NON_NULLABLE_TYPES.has(tree.columnType.definition.type)) {
    return false;
  }
  if (tree.child != null) {
    return isNullable(tree.child);
  }

  return true;
}
