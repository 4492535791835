import { Button, cn, Tooltip, TooltipContent, TooltipTrigger } from "@archetype/ui";
import assertNever from "assert-never";
import Link from "next/link";
import React, { useCallback, useEffect, useState } from "react";

import type { IDataTableCellAction } from "../cells/api";

interface IDataTableActionButton extends IDataTableCellAction {
  cellRef: React.RefObject<HTMLDivElement>;
}

export function DataTableActionButton({
  onClick,
  cellRef,
  text,
  icon,
  tooltip,
  link,
  className,
}: IDataTableActionButton): React.ReactNode {
  const [showButton, setShowButton] = useState(false);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      onClick?.(e);
    },
    [onClick],
  );

  useEffect(() => {
    const element = cellRef.current;

    if (element) {
      const handleMouseEnter = (): void => {
        setShowButton(true);
      };
      const handleMouseLeave = (): void => {
        setShowButton(false);
      };

      element.addEventListener("mouseenter", handleMouseEnter);
      element.addEventListener("mouseleave", handleMouseLeave);

      return (): void => {
        element.removeEventListener("mouseenter", handleMouseEnter);
        element.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [cellRef]);

  const handleLinkClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const renderContent = useCallback(() => {
    const buttonContent = (
      <Button
        className={cn(
          "pointer-events-none absolute right-0 top-1/2 z-30 -translate-y-1/2 opacity-0 transition-all",
          showButton && "pointer-events-auto opacity-100",
          className,
        )}
        iconLeft={icon}
        size="xs"
        variant="secondary"
        onClick={handleClick}
      >
        {text}
      </Button>
    );

    if (link == null) {
      return buttonContent;
    }

    switch (link.type) {
      case "internal": {
        return (
          <Link href={link.href} onClick={handleLinkClick}>
            {buttonContent}
          </Link>
        );
      }
      case "external": {
        return (
          <a href={link.href} onClick={handleLinkClick}>
            {buttonContent}
          </a>
        );
      }
      default: {
        assertNever(link);
      }
    }
  }, [icon, text, showButton, className, handleClick, link, handleLinkClick]);

  if (tooltip == null) {
    return renderContent();
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>{renderContent()}</TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
}
