import type { IComponentIdsDeclarationForId } from "@archetype/builder-definitions";
import type {
  IActionConfigValue,
  IDataLoadingQueryFilters,
  IDataViewFeature,
  IEntityRelationConfigValue,
  ILayoutSlotInstance,
  IStructuredFeature,
  IViewFeature,
  IViewFeatureHierarchy,
} from "@archetype/dsl";
import {
  ActionAvailableFeature,
  DataDisplayFeature,
  DataLoadingFeature,
  dataLoadingFeatureToQuery,
  DataViewFeature,
  FilterFeature,
  FreeTextConfigurationFeature,
  makeViewFeatureHierarchy,
  MetricSubviewFeature,
  SearchFeature,
  ViewFeature,
} from "@archetype/dsl";
import type {
  IActionId,
  IColumnId,
  IComponentDefinitionId,
  IEntityTypeId,
  IFeatureId,
  IInputSemanticId,
  IOutputSemanticId,
  IRelationId,
} from "@archetype/ids";
import {
  ActionButtonComponentDefinitionId,
  BoardComponentDefinitionId,
  CardListComponentDefinitionId,
  ChartComponentDefinitionId,
  DescriptionListComponentDefinitionId,
  EntityDetailsComponentDefinitionId,
  EntityListComponentDefinitionId,
  EventListComponentDefinitionId,
  FilterListComponentDefinitionId,
  ImageComponentDefinitionId,
  ListComponentDefinitionId,
  MapComponentDefinitionId,
  MetricCardComponentDefinitionId,
  SearchInputComponentDefinitionId,
  TableComponentDefinitionId,
  TableGroupedComponentDefinitionId,
} from "@archetype/ids";
import { keyByNoUndefined } from "@archetype/utils";
import assertNever from "assert-never";
import { values } from "lodash";
import { match } from "ts-pattern";

import { createFileLogger } from "../../logger";

const logger = createFileLogger("gatherComponentsFromFeatures");

type INoConfigReference = {
  type: "noConfig";
};

type IRawEntityTypeReference = {
  type: "rawReference";
  entityTypeId?: IEntityTypeId;
};

type IOtherFeatureReference = {
  type: "otherFeatureReference";
  featureId: IFeatureId; // Infers an entityTypeId in that hierarchy
  // TODO (julien): this is a bit TBD and not fully used right now
  // Basically if we just want to verify the type that would be at the end of that reference sequence, we can just pass the
  // entityTypeId from the parent and not have this feature reference type. However there could be some benefit to checking the pathing of the references
  // which we also don't do fully right now because this graph of feature hierarchy and the slot input/outputs graph are built differently
  // Additionally the component doesn't necessarilyknow which semantic id it uses from the parent so this is not ideal.
  // Let's see how far this goes and reassess.
  semanticId:
    | {
        type: "input";
        inputId: IInputSemanticId;
      }
    | {
        type: "output";
        outputId: IOutputSemanticId;
      };
};

// Should an output be described by an input of the same slot?
type IConfigReference = IRawEntityTypeReference | IOtherFeatureReference | INoConfigReference;

type IRuntimeConfigs<ID extends IComponentDefinitionId> = {
  componentDefinitionId: ID;
  inputs: Pick<
    {
      [_inputSemanticId in IInputSemanticId]: IConfigReference;
    },
    IComponentIdsDeclarationForId<ID>["inputs"][number]
  >;

  outputs: Pick<
    {
      [_outputSemanticId in IOutputSemanticId]: IRawEntityTypeReference | INoConfigReference;
    },
    IComponentIdsDeclarationForId<ID>["outputs"][number]
  >;
};

type ICommonRuntimeConfigs = {
  componentDefinitionId: IComponentDefinitionId;
  inputs: {
    [_inputSemanticId in IInputSemanticId]?: IConfigReference;
  };

  outputs: {
    [_outputSemanticId in IOutputSemanticId]?: IRawEntityTypeReference | INoConfigReference;
  };
};

// Typed config that enforces consistency between componentDefinitionId, functional config and the runtime config
// this is a generic though, which we are turning into a union of valid consistent configs with the map and getting the value of the map by its keys below
interface IGenericRuntimeAndFuncComponentConfig<T extends IComponentDefinitionId> extends IRuntimeConfigs<T> {
  slotInstance: ILayoutSlotInstance & { componentDefinitionId: T };
}

type ITempAllRuntimeConfigs = { [key in IComponentDefinitionId]: IGenericRuntimeAndFuncComponentConfig<key> };
/**
 * Typed config to enforce consistency between componentDefinitionId, functional config and the runtime config
 */
type ITypedRuntimeAndFuncComponentConfig = ITempAllRuntimeConfigs[keyof ITempAllRuntimeConfigs];

// Consumers of the results of this function do not need to care about that consistency in the types
export type IRuntimeAndFuncComponentConfig = ICommonRuntimeConfigs & {
  slotInstance: ILayoutSlotInstance;
};

export type IDataLoadingConfigs = Record<
  IFeatureId,
  {
    queryFilters: IDataLoadingQueryFilters;
    // Must be another feature reference because it shouldn't map to the raw input, otherwise it means it wouldn't be used
    // or would mean that all occurences of that type would have that filter, which is not necessarily the intent
    dataReference: IOtherFeatureReference;
  }
>;

export type ITypedRuntimeAndFuncComponentConfigs = Record<IFeatureId, ITypedRuntimeAndFuncComponentConfig>;
export type IRuntimeAndFuncComponentConfigs = Record<IFeatureId, IRuntimeAndFuncComponentConfig>;

export type IConfigurationFromFeatures = {
  componentConfig: Record<IFeatureId, IRuntimeAndFuncComponentConfig>;
  dataLoadingConfigs: IDataLoadingConfigs;
};

export const gatherComponentsFromFeatures = (
  features: Record<string, IStructuredFeature>,
): IConfigurationFromFeatures => {
  // We're dropping the non-view features, but they don't actually tell us anything about components, so that's fine.
  const viewFeatures = values(features).filter(
    (feature): feature is IViewFeature => ViewFeature.safeParse(feature).success,
  );

  // Feature hierarchy with existing ones as well so that we always pass the parent entity type id when adding a new nested feature
  const featureHierarchies = makeViewFeatureHierarchy(keyByNoUndefined(viewFeatures, (feature) => feature.id));

  // Need to add semantic input/output types here, the entity types they map to, either raw or a parent ref feature ref
  // That means we need to load the component definition, actually maybe it doesn't even give us type safety on which inputs/outputs are actually used?
  // we do have that on the layout impl though, so maybe we could move it
  // or add it as a const next to the component definition declaration so that we can derive a typed thing
  // similar to cosmetic props but as a "cache" for the full type so that it maps to something
  // but that's later
  const componentResult: ITypedRuntimeAndFuncComponentConfigs = {};
  const dataLoadingResult: IDataLoadingConfigs = {};

  featureHierarchies.forEach((featureHierarchy) => {
    createOrModifyComponentConfigFromHierarchy(componentResult, dataLoadingResult, featureHierarchy, undefined);
  });

  return {
    componentConfig: componentResult,
    dataLoadingConfigs: dataLoadingResult,
  };
};

const createOrModifyComponentConfigFromHierarchy = (
  componentRes: ITypedRuntimeAndFuncComponentConfigs,
  dataLoadingRes: IDataLoadingConfigs,
  featureHierarchy: IViewFeatureHierarchy,
  parentEntityTypeId: IEntityTypeId | undefined,
  // eslint-disable-next-line max-params -- internal only
): void => {
  const { currentEntityTypeId } = createOrModifyComponentConfig(
    componentRes,
    dataLoadingRes,
    featureHierarchy.feature,
    parentEntityTypeId,
  );

  featureHierarchy.subFeatures.map((subFeatureHierarchy) => {
    createOrModifyComponentConfigFromHierarchy(
      componentRes,
      dataLoadingRes,
      subFeatureHierarchy,
      currentEntityTypeId || parentEntityTypeId,
    );
  });
};

/**
 *
 * @param componentRes adds the inferred entity types to this result
 * @param parentEntityTypeRef must have a parent if the data model is defined on a parent feature, and it must be present in
 * result already (i.e. traverse as a hierarchy)
 */
const createOrModifyComponentConfig = (
  componentRes: ITypedRuntimeAndFuncComponentConfigs,
  dataLoadingRes: IDataLoadingConfigs,
  feature: IViewFeature,
  parentEntityTypeId: IEntityTypeId | undefined,
): {
  currentEntityTypeId: IEntityTypeId | undefined;
  // eslint-disable-next-line max-params -- internal only
} => {
  return match(feature)
    .with({ type: DataViewFeature.shape.type.value }, (dataViewFeature) => {
      const currentEntityTypeId: IEntityTypeId = dataViewFeature.dataModel;
      const componentConfig = handleDataViewFeature(dataViewFeature);

      componentRes[feature.id] = componentConfig;

      return {
        currentEntityTypeId,
      };
    })
    .with({ type: DataDisplayFeature.shape.type.value }, ({ columns, parent, entityRelations }) => {
      const currentEntityTypeId: IEntityTypeId | undefined = undefined;
      // Add columns to parent component config
      let parentComponentConfig = componentRes[parent];

      if (parentComponentConfig == null) {
        logger.error("Column data display must have a parent component config");

        return {
          currentEntityTypeId: undefined,
          componentConfig: undefined,
        };
      }

      parentComponentConfig = addColumnsToConfig(parentComponentConfig, columns.columnIds);
      parentComponentConfig = addRelationsToConfig(parentComponentConfig, entityRelations?.entityRelationIds);

      componentRes[parent] = parentComponentConfig;

      return {
        currentEntityTypeId,
      };
    })
    .with({ type: SearchFeature.shape.type.value }, ({ columns, parent }) => {
      if (parentEntityTypeId == null) {
        logger.error("Search feature must have a parent");

        return {
          currentEntityTypeId: undefined,
          componentConfig: undefined,
        };
      }

      const currentEntityTypeId: IEntityTypeId | undefined = undefined;
      const componentConfig: ITypedRuntimeAndFuncComponentConfig = {
        componentDefinitionId: SearchInputComponentDefinitionId,
        slotInstance: {
          componentDefinitionId: SearchInputComponentDefinitionId,
          cosmeticConfig: {},
          functionalConfig: {
            columnsToSearch: {
              type: "entityColumnList",
              entityTypeId: parentEntityTypeId,
              entityColumnIds: columns.columnIds,
            },
          },
        },
        inputs: {
          multiEntityQueryDataInput: {
            type: "otherFeatureReference",
            featureId: parent,
            semanticId: {
              type: "input",
              inputId: "multiEntityQueryDataInput",
            },
          },
        },
        outputs: {
          filteredMultiEntityQuery: {
            type: "rawReference",
            entityTypeId: parentEntityTypeId,
          },
        },
      };

      componentRes[feature.id] = componentConfig;

      return {
        currentEntityTypeId,
      };
    })
    .with({ type: FilterFeature.shape.type.value }, ({ columns, parent }) => {
      if (parentEntityTypeId == null) {
        logger.error("Filter feature must have a parent");

        return {
          currentEntityTypeId: undefined,
          componentConfig: undefined,
        };
      }

      const currentEntityTypeId: IEntityTypeId | undefined = undefined;
      const componentConfig: ITypedRuntimeAndFuncComponentConfig = {
        componentDefinitionId: FilterListComponentDefinitionId,
        slotInstance: {
          componentDefinitionId: FilterListComponentDefinitionId,
          cosmeticConfig: {},
          functionalConfig: {
            filterColumns: {
              type: "entityColumnList",
              entityTypeId: parentEntityTypeId,
              entityColumnIds: columns.columnIds,
            },
          },
        },
        inputs: {
          multiEntityQueryDataInput: {
            type: "otherFeatureReference",
            featureId: parent,
            semanticId: {
              type: "input",
              inputId: "multiEntityQueryDataInput",
            },
          },
        },
        outputs: {
          filteredMultiEntityQuery: {
            type: "rawReference",
            entityTypeId: parentEntityTypeId,
          },
        },
      };

      componentRes[feature.id] = componentConfig;

      return {
        currentEntityTypeId,
      };
    })
    .with({ type: DataLoadingFeature.shape.type.value }, (dataLoadingFeature) => {
      const currentEntityTypeId: IEntityTypeId | undefined = undefined;

      dataLoadingRes[feature.id] = {
        queryFilters: dataLoadingFeatureToQuery(dataLoadingFeature),
        dataReference: {
          type: "otherFeatureReference",
          featureId: dataLoadingFeature.parent,
          semanticId: {
            type: "input",
            inputId: "multiEntityQueryDataInput",
          },
        },
      };

      return {
        currentEntityTypeId,
      };
    })
    .with({ type: MetricSubviewFeature.shape.type.value }, ({ parent }) => {
      const currentEntityTypeId: IEntityTypeId | undefined = undefined;
      const componentConfig: ITypedRuntimeAndFuncComponentConfig | undefined = {
        componentDefinitionId: MetricCardComponentDefinitionId,
        slotInstance: {
          componentDefinitionId: MetricCardComponentDefinitionId,
          cosmeticConfig: {},
          functionalConfig: {},
        },
        inputs: {
          multiEntityQueryDataInput: {
            type: "otherFeatureReference",
            featureId: parent,
            semanticId: {
              type: "input",
              inputId: "multiEntityQueryDataInput",
            },
          },
        },
        outputs: {},
      };

      componentRes[feature.id] = componentConfig;

      return {
        currentEntityTypeId,
      };
    })
    .with({ type: FreeTextConfigurationFeature.shape.type.value }, () => {
      const currentEntityTypeId: IEntityTypeId | undefined = undefined;

      // Need to be collected to generate cosmetic config from
      return {
        currentEntityTypeId,
      };
    })
    .with({ type: ActionAvailableFeature.shape.type.value }, ({ parent, action, displayStyle }) => {
      const currentEntityTypeId: IEntityTypeId | undefined = undefined;

      if (displayStyle.type === "inline") {
        // Add columns to parent component config
        let parentComponentConfig = componentRes[parent];

        if (parentComponentConfig == null) {
          logger.error("Inline action feature must have a parent component config");
          // default to not inline
        } else {
          parentComponentConfig = addInlineActionToConfig(parentComponentConfig, action);

          componentRes[parent] = parentComponentConfig;

          return {
            currentEntityTypeId,
          };
        }
      }

      const componentConfig: ITypedRuntimeAndFuncComponentConfig | undefined = {
        componentDefinitionId: ActionButtonComponentDefinitionId,
        slotInstance: {
          componentDefinitionId: ActionButtonComponentDefinitionId,
          cosmeticConfig: {},
          functionalConfig: {
            actionId: action,
          },
        },
        inputs: {
          singleEntityDataInput: {
            type: "otherFeatureReference",
            featureId: parent, // Needs to change if mostly shown independently, and feature would change too
            semanticId: {
              type: "input",
              inputId: "multiEntityQueryDataInput",
            },
          },
        },
        outputs: {
          actionSelected: {
            type: "noConfig",
          },
        },
      };

      componentRes[feature.id] = componentConfig;

      return {
        currentEntityTypeId,
      };
    })
    .exhaustive();
};

const handleDataViewFeature = (dataViewFeature: IDataViewFeature): ITypedRuntimeAndFuncComponentConfig => {
  switch (dataViewFeature.dataViewType.type) {
    case "table": {
      return {
        componentDefinitionId: TableComponentDefinitionId,
        slotInstance: {
          componentDefinitionId: TableComponentDefinitionId,
          cosmeticConfig: {},
          functionalConfig: {
            columnsToDisplay: {
              type: "entityColumnList",
              entityTypeId: dataViewFeature.dataModel,
              entityColumnIds: [],
            },
            entityRelations: [],
            inlineActions: [],
          },
        },
        inputs: {
          multiEntityQueryDataInput: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
        },
        outputs: {
          userSelectedSingleEntity: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
          userSelectedLinkedEntity: {
            type: "rawReference",
          },
          actionSelected: {
            type: "noConfig",
          },
        },
      };
    }
    case "groupedTable": {
      return {
        componentDefinitionId: TableGroupedComponentDefinitionId,
        slotInstance: {
          componentDefinitionId: TableGroupedComponentDefinitionId,
          cosmeticConfig: {},
          functionalConfig: {
            columnsToDisplay: {
              type: "entityColumnList",
              entityTypeId: dataViewFeature.dataModel,
              entityColumnIds: [],
            },
            entityRelations: [],
            inlineActions: [],
            groupByColumn: {
              type: "entityColumn",
              entityTypeId: dataViewFeature.dataModel,
              entityColumnId: dataViewFeature.dataViewType.groupByColumn,
            },
          },
        },
        inputs: {
          multiEntityQueryDataInput: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
        },
        outputs: {
          userSelectedSingleEntity: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
          userSelectedLinkedEntity: {
            type: "rawReference",
          },
          actionSelected: {
            type: "noConfig",
          },
        },
      };
    }

    case "cardList": {
      return {
        componentDefinitionId: CardListComponentDefinitionId,
        slotInstance: {
          componentDefinitionId: CardListComponentDefinitionId,
          cosmeticConfig: {},
          functionalConfig: {
            columnsToDisplay: {
              type: "entityColumnList",
              entityTypeId: dataViewFeature.dataModel,
              entityColumnIds: [],
            },
            inlineActions: [],
            groupByColumn: {
              type: "entityColumn",
              entityTypeId: dataViewFeature.dataModel,
              entityColumnId: dataViewFeature.dataViewType.groupByColumn,
            },
          },
        },
        inputs: {
          multiEntityQueryDataInput: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
        },
        outputs: {
          userSelectedSingleEntity: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
          userSelectedLinkedEntity: {
            type: "rawReference",
          },
          actionSelected: {
            type: "noConfig",
          },
        },
      };
    }

    case "board": {
      // Here we would start adding the functional configuration
      // And we would add to it through nested requirements too
      return {
        componentDefinitionId: BoardComponentDefinitionId,
        slotInstance: {
          componentDefinitionId: BoardComponentDefinitionId,
          cosmeticConfig: {},
          functionalConfig: {
            groupByColumn: {
              type: "entityColumn",
              entityTypeId: dataViewFeature.dataModel,
              entityColumnId: dataViewFeature.dataViewType.groupByColumn,
            },
            columnsToDisplay: {
              type: "entityColumnList",
              entityTypeId: dataViewFeature.dataModel,
              entityColumnIds: [],
            },
            inlineActions: [],
          },
        },
        inputs: {
          multiEntityQueryDataInput: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
        },
        outputs: {
          userSelectedSingleEntity: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
          userSelectedLinkedEntity: {
            type: "rawReference",
          },
          actionSelected: {
            type: "noConfig",
          },
        },
      };
    }

    case "map": {
      return {
        componentDefinitionId: MapComponentDefinitionId,
        slotInstance: {
          componentDefinitionId: MapComponentDefinitionId,
          cosmeticConfig: {},
          functionalConfig: {},
        },
        inputs: {
          multiEntityQueryDataInput: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
        },
        outputs: {
          userSelectedSingleEntity: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
        },
      };
    }

    case "list": {
      return {
        componentDefinitionId: ListComponentDefinitionId,
        slotInstance: {
          componentDefinitionId: ListComponentDefinitionId,
          cosmeticConfig: {},
          functionalConfig: {
            columnsToDisplay: {
              type: "entityColumnList",
              entityTypeId: dataViewFeature.dataModel,
              entityColumnIds: [],
            },
            entityRelations: [],
            inlineActions: [],
          },
        },
        inputs: {
          multiEntityQueryDataInput: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
        },
        outputs: {
          userSelectedSingleEntity: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
          userSelectedLinkedEntity: {
            type: "rawReference",
          },
          actionSelected: {
            type: "noConfig",
          },
        },
      };
    }

    case "single": {
      return {
        componentDefinitionId: EntityDetailsComponentDefinitionId,
        slotInstance: {
          componentDefinitionId: EntityDetailsComponentDefinitionId,
          cosmeticConfig: {},
          functionalConfig: {
            columnsToDisplay: {
              type: "entityColumnList",
              entityTypeId: dataViewFeature.dataModel,
              entityColumnIds: [],
            },
          },
        },
        inputs: {
          singleEntityDataInput: {
            type: "rawReference",
            entityTypeId: dataViewFeature.dataModel,
          },
        },
        outputs: {},
      };
    }
  }

  assertNever(dataViewFeature.dataViewType);
};

const addColumnsToConfig = (
  config: ITypedRuntimeAndFuncComponentConfig,
  columns: IColumnId[],
): ITypedRuntimeAndFuncComponentConfig => {
  switch (config.componentDefinitionId) {
    case TableComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            ...config.slotInstance.functionalConfig,
            columnsToDisplay: {
              ...config.slotInstance.functionalConfig.columnsToDisplay,
              entityColumnIds: [...config.slotInstance.functionalConfig.columnsToDisplay.entityColumnIds, ...columns],
            },
          },
        },
      };
    }
    case TableGroupedComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            ...config.slotInstance.functionalConfig,
            columnsToDisplay: {
              ...config.slotInstance.functionalConfig.columnsToDisplay,
              entityColumnIds: [...config.slotInstance.functionalConfig.columnsToDisplay.entityColumnIds, ...columns],
            },
          },
        },
      };
    }
    case CardListComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            ...config.slotInstance.functionalConfig,
            columnsToDisplay: {
              ...config.slotInstance.functionalConfig.columnsToDisplay,
              entityColumnIds: [...config.slotInstance.functionalConfig.columnsToDisplay.entityColumnIds, ...columns],
            },
          },
        },
      };
    }
    case BoardComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            ...config.slotInstance.functionalConfig,
            columnsToDisplay: {
              ...config.slotInstance.functionalConfig.columnsToDisplay,
              entityColumnIds: [...config.slotInstance.functionalConfig.columnsToDisplay.entityColumnIds, ...columns],
            },
          },
        },
      };
    }
    case ListComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            ...config.slotInstance.functionalConfig,
            columnsToDisplay: {
              ...config.slotInstance.functionalConfig.columnsToDisplay,
              entityColumnIds: [...config.slotInstance.functionalConfig.columnsToDisplay.entityColumnIds, ...columns],
            },
            entityRelations: config.slotInstance.functionalConfig.entityRelations,
          },
        },
      };
    }

    case EntityDetailsComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            columnsToDisplay: {
              ...config.slotInstance.functionalConfig.columnsToDisplay,
              entityColumnIds: [...config.slotInstance.functionalConfig.columnsToDisplay.entityColumnIds, ...columns],
            },
          },
        },
      };
    }

    case MapComponentDefinitionId:
    case ActionButtonComponentDefinitionId:
    case ChartComponentDefinitionId:
    case DescriptionListComponentDefinitionId:
    case EntityListComponentDefinitionId:
    case EventListComponentDefinitionId:
    case FilterListComponentDefinitionId:
    case ImageComponentDefinitionId:
    case MetricCardComponentDefinitionId:
    case SearchInputComponentDefinitionId: {
      return config;
    }
  }
};

const addRelationsToConfig = (
  config: ITypedRuntimeAndFuncComponentConfig,
  entityRelationIds: IRelationId[] | undefined,
): ITypedRuntimeAndFuncComponentConfig => {
  const entityRelationsConfigValues: IEntityRelationConfigValue[] = (entityRelationIds ?? []).map((relationId) => ({
    type: "entityRelation",
    entityRelationId: relationId,
  }));

  switch (config.componentDefinitionId) {
    case ListComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            ...config.slotInstance.functionalConfig,
            entityRelations: [...config.slotInstance.functionalConfig.entityRelations, ...entityRelationsConfigValues],
          },
        },
      };
    }
    case TableComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            ...config.slotInstance.functionalConfig,
            entityRelations: [...config.slotInstance.functionalConfig.entityRelations, ...entityRelationsConfigValues],
          },
        },
      };
    }
    case TableGroupedComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            ...config.slotInstance.functionalConfig,
            entityRelations: [...config.slotInstance.functionalConfig.entityRelations, ...entityRelationsConfigValues],
          },
        },
      };
    }
    case CardListComponentDefinitionId:
    case BoardComponentDefinitionId:
    case EntityDetailsComponentDefinitionId:
    case MapComponentDefinitionId:
    case ActionButtonComponentDefinitionId:
    case ChartComponentDefinitionId:
    case DescriptionListComponentDefinitionId:
    case EntityListComponentDefinitionId:
    case EventListComponentDefinitionId:
    case FilterListComponentDefinitionId:
    case ImageComponentDefinitionId:
    case MetricCardComponentDefinitionId:
    case SearchInputComponentDefinitionId: {
      return config;
    }
  }
};

const addInlineActionToConfig = (
  config: ITypedRuntimeAndFuncComponentConfig,
  inlineActionId: IActionId,
): ITypedRuntimeAndFuncComponentConfig => {
  const inlineActionConfigValue: IActionConfigValue = {
    type: "action",
    actionId: inlineActionId,
  };

  switch (config.componentDefinitionId) {
    case TableComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            columnsToDisplay: config.slotInstance.functionalConfig.columnsToDisplay,
            entityRelations: config.slotInstance.functionalConfig.entityRelations,
            inlineActions: [...config.slotInstance.functionalConfig.inlineActions, inlineActionConfigValue],
          },
        },
      };
    }
    case TableGroupedComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            columnsToDisplay: config.slotInstance.functionalConfig.columnsToDisplay,
            entityRelations: config.slotInstance.functionalConfig.entityRelations,
            inlineActions: [...config.slotInstance.functionalConfig.inlineActions, inlineActionConfigValue],
            groupByColumn: config.slotInstance.functionalConfig.groupByColumn,
          },
        },
      };
    }
    case ListComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            columnsToDisplay: config.slotInstance.functionalConfig.columnsToDisplay,
            entityRelations: config.slotInstance.functionalConfig.entityRelations,
            inlineActions: [...config.slotInstance.functionalConfig.inlineActions, inlineActionConfigValue],
          },
        },
      };
    }
    case CardListComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            columnsToDisplay: config.slotInstance.functionalConfig.columnsToDisplay,
            inlineActions: [...config.slotInstance.functionalConfig.inlineActions, inlineActionConfigValue],
            groupByColumn: config.slotInstance.functionalConfig.groupByColumn,
          },
        },
      };
    }
    case BoardComponentDefinitionId: {
      return {
        ...config,
        slotInstance: {
          ...config.slotInstance,
          functionalConfig: {
            columnsToDisplay: config.slotInstance.functionalConfig.columnsToDisplay,
            inlineActions: [...config.slotInstance.functionalConfig.inlineActions, inlineActionConfigValue],
            groupByColumn: config.slotInstance.functionalConfig.groupByColumn,
          },
        },
      };
    }
    case EntityDetailsComponentDefinitionId:
    case MapComponentDefinitionId:
    case ActionButtonComponentDefinitionId:
    case ChartComponentDefinitionId:
    case DescriptionListComponentDefinitionId:
    case EntityListComponentDefinitionId:
    case EventListComponentDefinitionId:
    case FilterListComponentDefinitionId:
    case ImageComponentDefinitionId:
    case MetricCardComponentDefinitionId:
    case SearchInputComponentDefinitionId: {
      return config;
    }
  }
};
