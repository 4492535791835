import type { IViewFieldValue } from "@archetype/dsl";
import { isColumnViewField } from "@archetype/dsl";
import { MultiSelect } from "@archetype/ui";
import { useCallback } from "react";
import React from "react";

import { StringInput } from "../primitive/StringInput";
import type { IActionInputComponent } from "../types";

export const EnumMultiSelectInput: IActionInputComponent = ({
  versionType,
  field,
  onChange,
  input,
  entityTypeId,
  organizationId,
  entity,
  readOnly = false,
  currentUserInfo,
  isFieldTouched,
}) => {
  const { viewField } = input;

  const handleArrayChange = useCallback(
    (selection: { value: string; label: string }[]): void => {
      if (selection.length === 0) {
        onChange({ type: "null" });
      } else {
        onChange({
          type: "array",
          value: selection.map((option) => option.value),
        });
      }
    },
    [onChange],
  );

  const handleStringChange = useCallback(
    (value: IViewFieldValue) => {
      onChange(value);
    },
    [onChange],
  );

  const renderOption = useCallback((opt: { value: string; label: string }): React.ReactNode => {
    return <div>{opt.label}</div>;
  }, []);

  if (!isColumnViewField(viewField)) {
    return (
      <StringInput
        currentUserInfo={currentUserInfo}
        entity={entity}
        entityTypeId={entityTypeId}
        field={field}
        input={input}
        isFieldTouched={isFieldTouched}
        organizationId={organizationId}
        readOnly={readOnly}
        versionType={versionType}
        onChange={handleStringChange}
      />
    );
  }

  const columnType = viewField.column.columnType;

  if (columnType.type !== "enum" || columnType.enumAllowedValues == null || columnType.enumAllowedValues.length === 0) {
    return (
      <StringInput
        currentUserInfo={currentUserInfo}
        entity={entity}
        entityTypeId={entityTypeId}
        field={field}
        input={input}
        isFieldTouched={isFieldTouched}
        organizationId={organizationId}
        readOnly={readOnly}
        versionType={versionType}
        onChange={handleStringChange}
      />
    );
  }

  const enumValues = columnType.enumAllowedValues.map((option) => ({ value: option, label: option }));

  const currentValues =
    field.value?.type === "array"
      ? field.value.value.map((value) => ({
          value: value,
          label: value,
        }))
      : [];

  return (
    <MultiSelect
      disabled={readOnly}
      optionRenderer={renderOption}
      options={enumValues}
      placeholder={!readOnly ? "Select..." : "Selection is currently disabled..."}
      value={currentValues}
      onChange={handleArrayChange}
    />
  );
};
