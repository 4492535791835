import { Skeleton } from "@archetype/ui";
import { TableSkeleton } from "@archetype/ui";

export const InlineEntityTableSkeleton: React.FC<{
  title: string | undefined;
  count: number | undefined;
}> = ({ title, count }) => {
  return (
    <div className="flex flex-col">
      <div className="mb-4 flex flex-row items-center">
        <div className="flex flex-row items-center gap-x-2">
          <Skeleton className="size-6" />
          {title == null ? <Skeleton className="h-4 w-24" /> : <span>{title}</span>}
          {count == null ? <Skeleton className="h-4 w-6" /> : <span>({count})</span>}
        </div>
      </div>
      <TableSkeleton className="h-56" density="default" numberCols={10} numberRows={20} />
    </div>
  );
};
