import { createLoadedColumnViewField, createLoadedRelationViewField } from "@archetype/core";
import type { IVersionType } from "@archetype/dsl";
import type { IColumnId, IEntityTypeId, IOrganizationId, IRelationId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import type { IIconOrShape, IMentionLookupService } from "@archetype/ui";
import { values } from "lodash";
import { useCallback, useMemo } from "react";

import { getDisplayNameForViewField, getIconForViewField } from "../entityType/ColumnTypeUtils";

interface IMentionLookupServiceConfig {
  versionType: IVersionType;
  organizationId: IOrganizationId;
  entityTypeId: IEntityTypeId;
}

type IEntityFieldMention =
  | {
      type: "column";
      id: IColumnId;
      name: string;
      icon: IIconOrShape;
    }
  | {
      type: "relation";
      id: IRelationId;
      name: string;
      icon: IIconOrShape;
      direction: "aToB" | "bToA";
    };

export function useEntityFieldMentionLookupService({
  versionType,
  organizationId,
  entityTypeId,
}: IMentionLookupServiceConfig): IMentionLookupService<IEntityFieldMention> {
  const { data: allEntityTypes } = builderTrpc.dataModel.allEntityTypesInOrganization.useQuery({
    versionType,
    organizationId,
  });

  const { data: entityTypeQuery } = builderTrpc.dataModel.fullyLoadedEntityType.useQuery({
    id: entityTypeId,
    versionType,
  });

  const search = useCallback(
    async (query: string): Promise<IEntityFieldMention[]> => {
      if (entityTypeQuery == null || allEntityTypes == null) {
        return [];
      }

      const columns = values(entityTypeQuery.entityType.columns)
        .filter((column): column is NonNullable<typeof column> => column != null)
        .filter(
          (column) => query.length === 0 || column.displayMetadata.name.toLowerCase().includes(query.toLowerCase()),
        )
        .map((column): IEntityFieldMention => {
          const viewField = createLoadedColumnViewField(column);

          return {
            type: "column",
            id: column.id,
            name: column.displayMetadata.name,
            icon: getIconForViewField(viewField, allEntityTypes),
          };
        });

      const relations = Object.values(allEntityTypes.relations)
        .filter((relation): relation is NonNullable<typeof relation> => relation != null)
        .flatMap((relation): IEntityFieldMention[] => {
          const results: IEntityFieldMention[] = [];

          if (relation.entityTypeIdA === entityTypeId) {
            const direction = "aToB";
            const viewField = createLoadedRelationViewField(relation, direction);

            results.push({
              type: "relation",
              id: relation.id,
              name: getDisplayNameForViewField(viewField),
              icon: getIconForViewField(viewField, allEntityTypes),
              direction,
            });
          }

          if (relation.entityTypeIdB === entityTypeId) {
            const direction = "bToA";
            const viewField = createLoadedRelationViewField(relation, direction);

            results.push({
              type: "relation",
              id: relation.id,
              name: getDisplayNameForViewField(viewField),
              icon: getIconForViewField(viewField, allEntityTypes),
              direction,
            });
          }

          return results;
        });

      return columns.concat(relations);
    },
    [entityTypeQuery, entityTypeId, allEntityTypes],
  );

  return useMemo(
    () => ({
      search,
    }),
    [search],
  );
}
