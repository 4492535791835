import { EntityId, EntityTypeId } from "@archetype/ids";
import * as z from "zod";

import type { IFileColumnValue } from "./EntityValue";
import { EntityColumnValue } from "./EntityValue";

export const RelatedEntity = z.object({ entityId: EntityId, entityTypeId: EntityTypeId, displayName: z.string() });
export type IRelatedEntity = z.infer<typeof RelatedEntity>;

// field values
export const RelationFieldValue = z.object({
  type: z.literal("relatedEntities"),
  value: z.array(RelatedEntity),
});
export type IRelationFieldValue = z.infer<typeof RelationFieldValue>;

export const ViewFieldValue = z.discriminatedUnion("type", [...EntityColumnValue.options, RelationFieldValue]);
export type IViewFieldValue = z.infer<typeof ViewFieldValue>;

// helper methods
export const isRelationFieldValue = (value: IViewFieldValue): value is IRelationFieldValue =>
  value.type === "relatedEntities";

export const isFileFieldValue = (value: IViewFieldValue): value is IFileColumnValue => value.type === "file";
