import { useEffect, useState } from "react";

export const useSyncedLocalStorage = (
  key: string,
  initialValue: string | undefined,
): [string | null | undefined, (value: string | null | undefined) => void] => {
  const [storedValue, setStoredValue] = useState<string | null | undefined>(() => {
    const item = localStorage.getItem(key);

    return item ?? initialValue;
  });

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent): void => {
      if (event.key === key) {
        setStoredValue(event.newValue ?? initialValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return (): void => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, initialValue]);

  return [storedValue, setStoredValue];
};
