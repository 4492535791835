import { EntityTypeId, RelationId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { EntityTypeColor } from "../dataModel/EntityTypeColor";
import { EntityTypeShapeName } from "../dataModel/EntityTypeShapeName";

export const WorkspaceEntityType = z.object({
  title: ReadableString,
  description: ReadableString,
  type: z.enum(["core", "support"]),
});
export type IWorkspaceEntityType = z.infer<typeof WorkspaceEntityType>;

export const WorkspaceEntityTypeWithId = WorkspaceEntityType.extend({
  id: EntityTypeId,
});
export type IWorkspaceEntityTypeWithId = z.infer<typeof WorkspaceEntityTypeWithId>;

export const WorkspaceRelation = z.object({
  titleForBEntitiesWhenOnA: ReadableString,
  titleForAEntitiesWhenOnB: ReadableString,
  entityTypeATitle: ReadableString,
  entityTypeBTitle: ReadableString,
  cardinalityOnSideA: z.enum(["one", "many"]),
  cardinalityOnSideB: z.enum(["one", "many"]),
});
export type IWorkspaceRelation = z.infer<typeof WorkspaceRelation>;

export const WorkspaceRelationWithIds = WorkspaceRelation.extend({
  id: RelationId,
  entityTypeIdA: EntityTypeId,
  entityTypeIdB: EntityTypeId,
});
export type IWorkspaceRelationWithIds = z.infer<typeof WorkspaceRelationWithIds>;

export const WorkspaceWorkflow = z.object({
  title: ReadableString,
  description: ReadableString,
  selected: z.boolean(),
  icon: z.object({
    name: EntityTypeShapeName,
    color: EntityTypeColor,
  }),
});
export type IWorkspaceWorkflow = z.infer<typeof WorkspaceWorkflow>;

export const WorkspaceWorkflowWithIds = WorkspaceWorkflow.extend({
  targetEntityTypeId: EntityTypeId,
});
export type IWorkspaceWorkflowWithIds = z.infer<typeof WorkspaceWorkflowWithIds>;

export const Workspace = z.object({
  entityTypes: z.array(WorkspaceEntityType),
  relations: z.array(WorkspaceRelation),
});
export type IWorkspace = z.infer<typeof Workspace>;

export const WorkspaceWithIds = Workspace.extend({
  entityTypes: z.array(WorkspaceEntityTypeWithId),
  relations: z.array(WorkspaceRelationWithIds),
});
export type IWorkspaceWithIds = z.infer<typeof WorkspaceWithIds>;
