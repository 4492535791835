import { ActionId, ColumnId, EntityTypeId, LayoutConfigurationId, RelationId } from "@archetype/ids";
import { z } from "zod";

import { PrimitiveAnyColumn } from "../dataModel/columnTypes/primitiveColumnTypes";
import { DataLoadingQueryFilters } from "../dataModel/DataLoadingQuery";

export const ColumnTypeConstraint = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("any"),
  }),
  z.object({
    type: z.literal("anyNumeric"),
  }),
  z.object({
    type: z.literal("primary"),
    // TODO(marts): this probably isn't right
    primary: PrimitiveAnyColumn,
  }),
]);

export const JoinCardinalityConstraint = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("any"),
  }),
  z.object({
    type: z.literal("one"),
  }),
  z.object({
    type: z.literal("many"),
  }),
]);

// Static configuration types ———————————————————————————————————————————————————————————

export const EntityConfig = z.object({
  /**
   * No need to encode what entity type the component is for
   */
  type: z.literal("entityType"),
  // TODO: we could encode constraints on the data model and entities here,
  // e.g. for a kanban there must be a low cardinality directional enum column, and a setStatus action
  // And essentially those would be collected as constraints or required configuration (as requirements) for users
  // But there may be a better place to encode this, TBC
});

export const EntityColumnConfig = z.object({
  type: z.literal("entityColumn"),
  /**
   * Can validate consistency between multiple configs and guide selection
   */
  entityTypeConfigReference: LayoutConfigurationId,
  allowedTypes: z.set(ColumnTypeConstraint),
});

// Note this could also be supported with a vararg config with EntityColumnConfig, but this is simpler for now
export const EntityColumnListConfig = z.object({
  type: z.literal("entityColumnList"),
  /**
   * Can validate consistency between multiple configs and guide selection
   */
  entityTypeConfigReference: LayoutConfigurationId,
});

export const EntityRelationConfig = z.object({
  /**
   * Which join to use, potentially in an internal transformation between some slot inputs and outputs
   */
  type: z.literal("entityRelation"),
  entityTypeAConfigReference: LayoutConfigurationId,
  entityTypeBConfigReference: LayoutConfigurationId,
  entityTypeACardinality: JoinCardinalityConstraint,
  entityTypeBCardinality: JoinCardinalityConstraint,
});

export const ActionConfig = z.object({
  /**
   * Which join to use, potentially in an internal transformation between some slot inputs and outputs
   */
  type: z.literal("action"),
  // Probably some constraints on parameters
});

export const LayoutFunctionalConfig = z.discriminatedUnion("type", [
  EntityConfig,
  EntityColumnConfig,
  EntityColumnListConfig,
  EntityRelationConfig,
  ActionConfig,
]);

// Static config values ————————————————————————————————————————————————————————————————

export const EntityTypeConfigValue = z.object({
  type: z.literal("entityType"),
  entityTypeId: EntityTypeId,
  dataLoadingConfig: z
    .object({
      filters: DataLoadingQueryFilters.optional(),
    })
    .optional(),
});

export const EntityColumnConfigValue = z.object({
  type: z.literal("entityColumn"),
  entityTypeId: EntityTypeId,
  entityColumnId: ColumnId,
});

export const EntityColumnListConfigValue = z.object({
  type: z.literal("entityColumnList"),
  entityTypeId: EntityTypeId,
  entityColumnIds: z.array(ColumnId),
});
export type IEntityColumnListConfigValue = z.infer<typeof EntityColumnListConfigValue>;

export const EntityRelationConfigValue = z.object({
  /**
   * Which join to use, potentially in an internal transformation between some slot inputs and outputs
   */
  type: z.literal("entityRelation"),
  entityRelationId: RelationId,
});
export type IEntityRelationConfigValue = z.infer<typeof EntityRelationConfigValue>;

export const ActionConfigValue = z.object({
  /**
   * Which join to use, potentially in an internal transformation between some slot inputs and outputs
   */
  type: z.literal("action"),
  actionId: ActionId,
});
export type IActionConfigValue = z.infer<typeof ActionConfigValue>;

export const LayoutFunctionalConfigValue = z.discriminatedUnion("type", [
  EntityTypeConfigValue,
  EntityColumnConfigValue,
  EntityColumnListConfigValue,
  EntityRelationConfigValue,
  ActionConfigValue,
]);

export type ILayoutFunctionalConfig = z.infer<typeof LayoutFunctionalConfig>;
export type ILayoutFunctionalConfigValue = z.infer<typeof LayoutFunctionalConfigValue>;

export type ILayoutFunctionalConfigType = ILayoutFunctionalConfigValue["type"];
