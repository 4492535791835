import type { ILoadedEntity } from "@archetype/core";
import type { IVersionType } from "@archetype/dsl";
import type { IEntityId, IEntityTypeId, IOrganizationId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { skipToken } from "@tanstack/react-query";

import { useEntityHasSavingAction } from "./useEntityHasSavingAction";

/**
 * Replaces trpc getLoadedEntity to centralize disabling it to avoid conflicts between reloads and frontend action execution
 */
export const useLoadedEntity = ({
  query: { organizationId, versionType, entityTypeId, entityId },
  options: { explicitlyDisabled, refetchInterval },
}: {
  query: {
    organizationId: IOrganizationId;
    versionType: IVersionType;
    /**
     * Disabled if undefined, but useful for flexible props
     */
    entityTypeId: IEntityTypeId | undefined;
    /**
     * Disabled if undefined, but useful for flexible props
     */
    entityId: IEntityId | undefined;
  };
  options: {
    /**
     * Explicitly disabled if true, though it may be disabled for other internal reasons
     */
    explicitlyDisabled?: boolean;
    /**
     * Refetch interval in milliseconds
     */
    refetchInterval?: number;
  };
}): {
  entityData: { entity: ILoadedEntity } | undefined;
  isLoadingEntity: boolean;
  isErrorLoadingEntity: boolean;
} => {
  // Do not reload while there is a saving action
  const hasSavingAction = useEntityHasSavingAction({ versionType, entityTypeId, entityId });

  const {
    data: entityData,
    isLoading: isLoadingEntity,
    isError: isErrorLoadingEntity,
    // eslint-disable-next-line no-restricted-properties -- this is the replacement function/wrapper
  } = builderTrpc.dataLoading.getLoadedEntity.useQuery(
    entityTypeId != null && entityTypeId !== "" && entityId != null && entityId !== ""
      ? {
          organizationId,
          versionType,
          entityTypeId,
          entityId,
        }
      : skipToken,
    {
      refetchInterval,
      enabled: explicitlyDisabled !== true && !hasSavingAction.hasSaving,
    },
  );

  return {
    entityData,
    isLoadingEntity,
    isErrorLoadingEntity,
  };
};
