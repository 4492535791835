import { cn, Input } from "@archetype/ui";
import { useCallback } from "react";
import React from "react";

import type { IColumnFilterComponent } from "../types";

export const StringFilterInput: IColumnFilterComponent = ({ className, value, onChange }) => {
  const handleBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

      if (newValue === "") {
        onChange(undefined);
      } else {
        onChange({
          regex: {
            type: "regex" as const,
            value: newValue,
            flags: "gi",
          },
        });
      }
    },
    [onChange],
  );

  const currentValue = value?.regex?.value;

  // TODO filters - add case sensitivity and contain / full text search options
  return (
    <Input
      key={currentValue}
      small
      className={cn("w-full", className)}
      defaultValue={currentValue ?? ""}
      type="string"
      onBlur={handleBlur}
    />
  );
};
