import type {
  IFieldComputationInfo,
  ILoadedActionInput,
  ILoadedEntity,
  INestedCreateFormValues,
} from "@archetype/core";
import {
  convertFixedValueAutofillToInputDefaultValue,
  isAIViewField,
  isDerivedViewField,
  resolveDefaultValue,
} from "@archetype/core";
import type {
  IDependency,
  IDraftEmailExtractedActionInput,
  IEntityTypeCore,
  IRelatedFieldDependency,
  IRelationCore,
  IValidationError,
  IVersionType,
  IViewFieldValue,
} from "@archetype/dsl";
import {
  computeRelationViewFieldId,
  computeViewFieldId,
  type IActionCurrentUserInfo,
  isColumnViewField,
  isDynamicValueConfig,
  isLiveAutofill,
  isRelatedFieldDependency,
  isStaticValueConfig,
} from "@archetype/dsl";
import type { IActionId, IEntityId, IEntityTypeId, IOrganizationId, IRelationId, IViewFieldId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { FormField, FormItem, FormMessage, useDebounce, useMemoDeepCompare } from "@archetype/ui";
import { forEach, groupByNoUndefined, keyByNoUndefined, mapValues, pickBy } from "@archetype/utils";
import { isEqual, size } from "lodash";
import Link from "next/link";
import { useCallback, useEffect, useRef } from "react";
import type { ControllerRenderProps, UseFormReturn } from "react-hook-form";
import { useDeepCompareEffect, usePrevious } from "react-use";

import type { IGetHighlightedViewFieldRoute, IGetLinkedEntityRoute } from "../api";
import { FieldRenderer } from "../entity/FieldRenderer";
import { fieldValueToFieldRendererValue } from "../entity/fieldValueToFieldRendererValue";
import { getIconForViewField } from "../entityType/ColumnTypeUtils";
import type { ICreateNewProps } from "../inputs/api";
import { ActionField } from "./ActionField";

export interface IActionFormFieldProps {
  versionType: IVersionType;
  organizationId: IOrganizationId;
  currentUserInfo: IActionCurrentUserInfo;
  modifyingEntity: ILoadedEntity | undefined;
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>> | undefined;
  allRelations: Partial<Record<IRelationId, IRelationCore>> | undefined;
  dependencies: IDependency[] | undefined;
  entityId: IEntityId | undefined;
  entityTypeId: IEntityTypeId;
  actionId: IActionId;
  previousOrLogicFieldValue: IViewFieldValue | undefined;
  emailDraftValue: IDraftEmailExtractedActionInput | undefined;
  computationStatus: IFieldComputationInfo | undefined;
  isSavingDraft: boolean;
  input: ILoadedActionInput;
  form: UseFormReturn<Partial<Record<string, IViewFieldValue>>>;
  errors: IValidationError[];
  onSetValue: (id: IViewFieldId, value: IViewFieldValue, isUserEdited: boolean) => void;
  highlightedViewFieldId: IViewFieldId | undefined;
  getHighlightedViewFieldRoute: IGetHighlightedViewFieldRoute | undefined;
  getLinkedEntityRoute: IGetLinkedEntityRoute;
  /**
   *
   * Relevant only a relation input to be able to create an entity in a nested form
   */
  createNewProps: ICreateNewProps | undefined;
  readOnly: boolean;
  /**
   * Only relevant for a parent form that may need it for derivations
   */
  parentFormNestedCreateFormValues: INestedCreateFormValues | undefined;
  /**
   * Only relevant for a parent form that may need it for derivations
   */
  parentFormExternalUserSyntheticEntityId: IEntityId | undefined;
  /**
   * Only relevant for a parent form that may need it for derivations
   */
  parentFormExternalUserFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>> | undefined;
}

export const ActionFormField: React.FC<IActionFormFieldProps> = ({
  versionType,
  organizationId,
  modifyingEntity,
  allEntityTypes,
  allRelations,
  currentUserInfo,
  dependencies,
  entityId,
  entityTypeId,
  actionId,
  previousOrLogicFieldValue,
  computationStatus,
  emailDraftValue,
  isSavingDraft,
  input,
  errors,
  onSetValue: handleSetValue,
  form,
  highlightedViewFieldId,
  getHighlightedViewFieldRoute,
  getLinkedEntityRoute,
  createNewProps,
  readOnly = false,
  parentFormNestedCreateFormValues,
  parentFormExternalUserSyntheticEntityId,
  parentFormExternalUserFieldValues,
}) => {
  const { viewField, defaultValue } = input;

  const computePromiseRef = useRef<{ promise: Promise<unknown>; cancel: () => void }>();

  const { mutateAsync: runFieldComputation, isPending: isRunningFieldComputation } =
    trpc.dataStores.runFieldComputation.useMutation();

  const formValues = form.getValues();
  const dependencyValues: {
    currentFormValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
    externalUserFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>> | undefined;
    nestedCreateFormValues: INestedCreateFormValues | undefined;
  } = useMemoDeepCompare(() => {
    const currentFormValues = mapValues(
      keyByNoUndefined(dependencies ?? [], (dependency) =>
        isRelatedFieldDependency(dependency)
          ? computeRelationViewFieldId(dependency.relationId, dependency.direction)
          : computeViewFieldId(dependency.field),
      ),
      (_dependency, viewFieldId) => formValues[viewFieldId],
    );

    const relevantNestedCreateFormValues: INestedCreateFormValues = {};
    let relevantExternalUserFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>> | undefined = undefined;

    const relatedFieldDependenciesByViewFieldId: Record<IViewFieldId, IRelatedFieldDependency[]> = groupByNoUndefined(
      dependencies?.filter(isRelatedFieldDependency) ?? [],
      (dependency) => computeRelationViewFieldId(dependency.relationId, dependency.direction),
    );

    forEach(relatedFieldDependenciesByViewFieldId, (relatedFieldDependencies, dependencyRelationViewFieldId) => {
      const firstDependency = relatedFieldDependencies[0];

      if (dependencies == null || firstDependency == null) {
        return;
      }

      const allRelatedDependencyFieldIds = new Set(
        relatedFieldDependencies.map((dependency) => computeViewFieldId(dependency.relatedField)),
      );

      const currentRelatedFieldValues = formValues[dependencyRelationViewFieldId];

      const relation = allRelations?.[firstDependency.relationId];

      if (relation == null || currentRelatedFieldValues?.type !== "relatedEntities") {
        return;
      }

      const otherSideEntityTypeId =
        firstDependency.direction === "aToB" ? relation.entityTypeIdB : relation.entityTypeIdA;

      const currentRelatedEntities = currentRelatedFieldValues.value.map((entityValue) => ({
        entityId: entityValue.entityId,
        entityTypeId: entityValue.entityTypeId,
        nestedFormValues: parentFormNestedCreateFormValues?.[otherSideEntityTypeId]?.[entityValue.entityId],
      }));

      if (relevantNestedCreateFormValues[otherSideEntityTypeId] == null) {
        relevantNestedCreateFormValues[otherSideEntityTypeId] = {};
      }

      currentRelatedEntities.forEach(({ entityId: nestedEntityId, nestedFormValues }) => {
        if (nestedFormValues == null) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above
        relevantNestedCreateFormValues[otherSideEntityTypeId]![nestedEntityId] = {
          createActionId: nestedFormValues.createActionId,
          fieldValues: pickBy(nestedFormValues.fieldValues, (_, fieldId) => allRelatedDependencyFieldIds.has(fieldId)),
        };
      });

      const dependsOnExternalUserAction = currentRelatedFieldValues.value.some(
        (entityValue) =>
          entityValue.entityTypeId === currentUserInfo.userEntityTypeId &&
          entityValue.entityId === parentFormExternalUserSyntheticEntityId,
      );

      if (dependsOnExternalUserAction) {
        relevantExternalUserFieldValues = pickBy(parentFormExternalUserFieldValues ?? {}, (_, fieldId) =>
          allRelatedDependencyFieldIds.has(fieldId),
        );
      }
    });

    return {
      currentFormValues,
      externalUserFieldValues: parentFormExternalUserFieldValues == null ? undefined : relevantExternalUserFieldValues,
      nestedCreateFormValues: parentFormNestedCreateFormValues == null ? undefined : relevantNestedCreateFormValues,
    };
  }, [
    dependencies,
    formValues,
    parentFormExternalUserFieldValues,
    parentFormNestedCreateFormValues,
    parentFormExternalUserSyntheticEntityId,
    allRelations,
    currentUserInfo.userEntityTypeId,
  ]);

  const hasDependencies = size(dependencyValues.currentFormValues) > 0;
  const isFieldTouched = form.getFieldState(viewField.id).isTouched;

  const isFieldTouchedRef = useRef(isFieldTouched);

  useEffect(() => {
    isFieldTouchedRef.current = isFieldTouched;
  }, [isFieldTouched]);

  const debouncedDependencyValues = useDebounce(dependencyValues, 1000);
  const previousDependencyValues = usePrevious(debouncedDependencyValues);

  useDeepCompareEffect(() => {
    if (!hasDependencies) {
      // no dependencies, nothing to compute
      return;
    }

    if (isFieldTouchedRef.current) {
      // once user overrides the value in the form, we shouldn't compute that anymore
      return;
    }

    if (computationStatus?.status === "userEdited") {
      // values that where overridden by user shouldn't be computed again
      return;
    }

    if (isEqual(previousDependencyValues, debouncedDependencyValues)) {
      return;
    }

    const cpromise = cancellablePromise(
      runFieldComputation({
        viewFieldId: viewField.id,
        entityFieldValues: debouncedDependencyValues.currentFormValues,
        entityId,
        entityTypeId,
        versionType,
        externalUserSyntheticEntityId: parentFormExternalUserSyntheticEntityId ?? null,
        externalUserFieldValues: debouncedDependencyValues.externalUserFieldValues ?? null,
        nestedCreateFormValues: debouncedDependencyValues.nestedCreateFormValues ?? null,
        organizationId,
      }),
    );

    void cpromise.promise.then((res) => {
      // Should be a ref so that the effect is not triggered again by isFieldTouched, and we check the current value async
      if (res.type === "cancelled" || isFieldTouchedRef.current) {
        return;
      }

      const fieldValue = res.value.fieldValue;

      if (fieldValue != null) {
        handleSetValue(viewField.id, fieldValue, false);
      }
    });

    if (computePromiseRef.current != null) {
      computePromiseRef.current.cancel();
    }

    computePromiseRef.current = cpromise;
  }, [
    previousDependencyValues,
    debouncedDependencyValues,
    computationStatus?.status,
    viewField.displayName,
    hasDependencies,
    handleSetValue,
    runFieldComputation,
    viewField.id,
    entityId,
    entityTypeId,
    versionType,
    organizationId,
    parentFormExternalUserSyntheticEntityId,
  ]);

  const autofillDefaultValue = useMemoDeepCompare(() => {
    if (viewField.autofill == null) {
      return undefined;
    }
    if (isStaticValueConfig(viewField.autofill.config) || isDynamicValueConfig(viewField.autofill.config)) {
      return convertFixedValueAutofillToInputDefaultValue(viewField.autofill.config);
    }

    return undefined;
  }, [viewField]);

  const inputDefaultValue = useMemoDeepCompare(() => {
    const defaultValueToUse = autofillDefaultValue ?? defaultValue; // default value is still used for state columns

    if (defaultValueToUse == null || isDerivedViewField(viewField)) {
      return undefined;
    }

    return resolveDefaultValue(
      defaultValueToUse,
      currentUserInfo,
      isColumnViewField(viewField) ? viewField.column.columnType : undefined,
    );
  }, [autofillDefaultValue, defaultValue, currentUserInfo, viewField]);

  const previousDefaultValue = usePrevious(inputDefaultValue);

  // Set the form value based on default values
  useDeepCompareEffect(() => {
    if (inputDefaultValue == null) {
      return;
    }

    const currentValue = form.getValues(viewField.id);

    if (!input.allowChangingDefault) {
      if (!isEqual(currentValue, inputDefaultValue)) {
        // Set the current value for readonly fields
        handleSetValue(viewField.id, inputDefaultValue, false);
      }
    } else if (
      !isEqual(previousDefaultValue, inputDefaultValue) &&
      ((currentValue == null && previousDefaultValue == null) || isEqual(currentValue, previousDefaultValue))
    ) {
      // for editable fields only change it if empty or the current value is the previous default (which would happen on first compute)
      handleSetValue(viewField.id, inputDefaultValue, false);
    }
  }, [
    form,
    viewField.id,
    input.allowChangingDefault,
    inputDefaultValue,
    previousDefaultValue,
    handleSetValue,
    viewField.displayName,
  ]);

  /**
   * Form default or previous or computed value
   */
  const currentDefaultValue: IViewFieldValue | undefined = useMemoDeepCompare(
    () => inputDefaultValue ?? previousOrLogicFieldValue,
    [previousOrLogicFieldValue, inputDefaultValue],
  );

  const isAIGeneratedDefault = useMemoDeepCompare(() => {
    return (
      isAIViewField(viewField) &&
      !(computationStatus?.kind === "aiComputed" && computationStatus.status === "userEdited")
    );
  }, [computationStatus, viewField]);

  const isDerivationGeneratedDefault = useMemoDeepCompare(() => {
    return (
      isDerivedViewField(viewField) &&
      !(computationStatus?.kind === "derived" && computationStatus.status === "userEdited")
    );
  }, [computationStatus, viewField]);

  const generateWaitingForDependenciesText = useCallback(
    () => `Fill out ${dependencies?.map((dep) => dep.displayName).join(", ") ?? "undefined"}`,
    [dependencies],
  );

  const renderErrorMessages = useCallback((): React.JSX.Element[] => {
    return errors.map((error) => <FormMessage key={error.error}>{error.error}</FormMessage>);
  }, [errors]);

  const renderInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<Partial<Record<string, IViewFieldValue>>, IViewFieldId>;
    }): React.JSX.Element => {
      return (
        <FormItem>
          <ActionField
            actionId={actionId}
            allEntityTypes={allEntityTypes}
            createNewProps={createNewProps}
            currentUserInfo={currentUserInfo}
            emailDraftValue={emailDraftValue}
            entity={modifyingEntity}
            entityTypeId={entityTypeId}
            field={field}
            getHighlightedViewFieldRoute={getHighlightedViewFieldRoute}
            getLinkedEntityRoute={getLinkedEntityRoute}
            input={input}
            isAIDefault={isAIGeneratedDefault}
            isComputing={isRunningFieldComputation}
            isDerived={isDerivationGeneratedDefault}
            isFieldTouched={isFieldTouched}
            isHighlighted={highlightedViewFieldId === viewField.id}
            isSavingDraft={isSavingDraft}
            organizationId={organizationId}
            readOnly={readOnly}
            versionType={versionType}
            onSetValue={handleSetValue}
          />
          {renderErrorMessages()}
        </FormItem>
      );
    },
    [
      actionId,
      allEntityTypes,
      createNewProps,
      modifyingEntity,
      entityTypeId,
      emailDraftValue,
      getHighlightedViewFieldRoute,
      getLinkedEntityRoute,
      input,
      isAIGeneratedDefault,
      isRunningFieldComputation,
      isDerivationGeneratedDefault,
      isSavingDraft,
      highlightedViewFieldId,
      viewField.id,
      organizationId,
      readOnly,
      versionType,
      handleSetValue,
      renderErrorMessages,
      isFieldTouched,
      currentUserInfo,
    ],
  );

  // live computed fields
  if (viewField.autofill != null && isLiveAutofill(viewField.autofill)) {
    const value: IViewFieldValue | undefined =
      form.getValues(viewField.id) == null || form.getValues(viewField.id)?.type === "null"
        ? currentDefaultValue
        : form.getValues(viewField.id);

    const waitingForDepsString =
      value == null && !isRunningFieldComputation ? generateWaitingForDependenciesText() : undefined;

    const content = (
      <FieldRenderer
        key={input.viewField.id}
        entity={modifyingEntity}
        entityTypeId={entityTypeId}
        field={{
          displayName: input.viewField.displayName,
          id: input.viewField.id,
          icon: getIconForViewField(input.viewField, allEntityTypes ?? {}),
          computationStatus: isRunningFieldComputation ? "computing" : computationStatus?.status,
          isDerived: isDerivedViewField(viewField),
          value: fieldValueToFieldRendererValue(value, input.viewField),
          isAiAutofilled: isAIViewField(input.viewField),
          isSavingInAction: false, // technically could be already saving in a different action but would be confusing to show
          waitingForDependenciesString: waitingForDepsString,
        }}
        fieldRef={undefined}
        getLinkedEntityRoute={getLinkedEntityRoute}
        interactive={getHighlightedViewFieldRoute != null}
        isHighlighted={highlightedViewFieldId === viewField.id}
        organizationId={organizationId}
        versionType={versionType}
      />
    );

    if (getHighlightedViewFieldRoute != null) {
      return (
        <Link
          className="no-underline"
          href={getHighlightedViewFieldRoute({ entityTypeId, entityId, viewFieldId: viewField.id })}
        >
          {content}
        </Link>
      );
    }

    return content;
  }

  if (!input.allowChangingDefault) {
    return null;
  }

  return (
    <FormField
      key={viewField.id}
      control={form.control}
      defaultValue={currentDefaultValue}
      name={viewField.id}
      render={renderInput}
    />
  );
};

type ICancellablePromiseValue<T> = Promise<
  | {
      type: "result";
      value: T;
    }
  | {
      type: "cancelled";
    }
>;
const cancellablePromise = <T,>(promise: Promise<T>): { promise: ICancellablePromiseValue<T>; cancel: () => void } => {
  const isCancelled = { value: false };

  const wrappedPromise: ICancellablePromiseValue<T> = promise
    .then((d) => {
      return isCancelled.value ? { type: "cancelled" as const } : { type: "result" as const, value: d };
    })
    .catch((e: unknown) => {
      if (isCancelled.value) {
        return { type: "cancelled" as const };
      }
      throw e;
    });

  return {
    promise: wrappedPromise,
    cancel: (): void => {
      isCancelled.value = true;
    },
  };
};
