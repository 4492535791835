import type { IVersionType } from "@archetype/dsl";
import type { IEntityId, IEntityTypeId, IOrganizationId } from "@archetype/ids";
import type { IBreadcrumbNavItem } from "@archetype/ui";
import { cn, simpleLinkRenderer } from "@archetype/ui";
import React from "react";

import type { IGetEntityRoute } from "../api";
import { EntityBreadcrumb } from "./EntityBreadcrumb";

export function generateEntityBreadcrumb({
  organizationId,
  entityTypeId,
  entityId,
  isCurrentPage,
  versionType,
  getEntityRoute,
  enableSearch,
}: {
  organizationId: IOrganizationId;
  entityTypeId?: IEntityTypeId; // TODO @sspringer this should be required but for now we need to support application groups loading this breadcrumb
  entityId?: IEntityId;
  isCurrentPage: boolean;
  versionType: IVersionType;
  getEntityRoute: IGetEntityRoute;
  enableSearch?: boolean;
}): IBreadcrumbNavItem {
  const linkRenderer =
    entityTypeId == null || entityId == null
      ? undefined
      : simpleLinkRenderer(getEntityRoute({ entityTypeId, entityId }), {
          className: cn("overflow-hidden"),
        });

  return {
    text: (
      <EntityBreadcrumb
        enableSearch={enableSearch}
        entityId={entityId}
        entityTypeId={entityTypeId}
        getEntityRoute={getEntityRoute}
        organizationId={organizationId}
        versionType={versionType}
      />
    ),
    isCurrentPage,
    linkRenderer,
  };
}
