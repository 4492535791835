import type { IFieldComputationInfo, ILoadedEntityType, ILoadedViewField } from "@archetype/core";
import type {
  IDataTableCellAction,
  IDataTableCellIndicator,
  IDataTableLastEditError,
  IDataTableSelectionMode,
} from "@archetype/data-table";
import { Cell } from "@archetype/data-table";
import type { IValidationError, IVersionType } from "@archetype/dsl";
import {
  computeColumnViewFieldId,
  FieldValueParser,
  isAIConfig,
  isLiveAutofill,
  isLogicConfig,
  isLookupConfig,
  type IViewFieldValue,
} from "@archetype/dsl";
import type { IColumnId, IEntityId, IViewFieldId } from "@archetype/ids";
import type { IIconNames } from "@archetype/ui";
import { DEFAULT_TRANSFORMERS, defaultConfig, DefaultPlugins, useMemoDeepCompare, useToast } from "@archetype/ui";
import assertNever from "assert-never";
import { isEqual } from "lodash";
import type { DateTime } from "luxon";
import React, { useCallback, useMemo } from "react";

import type { IGetEntityRoute, IGetLinkedEntityRoute } from "../../api";
import { getConditionalFormattingClasses } from "../../entityType/ConditionalFormattingUtils";
import { createFileLogger } from "../../logger";
import { EntityFieldMentionNode } from "../../markdown/EntityFieldMentionNode";
import {
  EntityFieldMentionsPlugin,
  generateEntityFieldMentionTransformer,
} from "../../markdown/EntityFieldMentionsPlugin";
import type { IEntityDataTableCell, IEntityDataTableCellValue } from "../api";

const logger = createFileLogger("EntityTableCellWrapper");

export interface IEntityTableCellWrapper {
  className?: string;
  entityType: ILoadedEntityType;
  viewField: ILoadedViewField;
  cellValue: IEntityDataTableCellValue | undefined;
  isSaving?: boolean;
  lastEditErrors?: { operationId: string; errors: IValidationError[] };
  getLinkedEntityRoute: IGetLinkedEntityRoute;
  getFullPageEntityRoute: IGetEntityRoute;
  versionType: IVersionType;
  /**
   * If undefined, the cell will be read-only.
   */
  onChange?: (rowId: IEntityId, colId: IColumnId, value: IViewFieldValue) => Promise<void>;
  /**
   * If true, the cell will be read-only even if an onChange handler is provided.
   */
  readOnly?: boolean;
  /**
   * The cell will clear the last edit error when the error is viewed.
   */
  onViewLastEditError?: (operationId: string) => void;

  computeStatus?: IFieldComputationInfo;

  // Cell state
  isCellEditing: boolean;
  isCellSelected: boolean;
  isRowSelected: boolean;
  selectionMode: IDataTableSelectionMode<IEntityId, IViewFieldId>["type"];
  fieldValues: Record<IViewFieldId, IViewFieldValue>;

  // Cell identity
  rowId: IEntityId;
  colId: IViewFieldId;
}

export const EntityTableCellWrapper: React.FC<IEntityTableCellWrapper> = ({
  className,
  entityType,
  viewField,
  cellValue,
  isSaving,
  lastEditErrors,
  versionType,
  readOnly,
  getLinkedEntityRoute,
  getFullPageEntityRoute,
  onChange,
  onViewLastEditError: handleViewLastEditError,
  computeStatus,
  isCellEditing,
  isCellSelected,
  isRowSelected,
  selectionMode,
  fieldValues,
  rowId,
  colId,
}) => {
  const { toast } = useToast();

  const cellLastEditErrors: IDataTableLastEditError | undefined = useMemo(() => {
    if (lastEditErrors == null) return undefined;

    const { operationId, errors } = lastEditErrors;
    // Matches action form behavior of showing the first error only
    const firstError = errors[0];

    return { error: firstError?.error ?? "Unknown error", operationId, sticky: false };
  }, [lastEditErrors]);

  const handleUpdateEntity = useCallback(
    async (entityId: IEntityId, columnId: IColumnId, value: IViewFieldValue): Promise<void> => {
      if (entityType.supportActionsInfo == null) return;

      // Ignore updates that are the same as the current value
      if (isEqual(cellValue, value)) {
        return;
      }

      return await onChange?.(entityId, columnId, value);
    },
    [entityType.supportActionsInfo, cellValue, onChange],
  );

  const displayNameViewFieldId = useMemoDeepCompare(
    () => computeColumnViewFieldId(entityType.displayNameColumn),
    [entityType.displayNameColumn],
  );

  const cellContent = useMemoDeepCompare((): IEntityDataTableCell => {
    const isReadOnly = readOnly === true || viewField.autofill?.config.type === "logic" || onChange == null;

    if (cellValue == null) {
      return {
        type: "null",
        rowId,
        colId,
        readOnly: isReadOnly,
      };
    }

    switch (viewField.type) {
      case "column": {
        const column = viewField.column;

        if (viewField.id === displayNameViewFieldId) {
          const parsedValue = FieldValueParser.toString(cellValue.value);

          if (parsedValue != null) {
            return {
              type: "text",
              value: parsedValue,
              onChange: (value: string | null): Promise<void> => {
                if (value == null) {
                  return handleUpdateEntity(rowId, column.id, {
                    type: "null",
                  });
                }

                return handleUpdateEntity(rowId, column.id, {
                  type: "string",
                  value,
                });
              },
              rowId,
              colId,
              readOnly: isReadOnly,
            };
          }

          if (cellValue.value.type !== "null") {
            logger.error(
              {
                type: cellValue.value.type,
                value: cellValue.value,
              },
              "Invalid display name column type",
            );
          }

          return {
            type: "text",
            value: "",
            rowId,
            colId,
            onChange: (value: string | null): Promise<void> => {
              if (value == null) {
                return handleUpdateEntity(rowId, column.id, {
                  type: "null",
                });
              }

              return handleUpdateEntity(rowId, column.id, {
                type: "string",
                value,
              });
            },
            readOnly: isReadOnly,
          };
        }

        const conditionalFormatting = column.conditionalFormatting;

        switch (column.columnType.type) {
          case "enum": {
            return {
              type: "select",
              value: cellValue.value.type === "array" ? cellValue.value.value : [],
              options: (column.columnType.enumAllowedValues ?? []).map((v) => ({
                label: v,
                value: v,
              })),
              inclusiveMaxValuesToSelect: column.columnType.enumInclusiveMaxValuesToSelect,
              rowId,
              colId,
              onChange: (value: string[]): Promise<void> => {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
                if (value == null) {
                  return handleUpdateEntity(rowId, column.id, {
                    type: "null",
                  });
                }

                return handleUpdateEntity(rowId, column.id, {
                  type: "array",
                  value,
                });
              },
              readOnly: isReadOnly,
            };
          }

          case "statusEnum": {
            const colorVariant = getConditionalFormattingClasses({
              cell: cellValue.value,
              conditionalFormatting,
            })?.colorName;

            const parsedValue = FieldValueParser.toString(cellValue.value);
            const statusInfo =
              column.columnType.allowedValues.find((v) => v.id === parsedValue) ??
              column.columnType.archivedValues.find((v) => v.id === parsedValue);

            if (!statusInfo) {
              logger.error(
                {
                  type: cellValue.value.type,
                  value: cellValue.value,
                },
                "Invalid status column type",
              );

              return {
                type: "status",
                value: parsedValue ?? null,
                variant: "gray",
                rowId,
                colId,
                readOnly: isReadOnly,
              };
            }

            // Status enum values never editable
            return {
              type: "status",
              value: statusInfo.readableValue,
              variant: colorVariant == null || colorVariant === "neutral" ? "gray" : colorVariant,
              rowId,
              colId,
              readOnly: isReadOnly,
            };
          }

          case "email": {
            const parsedValue = FieldValueParser.toString(cellValue.value);

            return {
              type: "email",
              value: parsedValue ?? "",
              onChange: (value: string | null): Promise<void> => {
                if (value == null) {
                  return handleUpdateEntity(rowId, column.id, {
                    type: "null",
                  });
                }

                return handleUpdateEntity(rowId, column.id, {
                  type: "string",
                  value,
                });
              },
              rowId,
              colId,
              readOnly: isReadOnly,
            };
          }

          case "longText": {
            const parsedValue = FieldValueParser.toString(cellValue.value);
            const transformers = [
              generateEntityFieldMentionTransformer({
                fieldValues,
                loadedEntityType: entityType,
                variant: isReadOnly ? "text" : "badge",
              }),
              ...DEFAULT_TRANSFORMERS,
            ];

            return {
              type: "markdown",
              value: parsedValue ?? null,
              rowId,
              colId,
              readOnly: isReadOnly,
              transformers,
              nodes: (defaultConfig.nodes ?? []).concat(EntityFieldMentionNode),
              plugins: (
                <>
                  <DefaultPlugins autoFocus={false} readOnly={isReadOnly} transformers={transformers} />
                  <EntityFieldMentionsPlugin
                    entityTypeId={entityType.id}
                    fieldValues={fieldValues}
                    organizationId={entityType.organizationId}
                    variant={isReadOnly ? "text" : "badge"}
                    versionType={versionType}
                  />
                </>
              ),
              onChange: (value: string | null): Promise<void> => {
                if (value == null) {
                  return handleUpdateEntity(rowId, column.id, {
                    type: "null",
                  });
                }

                return handleUpdateEntity(rowId, column.id, {
                  type: "string",
                  value,
                });
              },
            };
          }

          case "shortText": {
            const parsedValue = FieldValueParser.toString(cellValue.value);

            return {
              type: "text",
              value: parsedValue ?? "",
              onChange: (value: string | null): Promise<void> => {
                if (value == null) {
                  return handleUpdateEntity(rowId, column.id, {
                    type: "null",
                  });
                }

                return handleUpdateEntity(rowId, column.id, {
                  type: "string",
                  value,
                });
              },
              rowId,
              colId,
              readOnly: isReadOnly,
            };
          }

          case "boolean": {
            const parsedValue = FieldValueParser.toBoolean(cellValue.value);

            return {
              type: "boolean",
              value: parsedValue ?? false,
              onChange: (value: boolean) =>
                handleUpdateEntity(rowId, column.id, {
                  type: "boolean",
                  value,
                }),
              rowId,
              colId,
              readOnly: isReadOnly,
            };
          }

          case "date":
          case "timestamp": {
            const parsedValue = FieldValueParser.toDateTime(cellValue.value);

            return {
              type: column.columnType.type === "date" ? "date" : "datetime",
              value: parsedValue === undefined ? null : parsedValue,
              onChange: (value: DateTime | null): Promise<void> => {
                if (value == null) {
                  return handleUpdateEntity(rowId, column.id, {
                    type: "null",
                  });
                }

                return handleUpdateEntity(rowId, column.id, {
                  type: "date",
                  value: value.toString(),
                });
              },
              rowId,
              colId,
              readOnly: isReadOnly,
            };
          }

          case "url": {
            const parsedValue = FieldValueParser.toString(cellValue.value);

            return {
              type: "link",
              value: {
                href: parsedValue ?? "",
                text: parsedValue ?? "",
              },
              onChange: (value: { href: string; text: string }) =>
                handleUpdateEntity(rowId, column.id, {
                  type: "string",
                  value: value.href,
                }),
              rowId,
              colId,
              readOnly: isReadOnly,
            };
          }

          case "geolocation": {
            const parsedValue = FieldValueParser.toString(cellValue.value);

            return {
              type: "text",
              value: parsedValue ?? "",
              onChange: (value: string | null): Promise<void> => {
                if (value == null) {
                  return handleUpdateEntity(rowId, column.id, {
                    type: "null",
                  });
                }

                return handleUpdateEntity(rowId, column.id, {
                  type: "string",
                  value,
                });
              },
              rowId,
              colId,
              readOnly: isReadOnly,
            };
          }

          case "number": {
            const parsedValue = FieldValueParser.toNumber(cellValue.value);

            return {
              type: "number",
              value: parsedValue === undefined ? null : parsedValue,
              onChange: (value: number | null) =>
                handleUpdateEntity(
                  rowId,
                  column.id,
                  value == null
                    ? { type: "null" }
                    : {
                        type: "number",
                        value,
                      },
                ),
              rowId,
              colId,
              readOnly: isReadOnly,
            };
          }

          case "phone": {
            const parsedValue = FieldValueParser.toString(cellValue.value);

            return {
              type: "phone",
              rowId,
              colId,
              readOnly: isReadOnly,
              value: parsedValue === undefined ? null : parsedValue,
              onChange: (value: string | null): Promise<void> => {
                if (value == null) {
                  return handleUpdateEntity(rowId, column.id, {
                    type: "null",
                  });
                }

                return handleUpdateEntity(rowId, column.id, {
                  type: "string",
                  value,
                });
              },
            };
          }

          case "file": {
            if (cellValue.value.type !== "file") {
              return {
                type: "file",
                value: [],
                rowId,
                colId,
                readOnly: isReadOnly,
              };
            }

            return {
              type: "file",
              value: cellValue.value.value,
              rowId,
              colId,
              readOnly: isReadOnly,
            };
          }

          default: {
            assertNever(column.columnType);
          }
        }
        break;
      }

      case "directionalRelation": {
        if (cellValue.value.type !== "relatedEntities") {
          return {
            type: "null",
            rowId,
            colId,
            readOnly: isReadOnly,
          };
        }

        return {
          type: "clickableList",
          value: cellValue.value.value.map((e) => ({
            text: e.displayName,
            href: {
              type: "internal",
              route: getLinkedEntityRoute({ entityTypeId: e.entityTypeId, entityId: e.entityId }),
            },
          })),
          rowId,
          colId,
          readOnly: isReadOnly,
        };
      }

      default: {
        assertNever(viewField);
      }
    }
  }, [
    readOnly,
    viewField,
    onChange,
    cellValue,
    rowId,
    colId,
    displayNameViewFieldId,
    handleUpdateEntity,
    fieldValues,
    entityType,
    getLinkedEntityRoute,
    versionType,
  ]);

  const cellActions: IDataTableCellAction[] = useMemo(() => {
    if (viewField.id === displayNameViewFieldId) {
      return [
        {
          icon: "maximize-2",
          tooltip: "Open full screen",
          className: "group-hover/row:!opacity-100",
          link: {
            type: "internal",
            href: getFullPageEntityRoute({ entityTypeId: entityType.id, entityId: rowId }),
          },
        },
      ];
    }

    if (cellValue == null || cellValue.value.type === "null" || cellValue.value.type === "relatedEntities") {
      return [];
    }

    return [
      {
        icon: "copy",
        tooltip: "Copy to clipboard",
        onClick: (e: React.MouseEvent): void => {
          e.stopPropagation();
          e.preventDefault();

          const value = FieldValueParser.toString(cellValue.value);

          if (value != null) {
            void navigator.clipboard.writeText(value);
            toast({
              variant: "success",
              icon: "check",
              title: "Copied to clipboard",
            });
          } else {
            logger.error("Error copying cell value");
            toast({
              variant: "error",
              title: "Error copying cell value",
              description: "Unable to copy this value",
            });
          }
        },
      },
    ];
  }, [cellValue, rowId, viewField.id, displayNameViewFieldId, getFullPageEntityRoute, entityType.id, toast]);

  const cellIndicator = useMemoDeepCompare((): IDataTableCellIndicator | undefined => {
    if (isSaving === true) {
      return {
        type: "saving",
      };
    }

    if (cellLastEditErrors != null) {
      return {
        type: "editError",
        lastEditError: cellLastEditErrors,
      };
    }

    if (viewField.autofill == null || !isLiveAutofill(viewField.autofill)) {
      return undefined;
    }

    if (isAIConfig(viewField.autofill.config)) {
      if (computeStatus?.status === "computing") {
        return {
          type: "computing",
          textColor: "purple",
          indicatorColor: "purple",
          text: "Thinking...",
          iconLeft: "sparkles" as IIconNames,
        };
      }

      if (computeStatus?.status === "recomputing") {
        return {
          type: "computing",
          textColor: "purple",
          indicatorColor: "purple",
          text: "Updating...",
          iconLeft: "sparkles" as IIconNames,
        };
      }

      if (computeStatus?.status === "error") {
        return {
          type: "error",
          error: "Failed to update",
        };
      }

      return {
        type: "selectionTooltipOnly",
        text: "Computed by AI",
        iconLeft: "sparkles" as IIconNames,
      };
    }

    if (isLogicConfig(viewField.autofill.config)) {
      if (computeStatus?.status === "computing") {
        return {
          type: "computing",
          textColor: "default",
          indicatorColor: "primary",
          text: "Thinking...",
          iconLeft: "sigma" as IIconNames,
        };
      }

      if (computeStatus?.status === "recomputing") {
        return {
          type: "computing",
          textColor: "default",
          indicatorColor: "primary",
          text: "Updating...",
          iconLeft: "sigma" as IIconNames,
        };
      }

      if (computeStatus?.status === "error") {
        return {
          type: "error",
          error: "Failed to update",
        };
      }

      return {
        type: "selectionTooltipOnly",
        text: "Computed live",
        iconLeft: "sigma" as IIconNames,
      };
    }

    if (isLookupConfig(viewField.autofill.config)) {
      if (computeStatus?.status === "computing") {
        return {
          type: "computing",
          textColor: "default",
          indicatorColor: "primary",
          text: "Computing by lookup",
          iconLeft: "search" as IIconNames,
        };
      }

      if (computeStatus?.status === "recomputing") {
        return {
          type: "computing",
          textColor: "default",
          indicatorColor: "primary",
          text: "Updating...",
          iconLeft: "search" as IIconNames,
        };
      }

      if (computeStatus?.status === "error") {
        return {
          type: "error",
          error: "Failed to update",
        };
      }

      return {
        type: "selectionTooltipOnly",
        text: "Live lookup",
        iconLeft: "search" as IIconNames,
      };
    }

    return undefined;
  }, [isSaving, viewField.autofill, computeStatus, cellLastEditErrors]);

  return (
    <Cell
      actions={cellActions}
      cell={cellContent}
      className={className}
      indicator={cellIndicator}
      isCellEditing={isCellEditing}
      isCellSelected={isCellSelected}
      isRowSelected={isRowSelected}
      selectionMode={selectionMode}
      onViewLastEditError={handleViewLastEditError}
    />
  );
};
