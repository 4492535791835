import type { IVersionType } from "@archetype/dsl";
import type { IIconNames } from "@archetype/ui";
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, Icon } from "@archetype/ui";
import { useCallback } from "react";

const VERSIONS = [
  {
    value: "dev",
    icon: "package-open",
    label: "Draft",
  },
  {
    value: "prod",
    icon: "package-check",
    label: "Published",
  },
] satisfies Array<{
  value: IVersionType;
  icon: IIconNames;
  label: string;
}>;

interface IVersionSelectorProps {
  disabled?: boolean;
  versionType: IVersionType;
  onChange?: (version: IVersionType) => void;
}

export const VersionSelector: React.FC<IVersionSelectorProps> = ({ versionType, disabled, onChange }) => {
  const currentVersionLabel = VERSIONS.find((v) => v.value === versionType)?.label;

  const handleChange = useCallback(
    (v: IVersionType) => (): void => {
      onChange?.(v);
    },
    [onChange],
  );

  if (disabled ?? false) {
    return (
      <Button disabled iconLeft="package-check" size="sm" variant="ghost">
        {currentVersionLabel}
      </Button>
    );
  }

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button iconLeft="package-check" size="sm" variant="ghost">
            {currentVersionLabel}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {VERSIONS.map(({ value, icon, label }) => (
            <DropdownMenuItem key={value} onClick={handleChange(value)}>
              <Icon className="mr-2" name={icon} />
              {label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
