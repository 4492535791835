import { cn, Switch } from "@archetype/ui";
import { useCallback } from "react";

import type { IActionInputComponent } from "../types";

export const BooleanInput: IActionInputComponent = ({ className, field, onChange, readOnly = false }) => {
  const handleChange = useCallback(
    (checked: boolean) => {
      onChange({
        type: "boolean",
        value: checked,
      });
    },
    [onChange],
  );

  const handleBlur = useCallback(() => {
    field.onBlur();
  }, [field]);

  const fieldValue = field.value?.type === "boolean" ? field.value.value : undefined;

  return (
    <Switch
      ref={field.ref}
      checked={fieldValue}
      className={cn("block", className)}
      defaultChecked={fieldValue}
      name={field.name}
      readOnly={readOnly}
      onBlur={handleBlur}
      onCheckedChange={handleChange}
    />
  );
};
