import type { ILoadedEntity, ILoadedRelationViewField } from "@archetype/core";
import type {
  IActionCurrentUserInfo,
  ICrossColumnAndConditions,
  IPerColumnAndedFilters,
  IVersionType,
} from "@archetype/dsl";
import { computeRelationViewFieldId, isRelationFieldValue, type IViewFieldValue } from "@archetype/dsl";
import type { IEntityId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { Combobox, MultiSelect, useMemoDeepCompare } from "@archetype/ui";
import { forEach, map } from "@archetype/utils";
import { skipToken } from "@tanstack/react-query";
import { size } from "lodash";
import type { RefCallback } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce, usePrevious } from "react-use";

import type { ICreateNewProps } from "./api";

const ADD_NEW_VALUE = "__add_new__";

type IOption = { label: string; value: string };

export const RelationInput: React.FC<{
  versionType: IVersionType;
  viewField: ILoadedRelationViewField;
  viewFieldValue: IViewFieldValue;
  onChange: (newValue: IViewFieldValue) => void;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  ref?: RefCallback<HTMLInputElement>;
  createNewProps?: ICreateNewProps;
  readOnly?: boolean;
  required: boolean;
  currentUserInfo: IActionCurrentUserInfo | undefined;
}> = ({
  versionType,
  viewField,
  viewFieldValue,
  onChange,
  ref,
  createNewProps,
  readOnly = false,
  required,
  currentUserInfo,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>("");
  /**
   * Can be used to not create new multiple times if the user already discarded it
   */
  const [hasAutoCreatedNew, setHasAutoCreatedNew] = useState(false);

  useDebounce(
    () => {
      setDebouncedSearchQuery(searchQuery);
    },
    500,
    [searchQuery],
  );

  const isMultiSelect: boolean = useMemo(() => {
    return (
      (viewField.direction === "aToB"
        ? viewField.relation.config.cardinalityOnSideB
        : viewField.relation.config.cardinalityOnSideA) === "many"
    );
  }, [viewField]);

  const selection: IOption[] = useMemoDeepCompare(() => {
    if (isRelationFieldValue(viewFieldValue)) {
      return viewFieldValue.value.map((v) => ({
        value: v.entityId,
        // correct the display name with the entity title if necessary
        label: createNewProps?.draftNewOptions[v.entityId]?.label ?? v.displayName,
      }));
    }

    return [];
  }, [viewFieldValue, createNewProps?.draftNewOptions]);

  const targetEntityTypeId =
    viewField.direction === "aToB" ? viewField.relation.entityTypeIdB : viewField.relation.entityTypeIdA;

  const { data: targetEntityTypeQuery } = builderTrpc.dataModel.fullyLoadedEntityType.useQuery({
    id: targetEntityTypeId,
    versionType,
  });

  const relationValidation = useMemoDeepCompare(() => {
    if (viewField.direction === "aToB") {
      return viewField.relation.config.validationsOnB;
    }

    return viewField.relation.config.validationsOnA;
  }, [viewField]);

  const filterQueryWithValidations: ICrossColumnAndConditions | undefined = useMemoDeepCompare(() => {
    if (relationValidation == null || !relationValidation.enabled) {
      return undefined;
    }

    const validationStateIds = relationValidation.stateIds ?? [];

    if (relationValidation.filters == null && validationStateIds.length === 0) {
      return undefined;
    }

    const perColumn: IPerColumnAndedFilters = {
      ...relationValidation.filters?.perColumn,
    };

    if (validationStateIds.length > 0 && targetEntityTypeQuery?.entityType.statusColumn != null) {
      perColumn[targetEntityTypeQuery.entityType.statusColumn] = {
        type: "or",
        rawOrConditions: {
          eq: validationStateIds.map((stateId) => ({ type: "value", value: { type: "string", value: stateId } })),
        },
        oredAndConditions: [],
      };
    }

    return {
      type: "and",
      perColumn,
      andedCrossColumnOrConditions: [],
      andedRelatedToFilters: [],
    };
  }, [relationValidation, targetEntityTypeQuery?.entityType.statusColumn]);

  const filterQueryWithSearch: ICrossColumnAndConditions | undefined = useMemoDeepCompare(() => {
    if (debouncedSearchQuery === "" || targetEntityTypeQuery == null) {
      return filterQueryWithValidations;
    }

    const perColumn: IPerColumnAndedFilters = {
      ...filterQueryWithValidations?.perColumn,
      [targetEntityTypeQuery.entityType.displayNameColumn]: {
        type: "or" as const, // can be either or/and because it's a single value
        rawOrConditions: {
          regex: {
            type: "regex" as const,
            value: debouncedSearchQuery,
            flags: "gi",
          },
        },
        oredAndConditions: [],
      },
    };

    return {
      type: "and",
      perColumn,
      andedCrossColumnOrConditions: [],
      andedRelatedToFilters: [],
    };
  }, [debouncedSearchQuery, targetEntityTypeQuery, filterQueryWithValidations]);

  const {
    data: entitiesPagedQuery,
    isLoading: isLoadingEntities,
    isFetching: isFetchingEntities,
  } = builderTrpc.dataLoading.getLoadedEntities.useInfiniteQuery(
    targetEntityTypeQuery == null
      ? skipToken
      : {
          organizationId: targetEntityTypeQuery.entityType.organizationId,
          dataLoadingQuery: {
            versionType,
            entityType: {
              type: "entityTypeId",
              entityTypeId: targetEntityTypeId,
            },
            filters: filterQueryWithSearch,
          },
          dataLoadingConfig: {
            specificRelations: [],
            specificColumns: [targetEntityTypeQuery.entityType.displayNameColumn],
          },
        },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialCursor: 0,
      // Enforce undefined if the entity type id has changed
      placeholderData: (previousData) =>
        previousData?.pages[0]?.entities[0]?.entityTypeId !== targetEntityTypeId ? undefined : previousData,
      trpc: {
        abortOnUnmount: true,
        context: {
          skipBatch: true,
        },
      },
    },
  );

  const targetEntities = useMemoDeepCompare(() => {
    return entitiesPagedQuery?.pages.flatMap((p) => p.entities).map((e) => ({ ...e, id: e.entityId }));
  }, [entitiesPagedQuery?.pages]);

  const shouldAutoCreateNew = useMemo(
    () =>
      !hasAutoCreatedNew && // if we've already triggered the create new abd there's no draft options, that means the user discarded it and better UX not to reopen
      required && // only create new if required as it's valid that there is no entity so we dont want to force open
      createNewProps != null &&
      // Dont create if there is already a draft
      size(createNewProps.draftNewOptions) === 0 &&
      !isLoadingEntities &&
      !isFetchingEntities &&
      debouncedSearchQuery === "" && // if there's a search query, should not auto create new, user likely wants to search something else
      targetEntities != null &&
      targetEntities.length === 0,
    [
      hasAutoCreatedNew,
      required,
      createNewProps,
      debouncedSearchQuery,
      isFetchingEntities,
      isLoadingEntities,
      targetEntities,
    ],
  );

  const previousShouldAutoCreateNew = usePrevious(shouldAutoCreateNew);

  useEffect(() => {
    // If loaded without a search query, and there is no result the user has access to,
    // we should open the create new modal automatically
    if (shouldAutoCreateNew && previousShouldAutoCreateNew !== true) {
      createNewProps?.onCreateNew();
      setHasAutoCreatedNew(true);
    }
  }, [shouldAutoCreateNew, previousShouldAutoCreateNew, createNewProps, setHasAutoCreatedNew]);

  const filteredTargetEntities: ILoadedEntity[] = useMemoDeepCompare(() => {
    const entities = targetEntities ?? [];

    // TODO @noa: move this to the query and executed in the BE to be respected by paging
    // if cardinality on origin side is one we should filter out already linked entities
    if (
      (viewField.direction === "aToB" && viewField.relation.config.cardinalityOnSideA === "one") ||
      (viewField.direction === "bToA" && viewField.relation.config.cardinalityOnSideB === "one")
    ) {
      const oppositeRelationField = computeRelationViewFieldId(
        viewField.relationId,
        viewField.direction === "aToB" ? "bToA" : "aToB",
      );

      return entities.filter((e) => {
        const oppositeRelationValue = e.fields[oppositeRelationField];

        return oppositeRelationValue == null || oppositeRelationValue.type === "null";
      });
    }

    return entities;
  }, [viewField, targetEntities]);

  const entityNamesByIds = useMemoDeepCompare(() => {
    const res: Record<IEntityId, string> = {};

    filteredTargetEntities.forEach((entity) => {
      res[entity.entityId] = entity.displayName;
    });

    selection.forEach((s) => {
      res[s.value as IEntityId] = s.label;
    });

    return res;
  }, [filteredTargetEntities, selection]);

  const optionsWithDraftsByIds = useMemoDeepCompare(() => {
    const res: Record<IEntityId, string> = {
      ...entityNamesByIds,
    };

    forEach(createNewProps?.draftNewOptions, (option) => {
      res[option.value] = option.label;
    });

    return res;
  }, [entityNamesByIds, createNewProps?.draftNewOptions]);

  const handleChange = useCallback(
    (values: IOption[]): void => {
      onChange(
        values.length === 0
          ? { type: "null" }
          : {
              type: "relatedEntities",
              value: values.map((s) => ({
                entityId: s.value as IEntityId,
                entityTypeId: targetEntityTypeId,
                displayName: s.label,
              })),
            },
      );
    },
    [onChange, targetEntityTypeId],
  );

  const handleClear = useCallback((): void => {
    handleChange([]);
  }, [handleChange]);

  const handleValueChange = useCallback(
    (value: IOption | null): void => {
      if (value === null) {
        handleClear();
      } else if (value.value === ADD_NEW_VALUE) {
        createNewProps?.onCreateNew();
      } else {
        handleChange([value]);
      }
    },
    [handleClear, handleChange, createNewProps],
  );

  const handleSearch = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  // Do not wrap this in a callback because undefined; means cant createNew for the multiselect component
  const handleCreateNew = createNewProps?.onCreateNew;

  const options = useMemo(() => {
    const rawOptions = map(optionsWithDraftsByIds, (name, id) => ({ value: id, label: name }));

    if (
      targetEntityTypeId !== currentUserInfo?.userEntityTypeId ||
      rawOptions.some(({ value }) => value === currentUserInfo.userEntityId)
    ) {
      return rawOptions;
    }

    return [
      {
        value: currentUserInfo.userEntityId,
        label: "Current user",
      },
    ].concat(rawOptions);
  }, [optionsWithDraftsByIds, currentUserInfo, targetEntityTypeId]);

  const disabledInput = (createNewProps == null && size(options) === 0) || readOnly;

  if (isMultiSelect) {
    return (
      <MultiSelect
        disabled={disabledInput}
        inputProps={{
          ref,
        }}
        isLoading={isLoadingEntities || isFetchingEntities}
        options={options}
        placeholder={`Select ${viewField.displayName}...`}
        value={selection}
        onChange={handleChange}
        onCreateItem={handleCreateNew}
        onSearch={handleSearch}
      />
    );
  }

  return (
    <Combobox
      ref={ref}
      disabled={disabledInput}
      isLoading={isLoadingEntities || isFetchingEntities}
      options={options}
      placeholder={`Select ${viewField.displayName}`}
      value={selection[0] ?? null}
      onChange={handleValueChange}
      onCreateItem={handleCreateNew}
      onSearch={handleSearch}
    />
  );
};
