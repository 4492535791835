import type { ILoadedEntity } from "@archetype/core";
import type { IVersionType } from "@archetype/dsl";
import type { IEntityId, IEntityTypeId, IOrganizationId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import {
  cn,
  Command,
  CommandInputAsTextInput,
  CommandItem,
  CommandList,
  Icon,
  Loader,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Skeleton,
  useDebounce,
} from "@archetype/ui";
import { skipToken } from "@tanstack/react-query";
import Link from "next/link";
import React, { useCallback } from "react";
import { useState } from "react";

import type { IGetEntityRoute } from "../api";
import { useLoadedEntity } from "../hooks/entity/useLoadedEntity";

interface IEntityBreadcrumb {
  organizationId: IOrganizationId;
  entityTypeId?: IEntityTypeId; // TODO @sspringer this should be required but for now we need to support application groups loading this breadcrumb
  entityId?: IEntityId;
  versionType: IVersionType;
  enableSearch?: boolean;
  getEntityRoute: IGetEntityRoute;
}

export const EntityBreadcrumb: React.FC<IEntityBreadcrumb> = ({
  organizationId,
  entityTypeId,
  entityId,
  versionType,
  enableSearch,
  getEntityRoute,
}) => {
  const [searchQuery, handleSetSearchQuery] = useState("");
  const [open, handleSetOpen] = useState(false);

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { data: entityTypeQuery } = builderTrpc.dataModel.fullyLoadedEntityType.useQuery(
    entityTypeId == null
      ? skipToken
      : {
          id: entityTypeId,
          versionType,
        },
  );

  const { entityData } = useLoadedEntity({
    query: { organizationId, versionType, entityTypeId, entityId },
    options: {},
  });

  const { data: loadedEntitiesQuery, isLoading: isLoadingEntitiesQuery } =
    builderTrpc.dataLoading.getLoadedEntities.useQuery(
      entityTypeId == null
        ? skipToken
        : {
            organizationId,
            dataLoadingQuery: {
              versionType,
              entityType: {
                type: "entityTypeId",
                entityTypeId,
              },
              searchQuery: {
                searchQueryValue: debouncedSearchQuery,
                searchColumns:
                  entityTypeQuery?.entityType.displayNameColumn != null
                    ? [entityTypeQuery.entityType.displayNameColumn]
                    : undefined,
              },
            },
            dataLoadingConfig: {
              specificRelations: null,
              specificColumns:
                entityTypeQuery?.entityType.displayNameColumn != null
                  ? [entityTypeQuery.entityType.displayNameColumn]
                  : null,
            },
          },
      {
        enabled: enableSearch === true, // allow empty search query so we have items in the list
      },
    );

  const entity = entityData?.entity;

  if (entity == null) {
    return (
      <div className="flex items-center space-x-2">
        <Skeleton className="h-6 w-16" />
      </div>
    );
  }

  if (enableSearch !== true) {
    return (
      <span className="flex min-w-0 flex-1 items-center space-x-2">
        <span className="min-w-0 truncate">{entity.displayName}</span>
      </span>
    );
  }

  return (
    <Popover open={open} onOpenChange={handleSetOpen}>
      <PopoverTrigger asChild>
        <button className="group flex min-w-0 flex-1 items-center justify-between gap-x-1" role="combobox">
          <span className="min-w-0 flex-1 truncate">{entity.displayName}</span>
          <Icon
            className={cn("size-3 shrink-0 opacity-0 transition-opacity group-hover:opacity-50", open && "opacity-50")}
            name="chevron-down"
          />
        </button>
      </PopoverTrigger>

      <PopoverPortal>
        <PopoverContent className="flex flex-col space-y-2 p-1" side="bottom">
          <SearchContent
            getEntityRoute={getEntityRoute}
            isLoading={isLoadingEntitiesQuery}
            results={loadedEntitiesQuery?.entities ?? []}
            searchQuery={searchQuery}
            value={entityId ?? null}
            onSearchQueryChange={handleSetSearchQuery}
          />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

interface ISearchContent {
  value: IEntityId | null;
  results: ILoadedEntity[];
  searchQuery: string;
  onSearchQueryChange: (searchQuery: string) => void;
  isLoading: boolean;
  getEntityRoute: IGetEntityRoute;
}

const SearchContent: React.FC<ISearchContent> = ({
  value,
  results,
  searchQuery,
  onSearchQueryChange: handleSearchQueryChange,
  isLoading,
  getEntityRoute,
}) => {
  return (
    <Command shouldFilter={false}>
      <CommandInputAsTextInput
        icon="search"
        placeholder="Search..."
        small={true}
        value={searchQuery}
        onValueChange={handleSearchQueryChange}
      />
      <SearchResults
        getEntityRoute={getEntityRoute}
        isLoading={isLoading}
        query={searchQuery}
        results={results}
        value={value}
      />
    </Command>
  );
};

interface ISearchResults {
  value: IEntityId | null;
  query: string;
  results: ILoadedEntity[];
  isLoading: boolean;
  getEntityRoute: IGetEntityRoute;
}

const SearchResults: React.FC<ISearchResults> = ({ results, isLoading, getEntityRoute, query }) => {
  const highlightText = useCallback((text: string, highlight: string): React.ReactNode => {
    if (highlight.trim() === "") return text;

    const parts = text.split(new RegExp(`(${highlight})`, "gi"));

    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={`${part}-${index.toString()}`} className="underline">
          {part}
        </span>
      ) : (
        <span key={`${part}-${index.toString()}`}>{part}</span>
      ),
    );
  }, []);

  return (
    <CommandList className="pt-2">
      {isLoading ? <Loader className="p-1 text-base" text="Searching..." /> : null}
      {!isLoading && query.length > 0 && results.length === 0 && <div className="p-1 text-base">No results found.</div>}
      {results.map(({ entityTypeId, entityId, displayName }) => {
        return (
          <Link
            key={entityId}
            className="no-underline"
            href={getEntityRoute({
              entityId,
              entityTypeId,
            })}
          >
            <CommandItem className="text-base" value={entityId}>
              <div className="inline truncate">{highlightText(displayName, query)}</div>
            </CommandItem>
          </Link>
        );
      })}
    </CommandList>
  );
};
