import * as React from "react";
import { useCallback, useState } from "react";

import { cn } from "../../lib/utils";
import { Button } from "./button";
import { inputVariants } from "./input";

export type ITextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  small?: boolean;
};

const TextareaBase = React.forwardRef<HTMLTextAreaElement, ITextareaProps>(
  ({ className, onKeyDown, small, ...props }, ref): React.JSX.Element => {
    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLTextAreaElement>): boolean | undefined => {
        // Submit form on cmd+enter (mac) or ctrl+enter (windows
        if (e.metaKey && e.key === "Enter") {
          const form = e.currentTarget.closest("form");

          if (form) {
            form.requestSubmit();

            return false;
          }
        }

        if (onKeyDown) {
          onKeyDown(e);

          return;
        }
      },
      [onKeyDown],
    );

    return (
      <textarea
        ref={ref}
        className={cn(inputVariants({ size: small === true ? "small" : "default" }), className)}
        onKeyDown={handleKeyDown}
        {...props}
      />
    );
  },
);

TextareaBase.displayName = "TextareaBase";

const Textarea = React.forwardRef<HTMLTextAreaElement, ITextareaProps>(
  ({ className, ...props }, ref): React.JSX.Element => {
    return <TextareaBase ref={ref} className={className} {...props} />;
  },
);

Textarea.displayName = "Textarea";

const TextareaWithButton = React.forwardRef<
  HTMLTextAreaElement,
  ITextareaProps & {
    defaultValue?: string;
    buttonText?: string;
    isLoading?: boolean;
    onSubmitButton: (value: string) => void;
  }
>(({ placeholder, disabled, small, onChange, defaultValue, buttonText, isLoading, onSubmitButton, ...props }, ref) => {
  const [value, setValue] = useState<string | undefined>(defaultValue);

  const handleSubmit = useCallback(() => {
    if (value != null) {
      onSubmitButton(value);
    }
  }, [value, onSubmitButton]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  return (
    <div className="relative w-full">
      <TextareaBase
        ref={ref}
        className="size-full min-h-32 resize-none"
        defaultValue={value}
        disabled={disabled}
        placeholder={placeholder}
        small={small}
        onChange={handleChange}
        {...props}
      />
      <Button
        className="absolute bottom-2 right-2 z-10 disabled:opacity-50"
        disabled={disabled === true || isLoading === true}
        iconRight="chevron-right"
        isLoading={isLoading}
        size="xs"
        onClick={handleSubmit}
      >
        {buttonText}
      </Button>
    </div>
  );
});

TextareaWithButton.displayName = "TextareaWithButton";

export { Textarea, TextareaBase, TextareaWithButton };
