import { motion } from "framer-motion";
import React from "react";

import type { IShapeColor } from "../../../lib/shapeColor";
import type { IShapeName } from "../../../lib/shapeName";
import type { IPreviewType } from "./onboarding-app-skeleton";
import { OnboardingAppSkeleton } from "./onboarding-app-skeleton";
import { OnboardingInnerLayout } from "./onboarding-inner-layout";

// Define the expected icon type based on usage in OnboardingAppSkeleton
interface IAppIcon {
  name: IShapeName;
  color: IShapeColor;
}

interface IOnboardingLayoutProps {
  children: React.ReactNode;
  nextButton?: {
    isLoading?: boolean;
    isDisabled?: boolean;
    text: string;
    onClick: () => void;
  };
  backButton?: {
    isDisabled?: boolean;
    isLoading?: boolean;
    text: string;
    onClick: () => void;
  };
  reloadButton?: {
    isDisabled?: boolean;
    isLoading?: boolean;
    text: string;
    onClick: () => void;
  };
  businessLogo?: string;
  businessName?: string;
  apps?: Array<{ title: string; icon: IAppIcon }>;
  preview?: IPreviewType;
  selectedApp?: string;
}

export function OnboardingLayout({
  children,
  nextButton,
  backButton,
  reloadButton,
  businessLogo,
  businessName,
  apps,
  preview,
  selectedApp,
}: IOnboardingLayoutProps): React.JSX.Element {
  return (
    <OnboardingInnerLayout backButton={backButton} nextButton={nextButton} reloadButton={reloadButton}>
      <div className="flex size-full grow">
        <div className="flex size-full grow flex-col justify-center overflow-y-auto p-6 md:w-1/2 md:p-12">
          {children}
        </div>

        <motion.div
          layout
          animate={{ opacity: 1, x: 0 }}
          className="hidden grow overflow-hidden py-12 md:block"
          initial={{ opacity: 0, x: "100%" }}
          layoutId="onboarding-skeleton-container"
          transition={{ duration: 0.5, delay: 0.25 }}
        >
          <OnboardingAppSkeleton
            apps={apps ?? []}
            businessLogo={businessLogo}
            businessName={businessName}
            preview={preview}
            selectedApp={selectedApp}
          />
        </motion.div>
      </div>
    </OnboardingInnerLayout>
  );
}
