import type { IEntityTypeCore, IRelationChange, IRelationCore } from "@archetype/dsl";
import {
  computeRelationViewFieldId,
  isRelationAutofillChange,
  isRelationCardinalityOnSideAChange,
  isRelationCardinalityOnSideBChange,
  isRelationCreateChange,
  isRelationDeleteChange,
  isRelationDisableAutofillChange,
  isRelationRenameAToBChange,
  isRelationRenameBToAChange,
} from "@archetype/dsl";
import type { IEntityTypeId, IRelationId } from "@archetype/ids";
import { RelationId } from "@archetype/ids";
import { flatMap, isNonNullable } from "@archetype/utils";
import assertNever from "assert-never";
import { values } from "lodash";

import { convertLLMRelationAutofillToAutofill } from "../llmTypes/convertLLMRelationToRelation";
import type {
  ICreateOrEditRelationOperation,
  IDeleteRelationOperation,
  IEditAutofillOperation,
  IEditOperation,
} from "../stateMachine/editOperations/operations";

export function convertRelationChangesToEditOperations({
  relationsById,
  entityTypesById,
  changes,
}: {
  relationsById: Record<IRelationId, IRelationCore>;
  entityTypesById: Record<IEntityTypeId, IEntityTypeCore>;
  changes: IRelationChange[];
}): IEditOperation[] {
  const res: Record<
    IRelationId,
    {
      autofillEdit?: IEditAutofillOperation;
      relationEdit?: ICreateOrEditRelationOperation;
      relationDelete?: IDeleteRelationOperation;
      relationCreate?: ICreateOrEditRelationOperation;
    }
  > = {};

  changes.forEach((change) => {
    if (isRelationCreateChange(change)) {
      const entityTypeA = entityTypesById[change.createNewRelation.entityTypeAId];
      const entityTypeB = entityTypesById[change.createNewRelation.entityTypeBId];

      if (entityTypeA == null) {
        throw new Error(`Entity type ${change.createNewRelation.entityTypeAId} not found`);
      }

      if (entityTypeB == null) {
        throw new Error(`Entity type ${change.createNewRelation.entityTypeBId} not found`);
      }

      const newRelation: IRelationCore = {
        id: RelationId.generate(),
        config: {
          cardinalityOnSideA: change.createNewRelation.cardinalityOnSideA,
          cardinalityOnSideB: change.createNewRelation.cardinalityOnSideB,
        },
        displayMetadataFromAToB: {
          name: change.createNewRelation.titleForBEntitiesWhenOnA,
        },
        displayMetadataFromBToA: {
          name: change.createNewRelation.titleForAEntitiesWhenOnB,
        },
        entityTypeIdA: entityTypeA.id,
        entityTypeIdB: entityTypeB.id,
      };

      res[newRelation.id] = {
        relationCreate: {
          type: "createOrEditRelation",
          relation: newRelation,
        },
      };

      relationsById[newRelation.id] = newRelation;

      return;
    }

    const relation = relationsById[change.relationId];

    if (relation == null) {
      throw new Error(`Relation ${change.relationId} not found`);
    }

    const prevColumn = res[relation.id]?.relationEdit?.relation ?? relation;

    if (isRelationAutofillChange(change)) {
      res[relation.id] = {
        ...res[relation.id],
        autofillEdit: {
          type: "editAutofill",
          viewField: {
            type: "directionalRelation",
            relationId: relation.id,
            direction: change.changeAutofillTo.direction,
            id: computeRelationViewFieldId(relation.id, change.changeAutofillTo.direction),
          },
          autofillConfig: convertLLMRelationAutofillToAutofill(change.changeAutofillTo.autofill),
          entityTypeId: change.changeAutofillTo.direction === "aToB" ? relation.entityTypeIdA : relation.entityTypeIdB,
        },
      };
    } else if (isRelationDisableAutofillChange(change)) {
      res[relation.id] = {
        ...res[relation.id],
        autofillEdit: {
          type: "editAutofill",
          viewField: {
            type: "directionalRelation",
            relationId: relation.id,
            direction: relation.autofill?.direction ?? "aToB",
            id: computeRelationViewFieldId(relation.id, relation.autofill?.direction ?? "aToB"),
          },
          autofillConfig: null,
          entityTypeId: relation.autofill?.direction === "aToB" ? relation.entityTypeIdA : relation.entityTypeIdB,
        },
      };
    } else if (isRelationRenameAToBChange(change)) {
      res[relation.id] = {
        ...res[relation.id],
        relationEdit: {
          type: "createOrEditRelation",
          relation: {
            ...prevColumn,
            displayMetadataFromAToB: {
              ...prevColumn.displayMetadataFromAToB,
              name: change.changeTitleForBEntitiesWhenOnATO,
            },
          },
        },
      };
    } else if (isRelationRenameBToAChange(change)) {
      res[relation.id] = {
        ...res[relation.id],
        relationEdit: {
          type: "createOrEditRelation",
          relation: {
            ...prevColumn,
            displayMetadataFromBToA: {
              ...prevColumn.displayMetadataFromBToA,
              name: change.changeTitleForAEntitiesWhenOnBTo,
            },
          },
        },
      };
    } else if (isRelationCardinalityOnSideAChange(change)) {
      res[relation.id] = {
        ...res[relation.id],
        relationEdit: {
          type: "createOrEditRelation",
          relation: {
            ...prevColumn,
            config: {
              ...prevColumn.config,
              cardinalityOnSideA: change.changeCardinalityOnSideATo,
            },
          },
        },
      };
    } else if (isRelationCardinalityOnSideBChange(change)) {
      res[relation.id] = {
        ...res[relation.id],
        relationEdit: {
          type: "createOrEditRelation",
          relation: {
            ...prevColumn,
            config: {
              ...prevColumn.config,
              cardinalityOnSideB: change.changeCardinalityOnSideBTo,
            },
          },
        },
      };
    } else if (isRelationDeleteChange(change)) {
      res[relation.id] = {
        ...res[relation.id],
        relationDelete: {
          type: "deleteRelation",
          relationId: relation.id,
          actionIds: null,
        },
      };
    } else {
      assertNever(change);
    }
  });

  return flatMap(res, (v) => values(v).filter(isNonNullable));
}
