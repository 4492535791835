import type { IComponentDefinition, IComponentDefinitionDeclaration, IComponentIdDeclaration } from "@archetype/dsl";
import type { IComponentDefinitionId } from "@archetype/ids";
import { keyByNoUndefined, makeNonReadonlyArray } from "@archetype/utils";
import { omit, once } from "lodash";

import { ActionButtonComponentDefinition, ActionButtonComponentIdDeclarations } from "../components/ActionButton";
import { BoardComponentDefinition, BoardComponentIdDeclarations } from "../components/Board";
import { CardListComponentDefinition, CardListComponentIdDeclarations } from "../components/CardList";
import { ChartComponentDefinition, ChartComponentIdDeclarations } from "../components/Chart";
import {
  DescriptionListComponentDefinition,
  DescriptionListComponentIdDeclarations,
} from "../components/DescriptionList";
import { EntityDetailsComponentDefinition, EntityDetailsComponentIdDeclarations } from "../components/EntityDetails";
import { EntityListComponentDefinition, EntityListComponentIdDeclarations } from "../components/EntityList";
import { EventListComponentDefinition, EventListComponentIdDeclarations } from "../components/EventList";
import { FilterListComponentDefinition, FilterListComponentIdDeclarations } from "../components/FilterList";
import { ImageComponentDefinition, ImageComponentIdDeclarations } from "../components/Image";
import { ListComponentDefinition, ListComponentIdDeclarations } from "../components/List";
import { MapComponentDefinition, MapComponentIdDeclarations } from "../components/Map";
import { MetricCardComponentDefinition, MetricCardComponentIdDeclarations } from "../components/MetricCard";
import { SearchInputComponentDefinition, SearchInputComponentIdDeclarations } from "../components/SearchInput";
import { TableComponentDefinition, TableComponentIdDeclarations } from "../components/Table";
import { TableGroupedComponentDefinition, TableGroupedComponentIdDeclarations } from "../components/TableGrouped";

export const COMPONENT_DEFINITIONS_ARR: IComponentDefinitionDeclaration[] = [
  ActionButtonComponentDefinition,
  BoardComponentDefinition,
  ChartComponentDefinition,
  DescriptionListComponentDefinition,
  EntityListComponentDefinition,
  EventListComponentDefinition,
  FilterListComponentDefinition,
  ImageComponentDefinition,
  ListComponentDefinition,
  MapComponentDefinition,
  MetricCardComponentDefinition,
  SearchInputComponentDefinition,
  TableComponentDefinition,
  TableGroupedComponentDefinition,
  CardListComponentDefinition,
  EntityDetailsComponentDefinition,
];

const COMPONENT_IDS_DECLARATIONS = [
  ActionButtonComponentIdDeclarations,
  BoardComponentIdDeclarations,
  ChartComponentIdDeclarations,
  DescriptionListComponentIdDeclarations,
  EntityListComponentIdDeclarations,
  EventListComponentIdDeclarations,
  FilterListComponentIdDeclarations,
  ImageComponentIdDeclarations,
  ListComponentIdDeclarations,
  MapComponentIdDeclarations,
  MetricCardComponentIdDeclarations,
  SearchInputComponentIdDeclarations,
  TableComponentIdDeclarations,
  TableGroupedComponentIdDeclarations,
  CardListComponentIdDeclarations,
  EntityDetailsComponentIdDeclarations,
];

export const GENERIC_COMPONENT_IDS_DECLARATIONS: IComponentIdDeclaration[] = COMPONENT_IDS_DECLARATIONS;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any -- test type
export const __DO_NOT_USE_test_component_id: IComponentDefinitionId = "" as any;
export const __DO_NOT_USE_test_id_completeness: (typeof COMPONENT_IDS_DECLARATIONS)[number]["id"] =
  __DO_NOT_USE_test_component_id;

export type IComponentIdsDeclarationForId<ID extends IComponentDefinitionId> =
  (typeof COMPONENT_IDS_DECLARATIONS)[number] & { id: ID };

export type IComponentDefinitionRegistry = Record<string, IComponentDefinition>;

export const getComponentDefinitionRegistry: () => IComponentDefinitionRegistry = once(() =>
  keyByNoUndefined(
    COMPONENT_DEFINITIONS_ARR.map((componentDefDeclaration) =>
      omit(
        {
          ...componentDefDeclaration,
          id: componentDefDeclaration.constIds.id,
          compatibleSemanticSizes: makeNonReadonlyArray(componentDefDeclaration.constIds.compatibleSemanticSizes),
          validCosmeticOptionalProps: makeNonReadonlyArray(componentDefDeclaration.constIds.validCosmeticOptionalProps),
        },
        "constIds",
      ),
    ),
    (c) => c.id,
  ),
);
