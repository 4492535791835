import { ColumnId, ValidationId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { ComputedElementValues } from "../dataModel/Element";
import { ViewFieldValue } from "../dataModel/ViewFieldValue";

export const LLMColumnType = z.object({
  type: z.enum([
    "email",
    "shortText",
    "longText",
    "number",
    "boolean",
    "date",
    "timestamp",
    "geolocation",
    "phone",
    "file",
    "url",
    "enum",
  ]),
  enumValues: z.array(z.string()).optional(),
  enumAllowMultipleValuesSelection: z.boolean().optional(),
});
export type ILLMColumnType = z.infer<typeof LLMColumnType>;

export const LLMColumnAutofill = z.union([
  z.object({
    dynamicValue: ComputedElementValues,
  }),
  z.object({
    staticValue: ViewFieldValue,
  }),
  z.object({
    logicDescription: z.string(),
    recomputationType: z.enum(["suggestion", "live"]),
  }),
  z.object({
    aiInstructions: z.string(),
    recomputationType: z.enum(["suggestion", "live"]),
  }),
]);
export type ILLMColumnAutofill = z.infer<typeof LLMColumnAutofill>;

export const LLMValidation = z.object({
  id: ValidationId,
  validationDescription: z.string(),
});
export type ILLMValidation = z.infer<typeof LLMValidation>;

export const LLMColumn = z.object({
  columnId: ColumnId,
  name: ReadableString,
  entityTypeName: ReadableString,
  columnType: LLMColumnType,
  autofill: LLMColumnAutofill.nullable(),
  validations: z.array(LLMValidation),
});
export type ILLMColumn = z.infer<typeof LLMColumn>;
