import type { IComponentDefinitionId, ISlotId } from "@archetype/ids";

import { AbstractError } from "./AbstractError";

export class ComponentInputsNotCompatibleWithSlot extends AbstractError<"ComponentInputsNotCompatibleWithSlot"> {
  constructor({ componentId, slotId }: { componentId: IComponentDefinitionId; slotId: ISlotId }) {
    super({
      name: "ComponentInputsNotCompatibleWithSlot",
      message: `Component ${componentId} inputs are not compatible with slot ${slotId}`,
    });
  }
}
