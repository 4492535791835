import type { IActionCore, IActionInput, IStateTransitionOrCreation, ITargetEntityTypeCore } from "@archetype/dsl";
import { computeColumnViewFieldId, createColumnViewField } from "@archetype/dsl";

export const standardizeActionInStateMachine = (
  actionCore: IActionCore,
  transition: IStateTransitionOrCreation,
  targetEntityType: ITargetEntityTypeCore,
): IActionCore => {
  const statusViewField = createColumnViewField(targetEntityType.statusColumn);

  // `null` only for create transition for now
  if (transition.from == null) {
    const displayNameViewFieldId = computeColumnViewFieldId(targetEntityType.displayNameColumn);
    const currentDisplayNameInput = actionCore.actionDefinition.inputs.find(
      (f) => f.viewField.id === displayNameViewFieldId,
    );
    const currentDisplayNameColumn = targetEntityType.columns.find((c) => c.id === targetEntityType.displayNameColumn);

    const mandatoryInputs: IActionInput[] = [
      {
        viewField: createColumnViewField(targetEntityType.statusColumn),
        defaultValue: {
          type: "static",
          value: { type: "string", value: transition.to },
        },
        allowChangingDefault: false,
        required: true,
      },
      {
        viewField: createColumnViewField(targetEntityType.primaryKey),
        defaultValue: {
          type: "computed",
          value: "randomUUID",
        },
        allowChangingDefault: false,
        required: true,
      },
      {
        viewField: statusViewField,
        defaultValue: {
          type: "static",
          value: { type: "string", value: transition.to },
        },
        allowChangingDefault: false,
        required: true,
      },
    ];

    if (currentDisplayNameInput == null) {
      mandatoryInputs.push({
        viewField: createColumnViewField(targetEntityType.displayNameColumn),
        allowChangingDefault: currentDisplayNameColumn?.autofill?.type !== "live", // true by default unless it's a live field
        required: true,
      });
    } else {
      currentDisplayNameInput.allowChangingDefault = currentDisplayNameColumn?.autofill?.type !== "live";
      currentDisplayNameInput.required = true;
    }

    const mandatoryInputFieldIdSet = new Set(mandatoryInputs.map((input) => input.viewField.id));

    const createAction: IActionCore = {
      ...actionCore,
      actionDefinition: {
        ...actionCore.actionDefinition,
        actionType: "add",
        fromStates: undefined,
        toState: transition.to,
        inputs: mandatoryInputs.concat(
          actionCore.actionDefinition.inputs.filter((input) => !mandatoryInputFieldIdSet.has(input.viewField.id)),
        ),
      },
    };

    return createAction;
  }

  const mandatoryInputs: IActionInput[] = [
    {
      viewField: statusViewField,
      defaultValue: {
        type: "static",
        value: { type: "string", value: transition.to },
      },
      allowChangingDefault: false,
      required: true,
    },
  ];

  const transitionAction: IActionCore = {
    ...actionCore,
    actionDefinition: {
      ...actionCore.actionDefinition,
      actionType: "modify",
      fromStates: [transition.from],
      toState: transition.to,
      inputs: mandatoryInputs.concat(
        actionCore.actionDefinition.inputs.filter((input) => input.viewField.id !== statusViewField.id),
      ),
    },
  };

  return transitionAction;
};
