import { cn, PhoneInput } from "@archetype/ui";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";

import { useCellClickInteraction } from "../../hooks/useCellClickInteraction";
import { useCellEditOnEnter } from "../../hooks/useCellEditOnEnter";
import { useCellExitOnEsc } from "../../hooks/useCellExitOnEsc";
import { useCellFocusOnEdit } from "../../hooks/useCellFocusOnEdit";
import { useCellSaveOnEnter } from "../../hooks/useCellSaveOnEnter";
import type { IDataTableCommonCellProps, IDataTablePhoneCell } from "./api";

export function PhoneCell<TRowId extends string, TColumnId extends string>({
  cell,
  isCellEditing,
  isCellSelected,
}: IDataTableCommonCellProps<TRowId, TColumnId, IDataTablePhoneCell<TRowId, TColumnId>>): React.ReactNode {
  const { value, onChange, rowId, colId, readOnly } = cell;

  const inputRef = useRef<HTMLInputElement>(null);
  const [innerValue, setInnerValue] = useState<string | null>(value);
  // Get the country from the phone number, or default to US
  const country = useMemo(() => {
    if (value === null || value === "") {
      return "US";
    }

    try {
      const phoneNumber = parsePhoneNumber(value, "US");

      return phoneNumber?.country ?? "US";
    } catch {
      return "US";
    }
  }, [value]);

  const handleSave = useCallback(() => {
    void onChange?.(innerValue ?? "");
  }, [onChange, innerValue]);

  const { handleCellClick } = useCellClickInteraction({
    isCellSelected,
    isCellEditing,
    colId,
    rowId,
    readOnly,
  });

  useCellEditOnEnter({
    rowId,
    colId,
    isCellEditing,
    isCellSelected,
  });

  useCellFocusOnEdit({
    isCellSelected,
    isCellEditing,
    inputRef,
  });

  useCellExitOnEsc({
    isCellSelected,
    isCellEditing,
    inputRef,
  });

  useCellSaveOnEnter({
    isCellSelected,
    isCellEditing,
    onEnter: handleSave,
  });

  const handleChange = useCallback((v: string): void => {
    setInnerValue(v);
  }, []);

  const handleBlur = useCallback(() => {
    if (innerValue !== value) {
      void onChange?.(innerValue ?? "");
    }
  }, [onChange, innerValue, value]);

  return (
    <div className="size-full overflow-hidden px-2" onClick={handleCellClick}>
      <PhoneInput
        // @sspringer I cannot figure out how to properly type this ref
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any -- functional
        ref={inputRef as any}
        className={cn(!isCellEditing && "pointer-events-none")}
        defaultCountry={country}
        small={true}
        value={innerValue ?? ""}
        variant="text"
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </div>
  );
}
