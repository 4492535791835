import type { IComponentDefinitionId, IInputSemanticId, IOutputSemanticId } from "@archetype/ids";
import { ComponentDefinitionId } from "@archetype/ids";
import { z } from "zod";

import type { IComponentSemanticSize } from "./common";
import { ComponentSemanticSize } from "./common";
import { UncheckedDisplayMetadata } from "./common/DisplayMetadata";
import type { ICosmeticOptionalPropNames } from "./config/CosmeticOptionalConfig";
import { CosmeticOptionalPropNames } from "./config/CosmeticOptionalConfig";
import { SemanticInputTypesRecord, SemanticOutputTypesRecord } from "./config/RuntimeSemanticParameters";

const ComponentParameterAbstract = z.object({
  /**
   * Should generally be similar across components slots for the same `semanticId`, but no need to enforce it.
   */
  displayMetadata: UncheckedDisplayMetadata,

  /**
   * Always false for an output parameter. (Note we could split types but this is probably better for code reusability)
   * optional means there may not be a different component outputting and passing that value.
   *
   *    This is notably different from "nullable" describing that the parameter value may be null, this means that there may be no parameter passed at all.
   *    For example nullable is an empty state or disabled, while optional is more of a config that a feature is absent. Additionally nullable only applies to singleEntity.
   */
  optional: z.boolean(),
});

/**
 * Used only in list of components, and referenced in an instantiated module
 */
export const ComponentDefinition = z.object({
  id: ComponentDefinitionId,

  displayMetadata: UncheckedDisplayMetadata,

  /**
   * The sizes at which the component can be rendered, should likely be a continous range
   */
  compatibleSemanticSizes: z.array(ComponentSemanticSize),

  /**
   * NOTES to self: as inputs are used for constraints on what can go in a slot, basically because we need to check
   * if the component is compatible, i.e. can it accept am entity input, does it output an entity that can be used downstream.
   *
   * So we will have 2 options to know which components are compatible with which slots:
   * - Automatic matching: this can either be some auto matching on the type (is there a "single entity" input parameter), or something more semantic which would allow more complex components,
   *   for example having 2 different "single entity" inputs
   * - Define inputs and outputs of the slots themselves, and then explicitly map those to the inputs/outputs of the component
   * (UX-wise this is irrelevant and we can provide all the same UXs in both cases)
   * The second option is cleaner, but may be unnecessary.
   * It may also make it hard to automatically add compatibility for new components as we introduce them...
   * Also option 1 could easily be migrated to option 2 as the matching logic is reproducible.
   * --> So option 1 for now
   *
   */
  inputs: SemanticInputTypesRecord(ComponentParameterAbstract),

  outputs: SemanticOutputTypesRecord(ComponentParameterAbstract, ComponentParameterAbstract),

  validCosmeticOptionalProps: z.array(CosmeticOptionalPropNames),
});

export type IComponentDefinition = z.infer<typeof ComponentDefinition>;

export type IComponentIdDeclaration = {
  id: IComponentDefinitionId;
  inputs: ReadonlyArray<IInputSemanticId>;
  outputs: ReadonlyArray<IOutputSemanticId>;
  compatibleSemanticSizes: ReadonlyArray<IComponentSemanticSize>;
  validCosmeticOptionalProps: ReadonlyArray<ICosmeticOptionalPropNames>;
};

export type IComponentDefinitionDeclaration = Omit<
  IComponentDefinition,
  "id" | "compatibleSemanticSizes" | "validCosmeticOptionalProps"
> & {
  constIds: IComponentIdDeclaration;
};

export type ITypedComponentDefinitionDeclaration<IDS extends IComponentIdDeclaration> = Omit<
  IComponentDefinition,
  "id" | "inputs" | "outputs" | "compatibleSemanticSizes" | "validCosmeticOptionalProps"
> & {
  constIds: IDS;
  inputs: Pick<Required<IComponentDefinition["inputs"]>, IDS["inputs"][number]>;
  outputs: Pick<Required<IComponentDefinition["outputs"]>, IDS["outputs"][number]>;
};
