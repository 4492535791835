import { keys } from "@archetype/utils";
import { z } from "zod";

export const StateColor = z.enum([
  "lilac",
  "purple",
  "pink",
  "red",
  "orange",
  "brown",
  "yellow",
  "lime",
  "sage",
  "green",
  "emerald",
  "teal",
  "blue",
  "neutral",
]);

export type IStateColor = z.infer<typeof StateColor>;

export const STATE_COLORS_CSS: {
  [_colorName in IStateColor]: string;
} = {
  lilac: "lilac",
  purple: "purple",
  pink: "pink",
  red: "red",
  orange: "orange",
  brown: "brown",
  yellow: "yellow",
  lime: "lime",
  sage: "sage",
  green: "green",
  emerald: "emerald",
  teal: "teal",
  blue: "blue",
  neutral: "neutral",
};

export const STATE_COLOR_NAMES: IStateColor[] = keys(STATE_COLORS_CSS);
