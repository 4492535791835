import { ViewFieldId } from "@archetype/ids";
import { z } from "zod";

export const FieldComputationHash = z.string().brand("FieldComputationHash");
export type IFieldComputationHash = z.infer<typeof FieldComputationHash>;

export const FieldComputationHashedInputValues = z.record(ViewFieldId, FieldComputationHash);
export type IFieldComputationHashedInputValues = z.infer<typeof FieldComputationHashedInputValues>;

export const FieldComputationStatus = z.enum(["success", "error", "computing", "recomputing", "userEdited"]);
export type IFieldComputationStatus = z.infer<typeof FieldComputationStatus>;
