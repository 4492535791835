import { Skeleton } from "@archetype/ui";
import React from "react";

// eslint-disable-next-line max-params -- internal and random ish anyway
const randomLookingLcg = (row: number, a = 11, c = 23, m = 5): number => {
  return ((row * a + c) % m) / m;
};

interface IEntityFieldsSkeleton {
  fieldsCount?: number;
}

export const EntityFieldsSkeleton: React.FC<IEntityFieldsSkeleton> = ({ fieldsCount = 10 }) => {
  return (
    <div className="flex flex-col">
      {Array.from({ length: fieldsCount }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key -- skeleton is random
        <div key={index} className="flex flex-row gap-3 py-1">
          <div className="flex w-52 min-w-52 flex-row gap-1">
            <Skeleton
              className="h-4"
              style={{
                width: `${(50 + 400 * randomLookingLcg(index + 1)).toFixed(0)}px`,
                maxWidth: "10rem",
              }}
            />
          </div>
          <Skeleton
            className="h-4"
            style={{
              width: `${(50 + 400 * randomLookingLcg(index + 1)).toFixed(0)}px`,
              maxWidth: "w-full",
            }}
          />
        </div>
      ))}
    </div>
  );
};
