import React, { useCallback, useEffect, useState } from "react";
import { usePrevious } from "react-use";

import { cn } from "../../lib/utils";
import { Button } from "../atoms/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "../atoms/tooltip";

export interface IWorkspaceLayout {
  callout?: React.ReactNode;
  leftSidebarHeader?: React.ReactNode;
  leftSidebarFooter?: React.ReactNode;
  leftSidebarContent: React.ReactNode;
  rightSidebarContent?: React.ReactNode | null;
  mainPanel: React.ReactNode;
  mainPanelHeader?: React.ReactNode;
  /**
   * If undefined will not apply a default, and will set to the default when the default changes
   */
  defaultLeftSidebarOpen?: boolean;
}

export const WorkspaceLayout: React.FC<IWorkspaceLayout> = ({
  callout,
  leftSidebarHeader,
  leftSidebarFooter,
  leftSidebarContent,
  rightSidebarContent,
  mainPanel,
  mainPanelHeader,
  defaultLeftSidebarOpen,
}) => {
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(defaultLeftSidebarOpen ?? window.innerWidth > 768);
  const isRightSidebarOpen = rightSidebarContent != null;

  const previousDefaultLeftSidebarOpen = usePrevious(defaultLeftSidebarOpen);

  useEffect(() => {
    if (
      previousDefaultLeftSidebarOpen != null &&
      defaultLeftSidebarOpen != null &&
      previousDefaultLeftSidebarOpen !== defaultLeftSidebarOpen
    ) {
      setIsLeftSidebarOpen(defaultLeftSidebarOpen);
    }
  }, [defaultLeftSidebarOpen, previousDefaultLeftSidebarOpen]);

  const handleToggleSidebar = useCallback(() => {
    setIsLeftSidebarOpen((isOpen) => !isOpen);
  }, []);

  const commonHeaderClass = cn("flex h-12 w-full shrink-0 items-center px-2");

  return (
    <main className="bg-paper-alt text-ink flex h-screen w-screen flex-col overflow-hidden">
      {callout != null ? <div className="flex h-8 w-full">{callout}</div> : null}
      <div className="relative flex size-full flex-row overflow-hidden">
        <div
          className={cn(
            "absolute inset-y-0 left-0 flex w-64 flex-col items-stretch overflow-y-auto pt-2 transition-all",
            isLeftSidebarOpen ? "translate-x-0" : "bg-paper-alt -translate-x-full",
          )}
        >
          <div className={commonHeaderClass}>{leftSidebarHeader}</div>
          <div className="shrink-0 grow">{leftSidebarContent}</div>
          {leftSidebarFooter}
        </div>

        <div
          className={cn(
            "border-border bg-paper flex h-full shrink-0 grow flex-col overflow-hidden border sm:m-2 sm:rounded-md",
            isLeftSidebarOpen ? "!ml-64" : "sm:ml-2",
            callout != null && mainPanelHeader != null ? "sm:max-h-[calc(100dvh-3rem)]" : undefined,
            callout == null && mainPanelHeader != null ? "sm:max-h-[calc(100dvh-1rem)]" : undefined,
            callout != null && mainPanelHeader === null ? "sm:max-h-dvh" : undefined,
            isLeftSidebarOpen ? "max-w-[calc(100dvw-16rem)]" : "max-w-[100dvw]",
            isRightSidebarOpen ? "sm:mr-0" : "sm:mr-2",
          )}
        >
          {mainPanelHeader != null ? (
            <div className={cn(commonHeaderClass, "border-border flex h-12 w-full items-center space-x-2 border-b")}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    className="shrink-0"
                    iconLeft="panel-left"
                    size="sm"
                    variant="ghost"
                    onClick={handleToggleSidebar}
                  />
                </TooltipTrigger>
                <TooltipContent>{isLeftSidebarOpen ? "Hide sidebar" : "Show sidebar"}</TooltipContent>
              </Tooltip>
              {mainPanelHeader}
            </div>
          ) : null}
          <div className={cn("flex size-full overflow-hidden")}>{mainPanel}</div>
        </div>

        <div
          className={cn(
            "relative inset-y-0 w-0 min-w-0 flex-col items-stretch overflow-y-auto transition-all",
            rightSidebarContent != null ? "md:w-72 md:min-w-72" : undefined,
          )}
        >
          {rightSidebarContent}
        </div>
      </div>
    </main>
  );
};
