import type { IEntityColumnValue, IEntityType, IRelation, IVersionType } from "@archetype/dsl";
import { USER_ENTITY_ID_COLUMN_ID } from "@archetype/dsl";
import type { IActionId, IEntityId, IEntityTypeId, IOrganizationId, IRelationId } from "@archetype/ids";
import { assertNonNullable, isNonNullable, keyByAndMapValues, keys, map, mapValues, pickBy } from "@archetype/utils";

import type { ILoadedAction } from "../apiTypes/LoadedActionType";
import { createFileLogger } from "../logger";
import { getActionAuthorizations } from "../query/authorization/action";
import { splitRelevantAuthorizations } from "../query/authorization/paths/comparison";
import { resolveAuthorizationPaths } from "../query/authorization/paths/resolver";
import { buildFiltersFromResolvedPaths } from "../query/authorization/queries/builder";
import type {
  IAuthorizationsByEntityScope,
  IAuthorizedStates,
  ILoadedAuthorizingPathFromQueried,
  ILoadedAuthorizingPathFromSpecified,
} from "../query/authorization/types";
import type { ILoadedCrossColumnAndConditions, ILoadedDataLoadingQuery } from "../query/loadedQuery";
import { reverseRelatedToFilterDegenerateTree } from "../query/relatedToFilters";

const logger = createFileLogger("action-authorization");

export interface IActionAuthorizationContext {
  entityTypesById: Record<IEntityTypeId, IEntityType>;
  relationsById: Record<IRelationId, IRelation>;
  actionsByOrganizationId: Record<IOrganizationId, ILoadedAction[]>;
  userEntityTypesByOrgId: Record<IOrganizationId, IEntityType>;
  actionsById: Record<IActionId, ILoadedAction>;
}

export interface IActionAuthorizationPaths {
  fromQueriedEntity: IAuthorizationsByEntityScope<ILoadedAuthorizingPathFromQueried>;
  fromOtherUnrelatedEntityType: IAuthorizationsByEntityScope<ILoadedAuthorizingPathFromSpecified>;
}

export function resolveOrganizationAuthorizationPaths(
  authContext: IActionAuthorizationContext,
  userEntityIdsByOrganizationId: Record<IOrganizationId, Record<IVersionType, IEntityId>>,
  versionType: IVersionType,
): Record<IOrganizationId, Record<IActionId, IActionAuthorizationPaths>> {
  return mapValues(authContext.actionsByOrganizationId, (orgActions, organizationId) => {
    const userEntityIdForOrg = userEntityIdsByOrganizationId[organizationId]?.[versionType];
    const userEntityTypeForOrg = authContext.userEntityTypesByOrgId[organizationId];

    assertNonNullable(userEntityIdForOrg, "userEntityIdForOrg");
    assertNonNullable(userEntityTypeForOrg, "userEntityTypeForOrg");

    return keyByAndMapValues(orgActions, (action) => {
      const auths = getActionAuthorizations(action);
      const loadedAuths = resolveAuthorizationPaths({
        authorizingPaths: auths,
        relationsById: authContext.relationsById,
        entityTypesById: authContext.entityTypesById,
        retrievalEntityTypesById: authContext.entityTypesById,
      });
      const { fromQueriedEntity, fromOtherUnrelatedEntityType } = splitRelevantAuthorizations(loadedAuths);

      logger.debug(
        {
          actionId: action.id,
          fromQueriedEntity,
          fromOtherUnrelatedEntityType,
        },
        "action authorization paths",
      );

      return {
        key: action.id,
        value: {
          fromQueriedEntity,
          fromOtherUnrelatedEntityType,
        },
      };
    });
  });
}

export function buildUserAuthorizationQueries({
  orgAuthPaths,
  unrelatedAuthResults,
  authContext,
  userEntityIdsByOrganizationId,
  versionType,
}: {
  orgAuthPaths: Record<IOrganizationId, Record<IActionId, IActionAuthorizationPaths>>;
  unrelatedAuthResults: Record<IOrganizationId, Record<IActionId, IAuthorizedStates>>;
  authContext: IActionAuthorizationContext;
  userEntityIdsByOrganizationId: Record<IOrganizationId, Record<IVersionType, IEntityId>>;
  versionType: IVersionType;
}): Record<IOrganizationId, ILoadedDataLoadingQuery> {
  return mapValues(orgAuthPaths, (authsByActionId, organizationId) => {
    const userEntityIdForOrg = userEntityIdsByOrganizationId[organizationId]?.[versionType];
    const userEntityTypeForOrg = authContext.userEntityTypesByOrgId[organizationId];

    assertNonNullable(userEntityIdForOrg, "userEntityIdForOrg");
    assertNonNullable(userEntityTypeForOrg, "userEntityTypeForOrg");

    const reverseAuthorizationPaths: Record<IActionId, ILoadedCrossColumnAndConditions> = mapValues(
      authsByActionId,
      ({ fromQueriedEntity }, actionId) => {
        const action = authContext.actionsById[actionId];

        assertNonNullable(action, "action");

        const authorizedThroughUnrelated = unrelatedAuthResults[organizationId]?.[action.id];
        const startingEntityType = authContext.entityTypesById[action.entityTypeId];

        assertNonNullable(authorizedThroughUnrelated, "authorizedThroughUnrelated");
        assertNonNullable(startingEntityType, "startingEntityType");

        const fromQueriedEntityFilters = buildFiltersFromResolvedPaths({
          startingEntityType,
          paths: fromQueriedEntity,
          authorizedForUserEntityId: userEntityIdForOrg,
        });

        const fromQueriedPathsReversed = fromQueriedEntityFilters.map((path) =>
          reverseRelatedToFilterDegenerateTree(path, authContext.entityTypesById),
        );

        const statusFilters: ILoadedCrossColumnAndConditions[] = [];

        if (authorizedThroughUnrelated.authorizedStateIds.length > 0) {
          const statusColumn = startingEntityType.statusColumn;

          assertNonNullable(statusColumn, "statusColumn");

          statusFilters.push({
            type: "and",
            perColumn: {
              [statusColumn]: {
                type: "or",
                rawOrConditions: {
                  eq: authorizedThroughUnrelated.authorizedStateIds.map((stateId) => ({
                    type: "value",
                    value: {
                      type: "string",
                      value: stateId,
                    },
                  })),
                },
                oredAndConditions: [],
              },
            },
            andedCrossColumnOrConditions: [],
            andedRelatedToFilters: [],
          });
        }

        return {
          type: "and",
          perColumn: {},
          andedCrossColumnOrConditions: [
            {
              type: "or",
              oredCrossColumnAndConditions: [...fromQueriedPathsReversed, ...statusFilters],
            },
          ],
          andedRelatedToFilters: [],
        };
      },
    );

    return {
      entityType: {
        type: "entityType",
        entityType: userEntityTypeForOrg,
      },
      versionType,
      filters: {
        type: "and",
        perColumn: {
          [USER_ENTITY_ID_COLUMN_ID]: {
            type: "and",
            rawAndOperations: {
              eq: {
                type: "value",
                value: {
                  type: "string",
                  value: userEntityIdForOrg,
                },
              },
            },
            andedOrConditions: [],
          },
        },
        andedCrossColumnOrConditions: [],
        andedRelatedToFilters: [],
      },
      derivations: mapValues(reverseAuthorizationPaths, (path) => ({
        type: "boolean" as const,
        calculation: path,
      })),
    };
  });
}

export function getAuthorizationBypassActionIds(
  unrelatedAuthResults: Record<IOrganizationId, Record<IActionId, IAuthorizedStates>>,
  allActions: ILoadedAction[],
): Record<IOrganizationId, IActionId[]> {
  const alwaysAllowedActionIdsByOrganizationId = mapValues(unrelatedAuthResults, (authsByActionId) =>
    keys(pickBy(authsByActionId, ({ isAnyStateAuthorized }) => isAnyStateAuthorized)),
  );

  allActions
    .filter((action) => action.actionDefinition.authorizedForAnyoneWithLink === true)
    .forEach((action) => {
      alwaysAllowedActionIdsByOrganizationId[action.organizationId] ??= [];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we just created it!
      alwaysAllowedActionIdsByOrganizationId[action.organizationId]!.push(action.id);
    });

  return alwaysAllowedActionIdsByOrganizationId;
}

export function processAuthorizationQueryResults(
  results: Record<IOrganizationId, { entities: Array<{ derivedValues?: Record<string, IEntityColumnValue> }> }>,
  bypassActionIds: Record<IOrganizationId, IActionId[]>,
): Record<IOrganizationId, IActionId[]> {
  return mapValues(results, ({ entities }, organizationId) => {
    const deriveds = entities[0]?.derivedValues;
    const alwaysAllowedActionIdsForOrganization = bypassActionIds[organizationId] ?? [];

    if (deriveds == null) {
      return alwaysAllowedActionIdsForOrganization;
    }

    const executableActionIds = map(deriveds, (derived, actionId) =>
      derived.type === "boolean" && derived.value ? actionId : undefined,
    ).filter(isNonNullable) as IActionId[];

    return executableActionIds.concat(alwaysAllowedActionIdsForOrganization);
  });
}
