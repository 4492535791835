"use client";

import { format } from "date-fns";
import * as React from "react";
import { useCallback } from "react";

import { cn } from "../../lib/utils";
import { Calendar } from "./calendar";
import { Icon } from "./icon";
import { Input, inputVariants } from "./input";
import { Popover, PopoverContent, PopoverPortal, PopoverTrigger } from "./popover";

interface IDateTimeProps {
  value: Date | undefined;
  onChange: (date: Date | undefined) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  small?: boolean;
  variant?: "button" | "text";
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
  className?: string;
  pickerPresentation?: "popover" | "inline";
}

interface IDateTimeDisplay extends IDateTimeProps {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const DateTimeDisplay = React.forwardRef<HTMLButtonElement | HTMLSpanElement, IDateTimeDisplay>(
  (
    { className, value, placeholder, small, variant = "button", onBlur, onFocus, disabled, onClick },
    ref,
  ): React.ReactElement => {
    const handleBlur = useCallback(() => onBlur?.(), [onBlur]);
    const handleFocus = useCallback(() => onFocus?.(), [onFocus]);
    const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => onClick?.(e), [onClick]);

    const content = (
      <>
        {variant === "button" && <Icon className="text-placeholder mr-2 size-4 shrink-0" name="calendar" />}
        <span className={cn("truncate", value ? "text-ink" : "text-placeholder")}>
          {value != null ? format(value, "PPP @ h:mm aa") : (placeholder ?? "Select a date and time...")}
        </span>
      </>
    );

    if (variant === "text") {
      return (
        <span
          ref={ref as React.Ref<HTMLSpanElement>}
          className={cn("flex items-center truncate", className, small === true ? "text-base" : "text-lg")}
        >
          {content}
        </span>
      );
    }

    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        className={cn(
          inputVariants({ size: small === true ? "small" : "default" }),
          "flex items-center justify-start",
          className,
        )}
        disabled={disabled}
        type="button"
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
      >
        {content}
      </button>
    );
  },
);

DateTimeDisplay.displayName = "DateTimeDisplay";

export const DateTimeInput: React.FC<IDateTimeProps> = ({
  value,
  onChange,
  className,
  placeholder,
  small = true,
  variant = "button",
  isOpen,
  onOpenChange,
  onBlur,
  onFocus,
  disabled,
  pickerPresentation = "popover",
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(value);
  const [selectedTime, setSelectedTime] = React.useState<string>(value ? format(value, "HH:mm") : "");

  const handleBlur = useCallback(() => onBlur?.(), [onBlur]);
  const handleFocus = useCallback(() => onFocus?.(), [onFocus]);
  const handleOpenChange = useCallback((open: boolean) => onOpenChange?.(open), [onOpenChange]);
  const handleChange = useCallback(
    (date: Date | undefined): void => {
      onChange(date);
    },
    [onChange],
  );

  const handleDateChange = useCallback(
    (date: Date | undefined): void => {
      setSelectedDate(date);
      if (date) {
        const [hours, minutes] = selectedTime.split(":").map(Number);

        if (hours != null && minutes != null) {
          date.setHours(hours, minutes);
          onChange(date);
        }
      } else {
        onChange(undefined);
      }
    },
    [selectedTime, onChange],
  );

  const handleTimeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setSelectedTime(e.target.value);
      if (selectedDate) {
        const [hours, minutes] = e.target.value.split(":").map(Number);
        const newDate = new Date(selectedDate);

        if (hours != null && minutes != null) {
          newDate.setHours(hours, minutes);
          onChange(newDate);
        }
      }
    },
    [selectedDate, onChange],
  );

  const pickerContent = (
    <div>
      <Calendar
        defaultMonth={selectedDate}
        disabled={disabled}
        initialFocus={pickerPresentation === "popover"}
        mode="single"
        selected={selectedDate}
        onSelect={handleDateChange}
      />
      <div className="border-t-border flex justify-center border-t py-1 *:mx-auto *:inline-flex *:w-auto *:grow-0">
        <Input
          className="border-0 text-center"
          disabled={disabled}
          small={small}
          type="time"
          value={selectedTime}
          onChange={handleTimeChange}
        />
      </div>
    </div>
  );

  if (pickerPresentation === "inline") return pickerContent;

  return (
    <Popover open={isOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <DateTimeDisplay
          className={className}
          disabled={disabled}
          placeholder={placeholder}
          small={small}
          value={value}
          variant={variant}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent align="start" className="w-auto p-0">
          {pickerContent}
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

DateTimeInput.displayName = "DateTimeInput";
