import type { IVersionType } from "@archetype/dsl";
import type { IEntityId, IOrganizationId } from "@archetype/ids";
import { cn, Skeleton } from "@archetype/ui";
import React from "react";

import { useLoadedEntity } from "../hooks/entity/useLoadedEntity";
import { useStableCurrentUserInfo } from "../hooks/useStableCurrentUserInfo";

interface IExternalUserName {
  entityId: IEntityId;
  organizationId: IOrganizationId;
  versionType: IVersionType;
  className?: string;
}

export const ExternalUserName: React.FC<IExternalUserName> = ({ entityId, organizationId, versionType, className }) => {
  const { data: currentUserInfoQuery } = useStableCurrentUserInfo({
    versionType,
  });

  const userEntityTypeId = currentUserInfoQuery?.currentUserInfo.userEntityTypeId;

  const { entityData } = useLoadedEntity({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we know the user entity type id is set
    query: { organizationId, versionType, entityTypeId: userEntityTypeId!, entityId },
    options: { explicitlyDisabled: currentUserInfoQuery == null },
  });

  if (entityData == null) {
    return <ExternalUserNameSkeleton className={className} />;
  }

  return <span className={cn("font-medium", className)}>{entityData.entity.displayName}</span>;
};

const ExternalUserNameSkeleton: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <span className="inline-block">
      <Skeleton className={cn("h-4 w-16", className)} />
    </span>
  );
};
