"use client";

import * as React from "react";

import { cn } from "../../lib/utils";
import { Loader } from "../molecules/loader/Loader";
import { Icon } from "./icon";

export type IFeatureConfigType =
  | "field"
  | "permission"
  | "fieldsOnView"
  | "permissionsOnView"
  | "fieldsOnAction"
  | "permissionsOnAction"
  | "addingNewAction"
  | "addingNewStep";

export interface IFeatureConfigItem {
  configName: string;
  configType?: IFeatureConfigType;
  status: "loading" | "complete" | "error";
  children?: IFeatureConfigItem[];
}

const CONFIG_METADATA = {
  field: {
    icon: "book-plus",
    loadingText: "Adding your new field...",
  },
  permission: {
    icon: "circle-user-round",
    loadingText: "Setting up user access...",
  },
  fieldsOnView: {
    icon: "check",
    loadingText: "Choosing the right fields for you...",
  },
  permissionsOnView: {
    icon: "check",
    loadingText: "Figuring out who can see this...",
  },
  fieldsOnAction: {
    icon: "check",
    loadingText: "Getting your form fields ready...",
  },
  permissionsOnAction: {
    icon: "check",
    loadingText: "Setting who can use this form...",
  },
  addingNewAction: {
    icon: "check",
    loadingText: "Creating your new form...",
  },
  addingNewStep: {
    icon: "check",
    loadingText: "Adding your next workflow step...",
  },
} as const;

interface IFeatureConfigItemProps {
  isRoot?: boolean;
  item: IFeatureConfigItem;
}

export function FeatureConfigItem({ isRoot = false, item }: IFeatureConfigItemProps): React.JSX.Element {
  function renderIcon(): React.JSX.Element {
    if (item.status === "loading") {
      return isRoot ? (
        <Loader className="size-6" text="" variant="pyramid" />
      ) : (
        <Loader className="-mr-1" size="xs" text="" variant="pyramid" />
      );
    }

    if (item.status === "error") {
      return <Icon className="text-muted-foreground size-4" name="circle-slash-2" />;
    }

    if (isRoot) {
      return (
        <div className="bg-brand flex size-5 items-center justify-center rounded-full">
          <Icon className="size-3 text-white" name="check" />
        </div>
      );
    }

    const iconName = item.configType !== undefined ? CONFIG_METADATA[item.configType].icon : "check";

    return <Icon className="text-muted-foreground size-4" name={iconName} />;
  }

  function getText(): string {
    return item.status === "loading" && item.configType !== undefined
      ? CONFIG_METADATA[item.configType].loadingText
      : item.configName;
  }

  return (
    <div className="mb-1 flex items-center gap-2">
      {renderIcon()}
      <span
        className={cn("text-base", item.status === "loading" ? "text-muted-foreground" : "", isRoot ? "text-ink" : "")}
      >
        {getText()}
      </span>
    </div>
  );
}
