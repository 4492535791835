import type { ITypedLayoutDefinitionDeclaration } from "@archetype/dsl";
import { LayoutConfigurationId, SearchableListLayoutId, SlotId } from "@archetype/ids";

export const SearchableListLayoutIds = {
  id: SearchableListLayoutId,
  layoutConfigurations: {
    mainEntityType: "entityType",
  },
  moduleParameters: {
    inputs: ["multiEntityQueryDataInput"],
    outputs: ["userSelectedSingleEntity"],
  },
  slots: {
    filteringActionBar: {
      optional: false,
      inputs: ["multiEntityQueryDataInput"],
      outputs: ["filteredMultiEntityQuery"],
    },
    main: {
      optional: false,
      inputs: ["multiEntityQueryDataInput"],
      outputs: ["userSelectedSingleEntity"],
    },
  },
} as const;

export const SearchableListLayout: ITypedLayoutDefinitionDeclaration<typeof SearchableListLayoutIds> = {
  constIds: SearchableListLayoutIds,
  displayMetadata: {
    name: "Searchable list",
    description: "A list with a search input",
  },
  tags: ["list", "search"],
  layoutConfiguration: {
    mainEntityType: {
      type: "entityType",
    },
  },
  slots: {
    filteringActionBar: {
      displayMetadata: {
        name: "Search input",
        description: "A search input",
      },
      constraints: {
        semanticSize: "actions",
        inputs: {
          multiEntityQueryDataInput: {
            ref: {
              type: "config",
              layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
            },
          },
        },
        outputs: {
          filteredMultiEntityQuery: {
            type: "config",
            layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
          },
        },
      },
    },
    main: {
      displayMetadata: {
        name: "List",
        description: "A list",
      },
      constraints: {
        semanticSize: "main",
        inputs: {
          multiEntityQueryDataInput: {
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("filteringActionBar"),
              outputId: "filteredMultiEntityQuery",
            },
          },
        },
        outputs: {
          userSelectedSingleEntity: {
            type: "config",
            layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
            nullable: true,
          },
        },
      },
    },
  },
  moduleParameterInputs: {
    multiEntityQueryDataInput: {
      type: "multiEntityQuery",
      entityType: LayoutConfigurationId.parse("mainEntityType"),
      displayMetadata: {
        name: "Main data shown",
        description: undefined,
      },
    },
  },
  moduleParameterOutputs: {
    userSelectedSingleEntity: {
      type: "singleEntity",
      entityType: LayoutConfigurationId.parse("mainEntityType"),
      displayMetadata: {
        name: "User selection of a list item",
        description: undefined,
      },
    },
  },
  variants: [],
};
