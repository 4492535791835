import type { IAction, IDataModelFeature, IEntityType, IRelation, IStateMachine } from "@archetype/dsl";
import type { IActionId, IEntityTypeId, IFeatureId, IRelationId } from "@archetype/ids";
import { keyByNoUndefined } from "@archetype/utils";

import {
  collectDataModelIdInDataModelFeatures,
  dataModelFeaturesWithReadableIdToUuids,
} from "../features/mapDataModelFeaturesDataModelReferences";
import { buildReadableIdMappings } from "../features/mapReadableIdMappings";

export const featuresFromStateMachine = ({
  stateMachine,
  allCurrentEntityTypes,
  allCurrentActions,
  allCurrentEntityRelations,
}: {
  stateMachine: IStateMachine;
  allCurrentEntityTypes: Record<IEntityTypeId, IEntityType>;
  allCurrentActions: Record<IActionId, IAction>;
  allCurrentEntityRelations: Record<IRelationId, IRelation>;
}): {
  features: Record<IFeatureId, IDataModelFeature>;
} => {
  // TODO @noa: add validation features
  const readableInferredFeaturesArr: IDataModelFeature[] = [];
  const readableInferredFeaturesMap = keyByNoUndefined(readableInferredFeaturesArr, (feature) => feature.id);

  // collect readable ids from features
  const readableIds = collectDataModelIdInDataModelFeatures(readableInferredFeaturesMap);

  // map readable ids with current entity types or new uuids
  const readableIdMappings = buildReadableIdMappings({
    readableIds,
    allEntityTypes: allCurrentEntityTypes,
    allActions: allCurrentActions,
    entityRelations: allCurrentEntityRelations,
    allStates: {
      ...stateMachine.archivedStates,
      ...stateMachine.states,
    },
  });

  const mappedDataModelFeatures = dataModelFeaturesWithReadableIdToUuids(
    readableInferredFeaturesMap,
    readableIdMappings,
  );

  return {
    features: mappedDataModelFeatures,
  };
};
