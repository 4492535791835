import { $convertToMarkdownString } from "@lexical/markdown";
import type { LexicalEditor } from "lexical";
import React, { useCallback, useMemo } from "react";

import { cn } from "../../../lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "../../atoms/avatar";
import { AvatarBadge } from "../../atoms/avatar-badge";
import { Button } from "../../atoms/button";
import type { IIconNames } from "../../atoms/icon";
import { Input } from "../../atoms/input";
import { type IMultiSelectOption, MultiSelect } from "../../atoms/multiselect";
import type { IMentionLookupService } from "../markdown";
import {
  ANNOTATION_TRANSFORMER,
  DEFAULT_TRANSFORMERS,
  DefaultPlugins,
  MENTION_TRANSFORMER,
  MentionsPlugin,
  mkBodyEditorConfig,
} from "../markdown";
import { MarkdownEditor } from "../markdown";
import type { IAnnotation } from "../markdown/nodes/AnnotationNode";

export interface IEmailRecipient extends IMultiSelectOption {
  avatarUrl?: string;
}

export interface IEmailComposer {
  className?: string;
  title: React.ReactNode;
  placeholder?: string;
  ctas?: Array<
    | {
        type: "icon";
        icon: IIconNames;
        onClick?: () => void;
      }
    | {
        type: "text";
        label: string;
        onClick?: () => void;
      }
  >;
  small?: boolean;
  from: IEmailRecipient | undefined;
  fromOptions: IMultiSelectOption[];
  to: IEmailRecipient[] | undefined;
  cc?: IEmailRecipient[];
  bcc?: IEmailRecipient[];
  toOptions: IMultiSelectOption[];
  subject: string | undefined;
  body: string | undefined;
  onFromChange?: (from: IEmailRecipient[]) => void;
  onSubjectChange?: (subject: string) => void;
  onToChange?: (to: IEmailRecipient[]) => void;
  onBodyChange?: (body: string) => void;
  onAnnotationClick?: (annotation: IAnnotation) => void;
  onAnnotationCreate?: (selection: string) => Promise<IAnnotation>;
}

function renderOption(option: IMultiSelectOption): React.ReactNode {
  const emailRecipient = option as IEmailRecipient;
  const hasValidAvatar = emailRecipient.avatarUrl != null && emailRecipient.avatarUrl !== "";

  return (
    <div className="flex items-center gap-2">
      <Avatar className="size-6">
        {hasValidAvatar ? (
          <AvatarImage alt={option.label} src={emailRecipient.avatarUrl} />
        ) : (
          <AvatarFallback>{option.label[0]}</AvatarFallback>
        )}
      </Avatar>
      <span>{option.label}</span>
    </div>
  );
}

function renderBadge(
  option: IMultiSelectOption,
  props: {
    onUnselect: () => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
    disabled?: boolean;
  },
): React.ReactNode {
  return (
    <AvatarBadge
      avatarFallback={option.label[0] ?? ""}
      avatarSrc={option.avatarUrl?.toString()}
      iconRight={props.disabled !== true ? "x" : undefined}
      interactive={props.disabled !== true}
      onClick={props.onUnselect}
      onKeyDown={props.onKeyDown}
      onMouseDown={props.onMouseDown}
    >
      {option.label}
    </AvatarBadge>
  );
}

const mentionLookupService: IMentionLookupService = {
  search: async (): Promise<Array<never>> => [],
};

export const EmailComposer: React.FC<IEmailComposer> = ({
  className,
  title,
  ctas,
  from,
  fromOptions = [],
  onFromChange,
  onSubjectChange,
  onToChange,
  subject = "",
  body = "",
  onBodyChange,
  to = [],
  toOptions = [],
  cc = [],
  bcc = [],
  placeholder = "Start typing or select a template...",
  onAnnotationClick: handleAnnotationClick,
  onAnnotationCreate: handleAnnotationCreate,
}) => {
  const fromReadOnly: boolean = onFromChange == null;
  const toReadOnly: boolean = onToChange == null;
  const subjectReadOnly: boolean = onSubjectChange == null;
  const bodyReadOnly: boolean = onBodyChange == null;

  const handleFromChange = useCallback(
    (options: IEmailRecipient[]) => {
      onFromChange?.(options);
    },
    [onFromChange],
  );

  const handleToChange = useCallback(
    (options: IEmailRecipient[]) => {
      onToChange?.(options);
    },
    [onToChange],
  );

  const handleSubjectChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSubjectChange?.(e.target.value);
    },
    [onSubjectChange],
  );

  const handleBodyChange = useCallback(
    (editor: LexicalEditor) => {
      editor.read(() => {
        const markdown = $convertToMarkdownString([
          MENTION_TRANSFORMER,
          ANNOTATION_TRANSFORMER,
          ...DEFAULT_TRANSFORMERS,
        ]);

        onBodyChange?.(markdown);
      });
    },
    [onBodyChange],
  );
  const bodyEditorConfig = useMemo(() => mkBodyEditorConfig(body), [body]);

  const maybeRenderCTAs = useCallback(() => {
    if (ctas == null || ctas.length === 0) {
      return null;
    }

    const results = ctas.map((cta, idx) => {
      if (cta.type === "icon") {
        // eslint-disable-next-line react/jsx-handler-names, react/no-array-index-key -- we don't need to handle the click event here
        return <Button key={idx.toString()} iconLeft={cta.icon} variant="ghost" onClick={cta.onClick} />;
      }

      return (
        // eslint-disable-next-line react/jsx-handler-names, react/no-array-index-key -- we don't need to handle the click event here
        <Button key={idx.toString()} variant="ghost" onClick={cta.onClick}>
          {cta.label}
        </Button>
      );
    });

    return <div className="flex items-center gap-1">{results}</div>;
  }, [ctas]);

  const handleClickStopPropagation = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  return (
    <div className={cn("size-full cursor-default rounded-md border", className)} onClick={handleClickStopPropagation}>
      <div className="flex items-center justify-between border-b px-4 py-2">
        <div className="text-base">{title}</div>
        {maybeRenderCTAs()}
      </div>

      <div className="flex flex-col py-2">
        <div className="flex items-start px-4">
          <span className="flex h-11 w-16 items-center py-2 text-base">From</span>
          <div className="flex-1">
            <MultiSelect
              badgeRenderer={renderBadge}
              className="bg-input w-full"
              disabled={fromReadOnly}
              optionRenderer={renderOption}
              options={fromOptions}
              placeholder="Select sender"
              small={true}
              value={from != null ? [from] : []}
              variant="borderless"
              onChange={handleFromChange}
            />
          </div>
        </div>

        <div className="flex items-start px-4">
          <span className="flex h-11 w-16 items-center py-2 text-base">To</span>
          <div className="flex-1">
            <MultiSelect
              badgeRenderer={renderBadge}
              className="bg-input w-full"
              disabled={toReadOnly}
              optionRenderer={renderOption}
              options={toOptions}
              placeholder="Select recipient"
              small={true}
              value={to}
              variant="borderless"
              onChange={handleToChange}
            />
          </div>
        </div>

        {cc.length > 0 && (
          <div className="flex items-start px-4">
            <span className="flex h-11 w-16 items-center py-2 text-base">cc</span>
            <div className="flex-1">
              <MultiSelect
                badgeRenderer={renderBadge}
                className="bg-input w-full"
                disabled={toReadOnly}
                optionRenderer={renderOption}
                options={toOptions}
                placeholder="Select cc recipients"
                small={true}
                value={to}
                variant="borderless"
              />
            </div>
          </div>
        )}

        {bcc.length > 0 && (
          <div className="flex items-start px-4">
            <span className="flex h-11 w-16 items-center py-2 text-base">bcc</span>
            <div className="flex-1">
              <MultiSelect
                badgeRenderer={renderBadge}
                className="bg-input w-full"
                disabled={toReadOnly}
                optionRenderer={renderOption}
                options={toOptions}
                placeholder="Select bcc recipients"
                small={true}
                value={to}
                variant="borderless"
              />
            </div>
          </div>
        )}

        <div className="flex items-start px-4">
          <span className="flex h-11 w-16 items-center py-2 text-base">Subject</span>
          <Input
            className="h-11 w-full border-none"
            placeholder="Enter subject"
            readOnly={subjectReadOnly}
            small={true}
            value={subject}
            onChange={handleSubjectChange}
          />
        </div>

        <div className="flex items-start px-1">
          <MarkdownEditor
            className={cn("w-full border-none px-0", bodyReadOnly && "px-3 py-2")}
            enableAnnotations={true}
            initialConfig={bodyEditorConfig}
            placeholder={placeholder}
            readOnly={bodyReadOnly}
            small={true}
            toolbar="floating"
            onChange={handleBodyChange}
          >
            <MentionsPlugin mentionLookupService={mentionLookupService} />
            <DefaultPlugins
              transformers={[MENTION_TRANSFORMER, ANNOTATION_TRANSFORMER, ...DEFAULT_TRANSFORMERS]}
              onAnnotationClick={handleAnnotationClick}
              onAnnotationCreate={handleAnnotationCreate}
            />
          </MarkdownEditor>
        </div>
      </div>
    </div>
  );
};
