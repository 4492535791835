import { ApplicationGroupId, ColumnId, EntityTypeId } from "@archetype/ids";
import { z } from "zod";

import type { IEntityTypeCore, ISupportEntityTypeCore } from "./dataModel/EntityType";
import { EntityTypeCore, RelevantViewFieldsByStateId } from "./dataModel/EntityType";

export const TargetEntityTypeCore = EntityTypeCore.merge(
  z.object({
    statusColumn: ColumnId,
    /**
     * Record from StateId but zod parses as Partial
     */
    relevantViewFieldsByStateId: RelevantViewFieldsByStateId,
    targetEntityTypeApplicationGroupId: ApplicationGroupId,
  }),
);

export const StateMachineDataModel = z.object({
  targetEntityTypeId: EntityTypeId,
});

export type IStateMachineDataModel = z.infer<typeof StateMachineDataModel>;
export type ISimpleDataModelStateMachineDataModel = z.infer<typeof StateMachineDataModel>;
export type ITargetEntityTypeCore = z.infer<typeof TargetEntityTypeCore>;

export const isTargetEntityTypeCore = (entityType: IEntityTypeCore): entityType is ITargetEntityTypeCore => {
  return (
    entityType.statusColumn != null &&
    entityType.relevantViewFieldsByStateId != null &&
    entityType.targetEntityTypeApplicationGroupId != null
  );
};

export function isSupportEntityTypeCore<T extends IEntityTypeCore>(
  entityType: T,
): entityType is T & ISupportEntityTypeCore {
  return entityType.supportActionsInfo != null;
}
