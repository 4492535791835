import { ReadableString } from "@archetype/utils";
import { z } from "zod";

export const LLMFeatureWorkspaceContext = z.object({
  contextType: z.literal("workspace"),
});
export type ILLMFeatureWorkspaceContext = z.infer<typeof LLMFeatureWorkspaceContext>;

export const LLMFeatureEntityTypeContext = z.object({
  contextType: z.literal("entityType"),
  entityTypeName: ReadableString,
});
export type ILLMFeatureEntityTypeContext = z.infer<typeof LLMFeatureEntityTypeContext>;

export const LLMFeatureStateContext = z.object({
  contextType: z.literal("state"),
  entityTypeName: ReadableString,
  stateName: ReadableString,
});
export type ILLMFeatureStateContext = z.infer<typeof LLMFeatureStateContext>;

export const LLMFeatureActionContext = z.object({
  contextType: z.literal("action"),
  entityTypeName: ReadableString,
  actionName: ReadableString,
});
export type ILLMFeatureTransitionContext = z.infer<typeof LLMFeatureActionContext>;

export const LLMFeatureRelationContext = z.object({
  contextType: z.literal("relation"),
  entityTypeName: ReadableString,
  relationName: ReadableString,
});
export type ILLMFeatureRelationContext = z.infer<typeof LLMFeatureRelationContext>;

export const LLMFeatureColumnContext = z.object({
  contextType: z.literal("column"),
  entityTypeName: ReadableString,
  columnName: ReadableString,
});
export type ILLMFeatureColumnContext = z.infer<typeof LLMFeatureColumnContext>;

export const LLMFeatureContext = z.discriminatedUnion("contextType", [
  LLMFeatureWorkspaceContext,
  LLMFeatureEntityTypeContext,
  LLMFeatureStateContext,
  LLMFeatureActionContext,
  LLMFeatureRelationContext,
  LLMFeatureColumnContext,
]);
export type ILLMFeatureContext = z.infer<typeof LLMFeatureContext>;
