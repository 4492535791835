import type { IComponentDefinitionId, ILayoutConfigurationId, ISlotId } from "@archetype/ids";

import { AbstractError } from "./AbstractError";

export class ModuleConfigFromOtherComponentsNotCompatible extends AbstractError<"ModuleConfigFromOtherComponentsNotCompatible"> {
  constructor({
    componentId,
    layoutConfigurationId,
    slotId,
  }: {
    componentId: IComponentDefinitionId;
    layoutConfigurationId: ILayoutConfigurationId;
    slotId: ISlotId;
  }) {
    super({
      name: "ModuleConfigFromOtherComponentsNotCompatible",
      message: `Implied layout configuration ${layoutConfigurationId} for component ${componentId} in slot ${slotId} is not compatible with existing layout configuration`,
    });
  }
}
