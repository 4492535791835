import type { IColumnId, IEntityId, IUserId } from "@archetype/ids";
import { type IReadableString, keyByNoUndefined } from "@archetype/utils";
import { once } from "lodash";

import type { IActionDefinition } from "../../schemas/dataModel/Action";
import type { IEntity } from "../../schemas/dataModel/Entity";
import type { IEntityType, IEntityTypeCore } from "../../schemas/dataModel/EntityType";
import type { IRawEntity } from "../../schemas/dataModel/RawEntity";
import { computeColumnViewFieldId, createColumnViewField } from "../../schemas/dataModel/ViewField";

export const USER_ENTITY_NAME = "Person" as "Person" & IReadableString;
const USER_ENTITY_PLURAL_NAME = "People" as "People" & IReadableString;

export const USER_ENTITY_ID_COLUMN_ID = "col_pyBOZWIZ4zlq" as IColumnId;
const USER_ENTITY_ID_COLUMN_NAME = "Person ID" as "Person ID" & IReadableString;

export const USER_NAME_COLUMN_ID = "col_iA5bCBnsoRM4" as IColumnId;
export const USER_NAME_VIEW_FIELD_ID = computeColumnViewFieldId(USER_NAME_COLUMN_ID);
const USER_NAME_COLUMN_NAME = "Full name" as "Full name" & IReadableString;

const USER_EMAIL_COLUMN_NAME = "Email" as "Email" & IReadableString;

export const USER_EMAIL_COLUMN_ID = "col_PA8JdiAP7u1z" as IColumnId;
export const USER_EMAIL_VIEW_FIELD_ID = computeColumnViewFieldId(USER_EMAIL_COLUMN_ID);

export const USER_ACTIVE_EMPLOYEE_COLUMN_NAME = "Active employee" as "Active employee" & IReadableString;

export const USER_ACTIVE_EMPLOYEE_COLUMN_ID = "col_MGo5brzvV6dr" as IColumnId;

const USER_LAST_INVITED_AT_COLUMN_NAME = "Last invited at" as "Last invited at" & IReadableString;

export const USER_LAST_INVITED_AT_COLUMN_ID = "col_7zmrEdBTh13P" as IColumnId;

const USER_ACTIVE_COLUMN_NAME = "Active user" as "Active user" & IReadableString;

export const USER_ACTIVE_COLUMN_ID = "col_CFuI99Gyhofx" as IColumnId;
export const USER_ACTIVE_VIEW_FIELD_ID = computeColumnViewFieldId(USER_ACTIVE_COLUMN_ID);

/**
 * Should the userId be a column here? If it is then how to we move data to it for existing entity types?
 * One way could be a special case in migration to "create from other" column (the pk)
 * Otherwise should we store it in a separate auth mapping table of userId<>org<>userEntityId ?
 * That's actually not bad because we can load that table when setting the context and it's quite easy to use?
 */

/**
 * THIS MUST BE INCREMENTED WHENEVER WE CHANGE THE USER ENTITY TYPE in the code
 * so that we can modify existing user entity types to contain this.
 * Note changes are currently additive only, if you delete a column from this BASE_USER_TYPE, it will not be deleted
 * from existing user entity types.
 */
export const BASE_USER_ENTITY_TYPE_VERSION = 2;

export const BASE_USER_TYPE: Omit<
  IEntityType,
  | "id"
  | "organizationId"
  | "versionId"
  | "computedFrom"
  | "authorizedByAnyOf"
  | "activityLogAuthorizedByAnyOf"
  | "authorizedByAnyOfPerStateId"
  | "updatedAt"
  | "version"
  | "supportActionsInfo"
  | "deleteActionId"
> = {
  userEntityTypeInfo: {
    baseUserEntityTypeVersion: BASE_USER_ENTITY_TYPE_VERSION,
  },
  basedOn: null,
  originVersionId: null,
  statusColumn: null,
  primaryKey: USER_ENTITY_ID_COLUMN_ID,
  displayNameColumn: USER_NAME_COLUMN_ID,
  displayMetadata: {
    name: USER_ENTITY_NAME,
    pluralName: USER_ENTITY_PLURAL_NAME,
  },
  color: "neutral",
  shape: "circle",
  columns: [
    {
      id: USER_ENTITY_ID_COLUMN_ID,
      displayMetadata: {
        name: USER_ENTITY_ID_COLUMN_NAME,
      },
      columnType: { type: "shortText" },
      nonNullable: true,
      unique: true,
    },
    {
      id: USER_NAME_COLUMN_ID,
      displayMetadata: {
        name: USER_NAME_COLUMN_NAME,
      },
      columnType: { type: "shortText" },
      nonNullable: true,
      unique: false,
    },
    {
      // TODO validations - when cross field validations available, we need to add a validation that cant edit email is Active is true
      id: USER_EMAIL_COLUMN_ID,
      displayMetadata: {
        name: USER_EMAIL_COLUMN_NAME,
      },
      columnType: { type: "email" },
      nonNullable: false,
      unique: true,
    },
    {
      id: USER_ACTIVE_EMPLOYEE_COLUMN_ID,
      displayMetadata: {
        name: USER_ACTIVE_EMPLOYEE_COLUMN_NAME,
      },
      columnType: { type: "boolean" },
      nonNullable: false,
      unique: false,
    },
    {
      id: USER_LAST_INVITED_AT_COLUMN_ID,
      displayMetadata: {
        name: USER_LAST_INVITED_AT_COLUMN_NAME,
      },
      columnType: { type: "timestamp" },
      nonNullable: false,
      unique: false,
    },
    {
      id: USER_ACTIVE_COLUMN_ID,
      displayMetadata: {
        name: USER_ACTIVE_COLUMN_NAME,
      },
      columnType: { type: "boolean" },
      nonNullable: false,
      unique: false,
    },
  ],
  relevantViewFieldsByStateId: null,
  targetEntityTypeApplicationGroupId: null,
};

// TODO validations - action should only be executable if the user is not currently active
export const getUserInviteActionDefinition = once(
  (): Omit<IActionDefinition, "authorizedByAnyOf"> => ({
    actionType: "modify",
    fromStates: undefined,
    toState: undefined,
    inputs: [
      {
        viewField: createColumnViewField(USER_LAST_INVITED_AT_COLUMN_ID),
        defaultValue: {
          type: "computed",
          value: "now",
        },
        allowChangingDefault: false,
        required: true,
      },
    ],
    contextualFields: [],
    sideEffects: {
      email: {
        isEnabled: true,
        toPersonRelation: null,
        toSelfIfUserEntity: true,
        viewFieldsToSend: [],
      },
    },
    authorizedForAnyoneWithLink: false,
  }),
);

const USER_SYNCED_ONLY_COLUMNS = [USER_ACTIVE_COLUMN_ID];

export const NON_USER_EDITABLE_USER_COLUMNS = USER_SYNCED_ONLY_COLUMNS.concat([USER_LAST_INVITED_AT_COLUMN_ID]);

export const getNonUserEditableUserColumnsSet = once(() => new Set(NON_USER_EDITABLE_USER_COLUMNS));

export const getUserEntityTypeColumnsSet = once(() => new Set(BASE_USER_TYPE.columns.map((c) => c.id)));

export const createUserEntity = ({
  entityId,
  entityType,
  userName,
  email,
  isClerkUserActive,
  isActiveEmployee,
}: {
  userId: IUserId;
  entityId: IEntityId;
  entityType: IEntityType;
  userName: string;
  email: string | undefined;
  isClerkUserActive: boolean;
  /**
   * Will not change the column value if there is no value
   */
  isActiveEmployee: boolean | undefined;
}): IRawEntity => {
  const rawEntity: IRawEntity = {
    entityId,
    entityTypeId: entityType.id,
    entityTypeVersionId: entityType.versionId,
    primaryKey: {
      type: "string",
      value: entityId,
    },
    columns: {
      [USER_ENTITY_ID_COLUMN_ID]: {
        type: "string",
        value: entityId,
      },
      [USER_NAME_COLUMN_ID]: {
        type: "string",
        value: userName,
      },
      [USER_EMAIL_COLUMN_ID]:
        email != null
          ? {
              type: "string",
              value: email,
            }
          : { type: "null" },
      [USER_ACTIVE_COLUMN_ID]: {
        type: "boolean",
        value: isClerkUserActive,
      },
    },
    displayName: userName,
  };

  // Only set if the
  if (isActiveEmployee != null) {
    rawEntity.columns[USER_ACTIVE_EMPLOYEE_COLUMN_ID] = {
      type: "boolean",
      value: isActiveEmployee,
    };
  }

  return rawEntity;
};

export const getUserEmail = (user: IEntity): string | null => {
  const emailFieldId = computeColumnViewFieldId(USER_EMAIL_COLUMN_ID);

  const email = user.fields[emailFieldId];

  if (email == null) {
    throw new Error(`User ${user.entityId} has no email`);
  }
  if (email.type === "string") {
    return email.value;
  }
  if (email.type === "null") {
    return null;
  }

  throw new Error(`User ${user.entityId} has no email`);
};

export const getUserEmailFromEntity = (user: IEntity): string | null => {
  const email = user.fields[computeColumnViewFieldId(USER_EMAIL_COLUMN_ID)];

  if (email == null) {
    throw new Error(`User ${user.entityId} has no email`);
  }
  if (email.type === "string") {
    return email.value;
  }
  if (email.type === "null") {
    return null;
  }

  throw new Error(`User ${user.entityId} has no email`);
};

export const isUserActiveFromEntity = (user: IEntity): boolean => {
  const activeFieldId = computeColumnViewFieldId(USER_ACTIVE_COLUMN_ID);
  const active = user.fields[activeFieldId];

  if (active == null) {
    throw new Error(`User ${user.entityId} has no active field`);
  }

  return active.type === "boolean" && active.value;
};

export const isUserInvitedFromEntity = (user: IEntity): boolean => {
  const lastInvitedAtFieldId = computeColumnViewFieldId(USER_LAST_INVITED_AT_COLUMN_ID);
  const lastInvitedAt = user.fields[lastInvitedAtFieldId];

  return lastInvitedAt != null;
};

/**
 * Noop if not a user entity type, or if the user entity type is already up to date
 */
export const upgradeUserEntityTypeCoreIfNeeded = (existingUserEntityType: IEntityTypeCore): IEntityTypeCore => {
  if (existingUserEntityType.userEntityTypeInfo == null) {
    // Not a user entity type, noop
    return existingUserEntityType;
  }

  if (
    existingUserEntityType.userEntityTypeInfo.baseUserEntityTypeVersion ===
    BASE_USER_TYPE.userEntityTypeInfo?.baseUserEntityTypeVersion
  ) {
    // No need to upgrade, already on latest version
    return existingUserEntityType;
  }

  const existingUserEntityTypeColumnIdsSet = new Set(existingUserEntityType.columns.map((c) => c.id));
  const baseUserColumnsById = keyByNoUndefined(BASE_USER_TYPE.columns, (c) => c.id);

  // Keeping order of existing entity type stable, but overriding columns already existing columns with new version
  const orderedColumns = existingUserEntityType.columns
    .map((c) => baseUserColumnsById[c.id] ?? c)
    .concat(BASE_USER_TYPE.columns.filter((c) => !existingUserEntityTypeColumnIdsSet.has(c.id)));

  const newUserEntityTypeCore: IEntityTypeCore = {
    ...existingUserEntityType,
    userEntityTypeInfo: BASE_USER_TYPE.userEntityTypeInfo,
    columns: orderedColumns,
  };

  return newUserEntityTypeCore;
};
