import type { IColumn, IDisplayMetadata, IStructuredFeature, IViewFeature, IViewField } from "@archetype/dsl";
import { isColumnViewField, NestedViewFeature } from "@archetype/dsl";
import type { IColumnId, IEntityId, IEntityTypeId, IFeatureId, IOrganizationId } from "@archetype/ids";
import { forEach, pickBy } from "@archetype/utils";
import { camelCase } from "lodash";

import type { IFieldComputationInfo } from "../apiTypes/LoadedEntity";
import type { INewColumnType } from "./inferrenceTypings";

export const FIXED_COLUMN_UUID_NAMESPACES = "d3b5cc0c-1b1a-4c1c-b9be-e99471f55938";
export const FIXED_COLUMN_TYPE_UUID_NAMESPACES = "1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed";

const getReferencedParentFeatureIds = (features: Record<IFeatureId, IStructuredFeature>): Set<IFeatureId> => {
  const parentFeatureIds: Set<IFeatureId> = new Set();

  forEach(features, (feature) => {
    const parsedNestedFeature = NestedViewFeature.safeParse(feature);

    if (parsedNestedFeature.success) {
      parentFeatureIds.add(parsedNestedFeature.data.parent);
    }
  });

  return parentFeatureIds;
};

export const filterExistingFeaturesRelevantForNewOnes = (
  newFeatures: Record<string, IViewFeature>,
  allExistingFeatures: Record<IFeatureId, IViewFeature>,
): Record<IFeatureId, IViewFeature> => {
  // This can contain both new and existing feature ids, we care only about existing ones though
  const newFeaturesParentFeatureIds = getReferencedParentFeatureIds(newFeatures);

  const relevantExistingFeatures: Record<IFeatureId, IViewFeature> = pickBy(
    allExistingFeatures,
    // Keeping only features that are referenced by the new ones, and features that are not in the newFeatures as an override
    (feature) => newFeaturesParentFeatureIds.has(feature.id) && newFeatures[feature.id] == null,
  );

  return relevantExistingFeatures;
};

export function deprecatedOptimisticReadableIdentifier(val: { displayMetadata: IDisplayMetadata }): string {
  return val.displayMetadata.name;
}

export function optimisticReadableIdentifier(val: { displayMetadata: IDisplayMetadata }): string {
  return optimisticReadableIdentifierFromString(val.displayMetadata.name);
}

export function optimisticReadableIdentifierFromString(str: string): string {
  // Could make it more sophisticated, but probably will mean more loss of information so we'd need to track the original string
  return camelCase(str).toLowerCase();
}

export interface IGetFixedColumnResult {
  columnId: IColumnId;
  createdColumn: IColumn;
  createdColumnType: INewColumnType;
}

export const isColumnViewFieldAIDefaultValue = (
  viewField: IViewField,
  defaultValue: string | null,
  computationStatus: IFieldComputationInfo,
): boolean =>
  defaultValue == null &&
  isColumnViewField(viewField) &&
  computationStatus.kind === "aiComputed" &&
  computationStatus.status !== "userEdited";

export const getEntityUrl = ({
  organizationId,
  entityTypeId,
  entityId,
}: {
  organizationId: IOrganizationId;
  entityTypeId: IEntityTypeId;
  entityId: IEntityId;
}): string => `${process.env.BASE_URL ?? ""}/${organizationId}/workspace/prod/app/${entityTypeId}/entity/${entityId}`;
