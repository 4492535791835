import type { IColumnId, IRelationId, IViewFieldId } from "@archetype/ids";
import { ColumnId, RelationId, ViewFieldId } from "@archetype/ids";
import * as z from "zod";

import type { IRelationDirection } from "./RelationBase";
import { RelationDirection } from "./RelationBase";

// storing type
export const StoredColumnViewField = z.object({
  type: z.literal("column"),
  columnId: ColumnId,
});
export type IStoredColumnViewField = z.infer<typeof StoredColumnViewField>;

export const StoredRelationViewField = z.object({
  type: z.literal("directionalRelation"),
  relationId: RelationId,
  direction: RelationDirection,
});
export type IStoredRelationViewField = z.infer<typeof StoredRelationViewField>;

export const StoredViewField = z.discriminatedUnion("type", [StoredColumnViewField, StoredRelationViewField]);
export type IStoredViewField = z.infer<typeof StoredViewField>;

// used type
export const ColumnViewField = StoredColumnViewField.merge(
  z.object({
    id: ViewFieldId,
  }),
);
export type IColumnViewField = z.infer<typeof ColumnViewField>;

export const RelationViewField = StoredRelationViewField.merge(
  z.object({
    id: ViewFieldId,
  }),
);
export type IRelationViewField = z.infer<typeof RelationViewField>;

export const ViewField = z.discriminatedUnion("type", [ColumnViewField, RelationViewField]);
export type IViewField = z.infer<typeof ViewField>;

// helper methods
// todo alon: maybe we need another type here
export const isColumnViewField = (viewField: { type: IViewField["type"] }): viewField is IColumnViewField =>
  viewField.type === "column";
export const isRelationViewField = (viewField: { type: IViewField["type"] }): viewField is IRelationViewField =>
  viewField.type === "directionalRelation";

export const computeColumnViewFieldId = (columnId: IColumnId): IViewFieldId => {
  return `column-${columnId}` as IViewFieldId;
};

export const computeRelationViewFieldId = (relationId: IRelationId, direction: IRelationDirection): IViewFieldId => {
  return `relation-${relationId}-${direction}` as IViewFieldId;
};

export const computeViewFieldId = (viewField: IStoredViewField): IViewFieldId => {
  if (isColumnViewField(viewField)) {
    return computeColumnViewFieldId(viewField.columnId);
  } else if (isRelationViewField(viewField)) {
    return computeRelationViewFieldId(viewField.relationId, viewField.direction);
  }

  throw new Error("Unknown view field type");
};

// constructor methods
export const createColumnViewField = (columnId: IColumnId): IColumnViewField => {
  return {
    id: computeColumnViewFieldId(columnId),
    type: "column",
    columnId,
  };
};
export const createRelationViewField = (relationId: IRelationId, direction: IRelationDirection): IRelationViewField => {
  return {
    id: computeRelationViewFieldId(relationId, direction),
    type: "directionalRelation",
    relationId,
    direction,
  };
};
export const createViewField = (storedViewField: IStoredViewField): IViewField => {
  if (isColumnViewField(storedViewField)) {
    return createColumnViewField(storedViewField.columnId);
  } else if (isRelationViewField(storedViewField)) {
    return createRelationViewField(storedViewField.relationId, storedViewField.direction);
  }
  throw new Error("Unknown view field type");
};
