import type { ILoadedRelationViewField } from "@archetype/core";
import type {
  IColumn,
  IDataLoadingFilterAndOperator,
  IDataLoadingSimpleEntityFilter,
  IDataLoadingSimpleRelationFilter,
  IEntityTypeCore,
  IVersionType,
} from "@archetype/dsl";
import type { IColumnId, IEntityTypeId } from "@archetype/ids";
import { Icon, ShapeColorIcon } from "@archetype/ui";
import { Switch } from "@archetype/ui";
import { isNonNullable } from "@archetype/utils";
import { useCallback, useMemo, useState } from "react";
import { match, P } from "ts-pattern";
import { v4 } from "uuid";

import { AI_FILTER_COLUMN_ID, GLOBAL_SEARCH__FILTER_COLUMN_ID, NON_FILTERABLE_COLUMN_TYPES } from "../constants";
import type { IAddAIFiltersFn } from "../hooks/useAddAIFilters";
import type { IAddOrUpdateFilterFn } from "../hooks/useSimpleFiltersActions";
import { getColumnTypeIcon } from "../utils/getColumnTypeIcon";
import { getRelationViewMetadata } from "../utils/getRelationViewMetadata";
import { CommandWithAIOption } from "./CommandWithAIOption";
import { DateInputFilter } from "./DateInputFilter";
import { ListFilter } from "./ListFilter";
import { NumberInputFilter } from "./NumberInputFilter";
import { RelationFilter } from "./RelationFilter";
import { TextInputFilter } from "./TextInputFilter";
import type { ICommandOption, IFilterColumnId } from "./types";

interface IFilterDropdown {
  filter?: IDataLoadingSimpleEntityFilter;
  columns: IColumn[];
  onAddOrUpdateFilter: IAddOrUpdateFilterFn;
  onAddAIFilters: IAddAIFiltersFn;
  entityType: IEntityTypeCore;
  allEntityTypes: Record<IEntityTypeId, IEntityTypeCore | undefined>;
  relationViews: ILoadedRelationViewField[];
  statusColumnId?: IColumnId;
  requestPopoverClose?: () => void;
  versionType: IVersionType;
}

export const FilterDropdown: React.FC<IFilterDropdown> = (props) => {
  const {
    allEntityTypes,
    columns,
    entityType,
    filter,
    onAddAIFilters: handleAddAIFilters,
    onAddOrUpdateFilter,
    relationViews,
    requestPopoverClose,
    statusColumnId,
    versionType,
  } = props;

  const [selectedOptionId, setSelectedOptionId] = useState<IFilterColumnId | undefined>(() => {
    if (filter?.type === "column") return filter.colId;
    if (filter?.type === "relation") return filter.relationId;
    if (filter?.type === "search") return GLOBAL_SEARCH__FILTER_COLUMN_ID;

    return undefined;
  });

  const topLevelOptions = useMemo<ICommandOption[]>(() => {
    const statusColumn = columns.find((col) => col.id === statusColumnId);

    const columnOptions = columns
      .filter((c) => c !== statusColumn && !c.unique && !NON_FILTERABLE_COLUMN_TYPES.includes(c.columnType.type))
      .map<ICommandOption>((c) => ({
        id: c.id,
        icon: getColumnTypeIcon(c.columnType.type),
        value: c.displayMetadata.name,
      }))
      .sort((a, b) => ("value" in a && "value" in b ? a.value.localeCompare(b.value) : 0));

    const relationOptions = relationViews
      .map<ICommandOption | null>((rel) => {
        const metadata = getRelationViewMetadata(rel, allEntityTypes);

        if (metadata == null) return null;

        return {
          id: rel.relation.id,
          icon: <ShapeColorIcon color={metadata.iconColor} shape={metadata.iconShape} size="sm" />,
          value: metadata.name,
        };
      })
      .filter(isNonNullable)
      .sort((a, b) => {
        if (!("component" in a) || !("component" in b)) return 0;

        if (typeof a.component === "string" && typeof b.component === "string") {
          return a.component.localeCompare(b.component);
        }

        return 0;
      });

    const options: (ICommandOption | null)[] = [
      { id: v4(), isSeparator: true },
      { id: GLOBAL_SEARCH__FILTER_COLUMN_ID, value: "Anything", icon: "scan-search" },

      statusColumn
        ? { id: statusColumn.id, value: statusColumn.displayMetadata.name, icon: getColumnTypeIcon("statusEnum") }
        : null,

      { id: v4(), isSeparator: true },
      ...columnOptions,
      { id: v4(), isSeparator: true },
      ...relationOptions,
    ];

    return options.filter(isNonNullable);
  }, [allEntityTypes, columns, relationViews, statusColumnId]);

  const handleColumnFilterSubmit = useCallback(
    (colId: IFilterColumnId) =>
      (operator: IDataLoadingFilterAndOperator, value: string[], closePopover: boolean = true): void => {
        onAddOrUpdateFilter({ type: "column", colId: colId as IColumnId, op: operator, value });

        if (closePopover) requestPopoverClose?.();
      },
    [onAddOrUpdateFilter, requestPopoverClose],
  );

  const handleGlobalSearchSubmit = useCallback(
    (value: string): void => {
      onAddOrUpdateFilter({ type: "search", value: [value] });
      requestPopoverClose?.();
    },
    [onAddOrUpdateFilter, requestPopoverClose],
  );

  const handleRelationFilterSubmit = useCallback(
    (data: IDataLoadingSimpleRelationFilter, closePopover: boolean = true): void => {
      onAddOrUpdateFilter(data);

      if (closePopover) requestPopoverClose?.();
    },
    [onAddOrUpdateFilter, requestPopoverClose],
  );

  const handleSelectOption = useCallback(
    (id: IFilterColumnId) => {
      const column = columns.find((col) => col.id === id);

      if (column?.columnType.type === "boolean") {
        onAddOrUpdateFilter({ type: "column", colId: id as IColumnId, op: "eq", value: ["true"] });
        requestPopoverClose?.();

        return;
      }

      setSelectedOptionId(id);
    },
    [columns, onAddOrUpdateFilter, requestPopoverClose],
  );

  if (selectedOptionId === undefined) {
    return (
      <CommandWithAIOption<IFilterColumnId>
        aiOptionId={AI_FILTER_COLUMN_ID}
        aiOptionLabel="AI Filter"
        options={topLevelOptions}
        onSelectAIOption={handleAddAIFilters}
        onSelectOption={handleSelectOption}
      />
    );
  }

  if (selectedOptionId === AI_FILTER_COLUMN_ID) {
    return (
      <TextInputFilter
        submitAsString
        className="min-w-[350px]"
        defaultValue={filter?.value[0]}
        iconLeft="sparkles"
        placeholder="Describe the filters you want to apply..."
        onSubmit={handleAddAIFilters}
      />
    );
  }

  if (selectedOptionId === GLOBAL_SEARCH__FILTER_COLUMN_ID) {
    return (
      <TextInputFilter
        submitAsString
        defaultValue={filter?.value[0]}
        iconLeft="scan-search"
        placeholder="Search all fields for..."
        onSubmit={handleGlobalSearchSubmit}
      />
    );
  }

  const relationView = relationViews.find((rel) => rel.relation.id === selectedOptionId);

  if (relationView !== undefined) {
    const { entityTypeIdA, entityTypeIdB } = relationView.relation;

    const otherEntityType = allEntityTypes[relationView.direction === "aToB" ? entityTypeIdB : entityTypeIdA];

    if (otherEntityType === undefined) return null;

    return (
      <RelationFilter
        allEntityTypes={allEntityTypes}
        defaultSelection={filter?.value.filter(isNonNullable) ?? []}
        entityType={entityType}
        operator={filter?.op}
        otherEntityType={otherEntityType}
        relationView={relationView}
        versionType={versionType}
        onSubmit={handleRelationFilterSubmit}
      />
    );
  }

  const column = columns.find((col) => col.id === selectedOptionId);

  if (column === undefined) return null;

  return match(column.columnType.type)
    .with(P.union("shortText", "longText", "phone", "email", "url"), (colType) => {
      const type = { shortText: "text", longText: "text", phone: "tel", email: "email", url: "url" }[colType];

      return (
        <TextInputFilter
          defaultValue={filter?.value[0]}
          iconLeft={getColumnTypeIcon(colType)}
          operator={filter?.op}
          placeholder={`Filter ${column.displayMetadata.name} by...`}
          type={type}
          onSubmit={handleColumnFilterSubmit(selectedOptionId)}
        />
      );
    })
    .with("number", (colType) => (
      <NumberInputFilter
        defaultValue={filter?.value[0]}
        iconLeft={getColumnTypeIcon(colType)}
        operator={filter?.op}
        placeholder={`Filter ${column.displayMetadata.name} by...`}
        onSubmit={handleColumnFilterSubmit(selectedOptionId)}
      />
    ))
    .with(P.union("date", "timestamp"), (type) => (
      <DateInputFilter
        canEditTime={type === "timestamp"}
        defaultValue={filter?.value[0]}
        operator={filter?.op}
        title={column.displayMetadata.name}
        onSubmit={handleColumnFilterSubmit(selectedOptionId)}
      />
    ))
    .with(P.union("enum", "statusEnum"), (type) => (
      <ListFilter
        allowMultiSelect={shouldAllowMultiSelectForColumn(column)}
        defaultSelection={filter?.value.filter(isNonNullable)}
        groups={[{ id: "options", options: listOptionsForColumn(column) }]}
        icon={<Icon className="mr-0 opacity-50" name={getColumnTypeIcon(column.columnType.type)} />}
        operator={type === "statusEnum" ? "eq" : filter?.op}
        title={column.displayMetadata.name}
        onSubmit={handleColumnFilterSubmit(selectedOptionId)}
      />
    ))
    .otherwise(() => null);
};

const listOptionsForColumn = (column: IColumn): ICommandOption[] => {
  return match(column)
    .with(
      { columnType: { type: "enum" } },
      (c) => c.columnType.enumAllowedValues?.map((value) => ({ id: value, value: value })) ?? [],
    )
    .with({ columnType: { type: "statusEnum" } }, (c) =>
      c.columnType.allowedValues.map((value) => ({ id: value.id, value: value.readableValue })),
    )
    .with({ columnType: { type: "boolean" } }, () => [
      { id: "true", value: "true", component: <Switch checked small /> },
      { id: "false", value: "false", component: <Switch small checked={false} /> },
    ])
    .otherwise(() => []);
};

const shouldAllowMultiSelectForColumn = (column: IColumn): boolean => {
  return match(column.columnType.type)
    .with("boolean", () => false)
    .otherwise(() => true);
};
