import { ActionId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { ActionKind } from "../dataModel/Action";

export const LLMActionInput = z.object({
  fieldName: ReadableString,
  allowChangingDefault: z.boolean(),
  isOptional: z.boolean(),
});
export type ILLMActionInput = z.infer<typeof LLMActionInput>;

export const LLMAction = z.object({
  actionId: ActionId,
  name: ReadableString,
  description: ReadableString.nullable(),
  actionType: ActionKind,
  inputs: z.array(LLMActionInput), // TODO: add relations
  contextualFieldNames: z.array(ReadableString),
  emailConfig: z
    .object({
      isEnabled: z.boolean(),
      toPersonRelationName: ReadableString,
      fieldNamesToSend: z.array(ReadableString),
    })
    .optional(),
  // TODO: add authorizations once we support that in features
});
export type ILLMAction = z.infer<typeof LLMAction>;
