import type { IEntityType, IRelation } from "@archetype/dsl";
import type { IEntityTypeId, IRelationId, IStateId } from "@archetype/ids";
import { forEach } from "@archetype/utils";

import type { IResolvedRelationTraversal } from "../../relationPath";
import { resolvePathNode } from "../../relationPath";
import type { IAuthorizationsByEntityScope, ILoadedAuthorizingPath, ILoadedRelevantAuthorizations } from "../types";

export const resolveAuthorizationPath = ({
  path,
  relationsById,
  entityTypesById,
}: {
  path: { relationId: IRelationId; direction: "aToB" | "bToA" }[];
  relationsById: Record<IRelationId, IRelation>;
  entityTypesById: Record<IEntityTypeId, IEntityType>;
}): Array<IResolvedRelationTraversal> | undefined => {
  const resolvedPath: Array<IResolvedRelationTraversal | undefined> = [];

  path.forEach((step) => {
    const resolvedNode = resolvePathNode(relationsById, entityTypesById, step);

    if (resolvedNode == null) {
      resolvedPath.push(undefined);

      return;
    }

    // Check that this node connects to the previous node
    if (resolvedPath.length > 0) {
      const prevNode = resolvedPath[resolvedPath.length - 1];

      if (
        prevNode == null ||
        prevNode.toEntityType.id !==
          (step.direction === "aToB" ? resolvedNode.relation.entityTypeIdA : resolvedNode.relation.entityTypeIdB)
      ) {
        resolvedPath.push(undefined);

        return;
      }
    }

    resolvedPath.push(resolvedNode);
  });

  if (resolvedPath.some((node) => node == null)) {
    return undefined;
  }

  return resolvedPath as Array<IResolvedRelationTraversal>;
};

export const resolveAuthorizationPaths = ({
  authorizingPaths,
  relationsById,
  entityTypesById,
  retrievalEntityTypesById,
}: {
  authorizingPaths: IAuthorizationsByEntityScope;
  relationsById: Record<IRelationId, IRelation>;
  entityTypesById: Record<IEntityTypeId, IEntityType>;
  retrievalEntityTypesById: Record<IEntityTypeId, IEntityType | undefined>;
}): ILoadedRelevantAuthorizations => {
  const loadedAnyState: ILoadedAuthorizingPath[] = [];
  const loadedComments: ILoadedAuthorizingPath[] = [];
  const loadedByStateId: Record<IStateId, ILoadedAuthorizingPath[]> = {};

  authorizingPaths.anyState.forEach(({ path, userTypeFilters, startingEntityType }) => {
    const resolvedPath = resolveAuthorizationPath({
      path,
      relationsById,
      entityTypesById,
    });

    let resolvedStartingEntityType: IEntityType | undefined;

    if (startingEntityType != null) {
      resolvedStartingEntityType = entityTypesById[startingEntityType.id];
      if (resolvedStartingEntityType == null) {
        throw new Error(`starting entity type ${startingEntityType.id} not found in entity types by id`);
      }
    }

    if (resolvedPath != null) {
      loadedAnyState.push({
        path: resolvedPath,
        userTypeFilters,
        startingEntityType:
          resolvedStartingEntityType == null
            ? undefined
            : {
                entityType: resolvedStartingEntityType,
                retrievalEntityType: retrievalEntityTypesById[resolvedStartingEntityType.id],
                filters: startingEntityType?.filters ?? {},
              },
      });
    }
  });
  authorizingPaths.activityLog.forEach(({ path, userTypeFilters, startingEntityType }) => {
    const resolvedPath = resolveAuthorizationPath({
      path,
      relationsById,
      entityTypesById,
    });

    let resolvedStartingEntityType: IEntityType | undefined;

    if (startingEntityType != null) {
      resolvedStartingEntityType = entityTypesById[startingEntityType.id];
      if (resolvedStartingEntityType == null) {
        throw new Error(`starting entity type ${startingEntityType.id} not found in entity types by id`);
      }
    }

    if (resolvedPath != null) {
      loadedComments.push({
        path: resolvedPath,
        userTypeFilters,
        startingEntityType:
          resolvedStartingEntityType == null
            ? undefined
            : {
                entityType: resolvedStartingEntityType,
                retrievalEntityType: retrievalEntityTypesById[resolvedStartingEntityType.id],
                filters: startingEntityType?.filters ?? {},
              },
      });
    }
  });

  forEach(authorizingPaths.byStateId, (byStateIdPaths, stateId) => {
    byStateIdPaths.forEach(({ path, userTypeFilters, startingEntityType }) => {
      const resolvedPath = resolveAuthorizationPath({
        path,
        relationsById,
        entityTypesById,
      });

      if (resolvedPath == null) {
        return;
      }

      let resolvedStartingEntityType: IEntityType | undefined;

      if (startingEntityType != null) {
        resolvedStartingEntityType = entityTypesById[startingEntityType.id];
        if (resolvedStartingEntityType == null) {
          throw new Error(`starting entity type ${startingEntityType.id} not found in entity types by id`);
        }
      }

      loadedByStateId[stateId] ??= [];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we know this is an array because of the line above
      loadedByStateId[stateId]!.push({
        path: resolvedPath,
        userTypeFilters,
        startingEntityType:
          resolvedStartingEntityType == null
            ? undefined
            : {
                entityType: resolvedStartingEntityType,
                retrievalEntityType: retrievalEntityTypesById[resolvedStartingEntityType.id],
                filters: startingEntityType?.filters ?? {},
              },
      });
    });
  });

  return {
    anyState: loadedAnyState,
    activityLog: loadedComments,
    byStateId: loadedByStateId,
  };
};
