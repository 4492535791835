import type { IAction, IActionLog, IDisplayMetadata, IEntityType, IRelation } from "@archetype/dsl";
import type { IActionVersionId, IEntityTypeId, IRelationId, IRelationVersionId } from "@archetype/ids";
import { forEach, type IReadableString, keyByNoUndefined, mapValues } from "@archetype/utils";

import type { ILoadedActionLog } from "../apiTypes/LoadedActionLog";
import { convertActionCoreToLoadedAction } from "./actions";

export function makeLoadedActionLogs({
  actionLogs,
  actionsByVersionId,
  relationsByVersionId,
  entityTypesByVersionId,
  defaultEntityEntityType,
  defaultRelationsById,
}: {
  actionLogs: IActionLog[];
  actionsByVersionId: Record<IActionVersionId, IAction>;
  relationsByVersionId: Record<IRelationVersionId, IRelation>;
  entityTypesByVersionId: Record<IEntityTypeId, IEntityType>;
  defaultEntityEntityType: IEntityType;
  defaultRelationsById: Record<IRelationId, IRelation>;
}): ILoadedActionLog[] {
  return actionLogs.map((actionLog): ILoadedActionLog => {
    const action = actionsByVersionId[actionLog.actionVersionId];

    const entityType = entityTypesByVersionId[actionLog.entityTypeId] ?? defaultEntityEntityType;

    const relationsByIdAtVersionId = mapValues(actionLog.relationVersionIds, (relationVersionId, relationId) =>
      relationVersionId != null ? relationsByVersionId[relationVersionId] : defaultRelationsById[relationId],
    );

    // Add defaults to avoid tying how the keys are defined when saving the action log with this
    forEach(defaultRelationsById, (relation, relationId) => {
      if (relationsByIdAtVersionId[relationId] == null) {
        relationsByIdAtVersionId[relationId] = relation;
      }
    });

    const errorDisplayMetadta: IDisplayMetadata = {
      name: "Action not found" as IReadableString,
    };

    const loadedAction =
      action != null
        ? convertActionCoreToLoadedAction(
            action,
            keyByNoUndefined(entityType.columns, (c) => c.id),
            relationsByIdAtVersionId,
            undefined,
          )
        : null;

    return {
      ...actionLog,
      loadedAction,
      actionDisplayMetadata: action?.displayMetadata ?? errorDisplayMetadta,
    };
  });
}
