import type { IEntity, IEntityType, IFileColumnValue, IRelationDirection } from "@archetype/dsl";
import type { IFieldComputationStatus } from "@archetype/dsl";
import type { IFileEntityColumnValue } from "@archetype/dsl";
import { computeColumnViewFieldId, computeRelationViewFieldId } from "@archetype/dsl";
import type { IColumnId, IRelationId } from "@archetype/ids";
import { keyByAndMapValues } from "@archetype/utils";

import type { ILoadedEntity } from "../apiTypes/LoadedEntity";

export interface IComputationFieldIds {
  aiColumnIds: IColumnId[];
  aiRelationIds: IRelationId[];
  derivedColumnIds: IColumnId[];
}

interface IColumnWithStatus {
  columnId: IColumnId;
  status: IFieldComputationStatus;
}

/**
 * Creates a loaded entity with updated fields and computation statuses
 *
 * @param entity - The base entity
 * @param parsedFieldValues - The new field values
 * @param computationStatuses - Object containing computation status information
 * @returns A loaded entity with updated fields and computation statuses
 */
export function createLoadedEntityWithComputations({
  entity,
  parsedFieldValues,
  derivedColumnComputations,
  aiColumnComputations,
  aiRelationComputations,
  overwrittenAIColumnComputations,
  overwrittenAIRelationComputations,
}: {
  entity: IEntity;
  parsedFieldValues: IEntity["fields"];
  derivedColumnComputations: IColumnWithStatus[];
  aiColumnComputations: IColumnWithStatus[];
  aiRelationComputations: Array<{
    relationId: IRelationId;
    direction: IRelationDirection;
    status: IFieldComputationStatus;
  }>;
  overwrittenAIColumnComputations: IColumnId[];
  overwrittenAIRelationComputations: Array<{
    relationId: IRelationId;
    direction: IRelationDirection;
  }>;
}): ILoadedEntity {
  return {
    ...entity,
    fields: {
      ...entity.fields,
      ...parsedFieldValues,
    },
    fieldsComputationStatuses: {
      ...keyByAndMapValues(derivedColumnComputations, (c) => ({
        key: computeColumnViewFieldId(c.columnId),
        value: {
          kind: "derived",
          status: c.status,
        },
      })),
      ...keyByAndMapValues(aiColumnComputations, (c) => ({
        key: computeColumnViewFieldId(c.columnId),
        value: {
          kind: "aiComputed",
          status: c.status,
        },
      })),
      ...keyByAndMapValues(aiRelationComputations, (c) => ({
        key: computeRelationViewFieldId(c.relationId, c.direction),
        value: {
          kind: "aiComputed",
          status: c.status,
        },
      })),
      ...keyByAndMapValues(overwrittenAIColumnComputations, (c) => ({
        key: computeColumnViewFieldId(c),
        value: {
          kind: "aiComputed",
          status: "userEdited",
        },
      })),
      ...keyByAndMapValues(overwrittenAIRelationComputations, (c) => ({
        key: computeRelationViewFieldId(c.relationId, c.direction),
        value: {
          kind: "aiComputed",
          status: "userEdited",
        },
      })),
    },
  };
}

/**
 * Identifies files that need to be deleted when updating an entity's file attachments
 *
 * @param entityType - The type definition of the entity
 * @param originalFields - The original entity's fields
 * @param updatedFields - The updated entity's fields
 * @returns Array of file URLs that should be deleted
 */
export function identifyFilesToDelete({
  entityType,
  originalFields,
  updatedFields,
}: {
  entityType: IEntityType;
  originalFields: IEntity["fields"];
  updatedFields: IEntity["fields"];
}): string[] {
  const fileUrlsToDelete: string[] = [];

  entityType.columns.forEach((column) => {
    if (column.columnType.type === "file") {
      const fieldId = computeColumnViewFieldId(column.id);

      const originalFiles = getFileValues(originalFields[fieldId] as IFileColumnValue);
      const updatedFiles = getFileValues(updatedFields[fieldId] as IFileColumnValue);

      if (originalFiles.length > 0) {
        const updatedFilesId = new Set(updatedFiles.map((file) => file.id));

        originalFiles.forEach((file) => {
          if (!updatedFilesId.has(file.id)) {
            fileUrlsToDelete.push(file.fileUrl);
          }
        });
      }
    }
  });

  return fileUrlsToDelete;
}

function getFileValues(field: IFileColumnValue | { type: null }): IFileEntityColumnValue[] {
  return field.type === "file" && "value" in field ? field.value : [];
}
