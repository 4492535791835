import type {
  IColumn,
  IEmailExtractedActionInput,
  IEntityType,
  IEntityTypeCore,
  IOrganizationInfo,
  IViewFieldValue,
} from "@archetype/dsl";
import type { IEntityTypeId, IViewFieldId } from "@archetype/ids";
import type { IReadableString } from "@archetype/utils";
import { isNonNullable, keyByNoUndefined, mapValues, pickBy } from "@archetype/utils";
import { kebabCase, values } from "lodash";

import { convertValueToViewFieldValue } from "../action/llmValueConversion";
import type { ILoadedAction } from "../apiTypes/LoadedActionType";
import type { ILoadedEntityType } from "../apiTypes/LoadedEntityType";
import { createFileLogger } from "../logger";

const logger = createFileLogger("emailActionArguments.ts");

export interface IWorkflowMetadataContext {
  actions: ILoadedAction[];
  entityType: IEntityType;
  organizationInfo: IOrganizationInfo;
}

export interface IWorkflowDescription {
  id: IEntityTypeId;
  entityType: IEntityType;
  actions: ILoadedAction[];
  entityName: string;
  description: string;
  organizationInfo: IOrganizationInfo;
}

export const buildWorkflowDescription = ({
  actions,
  entityType,
  organizationInfo,
}: IWorkflowMetadataContext): IWorkflowDescription => ({
  id: entityType.id,
  actions,
  entityType,
  entityName: entityType.displayMetadata.name,
  description: entityType.displayMetadata.description ?? "",
  organizationInfo,
});

export const castColumnActionInputs = (
  entityType: IEntityTypeCore,
  action: ILoadedAction,
  actionArgs: Record<IViewFieldId, { type: "found"; value: string } | { type: "not found" }>,
): Partial<Record<IViewFieldId, IViewFieldValue>> => {
  const columnsById = keyByNoUndefined(entityType.columns, (column) => column.id);
  const columnsByReadableId = keyByNoUndefined(entityType.columns, (column) => kebabCase(column.displayMetadata.name));
  const inputsByViewFieldId = keyByNoUndefined(action.actionDefinition.inputs, (input) => input.viewField.id);

  return pickBy(
    mapValues(actionArgs, (arg, viewFieldId): IViewFieldValue | undefined => {
      if (arg.type === "not found") {
        return undefined;
      }

      const input = inputsByViewFieldId[viewFieldId];
      const viewField = input?.viewField;

      if (viewField == null || viewField.type === "directionalRelation") {
        return undefined;
      }

      return convertValueToViewFieldValue(columnsById, columnsByReadableId, {
        value: arg.value,
        columnId: viewField.columnId,
      });
    }),
    isNonNullable,
  );
};

interface ITaggedEmailFields {
  from: string;
  subject: string;
  body: string;
}

type IEmailField = IEmailExtractedActionInput["foundInField"];

const getFieldContent = (email: ITaggedEmailFields, field: IEmailField): string => email[field];

const findValueField = (email: ITaggedEmailFields): IEmailField | undefined => {
  const fields: IEmailField[] = ["from", "subject", "body"];
  const fieldsWithValue = fields.filter((field) => getFieldContent(email, field).includes("<value>"));

  const firstField = fieldsWithValue[0];

  if (firstField == null) {
    logger.error("No field with value tag found", { email });

    return undefined;
  }

  if (fieldsWithValue.length > 1) {
    logger.error("Multiple fields with value tag found", { email });

    return undefined;
  }

  return firstField;
};

export const getArgLocation = (
  taggedFrom: string,
  taggedSubject: string,
  taggedBody: string,
):
  | Pick<IEmailExtractedActionInput, "foundInField" | "startCharIndexInclusive" | "endCharIndexExclusive">
  | undefined => {
  const email: ITaggedEmailFields = { from: taggedFrom, subject: taggedSubject, body: taggedBody };
  const foundInField = findValueField(email);

  if (foundInField == null) {
    return undefined;
  }

  const searchText = getFieldContent(email, foundInField);

  const startCharIndexInclusive = searchText.indexOf("<value>");

  if (startCharIndexInclusive === -1) {
    // this should never happen, since this is checked in `findValueField`
    throw new Error("No start char index found");
  }

  const endCharIndexExclusive = searchText.replace("<value>", "").indexOf("</value>");

  if (endCharIndexExclusive === -1) {
    logger.error("No end char index found", { searchText });

    return undefined;
  }

  return {
    foundInField,
    startCharIndexInclusive,
    endCharIndexExclusive,
  };
};

/**
 * Maps entity type columns and relations to a format suitable for email filtering
 */
export const mapEntityTypeColumnsAndRelationsForEmail = (
  entityType: ILoadedEntityType,
): Array<{
  name: IReadableString;
  description: IReadableString;
}> => {
  const columnMappings = values(entityType.columns)
    .filter(isNonNullable)
    .filter((column: IColumn) => column.id !== entityType.statusColumn)
    .map((column: IColumn) => ({
      name: column.displayMetadata.name,
      description: column.displayMetadata.description ?? ("" as IReadableString),
    }));

  const relationMappings = values(entityType.relations)
    .filter(isNonNullable)
    .flatMap((relation) => {
      if (relation.entityTypeIdA !== entityType.id && relation.entityTypeIdB !== entityType.id) {
        return [];
      }

      const mappings: { name: IReadableString; description: IReadableString }[] = [];

      if (relation.entityTypeIdA === entityType.id) {
        mappings.push({
          name: relation.displayMetadataFromAToB.name,
          description: relation.displayMetadataFromAToB.description ?? ("" as IReadableString),
        });
      }
      if (relation.entityTypeIdB === entityType.id) {
        mappings.push({
          name: relation.displayMetadataFromBToA.name,
          description: relation.displayMetadataFromBToA.description ?? ("" as IReadableString),
        });
      }

      return mappings;
    });

  return [...columnMappings, ...relationMappings];
};
