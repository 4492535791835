import type { ILoadedActionInput, ILoadedEntity } from "@archetype/core";
import type {
  IActionCurrentUserInfo,
  IDraftEmailExtractedActionInput,
  IEntityTypeCore,
  IVersionType,
  IViewFieldValue,
} from "@archetype/dsl";
import { isColumnViewField } from "@archetype/dsl";
import type { IActionId, IEntityTypeId, IOrganizationId, IViewFieldId } from "@archetype/ids";
import {
  AutofillIndicator,
  cn,
  ComputingSuggestion,
  DotPinging,
  FormControl,
  FormLabel,
  ShapeColorIcon,
  useMemoDeepCompare,
} from "@archetype/ui";
import { isEqual } from "lodash";
import Link from "next/link";
import { useCallback } from "react";
import type { ControllerRenderProps } from "react-hook-form";

import type { IGetHighlightedViewFieldRoute, IGetLinkedEntityRoute } from "../api";
import { LoadingEmailFieldIndicator } from "../email/LoadingEmailFieldIndicator";
import type { ICreateNewProps } from "../inputs/api";
import { ActionRelationInput } from "./actionInputs/builtin/ActionRelationInput";
import { getInputForDefinition } from "./actionInputs/getInputForDefinition";

export const ActionField: React.FC<{
  field: ControllerRenderProps<Partial<Record<string, IViewFieldValue>>, IViewFieldId>;
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>> | undefined;
  input: ILoadedActionInput;
  versionType: IVersionType;
  entity: ILoadedEntity | undefined;
  entityTypeId: IEntityTypeId;
  organizationId: IOrganizationId;
  actionId: IActionId;
  isFieldTouched: boolean;
  onSetValue: (formNameViewFieldId: IViewFieldId, value: IViewFieldValue, isUserEdited: boolean) => void;
  isHighlighted: boolean;
  isAIDefault: boolean;
  isDerived: boolean;
  emailDraftValue: IDraftEmailExtractedActionInput | undefined;
  isComputing: boolean;
  isSavingDraft: boolean;
  /**
   *
   * Relevant only a relation input to be able to create an entity in a nested form
   */
  createNewProps: ICreateNewProps | undefined;
  getHighlightedViewFieldRoute?: IGetHighlightedViewFieldRoute;
  getLinkedEntityRoute: IGetLinkedEntityRoute;
  readOnly: boolean;
  currentUserInfo: IActionCurrentUserInfo;
}> = ({
  allEntityTypes,
  currentUserInfo,
  input,
  field,
  versionType,
  entity,
  entityTypeId,
  organizationId,
  actionId,
  onSetValue,
  isFieldTouched,
  isHighlighted,
  isAIDefault,
  isDerived,
  emailDraftValue,
  isComputing,
  isSavingDraft,
  createNewProps,
  getHighlightedViewFieldRoute,
  readOnly,
}) => {
  const handleChange = useCallback(
    (newValue: IViewFieldValue) => {
      onSetValue(field.name, newValue, true);
    },
    [onSetValue, field.name],
  );

  const ActionInput = isColumnViewField(input.viewField)
    ? getInputForDefinition(input.viewField.column.columnType)
    : ActionRelationInput;

  const renderBadge = useCallback((): React.JSX.Element | undefined => {
    if (emailDraftValue != null && isEqual(emailDraftValue.value, field.value)) {
      // Only the current field can be rendered in the email so no need to pass all other fields through
      return (
        <LoadingEmailFieldIndicator
          allFieldsById={{ [input.viewField.id]: input.viewField }}
          emailExtractedData={{ [input.viewField.id]: emailDraftValue }}
          emailId={emailDraftValue.emailId}
          organizationId={organizationId}
        />
      );
    }

    if (isFieldTouched) {
      return undefined;
    }

    if (isComputing && (isDerived || isAIDefault)) {
      return <ComputingSuggestion />;
    }

    const hasValue = field.value != null && field.value.type !== "null";

    if (hasValue && isAIDefault) {
      return <AutofillIndicator type="ai" />;
    }

    if (hasValue && isDerived) {
      return <AutofillIndicator type="logic" />;
    }

    return undefined;
  }, [
    isFieldTouched,
    isComputing,
    isDerived,
    isAIDefault,
    emailDraftValue,
    field.value,
    input.viewField,
    organizationId,
  ]);

  const shapeInfoIfRelation = useMemoDeepCompare(() => {
    if (isColumnViewField(input.viewField)) {
      return undefined;
    }

    if (input.viewField.direction === "aToB") {
      return allEntityTypes?.[input.viewField.relation.entityTypeIdB];
    }

    return allEntityTypes?.[input.viewField.relation.entityTypeIdA];
  }, [allEntityTypes, input.viewField]);

  const content: React.JSX.Element = (
    <div
      className={cn(
        "relative -mx-2 -my-1 space-y-0.5 overflow-hidden rounded px-2 py-1",
        getHighlightedViewFieldRoute != null && "hover:bg-accent-background cursor-pointer",
        isHighlighted && "bg-accent-background",
      )}
      data-testid={`action-field-${isColumnViewField(input.viewField) ? `column-${input.viewField.column.columnType.type}` : "relation"}`}
    >
      <div className="flex h-8 items-center justify-between">
        <FormLabel className="flex items-center">
          {shapeInfoIfRelation ? (
            <ShapeColorIcon
              className="mr-1.5"
              color={shapeInfoIfRelation.color}
              shape={shapeInfoIfRelation.shape}
              size="sm"
            />
          ) : null}
          {input.viewField.displayName}
          {!input.required && <span className="text-muted-foreground ml-1">(optional)</span>}
          {isSavingDraft ? <DotPinging className="ml-2" color="gray" size="sm" /> : null}
        </FormLabel>
        {renderBadge()}
      </div>
      <FormControl>
        <ActionInput
          actionId={actionId}
          className={readOnly ? "bg-muted-background border-none" : undefined}
          createNewProps={createNewProps}
          currentUserInfo={currentUserInfo}
          entity={entity}
          entityTypeId={entityTypeId}
          field={field}
          input={input}
          isFieldTouched={isFieldTouched}
          organizationId={organizationId}
          readOnly={readOnly}
          versionType={versionType}
          onChange={handleChange}
        />
      </FormControl>
    </div>
  );

  if (getHighlightedViewFieldRoute != null) {
    return (
      <Link
        className="no-underline"
        href={getHighlightedViewFieldRoute({
          entityTypeId,
          entityId: entity?.entityId,
          viewFieldId: input.viewField.id,
        })}
      >
        {content}
      </Link>
    );
  }

  return content;
};
