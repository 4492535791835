import { ColumnId, EntityTypeId, ValidationId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { LLMColumnAutofill, LLMColumnType } from "../llm/llmColumn";

export const ColumnChangeBase = z.object({
  columnId: ColumnId,
});
export type IColumnChangeBase = z.infer<typeof ColumnChangeBase>;

export const ColumnNameChange = ColumnChangeBase.extend({
  changeColumnNameTo: ReadableString,
});
export type IColumnNameChange = z.infer<typeof ColumnNameChange>;

export const ColumnTypeChange = ColumnChangeBase.extend({
  changeColumnTypeTo: LLMColumnType,
});
export type IColumnTypeChange = z.infer<typeof ColumnTypeChange>;

export const ColumnAutofillChange = ColumnChangeBase.extend({
  changeAutofillTo: LLMColumnAutofill,
});
export type IColumnAutofillChange = z.infer<typeof ColumnAutofillChange>;

export const ColumnDisableAutofillChange = ColumnChangeBase.extend({
  disableAutofill: z.enum(["true"]),
});
export type IColumnDisableAutofillChange = z.infer<typeof ColumnDisableAutofillChange>;

export const ColumnAddValidationChange = ColumnChangeBase.extend({
  validationDescriptionToAdd: ReadableString,
});
export type IColumnAddValidationChange = z.infer<typeof ColumnAddValidationChange>;

export const ColumnRemoveValidationChange = ColumnChangeBase.extend({
  validationIdToRemove: ValidationId,
});
export type IColumnRemoveValidationChange = z.infer<typeof ColumnRemoveValidationChange>;

export const ColumnEditValidationChange = ColumnChangeBase.extend({
  validationIdToEdit: ValidationId,
  changeValidationDescriptionTo: ReadableString,
});
export type IColumnEditValidationChange = z.infer<typeof ColumnEditValidationChange>;

export const ColumnDeleteChange = ColumnChangeBase.extend({
  deleteColumn: z.enum(["true"]),
});
export type IColumnDeleteChange = z.infer<typeof ColumnDeleteChange>;

export const ColumnCreateChange = z.object({
  entityTypeId: EntityTypeId,
  columnToCreate: z.object({
    columnId: ColumnId.default(ColumnId.generate()),
    name: ReadableString,
    columnType: LLMColumnType,
  }),
});
export type IColumnCreateChange = z.infer<typeof ColumnCreateChange>;

export const ColumnChange = z.union([
  ColumnNameChange,
  ColumnTypeChange,
  ColumnAutofillChange,
  ColumnDisableAutofillChange,
  ColumnAddValidationChange,
  ColumnRemoveValidationChange,
  ColumnEditValidationChange,
  ColumnDeleteChange,
  ColumnCreateChange,
]);
export type IColumnChange = z.infer<typeof ColumnChange>;

export function isColumnNameChange(change: IColumnChange): change is IColumnNameChange {
  return "changeColumnNameTo" in change;
}

export function isColumnTypeChange(change: IColumnChange): change is IColumnTypeChange {
  return "changeColumnTypeTo" in change;
}

export function isColumnAutofillChange(change: IColumnChange): change is IColumnAutofillChange {
  return "changeAutofillTo" in change;
}

export function isColumnDisableAutofillChange(change: IColumnChange): change is IColumnDisableAutofillChange {
  return "disableAutofill" in change;
}

export function isColumnAddValidationChange(change: IColumnChange): change is IColumnAddValidationChange {
  return "validationDescriptionToAdd" in change;
}

export function isColumnRemoveValidationChange(change: IColumnChange): change is IColumnRemoveValidationChange {
  return "validationIdToRemove" in change;
}

export function isColumnEditValidationChange(change: IColumnChange): change is IColumnEditValidationChange {
  return "validationIdToEdit" in change;
}

export function isColumnDeleteChange(change: IColumnChange): change is IColumnDeleteChange {
  return "deleteColumn" in change;
}

export function isColumnCreateChange(change: IColumnChange): change is IColumnCreateChange {
  return "columnToCreate" in change;
}

export const ColumnValidationChange = z.union([
  ColumnAddValidationChange,
  ColumnRemoveValidationChange,
  ColumnEditValidationChange,
]);
export type IColumnValidationChange = z.infer<typeof ColumnValidationChange>;

export function isColumnValidationChange(change: IColumnChange): change is IColumnValidationChange {
  return (
    isColumnAddValidationChange(change) ||
    isColumnRemoveValidationChange(change) ||
    isColumnEditValidationChange(change)
  );
}
