import type { IViewFieldValue } from "@archetype/dsl";
import { isColumnViewField } from "@archetype/dsl";
import { cn, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@archetype/ui";
import { useCallback } from "react";

import { StringInput } from "../primitive/StringInput";
import type { IActionInputComponent } from "../types";

export const StatusEnumInput: IActionInputComponent = ({
  versionType,
  className,
  field,
  onChange,
  input,
  readOnly = false,
  entity,
  entityTypeId,
  organizationId,
  currentUserInfo,
  isFieldTouched,
}) => {
  const { viewField } = input;

  const handleChange = useCallback(
    (value: IViewFieldValue): void => {
      onChange(value);
    },
    [onChange],
  );

  const handleValueChange = useCallback(
    (value: string): void => {
      onChange(
        value === ""
          ? { type: "null" }
          : {
              type: "string",
              value,
            },
      );
    },
    [onChange],
  );

  const handleBlur = useCallback(() => {
    field.onBlur();
  }, [field]);

  if (!isColumnViewField(viewField)) {
    return (
      <StringInput
        currentUserInfo={currentUserInfo}
        entity={entity}
        entityTypeId={entityTypeId}
        field={field}
        input={input}
        isFieldTouched={isFieldTouched}
        organizationId={organizationId}
        readOnly={readOnly}
        versionType={versionType}
        onChange={handleChange}
      />
    );
  }
  const columnType = viewField.column.columnType;

  if (columnType.type !== "statusEnum" || columnType.allowedValues.length === 0) {
    return (
      <StringInput
        currentUserInfo={currentUserInfo}
        entity={entity}
        entityTypeId={entityTypeId}
        field={field}
        input={input}
        isFieldTouched={isFieldTouched}
        organizationId={organizationId}
        readOnly={readOnly}
        versionType={versionType}
        onChange={handleChange}
      />
    );
  }

  const enumValues = columnType.allowedValues;

  return (
    <Select
      defaultValue={field.value?.type === "string" ? field.value.value : undefined}
      disabled={readOnly}
      name={field.name}
      onValueChange={handleValueChange}
    >
      <SelectTrigger ref={field.ref} className={cn("w-full text-base", className)} onBlur={handleBlur}>
        <SelectValue placeholder={<div className="text-muted-foreground">{`Select ${viewField.displayName}`}</div>} />
      </SelectTrigger>

      <SelectContent>
        {enumValues.map((value) => (
          <SelectItem key={value.id} value={value.id}>
            {value.readableValue}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
