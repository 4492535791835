import { Badge } from "@archetype/ui";
import React from "react";

import type { IDataTableBadgeCell, IDataTableCommonCellProps } from "./api";

export function BadgeCell<TRowId extends string, TColId extends string>({
  cell,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTableBadgeCell<TRowId, TColId>>): React.ReactNode {
  const { value, variant } = cell;

  return (
    <div className="flex h-full flex-1 items-center px-2">
      <Badge colorVariant={variant} size="md">
        {value}
      </Badge>
    </div>
  );
}
