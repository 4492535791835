import React, { useCallback, useMemo, useRef, useState } from "react";
import { createContext } from "use-context-selector";

import type { IDataTableColumn, IDataTableRow } from "../api";

interface IDataTableInstanceContextType<
  TRowId extends string,
  TColId extends string,
  TRowData extends IDataTableRow<TRowId>,
  TCellValue,
> {
  tableRef: React.RefObject<HTMLDivElement>;
  columns: IDataTableColumn<TRowId, TColId, TRowData, TCellValue>[];

  data: TRowData[];

  rowCount: number;

  rowHeight: number;
  headerHeight: number;
  tableWidth: number;
  tableHeight: number;

  rowIndexMap: Map<TRowId, number>;

  columnWidths: number[];
  columnWidthMap: Map<TColId, number>;

  columnMap: Map<TColId, IDataTableColumn<TRowId, TColId, TRowData, TCellValue>>;

  cellValueMap: Map<TRowId, Map<TColId, TCellValue>>;

  cellValues: Array<Array<TCellValue>>;

  resizingColumnId: TColId | null;

  setIsResizing: (columnId: TColId | null) => void;
  resizeColumn: (columnId: TColId, width: number) => void;
}

export const DataTableInstanceContext = createContext<
  IDataTableInstanceContextType<string, string, IDataTableRow<string>, unknown>
>({} as IDataTableInstanceContextType<string, string, IDataTableRow<string>, unknown>);

const DEFAULT_ROW_HEIGHT = 42;
const DEFAULT_HEADER_HEIGHT = 44;

interface IDataTableInstanceProviderProps<
  TRowId extends string,
  TColId extends string,
  TRowData extends IDataTableRow<TRowId>,
  TCellValue,
> {
  children: React.ReactNode;
  data: TRowData[];
  columns: IDataTableColumn<TRowId, TColId, TRowData, TCellValue>[];
  rowCount: number;
  headerHeight?: number;
  rowHeight?: number;
}

export const DataTableInstanceProvider = <
  TRowId extends string,
  TColId extends string,
  TRowData extends IDataTableRow<TRowId>,
  TCellValue,
>({
  children,
  data,
  columns,
  rowCount,
  headerHeight = DEFAULT_HEADER_HEIGHT,
  rowHeight = DEFAULT_ROW_HEIGHT,
}: IDataTableInstanceProviderProps<TRowId, TColId, TRowData, TCellValue>): React.ReactElement => {
  const tableRef = useRef<HTMLDivElement>(null);
  const [resizingColumnId, setResizingColumnId] = useState<TColId | null>(null);

  const handleSetIsResizing = useCallback((columnId: TColId | null) => {
    setResizingColumnId(columnId);
  }, []);

  const [columnWidthMap, setColumnWidthMap] = useState<Map<TColId, number>>(() => {
    const initialMap = new Map<TColId, number>();

    columns.forEach((column) => {
      initialMap.set(column.id, column.minWidth);
    });

    return initialMap;
  });

  const resizeColumn = useCallback(
    (columnId: TColId, width: number) => {
      setColumnWidthMap((prev) => {
        const currentWidth = prev.get(columnId);
        const minWidth = columns.find((col) => col.id === columnId)?.minWidth ?? 0;
        const newWidth = Math.max(width, minWidth);

        if (currentWidth === newWidth) {
          return prev;
        }

        const newMap = new Map(prev);

        newMap.set(columnId, newWidth);

        return newMap;
      });
    },
    [columns],
  );

  const { tableWidth, tableHeight, columnWidths, columnMap } = useMemo(() => {
    const colMap = new Map<TColId, IDataTableColumn<TRowId, TColId, TRowData, TCellValue>>();
    const colWidths: number[] = [];

    let totalWidth = 0;

    columns.forEach((column) => {
      const width = columnWidthMap.get(column.id) ?? column.minWidth;

      colMap.set(column.id, column);
      colWidths.push(width);
      totalWidth += width;
    });

    const totalHeight = rowCount * rowHeight;

    return {
      tableWidth: totalWidth,
      tableHeight: totalHeight,
      columnWidths: colWidths,
      columnMap: colMap,
    };
  }, [columns, rowCount, rowHeight, columnWidthMap]);

  const { rowIndexMap, cellValueMap, cellValues } = useMemo(() => {
    const rMap = new Map<TRowId, number>();
    const cMap = new Map<TRowId, Map<TColId, TCellValue>>();
    const cellsArray: Array<Array<TCellValue>> = [];

    data.forEach((row, rowIndex) => {
      rMap.set(row.id, rowIndex);

      const innerCellValues: Array<TCellValue> = [];

      columns.forEach((column) => {
        const cellValue = column.accessorFn(row);
        const rowMap = cMap.get(row.id) ?? new Map<TColId, TCellValue>();

        rowMap.set(column.id, cellValue);
        cMap.set(row.id, rowMap);
        innerCellValues.push(cellValue);
      });

      cellsArray.push(innerCellValues);
    });

    return { rowIndexMap: rMap, cellValueMap: cMap, cellValues: cellsArray };
  }, [data, columns]);

  const value = useMemo(
    () => ({
      tableRef,
      data,
      rowIndexMap,
      columns,
      rowCount,
      rowHeight,
      headerHeight,
      tableWidth,
      tableHeight,
      columnWidthMap,
      columnWidths,
      columnMap,
      cellValueMap,
      cellValues,
      resizingColumnId,
      setIsResizing: handleSetIsResizing,
      resizeColumn,
    }),
    [
      tableRef,
      data,
      rowIndexMap,
      columns,
      rowCount,
      rowHeight,
      headerHeight,
      tableWidth,
      tableHeight,
      columnWidthMap,
      columnWidths,
      columnMap,
      cellValueMap,
      cellValues,
      resizingColumnId,
      handleSetIsResizing,
      resizeColumn,
    ],
  );

  // Since we cannot type contexts with generics we have to cast to any here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment -- functional
  return <DataTableInstanceContext.Provider value={value as any}>{children}</DataTableInstanceContext.Provider>;
};
