import type {
  IActionCore,
  ILLMStateMachineTransitionWithIds,
  ILLMStateMachineWithIds,
  IStateMachine,
  IStateMachineState,
  IStateMachineStateTransition,
} from "@archetype/dsl";
import type { IActionId, IStateId } from "@archetype/ids";
import { forEach, isNonNullable, mapKeysNoUndefined, mapValues } from "@archetype/utils";

export function convertStateMachineTransitionToLLMStateMachineTransition({
  transition,
  statesById,
  actionsById,
}: {
  transition: IStateMachineStateTransition;
  statesById: Record<IStateId, IStateMachineState & { isArchived: boolean }>;
  actionsById: Record<IActionId, IActionCore>;
}): ILLMStateMachineTransitionWithIds | undefined {
  const fromState = statesById[transition.from];
  const toState = statesById[transition.to];

  if (fromState == null || toState == null) {
    throw new Error(`State not found: ${transition.from} or ${transition.to}`);
  }

  if (fromState.isArchived || toState.isArchived) {
    // don't include archived states in the LLM state machine, it's confusing
    return undefined;
  }

  const action = actionsById[transition.actionId];

  if (action == null) {
    throw new Error(`Action not found: ${transition.actionId}`);
  }

  return {
    actionId: action.id,
    actionName: action.displayMetadata.name,
    fromState: {
      stateId: fromState.id,
      stateName: fromState.label,
    },
    toState: {
      stateId: toState.id,
      stateName: toState.label,
    },
  };
}

export function convertStateMachineToLLMStateMachine({
  stateMachine,
  actionsById,
}: {
  stateMachine: IStateMachine;
  actionsById: Record<IActionId, IActionCore>;
}): ILLMStateMachineWithIds {
  const statesById: Record<IStateId, IStateMachineState & { isArchived: boolean }> = mapKeysNoUndefined(
    mapValues(stateMachine.states, (state) => ({ ...state, isArchived: false })),
    (state) => state.id,
  );

  forEach(stateMachine.archivedStates ?? {}, (state) => {
    statesById[state.id] = { ...state, isArchived: true };
  });

  const initialState = statesById[stateMachine.initialState.state];
  const initialStateAction = actionsById[stateMachine.initialState.actionId];

  if (initialState == null || initialStateAction == null) {
    throw new Error(
      `Initial state or action not found: ${stateMachine.initialState.state} or ${stateMachine.initialState.actionId}`,
    );
  }

  return {
    initialAction: {
      actionId: initialStateAction.id,
      actionName: initialStateAction.displayMetadata.name,
      toState: {
        stateId: initialState.id,
        stateName: initialState.label,
      },
    },
    actions: stateMachine.stateTransitions
      .map((transition) =>
        convertStateMachineTransitionToLLMStateMachineTransition({ transition, statesById, actionsById }),
      )
      .filter(isNonNullable),
  };
}
