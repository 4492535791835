import { z } from "zod";

export const LLMTimestampInput = z.object({
  currentTimestamp: z.string().describe("The current date as a timestamp"),
  dateTimeDescription: z.string().describe("The description of the date/time to parse"),
});

export const LLMTimestampOutput = z.object({
  output: z.discriminatedUnion("type", [
    z.object({ type: z.literal("error"), error: z.string().optional() }),

    z.object({
      type: z.literal("timestamp"),
      timestamp: z.string().describe("The generated timestamp"),
    }),
  ]),
});

export type ILLMTimestampInputInput = z.infer<typeof LLMTimestampInput>;
export type ILLMTimestampInputOutput = z.infer<typeof LLMTimestampOutput>;
