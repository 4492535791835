import type { IEntityTypeCore, IRelatedToFilter } from "@archetype/dsl";
import type { IEntityId } from "@archetype/ids";
import { useMemoDeepCompare } from "@archetype/ui";
import { isNonNullable } from "@archetype/utils";

/**
 * Hook to extract entity IDs from a related-to filter value.
 *
 * @param value - The related-to filter value to extract IDs from
 * @param otherEntityType - The entity type that defines the primary key column
 * @returns Array of entity IDs referenced in the filter, or empty array if no filter/matches
 */
export const useRelatedToFilterEntityIds = (
  value: IRelatedToFilter | undefined,
  otherEntityType: IEntityTypeCore,
): IEntityId[] =>
  useMemoDeepCompare(() => {
    const perColumn = value?.filters?.perColumn[otherEntityType.primaryKey];

    if (perColumn?.type === "or") {
      return (
        perColumn.rawOrConditions.eq
          ?.map((x) => (x.type === "value" && x.value.type === "string" ? (x.value.value as IEntityId) : undefined))
          .filter(isNonNullable) ?? []
      );
    }

    return [];
  }, [value?.filters?.perColumn, otherEntityType.primaryKey]);
