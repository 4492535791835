import { BadgeWithDot } from "@archetype/ui";
import React from "react";

import { useCellClickInteraction } from "../../hooks/useCellClickInteraction";
import type { IDataTableCommonCellProps, IDataTableStatusCell } from "./api";

export function StatusCell<TRowId extends string, TColId extends string>({
  cell,
  isCellEditing,
  isCellSelected,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTableStatusCell<TRowId, TColId>>): React.ReactNode {
  const { value, variant, rowId, colId, readOnly } = cell;

  const { handleCellClick } = useCellClickInteraction({
    isCellEditing,
    isCellSelected,
    readOnly,
    rowId,
    colId,
  });

  return (
    <div className="flex h-full items-center px-2" onClick={handleCellClick}>
      <BadgeWithDot colorVariant={variant} label={value} />
    </div>
  );
}
