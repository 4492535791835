import type { IDataLoadingFilterAndOperator } from "@archetype/dsl";
import { cn } from "@archetype/ui";
import type { ComponentProps } from "react";
import { useCallback } from "react";

import { SubmittableInput } from "./SubmittableInput";

type ISubmittableInputProps = Omit<ComponentProps<typeof SubmittableInput>, "onSubmit">;

type INumberInputFilter = ISubmittableInputProps & {
  onSubmit: (operator: IDataLoadingFilterAndOperator, value: string[]) => void;
  operator?: IDataLoadingFilterAndOperator;
  placeholder?: string;
  defaultValue?: string | number;
};

export const NumberInputFilter: React.FC<INumberInputFilter> = (props) => {
  const { onSubmit, operator = "eq", className, ...rest } = props;

  const handleSubmit = useCallback(
    (value: string) => {
      onSubmit(operator, value.length > 0 ? [value] : []);
    },
    [operator, onSubmit],
  );

  return (
    <SubmittableInput
      iconLeft="binary"
      {...rest}
      autoFocus
      className={cn("border-0", className)}
      type="number"
      onSubmit={handleSubmit}
    />
  );
};
