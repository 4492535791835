import type {
  IAutofill,
  IColumn,
  IColumnType,
  IColumnValidation,
  ILLMColumn,
  ILLMColumnAutofill,
  ILLMColumnType,
  ILLMValidation,
} from "@archetype/dsl";
import type { IReadableString } from "@archetype/utils";
import assertNever from "assert-never";

export function convertColumnAutofillToLLMColumnAutofill(autofill: IAutofill | undefined): ILLMColumnAutofill | null {
  if (autofill == null) {
    return null;
  }

  switch (autofill.config.type) {
    case "static": {
      return {
        staticValue: autofill.config.value,
      };
    }
    case "dynamic": {
      if (autofill.config.value === "currentUser") {
        return null;
      }

      return {
        dynamicValue: autofill.config.value,
      };
    }
    case "logic":
    case "lookup": {
      return {
        logicDescription: autofill.config.rawLogic,
        recomputationType: autofill.type === "live" ? "live" : "suggestion",
      };
    }
    case "ai": {
      return {
        aiInstructions: autofill.config.rawInstructions,
        recomputationType: autofill.type === "live" ? "live" : "suggestion",
      };
    }
    default: {
      assertNever(autofill.config);
    }
  }
}

export function convertColumnTypeToLLMColumnType(columnType: IColumnType): ILLMColumnType {
  switch (columnType.type) {
    case "shortText":
    case "longText":
    case "number":
    case "boolean":
    case "date":
    case "timestamp":
    case "email":
    case "url":
    case "phone":
    case "file":
    case "geolocation": {
      return {
        type: columnType.type,
      };
    }
    case "enum": {
      return {
        type: "enum",
        enumValues: columnType.enumAllowedValues,
        enumAllowMultipleValuesSelection:
          columnType.enumInclusiveMaxValuesToSelect == null || columnType.enumInclusiveMaxValuesToSelect !== 1,
      };
    }
    case "statusEnum": {
      return {
        type: "enum",
        enumValues: columnType.allowedValues.map((value) => value.readableValue),
        enumAllowMultipleValuesSelection: false,
      };
    }
    default: {
      assertNever(columnType);
    }
  }
}

export function convertValidationToLLMValidation(validation: IColumnValidation): ILLMValidation {
  return {
    id: validation.id,
    validationDescription: validation.rawLogic,
  };
}

export function convertColumnToLLMColumn({
  column,
  entityType,
}: {
  column: IColumn;
  entityType: { displayMetadata: { name: IReadableString } };
}): ILLMColumn {
  return {
    columnId: column.id,
    name: column.displayMetadata.name,
    entityTypeName: entityType.displayMetadata.name,
    columnType: convertColumnTypeToLLMColumnType(column.columnType),
    autofill: convertColumnAutofillToLLMColumnAutofill(column.autofill),
    validations: column.validations?.map(convertValidationToLLMValidation) ?? [],
  };
}
