import assertNever from "assert-never";
import * as React from "react";
import { useCallback } from "react";

import { cn } from "../../lib/utils";
import type { IEmailComposer } from "../molecules/email/email-composer";
import { EmailComposer } from "../molecules/email/email-composer";
import { Eyebrow } from "./eyebrow";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Skeleton } from "./skeleton";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

type IAutofillIndicatorSimple = {
  type: "logic" | "ai" | "lookup";
};

type IAutofillIndicatorEmail = {
  type: "email";
  emailContent:
    | {
        type: "loading";
      }
    | {
        type: "unauthorized";
      }
    | {
        type: "emailContent";
        email: IEmailComposer;
      };
};

type IAutofillIndicator = {
  className?: string;
} & (IAutofillIndicatorSimple | IAutofillIndicatorEmail);

export const AutofillIndicator: React.FC<IAutofillIndicator> = ({ className, ...props }) => {
  const getSimpleAutofillTooltipContent = useCallback((arg: IAutofillIndicatorSimple) => {
    switch (arg.type) {
      case "ai": {
        return "This field is autofilled by AI";
      }
      case "lookup": {
        return "This field is autofilled by a lookup";
      }
      case "logic": {
        return "This field is autofilled by a formula";
      }
    }
  }, []);

  const getEmailAutofillTooltipContent = useCallback((arg: IAutofillIndicatorEmail) => {
    switch (arg.emailContent.type) {
      case "emailContent": {
        return <EmailComposer className="max-h-96 min-w-96 overflow-y-auto p-0" {...arg.emailContent.email} />;
      }
      case "unauthorized": {
        return <span className="text-muted-foreground italic">Private email</span>;
      }
      case "loading": {
        return (
          <div className="min-w-96 p-0">
            <Skeleton className="h-10 w-32" />
          </div>
        );
      }
      default: {
        assertNever(arg.emailContent);
      }
    }
  }, []);

  const getEyebrowContent = useCallback(() => {
    switch (props.type) {
      case "ai": {
        return "Autofilled by AI";
      }
      case "logic":
      case "lookup": {
        return "Autofilled";
      }

      case "email": {
        return "Detected in email";
      }
      default: {
        assertNever(props);
      }
    }
  }, [props]);

  const renderTrigger = useCallback(() => {
    return (
      <Eyebrow className={cn("cursor-pointer leading-6", className)} variant="secondary">
        {getEyebrowContent()}
      </Eyebrow>
    );
  }, [className, getEyebrowContent]);

  switch (props.type) {
    case "ai":
    case "lookup":
    case "logic": {
      return (
        <Tooltip>
          <TooltipTrigger asChild>{renderTrigger()}</TooltipTrigger>
          <TooltipContent>
            <p>{getSimpleAutofillTooltipContent({ type: props.type })}</p>
          </TooltipContent>
        </Tooltip>
      );
    }

    case "email": {
      return (
        <Popover>
          <Tooltip>
            <PopoverTrigger asChild>
              <TooltipTrigger asChild>{renderTrigger()}</TooltipTrigger>
            </PopoverTrigger>
            <TooltipContent>
              <p>Click to view email</p>
            </TooltipContent>
          </Tooltip>
          <PopoverContent asChild>{getEmailAutofillTooltipContent(props)}</PopoverContent>
        </Popover>
      );
    }

    default: {
      assertNever(props);
    }
  }
};
