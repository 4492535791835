import type { IDependency, IEntityType, IRelation } from "@archetype/dsl";
import {
  computeColumnViewFieldId,
  computeRelationViewFieldId,
  computeViewFieldId,
  Dependency,
  isComputedColumn,
  isComputedRelation,
  isFieldDependency,
} from "@archetype/dsl";
import { type IEntityTypeId, type IRelationId, type IViewFieldId, ViewFieldId } from "@archetype/ids";
import type { IReadableString } from "@archetype/utils";
import { forEach, mapValues, ReadableString } from "@archetype/utils";
import { concat, isEqual, uniqWith } from "lodash";
import { z } from "zod";

import { createLoadedColumnViewField, createLoadedRelationViewField } from "../apiTypes/LoadedViewField";

const DependencyFieldIdInfo = z.object({
  id: ViewFieldId,
  displayName: ReadableString,
});

export const DependenciesFieldIdsInfo = z.object({
  dependencies: z.record(ViewFieldId, z.array(DependencyFieldIdInfo).optional()),
  dependentFields: z.record(ViewFieldId, z.array(DependencyFieldIdInfo).optional()),
});
export type IDependenciesFieldIdsInfo = z.infer<typeof DependenciesFieldIdsInfo>;

export const DependenciesInfo = z.object({
  dependencies: z.record(ViewFieldId, z.array(Dependency).optional()),
  dependentFields: z.record(
    ViewFieldId,
    z.array(z.object({ id: ViewFieldId, displayName: ReadableString })).optional(),
  ),
});
export type IDependenciesInfo = z.infer<typeof DependenciesInfo>;

export const makeDependenciesInfo = ({
  targetEntityTypeId,
  entityTypesById,
  relationsForEntityType,
}: {
  targetEntityTypeId: IEntityTypeId;
  entityTypesById: Record<IEntityTypeId, IEntityType>;
  relationsForEntityType: Record<IRelationId, IRelation>;
}): IDependenciesInfo => {
  const dependenciesByFieldId: Record<IViewFieldId, Array<IDependency>> = {};
  const dependentFieldsByFieldId: Record<IViewFieldId, Array<{ id: IViewFieldId; displayName: IReadableString }>> = {};

  forEach(entityTypesById, (entityType) => {
    entityType.columns.forEach((column) => {
      if (!isComputedColumn(column)) {
        return;
      }

      const columnViewFieldId = computeColumnViewFieldId(column.id);
      const columnDeps: Array<IDependency> = [];

      if (isComputedColumn(column)) {
        columnDeps.push(...column.autofill.config.dependencies);
      }

      if (entityType.id === targetEntityTypeId && columnDeps.length > 0) {
        dependenciesByFieldId[columnViewFieldId] = uniqWith(
          concat(dependenciesByFieldId[columnViewFieldId] ?? [], columnDeps),
          isEqual,
        );
      }

      columnDeps.forEach((dep) => {
        const depViewFieldId = isFieldDependency(dep)
          ? computeViewFieldId(dep.field)
          : computeRelationViewFieldId(dep.relationId, dep.direction);
        const columnViewField = createLoadedColumnViewField(column);

        if (entityType.id === targetEntityTypeId) {
          dependentFieldsByFieldId[depViewFieldId] = uniqWith(
            concat(dependentFieldsByFieldId[depViewFieldId] ?? [], [
              { id: columnViewField.id, displayName: columnViewField.displayName },
            ]),
            isEqual,
          );
        }
      });
    });
  });

  forEach(relationsForEntityType, (relation) => {
    if (!isComputedRelation(relation)) {
      return;
    }

    const relationViewFieldId = computeRelationViewFieldId(relation.id, relation.autofill.direction);

    const relationOriginEntityTypeId =
      relation.autofill.direction === "aToB" ? relation.entityTypeIdA : relation.entityTypeIdB;
    const relationTargetEntityTypeId =
      relation.autofill.direction === "aToB" ? relation.entityTypeIdB : relation.entityTypeIdA;

    if (relationOriginEntityTypeId === targetEntityTypeId) {
      dependenciesByFieldId[relationViewFieldId] = uniqWith(
        concat(dependenciesByFieldId[relationViewFieldId] ?? [], relation.autofill.autofill.config.dependencies),
        isEqual,
      );
    }

    relation.autofill.autofill.config.dependencies.forEach((dep) => {
      const depViewFieldId = isFieldDependency(dep)
        ? computeViewFieldId(dep.field)
        : computeRelationViewFieldId(dep.relationId, dep.direction);
      const relationViewField = createLoadedRelationViewField(relation, relation.autofill.direction);

      if (relationTargetEntityTypeId === targetEntityTypeId) {
        dependentFieldsByFieldId[depViewFieldId] = uniqWith(
          concat(dependentFieldsByFieldId[depViewFieldId] ?? [], [
            { id: relationViewField.id, displayName: relationViewField.displayName },
          ]),
          isEqual,
        );
      }
    });
  });

  return {
    dependencies: dependenciesByFieldId,
    dependentFields: dependentFieldsByFieldId,
  };
};

export const dependenciesFieldIdsFromInfo = (info: IDependenciesInfo): IDependenciesFieldIdsInfo => {
  return {
    dependencies: mapValues(info.dependencies, (depFields) =>
      depFields?.map((depField) => ({
        id: isFieldDependency(depField)
          ? computeViewFieldId(depField.field)
          : computeRelationViewFieldId(depField.relationId, depField.direction),
        displayName: depField.displayName,
      })),
    ),
    dependentFields: mapValues(info.dependentFields, (depFields) =>
      depFields?.map((depField) => ({
        id: depField.id,
        displayName: depField.displayName,
      })),
    ),
  };
};
