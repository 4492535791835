import { ColumnId } from "@archetype/ids";
import { z } from "zod";

import { EntityColumnValue } from "../EntityValue";

export const DataLoadingQueryFilterValueLiteral = z.object({
  type: z.literal("value"),
  value: EntityColumnValue,
});

export const DataLoadingQueryFilterValueReference = z.object({
  type: z.literal("reference"),
  otherColumnId: ColumnId,
});

// TODO (julien): this needs to be added to features as well and handled in data stores
export const DataLoadingQueryFilterValue = z.discriminatedUnion("type", [
  DataLoadingQueryFilterValueLiteral,
  DataLoadingQueryFilterValueReference,
]);

export const DataLoadingQueryFilterRegex = z.object({
  type: z.literal("regex"),
  value: z.string(),
  flags: z.string(),
});

// const DataLoadingQueryFilterValue = z.discriminatedUnion("type", [
//   z.object({ type: z.literal("number"), value: z.number() }),
//   z.object({ type: z.literal("string"), value: z.string() }),
//   z.object({ type: z.literal("boolean"), value: z.boolean() }),
// ]);

/**
 * Enforces number of values that can be passed for a given operator and column in an AND filter
 * e.g. equal to multiple
 */
export const DataLoadingQueryColumnAndFilters = z
  .object({
    eq: DataLoadingQueryFilterValue, // would be always true with multiple
    eqIgnoreCase: DataLoadingQueryFilterValue, // would be always true with multiple
    neq: z.array(DataLoadingQueryFilterValue),
    neqIgnoreCase: z.array(DataLoadingQueryFilterValue),
    gt: DataLoadingQueryFilterValue,
    gte: DataLoadingQueryFilterValue,
    lt: DataLoadingQueryFilterValue,
    lte: DataLoadingQueryFilterValue,
    regex: DataLoadingQueryFilterRegex,
    /**
     * Checks if at least one value from the filter array is in the column
     * Filter and column should both be an array
     * Checks if the intersection between the two arrays is not empty
     * e.g:
     * filter: [1, 2, 6]
     * column: [1, 2, 3, 4, 5]
     * => true because 1 and 2 are in column
     */
    anyInArray: DataLoadingQueryFilterValue,
    /**
     * Checks if at least one value from the filter array is not in the column
     * Filter and column should both be an array
     * e.g:
     * filter: [1, 2, 6]
     * column: [1, 2, 3]
     * => true because 6 is not in column
     */
    noneInArray: DataLoadingQueryFilterValue,
    /**
     * Checks if all values from the filter array are in the column
     * Filter and column should both be an array
     * e.g:
     * filter: [1, 2, 3]
     * column: [1, 2, 3, 4, 5]
     * => true because 1, 2 and 3 are all in column
     */
    allInArray: DataLoadingQueryFilterValue,
    /**
     * Checks if all values from the filter array are not in the column
     * Filter and column should both be an array
     * e.g:
     * filter: [1, 2, 3]
     * column: [4, 5, 6]
     * => true because 1, 2 and 3 are all not in column
     */
    allNotInArray: DataLoadingQueryFilterValue,
  })
  .partial();

export const DataLoadingQueryColumnOrFilters = z
  .object({
    eq: z.array(DataLoadingQueryFilterValue),
    eqIgnoreCase: z.array(DataLoadingQueryFilterValue),
    neq: DataLoadingQueryFilterValue, // would be always true with multiple
    neqIgnoreCase: DataLoadingQueryFilterValue, // would be always true with multiple
    gt: DataLoadingQueryFilterValue,
    gte: DataLoadingQueryFilterValue,
    lt: DataLoadingQueryFilterValue,
    lte: DataLoadingQueryFilterValue,
    regex: DataLoadingQueryFilterRegex,
    /**
     * Checks if at least one value from the filter array is in the column
     * Filter and column should both be an array
     * Checks if the intersection between the two arrays is not empty
     * e.g:
     * filter: [1, 2, 6]
     * column: [1, 2, 3, 4, 5]
     * => true because 1 and 2 are in column
     */
    anyInArray: DataLoadingQueryFilterValue,
    /**
     * Checks if at least one value from the filter array is not in the column
     * Filter and column should both be an array
     * e.g:
     * filter: [1, 2, 6]
     * column: [1, 2, 3]
     * => true because 6 is not in column
     */
    noneInArray: DataLoadingQueryFilterValue,
    /**
     * Checks if all values from the filter array are in the column
     * Filter and column should both be an array
     * e.g:
     * filter: [1, 2, 3]
     * column: [1, 2, 3, 4, 5]
     * => true because 1, 2 and 3 are all in column
     */
    allInArray: DataLoadingQueryFilterValue,
    /**
     * Checks if all values from the filter array are not in the column
     * Filter and column should both be an array
     * e.g:
     * filter: [1, 2, 3]
     * column: [4, 5, 6]
     * => true because 1, 2 and 3 are all not in column
     */
    allNotInArray: DataLoadingQueryFilterValue,
  })
  .partial();

export const DataLoadingFilterAndOperator = DataLoadingQueryColumnAndFilters.keyof();
export const DataLoadingFilterOrOperator = DataLoadingQueryColumnOrFilters.keyof();

export type IDataLoadingQueryColumnAndFilters = z.infer<typeof DataLoadingQueryColumnAndFilters>;
export type IDataLoadingQueryColumnOrFilters = z.infer<typeof DataLoadingQueryColumnOrFilters>;
export type IDataLoadingFilterAndOperator = z.infer<typeof DataLoadingFilterAndOperator>;
export type IDataLoadingFilterOrOperator = z.infer<typeof DataLoadingFilterOrOperator>;
export type IDataLoadingQueryFilterValue = z.infer<typeof DataLoadingQueryFilterValue>;
export type IDataLoadingQueryFilterRegex = z.infer<typeof DataLoadingQueryFilterRegex>;

export type IDataLoadingQueryFilterValueLiteral = z.infer<typeof DataLoadingQueryFilterValueLiteral>;
export type IDataLoadingQueryFilterValueReference = z.infer<typeof DataLoadingQueryFilterValueReference>;
