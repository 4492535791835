import type { IAction, IEntityType, IRelation, IStateMachine } from "@archetype/dsl";
import type { IActionId, IColumnId, IEntityTypeId, IRelationId, IStateId } from "@archetype/ids";
import { ActionId, ColumnId, EntityTypeId, RelationId, StateId } from "@archetype/ids";
import { isNonNullable, keyByNoUndefined, mapKeysNoUndefined, mapValues } from "@archetype/utils";
import { pickBy } from "lodash";

import type { IReadableIdMappings } from "../dataModel/inferrenceTypings";
import { optimisticReadableIdentifier, optimisticReadableIdentifierFromString } from "../dataModel/utils";
import type { IReferencedDataModelIds, IStringIdentifierMappings } from "./dataModelReferencesMapperTypes";

type IReadableIdToUuidMapping = IStringIdentifierMappings<{
  ORIG_ENT_T_ID: string;
  TARG_ENT_T_ID: IEntityTypeId;
  ORIG_COL_ID: string;
  TARG_COL_ID: IColumnId;
  ORIG_ACT_ID: string;
  TARG_ACT_ID: IActionId;
  ORIG_REL_ID: string;
  TARG_REL_ID: IRelationId;
  ORIG_STATE_ID: string;
  TARG_STATE_ID: IStateId;
}>;

const readableIdToUuidMappingFromExistingDataModel = ({
  allEntityTypes,
  allActions,
  entityRelations,
  allStates,
}: {
  allEntityTypes: Record<IEntityTypeId, IEntityType>;
  allActions: Record<IActionId, IAction>;
  entityRelations: Record<IRelationId, IRelation>;
  allStates: IStateMachine["states"];
}): IReadableIdToUuidMapping => {
  const stringIdentifierMappings: IReadableIdToUuidMapping = {
    originToTargetIdentifiers: mapValues(
      mapKeysNoUndefined(allEntityTypes, (entityType) => optimisticReadableIdentifier(entityType)),
      (entityType) => ({
        targetIdentifier: entityType.id,
        columnOriginToTargetIdentifiers: mapValues(
          keyByNoUndefined(entityType.columns, (col) => optimisticReadableIdentifier(col)),
          (column) => column.id,
        ),
      }),
    ),
    preMappedParentColumnMapping: mapValues(allEntityTypes, (entityType) => ({
      columnOriginToTargetIdentifiers: mapValues(
        keyByNoUndefined(entityType.columns, (col) => optimisticReadableIdentifier(col)),
        (column) => column.id,
      ),
    })),
    originToTargetActionId: mapValues(
      mapKeysNoUndefined(allActions, (action) => optimisticReadableIdentifier(action)),
      (action) => action.id,
    ),
    originToTargetEntityRelationId: mapValues(entityRelations, (entityRelation) => entityRelation.id),
    originToTargetStateId: pickBy(
      mapValues(
        mapKeysNoUndefined(allStates, (state) =>
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
          state == null ? undefined : optimisticReadableIdentifierFromString(state.label),
        ),
        (state) => state.id,
      ),
      isNonNullable,
    ),
  };

  return stringIdentifierMappings;
};

export const buildReadableIdMappings = ({
  readableIds,
  allEntityTypes,
  allActions,
  entityRelations,
  allStates,
}: {
  readableIds: IReferencedDataModelIds<{
    ORIG_ENT_T_ID: IEntityTypeId;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: IColumnId;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;
    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }>;
  allEntityTypes: Record<IEntityTypeId, IEntityType>;
  allActions: Record<IActionId, IAction>;
  entityRelations: Record<IRelationId, IRelation>;
  allStates: IStateMachine["states"];
}): IReadableIdMappings => {
  const existingReadableIdMappings = readableIdToUuidMappingFromExistingDataModel({
    allEntityTypes,
    allActions,
    entityRelations,
    allStates,
  });

  const parentExistingEntityReadableIdToIds = mapValues(
    readableIds.preMappedEntityTypes,
    (originColumnIds, targetEntityTypeId) => {
      const columnMap: Record<string, IColumnId> = {};

      originColumnIds?.forEach((origColId) => {
        columnMap[origColId] =
          existingReadableIdMappings.preMappedParentColumnMapping[targetEntityTypeId]?.columnOriginToTargetIdentifiers[
            origColId
          ] || ColumnId.generate();
      });

      return {
        columnReadableIdsToIds: columnMap,
      };
    },
  );

  const readableIdsToIds = mapValues(readableIds.newEntityTypes, (originColumnIds, originEntityTypeId) => {
    const columnMap: Record<string, IColumnId> = {};

    originColumnIds?.forEach((origColId) => {
      columnMap[origColId] =
        existingReadableIdMappings.originToTargetIdentifiers[originEntityTypeId]?.columnOriginToTargetIdentifiers[
          origColId
        ] || ColumnId.generate();
    });

    return {
      id:
        existingReadableIdMappings.originToTargetIdentifiers[originEntityTypeId]?.targetIdentifier ||
        EntityTypeId.generate(),
      columnReadableIdsToIds: columnMap,
    };
  });

  const actionReadableIdsToIds = mapValues(
    keyByNoUndefined(Array.from(readableIds.newActions), (origActionId) => origActionId),
    (origActionId) => existingReadableIdMappings.originToTargetActionId[origActionId] || ActionId.generate(),
  );

  const entityRelationReadableIdsToIds = mapValues(
    keyByNoUndefined(Array.from(readableIds.newEntityRelations), (origEntityRelationId) => origEntityRelationId),
    (origEntityRelationId) =>
      existingReadableIdMappings.originToTargetEntityRelationId[origEntityRelationId] || RelationId.generate(),
  );

  const stateReadableIdsToIds = mapValues(
    keyByNoUndefined(Array.from(readableIds.newStates), (originStateId) => originStateId),
    (originStateId) => existingReadableIdMappings.originToTargetStateId[originStateId] || StateId.generate(),
  );

  return {
    readableIdsToIds,
    parentExistingEntityReadableIdToIds,
    actionReadableIdsToIds,
    entityRelationReadableIdsToIds,
    stateReadableIdsToIds,
  };
};
