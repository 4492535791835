import type { ILookupDerivation, IRelationDirection, IStoredViewField } from "@archetype/dsl";
import type { IEntityTypeId, IRelationId } from "@archetype/ids";

export function generateDerivationForLookup({
  viewField,
  basedOnRelationId,
  basedOnRelationDirection,
  entityTypeId,
}: {
  viewField: IStoredViewField;
  basedOnRelationId: IRelationId;
  basedOnRelationDirection: IRelationDirection;
  entityTypeId: IEntityTypeId;
}): ILookupDerivation {
  return {
    type: "lookup",
    element:
      viewField.type === "column"
        ? {
            type: "column",
            columnId: viewField.columnId,
            entityTypeId,
          }
        : {
            type: "relation",
            relationId: viewField.relationId,
            direction: viewField.direction,
          },
    basedOn: {
      type: "relation",
      relationId: basedOnRelationId,
      direction: basedOnRelationDirection,
    },
    filterBy: [],
  };
}
