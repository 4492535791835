import { Badge } from "@archetype/ui";

import type { IDataTableCommonCellProps, IDataTableFileCell } from "./api";

export function FileCell<TRowId extends string, TColumnId extends string>({
  cell,
}: IDataTableCommonCellProps<TRowId, TColumnId, IDataTableFileCell<TRowId, TColumnId>>): React.ReactNode {
  const shortenFileName = (name: string, maxLength: number): string => {
    if (name.length <= maxLength) return name;
    const start = name.slice(0, Math.ceil(maxLength / 2) - 1);
    const end = name.slice(-Math.floor(maxLength / 2) + 2);

    return `${start}...${end}`;
  };
  const handleFileClick =
    (fileUrl: string): React.MouseEventHandler =>
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      window.open(fileUrl, "_blank", "noopener,noreferrer");
    };

  return (
    <div className="flex size-full items-center gap-x-1 overflow-hidden bg-transparent px-2">
      {cell.value.map((file) => (
        <Badge key={file.id} colorVariant="gray" iconLeft="file" size="sm" onClick={handleFileClick(file.fileUrl)}>
          {shortenFileName(file.fileName, 15)}
        </Badge>
      ))}
    </div>
  );
}
