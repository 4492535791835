import type { ILoadedEntity } from "@archetype/core";
import type { IDataLoadingConfig, IDataLoadingQuery } from "@archetype/dsl";
import type { IOrganizationId } from "@archetype/ids";
import type { TRPCClientErrorLike } from "@archetype/trpc-react";
import { builderTrpc } from "@archetype/trpc-react";
import type { InfiniteQueryObserverResult } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { usePrevious } from "react-use";

interface IInfiniteLoadedEntitiesData {
  entities: ILoadedEntity[];
  totalNumberEntities: number;
  totalNumberPages: number;
  nextCursor?: number | null;
}

/**
 * Replacs trpc getLoadedEntity to centralize disabling it to avoid conflicts between reloads and frontend action execution
 */
export const useInfiniteLoadedEntities = ({
  query,
  options: { explicitlyDisabled, refetchInterval },
}: {
  query: {
    organizationId: IOrganizationId;
    dataLoadingQuery: IDataLoadingQuery;
    dataLoadingConfig: Omit<IDataLoadingConfig, "countsOnly">;
  };
  options: {
    /**
     * Explicitly disabled if true, though it may be disabled for other internal reasons
     */
    explicitlyDisabled?: boolean;
    /**
     * Refetch interval in milliseconds
     */
    refetchInterval?: number;
  };
}): {
  entitiesPagedData:
    | {
        pages: Array<IInfiniteLoadedEntitiesData>;
      }
    | undefined;
  isLoadingEntities: boolean;
  isErrorLoadingEntities: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- only for error type
    InfiniteQueryObserverResult<{ pages: Array<IInfiniteLoadedEntitiesData> }, TRPCClientErrorLike<any>>
  >;
  isFetchingNextPage: boolean;
} => {
  const getLoadedEntitiesQuery = builderTrpc.useUtils().dataLoading.getLoadedEntities;

  const previousQuery = usePrevious(query);
  const previousExplicitlyDisabled = usePrevious(explicitlyDisabled);

  useEffect(() => {
    if (
      (previousQuery != null && !isEqual(previousQuery, query)) ||
      (previousExplicitlyDisabled !== explicitlyDisabled && explicitlyDisabled === true)
    ) {
      void getLoadedEntitiesQuery.cancel(previousQuery);
    }
  }, [query, previousQuery, getLoadedEntitiesQuery, explicitlyDisabled, previousExplicitlyDisabled]);

  const {
    data: entitiesPagedData,
    isLoading: isLoadingEntities,
    isError: isErrorLoadingEntities,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = builderTrpc.dataLoading.getLoadedEntities.useInfiniteQuery(query, {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    initialCursor: 0,
    refetchInterval,
    enabled: explicitlyDisabled !== true,
    trpc: {
      abortOnUnmount: true,
      context: {
        skipBatch: true,
      },
    },
  });

  return {
    entitiesPagedData,
    isLoadingEntities,
    isErrorLoadingEntities,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
};
