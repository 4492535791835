import { type IExecuteActionQuery, loadedNestedFormValuesToNestedFormValues } from "@archetype/core";
import { omit } from "lodash";

import type { ILoadedExecuteActionQuery } from "./types";

export const loadedExecuteActionQueryToQuery = (
  loadedExecuteActionQuery: ILoadedExecuteActionQuery,
): IExecuteActionQuery => {
  return {
    ...omit(loadedExecuteActionQuery, "loadedNestedCreateFormValues"),
    nestedCreateFormValues: loadedNestedFormValuesToNestedFormValues(
      loadedExecuteActionQuery.loadedNestedCreateFormValues,
    ),
  };
};
