import type { IEntityType } from "@archetype/dsl";
import { keyByNoUndefined } from "@archetype/utils";
import { isEqual } from "lodash";

interface IEntityTypeInfo {
  entityType: IEntityType;
}

interface IEntityTypeVersionDiff {
  addedToColumns: IEntityType["columns"];
  modifiedToColumns: IEntityType["columns"];
  removedFromColumns: IEntityType["columns"];
}

export const diffEntityTypeVersions = (
  entityTypeInfoAtVersionFrom: IEntityTypeInfo,
  entityTypeInfoAtVersionTo: IEntityTypeInfo,
): IEntityTypeVersionDiff => {
  const columnsFrom = keyByNoUndefined(entityTypeInfoAtVersionFrom.entityType.columns, (col) => col.id);
  const columnsTo = keyByNoUndefined(entityTypeInfoAtVersionTo.entityType.columns, (col) => col.id);

  return {
    addedToColumns: entityTypeInfoAtVersionTo.entityType.columns.filter((col) => columnsFrom[col.id] == null),
    modifiedToColumns: entityTypeInfoAtVersionTo.entityType.columns.filter((colAtVersionTo) => {
      const colAtVersionFrom = columnsFrom[colAtVersionTo.id];

      if (colAtVersionTo.displayMetadata.name !== colAtVersionFrom?.displayMetadata.name) {
        return true;
      }

      // This could be richer as we enrich the semantics of types and use it in data generation
      if (!isEqual(colAtVersionFrom.columnType, colAtVersionTo.columnType)) {
        return true;
      }

      return false;
    }),
    removedFromColumns: entityTypeInfoAtVersionFrom.entityType.columns.filter((col) => columnsTo[col.id] == null),
  };
};
