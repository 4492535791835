import { forEach } from "@archetype/utils";
import dagre from "@dagrejs/dagre";

export function parseStateMachineToGraph(stateMachine: {
  states: Record<string, { label: string }>;
  stateTransitions: Array<{ from: string; to: string; id: string }>;
}): dagre.graphlib.Graph {
  const g = new dagre.graphlib.Graph({
    multigraph: true, // We set multigraph so we can use the action id as the name of an edge, and support multiple edges between nodes
  });

  // Set an object for the graph label
  g.setGraph({});

  // Default to assigning a new object as a label for each new edge.
  g.setDefaultEdgeLabel(() => ({}));

  // Adding states as nodes
  forEach(stateMachine.states, (data, stateId) => {
    g.setNode(stateId, { label: data.label });
  });

  // Adding transitions as edges
  stateMachine.stateTransitions.forEach((transition) => {
    g.setEdge(transition.from, transition.to, {}, transition.id);
  });

  dagre.layout(g);

  return g;
}
