import { FileId } from "@archetype/ids";
import * as z from "zod";

export const DateColumnValue = z.object({
  type: z.literal("date"),
  value: z.string(),
});
export type IDateColumnValue = z.infer<typeof DateColumnValue>;

export const TimestampColumnValue = z.object({
  type: z.literal("timestamp"),
  value: z.string(),
});
export type ITimestampColumnValue = z.infer<typeof TimestampColumnValue>;

// todo alon: move to a better location
export const FileVendor = z.enum(["vercel"]);
export type IFileVendor = z.infer<typeof FileVendor>;

export const FileEntityColumnValue = z.object({
  id: FileId,
  vendor: FileVendor,
  fileName: z.string(),
  fileUrl: z.string(),
  fileSizeInBytes: z.number().int(),
});
export type IFileEntityColumnValue = z.infer<typeof FileEntityColumnValue>;

export const FileColumnValue = z.object({
  type: z.literal("file"),
  value: z.array(FileEntityColumnValue),
});
export type IFileColumnValue = z.infer<typeof FileColumnValue>;

export const StringColumnValue = z.object({
  type: z.literal("string"),
  value: z.string(),
});
export type IStringColumnValue = z.infer<typeof StringColumnValue>;

export const IntegerColumnValue = z.object({
  type: z.literal("integer"),
  value: z.number().int(),
});
export type IIntegerColumnValue = z.infer<typeof IntegerColumnValue>;

export const NumberColumnValue = z.object({
  type: z.literal("number"),
  value: z.number(),
});
export type INumberColumnValue = z.infer<typeof NumberColumnValue>;

export const BooleanColumnValue = z.object({
  type: z.literal("boolean"),
  value: z.coerce.boolean(),
});
export type IBooleanColumnValue = z.infer<typeof BooleanColumnValue>;

export const ArrayColumnValue = z.object({
  type: z.literal("array"),
  value: z.array(z.string()),
});
export type IArrayColumnValue = z.infer<typeof ArrayColumnValue>;

export const NullColumnValue = z.object({
  type: z.literal("null"),
});
export type INullColumnValue = z.infer<typeof NullColumnValue>;

export const IdLikeEntityColumnValue = z.discriminatedUnion("type", [
  StringColumnValue,
  IntegerColumnValue,
  NumberColumnValue,
  FileColumnValue,
  ArrayColumnValue,
]);
export type IIdLikeEntityColumnValue = z.infer<typeof IdLikeEntityColumnValue>;

export const EntityColumnValueWithoutNull = z.discriminatedUnion("type", [
  ...IdLikeEntityColumnValue.options,
  BooleanColumnValue,
  DateColumnValue,
  TimestampColumnValue,
]);
export type IEntityColumnValueWithoutNull = z.infer<typeof EntityColumnValueWithoutNull>;

export const EntityColumnValue = z.discriminatedUnion("type", [
  ...EntityColumnValueWithoutNull.options,
  NullColumnValue,
]);
export type IEntityColumnValue = z.infer<typeof EntityColumnValue>;
