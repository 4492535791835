import type { IAutofill, IDisplayMetadata, ILLMRelation, ILLMRelationAutofill, IRelationCore } from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";
import assertNever from "assert-never";

export function convertRelationAutofillToLLMRelationAutofill(
  autofill: IAutofill | undefined,
): ILLMRelationAutofill | null {
  if (autofill == null) {
    return null;
  }

  switch (autofill.config.type) {
    case "static": {
      return null;
    }
    case "dynamic": {
      if (autofill.config.value !== "currentUser") {
        return null;
      }

      return {
        dynamicValue: "currentUser",
      };
    }
    case "logic":
    case "lookup": {
      return {
        logicDescription: autofill.config.rawLogic,
        recomputationType: autofill.type === "live" ? "live" : "suggestion",
      };
    }
    case "ai": {
      return {
        aiInstructions: autofill.config.rawInstructions,
        recomputationType: autofill.type === "live" ? "live" : "suggestion",
      };
    }
    default: {
      assertNever(autofill.config);
    }
  }
}

export function convertRelationToLLMRelation({
  relation,
  entityTypeA,
  entityTypeB,
}: {
  relation: IRelationCore;
  entityTypeA: { id: IEntityTypeId; displayMetadata: IDisplayMetadata };
  entityTypeB: { id: IEntityTypeId; displayMetadata: IDisplayMetadata };
}): ILLMRelation {
  return {
    relationId: relation.id,
    titleForBEntitiesWhenOnA: relation.displayMetadataFromAToB.name,
    titleForAEntitiesWhenOnB: relation.displayMetadataFromBToA.name,
    entityTypeATitle: entityTypeA.displayMetadata.name,
    entityTypeBTitle: entityTypeB.displayMetadata.name,
    entityTypeAId: entityTypeA.id,
    entityTypeBId: entityTypeB.id,
    cardinalityOnSideA: relation.config.cardinalityOnSideA,
    cardinalityOnSideB: relation.config.cardinalityOnSideB,
    autofill:
      relation.autofill == null
        ? null
        : {
            direction: relation.autofill.direction,
            autofill: convertRelationAutofillToLLMRelationAutofill(relation.autofill.autofill),
          },
  };
}
