import type { IRelationCore } from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";
import { Skeleton } from "@archetype/ui";

export const RelationName: React.FC<{
  relation: IRelationCore | undefined;
  displayingOnEntityTypeId: IEntityTypeId;
  isLoading: boolean;
}> = ({ relation, displayingOnEntityTypeId, isLoading }) => {
  if (isLoading) {
    return <Skeleton>Column name</Skeleton>;
  }

  if (relation == null) {
    return <div className="italic">Unknown</div>;
  }

  const relationName =
    relation.entityTypeIdA === displayingOnEntityTypeId
      ? relation.displayMetadataFromAToB
      : relation.displayMetadataFromBToA;

  return <div>{relationName.name}</div>;
};
