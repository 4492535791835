import {
  ActionButtonComponentDefinitionId,
  ActionId,
  BoardComponentDefinitionId,
  CardListComponentDefinitionId,
  ChartComponentDefinitionId,
  DescriptionListComponentDefinitionId,
  EntityDetailsComponentDefinitionId,
  EntityListComponentDefinitionId,
  EventListComponentDefinitionId,
  FilterListComponentDefinitionId,
  ImageComponentDefinitionId,
  ListComponentDefinitionId,
  MapComponentDefinitionId,
  MetricCardComponentDefinitionId,
  SearchInputComponentDefinitionId,
  TableComponentDefinitionId,
  TableGroupedComponentDefinitionId,
} from "@archetype/ids";
import { z } from "zod";

import {
  ActionConfigValue,
  EntityColumnConfigValue,
  EntityColumnListConfigValue,
  EntityRelationConfigValue,
} from "./ConfigTypes";

export const ActionButtonFunctionalConfig = z.object({
  componentDefinitionId: z.literal(ActionButtonComponentDefinitionId),
  functionalConfig: z.object({
    actionId: ActionId,
  }),
});

export const ChartFunctionalConfig = z.object({
  componentDefinitionId: z.literal(ChartComponentDefinitionId),
  functionalConfig: z.object({}),
});

export const DescriptionListFunctionalConfig = z.object({
  componentDefinitionId: z.literal(DescriptionListComponentDefinitionId),
  functionalConfig: z.object({}),
});

export const EntityListFunctionalConfig = z.object({
  componentDefinitionId: z.literal(EntityListComponentDefinitionId),
  functionalConfig: z.object({}),
});

export const EventListFunctionalConfig = z.object({
  componentDefinitionId: z.literal(EventListComponentDefinitionId),
  functionalConfig: z.object({}),
});

export const FilterListFunctionalConfig = z.object({
  componentDefinitionId: z.literal(FilterListComponentDefinitionId),
  functionalConfig: z.object({
    filterColumns: EntityColumnListConfigValue,
  }),
});

export const ImageFunctionalConfig = z.object({
  componentDefinitionId: z.literal(ImageComponentDefinitionId),
  functionalConfig: z.object({}),
});

export const ListFunctionalConfig = z.object({
  componentDefinitionId: z.literal(ListComponentDefinitionId),
  functionalConfig: z.object({
    columnsToDisplay: EntityColumnListConfigValue,
    entityRelations: z.array(EntityRelationConfigValue),
    inlineActions: z.array(ActionConfigValue),
  }),
});

export const MapFunctionalConfig = z.object({
  componentDefinitionId: z.literal(MapComponentDefinitionId),
  functionalConfig: z.object({}),
});

export const MetricCardFunctionalConfig = z.object({
  componentDefinitionId: z.literal(MetricCardComponentDefinitionId),
  functionalConfig: z.object({}),
});

export const SearchInputFunctionalConfig = z.object({
  componentDefinitionId: z.literal(SearchInputComponentDefinitionId),
  functionalConfig: z.object({
    columnsToSearch: EntityColumnListConfigValue,
  }),
});

export const TableFunctionalConfig = z.object({
  componentDefinitionId: z.literal(TableComponentDefinitionId),
  functionalConfig: z.object({
    columnsToDisplay: EntityColumnListConfigValue,
    entityRelations: z.array(EntityRelationConfigValue),
    inlineActions: z.array(ActionConfigValue),
  }),
});

export const TableGroupedFunctionalConfig = z.object({
  componentDefinitionId: z.literal(TableGroupedComponentDefinitionId),
  functionalConfig: z.object({
    groupByColumn: EntityColumnConfigValue,
    columnsToDisplay: EntityColumnListConfigValue,
    entityRelations: z.array(EntityRelationConfigValue),
    inlineActions: z.array(ActionConfigValue),
  }),
});

export const CardListFunctionalConfig = z.object({
  componentDefinitionId: z.literal(CardListComponentDefinitionId),
  functionalConfig: z.object({
    groupByColumn: EntityColumnConfigValue,
    columnsToDisplay: EntityColumnListConfigValue,
    inlineActions: z.array(ActionConfigValue),
  }),
});

export const BoardFunctionalConfig = z.object({
  // id allows to enforce some consistency between the functional configuration and the component it is for
  componentDefinitionId: z.literal(BoardComponentDefinitionId),
  functionalConfig: z.object({
    groupByColumn: EntityColumnConfigValue,
    columnsToDisplay: EntityColumnListConfigValue,
    inlineActions: z.array(ActionConfigValue),
  }),
});

export const EntityDetailsFunctionalConfig = z.object({
  componentDefinitionId: z.literal(EntityDetailsComponentDefinitionId),
  functionalConfig: z.object({
    columnsToDisplay: EntityColumnListConfigValue,
  }),
});

export const ComponentFunctionalConfig = z.discriminatedUnion("componentDefinitionId", [
  BoardFunctionalConfig,
  ActionButtonFunctionalConfig,
  ChartFunctionalConfig,
  DescriptionListFunctionalConfig,
  EntityListFunctionalConfig,
  EventListFunctionalConfig,
  FilterListFunctionalConfig,
  ImageFunctionalConfig,
  ListFunctionalConfig,
  MapFunctionalConfig,
  MetricCardFunctionalConfig,
  SearchInputFunctionalConfig,
  TableFunctionalConfig,
  TableGroupedFunctionalConfig,
  CardListFunctionalConfig,
  EntityDetailsFunctionalConfig,
]);

export type IBoardFunctionalConfig = z.infer<typeof BoardFunctionalConfig>;
export type IActionButtonFunctionalConfig = z.infer<typeof ActionButtonFunctionalConfig>;
export type IChartFunctionalConfig = z.infer<typeof ChartFunctionalConfig>;
export type IDescriptionListFunctionalConfig = z.infer<typeof DescriptionListFunctionalConfig>;
export type IEntityListFunctionalConfig = z.infer<typeof EntityListFunctionalConfig>;
export type IEventListFunctionalConfig = z.infer<typeof EventListFunctionalConfig>;
export type IFilterListFunctionalConfig = z.infer<typeof FilterListFunctionalConfig>;
export type IImageFunctionalConfig = z.infer<typeof ImageFunctionalConfig>;
export type IListFunctionalConfig = z.infer<typeof ListFunctionalConfig>;
export type IMapFunctionalConfig = z.infer<typeof MapFunctionalConfig>;
export type IMetricCardFunctionalConfig = z.infer<typeof MetricCardFunctionalConfig>;
export type ISearchInputFunctionalConfig = z.infer<typeof SearchInputFunctionalConfig>;
export type ITableFunctionalConfig = z.infer<typeof TableFunctionalConfig>;
export type ITableGroupedFunctionalConfig = z.infer<typeof TableGroupedFunctionalConfig>;
export type ICardListFunctionalConfig = z.infer<typeof CardListFunctionalConfig>;
export type IEntityViewFunctionalConfig = z.infer<typeof EntityDetailsFunctionalConfig>;

export type IComponentFunctionalConfig = z.infer<typeof ComponentFunctionalConfig>;
