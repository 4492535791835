import { ColumnTypeId, ColumnTypeVersionId } from "@archetype/ids";

import type { IFullColumnTypeTree } from "../../../utils/dataModel/resolveTypeTree";
import { BUILT_IN_DATA_MODEL_METADATA_INFO } from "./common";

export const StringTree: IFullColumnTypeTree = {
  columnType: {
    id: ColumnTypeId.parse("3eba21e1-7cc4-4815-953e-3171b6781a23"),
    versionId: ColumnTypeVersionId.parse("3b1f0a9e-9d29-4b81-a91c-91321f781275"),
    version: {
      major: 1,
      minor: 0,
      patch: 0,
    },
    updatedAt: new Date(),
    basedOn: null,
    computedFrom: BUILT_IN_DATA_MODEL_METADATA_INFO,
    originVersionId: null,
    definition: {
      type: "string",
      primitiveType: "string",
    },
    entityTypeId: null,
    columnId: null,
  },
  child: null,
};

export const NumberTree: IFullColumnTypeTree = {
  columnType: {
    id: ColumnTypeId.parse("f8ceb7c4-9085-4e94-8b3b-ba8f6f752d6b"),
    versionId: ColumnTypeVersionId.parse("f8ceb7c4-9085-4e94-8b3b-ba8f6f752d6b"),
    version: {
      major: 1,
      minor: 0,
      patch: 0,
    },
    updatedAt: new Date(),
    basedOn: null,
    computedFrom: BUILT_IN_DATA_MODEL_METADATA_INFO,
    originVersionId: null,
    definition: {
      type: "number",
      primitiveType: "number",
    },
    entityTypeId: null,
    columnId: null,
  },
  child: null,
};

export const BooleanTree: IFullColumnTypeTree = {
  columnType: {
    id: ColumnTypeId.parse("60d72c8d-5d75-4899-930c-339021c67ec9"),
    versionId: ColumnTypeVersionId.parse("60d72c8d-5d75-4899-930c-339021c67ec9"),
    version: {
      major: 1,
      minor: 0,
      patch: 0,
    },
    updatedAt: new Date(),
    basedOn: null,
    computedFrom: BUILT_IN_DATA_MODEL_METADATA_INFO,
    originVersionId: null,
    definition: {
      type: "boolean",
      primitiveType: "boolean",
    },
    entityTypeId: null,
    columnId: null,
  },
  child: null,
};

export const DateTree: IFullColumnTypeTree = {
  columnType: {
    id: ColumnTypeId.parse("8eb3a19b-26c1-4682-a984-4d534df50dab"),
    versionId: ColumnTypeVersionId.parse("8eb3a19b-26c1-4682-a984-4d534df50dab"),
    version: {
      major: 1,
      minor: 0,
      patch: 0,
    },
    updatedAt: new Date(),
    basedOn: null,
    computedFrom: BUILT_IN_DATA_MODEL_METADATA_INFO,
    originVersionId: null,
    definition: {
      type: "date",
      primitiveType: "string",
    },
    entityTypeId: null,
    columnId: null,
  },
  child: null,
};

export const TimestampTree: IFullColumnTypeTree = {
  columnType: {
    id: ColumnTypeId.parse("298c5492-8dee-4bf8-a782-a20e307f632f"),
    versionId: ColumnTypeVersionId.parse("298c5492-8dee-4bf8-a782-a20e307f632f"),
    version: {
      major: 1,
      minor: 0,
      patch: 0,
    },
    updatedAt: new Date(),
    basedOn: null,
    computedFrom: BUILT_IN_DATA_MODEL_METADATA_INFO,
    originVersionId: null,
    definition: {
      type: "timestamp",
      primitiveType: "string",
    },
    entityTypeId: null,
    columnId: null,
  },
  child: null,
};

export const GeolocationTree: IFullColumnTypeTree = {
  columnType: {
    id: ColumnTypeId.parse("e74849e7-e23d-4a18-b39c-0d9428859d8d"),
    versionId: ColumnTypeVersionId.parse("e74849e7-e23d-4a18-b39c-0d9428859d8d"),
    version: {
      major: 1,
      minor: 0,
      patch: 0,
    },
    updatedAt: new Date(),
    basedOn: null,
    computedFrom: BUILT_IN_DATA_MODEL_METADATA_INFO,
    originVersionId: null,
    definition: {
      type: "geolocation",
      primitiveType: "string",
    },
    entityTypeId: null,
    columnId: null,
  },
  child: null,
};
