import { ActionId, ColumnId, EntityTypeId, FeatureId, RelationId } from "@archetype/ids";
import type { AssertFalse, IsNever } from "@archetype/utils";
import { z } from "zod";

import { IdentifiableFeature } from "./common/IdentifiableFeature";

// TODO Add references to views and action views
// TODO Add same in module for embedding and add the app concepts etc

// Description overwritten in each type for model
export const ParentReference = z.object({
  // Types must repeated for intersection checks
  type: z.union([
    z.literal("dataDisplay"),
    z.literal("searchFeature"),
    z.literal("filterFeature"),
    z.literal("dataLoading"),
    z.literal("metricSubview"),
    z.literal("actionFeature"),
  ]),
  parent: FeatureId,
});

export const MultiColumnSelection = z
  .object({
    columnIds: z.array(ColumnId),
  })
  .describe("A selection of columns from an entity");

export const MultiEntityRelationSelection = z
  .object({
    entityRelationIds: z.array(RelationId),
  })
  .describe("A selection of entity relations");

export const KnownDataViewType = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("list"),
  }),
  z.object({
    type: z.literal("table"),
  }),
  z
    .object({
      type: z.literal("groupedTable"),
      groupByColumn: ColumnId,
    })
    .describe("A grouped table view needs a column to group by, e.g. status, or type"),
  z.object({
    type: z.literal("single").describe("A single entity view"),
  }),
  z.object({
    type: z.literal("map"),
    geospatialColumn: ColumnId,
  }),
  z
    .object({
      type: z.literal("board"),
      /**
       * Must be a low cardinality directional enum column
       */
      groupByColumn: ColumnId,
    })
    .describe("A board view needs a column to group by, e.g. status, or type"),
  z
    .object({
      type: z.literal("cardList"),
      /**
       * Must be a low cardinality directional enum column
       */
      groupByColumn: ColumnId,
    })
    .describe("A card list view needs a column to group by, e.g. status, or type"),
]);

export const DataViewFeature = z
  .object({
    type: z.literal("dataView"),

    /**
     * data model reference, can make that more detailed with columns etc
     */
    dataModel: EntityTypeId,

    dataViewType: KnownDataViewType,
  })
  .describe("Describes the type of view in which the data is displayed, created, or modified");

export const DataDisplayFeature = ParentReference.merge(
  z.object({
    type: z.literal("dataDisplay"),
    // Note: this could also enable more view types per column.
    columns: MultiColumnSelection.describe("The columns that can be searched on"),
    parent: FeatureId.describe(
      "The ID of the parent feature that the columns are going to be shown on, it that defines the data model and the view",
    ),
    entityRelations: z.optional(MultiEntityRelationSelection).describe("The related entity types"),
  }),
).describe("Describes which columns to display in a parent data view");
export const DataDisplayFeatureIntersection = z.intersection(ParentReference, DataDisplayFeature);
export type ICheckDataDisplayParent = AssertFalse<IsNever<z.infer<typeof DataDisplayFeatureIntersection>>>;

export const SearchFeature = z
  .object({
    type: z.literal("searchFeature"),
    // Note to self: should this be here or in a "composition type between all those things"
    columns: MultiColumnSelection.describe("The columns that can be searched on"),

    parent: FeatureId.describe(
      "The ID of the parent feature that the columns are going to be shown on, it that defines the data model and the view",
    ),
  })
  .describe("Describes a search functionality on the data defined by the parent feature");
export const SearchFeatureIntersection = z.intersection(ParentReference, SearchFeature);
export type ICheckSearchParent = AssertFalse<IsNever<z.infer<typeof SearchFeatureIntersection>>>;

/**
 * This is not a filter in data loading, but a user's ability to filter in the UI
 * If a data loading feature has been set on a column for which a filter should be shown here, then the loading defines the default filter state
 */
export const FilterFeature = z.object({
  type: z.literal("filterFeature"), // TBC, may be required for deser
  // Note to self: should this be here or in a "composition type between all those things"
  columns: MultiColumnSelection.describe("The columns that can be filtered on"),

  parent: FeatureId.describe(
    "The ID of the parent feature that the columns are going to be shown on, it that defines the data model and the view",
  ),

  filterViewType: z.object({}), // TODO
});

export const FilterFeatureIntersection = z.intersection(ParentReference, FilterFeature);
export type ICheckFilterParent = AssertFalse<IsNever<z.infer<typeof FilterFeatureIntersection>>>;

export const FilterValue = z.discriminatedUnion("type", [
  z.object({ type: z.literal("number"), value: z.number() }),
  z.object({ type: z.literal("string"), value: z.string() }),
]);

export const DataLoadingFeatureConstraints = z.object({
  filters: z
    .optional(
      z.array(
        z.object({
          column: ColumnId,
          value: FilterValue,
        }),
      ),
    )
    .describe(
      "Filters to apply to the data, e.g. status is active, or tier is gold. Note this is different from a UI filter feature for end users because a value is provided here, as a default filter when loading any data. This can be recognised when there is a statement like 'is filtered to' or 'is limited to', and a value is provided.",
    ),
  /**
   * Ordered list of columns to sort by
   */
  // TODO (julien) : add ASC/DESC or time based
  sorts: MultiColumnSelection.describe(
    "How the data should be sorted when loaded, e.g. by date, or by value to be rendered in a list.",
  ),
  // Need to add grouping etc... But there's a UX question on if grouping should be a transformation on the data schema
});

export const DataLoadingFeature = z
  .object({
    type: z.literal("dataLoading"),
    // TODO more complete DSL, pretty standard
    parent: FeatureId.describe(
      "The ID of the parent feature that the columns are going to be shown on, it that defines the data model and the view",
    ),

    constraints: DataLoadingFeatureConstraints,
  })
  .describe("How the data should be loaded, e.g. filters, sorts, grouping, etc...");
export const DataLoadingFeatureIntersection = z.intersection(ParentReference, DataLoadingFeature);
export type ICheckDataLoadingParent = AssertFalse<IsNever<z.infer<typeof DataLoadingFeatureIntersection>>>;

export const MetricSubviewFeatureAggregation = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("count"),
  }),
  z.object({
    type: z.literal("average"),
    /**
     * Note this is only on a core column of the entity, not on a join entity. This means that to have a metric
     * on a an entity that can be joined (e.g. "max value of items in the orders")
     */
    column: ColumnId,
  }),
  z.object({
    type: z.literal("sum"),
    column: ColumnId,
  }),
  z.object({
    type: z.literal("max"),
    column: ColumnId,
  }),
  z.object({
    type: z.literal("min"),
    column: ColumnId,
  }),
  // etc...
]);

export const MetricSubviewFeatureTimeAggregation = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("all"),
  }),
  z.object({
    type: z.literal("month"),
  }),
  z.object({
    type: z.literal("week"),
  }),
  z.object({
    type: z.literal("day"),
  }),
  z.object({
    type: z.literal("windowLength"),
    /**
     * Time before now in the window
     */
    windowLength: z.number(),
    windowUnit: z.enum(["hour", "day", "week", "month", "year"]),
  }),
]);

export const MetricSubviewFeatureType = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("latest"),
  }),
  z.object({
    type: z.literal("timeSeries"),
  }),
]);

export const MetricSubviewFeature = z
  .object({
    type: z.literal("metricSubview"),
    parent: FeatureId.describe(
      "A metric is always nested within another requirement that defines the data model to run the metric on (and layout to show it in)",
    ),
    aggregation: MetricSubviewFeatureAggregation,
    /**
     * Only for time series data
     */
    timeAggregation: MetricSubviewFeatureTimeAggregation.optional(),
    /**
     * @default inferred from data type
     *
     * // TBD: Not even sure what that would be yet
     */
    metricViewType: MetricSubviewFeatureType.optional(),

    // TODO: Allow conditional formatting in nested requirements? That would also make sense for non metric!
  })
  .describe(
    "A metric to show show in the UI, e.g. a count of orders, or the average value of orders, along with how it should be shown",
  );
export const MetricSubviewFeatureIntersection = z.intersection(ParentReference, MetricSubviewFeature);
export type ICheckMetricSubviewParent = AssertFalse<IsNever<z.infer<typeof MetricSubviewFeatureIntersection>>>;

// TODO: @ShaunSpringer this should be renamed to something like "displayStyle" or "renderingStyle"
export const ActionFeatureDisplayStyle = z
  .discriminatedUnion("type", [
    z.object({
      type: z.literal("bulk").describe("An action across multiple items at the same time."),
    }),
    z.object({
      type: z
        .literal("single")
        .describe(
          "A single item entry. Like adding a new item via a form, or editing the single item in a discrete view",
        ),
    }),
    z.object({
      type: z.literal("inline").describe("An inline action in a view such as a table or a list."),
    }),
  ])
  .describe(
    "How the action appears to a user. For instance, 'single' would be a form that allows creation of new items, or editing of a single item at a time, while bulk allows editing multiple items at once, and inline allows for updating a single item inline in a data view such as a table.",
  );

export const ActionAvailableFeature = z.object({
  type: z.literal("actionFeature"),

  parent: FeatureId.describe("The view the action should be show in. All actions must be represented in a view"),

  action: ActionId,
  /**
   * Both a reference to data and to the workflow at the same time, e.g. "on the order table row", or "on bulk"
   * this could have consequences on the UI as well, e.g. it could add a comment text area inline (may be tricky?)
   */
  displayStyle: ActionFeatureDisplayStyle,
});
export const ActionFeatureIntersection = z.intersection(ParentReference, ActionAvailableFeature);
export type ICheckActionFeatureParent = AssertFalse<IsNever<z.infer<typeof ActionFeatureIntersection>>>;

export const FreeTextConfigurationFeature = z.object({
  type: z.literal("freeTextConfiguration"),
  /**
   * The component that this configuration is for, described by the requirement it originates from
   *
   * Nto sure how to encode that yet
   */
  referencedComponent: FeatureId,
  /**
   * Note this should never be required, components have good defaults without this, but it may enable more configuration space.
   */
  freeText: z
    .string()
    .describe("The free text that will be compiled into a configuration for the referenced component"),
});

export const ViewFeature = z.discriminatedUnion("type", [
  DataViewFeature.merge(IdentifiableFeature),
  DataDisplayFeature.merge(IdentifiableFeature),
  SearchFeature.merge(IdentifiableFeature),
  FilterFeature.merge(IdentifiableFeature),
  DataLoadingFeature.merge(IdentifiableFeature),
  MetricSubviewFeature.merge(IdentifiableFeature),
  FreeTextConfigurationFeature.merge(IdentifiableFeature),
  ActionAvailableFeature.merge(IdentifiableFeature),
  // TODO add navigation / subviews!!!
]);
export const NestedViewFeature = z.intersection(ViewFeature, ParentReference);

export type IMultiColumnSelection = z.infer<typeof MultiColumnSelection>;
export type IKnownDataViewType = z.infer<typeof KnownDataViewType>;
export type IDataViewFeature = z.infer<typeof DataViewFeature>;
export type IDataDisplayFeature = z.infer<typeof DataDisplayFeature>;
export type ISearchFeature = z.infer<typeof SearchFeature>;
export type IFilterFeature = z.infer<typeof FilterFeature>;
export type IFilterValue = z.infer<typeof FilterValue>;
export type IDataLoadingFeature = z.infer<typeof DataLoadingFeature>;
export type IDataLoadingFeatureConstraints = z.infer<typeof DataLoadingFeatureConstraints>;
export type IMetricSubviewFeature = z.infer<typeof MetricSubviewFeature>;
export type IMetricSubviewFeatureAggregation = z.infer<typeof MetricSubviewFeatureAggregation>;
export type IMetricSubviewFeatureTimeAggregation = z.infer<typeof MetricSubviewFeatureTimeAggregation>;
export type IMetricSubviewFeatureType = z.infer<typeof MetricSubviewFeatureType>;
export type IActionFeatureDisplayStyle = z.infer<typeof ActionFeatureDisplayStyle>;
export type IActionAvailableFeature = z.infer<typeof ActionAvailableFeature>;
export type IFreeTextConfigurationFeature = z.infer<typeof FreeTextConfigurationFeature>;

export type IViewFeature = z.infer<typeof ViewFeature>;
export type INestedViewFeature = z.infer<typeof NestedViewFeature>;

// Business constraints on the data model: e.g. you can't get refunds after 30 days
// IT should have access to that, and then if they do, anyone can use those safely

/**
 * Examples
 * 
 *
"Admin users can view orders in a table" -> type: view, persona: Admin users, data: orders, dataViewType: table

"I can view customers in a list" -> type: view, persona: I, data: customers, dataViewType: list

"There, I can view the order id, name and email" -> type: MultiColumnSelection, persona: I, columns: [order id, name, email]

"Operator users can search orders by id, name, email" -> type: SearchFeature, persona: Operator users, data: orders, columns: [id, name, email]

"All users can filter by status" -> type: filterFeature, persona: All users, column: status

"Customers are filtered to status is active" -> type: DataLoadingFilter, Data: Customers, filter: { column: status, operator: is, value: active }

Admin users can view orders in a table
I can view customers in a list
There, I can view the order id, name and email
Operator users can search orders by id, name, email
All users can filter by status
Customers are filtered to status is active


Admin_users can view orders in a table
I can view customers in a list
There, I can view the order id, name and email
There, I can view the order_id, name and email
There, I can view order_id, name and email
There, I can view the order_id, name, email
Operator_users can search orders by id, name, email
All_users can filter by status, name and email
All_users can filter by status on a map
Customers are filtered to status is active

 * 
 */
