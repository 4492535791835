"use client";

import React, { useEffect, useRef, useState } from "react";

import { cn } from "../../lib/utils";

export interface IReasoningStatusStep {
  id: string;
  text: string;
}

interface IReasoningStatusProps {
  reasoningSteps?: IReasoningStatusStep[];
  minDisplayTime?: number;
  typingSpeed?: number;
  className?: string;
  onStepDisplayed?: (stepIndex: number) => void;
}

export const ReasoningStatus: React.FC<IReasoningStatusProps> = ({
  reasoningSteps = [],
  minDisplayTime = 2000,
  typingSpeed = 30,
  className,
  onStepDisplayed,
}) => {
  // State simplification: Track index, current target text, displayed text, and phase
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [currentStepText, setCurrentStepText] = useState<string>("");
  const [displayedText, setDisplayedText] = useState<string>("");
  const [phase, setPhase] = useState<"idle" | "typing" | "displaying">("idle");

  // Ref to prevent state updates after unmount in async callbacks
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return (): void => {
      isMounted.current = false;
    };
  }, []);

  // Effect 1: Start next step or handle initialization/reset
  useEffect(() => {
    if (!isMounted.current) return;

    // Case 1: Idle and there are steps to process
    if (phase === "idle" && reasoningSteps.length > 0 && currentStepIndex < reasoningSteps.length) {
      const nextStep = reasoningSteps[currentStepIndex];

      // Ensure the step exists before proceeding (belt-and-suspenders check)
      if (nextStep) {
        setCurrentStepText(nextStep.text);
        setDisplayedText("");
        setPhase("typing");
      }
    }
    // Case 2: Idle, but index is out of bounds (e.g., steps were removed) - Reset to last step
    else if (phase === "idle" && currentStepIndex >= reasoningSteps.length && reasoningSteps.length > 0) {
      const lastStepIndex = reasoningSteps.length - 1;
      const lastStep = reasoningSteps[lastStepIndex];

      if (lastStep) {
        setCurrentStepIndex(lastStepIndex);
        setCurrentStepText(lastStep.text);
        setDisplayedText(lastStep.text);
        setPhase("displaying");
      }
    }
    // Case 3: Idle and no steps - Clear everything
    else if (phase === "idle" && reasoningSteps.length === 0) {
      setCurrentStepIndex(0);
      setCurrentStepText("");
      setDisplayedText("");
    }
  }, [phase, currentStepIndex, reasoningSteps]);

  // Effect 2: Typewriter animation
  useEffect(() => {
    if (!isMounted.current || phase !== "typing") return;

    if (displayedText.length < currentStepText.length) {
      const timer = setTimeout(() => {
        if (!isMounted.current) return;
        setDisplayedText(currentStepText.substring(0, displayedText.length + 1));
      }, typingSpeed);

      return (): void => {
        clearTimeout(timer);
      };
    }
    // Typing finished, move to displaying phase and trigger callback
    setPhase("displaying");
    onStepDisplayed?.(currentStepIndex);
  }, [phase, currentStepText, displayedText, typingSpeed, onStepDisplayed, currentStepIndex]);

  // Effect 3: Display hold and advance logic
  useEffect(() => {
    if (!isMounted.current || phase !== "displaying") return;

    const displayTimer = setTimeout(() => {
      if (!isMounted.current) return;

      const hasMoreSteps = currentStepIndex < reasoningSteps.length - 1;

      if (hasMoreSteps) {
        setCurrentStepIndex((prevIndex) => prevIndex + 1);
        setPhase("idle");
      }
    }, minDisplayTime);

    return (): void => {
      clearTimeout(displayTimer);
    };
  }, [phase, currentStepIndex, reasoningSteps, minDisplayTime]);

  // Effect 4: Handle reasoningSteps updates (shrinking list or becoming empty)
  useEffect(() => {
    if (!isMounted.current) return;

    if (currentStepIndex >= reasoningSteps.length && reasoningSteps.length > 0) {
      const lastStepIndex = reasoningSteps.length - 1;
      const lastStep = reasoningSteps[lastStepIndex];

      if (lastStep) {
        setCurrentStepIndex(lastStepIndex);
        setCurrentStepText(lastStep.text);
        setDisplayedText(lastStep.text);
        setPhase("displaying");
      }
    } else if (reasoningSteps.length === 0 && (currentStepText !== "" || displayedText !== "")) {
      setPhase("idle");
      setCurrentStepIndex(0);
      setCurrentStepText("");
      setDisplayedText("");
    }
  }, [reasoningSteps, currentStepIndex, currentStepText, displayedText]);

  // Determine render variables
  const textToRender = displayedText;
  const showCursor = phase === "typing";
  const shouldRender = reasoningSteps.length > 0 && currentStepText !== "";

  return (
    <div className={cn("relative mx-auto h-7", className)}>
      {shouldRender ? (
        <div key={currentStepIndex} className={cn("absolute inset-0 opacity-100 transition-opacity duration-500")}>
          <p className="animate-gradient-x from-ink via-ink/40 to-ink bg-gradient-to-r bg-[length:200%_100%] bg-clip-text text-lg font-medium text-transparent">
            {textToRender}
            {showCursor ? <span className="animate-blink text-brand">|</span> : null}
          </p>
        </div>
      ) : null}
    </div>
  );
};
