import type { Transformer } from "@lexical/markdown";
import React, { useMemo } from "react";

import { AutoFocusPlugin, AutoLinkPlugin, HistoryPlugin, LinkPlugin, ListPlugin, MarkdownShortcutPlugin } from "../";
import type { IAnnotation } from "../nodes/AnnotationNode";
import { AnnotationPlugin } from "./AnnotationPlugin";
import { ANNOTATION_TRANSFORMER } from "./AnnotationPlugin";
import { useEditorCapabilities } from "./EditorContext";

interface IDefaultPluginsProps {
  transformers: Transformer[];
  onAnnotationClick?: (annotation: IAnnotation) => void;
  onAnnotationCreate?: (selection: string) => Promise<IAnnotation>;
  readOnly?: boolean;
  autoFocus?: boolean;
}

export function DefaultPlugins({
  transformers,
  readOnly,
  autoFocus = false,
  onAnnotationClick: handleAnnotationClick,
  onAnnotationCreate: handleAnnotationCreate,
}: IDefaultPluginsProps): React.JSX.Element {
  const { enableAnnotations: isAnnotationsEnabled } = useEditorCapabilities();

  // Filter out annotation transformer if annotations are disabled
  const filteredTransformers = useMemo(() => {
    if (!isAnnotationsEnabled) {
      return transformers.filter((transformer) => transformer !== ANNOTATION_TRANSFORMER);
    }

    return transformers;
  }, [isAnnotationsEnabled, transformers]);

  return (
    <>
      {readOnly !== true && <MarkdownShortcutPlugin transformers={filteredTransformers} />}
      {autoFocus ? <AutoFocusPlugin /> : null}
      <AutoLinkPlugin />
      <LinkPlugin />
      <ListPlugin />
      {readOnly !== true && <HistoryPlugin />}
      {isAnnotationsEnabled && handleAnnotationClick && handleAnnotationCreate ? (
        <AnnotationPlugin
          readOnly={readOnly}
          onAnnotationClick={handleAnnotationClick}
          onAnnotationCreate={handleAnnotationCreate}
        />
      ) : null}
    </>
  );
}
