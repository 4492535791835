import type { IActionCore, IStateMachine, IStateMachineStateTransition } from "@archetype/dsl";
import { makeEmptyEditActionDefinition, makeTransitionId } from "@archetype/dsl";
import type { IActionId, IApplicationGroupId, IOrganizationId, IStateId } from "@archetype/ids";
import { ActionId, TransitionId } from "@archetype/ids";
import type { IReadableString } from "@archetype/utils";
import { groupByNoUndefined, isNonNullable, keyByNoUndefined } from "@archetype/utils";
import { omit, partition } from "lodash";

interface IRemoveStateEdits {
  newOrEditedActions: Record<IActionId, IActionCore>;
  deletedActionIds: IActionId[];
  newStateMachine: IStateMachine;
}

interface ITransitionAndAction {
  transition: IStateMachineStateTransition;
  action: IActionCore;
}

/**
 *
 * Keeps the graph valid without removing any other state than the one provided. This is done by:
 * - If a downstream state has no other incoming transitions, we keep the paths from the transitions into the deleted state
 * - If a downstream state has other incoming transitions, do nothing
 * And we removed the transitions into the deleted state (potentially duplicated into transitions into dangling downstream states)
 *
 * If the deleted state is the initial state:
 * - If the initial state has a single transition out, remove the transition and change the initial state
 * - If there is !== 1 transition out, the deletion is invalid
 *
 * Anyway delete all transitions into the current state
 */
export const removeStateFromStateMachine = ({
  context,
  stateMachine,
  stateId,
  allActionsInStateMachine,
}: {
  context: {
    organizationId: IOrganizationId;
    applicationGroupId: IApplicationGroupId;
  };
  stateMachine: IStateMachine;
  stateId: IStateId;
  allActionsInStateMachine: Record<IActionId, IActionCore>;
}): IRemoveStateEdits => {
  const transitionsFromDeleted = stateMachine.stateTransitions.filter((t) => t.from === stateId && t.to !== stateId); // ignore self loops

  const stateToDelete = stateMachine.states[stateId];

  const newArchivedStates =
    stateToDelete != null ? { ...stateMachine.archivedStates, [stateId]: stateToDelete } : stateMachine.archivedStates;

  const updatedStates: IStateMachine["states"] = omit(stateMachine.states, stateId);

  if (stateMachine.initialState.state === stateId) {
    if (transitionsFromDeleted.length !== 1) {
      // Deletion is invalid
      return {
        newOrEditedActions: {},
        deletedActionIds: [],
        newStateMachine: stateMachine,
      };
    }

    const [keptTransitions, removedTransitions] = partition(
      stateMachine.stateTransitions,
      (t) => t.from !== stateId && t.to !== stateId, // Will also remove transitions back to the initial state
    );

    const newStateMachine: IStateMachine = {
      ...stateMachine,
      states: updatedStates,
      archivedStates: newArchivedStates,
      stateTransitions: keptTransitions,
      initialState: {
        ...stateMachine.initialState,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
        state: transitionsFromDeleted[0]!.to, // Probably makes more sense to keep the policy from the previous create
      },
    };

    return {
      newOrEditedActions: {},
      deletedActionIds: removedTransitions.map((t) => t.actionId),
      newStateMachine,
    };
  }

  const transitionsByToStateWithoutFromDeleted = groupByNoUndefined(
    stateMachine.stateTransitions.filter((t) => t.from !== stateId),
    (t) => t.to,
  );
  const transitionsToDeleted = transitionsByToStateWithoutFromDeleted[stateId];

  // TODO: we probably want to generate better names for those transitions
  const newReplacementTransitions: ITransitionAndAction[] = transitionsFromDeleted
    .flatMap((deletedTransition) => {
      const nextStateId = deletedTransition.to;
      const deletedAction = allActionsInStateMachine[deletedTransition.actionId];

      const otherTransitionsIntoNext = transitionsByToStateWithoutFromDeleted[nextStateId];

      if (otherTransitionsIntoNext == null || otherTransitionsIntoNext.length === 0) {
        const nextState = stateMachine.states[nextStateId];

        return (transitionsToDeleted ?? []).map((t) => {
          const fromState = stateMachine.states[t.from];

          if (nextState == null || fromState == null) {
            return undefined;
          }

          const actionId = ActionId.generate();

          return {
            transition: {
              from: t.from,
              id: makeTransitionId({
                label: deletedAction?.displayMetadata.name ?? (TransitionId.generate() as string as IReadableString),
                fromState: fromState.label,
                toState: nextState.label,
              }),
              to: nextStateId,
              actionId,
            },
            action: {
              id: actionId,
              displayMetadata: {
                name: deletedAction?.displayMetadata.name ?? nextState.label,
              },
              entityTypeId: deletedAction?.entityTypeId ?? stateMachine.dataModel.targetEntityTypeId,
              organizationId: deletedAction?.organizationId ?? context.organizationId,
              applicationGroupId: deletedAction?.applicationGroupId ?? context.applicationGroupId,
              actionDefinition:
                deletedAction?.actionDefinition ??
                makeEmptyEditActionDefinition({
                  stateInfo: {
                    fromState: deletedTransition.from,
                    toState: nextStateId,
                  },
                }),
            },
          };
        });
      }
    })
    .filter(isNonNullable);

  const [keptTransitions, removedTransitions] = partition(
    stateMachine.stateTransitions,
    (t) => t.from !== stateId && t.to !== stateId,
  );

  const newStateMachine: IStateMachine = {
    ...stateMachine,
    states: updatedStates,
    archivedStates: newArchivedStates,
    stateTransitions: keptTransitions.concat(newReplacementTransitions.map((t) => t.transition)),
  };

  return {
    newOrEditedActions: keyByNoUndefined(
      newReplacementTransitions.map((t) => t.action),
      (a) => a.id,
    ),
    deletedActionIds: removedTransitions.map((t) => t.actionId),
    newStateMachine,
  };
};

export const canRemoveStateFromStateMachine = (
  stateMachine: IStateMachine,
  state: IStateId,
): { canRemove: boolean; notRemovableReason?: string } => {
  const transitionsFromDeleted = stateMachine.stateTransitions.filter((t) => t.from === state && t.to !== state); // ignore self loops

  if (stateMachine.initialState.state === state) {
    if (transitionsFromDeleted.length !== 1) {
      // Deletion is invalid
      return {
        canRemove: false,
        notRemovableReason:
          transitionsFromDeleted.length === 0
            ? "No next step that could become initial step"
            : "Too many next step, we can't decide which should be the new initial step",
      };
    }

    return {
      canRemove: true,
    };
  }

  return {
    canRemove: true,
  };
};
