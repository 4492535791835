import type {
  IActionCurrentUserInfo,
  IColumnElement,
  IColumnType,
  IComputedElement,
  IElement,
  IFileEntityColumnValue,
  IInputDefaultValue,
  IMinimalEntityWithFields,
  IRelationElement,
  IRelationFieldValue,
  IStaticElement,
  IViewFieldValue,
} from "@archetype/dsl";
import {
  computeColumnViewFieldId,
  computeRelationViewFieldId,
  FieldValueParser,
  parseValueByColumnType,
  RelationFieldValue,
} from "@archetype/dsl";
import assertNever from "assert-never";
import { DateTime } from "luxon";
import { match } from "ts-pattern";
import { v4 } from "uuid";

export function resolveComputedElement(element: IComputedElement): IViewFieldValue {
  switch (element.value) {
    case "now": {
      return {
        type: "timestamp",
        value: DateTime.now().toString(),
      };
    }
    case "today": {
      return {
        type: "date",
        value: DateTime.now().startOf("day").toString(),
      };
    }
    case "currentMonth": {
      return {
        type: "number",
        value: DateTime.now().month,
      };
    }
    case "currentYear": {
      return {
        type: "number",
        value: DateTime.now().year,
      };
    }
    case "startOfDay": {
      return {
        type: "date",
        value: DateTime.now().startOf("day").toString(),
      };
    }
    case "endOfDay": {
      return {
        type: "date",
        value: DateTime.now().endOf("day").toString(),
      };
    }
    case "startOfMonth": {
      return {
        type: "date",
        value: DateTime.now().startOf("month").toString(),
      };
    }
    case "endOfMonth": {
      return {
        type: "date",
        value: DateTime.now().endOf("month").toString(),
      };
    }
    case "startOfYear": {
      return {
        type: "date",
        value: DateTime.now().startOf("year").toString(),
      };
    }
    case "endOfYear": {
      return {
        type: "date",
        value: DateTime.now().endOf("year").toString(),
      };
    }
    case "randomUUID": {
      return {
        type: "string",
        value: v4(),
      };
    }
    default: {
      assertNever(element.value);
    }
  }
}

export function resolveElement(
  element: IElement,
  entity: { fields: IMinimalEntityWithFields["fields"] } | undefined,
): IViewFieldValue {
  return match(element)
    .returnType<IViewFieldValue>()
    .with({ type: "static" }, (e: IStaticElement) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
      return e.value == null ? { type: "null" } : e.value;
    })
    .with({ type: "column" }, (e: IColumnElement) => {
      return entity?.fields[computeColumnViewFieldId(e.columnId)] ?? { type: "null" };
    })
    .with({ type: "relation" }, (e: IRelationElement) => {
      return entity?.fields[computeRelationViewFieldId(e.relationId, e.direction)] ?? { type: "null" };
    })
    .with({ type: "computed" }, (e: IComputedElement) => {
      return resolveComputedElement(e);
    })
    .exhaustive();
}

export function resolveDefaultValue(
  element: IInputDefaultValue,
  /**
   * Should only be optional when action executed by AI agent
   */
  currentUserInfo: IActionCurrentUserInfo | undefined,
  columnType: IColumnType | undefined,
): IViewFieldValue {
  return match(element)
    .returnType<IViewFieldValue>()
    .with({ type: "static" }, (e: IStaticElement) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- standardize
      if (e.value == null) {
        return { type: "null" };
      }
      if (e.value.type !== "relatedEntities" && columnType != null) {
        return parseValueByColumnType(e.value, columnType);
      }

      return e.value;
    })
    .with({ type: "computed" }, (e: IComputedElement) => {
      return resolveComputedElement(e);
    })
    .with({ type: "auth" }, () => {
      if (currentUserInfo == null) {
        return { type: "null" };
      }

      return RelationFieldValue.parse({
        type: "relatedEntities",
        value: [
          {
            entityId: currentUserInfo.userEntityId,
            entityTypeId: currentUserInfo.userEntityTypeId,
            displayName: currentUserInfo.userName,
          },
        ],
      });
    })
    .exhaustive();
}

export function getElementValue(
  element: IElement,
  entity: { fields: IMinimalEntityWithFields["fields"] },
): IRelationFieldValue["value"] | DateTime | IFileEntityColumnValue[] | string | number | boolean | null | undefined {
  const value = resolveElement(element, entity);

  return FieldValueParser.toValue(value);
}
