import type { IStateId } from "@archetype/ids";
import { forEach } from "@archetype/utils";
import { isEqual } from "lodash";

import type {
  IAuthorizationsByEntityScope,
  ILoadedAuthorizingPath,
  ILoadedAuthorizingPathFromQueried,
  ILoadedAuthorizingPathFromSpecified,
  ILoadedRelevantAuthorizations,
} from "../types";
import { isLoadedAuthorizingPathFromQueried, isLoadedAuthorizingPathFromSpecified } from "../types";

export const isAuthorizationEqual = <T extends ILoadedAuthorizingPath>(a: T, b: T): boolean => isEqual(a, b);

export const splitRelevantAuthorizations = (
  authorizations: ILoadedRelevantAuthorizations,
): {
  fromQueriedEntity: IAuthorizationsByEntityScope<ILoadedAuthorizingPathFromQueried>;
  fromOtherUnrelatedEntityType: IAuthorizationsByEntityScope<ILoadedAuthorizingPathFromSpecified>;
} => {
  const fromQueriedEntity: IAuthorizationsByEntityScope<ILoadedAuthorizingPathFromQueried> = {
    anyState: [],
    activityLog: [],
    byStateId: {},
  };
  const fromOtherUnrelatedEntityType: IAuthorizationsByEntityScope<ILoadedAuthorizingPathFromSpecified> = {
    anyState: [],
    activityLog: [],
    byStateId: {},
  };

  authorizations.anyState.forEach((authorization) => {
    if (isLoadedAuthorizingPathFromQueried(authorization)) {
      fromQueriedEntity.anyState.push(authorization);
    } else if (isLoadedAuthorizingPathFromSpecified(authorization)) {
      fromOtherUnrelatedEntityType.anyState.push(authorization);
    }
  });
  authorizations.activityLog.forEach((authorization) => {
    if (isLoadedAuthorizingPathFromQueried(authorization)) {
      fromQueriedEntity.activityLog.push(authorization);
    } else if (isLoadedAuthorizingPathFromSpecified(authorization)) {
      fromOtherUnrelatedEntityType.activityLog.push(authorization);
    }
  });
  forEach(authorizations.byStateId, (stateAuths, stateId) => {
    stateAuths.forEach((authorization) => {
      if (isLoadedAuthorizingPathFromQueried(authorization)) {
        fromQueriedEntity.byStateId[stateId] ??= [];
        fromQueriedEntity.byStateId[stateId]?.push(authorization);
      } else if (isLoadedAuthorizingPathFromSpecified(authorization)) {
        fromOtherUnrelatedEntityType.byStateId[stateId] ??= [];
        fromOtherUnrelatedEntityType.byStateId[stateId]?.push(authorization);
      }
    });
  });

  return { fromQueriedEntity, fromOtherUnrelatedEntityType };
};

export const createDeduplicatedAuthorizations = <T>(
  authEquals: (a: T, b: T) => boolean,
  authorizations: IAuthorizationsByEntityScope<T>,
): {
  authorizations: T[];
  indices: IAuthorizationsByEntityScope<number>;
} => {
  const { anyState, activityLog: comments, byStateId } = authorizations;

  const deduplicatedAuthorizations: T[] = [];
  const anyStateAuthorizationIndices: number[] = [];
  const commentsAuthorizationIndices: number[] = [];
  const stateIdToAuthorizationIndices: Record<IStateId, number[]> = {};

  anyState.forEach((authorization) => {
    const existingIndex = deduplicatedAuthorizations.findIndex((x) => authEquals(x, authorization));

    if (existingIndex === -1) {
      const newLength = deduplicatedAuthorizations.push(authorization);

      anyStateAuthorizationIndices.push(newLength - 1);

      return;
    }

    anyStateAuthorizationIndices.push(existingIndex);
  });
  comments.forEach((authorization) => {
    const existingIndex = deduplicatedAuthorizations.findIndex((x) => authEquals(x, authorization));

    if (existingIndex === -1) {
      const newLength = deduplicatedAuthorizations.push(authorization);

      commentsAuthorizationIndices.push(newLength - 1);

      return;
    }

    commentsAuthorizationIndices.push(existingIndex);
  });

  forEach(byStateId, (stateAuths, stateId) => {
    stateAuths.forEach((authorization) => {
      const existingIndex = deduplicatedAuthorizations.findIndex((x) => authEquals(x, authorization));

      if (existingIndex === -1) {
        const newLength = deduplicatedAuthorizations.push(authorization);

        stateIdToAuthorizationIndices[stateId] ??= [];
        stateIdToAuthorizationIndices[stateId]?.push(newLength - 1);

        return;
      }

      stateIdToAuthorizationIndices[stateId] ??= [];
      stateIdToAuthorizationIndices[stateId]?.push(existingIndex);
    });
  });

  return {
    authorizations: deduplicatedAuthorizations,
    indices: {
      anyState: anyStateAuthorizationIndices,
      activityLog: commentsAuthorizationIndices,
      byStateId: stateIdToAuthorizationIndices,
    },
  };
};
