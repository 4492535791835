import type {
  IActionCore,
  IAuthorization,
  IColumn,
  IColumnType,
  IEntityTypeCore,
  IRelationCore,
  ISupportEntityTypeCore,
  IWorkflowStateMachineColumnType,
  IWorkspaceRelationWithIds,
  IWorkspaceSupportEntityType,
} from "@archetype/dsl";
import {
  computeColumnViewFieldId,
  computeRelationViewFieldId,
  ENTITY_TYPE_COLOR_NAMES,
  ENTITY_TYPE_SHAPE_NAMES,
} from "@archetype/dsl";
import type { IActionId, IEntityTypeId, IOrganizationId, IRelationId } from "@archetype/ids";
import { ActionId, ColumnId } from "@archetype/ids";
import type { IReadableString } from "@archetype/utils";
import { humanize, keyByNoUndefined } from "@archetype/utils";
import { sample, values } from "lodash";

import { createInternalEmployeeAuthorization } from "../auth/createInternalEmployeeAuthorization";
import {
  createCreatedAtColumn,
  createCreatedByRelation,
  createPrimaryKeyColumn,
  createTitleColumn,
} from "./createBaseColumn";

function extractColumnType(columnType: IWorkflowStateMachineColumnType): IColumnType {
  if (columnType.typeName === "enum") {
    return { type: "enum", enumAllowedValues: columnType.enumAllowedValues ?? [], enumInclusiveMaxValuesToSelect: 1 };
  }

  return {
    type: columnType.typeName,
  };
}

export function createSupportEntityFromLLMResponse({
  targetEntityType,
  targetEntityTypeId,
  userEntityTypeId,
  relations,
  organizationId,
}: {
  targetEntityType: IWorkspaceSupportEntityType;
  targetEntityTypeId: IEntityTypeId;
  userEntityTypeId: IEntityTypeId;
  relations: IWorkspaceRelationWithIds[];
  organizationId: IOrganizationId;
}): {
  entityTypes: Record<IEntityTypeId, IEntityTypeCore>;
  actions: Record<IActionId, IActionCore>;
  relations: Record<IRelationId, IRelationCore>;
} {
  const titleColumn = createTitleColumn(targetEntityType.nameOfTitleColumn);
  const primaryKey = createPrimaryKeyColumn();
  const createdAtColumn = createCreatedAtColumn();
  const createdByRelation = createCreatedByRelation({
    entityTypeId: targetEntityTypeId,
    userEntityTypeId,
    userEntityTypePluralTitle: targetEntityType.pluralTitle,
  });

  const otherInputs: IActionCore["actionDefinition"]["inputs"] = [];
  const columnsByName: Record<IReadableString, IColumn> = {
    [targetEntityType.nameOfTitleColumn]: titleColumn,
    [primaryKey.displayMetadata.name]: primaryKey,
    [createdAtColumn.displayMetadata.name]: createdAtColumn,
  };

  targetEntityType.columns.forEach((f) => {
    if (f.label === targetEntityType.nameOfTitleColumn) {
      return;
    }

    let column: IColumn | undefined = columnsByName[f.label];

    if (column == null) {
      column = {
        id: ColumnId.generate(),
        displayMetadata: {
          name: humanize(f.label),
        },
        columnType: extractColumnType(f.columnType),
        nonNullable: false,
        unique: false,
      };

      columnsByName[f.label] = column;
    }

    otherInputs.push({
      required: false,
      viewField: {
        id: computeColumnViewFieldId(column.id),
        type: "column" as const,
        columnId: column.id,
      },
      allowChangingDefault: true,
      defaultValue: undefined,
    });
  });

  const createdRelations: IRelationCore[] = [];

  relations.forEach((r) => {
    if (r.entityTypeIdA !== targetEntityTypeId && r.entityTypeIdB !== targetEntityTypeId) {
      return;
    }

    const relation: IRelationCore = {
      id: r.id,
      config: {
        cardinalityOnSideA: r.cardinalityOnSideA,
        cardinalityOnSideB: r.cardinalityOnSideB,
      },
      displayMetadataFromAToB: {
        name: r.titleForBEntitiesWhenOnA,
      },
      displayMetadataFromBToA: {
        name: r.titleForAEntitiesWhenOnB,
      },
      entityTypeIdA: r.entityTypeIdA,
      entityTypeIdB: r.entityTypeIdB,
    };

    const direction = r.entityTypeIdA === targetEntityTypeId ? "aToB" : "bToA";

    otherInputs.push({
      required: false,
      viewField: {
        id: computeRelationViewFieldId(relation.id, direction),
        type: "directionalRelation" as const,
        relationId: relation.id,
        direction,
      },
      allowChangingDefault: true,
      defaultValue: undefined,
    });

    createdRelations.push(relation);
  });

  const employeePermissionFilter: IAuthorization = createInternalEmployeeAuthorization({ userEntityTypeId });

  const createAction: IActionCore = {
    id: ActionId.generate(),
    displayMetadata: {
      name: "Create" as IReadableString,
    },
    entityTypeId: targetEntityTypeId,
    organizationId,
    applicationGroupId: null,
    actionDefinition: {
      actionType: "add",
      inputs: [
        {
          required: true,
          viewField: {
            id: computeColumnViewFieldId(titleColumn.id),
            type: "column" as const,
            columnId: titleColumn.id,
          },
          allowChangingDefault: true,
          defaultValue: undefined,
        },
        {
          required: true,
          viewField: {
            id: computeColumnViewFieldId(primaryKey.id),
            type: "column" as const,
            columnId: primaryKey.id,
          },
          allowChangingDefault: true,
          defaultValue: undefined,
        },
        {
          required: true,
          viewField: {
            id: computeColumnViewFieldId(createdAtColumn.id),
            type: "column" as const,
            columnId: createdAtColumn.id,
          },
          allowChangingDefault: true,
          defaultValue: undefined,
        },
        ...otherInputs,
      ],
      contextualFields: [],
      authorizedByAnyOf: [employeePermissionFilter],
      authorizedForAnyoneWithLink: false,
    },
  };
  const modifyAction: IActionCore = {
    id: ActionId.generate(),
    displayMetadata: {
      name: "Modify" as IReadableString,
    },
    entityTypeId: targetEntityTypeId,
    organizationId,
    applicationGroupId: null,
    actionDefinition: {
      actionType: "modify",
      inputs: [
        {
          required: true,
          viewField: {
            id: computeColumnViewFieldId(titleColumn.id),
            type: "column" as const,
            columnId: titleColumn.id,
          },
          allowChangingDefault: true,
          defaultValue: undefined,
        },
        ...otherInputs,
      ],
      contextualFields: [],
      authorizedByAnyOf: [employeePermissionFilter],
      authorizedForAnyoneWithLink: false,
    },
  };
  const deleteAction: IActionCore = {
    id: ActionId.generate(),
    displayMetadata: {
      name: "Delete" as IReadableString,
    },
    entityTypeId: targetEntityTypeId,
    organizationId,
    applicationGroupId: null,
    actionDefinition: {
      actionType: "delete",
      inputs: [],
      contextualFields: [],
      authorizedByAnyOf: [],
      authorizedForAnyoneWithLink: false,
    },
  };

  const entityType: ISupportEntityTypeCore = {
    id: targetEntityTypeId,
    displayMetadata: {
      name: humanize(targetEntityType.title),
      pluralName: humanize(targetEntityType.pluralTitle),
      description: targetEntityType.description,
    },

    primaryKey: primaryKey.id,
    displayNameColumn: titleColumn.id,
    statusColumn: null,

    shape: sample(ENTITY_TYPE_SHAPE_NAMES) ?? "circle",
    color: sample(ENTITY_TYPE_COLOR_NAMES) ?? "lilac",

    columns: values(columnsByName),
    relevantViewFieldsByStateId: {},
    authorizedByAnyOf: [employeePermissionFilter],
    authorizedByAnyOfPerStateId: {},

    activityLogAuthorizedByAnyOf: [],

    authorizedForAnyoneWithLink: false,
    authorizedForAnyoneWithLinkPerStateId: {},

    organizationId,
    targetEntityTypeApplicationGroupId: null,

    supportActionsInfo: {
      create: createAction.id,
      update: modifyAction.id,
      otherActions: {
        invite: null,
      },
    },
    userEntityTypeInfo: null,

    deleteActionId: deleteAction.id,
  };

  return {
    entityTypes: {
      [entityType.id]: entityType,
    },
    actions: {
      [createAction.id]: createAction,
      [modifyAction.id]: modifyAction,
      [deleteAction.id]: deleteAction,
    },
    relations: {
      [createdByRelation.id]: createdByRelation,
      ...keyByNoUndefined(createdRelations, (r) => r.id),
    },
  };
}
