import type { IEntityColumnValue, IEntityType, IRelationCore, IViewFieldValue } from "@archetype/dsl";
import { isRelationFieldValue } from "@archetype/dsl";
import type { IColumnId, IEntityTypeId, IRelationId, IViewFieldId } from "@archetype/ids";
import { forEach, map, unpartialRecord } from "@archetype/utils";

import type { ILoadedEntityType } from "../apiTypes/LoadedEntityType";
import type { ILoadedRelationViewField, ILoadedViewField } from "../apiTypes/LoadedViewField";
import {
  createLoadedColumnViewField,
  createLoadedRelationViewField,
  isLoadedColumnViewField,
} from "../apiTypes/LoadedViewField";

export function generateLoadedRelationViewFieldsMap(
  entityTypeId: IEntityTypeId,
  // Partial because of zod and easier to use around code base
  relations: Partial<Record<IRelationId, IRelationCore>>,
): Record<IViewFieldId, ILoadedRelationViewField> {
  const res: Record<IViewFieldId, ILoadedRelationViewField> = {};

  forEach(relations, (relation) => {
    if (relation?.entityTypeIdA === entityTypeId) {
      const fieldAToB = createLoadedRelationViewField(relation, "aToB");

      res[fieldAToB.id] = fieldAToB;
    }
    if (relation?.entityTypeIdB === entityTypeId) {
      const fieldBToA = createLoadedRelationViewField(relation, "bToA");

      res[fieldBToA.id] = fieldBToA;
    }
  });

  return res;
}

export function generateLoadedViewFieldsMapForEntityType(
  entityType: {
    id: IEntityTypeId;
    columns: IEntityType["columns"];
  },
  relations: Partial<Record<IRelationId, IRelationCore>>,
): Record<IViewFieldId, ILoadedViewField> {
  const res: Record<IViewFieldId, ILoadedViewField> = {};

  entityType.columns.forEach((column) => {
    const field = createLoadedColumnViewField(column);

    res[field.id] = field;
  });

  const relationFields = generateLoadedRelationViewFieldsMap(entityType.id, relations);

  return { ...res, ...relationFields };
}

export function generateLoadedViewFieldsMapForLoadedEntityType(
  entityType: ILoadedEntityType,
): Record<IViewFieldId, ILoadedViewField> {
  const res: Record<IViewFieldId, ILoadedViewField> = {};

  forEach(unpartialRecord(entityType.columns), (column) => {
    const field = createLoadedColumnViewField(column);

    res[field.id] = field;
  });

  forEach(unpartialRecord(entityType.relations), (relation) => {
    if (relation.entityTypeIdA === entityType.id) {
      const fieldAToB = createLoadedRelationViewField(relation, "aToB");

      res[fieldAToB.id] = fieldAToB;
    }
    if (relation.entityTypeIdB === entityType.id) {
      const fieldBToA = createLoadedRelationViewField(relation, "bToA");

      res[fieldBToA.id] = fieldBToA;
    }
  });

  return res;
}

export function extractColumnValuesFromViewFieldValues(
  fieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>,
  entityType: IEntityType,
): Record<IColumnId, IEntityColumnValue> {
  const viewFieldsById = generateLoadedViewFieldsMapForEntityType(entityType, {});

  const res: Record<IColumnId, IEntityColumnValue> = {};

  map(fieldValues, (val, viewFieldId) => {
    if (val == null) {
      return;
    }

    const viewField = viewFieldsById[viewFieldId];

    if (viewField == null) {
      return;
    }

    //todo alon: maybe add files handling here
    if (isLoadedColumnViewField(viewField) && !isRelationFieldValue(val)) {
      res[viewField.columnId] = val;
    }
  });

  return res;
}
