import type { IStateMachine } from "@archetype/dsl";
import type { ITransitionId } from "@archetype/ids";
import { pickBy } from "@archetype/utils";

/**
 * If the next state has other transitions into it (including create transitions), just delete transition
 * If the next state has no other transitions into it, delete states in path until there is a transition into state
 *
 * Remove transition in removal queue
 * - If the next state has other transitions into it, nothing
 * - Add transitions from state to removal queue
 * - while not empty
 * then at the end filter states that are not in transitions (and create)
 *
 */
export const removeTransitionFromStateMachine = (
  stateMachine: IStateMachine,
  transitionId: ITransitionId,
): IStateMachine => {
  const firstDeletedTransition = stateMachine.stateTransitions.find((t) => t.id === transitionId);

  if (firstDeletedTransition == null) {
    return stateMachine;
  }

  const transitionsToRemove = [firstDeletedTransition];
  let filteredTransitions = stateMachine.stateTransitions;

  while (transitionsToRemove.length > 0) {
    const transitionToRemove = transitionsToRemove.shift();

    filteredTransitions = filteredTransitions.filter((t) => t.id !== transitionToRemove?.id);

    if (transitionToRemove == null) {
      continue;
    }

    // Check if the next state of the deleted transition should be kept, or if it's transitions should be added to the queue
    // the state will actually be deleted only at the end based on the filtered transitions

    const otherTransitionsToNextState = filteredTransitions.filter((t) => t.to === transitionToRemove.to);
    const transitionsFromNextState = filteredTransitions.filter((t) => t.from === transitionToRemove.to);

    // Ignoring if the transition is to the intital state
    if (otherTransitionsToNextState.length === 0 && stateMachine.initialState.state !== transitionToRemove.to) {
      transitionsToRemove.push(...transitionsFromNextState);
    }
  }

  const mentionedStates = new Set<string>(
    filteredTransitions.flatMap((t) => [t.from, t.to]).concat(stateMachine.initialState.state),
  );

  const updatedStates: IStateMachine["states"] = pickBy(stateMachine.states, (_s, stateId) =>
    mentionedStates.has(stateId),
  );

  const deletedStates: IStateMachine["states"] = pickBy(
    stateMachine.states,
    (_s, stateId) => !mentionedStates.has(stateId),
  );

  const newArchivedStates = { ...stateMachine.archivedStates, ...deletedStates };

  return {
    ...stateMachine,
    archivedStates: newArchivedStates,
    states: updatedStates,
    stateTransitions: filteredTransitions,
  };
};
