import type { IEntityTypeCore, IRelationCore } from "@archetype/dsl";
import type { IRelationId } from "@archetype/ids";
import { isNonNullable, keyByNoUndefined, pickBy } from "@archetype/utils";
import { values } from "lodash";

import type { ILoadedEntityType } from "../apiTypes/LoadedEntityType";

export const entityTypeCoreFromLoaded = (entityType: ILoadedEntityType): IEntityTypeCore => {
  return {
    ...entityType,
    columns: values(entityType.columns).filter(isNonNullable),
  };
};

export const entityTypeToLoaded = (
  entityType: IEntityTypeCore,
  relations: Partial<Record<IRelationId, IRelationCore>>,
): ILoadedEntityType => {
  return {
    ...entityType,
    columns: keyByNoUndefined(entityType.columns, (col) => col.id),
    relations: pickBy(
      relations,
      (relation) => relation?.entityTypeIdA === entityType.id || relation?.entityTypeIdB === entityType.id,
    ),
  };
};
