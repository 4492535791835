import { type ILoadedAction, isFieldValueNullableLike } from "@archetype/core";
import type {
  IEntityActionDraft,
  IEntityActionEmailEffectOverride,
  IVersionType,
  IViewFieldValue,
} from "@archetype/dsl";
import type { IEntityId, IViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { forEach, mapValues } from "@archetype/utils";
import { skipToken } from "@tanstack/react-query";
import { isEqual, size, throttle } from "lodash";
import type { MutableRefObject } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import type { UseFormReturn } from "react-hook-form";

import { createFileLogger } from "../logger";
import { withRefetchInterval } from "../utils/refetchInterval";
import { useActionDraftServiceDisabled } from "./useActionDraftServiceDisabled";

export const logger = createFileLogger("ActionDirectExecutionWrapper");

export type IActionSaveDraftArgs =
  | {
      type: "field";
      fieldId: IViewFieldId;
      value: IViewFieldValue | undefined;
      isUserEdit: boolean;
    }
  | {
      type: "email";
      value: IEntityActionEmailEffectOverride | undefined;
    };

export type IActionSaveDraft = (args: IActionSaveDraftArgs) => Promise<void>;

export interface IActionDraftServiceProps {
  versionType: IVersionType;
  action: ILoadedAction;
  entityId: IEntityId | undefined;

  defaultFieldValuesForCreate: Partial<Record<IViewFieldId, IViewFieldValue>> | undefined;

  // To set the values on load
  form: UseFormReturn<Partial<Record<string, IViewFieldValue>>>;
}

export type IActionSaveDraftState = {
  fields: Record<IViewFieldId, boolean>;
  failed: boolean;
  email: boolean;
};

function useStateWithRef<T>(initialValue: T): [MutableRefObject<T>, (newValue: T) => void] {
  const [value, setValue] = useState(initialValue);
  const ref = useRef(initialValue);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  const setValueWithRef = useCallback((newValue: T) => {
    ref.current = newValue;
    setValue(newValue);
  }, []);

  return [ref, setValueWithRef];
}

export interface IActionDraftService {
  saveState: IActionSaveDraftState;
  saveDraft: IActionSaveDraft;
  emailState: IEntityActionEmailEffectOverride | undefined;
  emailStateRef: MutableRefObject<IEntityActionEmailEffectOverride | undefined>;
  setEmailState: (email: IEntityActionEmailEffectOverride | undefined) => void;
}

export function useActionDraftService({
  versionType,
  action,
  form,
  entityId,
  defaultFieldValuesForCreate,
}: IActionDraftServiceProps): IActionDraftService {
  const [emailState, setEmailState] = useStateWithRef<IEntityActionEmailEffectOverride | undefined>(undefined);
  const isDraftCreationDisabled = useActionDraftServiceDisabled();
  const [savingDraftForFields, setSavingDraftForFields] = useState<{
    fields: Record<IViewFieldId, boolean>;
    email: boolean;
  }>({ fields: {}, email: false });
  // Ref so that it's a mutable object that can be read when checking the if there is anything to flush/save
  const pendingValuesToSaveRef = useRef<{
    fields: Record<
      IViewFieldId,
      | {
          value: IViewFieldValue;
          isUserEdit: boolean;
        }
      | undefined
    >;
    email: IEntityActionEmailEffectOverride | undefined;
  }>({ fields: {}, email: undefined });

  // Ref so that we can check if we need to save the draft without actually updating the callback on change
  const latestDraftRef = useRef<IEntityActionDraft | undefined>(undefined);
  const [isSavingDraft, setIsSavingDraft] = useStateWithRef(false);
  const { mutateAsync: trpcSaveDraft, error: trpcSaveDraftFailed } =
    builderTrpc.action.saveDraftForAction.useMutation();
  const getDraftForActionQuery = builderTrpc.useUtils().action.getDraftForAction;
  const { data: latestDraftQuery } = builderTrpc.action.getDraftForAction.useQuery(
    entityId == null
      ? skipToken
      : {
          versionType,
          organizationId: action.organizationId,
          actionId: action.id,
          entityTypeId: action.entityTypeId,
          entityId,
        },
    {
      enabled: !isSavingDraft.current && !isDraftCreationDisabled,
      ...withRefetchInterval(3000), // for collaboration
    },
  );

  const writeDraftToFormState = useCallback(
    (draftToWriteToState: IEntityActionDraft) => {
      if (isDraftCreationDisabled) {
        return;
      }

      if (pendingValuesToSaveRef.current.email == null && !isEqual(emailState.current, draftToWriteToState.email)) {
        setEmailState(draftToWriteToState.email);
      }

      forEach(draftToWriteToState.fields, (value, fieldId) => {
        if (
          value != null &&
          pendingValuesToSaveRef.current.fields[fieldId] == null &&
          !isEqual(form.getValues()[fieldId], value)
        ) {
          form.setValue(fieldId, value);
        }
      });
    },
    [form, isDraftCreationDisabled, emailState, setEmailState],
  );

  useEffect(() => {
    if (isDraftCreationDisabled) {
      return;
    }

    const latestDraft = latestDraftQuery?.draft;

    if (latestDraft != null) {
      latestDraftRef.current = latestDraft;
      writeDraftToFormState(latestDraft);
    }
  }, [latestDraftQuery?.draft, writeDraftToFormState, isDraftCreationDisabled]);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- checked manually but want to debounce/throttle
  const innerFlushDrafts = useCallback(
    throttle(async (): Promise<void> => {
      if (entityId == null || isDraftCreationDisabled) {
        return;
      }

      setSavingDraftForFields({
        fields: mapValues(pendingValuesToSaveRef.current.fields, () => true),
        email: pendingValuesToSaveRef.current.email != null,
      });

      const draftsToSave = pendingValuesToSaveRef.current;

      if (isSavingDraft.current || (size(draftsToSave.fields) === 0 && draftsToSave.email == null)) {
        return;
      }

      pendingValuesToSaveRef.current = { fields: {}, email: undefined };
      try {
        setIsSavingDraft(true);
        await getDraftForActionQuery.cancel({
          versionType,
          organizationId: action.organizationId,
          actionId: action.id,
          entityTypeId: action.entityTypeId,
          entityId,
        });

        const { draft: returnedFullDraft } = await trpcSaveDraft({
          organizationId: action.organizationId,
          versionType,
          actionId: action.id,
          entityTypeId: action.entityTypeId,
          entityId,
          emailEffectOverride: draftsToSave.email,
          fieldValues: draftsToSave.fields, //TODO update rpc
          defaultFieldValuesForCreate: defaultFieldValuesForCreate ?? null,
        });

        // With the returned full draft we can update the form values to allow some minimal collaboration
        writeDraftToFormState(returnedFullDraft);
      } finally {
        setIsSavingDraft(false);

        // Reset the savingDraftForFields to false/undefined for all fields in the ref at this point (there could be more edits to save for the same refs)
        setSavingDraftForFields({
          fields: mapValues(pendingValuesToSaveRef.current.fields, () => true),
          email: pendingValuesToSaveRef.current.email != null,
        });

        void innerFlushDrafts();
      }
    }, 2000),
    [
      setIsSavingDraft,
      trpcSaveDraft,
      getDraftForActionQuery,
      setSavingDraftForFields,
      defaultFieldValuesForCreate,
      action,
      entityId,
      versionType,
      writeDraftToFormState,
      isDraftCreationDisabled,
    ],
  );

  const debouncedSaveDraft = useCallback(
    async (args: IActionSaveDraftArgs): Promise<void> => {
      if (isDraftCreationDisabled) {
        return;
      }
      switch (args.type) {
        case "email": {
          const { value } = args;

          setEmailState(value);
          if (isEqual(latestDraftRef.current?.email, value)) return;

          pendingValuesToSaveRef.current.email = value;

          break;
        }
        case "field": {
          const { fieldId, value, isUserEdit } = args;

          if (viewFieldValuesEqual(latestDraftRef.current?.fields[fieldId], value)) return;

          pendingValuesToSaveRef.current.fields[fieldId] = value == null ? undefined : { value, isUserEdit };

          break;
        }
      }

      setSavingDraftForFields({
        fields: mapValues(pendingValuesToSaveRef.current.fields, () => true),
        email: pendingValuesToSaveRef.current.email != null,
      });

      await innerFlushDrafts();
    },
    [innerFlushDrafts, isDraftCreationDisabled, setEmailState],
  );

  const saveState = useMemo(
    (): IActionSaveDraftState => ({
      fields: savingDraftForFields.fields,
      failed: trpcSaveDraftFailed != null,
      email: savingDraftForFields.email,
    }),
    [savingDraftForFields, trpcSaveDraftFailed],
  );

  const emailStateCurrent = emailState.current;

  return useMemo(
    (): IActionDraftService => ({
      saveState,
      saveDraft: debouncedSaveDraft,
      emailState: emailStateCurrent,
      emailStateRef: emailState,
      setEmailState: setEmailState,
    }),
    [saveState, debouncedSaveDraft, emailState, emailStateCurrent, setEmailState],
  );
}

const viewFieldValuesEqual = (
  a: IViewFieldValue | null | undefined,
  b: IViewFieldValue | null | undefined,
): boolean => {
  if (isFieldValueNullableLike(a) && isFieldValueNullableLike(b)) {
    return true;
  }

  return isEqual(a, b);
};
