import { getComponentDefinitionRegistry, getLayoutDefinitionRegistry } from "@archetype/builder-definitions";
import type {
  IFeatureTransaction,
  ILayoutDefinition,
  ILayoutFunctionalConfigValue,
  IModuleInstance,
  IStructuredFeature,
} from "@archetype/dsl";
import { mergeDataLoadingQueryFilters } from "@archetype/dsl";
import type {
  IEntityTypeId,
  IFeatureId,
  IInputSemanticId,
  ILayoutConfigurationId,
  IOutputSemanticId,
  ISlotId,
} from "@archetype/ids";
import { FeatureId, InputSemanticId, OutputSemanticId, SlotId } from "@archetype/ids";
import { forEach } from "@archetype/utils";
import assertNever from "assert-never";
import { findKey, omit, pickBy, size } from "lodash";
import type { Result } from "neverthrow";
import { err, ok } from "neverthrow";
import { match } from "ts-pattern";

import { createFileLogger } from "../../logger";
import type { AbstractError } from "../errors/AbstractError";
import { ComponentInputsNotCompatibleWithSlot } from "../errors/ComponentInputsNotCompatibleWithSlot";
import { ComponentNotCompatibleWithSlotSize } from "../errors/ComponentNotCompatibleWithSlotSize";
import { ComponentNotDefinedInRegistry } from "../errors/ComponentNotDefinedInRegistry";
import { ComponentOutputsNotCompatibleWithSlot } from "../errors/ComponentOutputsNotCompatibleWithSlot";
import { FeatureIdMissingFromMap } from "../errors/FeatureIdMissingFromMap";
import { ModuleConfigFromOtherComponentsNotCompatible } from "../errors/ModuleConfigFromOtherComponentsNotCompatible";
import { SlotNotDefinedError } from "../errors/SlotNotDefined";
import type {
  IConfigurationFromFeatures,
  IDataLoadingConfigs,
  IRuntimeAndFuncComponentConfig,
  IRuntimeAndFuncComponentConfigs,
} from "./gatherComponentsFromFeatures";
import { gatherComponentsFromFeatures } from "./gatherComponentsFromFeatures";

const logger = createFileLogger("getCompatibleModuleConfigs");

interface IModuleConfig extends Pick<IModuleInstance, "slots" | "layoutConfiguration"> {
  featureIdToOccupiedSlotId: Record<IFeatureId, ISlotId>;
}

export interface IAnnotatedModuleConfig extends Pick<IModuleInstance, "slots" | "layoutConfiguration" | "layoutId"> {
  /** Number between 0 and 1 */
  requiredSlotsMatchPercentage: number;
}

export const getCompatibleModuleConfigs = (featureTransaction: IFeatureTransaction): IAnnotatedModuleConfig[] => {
  const featuresMap: Record<IFeatureId, IStructuredFeature> = {};

  featureTransaction.output.features.forEach((feature) => (featuresMap[feature.id] = feature));

  const componentsAndConfig = gatherComponentsFromFeatures(featuresMap);

  const layoutRegistry = getLayoutDefinitionRegistry();

  const res: IAnnotatedModuleConfig[] = [];

  forEach(layoutRegistry, (layoutDefinition) => {
    res.push(...getValidModuleConfigurationsForLayout(layoutDefinition, componentsAndConfig));
  });

  return res;
};

export const getValidModuleConfigurationsForLayout = (
  layoutDefinition: ILayoutDefinition,
  componentsAndConfig: IConfigurationFromFeatures,
): IAnnotatedModuleConfig[] => {
  const { componentConfig: allComponentConfigs } = componentsAndConfig;

  const requiredSlotsInLayout = pickBy(layoutDefinition.slots, (s) => s?.optional == null || !s.optional);
  const nbRequiredSlotsInLayout = size(requiredSlotsInLayout);

  return getValidModuleConfigurationForSubset({
    allComponentConfigs,
    layoutDefinition,
    componentsConfigToPlace: { ...allComponentConfigs },
    availableSlots: { ...layoutDefinition.slots },
    // Starting with empty
    partialModuleConfig: {
      layoutConfiguration: {},
      slots: {},
      featureIdToOccupiedSlotId: {},
    },
  }).map((moduleConfig) => ({
    slots: moduleConfig.slots,
    layoutConfiguration: withDataLoadingLayoutConfig(
      layoutDefinition,
      moduleConfig,
      componentsAndConfig.dataLoadingConfigs,
    ),
    layoutId: layoutDefinition.id,
    requiredSlotsMatchPercentage:
      size(pickBy(moduleConfig.slots, (_s, slotId) => requiredSlotsInLayout[slotId] != null)) / nbRequiredSlotsInLayout,
  }));
};

const withDataLoadingLayoutConfig = (
  layoutDefinition: ILayoutDefinition,
  moduleConfig: IModuleConfig,
  dataLoadingConfigs: IDataLoadingConfigs,
): IModuleConfig["layoutConfiguration"] => {
  const newLayoutConfig = { ...moduleConfig.layoutConfiguration };

  forEach(dataLoadingConfigs, (dataLoadingConfig) => {
    const referencedSlotId = moduleConfig.featureIdToOccupiedSlotId[dataLoadingConfig.dataReference.featureId];

    if (referencedSlotId == null) {
      return;
    }

    const slotReferencedConfigurationId = getReferencedLayoutConfig(
      layoutDefinition,
      referencedSlotId,
      dataLoadingConfig.dataReference.semanticId,
    );

    if (slotReferencedConfigurationId == null) {
      return;
    }

    const currentConfigValue = moduleConfig.layoutConfiguration[slotReferencedConfigurationId];

    if (currentConfigValue?.type !== "entityType") {
      return;
    }

    const configValueWithFilters: ILayoutFunctionalConfigValue = {
      type: "entityType",
      entityTypeId: currentConfigValue.entityTypeId,
      dataLoadingConfig: {
        filters: mergeDataLoadingQueryFilters(
          currentConfigValue.dataLoadingConfig?.filters,
          dataLoadingConfig.queryFilters,
        ),
      },
    };

    newLayoutConfig[slotReferencedConfigurationId] = configValueWithFilters;
  });

  return newLayoutConfig;
};

const getValidModuleConfigurationForSubset = ({
  allComponentConfigs,
  layoutDefinition,
  componentsConfigToPlace,
  availableSlots,
  partialModuleConfig,
}: {
  allComponentConfigs: IRuntimeAndFuncComponentConfigs;
  layoutDefinition: ILayoutDefinition;
  componentsConfigToPlace: IRuntimeAndFuncComponentConfigs;
  availableSlots: ILayoutDefinition["slots"];
  partialModuleConfig: IModuleConfig;
}): IModuleConfig[] => {
  if (size(componentsConfigToPlace) === 0) {
    return [partialModuleConfig]; // Nothing to do but returning config as it's valid
  }

  if (size(availableSlots) === 0) {
    return []; // Still a component to place but nothing to place it in so not a valid configuration
  }

  const firstKey = findKey(componentsConfigToPlace, () => true); // to avoid a copy of keys just to get the first
  const currentFeatureId = firstKey != null ? FeatureId.parse(firstKey) : undefined;
  const currentComponentConfig = currentFeatureId != null ? componentsConfigToPlace[currentFeatureId] : undefined;
  const currentComponentDefinition =
    currentComponentConfig != null
      ? getComponentDefinitionRegistry()[currentComponentConfig.slotInstance.componentDefinitionId]
      : undefined;

  if (currentFeatureId == null || currentComponentConfig == null || currentComponentDefinition == null) {
    return []; // not possible
  }

  const remainingComponents = omit(componentsConfigToPlace, currentFeatureId);

  const result: IModuleConfig[] = [];

  // try to place current component in each slot and get the valid configs for the rest
  forEach(availableSlots, (slot, rawSlotId) => {
    if (slot == null) {
      return;
    }

    const slotId = SlotId.parse(rawSlotId);
    const enrichedConfigWithCurrentComponent = enrichConfigWithComponentCompatible(
      allComponentConfigs,
      layoutDefinition,
      currentFeatureId,
      slotId,
      partialModuleConfig,
    );

    if (enrichedConfigWithCurrentComponent.isErr()) {
      // Not compatible
      return;
    }

    const configsWithRest = getValidModuleConfigurationForSubset({
      allComponentConfigs,
      layoutDefinition,
      componentsConfigToPlace: remainingComponents,
      availableSlots: omit(availableSlots, rawSlotId),
      partialModuleConfig: enrichedConfigWithCurrentComponent.value,
    });

    result.push(...configsWithRest);
  });

  return result;
};

type ISemanticId =
  | {
      type: "input";
      inputId: IInputSemanticId;
    }
  | {
      type: "output";
      outputId: IOutputSemanticId;
    };

const getReferencedEntityTypeId = (
  featureId: IFeatureId,
  inputOutputId: ISemanticId,
  allComponentConfigs: IRuntimeAndFuncComponentConfigs,
  depth = 0,
  // eslint-disable-next-line max-params -- internal only
): IEntityTypeId | undefined => {
  if (depth > 100) {
    logger.error("getReferencedEntityTypeId depth of config references too high", featureId, allComponentConfigs);
    throw new Error("getReferencedEntityTypeId depth of config references too high");
  }
  const config = allComponentConfigs[featureId];

  if (config == null) {
    throw new Error("No config");
  }

  return match(inputOutputId)
    .with({ type: "input" }, ({ inputId }) => {
      const input = config.inputs[inputId];

      if (input == null) {
        throw new Error("No config");
      }

      return match(input)
        .with({ type: "rawReference" }, ({ entityTypeId }) => entityTypeId)
        .with({ type: "otherFeatureReference" }, ({ featureId: referencedFeatureId, semanticId }) =>
          getReferencedEntityTypeId(referencedFeatureId, semanticId, allComponentConfigs, depth + 1),
        )
        .with({ type: "noConfig" }, () => undefined)
        .exhaustive();
    })
    .with({ type: "output" }, ({ outputId }) => {
      const output = config.outputs[outputId];

      if (output == null) {
        throw new Error("No config output");
      }

      return match(output)
        .with({ type: "rawReference" }, ({ entityTypeId }) => entityTypeId)
        .with({ type: "noConfig" }, () => undefined)
        .exhaustive();
    })
    .exhaustive();
};

const getReferencedLayoutConfig = (
  layoutDefinition: ILayoutDefinition,
  slotId: ISlotId,
  semanticId: ISemanticId,
  depth = 0,
  // eslint-disable-next-line max-params -- internal only
): ILayoutConfigurationId | undefined => {
  if (depth > 100) {
    logger.error("getReferencedLayoutConfig depth of config references too high", slotId, semanticId);
    throw new Error("getReferencedLayoutConfig depth of config references too high");
  }

  const slot = layoutDefinition.slots[slotId];

  if (slot == null) {
    throw new Error("No slot");
  }

  return match(semanticId)
    .with({ type: "input" }, ({ inputId }) => {
      const slotInput = slot.constraints.inputs[inputId];

      if (slotInput == null) {
        throw new Error("No slot input");
      }

      return match(slotInput.ref)
        .with({ type: "config" }, ({ layoutConfigurationId }) => layoutConfigurationId)
        .with({ type: "otherSlotOutput" }, ({ slotId: referencedSlotId, outputId }) =>
          getReferencedLayoutConfig(layoutDefinition, referencedSlotId, { type: "output", outputId }, depth + 1),
        )
        .exhaustive();
    })
    .with({ type: "output" }, ({ outputId }) => {
      const slotOutput = slot.constraints.outputs[outputId];

      if (slotOutput == null) {
        throw new Error("No slot output");
      }
      switch (slotOutput.type) {
        case "actionSelected": {
          return undefined;
        }
        case "config": {
          return slotOutput.layoutConfigurationId;
        }
        default: {
          assertNever(slotOutput);
        }
      }
    })
    .exhaustive();
};

type IKnownCompatibilityError =
  | SlotNotDefinedError
  | ComponentNotDefinedInRegistry
  | ComponentNotCompatibleWithSlotSize
  | ComponentOutputsNotCompatibleWithSlot
  | ModuleConfigFromOtherComponentsNotCompatible
  | ComponentInputsNotCompatibleWithSlot
  | FeatureIdMissingFromMap;

const isKnownCompatibilityError = (e: unknown): e is IKnownCompatibilityError => {
  return [
    "SlotNotDefinedError",
    "ComponentNotDefinedInRegistry",
    "ComponentNotCompatibleWithSlotSize",
    "ComponentOutputsNotCompatibleWithSlot",
    "ModuleConfigFromOtherComponentsNotCompatible",
    "ComponentInputsNotCompatibleWithSlot",
    "ModuleConfigFromOtherComponentsNotCompatible",
  ].includes((e as AbstractError<string>).name);
};

const enrichConfigWithComponentCompatible = (
  allComponentConfigs: IRuntimeAndFuncComponentConfigs,
  layoutDefinition: ILayoutDefinition,
  featureId: IFeatureId,
  slotId: ISlotId,
  partialModuleConfig: IModuleConfig,
  // eslint-disable-next-line max-params -- internal only
): Result<IModuleConfig, string | IKnownCompatibilityError> => {
  const componentConfig: IRuntimeAndFuncComponentConfig | undefined = allComponentConfigs[featureId];
  const slot = layoutDefinition.slots[slotId];

  if (slot == null) {
    return err(new SlotNotDefinedError({ slotId, layoutId: layoutDefinition.id }));
  }
  if (componentConfig == null) {
    return err(
      new FeatureIdMissingFromMap({
        featureId,
      }),
    );
  }

  const newConfig: IModuleConfig = {
    slots: { ...partialModuleConfig.slots },
    layoutConfiguration: { ...partialModuleConfig.layoutConfiguration },
    featureIdToOccupiedSlotId: partialModuleConfig.featureIdToOccupiedSlotId,
  };

  const componentDefinition = getComponentDefinitionRegistry()[componentConfig.slotInstance.componentDefinitionId];

  if (componentDefinition == null) {
    return err(new ComponentNotDefinedInRegistry({ componentId: componentConfig.slotInstance.componentDefinitionId }));
  }

  try {
    if (!componentDefinition.compatibleSemanticSizes.includes(slot.constraints.semanticSize)) {
      throw new ComponentNotCompatibleWithSlotSize({
        componentId: componentDefinition.id,
        slotId: slot.id,
        size: slot.constraints.semanticSize,
      });
    }

    // Going through map based on slots because slot outputs must be a subset of component outputs
    // and we can ignore additional comp outputs
    forEach(slot.constraints.outputs, (slotOutput, rawOutputId) => {
      if (slotOutput == null) {
        // Means it's just absent, but necessary of how type is described
        return;
      }

      const outputId = OutputSemanticId.parse(rawOutputId);
      const compOutput = componentConfig.outputs[OutputSemanticId.parse(outputId)];

      if (compOutput == null) {
        // Means the component is missing some output that the layout requires, so not compatible
        throw new ComponentOutputsNotCompatibleWithSlot({
          componentId: componentDefinition.id,
          slotId: slot.id,
        });
      }

      const referencedEntityTypeId = getReferencedEntityTypeId(
        featureId,
        {
          type: "output",
          outputId,
        },
        allComponentConfigs,
      );

      if (referencedEntityTypeId == null) {
        // Nothing to do, can skip
        return;
      }

      const slotReferencedConfigurationId = getReferencedLayoutConfig(layoutDefinition, slotId, {
        type: "output",
        outputId,
      });

      if (slotReferencedConfigurationId == null) {
        // Nothing to do, can skip
        return;
      }
      const currentConfigValue = partialModuleConfig.layoutConfiguration[slotReferencedConfigurationId];

      if (currentConfigValue == null) {
        // Add to config
        newConfig.layoutConfiguration[slotReferencedConfigurationId] = {
          type: "entityType",
          entityTypeId: referencedEntityTypeId,
        };
      } else {
        if (currentConfigValue.type !== "entityType" || currentConfigValue.entityTypeId !== referencedEntityTypeId) {
          // Incompatible implied layout config
          throw new ModuleConfigFromOtherComponentsNotCompatible({
            componentId: componentDefinition.id,
            slotId: slot.id,
            layoutConfigurationId: slotReferencedConfigurationId,
          });
        }
      }
    });

    // Iterating on component inputs because component inputs must be a subset of slot inputs
    // but actually what does it mean if the input is a raw reference? if we ignore it then it means the layoutConfig won't be complete
    // maybe we can check at the end? Or validate this here?
    forEach(componentConfig.inputs, (compInput, rawInputId) => {
      if (compInput == null) {
        // Means it's just absent, but necessary of how type is described
        return;
      }

      const inputId = InputSemanticId.parse(rawInputId);
      const slotInput = slot.constraints.inputs[inputId];

      if (slotInput == null) {
        if (componentDefinition.inputs[inputId]?.optional === true) {
          // If the input is optional we can ignore it
          return;
        }
        // Means the component has a required input that's not fulfilled so will not be functional
        throw new ComponentInputsNotCompatibleWithSlot({
          componentId: componentDefinition.id,
          slotId: slot.id,
        });
      }

      // Currently we only want to check that the config tree resolves to the same entity
      // not that the trees are equivalent, i.e. that the referenced feature resolves to a component in the slot that's referenced
      // Indeed that will currently not be the case because we construct those trees differently:
      // the feature references are based on the parent feature, generally the main view (i.e. view --> search)
      // but the slot references follows the data flow, which would go search --> view
      const referencedEntityTypeId = getReferencedEntityTypeId(
        featureId,
        {
          type: "input",
          inputId,
        },
        allComponentConfigs,
      );

      if (referencedEntityTypeId == null) {
        // Nothing to do, can skip
        return;
      }

      const slotReferencedConfigurationId = getReferencedLayoutConfig(layoutDefinition, slotId, {
        type: "input",
        inputId,
      });

      if (slotReferencedConfigurationId == null) {
        // Nothing to do, can skip
        return;
      }
      const currentConfigValue = partialModuleConfig.layoutConfiguration[slotReferencedConfigurationId];

      if (currentConfigValue == null) {
        // Add to config
        newConfig.layoutConfiguration[slotReferencedConfigurationId] = {
          type: "entityType",
          entityTypeId: referencedEntityTypeId,
        };
      } else {
        if (currentConfigValue.type !== "entityType" || currentConfigValue.entityTypeId !== referencedEntityTypeId) {
          // Incompatible implied layout config
          throw new ModuleConfigFromOtherComponentsNotCompatible({
            componentId: componentDefinition.id,
            slotId: slot.id,
            layoutConfigurationId: slotReferencedConfigurationId,
          });
        }
      }
    });

    // If all worked, can add the slot
    newConfig.slots[slotId] = componentConfig.slotInstance;
    newConfig.featureIdToOccupiedSlotId[featureId] = slotId;

    return ok(newConfig);
  } catch (e) {
    // Means it's not compatible

    if (isKnownCompatibilityError(e)) {
      return err(e);
    }

    logger.error(e, "Component not compatible");

    return err("Component not compatible");
  }
};
