import { cn } from "@archetype/ui";
import * as React from "react";
import { memo, useCallback, useMemo } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableInstanceContext } from "../../context/DataTableInstanceContext";
import { DataTableSelectionContext } from "../../context/DataTableSelectionContext";
import type { IVirtualColumn, IVirtualRow } from "../../hooks/useGridVirtualization";
import { DataTableCell, DataTableCellSkeleton } from "../cell/DataTableCell";

interface IDataTableRow {
  virtualRow: IVirtualRow;
  visibleColumns: IVirtualColumn[];
  enableStickyColumns: boolean;
  isSkeletonRow: boolean;
}

const getRowStyle = (virtualRow: IVirtualRow, tableWidth: number): React.CSSProperties => {
  const commonStyle = {
    minWidth: "100%",
    width: tableWidth,
    height: virtualRow.height,
  };

  if (virtualRow.pinned == null) {
    return {
      position: "absolute",
      transform: `translateY(${virtualRow.top.toFixed(0)}px)`,
      ...commonStyle,
    };
  }

  if (virtualRow.pinned === "top") {
    return {
      position: "sticky",
      top: virtualRow.top,
      zIndex: 2,
      ...commonStyle,
    };
  }

  // bottom
  return {
    position: "sticky",
    top: virtualRow.top,
    zIndex: 2,
    ...commonStyle,
  };
};

const DataTableRowComponent = ({
  virtualRow,
  visibleColumns,
  enableStickyColumns,
  isSkeletonRow,
}: IDataTableRow): React.ReactElement | null => {
  const tableWidth = useContextSelector(DataTableInstanceContext, (state) => state.tableWidth);
  const columns = useContextSelector(DataTableInstanceContext, (state) => state.columns);
  const rowId = useContextSelector(DataTableInstanceContext, (state) => state.data[virtualRow.index]?.id);
  const selectRow = useContextSelector(DataTableSelectionContext, (state) => state.selectRow);
  const selectionMode = useContextSelector(DataTableSelectionContext, (state) => state.selectionMode);

  const isRowSelected = useContextSelector(
    DataTableSelectionContext,
    (state) => rowId != null && state.selectedRowIndices.has(virtualRow.index),
  );

  const rowStyle = useMemo(() => getRowStyle(virtualRow, tableWidth), [virtualRow, tableWidth]);

  const rowClassName = useMemo(
    (): string =>
      cn(
        "group/row absolute z-0 flex overflow-visible border-b border-border bg-paper will-change-transform hover:bg-paper-alt",
        virtualRow.pinned === "bottom" && "border-t",
      ),
    [virtualRow.pinned],
  );

  const renderedCells = useMemo(() => {
    if (rowId == null) {
      return null;
    }

    return visibleColumns.map((column) => {
      const colId = columns[column.index]?.id;

      if (colId == null) {
        return null;
      }

      return (
        <DataTableCell
          key={column.index}
          colId={colId}
          enableStickyColumns={enableStickyColumns}
          isRowSelected={isRowSelected}
          rowId={rowId}
          virtualColumn={column}
          virtualRow={virtualRow}
          visibleColumns={visibleColumns}
        />
      );
    });
  }, [columns, enableStickyColumns, isRowSelected, rowId, virtualRow, visibleColumns]);

  const handleRowClick = useCallback(() => {
    if (selectionMode.type === "row" && !selectionMode.allowCellSelection) {
      selectRow(virtualRow.index);
    }
  }, [selectRow, virtualRow.index, selectionMode]);

  if (isSkeletonRow || rowId == null) {
    return (
      <DataTableRowSkeleton
        key={virtualRow.index}
        className={rowClassName}
        enableStickyColumns={enableStickyColumns}
        tableWidth={tableWidth}
        virtualRow={virtualRow}
        visibleColumns={visibleColumns}
      />
    );
  }

  return (
    <div
      aria-rowindex={virtualRow.index + 1}
      className={rowClassName}
      role="row"
      style={rowStyle}
      onClick={handleRowClick}
    >
      {renderedCells}
    </div>
  );
};

// Component for rendering skeleton row
interface IDataTableRowSkeleton {
  virtualRow: IVirtualRow;
  className: string;
  enableStickyColumns: boolean;
  tableWidth: number;
  visibleColumns: IVirtualColumn[];
}

function DataTableRowSkeleton({
  virtualRow,
  className,
  enableStickyColumns,
  tableWidth,
  visibleColumns,
}: IDataTableRowSkeleton): React.ReactElement {
  const rowStyle = useMemo(() => getRowStyle(virtualRow, tableWidth), [virtualRow, tableWidth]);

  return (
    <div key={virtualRow.index + 1} className={className} style={rowStyle}>
      {visibleColumns.map((column) => {
        return (
          <DataTableCellSkeleton
            key={column.index}
            enableStickyColumns={enableStickyColumns}
            isRowSelected={false}
            virtualColumn={column}
            virtualRow={virtualRow}
            visibleColumns={visibleColumns}
          />
        );
      })}
    </div>
  );
}

export const DataTableRow = memo(DataTableRowComponent);
