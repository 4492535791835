import React, { useMemo, useState } from "react";
import { createContext } from "use-context-selector";

interface IDataTableEditingContextType<TRowId extends string, TColId extends string> {
  editableCell: { rowId: TRowId; colId: TColId } | null;
  setEditableCell: React.Dispatch<React.SetStateAction<{ rowId: TRowId; colId: TColId } | null>>;
}

export const DataTableEditingContext = createContext<IDataTableEditingContextType<string, string>>(
  {} as IDataTableEditingContextType<string, string>,
);

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters -- can maybe be removed?
export function DataTableEditingProvider<TRowId extends string, TColId extends string>({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [editableCell, setEditableCell] = useState<{ rowId: TRowId; colId: TColId } | null>(null);

  const value = useMemo(() => ({ editableCell, setEditableCell }), [editableCell, setEditableCell]);

  // Since we cannot type contexts with generics we have to cast to any here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment -- context cant be typed with generics
  return <DataTableEditingContext.Provider value={value as any}>{children}</DataTableEditingContext.Provider>;
}
