import type { ILoadedActionLog, ILoadedViewField } from "@archetype/core";
import type { IVersionType } from "@archetype/dsl";
import { FieldValueParser } from "@archetype/dsl";
import type { IEmailId, IOrganizationId, IViewFieldId } from "@archetype/ids";
import { CommentTimestamp, Icon, useMemoDeepCompare } from "@archetype/ui";
import assertNever from "assert-never";
import { uniq } from "lodash";
import { useCallback, useState } from "react";

import { AiAgentUserName } from "../aiAgent/AiAgentUserName";
import { AiAgentUserProfileImage } from "../aiAgent/AiAgentUserProfileImage";
import { LoadingEmail } from "../email/LoadingEmail";
import { LoadingEmailTitle } from "../email/LoadingEmailTitle";
import { ExternalUserName } from "../user/ExternalUserName";
import { ExternalUserProfileImage } from "../user/ExternalUserProfileImage";
import { UserNameById } from "../user/UserNameById";
import { UserProfileImage } from "../user/UserProfileImage";

export const ActionLogItem: React.FC<{
  organizationId: IOrganizationId;
  versionType: IVersionType;
  actionLog: ILoadedActionLog;
  allFieldsById: Record<IViewFieldId, ILoadedViewField>;
}> = ({ organizationId, versionType, actionLog, allFieldsById }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = useCallback(() => {
    setExpanded((e) => !e);
  }, [setExpanded]);

  const emailIds = useMemoDeepCompare(() => {
    let otherEmailIds: IEmailId[] = [];

    switch (actionLog.executedBy.type) {
      case "email": {
        otherEmailIds = [actionLog.executedBy.emailId];
        break;
      }
      case "user": {
        otherEmailIds = actionLog.executedBy.emailIds ?? [];
        break;
      }
      case "aiAgent":
      case "externalUser": {
        break;
      }
      default: {
        assertNever(actionLog.executedBy);
      }
    }

    otherEmailIds = uniq(otherEmailIds);

    const mainEmailId = actionLog.executedBy.type === "email" ? actionLog.executedBy.emailId : undefined;

    return mainEmailId != null ? [mainEmailId].concat(otherEmailIds.filter((id) => id !== mainEmailId)) : otherEmailIds;
  }, [actionLog.executedBy]);

  return (
    <div
      className="border-accent hover:bg-accent flex w-full cursor-pointer border-b p-4 align-top"
      onClick={handleToggleExpand}
    >
      <div className="mr-2 shrink-0 overflow-hidden rounded-md">
        <ActionLogAuthorProfileImage actionLog={actionLog} />
      </div>
      <div className="h-fit w-0 grow transition-all">
        {/* Rest outside of the image */}
        <div className="flex flex-row justify-between text-base">
          {/* Title and time row */}
          <div className="flex items-center space-x-1">
            {actionLog.executedBy.type === "email" && (
              <LoadingEmailTitle emailId={actionLog.executedBy.emailId} organizationId={organizationId} />
            )}
            {actionLog.executedBy.type === "email" && <Icon className="text-primary size-3" fill="fill" name="zap" />}
            <span className="flex space-x-2">{actionLog.actionDisplayMetadata.name}</span>
          </div>
          <span className="text-muted-foreground text-sm">
            <CommentTimestamp timestamp={actionLog.executedAt} />
          </span>
        </div>
        <ActionLogAuthorName actionLog={actionLog} organizationId={organizationId} versionType={versionType} />
        <div className="text-base">{actionLog.comment}</div>
        {expanded ? <ActionLogDiff actionLog={actionLog} /> : null}
        {expanded && emailIds.length > 0
          ? emailIds.map((emailId) => (
              <LoadingEmail
                key={emailId}
                allFieldsById={allFieldsById}
                className="bg-paper mt-2"
                emailExtractedData={actionLog.emailExtractedData ?? undefined}
                emailId={emailId}
                organizationId={organizationId}
              />
            ))
          : null}
      </div>
    </div>
  );
};

const ActionLogAuthorProfileImage: React.FC<{ actionLog: ILoadedActionLog }> = ({ actionLog }) => {
  switch (actionLog.executedBy.type) {
    case "email":
    case "user": {
      return <UserProfileImage className="size-6" id={actionLog.executedBy.userId} />;
    }
    case "aiAgent": {
      return <AiAgentUserProfileImage className="size-6" />;
    }
    case "externalUser": {
      return <ExternalUserProfileImage className="size-6" />;
    }
    default: {
      return null;
    }
  }
};

const ActionLogAuthorName: React.FC<{
  actionLog: ILoadedActionLog;
  versionType: IVersionType;
  organizationId: IOrganizationId;
}> = ({ actionLog, versionType, organizationId }) => {
  switch (actionLog.executedBy.type) {
    case "email":
    case "user": {
      return <UserNameById className="text-muted-foreground text-sm" id={actionLog.executedBy.userId} />;
    }
    case "aiAgent": {
      return <AiAgentUserName className="text-sm" />;
    }
    case "externalUser": {
      return (
        <ExternalUserName
          className="text-muted-foreground text-sm"
          entityId={actionLog.executedBy.userEntityId}
          organizationId={organizationId}
          versionType={versionType}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const ActionLogDiff: React.FC<{
  actionLog: ILoadedActionLog;
}> = ({ actionLog }) => {
  if (actionLog.loadedAction == null) {
    return null;
  }

  return (
    <div className="mt-2 space-y-1">
      {actionLog.loadedAction.actionDefinition.inputs.map((input) => {
        if (!input.allowChangingDefault) {
          return null;
        }

        const columnType = input.viewField.type === "column" ? input.viewField.column.columnType : undefined;

        const valueBefore = actionLog.entitySnapshotBefore?.[input.viewField.id];
        const valueAfter = actionLog.entitySnapshotAfter?.[input.viewField.id];

        if (valueAfter == null) {
          return null;
        }

        if (valueBefore == null || (typeof valueBefore === "object" && valueBefore.type === "null")) {
          return (
            <div key={input.viewField.id} className="text-muted-foreground text-sm">
              <span className="font-semibold">{input.viewField.displayName}</span>
              {" set to "}
              <span className="font-semibold">{FieldValueParser.toReadableString(valueAfter, columnType)}</span>
            </div>
          );
        }

        return (
          <div key={input.viewField.id} className="text-muted-foreground text-sm">
            <span className="font-semibold">{input.viewField.displayName}</span>
            {" updated from "}
            <span>{FieldValueParser.toReadableString(valueBefore, columnType)}</span>
            {" to "}
            <span className="font-semibold">{FieldValueParser.toReadableString(valueAfter, columnType)}</span>
          </div>
        );
      })}
    </div>
  );
};
