import { cn } from "@archetype/ui";
import { range } from "lodash";
import React from "react";

import { EntityFieldsSkeleton } from "../entity/EntityFieldsSkeleton";
import { ActionFieldSkeleton } from "./ActionFieldSkeleton";

export const ActionFormSkeleton: React.FC<{ className?: string; fieldsCount?: number }> = ({
  className,
  fieldsCount = 4,
}) => {
  return (
    <div className={cn("flex flex-col space-y-4", className)}>
      <EntityFieldsSkeleton fieldsCount={fieldsCount} />
      {range(fieldsCount).map((idx) => (
        <ActionFieldSkeleton key={idx} />
      ))}
      <ActionFieldSkeleton />
    </div>
  );
};
