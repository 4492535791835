import type { IViewFieldValue } from "@archetype/dsl";
import type { IViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import type { EditorState } from "@archetype/ui";
import {
  cn,
  DEFAULT_TRANSFORMERS,
  defaultConfig,
  DefaultPlugins,
  editorStateToMarkdown,
  MarkdownEditor,
  markdownToEditorState,
} from "@archetype/ui";
import type { LexicalEditor } from "lexical";
import { $setSelection } from "lexical";
import { concat } from "lodash";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { usePrevious } from "react-use";

import { EntityFieldMentionNode } from "../../../markdown/EntityFieldMentionNode";
import {
  EntityFieldMentionsPlugin,
  generateEntityFieldMentionTransformer,
} from "../../../markdown/EntityFieldMentionsPlugin";
import type { IActionInputComponent } from "../types";

export const LongStringInput: IActionInputComponent = ({
  className,
  field,
  onChange,
  readOnly = false,
  isFieldTouched,
  versionType,
  entityTypeId,
  organizationId,
  entity,
}) => {
  const formContext = useFormContext<IViewFieldValue>();
  const formValues = useWatch({ control: formContext.control });

  const editorRef = useRef<LexicalEditor>(null);

  const previousStringValue = usePrevious(field.value?.type === "string" ? field.value.value : undefined);

  const { data: entityTypeQuery } = builderTrpc.dataModel.fullyLoadedEntityType.useQuery({
    id: entityTypeId,
    versionType,
  });

  const fieldValues: Record<IViewFieldId, IViewFieldValue> = useMemo(
    () => ({
      ...entity?.fields,
      ...formValues,
    }),
    [entity?.fields, formValues],
  );

  const transformers = useMemo(
    () =>
      concat(
        generateEntityFieldMentionTransformer({
          fieldValues,
          loadedEntityType: entityTypeQuery?.entityType,
          variant: "badge",
        }),
        DEFAULT_TRANSFORMERS,
      ),
    [fieldValues, entityTypeQuery?.entityType],
  );

  useEffect(() => {
    if (isFieldTouched) {
      // If field has been actually touched by a user then should not do anything
      // We could probably align that logic with showing the autofill tag or not otherwise
      return;
    }

    const currentStringValue = field.value?.type === "string" ? field.value.value : undefined;

    if (previousStringValue !== currentStringValue) {
      // setForceRerenderKey((prev) => prev + 1);

      editorRef.current?.update(
        () => {
          markdownToEditorState(currentStringValue ?? "", transformers)();

          $setSelection(null);
        },
        { discrete: true },
      );
    }
  }, [isFieldTouched, previousStringValue, field.value, transformers]);

  const handleChange = useCallback(
    (editor: LexicalEditor, editorState: EditorState) => {
      editor.read(() => {
        const markdown = editorStateToMarkdown(editorState, transformers)();

        const currentStringValue = field.value?.type === "string" ? field.value.value : "";

        if (markdown === currentStringValue) {
          return;
        }

        if (markdown.length === 0) {
          onChange({ type: "null" });
        } else {
          onChange({ type: "string", value: markdown });
        }
      });
    },
    [onChange, transformers, field.value],
  );

  const initialContent = useMemo(() => {
    return markdownToEditorState(field.value?.type === "string" ? field.value.value : "", transformers);
  }, [field.value, transformers]);

  const editorConfig = useMemo(
    () => ({
      ...defaultConfig,
      editorState: initialContent,
      nodes: (defaultConfig.nodes ?? []).concat(EntityFieldMentionNode),
    }),
    [initialContent],
  );

  return (
    <MarkdownEditor
      className={cn("size-full min-h-[400px] cursor-text overflow-y-auto p-0", className)}
      editorRef={editorRef}
      enableAnnotations={false}
      initialConfig={editorConfig}
      placeholder=""
      readOnly={readOnly}
      toolbar="floating"
      onChange={handleChange}
    >
      <EntityFieldMentionsPlugin
        entityTypeId={entityTypeId}
        fieldValues={fieldValues}
        organizationId={organizationId}
        versionType={versionType}
      />
      <DefaultPlugins autoFocus={false} transformers={transformers} />
    </MarkdownEditor>
  );
};
