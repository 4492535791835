import { builderTrpc } from "@archetype/trpc-react";
import type { IButtonProps } from "@archetype/ui";
import { Button, ButtonWithNotification } from "@archetype/ui";
import { skipToken } from "@tanstack/react-query";
import Link from "next/link";
import { useCallback } from "react";

import { createFileLogger } from "../logger";
import type { IActionExecutionComponentWrapperProps } from "./useActionDirectExecutionWrapper";
import { useActionDirectExecutionWrapper } from "./useActionDirectExecutionWrapper";

export const logger = createFileLogger("ActionButton");

interface IActionButtonProps extends IActionExecutionComponentWrapperProps {
  className?: string;
  variant?: IButtonProps["variant"];
  size?: IButtonProps["size"];
  labelOverride?: string;
}

export const ActionButton: React.FC<IActionButtonProps> = ({
  className,
  presetParameters,
  labelOverride,
  versionType,
  action,
  entityId,
  size,
  variant,
  onActionExecuted,
  dataTourId,
  getActionRoute,
  getFullPageActionRoute,
}) => {
  const { executeActionEnabled, actionRouteIfNonDirectExecution, isExecutingAction, handleExecuteAction } =
    useActionDirectExecutionWrapper({
      entityId,
      presetParameters,
      versionType,
      action,
      onActionExecuted,
      getActionRoute,
      getFullPageActionRoute,
    });

  const { data: hasActionDraft } = builderTrpc.action.getHasDraftForAction.useQuery(
    entityId == null
      ? skipToken
      : {
          versionType,
          organizationId: action.organizationId,
          actionId: action.id,
          entityId,
        },
  );

  const handleLinkClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  }, []);

  const finalLabel = labelOverride ?? action.displayMetadata.name;

  if (actionRouteIfNonDirectExecution == null) {
    return (
      <Button
        className={className}
        data-tour-id={dataTourId}
        disabled={!executeActionEnabled}
        isLoading={isExecutingAction}
        size={size}
        variant={variant}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- functional
        onClick={handleExecuteAction}
      >
        {finalLabel}
      </Button>
    );
  }

  return (
    <Link href={actionRouteIfNonDirectExecution} onClick={handleLinkClick}>
      <ButtonWithNotification
        containerClassName={className}
        data-testid="action-button"
        data-tour-id={dataTourId}
        disabled={!executeActionEnabled}
        isLoading={isExecutingAction}
        showDot={hasActionDraft?.hasDraft === true}
        size={size}
        variant={variant}
      >
        {finalLabel}
      </ButtonWithNotification>
    </Link>
  );
};
