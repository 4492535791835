import { ColumnId, EntityTypeId } from "@archetype/ids";
import { z } from "zod";

import { IdentifiableFeature } from "./common/IdentifiableFeature";

export const DataTypeRefinementFeature = z // limitation for a specific column
  .object({
    type: z.literal("dataTypeRefinement"),

    entityType: EntityTypeId,

    column: ColumnId,

    /**
     * a refinement to be enforced on the column type
     */
    refinement: z.discriminatedUnion("type", [
      // DataTypeNonNullableRefinement,
      z.object({
        type: z.literal("numberRange"),
        inclusiveMin: z.number().optional().nullable().describe("The minimum value of the number"),
        inclusiveMax: z.number().optional().nullable().describe("The maximum value of the number"),
      }),
      z.object({
        type: z.literal("stringRegex"),
        regex: z.string().describe("A regex to validate the string"),
      }),
      z.object({
        type: z.literal("stringEndsWith"),
        endsWithString: z.string().describe("The string that child must end with"),
      }),
      z.object({
        type: z.literal("stringEquals"),
        value: z.string(),
      }),
      z.object({
        type: z.literal("stringLength"),
        inclusiveMin: z.number().optional().nullable().describe("The minimum length of the string"),
        inclusiveMax: z.number().optional().nullable().describe("The maximum length of the string"),
      }),
      z.object({
        type: z.literal("enum"),
        allowedValues: z.array(z.string()).describe("The allowed values for the enum, must have at least one value"),
      }),
    ]),
  })
  .extend(IdentifiableFeature.shape)
  .describe("A refinement to be enforced on the column type");
export type IDataTypeRefinementFeature = z.infer<typeof DataTypeRefinementFeature>;

// No column naming feature, TBD if we want it
export const CompositeRefinementFeature = z.object({
  type: z.literal("compositeRefinement"),
  entityType: EntityTypeId,

  anyMustFit: z.array(
    z.object({
      allMustFit: z.array(
        DataTypeRefinementFeature.pick({
          column: true,
          refinement: true,
        }),
      ),
    }),
  ),
});
export type ICompositeRefinementFeature = z.infer<typeof CompositeRefinementFeature>;

export const ColumnDataTypeFeature = z.discriminatedUnion("type", [
  DataTypeRefinementFeature.extend(IdentifiableFeature.shape),
]);
export type IColumnDataTypeFeature = z.infer<typeof ColumnDataTypeFeature>;

export const DataModelFeature = z.discriminatedUnion("type", [
  ...ColumnDataTypeFeature.options,
  CompositeRefinementFeature.extend(IdentifiableFeature.shape),
]);
export type IDataModelFeature = z.infer<typeof DataModelFeature>;
