import { ColumnTypeId } from "@archetype/ids";
import { z } from "zod";

export const ColumnBooleanType = z.object({ type: z.literal("boolean"), primitiveType: z.literal("boolean") });
export type IColumnBooleanType = z.infer<typeof ColumnBooleanType>;

// Non-nullable boolean refinement
export const ColumnNonNullableBooleanRefinement = z
  .object({
    type: z.literal("nonNullableBoolean"),
    primitiveType: z.literal("boolean"),
    child: ColumnTypeId,
  })
  .describe("Refine a boolean to never be null");
export type IColumnNullableBooleanRefinement = z.infer<typeof ColumnNonNullableBooleanRefinement>;

export const ColumnBooleanRefinements = z.discriminatedUnion("type", [
  ColumnBooleanType,
  ColumnNonNullableBooleanRefinement,
]);
export type IColumnBooleanRefinements = z.infer<typeof ColumnBooleanRefinements>;
