import { cn, Skeleton } from "@archetype/ui";
import React, { memo, useMemo } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableEditingContext } from "../../context/DataTableEditingContext";
import { DataTableInstanceContext } from "../../context/DataTableInstanceContext";
import { DataTableSelectionContext } from "../../context/DataTableSelectionContext";
import type { IVirtualRow } from "../../hooks/useGridVirtualization";
import type { IVirtualColumn } from "../../hooks/useGridVirtualization";

interface IDataTableCellProps<TRowId extends string, TColId extends string> {
  rowId: TRowId;
  colId: TColId;
  virtualRow: IVirtualRow;
  virtualColumn: IVirtualColumn;
  enableStickyColumns: boolean;
  isRowSelected: boolean;
  visibleColumns: IVirtualColumn[];
}

interface IPinnedColumnInfo {
  isLastLeftPinned: boolean;
  isFirstRightPinned: boolean;
}

const getPinnedColumnInfo = (
  virtualColumn: IVirtualColumn,
  nextVirtualColumn: IVirtualColumn | undefined,
  previousVirtualColumn: IVirtualColumn | undefined,
): IPinnedColumnInfo => ({
  isLastLeftPinned: virtualColumn.pinned === "left" && nextVirtualColumn?.pinned !== "left",
  isFirstRightPinned: virtualColumn.pinned === "right" && previousVirtualColumn?.pinned !== "right",
});

const getBaseCellClassName = ({
  isCellSelected,
  isRowSelected,
  enableStickyColumns,
  isLastLeftPinned,
  isFirstRightPinned,
  pinnedPosition,
}: {
  isCellSelected: boolean;
  isRowSelected: boolean;
  enableStickyColumns: boolean;
  isLastLeftPinned: boolean;
  isFirstRightPinned: boolean;
  pinnedPosition: "left" | "right" | null;
}): string =>
  cn(
    "group/cell absolute overflow-hidden focus:outline-none border-border",
    enableStickyColumns && pinnedPosition === "left" && "z-10 border-b border-r",
    enableStickyColumns && pinnedPosition === "right" && "z-10 ml-auto border-b border-l",
    enableStickyColumns && isLastLeftPinned && "border-r",
    enableStickyColumns && isFirstRightPinned && "border-l",
    enableStickyColumns && pinnedPosition != null && "sticky bg-paper",
    isRowSelected && "bg-paper-alt",
    isCellSelected && "selected-highlight",
    "group-hover/row:bg-paper-alt group-hover/row:border-border group-hover/row:border-b",
  );

const getCellStyle = (virtualColumn: IVirtualColumn, virtualRow: IVirtualRow): React.CSSProperties => {
  const style: React.CSSProperties = {
    width: virtualColumn.width,
    height: virtualRow.height,
  };

  if (virtualColumn.pinned === "right") {
    style.right = virtualColumn.right;
  } else {
    style.left = virtualColumn.left;
  }

  return style;
};

// Create a comparison function for memo
const areEqual = (
  prevProps: IDataTableCellProps<string, string>,
  nextProps: IDataTableCellProps<string, string>,
): boolean => {
  return (
    prevProps.rowId === nextProps.rowId &&
    prevProps.colId === nextProps.colId &&
    prevProps.virtualRow === nextProps.virtualRow &&
    prevProps.virtualColumn === nextProps.virtualColumn &&
    prevProps.enableStickyColumns === nextProps.enableStickyColumns &&
    prevProps.isRowSelected === nextProps.isRowSelected
  );
};

export const DataTableCell = memo(function DataTableCell<TRowId extends string, TColId extends string>({
  rowId,
  colId,
  virtualRow,
  virtualColumn,
  enableStickyColumns,
  isRowSelected,
  visibleColumns,
}: IDataTableCellProps<TRowId, TColId>): React.ReactNode {
  const selectionMode = useContextSelector(DataTableSelectionContext, (state) => state.selectionMode.type);

  const isCellSelected = useContextSelector(
    DataTableSelectionContext,
    (state) => state.selectedCells.get(rowId)?.has(colId) ?? false,
  );

  const isCellEditing = useContextSelector(
    DataTableEditingContext,
    (state) => state.editableCell?.rowId === rowId && state.editableCell.colId === colId,
  );

  const column = useContextSelector(DataTableInstanceContext, (state) => state.columnMap.get(colId));
  const row = useContextSelector(DataTableInstanceContext, (state) => state.data[virtualRow.index]);
  const cellValue = useMemo(() => (row != null ? column?.accessorFn(row) : undefined), [column, row]);

  const nextVirtualColumn = visibleColumns[virtualColumn.index + 1];
  const previousVirtualColumn = visibleColumns[virtualColumn.index - 1];

  const { isLastLeftPinned, isFirstRightPinned } = getPinnedColumnInfo(
    virtualColumn,
    nextVirtualColumn,
    previousVirtualColumn,
  );

  const cellClassName = useMemo(
    () =>
      getBaseCellClassName({
        isCellSelected,
        isRowSelected,
        enableStickyColumns,
        isLastLeftPinned,
        isFirstRightPinned,
        pinnedPosition: virtualColumn.pinned,
      }),
    [isCellSelected, isRowSelected, enableStickyColumns, isLastLeftPinned, isFirstRightPinned, virtualColumn.pinned],
  );

  const cellStyle = useMemo(() => getCellStyle(virtualColumn, virtualRow), [virtualColumn, virtualRow]);

  const content = useMemo(() => {
    if (column == null || row == null || column.cellRenderer === "empty") {
      return null;
    }

    return column.cellRenderer({
      rowId,
      colId,
      readOnly: column.readOnly,
      isCellSelected,
      isCellEditing,
      isRowSelected,
      selectionMode,
      value: cellValue,
      row,
    });
  }, [column, row, rowId, colId, isCellSelected, isCellEditing, isRowSelected, selectionMode, cellValue]);

  if (column?.cellRenderer === "empty") {
    return null;
  }

  if (column == null || row == null) {
    return (
      <DataTableCellSkeleton
        enableStickyColumns={enableStickyColumns}
        isRowSelected={isRowSelected}
        virtualColumn={virtualColumn}
        virtualRow={virtualRow}
        visibleColumns={visibleColumns}
      />
    );
  }

  return (
    <div
      key={virtualColumn.index}
      aria-colindex={virtualColumn.index + 1}
      aria-selected={isCellSelected}
      className={cellClassName}
      role="cell"
      style={cellStyle}
    >
      {content}
    </div>
  );
}, areEqual);

interface IDataTableCellSkeleton {
  virtualRow: IVirtualRow;
  virtualColumn: IVirtualColumn;
  enableStickyColumns: boolean;
  isRowSelected: boolean;
  visibleColumns: IVirtualColumn[];
}

export function DataTableCellSkeleton({
  virtualRow,
  virtualColumn,
  enableStickyColumns,
  isRowSelected,
  visibleColumns,
}: IDataTableCellSkeleton): React.ReactNode {
  const nextVirtualColumn = visibleColumns[virtualColumn.index + 1];
  const previousVirtualColumn = visibleColumns[virtualColumn.index - 1];

  const { isLastLeftPinned, isFirstRightPinned } = getPinnedColumnInfo(
    virtualColumn,
    nextVirtualColumn,
    previousVirtualColumn,
  );

  const cellClassName = useMemo(
    () =>
      cn(
        getBaseCellClassName({
          isCellSelected: false,
          isRowSelected,
          enableStickyColumns,
          isLastLeftPinned,
          isFirstRightPinned,
          pinnedPosition: virtualColumn.pinned,
        }),
        "p-2",
      ),
    [isRowSelected, enableStickyColumns, isLastLeftPinned, isFirstRightPinned, virtualColumn.pinned],
  );

  const cellStyle = useMemo(() => getCellStyle(virtualColumn, virtualRow), [virtualColumn, virtualRow]);

  return (
    <div className={cn(cellClassName, "flex flex-col justify-center")} role="cell" style={cellStyle}>
      <Skeleton className="h-4 w-full" />
    </div>
  );
}
