import type { IColumnType } from "@archetype/dsl";
import { simpleColumnTypeToPrimitiveType } from "@archetype/dsl";
import { match } from "ts-pattern";

import { ActionPhoneInput } from "./builtin/ActionPhoneInput";
import { DateInput } from "./builtin/DateInput";
import { EnumInput } from "./builtin/EnumInput";
import { EnumMultiSelectInput } from "./builtin/EnumMultiSelectInput";
import { StatusEnumInput } from "./builtin/StatusEnumInput";
import { TimestampInput } from "./builtin/TimestampInput";
import { ActionFileUploader } from "./primitive/ActionFileUploader";
import { BooleanInput } from "./primitive/BooleanInput";
import { LongStringInput } from "./primitive/LongStringInput";
import { NumberInput } from "./primitive/NumberInput";
import { StringInput } from "./primitive/StringInput";
import type { IActionInputComponent } from "./types";

// Could merge both with a safe function from simpleColumnType to primitive
export const getInputForDefinition = (columnType: IColumnType): IActionInputComponent => {
  const primitiveType = simpleColumnTypeToPrimitiveType(columnType);

  return (
    getSimpleDataModelSpecialInput(columnType) ??
    match(primitiveType)
      .returnType<IActionInputComponent>()
      .with("boolean", () => BooleanInput)
      .with("string", () => StringInput)
      .with("number", () => NumberInput)
      .with("file", () => ActionFileUploader)
      .exhaustive()
  );
};

const getSimpleDataModelSpecialInput = (columnType: IColumnType): IActionInputComponent | undefined => {
  switch (columnType.type) {
    case "date": {
      return DateInput;
    }
    case "timestamp": {
      return TimestampInput;
    }
    case "file": {
      return ActionFileUploader;
    }
    case "phone": {
      return ActionPhoneInput;
    }
    case "email":
    case "shortText":
    case "number":
    case "boolean":
    case "url":
    case "geolocation": {
      return undefined;
    }
    case "longText": {
      return LongStringInput;
    }
    case "enum": {
      if (columnType.enumAllowedValues != null && columnType.enumAllowedValues.length > 0) {
        if (columnType.enumInclusiveMaxValuesToSelect === 1) {
          return EnumInput;
        }

        return EnumMultiSelectInput;
      }

      return undefined;
    }
    case "statusEnum": {
      if (columnType.allowedValues.length > 0) {
        return StatusEnumInput;
      }

      return undefined;
    }
  }
};
