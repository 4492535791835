import type { ILoadedRelationViewField } from "@archetype/core";
import type { IEntityTypeColor, IEntityTypeCore, IEntityTypeShapeName, IJoinCardinality } from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";

export const getRelationViewMetadata = (
  { relation, direction }: ILoadedRelationViewField,
  allEntityTypes: Record<IEntityTypeId, IEntityTypeCore | undefined>,
):
  | {
      entityId: IEntityTypeId;
      name: string;
      iconColor: IEntityTypeColor;
      iconShape: IEntityTypeShapeName;
      cardinality: IJoinCardinality;
    }
  | undefined => {
  const entityId = direction === "aToB" ? relation.entityTypeIdB : relation.entityTypeIdA;
  const entity = allEntityTypes[entityId];

  if (!entity) return undefined;

  const name = direction === "aToB" ? relation.displayMetadataFromAToB.name : relation.displayMetadataFromBToA.name;
  const cardinality = direction === "aToB" ? relation.config.cardinalityOnSideB : relation.config.cardinalityOnSideA;

  return { entityId, name, iconColor: entity.color, iconShape: entity.shape, cardinality };
};
