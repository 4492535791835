import type {
  IActionCore,
  IEntityTypeCore,
  IStateMachine,
  IStateMachineChange,
  IStateMachineState,
  IStateMachineStateTransition,
} from "@archetype/dsl";
import {
  isStateMachineAddActionChange,
  isStateMachineAddStateChange,
  isStateMachineRemoveActionChange,
  isStateMachineRemoveStateChange,
  isTargetEntityTypeCore,
} from "@archetype/dsl";
import type { IActionId, IApplicationGroupId, IEntityTypeId } from "@archetype/ids";
import { ActionId, TransitionId } from "@archetype/ids";
import { flatMap, groupByNoUndefined, isNonNullable, keyByNoUndefined, mapKeysNoUndefined } from "@archetype/utils";
import assertNever from "assert-never";

import { createInternalEmployeeAuthorization } from "../auth/createInternalEmployeeAuthorization";
import type { IEditOperation } from "../stateMachine/editOperations/operations";

export function convertStateMachineChangesToEditOperations({
  changes,
  entityTypesById,
  stateMachinesByApplicationGroupId,
  actionsById,
  userEntityTypeId,
}: {
  changes: IStateMachineChange[];
  entityTypesById: Record<IEntityTypeId, IEntityTypeCore>;
  stateMachinesByApplicationGroupId: Record<IApplicationGroupId, IStateMachine>;
  actionsById: Record<IActionId, IActionCore>;
  userEntityTypeId: IEntityTypeId;
}): IEditOperation[] {
  const changesByEntityTypeId = groupByNoUndefined(changes, (change) => change.entityTypeId);

  const operations: IEditOperation[] = flatMap(changesByEntityTypeId, (entityTypeChanges, entityTypeId) => {
    const entityType = entityTypesById[entityTypeId];

    if (entityType == null) {
      throw new Error(`Entity type ${entityTypeId} not found`);
    }

    if (!isTargetEntityTypeCore(entityType)) {
      throw new Error(`Entity type ${entityTypeId} is not a target entity type`);
    }

    const stateMachine = stateMachinesByApplicationGroupId[entityType.targetEntityTypeApplicationGroupId];

    if (stateMachine == null) {
      throw new Error(`State machine for application group ${entityType.targetEntityTypeApplicationGroupId} not found`);
    }

    const statesById = mapKeysNoUndefined(stateMachine.states, (state) => state.id);
    const transitionsByActionId = keyByNoUndefined(stateMachine.stateTransitions, (t) => t.actionId);

    return entityTypeChanges.map((change) => {
      if (isStateMachineRemoveActionChange(change)) {
        const action = actionsById[change.actionIdToRemove];

        if (!action) {
          throw new Error(`Action ${change.actionIdToRemove} not found`);
        }
        const transition = transitionsByActionId[action.id];

        if (!transition) {
          throw new Error(`Transition for action ${action.id} not found`);
        }

        return {
          type: "deleteTransition",
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          targetEntityTypeId: entityType.id,
          transitionId: transition.id,
        };
      }

      if (isStateMachineRemoveStateChange(change)) {
        const stateToRemove = statesById[change.stateIdToRemove];

        if (!stateToRemove) {
          throw new Error(`State ${change.stateIdToRemove} not found`);
        }

        return {
          type: "deleteState",
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          targetEntityTypeId: entityType.id,
          stateId: stateToRemove.id,
        };
      }

      if (isStateMachineAddActionChange(change)) {
        const fromState = statesById[change.actionToAdd.fromStateId];
        const toState = statesById[change.actionToAdd.toStateId];

        if (fromState == null) {
          throw new Error(`State ${change.actionToAdd.fromStateId} not found`);
        }

        if (toState == null) {
          throw new Error(`State ${change.actionToAdd.toStateId} not found`);
        }

        const action: IActionCore = {
          id: change.actionToAdd.actionId,
          displayMetadata: { name: change.actionToAdd.actionName },
          entityTypeId: entityType.id,
          organizationId: entityType.organizationId,
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          actionDefinition: {
            actionType: "modify",
            fromStates: [fromState.id],
            toState: toState.id,
            inputs: [],
            contextualFields: [],
            authorizedByAnyOf: [createInternalEmployeeAuthorization({ userEntityTypeId })],
          },
        };

        const transition: IStateMachineStateTransition = {
          from: fromState.id,
          to: toState.id,
          id: TransitionId.generate(),
          actionId: action.id,
        };

        transitionsByActionId[action.id] = transition;
        actionsById[action.id] = action;

        return {
          type: "createNewTransition",
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          targetEntityTypeId: entityType.id,
          newTransition: transition,
          newTransitionAction: action,
          newHappyPath: change.happyPathStateIds.map((id) => statesById[id]?.id).filter(isNonNullable),
        };
      }

      if (isStateMachineAddStateChange(change)) {
        const state: IStateMachineState = {
          id: change.stateToAdd.stateId,
          label: change.stateToAdd.stateName,
          color: change.stateToAdd.stateUniqueColor,
        };

        const fromState = statesById[change.actionToStateToAdd.fromStateId];

        if (fromState == null) {
          throw new Error(`State ${change.actionToStateToAdd.fromStateId} not found`);
        }

        const action: IActionCore = {
          id: ActionId.generate(),
          displayMetadata: { name: change.actionToStateToAdd.actionName },
          entityTypeId: entityType.id,
          organizationId: entityType.organizationId,
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          actionDefinition: {
            actionType: "modify",
            fromStates: [fromState.id],
            toState: state.id,
            inputs: [],
            contextualFields: [],
            authorizedByAnyOf: [createInternalEmployeeAuthorization({ userEntityTypeId })],
          },
        };

        const transition: IStateMachineStateTransition = {
          from: fromState.id,
          to: state.id,
          id: TransitionId.generate(),
          actionId: action.id,
        };

        statesById[state.id] = state;
        transitionsByActionId[action.id] = transition;
        actionsById[action.id] = action;

        return {
          type: "createNewState",
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          targetEntityTypeId: entityType.id,
          newState: state,
          newTransition: transition,
          newTransitionAction: action,
          newHappyPath: change.happyPathStateIds.map((id) => statesById[id]?.id).filter(isNonNullable),
          asInitialState: false,
        };
      }

      assertNever(change);
    });
  });

  return operations;
}
