import {
  EntityActionEmailEffectOverride,
  EntityType,
  ValidationError,
  ValidationGroup,
  VersionType,
  ViewFieldValue,
} from "@archetype/dsl";
import { ActionId, EntityId, EntityTypeId, ViewFieldId } from "@archetype/ids";
import { z } from "zod";

import { LoadedAction } from "./LoadedActionType";
import { LoadedEntity } from "./LoadedEntity";

export const NestedCreateFormValues = z.record(
  EntityTypeId,
  z
    .record(
      EntityId,
      z
        .object({
          createActionId: ActionId,
          fieldValues: z.record(ViewFieldId, ViewFieldValue.optional()),
        })
        .optional(),
    )
    .optional(),
);

export const LoadedCreateNestedAction = z.object({
  createAction: LoadedAction,
  entityType: EntityType,
  validationGroups: z.array(ValidationGroup),
  // Add the relevant fields by lookup or required
  relevantFields: z.array(ViewFieldId),
});

export const LoadedNestedCreateFormValuesForEntity = z.object({
  createAction: LoadedAction,
  entityType: EntityType,
  /**
   * Validation groups for the entity type
   */
  validationGroups: z.array(ValidationGroup),
  fieldValues: z.record(ViewFieldId, ViewFieldValue),
});

export const LoadedNestedCreateFormValues = z.record(
  EntityTypeId,
  z.record(EntityId, LoadedNestedCreateFormValuesForEntity),
);

export const ExecuteActionQuery = z.object({
  versionType: VersionType,
  actionId: ActionId,
  /**
   * Can be null only for a create action if there is no draft entity
   */
  entityId: EntityId.nullable(),
  fieldValues: z.nullable(z.record(ViewFieldId, ViewFieldValue.optional())),
  emailEffectOverride: EntityActionEmailEffectOverride.optional(),
  externalUserFieldValues: z.nullable(z.record(ViewFieldId, ViewFieldValue.optional())),
  externalUserSyntheticEntityId: z.nullable(EntityId),
  nestedCreateFormValues: NestedCreateFormValues,
});
export type IExecuteActionQuery = z.infer<typeof ExecuteActionQuery>;

export const ExecuteActionSuccess = z.object({
  success: z.literal(true),
  entitySnapshotAfter: z.record(ViewFieldId, ViewFieldValue.optional()).nullable(),
  entitySnapshotBefore: z.record(ViewFieldId, ViewFieldValue.optional()).nullable(),
  entityTypeId: EntityTypeId,
  /**
   * Can still be provided for a deleted entity
   */
  entityId: EntityId,
  /**
   * Will be null for delete actions as the entity does not have a new state
   */
  entity: LoadedEntity.nullable(),
});

export const NestedActionErrors = z.record(
  EntityTypeId,
  z.record(EntityId, z.array(ValidationError).optional()).optional(),
);

export const ReplacementNestedEntityIds = z.record(
  EntityTypeId,
  z.record(EntityId, z.object({ realEntityId: EntityId, displayName: z.string() }).optional()).optional(),
);

export const ExecuteActionError = z.object({
  success: z.literal(false),
  errors: z.array(ValidationError),
  nestedActionErrors: NestedActionErrors,
  successReplacementNestedEntityIds: ReplacementNestedEntityIds,
});

export const ExecuteActionResponse = z.union([ExecuteActionSuccess, ExecuteActionError]);

export type IExecuteActionSuccess = z.infer<typeof ExecuteActionSuccess>;
export type INestedActionErrors = z.infer<typeof NestedActionErrors>;
export type IExecuteActionError = z.infer<typeof ExecuteActionError>;
export type IExecuteActionResponse = z.infer<typeof ExecuteActionResponse>;
export type INestedCreateFormValues = z.infer<typeof NestedCreateFormValues>;
export type ILoadedCreateNestedAction = z.infer<typeof LoadedCreateNestedAction>;
export type ILoadedNestedCreateFormValuesForEntity = z.infer<typeof LoadedNestedCreateFormValuesForEntity>;
export type ILoadedNestedCreateFormValues = z.infer<typeof LoadedNestedCreateFormValues>;
export type IReplacementNestedEntityIds = z.infer<typeof ReplacementNestedEntityIds>;
