"use client";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";

import { cn } from "../../lib/utils";

const TooltipProvider = TooltipPrimitive.Provider;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipPortal = TooltipPrimitive.Portal;

const TooltipProviderContext = React.createContext(false);

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPortal>
    <TooltipPrimitive.Content
      ref={ref}
      className={cn(
        "border-border bg-paper text-ink animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1 z-50 max-w-60 cursor-default overflow-hidden rounded-md border px-3 py-1.5 text-sm shadow",
        className,
      )}
      sideOffset={sideOffset}
      {...props}
    />
  </TooltipPortal>
));

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const Tooltip = ({ children, ...props }: React.ComponentProps<typeof TooltipPrimitive.Root>): React.JSX.Element => {
  const hasProvider = React.useContext(TooltipProviderContext);

  if (hasProvider) {
    return <TooltipPrimitive.Root {...props}>{children}</TooltipPrimitive.Root>;
  }

  return (
    <TooltipProvider>
      <TooltipProviderContext.Provider value={true}>
        <TooltipPrimitive.Root {...props}>{children}</TooltipPrimitive.Root>
      </TooltipProviderContext.Provider>
    </TooltipProvider>
  );
};

Tooltip.displayName = TooltipPrimitive.Root.displayName;

export type ITooltipContentProps = TooltipPrimitive.TooltipContentProps;
export { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger };
