import { FeatureSuggestionId } from "@archetype/ids";
import { z } from "zod";

export const FeatureSuggestionBase = z.object({
  id: FeatureSuggestionId,
  clientFacingDescription: z.string(),
  internalTechnicalDescription: z.string(),
});

export type IFeatureSuggestionBase = z.infer<typeof FeatureSuggestionBase>;

export const FeatureSuggestionWorkspaceCategory = z.enum([
  "createNewWorkflow",
  "createNewSupportEntityTypeForWorkflow",
  "createNewProcessEntityTypeForWorkflow",
  "modifyEntityTypes",
]);

export type IFeatureSuggestionWorkspaceCategory = z.infer<typeof FeatureSuggestionWorkspaceCategory>;

export const FeatureSuggestionSupportEntityTypeCategory = z.enum([
  "changeEntityTypePermissions",
  "modifyColumns",
  "addColumnValidations",
  "addColumnAutofills",
  "addRelations",
  "modifyRelations",
  "removeRelations",
]);

export type IFeatureSuggestionSupportEntityTypeCategory = z.infer<typeof FeatureSuggestionSupportEntityTypeCategory>;

export const FeatureSuggestionProcessEntityTypeCategory = z.enum([
  ...FeatureSuggestionSupportEntityTypeCategory.options,
  "modifyEntityTypeProcess",
  "addNewAction",
  "addNewState",
  "modifyAction",
]);

export type IFeatureSuggestionProcessEntityTypeCategory = z.infer<typeof FeatureSuggestionProcessEntityTypeCategory>;

export const FeatureSuggestionEntityAtStateCategory = z.enum([
  "addExistingColumnsToForm",
  "addNewColumnsToForm",
  "addExistingRelationsToForm",
  "addNewRelationsToForm",
  "addExistingRelationsToForm",
  "removeColumnsFromForm",
  "removeColumnsFromEntityType",
  "modifyFormDisplayOrdering",
  "changeViewPermissionsAtThisState",
]);

export type IFeatureSuggestionEntityAtStateCategory = z.infer<typeof FeatureSuggestionEntityAtStateCategory>;

export const FeatureSuggestionActionCategory = z.enum([
  "addContextColumns",
  "addInputColumns",
  "addColumnValidations",
  "addColumnAutofills",
  "modifyColumnTypes",
  "removeInputColumns",
  "removeInputRelations",
  "addInputRelations",
  "addContextRelations",
]);

export type IFeatureSuggestionActionCategory = z.infer<typeof FeatureSuggestionActionCategory>;

export const FeatureSuggestionWorkspace = FeatureSuggestionBase.extend({
  type: z.literal("workspace"),
  suggestionCategory: FeatureSuggestionWorkspaceCategory,
});

export type IFeatureSuggestionWorkspace = z.infer<typeof FeatureSuggestionWorkspace>;

export const FeatureSuggestionSupportEntityType = FeatureSuggestionBase.extend({
  type: z.literal("supportEntityType"),
  suggestionCategory: FeatureSuggestionSupportEntityTypeCategory,
});

export type IFeatureSuggestionSupportEntityType = z.infer<typeof FeatureSuggestionSupportEntityType>;

export const FeatureSuggestionProcessEntityType = FeatureSuggestionBase.extend({
  type: z.literal("processEntityType"),
  suggestionCategory: FeatureSuggestionProcessEntityTypeCategory,
});
export type IFeatureSuggestionProcessEntityType = z.infer<typeof FeatureSuggestionProcessEntityType>;

export const FeatureSuggestionAction = FeatureSuggestionBase.extend({
  type: z.literal("action"),
  suggestionCategory: FeatureSuggestionActionCategory,
});

export type IFeatureSuggestionAction = z.infer<typeof FeatureSuggestionAction>;

export const FeatureSuggestionEntityTypeAtState = FeatureSuggestionBase.extend({
  type: z.literal("entityTypeAtState"),
  suggestionCategory: FeatureSuggestionEntityAtStateCategory,
});

export type IFeatureSuggestionEntityTypeAtState = z.infer<typeof FeatureSuggestionEntityTypeAtState>;

export const FeatureSuggestion = z.discriminatedUnion("type", [
  FeatureSuggestionWorkspace,
  FeatureSuggestionSupportEntityType,
  FeatureSuggestionProcessEntityType,
  FeatureSuggestionAction,
  FeatureSuggestionEntityTypeAtState,
]);
export type IFeatureSuggestion = z.infer<typeof FeatureSuggestion>;
