import type { IAutofill, ILLMRelationAutofill } from "@archetype/dsl";
import assertNever from "assert-never";

export function convertLLMRelationAutofillToAutofill(autofill: ILLMRelationAutofill | null): IAutofill | null {
  if (autofill === null) {
    return null;
  } else if ("dynamicValue" in autofill) {
    return {
      type: "snapshot",
      config: {
        type: "dynamic",
        value: autofill.dynamicValue,
      },
    };
  } else if ("logicDescription" in autofill) {
    return {
      type: autofill.recomputationType === "suggestion" ? "snapshot" : "live",
      config: {
        type: "logic",
        logic: autofill.logicDescription,
        rawLogic: autofill.logicDescription,
        steps: [],
        dependencies: [],
      },
    };
  } else if ("aiInstructions" in autofill) {
    return {
      type: autofill.recomputationType === "suggestion" ? "snapshot" : "live",
      config: {
        type: "ai",
        instructions: autofill.aiInstructions,
        rawInstructions: autofill.aiInstructions,
        dependencies: [],
      },
    };
  }
  assertNever(autofill);
}
