import { ActionId, StateId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

export const LLMStateMachineState = z.object({
  stateName: ReadableString,
});
export type ILLMStateMachineState = z.infer<typeof LLMStateMachineState>;

export const LLMStateMachineStateWithIds = z.object({
  stateId: StateId,
  stateName: ReadableString,
});
export type ILLMStateMachineStateWithIds = z.infer<typeof LLMStateMachineStateWithIds>;

export const LLMStateMachineTransition = z.object({
  actionName: ReadableString,
  fromState: LLMStateMachineState,
  toState: LLMStateMachineState,
});
export type ILLMStateMachineTransition = z.infer<typeof LLMStateMachineTransition>;

export const LLMStateMachineTransitionWithIds = z.object({
  actionId: ActionId,
  actionName: ReadableString,
  fromState: LLMStateMachineStateWithIds,
  toState: LLMStateMachineStateWithIds,
});
export type ILLMStateMachineTransitionWithIds = z.infer<typeof LLMStateMachineTransitionWithIds>;

export const LLMStateMachine = z.object({
  initialAction: z.object({
    actionName: ReadableString,
    toState: LLMStateMachineState,
  }),
  actions: z.array(LLMStateMachineTransition),
});
export type ILLMStateMachine = z.infer<typeof LLMStateMachine>;

export const LLMStateMachineWithIds = z.object({
  initialAction: z.object({
    actionId: ActionId,
    actionName: ReadableString,
    toState: LLMStateMachineStateWithIds,
  }),
  actions: z.array(LLMStateMachineTransitionWithIds),
});

export type ILLMStateMachineWithIds = z.infer<typeof LLMStateMachineWithIds>;
