import type { IOrganizationId } from "@archetype/ids";
import type { IUserId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { cn, Skeleton } from "@archetype/ui";
import { skipToken } from "@tanstack/react-query";
import Image from "next/image";
import React from "react";

interface IUserProfileImage {
  id: IUserId;
  className?: string;
  orgId?: IOrganizationId;
}

export const UserProfileImage: React.FC<IUserProfileImage> = ({ id, className, orgId }) => {
  const { data: userQuery } = trpc.users.byUserId.useQuery({
    id,
  });

  const { data: organizationQuery } = trpc.organization.byOrgId.useQuery(
    orgId == null
      ? skipToken
      : {
          orgId,
        },
  );

  if (userQuery === undefined) {
    return <Skeleton className={cn("size-8 rounded-md", className)} />;
  }

  return (
    <div className={cn("relative size-8 overflow-hidden rounded-md", className)}>
      <Image alt="" height={100} src={userQuery.clerkUser.imageUrl} width={100} />
      {organizationQuery?.organization.imageUrl != null && (
        <Image
          alt={organizationQuery.organization.name}
          className="absolute -bottom-1 -right-1"
          height={33}
          src={organizationQuery.organization.imageUrl}
          width={33}
        />
      )}
    </div>
  );
};
