import { Skeleton } from "@archetype/ui";

import { InlineEntityTableSkeleton } from "../entityTable/InlineEntityTableSkeleton";
import { EntityFieldsSkeleton } from "./EntityFieldsSkeleton";

export const EntityViewSkeleton: React.FC = () => {
  return (
    <div className="text-ink @container flex w-full max-w-2xl grow flex-col space-y-6 self-center">
      <Skeleton className="h-10 w-56" />
      <EntityFieldsSkeleton fieldsCount={10} />
      <InlineEntityTableSkeleton count={undefined} title={undefined} />
    </div>
  );
};
