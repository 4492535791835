import type { IEditOperation, ILoadedAction, IOperationsEdits, ISaveableOperationsEdits } from "@archetype/core";
import {
  applyOperations,
  collectDataToLoadFromOperations,
  convertActionCoreToLoadedAction,
  entityTypeCoreFromLoaded,
  entityTypeToLoaded,
  makeLoadedActionsFromRecord,
} from "@archetype/core";
import type { IActionCore, IEntityTypeCore, IRelationCore, IStateMachine } from "@archetype/dsl";
import { FAKE_DATA_VERSION_TYPE } from "@archetype/dsl";
import type {
  IActionId,
  IApplicationGroupId,
  IEntityTypeId,
  IOrganizationId,
  IRelationId,
  IStateId,
} from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import type { IReadableString } from "@archetype/utils";
import {
  forEach,
  groupByRecord,
  isNonNullable,
  keyByNoUndefined,
  keys,
  map,
  mapKeysAndValues,
  mapValues,
  unpartialRecord,
} from "@archetype/utils";
import { cloneDeep, isEqual, noop, pickBy, size } from "lodash";
import type { MutableRefObject } from "react";
import { createContext, useCallback, useContext, useRef, useState } from "react";

interface ICreateNewStateInfo {
  organizationId: IOrganizationId;
  applicationGroupId: IApplicationGroupId;
  newState: {
    label: IReadableString;
  };
  fromStateId: IStateId;
}

interface ICreateNewStartingStateInfo {
  organizationId: IOrganizationId;
  applicationGroupId: IApplicationGroupId;
  newState: {
    label: IReadableString;
  };
}

interface ICreateNewTransitionInfo {
  organizationId: IOrganizationId;
  applicationGroupId: IApplicationGroupId;
  transition: {
    from: IStateId;
    to: IStateId;
  };
}

const StateMachineEditContext = createContext<{
  // organizationId: IOrganizationId;
  // /**
  //  * Ensures can load the correct information even for queries that don't require an applicationGroupId
  //  * and set the cache for those operations
  //  */
  // contextApplicationGroupId: IApplicationGroupId | undefined;
  isApplyingOperations: boolean;
  setIsApplyingOperations: (newIsApplyingOperations: boolean) => void;
  failedToApplyOperations: boolean;
  setFailedToApplyOperations: (newFailedToApplyOperations: boolean) => void;
  pendingOperationsRef: MutableRefObject<IEditOperation[]>;
}>({
  // organizationId: "" as IOrganizationId,
  isApplyingOperations: false,
  setIsApplyingOperations: noop,
  failedToApplyOperations: false,
  setFailedToApplyOperations: noop,
  pendingOperationsRef: { current: [] },
});

export const StateMachineEditContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Ref so that it's a mutable object that can be read when checking the if there is anything to flush/save
  const pendingOperationsRef = useRef<IEditOperation[]>([]);
  const [isApplyingOperations, setIsApplyingOperations] = useState(false);
  const [failedToApplyOperations, setFailedToApplyOperations] = useState(false);

  // TODO support edit - maybe move all the edit logic here to avoid any unmounting issue?
  // TODO workspaces (julien) - maybe move all the edit logic here to avoid any unmounting issue?

  return (
    <StateMachineEditContext.Provider
      value={{
        isApplyingOperations,
        setIsApplyingOperations,
        failedToApplyOperations,
        setFailedToApplyOperations,
        pendingOperationsRef,
      }}
    >
      {children}
    </StateMachineEditContext.Provider>
  );
};

// MISSING — present but not synchronous
//    createNewTransition - async as generating name
//    createNewState(label, makeTransitionFromStateId)
//       -> async to make color and transition name and transition policy — maybe this one stays async for now
/**
 * We could change that to a pattern with a single function that takes a list of operations and applies them and sends them to the BE
 * (here instead there is one function per operation, though some operation definition are actually batched multiple operations)
 */
export const useStateMachineEdit = ({
  organizationId,
  contextApplicationGroupId,
  contextEntityTypeId,
}: {
  organizationId: IOrganizationId;
  /**
   * Ensures can load the correct information even for queries that don't require an applicationGroupId
   * and set the cache for those operations
   */
  contextApplicationGroupId: IApplicationGroupId | undefined;
  /**
   * HACKHACK until we can iterate through trpc utils caches with getQueriesData.
   * Allows to load from cache data keyed by entity type id when the edit is an action edit for example
   */
  contextEntityTypeId: IEntityTypeId | undefined;
}): {
  applyEditOperations: (operations: IEditOperation[]) => Promise<void>;
  isApplyingOperations: boolean;
  failedToApplyOperations: boolean;

  // Not synchronous changes
  createNewState: (createStateInfo: ICreateNewStateInfo) => Promise<IStateMachine | undefined>;
  isCreatingState: boolean;
  createNewInitialState: (createInitialStateInfo: ICreateNewStartingStateInfo) => Promise<IStateMachine | undefined>;
  isCreatingInitialState: boolean;
  createNewTransition: (createNewTransitionInfo: ICreateNewTransitionInfo) => Promise<IStateMachine | undefined>;
  isCreatingTransition: boolean;

  invalidateAllRelevantCaches: () => Promise<void>;
} => {
  const {
    // organizationId,
    isApplyingOperations,
    setIsApplyingOperations,
    failedToApplyOperations,
    setFailedToApplyOperations,
    // Ref so that it's a mutable object that can be read when checking the if there is anything to flush/save
    pendingOperationsRef,
  } = useContext(StateMachineEditContext);

  const { mutateAsync: trpcApplyStateMachineOrDataModelEdits } =
    trpc.processStateMachine.applyStateMachineOrDataModelEdits.useMutation();

  // ————————————————————————————
  // Can't be done synchronously but still called here to update ASAP on return from trpc call
  const { mutateAsync: trpcCreateState, isPending: isCreatingState } =
    trpc.processStateMachine.createNewState.useMutation();
  const { mutateAsync: trpcCreateInitialState, isPending: isCreatingInitialState } =
    trpc.processStateMachine.createNewStartingState.useMutation();
  const { mutateAsync: trpcCreateTransition, isPending: isCreatingTransition } =
    trpc.processStateMachine.createNewTransition.useMutation();
  // ————————————————————————————

  const getFullyLoadedActionQuery = trpc.useUtils().dataModel.getFullyLoadedActionById;
  const fullyLoadedEntityTypeQuery = trpc.useUtils().dataModel.fullyLoadedEntityType;
  const allEntityTypesInOrganizationQuery = trpc.useUtils().dataModel.allEntityTypesInOrganization;
  const stateMachineMetadataQuery = trpc.useUtils().processStateMachine.getStateMachineMetadataForApplicationGroup;
  const getRelationsByEntityTypeIdQuery = trpc.useUtils().dataModel.getRelationsByEntityTypeId;
  const getFullyLoadedActionsByEntityTypeQuery = trpc.useUtils().dataModel.getFullyLoadedActionsByEntityType;
  // Not setting cache (could do) but should invalidated
  const getAccessToActionQuery = trpc.useUtils().action.getAccessToAction;
  const getAccessToActionsByEntityTypeIdQuery = trpc.useUtils().action.getAccessToActionsByEntityTypeId;
  const getExecutableNestedActionsQuery = trpc.useUtils().dataModel.getExecutableNestedActions;
  const getExecutableConnectedCreateActionsQuery =
    trpc.useUtils().dataModel.getExecutableConnectedCreateActionsByViewFieldId;
  const getOrderedRelevantFieldsByStateQuery = trpc.useUtils().dataModel.getOrderedRelevantFieldsByState;
  const contextColumnsByStateQuery = trpc.useUtils().dataModel.contextColumnsByState;
  const getFieldsDependenciesQuery = trpc.useUtils().dataModel.getFieldsDependencyFieldIds;
  const maybeOrderedStatesForEntityTypeQuery = trpc.useUtils().dataModel.maybeOrderedStatesForEntityType;

  /**
   * Can be called synchronously before the queries to store the change is sent
   */
  const updateCaches = useCallback(
    ({
      stateMachineEdits,
      newOrEditedEntityTypes,
      newOrEditedRelations,
      deletedRelations,
      newOrEditedActions,
      deletedActions,
    }: ISaveableOperationsEdits) => {
      const deletedRelationsSet = new Set(deletedRelations);
      const deletedActionsSet = new Set(deletedActions);

      // Set cache for allEntityTypesInOrganizationQuery
      const currentAllEntityTypesInOrganization = allEntityTypesInOrganizationQuery.getData({
        versionType: FAKE_DATA_VERSION_TYPE,
        organizationId,
      });

      forEach(newOrEditedEntityTypes, (entityType) => {
        const currentLoadedEntityTypeResult = fullyLoadedEntityTypeQuery.getData({
          versionType: FAKE_DATA_VERSION_TYPE,
          id: entityType.id,
        });
        const currentLoadedEntityType = currentLoadedEntityTypeResult?.entityType;

        if (currentLoadedEntityType != null) {
          // No need to set the cache if no current value, and it may be wrong if the relations are not loaded
          // We could set it if and only if the relations are loaded, but not useful for now
          // Set the cache by individual entity type
          void fullyLoadedEntityTypeQuery.cancel({
            versionType: FAKE_DATA_VERSION_TYPE,
            id: entityType.id,
          });
          fullyLoadedEntityTypeQuery.setData(
            { versionType: FAKE_DATA_VERSION_TYPE, id: entityType.id },
            {
              entityType: entityTypeToLoaded(
                entityType,
                pickBy(
                  {
                    ...(currentAllEntityTypesInOrganization == null
                      ? undefined
                      : currentAllEntityTypesInOrganization.relations),
                    ...currentLoadedEntityType.relations,
                    ...newOrEditedRelations,
                  },
                  (relation) => isNonNullable(relation) && !deletedRelationsSet.has(relation.id),
                ),
              ),
            },
          );

          return;
        }
      });

      if (currentAllEntityTypesInOrganization != null) {
        // Only set if there is already a value
        // otherwise it should reload because we would write a wrong value (only with the edited one not all of them)
        const newEntityTypes: Record<IEntityTypeId, IEntityTypeCore> = cloneDeep(newOrEditedEntityTypes);

        void allEntityTypesInOrganizationQuery.cancel({
          versionType: FAKE_DATA_VERSION_TYPE,
          organizationId,
        });
        allEntityTypesInOrganizationQuery.setData(
          { versionType: FAKE_DATA_VERSION_TYPE, organizationId },
          {
            entityTypes: {
              ...currentAllEntityTypesInOrganization.entityTypes,
              ...newEntityTypes,
            },
            relations: {
              ...currentAllEntityTypesInOrganization.relations,
              ...newOrEditedRelations,
            },
          },
        );
      }

      // Set cache for getRelationsByEntityTypeIdQuery
      const newOrEditedRelationsByEntityTypeId: Record<IEntityTypeId, Record<IRelationId, IRelationCore>> = {};

      forEach(newOrEditedRelations, (relation) => {
        newOrEditedRelationsByEntityTypeId[relation.entityTypeIdA] = {
          ...newOrEditedRelationsByEntityTypeId[relation.entityTypeIdA],
          [relation.id]: relation,
        };
        newOrEditedRelationsByEntityTypeId[relation.entityTypeIdB] = {
          ...newOrEditedRelationsByEntityTypeId[relation.entityTypeIdB],
          [relation.id]: relation,
        };
      });

      forEach(newOrEditedRelationsByEntityTypeId, (relations, entityTypeId) => {
        const currentRelationsResult = getRelationsByEntityTypeIdQuery.getData({
          versionType: FAKE_DATA_VERSION_TYPE,
          entityTypeId,
        });

        if (currentRelationsResult == null) {
          // shouldn't set cache
          return;
        }

        void getRelationsByEntityTypeIdQuery.cancel({
          versionType: FAKE_DATA_VERSION_TYPE,
          entityTypeId,
        });
        getRelationsByEntityTypeIdQuery.setData(
          { versionType: FAKE_DATA_VERSION_TYPE, entityTypeId },
          {
            relations: pickBy(
              {
                ...currentRelationsResult.relations,
                ...relations,
              },
              (relation) => isNonNullable(relation) && !deletedRelationsSet.has(relation.id),
            ),
          },
        );
      });

      // Set state machine cache
      const currentStateMachineMetadataByApplicationGroupId = mapValues(stateMachineEdits, (_, applicationGroupId) => {
        const currentStateMachineMetadata = stateMachineMetadataQuery.getData({
          versionType: FAKE_DATA_VERSION_TYPE,
          applicationGroupId,
        })?.stateMachineMetadata;

        return currentStateMachineMetadata;
      });

      if (size(stateMachineEdits) > 0) {
        forEach(stateMachineEdits, (stateMachine, applicationGroupId) => {
          const currentStateMachineMetadata = currentStateMachineMetadataByApplicationGroupId[applicationGroupId];

          if (currentStateMachineMetadata != null) {
            stateMachineMetadataQuery.setData(
              { versionType: FAKE_DATA_VERSION_TYPE, applicationGroupId },
              {
                stateMachineMetadata: {
                  ...currentStateMachineMetadata,
                  stateMachine,
                },
              },
            );
          }
        });
      }

      const allEntityTypesById: Partial<Record<IEntityTypeId, IEntityTypeCore>> = {
        ...currentAllEntityTypesInOrganization?.entityTypes,
        ...newOrEditedEntityTypes,
      };

      const allRelationsById: Partial<Record<IRelationId, IRelationCore>> = {
        ...currentAllEntityTypesInOrganization?.relations,
        ...newOrEditedRelations,
      };

      const editedActionIdsSet = new Set(map(newOrEditedActions, (action) => action.id));
      const editedEntityTypeIdsSet = new Set(keys(newOrEditedEntityTypes));

      // Need to set the loaded acions for all actions that may have
      getFullyLoadedActionQuery.setQueriesData(
        {
          versionType: FAKE_DATA_VERSION_TYPE,
          // No action id as want to match all actions in caches
          actionId: undefined as unknown as IActionId, // This should work perTRPC/react-query documentation but doesn't, probably a FR for them
        },
        {
          exact: false,
          predicate: () => {
            // could filter by queryKey
            return true;
          },
        },
        (oldData) => {
          if (oldData == null) {
            return oldData;
          }

          // Technically should also change and reload if the action uses any relation that has been changed
          if (!editedEntityTypeIdsSet.has(oldData.action.entityTypeId) && !editedActionIdsSet.has(oldData.action.id)) {
            return oldData;
          }

          const entityType = allEntityTypesById[oldData.action.entityTypeId];

          if (entityType == null) {
            return oldData;
          }

          const dependenciesInfo = getFieldsDependenciesQuery.getData({
            versionType: FAKE_DATA_VERSION_TYPE,
            entityTypeId: entityType.id,
          });

          const fullyLoadedAction = convertActionCoreToLoadedAction(
            oldData.action,
            keyByNoUndefined(entityType.columns, (c) => c.id),
            allRelationsById,
            dependenciesInfo,
          );

          return {
            action: fullyLoadedAction,
          };
        },
      );

      const allDependenciesInfo = mapValues(
        {
          ...mapKeysAndValues(newOrEditedActions, (action) => ({
            key: action.entityTypeId,
            value: { id: action.entityTypeId },
          })),
          ...allEntityTypesById,
        },
        (entityType) =>
          entityType == null
            ? undefined
            : getFieldsDependenciesQuery.getData({
                versionType: FAKE_DATA_VERSION_TYPE,
                entityTypeId: entityType.id,
              }),
      );

      const fullyLoadedActions: Record<IActionId, ILoadedAction> = makeLoadedActionsFromRecord({
        actions: newOrEditedActions,
        entityTypesById: allEntityTypesById,
        relationsById: allRelationsById,
        dependenciesInfo: allDependenciesInfo,
      });
      const loadedActionsByEntityType = groupByRecord(fullyLoadedActions, (action) => action.entityTypeId);

      forEach(loadedActionsByEntityType, (newActionsForEntityType, entityTypeId) => {
        const currentActions: Partial<Record<IActionId, ILoadedAction>> | undefined =
          getFullyLoadedActionsByEntityTypeQuery.getData({
            versionType: FAKE_DATA_VERSION_TYPE,
            entityTypeId,
          })?.actions;

        void getFullyLoadedActionsByEntityTypeQuery.cancel({
          versionType: FAKE_DATA_VERSION_TYPE,
          entityTypeId,
        });
        getFullyLoadedActionsByEntityTypeQuery.setData(
          { versionType: FAKE_DATA_VERSION_TYPE, entityTypeId },
          {
            actions: pickBy(
              {
                ...currentActions,
                ...newActionsForEntityType,
              },
              (action) => isNonNullable(action) && !deletedActionsSet.has(action.id),
            ),
          },
        );
      });

      forEach(fullyLoadedActions, (action) => {
        void getFullyLoadedActionQuery.cancel({
          versionType: FAKE_DATA_VERSION_TYPE,
          actionId: action.id,
        });
        getFullyLoadedActionQuery.setData({ versionType: FAKE_DATA_VERSION_TYPE, actionId: action.id }, { action });
      });
    },
    [
      organizationId,
      fullyLoadedEntityTypeQuery,
      stateMachineMetadataQuery,
      getFieldsDependenciesQuery,
      getFullyLoadedActionQuery,
      getFullyLoadedActionsByEntityTypeQuery,
      getRelationsByEntityTypeIdQuery,
      allEntityTypesInOrganizationQuery,
    ],
  );

  /**
   * Should be called after the queries to store the change returns
   */
  const invalidateCaches = useCallback(async () => {
    // Ideally, we could generate the versionId in those logics so that the actual data that's written in the BE
    // is exactly the same as the cache value we update, and there's no risk of fake out-of-date-ness
    // that way we can not even invalidate the actual loads
    await Promise.all([
      // Cache is set for fullyLoadedAction, but we cannot currently set the cache when the action has not been edited but the loaded action has
      // e.g. if the column autofill on the entity type has been changed. (we need a FR in TRPC)
      getFullyLoadedActionQuery.invalidate(),
      // Cache not set for the below
      // We could be more precise on invalidation based on what has been modified,
      // but that should be for all operations that have been flushed since the last invalidate
      getAccessToActionQuery.invalidate(),
      getAccessToActionsByEntityTypeIdQuery.invalidate(),
      getExecutableNestedActionsQuery.invalidate(),
      getExecutableConnectedCreateActionsQuery.invalidate(),
      getOrderedRelevantFieldsByStateQuery.invalidate(),
      contextColumnsByStateQuery.invalidate(),
      getFieldsDependenciesQuery.invalidate(),
      maybeOrderedStatesForEntityTypeQuery.invalidate(),
    ]);
  }, [
    getFullyLoadedActionQuery,
    getAccessToActionQuery,
    getAccessToActionsByEntityTypeIdQuery,
    getExecutableNestedActionsQuery,
    getExecutableConnectedCreateActionsQuery,
    getOrderedRelevantFieldsByStateQuery,
    contextColumnsByStateQuery,
    getFieldsDependenciesQuery,
    maybeOrderedStatesForEntityTypeQuery,
  ]);

  const invalidateAllRelevantCaches = useCallback(async () => {
    await Promise.all([
      invalidateCaches(),
      stateMachineMetadataQuery.invalidate(),
      fullyLoadedEntityTypeQuery.invalidate(),
      getFullyLoadedActionQuery.invalidate(),
    ]);
  }, [invalidateCaches, stateMachineMetadataQuery, fullyLoadedEntityTypeQuery, getFullyLoadedActionQuery]);

  /**
   * Goal is to avoid sending parallel queries to the BE with operations
   * a better approach would be to actually apply the operations centrally in the BE even if received in parrallel
   * with a lock on the data that's loaded, but that's more complex for now and this will be functional.
   */
  const innerFlushOperations = useCallback(async (): Promise<void> => {
    const operationsToApply = pendingOperationsRef.current;

    if (!isApplyingOperations && operationsToApply.length > 0) {
      pendingOperationsRef.current = [];
      try {
        setIsApplyingOperations(true);

        await trpcApplyStateMachineOrDataModelEdits({
          organizationId,
          operations: operationsToApply,
        })
          .then(() => {
            // That's a way to reset on a new apply
            setFailedToApplyOperations(false);
          })
          .catch((e: unknown) => {
            // Only set this on BE saving error
            setFailedToApplyOperations(true);
            // Invalidate all the caches to undo the changes
            void invalidateAllRelevantCaches();

            throw e;
          });

        setIsApplyingOperations(false);

        if (pendingOperationsRef.current.length === 0) {
          // Only invalidate if we've applied all operations and no new ones have been pushed
          void invalidateCaches();
        }

        void innerFlushOperations();
      } catch (e) {
        setIsApplyingOperations(false);
        void innerFlushOperations();
        throw e;
      }
    }
  }, [
    organizationId,
    pendingOperationsRef,
    isApplyingOperations,
    setIsApplyingOperations,
    invalidateCaches,
    trpcApplyStateMachineOrDataModelEdits,
    setFailedToApplyOperations,
    invalidateAllRelevantCaches,
  ]);

  const debouncedApplyOperations = useCallback(
    async (operations: IEditOperation[]): Promise<void> => {
      pendingOperationsRef.current = [...pendingOperationsRef.current, ...operations];

      await innerFlushOperations();
    },
    [pendingOperationsRef, innerFlushOperations],
  );

  const applyEditOperations = useCallback(
    async (operations: IEditOperation[]) => {
      const dataToLoad = collectDataToLoadFromOperations(operations);

      const operationsApplicationGroupId = dataToLoad.applicationGroupIds[0];
      const applicationGroupId = operationsApplicationGroupId ?? contextApplicationGroupId;

      if (dataToLoad.applicationGroupIds.length > 1) {
        throw new Error("Edits on multiple application groups not supported");
      }

      const currentEntityTypesInOrganization = allEntityTypesInOrganizationQuery.getData({
        versionType: FAKE_DATA_VERSION_TYPE,
        organizationId,
      });

      const currentStateMachineMetadataRes =
        applicationGroupId == null
          ? undefined
          : stateMachineMetadataQuery.getData({
              versionType: FAKE_DATA_VERSION_TYPE,
              applicationGroupId,
            });
      const currentStateMachineMetadata = currentStateMachineMetadataRes?.stateMachineMetadata;

      if (
        currentStateMachineMetadata != null &&
        !dataToLoad.entityTypeIds.includes(currentStateMachineMetadata.stateMachine.dataModel.targetEntityTypeId)
      ) {
        dataToLoad.entityTypeIds.push(currentStateMachineMetadata.stateMachine.dataModel.targetEntityTypeId);

        // Even if not support entity types it will force to load actions by entity types
        dataToLoad.supportActionsForEntityTypeIds.push(
          currentStateMachineMetadata.stateMachine.dataModel.targetEntityTypeId,
        );
      }

      const entityTypeIdsToLoadSet = new Set(dataToLoad.entityTypeIds);
      const relationIdsToLoadSet = new Set(dataToLoad.relationIds);
      const actionIdsToLoadSet = new Set(dataToLoad.actionIds);
      const allActionsForStateMachineSet = new Set(dataToLoad.allActionsForStateMachine);
      const supportActionsForEntityTypeIdsSet = new Set(dataToLoad.supportActionsForEntityTypeIds);

      const currentRequestedEntityTypesInOrg =
        currentEntityTypesInOrganization != null
          ? pickBy(
              currentEntityTypesInOrganization.entityTypes,
              (entityType) => isNonNullable(entityType) && entityTypeIdsToLoadSet.has(entityType.id),
            )
          : {};

      const currentFullyLoadedEntityTypes = dataToLoad.entityTypeIds
        .map((entityTypeId) =>
          fullyLoadedEntityTypeQuery.getData({
            versionType: FAKE_DATA_VERSION_TYPE,
            id: entityTypeId,
          }),
        )
        .filter(isNonNullable);

      const currentEntityTypesExplictlyLoaded = currentFullyLoadedEntityTypes.map((loadedEntityType) =>
        entityTypeCoreFromLoaded(loadedEntityType.entityType),
      );

      const currentRelationsInOrg: Record<IRelationId, IRelationCore> =
        currentEntityTypesInOrganization != null
          ? pickBy(
              currentEntityTypesInOrganization.relations,
              (relation): relation is IRelationCore => isNonNullable(relation) && relationIdsToLoadSet.has(relation.id),
            )
          : {};

      const currentRelationsByEntityType: Record<IRelationId, IRelationCore> = {};

      currentFullyLoadedEntityTypes.forEach(({ entityType }) => {
        forEach(unpartialRecord(entityType.relations), (r) => {
          if (relationIdsToLoadSet.has(r.id)) {
            currentRelationsByEntityType[r.id] = r;
          }
        });
      });

      const currentEntityTypesExplicitlyLoadedById = keyByNoUndefined(currentEntityTypesExplictlyLoaded, (e) => e.id);

      const currentRelations = pickBy(
        currentEntityTypesInOrganization?.relations,
        (relation) => relation != null && relationIdsToLoadSet.has(relation.id),
      );

      // actions
      const currentActions: Record<IActionId, IActionCore> = {};

      dataToLoad.actionIds.forEach((actionId) => {
        const currentAction = getFullyLoadedActionQuery.getData({
          versionType: FAKE_DATA_VERSION_TYPE,
          actionId,
        })?.action;

        if (currentAction != null) {
          currentActions[actionId] = currentAction;
        }
      });

      dataToLoad.supportActionsForEntityTypeIds.forEach((entityTypeId) => {
        forEach(
          getFullyLoadedActionsByEntityTypeQuery.getData({
            versionType: FAKE_DATA_VERSION_TYPE,
            entityTypeId,
          })?.actions ?? {},
          (action) => {
            if (action == null) {
              return;
            }

            currentActions[action.id] = action;
          },
        );
      });

      if (contextEntityTypeId != null) {
        forEach(
          getFullyLoadedActionsByEntityTypeQuery.getData({
            versionType: FAKE_DATA_VERSION_TYPE,
            entityTypeId: contextEntityTypeId,
          })?.actions ?? {},
          (action) => {
            if (action == null) {
              return;
            }

            if (
              actionIdsToLoadSet.has(action.id) ||
              supportActionsForEntityTypeIdsSet.has(action.entityTypeId) ||
              (action.applicationGroupId != null && allActionsForStateMachineSet.has(action.applicationGroupId))
            ) {
              currentActions[action.id] = action;
            }
          },
        );
      }

      const initialOperationsEdits: IOperationsEdits = {
        organizationId,
        newOrEditedEntityTypes: Object.assign(currentRequestedEntityTypesInOrg, currentEntityTypesExplicitlyLoadedById),
        newOrEditedActions: currentActions,
        deletedActions: [],
        newOrEditedRelations: Object.assign(currentRelationsInOrg, currentRelationsByEntityType, currentRelations),
        deletedRelations: [],
        stateMachineEdits:
          currentStateMachineMetadata == null || applicationGroupId == null
            ? {}
            : {
                [applicationGroupId]: currentStateMachineMetadata.stateMachine,
              },
      };

      // Protect against edits to the input object in applying operations, both for the check for backend
      // and for the cache update otherwise react will not update properly with useMemoDeepCompare
      const initialOperationsEditsCopy = cloneDeep(initialOperationsEdits);
      const editRes = applyOperations({ operations, currentInfo: cloneDeep(initialOperationsEdits) });

      const editsAsInitialOperationEditsType: IOperationsEdits = {
        ...editRes,
        newOrEditedEntityTypes: editRes.newOrEditedEntityTypes,
        stateMachineEdits: editRes.stateMachineEdits,
      };

      if (isEqual(editsAsInitialOperationEditsType, initialOperationsEditsCopy)) {
        // Nothing has changed, no need to save in BE
        return;
      }

      updateCaches(editRes);

      await debouncedApplyOperations(operations);
    },
    [
      organizationId,
      contextApplicationGroupId,
      contextEntityTypeId,
      stateMachineMetadataQuery,
      fullyLoadedEntityTypeQuery,
      getFullyLoadedActionQuery,
      getFullyLoadedActionsByEntityTypeQuery,
      allEntityTypesInOrganizationQuery,
      updateCaches,
      debouncedApplyOperations,
    ],
  );

  const createNewState = useCallback(
    async (createStateInfo: ICreateNewStateInfo): Promise<IStateMachine | undefined> => {
      const { savedOperationEdits } = await trpcCreateState(createStateInfo);

      updateCaches(savedOperationEdits);

      void invalidateCaches();

      return savedOperationEdits.stateMachineEdits[createStateInfo.applicationGroupId];
    },
    [trpcCreateState, updateCaches, invalidateCaches],
  );

  const createNewInitialState = useCallback(
    async (createInitialStateInfo: ICreateNewStartingStateInfo): Promise<IStateMachine | undefined> => {
      const { savedOperationEdits } = await trpcCreateInitialState(createInitialStateInfo);

      updateCaches(savedOperationEdits);

      void invalidateCaches();

      return savedOperationEdits.stateMachineEdits[createInitialStateInfo.applicationGroupId];
    },
    [trpcCreateInitialState, updateCaches, invalidateCaches],
  );

  const createNewTransition = useCallback(
    async (createTransitionInfo: ICreateNewTransitionInfo): Promise<IStateMachine | undefined> => {
      const { savedOperationEdits } = await trpcCreateTransition(createTransitionInfo);

      updateCaches(savedOperationEdits);

      void invalidateCaches();

      return savedOperationEdits.stateMachineEdits[createTransitionInfo.applicationGroupId];
    },
    [trpcCreateTransition, updateCaches, invalidateCaches],
  );

  return {
    applyEditOperations,
    isApplyingOperations,
    failedToApplyOperations,
    createNewState,
    isCreatingState,
    createNewInitialState,
    isCreatingInitialState,
    createNewTransition,
    isCreatingTransition,
    invalidateAllRelevantCaches,
  };
};
