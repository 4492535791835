import type { ITransitionId } from "@archetype/ids";
import { TransitionId } from "@archetype/ids";
import type { IReadableString } from "@archetype/utils";
import { kebabCase } from "lodash";

export const makeTransitionId = ({
  label,
  fromState,
  toState,
}: {
  label: IReadableString;
  fromState: IReadableString;
  toState: IReadableString;
}): ITransitionId => TransitionId.parse(`${kebabCase(label)}-${kebabCase(fromState)}-${kebabCase(toState)}`);
