import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { MapComponentDefinitionId } from "@archetype/ids";

export const MapComponentIdDeclarations = {
  id: MapComponentDefinitionId,
  inputs: ["multiEntityQueryDataInput"],
  outputs: ["userSelectedSingleEntity"],
  compatibleSemanticSizes: ["main", "medium", "split-horizontal", "split-vertical"],
  validCosmeticOptionalProps: ["label"],
} as const;

export const MapComponentDefinition: ITypedComponentDefinitionDeclaration<typeof MapComponentIdDeclarations> = {
  constIds: MapComponentIdDeclarations,
  displayMetadata: {
    name: "Map",
    description: "A map component",
  },
  inputs: {
    multiEntityQueryDataInput: {
      displayMetadata: {
        name: "Map data",
        description: "Data to populate the map with",
      },
      optional: false,
    },
  },
  outputs: {
    userSelectedSingleEntity: {
      displayMetadata: {
        name: "Map selection",
        description: "The selected item in the map",
      },
      optional: false,
      nullable: true,
    },
  },
};
