import type { IColumn, IStateMachineStateTransition } from "@archetype/dsl";
import { humanize, isNonNullable, mapKeysNoUndefined, mapValues } from "@archetype/utils";

import type { IOperationsEditsWithStateMachine } from "./editOperations/operations";

export const standardizeColumn = (column: IColumn): IColumn => ({
  id: column.id,
  validations: column.validations,
  nonNullable: column.nonNullable,
  unique: column.unique,
  displayMetadata: {
    name: humanize(column.displayMetadata.name),
    description: column.displayMetadata.description,
  },
  autofill: column.autofill,
  columnType:
    // eslint-disable-next-line no-nested-ternary -- to cleanup
    column.columnType.type === "enum"
      ? {
          type: "enum",
          enumAllowedValues: column.columnType.enumAllowedValues?.map((s) => humanize(s)).filter(isNonNullable),
          enumInclusiveMaxValuesToSelect: column.columnType.enumInclusiveMaxValuesToSelect ?? 1,
        }
      : column.columnType.type === "statusEnum"
        ? {
            type: "statusEnum",
            allowedValues: column.columnType.allowedValues.map((v) => ({
              id: v.id,
              readableValue: humanize(v.readableValue),
            })),
            archivedValues: column.columnType.archivedValues.map((v) => ({
              id: v.id,
              readableValue: humanize(v.readableValue),
            })),
          }
        : column.columnType,
  conditionalFormatting:
    // eslint-disable-next-line no-nested-ternary -- to cleanup
    column.conditionalFormatting == null
      ? undefined
      : column.columnType.type === "statusEnum"
        ? column.conditionalFormatting
        : {
            type: "conditionalFormattingEnum",
            valueToNamedColor: mapKeysNoUndefined(column.conditionalFormatting.valueToNamedColor, (_value, key) =>
              humanize(key),
            ),
          },
});

export const standardizeReadableStringsInStateMachineOperations = (
  operationEdits: IOperationsEditsWithStateMachine,
): IOperationsEditsWithStateMachine => {
  const standardizeTransitions = (stateTransitions: IStateMachineStateTransition[]): IStateMachineStateTransition[] =>
    stateTransitions.map((transition) => ({
      id: transition.id,
      from: transition.from,
      to: transition.to,
      actionId: transition.actionId,
    }));

  return {
    organizationId: operationEdits.organizationId,
    stateMachineEdits: mapValues(operationEdits.stateMachineEdits, (stateMachine) => ({
      states: mapValues(stateMachine.states, (state) => ({
        id: state.id,
        label: humanize(state.label),
        color: state.color,
      })),
      archivedStates: mapValues(stateMachine.archivedStates, (state) => ({
        id: state.id,
        label: humanize(state.label),
        color: state.color,
      })),
      stateTransitions: standardizeTransitions(stateMachine.stateTransitions),
      happyPath: stateMachine.happyPath,
      aiAgents: stateMachine.aiAgents,
      initialState: {
        state: stateMachine.initialState.state,
        actionId: stateMachine.initialState.actionId,
      },
      dataModel: {
        targetEntityTypeId: stateMachine.dataModel.targetEntityTypeId,
      },
    })),
    targetEntityType: {
      organizationId: operationEdits.targetEntityType.organizationId,
      targetEntityTypeApplicationGroupId: operationEdits.targetEntityType.targetEntityTypeApplicationGroupId,
      supportActionsInfo: operationEdits.targetEntityType.supportActionsInfo,
      deleteActionId: operationEdits.targetEntityType.deleteActionId,
      userEntityTypeInfo: operationEdits.targetEntityType.userEntityTypeInfo,
      id: operationEdits.targetEntityType.id,
      primaryKey: operationEdits.targetEntityType.primaryKey,
      displayNameColumn: operationEdits.targetEntityType.displayNameColumn,
      statusColumn: operationEdits.targetEntityType.statusColumn,
      shape: operationEdits.targetEntityType.shape,
      color: operationEdits.targetEntityType.color,
      displayMetadata: {
        name: humanize(operationEdits.targetEntityType.displayMetadata.name),
        pluralName: humanize(operationEdits.targetEntityType.displayMetadata.pluralName),
        description: operationEdits.targetEntityType.displayMetadata.description,
      },
      relevantViewFieldsByStateId: operationEdits.targetEntityType.relevantViewFieldsByStateId,
      columns: operationEdits.targetEntityType.columns.map((column) => standardizeColumn(column)),
      authorizedByAnyOf: operationEdits.targetEntityType.authorizedByAnyOf,
      activityLogAuthorizedByAnyOf: operationEdits.targetEntityType.activityLogAuthorizedByAnyOf,
      authorizedByAnyOfPerStateId: operationEdits.targetEntityType.authorizedByAnyOfPerStateId,
    },
    deletedRelations: operationEdits.deletedRelations,
    newOrEditedEntityTypes: mapValues(operationEdits.newOrEditedEntityTypes, (entityType) => ({
      organizationId: entityType.organizationId,
      targetEntityTypeApplicationGroupId: entityType.targetEntityTypeApplicationGroupId,
      supportActionsInfo: entityType.supportActionsInfo,
      deleteActionId: entityType.deleteActionId,
      userEntityTypeInfo: entityType.userEntityTypeInfo,
      id: entityType.id,
      primaryKey: entityType.primaryKey,
      displayNameColumn: entityType.displayNameColumn,
      statusColumn: entityType.statusColumn,
      shape: entityType.shape,
      color: entityType.color,
      displayMetadata: {
        name: humanize(entityType.displayMetadata.name),
        pluralName: humanize(entityType.displayMetadata.pluralName),
        description: entityType.displayMetadata.description,
      },
      relevantViewFieldsByStateId: entityType.relevantViewFieldsByStateId,
      columns: entityType.columns.map((column) => standardizeColumn(column)),
      authorizedByAnyOf: entityType.authorizedByAnyOf,
      activityLogAuthorizedByAnyOf: entityType.activityLogAuthorizedByAnyOf,
      authorizedByAnyOfPerStateId: entityType.authorizedByAnyOfPerStateId,
    })),
    newOrEditedRelations: mapValues(operationEdits.newOrEditedRelations, (relation) => ({
      ...relation,
      displayMetadataFromAToB: {
        name: humanize(relation.displayMetadataFromAToB.name),
        description: relation.displayMetadataFromAToB.description,
      },
      displayMetadataFromBToA: {
        name: humanize(relation.displayMetadataFromBToA.name),
        description: relation.displayMetadataFromBToA.description,
      },
    })),
    newOrEditedActions: mapValues(operationEdits.newOrEditedActions, (action) => ({
      ...action,
      displayMetadata: {
        name: humanize(action.displayMetadata.name),
        description: action.displayMetadata.description,
      },
    })),
    deletedActions: operationEdits.deletedActions,
  };
};
