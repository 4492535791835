import { z } from "zod";

import { ComparisonOperator } from "./dataModel/ComparisonRule";
import { ComputedElementValues } from "./dataModel/Element";

export const LogicalStepColumnElement = z.object({
  columnName: z.string(),
  entityName: z.string(),
});
export type ILogicalStepColumnElement = z.infer<typeof LogicalStepColumnElement>;

export const LogicalStepStepOutputElement = z.object({
  outputOfStep: z.string(),
});
export type ILogicalStepStepOutputElement = z.infer<typeof LogicalStepStepOutputElement>;

export const LogicalStepRelationElement = z.object({
  relationName: z.string(),
  fromEntityName: z.string(),
  toEntityName: z.string().optional(),
});
export type ILogicalStepRelationElement = z.infer<typeof LogicalStepRelationElement>;

export const LogicalStepDynamicElement = z.object({
  dynamicValue: ComputedElementValues,
});

export const LogicalStepStringElement = z.object({
  stringValue: z.string(),
});

export const LogicalStepNumberElement = z.object({
  numberValue: z.number(),
});

export const LogicalStepBooleanElement = z.object({
  booleanValue: z.boolean(),
});

export const LogicalStepDateElement = z.object({
  dateValue: z.date(),
});

export const LogicalStepTimestampElement = z.object({
  timestampValue: z.date(),
});

export const LogicalStepWithoutStepOutputElement = z.union([
  LogicalStepColumnElement,
  LogicalStepDynamicElement,
  LogicalStepStringElement,
  LogicalStepNumberElement,
  LogicalStepBooleanElement,
  LogicalStepDateElement,
  LogicalStepTimestampElement,
  LogicalStepRelationElement,
]);
export type ILogicalStepWithoutStepOutputElement = z.infer<typeof LogicalStepWithoutStepOutputElement>;

export const LogicalStepElement = z.union([
  LogicalStepWithoutStepOutputElement,
  LogicalStepStepOutputElement,
  LogicalStepDynamicElement,
  LogicalStepStringElement,
  LogicalStepNumberElement,
  LogicalStepBooleanElement,
  LogicalStepDateElement,
  LogicalStepTimestampElement,
]);
export type ILogicalStepElement = z.infer<typeof LogicalStepElement>;

export const LogicalStepCondition = z.object({
  columnName: z.string(),
  entityName: z.string(),
  operator: ComparisonOperator,
  element: LogicalStepElement,
});
export type ILogicalStepCondition = z.infer<typeof LogicalStepCondition>;
