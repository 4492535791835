import { createCloudStorageClient } from "@archetype/cloud-storage";
import type { IFileEntityColumnValue } from "@archetype/dsl";
import type { IViewFieldValue } from "@archetype/dsl";
import type { IFileId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import type { FileRejection } from "@archetype/ui";
import { cn, FileInput, FileUploader, FileUploaderContent, FileUploaderItem } from "@archetype/ui";
import { Icon, Loader, toast } from "@archetype/ui";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import { createFileLogger } from "../../../logger";
import { PrivateActiveActionContext } from "../../ActionFormWrapper";
import type { IActionInputComponent } from "../types";

export const dropZoneAccept = {
  // (defined also in the fileColumnRouter)
  all: [".jpg", ".jpeg", ".png", ".gif", ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".txt", ".csv"],
};

const maximumSizeInBytes = 5 * 1024 * 1024; // 5 MB (defined also in the backend)

const logger = createFileLogger("ActionFileUploader");

export const ActionFileUploader: IActionInputComponent = ({
  field,
  onChange,
  entity,
  versionType,
  actionId,
  readOnly,
}): React.ReactElement => {
  const entityId = entity?.entityId;

  if (typeof actionId === "undefined") {
    throw new Error("actionId is required");
  }

  const [filesList, setFilesList] = useState<IFileEntityColumnValue[]>(() => {
    return field.value?.type === "file" && Array.isArray(field.value.value) ? field.value.value : [];
  });
  const currentActionUploadedFiles = useRef<Set<IFileId>>(new Set());

  const [isUploading, setIsUploading] = useState(false);
  const {
    setIsUploadingOrDeleting: setContextIsUploadingOrDeleting,
    uploadPromise,
    deletePromise,
  } = useContext(PrivateActiveActionContext);

  useEffect(() => {
    if (field.value?.type === "file" && Array.isArray(field.value.value)) {
      setFilesList(field.value.value);
    }

    return (): void => {
      setFilesList([]);
    };
  }, [field.value]);

  const getFileColumnUploadTokenAndPathMutation = builderTrpc.fileColumn.getFileColumnUploadTokenAndPath.useMutation();
  const deleteFileMutation = builderTrpc.fileColumn.deleteFile.useMutation();

  const [deletingFileIndices, setCurrentlyDeletingFileIndices] = useState<Set<number>>(new Set());

  const triggerOnChange = (
    updateFunction: (prevFilesList: IFileEntityColumnValue[]) => IFileEntityColumnValue[],
  ): void => {
    setFilesList((prevFilesList) => {
      const newFilesList = updateFunction(prevFilesList);
      const newValue: IViewFieldValue =
        newFilesList.length === 0
          ? { type: "null" }
          : {
              type: "file",
              value: newFilesList,
            };

      onChange(newValue);

      return newFilesList;
    });
  };

  const handleNewFiles = useCallback(
    async (files: File[] | null): Promise<void> => {
      setIsUploading(true);
      setContextIsUploadingOrDeleting(true);
      uploadPromise.current = (async (): Promise<void> => {
        try {
          const newFiles = await Promise.all(
            (files || []).map(async (file) => {
              const { token, filePath } = await getFileColumnUploadTokenAndPathMutation.mutateAsync({
                versionType: versionType,
                actionId: actionId,
                fileName: file.name,
                columnId: field.name.replace("column-", ""),
                entityId: entityId ?? null,
              });

              const cloudStorage = createCloudStorageClient();

              try {
                const { fileUrl } = await cloudStorage.clientUploadFile(filePath, file, { token: token });
                const uploadedFile = {
                  id: crypto.randomUUID() as IFileId,
                  fileName: file.name,
                  fileUrl: fileUrl,
                  fileSizeInBytes: file.size,
                  vendor: "vercel" as const,
                };

                currentActionUploadedFiles.current.add(uploadedFile.id);

                return uploadedFile;
              } catch (e) {
                logger.error(e);
                toast({
                  title: `Error uploading ${file.name}`,
                });

                return;
              }
            }),
          );

          triggerOnChange((prevFilesList) => [
            ...prevFilesList,
            ...newFiles.filter((file): file is IFileEntityColumnValue => file !== undefined),
          ]);
        } catch (e) {
          logger.error(e);
          toast({
            title: (e as Error).message,
          });
          throw e; // Rethrow the error to be caught in the submit action
        } finally {
          setIsUploading(false);
          setContextIsUploadingOrDeleting(false);
        }
      })();
      await uploadPromise.current;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- triggerOnChange would cause this to rerender all the time
    [
      filesList,
      getFileColumnUploadTokenAndPathMutation,
      versionType,
      actionId,
      field.name,
      entityId,
      setIsUploading,
      setContextIsUploadingOrDeleting,
      uploadPromise,
    ],
  );

  const handleRemoveFile = useCallback(
    async (i: number): Promise<void> => {
      setContextIsUploadingOrDeleting(true);
      deletePromise.current = (async (): Promise<void> => {
        try {
          const fileToDelete: IFileEntityColumnValue | undefined = filesList[i];

          if (!fileToDelete) {
            throw Error("File to be deleted not found");
          }

          setCurrentlyDeletingFileIndices((prev) => new Set(prev).add(i));

          if (currentActionUploadedFiles.current.has(fileToDelete.id)) {
            await deleteFileMutation.mutateAsync({
              versionType: versionType,
              actionId: actionId,
              fileUrl: fileToDelete.fileUrl,
              entityId: entityId ?? null,
            });

            currentActionUploadedFiles.current.delete(fileToDelete.id);
          }

          triggerOnChange((prevFilesList) => prevFilesList.filter((_, index) => index !== i));
        } catch (e) {
          logger.error(e);
          toast({
            title: "Error deleting file",
          });
        } finally {
          setCurrentlyDeletingFileIndices((prev) => {
            const newSet = new Set(prev);

            newSet.delete(i);

            return newSet;
          });
          setContextIsUploadingOrDeleting(false);
        }
      })();
      await deletePromise.current;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- triggerOnChange would cause this to rerender all the time
    [
      entityId,
      filesList,
      currentActionUploadedFiles,
      deleteFileMutation,
      versionType,
      actionId,
      setContextIsUploadingOrDeleting,
      deletePromise,
    ],
  );

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const firstRejection = fileRejections[0];

    if (firstRejection == null || firstRejection.errors.length === 0) {
      return;
    }

    let errorMessage: string | undefined;

    if (firstRejection.errors[0]?.code === "file-too-large") {
      errorMessage = `Maximum file size is ${(maximumSizeInBytes / 1024 / 1024).toFixed()} MB`;
    } else if (firstRejection.errors[0]?.code === "file-invalid-type") {
      errorMessage = `Invalid file type`;
    } else {
      errorMessage = firstRejection.errors[0]?.message;
    }

    toast({
      title: `Error uploading ${firstRejection.file.name}`,
      description: errorMessage,
    });
  }, []);

  const renderDropZone = useCallback(() => {
    let content: React.ReactNode;

    if (readOnly) {
      content = (
        <p className="text-muted-foreground mb-1 text-base">
          <Icon className="text-muted-foreground mr-1 inline-block size-4" name="upload-cloud" />
          <span className="font-semibold">File upload unavailable</span>
        </p>
      );
    } else {
      content = (
        <>
          <p className="text-muted-foreground mb-1 text-base">
            <Icon className="text-muted-foreground mr-1 inline-block size-4" name="upload-cloud" />
            <span className="font-semibold">Click to upload </span>
            or drag and drop
          </p>
          <p className="text-muted-foreground text-xs">CSV, PNG, JPG, PDF, DOC, XLS, PPT, TXT</p>
        </>
      );
    }

    return <div className="flex w-full flex-col items-center justify-center">{content}</div>;
  }, [readOnly]);

  const dropZoneConfig = {
    accept: dropZoneAccept,
    maxFiles: 20,
    maxSize: maximumSizeInBytes,
    multiple: true,
    onDropRejected: onDropRejected,
    disabled: readOnly,
  };

  return (
    <FileUploader
      className="relative space-y-1"
      dropzoneOptions={dropZoneConfig}
      value={null}
      onFileRemoval={handleRemoveFile}
      onNewFiles={handleNewFiles}
    >
      <FileInput
        className={cn(
          "border-border bg-accent-background hover:bg-muted-background w-full space-y-4 rounded-md border-2 border-dashed p-6",
          readOnly && "bg-muted-background hover:bg-muted-background cursor-not-allowed",
        )}
      >
        {renderDropZone()}
      </FileInput>
      <FileUploaderContent>
        {filesList.map((file, index) => (
          <FileUploaderItem
            key={file.id}
            className={cn(
              "bg-accent-background group p-2 text-base ease-in-out",
              deletingFileIndices.has(index) ? "cursor-default opacity-50" : "",
            )}
            index={index}
          >
            <a href={file.fileUrl} target="_blank">
              {file.fileName}
            </a>
          </FileUploaderItem>
        ))}
        {isUploading ? (
          <FileUploaderItem className="bg-accent-background rounded p-2 text-sm" index={-1} showDeleteButton={false}>
            <Loader text="Uploading..." />
          </FileUploaderItem>
        ) : null}
      </FileUploaderContent>
    </FileUploader>
  );
};
