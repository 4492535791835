import type { IViewFieldValue } from "@archetype/dsl";
import { isColumnViewField } from "@archetype/dsl";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@archetype/ui";
import { useCallback } from "react";

import { StringInput } from "../primitive/StringInput";
import type { IActionInputComponent } from "../types";

export const EnumInput: IActionInputComponent = ({
  versionType,
  currentUserInfo,
  className,
  field,
  onChange,
  input,
  readOnly = false,
  entityTypeId,
  organizationId,
  entity,
  isFieldTouched,
}) => {
  const { viewField } = input;
  const handleChange = useCallback(
    (value: IViewFieldValue): void => {
      onChange(value);
    },
    [onChange],
  );

  const handleValueChange = useCallback(
    (value: string | undefined): void => {
      onChange(
        value === undefined || value === ""
          ? { type: "null" }
          : {
              type: "array",
              value: [value],
            },
      );
    },
    [onChange],
  );

  const handleClear = useCallback((): void => {
    handleValueChange(undefined);
  }, [handleValueChange]);

  const handleBlur = useCallback((): void => {
    field.onBlur();
  }, [field]);

  if (!isColumnViewField(viewField)) {
    return (
      <StringInput
        currentUserInfo={currentUserInfo}
        entity={entity}
        entityTypeId={entityTypeId}
        field={field}
        input={input}
        isFieldTouched={isFieldTouched}
        organizationId={organizationId}
        readOnly={readOnly}
        versionType={versionType}
        onChange={handleChange}
      />
    );
  }
  const columnType = viewField.column.columnType;

  if (columnType.type !== "enum" || columnType.enumAllowedValues == null || columnType.enumAllowedValues.length === 0) {
    return (
      <StringInput
        currentUserInfo={currentUserInfo}
        entity={entity}
        entityTypeId={entityTypeId}
        field={field}
        input={input}
        isFieldTouched={isFieldTouched}
        organizationId={organizationId}
        readOnly={readOnly}
        versionType={versionType}
        onChange={handleChange}
      />
    );
  }

  const enumValues = columnType.enumAllowedValues;

  return (
    <Select
      disabled={enumValues.length === 0 || readOnly} // TODO @sspringer this should probably show some sort of UI message to the user
      name={field.name}
      value={field.value?.type === "array" ? field.value.value[0] : ""}
      onValueChange={handleValueChange}
    >
      <SelectTrigger ref={field.ref} className={className} onBlur={handleBlur} onClear={handleClear}>
        <SelectValue placeholder={`Select ${viewField.displayName}`} />
      </SelectTrigger>

      <SelectContent>
        {enumValues.map((value) => (
          <SelectItem key={value} value={value}>
            {value}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
