import type { BRAND } from "zod";

export type IIsStringLiteral<T> = T extends string & BRAND<infer _>
  ? false
  : string extends T
    ? false
    : T extends string
      ? true
      : false;

export type IGroupedBy<Key extends string, Value> =
  IIsStringLiteral<Key> extends true ? { [k in Key]?: Value[] } : Record<Key, Value[]>;

/**
 * Groups the elements of an array by the keys from the function provided, discarding any values where the function returns `undefined`
 */
export function groupByNoUndefined<Value, Key extends string = string>(
  arr: Value[],
  getKey: (value: Value, index: number) => Key | undefined,
): IGroupedBy<Key, Value> {
  const res: IGroupedBy<Key, Value> = {} as IGroupedBy<Key, Value>;

  arr.forEach((value, index) => {
    const newKey = getKey(value, index);

    if (newKey == null) {
      return;
    }

    let val: Value[] | undefined = res[newKey];

    if (val == null) {
      val = [];
      res[newKey] = val as IGroupedBy<Key, Value>[Key];
    }

    val.push(value);
  });

  return res;
}

/**
 * Groups the elements of an array by the keys from the function provided
 */
export function groupBy<Value, Key extends string = string>(
  arr: Value[],
  getKey: (value: Value, index: number) => Key,
): IGroupedBy<Key, Value> {
  return groupByNoUndefined(arr, getKey);
}
