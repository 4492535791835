import type {
  IComparisonRule,
  IFileEntityColumnValue,
  IMinimalEntityWithFields,
  IRelationFieldValue,
} from "@archetype/dsl";
import type { DateTime } from "luxon";

import { createFileLogger } from "../logger";
import { getElementValue } from "./elements";

const logger = createFileLogger("compare");

function compareValues(
  operator: IComparisonRule["operator"],
  firstValue:
    | IRelationFieldValue["value"]
    | DateTime
    | IFileEntityColumnValue[]
    | string
    | number
    | boolean
    | null
    | undefined,
  secondValue:
    | IRelationFieldValue["value"]
    | DateTime
    | IFileEntityColumnValue[]
    | string
    | number
    | boolean
    | null
    | undefined,
): boolean | undefined {
  const arraysEqual = (a: string[], b: string[]): boolean => a.length === b.length && a.every((v, i) => v === b[i]);

  if (Array.isArray(firstValue) && Array.isArray(secondValue)) {
    switch (operator) {
      case "equals": {
        return arraysEqual(firstValue as [], secondValue as []);
      }
      case "notEquals": {
        return !arraysEqual(firstValue as [], secondValue as []);
      }
      case "lessThan": {
        return firstValue.length < secondValue.length;
      }
      case "lessThanEqual": {
        return firstValue.length <= secondValue.length;
      }
      case "greaterThan": {
        return firstValue.length > secondValue.length;
      }
      case "greaterThanEqual": {
        return firstValue.length >= secondValue.length;
      }
      case "stringLengthEquals":
      case "stringLengthNotEquals":
      case "stringLengthLessThan":
      case "stringLengthLessThanEqual":
      case "stringLengthGreaterThan":
      case "stringLengthGreaterThanEqual":
      case "stringContains": {
        return false;
      }
    }
  }

  switch (operator) {
    case "equals":
    case "stringLengthEquals": {
      if (typeof firstValue === "string" && typeof secondValue === "string") {
        return firstValue.toLowerCase() === secondValue.toLowerCase();
      }

      return firstValue === secondValue;
    }
    case "notEquals":
    case "stringLengthNotEquals": {
      if (typeof firstValue === "string" && typeof secondValue === "string") {
        return firstValue.toLowerCase() !== secondValue.toLowerCase();
      }

      return firstValue !== secondValue;
    }
    case "lessThan":
    case "stringLengthLessThan": {
      if (firstValue == null || secondValue == null) {
        return false;
      }

      return firstValue < secondValue;
    }
    case "lessThanEqual":
    case "stringLengthLessThanEqual": {
      if (firstValue == null || secondValue == null) {
        return false;
      }

      return firstValue <= secondValue;
    }
    case "greaterThan":
    case "stringLengthGreaterThan": {
      if (firstValue == null || secondValue == null) {
        return false;
      }

      return firstValue > secondValue;
    }
    case "greaterThanEqual":
    case "stringLengthGreaterThanEqual": {
      if (firstValue == null || secondValue == null) {
        return false;
      }

      return firstValue >= secondValue;
    }
    case "stringContains": {
      if (firstValue == null || secondValue == null) {
        return false;
      }

      // eslint-disable-next-line @typescript-eslint/no-base-to-string -- we sort of accept that this might incorrectly stringify
      return String(firstValue).toLowerCase().includes(String(secondValue).toLowerCase());
    }
    default: {
      logger.error(`Unsupported operator: ${operator as string}`);

      return undefined;
    }
  }
}

export function compare(
  rule: IComparisonRule,
  entity: { fields: IMinimalEntityWithFields["fields"] },
  otherEntity?: { fields: IMinimalEntityWithFields["fields"] },
): boolean | undefined {
  let firstValue = getElementValue(rule.firstElement, entity);
  const secondValue = getElementValue(rule.secondElement, entity);
  const otherSecondValue = otherEntity == null ? undefined : getElementValue(rule.secondElement, otherEntity);

  // for string length comparison
  if (rule.operator.startsWith("stringLength")) {
    // Cannot do a stringLength on an object type (as it will stringify to `[object Object]`)
    if (typeof firstValue === "object") {
      logger.error({ firstValue }, "First value is not a string");

      return false;
    }

    firstValue = firstValue == null ? 0 : String(firstValue).length;
  }

  try {
    return compareValues(rule.operator, firstValue, secondValue ?? otherSecondValue);
  } catch (error) {
    logger.error(`Unable to compare: ${JSON.stringify(error)}`);

    return undefined;
  }
}
