import { Organization } from "@archetype/dsl";
import type { z } from "zod";

export const PublicOrganization = Organization.pick({
  id: true,
  name: true,
  slug: true,
  imageUrl: true,
  clerkOrganizationId: true,
});

export type IPublicOrganization = z.output<typeof PublicOrganization>;
