import { z } from "zod";

import type { IAutofillConfig } from "./AutofillConfig";
import {
  AIConfig,
  DynamicValueConfig,
  isAIConfig,
  isLogicConfig,
  isLookupConfig,
  LogicConfig,
  LookupConfig,
  StaticValueConfig,
} from "./AutofillConfig";

export const LiveAutofill = z.object({
  type: z.literal("live"),
  config: z.discriminatedUnion("type", [LogicConfig, AIConfig, LookupConfig]),
});
export type ILiveAutofill = z.infer<typeof LiveAutofill>;

export const SnapshotAutofill = z.object({
  type: z.literal("snapshot"),
  config: z.discriminatedUnion("type", [LogicConfig, AIConfig, LookupConfig, StaticValueConfig, DynamicValueConfig]),
});
export type ISnapshotAutofill = z.infer<typeof SnapshotAutofill>;

export const Autofill = z.discriminatedUnion("type", [LiveAutofill, SnapshotAutofill]);
export type IAutofill = z.infer<typeof Autofill>;

// helper methods

export const isLiveAutofill = (autofill: IAutofill): autofill is ILiveAutofill => autofill.type === "live";
export const isSnapshotAutofill = (autofill: IAutofill): autofill is ISnapshotAutofill => autofill.type === "snapshot";

export const isLiveSupportedConfig = (config: IAutofillConfig): config is ILiveAutofill["config"] =>
  isLogicConfig(config) || isAIConfig(config) || isLookupConfig(config);
