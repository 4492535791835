import { RelationId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { LLMStateMachine } from "../llm/llmStateMachine";

export const NewSupportEntityTypeChange = z.object({
  supportEntityTypeToCreate: z.object({
    name: ReadableString,
    pluralName: ReadableString,
    description: ReadableString,
    nameOfTitleColumn: ReadableString,
  }),
});
export type INewSupportEntityTypeChange = z.infer<typeof NewSupportEntityTypeChange>;

export const NewCoreEntityTypeChange = z.object({
  coreEntityTypeToCreate: z.object({
    name: ReadableString,
    pluralName: ReadableString,
    description: ReadableString,
    nameOfTitleColumn: ReadableString,
    stateMachine: LLMStateMachine,
    happyPathStateNames: z.array(ReadableString).min(1),
  }),
});
export type INewCoreEntityTypeChange = z.infer<typeof NewCoreEntityTypeChange>;

export const NewRelationChange = z.object({
  relationToCreate: z.object({
    relationId: RelationId.default(RelationId.generate()),
    titleForBEntitiesWhenOnA: ReadableString,
    titleForAEntitiesWhenOnB: ReadableString,
    entityTypeATitle: ReadableString,
    entityTypeBTitle: ReadableString,
    cardinalityOnSideA: z.enum(["one", "many"]),
    cardinalityOnSideB: z.enum(["one", "many"]),
  }),
});
export type INewRelationChange = z.infer<typeof NewRelationChange>;
