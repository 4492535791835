import { ColumnId, EntityId, EntityTypeId, FieldComputationId } from "@archetype/ids";
import { z } from "zod";

import { VersionType } from "../common/Version";
import { ColumnType } from "./Column";
import { ComputationInputValues } from "./ComputationInputValues";
import { FieldComputationHashedInputValues, FieldComputationStatus } from "./FieldComputation";

export const DerivedColumnComputationKey = z.object({
  versionType: VersionType,

  entityId: EntityId,
  entityTypeId: EntityTypeId,

  columnId: ColumnId,
});
export type IDerivedColumnComputationKey = z.infer<typeof DerivedColumnComputationKey>;

export const DerivedColumnComputationInfo = DerivedColumnComputationKey.merge(
  z.object({
    computationId: FieldComputationId,
    startedAt: z.date(),

    /**
     * Values of the entity provided to the model for computing the result, technically can be a subset of the entity (when we know which subset is relevant)
     */
    inputValues: FieldComputationHashedInputValues,

    derivationLogic: z.string(),
    expectedOutputType: ColumnType,

    // When recomputing, should the instructions contain the previous values or the new values? (Before it returns)
    // Or should we track separately the values being computed for and the values of the current result?
    status: FieldComputationStatus,
  }),
);

export const DerivedColumnComputationExecution = DerivedColumnComputationInfo.omit({
  startedAt: true,
  inputValues: true,
}).merge(
  z.object({
    inputValues: ComputationInputValues,
  }),
);

export const DerivedColumnComputationResult = DerivedColumnComputationInfo;

export type IDerivedColumnComputationInfo = z.infer<typeof DerivedColumnComputationInfo>;
export type IDerivedColumnComputationExecution = z.infer<typeof DerivedColumnComputationExecution>;
export type IDerivedColumnComputationResult = z.infer<typeof DerivedColumnComputationResult>;
