import type { MotionProps } from "framer-motion";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import type { IShapeColor } from "../../../lib/shapeColor";
import type { IShapeName } from "../../../lib/shapeName";
import { cn } from "../../../lib/utils";
import { ShapeColorIcon } from "../../atoms/shape-color-icon";
import { Skeleton } from "../../atoms/skeleton";

interface IApp {
  title: string;
  icon?: {
    name: IShapeName;
    color: IShapeColor;
  };
}

// Discriminated union for preview types
export interface IBasePreview {
  heading: string;
}

export interface IFormPreview extends IBasePreview {
  type: "form";
}

export interface IViewPreview extends IBasePreview {
  type: "view";
}

export interface IWorkflowPreview extends IBasePreview {
  type: "workflow";
}

export interface IWorkspacePreview extends IBasePreview {
  type: "workspace";
}

export type IPreviewType = IFormPreview | IViewPreview | IWorkflowPreview | IWorkspacePreview;

interface IOnboardingAppSkeleton {
  className?: string;
  apps: IApp[];
  selectedApp?: string;
  businessName?: string;
  businessLogo?: string;
  preview?: IPreviewType;
}

// Consistent animation timing function
const getConsistentTiming = (index: number, slower = false): MotionProps["transition"] => {
  return {
    duration: 0.3,
    delay: 0.1 + index * (slower ? 0.175 : 0.05), // Base delay + stagger based on index
  };
};

export const OnboardingAppSkeleton: React.FC<IOnboardingAppSkeleton> = ({
  apps,
  selectedApp,
  className,
  businessName,
  businessLogo,
  preview,
}) => {
  // Form preview renderer
  const renderFormPreview = (formPreview: IFormPreview): React.JSX.Element => {
    return (
      <motion.div
        animate={{ opacity: 1 }}
        className="w-full p-6"
        initial={{ opacity: 0 }}
        transition={getConsistentTiming(0)}
      >
        <motion.h2
          animate={{ opacity: 1, y: 0 }}
          className="mb-8 text-xl font-medium"
          initial={{ opacity: 0, y: -5 }}
          transition={getConsistentTiming(2)}
        >
          {formPreview.heading}
        </motion.h2>

        <div className="space-y-2">
          {Array.from({ length: 4 }).map((_, index) => {
            // Create a stable key with explicitly cast string
            const fieldIndex = String(index);
            const fieldKey = `form-field-${fieldIndex}`;

            return (
              <motion.div
                key={fieldKey}
                animate={{ opacity: 1, y: 0 }}
                className="space-y-1"
                initial={{ opacity: 0, y: 5 }}
                transition={getConsistentTiming(2 + index, true)}
              >
                <Skeleton className="h-3 w-24" />
                <Skeleton className="h-6 w-full" />
              </motion.div>
            );
          })}

          <motion.div
            animate={{ opacity: 1 }}
            className="mt-10 pt-4"
            initial={{ opacity: 0 }}
            transition={getConsistentTiming(6, true)}
          >
            <div className="flex justify-end space-x-4">
              <Skeleton className="h-6 w-16" />
              <Skeleton className="h-6 w-24" />
            </div>
          </motion.div>
        </div>
      </motion.div>
    );
  };

  // View preview renderer
  const renderViewPreview = (viewPreview: IViewPreview): React.JSX.Element => {
    return (
      <motion.div
        animate={{ opacity: 1 }}
        className="w-full space-y-6 p-6"
        initial={{ opacity: 0 }}
        transition={getConsistentTiming(0)}
      >
        <motion.h2
          animate={{ opacity: 1, y: 0 }}
          className="text-lg font-medium"
          initial={{ opacity: 0, y: -5 }}
          transition={getConsistentTiming(2)}
        >
          {viewPreview.heading}
        </motion.h2>

        <motion.div
          animate={{ opacity: 1 }}
          className="flex justify-start space-x-2"
          initial={{ opacity: 0 }}
          transition={getConsistentTiming(2, true)}
        >
          <Skeleton className="h-6 w-16" />
          <Skeleton className="h-6 w-12" />
          <Skeleton className="h-6 w-20" />
        </motion.div>

        <div className="space-y-2">
          {Array.from({ length: 5 }).map((_, index) => {
            // Create a stable key with explicitly cast string
            const fieldIndex = String(index);
            const fieldKey = `view-field-${fieldIndex}`;

            return (
              <motion.div
                key={fieldKey}
                animate={{ opacity: 1 }}
                className={cn("flex items-center gap-x-2", index === 4 ? "items-start" : "")}
                initial={{ opacity: 0 }}
                transition={getConsistentTiming(3 + index, true)}
              >
                <Skeleton className="h-3.5 w-24" />
                <Skeleton className={cn("h-3.5 w-36", index === 4 ? "h-32" : "h-3.5")} />
              </motion.div>
            );
          })}
        </div>
      </motion.div>
    );
  };

  // Workflow preview renderer
  const renderWorkflowPreview = (workflowPreview: IWorkflowPreview): React.JSX.Element => {
    return (
      <motion.div
        animate={{ opacity: 1 }}
        className="w-full p-6"
        initial={{ opacity: 0 }}
        transition={getConsistentTiming(0)}
      >
        <motion.h2
          animate={{ opacity: 1, y: 0 }}
          className="mb-8 text-xl font-medium"
          initial={{ opacity: 0, y: -5 }}
          transition={getConsistentTiming(2)}
        >
          {workflowPreview.heading}
        </motion.h2>

        <div className="space-y-12">
          {Array.from({ length: 3 }).map((_, sectionIndex) => {
            // Create stable keys
            const sectionIdxStr = String(sectionIndex);
            const sectionKey = `workflow-section-${sectionIdxStr}`;

            return (
              <motion.div
                key={sectionKey}
                animate={{ opacity: 1, y: 0 }}
                className="space-y-2"
                initial={{ opacity: 0, y: 10 }}
                transition={getConsistentTiming(3 + sectionIndex, true)}
              >
                {/* Minimal section label */}
                <motion.div
                  animate={{ opacity: 1 }}
                  className="flex items-center justify-between pb-2"
                  initial={{ opacity: 0 }}
                  transition={getConsistentTiming(4 + sectionIndex, true)}
                >
                  <Skeleton className="h-4 w-32" />
                </motion.div>

                {/* Clean table skeleton with overflow */}
                <div className="border-border-light w-[150%] overflow-hidden rounded-md border">
                  {/* Simple header */}
                  <div className="border-border-light h-6 border-b bg-gray-50" />

                  {/* Clean table rows */}
                  {Array.from({ length: 6 }).map((_y, rowIndex) => {
                    const rowIdxStr = String(rowIndex);
                    const rowKey = `row-${sectionIdxStr}-${rowIdxStr}`;

                    return (
                      <motion.div
                        key={rowKey}
                        animate={{ opacity: 1 }}
                        className="h-6 border-b border-gray-100 px-1 last:border-0"
                        initial={{ opacity: 0 }}
                        transition={getConsistentTiming(5 + sectionIndex + rowIndex, true)}
                      >
                        <div className="flex items-center gap-2">
                          {Array.from({ length: 6 }).map((_z, colIndex) => {
                            const colIdxStr = String(colIndex);
                            const cellKey = `cell-${sectionIdxStr}-${rowIdxStr}-${colIdxStr}`;

                            return (
                              <div key={cellKey} className="flex-1">
                                <Skeleton className="h-3 w-full" />
                              </div>
                            );
                          })}
                        </div>
                      </motion.div>
                    );
                  })}
                </div>
              </motion.div>
            );
          })}

          {/* Final section with minimal styling */}
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            className="space-y-4"
            initial={{ opacity: 0, y: 10 }}
            transition={getConsistentTiming(7)}
          >
            <motion.div
              animate={{ opacity: 1 }}
              className="pb-2"
              initial={{ opacity: 0 }}
              transition={getConsistentTiming(8)}
            >
              <Skeleton className="h-4 w-44" />
            </motion.div>

            <div className="grid grid-cols-3 gap-2">
              {Array.from({ length: 3 }).map((_, cardIndex) => {
                const cardIdxStr = String(cardIndex);
                const cardKey = `card-${cardIdxStr}`;

                return (
                  <motion.div
                    key={cardKey}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-muted-background space-y-4 rounded-md p-5"
                    initial={{ opacity: 0, y: 5 }}
                    transition={getConsistentTiming(9 + cardIndex, true)}
                  >
                    <Skeleton className="h-3.5 w-2/3" />
                    <Skeleton className="h-8 w-full" />
                    <Skeleton className="h-3 w-3/4" />
                    <Skeleton className="h-3 w-1/2" />
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </motion.div>
    );
  };

  // Workspace preview renderer
  const renderWorkspacePreview = (workspacePreview: IWorkspacePreview): React.JSX.Element => {
    return (
      <motion.div
        animate={{ opacity: 1 }}
        className="w-full p-6"
        initial={{ opacity: 0 }}
        transition={getConsistentTiming(0)}
      >
        <motion.h2
          animate={{ opacity: 1, y: 0 }}
          className="mb-8 text-xl font-medium"
          initial={{ opacity: 0, y: -5 }}
          transition={getConsistentTiming(2)}
        >
          {workspacePreview.heading}
        </motion.h2>

        {/* Workspace header with overview stats */}
        <motion.div
          animate={{ opacity: 1, y: 0 }}
          className="mb-8 flex flex-wrap gap-4"
          initial={{ opacity: 0, y: 5 }}
          transition={getConsistentTiming(2, true)}
        >
          {Array.from({ length: 4 }).map((_, index) => {
            const statKey = `workspace-stat-${String(index)}`;

            return (
              <motion.div
                key={statKey}
                animate={{ opacity: 1, scale: 1 }}
                className="bg-muted/30 flex flex-col rounded-md p-3"
                initial={{ opacity: 0, scale: 0.95 }}
                transition={getConsistentTiming(3 + index, true)}
              >
                <Skeleton className="mb-1 h-3 w-20" />
                <Skeleton className="h-5 w-12 font-semibold" />
              </motion.div>
            );
          })}
        </motion.div>

        <div className="space-y-8">
          {/* Recent activity section */}
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            className="space-y-2"
            initial={{ opacity: 0, y: 10 }}
            transition={getConsistentTiming(3)}
          >
            {/* Section header with actions */}
            <motion.div
              animate={{ opacity: 1 }}
              className="flex items-center justify-between pb-2"
              initial={{ opacity: 0 }}
              transition={getConsistentTiming(4)}
            >
              <Skeleton className="h-4 w-32" />
              <div className="flex gap-2">
                <Skeleton className="h-4 w-16 rounded-md" />
                <Skeleton className="h-4 w-16 rounded-md" />
              </div>
            </motion.div>

            {/* Activity feed */}
            <div className="border-border rounded-md border">
              {Array.from({ length: 4 }).map((_, activityIndex) => {
                const activityKey = `activity-${String(activityIndex)}`;

                return (
                  <motion.div
                    key={activityKey}
                    animate={{ opacity: 1 }}
                    className="border-border flex items-start gap-3 border-b p-3 last:border-0"
                    initial={{ opacity: 0 }}
                    transition={getConsistentTiming(5 + activityIndex)}
                  >
                    <Skeleton className="mt-1 size-5 shrink-0 rounded-full" />
                    <div className="grow space-y-1">
                      <div className="flex items-center justify-between">
                        <Skeleton className="h-3.5 w-40" />
                        <Skeleton className="h-3 w-16" />
                      </div>
                      <Skeleton className="h-3 w-full" />
                      <Skeleton className="h-3 w-3/4" />
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </motion.div>

          {/* Data tables section */}
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            className="space-y-2"
            initial={{ opacity: 0, y: 10 }}
            transition={getConsistentTiming(5)}
          >
            {/* Section header with tabs */}
            <motion.div
              animate={{ opacity: 1 }}
              className="flex items-center gap-4 border-b pb-2"
              initial={{ opacity: 0 }}
              transition={getConsistentTiming(6)}
            >
              <Skeleton className="h-4 w-20 rounded-sm" />
              <Skeleton className="h-4 w-24 rounded-sm" />
              <Skeleton className="h-4 w-16 rounded-sm" />
            </motion.div>

            {/* Data table */}
            <div className="border-border w-[150%] overflow-hidden rounded-md border">
              {/* Table header */}
              <div className="border-border-light bg-muted/20 flex h-8 items-center border-b px-3">
                {Array.from({ length: 5 }).map((_, headerIndex) => {
                  const headerKey = `header-${String(headerIndex)}`;

                  return (
                    <div key={headerKey} className={headerIndex === 0 ? "flex-[2]" : "flex-1"}>
                      <Skeleton className="h-3 w-4/5" />
                    </div>
                  );
                })}
              </div>

              {/* Table rows */}
              {Array.from({ length: 6 }).map((_, rowIndex) => {
                const rowKey = `table-row-${String(rowIndex)}`;

                return (
                  <motion.div
                    key={rowKey}
                    animate={{ opacity: 1 }}
                    className="border-border-light flex h-10 items-center border-b px-3 last:border-0"
                    initial={{ opacity: 0 }}
                    transition={getConsistentTiming(7 + rowIndex)}
                  >
                    {Array.from({ length: 5 }).map((__, colIndex) => {
                      const cellKey = `cell-${rowKey}-${String(colIndex)}`;

                      return (
                        <div key={cellKey} className={colIndex === 0 ? "flex-[2]" : "flex-1"}>
                          {colIndex === 0 ? (
                            <div className="flex items-center gap-2">
                              <Skeleton className="size-4 rounded-sm" />
                              <Skeleton className="h-3 w-20" />
                            </div>
                          ) : (
                            <Skeleton className="h-3 w-4/5" />
                          )}
                        </div>
                      );
                    })}
                  </motion.div>
                );
              })}
            </div>
          </motion.div>

          {/* Quick actions cards */}
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            className="space-y-4"
            initial={{ opacity: 0, y: 10 }}
            transition={getConsistentTiming(7)}
          >
            <motion.div
              animate={{ opacity: 1 }}
              className="pb-2"
              initial={{ opacity: 0 }}
              transition={getConsistentTiming(8)}
            >
              <Skeleton className="h-4 w-44" />
            </motion.div>

            <div className="grid grid-cols-3 gap-3">
              {Array.from({ length: 3 }).map((_, cardIndex) => {
                const cardKey = `action-card-${String(cardIndex)}`;

                return (
                  <motion.div
                    key={cardKey}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-muted-background border-border flex flex-col rounded-md border p-4"
                    initial={{ opacity: 0, y: 5 }}
                    transition={getConsistentTiming(9 + cardIndex)}
                  >
                    <div className="mb-2 flex justify-between">
                      <Skeleton className="h-4 w-24" />
                      <Skeleton className="size-4 rounded-full" />
                    </div>
                    <Skeleton className="mb-3 h-3 w-full" />
                    <Skeleton className="mb-1 h-3 w-3/4" />
                    <Skeleton className="h-3 w-1/2" />
                    <div className="mt-auto pt-3">
                      <Skeleton className="h-5 w-20 rounded-sm" />
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </motion.div>
    );
  };

  // Render the appropriate preview based on type
  const renderPreview = (): React.JSX.Element | null => {
    if (!preview) {
      return null;
    }

    switch (preview.type) {
      case "form": {
        return renderFormPreview(preview);
      }
      case "view": {
        return renderViewPreview(preview);
      }
      case "workflow": {
        return renderWorkflowPreview(preview);
      }
      case "workspace": {
        return renderWorkspacePreview(preview);
      }
      default: {
        return null;
      }
    }
  };

  return (
    <motion.div
      animate={{ opacity: 1 }}
      className="pointer-events-none flex size-full grow"
      initial={{ opacity: 0 }}
      transition={getConsistentTiming(0)}
    >
      <motion.div
        animate={{ x: 0, opacity: 1 }}
        className={cn("bg-paper-alt h-full w-52 space-y-6 overflow-hidden rounded-l-md p-4", className)}
        initial={{ x: -50, opacity: 0 }}
        transition={getConsistentTiming(1)}
      >
        {/* Business info section - only show if business name or logo is provided */}
        <motion.div animate={{ opacity: 1, y: 0 }} initial={{ opacity: 0, y: -10 }} transition={getConsistentTiming(2)}>
          <div className="flex items-center gap-2">
            {businessLogo != null ? (
              <motion.img
                alt={businessName ?? "Business logo"}
                animate={{ scale: 1, opacity: 1 }}
                className="size-5 rounded-sm object-contain"
                initial={{ scale: 0.8, opacity: 0 }}
                src={businessLogo}
                transition={getConsistentTiming(3)}
              />
            ) : (
              <Skeleton className="size-5" />
            )}
            {businessName != null ? (
              <motion.p
                animate={{ opacity: 1 }}
                className="truncate text-base font-semibold"
                initial={{ opacity: 0 }}
                transition={getConsistentTiming(4)}
              >
                {businessName}
              </motion.p>
            ) : (
              <Skeleton className="h-4 w-3/4" />
            )}
          </div>
        </motion.div>

        {/* Workflow title section */}
        <motion.div
          animate={{ opacity: 1, y: 0 }}
          className="mb-6"
          initial={{ opacity: 0, y: 10 }}
          transition={getConsistentTiming(5)}
        >
          {/* Placeholder items */}
          <div className="space-y-2">
            <motion.div
              animate={{ opacity: 1, x: 0 }}
              className="flex items-center gap-2"
              initial={{ opacity: 0, x: -10 }}
              transition={getConsistentTiming(6)}
            >
              <Skeleton className="size-3 rounded-full" />
              <Skeleton className="h-3 w-32" />
            </motion.div>
            <motion.div
              animate={{ opacity: 1, x: 0 }}
              className="flex items-center gap-2"
              initial={{ opacity: 0, x: -10 }}
              transition={getConsistentTiming(7)}
            >
              <Skeleton className="size-3 rounded-full" />
              <Skeleton className="h-3 w-28" />
            </motion.div>
          </div>
        </motion.div>

        {/* Your apps section */}
        <motion.div
          animate={{ opacity: 1, y: 0 }}
          className="space-y-2"
          initial={{ opacity: 0, y: 15 }}
          transition={getConsistentTiming(8)}
        >
          <motion.p
            animate={{ opacity: 1 }}
            className="text-sm"
            initial={{ opacity: 0 }}
            transition={getConsistentTiming(9)}
          >
            Your apps
          </motion.p>

          <div className="select-none space-y-2">
            {/* Always render 10 slots */}
            {Array.from({ length: 10 }).map((_, index) => {
              const app = apps[index]; // Get app data if it exists for this index
              const slotKey = `app-slot-${String(index)}`;

              return (
                // Outer div for positioning and initial stagger
                <motion.div
                  key={slotKey}
                  animate={{ opacity: 1, x: 0 }}
                  className="flex h-5 items-center gap-2" // Ensure consistent height
                  initial={{ opacity: 0, x: -5 }}
                  transition={getConsistentTiming(10 + index)}
                >
                  {/* AnimatePresence to handle content swap */}
                  <AnimatePresence initial={false} mode="wait">
                    {app ? (
                      // Real app content
                      <motion.div
                        key={app.title} // Key based on app title
                        animate={{ opacity: 1, x: 0 }}
                        className={cn(
                          "flex w-full items-center gap-2",
                          app.title === selectedApp && "bg-muted/50 -m-1 rounded p-1", // Highlight if selected
                        )}
                        exit={{ opacity: 0, x: -5 }}
                        initial={{ opacity: 0, x: 5 }}
                        transition={{ duration: 0.2 }}
                      >
                        {app.icon ? (
                          <ShapeColorIcon color={app.icon.color} shape={app.icon.name} size="sm" />
                        ) : (
                          <Skeleton className="size-3" /> // Fallback icon skeleton
                        )}
                        <span className="text-muted-foreground line-clamp-1 cursor-default text-sm">{app.title}</span>
                      </motion.div>
                    ) : (
                      // Skeleton content
                      <motion.div
                        key={`skeleton-${String(index)}`} // Key based on index
                        animate={{ opacity: 1, x: 0 }}
                        className="flex w-full items-center gap-2"
                        exit={{ opacity: 0, x: -5 }}
                        initial={{ opacity: 0, x: 5 }}
                        transition={{ duration: 0.2 }}
                      >
                        <Skeleton className="size-3 rounded-full" />
                        <Skeleton className="h-3 w-24" />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              );
            })}
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        animate={{ opacity: 1, x: 0 }}
        className="bg-paper-alt flex grow"
        initial={{ opacity: 0, x: 20 }}
        transition={getConsistentTiming(2)}
      >
        <motion.div
          animate={{ opacity: 1, scale: 1 }}
          className="border-border my-2 w-full overflow-hidden rounded-l-md border border-r-0 bg-white"
          initial={{ opacity: 0, scale: 0.95 }}
          transition={getConsistentTiming(3)}
        >
          <motion.div
            animate={{ opacity: 1 }}
            className="border-border flex border-b bg-white p-2.5"
            initial={{ opacity: 0 }}
            transition={getConsistentTiming(4)}
          >
            <div className="flex items-center gap-2">
              <Skeleton className="size-3 rounded-full" />
              <Skeleton className="h-3 w-32" />
            </div>
          </motion.div>

          {/* Preview content area - Wrap in AnimatePresence for smooth preview transitions */}
          <AnimatePresence mode="wait">
            <motion.div
              // Key ensures animation runs when preview type changes
              key={preview?.type ?? "default"}
              animate={{ opacity: 1, y: 0 }}
              className="w-[300px] overflow-hidden"
              exit={{ opacity: 0, y: -10 }}
              initial={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.2 }} // Quick transition for preview content
            >
              {renderPreview()}
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
