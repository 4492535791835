import type { IViewFieldValue } from "@archetype/dsl";
import type { IViewFieldId } from "@archetype/ids";
import { forEach } from "@archetype/utils";
import { isEqual } from "lodash";

export interface IViewFieldChange {
  before: IViewFieldValue;
  after: IViewFieldValue;
}

export type IViewFieldChanges = Record<IViewFieldId, IViewFieldChange>;

export function diffViewFields(
  before: Record<IViewFieldId, IViewFieldValue | undefined> | null,
  after: Record<IViewFieldId, IViewFieldValue | undefined> | null,
): IViewFieldChanges {
  if (after == null) {
    return {};
  }

  const changes: IViewFieldChanges = {};

  forEach(after, (afterValue, key) => {
    const beforeValue = before?.[key] ?? { type: "null" };

    if (afterValue == null || isEqual(beforeValue, afterValue)) {
      return;
    }

    changes[key] = {
      before: beforeValue,
      after: afterValue,
    };
  });

  return changes;
}
