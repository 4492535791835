"use client";

import * as React from "react";

interface IPanelHeaderProps {
  children: React.ReactNode;
}

export const PanelHeader: React.FC<IPanelHeaderProps> = ({ children }) => {
  return <div className="bg-paper sticky top-0 z-50">{children}</div>;
};
