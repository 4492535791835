import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { SearchInputComponentDefinitionId } from "@archetype/ids";

export const SearchInputComponentIdDeclarations = {
  id: SearchInputComponentDefinitionId,
  inputs: ["multiEntityQueryDataInput"],
  outputs: ["filteredMultiEntityQuery"],
  compatibleSemanticSizes: ["actions"],
  validCosmeticOptionalProps: ["label", "styling"],
} as const;

export const SearchInputComponentDefinition: ITypedComponentDefinitionDeclaration<
  typeof SearchInputComponentIdDeclarations
> = {
  constIds: SearchInputComponentIdDeclarations,
  displayMetadata: {
    name: "Search input",
    description: "A search input component",
  },
  inputs: {
    multiEntityQueryDataInput: {
      displayMetadata: {
        name: "Search data",
        description: "Data to search",
      },
      optional: false,
    },
  },
  outputs: {
    filteredMultiEntityQuery: {
      displayMetadata: {
        name: "Filtered data",
        description: "The input data, but filtered on the search query",
      },
      optional: false,
    },
    // Could add an output of a "string to highlight", or data with highlight
  },
};
