import { cn, ScrollArea, ScrollAreaProvider } from "@archetype/ui";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableInstanceContext } from "../context/DataTableInstanceContext";
import { DataTablePaginationContext } from "../context/DataTablePaginationContext";
import { useCellKeyboardNavigation } from "../hooks/useCellKeyboardNavigation";
import { useGridVirtualization } from "../hooks/useGridVirtualization";
import { DataTableHeader } from "./header/DataTableHeader";
import { DataTableRow } from "./row/DataTableRow";

const STICKY_COLUMNS_THRESHOLD = 768;

interface IDataTable {
  className?: string;
  stickyColumnsThreshold?: number;
}

export function DataTable({
  className,
  stickyColumnsThreshold = STICKY_COLUMNS_THRESHOLD,
}: IDataTable): React.ReactElement {
  const data = useContextSelector(DataTableInstanceContext, (state) => state.data);
  const tableRef = useContextSelector(DataTableInstanceContext, (state) => state.tableRef);
  const rowCount = useContextSelector(DataTableInstanceContext, (state) => state.rowCount);
  const columnWidths = useContextSelector(DataTableInstanceContext, (state) => state.columnWidths);
  const columns = useContextSelector(DataTableInstanceContext, (state) => state.columns);
  const rowHeight = useContextSelector(DataTableInstanceContext, (state) => state.rowHeight);
  const headerHeight = useContextSelector(DataTableInstanceContext, (state) => state.headerHeight);
  const hasNextPage = useContextSelector(DataTablePaginationContext, (state) => state.hasNextPage);
  const isFetchingNextPage = useContextSelector(DataTablePaginationContext, (state) => state.isFetchingNextPage);
  const onFetchNextPage = useContextSelector(DataTablePaginationContext, (state) => state.onFetchNextPage);

  const { onKeyDown: handleKeyDown } = useCellKeyboardNavigation({ disabled: false });

  const [enableStickyColumns, setEnableStickyColumns] = useState(true);
  const [rowsLoaded, setRowsLoaded] = useState(new Set<number>());
  const [containerWidth, setContainerWidth] = useState(0);

  const handleLoadMore = useCallback(async (): Promise<void> => {
    if (hasNextPage && !isFetchingNextPage) {
      await onFetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, onFetchNextPage]);

  useEffect(() => {
    setRowsLoaded(() => {
      const res: Set<number> = new Set();

      data.forEach((row, idx) => {
        res.add(idx);
      });

      return res;
    });
  }, [data]);

  const pinnedRows = useMemo(() => {
    const topIndexes: number[] = [];
    const bottomIndexes: number[] = [];

    data.forEach((row, index) => {
      if (row.pinned === "top") {
        topIndexes.push(index);
      } else if (row.pinned === "bottom") {
        bottomIndexes.push(index);
      }
    });

    return {
      top: topIndexes,
      bottom: bottomIndexes,
    };
  }, [data]);

  const pinnedColumns = useMemo(() => {
    const leftIndexes: number[] = [];
    const rightIndexes: number[] = [];

    columns.forEach((col, index) => {
      if (col.pinned === "left") {
        leftIndexes.push(index);
      } else if (col.pinned === "right") {
        rightIndexes.push(index);
      }
    });

    return {
      left: leftIndexes,
      right: rightIndexes,
    };
  }, [columns]);

  const getRowHeight = useCallback((): number => rowHeight, [rowHeight]);

  const handleIsRowLoaded = useCallback((index: number): boolean => rowsLoaded.has(index), [rowsLoaded]);

  const {
    onScroll: handleScroll,
    visibleRows,
    visibleColumns,
    totalHeight,
    totalWidth,
  } = useGridVirtualization({
    containerRef: tableRef,
    columnCount: columns.length,
    rowCount,
    getRowHeight,
    headerHeight,
    columnWidths,
    onLoadMore: handleLoadMore,
    isRowLoaded: handleIsRowLoaded,
    pinnedColumns,
    pinnedRows,
  });

  useEffect(() => {
    const currentTableRef = tableRef.current;

    const checkWidth = (): void => {
      const width = currentTableRef?.offsetWidth ?? window.innerWidth;

      setContainerWidth(width);
      setEnableStickyColumns(width >= stickyColumnsThreshold);
    };

    const resizeObserver = new ResizeObserver(() => {
      checkWidth();
    });

    if (currentTableRef) {
      resizeObserver.observe(currentTableRef);
    } else {
      window.addEventListener("resize", checkWidth);
      checkWidth();
    }

    return (): void => {
      if (currentTableRef) {
        resizeObserver.unobserve(currentTableRef);
      } else {
        window.removeEventListener("resize", checkWidth);
      }
    };
  }, [tableRef, stickyColumnsThreshold]);

  const adjustedTotalWidth = Math.max(totalWidth, containerWidth);

  const headers = useMemo(
    () =>
      columns.map((c) => ({
        id: c.id,
        index: columns.findIndex((col) => col.id === c.id),
        column: c,
      })),
    [columns],
  );

  return (
    <ScrollAreaProvider direction="both">
      <ScrollArea
        ref={tableRef}
        aria-colcount={columns.length}
        aria-rowcount={rowCount + columns.length}
        className={cn(
          "border-border bg-paper @container relative transform-gpu rounded border outline-none",
          className,
        )}
        role="table"
        style={{
          contain: "size layout",
          isolation: "isolate",
          position: "relative",
          overscrollBehavior: "contain",
        }}
        tabIndex={-1}
        onKeyDown={handleKeyDown}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- functional
        onScroll={handleScroll}
      >
        <div
          style={{
            height: totalHeight,
            width: adjustedTotalWidth,
            position: "relative",
            transform: "translateZ(0)",
          }}
          tabIndex={-1}
        >
          <DataTableHeader
            key="header"
            enableStickyColumns={enableStickyColumns}
            headers={headers}
            virtualRow={{
              index: 0,
              top: 0,
              height: headerHeight,
              pinned: "top",
            }}
            visibleColumns={visibleColumns}
          />
          {visibleRows.map((virtualRow) => (
            <DataTableRow
              key={virtualRow.index}
              enableStickyColumns={enableStickyColumns}
              isSkeletonRow={!rowsLoaded.has(virtualRow.index)}
              virtualRow={virtualRow}
              visibleColumns={visibleColumns}
            />
          ))}
        </div>
      </ScrollArea>
    </ScrollAreaProvider>
  );
}
