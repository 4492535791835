import "@trpc/react-query/shared"; // This fixes a weird typescript portable type error

import type { WebRouter } from "@archetype/trpc-web";
import { httpLink, loggerLink } from "@trpc/client";
import type { CreateTRPCNext } from "@trpc/next";
import { createTRPCNext } from "@trpc/next";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import type { NextPageContext } from "next";
import * as superjson from "superjson";

function getBaseUrl(): string {
  if (typeof window !== "undefined") {
    return "";
  }

  // reference for vercel.com
  if (process.env.VERCEL_URL != null) {
    return `https://${process.env.VERCEL_URL}`;
  }

  // assume localhost
  return `http://127.0.0.1:${process.env.PORT ?? "3000"}`;
}

/**
 * A set of strongly-typed React hooks from your `AppRouter` type signature with `createReactQueryHooks`.
 * @link https://trpc.io/docs/react#3-create-trpc-hooks
 */
export const trpc: CreateTRPCNext<WebRouter, NextPageContext> = createTRPCNext<WebRouter>({
  transformer: superjson,
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    return {
      /**
       * @link https://trpc.io/docs/links
       */
      links: [
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === "development" || (opts.direction === "down" && opts.result instanceof Error),
        }),
        // httpBatchLink({
        httpLink({
          url: `${getBaseUrl()}/api/trpc`,
          transformer: superjson,

          /**
           * Set custom request headers on every request from tRPC
           * @link https://trpc.io/docs/ssr
           */
          headers() {
            if (ctx?.req) {
              // To use SSR properly, you need to forward the client's headers to the server
              // This is so you can pass through things like cookies when we're server-side rendering

              // If you're using Node 18, omit the "connection" header

              const { connection: _connection, ...headers } = ctx.req.headers;

              return {
                ...headers,
                // Optional: inform trpc that it's an SSR request
                "x-ssr": "1",
              };
            }

            return {};
          },
        }),
      ],
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      queryClientConfig: {
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: process.env.NEXT_PUBLIC_DISABLE_REFETCH !== "true",
            refetchOnMount: process.env.NEXT_PUBLIC_DISABLE_REFETCH !== "true",
            refetchOnReconnect: process.env.NEXT_PUBLIC_DISABLE_REFETCH !== "true",
          },
        },
      },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  /**
   * Set headers or status code when doing SSR
   */
  // responseMeta(opts) {
  //   const ctx = opts.ctx as SSRContext;

  //   if (ctx.status) {
  //     // If HTTP status set, propagate that
  //     return {
  //       status: ctx.status,
  //     };
  //   }

  //   const error = opts.clientErrors[0];
  //   if (error) {
  //     // Propagate http first error from API calls
  //     return {
  //       status: error.data?.httpStatus ?? 500,
  //     };
  //   }

  //   // for app caching with SSR see https://trpc.io/docs/caching

  //   return {};
  // },
});

export type IRouterInput = inferRouterInputs<WebRouter>;
export type IRouterOutput = inferRouterOutputs<WebRouter>;
