import { convertToArray } from "@archetype/utils";
import { isNaN } from "lodash";
import { DateTime } from "luxon";
import { z } from "zod";

import { createFileLogger } from "../../../logger";
import type { IColumnType } from "../../../schemas/dataModel/Column";
import {
  FileEntityColumnValue,
  type IEntityColumnValue,
  type IFileEntityColumnValue,
} from "../../../schemas/dataModel/EntityValue";

const logger = createFileLogger("storedColumnValue");

function parseStoredBooleanValue(s: string): boolean | undefined {
  const lowerCasedS = s.toLowerCase();

  if (["yes", "1", "true"].includes(lowerCasedS)) {
    return true;
  }

  if (["no", "0", "false"].includes(lowerCasedS)) {
    return false;
  }

  return;
}

export function parseStoredDateValue(s: string): DateTime | undefined {
  const date = DateTime.fromISO(s);

  if (!date.isValid) {
    return undefined;
  }

  return date;
}

export function parseStoredTimestampValue(s: string): DateTime | undefined {
  let ts = DateTime.fromMillis(Number(s));

  // for backward compatibility
  if (!ts.isValid) {
    ts = DateTime.fromISO(s);
  }

  if (!ts.isValid) {
    return undefined;
  }

  return ts;
}

//todo alon: maybe a more elegant way to handle this?
export function parseStoredFilesValue(storedValue: string): Array<IFileEntityColumnValue> {
  if (storedValue === "") {
    return [];
  }

  try {
    return z.array(FileEntityColumnValue).parse(JSON.parse(storedValue));
  } catch (e) {
    logger.error("Error parsing stored files value", { storedValue, error: e });

    return [];
  }
}

export function storedColumnValueToEntityColumnValue(
  storedValue: string | Date | number | boolean | string[] | null | undefined,
  columnType: IColumnType,
): IEntityColumnValue {
  if (storedValue == null) {
    return { type: "null" };
  }

  switch (columnType.type) {
    case "longText":
    case "shortText":
    case "email":
    case "statusEnum":
    case "geolocation":
    case "phone": {
      return {
        type: "string",
        value: String(storedValue),
      };
    }
    case "enum": {
      return {
        type: "array",
        value: convertToArray(storedValue).map((val: unknown) => String(val)),
      };
    }
    case "url": {
      return {
        type: "string",
        value: String(storedValue),
      };
    }
    case "number": {
      const numberValue = Number(storedValue);

      if (isNaN(numberValue)) {
        return { type: "null" };
      }

      return {
        type: "number",
        value: Number(storedValue),
      };
    }
    case "boolean": {
      if (typeof storedValue === "boolean") {
        return {
          type: "boolean",
          value: storedValue,
        };
      }
      const parsedBool = parseStoredBooleanValue(String(storedValue));

      return parsedBool == null
        ? { type: "null" }
        : {
            type: "boolean",
            value: parsedBool,
          };
    }
    case "date": {
      if (storedValue instanceof Date) {
        return {
          type: "date",
          value: DateTime.fromJSDate(storedValue).setZone("utc", { keepLocalTime: true }).toString(),
        };
      }

      return {
        type: "date",
        value: String(storedValue),
      };
    }
    case "timestamp": {
      if (storedValue instanceof Date) {
        return {
          type: "timestamp",
          value: DateTime.fromJSDate(storedValue).toString(),
        };
      }

      return {
        type: "timestamp",
        value: String(storedValue),
      };
    }
    case "file": {
      const filesValue = parseStoredFilesValue(String(storedValue));

      return {
        type: "file",
        value: filesValue,
      };
    }
  }
}
