import React, { createContext, useContext } from "react";

import type { IActionDraftServiceProps } from "./useActionDraftService";
import { type IActionDraftService, useActionDraftService } from "./useActionDraftService";

const ActionDraftServiceContext = createContext<IActionDraftService | undefined>(undefined);

export const ActionDraftServiceProvider: React.FC<React.PropsWithChildren<IActionDraftServiceProps>> = ({
  children,
  versionType,
  action,
  form,
  entityId,
  defaultFieldValuesForCreate,
}) => {
  const draftService = useActionDraftService({
    versionType,
    action,
    form,
    entityId,
    defaultFieldValuesForCreate,
  });

  return <ActionDraftServiceContext.Provider value={draftService}>{children}</ActionDraftServiceContext.Provider>;
};

export const useActionDraftServiceContext = (): IActionDraftService => {
  const context = useContext(ActionDraftServiceContext);

  if (context === undefined) {
    throw new Error("useActionDraftServiceContext must be used within an ActionDraftServiceProvider");
  }

  return context;
};
