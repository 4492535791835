import type { IEntityType } from "@archetype/dsl";
import type { IStateId } from "@archetype/ids";
import { forEach } from "@archetype/utils";

import { createFileLogger } from "../../../logger";
import type { ILoadedCrossColumnAndConditions } from "../../loadedQuery";
import type { IAuthorizationsByEntityScope, IAuthorizedStates } from "../types";

const logger = createFileLogger("query/authorization/queries/state");

export const buildStateColumnFilter = (
  entityType: IEntityType,
  stateIds: IStateId[],
): ILoadedCrossColumnAndConditions | undefined => {
  const stateColumn = entityType.statusColumn;

  if (stateColumn == null) {
    logger.error(
      new Error(
        `Attempting to authorize by state ID for an entity type without a status column, id: ${entityType.id}, versionId: ${entityType.versionId}`,
      ),
    );

    return undefined;
  }

  return {
    type: "and",
    perColumn: {
      [stateColumn]: {
        type: "or",
        rawOrConditions: {
          eq: stateIds.map((stateId) => ({
            type: "value",
            value: {
              type: "string",
              value: stateId as string,
            },
          })),
        },
        oredAndConditions: [],
      },
    },
    andedRelatedToFilters: [],
    andedCrossColumnOrConditions: [],
  };
};

export const getAuthorizedStates = (
  deduplicatedAuthorizationsIndices: IAuthorizationsByEntityScope<number>,
  authorizationResults: boolean[],
): IAuthorizedStates => {
  const authorizedStateIds: IStateId[] = [];

  forEach(deduplicatedAuthorizationsIndices.byStateId, (indices, stateId) => {
    if (indices.some((index) => authorizationResults[index] ?? false)) {
      authorizedStateIds.push(stateId);
    }
  });

  const isAnyStateAuthorized = deduplicatedAuthorizationsIndices.anyState.some(
    (index) => authorizationResults[index] ?? false,
  );
  const isActivityLogAuthorized = deduplicatedAuthorizationsIndices.activityLog.some(
    (index) => authorizationResults[index] ?? false,
  );

  return {
    isAnyStateAuthorized,
    isActivityLogAuthorized,
    authorizedStateIds,
  };
};
