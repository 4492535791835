import { type IVersionType, VersionType } from "@archetype/dsl";
import { FileEntityColumnValue } from "@archetype/dsl";
import type { IColumnId, IEntityId, IEntityTypeId, IOrganizationId, IUserId } from "@archetype/ids";
import { z } from "zod";

// file's state is determined by its url
// temp files are stored in the user directory because we don't have an entity id at that point
// once the entity id is available, we move the file to the entity directory and they are considered as final
export enum ColumnFileState {
  temp = "temp",
  final = "final",
}

const versionTypeRegex = VersionType.options.join("|");

const allowedBaseDomains = (process.env.NEXT_PUBLIC_ALLOWED_CLOUD_STORAGE_BASE_DOMAINS ?? "").split(",").join("|");

const getColumnFileState = (fileUrl: IColumnFileUrl): ColumnFileState => {
  if (fileUrl.includes(`/${ColumnFileState.temp}/`)) {
    return ColumnFileState.temp;
  }

  return ColumnFileState.final;
};

const extractColumnFileUrlParts = (
  fileUrl: IColumnFileUrl,
): {
  baseUrl: string;
  state: ColumnFileState;
  versionType: IVersionType;
  organizationId: IOrganizationId;
  entityTypeId: IEntityTypeId;
  columnId: IColumnId;
  fileName: string;
  userId?: IUserId | undefined;
  entityId?: IEntityId | undefined;
} => {
  const fileState = getColumnFileState(fileUrl);

  if (fileState === ColumnFileState.temp) {
    const match = fileUrl.match(tempColumnFileUrlPattern);

    if (!match) {
      throw new Error("Invalid temp file URL format");
    }
    const [baseUrl, versionType, organizationId, userId, entityTypeId, columnId, fileName] = match as [
      string,
      IVersionType,
      IOrganizationId,
      IUserId,
      IEntityTypeId,
      IColumnId,
      string,
    ];

    return {
      baseUrl,
      state: ColumnFileState.temp,
      versionType: versionType,
      organizationId: organizationId,
      userId: userId,
      entityTypeId: entityTypeId,
      columnId: columnId,
      fileName: fileName,
    };
  }
  const match = fileUrl.match(finalColumnFileUrlPattern);

  if (!match) {
    throw new Error("Invalid final file URL format");
  }
  const [baseUrl, versionType, organizationId, entityTypeId, entityId, columnId, fileName] = match as [
    string,
    IVersionType,
    IOrganizationId,
    IEntityTypeId,
    IEntityId,
    IColumnId,
    string,
  ];

  return {
    baseUrl,
    state: ColumnFileState.final,
    versionType: versionType,
    organizationId: organizationId,
    entityTypeId: entityTypeId,
    entityId: entityId,
    columnId: columnId,
    fileName: fileName,
  };
};

export const tempColumnFileUrlPattern = new RegExp(
  `^https?://(?:${allowedBaseDomains})/uploads/(${versionTypeRegex})/([^/]+)/([^/]+)/${ColumnFileState.temp}/([^/]+)/([^/]+)/[^/]+$`,
);

export const finalColumnFileUrlPattern = new RegExp(
  `^https?://(?:${allowedBaseDomains})/uploads/(${versionTypeRegex})/([^/]+)/([^/]+)/([^/]+)/([^/]+)/[^/]+$`,
);

// don't change these schemas without migrating the cloud storage files, otherwise users won't be able to delete files
export const TempColumnFileUrl = z
  .string()
  .regex(tempColumnFileUrlPattern, { message: "Invalid temp file column URL or path" });

export const FinalColumnFileUrl = z
  .string()
  .regex(finalColumnFileUrlPattern, { message: "Invalid persisted file column URL or path" });

export const ColumnFileUrl = z.union([TempColumnFileUrl, FinalColumnFileUrl]);

export type IColumnFileUrl = z.infer<typeof ColumnFileUrl>;

export const fileColumnValidator = (entityId?: IEntityId): z.ZodTypeAny =>
  z.array(
    FileEntityColumnValue.extend({
      fileUrl: ColumnFileUrl.refine(
        (fileUrl) => {
          if (getColumnFileState(fileUrl) === ColumnFileState.temp || typeof entityId === "undefined") {
            return true;
          }
          const { entityId: urlEntityId } = extractColumnFileUrlParts(fileUrl);

          return urlEntityId === entityId;
        },
        {
          message: "the entity id in the file url does not match the entityId",
        },
      ),
    }),
  );

export function generateColumnFileRelativeUrl({
  fileState,
  versionType,
  organizationId,
  entityTypeId,
  columnId,
  fileName,
  userId,
  entityId,
}: {
  fileState: ColumnFileState;
  versionType: IVersionType;
  organizationId: IOrganizationId;
  entityTypeId: IEntityTypeId;
  columnId: string;
  fileName: string;
  userId: IUserId;
  entityId: IEntityId | null;
}): string {
  switch (fileState) {
    case ColumnFileState.temp: {
      return `uploads/${versionType}/${organizationId}/${userId}/${ColumnFileState.temp}/${entityTypeId}/${columnId}/${fileName}`;
    }
    case ColumnFileState.final: {
      if (entityId === null) {
        throw new Error("entityId is required for final files");
      }

      return `uploads/${versionType}/${organizationId}/${entityTypeId}/${entityId}/${columnId}/${fileName}`;
    }
  }
}

export function authorizeColumnFile({
  fileUrl,
  organizationId,
  entityTypeId,
  userId,
}: {
  fileUrl: IColumnFileUrl;
  organizationId: IOrganizationId;
  entityTypeId: IEntityTypeId;
  userId: IUserId | undefined;
}): void {
  const {
    organizationId: urlOrganizationId,
    userId: urlUserId,
    entityTypeId: urlEntityTypeId,
  } = extractColumnFileUrlParts(fileUrl);

  if (getColumnFileState(fileUrl) === ColumnFileState.temp) {
    if (urlOrganizationId !== organizationId || urlUserId !== userId) {
      throw new Error("File does not belong to the user");
    }
  }

  if (urlEntityTypeId !== entityTypeId) {
    throw new Error("File does not belong to the entity type");
  }
}
