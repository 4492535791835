import type { ICloudStorage } from "../index";
import { CloudStorageVendor } from "../index";
import { VercelCloudStorage } from "./vendors/vercel";

export function createCloudStorageClient(vendor?: CloudStorageVendor): ICloudStorage {
  if (typeof window === "undefined") {
    const allowedDomains = process.env.NEXT_PUBLIC_ALLOWED_CLOUD_STORAGE_BASE_DOMAINS?.split(",").map((d) => d.trim());

    if (!allowedDomains || allowedDomains.length === 0) {
      throw new Error("NEXT_PUBLIC_ALLOWED_CLOUD_STORAGE_BASE_DOMAINS is not defined or empty");
    }

    const isValidDomain = (domain: string): boolean =>
      /^(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(\.[a-zA-Z0-9-]{1,63})*\.[a-zA-Z]{2,}$/.test(domain);

    if (!allowedDomains.every(isValidDomain)) {
      throw new Error("NEXT_PUBLIC_ALLOWED_CLOUD_STORAGE_BASE_DOMAINS contains invalid domain(s)");
    }
  }

  switch (vendor) {
    case CloudStorageVendor.vercel:
    case undefined: {
      return new VercelCloudStorage();
    }
  }
}
