import { z } from "zod";

export const EntityTypeUploadSpec = z.object({
  entityName: z.string(),
  entityColumns: z.array(
    z.object({ name: z.string(), type: z.enum(["date", "timestamp", "string", "boolean", "number"]) }),
  ),
  entityColumnsMostLikelyToBeUsedAsIdByOrder: z.array(z.string()),
  entityColumnMostLikelyToBeUsedAsTitle: z.string(),
});
export type IEntityTypeUploadSpec = z.infer<typeof EntityTypeUploadSpec>;

export const EntityUploadSpec = z.object({
  targetEntity: z.string(),
  numberOfOccurrencesInDataset: z.number(),
  occurrencesColumnsMaps: z
    .object({
      columnsMap: z.array(z.object({ datasetColumnName: z.string(), targetEntityColumnName: z.string() })),
    })
    .array(),
});
export type IEntityUploadSpec = z.infer<typeof EntityUploadSpec>;
//
export const RelationUploadSpec = z.object({
  entityA: z.string(),
  entityB: z.string(),
  nameFromAToB: z.string(),
  nameFromBToA: z.string(),
  cardinalityOnA: z.enum(["one", "many"]),
  cardinalityOnB: z.enum(["one", "many"]),
});
export type IRelationUploadSpec = z.infer<typeof RelationUploadSpec>;

export const DataModelUploadSpecs = z.object({
  entityTypes: EntityTypeUploadSpec.array(),
  relations: RelationUploadSpec.array(),
});
export type IDataModelUploadSpecs = z.infer<typeof DataModelUploadSpecs>;
