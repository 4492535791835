import type { IUserId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { cn, Skeleton } from "@archetype/ui";
import React from "react";

interface IUserNameById {
  id: IUserId;
  className?: string;
}

export const UserNameById: React.FC<IUserNameById> = ({ id, className }) => {
  const { data: userQuery } = trpc.users.byUserId.useQuery({
    id,
  });

  if (userQuery === undefined) {
    return (
      <span className="inline-block">
        <Skeleton className={cn("h-4 w-16", className)} />
      </span>
    );
    // return <Skeleton className="rounded-full">First last name</Skeleton>;
  }

  return (
    <span className={cn("font-medium", className)}>
      {userQuery.clerkUser.firstName} {userQuery.clerkUser.lastName}
    </span>
  );
};
