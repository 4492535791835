import type { IMinimalEntityWithFields, IRelationRecord } from "@archetype/dsl";
import { computeColumnViewFieldId, FieldValueParser } from "@archetype/dsl";
import type { IColumnId, IEntityId } from "@archetype/ids";
import { forEach, groupByNoUndefined, mapValues } from "@archetype/utils";

export type IRelationMap = Record<IEntityId, IEntityId[]>;
export type IRelationMaps = {
  map: IRelationMap;
  backrefMap: IRelationMap;
};

export function generateEntityRelationMapFromRecords(relationRecords: IRelationRecord[]): IRelationMaps {
  const relationMap: IRelationMap = mapValues(
    groupByNoUndefined(relationRecords, (r) => r.valueOnA),
    (records) => records.map((r) => r.valueOnB as IEntityId),
  );
  const relationMapReversed: IRelationMap = mapValues(
    groupByNoUndefined(relationRecords, (r) => r.valueOnB),
    (records) => records.map((r) => r.valueOnA as IEntityId),
  );

  return {
    map: relationMap,
    backrefMap: relationMapReversed,
  };
}

export function generateEntityRelationMapUsingFK({
  entityTypeARelationColumn,
  entityTypeAEntities,
  entityTypeBRelationColumn,
  entityTypeBEntities,
}: {
  entityTypeARelationColumn: IColumnId;
  entityTypeAEntities: IMinimalEntityWithFields[];
  entityTypeBRelationColumn: IColumnId;
  entityTypeBEntities: IMinimalEntityWithFields[];
}): IRelationMaps {
  // index by relation column value
  const entityIdsByRelationColumn: Record<string, IEntityId[]> = {};
  const otherEntityIdsByRelationColumn: Record<string, IEntityId[]> = {};

  const entityTypeARelationFieldId = computeColumnViewFieldId(entityTypeARelationColumn);
  const entityTypeBRelationFieldId = computeColumnViewFieldId(entityTypeBRelationColumn);

  entityTypeAEntities.forEach((entity) => {
    const relationColumnValue = FieldValueParser.toString(entity.fields[entityTypeARelationFieldId]);

    if (relationColumnValue == null) {
      return;
    }

    const currEntities = entityIdsByRelationColumn[relationColumnValue] ?? [];

    currEntities.push(entity.entityId);
    entityIdsByRelationColumn[relationColumnValue] = currEntities;
  });

  entityTypeBEntities.forEach((otherEntity) => {
    const relationColumnOtherValue = FieldValueParser.toString(otherEntity.fields[entityTypeBRelationFieldId]);

    if (relationColumnOtherValue == null) {
      return;
    }

    const currOtherEntities = otherEntityIdsByRelationColumn[relationColumnOtherValue] ?? [];

    currOtherEntities.push(otherEntity.entityId);
    otherEntityIdsByRelationColumn[relationColumnOtherValue] = currOtherEntities;
  });

  const relationMap: IRelationMap = {};
  const otherRelationMap: IRelationMap = {};

  forEach(entityIdsByRelationColumn, (entityIds, relationColumnValue) => {
    const otherEntityIds = otherEntityIdsByRelationColumn[relationColumnValue] ?? [];

    entityIds.forEach((entityId) => {
      relationMap[entityId] = otherEntityIds;
    });
  });
  forEach(otherEntityIdsByRelationColumn, (otherEntityIds, relationColumnValue) => {
    const entityIds = entityIdsByRelationColumn[relationColumnValue] ?? [];

    otherEntityIds.forEach((otherEntityId) => {
      otherRelationMap[otherEntityId] = entityIds;
    });
  });

  return {
    map: relationMap,
    backrefMap: otherRelationMap,
  };
}
