import type { ILoadedViewField } from "@archetype/core";
import type { IDataTableHeaderMenu } from "@archetype/data-table";
import { Header } from "@archetype/data-table";
import type { IEntityTypeCore } from "@archetype/dsl";
import { isAIConfig, isLiveAutofill, isLogicConfig, isLookupConfig } from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";
import { type IIconNames, useMemoDeepCompare } from "@archetype/ui";
import React from "react";

import { getIconForViewField } from "../entityType/ColumnTypeUtils";

interface IEntityHeaderWrapper {
  viewField: ILoadedViewField;
  menuRenderer?: IDataTableHeaderMenu;
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>> | undefined;
}

export function EntityHeaderWrapper({
  viewField,
  menuRenderer,
  allEntityTypes,
}: IEntityHeaderWrapper): React.ReactNode {
  const { autofill, displayName } = viewField;

  const rightIcon = useMemoDeepCompare(() => {
    if (autofill == null) {
      return undefined;
    }

    if (!isLiveAutofill(autofill)) {
      return undefined;
    }

    if (isAIConfig(autofill.config)) {
      return {
        icon: "sparkles" as IIconNames,
        className: "text-purple-500",
        tooltip: "Generated automatically by AI",
      };
    }

    if (isLogicConfig(autofill.config)) {
      return {
        icon: "sigma" as IIconNames,
        tooltip: "Generated automatically by Logic",
      };
    }

    if (isLookupConfig(autofill.config)) {
      return {
        icon: "search" as IIconNames,
        tooltip: "Generated automatically by Lookup",
      };
    }
  }, [autofill]);

  return (
    <Header
      allowResize={true}
      columnId={viewField.id}
      label={displayName}
      leftIcon={{
        icon: getIconForViewField(viewField, allEntityTypes ?? {}),
      }}
      menu={menuRenderer}
      rightIcon={rightIcon}
    />
  );
}
