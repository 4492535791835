import { EntityCommentId, EntityId, UserId } from "@archetype/ids";
import { z } from "zod";

export const EntityComment = z.object({
  id: EntityCommentId,

  entityId: EntityId,

  author: z.discriminatedUnion("type", [
    z.object({
      type: z.literal("user"),
      userId: UserId,
    }),
    z.object({
      type: z.literal("aiAgent"),
      aiAgentId: z.string(),
    }),
  ]),

  sentAt: z.date(),

  // TODO: add ability to tag users and other entities and notify in an inbox
  commentText: z.string(),
});
export type IEntityComment = z.infer<typeof EntityComment>;
