import type { IStateId } from "@archetype/ids";
import { forEach, groupByNoUndefined, mapValues } from "@archetype/utils";
import { max, maxBy, range, values } from "lodash";

import { pathsToStates } from "./pathsToStates";
import type { IMinimalStateMachine } from "./types";

/**
 * This logic takes into account if there are 2 (non-looping i.e. valid) paths to a state,
 * it will be in the latter layer instead of being in the fastest possible, so that other states leading to it are before.
 */
export const stateLayerOrdering = (stateMachine: IMinimalStateMachine): IStateId[][] => {
  if (stateMachine.stateTransitions.length === 0) {
    return [];
  }

  const computedPathsToStates = pathsToStates(stateMachine, false);

  // Precompute only once
  const longestPathsLengthToStates = mapValues(
    computedPathsToStates,
    (paths) => maxBy(paths, (p) => p.length)?.length ?? 0,
  );

  // Initialize all layers
  const maxPathLengthForAllStates = max(values(longestPathsLengthToStates)) ?? 0;
  const stateLayers: IStateId[][] = range(maxPathLengthForAllStates + 1).map(() => []);

  // Add states to layers
  forEach(longestPathsLengthToStates, (maxLengthForState, state) => {
    stateLayers[maxLengthForState]?.push(state);
  });

  return stateLayers;
};

export const stateLayerOrderingFastestVisited = (stateMachine: IMinimalStateMachine): IStateId[][] => {
  const stateLayers: IStateId[][] = [];

  const {
    initialState: { state: initialState },
    stateTransitions,
  } = stateMachine;

  const transitionsByFromState = groupByNoUndefined(stateTransitions, (t) => t.from);

  const visitedStates = new Set<IStateId>();
  // const toVisit: string[] = [initialState.state];
  const toVisit: Array<{ state: IStateId; layerIndex: number }> = [{ state: initialState, layerIndex: 0 }];

  while (toVisit.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe with logic
    const { state, layerIndex } = toVisit.shift()!;

    if (visitedStates.has(state)) {
      continue;
    }

    visitedStates.add(state);

    if (stateLayers[layerIndex] == null) {
      stateLayers[layerIndex] = [];
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe with logic
    stateLayers[layerIndex]!.push(state);

    const transitions = transitionsByFromState[state];

    toVisit.push(...(transitions ?? []).map((t) => ({ state: t.to, layerIndex: layerIndex + 1 })));
  }

  return stateLayers;
};

export const stateOrdering = (stateMachine: IMinimalStateMachine): IStateId[] =>
  stateLayerOrdering(stateMachine).flat();
