import {
  ActionId,
  EntityId,
  EntityTypeId,
  InputSemanticId,
  LayoutConfigurationId,
  ModuleInstanceVersionId,
  OutputSemanticId,
  RelationId,
  SemanticInputsRecord,
  SemanticOutputsRecord,
  ViewFieldId,
} from "@archetype/ids";
import type { IMergedObject } from "@archetype/utils";
import { z } from "zod";

import { DataLoadingQuery } from "../dataModel/DataLoadingQuery";
import { RelationDirection } from "../dataModel/RelationBase";
import { ViewFieldValue } from "../dataModel/ViewFieldValue";

// Runtime component props types ————————————————————————————————————————————————————————

const SingleParameterType = z.object({
  /**
   * As an input, describes if the component can be rendered properly when passed a null value for the parameter.
   *    This is notably different from the parameter being optional:
   *    optional means there may not be a different component outputting and passing that value, while nullable means it can pass `null`
   *    Additionally, we may want to semantically render them differently in component implementation, e.g. nullable is an empty state or disabled, while optional is more of a config that a feature is absent
   *    And finally, nullable only applies to singleEntity.
   *
   * As an output, describes whether the component may output a null value depending on some UI state, user intereaction or else.
   */
  nullable: z.boolean(),
});

const MultiParameterType = z.object({
  // Note to self: not 100% certain this is useful, but not bad to have
  constraints: z.object({
    minNumberElement: z.number().int().gte(0),
    maxNumberElements: z.nullable(z.number().int().gte(0)),
  }),
});

const MultiQueryParameterType = z.object({});

const ActionSelectedParameterType = z.object({});

// Runtime component props values ————————————————————————————————————————————————————————

export const RuntimeSingleEntityValue = z.object({
  type: z.literal("singleEntityValue"),
  // Should not be necessary here but only because we need the entityTypeId to load an entity, the entityId is not enough
  entityTypeId: EntityTypeId,
  entityId: EntityId,
  highlightedViewFieldId: ViewFieldId.optional(),
});

// export const RuntimeSingleEntityFullValue = z.object({
//   type: z.literal("singleEntityValue"),
//   // Should not be necessary here but only because we need the entityTypeId to load an entity, the entityId is not enough
//   entityTypeId: EntityTypeId,
//   entity: EntityId,
//   fullEntity: Entity,
//   highlightedColumnId: EntityTypeColumnId.optional(),
// });

export const RuntimeMultiEntityValue = z.object({
  type: z.literal("multiEntityValue"),
  // Should not be necessary here but only because we need the entityTypeId to load an entity, the entityId is not enough
  entityTypeId: EntityTypeId,
  entityIds: z.array(EntityId),
});

export const RuntimeMultiEntityQueryValue = z.object({
  type: z.literal("multiEntityQueryValue"),
  dataLoadingQuery: DataLoadingQuery,
});

export const RuntimeActionSelectedParameterValue = z.object({
  type: z.literal("selectedAction"),
  actionId: ActionId,
  entityTypeId: EntityTypeId,
  defaultValues: z.record(ViewFieldId, ViewFieldValue.optional()).optional(),
  highlightedViewFieldId: ViewFieldId.optional(),
});

export type ISemanticInputTypesRecord<
  SingleItemType extends z.AnyZodObject,
  MultiItemQueryType extends z.AnyZodObject,
> = z.ZodObject<{
  singleEntityDataInput: z.ZodOptional<z.ZodNullable<SingleItemType>>;
  multiEntityQueryDataInput: z.ZodOptional<MultiItemQueryType>;
  primarySingleEntityDataInput: z.ZodOptional<SingleItemType>;
  secondarySingleEntityDataInput: z.ZodOptional<SingleItemType>;
}>;

/**
 *
 * @param itemType the zod type to extend with
 * @returns a record type with the input semantic ids as keys, and as values constraints for the corresponding type, and the extended type
 */
export function SemanticInputTypesRecord<ItemType extends z.AnyZodObject>(
  itemType: ItemType,
): ISemanticInputTypesRecord<
  IMergedObject<typeof SingleParameterType, ItemType>,
  IMergedObject<typeof MultiQueryParameterType, ItemType>
> {
  return SemanticInputsRecord(
    SingleParameterType.merge(itemType),
    MultiParameterType.merge(itemType),
    MultiQueryParameterType.merge(itemType),
  ).partial();
}

export type ISemanticOutputTypesRecord<
  SingleItemType extends z.AnyZodObject,
  MultiItemType extends z.AnyZodObject,
  MultiItemQueryType extends z.AnyZodObject,
  ActionSelectedType extends z.AnyZodObject,
> = z.ZodObject<{
  userSelectedSingleEntity: z.ZodOptional<z.ZodNullable<SingleItemType>>;
  userSelectedLinkedEntity: z.ZodOptional<z.ZodNullable<SingleItemType>>;
  userSelectedMultiEntity: z.ZodOptional<MultiItemType>;
  filteredMultiEntityQuery: z.ZodOptional<MultiItemQueryType>;
  actionSelected: z.ZodOptional<ActionSelectedType>;
}>;

/**
 *
 * @param itemType the zod type to extend with
 * @returns a record type with the input semantic ids as keys, and as values constraints for the corresponding type, and the extended type
 */

export function SemanticOutputTypesRecord<ItemType extends z.AnyZodObject, ActionItemType extends z.AnyZodObject>(
  itemType: ItemType,
  actionItemType: ActionItemType,
): ISemanticOutputTypesRecord<
  IMergedObject<typeof SingleParameterType, ItemType>,
  IMergedObject<typeof MultiParameterType, ItemType>,
  IMergedObject<typeof MultiQueryParameterType, ItemType>,
  IMergedObject<typeof ActionSelectedParameterType, ActionItemType>
> {
  return SemanticOutputsRecord(
    SingleParameterType.merge(itemType),
    MultiParameterType.merge(itemType),
    MultiQueryParameterType.merge(itemType),
    ActionSelectedParameterType.merge(actionItemType),
  ).partial();
}

export type IRuntimeSingleEntityValue = z.infer<typeof RuntimeSingleEntityValue>;
export type IRuntimeMultiEntityValue = z.infer<typeof RuntimeMultiEntityValue>;
export type IRuntimeMultiEntityQueryValue = z.infer<typeof RuntimeMultiEntityQueryValue>;
export type IRuntimeActionSelectedParameterValue = z.infer<typeof RuntimeActionSelectedParameterValue>;

// Runtime module props —————————————————————————————————————————————————————————————

export const SingleEntityModuleParameterType = z.object({
  type: z.literal("singleEntity"),
  entityType: LayoutConfigurationId,
});

export const MultiEntityListModuleParameterType = z.object({
  type: z.literal("multiEntity"),
  /**
   * This describes a starting set form which to render the module, insteat of rendering it on all entity types by defaults
   * Filters expressed in the module features are still respected, this is in addition.
   * The value can generally be a DSL query (e.g. when executing a join), or a list of entity ids
   */
  entityType: LayoutConfigurationId,
});

export const MultiEntityQueryModuleParameterType = z.object({
  type: z.literal("multiEntityQuery"),
  /**
   * This describes a starting set form which to render the module, insteat of rendering it on all entity types by defaults
   * Filters expressed in the module features are still respected, this is in addition.
   * The value can generally be a DSL query (e.g. when executing a join), or a list of entity ids
   */
  entityType: LayoutConfigurationId,
});

export const ActionSelectedModuleParameterType = z.object({
  type: z.literal("actionSelected"),
});

export const ModuleParameterConfig = z.object({
  inputModuleInstance: ModuleInstanceVersionId,
  inputParameterId: InputSemanticId,
  outputModuleInstance: ModuleInstanceVersionId,
  outputParameterId: OutputSemanticId,
  /**
   * If configured, acts as a transformation between the output and input
   * There are constraints on the cardinality of the join and the inputs/outputs that are based on what's expected by the input
   */
  relationInfo: z
    .object({
      relationTypeId: RelationId,
      /**
       * Output param to input param direction
       * Can be evident for non self referential joins, but needs to be explicit for self referential joins
       */
      direction: RelationDirection,
    })
    .optional(),
});

export const ModuleParameterOutputValuesRecord = SemanticOutputsRecord(
  RuntimeSingleEntityValue,
  RuntimeMultiEntityValue,
  RuntimeMultiEntityQueryValue,
  RuntimeActionSelectedParameterValue,
).partial();

// export const ModuleParameterOutputFullValuesRecord = SemanticOutputsRecord(
//   RuntimeSingleEntityFullValue,
//   RuntimeMultiEntityValue,
//   RuntimeMultiEntityQueryValue,
//   RuntimeActionSelectedParameterValue,
// ).partial();

export type IModuleParameterOutputValuesRecord = z.infer<typeof ModuleParameterOutputValuesRecord>;
// export type IModuleParameterOutputFullValuesRecord = z.infer<typeof ModuleParameterOutputFullValuesRecord>;
