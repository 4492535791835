import type {
  IEntityType,
  IKnownDataViewType,
  IStructuredFeature,
  IViewFeature,
  IViewFeatureHierarchy,
} from "@archetype/dsl";
import {
  ActionAvailableFeature,
  DataDisplayFeature,
  DataLoadingFeature,
  DataViewFeature,
  FilterFeature,
  FreeTextConfigurationFeature,
  makeViewFeatureHierarchy,
  MetricSubviewFeature,
  SearchFeature,
} from "@archetype/dsl";
import type { IActionId, IColumnId, IEntityTypeId, IFeatureId, IRelationId, IStateId } from "@archetype/ids";
import { isNonNullable, keyByNoUndefined, mapValues, visit } from "@archetype/utils";

import type { IReadableIdMappings } from "../dataModel/inferrenceTypings";
import {
  filterExistingFeaturesRelevantForNewOnes,
  optimisticReadableIdentifier,
  optimisticReadableIdentifierFromString,
} from "../dataModel/utils";
import { createFileLogger } from "../logger";
import type {
  DATA_MODEL_REF_MAPPING_ID_TYPES,
  IEntityTypeReference,
  IIdentifierMapperFunctions,
  IReferencedDataModelIds,
  IStringIdentifierMappings,
} from "./dataModelReferencesMapperTypes";
import { mapperFunctionsFromMappings } from "./dataModelReferencesMapperTypes";

const logger = createFileLogger("mapViewFeaturesDataModelReferences");

const throwingMapActionReference = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  actionOriginIdentifier: IDS["ORIG_ACT_ID"],
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IDS["TARG_ACT_ID"] => {
  const actionId = idMapperFunctions.origToTargActionId(actionOriginIdentifier);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- actually undefinedable because reading from record
  if (actionId == null) {
    const err = `Failed to map action reference ${actionOriginIdentifier}`;

    logger.error(err);
    throw new Error(err);
  }

  return actionId;
};

const mapColumnReference = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  columnOriginIdentifier: IDS["ORIG_COL_ID"],
  parentEntityTypeRef: IEntityTypeReference<IDS>,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IDS["TARG_COL_ID"] | undefined =>
  parentEntityTypeRef.type === "existingFeature"
    ? idMapperFunctions.origToTargColIdOnPreMappedEntityType(
        parentEntityTypeRef.targetEntityIdentifier,
        columnOriginIdentifier,
      )
    : idMapperFunctions.origToTargColId(parentEntityTypeRef.originEntityIdentifier, columnOriginIdentifier);

const throwingMapColumnReference = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  columnOriginIdentifier: IDS["ORIG_COL_ID"],
  parentEntityTypeRef: IEntityTypeReference<IDS> | undefined,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IDS["TARG_COL_ID"] => {
  if (parentEntityTypeRef == null) {
    const err = "No parent entity type reference provided for column mapping";

    logger.error(err);
    throw new Error(err);
  }

  const columnId = mapColumnReference(columnOriginIdentifier, parentEntityTypeRef, idMapperFunctions);

  if (columnId == null) {
    const err = `Failed to map column reference ${columnOriginIdentifier} on entity type ${
      parentEntityTypeRef.type === "existingFeature"
        ? parentEntityTypeRef.targetEntityIdentifier
        : parentEntityTypeRef.originEntityIdentifier
    }`;

    logger.error(err);
    throw new Error(err);
  }

  return columnId;
};

const mapValidColumnReferences = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  columnIds: IDS["ORIG_COL_ID"][],
  parentEntityTypeRef: IEntityTypeReference<IDS> | undefined,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IDS["TARG_COL_ID"][] => {
  if (parentEntityTypeRef == null) {
    const err = "No parent entity type reference provided for column mapping";

    logger.error(err);
    throw new Error(err);
  }

  return columnIds
    .map((readableColumnId) => {
      const columnId = mapColumnReference(readableColumnId, parentEntityTypeRef, idMapperFunctions);

      if (columnId == null) {
        const err = `Failed to map column reference ${readableColumnId} on entity type ${
          parentEntityTypeRef.type === "existingFeature"
            ? parentEntityTypeRef.targetEntityIdentifier
            : parentEntityTypeRef.originEntityIdentifier
        }`;

        logger.error(err);

        return undefined;
      }

      return columnId;
    })
    .filter(isNonNullable);
};

// TODO (julien): make different types for features with readable ids vs. not (with generic?)
/**
 * Should contain readable id, not mapped UUIDs (probably standardized with standardizeReadableIdsInViewFeatures)
 */
export const viewFeaturesWithReadableIdToUuids = (
  newFeatures: Record<string, IViewFeature>,
  /**
   * Should contain UUIDs
   */
  existingFeatures: Record<IFeatureId, IViewFeature>,
  /**
   * Generated
   */
  readableIdMappings: IReadableIdMappings,
): Record<string, IViewFeature> => {
  const stringIdentifierMappings: IStringIdentifierMappings<{
    ORIG_ENT_T_ID: string;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: string;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: string;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: string;
    TARG_REL_ID: IRelationId;
    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    originToTargetIdentifiers: mapValues(readableIdMappings.readableIdsToIds, (entityTypeReadableIds) => ({
      targetIdentifier: entityTypeReadableIds.id,
      columnOriginToTargetIdentifiers: entityTypeReadableIds.columnReadableIdsToIds,
    })),
    preMappedParentColumnMapping: mapValues(
      readableIdMappings.parentExistingEntityReadableIdToIds,
      ({ columnReadableIdsToIds }) => ({
        columnOriginToTargetIdentifiers: columnReadableIdsToIds,
      }),
    ),
    originToTargetActionId: readableIdMappings.actionReadableIdsToIds,
    originToTargetEntityRelationId: readableIdMappings.entityRelationReadableIdsToIds,
    originToTargetStateId: readableIdMappings.stateReadableIdsToIds,
  };

  return mapEntityTypeReferencesInViewFeatures(
    newFeatures,
    existingFeatures,
    mapperFunctionsFromMappings(stringIdentifierMappings),
  );
};

export const viewFeaturesUuidToReadableId = (
  newFeatures: Record<string, IViewFeature>,
  existingFeatures: Record<IFeatureId, IViewFeature>,
  // Probably want to traverse the feature tree once to load only the relevant ones
  allReferencedEntityTypes: Record<IEntityTypeId, IEntityType>,
): Record<string, IViewFeature> => {
  const stringIdentifierMappings: IStringIdentifierMappings<{
    ORIG_ENT_T_ID: string;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: string;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: string;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: string;
    TARG_REL_ID: IRelationId;
    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    originToTargetIdentifiers: mapValues(allReferencedEntityTypes, (entityType) => ({
      targetIdentifier: optimisticReadableIdentifier(entityType) as IEntityTypeId, // Could make it a camelCase if model more performant with it
      columnOriginToTargetIdentifiers: mapValues(
        keyByNoUndefined(entityType.columns, (col) => col.id),
        (column) => optimisticReadableIdentifier(column) as IColumnId,
      ),
    })),
    preMappedParentColumnMapping: {
      // Could add newFeatures here as well
    },
    originToTargetActionId: {},
    originToTargetEntityRelationId: {},
    originToTargetStateId: {},
  };

  return mapEntityTypeReferencesInViewFeatures(
    newFeatures,
    existingFeatures,
    mapperFunctionsFromMappings(stringIdentifierMappings),
  );
};

export const collectDataModelIdInViewFeatures = (
  newFeatures: Record<string, IViewFeature>,
  existingFeatures: Record<IFeatureId, IViewFeature>,
): IReferencedDataModelIds<{
  ORIG_ENT_T_ID: IEntityTypeId;
  TARG_ENT_T_ID: IEntityTypeId;
  ORIG_COL_ID: IColumnId;
  TARG_COL_ID: IColumnId;
  ORIG_ACT_ID: IActionId;
  TARG_ACT_ID: IActionId;
  ORIG_REL_ID: IRelationId;
  TARG_REL_ID: IRelationId;
  ORIG_STATE_ID: IStateId;
  TARG_STATE_ID: IStateId;
}> => {
  const referencedDataModelIds: IReferencedDataModelIds<{
    ORIG_ENT_T_ID: IEntityTypeId;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: IColumnId;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;
    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    newEntityTypes: {},
    preMappedEntityTypes: {},
    newActions: new Set(),
    newEntityRelations: new Set(),
    newStates: new Set(),
  };

  // Actually a no-op here but side effect of collecting the ids (we could make it cleaner with a forEach but not required)
  const idMapperFunctions: IIdentifierMapperFunctions<{
    ORIG_ENT_T_ID: IEntityTypeId;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: IColumnId;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;
    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    origToTargEntityTypeId: (originEntityTypeId: IEntityTypeId) => {
      if (referencedDataModelIds.newEntityTypes[originEntityTypeId] == null) {
        referencedDataModelIds.newEntityTypes[originEntityTypeId] = new Set<IColumnId>();
      }

      // Noop
      return originEntityTypeId;
    },
    origToTargColId: (originEntityTypeId: IEntityTypeId, origColId: IColumnId) => {
      if (referencedDataModelIds.newEntityTypes[originEntityTypeId] == null) {
        referencedDataModelIds.newEntityTypes[originEntityTypeId] = new Set<IColumnId>();
      }

      referencedDataModelIds.newEntityTypes[originEntityTypeId]?.add(origColId);

      // Noop
      return origColId;
    },
    origToTargColIdOnPreMappedEntityType: (preMappedEntityTypeId: IEntityTypeId, origColId: IColumnId) => {
      if (referencedDataModelIds.preMappedEntityTypes[preMappedEntityTypeId] == null) {
        referencedDataModelIds.preMappedEntityTypes[preMappedEntityTypeId] = new Set<IColumnId>();
      }

      referencedDataModelIds.preMappedEntityTypes[preMappedEntityTypeId]?.add(origColId);

      // Noop
      return origColId;
    },
    origToTargActionId: (originActionId: IActionId) => {
      referencedDataModelIds.newActions.add(originActionId);

      // Noop
      return originActionId;
    },
    origToTargEntityRelationId: (originEntityRelationId: IRelationId) => {
      referencedDataModelIds.newEntityRelations.add(originEntityRelationId);

      // Noop
      return originEntityRelationId;
    },
    origToTargStateId: (originStateId: IStateId) => {
      referencedDataModelIds.newStates.add(originStateId);

      // Noop
      return originStateId;
    },
  };

  mapEntityTypeReferencesInViewFeatures(newFeatures, existingFeatures, idMapperFunctions);

  return referencedDataModelIds;
};

export const standardizeReadableIdsInViewFeatures = (
  /**
   * Should contain readable id, not mapped UUIDs
   */
  newFeatures: Record<string, IViewFeature>,
  existingFeatures: Record<IFeatureId, IViewFeature>,
): Record<string, IViewFeature> => {
  // Actually a no-op here but side effect of collecting the ids (we could make it cleaner with a forEach but not required)
  const idMapperFunctions: IIdentifierMapperFunctions<{
    ORIG_ENT_T_ID: IEntityTypeId;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: IColumnId;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;
    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    origToTargEntityTypeId: (originEntityTypeId: IEntityTypeId) =>
      optimisticReadableIdentifierFromString(originEntityTypeId) as IEntityTypeId,
    origToTargColId: (originEntityTypeId: IEntityTypeId, origColId: IColumnId) =>
      optimisticReadableIdentifierFromString(origColId) as IColumnId,
    origToTargColIdOnPreMappedEntityType: (preMappedEntityTypeId: IEntityTypeId, origColId: IColumnId) =>
      optimisticReadableIdentifierFromString(origColId) as IColumnId,
    origToTargActionId: (originActionId: IActionId) =>
      optimisticReadableIdentifierFromString(originActionId) as IActionId,
    origToTargEntityRelationId: (originEntityRelationId: IRelationId) =>
      optimisticReadableIdentifierFromString(originEntityRelationId) as IRelationId,
    origToTargStateId: (originStateId: IStateId) => optimisticReadableIdentifierFromString(originStateId) as IStateId,
  };

  return mapEntityTypeReferencesInViewFeatures(newFeatures, existingFeatures, idMapperFunctions);
};

export const mapEntityTypeReferencesInViewFeatures = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  newFeatures: Record<string, IViewFeature>,
  allExistingFeatures: Record<IFeatureId, IViewFeature>,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): Record<string, IViewFeature> => {
  const relevantExistingFeatures = filterExistingFeaturesRelevantForNewOnes(newFeatures, allExistingFeatures);
  // Feature hierarchy with existing ones as well so that we always pass the parent entity type id when adding a new nested feature
  const featureHierarchies = makeViewFeatureHierarchy({ ...relevantExistingFeatures, ...newFeatures });

  const result: Record<string, IViewFeature> = {};

  featureHierarchies.forEach((featureHierarchy) => {
    mapReferencesFromHierarchy({
      res: result,
      featureHierarchy,
      existingFeatures: relevantExistingFeatures,
      idMapperFunctions,
      parentEntityTypeRef: undefined,
    });
  });

  return result;
};

const mapReferencesFromHierarchy = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>({
  res,
  featureHierarchy,
  existingFeatures,
  idMapperFunctions,
  parentEntityTypeRef,
}: {
  res: Record<string, IViewFeature>;
  featureHierarchy: IViewFeatureHierarchy;
  existingFeatures: Record<IFeatureId, IViewFeature>;
  idMapperFunctions: IIdentifierMapperFunctions<IDS>;
  parentEntityTypeRef: IEntityTypeReference<IDS> | undefined;
}): void => {
  const { currentEntityTypeRef, remappedFeature } = mapReferencesInFeature({
    feature: featureHierarchy.feature,
    existingFeatures,
    idMapperFunctions,
    parentEntityTypeRef,
  });

  if (remappedFeature != null) {
    res[remappedFeature.id] = remappedFeature;
  }

  featureHierarchy.subFeatures.map((subFeatureHierarchy) => {
    mapReferencesFromHierarchy({
      res,
      featureHierarchy: subFeatureHierarchy,
      existingFeatures,
      idMapperFunctions,
      parentEntityTypeRef: currentEntityTypeRef || parentEntityTypeRef,
    });
  });
};

interface IRemappedFeatures<IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES> {
  // Allows to be consistent on how to initialize the maps and define the parent entity type id
  currentEntityTypeRef: IEntityTypeReference<IDS> | undefined;
  // Not remapping the feature if it's already existing
  remappedFeature: IViewFeature | undefined;
}

/**
 *
 * @param result adds the inferred entity types to this result
 * @param parentEntityTypeRef must have a parent if the data model is defined on a parent feature, and it must be present in
 * result already (i.e. traverse as a hierarchy)
 */
const mapReferencesInFeature = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>({
  feature,
  existingFeatures,
  idMapperFunctions,
  parentEntityTypeRef,
}: {
  feature: IViewFeature;
  existingFeatures: Record<IFeatureId, IStructuredFeature>;
  idMapperFunctions: IIdentifierMapperFunctions<IDS>;
  parentEntityTypeRef: IEntityTypeReference<IDS> | undefined;
}): IRemappedFeatures<IDS> => {
  if (existingFeatures[feature.id] != null) {
    // Skipping if existing feature
    if (feature.type === DataViewFeature.shape.type.value) {
      // No remapping but setting the dataModel for child features
      return {
        currentEntityTypeRef: {
          type: "existingFeature" as const,
          targetEntityIdentifier: feature.dataModel,
        },
        remappedFeature: undefined,
      };
    }

    return {
      currentEntityTypeRef: undefined,
      remappedFeature: undefined,
    };
  }

  return visit<IViewFeature, IRemappedFeatures<IDS>>(feature, {
    [DataViewFeature.shape.type.value]: ({ id, type, dataModel, dataViewType }) => ({
      currentEntityTypeRef: {
        type: "newFeature" as const,
        originEntityIdentifier: dataModel,
      },
      remappedFeature: {
        id,
        type,
        dataViewType: visit<IKnownDataViewType, IKnownDataViewType>(dataViewType, {
          list: (x) => x,
          table: (x) => x,
          groupedTable: (x) => x,
          single: (x) => x,
          map: ({ type: targetType, geospatialColumn }) => ({
            type: targetType,
            geospatialColumn: throwingMapColumnReference(
              geospatialColumn,
              { type: "newFeature", originEntityIdentifier: dataModel },
              idMapperFunctions,
            ),
          }),
          board: ({ type: targetType, groupByColumn }) => ({
            type: targetType,
            groupByColumn: throwingMapColumnReference(
              groupByColumn,
              { type: "newFeature", originEntityIdentifier: dataModel },
              idMapperFunctions,
            ),
          }),
          cardList: ({ type: targetType, groupByColumn }) => ({
            type: targetType,
            groupByColumn: throwingMapColumnReference(
              groupByColumn,
              { type: "newFeature", originEntityIdentifier: dataModel },
              idMapperFunctions,
            ),
          }),
        }),
        dataModel: idMapperFunctions.origToTargEntityTypeId(dataModel),
      },
    }),
    [DataDisplayFeature.shape.type.value]: ({ id, type, columns, parent }) => ({
      currentEntityTypeRef: undefined,
      remappedFeature: {
        id,
        type,
        columns: {
          columnIds: mapValidColumnReferences(columns.columnIds, parentEntityTypeRef, idMapperFunctions),
        },
        parent,
      },
    }),
    [SearchFeature.shape.type.value]: ({ id, type, columns, parent }) => ({
      currentEntityTypeRef: undefined,
      remappedFeature: {
        id,
        type,
        columns: {
          columnIds: mapValidColumnReferences(columns.columnIds, parentEntityTypeRef, idMapperFunctions),
        },
        parent,
      },
    }),
    [FilterFeature.shape.type.value]: ({ id, type, columns, parent }) => ({
      currentEntityTypeRef: undefined,
      remappedFeature: {
        id,
        type,
        columns: {
          columnIds: mapValidColumnReferences(columns.columnIds, parentEntityTypeRef, idMapperFunctions),
        },
        parent,
        filterViewType: {},
      },
    }),
    [DataLoadingFeature.shape.type.value]: ({ id, type, constraints, parent }) => ({
      currentEntityTypeRef: undefined,
      remappedFeature: {
        id,
        type,
        parent,
        constraints: {
          filters: constraints.filters?.map(({ column, value }) => ({
            column: throwingMapColumnReference(column, parentEntityTypeRef, idMapperFunctions),
            value,
          })),
          sorts: {
            columnIds: mapValidColumnReferences(constraints.sorts.columnIds, parentEntityTypeRef, idMapperFunctions),
          },
        },
      },
    }),
    [MetricSubviewFeature.shape.type.value]: () => {
      // TODO

      return {
        currentEntityTypeRef: undefined,
        remappedFeature: feature,
      };
    },
    [FreeTextConfigurationFeature.shape.type.value]: () => ({
      currentEntityTypeRef: undefined,
      remappedFeature: feature,
    }),
    [ActionAvailableFeature.shape.type.value]: ({ action, ...typedFeature }) => {
      return {
        currentEntityTypeRef: undefined,
        remappedFeature: {
          action: throwingMapActionReference(action, idMapperFunctions),
          ...typedFeature,
        },
      };
    },
  });
};
