import type { IDataLoadingFilterAndOperator } from "@archetype/dsl";
import type { IIconNames } from "@archetype/ui";
import {
  Badge,
  Checkbox,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Icon,
  Loader,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@archetype/ui";
import type { MouseEventHandler } from "react";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useKeyPress } from "react-use";

import type { IListFilterOptionsGroup } from "./types";

interface IListFilter {
  allowMultiSelect?: boolean;
  defaultSelection?: string[];
  groups: IListFilterOptionsGroup[];
  icon?: IIconNames | Exclude<React.ReactNode, string>;
  isLoading?: boolean;
  loadingComponent?: React.ReactNode;
  noResultsComponent?: React.ReactNode;
  onSubmit: (operator: IDataLoadingFilterAndOperator, value: string[], closePopover?: boolean) => void;
  operator?: IDataLoadingFilterAndOperator;
  title: string;
}

export const ListFilter: React.FC<IListFilter> = (props) => {
  const {
    allowMultiSelect = true,
    defaultSelection,
    groups,
    icon,
    isLoading = false,
    loadingComponent = <Loader className="px-2 py-3" text="Loading..." />,
    noResultsComponent = <CommandEmpty>No results found</CommandEmpty>,
    onSubmit,
    operator = "anyInArray",
    title,
  } = props;

  const [isShiftKeyDown] = useKeyPress("Shift");
  const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultSelection ?? []);

  // Tracks which options were deselected with shift held down
  // These options will not be added back when pressing enter without shift pressed
  // This mimics Linear's behavior
  const [deselectedOptions, setDeselectedOptions] = useState<string[]>([]);

  const flattenedOptions = useMemo(() => groups.flatMap(({ options }) => options), [groups]);

  const handleOptionSelect = useCallback(
    (optionId: string): (() => void) =>
      () => {
        if (!allowMultiSelect) {
          const option = flattenedOptions.find(({ id }) => id === optionId);

          if (option && "value" in option) onSubmit("eq", [option.value]);

          return;
        }

        // Managing selection without closing the popover
        if (isShiftKeyDown) {
          if (selectedOptions.includes(optionId)) setDeselectedOptions((prev) => [...prev, optionId]);

          const newSelection = toggleOption(selectedOptions, optionId);

          setSelectedOptions(newSelection);
          onSubmit(operator, newSelection, false);

          return;
        }

        let newSelection = selectedOptions;

        // Only add back the option if it was not previously deselected
        if (!deselectedOptions.includes(optionId)) {
          if (!selectedOptions.includes(optionId)) {
            newSelection = toggleOption(newSelection, optionId);
            setSelectedOptions(newSelection);
          }
        }

        onSubmit(operator, newSelection);
      },
    [allowMultiSelect, isShiftKeyDown, selectedOptions, deselectedOptions, onSubmit, operator, flattenedOptions],
  );

  const handleCheckboxClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e): void => {
      e.stopPropagation();

      const newSelection = toggleOption(selectedOptions, e.currentTarget.value);

      setSelectedOptions(newSelection);
      onSubmit(operator, newSelection, false);
    },
    [onSubmit, operator, selectedOptions],
  );

  return (
    <Command>
      <div className="relative">
        <CommandInput
          autoFocus
          className={cn(
            "rounded-b-none border-x-0 border-t-0 px-1.5 text-base [&>input]:text-base",
            allowMultiSelect && "pr-6",
          )}
          icon={icon}
          placeholder={title}
        />
        {allowMultiSelect ? (
          <Tooltip>
            <TooltipTrigger className="absolute right-2 top-3.5">
              <Icon name="info" />
            </TooltipTrigger>
            <TooltipContent className="py-2">
              Use&nbsp;
              <kbd>
                <Badge>shift + ↵</Badge>
              </kbd>
              &nbsp;to select multiple
            </TooltipContent>
          </Tooltip>
        ) : null}
      </div>
      <CommandList className="min-w-[300px]">
        {isLoading ? loadingComponent : noResultsComponent}

        {!isLoading &&
          groups.map(({ id, title: heading, options }) => {
            const Wrapper = heading != null ? CommandGroup : Fragment;

            return (
              <Wrapper key={id} {...(heading != null ? { heading } : {})}>
                {options.map((option) => {
                  if ("isSeparator" in option) return <CommandSeparator key={option.id} />;

                  return (
                    <CommandItem
                      key={option.id}
                      className="min-h-9 text-base"
                      value={option.value}
                      onSelect={handleOptionSelect(option.id)}
                    >
                      <div className="flex items-center gap-2">
                        {allowMultiSelect ? (
                          <Checkbox
                            checked={selectedOptions.includes(option.id)}
                            value={option.id}
                            onClick={handleCheckboxClick}
                          />
                        ) : null}
                        {option.component ?? option.value}
                      </div>
                    </CommandItem>
                  );
                })}
              </Wrapper>
            );
          })}
      </CommandList>
    </Command>
  );
};

const toggleOption = (options: string[], optionId: string): string[] => {
  return options.includes(optionId) ? options.filter((id) => id !== optionId) : [...options, optionId];
};
