import { LayoutConfigurationId, OutputSemanticId, SlotId } from "@archetype/ids";
import { z } from "zod";

import { ComponentSemanticSize } from "./common";
import { UncheckedDisplayMetadata } from "./common/DisplayMetadata";
import { SemanticInputTypesRecord, SemanticOutputTypesRecord } from "./config/RuntimeSemanticParameters";

export const LayoutConfigReference = z.object({
  type: z.literal("config"),
  layoutConfigurationId: LayoutConfigurationId,
});

export const OtherSlotOutputReference = z.object({
  type: z.literal("otherSlotOutput"),
  slotId: SlotId,
  outputId: OutputSemanticId,
});

export const EntityConfigReference = z.object({
  ref: z.discriminatedUnion("type", [LayoutConfigReference, OtherSlotOutputReference]),
});

export const Slot = z.object({
  id: SlotId,

  // May be useful to enable suggestions, or could be simplified to a readable id
  displayMetadata: UncheckedDisplayMetadata,

  optional: z.boolean().optional(),

  constraints: z.object({
    /**
     *
     *
     * Can make slot sizes dynamic based on whether other slots have had components fed to them
     * e.g. if there is a row with 2 slots next to each other, can we be responsive if the second one isnt used?
     * Can the slot be responsive in general?
     * Maybe we need more information.
     */
    semanticSize: ComponentSemanticSize,

    /**
     * Superset of inputs of matching components, i.e. a matching component may have fewer inputs,
     * that would mean that some data is ignored.
     */
    inputs: SemanticInputTypesRecord(EntityConfigReference),

    /**
     * Subset of outputs of matching components.
     * i.e. matching components may be able to output more parameters than necessary here.
     */
    outputs: SemanticOutputTypesRecord(
      LayoutConfigReference,
      z.object({
        type: z.literal("actionSelected"),
      }),
    ),
  }),
  // TODO component categories?
});

export type ISlot = z.infer<typeof Slot>;
