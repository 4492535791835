import { z } from "zod";

export const EmptyObject = z.object({});

export const ComponentSemanticSize = z.union([
  z.literal("small-inline"), // Can be inside a cell in a table, TBC if we want that one
  z.literal("sidebar"), // Generally almost full screen vertically but rather narrow, and generally for a filter bar, an entity list, a timeline
  z.literal("split-vertical"), // Roughly half the screen horizontaly and full screen vertically
  z.literal("main"), // For a main view like a table or a map, a large timeline, will occupy most of the screen
  z.literal("medium"), // A metric, a minimap, or chart
  z.literal("split-horizontal"), // Very wide but roughly half the screen vertically
  z.literal("summary"), // Very wide but not very large vertically, good for metrics for example
  z.literal("actions"), // Generally wide, but pretty thin vertically, can be great for some actions
]);

export type IComponentSemanticSize = z.infer<typeof ComponentSemanticSize>;

export const LayoutConstraint = z.object({
  orientation: z.union([z.literal("horizontal"), z.literal("vertical"), z.literal("both"), z.literal("inline")]),
  size: z.array(z.union([z.literal("sm"), z.literal("md"), z.literal("lg")])),
});

/**
 * selectFirst
 * makeSingleElementArray
 * pivotEntity (differentiate between single and multi? Probably enforces cosntraints on the type of join)
 *
 * Describes how a paraneter can be transformed by the module between different slots.
 * i.e. the output of one slot may not be directly the input to another slot, but may require pivoting etc
 * // Note to self: thinking more this may just live in the internal logic of the Module definition, i.e. in code
 *
 */
export const ParameterTransformation = z.discriminatedUnion("type", [
  z.object({
    /**
     * From a `singleEntity` to a `multiEntity` containing only that entity
     */
    type: z.literal("makeSingleElementArray"),
  }),
  z.object({
    /**
     * From a `singleEntity` or a `multiEntity` with any type of join
     */
    type: z.literal("pivotToManyEntity"),
  }),
  z.object({
    /**
     * From a `singleEntity` to a `singleEntity`, enforces that the join is one-to-one.
     * This also means users have to configure (through requirements) both the initial data model, the second one and the join
     * Note to self: TODO: how do we encode and collect those kind of constraints on the data model.
     */
    type: z.literal("pivotToSingleEntity"),
  }),
]);
