import type { IColumnType, IPrimitiveColumnType } from "../../schemas/dataModel/Column";
import type { IEntityColumnValue } from "../../schemas/dataModel/EntityValue";

export const simpleColumnTypeToPrimitiveType = (simpleColumnType: IColumnType): IPrimitiveColumnType => {
  switch (simpleColumnType.type) {
    case "email":
    case "shortText":
    case "longText":
    case "date":
    case "timestamp":
    case "geolocation":
    case "enum":
    case "phone":
    case "url":
    case "statusEnum": {
      return "string";
    }
    case "boolean": {
      return "boolean";
    }
    case "number": {
      return "number";
    }
    case "file": {
      return "file";
    }
  }
};

export const simpleColumnTypeToValueType = (simpleColumnType: IColumnType): IEntityColumnValue["type"] => {
  switch (simpleColumnType.type) {
    case "email":
    case "shortText":
    case "longText":
    case "geolocation":
    case "enum":
    case "statusEnum":
    case "phone": {
      return "string";
    }
    case "url": {
      return "string";
    }
    case "boolean": {
      return "boolean";
    }
    case "number": {
      return "number";
    }
    case "timestamp": {
      return "timestamp";
    }
    case "date": {
      return "date";
    }
    case "file": {
      return "file";
    }
  }
};
