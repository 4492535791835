import {
  DataModelMetadataId,
  DataModelMetadataVersionId,
  RequirementId,
  StateMachineMetadataId,
  StateMachineMetadataVersionId,
} from "@archetype/ids";
import { z } from "zod";

import { VersionIdentifiable } from "../common/VersionIdentifiable";
import { DataModelFeature } from "../StructuredDataModelFeature";

export const DataModelRequirement = z.object({
  id: RequirementId,
  features: z.array(DataModelFeature),
});

export type IDataModelRequirement = z.infer<typeof DataModelRequirement>;

export const DataModelRequirements = z.object({
  requirements: z.array(DataModelRequirement),
});

export const DataModelMetadata = VersionIdentifiable(
  DataModelMetadataId,
  DataModelMetadataVersionId,
  StateMachineMetadataId,
  StateMachineMetadataVersionId,
).merge(
  z.object({
    requirements: DataModelRequirements,
  }),
);

export type IDataModelMetadata = z.infer<typeof DataModelMetadata>;
