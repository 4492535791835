import type { IAction, IActionCore, IEntityTypeCore, IRelationCore, IStateMachine, IViewField } from "@archetype/dsl";
import {
  computeColumnViewFieldId,
  computeRelationViewFieldId,
  computeViewFieldId,
  generateViewFieldsForEntityType,
  isFieldDependency,
  pathsToStates,
} from "@archetype/dsl";
import type { IActionId, IColumnId, IRelationId, IStateId, ITransitionId, IViewFieldId } from "@archetype/ids";
import { flatMap, isNonNullable, keyByNoUndefined, mapValues } from "@archetype/utils";
import { clone, uniq } from "lodash";

import type { IDependenciesInfo } from "../dependencies/dependencyUtils";
import {
  DATA_MODEL_OPERATIONS,
  getDisabledOperationsByViewFieldId,
} from "../stateMachine/editOperations/disabledOperations";

function travelGraph({
  actions,
  originState,
  targetState,
  visitedStates: initialVisitedStates,
}: {
  actions: IAction[];
  originState: IStateId;
  targetState: IStateId;
  visitedStates?: Set<IStateId>;
}): IAction[] {
  const visitedStates = initialVisitedStates ?? new Set();

  visitedStates.add(originState);

  const relevantActions = actions.filter(
    (a) =>
      a.actionDefinition.fromStates?.includes(originState) === true &&
      a.actionDefinition.toState != null &&
      !visitedStates.has(a.actionDefinition.toState),
  );

  // if no actions left to travel
  if (relevantActions.length === 0) {
    return [];
  }

  // if last action to target
  const actionToTarget = relevantActions.find((a) => a.actionDefinition.toState === targetState);

  if (actionToTarget != null) {
    return [actionToTarget];
  }

  // otherwise
  for (const action of relevantActions) {
    const nextState = action.actionDefinition.toState;

    if (nextState != null) {
      const nextActionsInPath = travelGraph({ actions, originState: nextState, targetState, visitedStates });

      if (nextActionsInPath.length > 0) {
        return [action, ...nextActionsInPath];
      }
    }
  }

  return [];
}

export function findActionsInPath(actions: IAction[], targetState: IStateId): IAction[] {
  const addAction = actions.find((a) => a.actionDefinition.actionType === "add");

  if (addAction == null) {
    throw new Error("Could not find add action!");
  }

  const initialState = addAction.actionDefinition.toState;

  if (initialState == null) {
    throw new Error("Could not find initial state!");
  }

  if (targetState === initialState) {
    // save the travel time
    return [addAction];
  }

  return [addAction, ...travelGraph({ actions, originState: initialState, targetState })];
}

/**
 * takes a list of fields, and add computed fields that have all their dependencies in the list
 * inserts the computed fields right after the all deps have been "fullfilled" - exist in the list
 * keeps the order of the given fields
 * @param fields list of fields that are already present
 * @param dependenciesInfo all computed fields that we want to add if fullfilled
 * @returns list of fields with the fullfilled computed fields added
 */

export function insertFulfilledDependents(fields: IViewField[], dependenciesInfo: IDependenciesInfo): IViewFieldId[] {
  // So that we dont insert multiple times if already in the array at a later position
  const allPresentFieldIds = new Set(fields.map((f) => f.id));
  const { dependencies, dependentFields } = dependenciesInfo;

  const newFieldIds: IViewFieldId[] = clone(fields).map((f) => f.id);

  // Allows to remove elements until there's no more missing dependencies in visited, and avoid traversing the array many times
  // Can be inititialized on first read for the ones we need to visit only
  const dependenciesAsSet: Record<IViewFieldId, Set<IViewFieldId>> = {};

  // for loop on index because forEach iterates on the initial value of the array
  // and here we want this to run "recursively" on the elements we insert
  for (let i = 0; i < newFieldIds.length; i++) {
    const currentFieldId = newFieldIds[i];

    if (currentFieldId == null) {
      // not possible
      continue;
    }

    const dependents = dependentFields[currentFieldId];

    if (dependents == null) {
      // Not dependents that we might want to add at this index, not a problem
      continue;
    }

    dependents.forEach((dependent) => {
      const dependentId = dependent.id;

      // Checking on allPresentFieldIds because don't want to add if it's already further as well
      if (allPresentFieldIds.has(dependentId)) {
        // Already present, possibly after this index, no need to add
        return;
      }

      if (dependenciesAsSet[dependentId] == null && dependencies[dependentId] != null) {
        dependenciesAsSet[dependentId] = new Set(
          (dependencies[dependentId] ?? []).map((d) =>
            isFieldDependency(d) ? computeViewFieldId(d.field) : computeRelationViewFieldId(d.relationId, d.direction),
          ),
        );
      }

      if (dependenciesAsSet[dependentId] == null) {
        // That should not happen, skipping
        return;
      }

      // Check should be on previously visited fields only
      // This was initialized from the full list of dependencies, and we remove the current when visiting it
      // which means that we dont need to remove all the list of visited everytime we check
      dependenciesAsSet[dependentId]?.delete(currentFieldId);

      const allDependenciesFulfilled =
        dependenciesAsSet[dependentId] != null && dependenciesAsSet[dependentId]?.size === 0;

      if (!allDependenciesFulfilled) {
        // Not all dependencies fulfilled, skipping
        return;
      }

      // Adding new field
      newFieldIds.splice(i + 1, 0, dependentId);
      allPresentFieldIds.add(dependentId);
    });
  }

  return newFieldIds;
}

export interface IRelevantInfoForState {
  relevantViewFields: IViewField[];
  pathsToState: ITransitionId[][];
}

/**
 * generates a map of state id to "relevant" fields - which are all fields in prev actions + fullfilled computed fields
 * keeps order of fields according to the order of the actions and the order inside each action
 * this is mainly used for:
 * - suggest what "should" be showing for a state
 * - order the action form (on save, but we also have a live version of this logic for now since the deps are computed async-ly)
 * - suggest context fields that are relevant for the action
 */
export function getOrderedRelevantViewFieldsByState({
  stateMachine,
  actionsById,
  targetEntityType,
  relationsById,
  dependenciesInfo,
}: {
  stateMachine: Omit<IStateMachine, "dataModel">;
  /**
   * All actions for transitions in the state machine to actually get the inputs used in each transition
   */
  actionsById: Record<IActionId, IActionCore>;
  /**
   * If not provided, some view fields that should not be shown in a state view will be removed from this list
   */
  targetEntityType: Omit<IEntityTypeCore, "relevantViewFieldsByStateId">;
  relationsById: Record<IRelationId, IRelationCore>;
  /**
   * If not provided, computed columns will not be added when not in a transition but all their dependencies are (i.e. they can be computed by then)
   */
  dependenciesInfo: IDependenciesInfo | undefined;
}): Record<IStateId, IRelevantInfoForState> {
  const transitionIdsPathsToState = pathsToStates(stateMachine, false);

  const transitionIdToViewFields: Record<ITransitionId, IViewField[]> = mapValues(
    keyByNoUndefined(stateMachine.stateTransitions, (t) => t.id),
    (t) => actionsById[t.actionId]?.actionDefinition.inputs.map((i) => i.viewField) ?? [],
  );

  const viewFieldsById = keyByNoUndefined(
    generateViewFieldsForEntityType({ entityType: targetEntityType, relationsById }),
    (f) => f.id,
  );

  const disabledOperations = getDisabledOperationsByViewFieldId(targetEntityType);

  const initialTransitionColumns: IViewField[] =
    actionsById[stateMachine.initialState.actionId]?.actionDefinition.inputs.map((i) => i.viewField) ?? [];

  return mapValues(transitionIdsPathsToState, (paths) => {
    // Can check if the current state is already within the path (without the last transition)
    // const [pathsWithLoop, pathsWithoutLoop] = partition(paths, (path) =>
    //   path.map((t) => transitionsById[t]?.from).includes(currentState),
    // );

    // We can prioritize the last transitions to state here
    const transitionsWithoutLoops = paths.flat();
    // const transitionsWithoutLoopsSet = new Set(transitionsWithoutLoops);
    // const transitionsRelevantForLoops = pathsWithLoop.flat().filter((t) => !transitionsWithoutLoopsSet.has(t));

    // uniq to keep order stable
    const relevantViewFieldsWithoutLoops = uniq(
      // All initial transitions everywhere
      transitionsWithoutLoops
        .flatMap((transitionId) => transitionIdToViewFields[transitionId])
        .concat(initialTransitionColumns),
    ).filter(isNonNullable);

    const relevantFieldsAndDependents =
      dependenciesInfo == null
        ? relevantViewFieldsWithoutLoops
        : insertFulfilledDependents(relevantViewFieldsWithoutLoops, dependenciesInfo)
            .map((id) => viewFieldsById[id])
            .filter(isNonNullable);

    // traverse this array and for each item, check all dependents, if their dependencies are in the set of already visited, insert them in the array
    // and iterate in a way where you will also check the new ones that are added

    // const columnsRelevantWithoutLoopsSet = new Set(columnsRelevantWithoutLoops);
    // const relevantColumnsForLoops = transitionsRelevantForLoops
    //   .flatMap((t) => transitionIdToColumns[t])
    //   .filter((c): c is IEntityTypeColumnId => isNonNullable(c) && !columnsRelevantWithoutLoopsSet.has(c));

    return {
      relevantViewFields: relevantFieldsAndDependents.filter(
        (field) => disabledOperations[field.id]?.has(DATA_MODEL_OPERATIONS.addToStateView) !== true,
      ),
      pathsToState: paths,
      // relevantColumnsForLoops,
    };
  });
}

export const getColumnsCreatedByNoTransition = (
  allColumnIds: IColumnId[],
  relevantViewFieldsByState: Record<IStateId, IRelevantInfoForState> | undefined,
): IColumnId[] => {
  if (relevantViewFieldsByState == null) {
    return allColumnIds;
  }
  const viewFieldsInTransitionActions = new Set(
    flatMap(relevantViewFieldsByState, (field) => field.relevantViewFields.map((input) => input.id)),
  );

  return allColumnIds.filter((columnId) => !viewFieldsInTransitionActions.has(computeColumnViewFieldId(columnId)));
};
