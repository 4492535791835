import { cn } from "@archetype/ui";
import React, { useCallback, useRef } from "react";
import ContentEditable from "react-contenteditable";

import { ExpandableCellWrapper } from "../ExpandableCellWrapper";
import type { IDataTableCommonCellProps, IDataTablePercentageCell } from "./api";

export function PercentageCell<TRowId extends string, TColId extends string>({
  cell,
  isCellEditing,
  isCellSelected,
  isRowSelected,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTablePercentageCell<TRowId, TColId>>): React.ReactNode {
  const { value, onChange, readOnly } = cell;
  const contentEditableRef = useRef<HTMLSpanElement>(null);

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLSpanElement>): void => {
      const textContent = event.currentTarget.textContent;

      if (textContent == null || textContent.trim() === "") {
        if (value !== 0) {
          void onChange?.(0);
        }

        return;
      }

      const newValue = parseFloat(textContent);

      if (!isNaN(newValue) && newValue !== value) {
        void onChange?.(newValue);
      }
    },
    [onChange, value],
  );

  return (
    <ExpandableCellWrapper
      cell={cell}
      isCellEditing={isCellEditing}
      isCellSelected={isCellSelected}
      isRowSelected={isRowSelected}
    >
      <ContentEditable
        className={cn("size-full text-base outline-none", (readOnly || !isCellEditing) && "truncate")}
        html={value?.toString() ?? ""}
        innerRef={contentEditableRef}
        onBlur={handleChange}
        onChange={handleChange}
      />
    </ExpandableCellWrapper>
  );
}
