import type { IRelationFieldValue, IViewFieldValue } from "@archetype/dsl";
import type { IEmailExtractedActionInput } from "@archetype/dsl";
import type { IEmailId, IViewFieldId } from "@archetype/ids";
import { isNonNullable, map, mapValues } from "@archetype/utils";

import { entityTypeCoreFromLoaded } from "../adapters/entityTypes";
import type { ILoadedAction } from "../apiTypes/LoadedActionType";
import type { ILoadedEntityType } from "../apiTypes/LoadedEntityType";
import { castColumnActionInputs } from "../inboundEmail/emailActionArguments";

export type IMaybeExtractedColumnValue =
  | {
      type: "found";
      value: string;
      location:
        | Pick<IEmailExtractedActionInput, "foundInField" | "startCharIndexInclusive" | "endCharIndexExclusive">
        | undefined;
    }
  | {
      type: "not found";
    };

/**
 * Extracts locations of values in the email for tracking
 */
export const extractLocations = ({
  actionColumnInputValues,
  emailId,
}: {
  actionColumnInputValues: Record<IViewFieldId, IMaybeExtractedColumnValue>;
  emailId: IEmailId;
}): IEmailExtractedActionInput[] =>
  map(actionColumnInputValues, (arg, viewFieldId) =>
    arg.type === "not found" || arg.location == null
      ? undefined
      : {
          viewFieldId,
          emailId,
          foundInField: arg.location.foundInField,
          startCharIndexInclusive: arg.location.startCharIndexInclusive,
          endCharIndexExclusive: arg.location.endCharIndexExclusive,
        },
  ).filter(isNonNullable);

/**
 * Formats action argument values from extracted column and relation values
 */
export const formatActionArgValues = ({
  chosenEntityType,
  chosenAction,
  actionColumnInputValues,
  relationFieldValues,
}: {
  chosenEntityType: ILoadedEntityType;
  chosenAction: ILoadedAction;
  actionColumnInputValues: Record<IViewFieldId, { type: "found"; value: string } | { type: "not found" }>;
  relationFieldValues: Record<IViewFieldId, { type: "found"; value: IRelationFieldValue } | { type: "not found" }>;
}): Partial<Record<IViewFieldId, IViewFieldValue>> => {
  const castedColumnArgValues = castColumnActionInputs(
    entityTypeCoreFromLoaded(chosenEntityType),
    chosenAction,
    actionColumnInputValues,
  );

  const relationArgValues = mapValues(relationFieldValues, (argValue) => {
    if (argValue.type === "not found") {
      return undefined;
    }

    return argValue.value;
  });

  return {
    ...castedColumnArgValues,
    ...relationArgValues,
  };
};
