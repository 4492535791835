import type { IAction, IAuthorizingRelationPath, IDataLoadingRelationConfig, IEntityType } from "@archetype/dsl";
import type { IEntityTypeId, IRelationId, IStateId } from "@archetype/ids";
import { forEach, isNonNullable } from "@archetype/utils";
import { uniq, values } from "lodash";

import type { IAuthorizationsByEntityScope } from "./types";

export const getEntityTypeAuthorizations = (
  entityType: IEntityType,
  entityTypeActions: IAction[],
): IAuthorizationsByEntityScope => {
  const anyState = entityType.authorizedByAnyOf.map((authorization) => authorization.authorizedByRelationPath);
  const comments = entityType.activityLogAuthorizedByAnyOf.map((comment) => comment.authorizedByRelationPath);
  const byStateId: Record<IStateId, IAuthorizingRelationPath[]> = {};

  forEach(entityType.authorizedByAnyOfPerStateId, (stateAuths, stateId) => {
    if (stateAuths == null) {
      return;
    }

    byStateId[stateId] ??= [];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we know this is an array because of the line above
    byStateId[stateId]!.push(
      ...stateAuths.filter(isNonNullable).map(({ authorizedByRelationPath }) => authorizedByRelationPath),
    );
  });

  entityTypeActions.forEach((action) => {
    // add actions do not authorize retrieval of anything
    if (action.actionDefinition.actionType === "add") {
      return;
    }

    const fromStates = action.actionDefinition.fromStates;

    if (fromStates == null) {
      anyState.push(
        ...action.actionDefinition.authorizedByAnyOf.map((authorization) => authorization.authorizedByRelationPath),
      );
    } else {
      fromStates.forEach((stateId: IStateId) => {
        byStateId[stateId] ??= [];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we know this is an array because of the line above
        byStateId[stateId]!.push(
          ...action.actionDefinition.authorizedByAnyOf.map((authorization) => authorization.authorizedByRelationPath),
        );
      });
    }
  });

  return {
    anyState,
    activityLog: comments,
    byStateId,
  };
};

export const collectRelationIdsFromSpecificRelations = (
  specificRelations: IDataLoadingRelationConfig[] | null,
): IRelationId[] => uniq(specificRelations?.map(({ id }) => id) ?? []);

export const collectIdsFromAuthorizations = (
  authorizations: IAuthorizationsByEntityScope,
): {
  entityTypeIds: IEntityTypeId[];
  relationIds: IRelationId[];
} => {
  const entityTypeIds = new Set<IEntityTypeId>();
  const relationIds = new Set<IRelationId>();

  const authorizingPaths = [
    ...authorizations.anyState,
    ...authorizations.activityLog,
    ...values(authorizations.byStateId).flat(),
  ].filter(isNonNullable);

  authorizingPaths.forEach((config) => {
    if (config.startingEntityType != null) {
      entityTypeIds.add(config.startingEntityType.id);
    }

    config.path.forEach((step) => {
      relationIds.add(step.relationId);
    });
  });

  return {
    relationIds: Array.from(relationIds),
    entityTypeIds: Array.from(entityTypeIds),
  };
};
