import { forEach } from "./forEach";

/**
 * Creates an array of values by running each property of `obj` through `iteratee`.
 *
 * @param obj The object to iterate over
 * @param iteratee The function invoked per iteration
 * @returns An array of the results of running `iteratee` for each property
 */

export function map<const Key extends string, const Value, R>(
  obj: Record<Key, Value> | undefined,
  iteratee: (value: Value, key: Key, obj: Record<Key, Value>) => R,
): R[] {
  if (obj == null) {
    return [];
  }

  const res: R[] = [];

  forEach(obj, (value, key) => {
    res.push(iteratee(value, key, obj));
  });

  return res;
}

/**
 * Creates an array of values by running each property of `obj` through `iteratee`.
 *
 * @param obj The object to iterate over
 * @param iteratee The function invoked per iteration
 * @returns An array of the results of running `iteratee` for each property
 */

export function mapPartial<const Key extends string, const Value, R>(
  obj: Partial<Record<Key, Value>> | undefined,
  iteratee: (value: Value, key: Key) => R,
): R[] {
  const res: R[] = [];

  forEach(obj, (value, key) => {
    if (value === undefined) {
      return;
    }

    res.push(iteratee(value, key));
  });

  return res;
}
