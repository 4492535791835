import React from "react";

import { cn } from "../../lib/utils";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage } from "../atoms/breadcrumbs";
import { Header } from "../molecules/header/Header";
import type { IBreadcrumbNavItem } from "./api";

export interface IAbstractDashboardLayout {
  className?: string;
  breadcrumbs: IBreadcrumbNavItem[];
  actions?: React.ReactNode;
  leftPanel?: React.ReactNode;
  mainPanel: React.ReactNode;
}

export const AbstractDashboardLayout: React.FC<IAbstractDashboardLayout> = ({
  className,
  breadcrumbs,
  mainPanel,
  leftPanel,
  actions,
}) => {
  const breadcrumbContent = (
    <Breadcrumb>
      <BreadcrumbList>
        {breadcrumbs.map((breadcrumb, idx) => (
          // eslint-disable-next-line react/no-array-index-key -- index based with breadcrumbs
          <React.Fragment key={idx}>
            <BreadcrumbItem>
              {breadcrumb.linkRenderer ? (
                <BreadcrumbLink>
                  {breadcrumb.linkRenderer({
                    children: breadcrumb.text,
                  })}
                </BreadcrumbLink>
              ) : (
                <BreadcrumbPage>{breadcrumb.text}</BreadcrumbPage>
              )}
            </BreadcrumbItem>
          </React.Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );

  return (
    <main className={cn("text-ink flex h-screen w-screen flex-col overflow-hidden", className)}>
      <Header leftContent={breadcrumbContent} rightContent={actions} />
      <div className="flex h-0 max-h-full shrink grow">
        {leftPanel != null && <aside className="flex h-full flex-col">{leftPanel}</aside>}
        <div className="flex w-full grow flex-col">{mainPanel}</div>
      </div>
    </main>
  );
};
