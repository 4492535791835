import type { IComponentDefinition, ISlot } from "@archetype/dsl";
import { isObjectSubset } from "@archetype/utils";
import type { Result } from "neverthrow";
import { err, ok } from "neverthrow";

import { ComponentInputsNotCompatibleWithSlot } from "../errors/ComponentInputsNotCompatibleWithSlot";
import { ComponentNotCompatibleWithSlotSize } from "../errors/ComponentNotCompatibleWithSlotSize";
import { ComponentOutputsNotCompatibleWithSlot } from "../errors/ComponentOutputsNotCompatibleWithSlot";

/**
 * Only used for testing right now, maybe to clean up
 */
export const isComponentCompatibleWithSlot = ({
  component,
  slot,
}: {
  component: IComponentDefinition;
  slot: ISlot;
}): Result<
  true,
  ComponentNotCompatibleWithSlotSize | ComponentInputsNotCompatibleWithSlot | ComponentOutputsNotCompatibleWithSlot
> => {
  if (!component.compatibleSemanticSizes.includes(slot.constraints.semanticSize)) {
    return err(
      new ComponentNotCompatibleWithSlotSize({
        componentId: component.id,
        slotId: slot.id,
        size: slot.constraints.semanticSize,
      }),
    );
  }

  // TODO should consider constraints of each type presumably
  // Slot inputs must be a superset of inputs of matching components, i.e. a matching component may have fewer inputs, that would mean that some data is ignored.
  if (!isObjectSubset(component.inputs, slot.constraints.inputs)) {
    return err(
      new ComponentInputsNotCompatibleWithSlot({
        componentId: component.id,
        slotId: slot.id,
      }),
    );
  }

  // Slot outputs must be a subset of outputs of matching components.
  // i.e. matching components may be able to output more parameters than necessary here.
  if (!isObjectSubset(slot.constraints.outputs, component.outputs)) {
    return err(
      new ComponentOutputsNotCompatibleWithSlot({
        componentId: component.id,
        slotId: slot.id,
      }),
    );
  }

  return ok(true);
};
