import type { IDependenciesFieldIdsInfo, ILoadedAction, ILoadedEntityType, ILoadedViewField } from "@archetype/core";
import { DATA_MODEL_OPERATIONS } from "@archetype/core";
import type { IStateMachine, IVersionType } from "@archetype/dsl";
import type { ITransitionId, IViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { flatMap, isNonNullable, unpartialRecord } from "@archetype/utils";
import { uniq, uniqBy } from "lodash";
import { useMemo } from "react";

import { withRefetchInterval } from "../../utils/refetchInterval";
import { useActionFields } from "./useActionFields";

export function useActionFieldsForTransition({
  action,
  entityType,
  versionType,
  stateMachine,
  transitionId,
}: {
  action: ILoadedAction;
  entityType: ILoadedEntityType;
  versionType: IVersionType;
  stateMachine: IStateMachine;
  transitionId: ITransitionId;
}): {
  contextualFields: ILoadedViewField[];
  regularFields: {
    fixed: ILoadedViewField[];
    mutable: ILoadedViewField[];
  };
  hiddenFields: {
    fixed: ILoadedViewField[];
    mutable: ILoadedViewField[];
  };
  usedFields: {
    fixed: ILoadedViewField[];
    mutable: ILoadedViewField[];
  };
  unusedFields: ILoadedViewField[];
  availableContextualFields: ILoadedViewField[];
  disabledOperationsByFieldId: Record<IViewFieldId, Set<DATA_MODEL_OPERATIONS>>;
  allViewFieldsById: Record<IViewFieldId, ILoadedViewField>;
  fieldsThatCannotBeRemoved: Record<IViewFieldId, boolean>;
  dependenciesInfo: IDependenciesFieldIdsInfo | undefined;
} {
  const { data: relevantFieldsQuery } = builderTrpc.dataModel.getOrderedRelevantFieldsByState.useQuery(
    {
      versionType,
      entityTypeId: entityType.id,
    },
    {
      ...withRefetchInterval(20000),
    },
  );

  const {
    allViewFieldsById,
    contextualFields,
    regularFields,
    hiddenFields,
    unusedFields,
    usedFields,
    disabledOperationsByFieldId,
    fieldsThatCannotBeRemoved,
    dependenciesInfo,
  } = useActionFields({
    action,
    entityType,
    versionType,
  });

  const availableContextualFields: ILoadedViewField[] = useMemo(() => {
    const existingContextFieldIds = new Set(action.actionDefinition.contextualFields.map((field) => field.id));
    const actionFieldIds = new Set(action.actionDefinition.inputs.map((input) => input.viewField.id));

    const transition = stateMachine.stateTransitions.find((t) => t.id === transitionId);
    const dynamicRelevantFieldsPerState = relevantFieldsQuery?.relevantViewFieldsByState ?? {};

    const relevantFields = uniq(
      (transition != null
        ? dynamicRelevantFieldsPerState[transition.from]?.relevantViewFields
        : flatMap(unpartialRecord(dynamicRelevantFieldsPerState), (fields) => fields.relevantViewFields)) ?? [],
    );

    const viewFields = uniqBy(
      relevantFields.filter((field) => {
        if (actionFieldIds.has(field.id) || existingContextFieldIds.has(field.id)) {
          return false;
        }

        return disabledOperationsByFieldId[field.id]?.has(DATA_MODEL_OPERATIONS.addToStateView) !== true;
      }),
      (f) => f.id,
    );

    return viewFields.map((field) => allViewFieldsById[field.id]).filter(isNonNullable);
  }, [
    action.actionDefinition.contextualFields,
    action.actionDefinition.inputs,
    stateMachine.stateTransitions,
    relevantFieldsQuery?.relevantViewFieldsByState,
    transitionId,
    disabledOperationsByFieldId,
    allViewFieldsById,
  ]);

  return {
    availableContextualFields,
    contextualFields,
    regularFields,
    hiddenFields,
    usedFields,
    unusedFields,
    disabledOperationsByFieldId,
    fieldsThatCannotBeRemoved,
    allViewFieldsById,
    dependenciesInfo,
  };
}
