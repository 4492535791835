import type { IEntityId, IEntityTypeId, IViewFieldId } from "@archetype/ids";
import { ViewFieldId } from "@archetype/ids";
import { z } from "zod";

export const ValidationError = z.object({
  error: z.string(),
  viewFieldId: ViewFieldId.optional(),
});

export type IValidationError = z.infer<typeof ValidationError>;

export type ISingleFormValidationErrors = {
  generalErrors: string[];
  fieldErrors: IValidationErrorByFieldId;
};

export type IValidationErrorByFieldId = Record<IViewFieldId, IValidationError[]>;
export type IValidationErrors = ISingleFormValidationErrors & {
  nestedActionErrors: Partial<Record<IEntityTypeId, Partial<Record<IEntityId, ISingleFormValidationErrors>>>>;
};
