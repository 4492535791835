// Cosmetic optional props ———————————————————————————————————————————————————————————

import { z } from "zod";

export const CosmeticOptionalProps = z
  .object({
    intent: z.union([
      z.literal("primary"),
      z.literal("none"),
      z.literal("success"),
      z.literal("warning"),
      z.literal("danger"),
    ]),
    label: z.string(),
    styling: z.union([z.literal("default"), z.literal("minimal"), z.literal("outline")]),
  })
  .partial();

export const CosmeticOptionalPropNames = CosmeticOptionalProps.keyof();

export type ICosmeticOptionalProps = z.infer<typeof CosmeticOptionalProps>;
export type ICosmeticOptionalPropNames = z.infer<typeof CosmeticOptionalPropNames>;
