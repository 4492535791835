import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { ChartComponentDefinitionId } from "@archetype/ids";

export const ChartComponentIdDeclarations = {
  id: ChartComponentDefinitionId,
  inputs: ["multiEntityQueryDataInput", "primarySingleEntityDataInput"],
  outputs: ["userSelectedSingleEntity"],
  compatibleSemanticSizes: ["main", "split-horizontal", "medium"],
  validCosmeticOptionalProps: ["label"],
} as const;

export const ChartComponentDefinition: ITypedComponentDefinitionDeclaration<typeof ChartComponentIdDeclarations> = {
  constIds: ChartComponentIdDeclarations,
  displayMetadata: {
    name: "Chart",
    description: "A chart component",
  },
  inputs: {
    multiEntityQueryDataInput: {
      displayMetadata: {
        name: "Chart data",
        description: "Data to populate the chart with",
      },
      optional: false,
    },
    primarySingleEntityDataInput: {
      displayMetadata: {
        name: "Chart data",
        description: "Data to populate the chart with",
      },
      optional: false,
      nullable: true,
    },
  },
  outputs: {
    userSelectedSingleEntity: {
      displayMetadata: {
        name: "Chart selection",
        description: "The selected range of data in the chart",
      },
      optional: true,
      nullable: true,
    },
  },
};
