import type { ILoadedAction } from "@archetype/core";
import type { IStateId } from "@archetype/ids";

export function filterRelevantActionsForState(state: IStateId | undefined, actions: ILoadedAction[]): ILoadedAction[] {
  return actions.filter((action) => {
    const fromStates = action.actionDefinition.fromStates;

    if (fromStates == null || state == null || fromStates.length === 0) {
      // For null state, only keep actions with no fromStates
      return state == null && (fromStates == null || fromStates.length === 0);
    }

    return fromStates.includes(state);
  });
}
