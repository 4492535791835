import type { ILoadedEntityType, ILoadedViewField, IRelevantInfoForState } from "@archetype/core";
import {
  createLoadedColumnViewField,
  createLoadedViewField,
  generateLoadedViewFieldsMapForLoadedEntityType,
} from "@archetype/core";
import type { IColumn, IRelationCore } from "@archetype/dsl";
import { computeColumnViewFieldId, type IVersionType } from "@archetype/dsl";
import type { IColumnId, IEntityTypeId, IRelationId, IStateId, IViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { useMemoDeepCompare } from "@archetype/ui";
import { isNonNullable, keyByAndMapValues, pickBy, unpartialRecord } from "@archetype/utils";
import { uniqBy, values } from "lodash";

import { withRefetchInterval } from "../../utils/refetchInterval";
import { sortFields } from "../../utils/sortFields";

export interface IEntityTypeFieldsInfo {
  fixedRelevantFields: ILoadedViewField[];
  hiddenFields: ILoadedViewField[];
  relevantFields: ILoadedViewField[];
  missingRelevantFields: ILoadedViewField[];
  restNonRelevantFields: ILoadedViewField[];

  allFields: ILoadedViewField[];
  dynamicRelevantFieldsPerState: Partial<Record<IStateId, IRelevantInfoForState>> | undefined;
}

export function useEntityTypeFields({
  entityTypeId,
  entityType,
  versionType,
  stateId,
}: {
  // Duplicating the prop to avoid waiting sequentially for the entity type
  entityTypeId: IEntityTypeId;
  entityType: ILoadedEntityType | undefined;
  versionType: IVersionType;
  stateId: IStateId | undefined;
}): IEntityTypeFieldsInfo & {
  isLoading: boolean;
} {
  const { isLoading, fieldsInfoByState, nonStateFieldsInfo } = useEntityTypeFieldsByState({
    entityTypeId,
    entityType,
    versionType,
  });

  if (stateId == null) {
    return { ...nonStateFieldsInfo, isLoading };
  }

  const res = fieldsInfoByState[stateId] ?? nonStateFieldsInfo;

  return { ...res, isLoading };
}

export function useEntityTypeFieldsByState({
  entityTypeId,
  entityType,
  versionType,
}: {
  // Duplicating the prop to avoid waiting sequentially for the entity type
  entityTypeId: IEntityTypeId;
  entityType: ILoadedEntityType | undefined;
  versionType: IVersionType;
}): {
  fieldsInfoByState: Record<IStateId, IEntityTypeFieldsInfo>;
  nonStateFieldsInfo: IEntityTypeFieldsInfo;
  isLoading: boolean;
} {
  const { data: relevantFieldsQuery, isLoading } = builderTrpc.dataModel.getOrderedRelevantFieldsByState.useQuery(
    {
      entityTypeId,
      versionType,
    },
    { ...withRefetchInterval(20000) },
  );

  const results: {
    fieldsInfoByState: Record<IStateId, IEntityTypeFieldsInfo>;
    nonStateFieldsInfo: IEntityTypeFieldsInfo;
  } = useMemoDeepCompare(() => {
    if (entityType == null || isLoading || relevantFieldsQuery == null) {
      return {
        fieldsInfoByState: {},
        nonStateFieldsInfo: {
          fixedRelevantFields: [],
          hiddenFields: [],
          relevantFields: [],
          missingRelevantFields: [],
          restNonRelevantFields: [],
          allFields: [],
          dynamicRelevantFieldsPerState: {},
        },
      };
    }

    const columnsById = unpartialRecord(entityType.columns);
    const relationsById = unpartialRecord(entityType.relations);

    const primaryKeyColumn = columnsById[entityType.primaryKey];
    const displayNameColumn = columnsById[entityType.displayNameColumn];
    const statusColumn = entityType.statusColumn == null ? undefined : columnsById[entityType.statusColumn];

    const stateIds =
      statusColumn?.columnType.type === "statusEnum" ? statusColumn.columnType.allowedValues.map((v) => v.id) : [];

    const generalRelevantFieldIdsOrder = relevantFieldsQuery.generalRelevantFieldsOrder;
    const dynamicRelevantFieldsPerState = relevantFieldsQuery.relevantViewFieldsByState;

    const allViewFieldsById = generateLoadedViewFieldsMapForLoadedEntityType(entityType);

    const primaryKeyField = primaryKeyColumn && createLoadedColumnViewField(primaryKeyColumn);
    const hiddenFields = primaryKeyField != null ? [primaryKeyField] : [];

    const fixedRelevantFields: ILoadedViewField[] = [
      displayNameColumn && createLoadedColumnViewField(displayNameColumn),
      statusColumn && createLoadedColumnViewField(statusColumn),
    ].filter(isNonNullable);

    const fixedOrHiddenFieldIdsSet = new Set(
      [
        computeColumnViewFieldId(entityType.primaryKey),
        computeColumnViewFieldId(entityType.displayNameColumn),
        entityType.statusColumn == null ? undefined : computeColumnViewFieldId(entityType.statusColumn),
      ].filter(isNonNullable),
    );

    const fieldsInfoByState = keyByAndMapValues(stateIds, (stateId) => {
      return {
        key: stateId,
        value: makeFieldsInfoForState({
          stateId,
          entityType,
          generalRelevantFieldIdsOrder,
          dynamicRelevantFieldsPerState,
          columnsById,
          relationsById,
          allViewFieldsById,
          hiddenFields,
          fixedRelevantFields,
          fixedOrHiddenFieldIdsSet,
        }),
      };
    });

    const nonStateFieldsInfo = makeFieldsInfoForState({
      stateId: undefined,
      entityType,
      generalRelevantFieldIdsOrder,
      dynamicRelevantFieldsPerState,
      columnsById,
      relationsById,
      allViewFieldsById,
      hiddenFields,
      fixedRelevantFields,
      fixedOrHiddenFieldIdsSet,
    });

    return {
      fieldsInfoByState,
      nonStateFieldsInfo,
    };
  }, [entityType, relevantFieldsQuery, isLoading]);

  return { ...results, isLoading };
}

const makeFieldsInfoForState = ({
  stateId,
  entityType,
  generalRelevantFieldIdsOrder,
  dynamicRelevantFieldsPerState,
  columnsById,
  relationsById,
  allViewFieldsById,
  hiddenFields,
  fixedRelevantFields,
  fixedOrHiddenFieldIdsSet,
}: {
  stateId: IStateId | undefined;
  entityType: ILoadedEntityType;

  generalRelevantFieldIdsOrder: IViewFieldId[] | null;
  dynamicRelevantFieldsPerState: Partial<Record<IStateId, IRelevantInfoForState>> | null;
  columnsById: Record<IColumnId, IColumn>;
  relationsById: Record<IRelationId, IRelationCore>;
  allViewFieldsById: Record<IViewFieldId, ILoadedViewField>;
  hiddenFields: ILoadedViewField[];
  fixedRelevantFields: ILoadedViewField[];
  fixedOrHiddenFieldIdsSet: Set<IViewFieldId>;
}): IEntityTypeFieldsInfo => {
  const dynamicRelevantFieldIdsForState =
    stateId != null ? dynamicRelevantFieldsPerState?.[stateId]?.relevantViewFields : undefined;
  const relevantFieldIdsForState = stateId != null ? entityType.relevantViewFieldsByStateId?.[stateId] : undefined;
  const relevantFields: ILoadedViewField[] = uniqBy(
    (
      relevantFieldIdsForState?.map((field) => createLoadedViewField(field, columnsById, relationsById)) ??
      dynamicRelevantFieldIdsForState?.map((field) => createLoadedViewField(field, columnsById, relationsById)) ??
      generalRelevantFieldIdsOrder?.map((fieldId) => allViewFieldsById[fieldId]) ??
      []
    )
      .filter((f) => isNonNullable(f) && !fixedOrHiddenFieldIdsSet.has(f.id))
      .filter(isNonNullable),
    (field) => field.id,
  );

  const dynamicRelevantFields =
    stateId != null ? (dynamicRelevantFieldsPerState?.[stateId]?.relevantViewFields ?? []) : [];

  const relevantFieldIdsSet = new Set(
    relevantFields
      .concat(fixedRelevantFields)
      .concat(hiddenFields)
      .map((field) => field.id),
  );

  const missingRelevantFields = dynamicRelevantFields.filter((field) => !relevantFieldIdsSet.has(field.id));

  const missingRelevantFieldIdsSet = new Set(missingRelevantFields.map((f) => f.id));

  const restNonRelevantFields = values(
    pickBy(
      allViewFieldsById,
      (field) => !missingRelevantFieldIdsSet.has(field.id) && !relevantFieldIdsSet.has(field.id),
    ),
  );

  const missingRelevantLoadedFields = sortFields(
    missingRelevantFields
      .map((field) => createLoadedViewField(field, columnsById, relationsById))
      .filter(isNonNullable),
    entityType,
  );

  const sortedRelevantFields = sortFields(relevantFields, entityType);
  const sortedRestNonRelevantFields = sortFields(restNonRelevantFields, entityType);

  const allFields = fixedRelevantFields
    .concat(sortedRelevantFields)
    .concat(missingRelevantLoadedFields)
    .concat(sortedRestNonRelevantFields)
    .filter(isNonNullable);

  const fieldsForState: IEntityTypeFieldsInfo = {
    fixedRelevantFields,
    hiddenFields,
    relevantFields: sortedRelevantFields,
    missingRelevantFields: missingRelevantLoadedFields,
    restNonRelevantFields: sortedRestNonRelevantFields,
    allFields,
    dynamicRelevantFieldsPerState: dynamicRelevantFieldsPerState ?? {},
  };

  return fieldsForState;
};
