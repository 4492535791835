import type { IOrganizationId } from "@archetype/ids";
import type { IBreadcrumbNavItem } from "@archetype/ui";
import { simpleLinkRenderer, Skeleton } from "@archetype/ui";
import Image from "next/image";

export function generateOrganizationBreadcrumb({
  orgInfo,
  orgId,
  isCurrentPage,
}: {
  orgInfo?: {
    imageUrl: string;
    name: string;
  };
  orgId: IOrganizationId;
  isCurrentPage: boolean;
}): IBreadcrumbNavItem {
  return {
    text: (
      <div className="flex cursor-pointer items-center space-x-2">
        {orgInfo == null ? (
          <>
            <Skeleton className="mr-1">IMG</Skeleton>
            <Skeleton>Organization Name</Skeleton>
          </>
        ) : (
          <div className="flex size-[24px] items-center justify-center overflow-hidden rounded">
            <Image alt={orgInfo.name} height={24} src={orgInfo.imageUrl} width={24} />
          </div>
        )}
        <div className="text-lg">{orgInfo?.name}</div>
      </div>
    ),
    isCurrentPage,
    linkRenderer: simpleLinkRenderer({
      pathname: "/[orgId]/workspace/[versionType]",
      query: {
        orgId,
        versionType: "prod",
      },
    }),
  };
}
