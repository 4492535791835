import type { ICrossColumnAndConditions } from "@archetype/dsl";
import type { IColumnId, IEntityId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { MultiSelect, useMemoDeepCompare } from "@archetype/ui";
import { keyByAndMapValues, map } from "@archetype/utils";
import { concat } from "lodash";
import { useCallback } from "react";

import { useRelatedToFilterEntityIds } from "./hooks/useRelatedToFilterEntityIds";
import { useRelationFilterSearch } from "./hooks/useRelationFilterSearch";
import type { IRelationFilterComponent } from "./types";

type IOption = { label: string; value: string };

const getCrossColumnAndConditions = ({
  relatedEntityPrimaryKeyColumnId,
  relatedEntityIds,
}: {
  relatedEntityPrimaryKeyColumnId: IColumnId;
  relatedEntityIds: IEntityId[];
}): ICrossColumnAndConditions => ({
  type: "and",
  andedCrossColumnOrConditions: [],
  andedRelatedToFilters: [],
  perColumn: {
    [relatedEntityPrimaryKeyColumnId]: {
      type: "or",
      rawOrConditions: {
        eq: relatedEntityIds.map((id) => ({
          type: "value",
          value: {
            type: "string" as const,
            value: id,
          },
        })),
      },
      oredAndConditions: [],
    },
  },
});

export const RelationFilterInput: IRelationFilterComponent = ({
  value,
  onChange,
  versionType,
  entityType,
  relation,
  direction,
  otherEntityType,
}) => {
  const selectedEntityIds = useRelatedToFilterEntityIds(value, otherEntityType);
  const targetEntityTypeId = otherEntityType.id;

  const { handleSearch, filterQueryWithSearch } = useRelationFilterSearch(otherEntityType);

  const {
    data: selectedEntitiesQuery,
    isLoading: isLoadingSelectedEntities,
    isFetching: isFetchingSelectedEntities,
  } = builderTrpc.dataLoading.getLoadedEntities.useQuery(
    {
      organizationId: otherEntityType.organizationId,
      dataLoadingQuery: {
        versionType,
        entityType: {
          type: "entityTypeId",
          entityTypeId: otherEntityType.id,
          entities: selectedEntityIds,
        },
        filters: filterQueryWithSearch,
      },
      dataLoadingConfig: {
        specificRelations: [],
        specificColumns: [otherEntityType.displayNameColumn],
      },
    },
    {
      // Enforce undefined if the entity type id has changed
      placeholderData: (previousData) =>
        previousData?.entities[0]?.entityTypeId !== targetEntityTypeId ? undefined : previousData,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    },
  );

  const {
    data: entitiesPagedQuery,
    isLoading: isLoadingEntities,
    isFetching: isFetchingEntities,
  } = builderTrpc.dataLoading.getLoadedEntities.useInfiniteQuery(
    {
      organizationId: entityType.organizationId,
      dataLoadingQuery: {
        versionType,
        entityType: {
          type: "joinTraversal",
          relationTypeId: relation.id,
          relationDirection: direction,
          startingEntities: {
            type: "entityTypeId",
            entityTypeId: entityType.id,
          },
        },
        filters: undefined,
      },
      dataLoadingConfig: {
        specificRelations: [],
        specificColumns: [otherEntityType.displayNameColumn],
      },
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialCursor: 0,
      // Enforce undefined if the entity type id has changed
      placeholderData: (previousData) =>
        previousData?.pages[0]?.entities[0]?.entityTypeId !== targetEntityTypeId ? undefined : previousData,
      trpc: {
        abortOnUnmount: true,
        context: {
          skipBatch: true,
        },
      },
    },
  );

  const entityNamesByIds = useMemoDeepCompare(() => {
    const selectableEntities = entitiesPagedQuery?.pages.flatMap((p) => p.entities) ?? [];

    return keyByAndMapValues(concat(selectableEntities, selectedEntitiesQuery?.entities ?? []), (entity) => ({
      key: entity.entityId,
      value: entity.displayName,
    }));
  }, [entitiesPagedQuery?.pages, selectedEntitiesQuery?.entities]);

  const selection: IOption[] = useMemoDeepCompare(
    () =>
      selectedEntityIds.map((entityId) => ({
        value: entityId,
        label: entityNamesByIds[entityId] ?? "Loading...",
      })),
    [selectedEntityIds, entityNamesByIds],
  );

  const handleChange = useCallback(
    (values: IOption[]): void => {
      if (values.length === 0) {
        onChange(undefined);
      } else {
        onChange(
          getCrossColumnAndConditions({
            relatedEntityPrimaryKeyColumnId: otherEntityType.primaryKey,
            relatedEntityIds: values.map((v) => v.value as IEntityId),
          }),
        );
      }
    },
    [onChange, otherEntityType.primaryKey],
  );

  const options = map(entityNamesByIds, (name, id) => ({ value: id, label: name }));

  return (
    <MultiSelect
      isLoading={isLoadingEntities || isFetchingEntities || isLoadingSelectedEntities || isFetchingSelectedEntities}
      options={options}
      placeholder={`Select ${direction === "aToB" ? relation.displayMetadataFromAToB.name : relation.displayMetadataFromBToA.name}...`}
      value={selection}
      onChange={handleChange}
      onSearch={handleSearch}
    />
  );
};
