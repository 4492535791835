import {
  ActionId,
  ApplicationGroupId,
  ColumnId,
  EntityTypeId,
  EntityTypeVersionId,
  OrganizationId,
  StateId,
} from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import * as z from "zod";

import { Authorization } from "../Authorization";
import { DisplayMetadata } from "../common/DisplayMetadata";
import { VersionIdentifiable } from "../common/VersionIdentifiable";
import { Column } from "./Column";
import { EntityTypeColor } from "./EntityTypeColor";
import { EntityTypeShapeName } from "./EntityTypeShapeName";
import { ViewField } from "./ViewField";

export const RelevantViewFieldsByStateId = z.record(z.string(), z.array(ViewField));

export const EntityTypeSupportEntityActionIds = z.object({
  create: ActionId,
  update: ActionId,
  /**
   * Other actions that may only be relevant for special entity types,
   * but grouping them all together allows to iterate through "all other actions"
   */
  otherActions: z.object({
    invite: ActionId.nullable(),
  }),
});

export const EntityTypeDisplayMetadata = DisplayMetadata.extend({
  pluralName: ReadableString,
});

export type IEntityTypeDisplayMetadata = z.infer<typeof EntityTypeDisplayMetadata>;

export const EntityTypeCore = z.object({
  id: EntityTypeId,
  primaryKey: ColumnId,
  displayNameColumn: ColumnId,
  statusColumn: ColumnId.nullable(),

  displayMetadata: EntityTypeDisplayMetadata,
  shape: EntityTypeShapeName,
  color: EntityTypeColor,

  columns: z.array(Column),
  /**
   * Record from StateId but zod parses as Partial
   */
  relevantViewFieldsByStateId: RelevantViewFieldsByStateId.nullable(),
  authorizedByAnyOf: z
    .array(Authorization)
    .describe("An entity of this type will be authorized if any of these authorizations match"),
  authorizedByAnyOfPerStateId: z.record(StateId, z.array(Authorization).optional()),

  activityLogAuthorizedByAnyOf: z
    .array(Authorization)
    .describe(
      "An entity of this type will be authorized to be commented on and view activity logs if any of these authorizations match",
    ),

  authorizedForAnyoneWithLink: z.boolean().optional(),
  authorizedForAnyoneWithLinkPerStateId: z.record(StateId, z.boolean().optional()).optional(),

  /**
  /**
   * There should be a single one per organization.
   * true if and only if baseUserEntityTypeVersion is not null
   */
  userEntityTypeInfo: z
    .object({
      baseUserEntityTypeVersion: z.number(),
    })
    .nullable(),
  /**
   * Scope of the entity type to the organization that created it.
   * Entity types are never shared between organizations for now.
   * Collaboration across organization works through users being invited inside an organization
   */
  organizationId: OrganizationId,
  /**
   * If the entity type is a target entity type of a state machine, this identifies which applicationGroup / stateMachine.
   * It can be at most part of a state machine, but it can also be a support entity type.
   * If this is set, this entity type should only be editable in that applicationGroup, and edits of entities
   * only through actions of that stateMachine.
   */
  targetEntityTypeApplicationGroupId: ApplicationGroupId.nullable(),

  /**
   * Only relevant for support entity types, i.e. if targetEntityTypeApplicationGroupId is `null`.
   */
  supportActionsInfo: EntityTypeSupportEntityActionIds.nullable(),

  deleteActionId: ActionId,
});

export const EntityType = VersionIdentifiable(EntityTypeId, EntityTypeVersionId, z.null(), z.null()).merge(
  EntityTypeCore.omit({
    authorizedByAnyOf: true,
  }).extend({
    authorizedByAnyOf: z.array(Authorization),
  }),
);

export const SupportEntityTypeCore = EntityTypeCore.extend({
  supportActionsInfo: EntityTypeSupportEntityActionIds,
});

export type IEntityTypeCore = z.infer<typeof EntityTypeCore>;
export type ISupportEntityTypeCore = z.infer<typeof SupportEntityTypeCore>;
export type IEntityType = z.infer<typeof EntityType>;

export type IEntityTypeSupportActionIds = z.infer<typeof EntityTypeSupportEntityActionIds>;
