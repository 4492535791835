import type { IActionCore, IEntityTypeCore, IRelationCore, IStateMachine, ITargetEntityTypeCore } from "@archetype/dsl";
import {
  ActionCore,
  AiAgentDefinition,
  Authorization,
  Autofill,
  Column,
  EntityTypeColor,
  EntityTypeCore,
  EntityTypeDisplayMetadata,
  EntityTypeShapeName,
  RelationCore,
  StateMachine,
  StateMachineState,
  StateMachineStateTransition,
  ViewField,
} from "@archetype/dsl";
import type {
  IActionId,
  IApplicationGroupId,
  IEntityTypeId,
  IOrganizationId,
  IRelationId,
  IStateMachineMetadataId,
  IStateMachineMetadataVersionId,
} from "@archetype/ids";
import {
  ActionId,
  ApplicationGroupId,
  ColumnId,
  EntityTypeId,
  RelationId,
  StateId,
  TransitionId,
} from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { ActionEdit } from "../../apiTypes/LoadedActionType";

export const SetEntityTypeAuthorizationOperation = z.object({
  type: z.literal("setEntityTypeAuthorization"),
  entityTypeId: EntityTypeId,
  /**
   * An entity with this entity type can be viewed if any of the authorizations are satisfied
   */
  authorizedByAnyOf: z.array(Authorization).optional(),
  authorizedForAnyoneWithLink: z.boolean().optional(),
});
export const SetActivityLogAuthorizationOperation = z.object({
  type: z.literal("setActivityLogAuthorization"),
  entityTypeId: EntityTypeId,
  /**
   * An entity with this entity type can be commented on if any of the authorizations are satisfied
   */
  activityLogAuthorizedByAnyOf: z.array(Authorization).optional(),
});

export const SetEntityTypeStateAuthorizationsOperation = z.object({
  type: z.literal("setEntityTypeStateAuthorizations"),
  entityTypeId: EntityTypeId,
  stateId: StateId,
  /**
   * An entity with this entity type can be viewed if any of the authorizations are satisfied for the given state
   */
  authorizedByAnyOf: z.array(Authorization).optional(),
  authorizedForAnyoneWithLink: z.boolean().optional(),
});

export const SetActionAuthorizationOperation = z.object({
  type: z.literal("setActionAuthorization"),
  actionId: ActionId,
  /**
   * An entity with this entity type can be viewed if any of the authorizations are satisfied
   */
  authorizedByAnyOf: z.array(Authorization),
  authorizedForAnyoneWithLink: z.boolean().optional(),
});

export const ReplaceViewFieldOperation = z.object({
  type: z.literal("replaceViewField"),

  /**
   * Will replace in all the following actions if provided, or only on the entity type if not
   */
  actionIds: ActionId.array().nullable(),

  entityTypeId: EntityTypeId,
  /**
   * Will be replaced where used
   */
  oldViewField: ViewField,
  /**
   * To replace with
   */
  newViewField: ViewField,
});

export const SetEntityTypeShapeNameOperation = z.object({
  type: z.literal("setEntityTypeShapeName"),
  entityTypeId: EntityTypeId,
  newShape: EntityTypeShapeName,
});

export const SetEntityTypeColorOperation = z.object({
  type: z.literal("setEntityTypeColor"),
  entityTypeId: EntityTypeId,
  newColor: EntityTypeColor,
});

export const SetEntityTypeDisplayMetadataOperation = z.object({
  type: z.literal("setEntityTypeDisplayMetadata"),
  entityTypeId: EntityTypeId,
  newDisplayMetadata: EntityTypeDisplayMetadata,
});

export const DeleteEntityTypeOperation = z.object({
  type: z.literal("deleteEntityType"),
  entityTypeId: EntityTypeId,
});

export const CreateOrEditColumnOperation = z.object({
  type: z.literal("createOrEditColumn"),
  entityTypeId: EntityTypeId,
  column: Column,
});

export const DeleteColumnOperation = z.object({
  type: z.literal("deleteColumn"),

  /**
   * Will delete references in those actions if provided, or only on the entity type if not
   */
  actionIds: ActionId.array().nullable(),
  entityTypeId: EntityTypeId,
  columnIds: ColumnId.array(),
});

export const CreateOrEditRelationOperation = z.object({
  type: z.literal("createOrEditRelation"),
  relation: RelationCore,
});

export const DeleteRelationOperation = z.object({
  type: z.literal("deleteRelation"),
  relationId: RelationId,
  /**
   * Will delete references in those actions if provided, or only on the relation if not
   */
  actionIds: ActionId.array().nullable(),
});

export const SetViewFieldsForStateOperation = z.object({
  type: z.literal("setViewFieldsForState"),
  entityTypeId: EntityTypeId,
  stateId: StateId,
  fields: ViewField.array(),
});

export const EditActionNameOperation = z.object({
  type: z.literal("editActionName"),
  actionId: ActionId,
  newName: ReadableString,
});

export const SetActionOperation = z.object({
  type: z.literal("setAction"),
  applicationGroupId: ApplicationGroupId,
  targetEntityTypeId: EntityTypeId,
  action: ActionEdit,
});

export const EditAutofillOperation = z.object({
  type: z.literal("editAutofill"),
  entityTypeId: EntityTypeId,
  viewField: ViewField,
  autofillConfig: Autofill.nullable(),
});

export const AiAgentForStateOperation = z.object({
  type: z.literal("setAiAgentForState"),
  applicationGroupId: ApplicationGroupId,
  stateId: StateId,
  aiAgentDefinition: AiAgentDefinition.nullable(),
});

export const DeleteStateOperattion = z.object({
  type: z.literal("deleteState"),
  applicationGroupId: ApplicationGroupId,
  targetEntityTypeId: EntityTypeId,
  stateId: StateId,
});

export const DeleteTransitionOperation = z.object({
  type: z.literal("deleteTransition"),
  applicationGroupId: ApplicationGroupId,
  targetEntityTypeId: EntityTypeId,
  transitionId: TransitionId,
});

export const EditStateOperation = z.object({
  type: z.literal("editState"),
  applicationGroupId: ApplicationGroupId,
  targetEntityTypeId: EntityTypeId,
  updatedState: StateMachineState,
});

export const CreateNewStateOperation = z.object({
  type: z.literal("createNewState"),
  applicationGroupId: ApplicationGroupId,
  targetEntityTypeId: EntityTypeId,
  newState: StateMachineState,
  newHappyPath: z.array(StateId),
  newTransition: StateMachineStateTransition.omit({
    actionId: true,
  }),
  newTransitionAction: ActionCore.omit({
    id: true,
  }),
  /**
   * If true, the state machine will now replace the initial state with this new state, note the appropriate
   * edits to actions should also be provided (e.g. changing the create action to a new target state)
   */
  asInitialState: z.boolean(),
});

export const CreateNewTransitionOperation = z.object({
  type: z.literal("createNewTransition"),
  applicationGroupId: ApplicationGroupId,
  targetEntityTypeId: EntityTypeId,
  newTransition: StateMachineStateTransition.omit({
    actionId: true,
  }),
  newTransitionAction: ActionCore.omit({
    id: true,
  }),
  newHappyPath: z.array(StateId),
});

export const CreateNewSupportEntityTypeOperation = z.object({
  type: z.literal("createNewSupportEntityType"),
  entityType: EntityTypeCore.omit({
    deleteActionId: true,
  }),
});

export const CreateNewProcessEntityTypeOperation = z.object({
  type: z.literal("createNewProcessEntityType"),
  entityType: EntityTypeCore.omit({
    deleteActionId: true,
  }),
  stateMachine: StateMachine,
  actions: ActionCore.array(),
  applicationGroupId: ApplicationGroupId,
});

export const CreateNewRelationOperation = z.object({
  type: z.literal("createNewRelation"),
  relation: RelationCore,
});

export const EditOperation = z.discriminatedUnion("type", [
  SetEntityTypeAuthorizationOperation,
  SetActivityLogAuthorizationOperation,
  SetEntityTypeStateAuthorizationsOperation,
  SetActionAuthorizationOperation,
  ReplaceViewFieldOperation,
  SetEntityTypeShapeNameOperation,
  SetEntityTypeColorOperation,
  SetEntityTypeDisplayMetadataOperation,
  CreateOrEditColumnOperation,
  DeleteColumnOperation,
  CreateOrEditRelationOperation,
  DeleteRelationOperation,
  SetViewFieldsForStateOperation,
  EditActionNameOperation,
  SetActionOperation,
  AiAgentForStateOperation,
  EditAutofillOperation,
  DeleteStateOperattion,
  DeleteTransitionOperation,
  EditStateOperation,
  CreateNewStateOperation,
  CreateNewTransitionOperation,
  CreateNewSupportEntityTypeOperation,
  CreateNewRelationOperation,
  CreateNewProcessEntityTypeOperation,
]);

export type IReplaceViewFieldOperation = z.infer<typeof ReplaceViewFieldOperation>;
export type ICreateOrEditColumnOperation = z.infer<typeof CreateOrEditColumnOperation>;
export type IDeleteColumnOperation = z.infer<typeof DeleteColumnOperation>;
export type ICreateOrEditRelationOperation = z.infer<typeof CreateOrEditRelationOperation>;
export type IDeleteRelationOperation = z.infer<typeof DeleteRelationOperation>;
export type ISetViewFieldsForStateOperation = z.infer<typeof SetViewFieldsForStateOperation>;
export type ISetActionOperation = z.infer<typeof SetActionOperation>;
export type ISetEntityTypeShapeNameOperation = z.infer<typeof SetEntityTypeShapeNameOperation>;
export type ISetEntityTypeColorOperation = z.infer<typeof SetEntityTypeColorOperation>;
export type ISetEntityTypeDisplayMetadataOperation = z.infer<typeof SetEntityTypeDisplayMetadataOperation>;
export type IAiAgentForStateOperation = z.infer<typeof AiAgentForStateOperation>;
export type IEditActionNameOperation = z.infer<typeof EditActionNameOperation>;
export type IEditAutofillOperation = z.infer<typeof EditAutofillOperation>;
export type IDeleteStateOperattion = z.infer<typeof DeleteStateOperattion>;
export type IDeleteTransitionOperation = z.infer<typeof DeleteTransitionOperation>;
export type IEditStateOperation = z.infer<typeof EditStateOperation>;
export type ICreateNewSupportEntityTypeOperation = z.infer<typeof CreateNewSupportEntityTypeOperation>;
export type ICreateNewProcessEntityTypeOperation = z.infer<typeof CreateNewProcessEntityTypeOperation>;
export type ICreateNewRelationOperation = z.infer<typeof CreateNewRelationOperation>;
export type ISetActionAuthorizationOperation = z.infer<typeof SetActionAuthorizationOperation>;
export type IEditOperation = z.infer<typeof EditOperation>;

interface IDataModelModelEdits {
  /**
   * Context for all the edits
   */
  organizationId: IOrganizationId;
  /**
   * New or edited support entity types and the target entity types.
   * New or edited means it should be saved accordingly, and update caches accordingly (both adding and replacing)
   */
  newOrEditedEntityTypes: Record<IEntityTypeId, IEntityTypeCore>;
  /**
   * New or edited relations, generally between new entity types and (existing or new entity types).
   */
  newOrEditedRelations: Record<IRelationId, IRelationCore>;
  /**
   * Deleted relations, generally between new entity types and (existing or new entity types).
   */
  deletedRelations: IRelationId[];
  /**
   * New or edited actions
   */
  newOrEditedActions: Record<IActionId, IActionCore>;
  /**
   * Deleted actions, generally because of removing some transitions in state machine
   */
  deletedActions: IActionId[];
}

export interface IOperationsEdits extends IDataModelModelEdits {
  stateMachineEdits: Record<IApplicationGroupId, IStateMachine>;
}

/**
 * Useful to enforce presence of state machine, e.g. after onboarding
 */
export type IOperationsEditsWithStateMachine = Omit<IOperationsEdits, "stateMachineEdits"> &
  Required<Pick<IOperationsEdits, "stateMachineEdits">> & {
    /**
     * Should also be present in the all entity types list
     */
    targetEntityType: ITargetEntityTypeCore;
  };

export interface ISaveableOperationsEdits extends Omit<IOperationsEdits, "newOrEditedEntityTypes"> {
  /**
   * Enforce generating the support action types for the entity type if and only if it is not a target entity type
   */
  newOrEditedEntityTypes: {
    [entityTypeId: IEntityTypeId]:
      | ITargetEntityTypeCore
      | (IEntityTypeCore & {
          supportActionsInfo: NonNullable<IEntityTypeCore["supportActionsInfo"]>;
          targetEntityTypeApplicationGroupId: null;
        });
  };
}

interface IComputedFromStateMachineInfo {
  id: IStateMachineMetadataId;
  versionId: IStateMachineMetadataVersionId;
}

// Not fully necessary to have both this type and the one above but makes saving the data model easier without passing the stateMachine
// and updating the cache with the actions cleaner
export interface ISaveableDataModelEdits extends Omit<IDataModelModelEdits, "newOrEditedEntityTypes"> {
  /**
   * New or edited support entity types and the target entity types.
   * New or edited means it should be saved accordingly, and update caches accordingly (both adding and replacing)
   */
  newOrEditedEntityTypes: Record<
    IEntityTypeId,
    {
      entityType: IEntityTypeCore;
      /**
       * Only present if the entityType is the target of the currently edited stateMachine
       */
      computedFromStateMachineInfo: IComputedFromStateMachineInfo | undefined;
    }
  >;
}

export interface ICollectedOperationsDataIdentifiers {
  /**
   * Should be a single unique entry at a time for now, so can fail early otherwise, or batch in multiple edit operations
   */
  applicationGroupIds: IApplicationGroupId[];
  allActionsForStateMachine: IApplicationGroupId[];
  entityTypeIds: IEntityTypeId[];
  relationIds: IRelationId[];
  supportActionsForEntityTypeIds: IEntityTypeId[];
  actionIds: IActionId[];
}
