import { DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuItem, Icon, Input } from "@archetype/ui";
import assertNever from "assert-never";
import React, { useCallback, useState } from "react";

import type { IDataTableHeaderCheckboxCell, IDataTableHeaderMenuItem } from "./Header";

export interface IHeaderMenu {
  items: IDataTableHeaderMenuItem[];
  searchable?: boolean;
}

export function HeaderMenu({ items, searchable = false }: IHeaderMenu): React.ReactNode {
  const [search, setSearch] = useState("");

  const filteredItems = items.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  }, []);

  const handleCheckboxItemClick = useCallback(
    (item: IDataTableHeaderCheckboxCell) => (): void => {
      item.onChange(!item.checked);
    },
    [],
  );

  return (
    <DropdownMenuContent className="w-56">
      {searchable ? (
        <div className="p-2">
          <Input
            autoFocus={true}
            className="h-8"
            placeholder="Search..."
            value={search}
            onChange={handleSearchChange}
          />
        </div>
      ) : null}
      {filteredItems.map((item) => {
        switch (item.type) {
          case "icon": {
            const { onClick: handleItemClick } = item;

            return (
              <DropdownMenuItem key={item.label} className="flex items-center gap-2" onClick={handleItemClick}>
                <Icon name={item.icon} />
                <span>{item.label}</span>
              </DropdownMenuItem>
            );
          }
          case "checkbox": {
            return (
              <DropdownMenuCheckboxItem key={item.label} checked={item.checked} onClick={handleCheckboxItemClick(item)}>
                {item.label}
              </DropdownMenuCheckboxItem>
            );
          }
          default: {
            assertNever(item);
          }
        }
      })}
    </DropdownMenuContent>
  );
}
