import assertNever from "assert-never";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { camelCase } from "lodash";
import { DateTime } from "luxon";

import type { IColumnType } from "../../../schemas/dataModel/Column";
import type { IEntityColumnValue } from "../../../schemas/dataModel/EntityValue";
import type { IViewFieldValue } from "../../../schemas/dataModel/ViewFieldValue";
import { FieldValueParser } from "./fieldValueParser";

export const viewEntityFieldValueAsString = (
  columnValue: IViewFieldValue | null | undefined,
  columnType: IColumnType | undefined,
): string | null => {
  if (columnValue == null || columnValue.type === "null") {
    return null;
  }

  if (columnType?.type === "statusEnum") {
    const statusInfo =
      columnType.allowedValues.find((v) => v.id === columnValue.value) ??
      columnType.archivedValues.find((v) => v.id === columnValue.value);

    if (statusInfo != null) {
      return statusInfo.readableValue;
    }
  }

  return FieldValueParser.toString(columnValue) ?? null;
};

export const convertEntityColumnValueFromReadableValue = (
  columnType: IColumnType | undefined,
  readableValue: unknown,
): IEntityColumnValue => {
  if (readableValue == null) {
    return {
      type: "null",
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-base-to-string -- this might be an object, and this might stringify to `[object Object]`, but that's not a problem
  const stringifiedReadableValue = String(readableValue);

  switch (columnType?.type) {
    case undefined: {
      return {
        type: "string",
        value: stringifiedReadableValue,
      };
    }
    case "shortText":
    case "longText":
    case "email":
    case "geolocation":
    case "url": {
      return {
        type: "string",
        value: stringifiedReadableValue,
      };
    }
    case "enum": {
      if (Array.isArray(readableValue)) {
        return {
          type: "array",
          value: readableValue as string[],
        };
      }

      let arrayItems = stringifiedReadableValue.split(";");

      if (arrayItems.length === 1) {
        arrayItems = stringifiedReadableValue.split(",");
      }
      if (arrayItems.length === 0) {
        return {
          type: "null",
        };
      }

      arrayItems = arrayItems
        .map((item) => String(item).trim())
        .map((item) => {
          // Find the matching enumAllowedValue case-insensitively
          const matchedValue = columnType.enumAllowedValues?.find(
            (allowedValue) => allowedValue.toLowerCase() === item.toLowerCase(),
          );

          return matchedValue ?? item;
        })
        .filter((item) => columnType.enumAllowedValues?.includes(item) === true);

      return {
        type: "array",
        value: arrayItems,
      };
    }
    case "phone": {
      const phoneNumber = stringifiedReadableValue;
      const parsedNumber = parsePhoneNumberFromString(phoneNumber);

      if (parsedNumber && parsedNumber.isValid()) {
        return {
          type: "string",
          value: parsedNumber.format("E.164"),
        };
      }

      // removing any chars that are not a digit or a +
      return {
        type: "string",
        value: phoneNumber.replace(/[^\d+]/g, ""),
      };
    }
    case "statusEnum": {
      const optimisticLlmStringValue = camelCase(stringifiedReadableValue).toLowerCase();
      const statusInfo = columnType.allowedValues.find(
        (v) => camelCase(v.readableValue).toLowerCase() === optimisticLlmStringValue,
      );

      return {
        type: "string",
        value: statusInfo?.id ?? stringifiedReadableValue,
      };
    }
    case "number": {
      const numberValue = Number(readableValue);

      if (readableValue === "" || isNaN(numberValue)) {
        return {
          type: "null",
        };
      }

      return {
        type: "number",
        value: numberValue,
      };
    }
    case "boolean": {
      return {
        type: "boolean",
        value: !(stringifiedReadableValue.toLowerCase() in ["false", "no", "", "wrong", "n/a"]),
      };
    }

    case "date": {
      const date = DateTime.fromJSDate(new Date(stringifiedReadableValue));

      return date.isValid
        ? {
            type: "date",
            value: date.toString(),
          }
        : { type: "null" };
    }

    case "timestamp": {
      const ts = DateTime.fromJSDate(new Date(stringifiedReadableValue));

      return ts.isValid
        ? {
            type: "timestamp",
            value: ts.toString(),
          }
        : { type: "null" };
    }

    case "file": {
      return {
        type: "string",
        value: stringifiedReadableValue,
      };
    }
  }
};

export const convertReadableValueToSearchableValue = (
  columnType: IColumnType,
  readableValue: string,
): string | undefined => {
  switch (columnType.type) {
    case "shortText":
    case "longText":
    case "email":
    case "geolocation":
    case "url":
    case "phone":
    case "date":
    case "timestamp":
    case "file":
    case "number":
    case "enum": {
      return readableValue;
    }
    case "statusEnum": {
      const optimisticLlmStringValue = camelCase(String(readableValue)).toLowerCase();
      const statusInfo = columnType.allowedValues.find(
        (v) => camelCase(v.readableValue).toLowerCase() === optimisticLlmStringValue,
      );

      return statusInfo?.id ?? String(readableValue);
    }
    case "boolean": {
      // Not searchable
      return undefined;
    }
  }
};

export function entityColumnValueToStoredColumnValue(
  columnValue: IEntityColumnValue,
): string | boolean | number | Array<string> | null {
  switch (columnValue.type) {
    case "null": {
      return null;
    }
    case "string":
    case "number":
    case "integer":
    case "array":
    case "boolean": {
      return columnValue.value;
    }
    case "date": {
      return DateTime.fromISO(columnValue.value, { setZone: true }).toFormat("yyyy-MM-dd");
    }
    case "timestamp": {
      return columnValue.value;
    }
    case "file": {
      return JSON.stringify(columnValue.value);
    }
    default: {
      assertNever(columnValue);
    }
  }
}
