import type { IActionCore, IColumn, IEntityTypeCore, IRelationCore } from "@archetype/dsl";
import type { IActionId, IColumnId, IEntityTypeId, IRelationId } from "@archetype/ids";
import { isNonNullable, keyByNoUndefined, mapValues, pickByGuarded } from "@archetype/utils";

import type { ILoadedAction, ILoadedActionInput } from "../apiTypes/LoadedActionType";
import type { ILoadedViewField } from "../apiTypes/LoadedViewField";
import { orderActionInputsWithDependencies } from "../dataModel/orderActionInputsWithDependencies";
import type { IDependenciesFieldIdsInfo } from "../dependencies/dependencyUtils";
import { createLoadedViewField } from "./viewFields";

export function convertLoadedActionToActionCore(loadedAction: ILoadedAction): IActionCore {
  return loadedAction;
}

// eslint-disable-next-line max-params -- TODO fields order fix
export function convertActionCoreToLoadedAction(
  action: IActionCore,
  columnsById: Record<IColumnId, IColumn>,
  relationsById: Partial<Record<IRelationId, IRelationCore>>,
  /**
   * If provided, will be used to reorder fields if necessary so that a dependent live field is after its dependency
   */
  dependenciesInfo: IDependenciesFieldIdsInfo | undefined,
): ILoadedAction {
  const reorderedInputs =
    dependenciesInfo == null
      ? action.actionDefinition.inputs
      : orderActionInputsWithDependencies(action.actionDefinition.inputs, dependenciesInfo);

  const inputs: ILoadedActionInput[] = reorderedInputs
    .map((input) => {
      const loadedViewField = createLoadedViewField(input.viewField, columnsById, relationsById);

      if (loadedViewField == null) {
        throw new Error(`View field not found: ${input.viewField.id} in action ${action.id}`);
      }

      return { ...input, viewField: loadedViewField };
    })
    .filter(isNonNullable);

  const contextualFields: ILoadedViewField[] = action.actionDefinition.contextualFields
    .map((viewField) => {
      const loadedViewField = createLoadedViewField(viewField, columnsById, relationsById);

      if (loadedViewField == null) {
        throw new Error(`View field not found: ${viewField.id} in action ${action.id}`);
      }

      return loadedViewField;
    })
    .filter(isNonNullable);

  const emailSideEffectFields = action.actionDefinition.sideEffects?.email.viewFieldsToSend
    .map((viewField) => {
      const loadedViewField = createLoadedViewField(viewField, columnsById, relationsById);

      return loadedViewField == null ? undefined : loadedViewField;
    })
    .filter(isNonNullable);

  return {
    ...action,
    actionDefinition: {
      ...action.actionDefinition,
      inputs,
      contextualFields,
      sideEffects:
        action.actionDefinition.sideEffects == null
          ? undefined
          : {
              email: {
                ...action.actionDefinition.sideEffects.email,
                viewFieldsToSend: emailSideEffectFields ?? [],
              },
            },
    },
  };
}

export const makeLoadedActionsFromRecord = ({
  actions,
  entityTypesById,
  relationsById,
  dependenciesInfo,
}: {
  actions: Record<IActionId, IActionCore | undefined>;
  entityTypesById: Partial<Record<IEntityTypeId, IEntityTypeCore>>;
  relationsById: Partial<Record<IRelationId, IRelationCore>>;
  dependenciesInfo: Partial<Record<IEntityTypeId, IDependenciesFieldIdsInfo>>;
}): Record<IActionId, ILoadedAction> => {
  const columnsByEntityTypeId = mapValues(entityTypesById, (entityType) =>
    entityType == null ? undefined : keyByNoUndefined(entityType.columns, (col) => col.id),
  );

  const definedActions: Record<IActionId, IActionCore> = pickByGuarded(actions, isNonNullable);

  const res: Record<IActionId, ILoadedAction> = pickByGuarded(
    mapValues(definedActions, (action) =>
      convertActionCoreToLoadedAction(
        action,
        columnsByEntityTypeId[action.entityTypeId] ?? {},
        relationsById,
        dependenciesInfo[action.entityTypeId],
      ),
    ),
    isNonNullable,
  );

  return res;
};

export const makeLoadedActions = ({
  actions,
  entityTypesById,
  relationsById,
  dependenciesInfo,
}: {
  actions: IActionCore[];
  entityTypesById: Partial<Record<IEntityTypeId, IEntityTypeCore>>;
  relationsById: Partial<Record<IRelationId, IRelationCore>>;
  dependenciesInfo: Partial<Record<IEntityTypeId, IDependenciesFieldIdsInfo>>;
}): ILoadedAction[] => {
  const columnsByEntityTypeId = mapValues(entityTypesById, (entityType) =>
    entityType == null ? undefined : keyByNoUndefined(entityType.columns, (col) => col.id),
  );

  return actions.map((action) =>
    convertActionCoreToLoadedAction(
      action,
      columnsByEntityTypeId[action.entityTypeId] ?? {},
      relationsById,
      dependenciesInfo[action.entityTypeId],
    ),
  );
};
