import type {
  IColumnOrConditions,
  ICrossColumnAndConditions,
  IDataLoadingQueryColumnOrFilters,
  IDataLoadingQueryFilters,
  IRelatedToFilter,
  IRelationDirection,
} from "@archetype/dsl";
import { computeRelationViewFieldId } from "@archetype/dsl";
import type { IColumnId, IRelationId, IViewFieldId } from "@archetype/ids";
import { pickBy } from "@archetype/utils";
import { isEqual } from "lodash";
import { useCallback } from "react";

function createColumnFilter(
  newColumnFilterValue: IDataLoadingQueryColumnOrFilters | undefined,
): IColumnOrConditions | undefined {
  return newColumnFilterValue == null
    ? undefined
    : {
        type: "or",
        rawOrConditions: newColumnFilterValue,
        oredAndConditions: [],
      };
}

function updateColumnFilter({
  filters,
  filterableColumnIdsSet,
  columnId,
  newColumnFilterValue,
}: {
  filters: IDataLoadingQueryFilters | undefined;
  filterableColumnIdsSet: Set<IColumnId>;
  columnId: IColumnId;
  newColumnFilterValue: IDataLoadingQueryColumnOrFilters | undefined;
}): IDataLoadingQueryFilters | undefined {
  const newColumnFilters = createColumnFilter(newColumnFilterValue);

  // Skip if no change
  if (isEqual(newColumnFilters, filters?.perColumn[columnId])) {
    return undefined;
  }

  const mergedPerColumnFilters = {
    ...filters?.perColumn,
    [columnId]: newColumnFilters,
  };

  return {
    type: "and",
    perColumn: pickBy(mergedPerColumnFilters, (_val, innerColId) =>
      filterableColumnIdsSet.has(innerColId as IColumnId),
    ),
    andedCrossColumnOrConditions: filters?.andedCrossColumnOrConditions ?? [],
    andedRelatedToFilters: filters?.andedRelatedToFilters ?? [],
  };
}

function updateRelationFilter({
  filters,
  filterableRelationKeysSet,
  relationId,
  direction,
  newRelationFilterValueConditions,
}: {
  filters: IDataLoadingQueryFilters | undefined;
  filterableRelationKeysSet: Set<IViewFieldId>;
  relationId: IRelationId;
  direction: IRelationDirection;
  newRelationFilterValueConditions: ICrossColumnAndConditions | undefined;
}): IDataLoadingQueryFilters | undefined {
  if (newRelationFilterValueConditions == null) {
    if (filters?.andedRelatedToFilters?.find((f) => f.relationId === relationId && f.direction === direction) == null) {
      return undefined;
    }

    return {
      type: "and",
      perColumn: filters.perColumn,
      andedCrossColumnOrConditions: filters.andedCrossColumnOrConditions,
      andedRelatedToFilters: (filters.andedRelatedToFilters ?? []).filter(
        (f) => f.relationId !== relationId || f.direction !== direction,
      ),
    };
  }

  const relationKey: IViewFieldId = computeRelationViewFieldId(relationId, direction);

  if (!filterableRelationKeysSet.has(relationKey)) {
    return undefined;
  }

  const existingFilter = (filters?.andedRelatedToFilters ?? []).find(
    (f) => f.relationId === relationId && f.direction === direction,
  );

  const newRelationFilterValue: IRelatedToFilter = {
    type: "relatedTo",
    relationId,
    direction,
    filters: newRelationFilterValueConditions,
  };

  // Skip if no change
  if (isEqual(existingFilter, newRelationFilterValue)) {
    return undefined;
  }

  const mergedAndedRelatedToFilters = [
    ...(filters?.andedRelatedToFilters ?? []).filter((f) => f.relationId !== relationId || f.direction !== direction),
    newRelationFilterValue,
  ];

  return {
    type: "and",
    perColumn: filters?.perColumn ?? {},
    andedCrossColumnOrConditions: filters?.andedCrossColumnOrConditions ?? [],
    andedRelatedToFilters: mergedAndedRelatedToFilters,
  };
}

export function useFilterUpdates({
  filters,
  filterableColumnIdsSet,
  filterableRelationKeysSet,
  handleChangeFilters,
}: {
  filters: IDataLoadingQueryFilters | undefined;
  filterableColumnIdsSet: Set<IColumnId>;
  filterableRelationKeysSet: Set<IViewFieldId>;
  handleChangeFilters: (newFilters: IDataLoadingQueryFilters) => void;
}): {
  handleUpdateFilterValue: (
    columnId: IColumnId,
  ) => (newColumnFilterValue: IDataLoadingQueryColumnOrFilters | undefined) => void;
  handleUpdateRelationFilterValue: (
    relationId: IRelationId,
    direction: IRelationDirection,
  ) => (newRelationFilterValueConditions: ICrossColumnAndConditions | undefined) => void;
} {
  const handleUpdateFilterValue = useCallback(
    (columnId: IColumnId) =>
      (newColumnFilterValue: IDataLoadingQueryColumnOrFilters | undefined): void => {
        const results = updateColumnFilter({
          filters,
          filterableColumnIdsSet,
          columnId,
          newColumnFilterValue,
        });

        if (results != null) {
          handleChangeFilters(results);
        }
      },
    [filters, handleChangeFilters, filterableColumnIdsSet],
  );

  const handleUpdateRelationFilterValue = useCallback(
    (relationId: IRelationId, direction: IRelationDirection) =>
      (newRelationFilterValueConditions: ICrossColumnAndConditions | undefined): void => {
        const results = updateRelationFilter({
          filters,
          filterableRelationKeysSet,
          relationId,
          direction,
          newRelationFilterValueConditions,
        });

        if (results != null) {
          handleChangeFilters(results);
        }
      },
    [filters, handleChangeFilters, filterableRelationKeysSet],
  );

  return {
    handleUpdateFilterValue,
    handleUpdateRelationFilterValue,
  };
}

export const __test = {
  createColumnFilter,
  updateColumnFilter,
  updateRelationFilter,
};
