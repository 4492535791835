import { ActionId, ColumnId, EntityTypeId, RelationId, StateId } from "@archetype/ids";
import { z } from "zod";

export const FeatureBase = z.object({
  clientFacingTitle: z.string(),
  internalDescription: z.string(),
});
export type IFeatureBase = z.infer<typeof FeatureBase>;

export const WorkspaceFeature = FeatureBase.extend({
  type: z.literal("workspace"),
});
export type IWorkspaceFeature = z.infer<typeof WorkspaceFeature>;

export const WorkflowFeature = FeatureBase.extend({
  type: z.literal("workflow"),
  entityTypeId: EntityTypeId,
});
export type IWorkflowFeature = z.infer<typeof WorkflowFeature>;

export const EntityTypeFeature = FeatureBase.extend({
  type: z.literal("entityType"),
  entityTypeId: EntityTypeId,
});
export type IEntityTypeFeature = z.infer<typeof EntityTypeFeature>;

export const ActionFeature = FeatureBase.extend({
  type: z.literal("action"),
  actionId: ActionId,
  entityTypeId: EntityTypeId,
});
export type IActionFeature = z.infer<typeof ActionFeature>;

export const StateFeature = FeatureBase.extend({
  type: z.literal("state"),
  stateId: StateId,
  entityTypeId: EntityTypeId,
});
export type IStateFeature = z.infer<typeof StateFeature>;

export const ColumnFeature = FeatureBase.extend({
  type: z.literal("column"),
  columnId: ColumnId,
  entityTypeId: EntityTypeId,
});
export type IColumnFeature = z.infer<typeof ColumnFeature>;

export const RelationFeature = FeatureBase.extend({
  type: z.literal("relation"),
  relationId: RelationId,
});
export type IRelationFeature = z.infer<typeof RelationFeature>;

export const Feature = z.discriminatedUnion("type", [
  WorkspaceFeature,
  WorkflowFeature,
  ActionFeature,
  StateFeature,
  ColumnFeature,
  RelationFeature,
]);
export type IFeature = z.infer<typeof Feature>;
