import { Switch } from "@archetype/ui";
import React, { useCallback, useEffect } from "react";

import { useCellClickInteraction } from "../../hooks/useCellClickInteraction";
import type { IDataTableBooleanCell, IDataTableCommonCellProps } from "./api";

export function BooleanCell<TRowId extends string, TColId extends string>({
  cell,
  isCellSelected,
  isCellEditing,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTableBooleanCell<TRowId, TColId>>): React.ReactElement {
  const { value, onChange, readOnly, rowId, colId } = cell;

  const { handleCellClick } = useCellClickInteraction({
    isCellEditing,
    isCellSelected,
    readOnly,
    rowId,
    colId,
  });

  const handleChange = useCallback(
    (checked: boolean): void => {
      if (readOnly) {
        return;
      }

      if (checked !== value) {
        void onChange?.(checked);
      }
    },
    [onChange, readOnly, value],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "Enter" && isCellSelected && !readOnly) {
        handleChange(!value);

        event.preventDefault();
        event.stopPropagation();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return (): void => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isCellSelected, readOnly, value, handleChange]);

  return (
    <div className="flex h-full items-center px-2" onClick={handleCellClick}>
      <Switch checked={value} disabled={readOnly} small={true} onCheckedChange={handleChange} />
    </div>
  );
}
