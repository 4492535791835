import type { IColumnTypeId } from "@archetype/ids";
import { map } from "@archetype/utils";

import type { INonIdVersionIdentifiableFields } from "../../schemas/common/VersionIdentifiable";
import type { IComplexColumnType } from "../../schemas/dataModel/ColumnType";

export interface IFullColumnTypeTree {
  columnType: Readonly<IComplexColumnType>;
  child: Readonly<IFullColumnTypeTree> | null;
}

export interface IColumnTypeTree {
  columnType: Readonly<Omit<IComplexColumnType, INonIdVersionIdentifiableFields>>;
  child: Readonly<IColumnTypeTree> | null;
}

export type IColumnTypeTreeByTypeId = Readonly<Record<IColumnTypeId, IColumnTypeTree>>;

export function resolveTreeForAllColTypeIds(
  columnTypes: Record<IColumnTypeId, Omit<IComplexColumnType, INonIdVersionIdentifiableFields>>,
): IColumnTypeTreeByTypeId {
  const typeIdsToResolve: IColumnTypeId[] = map(columnTypes, (type) => type.id);
  const resolvedTrees: Record<IColumnTypeId, IColumnTypeTree> = {};

  while (typeIdsToResolve.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- This is asserted by the loop condition
    const typeId = typeIdsToResolve.shift()!;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- columnTypes is built from typeIdsToResolve so this must exist
    const type = columnTypes[typeId]!;

    let child: IColumnTypeTree | null = null;

    if ("child" in type.definition) {
      if (typeIdsToResolve.includes(type.definition.child)) {
        typeIdsToResolve.push(typeId);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- The above check ensures that all children are resolved
        child = resolvedTrees[type.definition.child]!;
      }
    }

    const resolvedTree = {
      columnType: type,
      child,
    };

    resolvedTrees[typeId] = resolvedTree;
  }

  return resolvedTrees;
}
