import type { IStateId, ITransitionId } from "@archetype/ids";
import { groupByNoUndefined, removeLoopsInArray } from "@archetype/utils";

import { createFileLogger } from "../../logger";

interface IMinimalStateMachine {
  initialState: { state: IStateId };
  stateTransitions: Array<{ id: ITransitionId; from: IStateId; to: IStateId }>;
}

const logger = createFileLogger("modifiedHappyPath");

/**
 * Modify the happy path without generation, this can be used on non LLM edit operations like remove states or transitions
 * On addind a new state/transition, i.e. an operation that can cause to extend the happy path, we will generate policy with an LLM anyway
 * so we can generate a new happy path with an LLM as well
 */
export const modifiedHappyPath = (initialHappyPath: IStateId[], newStateMachine: IMinimalStateMachine): IStateId[] => {
  const transitionsByFromState = groupByNoUndefined(newStateMachine.stateTransitions, (t) => t.from);

  const allStates = new Set(
    newStateMachine.stateTransitions.flatMap((t) => [t.from, t.to]).concat(newStateMachine.initialState.state),
  );
  // filter happy path with states that are still in new state machine, and remove loops
  const filteredHappyPath = removeLoopsInArray(initialHappyPath.filter((state) => allStates.has(state)));

  // Remove loops if any

  if (filteredHappyPath.length === 0) {
    logger.error({ initialHappyPath, newStateMachine }, "Empty filter happy path");

    return [];
  }

  // Initial state check
  // if the new start state is not the first state of happy path, needs to be fixed
  if (filteredHappyPath[0] !== newStateMachine.initialState.state) {
    if (
      transitionsByFromState[newStateMachine.initialState.state]?.some((t) => t.to === filteredHappyPath[0]) === true
    ) {
      //  if there is a transition from the new start state to the first state of happy path, add the new start state at beginning of happy path
      filteredHappyPath.unshift(newStateMachine.initialState.state);
      logger.debug({ initialHappyPath, filteredHappyPath }, "Adding new initial state to beginning");
    } else {
      // find the new start in the happy path
      const newStartIndex = filteredHappyPath.findIndex((state) => state === newStateMachine.initialState.state);

      // if it's present, remove all the states before it
      if (newStartIndex !== -1) {
        logger.debug(
          { initialHappyPath, filteredHappyPath, newStartIndex },
          "Start state found in happy path, removing elements before",
        );
        filteredHappyPath.splice(0, newStartIndex);
      } else {
        logger.error(
          { initialHappyPath, filteredHappyPath, newStateMachine },
          "New start state not found in happy path",
        );

        return [newStateMachine.initialState.state];
      }
    }
  }

  // Check if the there are still transitions for each consecutive step
  // and that starts by the initial step

  const indexOfFirstIncorrectStateInPath = filteredHappyPath.findIndex((toState, idx) => {
    if (idx === 0) {
      // Already covered by the initial transition check above
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe with logic
    const fromState = filteredHappyPath[idx - 1]!;

    const hasTransitionForConsecutiveStates = transitionsByFromState[fromState]?.some((t) => t.to === toState) === true;

    return !hasTransitionForConsecutiveStates;
  });

  if (indexOfFirstIncorrectStateInPath === -1) {
    // Happy path is correct, return it
    logger.debug({ initialHappyPath, filteredHappyPath }, "Valid happy path");

    return filteredHappyPath;
  }

  logger.debug(
    {
      initialHappyPath,
      filteredHappyPath,
      indexOfFirstIncorrectStateInPath,
    },
    "Incorrect happy path index",
  );

  // keep only until the first incorrect state
  filteredHappyPath.splice(indexOfFirstIncorrectStateInPath);

  return filteredHappyPath;
};
