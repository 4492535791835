import { AutoLinkPlugin as LexicalAutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import React from "react";

// URL matching regex used to identify URLs in text
const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const EMAIL_MATCHER =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

interface IAutoLinkMatch {
  index: number;
  length: number;
  text: string;
  url: string;
}

const MATCHERS = [
  (text: string): IAutoLinkMatch | null => {
    const match = URL_MATCHER.exec(text);

    if (match === null) {
      return null;
    }
    const fullMatch = match[0];

    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith("http") ? fullMatch : `https://${fullMatch}`,
    };
  },
  (text: string): IAutoLinkMatch | null => {
    const match = EMAIL_MATCHER.exec(text);

    if (match === null) {
      return null;
    }
    const fullMatch = match[0];

    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: `mailto:${fullMatch}`,
    };
  },
];

export const AutoLinkPlugin = (): React.JSX.Element => {
  return <LexicalAutoLinkPlugin matchers={MATCHERS} />;
};
