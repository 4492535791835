import type { IEntityTypeCore } from "@archetype/dsl";
import { uniqBy } from "lodash";

import type { ICreateOrEditColumnOperation, IOperationsEdits } from "./operations";

export const applyCreateOrEditColumnOperation = (
  operation: ICreateOrEditColumnOperation,
  currentInfo: IOperationsEdits,
): IOperationsEdits => {
  const { column: editedColumn, entityTypeId } = operation;

  const entityType = currentInfo.newOrEditedEntityTypes[entityTypeId];

  if (entityType == null) {
    throw new Error("Entity type not found");
  }

  const existingColumnIds = new Set(entityType.columns.map((c) => c.id));
  const isNewColumn = !existingColumnIds.has(editedColumn.id);

  const editedEntityType: IEntityTypeCore = {
    ...entityType,
    columns: uniqBy(
      isNewColumn
        ? entityType.columns.concat(editedColumn)
        : entityType.columns.map((c) => (c.id === editedColumn.id ? editedColumn : c)),
      "id",
    ),
  };

  return {
    ...currentInfo,
    newOrEditedEntityTypes: {
      ...currentInfo.newOrEditedEntityTypes,
      [editedEntityType.id]: editedEntityType,
    },
  };
};
