import type { IEmailExtractedActionInput } from "@archetype/dsl";
import type { IViewFieldId } from "@archetype/ids";
import { isNonNullable, map } from "@archetype/utils";

import type { ILoadedViewField } from "../apiTypes/LoadedViewField";

/**
 *
 * This takes an raw email text (or any text), and a set of extracted data from that text that are described
 * by a viewFieldId and a location (start/end char index), and the text at that location with an annotation formatted text
 * so that it can be rendered by the EmailComposer component in our ui-archetype library.
 * The format of the annotation is `{A[${originalText}](${popoverText})}`
 * If the field is not found, we still show as an annotation but render a space " " as the popover
 *
 */
export const makeAnnotatedEmailFromExtractedInputs = ({
  email,
  emailExtractedData,
  viewFieldById,
}: {
  email: string;
  emailExtractedData: Partial<Record<IViewFieldId, IEmailExtractedActionInput>>;
  viewFieldById: Record<IViewFieldId, ILoadedViewField>;
}): string => {
  // Convert the extracted data into an array of annotations with their positions
  const annotations = map(emailExtractedData, (extractedData, viewFieldId) => {
    if (extractedData == null || extractedData.foundInField !== "body") {
      return null;
    }

    const viewField = viewFieldById[viewFieldId];

    return {
      startIndex: extractedData.startCharIndexInclusive,
      endIndex: extractedData.endCharIndexExclusive,
      fieldName: viewField?.displayName ?? " ", // empty space important for rendering library
      extractedText: email.slice(extractedData.startCharIndexInclusive, extractedData.endCharIndexExclusive),
    };
  })
    .filter(isNonNullable)
    // Sort by start index in reverse order so we can replace from end to beginning
    // This prevents position shifts as we make replacements
    .sort((a, b) => b.startIndex - a.startIndex);

  // Make a copy of the email that we'll modify
  let annotatedEmail = email;

  // Replace each extracted section with its annotated version
  annotations.forEach((annotation) => {
    const before = annotatedEmail.slice(0, annotation.startIndex);
    const after = annotatedEmail.slice(annotation.endIndex);

    // Format: {A[extracted text](field name)}
    const annotatedText = `{A[${annotation.extractedText}](${annotation.fieldName})}`;

    annotatedEmail = before + annotatedText + after;
  });

  return annotatedEmail;
};
