import type {
  IColumnTypeId,
  ICompositeTypeId,
  ICompositeTypeInternalReferenceId,
  ICompositeTypeUnionedSetId,
} from "@archetype/ids";
import { forEach } from "@archetype/utils";

import type { INonIdVersionIdentifiableFields } from "../../schemas/common/VersionIdentifiable";
import type { ICompositeType } from "../../schemas/dataModel/CompositeType";
import type { IColumnTypeTree, IFullColumnTypeTree } from "./resolveTypeTree";

export interface IFullCompositeTree {
  composite: Readonly<ICompositeType>;
  /**
   * Children of the composite type. These are the resolved trees of the
   * composite type definition's referenced column types.
   */
  unionedChildren: Readonly<
    Record<ICompositeTypeUnionedSetId, Readonly<Record<ICompositeTypeInternalReferenceId, IFullColumnTypeTree>>>
  >;
}

export interface ICompositeTree {
  composite: Readonly<Omit<ICompositeType, INonIdVersionIdentifiableFields>>;
  /**
   * Children of the composite type. These are the resolved trees of the
   * composite type definition's referenced column types.
   */
  unionedChildren: Readonly<
    Record<ICompositeTypeUnionedSetId, Readonly<Record<ICompositeTypeInternalReferenceId, IColumnTypeTree>>>
  >;
}

export const resolveCompositesTreesForAllCompTypeIds = (
  compositeTypes: Readonly<Record<ICompositeTypeId, Omit<ICompositeType, INonIdVersionIdentifiableFields>>>,
  resolvedTypeTrees: Readonly<Record<IColumnTypeId, IColumnTypeTree>>,
): Readonly<Record<ICompositeTypeId, ICompositeTree>> => {
  const resolvedTrees: Record<ICompositeTypeId, ICompositeTree> = {};

  forEach(compositeTypes, (type) => {
    const typeId = type.id;

    const unionedChildren: Record<
      ICompositeTypeUnionedSetId,
      Readonly<Record<ICompositeTypeInternalReferenceId, IColumnTypeTree>>
    > = {};

    forEach(type.definition.unions, (types, unionedId) => {
      const children: Record<ICompositeTypeInternalReferenceId, IColumnTypeTree> = {};

      forEach(types, (childId, refId) => {
        const resolved = resolvedTypeTrees[childId];

        if (resolved == null) {
          throw new Error(`Child ${childId} not found in resolved tree`);
        }

        children[refId as ICompositeTypeInternalReferenceId] = resolved;
      });

      unionedChildren[unionedId as ICompositeTypeUnionedSetId] = children;
    });

    const resolvedTree = {
      composite: type,
      unionedChildren,
    };

    resolvedTrees[typeId] = resolvedTree;
  });

  return resolvedTrees;
};
