import type { IColumnId } from "@archetype/ids";
import { forEach } from "@archetype/utils";
import { once } from "lodash";

import type {
  IColumnAndConditions,
  IColumnFilterConditions,
  IColumnOrConditions,
} from "../../schemas/dataModel/dataLoading/ColumnFilterConditions";
import type {
  ICrossColumnAndConditions,
  ICrossColumnOrConditions,
} from "../../schemas/dataModel/dataLoading/CrossColumnConditions";
import type {
  IDataLoadingFilterAndOperator,
  IDataLoadingFilterOrOperator,
  IDataLoadingQueryColumnAndFilters,
  IDataLoadingQueryColumnOrFilters,
} from "../../schemas/dataModel/dataLoading/PerColumnConditions";
import type { IDataLoadingQueryFilters } from "../../schemas/dataModel/DataLoadingQuery";
import type { IEntityColumnValue } from "../../schemas/dataModel/EntityValue";

interface IGenericFilterOperatorAndValue<T extends IDataLoadingFilterAndOperator> {
  operator: T;
  filterValue: Required<IDataLoadingQueryColumnAndFilters>[T];
}

export type IAndFilterOperatorValue = {
  [operator in IDataLoadingFilterAndOperator]: IGenericFilterOperatorAndValue<operator>;
}[IDataLoadingFilterAndOperator];

type IDataLoadingQueryAndFiltersVisitorArgs<operator extends IDataLoadingFilterAndOperator> =
  IGenericFilterOperatorAndValue<operator> & {
    columnValue: IEntityColumnValue;
  };

export type IDataLoadingQueryColumnAndFiltersVisitor<T> = {
  [operator in IDataLoadingFilterAndOperator]: (comparisonInfo: IDataLoadingQueryAndFiltersVisitorArgs<operator>) => T;
};

interface IGenericFilterOperatorOrValue<T extends IDataLoadingFilterOrOperator> {
  operator: T;
  filterValue: Required<IDataLoadingQueryColumnOrFilters>[T];
}

export type IOrFilterOperatorValue = {
  [operator in IDataLoadingFilterOrOperator]: IGenericFilterOperatorOrValue<operator>;
}[IDataLoadingFilterOrOperator];

export type IDataLoadingQueryColumnOrFiltersVisitor<T> = {
  [operator in IDataLoadingFilterOrOperator]: (
    comparisonInfo: IGenericFilterOperatorOrValue<operator> & {
      columnValue: IEntityColumnValue;
    },
  ) => T;
};

const visitColumnRawAndFilters = (
  columnId: IColumnId,
  filters: IDataLoadingQueryColumnAndFilters,
  columnValues: Partial<Record<IColumnId, IEntityColumnValue>>,
): boolean => {
  const columnValue: IEntityColumnValue = columnValues[columnId] ?? { type: "null" };

  const visitor = getColumnRawAndFilterVisitor();

  const operatorsAndValues: Array<IAndFilterOperatorValue> = [];

  forEach(
    filters,
    (filterValue, operator) =>
      filterValue != null &&
      operatorsAndValues.push({
        operator,
        filterValue,
      } as IAndFilterOperatorValue),
  );

  return operatorsAndValues.every((operatorAndValue) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- unsure how to make this typesafe
    visitor[operatorAndValue.operator]({
      ...operatorAndValue,
      columnValue,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- unsure how to make this typesafe
    } as any),
  );
};

const visitColumnRawOrFilters = (
  columnId: IColumnId,
  filters: IDataLoadingQueryColumnOrFilters,
  columnValues: Partial<Record<IColumnId, IEntityColumnValue>>,
): boolean => {
  const visitor = getColumnRawOrFilterVisitor();
  const columnValue: IEntityColumnValue = columnValues[columnId] || { type: "null" };

  const operatorsAndValues: Array<IOrFilterOperatorValue> = [];

  forEach(
    filters,
    (filterValue, operator) =>
      filterValue != null &&
      operatorsAndValues.push({
        operator,
        filterValue,
      } as IOrFilterOperatorValue),
  );

  return operatorsAndValues.some((operatorAndValue) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- unsure how to make this typesafe
    visitor[operatorAndValue.operator]({
      ...operatorAndValue,
      columnValue,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- unsure how to make this typesafe
    } as any),
  );
};

const visitColumnAndConditions = (
  columnId: IColumnId,
  columnAndConditions: IColumnAndConditions,
  columnValues: Partial<Record<IColumnId, IEntityColumnValue>>,
): boolean => {
  return (
    visitColumnRawAndFilters(columnId, columnAndConditions.rawAndOperations, columnValues) &&
    (columnAndConditions.andedOrConditions.length === 0
      ? true
      : columnAndConditions.andedOrConditions.every((columnOrConditions) =>
          visitColumnOrConditions(columnId, columnOrConditions, columnValues),
        ))
  );
};

const visitColumnOrConditions = (
  columnId: IColumnId,
  columnOrConditions: IColumnOrConditions,
  columnValues: Partial<Record<IColumnId, IEntityColumnValue>>,
): boolean => {
  return (
    visitColumnRawOrFilters(columnId, columnOrConditions.rawOrConditions, columnValues) ||
    columnOrConditions.oredAndConditions.some((columnOrCondition) =>
      visitColumnAndConditions(columnId, columnOrCondition, columnValues),
    )
  );
};

type IFlattenedPerColumnAndedFilters = Array<{
  columnId: IColumnId;
  columnFilterConditions: IColumnFilterConditions;
}>;

const visitPerColumnAndedFilters = (
  perColumnAndedFilters: ICrossColumnAndConditions["perColumn"],
  columnValues: Partial<Record<IColumnId, IEntityColumnValue>>,
): boolean => {
  const flattenedConditions: IFlattenedPerColumnAndedFilters = [];

  forEach(perColumnAndedFilters, (columnFilterConditions, columnId) => {
    if (columnFilterConditions == null) {
      return;
    }

    flattenedConditions.push({ columnId, columnFilterConditions });
  });

  if (flattenedConditions.length === 0) {
    return true;
  }

  return flattenedConditions.every(({ columnId, columnFilterConditions }) =>
    columnFilterConditions.type === "and"
      ? visitColumnAndConditions(columnId, columnFilterConditions, columnValues)
      : visitColumnOrConditions(columnId, columnFilterConditions, columnValues),
  );
};

const visitCrossColumnAndConditions = (
  crossColumnAndConditions: ICrossColumnAndConditions,
  columnValues: Partial<Record<IColumnId, IEntityColumnValue>>,
): boolean => {
  return (
    visitPerColumnAndedFilters(crossColumnAndConditions.perColumn, columnValues) &&
    (crossColumnAndConditions.andedCrossColumnOrConditions.length === 0
      ? true
      : crossColumnAndConditions.andedCrossColumnOrConditions.every((crossColumnOrConditions) =>
          visitCrossColumnOrConditions(crossColumnOrConditions, columnValues),
        ))
  );
};

const visitCrossColumnOrConditions = (
  crossColumnOrConditions: ICrossColumnOrConditions,
  columnValues: Partial<Record<IColumnId, IEntityColumnValue>>,
): boolean => {
  return crossColumnOrConditions.oredCrossColumnAndConditions.some((crossColumnAndConditions) =>
    visitCrossColumnAndConditions(crossColumnAndConditions, columnValues),
  );
};

export const visitDataLoadingQueryFilters = (
  dataLoadingFilters: IDataLoadingQueryFilters,
  columnValues: Partial<Record<IColumnId, IEntityColumnValue>>,
): boolean => visitCrossColumnAndConditions(dataLoadingFilters, columnValues);

// ———————————————————————————————————————————————_

const getColumnRawAndFilterVisitor: () => IDataLoadingQueryColumnAndFiltersVisitor<boolean> = once(() => ({
  eq: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return true;
      }

      return false;
    }

    return columnValue.value === filterValue.value.value;
  },
  eqIgnoreCase: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return true;
      }

      return false;
    }

    return JSON.stringify(columnValue.value).toLowerCase() === JSON.stringify(filterValue.value.value).toLowerCase();
  },
  neq: ({ columnValue, filterValue: filterValues }): boolean => {
    return filterValues.every((filterValue) => {
      if (filterValue.type === "reference") {
        return true;
      }
      if (filterValue.value.type === "null" || columnValue.type === "null") {
        if (filterValue.value.type !== columnValue.type) {
          return true;
        }

        return false;
      }

      return columnValue.value !== filterValue.value.value;
    });
  },
  neqIgnoreCase: ({ columnValue, filterValue: filterValues }): boolean => {
    return filterValues.every((filterValue) => {
      if (filterValue.type === "reference") {
        return true;
      }
      if (filterValue.value.type === "null" || columnValue.type === "null") {
        if (filterValue.value.type !== columnValue.type) {
          return true;
        }

        return false;
      }

      return JSON.stringify(columnValue.value).toLowerCase() !== JSON.stringify(filterValue.value.value).toLowerCase();
    });
  },
  gt: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return true;
      }

      return false;
    }

    return columnValue.value > filterValue.value.value;
  },
  gte: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return true;
      }

      return false;
    }

    return columnValue.value >= filterValue.value.value;
  },
  lt: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return true;
      }

      return false;
    }

    return columnValue.value < filterValue.value.value;
  },
  lte: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return true;
      }

      return false;
    }

    return columnValue.value <= filterValue.value.value;
  },
  regex: ({ columnValue, filterValue }): boolean => {
    const regex = new RegExp(filterValue.value, filterValue.flags);

    if (columnValue.type !== "string") {
      return false;
    }

    return regex.test(columnValue.value);
  },
  anyInArray: (): boolean => {
    throw Error("Not implemented");
  },
  noneInArray: (): boolean => {
    throw Error("Not implemented");
  },
  allInArray: (): boolean => {
    throw Error("Not implemented");
  },
  allNotInArray: (): boolean => {
    throw Error("Not implemented");
  },
}));

const getColumnRawOrFilterVisitor: () => IDataLoadingQueryColumnOrFiltersVisitor<boolean> = once(() => ({
  eq: ({ columnValue, filterValue: filterValues }): boolean => {
    return filterValues.some((filterValue) => {
      if (filterValue.type === "reference") {
        return true;
      }
      if (filterValue.value.type === "null" || columnValue.type === "null") {
        if (filterValue.value.type === columnValue.type) {
          return true;
        }

        return false;
      }

      return columnValue.value === filterValue.value.value;
    });
  },
  eqIgnoreCase: ({ columnValue, filterValue: filterValues }): boolean => {
    return filterValues.some((filterValue) => {
      if (filterValue.type === "reference") {
        return true;
      }
      if (filterValue.value.type === "null" || columnValue.type === "null") {
        if (filterValue.value.type === columnValue.type) {
          return true;
        }

        return false;
      }

      return JSON.stringify(columnValue.value).toLowerCase() === JSON.stringify(filterValue.value.value).toLowerCase();
    });
  },
  neq: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return false;
      }

      return true;
    }

    return columnValue.value !== filterValue.value.value;
  },
  neqIgnoreCase: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return false;
      }

      return true;
    }

    return JSON.stringify(columnValue.value).toLowerCase() !== JSON.stringify(filterValue.value.value).toLowerCase();
  },
  gt: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return true;
      }

      return false;
    }

    return columnValue.value > filterValue.value.value;
  },
  gte: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return true;
      }

      return false;
    }

    return columnValue.value >= filterValue.value.value;
  },
  lt: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return true;
      }

      return false;
    }

    return columnValue.value < filterValue.value.value;
  },
  lte: ({ columnValue, filterValue }): boolean => {
    if (filterValue.type === "reference") {
      return true;
    }

    if (filterValue.value.type === "null" || columnValue.type === "null") {
      if (filterValue.value.type === "null" && columnValue.type === "null") {
        return true;
      }

      return false;
    }

    return columnValue.value <= filterValue.value.value;
  },
  regex: ({ columnValue, filterValue }): boolean => {
    const regex = new RegExp(filterValue.value, filterValue.flags);

    if (columnValue.type !== "string") {
      return false;
    }

    return regex.test(columnValue.value);
  },
  anyInArray: (): boolean => {
    throw Error("Not implemented");
  },
  noneInArray: (): boolean => {
    throw Error("Not implemented");
  },
  allInArray: (): boolean => {
    throw Error("Not implemented");
  },
  allNotInArray: (): boolean => {
    throw Error("Not implemented");
  },
}));
