import type { ILayoutId, ISlotId } from "@archetype/ids";

import { AbstractError } from "./AbstractError";

export class SlotNotDefinedError extends AbstractError<"SlotNotDefinedError"> {
  constructor({ slotId, layoutId }: { slotId: ISlotId; layoutId: ILayoutId }) {
    super({
      name: "SlotNotDefinedError",
      message: `Slot ${slotId} is not defined in layout ${layoutId}`,
    });
  }
}
