import { cva } from "class-variance-authority";

import { cn } from "../../lib/utils";

const colors = {
  lilac: "bg-pastel-lilac-foreground",
  purple: "bg-pastel-purple-foreground",
  pink: "bg-pastel-pink-foreground",
  red: "bg-pastel-red-foreground",
  orange: "bg-pastel-orange-foreground",
  brown: "bg-pastel-brown-foreground",
  yellow: "bg-pastel-yellow-foreground",
  lime: "bg-pastel-lime-foreground",
  sage: "bg-pastel-sage-foreground",
  green: "bg-pastel-green-foreground",
  emerald: "bg-pastel-emerald-foreground",
  teal: "bg-pastel-teal-foreground",
  blue: "bg-pastel-blue-foreground",
  neutral: "bg-pastel-neutral-foreground",
} as const;

const ringColors = {
  lilac: "ring-pastel-lilac-background",
  purple: "ring-pastel-purple-background",
  pink: "ring-pastel-pink-background",
  red: "ring-pastel-red-background",
  orange: "ring-pastel-orange-background",
  brown: "ring-pastel-brown-background",
  yellow: "ring-pastel-yellow-background",
  lime: "ring-pastel-lime-background",
  sage: "ring-pastel-sage-background",
  green: "ring-pastel-green-background",
  emerald: "ring-pastel-emerald-background",
  teal: "ring-pastel-teal-background",
  blue: "ring-pastel-blue-background",
  neutral: "ring-pastel-neutral-background",
} as const;

const borderColors = {
  lilac: "border-pastel-lilac-foreground",
  purple: "border-pastel-purple-foreground",
  pink: "border-pastel-pink-foreground",
  red: "border-pastel-red-foreground",
  orange: "border-pastel-orange-foreground",
  brown: "border-pastel-brown-foreground",
  yellow: "border-pastel-yellow-foreground",
  lime: "border-pastel-lime-foreground",
  sage: "border-pastel-sage-foreground",
  green: "border-pastel-green-foreground",
  emerald: "border-pastel-emerald-foreground",
  teal: "border-pastel-teal-foreground",
  blue: "border-pastel-blue-foreground",
  neutral: "border-border",
  primary: "border-primary",
} as const;

export const EntityColorVariants = cva("relative size-5 rounded-full", {
  variants: {
    color: colors,
  },
  defaultVariants: {
    color: "neutral",
  },
});
export type IEntityStateColorVariants = keyof typeof colors;
export const EntityStateColorVariantsList = Object.keys(colors) as IEntityStateColorVariants[];

interface IEntityStateColor {
  className?: string;
  selected?: boolean;
  color: IEntityStateColorVariants;
}

export const EntityStateColor = ({ className, selected, color }: IEntityStateColor): React.ReactNode => {
  return (
    <span
      className={cn(
        EntityColorVariants({ color: color }),
        selected === true && ringColors[color],
        selected === true && "ring-2",
        className,
      )}
    />
  );
};

export const DottedEntityStateColor = ({
  className,
  color,
}: {
  className?: string;
  color: IEntityStateColorVariants | "primary";
}): React.ReactNode => {
  return <span className={cn("relative size-5 rounded-full border border-dashed", borderColors[color], className)} />;
};
