import type { ITypedLayoutDefinitionDeclaration } from "@archetype/dsl";
import { ComparisonListLayoutId, LayoutConfigurationId, SlotId } from "@archetype/ids";

export const ComparisonListLayoutIds = {
  id: ComparisonListLayoutId,
  layoutConfigurations: {
    mainEntityType: "entityType",
  },
  moduleParameters: {
    inputs: ["singleEntityDataInput"],
    outputs: [],
  },
  slots: {
    primary: {
      optional: false,
      inputs: ["singleEntityDataInput"],
      outputs: ["userSelectedSingleEntity"],
    },
    secondary: {
      optional: false,
      inputs: ["singleEntityDataInput"],
      outputs: ["userSelectedSingleEntity"],
    },
    comparison: {
      optional: false,
      inputs: ["primarySingleEntityDataInput", "secondarySingleEntityDataInput"],
      outputs: [],
    },
  },
} as const;

export const ComparisonListLayout: ITypedLayoutDefinitionDeclaration<typeof ComparisonListLayoutIds> = {
  constIds: ComparisonListLayoutIds,
  displayMetadata: {
    name: "Comparison list",
    description: "A list with a search input",
  },
  tags: ["list", "search"],
  layoutConfiguration: {
    mainEntityType: {
      type: "entityType",
    },
  },
  slots: {
    primary: {
      displayMetadata: {
        name: "Primary entity selector",
        description: "Selector for the primary entity",
      },
      constraints: {
        semanticSize: "split-vertical",
        inputs: {
          singleEntityDataInput: {
            nullable: false,
            ref: {
              type: "config",
              layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
            },
          },
        },
        outputs: {
          userSelectedSingleEntity: {
            nullable: false,
            type: "config",
            layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
          },
        },
      },
    },

    secondary: {
      displayMetadata: {
        name: "Secondary entity selector",
        description: "Selector for the secondary entity",
      },
      constraints: {
        semanticSize: "split-vertical",
        inputs: {
          singleEntityDataInput: {
            nullable: false,
            ref: {
              type: "config",
              layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
            },
          },
        },
        outputs: {
          userSelectedSingleEntity: {
            nullable: false,
            type: "config",
            layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
          },
        },
      },
    },

    comparison: {
      displayMetadata: {
        name: "Comparison view",
        description: "View of the results of the comparison",
      },
      constraints: {
        semanticSize: "summary",
        inputs: {
          primarySingleEntityDataInput: {
            nullable: false,
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("primary"),
              outputId: "userSelectedSingleEntity",
            },
          },
          secondarySingleEntityDataInput: {
            nullable: false,
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("secondary"),
              outputId: "userSelectedSingleEntity",
            },
          },
        },
        outputs: {},
      },
    },
  },
  moduleParameterInputs: {
    singleEntityDataInput: {
      type: "singleEntity",
      entityType: LayoutConfigurationId.parse("mainEntityType"),
      displayMetadata: {
        name: "First entity to compare",
        description: undefined,
      },
    },
  },
  moduleParameterOutputs: {},
  variants: [],
};
