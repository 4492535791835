import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { DescriptionListComponentDefinitionId } from "@archetype/ids";

export const DescriptionListComponentIdDeclarations = {
  id: DescriptionListComponentDefinitionId,
  inputs: ["singleEntityDataInput"],
  outputs: [],
  compatibleSemanticSizes: ["main", "medium", "split-vertical", "split-horizontal"],
  validCosmeticOptionalProps: [],
} as const;

export const DescriptionListComponentDefinition: ITypedComponentDefinitionDeclaration<
  typeof DescriptionListComponentIdDeclarations
> = {
  constIds: DescriptionListComponentIdDeclarations,
  displayMetadata: {
    name: "Description list",
    description: "A list of properties on an entity",
  },
  inputs: {
    singleEntityDataInput: {
      displayMetadata: {
        name: "Entity",
        description: "Entity to display",
      },
      optional: false,
      nullable: false,
    },
  },
  outputs: {},
};
