import type { IActionCore, IEntityTypeCore } from "@archetype/dsl";
import { computeColumnViewFieldId } from "@archetype/dsl";
import type { IActionId } from "@archetype/ids";
import { forEach, mapValues } from "@archetype/utils";

import { actionDefinitionWithoutDeletedFields } from "./applyDeleteRelationOperation";
import type { IDeleteColumnOperation, IOperationsEdits } from "./operations";

export const applyDeleteColumnOperation = (
  operation: IDeleteColumnOperation,
  currentInfo: IOperationsEdits,
): IOperationsEdits => {
  const { entityTypeId, columnIds } = operation;

  const currentEntityType = currentInfo.newOrEditedEntityTypes[entityTypeId];

  if (currentEntityType == null) {
    throw new Error("Entity type not found");
  }

  const columnIdsToDeleteSet = new Set(columnIds);
  const columnViewFieldIdsToDeleteSet = new Set(columnIds.map((c) => computeColumnViewFieldId(c)));

  const editedEntityType: IEntityTypeCore = {
    ...currentEntityType,
    columns: currentEntityType.columns.filter((c) => !columnIdsToDeleteSet.has(c.id)),
    relevantViewFieldsByStateId: mapValues(currentEntityType.relevantViewFieldsByStateId, (v) =>
      v.filter((f) => !columnViewFieldIdsToDeleteSet.has(f.id)),
    ),
  };

  const editedActions: Record<IActionId, IActionCore> = {};

  forEach(currentInfo.newOrEditedActions, (action, actionId) => {
    const editedActionDefinition = actionDefinitionWithoutDeletedFields(
      columnViewFieldIdsToDeleteSet,
      action.actionDefinition,
    );

    editedActions[actionId] = {
      ...action,
      actionDefinition: editedActionDefinition,
    };
  });

  // TODO: we should also treat relation filters, auth filters, etc?

  return {
    ...currentInfo,
    newOrEditedEntityTypes: {
      ...currentInfo.newOrEditedEntityTypes,
      [editedEntityType.id]: editedEntityType,
    },
    newOrEditedActions: editedActions,
  };
};
