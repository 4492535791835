import React from "react";
import ContentEditable from "react-contenteditable";

import { useCellContentEditable } from "../../hooks/useCellContentEditable";
import { useCellEditOnEnter } from "../../hooks/useCellEditOnEnter";
import { ExpandableCellWrapper } from "../ExpandableCellWrapper";
import type { IDataTableCommonCellProps, IDataTableNumberCell } from "./api";

export function NumberCell<TRowId extends string, TColId extends string>({
  cell,
  isCellSelected,
  isCellEditing,
  isRowSelected,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTableNumberCell<TRowId, TColId>>): React.ReactNode {
  const { value, onChange, colId, rowId, readOnly } = cell;

  const { contentEditableRef, localValue, handleChange, handleBlur, handleKeyDown } = useCellContentEditable({
    initialValue: value,
    rowId,
    colId,
    onChange,
    parseValue: (newValue) => {
      const parsedValue = parseFloat(newValue.replace(/[^0-9.-]/g, ""));

      return isNaN(parsedValue) ? value : parsedValue;
    },
    validateInput: (e) => /[0-9.-]/.test(e.key),
    readOnly,
    isCellEditing,
  });

  useCellEditOnEnter({
    rowId,
    colId,
    isCellEditing,
    isCellSelected,
  });

  return (
    <ExpandableCellWrapper
      cell={cell}
      isCellEditing={isCellEditing}
      isCellSelected={isCellSelected}
      isRowSelected={isRowSelected}
    >
      <ContentEditable
        className="flex size-full items-center truncate text-base outline-none"
        disabled={!isCellEditing || readOnly}
        html={localValue?.toString() ?? ""}
        innerRef={contentEditableRef}
        role="textbox"
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </ExpandableCellWrapper>
  );
}
