"use client";

import { AnimatePresence, motion } from "framer-motion";
import type React from "react";
import { useCallback, useState } from "react";

import { cn } from "../../lib/utils";
import { Icon } from "./icon";

// Types for our build steps
export type IBuildProgressStatus = "pending" | "in-progress" | "completed" | "failed";

export interface IBuildStep {
  id: string;
  name: string;
  status: IBuildProgressStatus;
  children?: IBuildStep[];
  startTime?: Date;
  endTime?: Date;
}

// Props for our component
interface IBuildProgressProps {
  steps: IBuildStep[];
  onStepClick?: (stepId: string) => void;
}

export const BuildProgress: React.FC<IBuildProgressProps> = ({ steps, onStepClick: handleStepClick }) => {
  return (
    <div className="mx-auto w-full max-w-3xl">
      <div className="space-y-1">
        {steps.map((step) => (
          <StepItem key={step.id} level={0} step={step} onStepClick={handleStepClick} />
        ))}
      </div>
    </div>
  );
};

interface IStepItemProps {
  step: IBuildStep;
  level: number;
  onStepClick?: (stepId: string) => void;
}

const StepItem: React.FC<IStepItemProps> = ({ step, level, onStepClick }) => {
  const [expanded, setExpanded] = useState(true);
  const hasChildren = step.children && step.children.length > 0;

  const handleToggleExpand = useCallback(
    (e: React.MouseEvent): void => {
      e.stopPropagation();
      setExpanded(!expanded);
    },
    [expanded],
  );

  const handleClick = useCallback(() => {
    onStepClick?.(step.id);
  }, [onStepClick, step.id]);

  return (
    <div>
      <motion.div
        animate={{ opacity: 1 }}
        className={cn(
          "group flex items-center gap-2 rounded-md px-3 py-2",
          "cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-900/20",
        )}
        initial={{ opacity: 0 }}
        style={{ marginLeft: `${(level * 16).toFixed(0)}px` }}
        onClick={handleClick}
      >
        <StatusDot status={step.status} />

        <div className="min-w-0 flex-1">
          <div className="flex items-center gap-2">
            <span
              className={cn(
                "truncate text-sm font-medium",
                step.status === "completed" && "text-gray-500 dark:text-gray-400",
                step.status === "in-progress" && "text-gray-900 dark:text-gray-100",
                step.status === "failed" && "text-gray-900 dark:text-gray-100",
                step.status === "pending" && "text-gray-400 dark:text-gray-500",
              )}
            >
              {step.name}
            </span>
          </div>

          {step.startTime ? (
            <div className="text-xs text-gray-400 dark:text-gray-500">
              {formatTime(step.startTime)}
              {step.endTime ? (
                <>
                  <span className="mx-1">→</span>
                  {formatDuration(step.startTime, step.endTime)}
                </>
              ) : null}
            </div>
          ) : null}
        </div>

        {hasChildren === true ? (
          <button
            className="p-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
            onClick={handleToggleExpand}
          >
            {expanded ? (
              <Icon className="size-4" name="chevron-down" />
            ) : (
              <Icon className="size-4" name="chevron-right" />
            )}
          </button>
        ) : null}
      </motion.div>

      {hasChildren === true && expanded ? (
        <AnimatePresence>
          <motion.div
            animate={{ opacity: 1, height: "auto" }}
            className="space-y-1 overflow-hidden"
            exit={{ opacity: 0, height: 0 }}
            initial={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.15 }}
          >
            {step.children?.map((childStep) => (
              <StepItem key={childStep.id} level={level + 1} step={childStep} onStepClick={handleClick} />
            ))}
          </motion.div>
        </AnimatePresence>
      ) : null}
    </div>
  );
};

const StatusDot: React.FC<{ status: IBuildProgressStatus }> = ({ status }) => {
  switch (status) {
    case "completed": {
      return (
        <div className="flex size-4 items-center justify-center">
          <Icon className="size-3.5 text-green-500 dark:text-green-400" name="check" />
        </div>
      );
    }
    case "in-progress": {
      return (
        <div className="flex size-4 items-center justify-center">
          <motion.div
            animate={{ scale: [1, 1.2, 1] }}
            className="size-2 rounded-full bg-blue-500 dark:bg-blue-400"
            transition={{ duration: 2, repeat: Number.POSITIVE_INFINITY }}
          />
        </div>
      );
    }
    case "failed": {
      return (
        <div className="flex size-4 items-center justify-center">
          <Icon className="size-3.5 text-red-500 dark:text-red-400" name="x" />
        </div>
      );
    }
    case "pending": {
      return (
        <div className="flex size-4 items-center justify-center">
          <Icon className="size-2 text-gray-300 dark:text-gray-600" name="circle" />
        </div>
      );
    }
  }
};

// Helper function to format time
function formatTime(date: Date | string): string {
  // If date is a string, convert it to a Date object
  const dateObj = typeof date === "string" ? new Date(date) : date;

  // Check if dateObj is a valid Date
  if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
    return "Invalid date";
  }

  return dateObj.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}

// Helper function to calculate duration between two dates
function formatDuration(start: Date | string, end: Date | string): string {
  // Convert to Date objects if they're strings
  const startDate = typeof start === "string" ? new Date(start) : start;
  const endDate = typeof end === "string" ? new Date(end) : end;

  // Check if dates are valid
  if (
    !(startDate instanceof Date) ||
    isNaN(startDate.getTime()) ||
    !(endDate instanceof Date) ||
    isNaN(endDate.getTime())
  ) {
    return "";
  }

  // Calculate duration in seconds
  const durationMs = endDate.getTime() - startDate.getTime();
  const durationSec = Math.floor(durationMs / 1000);

  if (durationSec < 60) {
    return `${durationSec.toFixed(0)}s`;
  }
  const minutes = Math.floor(durationSec / 60);
  const seconds = durationSec % 60;

  return `${minutes.toFixed(0)}m ${seconds.toFixed(0)}s`;
}
