import { z } from "zod";

/**
 * Schema for workflow status reporting
 */

export const WorkflowStatusEnum = z.enum([
  "RUNNING",
  "COMPLETED",
  "FAILED",
  "CANCELED",
  "TERMINATED",
  "TIMED_OUT",
  "CONTINUED_AS_NEW",
]);

export type IWorkflowStatus = z.infer<typeof WorkflowStatusEnum>;

/**
 * Schema for workflow event types
 */
export const WorkflowEventTypeEnum = z.enum(["ENTITY_TYPE", "SUPPORT_ENTITY_TYPE", "FORM", "FIELD", "VIEW"]);

export type IWorkflowEventType = z.infer<typeof WorkflowEventTypeEnum>;

/**
 * Schema for status log entries
 */
export const StatusLogEntry = z.object({
  timestamp: z.string(),
  message: z.string(),
  level: z.enum(["info", "warning", "error"]).default("info"),
});

export type IStatusLogEntry = z.infer<typeof StatusLogEntry>;

/**
 * Define the enum for reasoning step types
 */
export const ReasoningStepType = z.enum([
  "generic",
  "workflow_process",
  "data_structure",
  "view_design",
  "support_entity",
  "saving",
  "finalizing",
  "completed",
]);

export type IReasoningStepType = z.infer<typeof ReasoningStepType>;

/**
 * Base reasoning step with type discriminator
 */
export const ReasoningStepBase = z.object({
  id: z.string(),
  text: z.string(),
  type: ReasoningStepType,
});

export type IReasoningStepBase = z.infer<typeof ReasoningStepBase>;
/**
 * Entity related reasoning step
 */
export const ReasoningStepEntityType = ReasoningStepBase.extend({
  type: z.literal("entity_type"),
  entityName: z.string(),
});

export type IReasoningStepEntityType = z.infer<typeof ReasoningStepEntityType>;

/**
 * Workflow Process Step
 */
export const WorkflowProcessStep = ReasoningStepBase.extend({
  type: z.literal("workflow_process"),
  entityName: z.string(),
});

export type IWorkflowProcessStep = z.infer<typeof WorkflowProcessStep>;

/**
 * View Design Step
 */
export const ViewDesignStep = ReasoningStepBase.extend({
  type: z.literal("view_design"),
  viewName: z.string(),
});

export type IViewDesignStep = z.infer<typeof ViewDesignStep>;

/**
 * Data Structure Step
 */
export const DataStructureStep = ReasoningStepBase.extend({
  type: z.literal("data_structure"),
  entityName: z.string(),
});

export type IDataStructureStep = z.infer<typeof DataStructureStep>;

/**
 * Support Entity Step
 */
export const SupportEntityStep = ReasoningStepBase.extend({
  type: z.literal("support_entity"),
  entityName: z.string(),
});

export type ISupportEntityStep = z.infer<typeof SupportEntityStep>;

/**
 * Generic Steps
 */
export const GenericStep = ReasoningStepBase.extend({
  type: z.literal("generic"),
});

export type IGenericStep = z.infer<typeof GenericStep>;

/**
 * Union type for all reasoning step types
 */
export const WorkflowReasoningStep = z.union([
  WorkflowProcessStep,
  ViewDesignStep,
  DataStructureStep,
  SupportEntityStep,
  GenericStep,
]);

export type IWorkflowReasoningStep = z.infer<typeof WorkflowReasoningStep>;
