import type {
  ICrossColumnAndConditions,
  ICrossColumnOrConditions,
  IDataLoadingQuery,
  IDataLoadingQueryEntityTypeJoinTraversalReference,
  IDataLoadingQueryEntityTypeLiteralReference,
  IDataLoadingQueryEntityTypeReference,
  IDataLoadingSearchQuery,
  IEntityType,
  IRelatedToFilter,
  IRelation,
  IRelationDirection,
} from "@archetype/dsl";
import type { IEntityTypeId, IRelationId } from "@archetype/ids";
import { filter } from "@archetype/utils";

import type {
  ILoadedCrossColumnAndConditions,
  ILoadedCrossColumnOrConditions,
  ILoadedDataLoadingQuery,
  ILoadedEntityTypeReference,
  ILoadedJoinTraversal,
  ILoadedQueryEntityType,
  ILoadedRelatedToFilter,
  ILoadedSearchQuery,
} from "./loadedQuery";

const convertAndedRelatedToFilters = (
  filt: IRelatedToFilter,
  fetchedEntityTypes: Record<IEntityTypeId, IEntityType>,
  fetchedRelations: Record<IRelationId, IRelation>,
): ILoadedRelatedToFilter => {
  const relation = getOrThrowRelation(filt.relationId, fetchedRelations);

  return {
    type: "relatedTo",
    direction: filt.direction,
    toEntityType: getOrThrowEntityType(
      filt.direction === "aToB" ? relation.entityTypeIdB : relation.entityTypeIdA,
      fetchedEntityTypes,
    ),
    relation,
    filters:
      filt.filters != null
        ? visitCrossColumnAndConditions(filt.filters, fetchedEntityTypes, fetchedRelations)
        : undefined,
  };
};

const visitCrossColumnOrConditions = (
  conditions: ICrossColumnOrConditions,
  fetchedEntityTypes: Record<IEntityTypeId, IEntityType>,
  fetchedRelations: Record<IRelationId, IRelation>,
): ILoadedCrossColumnOrConditions => {
  return {
    ...conditions,
    oredCrossColumnAndConditions: conditions.oredCrossColumnAndConditions.map((andConditions) =>
      visitCrossColumnAndConditions(andConditions, fetchedEntityTypes, fetchedRelations),
    ),
  };
};

const visitCrossColumnAndConditions = (
  conditions: ICrossColumnAndConditions,
  fetchedEntityTypes: Record<IEntityTypeId, IEntityType>,
  fetchedRelations: Record<IRelationId, IRelation>,
): ILoadedCrossColumnAndConditions => {
  return {
    ...conditions,
    andedCrossColumnOrConditions: conditions.andedCrossColumnOrConditions.map((oredConditions) =>
      visitCrossColumnOrConditions(oredConditions, fetchedEntityTypes, fetchedRelations),
    ),
    andedRelatedToFilters: (conditions.andedRelatedToFilters ?? []).map((filt) =>
      convertAndedRelatedToFilters(filt, fetchedEntityTypes, fetchedRelations),
    ),
  };
};

const getOrThrowEntityType = (
  entityTypeId: IEntityTypeId,
  fetchedEntityTypes: Record<IEntityTypeId, IEntityType>,
): IEntityType => {
  const entityType = fetchedEntityTypes[entityTypeId];

  if (entityType == null) {
    throw new Error(`Entity type ${entityTypeId} not found in fetched entity types`);
  }

  return entityType;
};

const getOrThrowRelation = (relationId: IRelationId, fetchedRelations: Record<IRelationId, IRelation>): IRelation => {
  const relation = fetchedRelations[relationId];

  if (relation == null) {
    throw new Error(`Relation ${relationId} not found in fetched relations`);
  }

  return relation;
};

const convertLiteralEntityTypeReference = (
  entityType: IDataLoadingQueryEntityTypeLiteralReference,
  fetchedEntityTypes: Record<IEntityTypeId, IEntityType>,
): ILoadedEntityTypeReference => ({
  type: "entityType",
  entityType: getOrThrowEntityType(entityType.entityTypeId, fetchedEntityTypes),
  entityIds: entityType.entities,
});

const convertJoinTraversalReference = (
  entityType: IDataLoadingQueryEntityTypeJoinTraversalReference,
  fetchedEntityTypes: Record<IEntityTypeId, IEntityType>,
  fetchedRelations: Record<IRelationId, IRelation>,
): ILoadedJoinTraversal => {
  const relation = getOrThrowRelation(entityType.relationTypeId, fetchedRelations);

  let finalEntityType: IEntityType;

  if (entityType.relationDirection === "aToB") {
    finalEntityType = getOrThrowEntityType(relation.entityTypeIdB, fetchedEntityTypes);
  } else {
    finalEntityType = getOrThrowEntityType(relation.entityTypeIdA, fetchedEntityTypes);
  }

  const otherSideEntityTypeId =
    entityType.relationDirection === "aToB" ? relation.entityTypeIdA : relation.entityTypeIdB;

  if (entityType.startingEntities.entityTypeId !== otherSideEntityTypeId) {
    const entityTypeSide = entityType.relationDirection === "aToB" ? "A" : "B";

    throw new Error(
      `Starting entity type ID ${entityType.startingEntities.entityTypeId} does not match relation entity type ${
        entityTypeSide
      } ID ${otherSideEntityTypeId}`,
    );
  }

  return {
    type: "joinTraversal",
    relation,
    direction: entityType.relationDirection,
    startingEntities: convertLiteralEntityTypeReference(entityType.startingEntities, fetchedEntityTypes),
    endEntityType: finalEntityType,
  };
};

const convertEntityTypeReference = (
  entityType: IDataLoadingQueryEntityTypeReference,
  fetchedEntityTypes: Record<IEntityTypeId, IEntityType>,
  fetchedRelations: Record<IRelationId, IRelation>,
): ILoadedQueryEntityType => {
  switch (entityType.type) {
    case "entityTypeId": {
      return convertLiteralEntityTypeReference(entityType, fetchedEntityTypes);
    }
    case "joinTraversal": {
      return convertJoinTraversalReference(entityType, fetchedEntityTypes, fetchedRelations);
    }
  }
};

const convertSearchQuery = ({
  searchQuery,
  fromEntityTypeId,
  fetchedEntityTypes,
  fetchedRelations,
}: {
  searchQuery: IDataLoadingSearchQuery;
  fromEntityTypeId: IEntityTypeId;
  fetchedEntityTypes: Record<IEntityTypeId, IEntityType>;
  fetchedRelations: Record<IRelationId, IRelation>;
}): ILoadedSearchQuery => ({
  searchQueryValue: searchQuery.searchQueryValue,
  searchColumns: searchQuery.searchColumns ?? [],

  // the logic here is a little weird, because there's no way to define this in the regular search query YET
  searchThroughRelations: [
    ...filter(fetchedRelations, (relation) => relation.entityTypeIdA === fromEntityTypeId).map((relation) => ({
      relation,
      direction: "aToB" as IRelationDirection,
      endEntityType: getOrThrowEntityType(relation.entityTypeIdB, fetchedEntityTypes),
    })),
    ...filter(fetchedRelations, (relation) => relation.entityTypeIdB === fromEntityTypeId).map((relation) => ({
      relation,
      direction: "bToA" as IRelationDirection,
      endEntityType: getOrThrowEntityType(relation.entityTypeIdA, fetchedEntityTypes),
    })),
  ],
});

const getFinalEntityTypeForQuery = (entityType: ILoadedQueryEntityType): IEntityType => {
  if (entityType.type === "joinTraversal") {
    return entityType.endEntityType;
  }

  return entityType.entityType;
};

export const convertDslQuery = (
  dataLoadingQuery: IDataLoadingQuery,
  fetchedEntityTypes: Record<IEntityTypeId, IEntityType>,
  fetchedRelations: Record<IRelationId, IRelation>,
): ILoadedDataLoadingQuery => {
  const entityType = convertEntityTypeReference(dataLoadingQuery.entityType, fetchedEntityTypes, fetchedRelations);

  const filters =
    dataLoadingQuery.filters != null
      ? visitCrossColumnAndConditions(dataLoadingQuery.filters, fetchedEntityTypes, fetchedRelations)
      : undefined;

  const searchQuery =
    dataLoadingQuery.searchQuery != null
      ? convertSearchQuery({
          searchQuery: dataLoadingQuery.searchQuery,
          fromEntityTypeId: getFinalEntityTypeForQuery(entityType).id,
          fetchedEntityTypes,
          fetchedRelations,
        })
      : undefined;

  return {
    ...dataLoadingQuery,
    searchQuery,
    entityType,
    filters,
  };
};

export const __convertDslQueryTest__ = {
  convertLiteralEntityTypeReference,
  convertJoinTraversalReference,
  convertEntityTypeReference,
  visitCrossColumnAndConditions,
  visitCrossColumnOrConditions,
  convertAndedRelatedToFilters,
  convertSearchQuery,
};
