import type { IColumnTypeId } from "@archetype/ids";
import { isNonNullable, keyByNoUndefined, mapValues, pickBy } from "@archetype/utils";
import { isEqual } from "lodash";

import type { IEntityTypeCore } from "../../../schemas/dataModel/EntityType";

export type IDiff =
  | { type: "added" }
  | {
      type: "changed";
      dataTypeChanged: boolean;
    };

export const getEntityTypeDiff = (
  fromEntityType: IEntityTypeCore | undefined,
  toEntityType: IEntityTypeCore,
): Record<IColumnTypeId, IDiff> => {
  if (fromEntityType == null) {
    return mapValues(
      keyByNoUndefined(toEntityType.columns, (column) => column.id),
      () => ({ type: "added" }),
    );
  }

  const fromColumnsById = keyByNoUndefined(fromEntityType.columns, (column) => column.id);

  return pickBy(
    mapValues(
      keyByNoUndefined(toEntityType.columns, (column) => column.id),
      (column) => {
        const fromColumn = fromColumnsById[column.id];

        if (fromColumn == null) {
          return {
            type: "added" as const,
          };
        }

        if (!isEqual(column.columnType, fromColumn.columnType)) {
          return {
            type: "changed" as const,
            dataTypeChanged: true,
          };
        }

        return undefined;
      },
    ),
    isNonNullable,
  );
};
