import {
  ActionId,
  ApplicationGroupId,
  RelationId,
  StateId,
  StateMachineMetadataId,
  StateMachineMetadataVersionId,
  TransitionId,
} from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { VersionIdentifiable } from "./common/VersionIdentifiable";
import { RelationDirection } from "./dataModel/RelationBase";
import { ViewField } from "./dataModel/ViewField";
import { StateColor } from "./stateMachine/stateColors";
import { StateMachineDataModel } from "./StateMachineDataModel";

export const TransitionEmailSideEffect = z.object({
  isEnabled: z.boolean(),
  toPersonRelation: z.object({ relationId: RelationId, direction: RelationDirection }).nullable(),
  viewFieldsToSend: z.array(ViewField),
});
export type ITransitionEmailSideEffect = z.infer<typeof TransitionEmailSideEffect>;

export const StateMachineState = z.object({
  id: StateId,
  label: ReadableString,
  color: StateColor,
});
export type IStateMachineState = z.infer<typeof StateMachineState>;

export const StateMachineStateTransition = z.object({
  from: StateId,
  to: StateId,
  /**
   * Unique identifier for the transition in the state machine
   */
  id: TransitionId,
  actionId: ActionId,
});
export type IStateMachineStateTransition = z.infer<typeof StateMachineStateTransition>;

export const StateMachineInitialState = z.object({
  state: StateId,
  actionId: ActionId,
});
export type IStateMachineInitialState = z.infer<typeof StateMachineInitialState>;

// Probably want a safer approach but this is a start
export const AI_AGENT_ID = "AI Agent";

export const AiAgentDefinition = z.object({
  enabled: z.boolean(),
  instructions: z.string(),
  rawInstructions: z.string(),
  // Can also add sub selection of which transitions it has access to
  // and special instructions per state, in particular to select the inputs
});

export const StateMachine = z.object({
  /**
   * Record from StateId, but zod makes it a partial record with a branded string
   */
  states: z.record(z.string(), StateMachineState),
  /**
   * All deleted states should be kept here to reuse old ids, and still be able to render
   * an entity status in case there is an entity in a state that was deleted.
   *
   * Record from StateId, but zod makes it a partial record with a branded string
   */
  archivedStates: z.record(z.string(), StateMachineState).optional(),

  initialState: StateMachineInitialState,

  stateTransitions: z.array(StateMachineStateTransition),

  /**
   * From stateIds, aiAgents are defined on states?
   */
  aiAgents: z.record(StateId, AiAgentDefinition.optional()),
  /**
   * Path of stateIds
   */
  happyPath: z.array(StateId),
  dataModel: StateMachineDataModel,
});

export const StateMachineMetadata = VersionIdentifiable(
  StateMachineMetadataId,
  StateMachineMetadataVersionId,
  z.null(),
  z.null(),
).merge(
  z.object({
    /**
     * The application the module instance is part of
     */
    applicationGroupId: ApplicationGroupId,

    stateMachine: StateMachine,
  }),
);

export type IAiAgentDefinition = z.infer<typeof AiAgentDefinition>;
export type IStateMachine = z.infer<typeof StateMachine>;
export type IStateMachineMetadata = z.infer<typeof StateMachineMetadata>;
