import type { ILoadedAction } from "@archetype/core";
import type {
  IActionCurrentUserInfo,
  IEntityType,
  IEntityTypeCore,
  IRelationCore,
  ISingleFormValidationErrors,
  IVersionType,
  IViewFieldValue,
} from "@archetype/dsl";
import type { IEntityTypeId, IOrganizationId, IRelationId, IViewFieldId } from "@archetype/ids";
import { Button, cn } from "@archetype/ui";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";
import type { UseFormReturn } from "react-hook-form";

import type { IGetActionRoute, IGetHighlightedViewFieldRoute, IGetLinkedEntityRoute } from "../api";
import type { IActionFormExternalUserCreateProps } from "./ActionForm";
import { ActionFormField } from "./ActionFormField";

interface IActionFormExternalUserFormProps {
  versionType: IVersionType;
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>> | undefined;
  allRelations: Partial<Record<IRelationId, IRelationCore>> | undefined;
  organizationId: IOrganizationId;
  currentUserInfo: IActionCurrentUserInfo;
  getActionRoute: IGetActionRoute;
  getFullPageActionRoute: IGetActionRoute;
  getHighlightedViewFieldRoute: IGetHighlightedViewFieldRoute | undefined;
  getLinkedEntityRoute: IGetLinkedEntityRoute;
  form: UseFormReturn<Partial<Record<string, IViewFieldValue>>>;
  externalUserUpsertProps: IActionFormExternalUserCreateProps;
  validationErrors: ISingleFormValidationErrors;
  onSetValue: (id: IViewFieldId, value: IViewFieldValue, isUserEdited: boolean) => void;
}

export const ActionFormExternalUserForm: React.FC<IActionFormExternalUserFormProps> = ({
  versionType,
  organizationId,
  allEntityTypes,
  allRelations,
  currentUserInfo,
  getActionRoute,
  getFullPageActionRoute,
  getHighlightedViewFieldRoute,
  getLinkedEntityRoute,
  externalUserUpsertProps,
  validationErrors,
  onSetValue,
  form,
}) => {
  const { externalUserUpsertAction, userEntityType } = externalUserUpsertProps;
  const router = useRouter();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-items-start gap-2">
        <Link
          className="no-underline"
          href={{
            pathname: "/sign-in/[[...index]]",
            query: {
              redirect_url: router.asPath,
            },
          }}
        >
          <Button variant="secondary">Sign in</Button>
        </Link>
        <Link
          className="no-underline"
          href={{
            pathname: "/sign-up/[[...index]]",
            query: {
              redirect_url: router.asPath,
            },
          }}
        >
          <Button variant="primary">Sign up</Button>
        </Link>
      </div>
      <div className="flex items-center gap-2">
        <div className="h-px w-full bg-gray-200" />
        <div className="text-sm text-gray-500">or</div>
        <div className="h-px w-full bg-gray-200" />
      </div>
      <ActionFormExternalUserCreateForm
        action={externalUserUpsertAction}
        allEntityTypes={allEntityTypes}
        allRelations={allRelations}
        currentUserInfo={currentUserInfo}
        externalUserUpsertProps={externalUserUpsertProps}
        form={form}
        getActionRoute={getActionRoute}
        getFullPageActionRoute={getFullPageActionRoute}
        getHighlightedViewFieldRoute={getHighlightedViewFieldRoute}
        getLinkedEntityRoute={getLinkedEntityRoute}
        organizationId={organizationId}
        userEntityType={userEntityType}
        validationErrors={validationErrors}
        versionType={versionType}
        // eslint-disable-next-line react/jsx-handler-names -- passthrough
        onSetValue={onSetValue}
      />
    </div>
  );
};

const ActionFormExternalUserCreateForm: React.FC<
  IActionFormExternalUserFormProps & {
    action: ILoadedAction;
    userEntityType: IEntityType;
  }
> = ({
  versionType,
  allEntityTypes,
  allRelations,
  userEntityType,
  action,
  currentUserInfo,
  organizationId,
  getHighlightedViewFieldRoute,
  getLinkedEntityRoute,
  validationErrors,
  onSetValue,
  form,
}) => {
  const allEntityTypesWithUserEntityType = useMemo(() => {
    return {
      ...allEntityTypes,
      [userEntityType.id]: userEntityType,
    };
  }, [allEntityTypes, userEntityType]);

  return (
    <div className={cn("flex flex-col space-y-2")}>
      {action.actionDefinition.inputs.map((input) => (
        <ActionFormField
          key={input.viewField.id}
          actionId={action.id}
          allEntityTypes={allEntityTypesWithUserEntityType}
          allRelations={allRelations}
          computationStatus={undefined}
          createNewProps={undefined}
          currentUserInfo={currentUserInfo}
          dependencies={undefined}
          emailDraftValue={undefined}
          entityId={undefined}
          entityTypeId={action.entityTypeId}
          errors={validationErrors.fieldErrors[input.viewField.id] ?? []}
          form={form}
          getHighlightedViewFieldRoute={getHighlightedViewFieldRoute}
          getLinkedEntityRoute={getLinkedEntityRoute}
          highlightedViewFieldId={undefined}
          input={input}
          isSavingDraft={false}
          modifyingEntity={undefined}
          organizationId={organizationId}
          parentFormExternalUserFieldValues={undefined}
          parentFormExternalUserSyntheticEntityId={undefined}
          parentFormNestedCreateFormValues={undefined}
          previousOrLogicFieldValue={undefined}
          readOnly={!input.allowChangingDefault}
          versionType={versionType}
          // eslint-disable-next-line react/jsx-handler-names -- passthrough
          onSetValue={onSetValue}
        />
      ))}
    </div>
  );
};
