import type { ILoadedAction, ILoadedCreateNestedAction, ILoadedEntity, ILoadedEntityType } from "@archetype/core";
import { isAIViewField, isDerivedViewField } from "@archetype/core";
import type {
  IActionCurrentUserInfo,
  IEntityActionDraft,
  IEntityTypeCore,
  IRelationCore,
  IVersionType,
  IViewFieldValue,
} from "@archetype/dsl";
import type { IActionId, IEntityId, IEntityTypeId, IOrganizationId, IRelationId, IViewFieldId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { NonIdealState } from "@archetype/ui";
import { generateTitle } from "@archetype/utils";
import { skipToken } from "@tanstack/react-query";
import Head from "next/head";
import { useCallback, useMemo } from "react";

import type {
  IGetActionRoute,
  IGetEntityRoute,
  IGetEntityTypeRoute,
  IGetHighlightedViewFieldRoute,
  IGetLinkedEntityRoute,
} from "../api";
import { useIsSignedIn, useIsSignedInOrganization } from "../auth/AuthenticationContext";
import { FieldsRenderer } from "../entity/FieldsRenderer";
import { fieldValueToFieldRendererValue } from "../entity/fieldValueToFieldRendererValue";
import { getIconForViewField } from "../entityType/ColumnTypeUtils";
import { useLoadedEntity } from "../hooks/entity/useLoadedEntity";
import { useStableCurrentUserInfo } from "../hooks/useStableCurrentUserInfo";
import { withRefetchInterval } from "../utils/refetchInterval";
import { getActionReferenceEntity, type IActionEntityReference } from "./actionExecution/actionEntityReference";
import type { IActionFormExternalUserCreateProps } from "./ActionForm";
import { ActionFormWrapper } from "./ActionFormWrapper";
import { ActionViewSkeleton } from "./ActionViewSkeleton";
import { useCreateActionDraftOrRedirect } from "./useCreateActionDraftOrRedirect";

interface IActionViewProps {
  organizationId: IOrganizationId;
  versionType: IVersionType;
  actionId: IActionId;
  entityTypeId: IEntityTypeId;
  entityId: IEntityId | undefined;
  isCreateActionDraft: boolean;
  onActionExecuted: (entity: ILoadedEntity | undefined) => Promise<void>;
  highlightedViewFieldId: IViewFieldId | undefined;
  defaultValues?: Partial<Record<IViewFieldId, IViewFieldValue>>;
  getActionRoute: IGetActionRoute;
  getFullPageActionRoute: IGetActionRoute;
  getEntityRoute: IGetEntityRoute;
  getEntityTypeRoute: IGetEntityTypeRoute;
  getLinkedEntityRoute: IGetLinkedEntityRoute;
  getHighlightedViewFieldRoute: IGetHighlightedViewFieldRoute | undefined;
  showTitle?: boolean;
}

export const ActionView: React.FC<IActionViewProps> = ({
  organizationId,
  versionType,
  actionId,
  entityTypeId,
  entityId,
  isCreateActionDraft: isCreateActionDraftSelection,
  onActionExecuted: handleActionExecuted,
  highlightedViewFieldId,
  defaultValues,
  getActionRoute,
  getEntityRoute,
  getEntityTypeRoute,
  getLinkedEntityRoute,
  getFullPageActionRoute,
  getHighlightedViewFieldRoute,
  showTitle = true,
}) => {
  const isSignedIn = useIsSignedIn();
  const isSignedInOrganization = useIsSignedInOrganization();
  const isCreateActionDraft = isCreateActionDraftSelection && isSignedInOrganization;

  const { data: actionData, isLoading: isLoadingAction } = trpc.dataModel.getFullyLoadedActionById.useQuery({
    versionType,
    actionId,
  });

  const { entityData, isLoadingEntity } = useLoadedEntity({
    query: {
      organizationId,
      versionType,
      entityTypeId,
      // won't be called if there is no entityId param
      entityId,
    },
    // There won't actually be a draft if is a createActionDraft
    options: { explicitlyDisabled: entityId == null || isCreateActionDraft },
  });

  const entity = entityData?.entity;
  const action = actionData?.action;

  const { data: entityTypeQuery, isLoading: isLoadingEntityType } = trpc.dataModel.fullyLoadedEntityType.useQuery({
    versionType,
    id: entityTypeId,
  });

  const { data: actionAccessQuery, isLoading: isLoadingActionAccess } = trpc.action.getAccessToAction.useQuery(
    {
      versionType,
      entityTypeId,
      entityId: entityId ?? null,
      actionId: actionId,
      isCreateActionDraft,
    },
    {
      ...withRefetchInterval(10000),
    },
  );

  const entityType = entityTypeQuery?.entityType;

  // Used only for rendering should not be blocking with a loader/skeleton
  const { data: allEntityTypesQuery } = trpc.dataModel.allEntityTypesInOrganization.useQuery({
    versionType,
    organizationId: organizationId,
  });

  // Not blocking loading because only apparent when used
  const { data: nestedActionsQuery } = trpc.dataModel.getExecutableNestedActions.useQuery({
    versionType,
    parentActionId: actionId,
    entityTypeId: entityTypeId,
  });

  // prefetching if there is a draft
  const { data: initialDraft, isLoading: isLoadingCurrentDraft } = trpc.action.getDraftForAction.useQuery(
    entityId == null
      ? skipToken
      : {
          versionType,
          actionId,
          entityId,
          entityTypeId,
          organizationId,
        },
  );

  const { data: externalUserCreateActionQuery, isLoading: isLoadingExternalUserCreateActionQuery } =
    trpc.action.getExternalUserCreateAction.useQuery(
      {
        actionId,
        versionType,
        organizationId,
      },
      {
        enabled: !isSignedIn,
      },
    );

  const externalUserUpsertProps: IActionFormExternalUserCreateProps | undefined = useMemo(
    () =>
      externalUserCreateActionQuery != null
        ? {
            ...externalUserCreateActionQuery,
            renderExternalUserForm: true,
          }
        : undefined,
    [externalUserCreateActionQuery],
  );

  const { data: currentUserInfoQuery, isLoading: isLoadingCurrentUserInfo } = useStableCurrentUserInfo({
    versionType,
  });

  const { isCreatingDraft } = useCreateActionDraftOrRedirect({
    actionId,
    entityTypeId,
    organizationId,
    entityId,
    versionType,
    defaultValues,
    getActionRoute,
  });

  const entityReference: IActionEntityReference | { type: "invalid" } | undefined = useMemo(() => {
    if (isCreateActionDraft) {
      if (entityId == null) {
        // Invalid state should be loading anyway and redirected after creating the draft above
        return {
          type: "invalid" as const,
        };
      }

      return {
        type: "createDraftEntity" as const,
        entityId,
      };
    }

    if (action?.actionDefinition.actionType === "add") {
      return undefined;
    }

    if (entity == null) {
      // Invalid state should be loading
      return undefined;
    }

    return {
      type: "modifyingEntity" as const,
      entity: entity,
    };
  }, [action, entity, isCreateActionDraft, entityId]);

  if (
    isLoadingAction ||
    isLoadingEntity ||
    isLoadingEntityType ||
    isLoadingActionAccess ||
    isCreatingDraft ||
    isLoadingCurrentDraft ||
    isLoadingExternalUserCreateActionQuery ||
    isLoadingCurrentUserInfo
  ) {
    // Not isAccessLoading because that's handled on other component to maybe show title
    return <ActionViewSkeleton />;
  }

  if (action == null || actionAccessQuery == null || entityType == null || currentUserInfoQuery == null) {
    return (
      <div className="flex grow flex-col items-center justify-center">
        <NonIdealState resourceType="action" variant="error" />
      </div>
    );
  }

  if (entityReference?.type === "invalid") {
    return (
      <div className="flex grow flex-col items-center justify-center">
        <NonIdealState resourceType="entity" variant="error" />
      </div>
    );
  }

  return (
    <LoadedActionView
      action={action}
      allEntityTypes={allEntityTypesQuery?.entityTypes}
      allRelations={allEntityTypesQuery?.relations}
      canExecute={actionAccessQuery.canExecute}
      currentUserInfo={currentUserInfoQuery.currentUserInfo}
      defaultValues={defaultValues}
      draft={initialDraft?.draft ?? undefined}
      entityReference={entityReference}
      entityType={entityType}
      externalUserUpsertProps={externalUserUpsertProps}
      getActionRoute={getActionRoute}
      getEntityRoute={getEntityRoute}
      getEntityTypeRoute={getEntityTypeRoute}
      getFullPageActionRoute={getFullPageActionRoute}
      getHighlightedViewFieldRoute={getHighlightedViewFieldRoute}
      getLinkedEntityRoute={getLinkedEntityRoute}
      highlightedViewFieldId={highlightedViewFieldId}
      nestedActionByViewFieldId={nestedActionsQuery?.nestedActionByViewFieldId}
      organizationId={organizationId}
      showTitle={showTitle}
      versionType={versionType}
      onActionExecuted={handleActionExecuted}
    />
  );
};

type ILoadedActionViewProps = Omit<
  IActionViewProps,
  "entityId" | "actionId" | "entityTypeId" | "isCreateActionDraft"
> & {
  action: ILoadedAction;
  entityReference: IActionEntityReference | undefined;
  currentUserInfo: IActionCurrentUserInfo;
  draft: IEntityActionDraft | undefined;
  entityType: ILoadedEntityType;
  canExecute: boolean;
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>> | undefined;
  allRelations: Partial<Record<IRelationId, IRelationCore>> | undefined;
  externalUserUpsertProps: IActionFormExternalUserCreateProps | undefined;
  nestedActionByViewFieldId: Partial<Record<IViewFieldId, ILoadedCreateNestedAction>> | undefined;
};

const LoadedActionView: React.FC<ILoadedActionViewProps> = ({
  versionType,
  action,
  entityReference,
  currentUserInfo,
  externalUserUpsertProps,
  draft,
  entityType,
  organizationId,
  canExecute,
  allEntityTypes,
  allRelations,
  nestedActionByViewFieldId,
  onActionExecuted: handleActionExecuted,
  highlightedViewFieldId,
  defaultValues,
  getActionRoute,
  getEntityRoute,
  getEntityTypeRoute,
  getLinkedEntityRoute,
  getFullPageActionRoute,
  getHighlightedViewFieldRoute,
  showTitle = true,
}) => {
  const modifyingEntity = getActionReferenceEntity(entityReference);

  const maybeRenderEntityCard = useCallback(() => {
    if (!canExecute) {
      return null;
    }

    if (action.actionDefinition.actionType === "add" || modifyingEntity == null) {
      return null;
    }

    const contextualFields = action.actionDefinition.contextualFields;
    const fields = contextualFields.map((field) => ({
      id: field.id,
      icon: getIconForViewField(field, allEntityTypes ?? {}),
      displayName: field.displayName,
      computationStatus: modifyingEntity.fieldsComputationStatuses[field.id]?.status,
      isDerived: isDerivedViewField(field),
      isAiAutofilled: isAIViewField(field),
      isSavingInAction: false, // Could be technically be true but might be confusing to show
      value: fieldValueToFieldRendererValue(modifyingEntity.fields[field.id], field),
    }));

    if (contextualFields.length > 0) {
      return (
        <FieldsRenderer
          entity={modifyingEntity}
          entityId={modifyingEntity.entityId}
          entityTypeId={modifyingEntity.entityTypeId}
          fields={fields}
          getHighlightedViewFieldRoute={getHighlightedViewFieldRoute}
          getLinkedEntityRoute={getLinkedEntityRoute}
          highlightedViewFieldId={highlightedViewFieldId}
          organizationId={organizationId}
          versionType={versionType}
        />
      );
    }

    return null;
  }, [
    action,
    modifyingEntity,
    getLinkedEntityRoute,
    canExecute,
    getHighlightedViewFieldRoute,
    highlightedViewFieldId,
    allEntityTypes,
    versionType,
    organizationId,
  ]);

  const renderTitle = useCallback(() => {
    if (!showTitle) {
      return null;
    }

    if (action.actionDefinition.actionType === "add") {
      return <h1>Create {entityType.displayMetadata.name}</h1>;
    }

    return (
      <div className="flex flex-col gap-2">
        <div className="text-lg font-semibold uppercase">{action.displayMetadata.name}</div>
        <h1>{modifyingEntity ? modifyingEntity.displayName : ""}</h1>
      </div>
    );
  }, [action, modifyingEntity, entityType, showTitle]);

  const backRoute =
    modifyingEntity != null
      ? getEntityRoute({ entityTypeId: modifyingEntity.entityTypeId, entityId: modifyingEntity.entityId })
      : getEntityTypeRoute({ entityTypeId: action.entityTypeId });

  if (!canExecute) {
    return (
      <>
        <Head>
          <title>
            {generateTitle(
              `${action.displayMetadata.name} ${modifyingEntity?.displayName ?? ""} on ${entityType.displayMetadata.pluralName}`,
              versionType === "dev",
            )}
          </title>
        </Head>
        <div className="flex w-full grow flex-col items-center" data-testid="action-view">
          <div className="text-ink flex w-full max-w-2xl grow flex-col space-y-6 self-center">
            <div className="flex max-w-full grow flex-col space-y-10">
              {renderTitle()}
              <div className="space-y-10">
                <NonIdealState backRoute={backRoute} resourceType="form" variant="permissionDenied" />
              </div>
            </div>
          </div>
        </div>
      </>
    );

    return;
  }

  return (
    <>
      <Head>
        <title>
          {generateTitle(
            `${action.displayMetadata.name} ${modifyingEntity?.displayName ?? ""} on ${entityType.displayMetadata.pluralName}`,
            versionType === "dev",
          )}
        </title>
      </Head>
      <div className="flex w-full grow flex-col items-center" data-testid="action-view">
        <div className="text-ink flex w-full max-w-2xl grow flex-col space-y-6 self-center">
          <div className="flex max-w-full grow flex-col space-y-10">
            {renderTitle()}
            <div className="space-y-10">
              {maybeRenderEntityCard()}
              <ActionFormWrapper
                action={action}
                allEntityTypes={allEntityTypes}
                allRelations={allRelations}
                currentUserInfo={currentUserInfo}
                defaultValues={defaultValues}
                emailDraftValues={draft?.emailExtractedData ?? undefined}
                entityReference={entityReference}
                externalUserUpsertProps={externalUserUpsertProps}
                getActionRoute={getActionRoute}
                getEntityRoute={getEntityRoute}
                getEntityTypeRoute={getEntityTypeRoute}
                getFullPageActionRoute={getFullPageActionRoute}
                getHighlightedViewFieldRoute={getHighlightedViewFieldRoute}
                getLinkedEntityRoute={getLinkedEntityRoute}
                highlightedViewFieldId={highlightedViewFieldId}
                initialDraftValues={draft?.fields}
                isUserEditedInDraftValues={draft?.isUserEditedFields}
                nestedActionByViewFieldId={nestedActionByViewFieldId}
                organizationId={organizationId}
                versionType={versionType}
                onActionExecuted={handleActionExecuted}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
