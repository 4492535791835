import type { ICompositeTypeInternalReferenceId, ICompositeTypeUnionedSetId } from "@archetype/ids";
import { map, mapValues } from "@archetype/utils";
import { z } from "zod";

import type { ICompositeTree } from "../../utils/dataModel/resolveCompositesTree";
import { createColumnTypeValidator } from "../columnTypes/rootValidator";

export const createCompositeTypeValidator = (tree: ICompositeTree): z.ZodTypeAny => {
  const toUnion = map(tree.composite.definition.unions, (intersections, unionedId) => {
    const zipped = mapValues(intersections, (childId, refId) => {
      const child =
        tree.unionedChildren[unionedId as ICompositeTypeUnionedSetId]?.[refId as ICompositeTypeInternalReferenceId];

      if (child == null) {
        throw new Error(`Child ${refId} not found in resolved tree`);
      }

      return {
        childId,
        child,
      };
    });

    return z.object(mapValues(zipped, ({ child }) => createColumnTypeValidator(child))) as z.ZodTypeAny;
  });

  if (toUnion.length === 0) {
    throw new Error("Composite type must have at least one unioned set");
  }

  // z.union requires at least two arguments, this workaround is to satisfy that
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe with logic
  return toUnion.length === 1 ? toUnion[0]! : z.union([toUnion[0]!, toUnion[1]!, ...toUnion.slice(2)]);
};
