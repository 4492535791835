import type { ILoadedAction } from "@archetype/core";
import type {
  IActionCurrentUserInfo,
  IEntityTypeCore,
  IRelationCore,
  ISingleFormValidationErrors,
  IVersionType,
  IViewFieldValue,
} from "@archetype/dsl";
import type { IEntityId, IEntityTypeId, IOrganizationId, IRelationId, IViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { Button, cn, Form, FormMessage, Separator, ShapeColorIcon, Skeleton, useMemoDeepCompare } from "@archetype/ui";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useDeepCompareEffect } from "react-use";

import type { IGetLinkedEntityRoute } from "../api";
import { ActionFormField } from "./ActionFormField";

interface IActionViewNestedCreateProps {
  className?: string;
  versionType: IVersionType;
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>> | undefined;
  allRelations: Partial<Record<IRelationId, IRelationCore>> | undefined;
  /**
   * Must be a create action
   */
  action: ILoadedAction;
  currentUserInfo: IActionCurrentUserInfo;
  createDraftEntityId: IEntityId;
  relevantFields: IViewFieldId[];
  isLoading: boolean;
  organizationId: IOrganizationId;
  validationErrors: ISingleFormValidationErrors;
  onSyncFormValues: (args: {
    nestedCreateEntityId: IEntityId;
    values: Partial<Record<IViewFieldId, IViewFieldValue>>;
  }) => void;
  onCancel: () => void;
  getLinkedEntityRoute: IGetLinkedEntityRoute;
}

export const ActionViewNestedCreate: React.FC<IActionViewNestedCreateProps> = ({
  className,
  versionType,
  allEntityTypes,
  allRelations,
  action,
  createDraftEntityId,
  currentUserInfo,
  relevantFields,
  organizationId,
  validationErrors,
  onCancel: handleCancel,
  onSyncFormValues,
  getLinkedEntityRoute,
}) => {
  // Only valid if `relevantFields` is provided
  const [hideIrrelevantFields, setHideIrrelevantFields] = useState(true);

  const handleToggleAllFields = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      // Because nested in form
      e.preventDefault();
      e.stopPropagation();
      setHideIrrelevantFields((prevHideIrrelevantFields) => !prevHideIrrelevantFields);
    },
    [setHideIrrelevantFields],
  );

  const relevantFieldIdsSet = useMemoDeepCompare(
    () =>
      new Set(
        relevantFields.concat(
          action.actionDefinition.inputs
            .filter((i) => !i.allowChangingDefault || i.required)
            .map((i) => i.viewField.id),
        ),
      ),
    [relevantFields, action.actionDefinition.inputs],
  );

  const skippedFieldIdsSet = useMemoDeepCompare(
    () =>
      new Set(
        action.actionDefinition.inputs
          .filter((i) => i.allowChangingDefault && !relevantFieldIdsSet.has(i.viewField.id))
          .map((i) => i.viewField.id),
      ),
    [action.actionDefinition.inputs, relevantFieldIdsSet],
  );

  const form = useForm<Partial<Record<string, IViewFieldValue>>>({});

  const formValues = form.watch();

  useDeepCompareEffect(() => {
    onSyncFormValues({
      nestedCreateEntityId: createDraftEntityId,
      values: formValues,
    });
  }, [formValues, createDraftEntityId, onSyncFormValues]);

  const { data: entityTypeQuery } = builderTrpc.dataModel.fullyLoadedEntityType.useQuery({
    versionType,
    id: action.entityTypeId,
  });

  const { data: dependenciesQuery } = builderTrpc.dataModel.getFieldsDependencies.useQuery({
    versionType,
    entityTypeId: action.entityTypeId,
  });

  const dependencies = dependenciesQuery?.dependencies;

  const handleSetValue = useCallback(
    (id: IViewFieldId, value: IViewFieldValue, isUserEdited: boolean) => {
      // clearColumnValidationErrors(id);
      form.setValue(id, value, { shouldTouch: isUserEdited });
      // void saveDraft({ fieldId: id, value, isUserEdit: isUserEdited });
    },
    [form],
  );

  return (
    <div className={cn("border-border text-ink flex w-full max-w-2xl grow flex-col rounded-md border", className)}>
      <div className="flex flex-row items-center justify-between space-x-2 p-4">
        <div className="flex flex-row items-center space-x-2">
          <ShapeColorIcon
            color={entityTypeQuery?.entityType.color ?? "neutral"}
            shape={entityTypeQuery?.entityType.shape ?? "triangle"}
            size="md"
          />
          {entityTypeQuery != null ? (
            <span className="font-medium">Create New {entityTypeQuery.entityType.displayMetadata.name}</span>
          ) : (
            <Skeleton className="h-4 w-24" />
          )}
        </div>
        <Button iconLeft="x" size="xs" title="Cancel" variant="ghost" onClick={handleCancel} />
      </div>
      <Separator />
      {/* This is intentionally not ActionFormWrapper because it contains some draft management that's only for a parent form */}
      <Form {...form}>
        <form className={cn("my-2 flex flex-col space-y-4 px-4")}>
          {action.actionDefinition.inputs
            .filter((input) => relevantFieldIdsSet.has(input.viewField.id))
            .map((input) => (
              <ActionFormField
                key={input.viewField.id}
                actionId={action.id}
                allEntityTypes={allEntityTypes}
                allRelations={allRelations}
                computationStatus={undefined}
                createNewProps={undefined}
                currentUserInfo={currentUserInfo}
                dependencies={dependencies?.[input.viewField.id]}
                emailDraftValue={undefined}
                entityId={undefined}
                entityTypeId={action.entityTypeId}
                errors={validationErrors.fieldErrors[input.viewField.id] ?? []}
                form={form}
                getHighlightedViewFieldRoute={undefined}
                getLinkedEntityRoute={getLinkedEntityRoute}
                highlightedViewFieldId={undefined}
                input={input}
                isSavingDraft={false} // TODO nested forms - can mark as saving draft
                modifyingEntity={undefined}
                organizationId={organizationId}
                parentFormExternalUserFieldValues={undefined}
                parentFormExternalUserSyntheticEntityId={undefined}
                parentFormNestedCreateFormValues={undefined}
                previousOrLogicFieldValue={undefined}
                readOnly={!input.allowChangingDefault}
                versionType={versionType}
                onSetValue={handleSetValue}
              />
            ))}
          {skippedFieldIdsSet.size > 0 && (
            <Button
              className="w-full"
              iconLeft={hideIrrelevantFields ? "chevron-down" : "chevron-up"}
              size="xs"
              variant="ghost"
              onClick={handleToggleAllFields}
            >
              {hideIrrelevantFields ? "Show all fields" : "Hide extra fields"}
            </Button>
          )}
          {!hideIrrelevantFields &&
            skippedFieldIdsSet.size > 0 &&
            action.actionDefinition.inputs
              .filter((i) => skippedFieldIdsSet.has(i.viewField.id))
              .map((input) => (
                <ActionFormField
                  key={input.viewField.id}
                  actionId={action.id}
                  allEntityTypes={allEntityTypes}
                  allRelations={allRelations}
                  computationStatus={undefined}
                  createNewProps={undefined}
                  currentUserInfo={currentUserInfo}
                  dependencies={dependencies?.[input.viewField.id]}
                  emailDraftValue={undefined}
                  entityId={undefined}
                  entityTypeId={action.entityTypeId}
                  errors={validationErrors.fieldErrors[input.viewField.id] ?? []}
                  form={form}
                  getHighlightedViewFieldRoute={undefined}
                  getLinkedEntityRoute={getLinkedEntityRoute}
                  highlightedViewFieldId={undefined}
                  input={input}
                  isSavingDraft={false} // TODO nested forms - can mark as saving draft
                  modifyingEntity={undefined}
                  organizationId={organizationId}
                  parentFormExternalUserFieldValues={undefined}
                  parentFormExternalUserSyntheticEntityId={undefined}
                  parentFormNestedCreateFormValues={undefined}
                  previousOrLogicFieldValue={undefined}
                  readOnly={!input.allowChangingDefault}
                  versionType={versionType}
                  onSetValue={handleSetValue}
                />
              ))}
        </form>
        {validationErrors.generalErrors.map((error) => (
          <FormMessage key={error}>{error}</FormMessage>
        ))}
      </Form>
    </div>
  );
};
