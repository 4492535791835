import type { IActionCore, IActionDefinition } from "@archetype/dsl";
import { computeRelationViewFieldId } from "@archetype/dsl";
import type { IActionId, IViewFieldId } from "@archetype/ids";
import { forEach, mapValues } from "@archetype/utils";

import type { IDeleteRelationOperation, IOperationsEdits } from "./operations";

export const applyDeleteRelationOperation = (
  operation: IDeleteRelationOperation,
  currentInfo: IOperationsEdits,
): IOperationsEdits => {
  const { relationId } = operation;

  const relationViewFieldsToDelete = new Set([
    computeRelationViewFieldId(relationId, "aToB"),
    computeRelationViewFieldId(relationId, "bToA"),
  ]);

  const editedActions: Record<IActionId, IActionCore> = {};

  forEach(currentInfo.newOrEditedActions, (action) => {
    const editedActionDefinition = actionDefinitionWithoutDeletedFields(
      relationViewFieldsToDelete,
      action.actionDefinition,
    );

    editedActions[action.id] = {
      ...action,
      actionDefinition: editedActionDefinition,
    };
  });

  const editedEntityTypes = mapValues(currentInfo.newOrEditedEntityTypes, (entityType) => {
    return {
      ...entityType,
      relevantViewFieldsByStateId: mapValues(entityType.relevantViewFieldsByStateId, (viewFields) => {
        return viewFields.filter((viewField) => !relationViewFieldsToDelete.has(viewField.id));
      }),
    };
  });

  return {
    ...currentInfo,
    newOrEditedActions: editedActions,
    deletedRelations: currentInfo.deletedRelations.concat([operation.relationId]),
    newOrEditedEntityTypes: editedEntityTypes,
  };
};

// Exported to be used by column deletion
export const actionDefinitionWithoutDeletedFields = (
  deletedFieldIds: Set<IViewFieldId>,
  actionDefinition: IActionDefinition,
): IActionDefinition => ({
  ...actionDefinition,
  contextualFields: actionDefinition.contextualFields.filter((f) => !deletedFieldIds.has(f.id)),
  inputs: actionDefinition.inputs.filter((f) => !deletedFieldIds.has(f.viewField.id)),
});
