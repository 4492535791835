import type { IStateId } from "@archetype/ids";
import {
  ActionId,
  ActionVersionId,
  ApplicationGroupId,
  EntityId,
  EntityTypeId,
  OrganizationId,
  RelationId,
  StateId,
  StateMachineMetadataId,
  StateMachineMetadataVersionId,
  UserId,
} from "@archetype/ids";
import * as z from "zod";

import { Authorization } from "../Authorization";
import { DisplayMetadata } from "../common/DisplayMetadata";
import { VersionIdentifiable } from "../common/VersionIdentifiable";
import { AuthElement, ComputedElement, StaticElement } from "./Element";
import { RelationDirection } from "./RelationBase";
import { ViewField } from "./ViewField";

export const InputDefaultValue = z.discriminatedUnion("type", [StaticElement, ComputedElement, AuthElement]);
export type IInputDefaultValue = z.infer<typeof InputDefaultValue>;

export const ActionCurrentUserInfo = z.object({
  isRealUserEntityId: z.boolean(),
  userEntityId: EntityId,
  userId: UserId.nullable(),
  userEntityTypeId: EntityTypeId,
  userName: z.string(),
  emailAddress: z.string().nullable(),
});
export type IActionCurrentUserInfo = z.infer<typeof ActionCurrentUserInfo>;

export const EmailSideEffect = z.object({
  isEnabled: z.boolean(),
  toPersonRelation: z.object({ relationId: RelationId, direction: RelationDirection }).nullable(),
  /**
   * Configuration only available for the user entity type to send the email to the user itself that the action is for.
   * (the equivalent of an identity relation)
   */
  toSelfIfUserEntity: z.boolean().optional(), // optional because only relevant in rare cases
  viewFieldsToSend: z.array(ViewField),
});
export type IEmailSideEffect = z.infer<typeof EmailSideEffect>;

export const ActionInput = z.object({
  viewField: ViewField,
  defaultValue: InputDefaultValue.optional(),
  allowChangingDefault: z.boolean(),
  /**
   * Cannot be not required if column is nonNullable anyway
   * required for an "add" action should be exactly the same as column nonNullable
   */
  required: z.boolean(),
});
export type IActionInput = z.infer<typeof ActionInput>;

export const ActionKind = z.enum(["add", "modify", "delete"]);
export type IActionKind = z.infer<typeof ActionKind>;

export const ActionDefinitionCore = z.object({
  actionType: ActionKind,
  fromStates: z.array(StateId).optional(),
  toState: StateId.optional(),
  inputs: z.array(ActionInput),
  contextualFields: z.array(ViewField), // Set of columns used to show context in a form.
  sideEffects: z
    .object({
      email: EmailSideEffect,
    })
    .optional(),
  authorizedByAnyOf: z
    .array(Authorization)
    .describe("A user can execute this execute when any of these authorizations apply"),
  authorizedForAnyoneWithLink: z
    .boolean()
    .optional()
    .describe("if true, ANYONE on the web with the link can execute this action"),
});
const actionDefinitionRefinement = [
  (data: IActionCoreDefinition): boolean =>
    data.actionType === "add" || data.authorizedForAnyoneWithLink === false || data.authorizedForAnyoneWithLink == null,
  {
    message: "Action cannot be authorized for anyone with link if it's not an add action",
  },
] as const;

export const ActionCoreDefinition = ActionDefinitionCore.refine(...actionDefinitionRefinement);
export type IActionCoreDefinition = z.infer<typeof ActionDefinitionCore>;

export const ActionCore = z.object({
  id: ActionId,
  displayMetadata: DisplayMetadata,
  entityTypeId: EntityTypeId,
  organizationId: OrganizationId,
  applicationGroupId: ApplicationGroupId.nullable(),
  actionDefinition: ActionCoreDefinition,
});
export type IActionCore = z.infer<typeof ActionCore>;

export const ActionDefinition = ActionDefinitionCore.omit({
  authorizedByAnyOf: true,
})
  .extend({
    authorizedByAnyOf: z.array(Authorization),
  })
  .refine(...actionDefinitionRefinement);
export type IActionDefinition = z.infer<typeof ActionDefinition>;

export const Action = VersionIdentifiable(
  ActionId,
  ActionVersionId,
  StateMachineMetadataId.nullable(),
  StateMachineMetadataVersionId.nullable(),
).merge(ActionCore);
export type IAction = z.infer<typeof Action>;

export const makeEmptyEditActionDefinition = ({
  stateInfo,
}: {
  stateInfo:
    | undefined
    | {
        fromState: IStateId;
        toState: IStateId;
      };
}): IActionDefinition => ({
  actionType: "add",
  fromStates: stateInfo?.fromState == null ? [] : [stateInfo.fromState],
  toState: stateInfo?.toState,
  inputs: [],
  contextualFields: [],
  authorizedByAnyOf: [],
  sideEffects: undefined,
});
