import { useCallback, useEffect } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableEditingContext } from "../context/DataTableEditingContext";

export function useCellEditOnEnter({
  rowId,
  colId,
  isCellSelected,
  isCellEditing,
}: {
  rowId: string;
  colId: string;
  isCellSelected: boolean;
  isCellEditing: boolean;
}): { handleStartEditing: () => void } {
  const setEditableCell = useContextSelector(DataTableEditingContext, (state) => state.setEditableCell);

  const handleStartEditing = useCallback(() => {
    if (isCellSelected && !isCellEditing) {
      setEditableCell({ rowId, colId });
    }
  }, [isCellSelected, isCellEditing, rowId, colId, setEditableCell]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter" && isCellSelected && !isCellEditing) {
        handleStartEditing();

        e.preventDefault();
        e.stopPropagation();
      }
    },
    [isCellSelected, isCellEditing, handleStartEditing],
  );

  useEffect(() => {
    if (isCellSelected) {
      document.addEventListener("keydown", handleKeyDown);

      return (): void => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isCellSelected, handleKeyDown]);

  return { handleStartEditing };
}
