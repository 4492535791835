import type { EditorConfig, LexicalNode, NodeKey, SerializedLexicalNode } from "lexical";
import { DecoratorNode } from "lexical";
import React from "react";

import { Tooltip, TooltipContent, TooltipTrigger } from "../../../atoms/tooltip";

export interface IAnnotation {
  content: string;
  text: string;
}

interface ISerializedAnnotationNode extends SerializedLexicalNode {
  annotation: IAnnotation;
  type: "annotation";
  version: 1;
}

export class AnnotationNode extends DecoratorNode<React.JSX.Element> {
  __annotation: IAnnotation;

  static getType(): string {
    return "annotation";
  }

  static clone(node: AnnotationNode): AnnotationNode {
    return new AnnotationNode(node.__annotation, node.__key);
  }

  constructor(annotation: IAnnotation, key?: NodeKey) {
    super(key);
    this.__annotation = annotation;
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const dom = document.createElement("span");

    return dom;
  }

  updateDOM(): false {
    return false;
  }

  getAnnotation(): IAnnotation {
    return this.__annotation;
  }

  setAnnotation(annotation: IAnnotation): void {
    const writable = this.getWritable();

    writable.__annotation = annotation;
  }

  getTextContent(): string {
    return this.__annotation.text;
  }

  getClassName(): string {
    return "cursor-pointer bg-accent border-b border-1 border-muted px-1";
  }

  decorate(): React.JSX.Element {
    const hasContent =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- more safety
      this.__annotation.text !== " " && this.__annotation.content !== "" && this.__annotation.content != null;

    const tooltipContent = this.__annotation.content;

    if (!hasContent) {
      return <span className={this.getClassName()}>{this.__annotation.text}</span>;
    }

    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className={this.getClassName()}>{this.__annotation.text}</span>
        </TooltipTrigger>
        <TooltipContent>
          <div className="whitespace-pre-line">{tooltipContent}</div>
        </TooltipContent>
      </Tooltip>
    );
  }

  exportJSON(): ISerializedAnnotationNode {
    return {
      annotation: this.__annotation,
      type: "annotation",
      version: 1,
    };
  }

  static importJSON(serializedNode: ISerializedAnnotationNode): AnnotationNode {
    return new AnnotationNode(serializedNode.annotation);
  }
}

export function $createAnnotationNode(annotation: Omit<IAnnotation, "text">, text: string): AnnotationNode {
  return new AnnotationNode({ ...annotation, text });
}

export function $isAnnotationNode(node: LexicalNode | null | undefined): node is AnnotationNode {
  return node instanceof AnnotationNode;
}
