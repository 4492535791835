import type {
  IActionCore,
  IActionCurrentUserInfo,
  IMinimalEntityWithFields,
  IValidationError,
  IValidationGroup,
  IViewFieldValue,
} from "@archetype/dsl";
import {
  computeColumnViewFieldId,
  isColumnViewField,
  isFixedValueConfig,
  isLiveColumn,
  isRelationFieldValue,
  isRelationViewField,
} from "@archetype/dsl";
import type { IEntityId, IViewFieldId } from "@archetype/ids";
import { forEach, keyByNoUndefined, pickBy } from "@archetype/utils";

import type { IExecuteActionError, ILoadedNestedCreateFormValues } from "../apiTypes/ActionExecution";
import type { ILoadedAction } from "../apiTypes/LoadedActionType";
import type { ILoadedRelationViewField } from "../apiTypes/LoadedViewField";
import { isLoadedColumnViewField, isLoadedRelationViewField } from "../apiTypes/LoadedViewField";
import { convertFixedValueAutofillToInputDefaultValue } from "../dataModel/autofillToActionDefaultsAdapters";
import { compare } from "../dataModel/compare";
import { resolveDefaultValue } from "../dataModel/elements";
import type { IExternalUserCreateProps } from "./externalUserCreateProps";
import { hasNestedActionError } from "./hasNestedActionError";
import {
  getValidatorByType,
  isColumnValidationIndex,
  isFieldValueNullableLike,
  isMissingRequiredField,
} from "./validations/validateEntityOnAction";

// TODO nested forms - we can pass in all relations the nested form is used with to validate those, and then basically execute the filter in the FE
function validateFieldValues({
  loadedAction,
  validationGroups,
  fieldValues,
  entityId,
}: {
  loadedAction: ILoadedAction;
  validationGroups: IValidationGroup[];
  fieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
  entityId?: IEntityId;
}): IValidationError[] {
  const fieldsInAction = loadedAction.actionDefinition.inputs.map((i) => i.viewField);
  const fieldIdsInAction = new Set(fieldsInAction.map((f) => f.id));

  const validationErrors: IValidationError[] = [];

  // check required inputs
  const missingRequiredFieldIds: IViewFieldId[] = [];

  const nonEditableFieldIds: Set<IViewFieldId> = new Set(
    loadedAction.actionDefinition.inputs.filter((i) => !i.allowChangingDefault).map((i) => i.viewField.id),
  );

  loadedAction.actionDefinition.inputs.forEach((input) => {
    const column = isLoadedColumnViewField(input.viewField) ? input.viewField.column : undefined;

    if (!input.allowChangingDefault || (column != null && isLiveColumn(column))) {
      return;
    }

    if (isMissingRequiredField(input, fieldValues[input.viewField.id])) {
      validationErrors.push({
        error: "Field is required",
        viewFieldId: input.viewField.id,
      });
      missingRequiredFieldIds.push(input.viewField.id);
    }
  });

  // check non-nullable columns
  fieldsInAction.forEach((field) => {
    const column = isLoadedColumnViewField(field) ? field.column : undefined;

    if (nonEditableFieldIds.has(field.id)) {
      return;
    }

    if (column == null || !isLiveColumn(column)) {
      const isNonNullableColumn = isColumnViewField(field) && field.column.nonNullable;
      const fieldValue = fieldValues[field.id];

      if (isNonNullableColumn && isFieldValueNullableLike(fieldValue) && !missingRequiredFieldIds.includes(field.id)) {
        validationErrors.push({
          error: "Column is required",
          viewFieldId: field.id,
        });
      }
    }
  });

  // check column types
  fieldsInAction.forEach((field) => {
    const col = isLoadedColumnViewField(field) ? field.column : undefined;

    if (col == null) {
      return;
    }

    const columnValue = fieldValues[computeColumnViewFieldId(col.id)];

    if (columnValue != null && columnValue.type !== "null") {
      const validator = getValidatorByType(col.columnType, entityId);
      const parsed = validator.safeParse(columnValue.value);

      if (!parsed.success) {
        parsed.error.issues.forEach((issue) => {
          validationErrors.push({
            error: issue.message,
            viewFieldId: computeColumnViewFieldId(col.id),
          });
        });
      }
    }
  });

  // check relations
  const relationFieldsInAction: ILoadedRelationViewField[] = fieldsInAction.filter(isLoadedRelationViewField);

  relationFieldsInAction.forEach((field) => {
    const fieldValue = fieldValues[field.id] ?? { type: "null" };

    if (!isRelationFieldValue(fieldValue) && fieldValue.type !== "null") {
      validationErrors.push({ error: "Must be of type entity or empty", viewFieldId: field.id });
    }

    const { relation } = field;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
    if (relation != null) {
      const expectedCardinality =
        field.direction === "aToB" ? relation.config.cardinalityOnSideB : relation.config.cardinalityOnSideA;

      if (expectedCardinality === "one" && isRelationFieldValue(fieldValue) && fieldValue.value.length > 1) {
        validationErrors.push({ error: "Can only assign one related entity", viewFieldId: field.id });
      }
    }
  });

  // run validations
  // todo: run action validations
  validationGroups.forEach((validationGroup) => {
    const fieldId = isColumnValidationIndex(validationGroup.index)
      ? computeColumnViewFieldId(validationGroup.index.id)
      : undefined;

    if (fieldId == null || !fieldIdsInAction.has(fieldId)) {
      return;
    }

    validationGroup.validations.forEach((validation) => {
      validation.checks.forEach((check) => {
        const valid = compare(check, { fields: fieldValues });

        if (isColumnValidationIndex(validationGroup.index) && valid === false) {
          validationErrors.push({
            error: validation.displayMetadata?.errorMessage ?? "unknown error",
            viewFieldId: computeColumnViewFieldId(validationGroup.index.id),
          });
        }
      });
    });
  });

  return validationErrors;
}

function providedUneditableFieldValues({
  action,
  providedFieldValues,
}: {
  action: IActionCore;
  providedFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
}): IViewFieldId[] {
  const editableActionInputsFieldIds = new Set(
    action.actionDefinition.inputs.filter((input) => input.allowChangingDefault).map((input) => input.viewField.id),
  );

  const uneditableProvidedFieldValues: IViewFieldId[] = [];

  forEach(providedFieldValues, (value, fieldId) => {
    if (value != null && !editableActionInputsFieldIds.has(fieldId)) {
      uneditableProvidedFieldValues.push(fieldId);
    }
  });

  return uneditableProvidedFieldValues;
}

interface IParseFieldValuesResult {
  /**
   * includes pre-existing fields from the entity, inputs and uneditable defaults
   */
  newEntityFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
  /**
   * includes fields that are edited/inputs and fields that are not input but are uneditable defaults of the action
   */
  editedFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
}

type ISingleActionValidationResult =
  | { success: true; errors: IValidationError[] }
  | Omit<IExecuteActionError, "nestedActionErrors">;

export type IFullActionValidationResult = {
  success: boolean;
  parsedExternalUserFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>> | undefined;
} & Omit<IExecuteActionError, "success">;

function parseFieldValues({
  loadedAction,
  fieldValues,
  preexistingFieldValues,
  currentUserInfo,
}: {
  loadedAction: ILoadedAction;
  fieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
  /**
   * undefined for a create action
   */
  preexistingFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>> | undefined;
  /**
   * Should only be optional when action executed by AI agent
   */
  currentUserInfo: IActionCurrentUserInfo | undefined;
}): IParseFieldValuesResult {
  const inputsByFieldId = keyByNoUndefined(loadedAction.actionDefinition.inputs, (i) => i.viewField.id);

  const filteredFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>> = {};

  forEach(fieldValues, (fieldValue, fieldId) => {
    const input = inputsByFieldId[fieldId];

    if (input == null) {
      return;
    }

    if (isRelationViewField(input.viewField)) {
      filteredFieldValues[fieldId] = fieldValue;

      return;
    }

    const column = input.viewField.column;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
    if (column != null && !isLiveColumn(column)) {
      filteredFieldValues[fieldId] = fieldValue;
    }
  });

  const parsedFieldValues: Record<IViewFieldId, IViewFieldValue> = {};

  // parse given values
  loadedAction.actionDefinition.inputs.forEach((input) => {
    const fieldValue = filteredFieldValues[input.viewField.id];
    const loadedViewField = input.viewField;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
    if (loadedViewField == null) {
      throw new Error(`Loaded view field ${input.viewField.id} failed`);
    }
    const columnType = isColumnViewField(loadedViewField) ? loadedViewField.column.columnType : undefined;
    const defaultValueElement =
      loadedViewField.autofill?.type === "snapshot" && isFixedValueConfig(loadedViewField.autofill.config)
        ? convertFixedValueAutofillToInputDefaultValue(loadedViewField.autofill.config)
        : input.defaultValue;
    const defaultValue =
      defaultValueElement != null ? resolveDefaultValue(defaultValueElement, currentUserInfo, columnType) : undefined;

    if (!input.allowChangingDefault) {
      if (defaultValue != null) {
        // if default should win
        parsedFieldValues[input.viewField.id] = defaultValue;
      }

      // Not changing allowed to write anything but default so returning anyway
      return;
    }

    if (defaultValue != null) {
      if (isFieldValueNullableLike(fieldValue)) {
        // if there is a default, we set it if the value is nullish
        // This explicitly does not allow to "remove" a default and set the column to null, but we can ensure differentiation
        // between null and { type: "null" } later if needed (might require changes in how values provided by form in FE)
        parsedFieldValues[input.viewField.id] = defaultValue;

        return;
      }
    }

    if (fieldValue != null) {
      // Only write the value if present, i.e. we do not default to { type: "null" } for absent or `null`
      parsedFieldValues[input.viewField.id] = fieldValue;

      return;
    }
  });

  // unify with existing values
  const entityFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>> = {
    ...preexistingFieldValues,
    ...parsedFieldValues,
  };

  return {
    newEntityFieldValues: entityFieldValues,
    editedFieldValues: parsedFieldValues,
  };
}

export const parseFieldValuesAndValidateColumns = ({
  currentUserInfo,
  loadedAction,
  fieldValues,
  loadedNestedCreateFormValues,
  maybeExternalUserCreateProps,
  maybeExternalUserFieldValues,
  preexistingEntity,
  validationGroups,
}: {
  currentUserInfo: IActionCurrentUserInfo | undefined;
  loadedAction: ILoadedAction;
  fieldValues: Partial<IMinimalEntityWithFields["fields"]>;
  loadedNestedCreateFormValues: ILoadedNestedCreateFormValues;
  preexistingEntity: IMinimalEntityWithFields | undefined;
  maybeExternalUserCreateProps: IExternalUserCreateProps | undefined;
  maybeExternalUserFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>> | undefined;
  validationGroups: IValidationGroup[];
}): IFullActionValidationResult &
  IParseFieldValuesResult & {
    parsedNestedCreateFormValues: ILoadedNestedCreateFormValues;
  } => {
  const parsedNestedCreateFormValues: ILoadedNestedCreateFormValues = {};
  const nestedActionErrors: IExecuteActionError["nestedActionErrors"] = {};

  forEach(loadedNestedCreateFormValues, (nestedCreateFormForEntityType, nestedEntityTypeId) => {
    parsedNestedCreateFormValues[nestedEntityTypeId] = {};
    nestedActionErrors[nestedEntityTypeId] = {};

    forEach(nestedCreateFormForEntityType, (nestedCreateForm, nestedEntityId) => {
      if (nestedCreateForm == null) {
        return undefined;
      }

      const nestedParsedFieldValues = parseFieldValuesAndValidateColumnsSingleAction({
        loadedAction: nestedCreateForm.createAction,
        fieldValues: nestedCreateForm.fieldValues,
        preexistingEntity: undefined,
        validationGroups: nestedCreateForm.validationGroups,
        currentUserInfo,
      });

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- initialized above
      parsedNestedCreateFormValues[nestedEntityTypeId]![nestedEntityId] = {
        ...nestedCreateForm,
        fieldValues: nestedParsedFieldValues.newEntityFieldValues,
      };
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- initialized above
      nestedActionErrors[nestedEntityTypeId]![nestedEntityId] = nestedParsedFieldValues.errors;
    });
  });

  const hasNestedError = hasNestedActionError(nestedActionErrors);

  const parsedFieldValuesResult = parseFieldValuesAndValidateColumnsSingleAction({
    currentUserInfo,
    loadedAction,
    fieldValues,
    preexistingEntity,
    validationGroups,
  });

  const parsedExternalUserFieldValuesResult =
    maybeExternalUserCreateProps == null || maybeExternalUserFieldValues == null
      ? undefined
      : parseFieldValuesAndValidateColumnsSingleAction({
          currentUserInfo,
          loadedAction: maybeExternalUserCreateProps.externalUserUpsertLoadedAction,
          fieldValues: maybeExternalUserFieldValues,
          preexistingEntity: maybeExternalUserCreateProps.existingUserEntity,
          validationGroups,
        });

  const combinedErrors = parsedFieldValuesResult.errors.concat(parsedExternalUserFieldValuesResult?.errors ?? []);
  const combinedSuccess = parsedFieldValuesResult.success && (parsedExternalUserFieldValuesResult?.success ?? true);

  if (!hasNestedError && combinedSuccess) {
    return {
      // ...parsedFieldValuesResult,
      success: combinedSuccess,
      errors: combinedErrors,
      nestedActionErrors: {},
      successReplacementNestedEntityIds: {}, // no executed nested actions at this point
      newEntityFieldValues: parsedFieldValuesResult.newEntityFieldValues,
      editedFieldValues: parsedFieldValuesResult.editedFieldValues,
      parsedNestedCreateFormValues,
      parsedExternalUserFieldValues: parsedExternalUserFieldValuesResult?.newEntityFieldValues,
    };
  }

  return {
    success: false,
    errors: parsedFieldValuesResult.errors,
    newEntityFieldValues: parsedFieldValuesResult.newEntityFieldValues,
    editedFieldValues: parsedFieldValuesResult.editedFieldValues,
    parsedNestedCreateFormValues,
    parsedExternalUserFieldValues: parsedExternalUserFieldValuesResult?.newEntityFieldValues,
    nestedActionErrors,
    successReplacementNestedEntityIds: {}, // no executed nested actions at this point
  };
};

const parseFieldValuesAndValidateColumnsSingleAction = ({
  currentUserInfo,
  loadedAction,
  fieldValues,
  preexistingEntity,
  validationGroups,
}: {
  currentUserInfo: IActionCurrentUserInfo | undefined;
  loadedAction: ILoadedAction;
  fieldValues: Partial<IMinimalEntityWithFields["fields"]>;
  preexistingEntity: IMinimalEntityWithFields | undefined;
  validationGroups: IValidationGroup[];
}): ISingleActionValidationResult & IParseFieldValuesResult => {
  const uneditableProvidedFieldValues = providedUneditableFieldValues({
    action: loadedAction,
    providedFieldValues: fieldValues,
  });

  if (uneditableProvidedFieldValues.length > 0) {
    const uneditableProvidedFieldValuesSet = new Set(uneditableProvidedFieldValues);

    const filteredFieldValues = pickBy(fieldValues, (_, fieldId) => !uneditableProvidedFieldValuesSet.has(fieldId));

    return {
      success: false,
      errors: uneditableProvidedFieldValues.map((fieldId) => ({
        error: "Field is not editable",
        viewFieldId: fieldId,
      })),
      newEntityFieldValues: {
        ...preexistingEntity?.fields,
        ...filteredFieldValues,
      },
      editedFieldValues: filteredFieldValues,
      successReplacementNestedEntityIds: {}, // no executed nested actions at this point
    };
  }

  const { newEntityFieldValues, editedFieldValues } = parseFieldValues({
    loadedAction,
    fieldValues,
    preexistingFieldValues: preexistingEntity?.fields,
    currentUserInfo,
  });

  const columnValidationErrors = validateFieldValues({
    loadedAction,
    validationGroups,
    fieldValues: newEntityFieldValues,
    entityId: preexistingEntity?.entityId,
  });

  if (columnValidationErrors.length === 0) {
    return {
      success: true,
      errors: [],
      newEntityFieldValues,
      editedFieldValues,
    };
  }

  return {
    success: false,
    errors: columnValidationErrors,
    newEntityFieldValues,
    editedFieldValues,
    successReplacementNestedEntityIds: {}, // no executed nested actions at this point
  };
};

export const __test = {
  parseFieldValuesAndValidateColumns,
  parseFieldValuesAndValidateColumnsSingleAction,
  validateFieldValues,
  providedUneditableFieldValues,
};
