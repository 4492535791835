import { cn } from "@archetype/ui";
import React from "react";

interface IAiAgentUserName {
  className?: string;
}

export const AiAgentUserName: React.FC<IAiAgentUserName> = ({ className }) => {
  return (
    <span className={cn("font-semibold", className)}>
      <span className="text-magic text-magic-animated">AI Agent</span>
    </span>
  );
};
