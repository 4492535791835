import { keyByNoUndefined } from "@archetype/utils";
import { values } from "lodash";

import type { IFullCompositeTree } from "../../../utils/dataModel/resolveCompositesTree";
import type { IFullColumnTypeTree } from "../../../utils/dataModel/resolveTypeTree";
import { EmailTree } from "./Email";
import { BooleanTree, DateTree, GeolocationTree, NumberTree, StringTree, TimestampTree } from "./primitives";

export { EmailTree } from "./Email";
export { BooleanTree, DateTree, GeolocationTree, NumberTree, StringTree, TimestampTree } from "./primitives";

const allBuiltinColumnTypes: Record<string, IFullColumnTypeTree> = {
  EmailTree,
  BooleanTree,
  DateTree,
  GeolocationTree,
  NumberTree,
  StringTree,
  TimestampTree,
};

const allBuiltingCompositeTypes: Record<string, IFullCompositeTree> = {};

export const ALL_BUILTIN_COLUMN_TYPE_TREES = values(allBuiltinColumnTypes);
export const ALL_BUILTIN_COMPOSITE_TYPE_TREES = values(allBuiltingCompositeTypes);

export const ALL_BUILTIN_COLUMN_TYPE_TREES_BY_ID = keyByNoUndefined(
  ALL_BUILTIN_COLUMN_TYPE_TREES,
  (t) => t.columnType.id,
);

export const ALL_BUILTIN_COMPOSITE_TYPE_TREES_BY_ID = keyByNoUndefined(
  ALL_BUILTIN_COMPOSITE_TYPE_TREES,
  (t) => t.composite.id,
);
