import { Checkbox } from "@archetype/ui";
import React, { useCallback } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableSelectionContext } from "../../context/DataTableSelectionContext";

export function CheckboxHeader(): React.ReactNode {
  const isAllRowsSelected = useContextSelector(DataTableSelectionContext, (state) => state.isAllRowsSelected);
  const isSomeRowsSelected = useContextSelector(DataTableSelectionContext, (state) => state.isSomeRowsSelected);
  const selectAllRows = useContextSelector(DataTableSelectionContext, (state) => state.selectAllRows);
  const clearRowSelection = useContextSelector(DataTableSelectionContext, (state) => state.clearRowSelection);

  const handleCheckboxChange = useCallback(() => {
    if (isAllRowsSelected) {
      clearRowSelection();
    } else {
      selectAllRows();
    }
  }, [isAllRowsSelected, selectAllRows, clearRowSelection]);

  return (
    <div className="flex size-full items-center justify-center">
      <Checkbox
        aria-label="Select all rows"
        checked={isAllRowsSelected || (isSomeRowsSelected && "indeterminate")}
        onCheckedChange={handleCheckboxChange}
      />
    </div>
  );
}
