import {
  ApplicationId,
  LayoutConfigurationId,
  LayoutId,
  ModuleInstanceId,
  ModuleInstanceMetadataId,
  ModuleInstanceMetadataVersionId,
  ModuleInstanceVersionId,
  SemanticInputsRecord,
  StateMachineMetadataId,
  StateMachineMetadataVersionId,
} from "@archetype/ids";
import { z } from "zod";

import { VersionIdentifiable } from "./common/VersionIdentifiable";
import { LayoutFunctionalConfigValue } from "./config/ConfigTypes";
import { ModuleParameterConfig } from "./config/RuntimeSemanticParameters";
import { FeatureTransaction } from "./FeatureTransaction";
import { LayoutSlotInstanceMap } from "./LayoutSlotInstanceMap";

export const ModuleInstanceMetadata = VersionIdentifiable(
  ModuleInstanceMetadataId,
  ModuleInstanceMetadataVersionId,
  StateMachineMetadataId,
  StateMachineMetadataVersionId,
).merge(
  z.object({
    /**
     * The application the module instance is part of
     */
    applicationId: ApplicationId,

    name: z.string(),

    documentation: z.object({
      type: z.literal("documentationV1"),
      strDocumentation: z.string(),
    }),

    featureTransaction: FeatureTransaction,
  }),
);

export const ModuleInstance = VersionIdentifiable(
  ModuleInstanceId,
  ModuleInstanceVersionId,
  ModuleInstanceMetadataId,
  ModuleInstanceMetadataVersionId,
).merge(
  z.object({
    // Reference to the layout
    layoutId: LayoutId,

    // vararg slots for components, i.e. a slot that can have 2 to 5 sub slots (e.g. a certain number of kpis)
    slots: LayoutSlotInstanceMap,

    layoutConfiguration: z.record(LayoutConfigurationId, LayoutFunctionalConfigValue.optional()),

    inputRuntimeParameterConfig: SemanticInputsRecord(
      ModuleParameterConfig.omit({ inputParameterId: true, inputModuleInstance: true }),
      ModuleParameterConfig.omit({ inputParameterId: true, inputModuleInstance: true }),
      ModuleParameterConfig.omit({ inputParameterId: true, inputModuleInstance: true }),
    ).partial(),
  }),
);

export type IModuleInstanceMetadata = z.infer<typeof ModuleInstanceMetadata>;
export type IModuleInstance = z.infer<typeof ModuleInstance>;
