import { EntityTypeId, OrganizationId, RelationId, RelationVersionId, StateId } from "@archetype/ids";
import { z } from "zod";

import { DisplayMetadata } from "../common/DisplayMetadata";
import { VersionIdentifiable } from "../common/VersionIdentifiable";
import type { IAutofill, ILiveAutofill, ISnapshotAutofill } from "./Autofill";
import { Autofill, isLiveAutofill } from "./Autofill";
import type { IAIConfig, ILogicConfig, ILookupConfig } from "./AutofillConfig";
import { isAIConfig, isLogicConfig, isLookupConfig } from "./AutofillConfig";
import { DataLoadingQueryFilters } from "./DataLoadingQuery";
import { JoinCardinality, RelationDirection } from "./RelationBase";

export const RelationSideValidation = z.object({
  enabled: z.boolean(),
  /**
   * If true, those validations are only application in an action that sets that relation.
   * For example, you can only create a Quote (with a relation to Inquiry) from a `Pending` Inquiry,
   * but the Inquiry can later be moved to `Quoted` or `Archived` state and the relation can exist correctly.
   *
   * Conversely an example where false, Issues must be linked to an `Active` Employee, but if the employee is later set to `Inactive`,
   * we should see a warning that the relation is in a bad state.
   */
  // applicableOnCreateLinkOnly: z.boolean().optional(),
  /**
   * The state ids that the entity of entityTypeIdA must be in
   * if applyOnCreateLinkOnly is true, then it's only when executing an action, otherwise it should be true always.
   * If there is no restriction, then the relation is applicable in all states.
   */
  stateIds: z.array(StateId).optional(),
  /**
   * Filters that the entity of entityTypeIdA must abide to be able to link to entity of entityTypeIdB.
   * Can be used to filter selectable entities of type A from a RelationInput in a form of entity type B.
   */
  filters: DataLoadingQueryFilters.optional(),
  // We could extend this definition to define a filter of what conditions on B is that validation applicable to
  // to be able to do things like "Open issues must be linked to Active employees", but that's not necessary for now
});
export type IRelationSideValidation = z.infer<typeof RelationSideValidation>;

export const RelationConfiguration = z.object({
  // for our internal relations we use separate relation table to store the relation since it simplifies things,
  // when we'll have user data as well we'll need to add table and relation columns attributes that will point to the table that acts as a relation table.
  // for example, a table with fk to another table will be the relation table with the id and the fk as relation columns
  cardinalityOnSideA: JoinCardinality,
  cardinalityOnSideB: JoinCardinality,
  validationsOnA: RelationSideValidation.optional(),
  validationsOnB: RelationSideValidation.optional(),
});
export type IRelationConfiguration = z.infer<typeof RelationConfiguration>;

export const RelationAutofillConfig = z.object({
  autofill: Autofill,
  direction: RelationDirection,
});
export type IRelationAutofillConfig = z.infer<typeof RelationAutofillConfig>;

export const RelationCore = z.object({
  id: RelationId,
  displayMetadataFromAToB: DisplayMetadata,
  displayMetadataFromBToA: DisplayMetadata,
  entityTypeIdA: EntityTypeId,
  entityTypeIdB: EntityTypeId,
  config: RelationConfiguration,
  autofill: RelationAutofillConfig.optional(),
});
export type IRelationCore = z.infer<typeof RelationCore>;

export const Relation = VersionIdentifiable(RelationId, RelationVersionId, z.null(), z.null())
  .merge(RelationCore)
  .merge(
    z.object({
      organizationId: OrganizationId,
    }),
  );

export type IRelation = z.infer<typeof Relation>;

export const RelationRecord = z.object({
  entityRelationId: RelationId,
  valueOnA: z.string(),
  valueOnB: z.string(),
});
export type IRelationRecord = z.infer<typeof RelationRecord>;

// helper methods and types

/** we don't support logic autofill on relations yet */
export const isSupportedRelationAutofill = (autofill: IAutofill): boolean => !isLogicConfig(autofill.config);

export type ILiveRelation = Omit<IRelation, "autofill"> & {
  autofill: Omit<IRelationAutofillConfig, "autofill"> & { autofill: ILiveAutofill };
};
export const isLiveRelation = (relation: IRelationCore): relation is ILiveRelation =>
  relation.autofill != null && isLiveAutofill(relation.autofill.autofill);

type ILiveAIRelation = Omit<IRelation, "autofill"> & {
  autofill: Omit<IRelationAutofillConfig, "autofill"> & { autofill: ILiveAutofill & { config: IAIConfig } };
};
type ISnapshotAIRelation = Omit<IRelation, "autofill"> & {
  autofill: Omit<IRelationAutofillConfig, "autofill"> & { autofill: ISnapshotAutofill & { config: IAIConfig } };
};

export type IAIRelation = ILiveAIRelation | ISnapshotAIRelation;
export const isAIRelation = (relation: IRelationCore): relation is IAIRelation =>
  relation.autofill != null && isAIConfig(relation.autofill.autofill.config);

type ILiveLogicRelation = Omit<IRelation, "autofill"> & {
  autofill: Omit<IRelationAutofillConfig, "autofill"> & { autofill: ILiveAutofill & { config: ILogicConfig } };
};
type ISnapshotLogicRelation = Omit<IRelation, "autofill"> & {
  autofill: Omit<IRelationAutofillConfig, "autofill"> & { autofill: ISnapshotAutofill & { config: ILogicConfig } };
};

export type ILogicRelation = ILiveLogicRelation | ISnapshotLogicRelation;
export const isLogicRelation = (relation: IRelationCore): relation is ILogicRelation =>
  relation.autofill != null && isLogicConfig(relation.autofill.autofill.config);

type ILiveLookupRelation = Omit<IRelation, "autofill"> & {
  autofill: Omit<IRelationAutofillConfig, "autofill"> & { autofill: ILiveAutofill & { config: ILookupConfig } };
};
type ISnapshotLookupRelation = Omit<IRelation, "autofill"> & {
  autofill: Omit<IRelationAutofillConfig, "autofill"> & { autofill: ISnapshotAutofill & { config: ILookupConfig } };
};

export type ILookupRelation = ILiveLookupRelation | ISnapshotLookupRelation;
export const isLookupRelation = (relation: IRelationCore): relation is ILookupRelation =>
  relation.autofill != null && isLookupConfig(relation.autofill.autofill.config);

export type IDerivedRelation = ILogicRelation | ILookupRelation;
export const isDerivedRelation = (relation: IRelationCore): relation is IDerivedRelation =>
  isLogicRelation(relation) || isLookupRelation(relation);

export type IComputedRelation = IAIRelation | IDerivedRelation;
export const isComputedRelation = (relation: IRelationCore): relation is IComputedRelation =>
  isAIRelation(relation) || isDerivedRelation(relation);
