import { EntityTypeId, StateId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { StateColor } from "../stateMachine/stateColors";

export const StateChangeBase = z.object({
  entityTypeId: EntityTypeId,
  stateId: StateId,
});
export type IStateChangeBase = z.infer<typeof StateChangeBase>;

export const StateNameChange = StateChangeBase.extend({
  changeStateNameTo: ReadableString,
});
export type IStateNameChange = z.infer<typeof StateNameChange>;

export const StateColorChange = StateChangeBase.extend({
  changeStateColorTo: StateColor,
});
export type IStateColorChange = z.infer<typeof StateColorChange>;

export const StateAddViewFieldChange = StateChangeBase.extend({
  viewFieldToAdd: ReadableString,
});
export type IStateAddViewFieldChange = z.infer<typeof StateAddViewFieldChange>;

export const StateRemoveViewFieldChange = StateChangeBase.extend({
  viewFieldToRemove: ReadableString,
});
export type IStateRemoveViewFieldChange = z.infer<typeof StateRemoveViewFieldChange>;

export const StateEnableOrEditAIAgentChange = StateChangeBase.extend({
  aiAgentInstructionsToAddOrEdit: z.string(),
});
export type IStateEnableOrEditAIAgentChange = z.infer<typeof StateEnableOrEditAIAgentChange>;

export const StateDisableAIAgentChange = StateChangeBase.extend({
  disableAIAgent: z.enum(["true"]),
});
export type IStateDisableAIAgentChange = z.infer<typeof StateDisableAIAgentChange>;

export const StateChange = z.union([
  StateNameChange,
  StateColorChange,
  StateAddViewFieldChange,
  StateRemoveViewFieldChange,
  StateEnableOrEditAIAgentChange,
  StateDisableAIAgentChange,
]);
export type IStateChange = z.infer<typeof StateChange>;

export function isStateNameChange(change: IStateChange): change is IStateNameChange {
  return "changeStateNameTo" in change;
}

export function isStateColorChange(change: IStateChange): change is IStateColorChange {
  return "changeStateColorTo" in change;
}

export function isStateAddViewFieldChange(change: IStateChange): change is IStateAddViewFieldChange {
  return "viewFieldToAdd" in change;
}

export function isStateRemoveViewFieldChange(change: IStateChange): change is IStateRemoveViewFieldChange {
  return "viewFieldToRemove" in change;
}

export function isStateEnableOrEditAIAgentChange(change: IStateChange): change is IStateEnableOrEditAIAgentChange {
  return "aiAgentInstructionsToAddOrEdit" in change;
}

export function isStateDisableAIAgentChange(change: IStateChange): change is IStateDisableAIAgentChange {
  return "disableAIAgent" in change;
}

export function isStateChange(change: object): change is IStateChange {
  return "entityTypeName" in change && "stateName" in change;
}
