import { forEach, mapValues } from "@archetype/utils";
import { match } from "ts-pattern";

import type { ICrossColumnAndConditions } from "../../schemas/dataModel/dataLoading/CrossColumnConditions";
import type {
  IDataLoadingQueryColumnAndFilters,
  IDataLoadingQueryColumnOrFilters,
} from "../../schemas/dataModel/dataLoading/PerColumnConditions";
import type { IDataLoadingQueryFilters } from "../../schemas/dataModel/DataLoadingQuery";
import type { IAndFilterOperatorValue, IOrFilterOperatorValue } from "./filterVisitors";

export const mergeDataLoadingQueryFilters = (
  baseDataLoadingFilters: IDataLoadingQueryFilters | undefined,
  toMergeDataLoadingFilters: IDataLoadingQueryFilters | undefined,
): IDataLoadingQueryFilters | undefined => {
  if (baseDataLoadingFilters == null) {
    return toMergeDataLoadingFilters;
  }

  if (toMergeDataLoadingFilters == null) {
    return baseDataLoadingFilters;
  }

  return mergeCrossColumnAndConditions(baseDataLoadingFilters, toMergeDataLoadingFilters);
};

const mergeColumnRawAndFilters = (
  baseAndFilters: IDataLoadingQueryColumnAndFilters,
  toMergeAndFilters: IDataLoadingQueryColumnAndFilters,
): IDataLoadingQueryColumnAndFilters => {
  const newAndFilters = { ...baseAndFilters };

  const operatorsAndValues: Array<IAndFilterOperatorValue> = [];

  forEach(
    toMergeAndFilters,
    (filterValue, operator) =>
      filterValue != null &&
      operatorsAndValues.push({
        operator,
        filterValue,
      } as IAndFilterOperatorValue),
  );

  operatorsAndValues.forEach((opAndValue) => {
    match(opAndValue)
      .with({ operator: "eq" }, ({ filterValue }) => (newAndFilters.eq = filterValue))
      .with({ operator: "eqIgnoreCase" }, ({ filterValue }) => (newAndFilters.eqIgnoreCase = filterValue))
      .with(
        { operator: "neq" },
        ({ filterValue }) => (newAndFilters.neq = (newAndFilters.neq ?? []).concat(filterValue)),
      )
      .with(
        { operator: "neqIgnoreCase" },
        ({ filterValue }) => (newAndFilters.neqIgnoreCase = (newAndFilters.neqIgnoreCase ?? []).concat(filterValue)),
      )
      .with({ operator: "gt" }, ({ filterValue }) => (newAndFilters.gt = filterValue))
      .with({ operator: "gte" }, ({ filterValue }) => (newAndFilters.gte = filterValue))
      .with({ operator: "lt" }, ({ filterValue }) => (newAndFilters.lt = filterValue))
      .with({ operator: "lte" }, ({ filterValue }) => (newAndFilters.lte = filterValue))
      .with({ operator: "regex" }, ({ filterValue }) => (newAndFilters.regex = filterValue))
      .with({ operator: "anyInArray" }, ({ filterValue }) => (newAndFilters.anyInArray = filterValue))
      .with({ operator: "noneInArray" }, ({ filterValue }) => (newAndFilters.noneInArray = filterValue))
      .with({ operator: "allInArray" }, ({ filterValue }) => (newAndFilters.allInArray = filterValue))
      .with({ operator: "allNotInArray" }, ({ filterValue }) => (newAndFilters.allNotInArray = filterValue))
      .exhaustive();
  });

  return newAndFilters;
};

const mergeColumnRawOrFilters = (
  baseOrFilters: IDataLoadingQueryColumnOrFilters,
  toMergeOrFilters: IDataLoadingQueryColumnOrFilters,
): IDataLoadingQueryColumnOrFilters => {
  const newOrFilters = { ...baseOrFilters };

  const operatorsAndValues: Array<IOrFilterOperatorValue> = [];

  forEach(
    toMergeOrFilters,
    (filterValue, operator) =>
      filterValue != null &&
      operatorsAndValues.push({
        operator,
        filterValue,
      } as IOrFilterOperatorValue),
  );

  operatorsAndValues.forEach((opAndValue) => {
    match(opAndValue)
      .with({ operator: "eq" }, ({ filterValue }) => (newOrFilters.eq = (newOrFilters.eq ?? []).concat(filterValue)))
      .with(
        { operator: "eqIgnoreCase" },
        ({ filterValue }) => (newOrFilters.eqIgnoreCase = (newOrFilters.eqIgnoreCase ?? []).concat(filterValue)),
      )
      .with({ operator: "neq" }, ({ filterValue }) => (newOrFilters.neq = filterValue))
      .with({ operator: "neqIgnoreCase" }, ({ filterValue }) => (newOrFilters.neqIgnoreCase = filterValue))
      .with({ operator: "gt" }, ({ filterValue }) => (newOrFilters.gt = filterValue))
      .with({ operator: "gte" }, ({ filterValue }) => (newOrFilters.gte = filterValue))
      .with({ operator: "lt" }, ({ filterValue }) => (newOrFilters.lt = filterValue))
      .with({ operator: "lte" }, ({ filterValue }) => (newOrFilters.lte = filterValue))
      .with({ operator: "regex" }, ({ filterValue }) => (newOrFilters.regex = filterValue))
      .with({ operator: "anyInArray" }, ({ filterValue }) => (newOrFilters.anyInArray = filterValue))
      .with({ operator: "noneInArray" }, ({ filterValue }) => (newOrFilters.noneInArray = filterValue))
      .with({ operator: "allInArray" }, ({ filterValue }) => (newOrFilters.allInArray = filterValue))
      .with({ operator: "allNotInArray" }, ({ filterValue }) => (newOrFilters.allNotInArray = filterValue))
      .exhaustive();
  });

  return newOrFilters;
};

const mergePerColumnAndedFilters = (
  basePerColumnAndedFilters: ICrossColumnAndConditions["perColumn"],
  toMergePerColumnAndedFilters: ICrossColumnAndConditions["perColumn"],
): ICrossColumnAndConditions["perColumn"] => {
  const newFilters: ICrossColumnAndConditions["perColumn"] = mapValues(
    // First adding all the keys from toMerge absent from base
    { ...toMergePerColumnAndedFilters, ...basePerColumnAndedFilters },
    // Then overlaying the value from toMerge when key is present in both
    (columnFilterConditions, columnId) => {
      const toMergeColumnCondition = toMergePerColumnAndedFilters[columnId];

      if (columnFilterConditions == null) {
        return toMergeColumnCondition;
      }

      if (toMergeColumnCondition == null) {
        return columnFilterConditions;
      }

      if (columnFilterConditions.type === "and") {
        if (toMergeColumnCondition.type === "and") {
          return {
            type: "and" as const,
            rawAndOperations: mergeColumnRawAndFilters(
              columnFilterConditions.rawAndOperations,
              toMergeColumnCondition.rawAndOperations,
            ),
            andedOrConditions: columnFilterConditions.andedOrConditions.concat(
              toMergeColumnCondition.andedOrConditions,
            ),
          };
        }

        return {
          // type "and"
          ...columnFilterConditions,
          andedOrConditions: columnFilterConditions.andedOrConditions.concat([toMergeColumnCondition]),
        };
      }
      if (toMergeColumnCondition.type === "or") {
        return {
          type: "or" as const,
          rawOrConditions: mergeColumnRawOrFilters(
            columnFilterConditions.rawOrConditions,
            toMergeColumnCondition.rawOrConditions,
          ),
          // TBC: this might be better with adding the toMergedConditions to all ored conditions?
          oredAndConditions: columnFilterConditions.oredAndConditions.concat(toMergeColumnCondition.oredAndConditions),
        };
      }

      return {
        // type "and"
        ...toMergeColumnCondition,
        andedOrConditions: toMergeColumnCondition.andedOrConditions.concat([columnFilterConditions]),
      };
    },
  );

  return newFilters;
};

// Merging cross column filters at the top level is pretty uninteresting because we can't identify what can be merged
// That's where per column filters become interesting
const mergeCrossColumnAndConditions = (
  baseCrossColumnAndConditions: ICrossColumnAndConditions,
  toMergeCrossColumnAndConditions: ICrossColumnAndConditions,
): ICrossColumnAndConditions => {
  return {
    type: "and",
    perColumn: mergePerColumnAndedFilters(
      baseCrossColumnAndConditions.perColumn,
      toMergeCrossColumnAndConditions.perColumn,
    ),
    andedCrossColumnOrConditions: baseCrossColumnAndConditions.andedCrossColumnOrConditions.concat(
      toMergeCrossColumnAndConditions.andedCrossColumnOrConditions,
    ),
    andedRelatedToFilters: (baseCrossColumnAndConditions.andedRelatedToFilters ?? []).concat(
      toMergeCrossColumnAndConditions.andedRelatedToFilters ?? [],
    ),
  };
};
