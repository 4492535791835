import type { ILoadedViewField } from "@archetype/core";
import type { IColumn, IColumnType, IEntityTypeCore } from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";
import type { IIconNames, IIconOrShape } from "@archetype/ui";
import type { IReadableString } from "@archetype/utils";
import { ReadableString } from "@archetype/utils";

export const getDisplayNameForViewField = (viewField: ILoadedViewField): IReadableString => {
  switch (viewField.type) {
    case "column": {
      return viewField.displayName;
    }

    case "directionalRelation": {
      return viewField.direction === "aToB"
        ? viewField.relation.displayMetadataFromAToB.name
        : viewField.relation.displayMetadataFromBToA.name;
    }
  }
};

export const getIconForViewField = (
  viewField: ILoadedViewField,
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>>,
): IIconOrShape => {
  switch (viewField.type) {
    case "column": {
      return {
        type: "icon",
        value: getIconForColumn(viewField.column),
      };
    }
    case "directionalRelation": {
      const targetEntityTypeInfo =
        viewField.direction === "aToB"
          ? allEntityTypes[viewField.relation.entityTypeIdB]
          : allEntityTypes[viewField.relation.entityTypeIdA];

      if (targetEntityTypeInfo == null) {
        return {
          type: "icon",
          value: "spline",
        };
      }

      return {
        type: "shape",
        value: targetEntityTypeInfo.shape,
        color: targetEntityTypeInfo.color,
      };
    }
  }
};

export const getIconForColumn = (column: IColumn): IIconNames => getIconForColumnType(column.columnType.type);

export const getIconForColumnType = (type: IColumnType["type"]): IIconNames => {
  switch (type) {
    case "email": {
      return "at-sign";
    }
    case "shortText": {
      return "text-cursor-input";
    }
    case "longText": {
      return "text";
    }
    case "number": {
      return "binary";
    }
    case "boolean": {
      return "toggle-right";
    }
    case "date": {
      return "calendar-days";
    }
    case "timestamp": {
      return "clock";
    }
    case "geolocation": {
      return "map-pin";
    }
    case "enum": {
      return "chevron-down-circle";
    }
    case "statusEnum": {
      return "chevron-down-circle";
    }
    case "phone": {
      return "smartphone";
    }
    case "file": {
      return "paperclip";
    }
    case "url": {
      return "link";
    }
  }
};

export const getReadableStringForType = (type: IColumnType["type"]): IReadableString => {
  switch (type) {
    case "email": {
      return ReadableString.parse("Email");
    }
    case "shortText": {
      return ReadableString.parse("Input");
    }
    case "longText": {
      return ReadableString.parse("Text area");
    }
    case "number": {
      return ReadableString.parse("Number");
    }
    case "boolean": {
      return ReadableString.parse("Toggle");
    }
    case "date": {
      return ReadableString.parse("Date");
    }
    case "timestamp": {
      return ReadableString.parse("Date and time");
    }
    case "geolocation": {
      return ReadableString.parse("Location");
    }
    case "enum": {
      return ReadableString.parse("Select");
    }
    case "statusEnum": {
      return ReadableString.parse("Status");
    }
    case "phone": {
      return ReadableString.parse("Phone");
    }
    case "file": {
      return ReadableString.parse("File");
    }
    case "url": {
      return ReadableString.parse("URL");
    }
  }
};

export const getAutofillIcon = (autofillType: "ai" | "logic" | "dynamic" | "static" | "lookup"): IIconNames => {
  switch (autofillType) {
    case "ai": {
      return "sparkles";
    }
    case "logic": {
      return "sigma";
    }
    case "lookup": {
      return "search";
    }
    case "dynamic": {
      return "wand-2";
    }
    case "static": {
      return "pin";
    }
  }
};

export const getAutofillString = (autofillType: "ai" | "logic" | "dynamic" | "static" | "lookup"): IReadableString => {
  switch (autofillType) {
    case "ai": {
      return ReadableString.parse("AI Assistant");
    }
    case "logic": {
      return ReadableString.parse("Logic");
    }
    case "static": {
      return ReadableString.parse("Fixed Value");
    }
    case "dynamic": {
      return ReadableString.parse("Dynamic Value");
    }
    case "lookup": {
      return ReadableString.parse("Lookup");
    }
  }
};
