import type { IDataLoadingFilterAndOperator } from "@archetype/dsl";
import { builderTrpc } from "@archetype/trpc-react";
import { Calendar, DateTimeInput, Loader, Separator, useToast } from "@archetype/ui";
import { addDays, addMonths, addWeeks, subDays, subMonths, subWeeks } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { match } from "ts-pattern";

import { CommandWithAIOption } from "./CommandWithAIOption";
import { SubmittableInput } from "./SubmittableInput";
import type { ICommandOption } from "./types";

interface IDateInputFilter {
  title: string;
  onSubmit: (operator: IDataLoadingFilterAndOperator, value: string[], closePopover?: boolean) => void;
  operator?: IDataLoadingFilterAndOperator;
  canEditTime?: boolean;
  defaultValue?: string;
}

const aiOptionId = "ai date";
const genericFailureReason = "Could not generate a date from the description";

const topLevelOptions = [
  { id: "now", value: "Today" },
  { id: "tomorrow", value: "Tomorrow" },
  { id: "yesterday", value: "Yesterday" },
  { id: "oneWeekAgo", value: "One week ago" },
  { id: "oneWeekFromNow", value: "One week from now" },
  { id: "oneMonthAgo", value: "One month ago" },
  { id: "oneMonthFromNow", value: "One month from now" },
] as const satisfies readonly ICommandOption[];

type ITopLevelOptionIds = (typeof topLevelOptions)[number]["id"];
type IAllOptionIds = ITopLevelOptionIds | typeof aiOptionId;

export const DateInputFilter: React.FC<IDateInputFilter> = (props) => {
  const { title, defaultValue, onSubmit, operator = "eq", canEditTime = false } = props;

  const { toast } = useToast();
  const { mutateAsync: parseDatetime } = builderTrpc.datetime.parseDatetime.useMutation();
  const aiParsingAbortControllerRef = useRef<AbortController | undefined>();

  const [selectedOptionId, setSelectedOptionId] = useState<string | undefined>();
  const [isParsingAIDateValue, setIsParsingAIDateValue] = useState<string | undefined>();

  const [selectedDate, handleSetSelectedDate] = useState<Date | undefined>(
    defaultValue != null ? new Date(defaultValue) : undefined,
  );

  useEffect(() => {
    return (): void => aiParsingAbortControllerRef.current?.abort();
  }, []);

  const submitDate = useCallback(
    (date: Date | undefined, closePopover?: boolean) => {
      handleSetSelectedDate(date);
      onSubmit(operator, date !== undefined ? [date.toISOString()] : [], closePopover);
    },
    [onSubmit, operator],
  );

  const handleCalendarDateChange = useCallback(
    (date: Date | undefined) => {
      submitDate(date, !canEditTime);
    },
    [submitDate, canEditTime],
  );

  const handleSelectOption = useCallback(
    (optionId: IAllOptionIds) => {
      if (optionId === aiOptionId) {
        setSelectedOptionId(optionId);

        return;
      }

      const date = getDateFromOptionId(optionId);

      if (date != null) submitDate(date, !canEditTime);
    },
    [submitDate, canEditTime],
  );

  const handleSelectAIOption = useCallback(
    (value: string) => {
      aiParsingAbortControllerRef.current = new AbortController();
      setIsParsingAIDateValue(value);

      parseDatetime({ currentTimestamp: new Date().toISOString(), dateTimeDescription: value })
        .then(({ output }) => {
          if (aiParsingAbortControllerRef.current?.signal.aborted === true) return;

          if (output.type === "timestamp") {
            submitDate(new Date(output.timestamp), true);
          } else {
            toast({ title: "Error", variant: "error", description: output.error ?? genericFailureReason });
          }
        })
        .catch(() => {
          if (aiParsingAbortControllerRef.current?.signal.aborted !== true) {
            toast({ title: "Error", variant: "error", description: genericFailureReason });
          }
        })
        .finally(() => {
          if (aiParsingAbortControllerRef.current?.signal.aborted !== true) {
            setIsParsingAIDateValue(undefined);
          }
        });
    },
    [parseDatetime, toast, submitDate],
  );

  if (isParsingAIDateValue != null) {
    return (
      <Loader
        className="gap-x-2 p-3 text-base text-black"
        size="xs"
        text={`Parsing date from "${isParsingAIDateValue}"...`}
        variant="sparkles"
      />
    );
  }

  if (selectedOptionId === undefined) {
    return (
      <div className="flex">
        <CommandWithAIOption
          aiOptionId={aiOptionId}
          aiOptionLabel="AI date"
          inputIcon="calendar"
          inputPlaceholder={title}
          options={topLevelOptions}
          onSelectAIOption={handleSelectAIOption}
          onSelectOption={handleSelectOption}
        />
        <Separator className="h-auto" orientation="vertical" />
        <div>
          {canEditTime ? (
            <DateTimeInput small pickerPresentation="inline" value={selectedDate} onChange={handleCalendarDateChange} />
          ) : (
            <Calendar mode="single" selected={selectedDate} onSelect={handleCalendarDateChange} />
          )}
        </div>
      </div>
    );
  }

  if (selectedOptionId === aiOptionId) {
    return (
      <SubmittableInput
        className="min-w-72"
        placeholder={`Set date of ${title} with AI`}
        onSubmit={handleSelectAIOption}
      />
    );
  }
};

const getDateFromOptionId = (optionId: ITopLevelOptionIds): Date | undefined => {
  return match(optionId)
    .with("now", () => new Date())
    .with("tomorrow", () => addDays(new Date(), 1))
    .with("yesterday", () => subDays(new Date(), 1))
    .with("oneWeekAgo", () => subWeeks(new Date(), 1))
    .with("oneWeekFromNow", () => addWeeks(new Date(), 1))
    .with("oneMonthAgo", () => subMonths(new Date(), 1))
    .with("oneMonthFromNow", () => addMonths(new Date(), 1))
    .exhaustive();
};
