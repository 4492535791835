import { ActionId, EntityTypeId, StateId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { StateColor } from "../stateMachine/stateColors";

export const StateMachineChangeBase = z.object({
  entityTypeId: EntityTypeId,
});
export type IStateMachineChangeBase = z.infer<typeof StateMachineChangeBase>;

export const StateMachineAddActionChange = StateMachineChangeBase.extend({
  actionToAdd: z.object({
    actionId: ActionId.default(ActionId.generate()),
    actionName: ReadableString,
    fromStateId: StateId,
    toStateId: StateId,
  }),
  happyPathStateIds: z.array(StateId),
});
export type IStateMachineAddActionChange = z.infer<typeof StateMachineAddActionChange>;

export const StateMachineRemoveActionChange = StateMachineChangeBase.extend({
  actionIdToRemove: ActionId,
});
export type IStateMachineRemoveActionChange = z.infer<typeof StateMachineRemoveActionChange>;

export const StateMachineAddStateChange = StateMachineChangeBase.extend({
  stateToAdd: z.object({
    stateId: StateId.default(StateId.generate()),
    stateName: ReadableString,
    stateUniqueColor: StateColor,
  }),
  actionToStateToAdd: z.object({
    actionId: ActionId.default(ActionId.generate()),
    actionName: ReadableString,
    fromStateId: StateId,
  }),
  happyPathStateIds: z.array(StateId),
});
export type IStateMachineAddStateChange = z.infer<typeof StateMachineAddStateChange>;

export const StateMachineRemoveStateChange = StateMachineChangeBase.extend({
  stateIdToRemove: StateId,
});
export type IStateMachineRemoveStateChange = z.infer<typeof StateMachineRemoveStateChange>;

export const StateMachineChange = z.union([
  StateMachineAddActionChange,
  StateMachineRemoveActionChange,
  StateMachineRemoveStateChange,
  StateMachineAddStateChange,
]);
export type IStateMachineChange = z.infer<typeof StateMachineChange>;

export function isStateMachineAddActionChange(change: IStateMachineChange): change is IStateMachineAddActionChange {
  return "actionToAdd" in change;
}

export function isStateMachineRemoveActionChange(
  change: IStateMachineChange,
): change is IStateMachineRemoveActionChange {
  return "actionIdToRemove" in change;
}

export function isStateMachineAddStateChange(change: IStateMachineChange): change is IStateMachineAddStateChange {
  return "stateToAdd" in change;
}

export function isStateMachineRemoveStateChange(change: IStateMachineChange): change is IStateMachineRemoveStateChange {
  return "stateIdToRemove" in change;
}
