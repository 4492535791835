import { z } from "zod";

export const PostmarkEmailParticipant = z.object({
  Email: z.string(),
  Name: z.string(),
  MailboxHash: z.string(),
});

export const PostmarkHeader = z.object({
  Name: z.string(),
  Value: z.string(),
});

export const PostmarkAttachment = z.object({
  Name: z.string(),
  Content: z.string(),
  ContentType: z.string(),
  ContentLength: z.number(),
});

export const PostmarkInboundEmail = z.object({
  FromName: z.string(),
  MessageStream: z.string().optional(),
  From: z.string(),
  FromFull: PostmarkEmailParticipant,
  To: z.string(),
  ToFull: z.array(PostmarkEmailParticipant),
  Cc: z.string(),
  CcFull: z.array(PostmarkEmailParticipant),
  Bcc: z.string(),
  BccFull: z.array(PostmarkEmailParticipant),
  OriginalRecipient: z.string(),
  Subject: z.string(),
  MessageID: z.string(),
  ReplyTo: z.string(),
  MailboxHash: z.string().optional(),
  Date: z.string(),
  TextBody: z.string(),
  HtmlBody: z.string(),
  StrippedTextReply: z.string().optional(),
  Tag: z.string().optional(),
  Headers: z.array(PostmarkHeader).optional(),
  Attachments: z.array(PostmarkAttachment).optional(),
});

export type IPostmarkEmailParticipant = z.infer<typeof PostmarkEmailParticipant>;
export type IPostmarkHeader = z.infer<typeof PostmarkHeader>;
export type IPostmarkAttachment = z.infer<typeof PostmarkAttachment>;
export type IPostmarkInboundEmail = z.infer<typeof PostmarkInboundEmail>;
