import { motion } from "framer-motion";

import { cn } from "../../../lib/utils";

export interface IOnboardingStepTitle {
  title: string;
  subtitle: string;
  /**
   * Alignment of the title and subtitle
   * @default "center"
   */
  align?: "left" | "center";
}

export const OnboardingStepTitle: React.FC<IOnboardingStepTitle> = ({ title, subtitle, align = "center" }) => {
  return (
    <div className={cn("flex w-full flex-col space-y-2", align === "center" ? "items-center" : "items-start")}>
      <motion.h2
        animate={{ opacity: 1, y: 0 }}
        className={cn(align === "center" ? "text-center" : "text-left")}
        initial={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
      >
        {title}
      </motion.h2>
      <motion.p
        animate={{ opacity: 1, y: 0 }}
        className={cn("text-muted-foreground", align === "center" ? "text-center" : "text-left")}
        initial={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {subtitle}
      </motion.p>
    </div>
  );
};
