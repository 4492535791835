import type { IVersionType } from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";
import type { IBreadcrumbNavItem } from "@archetype/ui";
import { simpleLinkRenderer } from "@archetype/ui";
import React from "react";

import type { IGetEntityTypeRoute } from "../api";
import { EntityTypeBreadcrumb } from "./EntityTypeBreadcrumb";

export function generateEntityTypeBreadcrumb({
  entityTypeId,
  isCurrentPage,
  versionType,
  getEntityTypeRoute,
}: {
  entityTypeId?: IEntityTypeId; // TODO @sspringer this should be required but for now we need to support application groups loading this breadcrumb
  isCurrentPage: boolean;
  versionType: IVersionType;
  getEntityTypeRoute: IGetEntityTypeRoute;
}): IBreadcrumbNavItem {
  const linkRenderer = entityTypeId == null ? undefined : simpleLinkRenderer(getEntityTypeRoute({ entityTypeId }));

  return {
    text: <EntityTypeBreadcrumb entityTypeId={entityTypeId} versionType={versionType} />,
    isCurrentPage,
    linkRenderer,
  };
}
