import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { MetricCardComponentDefinitionId } from "@archetype/ids";

export const MetricCardComponentIdDeclarations = {
  id: MetricCardComponentDefinitionId,
  inputs: ["multiEntityQueryDataInput"],
  outputs: [],
  compatibleSemanticSizes: ["summary", "medium", "split-vertical", "split-horizontal"],
  validCosmeticOptionalProps: ["label", "styling"],
} as const;

export const MetricCardComponentDefinition: ITypedComponentDefinitionDeclaration<
  typeof MetricCardComponentIdDeclarations
> = {
  constIds: MetricCardComponentIdDeclarations,
  displayMetadata: {
    name: "Metric card",
    description: "A card that displays a single metric",
  },
  inputs: {
    multiEntityQueryDataInput: {
      displayMetadata: {
        name: "Metric data",
        description: "Metrics data to display",
      },
      optional: false,
    },
  },
  outputs: {},
};
