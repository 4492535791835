import type { IColumnType, IConditionalFormattingDefinition, IEntityTypeCore, IStateMachine } from "@archetype/dsl";
import { ColumnId } from "@archetype/ids";
import { map, mapKeysNoUndefined, mapValues, ReadableString } from "@archetype/utils";
import { cloneDeep } from "lodash";

// Generic to show that a TargetEntityTypeCore will remain a TargetEntityTypeCore
export const correctEntityTypeIfNeeded = <T extends IEntityTypeCore>(
  previousEntityType: T,
  potentialStateMachine: IStateMachine | undefined,
): T => {
  const newEntityType: T = cloneDeep(previousEntityType);

  // Correct statusColumn if needed ————————————————————————————————————————————————————————

  if (newEntityType.statusColumn != null) {
    if (newEntityType.statusColumn === newEntityType.primaryKey) {
      const currentStatusAndIdColumn = newEntityType.columns.find((column) => column.id === newEntityType.statusColumn);

      if (currentStatusAndIdColumn != null && currentStatusAndIdColumn.columnType.type === "statusEnum") {
        newEntityType.primaryKey = ColumnId.generate();
      } else {
        newEntityType.statusColumn = ColumnId.generate();
      }
    }

    if (newEntityType.statusColumn === newEntityType.displayNameColumn) {
      const currentStatusAndTitleColumn = newEntityType.columns.find(
        (column) => column.id === newEntityType.statusColumn,
      );

      if (currentStatusAndTitleColumn != null && currentStatusAndTitleColumn.columnType.type === "statusEnum") {
        newEntityType.displayNameColumn = ColumnId.generate();
      } else {
        newEntityType.statusColumn = ColumnId.generate();
      }
    }

    let editedStatusColumnType: IColumnType | undefined = undefined;
    let editedStatusConditionalFormatting: IConditionalFormattingDefinition | undefined = undefined;

    // Important to check if it's for the right entity type
    if (potentialStateMachine != null && potentialStateMachine.dataModel.targetEntityTypeId === newEntityType.id) {
      editedStatusColumnType = {
        type: "statusEnum",
        allowedValues: map(potentialStateMachine.states, (state) => ({
          id: state.id,
          readableValue: state.label,
        })),
        archivedValues: map(potentialStateMachine.archivedStates ?? {}, (state) => ({
          id: state.id,
          readableValue: state.label,
        })),
      };
      editedStatusConditionalFormatting = {
        type: "conditionalFormattingEnum",
        valueToNamedColor: mapValues(
          mapKeysNoUndefined(potentialStateMachine.states, (s) => s.id),
          (s) => s.color,
        ),
      };
    }

    let statusColumnIndex = newEntityType.columns.findIndex((column) => column.id === newEntityType.statusColumn);

    if (statusColumnIndex === -1) {
      newEntityType.columns.unshift({
        id: newEntityType.statusColumn,
        displayMetadata: {
          name: ReadableString.parse("Status"),
        },
        columnType: editedStatusColumnType ?? {
          type: "statusEnum",
          allowedValues: [],
          archivedValues: [],
        },
        validations: [],
        conditionalFormatting: editedStatusConditionalFormatting ?? {
          type: "conditionalFormattingEnum",
          valueToNamedColor: {},
        },
        nonNullable: true,
        unique: false,
      });
      statusColumnIndex = 0;
    }

    if (editedStatusColumnType != null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
      newEntityType.columns[statusColumnIndex]!.columnType = editedStatusColumnType;
    }
    if (editedStatusConditionalFormatting != null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
      newEntityType.columns[statusColumnIndex]!.conditionalFormatting = editedStatusConditionalFormatting;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
    newEntityType.columns[statusColumnIndex]!.unique = false;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
    newEntityType.columns[statusColumnIndex]!.nonNullable = true;
  }

  // Correct primaryKey if needed ————————————————————————————————————————————————————————

  if (newEntityType.displayNameColumn === newEntityType.primaryKey) {
    newEntityType.displayNameColumn = ColumnId.generate();
  }

  let indexOfPrimaryKey = newEntityType.columns.findIndex((column) => column.id === newEntityType.primaryKey);

  if (indexOfPrimaryKey === -1) {
    newEntityType.columns.unshift({
      id: newEntityType.primaryKey,
      displayMetadata: {
        name: ReadableString.parse("Id"),
      },
      columnType: {
        type: "shortText",
      },
      validations: [],
      conditionalFormatting: undefined,
      nonNullable: true,
      unique: true,
    });
    indexOfPrimaryKey = 0;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
  newEntityType.columns[indexOfPrimaryKey]!.columnType = {
    type: "shortText",
  };
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
  newEntityType.columns[indexOfPrimaryKey]!.conditionalFormatting = undefined;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
  newEntityType.columns[indexOfPrimaryKey]!.nonNullable = true;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
  newEntityType.columns[indexOfPrimaryKey]!.unique = true;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
  newEntityType.columns[indexOfPrimaryKey]!.autofill = {
    type: "snapshot",
    config: {
      type: "dynamic",
      value: "randomUUID",
    },
  };

  // Correct displayNameColumn if needed ————————————————————————————————————————————————————————

  let indexOfDisplayName = newEntityType.columns.findIndex((column) => column.id === newEntityType.displayNameColumn);

  if (indexOfDisplayName === -1) {
    newEntityType.columns.unshift({
      id: newEntityType.displayNameColumn,
      displayMetadata: {
        name: ReadableString.parse("Title"),
      },
      columnType: {
        type: "shortText",
      },
      validations: [],
      conditionalFormatting: undefined,
      nonNullable: true,
      unique: false,
    });
    indexOfDisplayName = 0;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
  newEntityType.columns[indexOfDisplayName]!.nonNullable = true;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe
  newEntityType.columns[indexOfDisplayName]!.unique = false;

  return newEntityType;
};
