import React from "react";

import type { IDataTableCell } from "./api";
import { BadgeCell } from "./BadgeCell";
import { BooleanCell } from "./BooleanCell";
import { CheckboxCell } from "./CheckboxCell";
import { CheckboxWithNumberCell } from "./CheckboxWithNumberCell";
import { ClickableListCell } from "./ClickableListCell";
import { CurrencyCell } from "./CurrencyCell";
import { DateCell } from "./DateCell";
import { DateTimeCell } from "./DateTimeCell";
import { EmailCell } from "./EmailCell";
import { FileCell } from "./FileCell";
import { LinkCell } from "./LinkCell";
import { MarkdownCell } from "./MarkdownCell";
import { NullCell } from "./NullCell";
import { NumberCell } from "./NumberCell";
import { PercentageCell } from "./PercentageCell";
import { PhoneCell } from "./PhoneCell";
import { RowSelectionCell } from "./RowSelectionCell";
import { SelectCell } from "./SelectCell";
import { StatusCell } from "./StatusCell";
import { TextCell } from "./TextCell";

interface ICellContentProps<TRowId extends string, TColumnId extends string> {
  cell: IDataTableCell<TRowId, TColumnId>;
  isCellEditing: boolean;
  isCellSelected: boolean;
  isRowSelected: boolean;
}

export const CellContent = <TRowId extends string, TColumnId extends string>({
  cell,
  isCellEditing,
  isCellSelected,
  isRowSelected,
}: ICellContentProps<TRowId, TColumnId>): React.ReactElement => {
  switch (cell.type) {
    case "rowSelection": {
      return (
        <RowSelectionCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "text": {
      return (
        <TextCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "number": {
      return (
        <NumberCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "date": {
      return (
        <DateCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "datetime": {
      return (
        <DateTimeCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "currency": {
      return (
        <CurrencyCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "percentage": {
      return (
        <PercentageCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "select": {
      return (
        <SelectCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "checkbox": {
      return (
        <CheckboxCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "link": {
      return (
        <LinkCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "clickableList": {
      return (
        <ClickableListCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "badge": {
      return (
        <BadgeCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "boolean": {
      return (
        <BooleanCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "checkboxWithNumber": {
      return (
        <CheckboxWithNumberCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "phone": {
      return (
        <PhoneCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "email": {
      return (
        <EmailCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "file": {
      return (
        <FileCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "status": {
      return (
        <StatusCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "markdown": {
      return (
        <MarkdownCell
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
      );
    }
    case "null": {
      return <NullCell />;
    }
  }
};

CellContent.displayName = "CellContent";
