import type { IActionId, IStateId, ITransitionId } from "@archetype/ids";
import { groupByNoUndefined, mapValues } from "@archetype/utils";

import type { IStateMachine, IStateMachineStateTransition } from "../../schemas/ProcessStateMachine";

export interface IDefaultTransitionInfo {
  primaryTransition: IStateMachineStateTransition | undefined;
  nonExecutablePrimaryTransitionId: ITransitionId | undefined;
  /**
   * For context columns, but not removed from the other transitions. No default if no transition
   */
  defaultTransition: IStateMachineStateTransition | undefined;
  otherTransitions: IStateMachineStateTransition[];
}

export type IDefaultTransitionByState = Record<IStateId, IDefaultTransitionInfo>;

const getTransitionIdsInHappyPath = (
  happyPath: IStateId[],
  transitionsByFromState: Record<IStateId, IStateMachineStateTransition[]>,
): Set<ITransitionId> => {
  const transitionIdsInHappyPath: ITransitionId[] = happyPath.flatMap((toState, idx) => {
    if (idx === 0) return []; // Skip first state as no transitions lead to it
    const fromState = happyPath[idx - 1];

    if (fromState == null) return [];

    return transitionsByFromState[fromState]?.filter((t) => t.to === toState).map((t) => t.id) ?? [];
  });

  return new Set(transitionIdsInHappyPath);
};

const findTransitionWithExecutablePriority = (
  transitions: IStateMachineStateTransition[],
  executableActionIdsSet: Set<IActionId>,
  transitionIdsSetInHappyPath: Set<ITransitionId>,
  happyPathStatesSet: Set<IStateId>,
  // eslint-disable-next-line max-params -- internal and all different types and all not empty
): IStateMachineStateTransition | undefined =>
  // Priority to happy path if it is executable by user (need to be checked here because there could be multiple happy path transitions)
  transitions.find((t) => transitionIdsSetInHappyPath.has(t.id) && executableActionIdsSet.has(t.actionId)) ??
  // then prioritize transition to happy path
  transitions.find((t) => happyPathStatesSet.has(t.to) && executableActionIdsSet.has(t.actionId));

export const defaultTransitionByState = (
  stateMachine: Pick<IStateMachine, "stateTransitions" | "happyPath">,
  /**
   * The executable actionIds for the user if defined, to prioritize columns from actions that can be executed
   */
  executableActionIds: IActionId[] | undefined,
): IDefaultTransitionByState => {
  const { stateTransitions, happyPath } = stateMachine;
  const happyPathStatesSet = new Set(happyPath);
  const transitionsByFromState = groupByNoUndefined(stateTransitions, (t) => t.from);

  // Default to all actions if no executableActionIds are provided - no need for create action here because it's for transitions only
  const allActionIds = stateTransitions.map((t) => t.actionId);
  const executableActionIdsSet = new Set(executableActionIds ?? allActionIds);

  const transitionIdsSetInHappyPath = getTransitionIdsInHappyPath(happyPath, transitionsByFromState);
  const res: IDefaultTransitionByState = mapValues(transitionsByFromState, (transitions) => {
    const maybeHappyPathTransition = transitions.find((t) => transitionIdsSetInHappyPath.has(t.id));
    const maybeTransitionToHappyPath = transitions.find((t) => happyPathStatesSet.has(t.to));

    const primaryTransitionByExecutable = findTransitionWithExecutablePriority(
      transitions,
      executableActionIdsSet,
      transitionIdsSetInHappyPath,
      happyPathStatesSet,
    );

    const executableTransitions = transitions.filter((t) => executableActionIdsSet.has(t.actionId));

    // default to happy path transition or any
    const defaultTransition =
      maybeHappyPathTransition ??
      // Otherwise transition that's executable
      executableTransitions[0] ??
      maybeTransitionToHappyPath ??
      transitions[0];

    return {
      primaryTransition: primaryTransitionByExecutable,
      nonExecutablePrimaryTransitionId: maybeHappyPathTransition?.id ?? maybeTransitionToHappyPath?.id,
      defaultTransition: primaryTransitionByExecutable ?? executableTransitions[0] ?? defaultTransition,
      otherTransitions: executableTransitions.filter((t) => t.id !== primaryTransitionByExecutable?.id),
    };
  });

  return res;
};
