import type {
  IActionInput,
  IColumnType,
  IColumnValidationIndex,
  IValidationGroup,
  IViewFieldValue,
} from "@archetype/dsl";
import type { IEntityId, IStateId } from "@archetype/ids";
import assertNever from "assert-never";
import { DateTime } from "luxon";
import { z } from "zod";

import { fileColumnValidator } from "./fileColumnValidator";
import { phoneColumnValidator } from "./phoneColumnValidator";

export const isColumnValidationIndex = (index: IValidationGroup["index"]): index is IColumnValidationIndex => {
  return index.type === "column";
};

export function isMissingRequiredField(actionInput: IActionInput, fieldValue: IViewFieldValue | undefined): boolean {
  return actionInput.required && isFieldValueNullableLike(fieldValue);
}

/**
 * Check if a field value is nullable like
 *
 * Some types will have specific emptiness checks, like for example an empty string that is displayed in all
 * places in an equivalent way to a null value.
 */
export function isFieldValueNullableLike(fieldValue: IViewFieldValue | null | undefined): boolean {
  if (fieldValue == null || fieldValue.type === "null") {
    return true;
  }

  switch (fieldValue.type) {
    case "string": {
      return fieldValue.value.length === 0;
    }
    case "file": {
      return fieldValue.value.length === 0;
    }
    case "relatedEntities": {
      return fieldValue.value.length === 0;
    }
    case "array": {
      return fieldValue.value.length === 0;
    }
    case "boolean":
    case "number":
    case "integer":
    case "timestamp":
    case "date": {
      return false;
    }

    default: {
      return assertNever(fieldValue);
    }
  }
}

export function getValidatorByType(columnType: IColumnType, entityId: IEntityId | undefined): z.ZodTypeAny {
  let validator: z.ZodTypeAny | undefined;

  switch (columnType.type) {
    case "email": {
      validator = z.string().email().nullable();
      break;
    }
    case "shortText":
    case "longText":
    case "geolocation": {
      validator = z.string().nullable();
      break;
    }

    case "file": {
      validator = fileColumnValidator(entityId);
      break;
    }

    case "phone": {
      validator = phoneColumnValidator;
      break;
    }

    case "url": {
      validator = z.string().url().nullable();
      break;
    }

    case "boolean": {
      validator = z.boolean().nullable();
      break;
    }

    case "number": {
      validator = z.number().nullable();
      break;
    }

    case "timestamp": {
      validator = z
        .string()
        .refine((s) => DateTime.fromISO(s).isValid, {
          message: "Invalid timestamp",
        })
        .nullable();
      break;
    }

    case "date": {
      validator = z
        .string()
        .refine((s) => DateTime.fromISO(s).isValid, {
          message: "Invalid date",
        })
        .nullable();
      break;
    }

    case "enum": {
      if ("enumAllowedValues" in columnType && columnType.enumAllowedValues != null) {
        const enumAllowedValues = columnType.enumAllowedValues;

        validator = z
          .array(z.string())
          .refine(
            (values) => values.every((value) => enumAllowedValues.includes(value)),
            (values) => {
              const invalidValues = values.filter((value) => !enumAllowedValues.includes(value));

              return {
                message: `Invalid values: ${invalidValues.join(", ")}`,
              };
            },
          )
          .nullable();
        break;
      } else {
        throw new Error("Enum must have allowed values defined!");
      }
    }

    case "statusEnum": {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety type may be wrong
      if ("allowedValues" in columnType && columnType.allowedValues != null) {
        validator = z
          .string()
          .refine(
            (s) => columnType.allowedValues.map((v) => v.id).includes(s as IStateId),
            (s) => ({
              message: `Invalid status ${s}`,
            }),
          )
          .nullable();
        break;
      } else {
        throw new Error("Status enum must have allowed values defined!");
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
  if (validator == null) {
    throw new Error(`Could not find matching validator for type ${columnType.type}`);
  }

  return validator;
}
