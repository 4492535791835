import type { IVersionType } from "@archetype/dsl";
import type { IEntityId, IEntityTypeId, IViewFieldId } from "@archetype/ids";
import { useMemoDeepCompare } from "@archetype/ui";
import { mapValues } from "@archetype/utils";

import { useInvalidatingActionExecution } from "../../action/actionExecution/useInvalidatingActionExecution";

export const useEntityHasSavingAction = ({
  versionType,
  entityTypeId,
  entityId,
}: {
  versionType: IVersionType;
  /**
   * Nothing interesting if null, makes props more flexible
   */
  entityTypeId: IEntityTypeId | undefined;
  /**
   * Nothing interesting if null
   */
  entityId: IEntityId | undefined;
}): {
  hasSaving: boolean;
  savingCount: number;
  savingFieldIds: Record<IViewFieldId, boolean>;
} => {
  const { savingState } = useInvalidatingActionExecution();

  const savingStateForEntityType = useMemoDeepCompare(
    () => (entityTypeId != null && entityId != null ? savingState[versionType]?.[entityTypeId]?.[entityId] : undefined),
    [savingState, versionType, entityTypeId, entityId],
  );

  if (entityId == null) {
    return {
      hasSaving: false,
      savingCount: 0,
      savingFieldIds: {},
    };
  }

  return {
    hasSaving: savingStateForEntityType != null && savingStateForEntityType.savingCount > 0,
    savingCount: savingStateForEntityType?.savingCount ?? 0,
    savingFieldIds: mapValues(savingStateForEntityType?.savingByViewFieldId, (s) => s > 0),
  };
};
