import type { IColumnType } from "@archetype/dsl";
import type { IIconNames } from "@archetype/ui";
import { match } from "ts-pattern";

export const getColumnTypeIcon = (columnType: IColumnType["type"]): IIconNames => {
  return match(columnType)
    .returnType<IIconNames>()
    .with("shortText", "longText", () => "text")
    .with("number", () => "binary")
    .with("date", "timestamp", () => "calendar")
    .with("enum", "statusEnum", () => "chevron-down-circle")
    .with("boolean", () => "toggle-right")
    .with("phone", () => "smartphone")
    .with("email", () => "mail")
    .with("url", () => "link")
    .with("geolocation", () => "map-pin")
    .with("file", () => "file")
    .exhaustive();
};
