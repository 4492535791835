import { ColumnTypeId } from "@archetype/ids";
import { z } from "zod";

export const ColumnNumberType = z.object({ type: z.literal("number"), primitiveType: z.literal("number") });
export type IColumnNumberType = z.infer<typeof ColumnNumberType>;

// Number range refinement
export const ColumnNumberRangeRefinement = z
  .object({
    type: z.literal("numberRange"),
    primitiveType: z.literal("number"),
    inclusiveMin: z.number().optional(),
    inclusiveMax: z.number().optional(),
    child: ColumnTypeId,
  })
  .describe("Refine a number to be within a range");
export type IColumnNumberRangeRefinement = z.infer<typeof ColumnNumberRangeRefinement>;

// Non-nullable number refinement
export const ColumnNonNullableNumberRefinement = z
  .object({
    type: z.literal("nonNullableNumber"),
    primitiveType: z.literal("number"),
    child: ColumnTypeId,
  })
  .describe("Refine a number to never be null");
export type IColumnNullableNumberRefinement = z.infer<typeof ColumnNonNullableNumberRefinement>;

export const ColumnNumberRefinements = z.discriminatedUnion("type", [
  ColumnNumberType,
  ColumnNumberRangeRefinement,
  ColumnNonNullableNumberRefinement,
]);
export type IColumnNumberRefinements = z.infer<typeof ColumnNumberRefinements>;
