import { DateTimeInput as UIDateTimeInput } from "@archetype/ui";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { match } from "ts-pattern";

import type { IActionInputComponent } from "../types";

export const TimestampInput: IActionInputComponent = ({ className, field, onChange, readOnly = false }) => {
  const handleChange = useCallback(
    (value: Date | undefined): void => {
      onChange(
        value == null
          ? { type: "null" }
          : {
              type: "timestamp",
              value: DateTime.fromJSDate(value).toString(),
            },
      );
    },
    [onChange],
  );

  const handleBlur = useCallback(() => {
    field.onBlur();
  }, [field]);

  const value: Date | undefined = match(field.value)
    .with({ type: "date" }, { type: "timestamp" }, (v) => DateTime.fromISO(v.value).toJSDate())
    .with({ type: "string" }, (v) => new Date(v.value))
    .otherwise(() => undefined);

  return (
    <UIDateTimeInput
      className={className}
      disabled={readOnly}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};
