import type { IVersionType } from "@archetype/dsl";
import type { IActionId, IEntityTypeId, IOrganizationId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { randomLookingLcg, Skeleton } from "@archetype/ui";
import React from "react";

import type { IEntityTableCellWrapper } from "./EntityTableCellWrapper";
import { EntityTableCellWrapper } from "./EntityTableCellWrapper";

type IEntityTableDraftCellWrapper = Omit<IEntityTableCellWrapper, "cellValue"> & {
  organizationId: IOrganizationId;
  versionType: IVersionType;
  entityTypeId: IEntityTypeId;
  actionId: IActionId;
};

export const EntityTableDraftCellWrapper: React.FC<IEntityTableDraftCellWrapper> = ({
  organizationId,
  versionType,
  entityTypeId,
  actionId,
  ...props
}) => {
  const { rowId, colId } = props;

  const { data: draftQuery, isLoading: isLoadingDraft } = builderTrpc.action.getDraftForAction.useQuery({
    organizationId,
    versionType,
    actionId: actionId,
    entityTypeId,
    entityId: rowId,
  });

  const draft = draftQuery?.draft;

  if (isLoadingDraft) {
    const colSeed = colId.charCodeAt(colId.length - 2) + colId.charCodeAt(colId.length - 1);
    const rowSeed = rowId.charCodeAt(rowId.length - 2) + rowId.charCodeAt(rowId.length - 1);

    return (
      <div className="flex h-full items-center px-2">
        <Skeleton className="h-5" style={{ width: 50 + 100 * randomLookingLcg(colSeed, rowSeed + 1) }} />
      </div>
    );
  }

  const cellValue = draft?.fields[colId];

  return (
    <EntityTableCellWrapper
      {...props}
      cellValue={{
        value: cellValue ?? {
          type: "null",
        },
        computeStatus: { kind: null, status: "success" },
      }}
      versionType={versionType}
    />
  );
};
