import { mapValues } from "@archetype/utils";

import type { ILoadedNestedCreateFormValues, INestedCreateFormValues } from "../apiTypes/ActionExecution";

export const loadedNestedFormValuesToNestedFormValues = (
  loadedNestedCreateFormValues: ILoadedNestedCreateFormValues,
): INestedCreateFormValues => {
  return mapValues(loadedNestedCreateFormValues, (nestedForEntityType) =>
    mapValues(nestedForEntityType, (nestedEntityCreation) =>
      nestedEntityCreation == null
        ? undefined
        : {
            createActionId: nestedEntityCreation.createAction.id,
            fieldValues: nestedEntityCreation.fieldValues,
          },
    ),
  );
};
