import type { IIconNames } from "@archetype/ui";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger, Icon } from "@archetype/ui";
import React from "react";

import type { IDataTableHeaderMenu } from "./Header";
import { HeaderMenu } from "./HeaderMenu";

export interface IButtonHeader {
  buttons: {
    icon: IIconNames;
    menu: IDataTableHeaderMenu;
  }[];
}

export function ButtonHeader({ buttons }: IButtonHeader): React.ReactNode {
  return (
    <div className="divide-border flex size-full items-center justify-center divide-x">
      {buttons.map((button, idx) => (
        // eslint-disable-next-line react/no-array-index-key -- no unicity on props
        <DropdownMenu key={idx}>
          <DropdownMenuTrigger className="hover:bg-accent-background active:bg-accent-background flex size-full cursor-pointer items-center justify-center px-2 py-4">
            <Icon name={button.icon} />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {button.menu.type === "dropdown" && (
              <HeaderMenu items={button.menu.items} searchable={button.menu.searchable} />
            )}
            {button.menu.type === "custom" && button.menu.renderer()}
          </DropdownMenuContent>
        </DropdownMenu>
      ))}
    </div>
  );
}
