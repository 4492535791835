import type { ILoadedRelationViewField } from "@archetype/core";
import type { IEntityTypeCore, IVersionType } from "@archetype/dsl";
import type { IColumnId, IEntityTypeId } from "@archetype/ids";
import { useMemoDeepCompare } from "@archetype/ui";
import { isNonNullable, keyByNoUndefined } from "@archetype/utils";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { match } from "ts-pattern";

import { useAddAIFilters } from "./hooks/useAddAIFilters";
import { useFiltersFromURL } from "./hooks/useFiltersFromURL";
import { useSimpleFiltersActions } from "./hooks/useSimpleFiltersActions";
import { InlineFilterEditor } from "./InlineFilterEditor";

interface IEntityTypeInlineFilters {
  entityType: IEntityTypeCore;
  allEntityTypes: Record<IEntityTypeId, IEntityTypeCore | undefined>;
  filterableColumnIds: IColumnId[];
  filterableRelations: ILoadedRelationViewField[];
  versionType: IVersionType;
}

export const EntityTypeInlineFilters: React.FC<IEntityTypeInlineFilters> = ({
  entityType,
  filterableColumnIds,
  filterableRelations,
  allEntityTypes,
  versionType,
}) => {
  const [filters, setFilters] = useFiltersFromURL();

  const { addAIFilters: handleAddAIFilters, isLoading: isAddingAIFilter } = useAddAIFilters(entityType);

  const {
    addOrUpdateFilter: handleAddOrUpdateFilter,
    removeFilterByColumnId,
    removeFilterByRelationId,
    removeGlobalSearchFilter,
  } = useSimpleFiltersActions();

  const columnById = useMemoDeepCompare(
    () => keyByNoUndefined(entityType.columns, (col) => col.id),
    [entityType.columns],
  );

  const filterColumns = useMemoDeepCompare(
    () => filterableColumnIds.map((colId) => columnById[colId]).filter(isNonNullable),
    [filterableColumnIds, columnById],
  );

  const activeFilters = useMemo(() => {
    const active = filters
      .map((filter, index) => {
        if (filter.type === "search") return { id: String(index), data: { filter } };

        // ToDo: check if we get a filterColumn for relations. If so, remove this.
        if (filter.type === "relation") return { id: String(index), data: { filter } };

        const column = filterColumns.find((col) => col.id === filter.colId);

        return column ? { id: String(index), data: { column, filter } } : null;
      })
      .filter(isNonNullable);

    return [...active, { id: String(filters.length), data: undefined }];
  }, [filterColumns, filters]);

  const removeEmptyFilters = useCallback(() => {
    const newFilters = activeFilters
      .filter(({ data }) => data != null && data.filter.value.length > 0)
      .map(({ data }) => data?.filter)
      .filter(isNonNullable);

    if (newFilters.length !== filters.length) setFilters(newFilters);
  }, [activeFilters, filters.length, setFilters]);

  const removeEmptyFiltersOnNextUpdateRef = useRef(false);

  // Remove empty filters. We also schedule it to run on the next update because when the popover closes,
  // the URL might not yet have the latest filters, which could lead to empty filters sticking around.
  const handleCloseFilterEditor = useCallback(() => {
    removeEmptyFiltersOnNextUpdateRef.current = true;
    removeEmptyFilters();
  }, [removeEmptyFilters]);

  useEffect(() => {
    if (!removeEmptyFiltersOnNextUpdateRef.current) return;

    removeEmptyFilters();

    removeEmptyFiltersOnNextUpdateRef.current = false;
  }, [activeFilters, removeEmptyFilters]);

  const handleRemoveFilterWithId = useCallback(
    (filterId: string) => {
      return (): void => {
        const activeFilter = activeFilters.find(({ id }) => id === filterId);
        const filter = activeFilter?.data?.filter;

        if (filter === undefined) return;

        match(filter)
          .with({ type: "search" }, () => {
            removeGlobalSearchFilter();
          })
          .with({ type: "column" }, (typedFilter) => {
            removeFilterByColumnId(typedFilter.colId);
          })
          .with({ type: "relation" }, (typedFilter) => {
            removeFilterByRelationId(typedFilter.relationId);
          });
      };
    },
    [activeFilters, removeFilterByColumnId, removeFilterByRelationId, removeGlobalSearchFilter],
  );

  if (isAddingAIFilter) {
    return (
      <div className="flex items-center gap-2">
        <div className="bg-muted-background h-6 w-32 animate-pulse rounded" />
        <div className="bg-muted-background h-6 w-48 animate-pulse rounded" />
        <div className="bg-muted-background h-6 w-16 animate-pulse rounded" />
      </div>
    );
  }

  return (
    <div className="flex flex-wrap items-center gap-2">
      {activeFilters.map(({ id, data }) => (
        <InlineFilterEditor
          key={id}
          {...data}
          allEntityTypes={allEntityTypes}
          entityType={entityType}
          filterColumns={filterColumns}
          filterCount={filters.length}
          filterableRelations={filterableRelations}
          statusColumnId={entityType.statusColumn ?? undefined}
          versionType={versionType}
          onAddAIFilters={handleAddAIFilters}
          onAddOrUpdateFilter={handleAddOrUpdateFilter}
          onClose={handleCloseFilterEditor}
          onRemove={handleRemoveFilterWithId(id)}
        />
      ))}
    </div>
  );
};
