import { ReadableString } from "@archetype/utils";
import { z } from "zod";

export const WorkspaceSupportEntityTypeColumnType = z.object({
  typeName: z.enum([
    "email",
    "shortText",
    "longText",
    "number",
    "boolean",
    "date",
    "timestamp",
    "geolocation",
    "phone",
    "file",
    "url",
    "enum",
  ]),
  enumAllowedValues: z.array(ReadableString).nullable(),
});
export type IWorkspaceSupportEntityTypeColumnType = z.infer<typeof WorkspaceSupportEntityTypeColumnType>;

export const WorkspaceSupportEntityTypeColumn = z.object({
  label: ReadableString,
  columnType: WorkspaceSupportEntityTypeColumnType,
});
export type IWorkspaceSupportEntityTypeColumn = z.infer<typeof WorkspaceSupportEntityTypeColumn>;

export const WorkspaceSupportEntityTypeRelation = z.object({
  label: ReadableString,
  backrefLabel: ReadableString,
  relatedEntityType: ReadableString,
  allowMultipleRelatedEntities: z.boolean(),
});
export type IWorkspaceSupportEntityTypeRelation = z.infer<typeof WorkspaceSupportEntityTypeRelation>;

export const WorkspaceSupportEntityType = z.object({
  title: ReadableString,
  pluralTitle: ReadableString,
  description: ReadableString,
  nameOfTitleColumn: ReadableString,
  columns: z.array(WorkspaceSupportEntityTypeColumn),
});
export type IWorkspaceSupportEntityType = z.infer<typeof WorkspaceSupportEntityType>;
