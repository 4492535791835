import { cn, Input } from "@archetype/ui";
import { useCallback } from "react";
import React from "react";

import type { IColumnFilterComponent } from "../types";

export const NumberFilterInput: IColumnFilterComponent = ({ className, value, onChange }) => {
  const handleBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

      if (newValue === "") {
        onChange(undefined);
      } else {
        onChange({
          eq: [{ type: "value", value: { type: "number", value: Number(newValue) } }],
        });
      }
    },
    [onChange],
  );

  const currentWrappedValue = value?.eq?.[0];
  const currentValue =
    // ternary needed to keep it a const
    currentWrappedValue?.type === "value" && currentWrappedValue.value.type === "number"
      ? currentWrappedValue.value.value
      : undefined;

  // TODO filters - add range slider instead of input
  return (
    <Input
      small
      className={cn("w-full", className)}
      defaultValue={currentValue == null ? "" : String(currentValue)}
      type="number"
      onBlur={handleBlur}
    />
  );
};
