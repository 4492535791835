import type { ILayoutDefinition, ILayoutDefinitionDeclaration, ILayoutIdDeclaration } from "@archetype/dsl";
import type { ILayoutId } from "@archetype/ids";
import { SlotId } from "@archetype/ids";
import { keyByNoUndefined, mapValues } from "@archetype/utils";
import { omit, once } from "lodash";

import { ComparisonListLayout, ComparisonListLayoutIds } from "../layouts/ComparisonList";
import { FilterableListLayout, FilterableListLayoutIds } from "../layouts/FilterableList";
import {
  SearchableFilterableListSplitViewLayout,
  SearchableFilterableListSplitViewLayoutIds,
} from "../layouts/SearchableFilterableListSplitView";
import { SearchableListLayout, SearchableListLayoutIds } from "../layouts/SearchableList";
import { SearchableListWithFilterLayout, SearchableListWithFilterLayoutIds } from "../layouts/SearchableListWithFilter";
import {
  SearchableListWithMetricsLayout,
  SearchableListWithMetricsLayoutIds,
} from "../layouts/SearchableListWithMetrics";
import { SimpleListLayout, SimpleListLayoutIds } from "../layouts/SimpleList";

export const LAYOUTS_DEFINITIONS_ARR: ILayoutDefinitionDeclaration[] = [
  ComparisonListLayout,
  FilterableListLayout,
  SearchableListLayout,
  SearchableListWithMetricsLayout,
  SearchableListWithFilterLayout,
  SimpleListLayout,
  SearchableFilterableListSplitViewLayout,
];

const LAYOUT_IDS_DECLARATIONS = [
  ComparisonListLayoutIds,
  FilterableListLayoutIds,
  SearchableListLayoutIds,
  SearchableListWithMetricsLayoutIds,
  SearchableListWithFilterLayoutIds,
  SimpleListLayoutIds,
  SearchableFilterableListSplitViewLayoutIds,
];

export const GENERIC_LAYOUT_IDS_DECLARATIONS: ILayoutIdDeclaration[] = LAYOUT_IDS_DECLARATIONS;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any -- test type
export const __DO_NOT_USE_test_layout_id: ILayoutId = "" as any;
export const __DO_NOT_USE_test_id_completeness: (typeof LAYOUT_IDS_DECLARATIONS)[number]["id"] =
  __DO_NOT_USE_test_layout_id;

export type ILayoutIdsDeclarationForId<ID extends ILayoutId> = (typeof LAYOUT_IDS_DECLARATIONS)[number] & { id: ID };

export type ILayoutDefinitionRegistry = Record<string, ILayoutDefinition>;

export const getLayoutDefinitionRegistry: () => ILayoutDefinitionRegistry = once(() =>
  keyByNoUndefined(
    LAYOUTS_DEFINITIONS_ARR.map((layout) =>
      omit(
        {
          ...layout,
          id: layout.constIds.id as ILayoutId,
          slots: mapValues(layout.slots, (slot, slotId) => ({
            ...slot,
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- test type
            optional: layout.constIds.slots[slotId]?.optional || false,
            id: SlotId.parse(slotId),
          })),
        },
        "constIds",
      ),
    ),
    (layout) => layout.id,
  ),
);
