import { forEach } from "@archetype/utils";

import type { DATA_MODEL_REF_MAPPING_ID_TYPES, IReferencedDataModelIds } from "./dataModelReferencesMapperTypes";

export const mergeReferencedDataModelMappings = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  ...mappings: IReferencedDataModelIds<IDS>[]
): IReferencedDataModelIds<IDS> => {
  const outputMapping: IReferencedDataModelIds<IDS> = {
    newEntityTypes: {} as IReferencedDataModelIds<IDS>["newEntityTypes"],
    preMappedEntityTypes: {} as IReferencedDataModelIds<IDS>["preMappedEntityTypes"],
    newActions: new Set<IDS["ORIG_ACT_ID"]>(),
    newEntityRelations: new Set<IDS["ORIG_REL_ID"]>(),
    newStates: new Set<IDS["ORIG_STATE_ID"]>(),
  };

  mappings.forEach((mapping) => {
    forEach(mapping.newEntityTypes, (columnIds, entityTypeId) => {
      if (outputMapping.newEntityTypes[entityTypeId] != null) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually checked for null above
        const current = outputMapping.newEntityTypes[entityTypeId]!;

        columnIds?.forEach((colId) => current.add(colId));
      } else {
        outputMapping.newEntityTypes[entityTypeId] = columnIds;
      }
    });

    forEach(mapping.preMappedEntityTypes, (columnIds, entityTypeId) => {
      if (outputMapping.preMappedEntityTypes[entityTypeId] != null) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually checked for null above
        const current = outputMapping.preMappedEntityTypes[entityTypeId]!;

        columnIds?.forEach((colId) => current.add(colId));
      } else {
        outputMapping.preMappedEntityTypes[entityTypeId] = columnIds;
      }
    });

    mapping.newActions.forEach((actionId: IDS["ORIG_ACT_ID"]) => {
      outputMapping.newActions.add(actionId);
    });

    mapping.newEntityRelations.forEach((entityRelationId: IDS["ORIG_REL_ID"]) => {
      outputMapping.newEntityRelations.add(entityRelationId);
    });

    mapping.newStates.forEach((stateId: IDS["ORIG_STATE_ID"]) => {
      outputMapping.newStates.add(stateId);
    });
  });

  return outputMapping;
};
