import type { IColumnId, IRelationId } from "@archetype/ids";
import { cn } from "@archetype/ui";
import type { EditorConfig, LexicalNode, RangeSelection, SerializedTextNode } from "lexical";
import { $createTextNode, TextNode } from "lexical";

type IEntityFieldMentionType =
  | {
      type: "column";
      columnId: IColumnId;
    }
  | {
      type: "relation";
      relationId: IRelationId;
      direction: "aToB" | "bToA";
    };

interface ISerializedEntityFieldMentionNode extends SerializedTextNode {
  mention: IEntityFieldMentionType;
  type: "entity-field-mention";
  version: 1;
}

export class EntityFieldMentionNode extends TextNode {
  __mention: IEntityFieldMentionType;
  __variant: "badge" | "text";

  static getType(): string {
    return "entity-field-mention";
  }

  static clone(node: EntityFieldMentionNode): EntityFieldMentionNode {
    const clone = new EntityFieldMentionNode(node.getTextContent(), node.__mention, node.__variant);

    clone.__key = node.__key;

    return clone;
  }

  getStyle(): string {
    return cn(
      "inline-block",
      this.__variant === "badge" ? "cursor-pointer select-none rounded bg-accent px-1.5 py-0.5" : "",
    );
  }

  constructor(text: string, mention: IEntityFieldMentionType, variant: "badge" | "text" = "badge") {
    super(text);
    this.__mention = mention;
    this.__variant = variant;
  }

  setTextContent(text: string): this {
    if (text === this.getTextContent()) {
      return this;
    }

    super.setTextContent(text);

    return this;
  }

  getTextContent(): string {
    return super.getTextContent();
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    const text = this.getTextContent();

    dom.className = cn(this.getStyle(), {
      "text-muted-foreground": text.length === 0,
    });
    dom.contentEditable = "false";

    if (text.length === 0) {
      dom.textContent = "Empty";
    }

    return dom;
  }

  updateDOM(prevNode: this, dom: HTMLElement, _config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, _config);
    const text = this.getTextContent();

    if (text.length === 0) {
      dom.textContent = "Empty";
    }

    dom.className = this.getStyle();

    return isUpdated || prevNode.__mention !== this.__mention;
  }

  static importJSON(serializedNode: ISerializedEntityFieldMentionNode): EntityFieldMentionNode {
    const node = $createEntityFieldMentionNode({
      mention: serializedNode.mention,
      value: serializedNode.text,
    });

    return node;
  }

  exportJSON(): ISerializedEntityFieldMentionNode {
    return {
      ...super.exportJSON(),
      mention: this.__mention,
      type: "entity-field-mention",
      version: 1,
    };
  }

  // Handle selection at offset 0 to allow insertion before mention
  selectPrevious(anchorOffset?: number, focusOffset?: number): RangeSelection {
    const textNode = $createTextNode("");

    this.insertBefore(textNode);

    return textNode.select(anchorOffset ?? 0, focusOffset ?? 0);
  }

  // Override to prevent text content from being cleared
  remove(preserveEmptyParent?: boolean): void {
    const text = this.getTextContent();
    const textNode = $createTextNode(text);

    this.replace(textNode);
    textNode.remove(preserveEmptyParent);
  }

  // Prevent the node from being edited
  isInert(): boolean {
    return true;
  }

  // Prevent the node from being split
  isSegmented(): boolean {
    return true;
  }
}

export function $createEntityFieldMentionNode({
  mention,
  value = "",
  variant = "badge",
}: {
  mention: IEntityFieldMentionType;
  value?: string;
  variant?: "badge" | "text";
}): EntityFieldMentionNode {
  return new EntityFieldMentionNode(value, mention, variant);
}

export function $isEntityFieldMentionNode(node: LexicalNode | null | undefined): node is EntityFieldMentionNode {
  return node instanceof EntityFieldMentionNode;
}

export function $isColumnMention(mention: IEntityFieldMentionType): mention is { type: "column"; columnId: IColumnId } {
  return mention.type === "column";
}

export function $isRelationMention(
  mention: IEntityFieldMentionType,
): mention is { type: "relation"; relationId: IRelationId; direction: "aToB" | "bToA" } {
  return mention.type === "relation";
}
