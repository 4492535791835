import { ColumnTypeId, ColumnTypeVersionId } from "@archetype/ids";

import type { IFullColumnTypeTree } from "../../../utils/dataModel/resolveTypeTree";
import type { IComplexColumnType } from "../ColumnType";
import { BUILT_IN_DATA_MODEL_METADATA_INFO } from "./common";
import { StringTree } from "./primitives";

const EMAIL_TYPE_ID = ColumnTypeId.parse("1899f6f1-606b-45bb-930c-4d1826c4529d");

const EmailType: IComplexColumnType = {
  id: EMAIL_TYPE_ID,
  versionId: ColumnTypeVersionId.parse("d2c20fc8-5f2e-433c-b9b0-4fdc21fa0e70"),
  version: {
    major: 1,
    minor: 0,
    patch: 0,
  },
  updatedAt: new Date(),
  basedOn: null,
  computedFrom: BUILT_IN_DATA_MODEL_METADATA_INFO,
  originVersionId: null,
  displayMetadata: {
    name: "Email",
  },
  definition: {
    type: "stringEndsWith",
    primitiveType: "string",
    child: StringTree.columnType.id,
    endsWithString: "@archetype.do",
  },
  entityTypeId: null,
  columnId: null,
};

export const EmailTree: IFullColumnTypeTree = {
  columnType: EmailType,
  child: StringTree,
};
