import { ViewFieldId } from "@archetype/ids";
import * as z from "zod";

import { RawEntity } from "./RawEntity";
import { ViewFieldValue } from "./ViewFieldValue";

export const Entity = RawEntity.omit({ columns: true }).merge(
  z.object({
    fields: z.record(ViewFieldId, ViewFieldValue.optional()),
  }),
);
export type IEntity = z.infer<typeof Entity>;
