import {
  type IExecuteActionQuery,
  type IExecuteActionResponse,
  type IExternalUserCreateProps,
  type ILoadedAction,
  type ILoadedEntity,
  type IReplacementNestedEntityIds,
  replaceFormAndNestedValuesWithRealEntityIds,
} from "@archetype/core";
import type { IEntityActionDraft, IValidationError, IValidationGroup, IVersionType } from "@archetype/dsl";
import type { IActionId, IEntityId, IEntityTypeId, IOrganizationId, IStateId, IViewFieldId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { useToast } from "@archetype/ui";
import { isNonNullable, keys } from "@archetype/utils";
import { noop } from "lodash";
import { createContext, useCallback, useContext, useState } from "react";
import { v4 } from "uuid";

import type { IEntityIdOrCreateActionDraft, IGetActionRoute } from "../../api";
import { useStableCurrentUserInfo } from "../../hooks/useStableCurrentUserInfo";
import { createFileLogger } from "../../logger";
import { loadedExecuteActionQueryToQuery } from "./loadedExecuteActionQueryToQuery";
import { makeEntityToRollback, makeEntityToSet } from "./makeEntityForCaches";
import {
  decrementSavingCount,
  incrementSavingCount,
  markAsErrorAndDecrementSavingCount,
  removeError,
} from "./savingStateHandlersUtils";
import type {
  IActionExecutionSavingState,
  IClearErrorArgs,
  ILoadedExecuteActionQuery,
  ISavingStateArgs,
  ISetAsErrorArgs,
  IValueForCache,
} from "./types";

export const logger = createFileLogger("useInvalidatingActionExecution");

interface IInvalidationParams {
  organizationId: IOrganizationId;

  /**
   * The current loaded entity, if any e.g. not for a create action.
   */
  actionInfo: ILoadedAction;
  actionToState: IStateId | undefined;
  shouldWaitForAsyncExecution: boolean;

  isCreateActionDraft: boolean;

  /**
   * Validation groups to use for FE validation if optimisticaly setting the cache
   */
  validationGroups: IValidationGroup[];

  maybeExternalUserCreateProps: IExternalUserCreateProps | undefined;
}

interface IExecuteActionArgs {
  executeActionQuery: ILoadedExecuteActionQuery;
  invalidationParams: IInvalidationParams;
  getActionRouteRedirectOnError: IGetActionRoute | undefined;
}

interface IOnActionInvalidateArgs {
  organizationId: IOrganizationId;

  actionId: IActionId;

  isCreateActionExecution: boolean;

  versionType: IVersionType;

  entityId: IEntityId;
  entityTypeId: IEntityTypeId;
}

interface IOnActionCacheUpdateArgs {
  organizationId: IOrganizationId;

  versionType: IVersionType;

  valueForCache: IValueForCache | undefined;

  actionToState: IStateId | undefined;

  actionId: IActionId;

  actionDraftCacheValue:
    | {
        draft: IEntityActionDraft | undefined;
        isCreateActionDraft: boolean;
      }
    | undefined;
}

const ActionExecutionContext = createContext<{
  savingState: IActionExecutionSavingState;
  executeAction: (args: IExecuteActionArgs) => Promise<IExecuteActionResponse>;
  setAsSaving: (args: ISavingStateArgs) => void;
  setAsSaved: (args: ISavingStateArgs) => void;
  setAsError: (args: ISetAsErrorArgs) => void;
  clearError: (args: IClearErrorArgs) => void;
}>({
  savingState: {},
  executeAction: () =>
    Promise.resolve({ success: false, errors: [], nestedActionErrors: {}, successReplacementNestedEntityIds: {} }),
  setAsSaving: noop,
  setAsSaved: noop,
  setAsError: noop,
  clearError: noop,
});

export const ActionExecutionContextProvider: React.FC<{
  versionType: IVersionType;
  children: React.ReactNode;
}> = ({ versionType: userInfoVersionType, children }) => {
  const [savingState, setSavingState] = useState<IActionExecutionSavingState>({});

  const { toast } = useToast();
  const { mutateAsync: executeActionTrpc } = trpc.action.executeAction.useMutation();

  // Only getting the util to write to cache
  // eslint-disable-next-line no-restricted-properties -- this is to set the cache not load directly
  const getLoadedEntityQuery = trpc.useUtils().dataLoading.getLoadedEntity;
  const getLoadedEntitiesQuery = trpc.useUtils().dataLoading.getLoadedEntities;
  const getBatchEntityCountQuery = trpc.useUtils().dataLoading.getBatchEntityCount;
  const actionLogQuery = trpc.useUtils().action.getActionLogsForEntityId;
  const previewEntitiesQuery = trpc.useUtils().dataLoading.getExampleEntitiesForActionPreviews;
  const getAccessToActionQuery = trpc.useUtils().action.getAccessToAction;
  const getAccessToActionsByEntityTypeIdQuery = trpc.useUtils().action.getAccessToActionsByEntityTypeId;
  const getValidRelationFieldsForEntityQuery = trpc.useUtils().dataModel.getValidRelationFieldsForEntity;
  const getValidationGroupsByEntityTypeQuery = trpc.useUtils().dataModel.getValidationGroupsByEntityType;

  // Draft endpoints
  const getCreateDraftIdsForEntityTypeQuery = trpc.useUtils().action.getCreateDraftIdsForEntityType;
  const getHasDraftForActionQuery = trpc.useUtils().action.getHasDraftForAction;
  const getDraftForActionQuery = trpc.useUtils().action.getDraftForAction;

  // To load current information only, not affected by edits
  const fullyLoadedEntityTypeQuery = trpc.useUtils().dataModel.fullyLoadedEntityType;

  const { data: currentUserInfoQuery } = useStableCurrentUserInfo({
    versionType: userInfoVersionType,
  });

  // Saving state handlers —————————————————————————————————————————————————————————————————————————————————————

  const setAsSaving = useCallback((args: ISavingStateArgs) => {
    setSavingState((prevSavingState) => incrementSavingCount(args, prevSavingState));
  }, []);

  const setAsSaved = useCallback((args: ISavingStateArgs) => {
    setSavingState((prevSavingState) => decrementSavingCount(args, prevSavingState));
  }, []);

  const setAsError = useCallback((args: ISetAsErrorArgs) => {
    setSavingState((prevSavingState) => markAsErrorAndDecrementSavingCount(args, prevSavingState));
  }, []);

  const clearError = useCallback((args: IClearErrorArgs) => {
    setSavingState((prevSavingState) => removeError(args, prevSavingState));
  }, []);

  // Cache setting handlers ————————————————————————————————————————————————————————————————————————————————

  const getCachedEntity = useCallback(
    ({
      entityId,
      entityTypeId,
      versionType,
      organizationId,
    }: {
      entityId: IEntityId | undefined;
      entityTypeId: IEntityTypeId;
      versionType: IVersionType;
      organizationId: IOrganizationId;
    }): ILoadedEntity | undefined => {
      if (entityId == null) {
        return undefined;
      }

      const singleLoadedEntity = getLoadedEntityQuery.getData({
        organizationId,
        versionType,
        entityId,
        entityTypeId,
      })?.entity;

      if (singleLoadedEntity != null) {
        return singleLoadedEntity;
      }

      const loadedEntities = getLoadedEntitiesQuery.getData({
        organizationId,
        dataLoadingQuery: {
          versionType,
          entityType: { type: "entityTypeId", entityTypeId },
          filters: undefined,
        },
        dataLoadingConfig: {
          specificRelations: null,
          specificColumns: null,
        },
      });

      const loadedInList = loadedEntities?.entities.find((entity) => entity.entityId === entityId);

      if (loadedInList != null) {
        return loadedInList;
      }

      const infiniteLoadedEntities = getLoadedEntitiesQuery.getInfiniteData({
        organizationId,
        dataLoadingQuery: {
          versionType,
          entityType: { type: "entityTypeId", entityTypeId },
          filters: undefined,
        },
        dataLoadingConfig: {
          specificRelations: null,
          specificColumns: null,
        },
      });

      const loadedInInfinite = infiniteLoadedEntities?.pages
        .flatMap((page) => page.entities)
        .find((entity) => entity.entityId === entityId);

      if (loadedInInfinite != null) {
        return loadedInInfinite;
      }

      return undefined;
    },
    [getLoadedEntityQuery, getLoadedEntitiesQuery],
  );

  const updateCaches = useCallback(
    ({
      organizationId,
      versionType,
      valueForCache,
      actionToState,
      actionId,
      actionDraftCacheValue,
    }: IOnActionCacheUpdateArgs) => {
      if (valueForCache == null) {
        return;
      }

      const entityToSet: ILoadedEntity | undefined = valueForCache.entity;
      const { entityTypeId, entityId, isNew } = valueForCache;

      // Setting the action drafts
      if (actionDraftCacheValue != null) {
        void getHasDraftForActionQuery.cancel({
          organizationId,
          versionType,
          entityId,
          actionId,
        });

        void getDraftForActionQuery.cancel({
          organizationId,
          versionType,
          entityId,
          entityTypeId,
          actionId,
        });

        if (actionDraftCacheValue.draft == null) {
          // Means this is the successful action execution and we remove the drafts
          getHasDraftForActionQuery.setData(
            {
              organizationId,
              versionType,
              entityId,
              actionId,
            },
            {
              hasDraft: false,
            },
          );

          getDraftForActionQuery.setData(
            {
              organizationId,
              versionType,
              entityId,
              entityTypeId,
              actionId,
            },
            {
              draft: null,
            },
          );

          getCreateDraftIdsForEntityTypeQuery.setData(
            {
              organizationId,
              entityTypeId,
              versionType,
            },
            (oldData) => {
              if (oldData != null) {
                return {
                  draftIds: oldData.draftIds.filter((id) => id.entityId !== entityId || id.actionId !== actionId),
                };
              }

              return undefined;
            },
          );
        } else {
          // In this case we are readding the drafts likely because the action execution failed
          getHasDraftForActionQuery.setData(
            {
              organizationId,
              versionType,
              entityId,
              actionId,
            },
            {
              hasDraft: true,
            },
          );
          getDraftForActionQuery.setData(
            {
              organizationId,
              versionType,
              entityId,
              entityTypeId,
              actionId,
            },
            {
              draft: actionDraftCacheValue.draft,
            },
          );
          if (actionDraftCacheValue.isCreateActionDraft) {
            const { createdAt: draftCreatedAt, entityId: draftEntityId } = actionDraftCacheValue.draft;

            getCreateDraftIdsForEntityTypeQuery.setData(
              {
                organizationId,
                entityTypeId,
                versionType,
              },
              (oldData) => {
                if (oldData != null) {
                  return {
                    draftIds: oldData.draftIds.concat([
                      {
                        createdAt: draftCreatedAt,
                        entityId: draftEntityId,
                        actionId,
                      },
                    ]),
                  };
                }

                return undefined;
              },
            );
          }
        }
      }

      if (entityToSet != null && actionToState != null) {
        const currentPreviews = previewEntitiesQuery.getData({ entityTypeId, organizationId, versionType });

        if (currentPreviews != null) {
          void previewEntitiesQuery.cancel();
          previewEntitiesQuery.setData(
            { entityTypeId, organizationId, versionType },
            {
              ...currentPreviews,
              stateIdByEntityId: {
                ...currentPreviews.stateIdByEntityId,
                [entityId]: actionToState,
              },
            },
          );
        }
      }

      // Cancelling queries that are in flight so that the return does not override the optimistic update
      // Might be better to cancel all to work with paging
      void getLoadedEntitiesQuery.cancel({
        organizationId,
        dataLoadingQuery: {
          versionType,
          entityType: {
            type: "entityTypeId",
            entityTypeId,
          },
          filters: undefined,
        },
        dataLoadingConfig: {
          specificRelations: null,
          specificColumns: null,
        },
      });
      getLoadedEntitiesQuery.setInfiniteData(
        {
          organizationId,
          dataLoadingQuery: {
            versionType,
            entityType: {
              type: "entityTypeId",
              entityTypeId,
            },
            filters: undefined,
          },
          dataLoadingConfig: {
            specificRelations: null,
            specificColumns: null,
          },
        },
        (oldPagedData) => ({
          pageParams: oldPagedData == null ? [] : oldPagedData.pageParams,
          pages:
            oldPagedData == null
              ? []
              : oldPagedData.pages
                  .map((oldData, pageIndex) => {
                    if (
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
                      oldData == null ||
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
                      oldData.entities == null ||
                      oldData.entities[0]?.entityTypeId !== entityTypeId
                    ) {
                      return oldData;
                    }

                    // If we are adding a new entity, we update the total number of entities for all pages
                    // but only add the new entity to the first page.
                    if (isNew === true && entityToSet != null) {
                      if (pageIndex === 0) {
                        return {
                          ...oldData,
                          totalNumberEntities: oldData.totalNumberEntities + 1,
                          entities: oldData.entities.concat([entityToSet]),
                        };
                      }

                      return {
                        ...oldData,
                        totalNumberEntities: oldData.totalNumberEntities + 1,
                      };
                    }

                    return {
                      ...oldData,
                      // Reducing the total if we are deleting an entity
                      totalNumberEntities:
                        entityToSet == null ? oldData.totalNumberEntities - 1 : oldData.totalNumberEntities,
                      entities:
                        entityToSet != null
                          ? oldData.entities.map((entity) => (entity.entityId === entityId ? entityToSet : entity))
                          : oldData.entities.filter((entity) => entity.entityId !== entityId),
                    };
                  })
                  .filter(isNonNullable),
        }),
      );

      getLoadedEntitiesQuery.setQueriesData(
        {
          organizationId,
          dataLoadingQuery: {
            versionType,
            entityType: {
              type: "entityTypeId",
              entityTypeId,
            },
            filters: undefined,
          },
          dataLoadingConfig: {
            specificRelations: null,
            specificColumns: null,
          },
        },
        {
          exact: false,
          predicate: () => {
            // could filter by queryKey
            return true;
          },
        },
        (oldData) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
          if (oldData == null || oldData.entities == null || oldData.entities[0]?.entityTypeId !== entityTypeId) {
            return oldData;
          }

          if (isNew === true && entityToSet != null) {
            if (oldData.nextCursor == null) {
              return {
                ...oldData,
                totalNumberEntities: oldData.totalNumberEntities + 1,
                entities: [...oldData.entities, entityToSet],
              };
            }

            return {
              ...oldData,
              totalNumberEntities: oldData.totalNumberEntities + 1,
            };
          }

          return {
            ...oldData,
            entities:
              entityToSet != null
                ? oldData.entities.map((entity) => (entity.entityId === entityId ? entityToSet : entity))
                : oldData.entities.filter((entity) => entity.entityId !== entityId),
          };
        },
      );

      if (entityToSet != null) {
        void getLoadedEntityQuery.cancel({
          organizationId,
          versionType,
          entityId,
          entityTypeId,
        });
        getLoadedEntityQuery.setData(
          {
            organizationId,
            versionType,
            entityId,
            entityTypeId,
          },
          {
            entity: entityToSet,
          },
        );
      }

      return;
    },
    [
      getLoadedEntitiesQuery,
      getLoadedEntityQuery,
      previewEntitiesQuery,
      getCreateDraftIdsForEntityTypeQuery,
      getDraftForActionQuery,
      getHasDraftForActionQuery,
    ],
  );

  const invalidatePostAction = useCallback(
    async ({
      entityId,
      entityTypeId,
      versionType,
      organizationId,
      actionId,
      isCreateActionExecution,
    }: IOnActionInvalidateArgs): Promise<void> => {
      await Promise.all([
        getLoadedEntitiesQuery.invalidate(),
        getBatchEntityCountQuery.invalidate({
          organizationId,
          entityTypeReference: { type: "entityTypeId", entityTypeId },
          versionType,
        }),
        getLoadedEntityQuery.invalidate({ entityId, entityTypeId, versionType }),
        getHasDraftForActionQuery.invalidate({ organizationId, versionType, entityId, actionId }),
        getDraftForActionQuery.invalidate({ organizationId, versionType, entityId, entityTypeId, actionId }),
        isCreateActionExecution
          ? getCreateDraftIdsForEntityTypeQuery.invalidate({ organizationId, entityTypeId, versionType })
          : undefined,
        actionLogQuery.invalidate({ entityId, versionType }),
        previewEntitiesQuery.invalidate(),
        getAccessToActionQuery.invalidate({ versionType, entityId }),
        getAccessToActionsByEntityTypeIdQuery.invalidate({ versionType, entityTypeId, entityId }),
        getValidRelationFieldsForEntityQuery.invalidate({ versionType, entityTypeId, entityId }),
      ]);
    },
    [
      getLoadedEntitiesQuery,
      getBatchEntityCountQuery,
      getLoadedEntityQuery,
      getHasDraftForActionQuery,
      getDraftForActionQuery,
      getCreateDraftIdsForEntityTypeQuery,
      actionLogQuery,
      previewEntitiesQuery,
      getAccessToActionQuery,
      getAccessToActionsByEntityTypeIdQuery,
      getValidRelationFieldsForEntityQuery,
    ],
  );

  const handleActionExecutionError = useCallback(
    ({
      executeActionQuery,
      invalidationParams,
      errors,
      successReplacementNestedEntityIds,
      previousActionDraftCacheValue,
      previousLoadedEntity,
      savingStateArgs,
      savingOperationId,
      getActionRouteRedirectOnError,
    }: {
      executeActionQuery: IExecuteActionQuery;
      invalidationParams: IInvalidationParams;
      previousActionDraftCacheValue:
        | {
            draft: IEntityActionDraft | undefined;
            isCreateActionDraft: boolean;
          }
        | undefined;
      errors: IValidationError[];
      successReplacementNestedEntityIds: IReplacementNestedEntityIds;
      previousLoadedEntity: ILoadedEntity | undefined;
      savingStateArgs: ISavingStateArgs | undefined;
      savingOperationId: string;
      getActionRouteRedirectOnError: IGetActionRoute | undefined;
    }) => {
      const rollbackEntity = makeEntityToRollback({
        actionInfo: invalidationParams.actionInfo,
        executeActionQuery: executeActionQuery,
        previousLoadedEntity,
      });

      updateCaches({
        organizationId: invalidationParams.organizationId,
        actionToState: invalidationParams.actionToState,
        versionType: executeActionQuery.versionType,
        valueForCache: rollbackEntity,
        actionId: invalidationParams.actionInfo.id,
        actionDraftCacheValue: previousActionDraftCacheValue,
      });

      if (savingStateArgs != null) {
        setAsError({
          savingStateArgs,
          operationId: savingOperationId,
          errors,
        });
      }

      // Invalidate as the BE error could be due to non-reloaded validation groups
      void getValidationGroupsByEntityTypeQuery.invalidate({
        versionType: executeActionQuery.versionType,
        entityTypeId: invalidationParams.actionInfo.entityTypeId,
      });

      const entityAndCreateActionDraft: IEntityIdOrCreateActionDraft =
        executeActionQuery.entityId == null
          ? {
              entityId: undefined,
              isCreateActionDraft: false,
            }
          : { entityId: executeActionQuery.entityId, isCreateActionDraft: invalidationParams.isCreateActionDraft };

      const fieldValuesCorrectionResult =
        executeActionQuery.fieldValues == null
          ? undefined
          : replaceFormAndNestedValuesWithRealEntityIds({
              replacementNestedEntityIds: successReplacementNestedEntityIds,
              fieldValues: executeActionQuery.fieldValues,
              nestedCreateFormValues: {}, // No more nested forms at this point
            });

      const reopenActionRoute = getActionRouteRedirectOnError?.({
        entityTypeId: invalidationParams.actionInfo.entityTypeId,
        actionId: invalidationParams.actionInfo.id,
        defaultValues: fieldValuesCorrectionResult?.fieldValues, // Use the previously used field values, corrected for the successful nested actions
        ...entityAndCreateActionDraft,
      });

      const toastAction = reopenActionRoute != null ? { label: "Retry", route: reopenActionRoute } : undefined;

      toast({
        title: "Error saving action",
        description: "Validation errors",
        action: toastAction,
        duration: 30000,
        variant: "error",
      });
    },
    [toast, updateCaches, setAsError, getValidationGroupsByEntityTypeQuery],
  );

  // Action execution handlers ——————————————————————————————————————————————————————————————————————————————————

  const wrappedExecuteActionTrpc = useCallback(
    async ({
      executeActionQuery,
      invalidationParams,
      getActionRouteRedirectOnError,
      savingStateArgs,
      previousLoadedEntity,
      savingOperationId,
      previousActionDraftCacheValue,
    }: Omit<IExecuteActionArgs, "executeActionQuery"> & {
      executeActionQuery: IExecuteActionQuery;
      savingStateArgs: ISavingStateArgs | undefined;
      previousLoadedEntity: ILoadedEntity | undefined;
      previousActionDraftCacheValue:
        | {
            draft: IEntityActionDraft | undefined;
            isCreateActionDraft: boolean;
          }
        | undefined;
      savingOperationId: string;
    }): Promise<IExecuteActionResponse> => {
      const queryRes = await executeActionTrpc(executeActionQuery);
      const res = queryRes.result;

      if (res.success) {
        // Should only invalidate if there are no saving in progress for the entity
        void invalidatePostAction({
          organizationId: invalidationParams.organizationId,
          versionType: executeActionQuery.versionType,
          entityId: res.entityId,
          entityTypeId: res.entityTypeId,
          actionId: invalidationParams.actionInfo.id,
          isCreateActionExecution: invalidationParams.actionInfo.actionDefinition.actionType === "add",
        });

        if (savingStateArgs != null) {
          setAsSaved(savingStateArgs);
        }
      } else {
        handleActionExecutionError({
          executeActionQuery: executeActionQuery,
          invalidationParams,
          errors: res.errors,
          successReplacementNestedEntityIds: res.successReplacementNestedEntityIds,
          previousLoadedEntity,
          savingStateArgs,
          savingOperationId,
          getActionRouteRedirectOnError,
          previousActionDraftCacheValue,
        });
      }

      return res;
    },
    [executeActionTrpc, invalidatePostAction, setAsSaved, handleActionExecutionError],
  );

  const executeAction = useCallback(
    async ({
      executeActionQuery: loadedExecuteActionQuery,
      invalidationParams,
      getActionRouteRedirectOnError,
    }: IExecuteActionArgs): Promise<IExecuteActionResponse> => {
      const executeActionQuery = loadedExecuteActionQueryToQuery(loadedExecuteActionQuery);

      const previousLoadedEntity =
        invalidationParams.actionInfo.actionDefinition.actionType === "add" || executeActionQuery.entityId == null
          ? undefined
          : getCachedEntity({
              organizationId: invalidationParams.organizationId,
              entityId: executeActionQuery.entityId,
              entityTypeId: invalidationParams.actionInfo.entityTypeId,
              versionType: executeActionQuery.versionType,
            });

      const previousActionDraftCacheValueQueryRes =
        executeActionQuery.entityId == null
          ? undefined
          : getDraftForActionQuery.getData({
              organizationId: invalidationParams.organizationId,
              actionId: invalidationParams.actionInfo.id,
              entityTypeId: invalidationParams.actionInfo.entityTypeId,
              entityId: executeActionQuery.entityId,
              versionType: executeActionQuery.versionType,
            });

      const previousActionDraftCacheValue =
        previousActionDraftCacheValueQueryRes?.draft == null
          ? undefined
          : {
              draft: previousActionDraftCacheValueQueryRes.draft,
              isCreateActionDraft: invalidationParams.actionInfo.actionDefinition.actionType === "add",
            };

      const entityTypeQueryRes = fullyLoadedEntityTypeQuery.getData({
        id: invalidationParams.actionInfo.entityTypeId,
        versionType: executeActionQuery.versionType,
      });

      const optimisticExecutionResult = makeEntityToSet({
        actionInfo: invalidationParams.actionInfo,
        entityType: entityTypeQueryRes?.entityType,
        previousLoadedEntity,
        loadedExecuteActionQuery,
        validationGroups: invalidationParams.validationGroups,
        currentUserInfo: currentUserInfoQuery?.currentUserInfo,
        maybeExternalUserCreateProps: invalidationParams.maybeExternalUserCreateProps,
      });

      if (optimisticExecutionResult?.actionExecutionResult.success === false) {
        return {
          success: false,
          errors: optimisticExecutionResult.actionExecutionResult.errors,
          nestedActionErrors: optimisticExecutionResult.actionExecutionResult.nestedActionErrors,
          successReplacementNestedEntityIds: {}, // nothing executed yet
        };
      }

      updateCaches({
        organizationId: invalidationParams.organizationId,
        actionId: invalidationParams.actionInfo.id,
        actionToState: invalidationParams.actionToState,
        versionType: executeActionQuery.versionType,
        valueForCache:
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- last type condition
          optimisticExecutionResult && optimisticExecutionResult.actionExecutionResult.success
            ? {
                isNew: optimisticExecutionResult.isNewForCache,
                entityId: optimisticExecutionResult.actionExecutionResult.entityId,
                entityTypeId: optimisticExecutionResult.actionExecutionResult.entityTypeId,
                entity: optimisticExecutionResult.actionExecutionResult.entity ?? undefined,
              }
            : undefined,
        actionDraftCacheValue: {
          draft: undefined,
          isCreateActionDraft: invalidationParams.actionInfo.actionDefinition.actionType === "add",
        },
      });

      const savingViewFieldIds: IViewFieldId[] = keys(optimisticExecutionResult?.editedFieldValues ?? {});
      const savingStateArgs: ISavingStateArgs | undefined =
        executeActionQuery.entityId != null
          ? {
              versionType: executeActionQuery.versionType,
              entityTypeId: invalidationParams.actionInfo.entityTypeId,
              entityId: executeActionQuery.entityId,
              viewFieldIds: savingViewFieldIds,
            }
          : undefined;
      const savingOperationId = v4();

      try {
        const optimisticActionRes = optimisticExecutionResult?.actionExecutionResult;
        let res: IExecuteActionResponse;

        if (savingStateArgs != null) {
          setAsSaving(savingStateArgs);
        }

        if (optimisticActionRes == null || invalidationParams.shouldWaitForAsyncExecution) {
          res = await wrappedExecuteActionTrpc({
            executeActionQuery,
            invalidationParams,
            getActionRouteRedirectOnError,
            savingStateArgs,
            previousLoadedEntity,
            savingOperationId,
            previousActionDraftCacheValue,
          });
        } else {
          res = optimisticActionRes;
          void wrappedExecuteActionTrpc({
            executeActionQuery,
            invalidationParams,
            getActionRouteRedirectOnError,
            savingStateArgs,
            previousLoadedEntity,
            savingOperationId,
            previousActionDraftCacheValue,
          });
        }

        return res;
      } catch (e) {
        logger.error({ error: e }, "Error executing action");

        handleActionExecutionError({
          executeActionQuery: executeActionQuery,
          invalidationParams,
          errors: [{ error: "Error saving action" }],
          successReplacementNestedEntityIds: {}, // could be something executed but we dont know from that thrown error
          previousLoadedEntity,
          savingStateArgs,
          savingOperationId,
          getActionRouteRedirectOnError,
          previousActionDraftCacheValue,
        });

        return {
          success: false,
          errors: [{ error: "Error saving action" }],
          nestedActionErrors: {},
          successReplacementNestedEntityIds: {}, // could be something executed but we dont know from that thrown error
        };
      }
    },
    [
      updateCaches,
      getCachedEntity,
      handleActionExecutionError,
      setAsSaving,
      currentUserInfoQuery?.currentUserInfo,
      wrappedExecuteActionTrpc,
      fullyLoadedEntityTypeQuery,
      getDraftForActionQuery,
    ],
  );

  return (
    <ActionExecutionContext.Provider
      value={{
        savingState,
        executeAction,
        setAsSaving,
        setAsSaved,
        setAsError,
        clearError,
      }}
    >
      {children}
    </ActionExecutionContext.Provider>
  );
};

export const useInvalidatingActionExecution = (): {
  executeAction: (args: IExecuteActionArgs) => Promise<IExecuteActionResponse>;
  clearError: (args: IClearErrorArgs) => void;
  savingState: IActionExecutionSavingState;
} => {
  const { savingState, executeAction, clearError } = useContext(ActionExecutionContext);

  return {
    executeAction,
    clearError,
    savingState,
  };
};
