import type { ILoadedEntity } from "@archetype/core";
import type { IEntityId } from "@archetype/ids";
import { match } from "ts-pattern";

/**
 * For a create action, there will be an entityId for the draft, but it will not be loaded as an actual entity.
 */
export type IActionEntityReference =
  | {
      type: "modifyingEntity";
      entity: ILoadedEntity;
    }
  | {
      type: "createDraftEntity";
      entityId: IEntityId;
    };

export const getActionReferenceEntityId = (reference: IActionEntityReference | undefined): IEntityId | undefined => {
  if (reference == null) {
    return undefined;
  }

  return match(reference)
    .returnType<IEntityId | undefined>()
    .with({ type: "modifyingEntity" }, (ref) => ref.entity.entityId)
    .with({ type: "createDraftEntity" }, (ref) => ref.entityId)
    .exhaustive();
};

export const getActionReferenceEntity = (reference: IActionEntityReference | undefined): ILoadedEntity | undefined => {
  if (reference == null) {
    return undefined;
  }

  return match(reference)
    .returnType<ILoadedEntity | undefined>()
    .with({ type: "modifyingEntity" }, (ref) => ref.entity)
    .with({ type: "createDraftEntity" }, () => undefined)
    .exhaustive();
};
