import { z } from "zod";

export const Version = z.object({
  major: z.number(),
  minor: z.number(),
  patch: z.number(),
});

export const VersionType = z.enum(["prod", "dev", "preview"]);

export type IVersion = z.infer<typeof Version>;
export type IVersionType = z.infer<typeof VersionType>;

export const FAKE_DATA_VERSION_TYPE = "dev" satisfies IVersionType;
export const REAL_DATA_VERSION_TYPE = "prod" satisfies IVersionType;
