import { ReadableString } from "@archetype/utils";
import { z } from "zod";

export const DisplayMetadata = z.object({
  name: ReadableString,
  description: z.optional(ReadableString),
});

/**
 * Can be used for e.g. component definitions that are defined in code anyway so not error prone
 */
export const UncheckedDisplayMetadata = z.object({
  name: z.string(),
  description: z.optional(z.string()),
});

export type IDisplayMetadata = z.infer<typeof DisplayMetadata>;
export type IUncheckedDisplayMetadata = z.infer<typeof UncheckedDisplayMetadata>;
