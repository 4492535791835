import type { IActionCore, IEntityTypeCore, IRelationCore } from "@archetype/dsl";
import {
  computeRelationViewFieldId,
  type IActionInput,
  type IEmailSideEffect,
  type ILLMAction,
  type ILLMActionInput,
} from "@archetype/dsl";
import type { IRelationId, IViewFieldId } from "@archetype/ids";
import { isNonNullable } from "@archetype/utils";

import { generateLoadedViewFieldsMapForEntityType } from "../adapters/generateViewFieldMaps";
import type { ILoadedViewField } from "../apiTypes/LoadedViewField";
import { createFileLogger } from "../logger";

const logger = createFileLogger("convertActionToLLMAction");

export function convertActionInputToLLMActionInput({
  input,
  viewFieldsById,
}: {
  input: IActionInput;
  viewFieldsById: Record<IViewFieldId, ILoadedViewField>;
}): ILLMActionInput | undefined {
  const inputViewField = viewFieldsById[input.viewField.id];

  if (inputViewField == null) {
    logger.error(`View field ${input.viewField.id} not found`);

    return;
  }

  return {
    fieldName: inputViewField.displayName,
    allowChangingDefault: input.allowChangingDefault,
    isOptional: !input.required,
  };
}

export function convertEmailSideEffectToLLMEmailConfig({
  emailSideEffect,
  viewFieldsById,
}: {
  emailSideEffect: IEmailSideEffect | undefined;
  viewFieldsById: Record<IViewFieldId, ILoadedViewField>;
}): ILLMAction["emailConfig"] | null {
  if (emailSideEffect == null || !emailSideEffect.isEnabled) {
    return null;
  }

  const toPerson = emailSideEffect.toPersonRelation;

  if (toPerson == null) {
    // TODO: support user entity as defined in toSelfIfUserEntity as well
    return null;
  }

  const toPersonRelationField = viewFieldsById[computeRelationViewFieldId(toPerson.relationId, toPerson.direction)];

  if (toPersonRelationField == null) {
    throw new Error(`Relation field ${toPerson.relationId} not found`);
  }

  return {
    isEnabled: emailSideEffect.isEnabled,
    toPersonRelationName: toPersonRelationField.displayName,
    fieldNamesToSend: emailSideEffect.viewFieldsToSend
      .map((f) => viewFieldsById[f.id]?.displayName)
      .filter(isNonNullable),
  };
}

export function convertActionToLLMAction({
  action,
  entityType,
  relationsById,
}: {
  action: IActionCore;
  entityType: IEntityTypeCore;
  relationsById: Record<IRelationId, IRelationCore>;
}): ILLMAction {
  const viewFieldsById = generateLoadedViewFieldsMapForEntityType(entityType, relationsById);

  const emailConfig = convertEmailSideEffectToLLMEmailConfig({
    emailSideEffect: action.actionDefinition.sideEffects?.email,
    viewFieldsById,
  });

  return {
    actionId: action.id,
    name: action.displayMetadata.name,
    description: action.displayMetadata.description ?? null,
    actionType: action.actionDefinition.actionType,
    inputs: action.actionDefinition.inputs
      .map((i) => convertActionInputToLLMActionInput({ input: i, viewFieldsById }))
      .filter(isNonNullable),
    contextualFieldNames: action.actionDefinition.contextualFields
      .map((f) => viewFieldsById[f.id]?.displayName)
      .filter(isNonNullable),
    emailConfig: emailConfig ?? undefined,
  };
}
