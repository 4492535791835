import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { EntityListComponentDefinitionId } from "@archetype/ids";

export const EntityListComponentIdDeclarations = {
  id: EntityListComponentDefinitionId,
  inputs: ["multiEntityQueryDataInput"],
  outputs: ["userSelectedSingleEntity"],
  compatibleSemanticSizes: ["sidebar", "main", "split-horizontal"],
  validCosmeticOptionalProps: ["label"],
} as const;

export const EntityListComponentDefinition: ITypedComponentDefinitionDeclaration<
  typeof EntityListComponentIdDeclarations
> = {
  constIds: EntityListComponentIdDeclarations,
  displayMetadata: {
    name: "Entity list",
    description: "A list that can display a list of entities",
  },
  inputs: {
    multiEntityQueryDataInput: {
      displayMetadata: {
        name: "Entity data",
        description: "Data populate the entity list with",
      },
      optional: false,
    },
  },
  outputs: {
    userSelectedSingleEntity: {
      displayMetadata: {
        name: "Selected data",
        description: "The selected item from the list",
      },
      optional: false,
      nullable: true,
    },
  },
};
