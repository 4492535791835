import { FeatureTransactionId } from "@archetype/ids";
import { z } from "zod";

import { ViewFeature } from "./StructuredViewFeature";

// TODO: hierarchy may be weird if only between structured features, TODO for requirements
export const FeatureTransaction = z.object({
  id: FeatureTransactionId,
  input: z.string().describe("The raw text of the requirement, that corresponds to one or more features"),
  output: z.object({
    features: z.array(ViewFeature),
    summary: z.string(),
    response: z.string(),
  }),
});

export type IFeatureTransaction = z.infer<typeof FeatureTransaction>;
