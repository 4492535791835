import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { ListComponentDefinitionId } from "@archetype/ids";

export const ListComponentIdDeclarations = {
  id: ListComponentDefinitionId,
  inputs: ["multiEntityQueryDataInput"],
  outputs: ["userSelectedSingleEntity", "userSelectedLinkedEntity", "actionSelected"],
  compatibleSemanticSizes: ["main", "medium", "split-horizontal", "split-vertical"],
  validCosmeticOptionalProps: ["label"],
} as const;

export const ListComponentDefinition: ITypedComponentDefinitionDeclaration<typeof ListComponentIdDeclarations> = {
  constIds: ListComponentIdDeclarations,
  displayMetadata: {
    name: "List",
    description: "A list component",
  },
  inputs: {
    multiEntityQueryDataInput: {
      displayMetadata: {
        name: "List data",
        description: "Data to populate the list with",
      },
      optional: false,
    },
  },
  outputs: {
    userSelectedSingleEntity: {
      displayMetadata: {
        name: "List selection",
        description: "The selected item in the list",
      },
      optional: false,
      nullable: true,
    },
    userSelectedLinkedEntity: {
      displayMetadata: {
        name: "List linked entity selection",
        description: "The selected linked item in the list",
      },
      optional: false,
      nullable: true,
    },
    actionSelected: {
      displayMetadata: {
        name: "Action callback",
        description: "The callback to show the action",
      },
      optional: false,
    },
  },
};
