import type { IEntityTypeCore } from "@archetype/dsl";
import type { IColumnId, IEntityTypeId } from "@archetype/ids";
import { forEach } from "@archetype/utils";

import { optimisticReadableIdentifierFromString } from "../..";

export interface IColumnsOptimisticRecord<ValueType> {
  map: Record<string, Record<string, ValueType>>;
}

/**
 * This should not be used as a function argument, especially if it might be used in temporal activities,
 * as the class would not be ser/deser correctly.
 *
 * Instead, use the IColumnsOptimisticRecord, and can instantiate the class from the record
 */
export class ColumnsOptimisticRecord<T> implements IColumnsOptimisticRecord<T> {
  map: Record<string, Record<string, T>> = {};

  static fromRecord<U>(record: IColumnsOptimisticRecord<U>): ColumnsOptimisticRecord<U> {
    const columnsMap = new ColumnsOptimisticRecord<U>();

    columnsMap.map = record.map;

    return columnsMap;
  }

  add({
    entityTypeIdentifier,
    columnIdentifier,
    value,
  }: {
    entityTypeIdentifier: string;
    columnIdentifier: string;
    value: T;
  }): void {
    const currIdsMap = this.map[entityTypeIdentifier] ?? {};

    currIdsMap[optimisticReadableIdentifierFromString(columnIdentifier)] = value;
    this.map[entityTypeIdentifier] = currIdsMap;
  }

  get({
    entityTypeIdentifier,
    columnIdentifier,
  }: {
    entityTypeIdentifier: string;
    columnIdentifier: string;
  }): T | undefined {
    return (this.map[entityTypeIdentifier] ?? {})[optimisticReadableIdentifierFromString(columnIdentifier)];
  }

  getOrCreate({
    entityTypeIdentifier,
    columnIdentifier,
    defaultValue,
  }: {
    entityTypeIdentifier: string;
    columnIdentifier: string;
    defaultValue: T;
  }): T {
    let res = this.get({ entityTypeIdentifier, columnIdentifier });

    if (res == null) {
      this.add({ entityTypeIdentifier, columnIdentifier, value: defaultValue });

      res = defaultValue;
    }

    return res;
  }
}

export function makeColumnsByNameOptimisticRec({
  entityTypes,
}: {
  entityTypes: Record<IEntityTypeId, IEntityTypeCore>;
}): ColumnsOptimisticRecord<IColumnId> {
  const columnsMap = new ColumnsOptimisticRecord<IColumnId>();

  forEach(entityTypes, (entityType) => {
    entityType.columns.forEach((column) => {
      columnsMap.add({
        entityTypeIdentifier: entityType.displayMetadata.name,
        columnIdentifier: column.displayMetadata.name,
        value: column.id,
      });
    });
  });

  return columnsMap;
}
