import type {
  IActionCore,
  IAuthorization,
  IColumn,
  IColumnType,
  IEntityTypeCore,
  IRelationCore,
  IRelationDirection,
  IStateMachine,
  IWorkflowStateMachine,
  IWorkflowStateMachineColumnType,
  IWorkspaceEntityTypeWithId,
  IWorkspaceRelation,
  IWorkspaceRelationWithIds,
} from "@archetype/dsl";
import {
  computeColumnViewFieldId,
  computeRelationViewFieldId,
  ENTITY_TYPE_COLOR_NAMES,
  ENTITY_TYPE_SHAPE_NAMES,
} from "@archetype/dsl";
import type {
  IActionId,
  IApplicationGroupId,
  IEntityTypeId,
  IOrganizationId,
  IRelationId,
  IStateId,
  ITransitionId,
} from "@archetype/ids";
import { ActionId, ColumnId, RelationId } from "@archetype/ids";
import type { IReadableString } from "@archetype/utils";
import { humanize, keyByNoUndefined, mapValues } from "@archetype/utils";
import { concat, sample, uniqBy, values } from "lodash";

import { createInternalEmployeeAuthorization } from "../auth/createInternalEmployeeAuthorization";
import { createFileLogger } from "../logger";
import {
  createCreatedAtColumn,
  createCreatedByRelation,
  createPrimaryKeyColumn,
  createStatusColumn,
  createTitleColumn,
} from "./createBaseColumn";

const logger = createFileLogger("createStateMachineFromLLMResponse");

function extractColumnType(columnType: IWorkflowStateMachineColumnType): IColumnType {
  if (columnType.typeName === "enum") {
    return { type: "enum", enumAllowedValues: columnType.enumAllowedValues ?? [], enumInclusiveMaxValuesToSelect: 1 };
  }

  return {
    type: columnType.typeName,
  };
}

function createNewRelation(
  relation: IWorkspaceRelation,
  entityTypeA: IWorkspaceEntityTypeWithId,
  entityTypeB: IWorkspaceEntityTypeWithId,
): IRelationCore {
  return {
    id: RelationId.generate(),
    entityTypeIdA: entityTypeA.id,
    entityTypeIdB: entityTypeB.id,
    config: {
      cardinalityOnSideA: relation.cardinalityOnSideA,
      cardinalityOnSideB: relation.cardinalityOnSideB,
    },
    displayMetadataFromAToB: {
      name: relation.titleForBEntitiesWhenOnA,
    },
    displayMetadataFromBToA: {
      name: relation.titleForAEntitiesWhenOnB,
    },
  };
}

function convertWorkspaceRelationToRelationCore(relation: IWorkspaceRelationWithIds): IRelationCore {
  return {
    id: relation.id,
    entityTypeIdA: relation.entityTypeIdA,
    entityTypeIdB: relation.entityTypeIdB,
    config: {
      cardinalityOnSideA: relation.cardinalityOnSideA,
      cardinalityOnSideB: relation.cardinalityOnSideB,
    },
    displayMetadataFromAToB: {
      name: relation.titleForBEntitiesWhenOnA,
    },
    displayMetadataFromBToA: {
      name: relation.titleForAEntitiesWhenOnB,
    },
  };
}

function generateRelationKey(
  title: string,
  relation: { entityTypeIdA: IEntityTypeId; entityTypeIdB: IEntityTypeId },
): string {
  const key = concat([title], [relation.entityTypeIdA, relation.entityTypeIdB].sort());

  return key.join("-");
}

export function createStateMachineFromLLMResponse({
  stateMachine,
  entityTypeId,
  userEntityTypeId,
  relations,
  entityTypes,
  organizationId,
  applicationGroupId,
}: {
  stateMachine: IWorkflowStateMachine;
  entityTypeId: IEntityTypeId;
  userEntityTypeId: IEntityTypeId;
  relations: IWorkspaceRelationWithIds[];
  entityTypes: IWorkspaceEntityTypeWithId[];
  organizationId: IOrganizationId;
  applicationGroupId: IApplicationGroupId;
}): {
  stateMachineEdits: Record<IApplicationGroupId, IStateMachine>;
  entityTypes: Record<IEntityTypeId, IEntityTypeCore>;
  actions: Record<IActionId, IActionCore>;
  relations: Record<IRelationId, IRelationCore>;
} {
  const statesById = keyByNoUndefined(stateMachine.states, (s) => s.id);

  // make sure all happy path states exist
  stateMachine.happyPathStateIdsByOrder.forEach((stateId) => {
    const state = statesById[stateId];

    if (state == null) {
      throw new Error(`State ${stateId} not found`);
    }
  });

  const targetEntityType = entityTypes.find((e) => e.id === entityTypeId);

  if (targetEntityType == null) {
    throw new Error(`Target entity type ${entityTypeId} not found`);
  }

  const statusColumn = createStatusColumn(stateMachine.states.map((s) => ({ id: s.id, readableValue: s.label })));
  const titleColumn = createTitleColumn(stateMachine.entityType.nameOfTitleColumn);
  const primaryKey = createPrimaryKeyColumn();
  const createdAtColumn = createCreatedAtColumn();

  const otherCreateInputs: IActionCore["actionDefinition"]["inputs"] = [];
  const columnsByName: Record<IReadableString, IColumn> = {
    [titleColumn.displayMetadata.name]: titleColumn,
    [primaryKey.displayMetadata.name]: primaryKey,
    [statusColumn.displayMetadata.name]: statusColumn,
    [createdAtColumn.displayMetadata.name]: createdAtColumn,
  };

  const entityTypesByTitle = keyByNoUndefined(entityTypes, (e) => e.title);

  const relationsAndDirectionByNameAndEntityIds: Record<
    string,
    { relation: IRelationCore; direction: IRelationDirection }
  > = {
    ...mapValues(
      keyByNoUndefined(
        relations.filter((r) => r.entityTypeIdA === entityTypeId),
        (r) => generateRelationKey(r.titleForBEntitiesWhenOnA, r),
      ),
      (r) => ({ relation: convertWorkspaceRelationToRelationCore(r), direction: "aToB" }),
    ),
    ...mapValues(
      keyByNoUndefined(
        relations.filter((r) => r.entityTypeIdB === entityTypeId),
        (r) => generateRelationKey(r.titleForAEntitiesWhenOnB, r),
      ),
      (r) => ({ relation: convertWorkspaceRelationToRelationCore(r), direction: "bToA" }),
    ),
  };

  // check if the createdByRelation exists already, if not - create it
  const createdByRelationKey = generateRelationKey(stateMachine.entityType.nameOfCreatedByRelation, {
    entityTypeIdA: targetEntityType.id,
    entityTypeIdB: userEntityTypeId,
  });

  const createdByRelationAndDirection = relationsAndDirectionByNameAndEntityIds[createdByRelationKey];

  let createdByRelation: IRelationCore;
  let createdByRelationDirection: IRelationDirection;

  if (createdByRelationAndDirection == null) {
    createdByRelation = createCreatedByRelation({
      entityTypeId,
      userEntityTypeId,
      userEntityTypePluralTitle: stateMachine.entityType.pluralTitle,
      nameOfCreatedByRelation: stateMachine.entityType.nameOfCreatedByRelation,
    });
    createdByRelationDirection = "aToB";

    relationsAndDirectionByNameAndEntityIds[createdByRelationKey] = {
      relation: createdByRelation,
      direction: createdByRelationDirection,
    };
  } else {
    createdByRelation = {
      ...createdByRelationAndDirection.relation,
      autofill: {
        direction: createdByRelationAndDirection.direction,
        autofill: {
          type: "snapshot",
          config: { type: "dynamic", value: "currentUser" },
        },
      },
    };

    createdByRelationDirection = createdByRelationAndDirection.direction;
  }

  const llmCreateInputColumns = uniqBy(stateMachine.initialTransition.inputColumns, "label");

  llmCreateInputColumns.forEach((f) => {
    if (f.label === stateMachine.entityType.nameOfTitleColumn || f.label === statusColumn.displayMetadata.name) {
      return;
    }

    let column: IColumn | undefined = columnsByName[f.label];

    if (column == null) {
      column = {
        id: ColumnId.generate(),
        displayMetadata: {
          name: humanize(f.label),
        },
        columnType: extractColumnType(f.columnType),
        nonNullable: false,
        unique: false,
      };

      columnsByName[f.label] = column;
    }

    otherCreateInputs.push({
      required: !f.isOptional,
      viewField: {
        id: computeColumnViewFieldId(column.id),
        type: "column" as const,
        columnId: column.id,
      },
      allowChangingDefault: true,
      defaultValue: undefined,
    });
  });

  stateMachine.initialTransition.inputRelations?.forEach((f) => {
    const title =
      f.entityTypeATitle === targetEntityType.title ? f.titleForBEntitiesWhenOnA : f.titleForAEntitiesWhenOnB;

    const entityTypeA = entityTypesByTitle[f.entityTypeATitle];
    const entityTypeB = entityTypesByTitle[f.entityTypeBTitle];

    if (entityTypeA == null || entityTypeB == null) {
      throw new Error(`Entity type ${f.entityTypeATitle} or ${f.entityTypeBTitle} not found`);
    }

    const relationKey = generateRelationKey(title, { entityTypeIdA: entityTypeA.id, entityTypeIdB: entityTypeB.id });

    if (relationKey === createdByRelationKey) {
      // we already have the createdByRelation, so we don't need to add it again
      return;
    }

    let relationAndDirection = relationsAndDirectionByNameAndEntityIds[relationKey];

    if (relationAndDirection == null) {
      const relation = createNewRelation(f, entityTypeA, entityTypeB);
      const direction = relation.entityTypeIdA === entityTypeId ? "aToB" : "bToA";

      relationAndDirection = { relation, direction };
      relationsAndDirectionByNameAndEntityIds[relationKey] = relationAndDirection;
    }

    otherCreateInputs.push({
      required: !f.isOptional,
      viewField: {
        id: computeRelationViewFieldId(relationAndDirection.relation.id, relationAndDirection.direction),
        type: "directionalRelation" as const,
        relationId: relationAndDirection.relation.id,
        direction: relationAndDirection.direction,
      },
      allowChangingDefault: true,
      defaultValue: undefined,
    });
  });

  const hardCodedCreateInputs: IActionCore["actionDefinition"]["inputs"] = [
    {
      required: true,
      viewField: {
        id: computeColumnViewFieldId(statusColumn.id),
        type: "column" as const,
        columnId: statusColumn.id,
      },
      allowChangingDefault: false,
      defaultValue: {
        type: "static",
        value: {
          type: "string",
          value: stateMachine.initialTransition.toStateId,
        },
      },
    },
    {
      required: true,
      viewField: {
        id: computeColumnViewFieldId(primaryKey.id),
        type: "column" as const,
        columnId: primaryKey.id,
      },
      allowChangingDefault: false,
      defaultValue: undefined,
    },
    {
      required: true,
      viewField: {
        id: computeColumnViewFieldId(titleColumn.id),
        type: "column" as const,
        columnId: titleColumn.id,
      },
      allowChangingDefault: true,
      defaultValue: undefined,
    },
    {
      required: true,
      viewField: {
        id: computeColumnViewFieldId(createdAtColumn.id),
        type: "column" as const,
        columnId: createdAtColumn.id,
      },
      allowChangingDefault: false,
      defaultValue: undefined,
    },
    {
      required: true,
      viewField: {
        id: computeRelationViewFieldId(createdByRelation.id, "aToB"),
        type: "directionalRelation" as const,
        relationId: createdByRelation.id,
        direction: "aToB",
      },
      allowChangingDefault: false,
      defaultValue: undefined,
    },
  ];

  const employeePermissionFilter: IAuthorization = createInternalEmployeeAuthorization({ userEntityTypeId });

  const createAction: IActionCore = {
    id: ActionId.generate(),
    displayMetadata: {
      name: humanize(stateMachine.initialTransition.label),
    },
    entityTypeId: entityTypeId,
    organizationId,
    applicationGroupId,
    actionDefinition: {
      actionType: "add",
      fromStates: [],
      toState: stateMachine.initialTransition.toStateId,
      inputs: concat(hardCodedCreateInputs, otherCreateInputs),
      contextualFields: [],
      authorizedByAnyOf: [employeePermissionFilter],
      authorizedForAnyoneWithLink: stateMachine.initialTransition.authorization === "public",
    },
  };

  const deleteAction: IActionCore = {
    id: ActionId.generate(),
    displayMetadata: {
      name: "Delete" as IReadableString,
    },
    entityTypeId: entityTypeId,
    organizationId,
    applicationGroupId,
    actionDefinition: {
      actionType: "delete",
      inputs: [],
      contextualFields: [],
      authorizedByAnyOf: [],
      authorizedForAnyoneWithLink: false,
    },
  };

  const otherActions: IActionCore[] = [];
  const transitionsWithActionIds: { id: ITransitionId; from: IStateId; to: IStateId; actionId: IActionId }[] = [];

  stateMachine.transitions.forEach((t) => {
    const inputs: IActionCore["actionDefinition"]["inputs"] = [
      {
        required: true,
        viewField: {
          id: computeColumnViewFieldId(statusColumn.id),
          type: "column" as const,
          columnId: statusColumn.id,
        },
        allowChangingDefault: false,
        defaultValue: {
          type: "static",
          value: {
            type: "string",
            value: t.toStateId,
          },
        },
      },
    ];
    const contextualFields: IActionCore["actionDefinition"]["contextualFields"] = [];

    t.inputColumns?.forEach((f) => {
      if (f.label === stateMachine.entityType.nameOfTitleColumn || f.label === statusColumn.displayMetadata.name) {
        return;
      }

      let column: IColumn | undefined = columnsByName[f.label];

      if (column == null) {
        column = {
          id: ColumnId.generate(),
          displayMetadata: {
            name: humanize(f.label),
          },
          columnType: extractColumnType(f.columnType),
          nonNullable: false,
          unique: false,
        };

        columnsByName[f.label] = column;
      }

      inputs.push({
        required: !f.isOptional,
        viewField: {
          id: computeColumnViewFieldId(column.id),
          type: "column" as const,
          columnId: column.id,
        },
        allowChangingDefault: true,
        defaultValue: undefined,
      });
    });

    t.inputRelations?.forEach((f) => {
      const title =
        f.entityTypeATitle === targetEntityType.title ? f.titleForBEntitiesWhenOnA : f.titleForAEntitiesWhenOnB;

      const entityTypeA = entityTypesByTitle[f.entityTypeATitle];
      const entityTypeB = entityTypesByTitle[f.entityTypeBTitle];

      if (entityTypeA == null || entityTypeB == null) {
        throw new Error(`Entity type ${f.entityTypeATitle} or ${f.entityTypeBTitle} not found`);
      }

      const relationKey = generateRelationKey(title, { entityTypeIdA: entityTypeA.id, entityTypeIdB: entityTypeB.id });

      let relationAndDirection = relationsAndDirectionByNameAndEntityIds[relationKey];

      if (relationAndDirection == null) {
        const relation = createNewRelation(f, entityTypeA, entityTypeB);
        const direction = relation.entityTypeIdA === entityTypeId ? "aToB" : "bToA";

        relationAndDirection = { relation, direction };
        relationsAndDirectionByNameAndEntityIds[relationKey] = relationAndDirection;
      }

      inputs.push({
        required: !f.isOptional,
        viewField: {
          id: computeRelationViewFieldId(relationAndDirection.relation.id, relationAndDirection.direction),
          type: "directionalRelation" as const,
          relationId: relationAndDirection.relation.id,
          direction: relationAndDirection.direction,
        },
        allowChangingDefault: true,
        defaultValue: undefined,
      });
    });

    // TODO: we need to process context fields in a later loop becaues we need the fields from the inputs first
    t.contextColumns?.forEach((f) => {
      if (f.label === stateMachine.entityType.nameOfTitleColumn || f.label === statusColumn.displayMetadata.name) {
        return;
      }

      const column: IColumn | undefined = columnsByName[f.label];

      if (column == null) {
        logger.error(`Column ${f.label} not found`);

        return;
      }

      contextualFields.push({
        id: computeColumnViewFieldId(column.id),
        type: "column" as const,
        columnId: column.id,
      });
    });

    t.contextRelations?.forEach((f) => {
      const title = targetEntityType.title;

      const entityTypeA = entityTypesByTitle[f.entityTypeATitle];
      const entityTypeB = entityTypesByTitle[f.entityTypeBTitle];

      if (entityTypeA == null || entityTypeB == null) {
        throw new Error(`Entity type ${f.entityTypeATitle} or ${f.entityTypeBTitle} not found`);
      }

      const relationKey = generateRelationKey(title, { entityTypeIdA: entityTypeA.id, entityTypeIdB: entityTypeB.id });
      const relationAndDirection = relationsAndDirectionByNameAndEntityIds[relationKey];

      if (relationAndDirection == null) {
        logger.error(`Relation ${f.label} not found`);

        return;
      }

      contextualFields.push({
        id: computeRelationViewFieldId(relationAndDirection.relation.id, relationAndDirection.direction),
        type: "directionalRelation" as const,
        relationId: relationAndDirection.relation.id,
        direction: relationAndDirection.direction,
      });
    });

    const action: IActionCore = {
      id: ActionId.generate(),
      displayMetadata: {
        name: humanize(t.label),
      },
      entityTypeId: entityTypeId,
      organizationId,
      applicationGroupId,
      actionDefinition: {
        actionType: "modify",
        fromStates: [t.fromStateId],
        toState: t.toStateId,
        inputs,
        contextualFields,
        authorizedByAnyOf: [employeePermissionFilter],
        authorizedForAnyoneWithLink: false,
      },
    };

    otherActions.push(action);
    transitionsWithActionIds.push({ id: t.id, from: t.fromStateId, to: t.toStateId, actionId: action.id });
  });
  const actions: IActionCore[] = concat([createAction, deleteAction], otherActions);

  const entityType: IEntityTypeCore = {
    id: entityTypeId,
    displayMetadata: {
      name: humanize(stateMachine.entityType.title),
      pluralName: humanize(stateMachine.entityType.pluralTitle),
      description: stateMachine.entityType.description,
    },

    primaryKey: primaryKey.id,
    displayNameColumn: titleColumn.id,
    statusColumn: statusColumn.id,

    shape: sample(ENTITY_TYPE_SHAPE_NAMES) ?? "circle",
    color: sample(ENTITY_TYPE_COLOR_NAMES) ?? "lilac",

    columns: values(columnsByName),
    relevantViewFieldsByStateId: {
      [stateMachine.initialTransition.toStateId]: createAction.actionDefinition.inputs.map((i) => i.viewField),
      ...mapValues(statesById, (state) => {
        const relevantActions = actions.filter((a) => a.actionDefinition.toState === state.id);

        return relevantActions.flatMap((a) => {
          return a.actionDefinition.inputs.map((i) => i.viewField);
        });
      }),
    },
    authorizedByAnyOf: [
      employeePermissionFilter,
      {
        authorizedByRelationPath: {
          path: [
            {
              direction: "aToB",
              relationId: createdByRelation.id,
            },
          ],
        },
      },
    ],
    authorizedByAnyOfPerStateId: {},

    activityLogAuthorizedByAnyOf: [],

    authorizedForAnyoneWithLink: false,
    authorizedForAnyoneWithLinkPerStateId: {},

    organizationId,
    targetEntityTypeApplicationGroupId: applicationGroupId,

    supportActionsInfo: null,
    userEntityTypeInfo: null,

    deleteActionId: deleteAction.id,
  };

  const stateMachineEdits: Record<IApplicationGroupId, IStateMachine> = {
    [applicationGroupId]: {
      states: statesById,
      stateTransitions: transitionsWithActionIds,
      initialState: {
        actionId: createAction.id,
        state: stateMachine.initialTransition.toStateId,
      },
      dataModel: {
        targetEntityTypeId: entityType.id,
      },
      aiAgents: {},
      happyPath: stateMachine.happyPathStateIdsByOrder,
    },
  };

  return {
    stateMachineEdits,
    entityTypes: {
      [entityType.id]: entityType,
    },
    actions: keyByNoUndefined(actions, (a) => a.id),
    relations: {
      ...keyByNoUndefined(
        values(relationsAndDirectionByNameAndEntityIds).map((r) => r.relation),
        (r) => r.id,
      ),
    },
  };
}
