import type { IVersionType } from "@archetype/dsl";
import { EntityId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { useEffect } from "react";

import { useSyncedLocalStorage } from "./useSyncedLocalStorage";

const EXTERNAL_USER_SYNTHETIC_ENTITY_ID_KEY = "externalUserSyntheticEntityId";

/**
 * Syncs the first synthetic external user entity id generated with local storage
 *
 * This guarantees stability of actions of external users within the same session
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- pass through trpc query
export const useStableCurrentUserInfo = ({ versionType }: { versionType: IVersionType }) => {
  const [storedExternalUserSyntheticEntityId, setStoredExternalUserSyntheticEntityId] = useSyncedLocalStorage(
    EXTERNAL_USER_SYNTHETIC_ENTITY_ID_KEY,
    undefined,
  );

  const preExistingExternalUserSyntheticEntityId =
    storedExternalUserSyntheticEntityId != null
      ? (EntityId.safeParse(storedExternalUserSyntheticEntityId).data ?? null)
      : null;

  const currentUserInfoQuery = builderTrpc.users.getCurrentUserInfo.useQuery({
    versionType,
    externalUserSyntheticEntityId: preExistingExternalUserSyntheticEntityId,
  });

  useEffect(() => {
    const externalUserSyntheticEntityId = currentUserInfoQuery.data?.externalUserSyntheticEntityId;

    if (externalUserSyntheticEntityId != null) {
      setStoredExternalUserSyntheticEntityId(externalUserSyntheticEntityId);
    }
  }, [currentUserInfoQuery.data?.externalUserSyntheticEntityId, setStoredExternalUserSyntheticEntityId]);

  return currentUserInfoQuery;
};
