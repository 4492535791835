import type { ILoadedEntity } from "@archetype/core";
import type { IVersionType } from "@archetype/dsl";
import type { IEntityId, IEntityTypeId, IOrganizationId, IViewFieldId } from "@archetype/ids";
import Link from "next/link";
import * as React from "react";
import { useCallback, useEffect, useRef } from "react";

import type { IGetHighlightedViewFieldRoute, IGetLinkedEntityRoute } from "../api";
import type { IFieldRendererField } from "./FieldRenderer";
import { FieldRenderer } from "./FieldRenderer";

interface IFieldsRenderer {
  entityTypeId: IEntityTypeId;
  entityId: IEntityId;
  fields: IFieldRendererField[];
  getLinkedEntityRoute?: IGetLinkedEntityRoute;
  getHighlightedViewFieldRoute?: IGetHighlightedViewFieldRoute;
  highlightedViewFieldId?: IViewFieldId;
  isLoadingContextActions?: boolean;
  organizationId: IOrganizationId;
  versionType: IVersionType;
  entity: ILoadedEntity | undefined;
}

export const FieldsRenderer: React.FC<IFieldsRenderer> = ({
  entityTypeId,
  entityId,
  fields,
  getLinkedEntityRoute,
  getHighlightedViewFieldRoute,
  highlightedViewFieldId,
  isLoadingContextActions = false,
  versionType,
  organizationId,
  entity,
}) => {
  const highlightedFieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoadingContextActions && highlightedViewFieldId && highlightedFieldRef.current) {
      highlightedFieldRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [highlightedViewFieldId, isLoadingContextActions]);

  const renderField = useCallback(
    (field: IFieldRendererField) => {
      const content = (
        <FieldRenderer
          key={field.id}
          entity={entity}
          entityTypeId={entityTypeId}
          field={field}
          fieldRef={field.id === highlightedViewFieldId ? highlightedFieldRef : undefined}
          getLinkedEntityRoute={getLinkedEntityRoute}
          interactive={getHighlightedViewFieldRoute != null}
          isHighlighted={field.id === highlightedViewFieldId}
          organizationId={organizationId}
          versionType={versionType}
        />
      );

      if (getHighlightedViewFieldRoute != null) {
        return (
          <Link
            className="no-underline"
            href={getHighlightedViewFieldRoute({ entityTypeId, entityId, viewFieldId: field.id })}
          >
            {content}
          </Link>
        );
      }

      return content;
    },
    [
      entityTypeId,
      highlightedViewFieldId,
      getLinkedEntityRoute,
      getHighlightedViewFieldRoute,
      organizationId,
      versionType,
      entityId,
      entity,
    ],
  );

  return <div className="grid grow grid-cols-1 gap-4">{fields.map(renderField)}</div>;
};
