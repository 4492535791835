import React, { useCallback, useMemo } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableInstanceContext } from "../../context/DataTableInstanceContext";
import { DataTableSelectionContext } from "../../context/DataTableSelectionContext";
import type { IAbstractDataTableCell, IDataTableCheckboxWithNumberCell, IDataTableCommonCellProps } from "./api";
import { CheckboxWithNumberCell } from "./CheckboxWithNumberCell";

type IRowSelectionCellProps<TRowId extends string, TColId extends string> = IDataTableCommonCellProps<
  TRowId,
  TColId,
  IAbstractDataTableCell<TRowId, TColId>
>;

export function RowSelectionCell<TRowId extends string, TColId extends string>({
  isCellSelected,
  isRowSelected,
  cell,
}: IRowSelectionCellProps<TRowId, TColId>): React.ReactNode {
  const toggleRow = useContextSelector(DataTableSelectionContext, (state) => state.toggleRow);
  const rowIndex = useContextSelector(DataTableInstanceContext, (state) => state.rowIndexMap.get(cell.rowId) ?? 0);

  const handleChange = useCallback(() => {
    toggleRow(rowIndex);
  }, [rowIndex, toggleRow]);

  const checkboxCell: IDataTableCommonCellProps<
    TRowId,
    TColId,
    IDataTableCheckboxWithNumberCell<TRowId, TColId>
  >["cell"] = useMemo(
    () => ({
      type: "checkboxWithNumber",
      rowId: cell.rowId,
      colId: cell.colId,
      value: {
        checked: isRowSelected,
        number: rowIndex + 1,
      },
      onChange: handleChange,
      readOnly: cell.readOnly,
    }),
    [cell.rowId, cell.colId, cell.readOnly, isRowSelected, rowIndex, handleChange],
  );

  // We need to prevent the click from bubbling up to the row
  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <div className="flex size-full items-center justify-center" onClick={handleClick}>
      <CheckboxWithNumberCell
        cell={checkboxCell}
        isCellEditing={false}
        isCellSelected={isCellSelected}
        isRowSelected={isRowSelected}
      />
    </div>
  );
}
