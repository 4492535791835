import { isNonNullable, mapValues, pickBy } from "@archetype/utils";
import { size } from "lodash";

import type { IColumnType } from "../../schemas/dataModel/Column";
import type { ICrossColumnAndConditions } from "../../schemas/dataModel/dataLoading/CrossColumnConditions";
import type {
  IDataLoadingQueryColumnAndFilters,
  IDataLoadingQueryColumnOrFilters,
  IDataLoadingQueryFilterRegex,
  IDataLoadingQueryFilterValue,
} from "../../schemas/dataModel/dataLoading/PerColumnConditions";
import { parseValueByColumnType } from "./columnValues/parseValueByColumnType";
import { stringToArray } from "./stringAndArray";

export const getFilterCount = (filters: ICrossColumnAndConditions | undefined): number => {
  return (
    size(
      pickBy(
        mapValues(filters?.perColumn ?? {}, (columnFilterConfitions) => {
          if (columnFilterConfitions?.type === "or") {
            return columnFilterConfitions.rawOrConditions;
          }

          // ignore if "and"
          return undefined;
        }),
        isNonNullable,
      ),
    ) + (filters?.andedRelatedToFilters?.length ?? 0)
  );
};

export const EMPTY_ARRAY_FILTER_VALUE: IDataLoadingQueryFilterValue = {
  type: "value",
  value: {
    type: "array",
    value: [],
  },
};

export function convertStringToArrayFilterValue(value: string): IDataLoadingQueryFilterValue {
  const arrayItems = stringToArray(value);

  return {
    type: "value",
    value: {
      type: "array",
      value: arrayItems,
    },
  };
}

function convertAnyInArrayToRegex(value: IDataLoadingQueryFilterValue): IDataLoadingQueryFilterRegex | undefined {
  if (value.type === "value" && value.value.type === "array") {
    const pattern = value.value.value.join("|");

    return {
      type: "regex",
      value: pattern,
      flags: "i",
    };
  }

  return undefined;
}

function convertAllInArrayToRegex(value: IDataLoadingQueryFilterValue): IDataLoadingQueryFilterRegex | undefined {
  if (value.type === "value" && value.value.type === "array") {
    const pattern = value.value.value.map((item) => `(?=.*${item})`).join("");

    return {
      type: "regex",
      value: `^${pattern}.*$`,
      flags: "i",
    };
  }

  return undefined;
}

function convertNoneInArrayToRegex(value: IDataLoadingQueryFilterValue): IDataLoadingQueryFilterRegex | undefined {
  if (value.type === "value" && value.value.type === "array") {
    const pattern = value.value.value.map((item) => `(?!.*${item})`).join("");

    return {
      type: "regex",
      value: `^${pattern}.*$`,
      flags: "i",
    };
  }

  return undefined;
}

function convertAllNotInArrayToRegex(value: IDataLoadingQueryFilterValue): IDataLoadingQueryFilterRegex | undefined {
  // Similar to convertNoneInArrayToRegex because Regex is not well-suited for counting the number of matches to enforce conditions like "not all items are present
  return convertNoneInArrayToRegex(value);
}

function combineAndRegexFilters(filters: IDataLoadingQueryFilterRegex[]): IDataLoadingQueryFilterRegex {
  if (filters.length === 1) {
    return {
      type: "regex",
      value: filters[0]?.value ?? "",
      flags: "i",
    };
  }

  // For multiple filters, use positive lookaheads to match patterns in any order
  const combinedPattern = filters.map((filter) => `(?=.*${filter.value})`).join("");

  return {
    type: "regex",
    value: `^${combinedPattern}.*$`,
    flags: "i",
  };
}

function combineOrRegexFilters(filters: IDataLoadingQueryFilterRegex[]): IDataLoadingQueryFilterRegex {
  const combinedPattern = filters.map((filter) => filter.value).join("|");

  return {
    type: "regex",
    value: combinedPattern,
    flags: "i",
  };
}

function convertRegexToArrayFilters(
  regex: IDataLoadingQueryFilterRegex,
  existingArrayItems: string[],
): IDataLoadingQueryFilterValue {
  const arrayItems = regex.value.split("|").concat(existingArrayItems);

  return {
    type: "value",
    value: {
      type: "array",
      value: arrayItems,
    },
  };
}

export const cleanUpValueByColumnType = (
  value: IDataLoadingQueryFilterValue,
  columnType: IColumnType,
): IDataLoadingQueryFilterValue => {
  if (value.type === "value") {
    return {
      type: "value",
      value: parseValueByColumnType(value.value, columnType),
    };
  }

  return value;
};

/**
 * This should ensure that a filter on the id column of an entity remains an equal as the config component relies on it
 */
export const cleanUpOrFiltersByColumnType = (
  columnType: IColumnType,
  filters: IDataLoadingQueryColumnOrFilters,
): IDataLoadingQueryColumnOrFilters => {
  const updatedFilters = { ...filters };

  if (columnType.type !== "enum") {
    const regexFilters: IDataLoadingQueryFilterRegex[] = [];

    // Add existing regex if present
    if (updatedFilters.regex != null) {
      regexFilters.push(updatedFilters.regex);
    }

    // Convert array operations to regex
    if (updatedFilters.anyInArray != null) {
      const regex = convertAnyInArrayToRegex(updatedFilters.anyInArray);

      if (regex) {
        regexFilters.push(regex);
      }
    }

    if (updatedFilters.allInArray != null) {
      const regex = convertAllInArrayToRegex(updatedFilters.allInArray);

      if (regex) {
        regexFilters.push(regex);
      }
    }

    if (updatedFilters.noneInArray != null) {
      const regex = convertNoneInArrayToRegex(updatedFilters.noneInArray);

      if (regex) {
        regexFilters.push(regex);
      }
    }

    if (updatedFilters.allNotInArray != null) {
      const regex = convertAllNotInArrayToRegex(updatedFilters.allNotInArray);

      if (regex) {
        regexFilters.push(regex);
      }
    }

    // Combine all regex filters if any exist
    if (regexFilters.length > 0) {
      updatedFilters.regex = combineOrRegexFilters(regexFilters);
      // Clear the array operations since they've been converted
      updatedFilters.anyInArray = undefined;
      updatedFilters.allInArray = undefined;
      updatedFilters.noneInArray = undefined;
      updatedFilters.allNotInArray = undefined;
    }

    return updatedFilters;
  }

  // for enum column, convert regex to array filters if anyInArray is not set
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- last type check
  if (columnType.type === "enum") {
    if (updatedFilters.regex != null) {
      const existingArrayItems =
        updatedFilters.anyInArray?.type === "value" && updatedFilters.anyInArray.value.type === "array"
          ? updatedFilters.anyInArray.value.value
          : [];

      updatedFilters.anyInArray = convertRegexToArrayFilters(updatedFilters.regex, existingArrayItems);
      updatedFilters.regex = undefined;
    }
  }

  return updatedFilters;
};

export const cleanUpAndFiltersByColumnType = (
  columnType: IColumnType,
  filters: IDataLoadingQueryColumnAndFilters,
): IDataLoadingQueryColumnAndFilters => {
  const updatedFilters = { ...filters };

  updatedFilters.eq = updatedFilters.eq != null ? cleanUpValueByColumnType(updatedFilters.eq, columnType) : undefined;
  updatedFilters.neq =
    updatedFilters.neq != null ? updatedFilters.neq.map((neq) => cleanUpValueByColumnType(neq, columnType)) : undefined;

  // for non-enum column, convert array filters to regex
  if (columnType.type !== "enum") {
    const regexFilters: IDataLoadingQueryFilterRegex[] = [];

    // Add existing regex if present
    if (updatedFilters.regex != null) {
      regexFilters.push(updatedFilters.regex);
    }

    // Convert array operations to regex
    if (updatedFilters.anyInArray != null) {
      const regex = convertAnyInArrayToRegex(updatedFilters.anyInArray);

      if (regex) {
        regexFilters.push(regex);
      }
    }

    if (updatedFilters.allInArray != null) {
      const regex = convertAllInArrayToRegex(updatedFilters.allInArray);

      if (regex) {
        regexFilters.push(regex);
      }
    }

    if (updatedFilters.noneInArray != null) {
      const regex = convertNoneInArrayToRegex(updatedFilters.noneInArray);

      if (regex) {
        regexFilters.push(regex);
      }
    }

    if (updatedFilters.allNotInArray != null) {
      const regex = convertAllNotInArrayToRegex(updatedFilters.allNotInArray);

      if (regex) {
        regexFilters.push(regex);
      }
    }

    // Combine all regex filters if any exist
    if (regexFilters.length > 0) {
      updatedFilters.regex = combineAndRegexFilters(regexFilters);
      // Clear the array operations since they've been converted
      updatedFilters.anyInArray = undefined;
      updatedFilters.allInArray = undefined;
      updatedFilters.noneInArray = undefined;
      updatedFilters.allNotInArray = undefined;
    }
  }

  // for enum column, convert regex to array filters if anyInArray is not set
  if (columnType.type === "enum") {
    if (updatedFilters.anyInArray == null) {
      if (updatedFilters.regex != null) {
        updatedFilters.anyInArray = convertRegexToArrayFilters(updatedFilters.regex, []);
        updatedFilters.regex = undefined;
      }
    }
  }

  return updatedFilters;
};
