import { RelationId } from "@archetype/ids";
import { z } from "zod";

import { Dependency } from "./Dependency";
import { DerivationStep } from "./Derivation";
import { ComputedElementValues } from "./Element";
import { RelationDirection } from "./RelationBase";
import { StoredViewField } from "./ViewField";
import { ViewFieldValue } from "./ViewFieldValue";
export const LogicConfig = z.object({
  type: z.literal("logic"),
  logic: z.string(),
  rawLogic: z.string(),
  steps: z.array(DerivationStep),
  dependencies: z.array(Dependency),
});
export type ILogicConfig = z.infer<typeof LogicConfig>;

export const LookupConfig = z.object({
  type: z.literal("lookup"),
  config: z.object({
    relationId: RelationId,
    direction: RelationDirection,
    lookupField: StoredViewField,
  }),
  rawLogic: z.string(),
  steps: z.array(DerivationStep),
  dependencies: z.array(Dependency),
});
export type ILookupConfig = z.infer<typeof LookupConfig>;

export const AIConfig = z.object({
  type: z.literal("ai"),
  instructions: z.string(),
  rawInstructions: z.string(),
  dependencies: z.array(Dependency),
});
export type IAIConfig = z.infer<typeof AIConfig>;

export const StaticValueConfig = z.object({
  type: z.literal("static"),
  value: ViewFieldValue,
});
export type IStaticValueConfig = z.infer<typeof StaticValueConfig>;

export const DynamicValueConfigValues = z.enum([...ComputedElementValues.options, "currentUser"]);
export type IDynamicValueConfigValues = z.infer<typeof DynamicValueConfigValues>;

export const DynamicValueConfig = z.object({
  type: z.literal("dynamic"),
  value: DynamicValueConfigValues,
});
export type IDynamicValueConfig = z.infer<typeof DynamicValueConfig>;

export const FixedDynamicValuesList: string[] = [...ComputedElementValues.options, "currentUser"];

export const AutofillConfig = z.discriminatedUnion("type", [
  LogicConfig,
  LookupConfig,
  AIConfig,
  StaticValueConfig,
  DynamicValueConfig,
]);
export type IAutofillConfig = z.infer<typeof AutofillConfig>;

// helper methods and types

export const isLookupConfig = (config: IAutofillConfig): config is ILookupConfig => config.type === "lookup";
export const isLogicConfig = (config: IAutofillConfig): config is ILogicConfig => config.type === "logic";
export const isAIConfig = (config: IAutofillConfig): config is IAIConfig => config.type === "ai";
export const isStaticValueConfig = (config: IAutofillConfig): config is IStaticValueConfig => config.type === "static";
export const isDynamicValueConfig = (config: IAutofillConfig): config is IDynamicValueConfig =>
  config.type === "dynamic";

export type IFixedValueConfig = IStaticValueConfig | IDynamicValueConfig;
export const isFixedValueConfig = (config: IAutofillConfig): config is IFixedValueConfig =>
  isStaticValueConfig(config) || isDynamicValueConfig(config);
