import type { IDataModelFeature, IEntityType } from "@archetype/dsl";
import type { IActionId, IColumnId, IEntityTypeId, IRelationId, IStateId } from "@archetype/ids";
import { keyByNoUndefined, mapValues, visit } from "@archetype/utils";

import type { IReadableIdMappings } from "../dataModel/inferrenceTypings";
import { optimisticReadableIdentifier, optimisticReadableIdentifierFromString } from "../dataModel/utils";
import { createFileLogger } from "../logger";
import type {
  DATA_MODEL_REF_MAPPING_ID_TYPES,
  IIdentifierMapperFunctions,
  IReferencedDataModelIds,
  IStringIdentifierMappings,
} from "./dataModelReferencesMapperTypes";
import { mapperFunctionsFromMappings } from "./dataModelReferencesMapperTypes";

const logger = createFileLogger("mapDataModelFeaturesDataModelReferences");

export const dataModelFeaturesWithReadableIdToUuids = (
  newFeatures: Record<string, IDataModelFeature>,
  readableIdMappings: IReadableIdMappings,
): Record<string, IDataModelFeature> => {
  const stringIdentifierMappings: IStringIdentifierMappings<{
    ORIG_ENT_T_ID: string;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: string;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;
    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    originToTargetIdentifiers: mapValues(readableIdMappings.readableIdsToIds, (entityTypeReadableIds) => ({
      targetIdentifier: entityTypeReadableIds.id,
      columnOriginToTargetIdentifiers: entityTypeReadableIds.columnReadableIdsToIds,
    })),
    preMappedParentColumnMapping: mapValues(
      readableIdMappings.parentExistingEntityReadableIdToIds,
      ({ columnReadableIdsToIds }) => ({
        columnOriginToTargetIdentifiers: columnReadableIdsToIds,
      }),
    ),
    originToTargetActionId: readableIdMappings.actionReadableIdsToIds,
    originToTargetEntityRelationId: readableIdMappings.entityRelationReadableIdsToIds,
    originToTargetStateId: readableIdMappings.stateReadableIdsToIds,
  };

  return mapEntityTypeReferencesInDataModelFeatures(newFeatures, mapperFunctionsFromMappings(stringIdentifierMappings));
};

export const dataModelFeaturesUuidToReadableId = (
  newFeatures: Record<string, IDataModelFeature>,
  // Probably want to traverse the feature tree once to load only the relevant ones
  allReferencedEntityTypes: Record<IEntityTypeId, IEntityType>,
): Record<string, IDataModelFeature> => {
  const stringIdentifierMappings: IStringIdentifierMappings<{
    ORIG_ENT_T_ID: string;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: string;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;
    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    originToTargetIdentifiers: mapValues(allReferencedEntityTypes, (entityType) => ({
      targetIdentifier: optimisticReadableIdentifier(entityType) as IEntityTypeId, // Could make it a camelCase if model more performant with it
      columnOriginToTargetIdentifiers: mapValues(
        keyByNoUndefined(entityType.columns, (col) => col.id),
        (column) => optimisticReadableIdentifier(column) as IColumnId,
      ),
    })),
    preMappedParentColumnMapping: {
      // Could add newFeatures here as well
    },
    // TODO(marts): this function is never used, but this is broken probably
    originToTargetActionId: {},
    originToTargetEntityRelationId: {},
    originToTargetStateId: {},
  };

  return mapEntityTypeReferencesInDataModelFeatures(newFeatures, mapperFunctionsFromMappings(stringIdentifierMappings));
};

export const collectDataModelIdInDataModelFeatures = (
  newFeatures: Record<string, IDataModelFeature>,
): IReferencedDataModelIds<{
  ORIG_ENT_T_ID: IEntityTypeId;
  TARG_ENT_T_ID: IEntityTypeId;
  ORIG_COL_ID: IColumnId;
  TARG_COL_ID: IColumnId;
  ORIG_ACT_ID: IActionId;
  TARG_ACT_ID: IActionId;
  ORIG_REL_ID: IRelationId;
  TARG_REL_ID: IRelationId;
  ORIG_STATE_ID: IStateId;
  TARG_STATE_ID: IStateId;
}> => {
  const referencedDataModelIds: IReferencedDataModelIds<{
    ORIG_ENT_T_ID: IEntityTypeId;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: IColumnId;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;
    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    newEntityTypes: {},
    preMappedEntityTypes: {},
    newActions: new Set<IActionId>(),
    newEntityRelations: new Set<IRelationId>(),
    newStates: new Set<IStateId>(),
  };

  // Actually a no-op here but side effect of collecting the ids (we could make it cleaner with a forEach but not required)
  const idMapperFunctions: IIdentifierMapperFunctions<{
    ORIG_ENT_T_ID: IEntityTypeId;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: IColumnId;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;
    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    origToTargEntityTypeId: (originEntityTypeId: IEntityTypeId) => {
      if (referencedDataModelIds.newEntityTypes[originEntityTypeId] == null) {
        referencedDataModelIds.newEntityTypes[originEntityTypeId] = new Set<IColumnId>();
      }

      // Noop
      return originEntityTypeId;
    },
    origToTargColId: (originEntityTypeId: IEntityTypeId, origColId: IColumnId) => {
      if (referencedDataModelIds.newEntityTypes[originEntityTypeId] == null) {
        referencedDataModelIds.newEntityTypes[originEntityTypeId] = new Set<IColumnId>();
      }

      referencedDataModelIds.newEntityTypes[originEntityTypeId]?.add(origColId);

      // Noop
      return origColId;
    },
    origToTargColIdOnPreMappedEntityType: (preMappedEntityTypeId: IEntityTypeId, origColId: IColumnId) => {
      if (referencedDataModelIds.preMappedEntityTypes[preMappedEntityTypeId] == null) {
        referencedDataModelIds.preMappedEntityTypes[preMappedEntityTypeId] = new Set<IColumnId>();
      }

      referencedDataModelIds.preMappedEntityTypes[preMappedEntityTypeId]?.add(origColId);

      // Noop
      return origColId;
    },
    origToTargActionId: (originActionId: IActionId) => {
      referencedDataModelIds.newActions.add(originActionId);

      // Noop
      return originActionId;
    },
    origToTargEntityRelationId: (originEntityRelationId: IRelationId) => {
      referencedDataModelIds.newEntityRelations.add(originEntityRelationId);

      // Noop
      return originEntityRelationId;
    },
    origToTargStateId: (originStateId: IStateId) => {
      referencedDataModelIds.newStates.add(originStateId);

      // Noop
      return originStateId;
    },
  };

  mapEntityTypeReferencesInDataModelFeatures(newFeatures, idMapperFunctions);

  return referencedDataModelIds;
};

export const standardizeReadableIdsInDataModelFeatures = (
  /**
   * Should contain readable id, not mapped UUIDs
   */
  newFeatures: Record<string, IDataModelFeature>,
): Record<string, IDataModelFeature> => {
  // Actually a no-op here but side effect of collecting the ids (we could make it cleaner with a forEach but not required)
  const idMapperFunctions: IIdentifierMapperFunctions<{
    ORIG_ENT_T_ID: IEntityTypeId;
    TARG_ENT_T_ID: IEntityTypeId;
    ORIG_COL_ID: IColumnId;
    TARG_COL_ID: IColumnId;
    ORIG_ACT_ID: IActionId;
    TARG_ACT_ID: IActionId;
    ORIG_REL_ID: IRelationId;
    TARG_REL_ID: IRelationId;
    ORIG_STATE_ID: IStateId;
    TARG_STATE_ID: IStateId;
  }> = {
    origToTargEntityTypeId: (originEntityTypeId: IEntityTypeId) =>
      optimisticReadableIdentifierFromString(originEntityTypeId) as IEntityTypeId,
    origToTargColId: (originEntityTypeId: IEntityTypeId, origColId: IColumnId) =>
      optimisticReadableIdentifierFromString(origColId) as IColumnId,
    origToTargColIdOnPreMappedEntityType: (preMappedEntityTypeId: IEntityTypeId, origColId: IColumnId) =>
      optimisticReadableIdentifierFromString(origColId) as IColumnId,
    origToTargActionId: (originActionId: IActionId) =>
      optimisticReadableIdentifierFromString(originActionId) as IActionId,
    origToTargEntityRelationId: (originEntityRelationId: IRelationId) =>
      optimisticReadableIdentifierFromString(originEntityRelationId) as IRelationId,
    origToTargStateId: (originStateId: IStateId) => optimisticReadableIdentifierFromString(originStateId) as IStateId,
  };

  return mapEntityTypeReferencesInDataModelFeatures(newFeatures, idMapperFunctions);
};

export const mapEntityTypeReferencesInDataModelFeatures = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  newFeatures: Record<string, IDataModelFeature>,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): Record<string, IDataModelFeature> => {
  const result: Record<string, IDataModelFeature> = mapValues(newFeatures, (feature) =>
    mapReferencesInDataModelFeature(feature, idMapperFunctions),
  );

  return result;
};

const throwingOrigToTargColId = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  originEntityTypeIdentifier: IDS["ORIG_ENT_T_ID"],
  columnOriginIdentifier: IDS["ORIG_COL_ID"],
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IDS["TARG_COL_ID"] => {
  const targetColumnIdentifier = idMapperFunctions.origToTargColId(originEntityTypeIdentifier, columnOriginIdentifier);

  if (targetColumnIdentifier == null) {
    const err = `Failed to map column reference ${columnOriginIdentifier} on entity type ${originEntityTypeIdentifier}`;

    logger.error(err);
    throw new Error(err);
  }

  return targetColumnIdentifier;
};

/**
 *
 * @param result adds the inferred entity types to this result
 * @param parentEntityTypeRef must have a parent if the data model is defined on a parent feature, and it must be present in
 * result already (i.e. traverse as a hierarchy)
 */
export const mapReferencesInDataModelFeature = <IDS extends DATA_MODEL_REF_MAPPING_ID_TYPES>(
  feature: IDataModelFeature,
  idMapperFunctions: IIdentifierMapperFunctions<IDS>,
): IDataModelFeature =>
  visit<IDataModelFeature, IDataModelFeature>(feature, {
    dataTypeRefinement: (typedFeature) => ({
      ...typedFeature,
      entityType: idMapperFunctions.origToTargEntityTypeId(typedFeature.entityType),
      column: throwingOrigToTargColId(typedFeature.entityType, typedFeature.column, idMapperFunctions),
    }),
    compositeRefinement: (typedFeature) => ({
      ...typedFeature,
      entityType: idMapperFunctions.origToTargEntityTypeId(typedFeature.entityType),
      anyMustFit: typedFeature.anyMustFit.map((anyMustFit) => ({
        ...anyMustFit,
        allMustFit: anyMustFit.allMustFit.map((allMustFit) => ({
          ...allMustFit,
          column: throwingOrigToTargColId(typedFeature.entityType, allMustFit.column, idMapperFunctions),
        })),
      })),
    }),
  });
