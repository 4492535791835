import { EntityId, EntityTypeId, RelationId } from "@archetype/ids";
import { z } from "zod";

import { RelationDirection } from "../RelationBase";

export const DataLoadingQueryEntityTypeLiteralReference = z.object({
  type: z.literal("entityTypeId"),
  entityTypeId: EntityTypeId,
  /**
   * By default all entitites will be used
   */
  entities: z.array(EntityId).optional(),
});

export const DataLoadingQueryEntityTypeJoinTraversalReference = z.object({
  type: z.literal("joinTraversal"),
  relationTypeId: RelationId,
  /**
   * The direction in which the relation is traversed when executing the join,
   * so from starting entities' type or towards output entity type of that QueryEntityTypeReference
   * e.g. "aToB" means starting entities are of type A and the general query is of entity type B
   */
  relationDirection: RelationDirection,
  /**
   * By default the entity type will be read from the join, and all entities will be used
   */
  startingEntities: DataLoadingQueryEntityTypeLiteralReference,
});
export const DataLoadingQueryEntityTypeReference = z.discriminatedUnion("type", [
  DataLoadingQueryEntityTypeLiteralReference,
  DataLoadingQueryEntityTypeJoinTraversalReference,
]);

export type IDataLoadingQueryEntityTypeLiteralReference = z.infer<typeof DataLoadingQueryEntityTypeLiteralReference>;
export type IDataLoadingQueryEntityTypeJoinTraversalReference = z.infer<
  typeof DataLoadingQueryEntityTypeJoinTraversalReference
>;
