import { EntityTypeId } from "@archetype/ids";
import { z } from "zod";

import { JoinCardinality } from "../dataModel/RelationBase";

export const LlmDataModelEntityType = z.object({
  entityTypeId: EntityTypeId,
  entityName: z.string(),
  columns: z.record(
    z.string(), // column name
    z.object({
      type: z.string(),
      description: z.string(),
    }),
  ),
  relations: z.record(
    z.string(), // relation name
    z.object({
      description: z.string(),
      targetEntity: z.string(),
      cardinality: JoinCardinality,
    }),
  ),
});
export type ILlmDataModelEntityType = z.infer<typeof LlmDataModelEntityType>;

export const LlmDataModel = z.record(z.string(), LlmDataModelEntityType);
export type ILlmDataModel = z.infer<typeof LlmDataModel>;
