import type { IRelation } from "@archetype/dsl";
import {
  computeColumnViewFieldId,
  computeRelationViewFieldId,
  type IEntityType,
  type IStoredViewField,
} from "@archetype/dsl";
import type { IColumnId, IRelationId, IViewFieldId } from "@archetype/ids";
import assertNever from "assert-never";

/**
 * Returns a map of ViewFieldIds in the referenced entity type to the columnIds that
 * use it for autofill
 */
export const findAutofillViewFieldReferencesFromEntityType = ({
  entityType,
  relationsForEntityType,
  referencedEntityType,
}: {
  entityType: IEntityType;
  relationsForEntityType: Record<IRelationId, IRelation>;
  referencedEntityType: IEntityType;
}): Record<IViewFieldId, { columnIds: IColumnId[] }> => {
  const references: Record<IViewFieldId, { columnIds: IColumnId[] }> = {};

  entityType.columns.forEach(({ autofill, id: columnId }) => {
    if (autofill?.config == null) {
      return;
    }

    switch (autofill.config.type) {
      case "ai":
      case "logic":
      case "dynamic":
      case "static": {
        return;
      }
      case "lookup": {
        const relation = relationsForEntityType[autofill.config.config.relationId];

        const otherEntityTypeId =
          autofill.config.config.direction === "aToB" ? relation?.entityTypeIdB : relation?.entityTypeIdA;
        const lookupFieldId = getStoredFieldViewFieldId(autofill.config.config.lookupField);

        if (otherEntityTypeId == null || otherEntityTypeId !== referencedEntityType.id) {
          return;
        }

        references[lookupFieldId] = {
          columnIds: (references[lookupFieldId]?.columnIds ?? []).concat([columnId]),
        };

        break;
      }
      default: {
        assertNever(autofill.config);
      }
    }
  });

  return references;
};

const getStoredFieldViewFieldId = (storedViewField: IStoredViewField): IViewFieldId => {
  switch (storedViewField.type) {
    case "column": {
      return computeColumnViewFieldId(storedViewField.columnId);
    }
    case "directionalRelation": {
      return computeRelationViewFieldId(storedViewField.relationId, storedViewField.direction);
    }
    default: {
      assertNever(storedViewField);
    }
  }
};
