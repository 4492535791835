import { format } from "date-fns";

const DATE_FORMAT = "PPP";
const DATE_TIME_FORMAT = "PPP @ h:mm aa";

export const formatDatetime = (date?: Date | string, includeTime = true): string | undefined => {
  if (date === undefined) return undefined;

  try {
    return format(new Date(date), includeTime ? DATE_TIME_FORMAT : DATE_FORMAT);
  } catch {
    return date as string;
  }
};
