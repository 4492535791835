import {
  Badge,
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "@archetype/ui";
import React, { useCallback, useEffect, useState } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableEditingContext } from "../../context/DataTableEditingContext";
import { useCellClickInteraction } from "../../hooks/useCellClickInteraction";
import { useCellEditOnEnter } from "../../hooks/useCellEditOnEnter";
import type { IDataTableCommonCellProps, IDataTableSelectCell } from "./api";

export const SelectCell = function SelectCell<TRowId extends string, TColId extends string>({
  cell,
  isCellEditing,
  isCellSelected,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTableSelectCell<TRowId, TColId>>): React.ReactElement {
  const { value, onChange, options, readOnly, colId, rowId, inclusiveMaxValuesToSelect } = cell;
  const maxValuesToSelect = inclusiveMaxValuesToSelect ?? Infinity;
  const isMultiSelect = maxValuesToSelect !== 1;
  const setEditableCell = useContextSelector(DataTableEditingContext, (state) => state.setEditableCell);

  const { handleCellClick } = useCellClickInteraction({
    isCellEditing,
    isCellSelected,
    readOnly,
    rowId,
    colId,
  });

  useCellEditOnEnter({ rowId, colId, isCellSelected, isCellEditing });
  // useCellExitOnEsc({ inputRef: null, isCellSelected, isCellEditing });

  // Maintain local state for multi-select; use prop value for single-select
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
  const [localValue, setLocalValue] = useState<string[]>(value ?? []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
    setLocalValue(value ?? []);
  }, [value]);

  const handleOptionSelect = useCallback(
    (selectedOptionValue: string) => (): void => {
      if (readOnly) {
        return;
      }

      if (isMultiSelect) {
        // For multi select, call keep the values in local state and keep the dropdown open
        setLocalValue((prevValue) => {
          let newValue: string[];

          if (prevValue.includes(selectedOptionValue)) {
            newValue = prevValue.filter((v) => v !== selectedOptionValue);
          } else {
            newValue = [...prevValue, selectedOptionValue];
          }

          return newValue;
        });
      } else {
        // For single-select, call onChange immediately and close the dropdown
        void onChange?.([selectedOptionValue]);
        setEditableCell(null);
      }
    },
    [readOnly, isMultiSelect, onChange, setEditableCell],
  );

  // Handle blur event to save changes (only for multi-select)
  const handleBlur = useCallback(() => {
    if (!isMultiSelect || readOnly) {
      return;
    }

    if (onChange && localValue !== value) {
      void onChange(localValue);
    }

    setEditableCell(null);
  }, [isMultiSelect, onChange, localValue, value, readOnly, setEditableCell]);

  const handlePopoverOpenChange = useCallback(
    (isOpen: boolean): void => {
      if (!isOpen) {
        handleBlur();
      }
    },
    [handleBlur],
  );

  const selectedOptions = isMultiSelect
    ? options.filter((option) => localValue.includes(option.value))
    : options.filter((option) => value.includes(option.value));

  return (
    <div className="flex h-full flex-1 px-2" onClick={handleCellClick}>
      <Popover open={!readOnly && isCellEditing} onOpenChange={handlePopoverOpenChange}>
        <PopoverTrigger asChild>
          <button className="no-focus-outline flex size-full flex-nowrap items-center gap-1 overflow-hidden">
            {selectedOptions.map((option) => (
              <Badge key={option.value} className="truncate" colorVariant="gray" size="sm">
                {option.label}
              </Badge>
            ))}
          </button>
        </PopoverTrigger>
        <PopoverPortal container={document.body}>
          <PopoverContent className="p-1">
            <Command>
              <CommandInput placeholder="Search..." small={true} />
              <CommandList>
                {options.map((option) => {
                  const isSelected = isMultiSelect ? localValue.includes(option.value) : value.includes(option.value);

                  return (
                    <CommandItem key={option.value} value={option.value} onSelect={handleOptionSelect(option.value)}>
                      {option.label}
                      {isSelected ? <span className="ml-2">✓</span> : null}
                    </CommandItem>
                  );
                })}
              </CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
            </Command>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </div>
  );
};

SelectCell.displayName = "SelectCell";
