import type { workflows } from "@archetype/backend";
import type { TRPCClientErrorLike } from "@archetype/trpc-react";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { skipToken } from "@tanstack/react-query";
import type { WorkflowExecutionInfo, WorkflowExecutionStatusName } from "@temporalio/client";
import type { DecorateProcedure, UseTRPCMutationOptions, UseTRPCMutationResult } from "@trpc/react-query/shared";
import { useEffect, useState } from "react";

import { withRefetchInterval } from "../utils/refetchInterval";

interface IRunData {
  workflowId: string;
  runId: string;
}

export type IExecutionStatus = WorkflowExecutionInfo["status"] & {
  result?: unknown;
};

type IStatusChangeCallback = (data: IExecutionStatus) => void;

export const useTemporalRun = (
  onStatusChange?: IStatusChangeCallback,
): {
  setRunData: (arg: IRunData) => void;
} & { data: IExecutionStatus | undefined } => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [completedData, setCompletedData] = useState<IExecutionStatus | undefined>(undefined);
  const [runData, setRunData] = useState<IRunData | undefined>(undefined);
  const data = trpc.temporal.getTemporalRunStatus.useQuery(runData ?? skipToken, {
    ...withRefetchInterval(750),
    enabled: !isCompleted,
  });

  useEffect(() => {
    if (data.data != null) {
      onStatusChange?.(data.data);

      if (data.data.name === "COMPLETED") {
        setIsCompleted(true);
        setCompletedData(data.data);
      }
    }
  }, [data.data, onStatusChange]);

  return {
    data: data.data ?? completedData,
    setRunData,
  };
};

export const useTemporalRunFromInfo = (
  runData: IRunData,
  onStatusChange: IStatusChangeCallback,
): { data: IExecutionStatus | undefined } => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [completedData, setCompletedData] = useState<IExecutionStatus | undefined>(undefined);
  const data = trpc.temporal.getTemporalRunStatus.useQuery(runData, {
    ...withRefetchInterval(500),
    enabled: !isCompleted,
  });

  useEffect(() => {
    if (data.data != null) {
      onStatusChange(data.data);

      if (data.data.name === "COMPLETED") {
        setIsCompleted(true);
        setCompletedData(data.data);
      }
    }
  }, [data.data, onStatusChange]);

  return {
    data: data.data ?? completedData,
  };
};

export const LOADING_STATUSES = ["UNSPECIFIED", "RUNNING"] as const;

export const runStatusIn = (
  data: Readonly<IExecutionStatus> | undefined,
  statuses: ReadonlyArray<WorkflowExecutionStatusName>,
): boolean => data != null && statuses.includes(data.name);

export const useTrpcTriggeredRun = <
  TDef extends {
    input: unknown;
    output: IRunData;
    transformer: boolean;
    errorShape: unknown;
  },
  TContext,
>(
  endpoint: DecorateProcedure<"mutation", TDef>,
  opts: UseTRPCMutationOptions<TDef["input"], TRPCClientErrorLike<TDef>, TDef["output"], TContext>,
  onStatusChange?: IStatusChangeCallback,
): {
  isPending: boolean;
  hasTriggeredOrSucceeded: boolean;
  hasSucceeded: boolean;
  hasFailed: boolean;
  startRun: PromiseFulfilledResult<
    ReturnType<DecorateProcedure<"mutation", TDef>["useMutation"]>
  >["value"]["mutateAsync"];
  temporal: ReturnType<typeof useTemporalRun>;
  mutation: Omit<
    UseTRPCMutationResult<TDef["output"], TRPCClientErrorLike<TDef>, TDef["input"], TContext>,
    "mutate" | "mutateAsnyc"
  >;
} => {
  const [isAwaitingRunStart, setIsAwaitingRunStart] = useState(false);

  const innerOnStatusChange = (data: IExecutionStatus): void => {
    if (data.name === "COMPLETED" || data.name === "FAILED") {
      setIsAwaitingRunStart(false);
    }
    onStatusChange?.(data);
  };

  const mutation = endpoint.useMutation(opts);
  const temporal = useTemporalRun(innerOnStatusChange);

  return {
    temporal,
    mutation: {
      ...mutation,
    },
    isPending: mutation.isPending || isAwaitingRunStart || runStatusIn(temporal.data, LOADING_STATUSES),
    hasTriggeredOrSucceeded:
      mutation.isPending ||
      isAwaitingRunStart ||
      runStatusIn(temporal.data, [...LOADING_STATUSES, "COMPLETED"] as const),
    hasSucceeded: runStatusIn(temporal.data, ["COMPLETED"] as const),
    hasFailed: runStatusIn(temporal.data, ["FAILED"] as const),
    startRun: async (...args): Promise<TDef["output"]> => {
      setIsAwaitingRunStart(true);
      const res = await mutation.mutateAsync(...args);

      temporal.setRunData(res);

      return res;
    },
  };
};

type ITemporalWorkflows = keyof typeof workflows;
// Only get workflows that are callable functions
export type ITemporalRunResult<T extends ITemporalWorkflows> = (typeof workflows)[T] extends (
  ...args: unknown[]
) => unknown
  ? Awaited<ReturnType<(typeof workflows)[T]>> | undefined
  : never;
