import { useState } from "react";
import { useDebounce } from "react-use";

/**
 * Hook to debounce a value with a specified delay
 * @param value The value to debounce
 * @param delay The delay in milliseconds
 * @returns The debounced value
 */
export function useDebouncedValue<T>(value: T, delay: number = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    delay,
    [value],
  );

  return debouncedValue;
}
