import type {
  IColumn,
  IColumnAndConditions,
  IDataLoadingQueryFilters,
  IEntityType,
  ILogicalStepCondition,
  IRelatedToFilter,
  IRelationCore,
} from "@archetype/dsl";
import type { IColumnId, IEntityTypeId } from "@archetype/ids";
import type { IReadableString } from "@archetype/utils";
import { assertNonNullable, keyByNoUndefined } from "@archetype/utils";
import { assign, values } from "lodash";

import type { ILoadedEntityType } from "../apiTypes/LoadedEntityType";
import { createFileLogger } from "../logger";

const logger = createFileLogger("logicalStep");

const buildColumnFilter = (
  existingConditions: IColumnAndConditions | undefined,
  column: IColumn,
  condition: ILogicalStepCondition,
): IColumnAndConditions => {
  const conditions = existingConditions ?? {
    type: "and",
    rawAndOperations: {},
    andedOrConditions: [],
  };

  const { operator, element } = condition;

  switch (operator) {
    case "equals": {
      if ("stringValue" in element) {
        conditions.rawAndOperations.eq = {
          type: "value",
          value: { type: "string", value: element.stringValue },
        };
      } else if ("numberValue" in element) {
        conditions.rawAndOperations.eq = {
          type: "value",
          value: { type: "number", value: element.numberValue },
        };
      } else if ("booleanValue" in element) {
        conditions.rawAndOperations.eq = {
          type: "value",
          value: { type: "boolean", value: element.booleanValue },
        };
      }
      break;
    }
    case "notEquals": {
      if ("stringValue" in element) {
        conditions.rawAndOperations.neq = [
          {
            type: "value",
            value: { type: "string", value: element.stringValue },
          },
        ];
      } else if ("numberValue" in element) {
        conditions.rawAndOperations.neq = [
          {
            type: "value",
            value: { type: "number", value: element.numberValue },
          },
        ];
      } else if ("booleanValue" in element) {
        conditions.rawAndOperations.neq = [
          {
            type: "value",
            value: { type: "boolean", value: element.booleanValue },
          },
        ];
      }
      break;
    }
    case "lessThan": {
      if ("numberValue" in element) {
        conditions.rawAndOperations.lt = {
          type: "value",
          value: { type: "number", value: element.numberValue },
        };
      }
      break;
    }
    case "lessThanEqual": {
      if ("numberValue" in element) {
        conditions.rawAndOperations.lte = {
          type: "value",
          value: { type: "number", value: element.numberValue },
        };
      }
      break;
    }
    case "greaterThan": {
      if ("numberValue" in element) {
        conditions.rawAndOperations.gt = {
          type: "value",
          value: { type: "number", value: element.numberValue },
        };
      }
      break;
    }
    case "greaterThanEqual": {
      if ("numberValue" in element) {
        conditions.rawAndOperations.gte = {
          type: "value",
          value: { type: "number", value: element.numberValue },
        };
      }
      break;
    }
    case "stringContains": {
      if ("stringValue" in element) {
        conditions.rawAndOperations.regex = {
          type: "regex",
          value: element.stringValue,
          flags: "i",
        };
      }
      break;
    }
    case "stringLengthEquals":
    case "stringLengthNotEquals":
    case "stringLengthLessThan":
    case "stringLengthLessThanEqual":
    case "stringLengthGreaterThan":
    case "stringLengthGreaterThanEqual": {
      logger.warn({ operator }, "stringLength operators are not implemented yet");

      break;
    }
  }

  return conditions;
};

const buildRelationFilter = ({
  entityType,
  otherEntityType,
  relation,
  condition,
}: {
  entityType: ILoadedEntityType;
  otherEntityType: IEntityType;
  relation: IRelationCore;
  condition: ILogicalStepCondition;
}): IRelatedToFilter => {
  const column = otherEntityType.columns.find((c) => c.id === otherEntityType.displayNameColumn);

  assertNonNullable(column, "display name column not found");

  return {
    type: "relatedTo",
    relationId: relation.id,
    direction: relation.entityTypeIdA === entityType.id ? "aToB" : "bToA",
    filters: {
      type: "and",
      perColumn: {
        [otherEntityType.displayNameColumn]: buildColumnFilter(undefined, column, condition),
      },
      andedCrossColumnOrConditions: [],
    },
  };
};

export const convertLogicalStepConditionsToQueryFilters = (
  stepConditions: ILogicalStepCondition[],
  entityType: ILoadedEntityType,
  entityTypesById: Record<IEntityTypeId, IEntityType>,
): IDataLoadingQueryFilters => {
  const perColumnAndConditions: Record<IColumnId, IColumnAndConditions> = {};
  const perRelationAndConditions: Array<IRelatedToFilter> = [];

  const columnsByName = keyByNoUndefined(values(entityType.columns), (c) => c?.displayMetadata.name);
  const relationsByName = assign(
    keyByNoUndefined(values(entityType.relations), (r) => r?.displayMetadataFromAToB.name),
    keyByNoUndefined(values(entityType.relations), (r) => r?.displayMetadataFromBToA.name),
  );

  stepConditions.forEach((condition) => {
    const column = columnsByName[condition.columnName as IReadableString];

    if (!column) {
      const relation = relationsByName[condition.columnName as IReadableString];

      if (!relation) {
        logger.warn({ columnName: condition.columnName }, "column not found");

        return;
      }

      const otherEntityType = entityTypesById[relation.entityTypeIdB];

      assertNonNullable(otherEntityType, "other entity type not found");

      const relationConditions = buildRelationFilter({ entityType, otherEntityType, relation, condition });

      perRelationAndConditions.push(relationConditions);

      return;
    }

    perColumnAndConditions[column.id] = buildColumnFilter(perColumnAndConditions[column.id], column, condition);
  });

  return {
    type: "and",
    perColumn: perColumnAndConditions,
    andedCrossColumnOrConditions: [],
    andedRelatedToFilters: perRelationAndConditions,
  };
};
