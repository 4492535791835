import { EntityTypeId, RelationId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { JoinCardinality, RelationDirection } from "../dataModel/RelationBase";
import { LLMRelationAutofill } from "../llm/llmRelation";

export const RelationChangeBase = z.object({
  relationId: RelationId,
});
export type IRelationChangeBase = z.infer<typeof RelationChangeBase>;

export const RelationRenameAToBChange = RelationChangeBase.extend({
  changeTitleForBEntitiesWhenOnATO: ReadableString,
});
export type IRelationRenameAToBChange = z.infer<typeof RelationRenameAToBChange>;

export const RelationRenameBToAChange = RelationChangeBase.extend({
  changeTitleForAEntitiesWhenOnBTo: ReadableString,
});
export type IRelationRenameBToAChange = z.infer<typeof RelationRenameBToAChange>;

export const RelationCardinalityOnSideAChange = RelationChangeBase.extend({
  changeCardinalityOnSideATo: JoinCardinality,
});
export type IRelationCardinalityOnSideAChange = z.infer<typeof RelationCardinalityOnSideAChange>;

export const RelationCardinalityOnSideBChange = RelationChangeBase.extend({
  changeCardinalityOnSideBTo: JoinCardinality,
});
export type IRelationCardinalityOnSideBChange = z.infer<typeof RelationCardinalityOnSideBChange>;

export const RelationAutofillChange = RelationChangeBase.extend({
  changeAutofillTo: z.object({
    autofill: LLMRelationAutofill,
    direction: RelationDirection,
  }),
});
export type IRelationAutofillChange = z.infer<typeof RelationAutofillChange>;

export const RelationDisableAutofillChange = RelationChangeBase.extend({
  disableAutofill: z.enum(["true"]),
});
export type IRelationDisableAutofillChange = z.infer<typeof RelationDisableAutofillChange>;

export const RelationDeleteChange = RelationChangeBase.extend({
  deleteRelation: z.enum(["true"]),
});
export type IRelationDeleteChange = z.infer<typeof RelationDeleteChange>;

export const RelationCreateChange = z.object({
  createNewRelation: z.object({
    relationId: RelationId.default(RelationId.generate()),
    titleForBEntitiesWhenOnA: ReadableString,
    titleForAEntitiesWhenOnB: ReadableString,
    entityTypeAId: EntityTypeId,
    entityTypeBId: EntityTypeId,
    cardinalityOnSideA: z.enum(["one", "many"]),
    cardinalityOnSideB: z.enum(["one", "many"]),
  }),
});
export type IRelationCreateChange = z.infer<typeof RelationCreateChange>;

// TODO: Add validation changes

export const RelationChange = z.union([
  RelationRenameAToBChange,
  RelationRenameBToAChange,
  RelationAutofillChange,
  RelationDisableAutofillChange,
  RelationCardinalityOnSideAChange,
  RelationCardinalityOnSideBChange,
  RelationDeleteChange,
  RelationCreateChange,
]);
export type IRelationChange = z.infer<typeof RelationChange>;

export function isRelationRenameAToBChange(change: IRelationChange): change is IRelationRenameAToBChange {
  return "changeTitleForBEntitiesWhenOnATO" in change;
}

export function isRelationRenameBToAChange(change: IRelationChange): change is IRelationRenameBToAChange {
  return "changeTitleForAEntitiesWhenOnBTo" in change;
}

export function isRelationAutofillChange(change: IRelationChange): change is IRelationAutofillChange {
  return "changeAutofillTo" in change;
}

export function isRelationDisableAutofillChange(change: IRelationChange): change is IRelationDisableAutofillChange {
  return "disableAutofill" in change;
}

export function isRelationDeleteChange(change: IRelationChange): change is IRelationDeleteChange {
  return "deleteRelation" in change;
}

export function isRelationCreateChange(change: IRelationChange): change is IRelationCreateChange {
  return "createNewRelation" in change;
}

export function isRelationCardinalityOnSideAChange(
  change: IRelationChange,
): change is IRelationCardinalityOnSideAChange {
  return "changeCardinalityOnSideATo" in change;
}

export function isRelationCardinalityOnSideBChange(
  change: IRelationChange,
): change is IRelationCardinalityOnSideBChange {
  return "changeCardinalityOnSideBTo" in change;
}
