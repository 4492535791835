import {
  ActionId,
  ApplicationGroupId,
  ColumnId,
  EntityId,
  EntityTypeId,
  RelationId,
  StateId,
  TransitionId,
} from "@archetype/ids";
import { z } from "zod";

import { VersionType } from "../common/Version";

export const FeatureWorkspaceContext = z.object({
  type: z.literal("workspace"),
});
export type IFeatureWorkspaceContext = z.infer<typeof FeatureWorkspaceContext>;

export const FeatureWorkflowContext = z.object({
  type: z.literal("workflow"),
  workflowId: ApplicationGroupId,
});
export type IFeatureWorkflowContext = z.infer<typeof FeatureWorkflowContext>;

export const FeatureEntityTypeContext = z.object({
  type: z.literal("entityType"),
  entityTypeId: EntityTypeId,
});
export type IFeatureEntityTypeContext = z.infer<typeof FeatureEntityTypeContext>;

export const FeatureEntityTypePermissionsContext = z.object({
  type: z.literal("entityTypePermissions"),
  entityTypeId: EntityTypeId,
});
export type IFeatureEntityTypePermissionsContext = z.infer<typeof FeatureEntityTypePermissionsContext>;

export const FeatureStateContext = z.object({
  type: z.literal("state"),
  entityTypeId: EntityTypeId,
  stateId: StateId,
});
export type IFeatureStateContext = z.infer<typeof FeatureStateContext>;

export const FeatureTransitionContext = z.object({
  type: z.literal("transition"),
  entityTypeId: EntityTypeId,
  transitionId: TransitionId,
});
export type IFeatureTransitionContext = z.infer<typeof FeatureTransitionContext>;

export const FeatureActionContext = z.object({
  type: z.literal("action"),
  entityTypeId: EntityTypeId,
  actionId: ActionId,
});
export type IFeatureActionContext = z.infer<typeof FeatureActionContext>;

export const FeatureEntityContext = z.object({
  type: z.literal("entity"),
  entityTypeId: EntityTypeId,
  entityId: EntityId,
  versionType: VersionType,
});
export type IFeatureEntityContext = z.infer<typeof FeatureEntityContext>;

export const FeatureRelationContext = z.object({
  type: z.literal("relation"),
  entityTypeId: EntityTypeId,
  relationId: RelationId,
});
export type IFeatureRelationContext = z.infer<typeof FeatureRelationContext>;

export const FeatureColumnContext = z.object({
  type: z.literal("column"),
  entityTypeId: EntityTypeId,
  columnId: ColumnId,
});
export type IFeatureColumnContext = z.infer<typeof FeatureColumnContext>;

export const FeatureContext = z.discriminatedUnion("type", [
  FeatureWorkspaceContext,
  FeatureWorkflowContext,
  FeatureActionContext,
  FeatureStateContext,
  FeatureTransitionContext,
  FeatureColumnContext,
  FeatureEntityTypeContext,
  FeatureEntityContext,
  FeatureRelationContext,
  FeatureEntityTypePermissionsContext,
]);
export type IFeatureContext = z.infer<typeof FeatureContext>;
