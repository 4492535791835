import { z } from "zod";

import { DataLoadingQueryColumnAndFilters, DataLoadingQueryColumnOrFilters } from "./PerColumnConditions";

const BaseColumnOrConditions = z.object({
  type: z.literal("or"),
  rawOrConditions: DataLoadingQueryColumnOrFilters,
});

type IColumnOrConditionsInput = z.input<typeof BaseColumnOrConditions> & {
  oredAndConditions: IColumnAndConditionsInput[];
};

type IColumnOrConditionsOutput = z.output<typeof BaseColumnOrConditions> & {
  oredAndConditions: IColumnAndConditionsOutput[];
};
export type IColumnOrConditions = z.infer<typeof ColumnOrConditions>;

export const ColumnOrConditions: z.ZodType<IColumnOrConditionsOutput, z.ZodTypeDef, IColumnOrConditionsInput> =
  BaseColumnOrConditions.extend({
    oredAndConditions: z.lazy(() => z.array(ColumnAndConditions)),
  });

const BaseColumnAndConditions = z.object({
  type: z.literal("and"),
  rawAndOperations: DataLoadingQueryColumnAndFilters,
});

type IColumnAndConditionsInput = z.input<typeof BaseColumnAndConditions> & {
  andedOrConditions: IColumnOrConditionsInput[];
};

type IColumnAndConditionsOutput = z.output<typeof BaseColumnAndConditions> & {
  andedOrConditions: IColumnOrConditionsOutput[];
};
export type IColumnAndConditions = z.infer<typeof ColumnAndConditions>;

export const ColumnAndConditions: z.ZodType<IColumnAndConditionsOutput, z.ZodTypeDef, IColumnAndConditionsInput> =
  BaseColumnAndConditions.extend({
    andedOrConditions: z.lazy(() => z.array(ColumnOrConditions)),
  });

export const ColumnFilterConditions = z.union([ColumnOrConditions, ColumnAndConditions]);
export type IColumnFilterConditions = z.infer<typeof ColumnFilterConditions>;
