import type { IComponentSemanticSize } from "@archetype/dsl";
import type { IComponentDefinitionId, ISlotId } from "@archetype/ids";

import { AbstractError } from "./AbstractError";

export class ComponentNotCompatibleWithSlotSize extends AbstractError<"ComponentNotCompatibleWithSlotSize"> {
  constructor({
    componentId,
    slotId,
    size,
  }: {
    componentId: IComponentDefinitionId;
    slotId: ISlotId;
    size: IComponentSemanticSize;
  }) {
    super({
      name: "ComponentNotCompatibleWithSlotSize",
      message: `Component ${componentId} is not compatible with slot ${slotId} semantic size ${size}`,
    });
  }
}
