import { ColumnId, RelationId } from "@archetype/ids";
import { z } from "zod";

import { VersionType } from "../common/Version";
import { CrossColumnAndConditions } from "./dataLoading/CrossColumnConditions";
import { DataLoadingQueryEntityTypeReference } from "./dataLoading/EntityTypeReference";
import { DataLoadingFilterAndOperator } from "./dataLoading/PerColumnConditions";
import { RelationDirection } from "./RelationBase";

// Always starting with AND at root for now
export const DataLoadingQueryFilters = CrossColumnAndConditions;

const DataLoadingSearchQuery = z.object({
  searchQueryValue: z.string(),
  /**
   * By default all columns are searched
   */
  searchColumns: z.array(ColumnId).optional(),
});

export const DataLoadingQuery = z.object({
  versionType: VersionType,
  entityType: DataLoadingQueryEntityTypeReference,
  filters: DataLoadingQueryFilters.optional(),
  searchQuery: DataLoadingSearchQuery.optional(),
});

const DataLoadingRelationConfig = z.object({
  id: RelationId,
  direction: RelationDirection,
});

export const DataLoadingConfig = z.object({
  /**
   * By default all relations are loaded
   */
  specificRelations: z.array(DataLoadingRelationConfig).nullable(),
  /**
   * By default all columns are loaded
   */
  specificColumns: ColumnId.array().nullable(),
  /**
   * When set to true, the query will only return the number of entities that match the query and number of pages needed to retrieve all entities
   */
  countsOnly: z.boolean(),
});

export type IDataLoadingQueryEntityTypeReference = z.infer<typeof DataLoadingQueryEntityTypeReference>;
export type IDataLoadingQueryFilters = z.infer<typeof DataLoadingQueryFilters>;
export type IDataLoadingSearchQuery = z.infer<typeof DataLoadingSearchQuery>;
export type IDataLoadingQuery = z.infer<typeof DataLoadingQuery>;
export type IDataLoadingRelationConfig = z.infer<typeof DataLoadingRelationConfig>;
export type IDataLoadingConfig = z.infer<typeof DataLoadingConfig>;

export const DataLoadingSimpleColumnFilter = z.object({
  type: z.literal("column"),
  op: DataLoadingFilterAndOperator,
  colId: ColumnId,
  value: z.array(z.string()),
});

export const DataLoadingSimpleRelationFilter = z.object({
  type: z.literal("relation"),
  op: DataLoadingFilterAndOperator,
  colId: ColumnId,
  value: z.array(z.string()),
  direction: RelationDirection,
  relationId: RelationId,
});

export const DataLoadingGlobalSearchFilter = z.object({
  type: z.literal("search"),
  op: z.literal("regex").optional(),
  value: z.array(z.string()),
});

export const DataLoadingSimpleEntityFilter = z.union([
  DataLoadingSimpleColumnFilter,
  DataLoadingSimpleRelationFilter,
  DataLoadingGlobalSearchFilter,
]);

export type IDataLoadingSimpleEntityFilter = z.infer<typeof DataLoadingSimpleEntityFilter>;
export type IDataLoadingSimpleColumnFilter = z.infer<typeof DataLoadingSimpleColumnFilter>;
export type IDataLoadingSimpleRelationFilter = z.infer<typeof DataLoadingSimpleRelationFilter>;
export type IDataLoadingSimpleGlobalSearchFilter = z.infer<typeof DataLoadingGlobalSearchFilter>;
