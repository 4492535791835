import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { EntityDetailsComponentDefinitionId } from "@archetype/ids";

export const EntityDetailsComponentIdDeclarations = {
  id: EntityDetailsComponentDefinitionId,
  inputs: ["singleEntityDataInput"],
  outputs: [],
  compatibleSemanticSizes: ["main", "medium", "split-vertical"],
  validCosmeticOptionalProps: [],
} as const;

export const EntityDetailsComponentDefinition: ITypedComponentDefinitionDeclaration<
  typeof EntityDetailsComponentIdDeclarations
> = {
  constIds: EntityDetailsComponentIdDeclarations,
  displayMetadata: {
    name: "Entity view",
    description: "A view of a single entity",
  },
  inputs: {
    singleEntityDataInput: {
      displayMetadata: {
        name: "Entity",
        description: "The entity to display",
      },
      nullable: true,
      optional: false,
    },
  },
  outputs: {},
};
