import type { IComponentDefinitionId } from "@archetype/ids";

import { AbstractError } from "./AbstractError";

export class ComponentNotDefinedInRegistry extends AbstractError<"ComponentNotDefinedInRegistry"> {
  constructor({ componentId }: { componentId: IComponentDefinitionId }) {
    super({
      name: "ComponentNotDefinedInRegistry",
      message: `Component ${componentId} is not defined in the registry`,
    });
  }
}
