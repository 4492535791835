import { ApplicationGroupId, OrganizationId, UserId } from "@archetype/ids";
import { z } from "zod";

export const DataStoreConfig = z.object({
  type: z.literal("vercelBlob"),
  identifier: z.string().nullable(),
});

export const ApplicationGroup = z.object({
  id: ApplicationGroupId,
  userId: UserId,
  createdAt: z.date(),
  updatedAt: z.date(),
  organizationId: OrganizationId,
});

export type IApplicationGroup = z.infer<typeof ApplicationGroup>;
