import { match } from "ts-pattern";
import { z } from "zod";

import { createFileLogger } from "../../logger";
import { ColumnTypeDefinitions } from "../../schemas/dataModel/ColumnType";
import {
  ColumnEnumRefinement,
  ColumnStringEndsWithRefinement,
  ColumnStringEqualsRefinement,
  ColumnStringLengthRefinement,
  ColumnStringRegexRefinement,
} from "../../schemas/dataModel/columnTypes/ColumnStringType";
import type { IColumnTypeTree } from "../../utils/dataModel/resolveTypeTree";

const logger = createFileLogger("stringValidators");

type IStringValidator = z.ZodType<string>;

const createStringValidator = (): IStringValidator => z.string();
const createDateValidator = (): IStringValidator =>
  z
    .string()
    .pipe(z.coerce.date())
    .transform((date) => date.toISOString());

const createStringEqualsValidator = (validator: z.ZodType<string>, tree: IColumnTypeTree): IStringValidator => {
  const { value: expected } = ColumnStringEqualsRefinement.parse(tree.columnType.definition);

  return validator.refine((value) => value === expected, {
    message: "String does not match expected value",
  });
};

const createStringLengthValidator = (validator: z.ZodType<string>, tree: IColumnTypeTree): IStringValidator => {
  let refinedValidator = validator;
  const { inclusiveMin, inclusiveMax } = ColumnStringLengthRefinement.parse(tree.columnType.definition);

  if (inclusiveMin != null) {
    refinedValidator = refinedValidator.refine((value) => value.length >= inclusiveMin, {
      message: "String too short",
    });
  }
  if (inclusiveMax != null) {
    refinedValidator = refinedValidator.refine((value) => value.length <= inclusiveMax, { message: "String too long" });
  }

  return refinedValidator;
};

const createStringRegexValidator = (validator: z.ZodType<string>, tree: IColumnTypeTree): IStringValidator => {
  const { regex } = ColumnStringRegexRefinement.parse(tree.columnType.definition);

  return validator.refine((value) => new RegExp(regex).test(value), {
    message: "String does not match regex",
  });
};

const createStringEndsWithValidator = (validator: z.ZodType<string>, tree: IColumnTypeTree): IStringValidator => {
  const { endsWithString } = ColumnStringEndsWithRefinement.parse(tree.columnType.definition);

  return validator.refine((value) => value.endsWith(endsWithString), {
    message: `String does not end with "${endsWithString}"`,
  });
};

const createEnumValidator = (validator: z.ZodType<string>, tree: IColumnTypeTree): IStringValidator => {
  const { allowedValues } = ColumnEnumRefinement.parse(tree.columnType.definition);

  return validator.refine((value) => allowedValues.includes(value), {
    message: `String is not value of enum: ${allowedValues.map((x) => `"${x}"`).join(", ")}`,
  });
};

const createNonNullableStringValidator = (validator: z.ZodType<string>): IStringValidator => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- validator not typed properly
  return validator.refine((value) => value != null, { message: "String cannot be null" });
};

export const createStringTypeValidator = (tree: IColumnTypeTree): IStringValidator => {
  logger.trace(tree, "Creating string validator");
  const type = ColumnTypeDefinitions.and(z.object({ primitiveType: z.literal("string") })).parse(
    tree.columnType.definition,
  );

  return match(type)
    .returnType<IStringValidator>()
    .with({ type: "string" }, () => createStringValidator())
    .with({ type: "stringEquals" }, ({ child }) => {
      if (tree.child == null) {
        throw new Error(`Child ${child} does not exist`);
      }

      return createStringEqualsValidator(createStringTypeValidator(tree.child), tree);
    })
    .with({ type: "stringLength" }, ({ child }) => {
      if (tree.child == null) {
        throw new Error(`Child ${child} does not exist`);
      }

      return createStringLengthValidator(createStringTypeValidator(tree.child), tree);
    })
    .with({ type: "stringRegex" }, ({ child }) => {
      if (tree.child == null) {
        throw new Error(`Child ${child} does not exist`);
      }

      return createStringRegexValidator(createStringTypeValidator(tree.child), tree);
    })
    .with({ type: "stringEndsWith" }, ({ child }) => {
      if (tree.child == null) {
        throw new Error(`Child ${child} does not exist`);
      }

      return createStringEndsWithValidator(createStringTypeValidator(tree.child), tree);
    })
    .with({ type: "enum" }, ({ child }) => {
      if (tree.child == null) {
        throw new Error(`Child ${child} does not exist`);
      }

      return createEnumValidator(createStringTypeValidator(tree.child), tree);
    })
    .with({ type: "nonNullableString" }, () => {
      if (tree.child == null) {
        throw new Error(`Child does not exist`);
      }

      return createNonNullableStringValidator(createStringTypeValidator(tree.child));
    })
    .with({ type: "date" }, () => {
      return createDateValidator();
    })
    .with({ type: "timestamp" }, () => {
      throw new Error("Timestamp type not implemented");
    })
    .with({ type: "timeseries" }, () => {
      throw new Error("Timeseries type not implemented");
    })
    .with({ type: "array" }, () => {
      throw new Error("Array type not implemented");
    })
    .with({ type: "geolocation" }, () => {
      throw new Error("Geolocation type not implemented");
    })
    .exhaustive();
};
