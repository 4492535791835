export enum CloudStorageVendor {
  vercel = "vercel",
}

export interface IFileUploadOptions {
  access?: "public";
  token: string;
  addRandomSuffix?: boolean;
  multipart?: boolean;
}

export interface IOnUploadCompleted {
  callbackUrl: string;
  tokenPayload?: string | null;
}

export interface IClientUploadFileResponse {
  fileUrl: string;
}

export interface IClientUploadTokenResponse {
  token: string;
}

export interface IDeleteFilesResponse {
  [fileUrl: string]: boolean;
}

export interface ICopyFileResponse {
  success: boolean;
  newUrl: string;
}

export interface IMoveFileResponse {
  success: boolean;
  newUrl: string;
}

export interface ICloudStorage {
  getClientUploadToken({
    pathname,
    maximumSizeInBytes,
    allowedContentTypes,
    onUploadCompleted,
    addRandomSuffix,
  }: {
    pathname: string;
    maximumSizeInBytes: number;
    allowedContentTypes: string[];
    onUploadCompleted?: IOnUploadCompleted;
    addRandomSuffix?: boolean;
  }): Promise<IClientUploadTokenResponse>;

  clientUploadFile(fileUrl: string, file: File, options: IFileUploadOptions): Promise<IClientUploadFileResponse>;

  deleteFiles(fileUrls: string[]): Promise<IDeleteFilesResponse>;

  copyFile(sourceUrl: string, destinationUrl: string): Promise<ICopyFileResponse>;

  moveFile(sourceUrl: string, destinationUrl: string): Promise<IMoveFileResponse>;
}

export { createCloudStorageClient } from "./src/factory";
export { VercelCloudStorage } from "./src/vendors/vercel";
