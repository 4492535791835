import type { ILoadedAction, ILoadedEntity } from "@archetype/core";
import type { IVersionType, IViewFieldValue } from "@archetype/dsl";
import type { IEntityId, IViewFieldId } from "@archetype/ids";
import type { IBadgeColorVariant } from "@archetype/ui";
import { BadgeWithDot, Card, cn, Skeleton, TruncateList, useMemoDeepCompare } from "@archetype/ui";
import { useCallback } from "react";

import { ActionButton } from "../action/ActionButton";
import { MultiActionPopoverButton } from "../action/MultiActionPopoverButton";
import type { IGetActionRoute } from "../api";

export interface IEntityViewSecondaryAction {
  action: ILoadedAction;
  entityId: IEntityId | undefined;
  defaultValues: Record<IViewFieldId, IViewFieldValue> | undefined;
}

interface IActionCard {
  className?: string;
  versionType: IVersionType;
  entity: ILoadedEntity;
  statusBadge:
    | {
        color: IBadgeColorVariant;
        displayName: string;
      }
    | undefined;
  isLoadingExecutableActions?: boolean;
  primaryAction: ILoadedAction | undefined;
  secondaryActions: IEntityViewSecondaryAction[];
  onActionExecuted: (entity: ILoadedEntity | undefined) => Promise<void>;
  cardVariant?: Parameters<typeof Card>[0]["variant"];
  getActionRoute: IGetActionRoute;
  getFullPageActionRoute: IGetActionRoute;
}

export const ActionCard: React.FC<IActionCard> = ({
  className,
  entity,
  statusBadge,
  primaryAction,
  secondaryActions,
  isLoadingExecutableActions,
  onActionExecuted: handleActionExecuted,
  getActionRoute,
  getFullPageActionRoute,
  cardVariant,
  versionType,
}) => {
  const allActions = useMemoDeepCompare(() => {
    const allActionsInner: Array<IEntityViewSecondaryAction & { variant: "primary" | "secondary" }> =
      secondaryActions.map((actionWithInfo) => ({ ...actionWithInfo, variant: "secondary" }));

    if (primaryAction != null) {
      allActionsInner.unshift({
        action: primaryAction,
        entityId: entity.entityId,
        defaultValues: {},
        variant: "primary",
      });
    }

    return allActionsInner;
  }, [primaryAction, entity.entityId, secondaryActions]);

  const renderTruncator = useCallback(
    ({ hiddenItemsCount }: { hiddenItemsCount: number }) => {
      return (
        <MultiActionPopoverButton
          actions={allActions.slice(-hiddenItemsCount)}
          getActionRoute={getActionRoute}
          getFullPageActionRoute={getFullPageActionRoute}
          variant="secondary"
          versionType={versionType}
          onActionExecuted={handleActionExecuted}
        />
      );
    },
    [allActions, getActionRoute, getFullPageActionRoute, handleActionExecuted, versionType],
  );

  if (allActions.length === 0 && statusBadge == null) {
    return null;
  }

  return (
    <Card
      className={cn("border-border bg-paper flex shrink-0 items-center justify-between border p-2", className)}
      variant={cardVariant}
    >
      {statusBadge != null && (
        <BadgeWithDot className="shrink-0" colorVariant={statusBadge.color} label={statusBadge.displayName} />
      )}
      <div className="min-w-0 flex-1">
        {isLoadingExecutableActions === true && allActions.length === 0 ? (
          <Skeleton className="h-8 w-24" />
        ) : (
          <div className="ml-4 flex items-center justify-end">
            <TruncateList
              className="flex items-center gap-1"
              renderTruncator={renderTruncator}
              showTruncator={true}
              truncatePosition="end"
            >
              {allActions.map(({ action, variant, entityId, defaultValues }) => (
                <ActionButton
                  key={action.id}
                  action={action}
                  entityId={entityId}
                  getActionRoute={getActionRoute}
                  getFullPageActionRoute={getFullPageActionRoute}
                  presetParameters={{
                    defaultValues: (defaultValues ?? {}) as Partial<Record<IViewFieldId, IViewFieldValue>>,
                  }}
                  size="sm"
                  variant={variant}
                  versionType={versionType}
                  onActionExecuted={handleActionExecuted}
                />
              ))}
            </TruncateList>
          </div>
        )}
      </div>
    </Card>
  );
};
