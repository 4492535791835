import type { ICrossColumnAndConditions, IPerColumnAndedFilters } from "@archetype/dsl";
import type { IEntityId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { MultiSelect, useMemoDeepCompare } from "@archetype/ui";
import { isNonNullable, keyByAndMapValues, map } from "@archetype/utils";
import { concat } from "lodash";
import { useCallback, useMemo, useState } from "react";
import React from "react";

import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import type { IColumnFilterComponent } from "../types";

type IOption = { label: string; value: string };

export const EntityFilterInput: IColumnFilterComponent = ({ value, onChange, versionType, entityType }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);

  const selectedEntityIds = useMemoDeepCompare(
    () =>
      value?.eq
        ?.map((x) => (x.type === "value" && x.value.type === "string" ? (x.value.value as IEntityId) : undefined))
        .filter(isNonNullable),
    [value?.eq],
  );

  const targetEntityTypeId = entityType.id;

  const filterQueryWithSearch: ICrossColumnAndConditions | undefined = useMemoDeepCompare(() => {
    if (debouncedSearchQuery === "") {
      return undefined;
    }

    const perColumn: IPerColumnAndedFilters = {
      [entityType.displayNameColumn]: {
        type: "or" as const, // can be either or/and because it's a single value
        rawOrConditions: {
          regex: {
            type: "regex" as const,
            value: debouncedSearchQuery,
            flags: "gi",
          },
        },
        oredAndConditions: [],
      },
    };

    return {
      type: "and",
      perColumn,
      andedCrossColumnOrConditions: [],
      andedRelatedToFilters: [],
    };
  }, [debouncedSearchQuery, entityType.displayNameColumn]);

  const {
    data: selectedEntitiesQuery,
    isLoading: isLoadingSelectedEntities,
    isFetching: isFetchingSelectedEntities,
  } = builderTrpc.dataLoading.getLoadedEntities.useQuery(
    {
      organizationId: entityType.organizationId,
      dataLoadingQuery: {
        versionType,
        entityType: {
          type: "entityTypeId",
          entityTypeId: entityType.id,
          entities: selectedEntityIds,
        },
        filters: filterQueryWithSearch,
      },
      dataLoadingConfig: {
        specificRelations: [],
        specificColumns: [entityType.displayNameColumn],
      },
    },
    {
      // Enforce undefined if the entity type id has changed
      placeholderData: (previousData) =>
        previousData?.entities[0]?.entityTypeId !== targetEntityTypeId ? undefined : previousData,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    },
  );

  const {
    data: entitiesPagedQuery,
    isLoading: isLoadingEntities,
    isFetching: isFetchingEntities,
  } = builderTrpc.dataLoading.getLoadedEntities.useInfiniteQuery(
    {
      organizationId: entityType.organizationId,
      dataLoadingQuery: {
        versionType,
        entityType: {
          type: "entityTypeId",
          entityTypeId: entityType.id,
        },
        filters: filterQueryWithSearch,
      },
      dataLoadingConfig: {
        specificRelations: [],
        specificColumns: [entityType.displayNameColumn],
      },
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialCursor: 0,
      // Enforce undefined if the entity type id has changed
      placeholderData: (previousData) =>
        previousData?.pages[0]?.entities[0]?.entityTypeId !== targetEntityTypeId ? undefined : previousData,
      trpc: {
        abortOnUnmount: true,
        context: {
          skipBatch: true,
        },
      },
    },
  );

  const selectableEntities = entitiesPagedQuery?.pages.flatMap((entityPage) => entityPage.entities);
  const entityNamesByIds = keyByAndMapValues(
    concat(selectableEntities ?? [], selectedEntitiesQuery?.entities ?? []),
    (entity) => ({
      key: entity.entityId,
      value: entity.displayName,
    }),
  );

  const selection: IOption[] = useMemoDeepCompare(
    () =>
      selectedEntityIds?.map((entityId) => ({
        value: entityId,
        label: entityNamesByIds[entityId] ?? "Loading...",
      })) ?? [],
    [selectedEntityIds, entityNamesByIds],
  );

  const handleChange = useCallback(
    (values: IOption[]): void => {
      if (values.length === 0) {
        onChange(undefined);
      } else {
        onChange({
          eq: values.map((v) => ({
            type: "value",
            value: {
              type: "string" as const,
              value: v.value,
            },
          })),
        });
      }
    },
    [onChange],
  );

  const handleSearch = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const options = useMemo(() => {
    return map(entityNamesByIds, (name, id) => ({ value: id, label: name }));
  }, [entityNamesByIds]);

  return (
    <MultiSelect
      isLoading={isLoadingEntities || isFetchingEntities || isLoadingSelectedEntities || isFetchingSelectedEntities}
      options={options}
      placeholder={`Select ${entityType.displayMetadata.name}...`}
      value={selection}
      onChange={handleChange}
      onSearch={handleSearch}
    />
  );
};
