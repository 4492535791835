import type { TextMatchTransformer } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type { LexicalCommand, LexicalNode, TextNode } from "lexical";
import {
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from "lexical";
import { useEffect } from "react";

import type { IAnnotation } from "../nodes/AnnotationNode";
import { $createAnnotationNode, $isAnnotationNode, AnnotationNode } from "../nodes/AnnotationNode";

export const ADD_ANNOTATION_COMMAND: LexicalCommand<IAnnotation | null> = createCommand("ADD_ANNOTATION_COMMAND");

interface IAnnotationPluginProps {
  onAnnotationClick?: (annotation: IAnnotation) => void;
  onAnnotationCreate?: (selection: string) => Promise<IAnnotation>;
  readOnly?: boolean;
}

export function AnnotationPlugin({
  onAnnotationClick: handleAnnotationClick,
  onAnnotationCreate: handleAnnotationCreate,
  readOnly,
}: IAnnotationPluginProps): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (readOnly === true) return;

    if (!editor.isEditable()) return;

    // Handle clicking on annotations to view them
    function handleClick(e: MouseEvent): void {
      const target = e.target;

      if (!(target instanceof HTMLElement)) return;

      const annotationElement = target.closest("[data-annotation-id]");

      if (!annotationElement) return;

      const annotationId = annotationElement.getAttribute("data-annotation-id");

      if (typeof annotationId !== "string" || annotationId === "") return;

      editor.getEditorState().read((): void => {
        function findAnnotationNode(node: LexicalNode): boolean {
          if ($isAnnotationNode(node)) {
            handleAnnotationClick?.(node.getAnnotation());

            return true;
          }

          if ($isElementNode(node)) {
            for (const child of node.getChildren()) {
              if (findAnnotationNode(child)) {
                return true;
              }
            }
          }

          return false;
        }

        findAnnotationNode($getRoot());
      });
    }

    const rootElement = editor.getRootElement();

    if (!rootElement) return;

    rootElement.addEventListener("click", handleClick);

    return (): void => {
      rootElement.removeEventListener("click", handleClick);
    };
  }, [editor, handleAnnotationClick, readOnly]);

  useEffect(() => {
    if (readOnly === true) return;

    if (!editor.isEditable()) return;

    return editor.registerCommand<IAnnotation | null>(
      ADD_ANNOTATION_COMMAND,
      (payload: IAnnotation | null): boolean => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) return false;

        // If onAnnotationCreate is provided, create the annotation
        if (handleAnnotationCreate) {
          void (async (): Promise<void> => {
            try {
              const annotation = await handleAnnotationCreate(selection.getTextContent());

              editor.update(() => {
                const currentSelection = $getSelection();

                if ($isRangeSelection(currentSelection)) {
                  const annotationNode = $createAnnotationNode(annotation, selection.getTextContent());

                  currentSelection.insertNodes([annotationNode]);
                }
              });
            } catch (error) {
              // Handle error appropriately in your application
              throw new Error(`Failed to create annotation: ${error instanceof Error ? error.message : String(error)}`);
            }
          })();

          return true;
        }

        // Otherwise use the payload directly if provided
        if (payload) {
          const annotationNode = $createAnnotationNode(payload, payload.text);

          selection.insertNodes([annotationNode]);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor, handleAnnotationCreate, readOnly]);

  return null;
}

// Format: {A[title](content)}
const ANNOTATION_REGEX = /\{A\[([^\]]+)\]\(([^)]+)\)\}/;

export const ANNOTATION_TRANSFORMER: TextMatchTransformer = {
  type: "text-match",
  dependencies: [AnnotationNode],
  export: (node): string | null => {
    if ($isAnnotationNode(node)) {
      const annotation = node.getAnnotation();

      return `{A[${annotation.text}](${annotation.content})}`;
    }

    return null;
  },
  importRegExp: ANNOTATION_REGEX,
  regExp: ANNOTATION_REGEX,
  replace: (textNode: TextNode, match: RegExpMatchArray): void => {
    const [, title, content] = match;

    if (typeof title === "string" && title !== "" && typeof content === "string" && content !== "") {
      const annotationNode = $createAnnotationNode(
        {
          content,
        },
        title,
      );

      textNode.replace(annotationNode);
    }
  },
};
