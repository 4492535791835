import type { Transformer } from "@lexical/markdown";
import { $convertFromMarkdownString, $convertToMarkdownString } from "@lexical/markdown";
import type { InitialConfigType } from "@lexical/react/LexicalComposer";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import type { EditorState, LexicalEditor } from "lexical";
import React, { useCallback, useMemo } from "react";

import { cn } from "../../../lib/utils";
import { inputVariants } from "../../atoms/input";
import { EditorCapabilitiesContext } from "./plugins/EditorContext";
import { FloatingToolbarPlugin } from "./plugins/FloatingToolbarPlugin";
import { BasicToolbarPlugin } from "./plugins/ToolbarPlugin";

/**
 * Helper function to convert markdown text into a Lexical editor state function
 */
export function markdownToEditorState(markdown: string, transformers: Transformer[]): () => void {
  return () => {
    // true, false to keep new lines and not merged adjacent lines
    $convertFromMarkdownString(markdown, transformers, undefined, true, false);
  };
}

/**
 * Helper function to convert a Lexical editor state into a markdown string
 */
export function editorStateToMarkdown(editorState: EditorState, transformers: Transformer[]): () => string {
  return () => {
    let markdown = "";

    editorState.read(() => {
      markdown = $convertToMarkdownString(transformers);
    });

    return markdown;
  };
}

interface IMarkdownEditorProps {
  className?: string;
  onChange?: (editor: LexicalEditor, editorState: EditorState) => void;
  readOnly?: boolean;
  placeholder?: string;
  initialConfig: InitialConfigType;
  toolbar?: "fixed" | "floating" | "none";
  children: React.ReactNode;
  small?: boolean;
  enableAnnotations?: boolean;
  innerClassName?: string;
  editorRef?: React.RefObject<LexicalEditor>;
}

export function MarkdownEditor({
  onChange,
  initialConfig,
  className,
  readOnly = false,
  placeholder = "Type something... Use @ to mention someone",
  toolbar = "fixed",
  children,
  small = false,
  innerClassName,
  enableAnnotations,
  editorRef,
}: IMarkdownEditorProps): React.JSX.Element {
  const handleChange = useCallback(
    (editorState: EditorState, editor: LexicalEditor, _tags: Set<string>) => {
      if (!readOnly) {
        onChange?.(editor, editorState);
      }
    },
    [onChange, readOnly],
  );

  const mergedConfig = useMemo(
    () => ({
      ...initialConfig,
      editable: !readOnly,
    }),
    [initialConfig, readOnly],
  );

  return (
    <EditorCapabilitiesContext.Provider value={{ enableAnnotations: enableAnnotations ?? false }}>
      <LexicalComposer initialConfig={mergedConfig}>
        <div
          className={cn(
            "relative flex size-full grow flex-col",
            !readOnly && inputVariants({ className: "h-full p-0" }),
            readOnly || toolbar === "fixed" ? "p-0" : "px-3 py-2",
            className,
          )}
        >
          {!readOnly && toolbar === "fixed" && <BasicToolbarPlugin />}
          {!readOnly && toolbar === "floating" && <FloatingToolbarPlugin />}
          <div className={cn("relative flex size-full grow overflow-y-auto")}>
            <RichTextPlugin
              ErrorBoundary={LexicalErrorBoundary}
              contentEditable={
                <ContentEditable
                  className={cn(
                    "text-ink size-full grow outline-none",
                    readOnly ? "cursor-text select-text" : "px-3 py-2",
                    small ? "text-base" : "text-lg",
                    innerClassName,
                  )}
                  readOnly={readOnly}
                />
              }
              placeholder={
                !readOnly ? (
                  <div
                    className={cn(
                      "text-placeholder pointer-events-none absolute left-0 top-0 w-full select-none px-3 py-2 tracking-wide",
                      small ? "text-base" : "text-lg",
                    )}
                  >
                    {placeholder}
                  </div>
                ) : null
              }
            />
            {children}
            <OnChangePlugin onChange={handleChange} />
            {editorRef != null && <EditorRefPlugin editorRef={editorRef} />}
          </div>
        </div>
      </LexicalComposer>
    </EditorCapabilitiesContext.Provider>
  );
}
