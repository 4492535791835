import type {
  IColumnTypeDefinitions,
  IComplexColumnType,
  ICompositeRefinementFeature,
  ICompositeType,
  IDataModelFeature,
  IDataTypeRefinementFeature,
  INonIdVersionIdentifiableFields,
} from "@archetype/dsl";
import {
  CompositeRefinementFeature,
  DataTypeRefinementFeature,
  getChildColumnForTypeDefinition,
  NumberTree,
  StringTree,
} from "@archetype/dsl";
import type {
  IColumnId,
  IColumnTypeId,
  ICompositeTypeId,
  ICompositeTypeInternalReferenceId,
  ICompositeTypeUnionedSetId,
} from "@archetype/ids";
import {
  ColumnTypeId,
  CompositeTypeId,
  CompositeTypeInternalReferenceId,
  CompositeTypeUnionedSetId,
} from "@archetype/ids";
import { forEach, groupByNoUndefined, isNonNullable, map, mapKeysAndValues, mapValues, visit } from "@archetype/utils";
import { camelCase, values } from "lodash";
import { match } from "ts-pattern";
import { v4 } from "uuid";

import { createFileLogger } from "../logger";

const logger = createFileLogger("buildDataModelFromFeatures");

type INewColumnType = Omit<IComplexColumnType, INonIdVersionIdentifiableFields>;
type INewCompositeType = Omit<ICompositeType, INonIdVersionIdentifiableFields>;

export interface IIntermediateColumnType
  extends Pick<IComplexColumnType, "id" | "definition" | "entityTypeId" | "columnId">,
    Partial<Pick<IComplexColumnType, "displayMetadata">> {}
export interface IIntermediateCompositeType
  extends Pick<ICompositeType, "id" | "entityTypeId" | "referenceToColumnMap">,
    Partial<Pick<ICompositeType, "displayMetadata">> {
  definition: {
    unions: Record<ICompositeTypeUnionedSetId, Record<ICompositeTypeInternalReferenceId, IColumnTypeId | null>>;
  };
}

export interface INewComplexDataModel {
  newColumnTypesToCreate: INewColumnType[];
  newCompositeTypesToCreate: INewCompositeType[];
}

interface IIntermediateDataModel {
  newColumnTypesToCreate: IIntermediateColumnType[];
  newCompositeTypesToCreate: IIntermediateCompositeType[];
}

type IDataTypeRefinementFeatureWithoutNonNullable = Omit<IDataTypeRefinementFeature, "id">;

export const buildDataModelFromFeatures = (features: IDataModelFeature[]): INewComplexDataModel => {
  logger.debug({ features }, "Starting buildDataModelFromFeatures");
  const cleanedFeatures = cleanFeatures(features);

  const allDataModels = cleanedFeatures.map((feature) =>
    visit<IDataModelFeature, IIntermediateDataModel>(feature, {
      dataTypeRefinement: getEntityAndColumnTypesForDataTypeRefinementFeature,
      compositeRefinement: getEntityAndColumnTypesForCompositeRefinementFeature,
    }),
  );

  const mergedIr = mergeDataModels(allDataModels);

  const res = completeIntermediateDataModel(mergedIr);

  logger.debug({ res }, "Finished buildDataModelFromFeatures");

  return res;
};

const cleanFeatures = (features: IDataModelFeature[]): IDataModelFeature[] =>
  features.filter((feature) =>
    match(feature)
      .returnType<boolean>()
      .with(
        { type: CompositeRefinementFeature.shape.type.value },
        (compositeFeature) =>
          compositeFeature.anyMustFit.reduce((sum, mustFit) => sum + mustFit.allMustFit.length, 0) !== 0,
      )
      .otherwise(() => true),
  );

const getEntityAndColumnTypesForDataTypeRefinementFeature = (
  feature: IDataTypeRefinementFeatureWithoutNonNullable,
): IIntermediateDataModel => {
  return match(feature)
    .returnType<IIntermediateDataModel>()
    .with({ refinement: { type: "numberRange" } }, () => ({
      newCompositeTypesToCreate: [],
      newColumnTypesToCreate: [],
    }))
    .with({ refinement: { type: "stringEquals" } }, () => ({
      newCompositeTypesToCreate: [],
      newColumnTypesToCreate: [],
    }))
    .with({ refinement: { type: "stringRegex" } }, () => ({
      newCompositeTypesToCreate: [],
      newColumnTypesToCreate: [],
    }))
    .with({ refinement: { type: "stringEndsWith" } }, () => ({
      newCompositeTypesToCreate: [],
      newColumnTypesToCreate: [],
    }))
    .with({ refinement: { type: "stringLength" } }, () => ({
      newCompositeTypesToCreate: [],
      newColumnTypesToCreate: [],
    }))
    .with({ refinement: { type: "enum" } }, (refinement) => {
      const referencedColumnTypeId = ColumnTypeId.generate();

      return {
        newCompositeTypesToCreate: [],
        newColumnTypesToCreate: [
          {
            id: referencedColumnTypeId,
            entityTypeId: feature.entityType,
            columnId: feature.column,
            definition: {
              type: "enum",
              primitiveType: "string",
              child: StringTree.columnType.id,
              allowedValues: refinement.refinement.allowedValues,
            },
          },
          StringTree.columnType,
        ],
      };
    })
    .exhaustive();
};

type ICompositeRefinementWithoutNonNullable = Omit<
  ICompositeRefinementFeature["anyMustFit"][number]["allMustFit"][number],
  "refinement"
> & {
  refinement: IDataTypeRefinementFeatureWithoutNonNullable["refinement"];
};

const getOrCreateInternalReferenceId = (
  columnToIdMap: Record<IColumnId, ICompositeTypeInternalReferenceId>,
  column: IColumnId,
): ICompositeTypeInternalReferenceId => {
  let referenceId = columnToIdMap[column];

  if (referenceId == null) {
    referenceId = CompositeTypeInternalReferenceId.parse(v4());
    columnToIdMap[column] = referenceId;
  }

  return referenceId;
};

export const getEntityAndColumnTypesForCompositeRefinementFeature = (
  feature: ICompositeRefinementFeature,
): IIntermediateDataModel => {
  return visit<ICompositeRefinementFeature, IIntermediateDataModel>(feature, {
    compositeRefinement: (compositeRefinement) => {
      const handledAnyMustFits: IIntermediateCompositeType["definition"]["unions"] = {};
      const allColumnTypes: IIntermediateColumnType[] = [];
      const columnToReferenceIdMap: Record<IColumnId, ICompositeTypeInternalReferenceId> = {};

      compositeRefinement.anyMustFit.forEach((anyMustFit) => {
        const refinements = anyMustFit.allMustFit;

        // Group refinements by column id...
        const refinementsByColumnId: Record<
          IColumnId,
          Array<ICompositeRefinementWithoutNonNullable["refinement"]>
        > = mapValues(
          groupByNoUndefined(refinements, (mustFit) => mustFit.column),
          (refinementsArr) => refinementsArr.map((refinement) => refinement.refinement),
        );

        // ...and merge the type trees for each column
        const typeDataByColumnId = mapValues(refinementsByColumnId, (refinementsArr, key) => {
          // This is needed to work around a limitation around lodash's typing
          const column = key;

          let allNewColumnTypesToCreate: IIntermediateColumnType[] = [];
          let finalReferencedTypeId: IColumnTypeId | null = null;

          for (const refinement of refinementsArr) {
            const { newColumnTypesToCreate } = getEntityAndColumnTypesForDataTypeRefinementFeature({
              type: DataTypeRefinementFeature.shape.type.value,
              entityType: compositeRefinement.entityType,
              column,
              refinement: refinement,
            });
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- probably checked
            const referencedId = newColumnTypesToCreate[0]!.id;

            // If this is the first one, there's nothing to merge
            if (finalReferencedTypeId == null) {
              finalReferencedTypeId = referencedId;
              allNewColumnTypesToCreate = newColumnTypesToCreate;
              continue;
            }

            const res = mergeColumnTypeTrees({
              existingColumnTypes: allNewColumnTypesToCreate,
              existingReferencedColumnTypeId: finalReferencedTypeId,
              newColumnTypes: newColumnTypesToCreate,
              newColumnTypeIdToMerge: referencedId,
            });

            allNewColumnTypesToCreate = res.newColumnTypes;
            finalReferencedTypeId = res.newReferencedColumnTypeId;
          }

          // This should never happen, but it'll make Typescript happy
          if (finalReferencedTypeId == null) {
            throw new Error("No refinements that created column types?");
          }

          return {
            types: allNewColumnTypesToCreate,
            rootRefinementTypeId: finalReferencedTypeId,
            referenceId: getOrCreateInternalReferenceId(columnToReferenceIdMap, column),
          };
        });

        const allMustFit: IIntermediateCompositeType["definition"]["unions"][ICompositeTypeUnionedSetId] = {};

        forEach(typeDataByColumnId, ({ referenceId, rootRefinementTypeId: referencedTypeId, types }) => {
          allColumnTypes.push(...types);
          allMustFit[referenceId] = referencedTypeId;
        });
        handledAnyMustFits[CompositeTypeUnionedSetId.parse(v4())] = allMustFit;
      });

      const newCompositeTypeId = CompositeTypeId.parse(v4());

      return {
        newCompositeTypesToCreate: [
          {
            id: newCompositeTypeId,
            definition: { unions: handledAnyMustFits },
            entityTypeId: compositeRefinement.entityType,
            referenceToColumnMap: mapKeysAndValues(columnToReferenceIdMap, (refId, column) => ({
              key: refId,
              value: column,
            })),
          },
        ],
        newColumnTypesToCreate: allColumnTypes,
      };
    },
  });
};

const selectColumnTypesInTree = <ColumnType extends { id: IColumnTypeId; definition?: IColumnTypeDefinitions }>(
  columnTypes: Record<IColumnTypeId, ColumnType>,
  columnTypeId: IColumnTypeId,
): ColumnType[] => {
  const columnType = columnTypes[columnTypeId];

  if (columnType == null) {
    throw new Error(`Could not find column type with id ${columnTypeId} in pre-computation column types`);
  }

  const childColumn = columnType.definition == null ? null : getChildColumnForTypeDefinition(columnType.definition);

  if (columnType.definition == null || childColumn == null) {
    return [columnType];
  }

  const res = selectColumnTypesInTree(columnTypes, childColumn);

  res.unshift(columnType);

  return res;
};

export const mergeColumnTypeTrees = ({
  existingColumnTypes,
  existingReferencedColumnTypeId,
  newColumnTypes,
  newColumnTypeIdToMerge,
}: {
  existingColumnTypes: IIntermediateColumnType[];
  existingReferencedColumnTypeId: IColumnTypeId;
  newColumnTypes: IIntermediateColumnType[];
  newColumnTypeIdToMerge: IColumnTypeId;
}): { newReferencedColumnTypeId: IColumnTypeId; newColumnTypes: IIntermediateColumnType[] } => {
  const existingReferencedColumnType = existingColumnTypes.find(
    (columnType) => columnType.id === existingReferencedColumnTypeId,
  );

  if (existingReferencedColumnType == null) {
    // Why would the newColumnTypeIdToMerge exist in the existingColumnTypes? --> Probably a mixup
    throw new Error(`Could not find column type with id ${existingReferencedColumnTypeId} in existingColumnTypes`);
  }

  const newReferencedColumnType = newColumnTypes.find((columnType) => columnType.id === newColumnTypeIdToMerge);

  if (newReferencedColumnType == null) {
    throw new Error(`Could not find column type with id ${newColumnTypeIdToMerge} in newColumnTypes`);
  }

  const existingLeafColumnType = existingColumnTypes.find(
    (columnType) => getChildColumnForTypeDefinition(columnType.definition) == null,
  );
  const newLeafColumnType = newColumnTypes.find(
    (columnType) => getChildColumnForTypeDefinition(columnType.definition) == null,
  );

  if (existingLeafColumnType == null) {
    throw new Error(`Could not find existing leaf column type`);
  }
  if (newLeafColumnType == null) {
    throw new Error(`Could not find new leaf column type`);
  }

  if (existingLeafColumnType.definition.primitiveType !== newLeafColumnType.definition.primitiveType) {
    logger.debug(
      {
        existingColumnTypes,
        newColumnTypes,
      },
      "Should not merge column types with different leaf primitive types",
    );
    // throw new Error(
    //   `Cannot merge column types with different leaf primitive types: ${
    //     existingReferencedColumnType.definition?.primitiveType ?? "[undetermined]"
    //   } and ${newReferencedColumnType.definition?.primitiveType ?? "[undetermined]"}`,
    // );

    // For now because we generate a lot of datamodels independently, default to string, then number
    let maybeStringColumn: "existing" | "new" | undefined;

    if (existingLeafColumnType.definition.primitiveType === StringTree.columnType.definition.primitiveType) {
      maybeStringColumn = "existing";
    } else if (newLeafColumnType.definition.primitiveType === StringTree.columnType.definition.primitiveType) {
      maybeStringColumn = "new";
    }

    let maybeNumberColumn: "existing" | "new" | undefined;

    if (existingLeafColumnType.definition.primitiveType === NumberTree.columnType.definition.primitiveType) {
      maybeNumberColumn = "existing";
    } else if (newLeafColumnType.definition.primitiveType === NumberTree.columnType.definition.primitiveType) {
      maybeNumberColumn = "new";
    }

    if (maybeStringColumn === "existing") {
      return {
        newReferencedColumnTypeId: existingReferencedColumnTypeId,
        newColumnTypes: existingColumnTypes,
      };
    } else if (maybeStringColumn === "new") {
      return {
        newReferencedColumnTypeId: newReferencedColumnType.id,
        newColumnTypes: newColumnTypes,
      };
    } else if (maybeNumberColumn === "existing") {
      return {
        newReferencedColumnTypeId: existingReferencedColumnTypeId,
        newColumnTypes: existingColumnTypes,
      };
    } else if (maybeNumberColumn === "new") {
      return {
        newReferencedColumnTypeId: newReferencedColumnType.id,
        newColumnTypes: newColumnTypes,
      };
    }
  }

  const existingLeafParent = existingColumnTypes.find((columnType) => {
    const child = getChildColumnForTypeDefinition(columnType.definition);

    return child != null && child === existingLeafColumnType.id;
  });

  if (existingLeafParent == null) {
    // No leaf parent should mean that the existing type is a built-in type, so we can return the other one
    return {
      newReferencedColumnTypeId: newColumnTypeIdToMerge,
      // There is only a leaf in the existing and it is no longer relevant so can be skipped
      newColumnTypes,
    };
  }

  if (existingLeafParent.definition.type === "enum" && newReferencedColumnType.definition.type === "enum") {
    // If both are enum, we can merge the enum and keep a single type
    const existingEnumValues = existingLeafParent.definition.allowedValues;
    const newEnumValues = newReferencedColumnType.definition.allowedValues;

    const groupedByOptimistic = groupByNoUndefined(existingEnumValues.concat(newEnumValues), (v) =>
      camelCase(v).toLocaleLowerCase(),
    );
    const mergedEnumValues = map(groupedByOptimistic, (equivalentValues) => equivalentValues[0]).filter(isNonNullable);

    const mergedColumnType: IIntermediateColumnType = {
      // Replaces "existingLeafParent" as a child and "newReferencedColumnType" as a parent
      id: existingLeafParent.id,
      entityTypeId: existingLeafParent.entityTypeId ?? newReferencedColumnType.entityTypeId,
      columnId: existingLeafParent.columnId ?? newReferencedColumnType.columnId,
      definition: {
        type: "enum",
        primitiveType: "string",
        allowedValues: mergedEnumValues,
        child: newReferencedColumnType.definition.child,
      },
    };

    return {
      newReferencedColumnTypeId: existingReferencedColumnTypeId,
      // Removing the current existingLeafParent and newReferencedColumnType that are replaced and the existingLeafColumnType that is dropped
      newColumnTypes: existingColumnTypes
        .filter((columnType) => columnType.id !== existingLeafColumnType.id && columnType.id !== existingLeafParent.id)
        .concat(mergedColumnType)
        // Need to filter independently because newLeafColumnType may be the same as existingLeafColumnType and we should keep it from here
        .concat(
          newColumnTypes.filter(
            // Removing the newReferencedColumnType that was replaced
            (columnType) => columnType.id !== newReferencedColumnType.id,
          ),
        ),
    };
  }

  // Merge trees by changing the child of the existing tree from a leaf to the head of the new tree
  return {
    newReferencedColumnTypeId: existingReferencedColumnTypeId,
    newColumnTypes: [
      ...existingColumnTypes.filter(
        (columnType) => columnType.id !== existingLeafColumnType.id && columnType.id !== existingLeafParent.id,
      ),
      // The new ones should stay, including the leaf as it stays the leaf of the new tree
      ...newColumnTypes,
      {
        ...existingLeafParent,
        // Merging is adding the new tree to instead of the primitive type reference in the existing tree
        // this means the top level columnTypeId for existing doesn't change and is the valid one
        definition: replaceColumnChild({
          columnType: existingLeafParent.definition,
          oldChildId: existingLeafColumnType.id,
          newChildId: newReferencedColumnType.id,
        }),
      },
    ],
  };
};

const replaceColumnChild = ({
  columnType,
  oldChildId,
  newChildId,
}: {
  columnType: IColumnTypeDefinitions;
  oldChildId: IColumnTypeId;
  newChildId: IColumnTypeId;
}): IColumnTypeDefinitions =>
  match(columnType)
    .with({ child: oldChildId }, (type) => ({ ...type, child: newChildId }))
    .otherwise(() => columnType);

export const mergeDataModels = (dataModels: IIntermediateDataModel[]): IIntermediateDataModel => {
  const columnTypesUsedByCompositesById: Record<IColumnTypeId, IIntermediateColumnType> = {};
  const compositeTypesById: Record<ICompositeTypeId, IIntermediateCompositeType> = {};

  // Need to be precomputed because we are going to access both the current (which references only columnTypeIds present in the current dataModel)
  // but also existing, which will reference a columnTypeId from a different dataModel (the one it was created in)
  const allDataModelColumnTypesMap: Record<IColumnTypeId, IIntermediateColumnType> = {};

  dataModels.forEach((dataModel) => {
    dataModel.newColumnTypesToCreate.forEach((type) => {
      allDataModelColumnTypesMap[type.id] = type;
    });
  });

  dataModels.forEach((dataModel) => {
    dataModel.newCompositeTypesToCreate.forEach((compositeType) => {
      const existingCompositeType = compositeTypesById[compositeType.id];

      if (existingCompositeType == null) {
        // Composite type isn't actually used in any entityType, so we can ignore it!
        logger.warn(
          `@buildDataModelFromFeatures — mergeDataModels: Composite type with id ${compositeType.id} is not used in any entity type, so it will be ignored.`,
        );

        return;
      }

      values(existingCompositeType.definition.unions)
        .flatMap((intersection) => values(intersection))
        .filter(isNonNullable)
        .forEach((columnTypeId) => {
          selectColumnTypesInTree(allDataModelColumnTypesMap, columnTypeId).forEach((columnType) => {
            columnTypesUsedByCompositesById[columnType.id] = columnType;
          });
        });
    });
  });

  // Select only used column types, only used by composites now
  const usedColumnTypesById: Record<IColumnTypeId, IIntermediateColumnType> = { ...columnTypesUsedByCompositesById };

  return {
    newColumnTypesToCreate: values(usedColumnTypesById),
    newCompositeTypesToCreate: values(compositeTypesById),
  };
};

const completeIntermediateDataModel = (intermediateDataModel: IIntermediateDataModel): INewComplexDataModel => {
  const finalNewColumnTypesToCreate: INewComplexDataModel["newColumnTypesToCreate"] = [];
  const finalNewCompositeTypesToCreate: INewComplexDataModel["newCompositeTypesToCreate"] = [];

  intermediateDataModel.newColumnTypesToCreate.forEach((columnType) => {
    finalNewColumnTypesToCreate.push(columnType);
  });

  intermediateDataModel.newCompositeTypesToCreate.forEach((compositeType) => {
    finalNewCompositeTypesToCreate.push({
      ...compositeType,
      definition: {
        unions: mapValues(compositeType.definition.unions, (union) =>
          mapValues(union, (columnTypeId) => columnTypeId ?? StringTree.columnType.id),
        ),
      },
    });
  });

  return {
    newColumnTypesToCreate: finalNewColumnTypesToCreate,
    newCompositeTypesToCreate: finalNewCompositeTypesToCreate,
  };
};
