import React from "react";

import { cn } from "../../../lib/utils";

interface IHeader {
  className?: string;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

export const Header: React.FC<IHeader> = ({ className, leftContent, rightContent }) => {
  return (
    <div
      className={cn(
        "bg-paper-alt theme-blue:border-none theme-blue:bg-paper z-10 flex h-14 w-full items-center justify-between border-b p-4 transition-all duration-300 ease-in-out",
        className,
      )}
    >
      <div className="flex items-center">{leftContent}</div>
      {rightContent}
    </div>
  );
};
