import type { IDataLoadingFilterAndOperator } from "@archetype/dsl";
import { cn } from "@archetype/ui";
import type { ComponentProps } from "react";
import { useCallback } from "react";

import { SubmittableInput } from "./SubmittableInput";

type ISubmittableInputProps = Omit<ComponentProps<typeof SubmittableInput>, "onSubmit">;

type ITextInputFilter = ISubmittableInputProps & {
  operator?: IDataLoadingFilterAndOperator;
  placeholder?: string;
  defaultValue?: string;
} & (
    | {
        submitAsString?: false | undefined;
        onSubmit: (operator: IDataLoadingFilterAndOperator, value: string[]) => void;
      }
    | {
        submitAsString?: true;
        onSubmit: (value: string) => void;
      }
  );

export const TextInputFilter: React.FC<ITextInputFilter> = (props) => {
  const { onSubmit, submitAsString, operator = "regex", className, ...rest } = props;

  const handleSubmit = useCallback(
    (value: string) => {
      if (submitAsString === true) {
        onSubmit(value);
      } else {
        onSubmit(operator, value.length > 0 ? [value] : []);
      }
    },
    [operator, onSubmit, submitAsString],
  );

  return (
    <SubmittableInput
      iconLeft="text"
      {...rest}
      autoFocus
      className={cn("border-0", className)}
      onSubmit={handleSubmit}
    />
  );
};
