import type { ILoadedRelationViewField } from "@archetype/core";
import type {
  IColumn,
  IDataLoadingFilterAndOperator,
  IDataLoadingQueryColumnOrFilters,
  IDataLoadingSimpleEntityFilter,
  IEntityTypeCore,
  IStableStatusEnumColumnType,
  IVersionType,
} from "@archetype/dsl";
import type { IColumnId, IEntityTypeId } from "@archetype/ids";
import {
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Icon,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  ShapeColorIcon,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@archetype/ui";
import type { ComponentProps, MouseEventHandler } from "react";
import { forwardRef, useCallback, useMemo, useRef, useState } from "react";
import { match } from "ts-pattern";

import { EntitiesSelectionSummary, RelatedEntitiesSelectionSummary } from "./EntitiesSelectionSummary";
import { FilterDropdown } from "./FilterDropdown/FilterDropdown";
import type { IAddAIFiltersFn } from "./hooks/useAddAIFilters";
import type { IAddOrUpdateFilterFn } from "./hooks/useSimpleFiltersActions";
import { getFilterOperatorLabel, getFilterOperatorsForColumnType } from "./utils/filterMatchingOptions";
import { formatDatetime } from "./utils/formatDatetime";
import { getColumnTypeIcon } from "./utils/getColumnTypeIcon";
import { getRelationViewMetadata } from "./utils/getRelationViewMetadata";

interface IInlineFilterEditor {
  allEntityTypes: Record<IEntityTypeId, IEntityTypeCore | undefined>;
  column?: IColumn;
  entityType: IEntityTypeCore;
  filter?: IDataLoadingSimpleEntityFilter;
  filterableRelations: ILoadedRelationViewField[];
  filterColumns: IColumn[];
  filterCount: number;
  onAddOrUpdateFilter: IAddOrUpdateFilterFn;
  onAddAIFilters: IAddAIFiltersFn;
  onClose?: () => void;
  onRemove: () => void;
  statusColumnId?: IColumnId;
  versionType: IVersionType;
}

export const InlineFilterEditor: React.FC<IInlineFilterEditor> = (props) => {
  const { filter, column, filterCount, onClose, ...rest } = props;

  const isEmpty = filter === undefined;
  const triggerRef = useRef<HTMLButtonElement>(null);

  const handleOnClose = useCallback(() => {
    onClose?.();

    // Attempt to focus the display value button after the popover closes
    setTimeout(() => triggerRef.current?.querySelector<HTMLButtonElement>("[data-filter-value] button")?.focus(), 50);
  }, [onClose]);

  const trigger = isEmpty ? (
    <Button className="shrink-0 px-2" iconLeft="list-filter" variant="ghost">
      {filterCount === 0 ? "Filter" : undefined}
    </Button>
  ) : (
    <InlineFilterEditorWithValue
      {...(rest as IInlineFilterEditor)}
      column={column}
      filter={filter}
      onClose={handleOnClose}
    />
  );

  return (
    <FilterDropdownPopover
      {...rest}
      ref={triggerRef}
      columns={rest.filterColumns}
      disabled={!isEmpty}
      filter={filter}
      relationViews={rest.filterableRelations}
      trigger={trigger}
      onClose={handleOnClose}
    />
  );
};

type IInlineFilterEditorWithValue = IInlineFilterEditor & Required<Pick<IInlineFilterEditor, "filter">>;

const InlineFilterEditorWithValue: React.FC<IInlineFilterEditorWithValue> = (props) => {
  const {
    allEntityTypes,
    column,
    entityType,
    filter,
    filterableRelations,
    filterColumns,
    onAddAIFilters: handleAddAIFilters,
    onAddOrUpdateFilter: handleAddOrUpdateFilter,
    onClose: handleOnClose,
    onRemove: handleRemove,
    statusColumnId,
    versionType,
  } = props;

  const isSearchFilter = filter.type === "search";

  let operators: (keyof IDataLoadingQueryColumnOrFilters)[] = isSearchFilter
    ? ["regex"]
    : getFilterOperatorsForColumnType(column?.columnType.type ?? "shortText");

  if (column?.columnType.type === "statusEnum") operators = ["eq"];

  const hasMultipleOperators = operators.length > 1;

  const handleChangeOperator = useCallback(
    (newOperator: IDataLoadingFilterAndOperator) => (): void => {
      if (filter.type !== "search") handleAddOrUpdateFilter({ ...filter, op: newOperator });
    },
    [filter, handleAddOrUpdateFilter],
  );

  const handleBooleanColumnToggle = useCallback(
    (checked: boolean): void => {
      handleAddOrUpdateFilter({ ...filter, value: [checked ? "true" : "false"] });
    },
    [handleAddOrUpdateFilter, filter],
  );

  const [fieldName, fieldIcon] = useMemo(() => {
    return match(filter)
      .returnType<[string, React.ReactNode]>()
      .with({ type: "search" }, () => ["Anything", <Icon key="icon" name="scan-search" />])
      .with({ type: "column" }, () => [
        column?.displayMetadata.name ?? "-",
        <Icon key="icon" name={getColumnTypeIcon(column?.columnType.type ?? "shortText")} />,
      ])
      .with({ type: "relation" }, (typedFilter) => {
        const relation = filterableRelations.find((r) => r.relation.id === typedFilter.relationId);

        if (!relation) return ["-", undefined];

        const metadata = getRelationViewMetadata(relation, allEntityTypes);

        if (!metadata) return ["-", undefined];

        return [
          metadata.name,
          <ShapeColorIcon key="icon" color={metadata.iconColor} shape={metadata.iconShape} size="sm" />,
        ];
      })
      .exhaustive();
  }, [filter, filterableRelations, allEntityTypes, column]);

  const operatorLabel = useMemo(() => {
    return match(filter)
      .returnType<string>()
      .with({ type: "search" }, () => "contains")
      .with({ type: "column" }, ({ op, value }) => {
        return column?.columnType.type === "statusEnum"
          ? getFilterOperatorLabel(value.length > 1 ? "anyInArray" : "eq")
          : getFilterOperatorLabel(op, column?.columnType.type);
      })
      .with({ type: "relation" }, ({ value }) => getFilterOperatorLabel(value.length > 1 ? "anyInArray" : "eq"))
      .otherwise(() => getFilterOperatorLabel("eq"));
  }, [filter, column?.columnType.type]);

  const displayValue = useMemo(() => {
    const values = filter.value;
    const columnType = column?.columnType.type;

    if (columnType === "boolean") {
      const isChecked = values[0] === "true";

      return (
        <div data-filter-value className="h-full">
          <div className="flex h-full items-center px-2 py-1">
            <Switch small checked={isChecked} onCheckedChange={handleBooleanColumnToggle} />
          </div>
        </div>
      );
    }

    let content: React.ReactNode = <EntitiesSelectionSummary entities={values} />;

    if (["date", "timestamp"].includes(columnType ?? "")) {
      content = formatDatetime(values[0], columnType === "timestamp");
    }

    if (columnType === "statusEnum") {
      const statuses: string[] = [];
      const statusColumnType = entityType.columns.find((c) => c.id === column?.id)?.columnType as
        | IStableStatusEnumColumnType
        | undefined;

      if (statusColumnType) {
        values.forEach((v) => {
          const statusState = statusColumnType.allowedValues.find((av) => av.id === v);

          statuses.push(statusState?.readableValue ?? "-");
        });
      }

      content = <EntitiesSelectionSummary entities={statuses} entityName="Statuses" />;
    }

    if (filter.type === "relation") {
      content = "";

      const relation = filterableRelations.find((r) => r.relation.id === filter.relationId);

      if (relation) {
        const metadata = getRelationViewMetadata(relation, allEntityTypes);
        const relatedEntityType = metadata ? allEntityTypes[metadata.entityId] : undefined;

        if (relatedEntityType) {
          content = (
            <RelatedEntitiesSelectionSummary
              entityIds={values}
              entityType={relatedEntityType}
              versionType={versionType}
            />
          );
        }
      }
    }

    return (
      <FilterDropdownPopover
        allEntityTypes={allEntityTypes}
        columns={filterColumns}
        entityType={entityType}
        filter={filter}
        relationViews={filterableRelations}
        statusColumnId={statusColumnId}
        trigger={
          <PopoverTrigger asChild data-filter-value>
            <div className="h-full">
              <Button className="rounded-none px-2 text-base font-normal focus-visible:ring-offset-0" variant="ghost">
                {content == null || content === "" ? "..." : content}
              </Button>
            </div>
          </PopoverTrigger>
        }
        versionType={versionType}
        onAddAIFilters={handleAddAIFilters}
        onAddOrUpdateFilter={handleAddOrUpdateFilter}
        onClose={handleOnClose}
      />
    );
  }, [
    filter,
    column?.columnType.type,
    column?.id,
    allEntityTypes,
    filterColumns,
    entityType,
    filterableRelations,
    statusColumnId,
    versionType,
    handleAddAIFilters,
    handleAddOrUpdateFilter,
    handleOnClose,
    handleBooleanColumnToggle,
  ]);

  return (
    <div className="border-secondary-background flex flex-nowrap rounded border text-base">
      <div className="border-r-muted-background flex max-w-40 items-center gap-1 whitespace-nowrap border-r px-2 py-1">
        <span className="shrink-0">{fieldIcon}</span>
        <Tooltip delayDuration={300}>
          <TooltipTrigger asChild>
            <span className="truncate">{fieldName}</span>
          </TooltipTrigger>
          <TooltipContent className="max-w-64" side="bottom">
            {fieldName}
          </TooltipContent>
        </Tooltip>
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild disabled={!hasMultipleOperators}>
          <div className="border-r-muted-background h-full border-r">
            <Button
              className={cn(
                "text-muted-foreground disabled:text-muted-foreground rounded-none px-2 text-base font-normal focus-visible:ring-offset-0",
                !hasMultipleOperators && "pointer-events-none focus-visible:ring-0",
              )}
              disabled={!hasMultipleOperators}
              variant="ghost"
            >
              {operatorLabel}
            </Button>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="w-56">
          {operators.map((op) => (
            <DropdownMenuItem key={op} onSelect={handleChangeOperator(op)}>
              {getFilterOperatorLabel(op, column?.columnType.type ?? "shortText")}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      <div className="border-r-muted-background whitespace-nowrap border-r text-base">{displayValue}</div>

      <div>
        <Button
          className="size-full rounded-l-none rounded-r px-2 py-1 focus-visible:ring-offset-0"
          variant="ghost"
          onClick={handleRemove}
        >
          <Icon className="size-3" name="x" />
        </Button>
      </div>
    </div>
  );
};

interface IFilterDropdownPopover extends Omit<ComponentProps<typeof FilterDropdown>, "filterColumns"> {
  trigger: React.ReactNode;
  disabled?: boolean;
  onClose?: () => void;
}

const FilterDropdownPopover = forwardRef<React.ElementRef<typeof PopoverTrigger>, IFilterDropdownPopover>(
  ({ trigger, disabled, onClose, ...rest }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const onCloseRequest = useCallback(() => {
      setIsOpen(false);
      onClose?.();
    }, [onClose]);

    const handleOnOpenChange = useCallback(
      (open: boolean) => {
        setIsOpen(open);

        if (!open) onClose?.();
      },
      [setIsOpen, onClose],
    );

    // Prevents nested popover triggers from opening the outer popover
    const handleTriggerClick = useCallback<MouseEventHandler<HTMLDivElement>>(
      (e) => {
        if (disabled === true) e.stopPropagation();
      },
      [disabled],
    );

    return (
      <Popover modal open={isOpen} onOpenChange={handleOnOpenChange}>
        <PopoverTrigger ref={ref} asChild>
          <div>
            <div onClick={handleTriggerClick}>{trigger}</div>
          </div>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent align="start" className="w-auto p-0">
            <FilterDropdown {...rest} requestPopoverClose={onCloseRequest} />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    );
  },
);

FilterDropdownPopover.displayName = "FilterDropdownPopover";
