import Link from "next/link";

import type { IUntypedRoute } from "../../api/untyped-route";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import { Icon, type IIconNames } from "./icon";

type INonIdealStateVariant = "notFound" | "permissionDenied" | "error" | "accessRevoke";

type IConfigType = {
  icon: IIconNames;
  message: string;
};

const VARIANT_CONFIGS: Record<INonIdealStateVariant, IConfigType> = {
  notFound: {
    icon: "search-x",
    message: "No results found",
  },
  permissionDenied: {
    icon: "shield-x",
    message: "You don't have access to this",
  },
  error: {
    icon: "frown",
    message: "Something went wrong",
  },
  accessRevoke: {
    icon: "shield-x",
    message: "You no longer have access to this",
  },
} as const;

interface INonIdealStateProps {
  backRoute?: IUntypedRoute;
  className?: string;
  variant?: INonIdealStateVariant;
  resourceType?: string;
  icon?: IIconNames;
  message?: string;
}

export function NonIdealState({
  backRoute,
  className,
  variant,
  resourceType,
  icon,
  message: customMessage,
}: INonIdealStateProps): React.JSX.Element {
  const config =
    variant != null
      ? {
          ...VARIANT_CONFIGS[variant],
          message:
            (variant === "permissionDenied" || variant === "accessRevoke") &&
            resourceType != null &&
            resourceType.trim() !== ""
              ? `${VARIANT_CONFIGS[variant].message} ${resourceType.toLowerCase()}`
              : VARIANT_CONFIGS[variant].message,
        }
      : {
          icon: icon ?? "frown",
          message: customMessage ?? "Something went wrong",
        };

  return (
    <div
      className={cn(
        "border-border bg-accent-background text-muted-foreground flex w-full flex-col items-center justify-center gap-4 rounded border p-8 text-center",
        className,
      )}
    >
      <Icon className="size-8 stroke-1" name={config.icon} />
      <p className="text-lg">{config.message}</p>
      {backRoute != null && (
        <Link href={backRoute}>
          <Button className="text-muted-foreground hover:text-muted-foreground/80 gap-2" size="sm" variant="ghost">
            Go back
          </Button>
        </Link>
      )}
    </div>
  );
}
