import { useCallback, useEffect } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableEditingContext } from "../context/DataTableEditingContext";

export function useCellSaveOnEnter({
  onEnter,
  isCellSelected,
  isCellEditing,
}: {
  onEnter: () => void;
  isCellSelected: boolean;
  isCellEditing: boolean;
}): void {
  const setEditableCell = useContextSelector(DataTableEditingContext, (state) => state.setEditableCell);

  const handleSave = useCallback(() => {
    if (isCellSelected && isCellEditing) {
      onEnter();

      setEditableCell(null);
    }
  }, [isCellSelected, isCellEditing, setEditableCell, onEnter]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter" && isCellSelected && isCellEditing) {
        e.preventDefault();
        e.stopPropagation();
        handleSave();
      }
    },
    [isCellSelected, isCellEditing, handleSave],
  );

  useEffect(() => {
    if (isCellSelected && isCellEditing) {
      document.addEventListener("keydown", handleKeyDown);

      return (): void => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isCellSelected, isCellEditing, handleKeyDown]);
}
