import type { IAutofill, IColumnType, ILLMColumnAutofill, ILLMColumnType } from "@archetype/dsl";
import assertNever from "assert-never";

export function convertLLMColumnAutofillToAutofill(autofill: ILLMColumnAutofill | null): IAutofill | null {
  if (autofill === null) {
    return null;
  } else if ("staticValue" in autofill) {
    return {
      type: "snapshot",
      config: {
        type: "static",
        value: autofill.staticValue,
      },
    };
  } else if ("dynamicValue" in autofill) {
    return {
      type: "snapshot",
      config: {
        type: "dynamic",
        value: autofill.dynamicValue,
      },
    };
  } else if ("logicDescription" in autofill) {
    return {
      type: autofill.recomputationType === "suggestion" ? "snapshot" : "live",
      config: {
        type: "logic",
        logic: autofill.logicDescription,
        rawLogic: autofill.logicDescription,
        steps: [],
        dependencies: [],
      },
    };
  } else if ("aiInstructions" in autofill) {
    return {
      type: autofill.recomputationType === "suggestion" ? "snapshot" : "live",
      config: {
        type: "ai",
        instructions: autofill.aiInstructions,
        rawInstructions: autofill.aiInstructions,
        dependencies: [],
      },
    };
  }
  assertNever(autofill);
}

export function convertLLMColumnTypeToColumnType(columnType: ILLMColumnType): IColumnType {
  switch (columnType.type) {
    case "email":
    case "shortText":
    case "longText":
    case "number":
    case "boolean":
    case "date":
    case "timestamp":
    case "geolocation":
    case "phone":
    case "file":
    case "url": {
      return {
        type: columnType.type,
      };
    }
    case "enum": {
      return {
        type: "enum",
        enumAllowedValues: columnType.enumValues ?? [],
        enumInclusiveMaxValuesToSelect: columnType.enumAllowMultipleValuesSelection === true ? null : 1,
      };
    }
    default: {
      assertNever(columnType.type);
    }
  }
}
