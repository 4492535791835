import type { IEmailMessageId } from "@archetype/ids";
import { EmailMessageId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { EmailRecipient } from "./EmailRecipient";

// Like git has many branches, all of which start from one initial commit
// and branch out at various commits, email is very similar.
// it starts from one initial email which when responded and forwarded creates
// multiple branches. from all of the emails you can go up to the initial email.
// so we use this types to talk about thread Branch which is basically pointer to specific
// email which from which we can go up to the root email (like from specific commit
// we can follow all previous commits).
export type IThreadBranch<T> = IThreadBranchTop<T> | IThreadBranchReply<T> | IThreadBranchForward<T>;
export type IThreadBranchTop<T> = { type: "top"; msg: T };
export type IThreadBranchReply<T> = { type: "reply"; msg: T; of: IThreadBranch<T> };
export type IThreadBranchForward<T> = { type: "forward"; msg: T; of: IThreadBranch<T> };

// This is like full git tree with all the branchings in it. as mentioned above,
// all starts from initial email "top", which can hav e N number of repays, and forwards,
// resulting in this kind of tree structure.
// also this tree is binary tree where we have data at the branches and on each side
// we have N children. while IThreadBranch is inverted form of a specific node in a tree.
export type IThreadTree<T> = { top: T; forwards: IThreadTree<T>[]; replies: IThreadTree<T>[] };

// Constructs thread tree from one email message
export const leafNode = <T>(msg: T): IThreadTree<T> => ({ top: msg, forwards: [], replies: [] });

export const threadBranchDepth = (branch: IThreadBranch<unknown>): number => {
  if (branch.type === "top") {
    return 0;
  }

  return 1 + threadBranchDepth(branch.of);
};

// Like arrays lists promises we can map over the values of the IThreadTree while
// preserving it's structure.
// some laws hold here
//
// expect(mapIThreadTree(t,_ => _) ).toEqual(t)
// expect(mapIThreadTree(mapIThreadTree(t, g), f) ).toEqual(mapIThreadTree(t, _ => f(g(_))))
export const mapIThreadTree = <T, U>(tree: IThreadTree<T>, map: (msg: T) => U): IThreadTree<U> => ({
  top: map(tree.top),
  forwards: tree.forwards.map((_) => mapIThreadTree(_, map)),
  replies: tree.replies.map((_) => mapIThreadTree(_, map)),
});

// Describes and email message with minimal viable information
export const EmailMsg = z.object({
  id: EmailMessageId,
  date: ReadableString,
  subject: ReadableString,
  body: ReadableString,
  from: EmailRecipient,
  to: z.array(EmailRecipient),
  cc: z.array(EmailRecipient),
  bcc: z.array(EmailRecipient),
});

export type IEmailMsg = z.infer<typeof EmailMsg>;

// Describes email message with references, used to reconstruct the IThreadBranch
export type IEmailMsgWithRefs = {
  msg: IEmailMsg;
  references: IEmailMessageId[];
};

const ThreadBranchOf = <T extends z.ZodTypeAny>(item: T): z.ZodType<IThreadBranch<z.infer<T>>> => {
  const threadBranchSchema = z.lazy(
    (): z.ZodType<IThreadBranch<z.infer<T>>> =>
      z.discriminatedUnion("type", [
        z.object({
          type: z.literal("top"),
          msg: item,
        }),
        z.object({
          type: z.literal("reply"),
          msg: item,
          of: threadBranchSchema,
        }),
        z.object({
          type: z.literal("forward"),
          msg: item,
          of: threadBranchSchema,
        }),
      ]) as z.ZodType<IThreadBranch<z.infer<T>>>,
  );

  return threadBranchSchema;
};

export const ThreadBranchOfEmailMsg: z.ZodSchema<IThreadBranch<IEmailMsg>> = ThreadBranchOf(EmailMsg);
