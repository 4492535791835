import { useCallback } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableEditingContext } from "../context/DataTableEditingContext";
import { DataTableSelectionContext } from "../context/DataTableSelectionContext";

export function useCellClickInteraction({
  rowId,
  colId,
  readOnly,
  isCellSelected,
  isCellEditing,
}: {
  rowId: string;
  colId: string;
  readOnly: boolean;
  isCellSelected: boolean;
  isCellEditing: boolean;
}): {
  handleCellClick: () => void;
} {
  const selectedCells = useContextSelector(DataTableSelectionContext, (state) => state.selectedCells);
  const selectCell = useContextSelector(DataTableSelectionContext, (state) => state.selectCell);
  const setEditableCell = useContextSelector(DataTableEditingContext, (state) => state.setEditableCell);

  const startEditing = useCallback(() => {
    if (!readOnly && isCellSelected && !isCellEditing) {
      setEditableCell({ rowId, colId });
    }
  }, [readOnly, isCellSelected, isCellEditing, rowId, colId, setEditableCell]);

  const handleCellClick = useCallback(() => {
    if (readOnly) {
      return;
    }

    if (selectedCells.get(rowId)?.has(colId) !== true) {
      selectCell(rowId, colId);
      setEditableCell(null);
    } else {
      startEditing();
    }
  }, [readOnly, selectedCells, rowId, colId, selectCell, setEditableCell, startEditing]);

  return {
    handleCellClick,
  };
}
