import type { IDependenciesFieldIdsInfo, ILoadedAction, ILoadedEntityType, ILoadedViewField } from "@archetype/core";
import {
  DATA_MODEL_OPERATIONS,
  entityTypeCoreFromLoaded,
  generateLoadedViewFieldsMapForEntityType,
  getDisabledOperationsByViewFieldId,
  isCreateAction,
} from "@archetype/core";
import { isLiveAutofill, type IVersionType } from "@archetype/dsl";
import type { IViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { keyByNoUndefined, mapValues } from "@archetype/utils";
import { uniqBy, values } from "lodash";
import { useMemo } from "react";

import { withRefetchInterval } from "../../utils/refetchInterval";
import { sortFields } from "../../utils/sortFields";

export function useActionFields({
  action,
  entityType,
  versionType,
}: {
  action: ILoadedAction;
  entityType: ILoadedEntityType;
  versionType: IVersionType;
}): {
  contextualFields: ILoadedViewField[];
  regularFields: {
    fixed: ILoadedViewField[];
    mutable: ILoadedViewField[];
  };
  hiddenFields: {
    fixed: ILoadedViewField[];
    mutable: ILoadedViewField[];
  };
  usedFields: {
    fixed: ILoadedViewField[];
    mutable: ILoadedViewField[];
  };
  unusedFields: ILoadedViewField[];
  disabledOperationsByFieldId: Record<IViewFieldId, Set<DATA_MODEL_OPERATIONS>>;
  allViewFieldsById: Record<IViewFieldId, ILoadedViewField>;
  fieldsThatCannotBeRemoved: Record<IViewFieldId, boolean>;
  dependenciesInfo: IDependenciesFieldIdsInfo | undefined;
} {
  const { data: relationsQuery } = builderTrpc.dataModel.getRelationsByEntityTypeId.useQuery({
    versionType,
    entityTypeId: entityType.id,
  });

  const { data: dependenciesQuery } = builderTrpc.dataModel.getFieldsDependencyFieldIds.useQuery(
    {
      versionType,
      entityTypeId: entityType.id,
    },
    {
      ...withRefetchInterval(10000),
    },
  );

  const results = useMemo(() => {
    const actionViewFields: ILoadedViewField[] = action.actionDefinition.inputs.map((i) => i.viewField);
    const actionViewFieldIds = new Set(actionViewFields.map((viewField) => viewField.id));

    const actionInputsByFieldId = keyByNoUndefined(action.actionDefinition.inputs, (input) => input.viewField.id);

    const innerShownNotOrderable: ILoadedViewField[] = [];
    const innerShown: ILoadedViewField[] = [];

    const innerHiddenNotOrderable: ILoadedViewField[] = [];
    const innerHidden: ILoadedViewField[] = [];

    const relations = relationsQuery?.relations ?? {};

    const entityTypeCore = entityTypeCoreFromLoaded(entityType);
    const disabledOperationsByFieldId = getDisabledOperationsByViewFieldId(entityTypeCore);
    const allViewFieldsById = generateLoadedViewFieldsMapForEntityType(entityTypeCore, relations);

    actionViewFields.forEach((viewField) => {
      const input = actionInputsByFieldId[viewField.id];

      if (input == null) {
        return;
      }

      if (
        disabledOperationsByFieldId[viewField.id]?.has(DATA_MODEL_OPERATIONS.reorder) === true ||
        (isCreateAction(action.actionDefinition) &&
          disabledOperationsByFieldId[viewField.id]?.has(DATA_MODEL_OPERATIONS.reorderCreateTransition) === true)
      ) {
        if (input.allowChangingDefault || (viewField.autofill != null && isLiveAutofill(viewField.autofill))) {
          innerShownNotOrderable.push(viewField);
        } else {
          innerHiddenNotOrderable.push(viewField);
        }
      } else {
        if (input.allowChangingDefault || (viewField.autofill != null && isLiveAutofill(viewField.autofill))) {
          innerShown.push(viewField);
        } else {
          innerHidden.push(viewField);
        }
      }
    });

    const fieldsThatCannotBeRemoved = mapValues(
      disabledOperationsByFieldId,
      (disabledOperations) =>
        (!isCreateAction(action.actionDefinition) &&
          disabledOperations.has(DATA_MODEL_OPERATIONS.removeFromAnyTransition)) ||
        (isCreateAction(action.actionDefinition) &&
          disabledOperations.has(DATA_MODEL_OPERATIONS.removeFromCreateTransition)),
    );

    const unusedFields = uniqBy(
      values(allViewFieldsById).filter(
        (viewField) =>
          !actionViewFieldIds.has(viewField.id) &&
          disabledOperationsByFieldId[viewField.id]?.has(DATA_MODEL_OPERATIONS.show) !== true,
      ),
      (f) => f.id,
    );

    const sortedUnusedFields = sortFields(unusedFields, entityType);

    return {
      contextualFields: uniqBy(action.actionDefinition.contextualFields, (f) => f.id),
      usedFields: {
        fixed: uniqBy(innerShownNotOrderable.concat(innerHiddenNotOrderable), (f) => f.id),
        mutable: uniqBy(innerShown.concat(innerHidden), (f) => f.id),
      },
      hiddenFields: {
        fixed: uniqBy(innerHiddenNotOrderable, (f) => f.id),
        mutable: uniqBy(innerHidden, (f) => f.id),
      },
      regularFields: {
        fixed: uniqBy(innerShownNotOrderable, (f) => f.id),
        mutable: uniqBy(innerShown, (f) => f.id),
      },
      unusedFields: sortedUnusedFields,
      disabledOperationsByFieldId,
      allViewFieldsById,
      fieldsThatCannotBeRemoved,
    };
  }, [action.actionDefinition, entityType, relationsQuery?.relations]);

  return {
    ...results,
    dependenciesInfo: dependenciesQuery,
  };
}
