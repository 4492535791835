import type { ILoadedEntityType, ILoadedViewField } from "@archetype/core";
import { isDerivedViewField, isFieldValueNullableLike, isLoadedRelationViewField } from "@archetype/core";
import { generateLoadedViewFieldsMapForEntityType, type ILoadedEntity } from "@archetype/core";
import type { IEntityTypeCore, IVersionType } from "@archetype/dsl";
import { isLiveAutofill } from "@archetype/dsl";
import { computeColumnViewFieldId, FieldValueParser } from "@archetype/dsl";
import type { IEntityTypeId, IOrganizationId, IViewFieldId } from "@archetype/ids";
import { useMemoDeepCompare } from "@archetype/ui";
import { isNonNullable } from "@archetype/utils";
import { uniqBy, values } from "lodash";
import React from "react";

import type { IGetHighlightedViewFieldRoute, IGetLinkedEntityRoute } from "../api";
import { getIconForViewField } from "../entityType/ColumnTypeUtils";
import { useEntityHasSavingAction } from "../hooks/entity/useEntityHasSavingAction";
import type { IFieldRendererField } from "./FieldRenderer";
import { FieldsRenderer } from "./FieldsRenderer";
import { fieldValueToFieldRendererValue } from "./fieldValueToFieldRendererValue";

interface IEntityFields {
  versionType: IVersionType;
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>>;
  entity: ILoadedEntity;
  entityType: ILoadedEntityType;
  getLinkedEntityRoute?: IGetLinkedEntityRoute;
  getHighlightedViewFieldRoute?: IGetHighlightedViewFieldRoute;
  highlightedViewFieldId?: IViewFieldId;
  isLoadingContextActions?: boolean;
  organizationId: IOrganizationId;
  validRelationFieldsIds: Record<IViewFieldId, boolean | undefined> | undefined;
}

export const EntityFields: React.FC<IEntityFields> = ({
  allEntityTypes,
  entity,
  entityType,
  versionType,
  getLinkedEntityRoute,
  getHighlightedViewFieldRoute,
  highlightedViewFieldId,
  isLoadingContextActions,
  validRelationFieldsIds,
  organizationId,
}) => {
  const entityTypeId = entity.entityTypeId;

  const entitySavingActionState = useEntityHasSavingAction({ versionType, entityTypeId, entityId: entity.entityId });

  // iterate over columns to display, create an array of display name, value, and id for each
  const statusFieldId = entityType.statusColumn == null ? undefined : computeColumnViewFieldId(entityType.statusColumn);
  const statusValue = statusFieldId == null ? undefined : entity.fields[statusFieldId];
  const statusValueAsString = statusValue == null ? undefined : FieldValueParser.toString(statusValue);

  const relevantFieldsForState = useMemoDeepCompare(
    () => (statusValueAsString == null ? undefined : entityType.relevantViewFieldsByStateId?.[statusValueAsString]),
    [statusValueAsString, entityType.relevantViewFieldsByStateId],
  );

  const allViewFields = useMemoDeepCompare(
    () =>
      generateLoadedViewFieldsMapForEntityType(
        { id: entityType.id, columns: values(entityType.columns).filter(isNonNullable) },
        entityType.relations,
      ),
    [entityType],
  );

  const relevantViewFields: ILoadedViewField[] = useMemoDeepCompare(
    () =>
      uniqBy(
        relevantFieldsForState?.map((f) => allViewFields[f.id]).filter(isNonNullable) ?? values(allViewFields),
        (f) => f.id,
      ),
    [relevantFieldsForState, allViewFields],
  );

  const hasExplicitRelevantFields = relevantFieldsForState != null;

  const hiddenFieldIds = useMemoDeepCompare(() => {
    const results = new Set<IViewFieldId>();

    if (entityType.statusColumn != null) {
      results.add(computeColumnViewFieldId(entityType.statusColumn));
    }

    results.add(computeColumnViewFieldId(entityType.primaryKey));
    results.add(computeColumnViewFieldId(entityType.displayNameColumn));

    return results;
  }, [entityType]);

  const fields = useMemoDeepCompare(() => {
    const results: IFieldRendererField[] = [];

    relevantViewFields.forEach((f) => {
      if (hiddenFieldIds.has(f.id)) {
        return;
      }

      const isNullLike = isFieldValueNullableLike(entity.fields[f.id]);

      if (
        !hasExplicitRelevantFields &&
        isNullLike &&
        isLoadedRelationViewField(f) &&
        validRelationFieldsIds?.[f.id] !== true
      ) {
        // If we are with an explicit list of relevant view fields, or if there is a value, always show
        // otherwise we hide if a relation view field is not in the valid ones
        return;
      }

      const computationStatus = entity.fieldsComputationStatuses[f.id];
      const isAiAutofilled = computationStatus?.kind === "aiComputed" && computationStatus.status !== "userEdited";
      const isDerived = f.autofill == null ? false : isLiveAutofill(f.autofill) && isDerivedViewField(f);

      const field: IFieldRendererField = {
        id: f.id,
        icon: getIconForViewField(f, allEntityTypes),
        displayName: f.displayName,
        value: fieldValueToFieldRendererValue(entity.fields[f.id], f),
        computationStatus: computationStatus?.status,
        isAiAutofilled,
        isDerived,
        isSavingInAction: entitySavingActionState.savingFieldIds[f.id] === true,
        column: isLoadedRelationViewField(f) ? undefined : { columnType: f.column.columnType },
      };

      results.push(field);
    });

    return results;
  }, [
    relevantViewFields,
    entity.fields,
    entity.fieldsComputationStatuses,
    entitySavingActionState.savingFieldIds,
    allEntityTypes,
    hiddenFieldIds,
    validRelationFieldsIds,
    hasExplicitRelevantFields,
  ]);

  return (
    <FieldsRenderer
      entity={entity}
      entityId={entity.entityId}
      entityTypeId={entityTypeId}
      fields={fields}
      getHighlightedViewFieldRoute={getHighlightedViewFieldRoute}
      getLinkedEntityRoute={getLinkedEntityRoute}
      highlightedViewFieldId={highlightedViewFieldId}
      isLoadingContextActions={isLoadingContextActions}
      organizationId={organizationId}
      versionType={versionType}
    />
  );
};
