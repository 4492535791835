import { match } from "ts-pattern";
import { z } from "zod";

export const OrganizationRole = z.union([z.literal("member"), z.literal("builder"), z.literal("admin")]);

export const ALL_ORGANIZATION_ROLES: IOrganizationRole[] = OrganizationRole.options.map((o) => o.value);
export const DEFAULT_ORGANIZATION_ROLE: IOrganizationRole = "member";

export const mapClerkRoleToOrganizationRole = (clerkRole: string): IOrganizationRole => {
  return match(clerkRole)
    .returnType<IOrganizationRole>()
    .with("admin", () => "admin")
    .with("org:builder", () => "builder")
    .with("basic_member", () => "member")
    .with("org:member", () => "member")
    .otherwise(() => DEFAULT_ORGANIZATION_ROLE);
};

export type IOrganizationRole = z.infer<typeof OrganizationRole>;
