import { match, P } from "ts-pattern";

import type { IColumn } from "../../schemas/dataModel/Column";
import type { IColumnTypeTree } from "./resolveTypeTree";

export const getHumanReadableColumnName = (column: Pick<IColumn, "displayMetadata">): string => {
  return column.displayMetadata.name;
};

export interface IHumanReadableRefinement {
  text: string;
  children?: IHumanReadableRefinement[];
}

export const getHumanReadableRefinement = (tree: IColumnTypeTree): IHumanReadableRefinement | null =>
  match(tree.columnType.definition)
    .returnType<IHumanReadableRefinement | null>()
    .with({ type: "stringLength", inclusiveMin: P.not(P.nullish), inclusiveMax: P.nullish }, ({ inclusiveMin }) => ({
      text: `min length ${inclusiveMin.toString()}`,
    }))
    .with({ type: "stringLength", inclusiveMin: P.nullish, inclusiveMax: P.not(P.nullish) }, ({ inclusiveMax }) => ({
      text: `max length ${inclusiveMax.toString()}`,
    }))
    .with(
      { type: "stringLength", inclusiveMin: P.not(P.nullish), inclusiveMax: P.not(P.nullish) },
      ({ inclusiveMin, inclusiveMax }) => ({
        text: `length from ${inclusiveMin.toString()} to ${inclusiveMax.toString()} inclusive`,
      }),
    )
    .with({ type: "stringRegex" }, ({ regex }) => ({ text: `matches regex "${regex}"` }))
    .otherwise(() => null);
