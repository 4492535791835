import type { IFeatureId } from "@archetype/ids";

import { AbstractError } from "./AbstractError";

export class FeatureIdMissingFromMap extends AbstractError<"FeatureIdMissingFromMap"> {
  constructor({ featureId }: { featureId: IFeatureId }) {
    super({
      name: "FeatureIdMissingFromMap",
      message: `Feature id ${featureId} is missing from map`,
    });
  }
}
