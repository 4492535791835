import type { PhoneNumber } from "libphonenumber-js";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { z } from "zod";

export const phoneColumnValidator = z.string().refine(
  (value) => {
    // we can check validation of international numbers, we can't check validation of national numbers because we don't know the country
    if (value.startsWith("+")) {
      const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(value);

      return phoneNumber?.isValid() ?? false;
    }

    return true;
  },
  {
    message: "Invalid phone number",
  },
);
