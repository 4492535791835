import type { IVersionType } from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { ShapeColorIcon } from "@archetype/ui";
import { Skeleton } from "@archetype/ui";
import { skipToken } from "@tanstack/react-query";

interface IEntityTypeBreadcrumb {
  entityTypeId?: IEntityTypeId; // TODO @sspringer this should be required but for now we need to support application groups loading this breadcrumb
  versionType: IVersionType;
}

export const EntityTypeBreadcrumb: React.FC<IEntityTypeBreadcrumb> = ({ entityTypeId, versionType }) => {
  const { data: entityTypeQuery } = builderTrpc.dataModel.fullyLoadedEntityType.useQuery(
    entityTypeId == null
      ? skipToken
      : {
          versionType,
          id: entityTypeId,
        },
  );

  const entityType = entityTypeQuery?.entityType;

  if (entityType == null) {
    return (
      <div className="flex items-center space-x-2">
        <Skeleton className="size-6" />
        <Skeleton className="h-6 w-16" />
      </div>
    );
  }

  return (
    <span className="flex items-center space-x-2">
      <ShapeColorIcon color={entityType.color} shape={entityType.shape} size="sm" />
      <span className="truncate">{entityType.displayMetadata.pluralName}</span>
    </span>
  );
};
