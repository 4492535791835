import type { ILayoutId } from "@archetype/ids";

import { AbstractError } from "./AbstractError";

export class LayoutNotDefinedInRegistry extends AbstractError<"LayoutNotDefinedInRegistry"> {
  constructor({ layoutId }: { layoutId: ILayoutId }) {
    super({
      name: "LayoutNotDefinedInRegistry",
      message: `Layout ${layoutId} is not defined in the registry`,
    });
  }
}
