import type { IEntityTypeId, IRelationId } from "@archetype/ids";
import { concat, values } from "lodash";

import type { IViewField } from "../..";
import type { IEntityType } from "../../schemas/dataModel/EntityType";
import type { IRelationCore } from "../../schemas/dataModel/Relation";
import { createColumnViewField, createRelationViewField } from "../../schemas/dataModel/ViewField";

export function generateViewFieldsForEntityType({
  entityType,
  relationsById,
}: {
  entityType: {
    id: IEntityTypeId;
    columns: IEntityType["columns"];
  };
  relationsById: Record<IRelationId, IRelationCore>;
}): IViewField[] {
  const columnFields: IViewField[] = entityType.columns.map((c) => createColumnViewField(c.id));
  const relationFields: IViewField[] = concat(
    values(relationsById)
      .filter((r) => r.entityTypeIdA === entityType.id)
      .map((r) => createRelationViewField(r.id, "aToB")),
    values(relationsById)
      .filter((r) => r.entityTypeIdB === entityType.id)
      .map((r) => createRelationViewField(r.id, "bToA")),
  );

  return columnFields.concat(relationFields);
}
