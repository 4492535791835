import { assertNever } from "assert-never";

import type { IColumnType } from "../../../schemas/dataModel/Column";
import type { IEntityColumnValue } from "../../../schemas/dataModel/EntityValue";
import { ARRAY_TO_STRING_SEPARATOR, stringToArray } from "../stringAndArray";
import { FieldValueParser } from "./fieldValueParser";

export function parseValueByColumnType(value: IEntityColumnValue, columnType: IColumnType): IEntityColumnValue {
  if (value.type === "null") {
    return { type: "null" };
  }
  switch (columnType.type) {
    case "date": {
      if (value.type === "date") {
        return value;
      }

      if (value.type === "timestamp") {
        return {
          type: "date",
          value: FieldValueParser.toDateTime(value)?.startOf("day").toString() ?? "",
        };
      }

      return { type: "null" };
    }
    case "timestamp": {
      if (value.type === "timestamp" || value.type === "date") {
        return value;
      }

      return { type: "null" };
    }
    case "number": {
      if (value.type === "number") {
        return value;
      }

      return { type: "null" };
    }
    case "enum": {
      if (value.type === "string") {
        return { type: "array", value: stringToArray(value.value) };
      } else if (value.type === "array" && value.value.length > 0) {
        return value;
      }

      return { type: "null" };
    }
    case "boolean": {
      if (value.type === "boolean") {
        return { type: "boolean", value: value.value };
      }

      return { type: "null" };
    }
    case "email":
    case "url":
    case "file":
    case "phone":
    case "shortText":
    case "geolocation": {
      if (value.type === "array") {
        return { type: "string", value: value.value.join(ARRAY_TO_STRING_SEPARATOR) };
      } else if (value.type === "string") {
        return value;
      }

      return { type: "null" };
    }
    case "statusEnum":
    case "longText": {
      if (value.type === "string") {
        return value;
      }

      return { type: "null" };
    }
    default: {
      assertNever(columnType);
    }
  }
}
