import { TruncateList } from "@archetype/ui";
import Link from "next/link";
import React, { useCallback } from "react";

import { ExpandableCellWrapper } from "../ExpandableCellWrapper";
import type { IDataTableClickableListCell, IDataTableCommonCellProps } from "./api";

export function ClickableListCell<TRowId extends string, TColumnId extends string>({
  cell,
  isCellSelected,
  isCellEditing,
  isRowSelected,
}: IDataTableCommonCellProps<TRowId, TColumnId, IDataTableClickableListCell<TRowId, TColumnId>> & {
  children?: React.ReactNode;
}): React.ReactNode {
  const { value } = cell;

  const renderTruncator = useCallback(
    ({ hiddenItemsCount }: { hiddenItemsCount: number }) => <span>+{hiddenItemsCount} more</span>,
    [],
  );

  return (
    <ExpandableCellWrapper
      cell={cell}
      isCellEditing={isCellEditing}
      isCellSelected={isCellSelected}
      isRowSelected={isRowSelected}
    >
      <TruncateList className="flex h-full flex-1 items-center text-base" renderTruncator={renderTruncator}>
        {value.map(({ text, href }) =>
          href.type === "internal" ? (
            <Link key={text} className="truncate whitespace-nowrap underline hover:cursor-pointer" href={href.route}>
              {text}
            </Link>
          ) : (
            <a key={text} className="truncate whitespace-nowrap underline hover:cursor-pointer" href={href.href}>
              {text}
            </a>
          ),
        )}
      </TruncateList>
    </ExpandableCellWrapper>
  );
}
