import type { IEntityTypeCore } from "@archetype/dsl";
import {
  computeColumnViewFieldId,
  getNonUserEditableUserColumnsSet,
  getUserEntityTypeColumnsSet,
} from "@archetype/dsl";
import type { IViewFieldId } from "@archetype/ids";
import { mapValues } from "@archetype/utils";

// maybe just pass the "isInitial" in getting disabled operations
export enum DATA_MODEL_OPERATIONS {
  "reorder" = "reorder",
  "reorderCreateTransition" = "reorderCreateTransition",
  "editActionFields" = "editActionFields",
  "hide" = "hide",
  "makeNullable" = "makeNullable",
  "addToStateView" = "addToStateView",
  "editType" = "editType",
  "showAutofill" = "showAutofill",
  "editAutofill" = "editAutofill",
  "addToForm" = "addToForm",
  "removeFromCreateTransition" = "removeFromCreateTransition",
  "removeFromAnyTransition" = "removeFromAnyTransition",
  "delete" = "delete",
  "show" = "show",
}

enum SEMANTIC_COLUMNS {
  "primaryKey" = "primaryKey",
  "displayName" = "displayName",
  "statusColumn" = "statusColumn",
  "userEntityTypeColumn" = "userEntityTypeColumn",
  "userEntityTypeNonUserEditableColumn" = "userEntityTypeNonUserEditableColumn",
}

const DISABLED_OPERATIONS: {
  [_key in SEMANTIC_COLUMNS]: DATA_MODEL_OPERATIONS[];
} = {
  primaryKey: [
    DATA_MODEL_OPERATIONS.delete,
    DATA_MODEL_OPERATIONS.reorder,
    DATA_MODEL_OPERATIONS.addToForm,
    DATA_MODEL_OPERATIONS.makeNullable,
    DATA_MODEL_OPERATIONS.addToStateView,
    DATA_MODEL_OPERATIONS.removeFromCreateTransition,
  ],
  displayName: [
    DATA_MODEL_OPERATIONS.delete,
    DATA_MODEL_OPERATIONS.removeFromCreateTransition,
    DATA_MODEL_OPERATIONS.editType,
    DATA_MODEL_OPERATIONS.makeNullable,
    DATA_MODEL_OPERATIONS.addToStateView,
  ],
  statusColumn: [
    DATA_MODEL_OPERATIONS.delete,
    DATA_MODEL_OPERATIONS.reorder,
    DATA_MODEL_OPERATIONS.removeFromCreateTransition,
    DATA_MODEL_OPERATIONS.removeFromAnyTransition,
    DATA_MODEL_OPERATIONS.editType,
    DATA_MODEL_OPERATIONS.makeNullable,
    DATA_MODEL_OPERATIONS.editActionFields,
    DATA_MODEL_OPERATIONS.addToStateView,
    DATA_MODEL_OPERATIONS.addToForm,
    DATA_MODEL_OPERATIONS.showAutofill,
    DATA_MODEL_OPERATIONS.editAutofill,
  ],
  userEntityTypeColumn: [
    DATA_MODEL_OPERATIONS.editType,
    DATA_MODEL_OPERATIONS.makeNullable,
    DATA_MODEL_OPERATIONS.delete,
  ],
  userEntityTypeNonUserEditableColumn: [DATA_MODEL_OPERATIONS.addToForm, DATA_MODEL_OPERATIONS.removeFromAnyTransition],
};

export function getDisabledOperationsByViewFieldId(
  entityType: Pick<
    IEntityTypeCore,
    "columns" | "primaryKey" | "displayNameColumn" | "statusColumn" | "userEntityTypeInfo"
  >,
): Record<IViewFieldId, Set<DATA_MODEL_OPERATIONS>> {
  const res: Record<IViewFieldId, DATA_MODEL_OPERATIONS[]> = {};

  entityType.columns.forEach((column) => {
    const viewFieldDisabledOperations: DATA_MODEL_OPERATIONS[] = [];

    if (column.id === entityType.primaryKey) {
      viewFieldDisabledOperations.push(...DISABLED_OPERATIONS.primaryKey);
    }

    if (column.id === entityType.displayNameColumn) {
      viewFieldDisabledOperations.push(...DISABLED_OPERATIONS.displayName);
    }

    if (column.id === entityType.statusColumn) {
      viewFieldDisabledOperations.push(...DISABLED_OPERATIONS.statusColumn);
    }

    if (entityType.userEntityTypeInfo != null && getUserEntityTypeColumnsSet().has(column.id)) {
      if (getUserEntityTypeColumnsSet().has(column.id)) {
        viewFieldDisabledOperations.push(...DISABLED_OPERATIONS.userEntityTypeColumn);
      }
      if (getNonUserEditableUserColumnsSet().has(column.id)) {
        viewFieldDisabledOperations.push(...DISABLED_OPERATIONS.userEntityTypeNonUserEditableColumn);
      }
    }

    res[computeColumnViewFieldId(column.id)] = viewFieldDisabledOperations;
  });

  return mapValues(res, (ops) => new Set(ops));
}
