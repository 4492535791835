import { ViewFieldId } from "@archetype/ids";
import { z } from "zod";

import { ViewFieldValue } from "./ViewFieldValue";

export const ComputationInputValues = z.object({
  entity: z.record(
    ViewFieldId, // column id
    ViewFieldValue,
  ),
  relatedEntities: z.record(
    ViewFieldId, // relation id
    z
      .record(
        ViewFieldId, // column id
        ViewFieldValue,
      )
      .array(),
  ),
});
export type IComputationInputValues = z.infer<typeof ComputationInputValues>;

export const NameBasedComputationInputValues = ComputationInputValues.extend({
  entity: z.record(
    z.string(), // column name
    ViewFieldValue,
  ),
  relatedEntities: z.record(
    z.string(), // relation name
    z
      .record(
        z.string(), // column name
        ViewFieldValue,
      )
      .array(),
  ),
});
export type INameBasedComputationInputValues = z.infer<typeof NameBasedComputationInputValues>;
