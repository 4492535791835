import React from "react";
import { createContext } from "use-context-selector";

interface IDataTablePaginationContextType {
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  onFetchNextPage: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- context cant be null
export const DataTablePaginationContext = createContext<IDataTablePaginationContextType>(null!);

interface IDataTablePaginationProviderProps {
  children: React.ReactNode;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  onFetchNextPage: () => Promise<void>;
}

export function DataTablePaginationProvider({
  children,
  hasNextPage,
  isFetchingNextPage,
  onFetchNextPage,
}: IDataTablePaginationProviderProps): React.ReactElement {
  return (
    <DataTablePaginationContext.Provider
      value={{
        hasNextPage,
        isFetchingNextPage,
        onFetchNextPage,
      }}
    >
      {children}
    </DataTablePaginationContext.Provider>
  );
}
