import { ActionLog, DisplayMetadata } from "@archetype/dsl";
import { z } from "zod";

import { LoadedAction } from "./LoadedActionType";

export const LoadedActionLog = ActionLog.omit({
  actionVersionId: true,
  entityTypeVersionId: true,
}).merge(
  z.object({
    actionDisplayMetadata: DisplayMetadata,
    loadedAction: LoadedAction.nullable(),
  }),
);

export type ILoadedActionLog = z.infer<typeof LoadedActionLog>;
