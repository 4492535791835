import { DataModelMetadataId, DataModelMetadataVersionId } from "@archetype/ids";

const BUILT_IN_DATA_MODEL_METADATA_ID = DataModelMetadataId.parse("6b03e449-9229-45e9-b903-236632318024");

const BUILT_IN_DATA_MODEL_METADATA_VERSION_ID = DataModelMetadataVersionId.parse(
  "ec7a95b7-abcd-404b-a875-da70c91160b4",
);

/**
 * Used as a computed from origin
 */
export const BUILT_IN_DATA_MODEL_METADATA_INFO = {
  id: BUILT_IN_DATA_MODEL_METADATA_ID,
  versionId: BUILT_IN_DATA_MODEL_METADATA_VERSION_ID,
};
