import { isNonNullable } from "@archetype/utils";

import type { IAuthorization, IAuthorizingRelationPath } from "../../schemas/Authorization";
import type { IAction, IActionCore, IActionKind } from "../../schemas/dataModel/Action";
import type { IEntityType, IEntityTypeCore } from "../../schemas/dataModel/EntityType";

/**
 * Returns all actions that are not of the given action type
 */
export const filterOutActionsByType = (actions: IAction[], actionType: IActionKind): IAction[] =>
  actions.filter((action) => action.actionDefinition.actionType !== actionType);

/**
 * Collects all authorizing paths for an entity type
 */
export const getAuthorizingPathsForEntityType = (entityType: IEntityType): IAuthorizingRelationPath[] =>
  entityType.authorizedByAnyOf.map((auth) => auth.authorizedByRelationPath).filter(isNonNullable);

/**
 * Collects all authorizing paths for a list of actions, excluding add actions
 */
export const getAuthorizingPathsForActions = (actions: IActionCore[]): IAuthorizingRelationPath[] =>
  actions
    .flatMap((action) => action.actionDefinition.authorizedByAnyOf)
    .map((auth) => auth.authorizedByRelationPath)
    .filter(isNonNullable);

export const isSelfAuthorization = (entityType: IEntityTypeCore, authorization: IAuthorization): boolean =>
  entityType.userEntityTypeInfo != null &&
  authorization.authorizedByRelationPath.path.length === 0 &&
  authorization.authorizedByRelationPath.startingEntityType == null &&
  authorization.authorizedByRelationPath.userTypeFilters == null &&
  authorization.authorizedForAnyoneWithLink !== true;
