"use client";

import * as React from "react";

import { cn } from "../../lib/utils";

interface IPanelContentProps {
  className?: string;
  children: React.ReactNode;
}

export const PanelContent = React.forwardRef<
  React.ElementRef<React.ForwardRefExoticComponent<React.RefAttributes<HTMLDivElement>>>,
  IPanelContentProps
>(({ className, children }, ref) => {
  return (
    <div ref={ref} className={cn("bg-paper @container flex flex-col px-6 py-8 sm:p-10", className)}>
      {children}
    </div>
  );
});
PanelContent.displayName = "PanelContent";
