import type {
  IColumn,
  IEntityTypeCore,
  IJoinCardinality,
  ILlmDataModel,
  IRelationCore,
  IRelationDirection,
} from "@archetype/dsl";
import type { IEntityTypeId, IRelationId } from "@archetype/ids";
import { isNonNullable, keyByNoUndefined, mapValues } from "@archetype/utils";
import { uniq, values } from "lodash";

import { isLoadedColumnViewField, isLoadedRelationViewField } from "../..";
import { generateLoadedViewFieldsMapForEntityType } from "../adapters/generateViewFieldMaps";

function generateRelationDescription(
  relation: IRelationCore,
  direction: IRelationDirection,
  entityTypesById: Record<IEntityTypeId, IEntityTypeCore>,
): string {
  const startingEntityTypeId = direction === "aToB" ? relation.entityTypeIdA : relation.entityTypeIdB;
  const endingEntityTypeId = direction === "aToB" ? relation.entityTypeIdB : relation.entityTypeIdA;

  const startingEntityType = entityTypesById[startingEntityTypeId];
  const endingEntityType = entityTypesById[endingEntityTypeId];

  if (startingEntityType == null || endingEntityType == null) {
    throw new Error("Entity type not found");
  }

  const startingEntityTypeDisplayName = startingEntityType.displayMetadata.name;
  const endingEntityTypeDisplayName = endingEntityType.displayMetadata.name;

  const relationName =
    direction === "aToB" ? relation.displayMetadataFromAToB.name : relation.displayMetadataFromBToA.name;

  return `Relation from ${startingEntityTypeDisplayName} to ${endingEntityTypeDisplayName} that represents ${relationName}`;
}

function getColumnDescription(column: IColumn): string | undefined {
  if (column.columnType.type === "enum") {
    const allowedValues = column.columnType.enumAllowedValues;

    if (allowedValues == null) {
      return;
    }

    return `Enum column with values: ${allowedValues.join(", ")}`;
  }
  if (column.columnType.type === "statusEnum") {
    return `Status enum column with values: ${column.columnType.allowedValues.map((v) => `value "${v.id}" means "${v.readableValue}"`).join(", ")}`;
  }

  return;
}

export function generateDataModelForLLM({
  relationsById,
  entityTypesById,
}: {
  relationsById: Record<IRelationId, IRelationCore>;
  entityTypesById: Record<IEntityTypeId, IEntityTypeCore>;
}): ILlmDataModel {
  const relatedEntityTypeIds = uniq(values(relationsById).flatMap((r) => [r.entityTypeIdA, r.entityTypeIdB]));
  const relatedEntityTypes = relatedEntityTypeIds.map((id) => entityTypesById[id]).filter(isNonNullable); // should include entity type obv

  const dataModel: ILlmDataModel = {};

  relatedEntityTypes.forEach((et) => {
    const viewFieldsById = generateLoadedViewFieldsMapForEntityType(et, relationsById);
    const columnFields = values(viewFieldsById)
      .map((f) => (isLoadedColumnViewField(f) ? f : undefined))
      .filter(isNonNullable);
    const relationFields = values(viewFieldsById)
      .map((f) => (isLoadedRelationViewField(f) ? f : undefined))
      .filter(isNonNullable);

    const columnsByName: Record<string, { type: string; description: string }> = mapValues(
      keyByNoUndefined(columnFields, (c) => c.displayName),
      (c) => ({
        type: c.column.columnType.type,
        description: getColumnDescription(c.column) ?? "",
      }),
    );
    const relationsByName: Record<
      string,
      { targetEntity: string; cardinality: IJoinCardinality; description: string }
    > = mapValues(
      keyByNoUndefined(relationFields, (r) => r.displayName),
      (r) => ({
        description: generateRelationDescription(r.relation, r.direction, entityTypesById), // help the llm choose the right direction
        targetEntity: r.direction === "aToB" ? r.relation.entityTypeIdB : r.relation.entityTypeIdA,
        cardinality:
          r.direction === "aToB" ? r.relation.config.cardinalityOnSideB : r.relation.config.cardinalityOnSideA,
      }),
    );

    dataModel[et.displayMetadata.name] = {
      entityTypeId: et.id,
      entityName: et.displayMetadata.name,
      columns: columnsByName,
      relations: relationsByName,
    };
  });

  return dataModel;
}
