import { cn, DotPinging } from "@archetype/ui";
import assertNever from "assert-never";
import { isEqual } from "lodash-es";
import React, { memo, useEffect, useMemo, useRef } from "react";

import { useSavingIndicator } from "../../hooks/useSavingIndicator";
import { useTooltipState } from "../../hooks/useTooltipState";
import { DataTableActionButton } from "../actions/DataTableActionButton";
import type { IDataTableCell, IDataTableCellWrapperProps, IDataTableCommonCellTooltip } from "./api";
import { CellContent } from "./CellContent";
import { CellTooltip } from "./CellTooltip";

const areEqual = (
  prevProps: IDataTableCellWrapperProps<string, string>,
  nextProps: IDataTableCellWrapperProps<string, string>,
): boolean => {
  return (
    prevProps.cell === nextProps.cell &&
    prevProps.isCellEditing === nextProps.isCellEditing &&
    prevProps.isCellSelected === nextProps.isCellSelected &&
    prevProps.isRowSelected === nextProps.isRowSelected &&
    isEqual(prevProps.indicator, nextProps.indicator)
  );
};

export const Cell = memo(function Cell<TRowId extends string, TColumnId extends string>({
  className,
  cell,
  indicator,
  actions,
  isCellEditing,
  isCellSelected,
  isRowSelected,
  selectionMode,
  onViewLastEditError,
}: IDataTableCellWrapperProps<TRowId, TColumnId, IDataTableCell<TRowId, TColumnId>>): React.ReactElement {
  const cellRef = useRef<HTMLDivElement>(null);
  const showSavingIndicator = useSavingIndicator(indicator);

  const { isOpen: isTooltipForceOpen } = useTooltipState({
    indicator,
    isCellSelected,
    isCellEditing,
    selectionMode,
    onViewLastEditError,
  });

  useEffect(() => {
    if (isCellSelected) {
      cellRef.current?.focus();
    }
  }, [isCellSelected]);

  const actionsContent = useMemo(() => {
    if (actions == null) {
      return null;
    }

    return (
      <div className="absolute right-2 top-1/2 z-10 -translate-y-1/2">
        {actions.map(({ icon, tooltip: tt, text, onClick: handleActionClick, className: actionClassName, link }) => (
          <DataTableActionButton
            key={`${icon ?? ""}-${text ?? ""}-${tt ?? ""}`}
            cellRef={cellRef}
            className={actionClassName}
            icon={icon}
            link={link}
            tooltip={tt}
            onClick={handleActionClick}
          />
        ))}
      </div>
    );
  }, [actions]);

  const tooltipToUse = useMemo((): IDataTableCommonCellTooltip | undefined => {
    if (indicator == null) {
      return undefined;
    }

    switch (indicator.type) {
      case "editError": {
        return { iconLeft: "alert-circle", text: indicator.lastEditError.error, variant: "danger" };
      }
      case "error": {
        return { iconLeft: "alert-circle", text: indicator.error, variant: "danger" };
      }
      case "computing": {
        return {
          iconLeft: indicator.iconLeft,
          text: indicator.text,
          variant: indicator.textColor,
        };
      }
      case "saving": {
        return undefined;
      }
      case "selectionTooltipOnly": {
        return { iconLeft: indicator.iconLeft, text: indicator.text };
      }
      default: {
        assertNever(indicator);
      }
    }
  }, [indicator]);

  const indicatorColor = useMemo((): "purple" | "danger" | "primary" | "default" | undefined => {
    if (indicator == null) {
      return undefined;
    }

    if (showSavingIndicator) {
      return "default";
    }

    switch (indicator.type) {
      case "editError":
      case "error": {
        return "danger";
      }
      case "computing": {
        return indicator.indicatorColor ?? "default";
      }
      case "saving": {
        return "default";
      }
      case "selectionTooltipOnly": {
        return undefined;
      }
      default: {
        assertNever(indicator);
      }
    }
  }, [indicator, showSavingIndicator]);

  const indicatorContent = useMemo(() => {
    if (indicatorColor != null) {
      return (
        <div className={cn("absolute right-3 top-1/2 -translate-y-1/2")}>
          <DotPinging color={indicatorColor} size="sm" />
        </div>
      );
    }

    return null;
  }, [indicatorColor]);

  const cellClassName = useMemo(() => {
    return cn(
      "relative z-0 size-full cursor-pointer items-center justify-center border-[1.5px] border-transparent outline-none",
      (isCellSelected || isCellEditing) && cell.readOnly && "border-[1.5px] border-gray-600",
      (isCellSelected || isCellEditing) && !cell.readOnly && "border-[1.5px] border-blue-600",
      indicator?.type === "editError" && isCellSelected && "border-red-500",
      className,
    );
  }, [isCellSelected, isCellEditing, cell, indicator?.type, className]);

  const cellContent = useMemo(
    () => (
      <div ref={cellRef} className={cellClassName}>
        <CellContent
          cell={cell}
          isCellEditing={isCellEditing}
          isCellSelected={isCellSelected}
          isRowSelected={isRowSelected}
        />
        {indicatorContent}
        {actionsContent}
      </div>
    ),
    [cellClassName, indicatorContent, cell, isCellEditing, isCellSelected, isRowSelected, actionsContent],
  );

  if (tooltipToUse != null) {
    if (!isTooltipForceOpen && indicatorContent == null) {
      // If there is no indicator, only show the tooltip if it's forced open
      return cellContent;
    }

    // If there is an indicator, either respect force open if true or uncontrolled on hover
    const isTooltipOpen = isTooltipForceOpen || undefined;

    return <CellTooltip cellContent={cellContent} isOpen={isTooltipOpen} tooltip={tooltipToUse} />;
  }

  return cellContent;
}, areEqual);
