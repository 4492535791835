"use client";

import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import * as React from "react";
import { useCallback } from "react";

import { cn } from "../../../lib/utils";
import { useScrollAreaContext } from "./scroll-area-provider";

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & {
    forceFullHeight?: boolean;
  }
>(({ className, children, forceFullHeight = false, ...props }, ref) => {
  const { handleScroll, direction } = useScrollAreaContext();

  const handleScrollInner = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      props.onScroll?.(event);
      handleScroll(event);
    },
    [handleScroll, props],
  );

  return (
    <ScrollAreaPrimitive.Root className={cn("relative size-full overflow-hidden", className)} {...props}>
      <ScrollAreaPrimitive.Viewport
        ref={ref}
        aria-label="Scrollable content"
        className={cn(
          "size-full scroll-p-2 rounded-[inherit] [&>div]:!block",
          forceFullHeight && "[&>div]:!h-full [&>div]:!max-h-full",
        )}
        role="region"
        onScroll={handleScrollInner}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      {(direction === "vertical" || direction === "both") && <ScrollBar orientation="vertical" />}
      {(direction === "horizontal" || direction === "both") && <ScrollBar orientation="horizontal" />}
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  );
});

ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = "vertical", ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    className={cn(
      "flex touch-none select-none transition-colors",
      orientation === "vertical" && "h-full w-2.5 border-l border-l-transparent p-px",
      orientation === "horizontal" && "h-2.5 border-t border-t-transparent p-px",
      className,
    )}
    orientation={orientation}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className="bg-border relative flex-1 rounded-full" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));

ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
