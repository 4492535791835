import type { IFeatureId } from "@archetype/ids";
import { forEach, groupByMatchesGuard, isNonNullable, keyByNoUndefined } from "@archetype/utils";

import type { IStructuredFeature } from "../../schemas/StructuredFeature";
import type { IViewFeature } from "../../schemas/StructuredViewFeature";
import { NestedViewFeature, ViewFeature } from "../../schemas/StructuredViewFeature";

export type IViewFeatureHierarchy = {
  feature: IViewFeature;
  subFeatures: IViewFeatureHierarchy[];
};

export const makeViewFeatureHierarchy = (features: Record<IFeatureId, IViewFeature>): IViewFeatureHierarchy[] => {
  // Not a feature hierarchy yet because there could be multiple levels of nesting
  const rootFeatureIds: IFeatureId[] = [];
  const subFeatureIds: Record<IFeatureId, IFeatureId[]> = {};

  forEach(features, (feature) => {
    const parsedNestedFeature = NestedViewFeature.safeParse(feature);

    if (parsedNestedFeature.success) {
      const parent = parsedNestedFeature.data.parent;

      if (features[parent] == null) {
        // If the parent reference is invalid, show as root feature (usually should not happen because might indicate an error in parsing or management logic)
        rootFeatureIds.push(feature.id);

        return;
      }

      if (subFeatureIds[parent] == null) {
        subFeatureIds[parent] = [];
      }
      subFeatureIds[parent]?.push(feature.id);
    } else {
      rootFeatureIds.push(feature.id);
    }
  });

  return makeSubViewFeatureHierarchy(rootFeatureIds, {
    subFeatureIds,
    features: features,
  });
};

const makeSubViewFeatureHierarchy = (
  rootFeatureIds: IFeatureId[],
  caches: {
    subFeatureIds: Record<IFeatureId, IFeatureId[]>;
    features: Record<IFeatureId, IViewFeature>;
  },
): IViewFeatureHierarchy[] => {
  return rootFeatureIds
    .map((featureId) => {
      const feature = caches.features[featureId];

      if (feature == null) {
        return undefined;
      }

      const subFeatureIds = caches.subFeatureIds[featureId];

      return {
        feature,
        subFeatures: subFeatureIds == null ? [] : makeSubViewFeatureHierarchy(subFeatureIds, caches),
      };
    })
    .filter(isNonNullable);
};

export interface IFeatureHierarchy {
  feature: IStructuredFeature;
  subFeatures: IFeatureHierarchy[];
}

export const makeFeatureHierarchy = (features: IStructuredFeature[]): IFeatureHierarchy[] => {
  const { matching: viewFeatures, others: otherFeatures } = groupByMatchesGuard(
    features,
    (feature): feature is IViewFeature => ViewFeature.safeParse(feature).success,
  );

  // view features make actual hierarchies
  const viewFeatureHierarchies = makeViewFeatureHierarchy(keyByNoUndefined(viewFeatures, (feature) => feature.id));

  // structured features are flat leaves
  const otherFeaturesHierarchies = otherFeatures.map((feature) => ({
    feature,
    subFeatures: [],
  }));

  return [...viewFeatureHierarchies, ...otherFeaturesHierarchies];
};
