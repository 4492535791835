import type { IVersionType } from "@archetype/dsl";
import type { IEntityId, IEntityTypeId } from "@archetype/ids";
import { DotPinging } from "@archetype/ui";
import pluralize from "pluralize";
import React from "react";

import { useEntityHasSavingAction } from "../hooks/entity/useEntityHasSavingAction";

export const ActionSavingIndicator: React.FC<{
  versionType: IVersionType;
  entityTypeId: IEntityTypeId;
  entityId: IEntityId;
}> = ({ versionType, entityTypeId, entityId }) => {
  const hasSavingAction = useEntityHasSavingAction({ versionType, entityTypeId, entityId });

  if (!hasSavingAction.hasSaving) {
    return null;
  }

  return (
    <div className="flex shrink-0 items-center space-x-2 px-2">
      <DotPinging color="gray" size="sm" />
      <span className="text-muted-foreground animate-pulse text-sm">
        Saving {pluralize("action", hasSavingAction.savingCount)}...
      </span>
    </div>
  );
};
