import { useEffect, useState } from "react";

import type { IDataTableCellIndicator } from "../components/cells/api";

const MIN_SAVING_INDICATOR_DURATION = 500;

export function useSavingIndicator(indicator: IDataTableCellIndicator | undefined): boolean {
  const [showSavingIndicator, setShowSavingIndicator] = useState(false);

  const isSaving = indicator?.type === "saving";

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isSaving) {
      setShowSavingIndicator(true);
    } else {
      timer = setTimeout(() => {
        setShowSavingIndicator(false);
      }, MIN_SAVING_INDICATOR_DURATION);
    }

    return (): void => {
      clearTimeout(timer);
    };
  }, [isSaving]);

  return showSavingIndicator;
}
