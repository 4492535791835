import { StateId, TransitionId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { StateColor } from "../stateMachine/stateColors";
import { WorkspaceRelation } from "./workspaceSuggestions";

export const WorkflowStateMachineColumnType = z.object({
  typeName: z.enum([
    "email",
    "shortText",
    "longText",
    "number",
    "boolean",
    "date",
    "timestamp",
    "geolocation",
    "phone",
    "file",
    "url",
    "enum",
  ]),
  enumAllowedValues: z.array(ReadableString).nullable(),
});
export type IWorkflowStateMachineColumnType = z.infer<typeof WorkflowStateMachineColumnType>;

export const WorkflowStateMachineColumn = z.object({
  label: ReadableString,
  columnType: WorkflowStateMachineColumnType,
  isOptional: z.boolean(),
});
export type IWorkflowStateMachineColumn = z.infer<typeof WorkflowStateMachineColumn>;

export const WorkflowStateMachineRelation = WorkspaceRelation.extend({
  isOptional: z.boolean(),
});
export type IWorkflowStateMachineRelation = z.infer<typeof WorkflowStateMachineRelation>;

export const WorkflowStateMachine = z.object({
  states: z.array(
    z.object({
      id: StateId,
      label: ReadableString,
      color: StateColor,
    }),
  ),
  initialTransition: z.object({
    id: TransitionId,
    label: ReadableString,
    toStateId: StateId,
    inputColumns: z.array(WorkflowStateMachineColumn),
    inputRelations: z.array(WorkflowStateMachineRelation).optional(),
    authorization: z.enum(["public", "private"]),
  }),
  transitions: z.array(
    z.object({
      id: TransitionId,
      label: ReadableString,
      fromStateId: StateId,
      toStateId: StateId,
      inputColumns: z.array(WorkflowStateMachineColumn).optional(),
      inputRelations: z.array(WorkflowStateMachineRelation).optional(),
      contextColumns: z
        .array(
          z.object({
            label: ReadableString,
          }),
        )
        .optional(),
      contextRelations: z
        .array(
          z.object({
            label: ReadableString,
            entityTypeATitle: ReadableString,
            entityTypeBTitle: ReadableString,
          }),
        )
        .optional(),
      authorization: z.enum(["public", "private"]),
    }),
  ),
  happyPathStateIdsByOrder: z.array(StateId),
  entityType: z.object({
    title: ReadableString,
    pluralTitle: ReadableString,
    description: ReadableString,
    nameOfTitleColumn: ReadableString.describe(
      "the name of a short text column that will be used to easily identify the entity by the user",
    ),
    nameOfCreatedByRelation: ReadableString.describe(
      "the role that describes who is going to be the creator of the entity",
    ),
  }),
});
export type IWorkflowStateMachine = z.infer<typeof WorkflowStateMachine>;
