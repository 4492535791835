import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { ActionButtonComponentDefinitionId } from "@archetype/ids";

export const ActionButtonComponentIdDeclarations = {
  id: ActionButtonComponentDefinitionId,
  inputs: ["singleEntityDataInput"],
  outputs: ["actionSelected"],
  compatibleSemanticSizes: ["actions"],
  validCosmeticOptionalProps: ["label", "intent"],
} as const;

export const ActionButtonComponentDefinition: ITypedComponentDefinitionDeclaration<
  typeof ActionButtonComponentIdDeclarations
> = {
  constIds: ActionButtonComponentIdDeclarations,
  displayMetadata: {
    name: "Action button",
    description: "A button that can perform actions on an entity",
  },
  inputs: {
    singleEntityDataInput: {
      displayMetadata: {
        name: "Entity data",
        description: "The data to perform actions on",
      },
      optional: true,
      nullable: true,
    },
  },
  outputs: {
    actionSelected: {
      displayMetadata: {
        name: "Action callback",
        description: "The callback to show the action",
      },
      optional: false,
    },
  },
};
