import type { IDataLoadingSimpleEntityFilter } from "@archetype/dsl";
import { DataLoadingSimpleEntityFilter } from "@archetype/dsl";
import { createQueryString } from "@archetype/utils";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback, useMemo } from "react";

import { createFileLogger } from "../../logger";

type IFilterUpdater = (newFilters: IDataLoadingSimpleEntityFilter[] | undefined) => void;

const logger = createFileLogger("useFiltersFromURL");
const FILTERS_QUERY_PARAM = "filters";

export function useFiltersFromURL(): [IDataLoadingSimpleEntityFilter[], IFilterUpdater] {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const filtersString = searchParams.get(FILTERS_QUERY_PARAM);

  const filters = useMemo(() => {
    try {
      if (filtersString == null || filtersString === "") return [];

      return DataLoadingSimpleEntityFilter.array().parse(JSON.parse(decodeURIComponent(filtersString)));
    } catch (e) {
      logger.error({ error: e }, "Error parsing entity type simple filters");
    }

    return [];
  }, [filtersString]);

  const setFilters = useCallback<IFilterUpdater>(
    (newFilters) => {
      const value = newFilters && newFilters.length > 0 ? JSON.stringify(newFilters) : "";
      const queryString = createQueryString({ searchParams, name: FILTERS_QUERY_PARAM, value });

      router.push(`${pathname}?${queryString}`);
    },
    [pathname, searchParams, router],
  );

  return [filters, setFilters];
}
