import type {
  IColumn,
  IEntityColumnValue,
  IMinimalEntity,
  IRelationCore,
  IRelationFieldValue,
  IViewField,
} from "@archetype/dsl";
import { computeColumnViewFieldId } from "@archetype/dsl";
import type { IColumnId, IRelationId, IViewFieldId } from "@archetype/ids";
import { map } from "@archetype/utils";

import type { ILoadedViewField } from "../apiTypes/LoadedViewField";
import { createLoadedColumnViewField, createLoadedRelationViewField } from "../apiTypes/LoadedViewField";

export function convertColumnValuesToFieldValues(
  columnValues: Partial<Record<IColumnId, IEntityColumnValue>>,
): Record<IViewFieldId, IEntityColumnValue> {
  const res: Record<IViewFieldId, IEntityColumnValue> = {};

  map(columnValues, (val, columnId) => {
    if (val == null) {
      return;
    }

    res[computeColumnViewFieldId(columnId)] = val;
  });

  return res;
}

export const createLoadedViewField = (
  viewField: IViewField,
  columnsById: Record<IColumnId, IColumn>,
  relationsById: Partial<Record<IRelationId, IRelationCore>>,
): ILoadedViewField | undefined => {
  switch (viewField.type) {
    case "column": {
      const column = columnsById[viewField.columnId];

      return column == null ? undefined : createLoadedColumnViewField(column);
    }
    case "directionalRelation": {
      const relation = relationsById[viewField.relationId];

      return relation == null ? undefined : createLoadedRelationViewField(relation, viewField.direction);
    }
  }
};

export function convertEntitiesToRelationValue(entities: IMinimalEntity[]): IRelationFieldValue | { type: "null" } {
  if (entities.length === 0) {
    return { type: "null" };
  }

  return {
    type: "relatedEntities",
    value: entities,
  };
}
