import type {
  IColumn,
  IDataLoadingQueryColumnOrFilters,
  IDataLoadingQueryFilters,
  IRelatedToFilter,
} from "@archetype/dsl";
import { cleanUpOrFiltersByColumnType, computeRelationViewFieldId } from "@archetype/dsl";
import type { IColumnId, IViewFieldId } from "@archetype/ids";
import { useMemoDeepCompare } from "@archetype/ui";
import { keyByNoUndefined, mapValues } from "@archetype/utils";

interface IFilterValues {
  columnFilterValues: Partial<Record<IColumnId, IDataLoadingQueryColumnOrFilters>>;
  relationFilterValues: Partial<Record<IViewFieldId, IRelatedToFilter>>;
}

export function useFilterValues(
  filters: IDataLoadingQueryFilters | undefined,
  columnById: Record<IColumnId, IColumn>,
): IFilterValues {
  const columnFilterValues = useMemoDeepCompare(
    () =>
      mapValues(filters?.perColumn ?? {}, (columnFilterConditions, columnId) => {
        if (columnFilterConditions?.type === "or") {
          const column = columnById[columnId];

          if (column == null) {
            return undefined;
          }

          return cleanUpOrFiltersByColumnType(column.columnType, columnFilterConditions.rawOrConditions);
        }

        // ignore if "and"
        return undefined;
      }),
    [filters?.perColumn, columnById],
  );

  const relationFilterValues = useMemoDeepCompare(
    () =>
      keyByNoUndefined(filters?.andedRelatedToFilters ?? [], (relationFilter) =>
        computeRelationViewFieldId(relationFilter.relationId, relationFilter.direction),
      ),
    [filters?.andedRelatedToFilters],
  );

  return {
    columnFilterValues,
    relationFilterValues,
  };
}
