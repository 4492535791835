import type { IFieldComputationInfo, ILoadedEntity } from "@archetype/core";
import type { IDataTableCell, IDataTableColumn, IDataTableRow } from "@archetype/data-table";
import type { IViewFieldValue } from "@archetype/dsl";
import type { IEntityId, IViewFieldId } from "@archetype/ids";

export const NEW_ROW_ENTITY_ID = "__new_row_entity_id__" as IEntityId;

export type IEntityDataTableCell = IDataTableCell<IEntityId, IViewFieldId>;

export type IEntityDataTableRow = IDataTableRow<IEntityId> & ILoadedEntity;

export type IEntityDataTableCellValue = {
  value: IViewFieldValue;
  computeStatus?: IFieldComputationInfo;
};

export type IEntityDataTableColumn = IDataTableColumn<
  IEntityId,
  IViewFieldId,
  IEntityDataTableRow,
  IEntityDataTableCellValue
>;
