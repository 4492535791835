"use client";

import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";

import { cn } from "../../lib/utils";

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & { small?: boolean; readOnly?: boolean }
>(({ className, small = false, readOnly = false, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "focus-visible:ring-ring focus-visible:ring-offset-paper data-[state=checked]:bg-primary data-[state=unchecked]:bg-muted-background peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      small ? "h-4 w-7" : "h-5 w-9",
      className,
    )}
    {...props}
    ref={ref}
    disabled={readOnly}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "bg-paper pointer-events-none block rounded-full shadow-lg ring-0 transition-transform",
        small
          ? "size-3 data-[state=checked]:translate-x-3 data-[state=unchecked]:translate-x-0"
          : "size-4 data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0",
      )}
    />
  </SwitchPrimitives.Root>
));

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
