import type {
  IActionSideEffectEmail,
  ILoadedAction,
  ILoadedCreateNestedAction,
  ILoadedEntity,
  ILoadedNestedCreateFormValues,
  ILoadedViewField,
} from "@archetype/core";
import { isLoadedRelationViewField, parseFieldValuesAndValidateColumns } from "@archetype/core";
import { loadedNestedFormValuesToNestedFormValues } from "@archetype/core";
import type {
  IActionCurrentUserInfo,
  IDraftEmailExtractedActionInput,
  IEntityType,
  IEntityTypeCore,
  IRelationCore,
  IValidationError,
  IValidationErrorByFieldId,
  IValidationErrors,
  IValidationGroup,
  IVersionType,
  IViewFieldValue,
} from "@archetype/dsl";
import type { IEntityId, IEntityTypeId, IOrganizationId, IRelationId } from "@archetype/ids";
import { EntityId, type IViewFieldId, ViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { cn, DotPinging, EmailComposer, Form, FormMessage, useMemoDeepCompare } from "@archetype/ui";
import { forEach, groupByNoUndefined, isNonNullable, keys, mapValues, pickByGuarded } from "@archetype/utils";
import { omit, uniq } from "lodash";
import { useCallback, useMemo, useRef } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useDebounce } from "react-use";

import type { IGetActionRoute, IGetHighlightedViewFieldRoute, IGetLinkedEntityRoute } from "../api";
import type { ICreateNewProps } from "../inputs/api";
import { useActionDraftServiceContext } from "./ActionDraftServiceContext";
import { ActionFormExternalUserForm } from "./ActionFormExternalUserForm";
import type { IActionFormFieldProps } from "./ActionFormField";
import { ActionFormField } from "./ActionFormField";
import type { INestedDraftEntities } from "./actionInputs/types";
import { ActionViewNestedCreate } from "./ActionViewNestedCreate";
import { useActionFormDataToSubmit } from "./useActionFormDataToSubmit";
import { useExternalUserCreateActionFieldValues } from "./useExternalUserCreateActionFieldValues";

export const TYPES_MAP: Record<string, string> = {
  string: "text",
  email: "email",
  number: "number",
};

export type IValidationErrorsDispatch = (prevValidationErrors: IValidationErrors) => IValidationErrors;

type ISyncNestedCreateFormValues = (args: {
  viewFieldId: IViewFieldId;
  nestedCreateEntityId: IEntityId;
  values: Partial<Record<IViewFieldId, IViewFieldValue>>;
}) => void;

export type IActionFormExternalUserCreateProps = {
  externalUserUpsertAction: ILoadedAction;
  userEntityType: IEntityType;
  userEntityTypeValidationGroups: IValidationGroup[];
  renderExternalUserForm: boolean;
};

export const ActionForm: React.FC<{
  versionType: IVersionType;
  modifyingEntity: ILoadedEntity | undefined;
  organizationId: IOrganizationId;
  action: ILoadedAction;
  currentUserInfo: IActionCurrentUserInfo;
  form: UseFormReturn<Partial<Record<string, IViewFieldValue>>>;
  emailDraftValues?: Partial<Record<IViewFieldId, IDraftEmailExtractedActionInput>>;
  externalUserUpsertProps: IActionFormExternalUserCreateProps | undefined;
  validationErrors: IValidationErrors;
  setFrontendValidationErrors: (setter: IValidationErrorsDispatch) => void;
  setBackendValidationErrors: (setter: IValidationErrorsDispatch) => void;
  className?: string;
  highlightedViewFieldId: IViewFieldId | undefined;
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>> | undefined;
  allRelations: Partial<Record<IRelationId, IRelationCore>> | undefined;
  // partial for zod type limits
  nestedActionByViewFieldId: Partial<Record<IViewFieldId, ILoadedCreateNestedAction>> | undefined;
  loadedNestedCreateFormValues: ILoadedNestedCreateFormValues;
  /**
   *
   * Only relevant for the parent form
   *
   */
  onSyncNestedCreateFormValues?: ISyncNestedCreateFormValues;
  nestedCreateForms: Record<IViewFieldId, IEntityId[]>;
  /**
   * Should be empty in a nested action itself to avoid having to resolve a specific order to the execution of all nested actions or circular depdendencies
   */
  nestedDraftEntities: INestedDraftEntities;
  onSetNestedCreateForms: (
    updater: (prev: Record<IViewFieldId, IEntityId[]>) => Record<IViewFieldId, IEntityId[]>,
  ) => void;
  onDeleteNestedCreateForm: (viewFieldId: IViewFieldId, nestedDraftEntityId: IEntityId) => void;
  getActionRoute: IGetActionRoute;
  getFullPageActionRoute: IGetActionRoute;
  getLinkedEntityRoute: IGetLinkedEntityRoute;
  getHighlightedViewFieldRoute: IGetHighlightedViewFieldRoute | undefined;
}> = ({
  versionType,
  modifyingEntity,
  organizationId,
  action,
  currentUserInfo,
  emailDraftValues,
  externalUserUpsertProps,
  validationErrors,
  setFrontendValidationErrors,
  setBackendValidationErrors,
  className,
  form,
  highlightedViewFieldId,
  allEntityTypes,
  allRelations,
  nestedActionByViewFieldId,
  loadedNestedCreateFormValues,
  onSyncNestedCreateFormValues: handleSyncNestedCreateFormValues,
  nestedCreateForms,
  nestedDraftEntities,
  onSetNestedCreateForms,
  onDeleteNestedCreateForm,
  getActionRoute,
  getFullPageActionRoute,
  getLinkedEntityRoute,
  getHighlightedViewFieldRoute,
}) => {
  const validatePromiseRef = useRef<{ promise: Promise<unknown>; cancel: () => void }>();

  const nestedCreateFormValues = useMemo(
    () => loadedNestedFormValuesToNestedFormValues(loadedNestedCreateFormValues),
    [loadedNestedCreateFormValues],
  );

  const externalUserFieldValues = useExternalUserCreateActionFieldValues({
    form,
    externalUserUpsertAction: externalUserUpsertProps?.externalUserUpsertAction,
  });

  const { getDataToSubmit } = useActionFormDataToSubmit({
    form,
    action,
    externalUserUpsertAction: externalUserUpsertProps?.externalUserUpsertAction,
    loadedNestedCreateFormValues,
  });

  const { data: validationGroupsQuery } = builderTrpc.dataModel.getValidationGroupsByEntityType.useQuery({
    versionType,
    entityTypeId: action.entityTypeId,
  });
  const validationGroups = useMemoDeepCompare(
    () => validationGroupsQuery?.validationGroups ?? [],
    [validationGroupsQuery?.validationGroups],
  );

  const { data: dependenciesQuery } = builderTrpc.dataModel.getFieldsDependencies.useQuery({
    versionType,
    entityTypeId: action.entityTypeId,
  });

  const dependencies = dependenciesQuery?.dependencies;

  const { mutateAsync: runValidations } = builderTrpc.dataStores.runValidationsOnEntity.useMutation();

  const readonlyFieldIds = useMemoDeepCompare(
    () =>
      new Set<IViewFieldId>(
        action.actionDefinition.inputs
          .concat(externalUserUpsertProps?.externalUserUpsertAction.actionDefinition.inputs ?? [])
          .filter((input) => !input.allowChangingDefault)
          .map((input) => input.viewField.id),
      ),
    [action.actionDefinition.inputs, externalUserUpsertProps?.externalUserUpsertAction],
  );

  const editableActionInputsFieldIds = useMemoDeepCompare(
    () =>
      new Set(
        action.actionDefinition.inputs
          .concat(externalUserUpsertProps?.externalUserUpsertAction.actionDefinition.inputs ?? [])
          .filter((input) => input.allowChangingDefault)
          .map((input) => input.viewField.id),
      ),
    [action.actionDefinition.inputs, externalUserUpsertProps?.externalUserUpsertAction],
  );

  const touchedFieldIds = useMemoDeepCompare(
    () =>
      new Set(
        (keys(form.formState.touchedFields) as IViewFieldId[]).filter((viewFieldId) =>
          editableActionInputsFieldIds.has(viewFieldId),
        ),
      ),
    [form.formState.touchedFields, editableActionInputsFieldIds],
  );

  const clearColumnValidationErrors = useCallback(
    (viewFieldId: IViewFieldId): void => {
      setFrontendValidationErrors((validationErrorsMap) => ({
        ...validationErrorsMap,
        fieldErrors: omit(validationErrorsMap.fieldErrors, viewFieldId),
      }));
      setBackendValidationErrors((validationErrorsMap) => ({
        ...validationErrorsMap,
        fieldErrors: omit(validationErrorsMap.fieldErrors, viewFieldId),
      }));
    },
    [setFrontendValidationErrors, setBackendValidationErrors],
  );

  const makeRelevantValidationErrorsByGroup = useCallback(
    (newValidationErrors: IValidationError[]): IValidationErrorByFieldId => {
      const newRelevantValidationErrors = newValidationErrors.filter(
        (error) =>
          error.viewFieldId != null &&
          (touchedFieldIds.has(error.viewFieldId) || readonlyFieldIds.has(error.viewFieldId)),
      );

      return groupByNoUndefined(newRelevantValidationErrors, (error) => error.viewFieldId);
    },
    [touchedFieldIds, readonlyFieldIds],
  );

  const runFrontendValidationsOnValues = useCallback((): void => {
    const {
      entityFieldValues,
      externalUserFieldValues: innerExternalUserFieldValues,
      loadedNestedCreateFormValues: loadedNestedCreateFormValuesToSubmit,
    } = getDataToSubmit();

    const { errors: rawFrontendValidationErrors, nestedActionErrors } = parseFieldValuesAndValidateColumns({
      currentUserInfo,
      loadedAction: action,
      fieldValues: entityFieldValues,
      preexistingEntity: modifyingEntity,
      validationGroups: validationGroups,
      loadedNestedCreateFormValues: loadedNestedCreateFormValuesToSubmit,
      maybeExternalUserCreateProps:
        externalUserUpsertProps == null
          ? undefined
          : {
              externalUserUpsertLoadedAction: externalUserUpsertProps.externalUserUpsertAction,
              existingUserEntity: undefined,
              userEntityTypeValidationGroups: externalUserUpsertProps.userEntityTypeValidationGroups,
              emailAddress: undefined,
            },
      maybeExternalUserFieldValues: innerExternalUserFieldValues,
    });

    const frontendValidationErrors = makeRelevantValidationErrorsByGroup(rawFrontendValidationErrors);
    const frontendGeneralErrors = rawFrontendValidationErrors
      .filter((error) => error.viewFieldId == null)
      .map((error) => error.error);

    setFrontendValidationErrors((_) => ({
      generalErrors: uniq(frontendGeneralErrors),
      fieldErrors: frontendValidationErrors,
      nestedActionErrors: mapValues(nestedActionErrors, (nestedErrors) =>
        mapValues(nestedErrors, (errorsForNestedEntity) => ({
          generalErrors: uniq(
            (errorsForNestedEntity ?? []).filter((error) => error.viewFieldId == null).map((error) => error.error),
          ),
          fieldErrors: mapValues(
            // TODO nested forms - track touched fields and readonly for nested forms too
            groupByNoUndefined(errorsForNestedEntity ?? [], (error) => error.viewFieldId),
            (errorsForField) => errorsForField,
          ),
        })),
      ),
    }));
  }, [
    currentUserInfo,
    action,
    getDataToSubmit,
    externalUserUpsertProps,
    modifyingEntity,
    validationGroups,
    makeRelevantValidationErrorsByGroup,
    setFrontendValidationErrors,
  ]);

  const runFullAsyncValidationsOnValues = (): void => {
    const {
      // TODO do we need some validation for email effect override?
      // emailEffectOverride,
      entityFieldValues,
      externalUserFieldValues: innerExternalUserFieldValues,
    } = getDataToSubmit();

    const cpromise = cancellablePromise(
      runValidations({
        versionType,
        entityId: modifyingEntity?.entityId ?? null,
        actionId: action.id,
        entityTypeId: action.entityTypeId,
        entityFieldValues,
        externalUserSyntheticEntityId: currentUserInfo.userEntityId,
        externalUserFieldValues: innerExternalUserFieldValues,
        nestedCreateFormValues,
      }),
    );

    void cpromise.promise.then((wrappedRes) => {
      if (wrappedRes.type === "cancelled") {
        return;
      }

      const res = wrappedRes.value;
      const relevantValidationErrors = res.asyncErrors.filter(
        (error) =>
          error.viewFieldId != null &&
          (touchedFieldIds.has(error.viewFieldId) || readonlyFieldIds.has(error.viewFieldId)),
      );

      const backendFieldValidationErrors = makeRelevantValidationErrorsByGroup(relevantValidationErrors);
      const backendGeneralErrors = res.asyncErrors
        .filter((error) => error.viewFieldId == null)
        .map((error) => error.error);

      setBackendValidationErrors((_) => ({
        generalErrors: uniq(backendGeneralErrors),
        fieldErrors: backendFieldValidationErrors,
        nestedActionErrors: mapValues(res.asyncNestedActionErrors, (errors) =>
          mapValues(errors, (errorsForNestedEntity) => ({
            generalErrors: uniq(
              (errorsForNestedEntity ?? []).filter((error) => error.viewFieldId == null).map((error) => error.error),
            ),
            fieldErrors: mapValues(
              groupByNoUndefined(errorsForNestedEntity ?? [], (error) => error.viewFieldId),
              (errorsForField) => errorsForField,
            ),
          })),
        ),
      }));
    });

    if (validatePromiseRef.current != null) {
      validatePromiseRef.current.cancel();
    }
    validatePromiseRef.current = cpromise;
  };

  const stringifiedColumnValues = JSON.stringify(form.watch());
  const stringifyNestedCreateFormValues = JSON.stringify(nestedCreateFormValues);

  useDebounce(
    () => {
      runFullAsyncValidationsOnValues();
    },
    300,
    [stringifiedColumnValues, stringifyNestedCreateFormValues],
  );

  useDebounce(
    () => {
      runFrontendValidationsOnValues();
    },
    300,
    [stringifiedColumnValues, stringifyNestedCreateFormValues],
  );

  const { saveDraft, saveState } = useActionDraftServiceContext();
  const handleSetValue = useCallback(
    (id: IViewFieldId, value: IViewFieldValue, isUserEdited: boolean) => {
      clearColumnValidationErrors(id);
      form.setValue(id, value, { shouldTouch: isUserEdited });
      void saveDraft({ type: "field", fieldId: id, value, isUserEdit: isUserEdited });
    },
    [clearColumnValidationErrors, saveDraft, form],
  );

  const handleCreateNew = useCallback(
    (viewField: ILoadedViewField) => (): void => {
      if (!isLoadedRelationViewField(viewField)) {
        return;
      }

      const targetEntityTypeId =
        viewField.direction === "aToB" ? viewField.relation.entityTypeIdB : viewField.relation.entityTypeIdA;

      const isMultiSelect: boolean =
        (viewField.direction === "aToB"
          ? viewField.relation.config.cardinalityOnSideB
          : viewField.relation.config.cardinalityOnSideA) === "many";

      /**
       * This will not be the actual entity type id (created in BE on action execution),
       * but might as well use this generator not v4()/uuid
       */
      const nestedDraftEntityId: IEntityId = EntityId.generate();

      if (isMultiSelect) {
        const currentValues = form.getValues()[viewField.id];
        const currentSelectedEntitiesValue = currentValues?.type === "relatedEntities" ? currentValues.value : [];

        handleSetValue(
          viewField.id,
          {
            type: "relatedEntities",
            value: currentSelectedEntitiesValue.concat([
              {
                entityTypeId: targetEntityTypeId,
                entityId: nestedDraftEntityId,
                displayName: "New item",
              },
            ]),
          },
          true,
        );
      } else {
        handleSetValue(
          viewField.id,
          {
            type: "relatedEntities" as const,
            value: [
              {
                entityTypeId: targetEntityTypeId,
                entityId: nestedDraftEntityId,
                displayName: "New item",
              },
            ],
          },
          true,
        );
      }

      onSetNestedCreateForms((currentNestedCreateForms) => ({
        ...currentNestedCreateForms,
        [viewField.id]: (currentNestedCreateForms[viewField.id] ?? []).concat(
          isMultiSelect || (currentNestedCreateForms[viewField.id] ?? []).length === 0
            ? [nestedDraftEntityId]
            : // Dont add any if not multi select and there's already a form open
              [],
        ),
      }));
    },
    [onSetNestedCreateForms, handleSetValue, form],
  );

  const makeCreateNewProps = useCallback(
    (viewField: ILoadedViewField): ICreateNewProps | undefined => {
      if (nestedActionByViewFieldId?.[viewField.id] == null || viewField.type !== "directionalRelation") {
        return undefined;
      }

      const targetEntityTypeId =
        viewField.direction === "aToB" ? viewField.relation.entityTypeIdB : viewField.relation.entityTypeIdA;

      const newItems = pickByGuarded(
        mapValues(nestedDraftEntities[targetEntityTypeId] ?? {}, (draftEntitiesForType) =>
          draftEntitiesForType == null
            ? undefined
            : {
                value: draftEntitiesForType.nestedDraftEntityId,
                label: draftEntitiesForType.entityTitle,
              },
        ),
        isNonNullable,
      );

      return {
        draftNewOptions: newItems,
        onCreateNew: handleCreateNew(viewField),
      };
    },
    [nestedActionByViewFieldId, handleCreateNew, nestedDraftEntities],
  );

  const handleCancelNewForm = useCallback(
    (
      nestedFormViewFieldId: IViewFieldId,
      nestedCreateFormId: IEntityId,
      /**
       * If set, will not set the form value without the draft entity id for this fieldId, if that is done outside of this function
       */
      skipSetValueForFieldId?: IViewFieldId,
    ) =>
      (): void => {
        onSetNestedCreateForms((currentNestedCreateForms) => ({
          ...currentNestedCreateForms,
          [nestedFormViewFieldId]: currentNestedCreateForms[nestedFormViewFieldId]?.filter(
            (formId) => formId !== nestedCreateFormId,
          ),
        }));

        onDeleteNestedCreateForm(nestedFormViewFieldId, nestedCreateFormId);

        const allFormValues = form.getValues();

        forEach(allFormValues, (value, rawFieldId) => {
          const fieldId = ViewFieldId.parse(rawFieldId);

          if (fieldId === skipSetValueForFieldId) {
            return;
          }

          const currentSelectedEntitiesValue = value?.type === "relatedEntities" ? value.value : undefined;

          if (currentSelectedEntitiesValue == null) {
            return;
          }

          const filteredValue = currentSelectedEntitiesValue.filter((entity) => entity.entityId !== nestedCreateFormId);

          if (filteredValue.length === currentSelectedEntitiesValue.length) {
            return;
          }

          if (filteredValue.length === 0) {
            handleSetValue(
              fieldId,
              {
                type: "null",
              },
              true,
            );
          } else {
            handleSetValue(
              fieldId,
              {
                type: "relatedEntities",
                value: filteredValue,
              },
              true,
            );
          }
        });
      },
    [onSetNestedCreateForms, handleSetValue, form, onDeleteNestedCreateForm],
  );

  const handleSetValueWithNestedFormHandling = useCallback(
    (id: IViewFieldId, value: IViewFieldValue, isUserEdited: boolean) => {
      const currentValue = form.getValues()[id];

      if (currentValue?.type === "relatedEntities") {
        const newRelatedEntityIds = new Set(
          value.type === "relatedEntities" ? value.value.map((entity) => entity.entityId) : [],
        );
        const unselectedRelatedEntities = currentValue.value.filter(
          (entity) => !newRelatedEntityIds.has(entity.entityId),
        );

        const draftEntityIdsNestedInField = new Set(nestedCreateForms[id] ?? []);

        // There should be a single one maximum as the setValue should be called once per change. Otherwise we can filter and make the handlers accept an array
        const unselectedNestedDraftEntity = unselectedRelatedEntities.find((entity) =>
          draftEntityIdsNestedInField.has(entity.entityId),
        );

        if (unselectedNestedDraftEntity != null) {
          handleCancelNewForm(id, unselectedNestedDraftEntity.entityId, id)();
        }
      }

      handleSetValue(id, value, isUserEdited);
    },
    [form, handleCancelNewForm, nestedCreateForms, handleSetValue],
  );

  const emailSideEffect = action.actionDefinition.sideEffects?.email;

  return (
    <Form {...form}>
      <form className={cn("flex flex-col space-y-4", className)}>
        {externalUserUpsertProps?.renderExternalUserForm === true ? (
          <ActionFormExternalUserForm
            allEntityTypes={allEntityTypes}
            allRelations={allRelations}
            currentUserInfo={currentUserInfo}
            externalUserUpsertProps={externalUserUpsertProps}
            form={form}
            getActionRoute={getActionRoute}
            getFullPageActionRoute={getFullPageActionRoute}
            getHighlightedViewFieldRoute={getHighlightedViewFieldRoute}
            getLinkedEntityRoute={getLinkedEntityRoute}
            organizationId={organizationId}
            validationErrors={validationErrors}
            versionType={versionType}
            onSetValue={handleSetValue}
          />
        ) : null}
        {action.actionDefinition.inputs.map((input) => (
          <ActionFormFieldWithNestedActions
            key={input.viewField.id}
            actionId={action.id}
            allEntityTypes={allEntityTypes}
            allRelations={allRelations}
            allValidationErrors={validationErrors}
            computationStatus={(modifyingEntity?.fieldsComputationStatuses ?? {})[input.viewField.id]}
            createNewProps={makeCreateNewProps(input.viewField)}
            currentUserInfo={currentUserInfo}
            dependencies={dependencies?.[input.viewField.id]}
            emailDraftValue={emailDraftValues?.[input.viewField.id]}
            entityId={modifyingEntity?.entityId}
            entityTypeId={action.entityTypeId}
            errors={validationErrors.fieldErrors[input.viewField.id] ?? []}
            form={form}
            getHighlightedViewFieldRoute={getHighlightedViewFieldRoute}
            getLinkedEntityRoute={getLinkedEntityRoute}
            highlightedViewFieldId={highlightedViewFieldId}
            input={input}
            isSavingDraft={saveState.fields[input.viewField.id] === true}
            modifyingEntity={modifyingEntity}
            nestedActionForField={nestedActionByViewFieldId?.[input.viewField.id]}
            nestedCreateFormIdsForField={nestedCreateForms[input.viewField.id]}
            organizationId={organizationId}
            parentFormExternalUserFieldValues={externalUserFieldValues}
            parentFormExternalUserSyntheticEntityId={currentUserInfo.userEntityId}
            parentFormNestedCreateFormValues={nestedCreateFormValues}
            previousOrLogicFieldValue={modifyingEntity?.fields[input.viewField.id]}
            readOnly={!input.allowChangingDefault}
            versionType={versionType}
            onCancelNewForm={handleCancelNewForm}
            onSetValue={handleSetValueWithNestedFormHandling}
            onSyncNestedCreateFormValues={handleSyncNestedCreateFormValues}
          />
        ))}
      </form>
      {emailSideEffect?.isEnabled === true && (
        <EmailSideEffectComponent allRelations={allRelations} emailSideEffect={emailSideEffect} />
      )}
      {validationErrors.generalErrors.map((error) => (
        <FormMessage key={error}>{error}</FormMessage>
      ))}
    </Form>
  );
};

type ICancellablePromiseValue<T> = Promise<
  | {
      type: "result";
      value: T;
    }
  | {
      type: "cancelled";
    }
>;

const cancellablePromise = <T,>(promise: Promise<T>): { promise: ICancellablePromiseValue<T>; cancel: () => void } => {
  const isCancelled = { value: false };

  const wrappedPromise: ICancellablePromiseValue<T> = promise
    .then((d) => {
      return isCancelled.value ? { type: "cancelled" as const } : { type: "result" as const, value: d };
    })
    .catch((e: unknown) => {
      if (isCancelled.value) {
        return { type: "cancelled" as const };
      }
      throw e;
    });

  return {
    promise: wrappedPromise,
    cancel: (): void => {
      isCancelled.value = true;
    },
  };
};

const EmailSideEffectComponent: React.FC<{
  emailSideEffect: IActionSideEffectEmail;
  allRelations: Partial<Record<IRelationId, IRelationCore>> | undefined;
}> = ({ emailSideEffect, allRelations }) => {
  const { saveDraft, saveState, emailState, emailStateRef } = useActionDraftServiceContext();
  const isSavingDraft = saveState.email;
  const handleBodyChange = useCallback(
    (body: string) => {
      void saveDraft({ type: "email", value: { subject: "", ...emailStateRef.current, body } });
    },
    [saveDraft, emailStateRef],
  );

  const handleSubjectChange = useCallback(
    (subject: string) => {
      void saveDraft({ type: "email", value: { body: "", ...emailStateRef.current, subject } });
    },
    [saveDraft, emailStateRef],
  );

  const emailEffectOverride = emailState;

  const toPersonRelation = emailSideEffect.toPersonRelation;

  if (allRelations == null || toPersonRelation == null) {
    return null;
  }

  const relation = allRelations[toPersonRelation.relationId];

  if (relation == null) {
    return null;
  }

  const toPersonRelationDirection = toPersonRelation.direction;

  const relationName =
    toPersonRelationDirection === "aToB"
      ? relation.displayMetadataFromAToB.name
      : relation.displayMetadataFromBToA.name;

  return (
    <div>
      <EmailComposer
        body={emailEffectOverride?.body ?? ""}
        from={{
          label: "Archie",
          value: "archie@archetype.do",
        }}
        fromOptions={[]}
        placeholder="Start typing to set custom email body..."
        subject={emailEffectOverride?.subject ?? ""}
        title={
          <div className="flex items-center">
            Compose Email {isSavingDraft ? <DotPinging className="ml-2" color="gray" size="sm" /> : null}
          </div>
        }
        // TODO resolve relation and show the value of the relation
        to={[{ label: relationName, value: "" }]}
        toOptions={[]}
        onBodyChange={handleBodyChange}
        onSubjectChange={handleSubjectChange}
      />
    </div>
  );
};

const ActionFormFieldWithNestedActions: React.FC<
  IActionFormFieldProps & {
    /**
     * To be used for errors on nested create forms
     */
    allValidationErrors: IValidationErrors;
    nestedActionForField:
      | {
          createAction: ILoadedAction;
          relevantFields: IViewFieldId[];
        }
      | undefined;
    nestedCreateFormIdsForField: IEntityId[] | undefined;
    onSyncNestedCreateFormValues?: ISyncNestedCreateFormValues;
    onCancelNewForm: (viewFieldId: IViewFieldId, nestedCreateFormId: IEntityId) => () => void;
    getLinkedEntityRoute: IGetLinkedEntityRoute;
    getHighlightedViewFieldRoute: IGetHighlightedViewFieldRoute | undefined;
  }
> = ({
  allValidationErrors,
  nestedActionForField,
  nestedCreateFormIdsForField,
  onCancelNewForm: handleCancelNewForm,
  ...props
}) => {
  const { onSyncNestedCreateFormValues, input } = props;

  const handleSyncNestedCreateFormValues = useCallback(
    (args: { nestedCreateEntityId: IEntityId; values: Partial<Record<IViewFieldId, IViewFieldValue>> }) => {
      onSyncNestedCreateFormValues?.({
        ...args,
        viewFieldId: input.viewField.id,
      });
    },
    [onSyncNestedCreateFormValues, input.viewField.id],
  );

  return (
    <>
      <ActionFormField {...props} />
      {nestedActionForField != null &&
        nestedCreateFormIdsForField != null &&
        nestedCreateFormIdsForField.length > 0 &&
        nestedCreateFormIdsForField.map((nestedCreateFormId) => (
          <ActionViewNestedCreate
            key={props.input.viewField.id + nestedCreateFormId}
            action={nestedActionForField.createAction}
            allEntityTypes={props.allEntityTypes}
            allRelations={props.allRelations}
            createDraftEntityId={nestedCreateFormId}
            currentUserInfo={props.currentUserInfo}
            getLinkedEntityRoute={props.getLinkedEntityRoute}
            isLoading={false}
            organizationId={props.organizationId}
            relevantFields={nestedActionForField.relevantFields}
            validationErrors={
              allValidationErrors.nestedActionErrors[nestedActionForField.createAction.entityTypeId]?.[
                nestedCreateFormId
              ] ?? {
                generalErrors: [],
                fieldErrors: {},
              }
            }
            versionType={props.versionType}
            onCancel={handleCancelNewForm(props.input.viewField.id, nestedCreateFormId)}
            onSyncFormValues={handleSyncNestedCreateFormValues}
          />
        ))}
    </>
  );
};
