import type { ILoadedAction } from "@archetype/core";
import { AdvancedDropdownMenuItemComponent } from "@archetype/ui";

import type { IGetActionRoute } from "../api";
import {
  type IActionExecutionComponentWrapperProps,
  useActionDirectExecutionWrapper,
} from "./useActionDirectExecutionWrapper";

interface IActionMenuItemProps extends IActionExecutionComponentWrapperProps {
  action: ILoadedAction;
  getActionRoute: IGetActionRoute;
}

export const ActionMenuItem: React.FC<IActionMenuItemProps> = ({
  action,
  entityId,
  presetParameters,
  versionType,
  onActionExecuted,
  getActionRoute,
  getFullPageActionRoute,
}) => {
  const { executeActionEnabled, actionRouteIfNonDirectExecution, isExecutingAction, handleExecuteAction } =
    useActionDirectExecutionWrapper({
      entityId,
      presetParameters,
      versionType,
      action,
      onActionExecuted,
      getActionRoute,
      getFullPageActionRoute,
    });

  if (actionRouteIfNonDirectExecution == null) {
    return (
      <AdvancedDropdownMenuItemComponent
        enforceIconAlignment={false}
        item={{
          id: action.id,
          type: "button",
          label: action.displayMetadata.name,
          disabled: !executeActionEnabled,
          loading: isExecutingAction,
          // eslint-disable-next-line @typescript-eslint/no-misused-promises -- ok here
          onSelect: handleExecuteAction,
        }}
      />
    );
  }

  return (
    <AdvancedDropdownMenuItemComponent
      enforceIconAlignment={false}
      item={{
        id: action.id,
        type: "link",
        label: action.displayMetadata.name,
        disabled: !executeActionEnabled,
        href: {
          type: "internal",
          route: actionRouteIfNonDirectExecution,
        },
      }}
    />
  );
};
