import type { ILoadedRelationViewField } from "@archetype/core";
import type { IEntityTypeCore, IVersionType } from "@archetype/dsl";
import { getFilterCount, type IDataLoadingQueryFilters } from "@archetype/dsl";
import type { IColumnId, IEntityTypeId } from "@archetype/ids";
import type { IButtonProps } from "@archetype/ui";
import { Button, PopoverPortal } from "@archetype/ui";
import { Popover, PopoverContent, PopoverTrigger } from "@archetype/ui";
import pluralize from "pluralize";

import { EntityTypeFilterContent } from "./EntityTypeFilterContent";

interface IEntityTypeFilter {
  entityType: IEntityTypeCore;
  allEntityTypes: Record<IEntityTypeId, IEntityTypeCore | undefined>;
  filterableColumnIds: IColumnId[];
  filterableRelations: ILoadedRelationViewField[];
  filters: IDataLoadingQueryFilters | undefined;
  onChangeFilters: (newFilters: IDataLoadingQueryFilters) => void;
  buttonSize?: IButtonProps["size"];
  versionType: IVersionType;
}

export const EntityTypeFilter: React.FC<IEntityTypeFilter> = ({
  entityType,
  allEntityTypes,
  filterableColumnIds,
  filterableRelations,
  filters,
  onChangeFilters: handleChangeFilters,
  buttonSize,
  versionType,
}) => {
  const filterCount = getFilterCount(filters);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="shrink-0 px-2" iconLeft="list-filter" size={buttonSize} variant="ghost">
          {filterCount > 0 ? `${filterCount.toFixed(0)} ${pluralize("filter", filterCount)}` : "Filter"}
        </Button>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent className="p-0">
          <EntityTypeFilterContent
            allEntityTypes={allEntityTypes}
            className="max-h-96 w-full max-w-2xl"
            entityType={entityType}
            filterableColumnIds={filterableColumnIds}
            filterableRelations={filterableRelations}
            filters={filters}
            versionType={versionType}
            onChangeFilters={handleChangeFilters}
          />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};
