import type {
  IDataLoadingQueryFilters,
  IDataLoadingSimpleColumnFilter,
  IDataLoadingSimpleEntityFilter,
  IDataLoadingSimpleRelationFilter,
} from "@archetype/dsl";

import { buildPerColumnFilter } from "./buildPerColumnFilter";

function isSimpleColumnFilter(filter: IDataLoadingSimpleEntityFilter): filter is IDataLoadingSimpleColumnFilter {
  return filter.type === "column";
}

function isSimpleRelationFilter(filter: IDataLoadingSimpleEntityFilter): filter is IDataLoadingSimpleRelationFilter {
  return filter.type === "relation";
}

export function convertDataLoadingSimpleToQueryFilters(
  filters: IDataLoadingSimpleEntityFilter[],
): IDataLoadingQueryFilters {
  const result: IDataLoadingQueryFilters = {
    type: "and",
    perColumn: {},
    andedRelatedToFilters: [],
    andedCrossColumnOrConditions: [],
  };

  const columnFilters: IDataLoadingSimpleColumnFilter[] = [];
  const relationFilters: IDataLoadingSimpleRelationFilter[] = [];

  filters.forEach((filter) => {
    if (isSimpleColumnFilter(filter)) columnFilters.push(filter);
    if (isSimpleRelationFilter(filter)) relationFilters.push(filter);
  });

  columnFilters.forEach(({ colId, op, value }) => {
    result.perColumn[colId] = {
      type: "or",
      rawOrConditions: buildPerColumnFilter(op, value),
      oredAndConditions: [],
    };
  });

  relationFilters.forEach(({ colId, relationId, direction, op, value }) => {
    result.andedRelatedToFilters ??= [];

    result.andedRelatedToFilters.push({
      type: "relatedTo",
      direction,
      relationId,
      filters: {
        type: "and",
        perColumn: {
          [colId]: {
            type: "or",
            rawOrConditions: { [op]: value.flatMap((id) => buildPerColumnFilter(op, [id])[op]) },
            oredAndConditions: [],
          },
        },
        andedRelatedToFilters: [],
        andedCrossColumnOrConditions: [],
      },
    });
  });

  return result;
}
