import type { ILoadedEntityType } from "@archetype/core";
import {
  computeColumnViewFieldId,
  computeRelationViewFieldId,
  FieldValueParser,
  type IVersionType,
  type IViewFieldValue,
} from "@archetype/dsl";
import type { IColumnId, IRelationId } from "@archetype/ids";
import { ColumnId, type IEntityTypeId, type IOrganizationId, type IViewFieldId, RelationId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import type { IIconOrShape, TextMatchTransformer } from "@archetype/ui";
import { cn, ShapeColorIcon } from "@archetype/ui";
import { Icon } from "@archetype/ui";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type { MenuTextMatch } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  useBasicTypeaheadTriggerMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import type { TextNode } from "lexical";
import { $createTextNode, $isTextNode } from "lexical";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";

import { useEntityFieldMentionLookupService } from "../entity/useEntityFieldMentionLookupService";
import { useEntityTypeFields } from "../hooks/viewFields/useEntityTypeFields";
import {
  $createEntityFieldMentionNode,
  $isColumnMention,
  $isEntityFieldMentionNode,
  $isRelationMention,
  EntityFieldMentionNode,
} from "./EntityFieldMentionNode";

interface IEntityFieldMentionsPluginProps {
  versionType: IVersionType;
  entityTypeId: IEntityTypeId;
  organizationId: IOrganizationId;
  fieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
  loadedEntityType?: ILoadedEntityType;
  variant?: "text" | "badge";
}

const ENTITY_FIELD_MENTION_REGEX = /@[cr]\.([^.\s]+)(?:\.([^.\s]+))?/;

export const generateEntityFieldMentionTransformer = ({
  fieldValues,
  loadedEntityType,
  variant,
}: {
  fieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
  loadedEntityType: ILoadedEntityType | undefined;
  variant: "badge" | "text";
}): TextMatchTransformer => {
  return {
    type: "text-match",
    dependencies: [EntityFieldMentionNode],
    export: (node): string | null => {
      if (!$isEntityFieldMentionNode(node)) {
        return null;
      }

      const mention = node.__mention;

      if ($isColumnMention(mention)) {
        const columnId = mention.columnId;

        return `@c.${columnId}`;
      }

      if ($isRelationMention(mention)) {
        const relationId = mention.relationId;
        const direction = mention.direction;

        if (loadedEntityType == null) {
          // eslint-disable-next-line no-console -- shouldnt happen
          console.error("loadedEntityType is null");

          return null;
        }

        return `@r.${relationId}.${direction}`;
      }

      return null;
    },
    importRegExp: ENTITY_FIELD_MENTION_REGEX,
    regExp: ENTITY_FIELD_MENTION_REGEX,
    replace: (textNode: TextNode, match: RegExpMatchArray): void => {
      const [fullMatch, columnOrRelationId, direction] = match;

      if (columnOrRelationId == null) {
        return;
      }

      // If it's a relation mention (@r)
      if (fullMatch.startsWith("@r.")) {
        const parseDirection = (direction ?? "aToB") as "aToB" | "bToA";
        const relationId = RelationId.parse(columnOrRelationId);
        const viewFieldId = computeRelationViewFieldId(relationId, parseDirection);
        const fieldValue = fieldValues[viewFieldId];
        const stringValue = FieldValueParser.toString(fieldValue);

        const mentionNode = $createEntityFieldMentionNode({
          mention: {
            type: "relation",
            relationId,
            direction: parseDirection,
          },
          value: stringValue ?? "",
          variant,
        });

        textNode.replace(mentionNode);
      } else {
        // It's a column mention (@c)
        const columnId = ColumnId.parse(columnOrRelationId);
        const viewFieldId = computeColumnViewFieldId(columnId);
        const fieldValue = fieldValues[viewFieldId];
        const stringValue = FieldValueParser.toString(fieldValue);

        const mentionNode = $createEntityFieldMentionNode({
          mention: {
            type: "column",
            columnId,
          },
          value: stringValue ?? "",
          variant,
        });

        textNode.replace(mentionNode);
      }
    },
  };
};

export function EntityFieldMentionsPlugin({
  versionType,
  entityTypeId,
  organizationId,
  fieldValues,
  variant = "badge",
}: IEntityFieldMentionsPluginProps): React.JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState<string | null>(null);
  const [results, setResults] = useState<Array<IEntityFieldMentionTypeaheadOption>>([]);
  const { data: entityTypeQuery } = builderTrpc.dataModel.fullyLoadedEntityType.useQuery({
    id: entityTypeId,
    versionType,
  });

  const { allFields } = useEntityTypeFields({
    entityTypeId,
    entityType: entityTypeQuery?.entityType,
    versionType,
    stateId: undefined,
  });

  const loadedEntityType = entityTypeQuery?.entityType;

  const mentionLookupService = useEntityFieldMentionLookupService({ versionType, entityTypeId, organizationId });

  // Register the EntityFieldMentionNode
  useEffect(() => {
    if (!editor.hasNodes([EntityFieldMentionNode])) {
      throw new Error("EntityFieldMentionNode not registered in editor config!");
    }
  }, [editor]);

  // Update field values when entity data changes
  useEffect(() => {
    const updateNodes = (): void => {
      editor.update((): void => {
        editor.getEditorState()._nodeMap.forEach((node) => {
          if (loadedEntityType == null) {
            return;
          }

          if (!$isEntityFieldMentionNode(node) || !node.isAttached()) {
            return;
          }

          const mention = node.__mention;
          let stringValue: string | undefined;

          if ($isColumnMention(mention)) {
            const columnId = mention.columnId;

            const fieldId = computeColumnViewFieldId(columnId);
            const fieldValue = fieldValues[fieldId];

            stringValue = FieldValueParser.toString(fieldValue);
          } else if ($isRelationMention(mention)) {
            const relationId = mention.relationId;
            const relation = loadedEntityType.relations[relationId];

            if (relation == null) {
              return;
            }

            const parsedDirection = mention.direction;
            const fieldId = computeRelationViewFieldId(relationId, parsedDirection);
            const fieldValue = fieldValues[fieldId];

            stringValue = FieldValueParser.toString(fieldValue);
          } else {
            return;
          }

          const currentText = node.getTextContent();

          if (stringValue !== currentText) {
            node.setTextContent(stringValue ?? "");
          }
        });
      });
    };

    // Debounce updates to prevent rapid re-renders
    const timeoutId = setTimeout(updateNodes, 100);

    return (): void => {
      clearTimeout(timeoutId);
    };
  }, [editor, fieldValues, loadedEntityType, allFields]);

  useEffect(() => {
    let isMounted = true;

    async function searchMentions(): Promise<void> {
      if (queryString == null) {
        setResults([]);

        return;
      }

      const searchResults = await mentionLookupService.search(queryString.replace("@", ""));

      if (isMounted) {
        setResults(searchResults);
      }
    }

    void searchMentions();

    return (): void => {
      isMounted = false;
    };
  }, [queryString, mentionLookupService]);

  const regexes = useMemo(() => buildMentionRegexes(DEFAULT_CONFIG), []);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
    minLength: 0,
  });

  const options = useMemo(
    () =>
      results.map((result) => new EntityFieldMentionTypeaheadOption(result)).slice(0, DEFAULT_CONFIG.suggestionLimit),
    [results],
  );

  const handleSelectOption = useCallback(
    (selectedOption: EntityFieldMentionTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        let mentionNode: TextNode | null = null;

        if (selectedOption.data.type === "column") {
          const id = selectedOption.data.id;

          if (id === "") {
            return;
          }

          const parsedColumnId = ColumnId.parse(id);
          const fieldId = computeColumnViewFieldId(parsedColumnId);

          // Get field value from field values map
          const fieldValue = FieldValueParser.toString(fieldValues[fieldId]);
          const stringValue = fieldValue == null ? "" : fieldValue;

          mentionNode = $createEntityFieldMentionNode({
            mention: {
              type: "column",
              columnId: parsedColumnId,
            },
            value: stringValue,
            variant,
          });
        } else {
          const id = selectedOption.data.id;

          if (id === "") {
            return;
          }

          const relationId = RelationId.parse(id);
          const relation = loadedEntityType?.relations[relationId];

          if (relation == null) {
            return;
          }

          const parsedDirection = selectedOption.data.direction;

          mentionNode = $createEntityFieldMentionNode({
            mention: {
              type: "relation",
              relationId,
              direction: parsedDirection,
            },
            value: "",
            variant,
          });
        }

        if (nodeToReplace != null) {
          nodeToReplace.replace(mentionNode);
        }

        // Create an empty text node after the mention if there isn't one
        let nextSibling = mentionNode.getNextSibling();

        if (!nextSibling || !$isTextNode(nextSibling)) {
          nextSibling = $createTextNode(" ");
          mentionNode.insertAfter(nextSibling);
        }

        // Move selection to the next node
        mentionNode.selectNext();
        closeMenu();
      });
    },
    [editor, fieldValues, loadedEntityType, variant],
  );

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);

      if (slashMatch !== null) {
        return null;
      }

      return getPossibleQueryMatch(text, regexes);
    },
    [checkForSlashTriggerMatch, editor, regexes],
  );

  const handleQueryChange = useCallback((query: string | null) => {
    setQueryString(query);
  }, []);

  const renderMenu = useCallback(
    (
      anchorElementRef: React.RefObject<HTMLElement>,
      {
        selectedIndex,
        selectOptionAndCleanUp,
        setHighlightedIndex: handleHighlight,
      }: {
        selectedIndex: number | null;
        selectOptionAndCleanUp: (option: EntityFieldMentionTypeaheadOption) => void;
        setHighlightedIndex: (index: number) => void;
      },
    ): React.JSX.Element | null => {
      if (!anchorElementRef.current || results.length === 0) {
        return null;
      }

      const handleSelect = (option: EntityFieldMentionTypeaheadOption, index: number): void => {
        handleHighlight(index);
        selectOptionAndCleanUp(option);
      };

      const menu = (
        <div className="border-border bg-paper fixed z-[999] flex flex-wrap gap-1 rounded-md border p-1 shadow">
          <ul className="flex flex-col gap-1">
            {options.map((option, index) => (
              <EntityFieldMentionsTypeaheadMenuItem
                key={option.key}
                index={index}
                isSelected={selectedIndex === index}
                option={option}
                onHighlight={handleHighlight}
                // eslint-disable-next-line react/jsx-no-bind -- internal to this fn cant use callback
                onSelect={handleSelect}
              />
            ))}
          </ul>
        </div>
      );

      return createPortal(menu, anchorElementRef.current);
    },
    [options, results.length],
  );

  return (
    <LexicalTypeaheadMenuPlugin<EntityFieldMentionTypeaheadOption>
      menuRenderFn={renderMenu}
      options={options}
      triggerFn={checkForMentionMatch}
      onQueryChange={handleQueryChange}
      onSelectOption={handleSelectOption}
    />
  );
}

type IEntityFieldMentionTypeaheadOption =
  | {
      type: "column";
      id: IColumnId;
      name: string;
      icon: IIconOrShape;
    }
  | {
      type: "relation";
      id: IRelationId;
      name: string;
      icon: IIconOrShape;
      direction: "aToB" | "bToA";
    };

class EntityFieldMentionTypeaheadOption extends MenuOption {
  data: IEntityFieldMentionTypeaheadOption;

  constructor(data: IEntityFieldMentionTypeaheadOption) {
    super(data.name);
    this.data = data;
  }
}

function EntityFieldMentionsTypeaheadMenuItem({
  index,
  isSelected,
  onHighlight: handleHighlight,
  onSelect: handleSelect,
  option,
}: {
  index: number;
  isSelected: boolean;
  onHighlight: (index: number) => void;
  onSelect: (option: EntityFieldMentionTypeaheadOption, index: number) => void;
  option: EntityFieldMentionTypeaheadOption;
}): React.JSX.Element {
  const handleSetRefElement = useCallback(
    (element: HTMLElement | null) => {
      option.setRefElement(element);
    },
    [option],
  );

  const handleClick = useCallback(() => {
    handleSelect(option, index);
  }, [handleSelect, index, option]);

  const handleMouseEnter = useCallback(() => {
    handleHighlight(index);
  }, [handleHighlight, index]);

  const maybeRenderIcon = useCallback(() => {
    if (option.data.icon.type === "icon") {
      return <Icon className="size-3" name={option.data.icon.value} />;
    }

    return (
      <ShapeColorIcon className="-mx-0.5" color={option.data.icon.color} shape={option.data.icon.value} size="sm" />
    );
  }, [option.data.icon]);

  return (
    <li
      key={option.data.id}
      ref={handleSetRefElement}
      aria-selected={isSelected}
      className={cn(
        "hover:bg-accent flex cursor-pointer items-center gap-x-2 rounded bg-transparent px-2 py-1",
        isSelected && "bg-accent",
      )}
      id={option.data.id}
      role="option"
      tabIndex={-1}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
    >
      {maybeRenderIcon()}
      <span className="text-base">{option.data.name}</span>
    </li>
  );
}

const DEFAULT_CONFIG = {
  triggers: ["@"],
  punctuation: "\\.,\\+\\*\\?\\$\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;",
  suggestionLimit: 10,
};

function buildMentionRegexes(config: typeof DEFAULT_CONFIG): {
  mentionsRegex: RegExp;
  aliasRegex: RegExp;
} {
  const TRIGGERS = config.triggers.join("");

  // Simplified regex that just matches @ followed by any non-whitespace characters
  const AtSignMentionsRegex = new RegExp("(^|\\s|\\()([" + TRIGGERS + "]([^\\s]*)?)$");

  // Same regex for alias
  const AtSignMentionsRegexAliasRegex = AtSignMentionsRegex;

  return {
    mentionsRegex: AtSignMentionsRegex,
    aliasRegex: AtSignMentionsRegexAliasRegex,
  };
}

function checkForAtSignMentions(
  text: string,
  minMatchLength: number,
  regexes: ReturnType<typeof buildMentionRegexes>,
): MenuTextMatch | null {
  let match = regexes.mentionsRegex.exec(text);

  if (match === null) {
    match = regexes.aliasRegex.exec(text);
  }

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];

    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + (maybeLeadingWhitespace?.length ?? 0),
        matchingString,
        replaceableString: match[2] ?? "",
      };
    }
  }

  return null;
}

function getPossibleQueryMatch(text: string, regexes: ReturnType<typeof buildMentionRegexes>): MenuTextMatch | null {
  return checkForAtSignMentions(text, 0, regexes);
}
