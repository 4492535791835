import type { IViewFieldValue } from "@archetype/dsl";
import { isRelationFieldValue } from "@archetype/dsl";
import type { IViewFieldId } from "@archetype/ids";
import { mapValues } from "@archetype/utils";

import type { ILoadedNestedCreateFormValues, IReplacementNestedEntityIds } from "../apiTypes/ActionExecution";

export const replaceFormAndNestedValuesWithRealEntityIds = ({
  replacementNestedEntityIds,
  fieldValues,
  nestedCreateFormValues,
}: {
  replacementNestedEntityIds: IReplacementNestedEntityIds;
  fieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
  nestedCreateFormValues: ILoadedNestedCreateFormValues;
}): {
  fieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
  nestedCreateFormValues: ILoadedNestedCreateFormValues;
} => ({
  fieldValues: replaceFieldValuesWithRealEntityIds({
    replacementNestedEntityIds,
    fieldValues,
  }),
  nestedCreateFormValues: mapValues(nestedCreateFormValues, (nestedCreateFormForEntityType) =>
    mapValues(nestedCreateFormForEntityType, (nestedCreateForm) =>
      nestedCreateForm == null
        ? undefined
        : {
            ...nestedCreateForm,
            fieldValues: replaceFieldValuesWithRealEntityIds({
              replacementNestedEntityIds,
              fieldValues: nestedCreateForm.fieldValues,
            }),
          },
    ),
  ),
});

const replaceFieldValuesWithRealEntityIds = ({
  fieldValues,
  replacementNestedEntityIds,
}: {
  fieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
  replacementNestedEntityIds: IReplacementNestedEntityIds;
}): Partial<Record<IViewFieldId, IViewFieldValue>> => {
  const parsedFieldValues = mapValues(fieldValues, (fieldValue) => {
    if (fieldValue == null || !isRelationFieldValue(fieldValue)) {
      return fieldValue;
    }

    return {
      ...fieldValue,
      value: fieldValue.value.map((v) => {
        const replacedEntityInfo = replacementNestedEntityIds[v.entityTypeId]?.[v.entityId];

        if (replacedEntityInfo == null) {
          return v;
        }

        return {
          ...v,
          entityId: replacedEntityInfo.realEntityId,
          displayName: replacedEntityInfo.displayName !== "" ? replacedEntityInfo.displayName : v.displayName,
        };
      }),
    };
  });

  return parsedFieldValues;
};

export const __test = {
  replaceFieldValuesWithRealEntityIds,
};
