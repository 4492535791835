import {
  MultiSelectDropdown,
  MultiSelectDropdownContent,
  MultiSelectDropdownItem,
  MultiSelectDropdownTrigger,
  MultiSelectDropdownValue,
} from "@archetype/ui";
import { isNonNullable, keyByNoUndefined, map } from "@archetype/utils";
import { useCallback, useMemo, useState } from "react";
import React from "react";

import type { IColumnFilterComponent } from "../types";
import { StringFilterInput } from "./StringFilterInput";

export const StatusEnumFilterInput: IColumnFilterComponent = (props) => {
  const { className, value, onChange: handleChange, columnType } = props;

  const [open, handleOpenChange] = useState(false);

  const statusEnumAllowedValues = useMemo(() => {
    return columnType.type === "statusEnum"
      ? keyByNoUndefined(columnType.archivedValues.concat(columnType.allowedValues), (v) => v.id as string)
      : undefined;
  }, [columnType]);

  const handleEnumChange = useCallback(
    (itemValue: string) =>
      (checked: boolean): void => {
        const currentValues =
          value?.eq
            ?.map((eq) => (eq.type === "value" && eq.value.type === "string" ? eq.value.value : undefined))
            .filter(isNonNullable) ?? [];

        let newValues: string[];

        if (checked) {
          newValues = [...currentValues, itemValue];
        } else {
          newValues = currentValues.filter((v) => v !== itemValue);
        }

        if (newValues.length === 0) {
          handleChange(undefined);
        } else {
          handleChange({
            eq: newValues.map((v) => ({ type: "value", value: { type: "string", value: v } })),
          });
        }
      },
    [value, handleChange],
  );

  if (columnType.type !== "statusEnum" || columnType.allowedValues.length === 0) {
    return <StringFilterInput {...props} />;
  }

  const enumValues = statusEnumAllowedValues;

  const currentValues =
    value?.eq
      ?.map((eq) => (eq.type === "value" && eq.value.type === "string" ? eq.value.value : undefined))
      .filter(isNonNullable) ?? [];

  return (
    <MultiSelectDropdown open={open} onOpenChange={handleOpenChange}>
      <MultiSelectDropdownTrigger small className={className}>
        <MultiSelectDropdownValue small label="status" selectedItems={currentValues} />
      </MultiSelectDropdownTrigger>
      <MultiSelectDropdownContent>
        {map(enumValues, (option) => (
          <MultiSelectDropdownItem
            key={option.id}
            checked={currentValues.includes(option.id as string)}
            onCheckedChange={handleEnumChange(option.id as string)}
          >
            {option.readableValue}
          </MultiSelectDropdownItem>
        ))}
      </MultiSelectDropdownContent>
    </MultiSelectDropdown>
  );
};
