import type { ILoadedEntity } from "@archetype/core";
import type { IColumnType, IVersionType } from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";
import type { IOrganizationId } from "@archetype/ids";
import type { IIconOrShape } from "@archetype/ui";
import { Icon, LiveFieldIndicator, ShapeColorIcon, Tooltip, TooltipContent, TooltipTrigger } from "@archetype/ui";
import { cn } from "@archetype/ui";
import * as React from "react";
import { useCallback } from "react";

import type { IGetLinkedEntityRoute } from "../api";
import type { IFieldValueRendererField } from "./FieldValueRenderer";
import { FieldValueRenderer } from "./FieldValueRenderer";

export interface IFieldRendererField extends IFieldValueRendererField {
  icon: IIconOrShape;
  isDerived: boolean;
  isAiAutofilled: boolean;
  /**
   * Render instead of the field value if waiting for dependencies to be filled in an action form.
   * TODO - should probably be typed more strongly with actual dependencies
   */
  waitingForDependenciesString?: string;
  column?: { columnType: IColumnType };
}

interface IFieldRenderer {
  field: IFieldRendererField;
  getLinkedEntityRoute?: IGetLinkedEntityRoute;
  isHighlighted: boolean;
  entity: ILoadedEntity | undefined;
  fieldRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  interactive?: boolean;
  versionType: IVersionType;
  organizationId: IOrganizationId;
  entityTypeId: IEntityTypeId;
}

export const FieldRenderer: React.FC<IFieldRenderer> = ({
  field,
  getLinkedEntityRoute,
  isHighlighted,
  fieldRef,
  className,
  interactive = true,
  versionType,
  organizationId,
  entityTypeId,
  entity,
}) => {
  const renderValue = useCallback(() => {
    if (field.waitingForDependenciesString != null) {
      return <div className="text-placeholder text-sm leading-5">{field.waitingForDependenciesString}</div>;
    }

    return (
      <FieldValueRenderer
        entity={entity}
        entityTypeId={entityTypeId}
        field={field}
        getLinkedEntityRoute={getLinkedEntityRoute}
        organizationId={organizationId}
        versionType={versionType}
        wrapperClass="items-start shrink whitespace-normal break-words text-base leading-5"
      />
    );
  }, [entity, entityTypeId, field, getLinkedEntityRoute, organizationId, versionType]);

  return (
    <div
      ref={fieldRef}
      className={cn(
        "relative -mx-2 -my-1 flex shrink-0 gap-3 rounded px-2 py-1",
        field.value.type === "string" && field.column?.columnType.type === "longText"
          ? "flex-col gap-1 sm:flex-row sm:gap-3"
          : "flex-row",
        interactive && "hover:bg-accent-background cursor-pointer",
        isHighlighted && "bg-accent-background",
        className,
      )}
      data-testid="entity-field"
      id={field.id}
    >
      <div className="flex w-36 shrink-0 items-start overflow-hidden sm:w-52">
        <div className="flex w-full items-center justify-between gap-1.5 overflow-hidden">
          <div className="flex items-center gap-1.5 overflow-hidden">
            {field.icon.type === "icon" ? (
              <Icon className="size-3.5 shrink-0" name={field.icon.value} />
            ) : (
              <ShapeColorIcon className="shrink-0" color={field.icon.color} shape={field.icon.value} size="sm" />
            )}
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="min-w-0">
                  <div className="text-muted-foreground w-full truncate text-base leading-5">{field.displayName}</div>
                </div>
              </TooltipTrigger>
              <TooltipContent>{field.displayName}</TooltipContent>
            </Tooltip>
          </div>
          <div className="flex shrink-0 items-center gap-2">
            {field.isDerived ? <LiveFieldIndicator type="live" /> : null}
            {field.isAiAutofilled ? <LiveFieldIndicator type="ai" /> : null}
          </div>
        </div>
      </div>
      {renderValue()}
    </div>
  );
};
