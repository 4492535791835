import type { IDataLoadingFilterOrOperator, IDataLoadingQueryColumnOrFilters } from "@archetype/dsl";
import { match, P } from "ts-pattern";

export const buildPerColumnFilter = (
  operator: IDataLoadingFilterOrOperator,
  filterValues: string[],
): IDataLoadingQueryColumnOrFilters => {
  return match([operator, filterValues])
    .returnType<IDataLoadingQueryColumnOrFilters>()

    .with([P.any, P.when((array) => array.length === 0)], () => ({}))

    .with([P.union("eq", "eqIgnoreCase"), P.any], ([type]) => ({
      [type]: [{ type: "value", value: { value: filterValues[0] ?? "", type: "string" } }],
    }))

    .with([P.union("neq", "neqIgnoreCase"), P.any], ([type]) => ({
      [type]: { type: "value", value: { value: filterValues[0] ?? "", type: "string" } },
    }))

    .with([P.union("lt", "lte", "gt", "gte"), P.any], ([type]) => ({
      [type]: { type: "value", value: { value: Number(filterValues[0]), type: "number" } },
    }))

    .with(["regex", P.any], () => ({ regex: { type: "regex", value: filterValues[0] ?? "", flags: "gi" } }))

    .with([P.union("anyInArray", "noneInArray", "allInArray", "allNotInArray"), P.any], ([type]) => ({
      [type]: { type: "value", value: { value: filterValues, type: "array" } },
    }))

    .exhaustive();
};
