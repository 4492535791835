export class AbstractError<T extends string> extends Error {
  __proto__ = Error;
  name: T;
  message: string;
  cause: unknown;

  constructor({ name, message, cause }: { name: T; message: string; cause?: unknown }) {
    super(message);
    Object.setPrototypeOf(this, AbstractError.prototype);

    this.name = name;
    this.message = message;
    this.cause = cause;
  }
}
