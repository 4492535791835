import type { IActionId, IStateId } from "@archetype/ids";
import { mapValues } from "@archetype/utils";

import type { IActionCore } from "../../schemas/dataModel/Action";
import type { IViewField } from "../../schemas/dataModel/ViewField";
import type { IStateMachine } from "../../schemas/ProcessStateMachine";
import { defaultTransitionByState, type IDefaultTransitionInfo } from "./defaultTransitionByState";

export interface IContextColumnInfo extends IDefaultTransitionInfo {
  contextualFields: IViewField[];
}

export type IContextColumnsByState = Record<IStateId, IContextColumnInfo>;

/**
 * Gets the context field for the primary action in a state, with a priority for the happy path action.
 * An end state (or a state with no outward transition) will have no context fields.
 * If executable actionIds are provided, we will not mark as primary an action that is not executable
 */
export const contextColumnByState = (
  stateMachine: Pick<IStateMachine, "stateTransitions" | "happyPath">,
  actionsById: Record<IActionId, IActionCore>,
  /**
   * The executable actionIds for the user if defined, to prioritize columns from actions that can be executed
   */
  executableActionIds: IActionId[] | undefined,
): IContextColumnsByState => {
  // An end state (no transition out) will not have any context column
  const res: IContextColumnsByState = mapValues(
    defaultTransitionByState(stateMachine, executableActionIds),
    (transitionInfo) => contextColumnInfoFromTransitionId(transitionInfo, actionsById),
  );

  return res;
};

const contextColumnInfoFromTransitionId = (
  transitionInfo: IDefaultTransitionInfo,
  actionsById: Record<IActionId, IActionCore>,
): IContextColumnInfo => {
  const transition = transitionInfo.primaryTransition ?? transitionInfo.defaultTransition;

  if (transition == null) {
    return {
      ...transitionInfo,
      contextualFields: [],
    };
  }

  return {
    ...transitionInfo,
    contextualFields: actionsById[transition.actionId]?.actionDefinition.contextualFields ?? [],
  };
};
