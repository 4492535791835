import type { ILoadedAction, ILoadedEntity } from "@archetype/core";
import type { IVersionType } from "@archetype/dsl";
import { computeColumnViewFieldId, FieldValueParser } from "@archetype/dsl";
import type { IColumnId, IStateId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { Skeleton, useMemoDeepCompare } from "@archetype/ui";
import React, { useCallback, useMemo } from "react";

import { ActionButton } from "../../action/ActionButton";
import { MultiActionPopoverButton } from "../../action/MultiActionPopoverButton";
import type { IGetActionRoute } from "../../api";
import { filterRelevantActionsForState } from "../../utils/filterRelevantActionsForState";

interface IEntityTableActionCell {
  versionType: IVersionType;
  entity: ILoadedEntity;
  inlineActions: ILoadedAction[];
  statusColumnId: IColumnId | null | undefined;
  getActionRoute: IGetActionRoute;
  getFullPageActionRoute: IGetActionRoute;
  onActionExecuted?: (entity: ILoadedEntity | undefined) => Promise<void>;
}

export const EntityTableActionCell: React.FC<IEntityTableActionCell> = ({
  versionType,
  entity,
  inlineActions,
  statusColumnId,
  getFullPageActionRoute,
  getActionRoute,
  onActionExecuted,
}) => {
  const { data: currentEntityActionAccessQuery, isLoading: isLoadingActionAccess } =
    builderTrpc.action.getAccessToActionsByEntityTypeId.useQuery(
      {
        versionType,
        entityTypeId: entity.entityTypeId,
        entityId: entity.entityId,
      },
      {
        trpc: {
          abortOnUnmount: true,
        },
      },
    );

  const currentEntityActionAccess = currentEntityActionAccessQuery?.accessByAction;

  const relevantActions = useMemo(() => {
    if (isLoadingActionAccess || currentEntityActionAccess == null) {
      return [];
    }

    const statusFieldId = statusColumnId == null ? null : computeColumnViewFieldId(statusColumnId);
    const entityStatusValue = statusFieldId == null ? null : entity.fields[statusFieldId];
    const entityStatus = FieldValueParser.toString(entityStatusValue) as IStateId | null;

    // return filterRelevantActionsForState(entityStatus ?? undefined, inlineActions);
    return filterRelevantActionsForState(entityStatus ?? undefined, inlineActions).filter(
      (action) => currentEntityActionAccess[action.id]?.canExecute === true,
    );
  }, [entity, statusColumnId, inlineActions, isLoadingActionAccess, currentEntityActionAccess]);

  const [firstAction, ...additionalActions] = relevantActions;

  const executableAdditionalActions = useMemoDeepCompare(
    () =>
      additionalActions.map((action) => ({
        action,
        entityId: entity.entityId,
        defaultValues: {},
      })),
    [entity.entityId, additionalActions],
  );

  const handleActionExecuted = onActionExecuted;

  const maybeRenderFirstAction = useCallback(() => {
    if (isLoadingActionAccess) {
      return <Skeleton className="h-8 w-24" />;
    }

    if (firstAction == null) {
      return null;
    }

    return (
      <ActionButton
        action={firstAction}
        className="min-w-1"
        entityId={entity.entityId}
        getActionRoute={getActionRoute}
        getFullPageActionRoute={getFullPageActionRoute}
        presetParameters={undefined}
        size="sm"
        variant="secondary"
        versionType={versionType}
        onActionExecuted={handleActionExecuted}
      />
    );
  }, [
    versionType,
    firstAction,
    entity.entityId,
    getFullPageActionRoute,
    getActionRoute,
    handleActionExecuted,
    isLoadingActionAccess,
  ]);

  const renderAdditionalActions = useCallback(() => {
    if (executableAdditionalActions.length === 0) {
      return null;
    }

    return (
      <MultiActionPopoverButton
        actions={executableAdditionalActions}
        getActionRoute={getActionRoute}
        getFullPageActionRoute={getFullPageActionRoute}
        variant="secondary"
        versionType={versionType}
        onActionExecuted={handleActionExecuted}
      />
    );
  }, [versionType, executableAdditionalActions, getFullPageActionRoute, getActionRoute, handleActionExecuted]);

  return (
    <div className="flex h-full items-center gap-x-1 overflow-hidden px-2">
      {maybeRenderFirstAction()}
      {renderAdditionalActions()}
    </div>
  );
};
