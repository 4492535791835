import { range, uniq } from "lodash";

import { useMemoDeepCompare } from "../../hooks/useMemoDeepCompare";
import { cn } from "../../lib/utils";
import { Skeleton } from "./skeleton";

export interface ITaskListProps {
  className?: string;
  tasks: Array<{
    text: string;
    status: "up-next" | "todo" | "done" | "failed";
  }>;
}

const SKELETON_WIDTHS = ["w-14", "w-20", "w-16", "w-20", "w-24", "w-16"];

export const TaskListSkeleton: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={cn("flex flex-col items-start gap-y-1 overflow-hidden", className)}>
      {range(6).map((n) => (
        <div key={n} className="flex w-full flex-row items-center justify-start gap-x-2">
          <div className="bg-skeleton size-4 shrink-0 text-nowrap rounded-full" />
          <Skeleton className={cn(SKELETON_WIDTHS[n] ?? "w-16", "h-4 max-w-full")} />
        </div>
      ))}
    </div>
  );
};

export const TaskList: React.FC<ITaskListProps> = ({ tasks, className }) => {
  const uniqueTasks = useMemoDeepCompare(() => uniq(tasks.map((task) => task.text)).length === tasks.length, [tasks]);

  return (
    <div className={cn("flex flex-col items-start gap-y-1 overflow-hidden", className)}>
      {tasks.map((task, index) => (
        <div
          key={uniqueTasks ? task.text : `${task.text}-${index.toString()}`}
          className="flex w-full flex-row items-center justify-start gap-x-2"
        >
          <div
            className={cn(
              "text-primary flex size-4 shrink-0 select-none justify-center rounded-full",
              task.status === "done" && "bg-primary",
              task.status === "up-next" && "border-primary text-primary border-2 font-medium",
              task.status === "todo" && "border border-gray-400",
              task.status === "failed" && "bg-gray-400",
            )}
          >
            {task.status === "done" && <span className="text-[6pt] text-white [line-height:2]">✔️</span>}
            {task.status === "failed" && <span className="text-[6pt] text-white [line-height:2]">❌</span>}
          </div>
          <div
            className={cn("shrink grow truncate text-nowrap text-base", task.status === "up-next" && "text-primary")}
            title={task.text}
          >
            {task.text}
          </div>
        </div>
      ))}
    </div>
  );
};
