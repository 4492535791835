import type { IApplicationEventId, IApplicationId, IUserId } from "@archetype/ids";

export enum ApplicationEvent {
  VIEW = "view",
}

export interface IApplicationEvent {
  id: IApplicationEventId;
  userId: IUserId;
  applicationId: IApplicationId;
  event: ApplicationEvent;
  createdAt: Date;
  updatedAt: Date;
}
