import type { ILoadedRelationViewField } from "@archetype/core";
import type { IDataLoadingQueryFilters, IEntityTypeCore, IVersionType } from "@archetype/dsl";
import { computeRelationViewFieldId } from "@archetype/dsl";
import type { IColumnId, IEntityTypeId, IViewFieldId } from "@archetype/ids";
import { cn, useMemoDeepCompare } from "@archetype/ui";
import { isNonNullable, keyByNoUndefined } from "@archetype/utils";
import React from "react";

import { ColumnFilterInput } from "./ColumnFilterInput";
import { useFilterUpdates } from "./hooks/useFilterUpdates";
import { useFilterValues } from "./hooks/useFilterValues";
import { RelationFilterInput } from "./RelationFilterInput";

interface IEntityTypeFilterContent {
  className?: string;
  versionType: IVersionType;
  entityType: IEntityTypeCore;
  filterableColumnIds: IColumnId[];
  filterableRelations: ILoadedRelationViewField[];
  filters: IDataLoadingQueryFilters | undefined;
  onChangeFilters: (newFilters: IDataLoadingQueryFilters) => void;
  allEntityTypes: Record<IEntityTypeId, IEntityTypeCore | undefined>;
}

export const EntityTypeFilterContent: React.FC<IEntityTypeFilterContent> = ({
  className,
  versionType,
  entityType,
  filterableColumnIds,
  filterableRelations,
  filters,
  onChangeFilters: handleChangeFilters,
  allEntityTypes,
}) => {
  const columnById = useMemoDeepCompare(() => keyByNoUndefined(entityType.columns, (col) => col.id), [entityType]);
  const filterableColumnIdsSet = useMemoDeepCompare(() => new Set(filterableColumnIds), [filterableColumnIds]);
  const filterableRelationKeysSet = useMemoDeepCompare(
    () =>
      new Set(
        filterableRelations.map(
          ({ relation, direction }): IViewFieldId => computeRelationViewFieldId(relation.id, direction),
        ),
      ),
    [filterableRelations],
  );

  const filterColumns = useMemoDeepCompare(
    () => filterableColumnIds.map((colId) => columnById[colId]).filter(isNonNullable),
    [filterableColumnIds, columnById],
  );

  const { columnFilterValues, relationFilterValues } = useFilterValues(filters, columnById);
  const { handleUpdateFilterValue, handleUpdateRelationFilterValue } = useFilterUpdates({
    filters,
    filterableColumnIdsSet,
    filterableRelationKeysSet,
    handleChangeFilters,
  });

  return (
    <div className={cn("flex flex-col items-stretch gap-y-4 overflow-auto p-4 text-base", className)}>
      {filterColumns.map((column) => (
        <div key={column.id} className="flex flex-row items-center space-x-2">
          <div className="shrink grow basis-0 truncate" title={column.displayMetadata.name}>
            {column.displayMetadata.name}
          </div>
          <div className="shrink grow basis-0">
            <ColumnFilterInput
              columnId={column.id}
              columnType={column.columnType}
              entityType={entityType}
              value={columnFilterValues[column.id]}
              versionType={versionType}
              onChange={handleUpdateFilterValue(column.id)}
            />
          </div>
        </div>
      ))}
      {filterableRelations.map(({ relation, direction }) => {
        const otherEntityType = allEntityTypes[direction === "aToB" ? relation.entityTypeIdB : relation.entityTypeIdA];

        if (otherEntityType == null) {
          return null;
        }

        return (
          <div key={`${relation.id}-${direction}`} className="flex flex-row items-center space-x-2">
            <div
              className="shrink grow basis-0 truncate"
              title={
                direction === "aToB" ? relation.displayMetadataFromAToB.name : relation.displayMetadataFromBToA.name
              }
            >
              {direction === "aToB" ? relation.displayMetadataFromAToB.name : relation.displayMetadataFromBToA.name}
            </div>
            <div className="shrink grow basis-0">
              <RelationFilterInput
                key={`${relation.id}-${direction}`}
                direction={direction}
                entityType={entityType}
                otherEntityType={otherEntityType}
                relation={relation}
                value={relationFilterValues[computeRelationViewFieldId(relation.id, direction)]}
                versionType={versionType}
                onChange={handleUpdateRelationFilterValue(relation.id, direction)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
