import type { IInputSemanticId, IOutputSemanticId, ISlotId } from "@archetype/ids";
import {
  LayoutConfigurationId,
  LayoutId,
  LayoutVariantId,
  SemanticInputsRecord,
  SemanticOutputsRecord,
  SlotId,
} from "@archetype/ids";
import { z } from "zod";

import { UncheckedDisplayMetadata } from "./common/DisplayMetadata";
import type { ILayoutFunctionalConfig, ILayoutFunctionalConfigType } from "./config/ConfigTypes";
import { LayoutFunctionalConfig } from "./config/ConfigTypes";
import {
  ActionSelectedModuleParameterType,
  MultiEntityListModuleParameterType,
  MultiEntityQueryModuleParameterType,
  SingleEntityModuleParameterType,
} from "./config/RuntimeSemanticParameters";
import type { ISlot } from "./Slot";
import { Slot } from "./Slot";

export const LayoutParameterDisplayMetadata = z.object({
  displayMetadata: UncheckedDisplayMetadata,
});

/**
 * This is an abstract module, not an instanciated module (plugged to data and specific components, renderable, usable)
 */
export const LayoutDefinition = z.object({
  id: LayoutId,
  displayMetadata: UncheckedDisplayMetadata,

  // TBC: probably useful for matching
  tags: z.array(z.string()),

  /**
   * For example the data model entity to use for the entire layout, or a join to use.
   * Some data configuration should definitely live here, but TBD if some also lives lower on component level config,
   * ideally component level config should be optional though, so that can be the cutoff criteria
   */
  layoutConfiguration: z.record(LayoutConfigurationId, LayoutFunctionalConfig),

  /**
   * Defines what can be provided by higher level app layout or navigation between modules
   */
  moduleParameterInputs: SemanticInputsRecord(
    SingleEntityModuleParameterType.merge(LayoutParameterDisplayMetadata),
    MultiEntityListModuleParameterType.merge(LayoutParameterDisplayMetadata),
    MultiEntityQueryModuleParameterType.merge(LayoutParameterDisplayMetadata),
  ).partial(),

  // TODO: need to think more about this, it feels closer to a selction of something in a UI generally
  // Still need to define the type checking here, it should be able to reference a type from the inputs without requiring a config
  /**
   * Referenceable outputs of the model that can be provided to higher level app layout or navigation between modules.
   * They may be a selection in a table, or only triggered on a click even.
   *
   * Note this means there is strict opinion at the module level on what is outputtable, as opposed to being flexible on the component config
   * e.g. "on the table I want to be able to select the order and open the view"
   */
  moduleParameterOutputs: SemanticOutputsRecord(
    SingleEntityModuleParameterType.merge(LayoutParameterDisplayMetadata),
    MultiEntityListModuleParameterType.merge(LayoutParameterDisplayMetadata),
    MultiEntityQueryModuleParameterType.merge(LayoutParameterDisplayMetadata),
    ActionSelectedModuleParameterType.merge(LayoutParameterDisplayMetadata),
  ).partial(),

  slots: z.record(SlotId, Slot),

  /**
   * [TBC - could also be encoded with metadata on different]
   * Examples could be treating an inbox with filters on the top or on the left as 2 different variants
   */
  variants: z.array(
    z.object({
      id: LayoutVariantId,
      description: z.optional(z.string()),
    }),
  ),
});

export type ILayoutDefinition = z.infer<typeof LayoutDefinition>;

export type ILayoutIdDeclaration = {
  id: string;
  moduleParameters: {
    inputs: ReadonlyArray<IInputSemanticId>;
    outputs: ReadonlyArray<IOutputSemanticId>;
  };
  layoutConfigurations: {
    [layoutConfigurationId: string]: ILayoutFunctionalConfigType;
  };
  slots: {
    [slotId: string]: {
      optional: boolean;
      inputs: ReadonlyArray<IInputSemanticId>;
      outputs: ReadonlyArray<IOutputSemanticId>;
    };
  };
};

export interface ILayoutDefinitionDeclaration extends Omit<ILayoutDefinition, "slots" | "id"> {
  constIds: ILayoutIdDeclaration;
  slots: Record<ISlotId, Omit<ISlot, "id" | "optional">>;
}

export type ITypedLayoutDefinitionDeclaration<IDS extends ILayoutIdDeclaration> = Omit<
  ILayoutDefinition,
  "id" | "layoutConfiguration" | "slots" | "moduleParameterInputs"
> & {
  constIds: IDS;
  moduleParameterInputs: Pick<
    Required<ILayoutDefinition["moduleParameterInputs"]>,
    IDS["moduleParameters"]["inputs"][number]
  >;
  moduleParameterOutputs: Pick<
    Required<ILayoutDefinition["moduleParameterOutputs"]>,
    IDS["moduleParameters"]["outputs"][number]
  >;
  layoutConfiguration: {
    [CONFIG_ID in keyof IDS["layoutConfigurations"]]: ILayoutFunctionalConfig & {
      type: IDS["layoutConfigurations"][CONFIG_ID];
    };
  };
  slots: {
    [SLOT_ID in keyof IDS["slots"]]: Omit<ISlot, "id" | "constraints" | "optional"> & {
      constraints: Omit<ISlot["constraints"], "inputs" | "outputs"> & {
        inputs: Pick<Required<ISlot["constraints"]["inputs"]>, IDS["slots"][SLOT_ID]["inputs"][number]>;
        outputs: Pick<Required<ISlot["constraints"]["outputs"]>, IDS["slots"][SLOT_ID]["outputs"][number]>;
      };
    };
  };
};
