import {
  type IExecuteActionResponse,
  type ILoadedAction,
  type ILoadedCreateNestedAction,
  type ILoadedEntity,
  type ILoadedNestedCreateFormValues,
  type ILoadedNestedCreateFormValuesForEntity,
  mergeFormValidationErrors,
} from "@archetype/core";
import type {
  IActionCurrentUserInfo,
  IDraftEmailExtractedActionInput,
  IEntityTypeCore,
  IRelationCore,
  IValidationErrors,
  IVersionType,
  IViewFieldValue,
} from "@archetype/dsl";
import { computeColumnViewFieldId, FieldValueParser } from "@archetype/dsl";
import {
  type IEntityId,
  type IEntityTypeId,
  type IOrganizationId,
  type IRelationId,
  type IViewFieldId,
  ViewFieldId,
} from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  useMemoDeepCompare,
} from "@archetype/ui";
import { forEach, groupByNoUndefined, keyByAndMapValues, keyByNoUndefined, mapValues } from "@archetype/utils";
import { isEqual, merge, noop, omit, uniq, uniqBy } from "lodash";
import { useRouter } from "next/router";
import React, { createContext, useCallback, useRef, useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useDeepCompareEffect, usePrevious } from "react-use";

import type {
  IGetActionRoute,
  IGetEntityRoute,
  IGetEntityTypeRoute,
  IGetHighlightedViewFieldRoute,
  IGetLinkedEntityRoute,
} from "../api";
import { createFileLogger } from "../logger";
import { ActionDraftServiceProvider } from "./ActionDraftServiceContext";
import type { IActionEntityReference } from "./actionExecution/actionEntityReference";
import { getActionReferenceEntity, getActionReferenceEntityId } from "./actionExecution/actionEntityReference";
import { useInvalidatingActionExecution } from "./actionExecution/useInvalidatingActionExecution";
import type { IActionFormExternalUserCreateProps, IValidationErrorsDispatch } from "./ActionForm";
import { ActionForm } from "./ActionForm";
import type { INestedDraftEntities } from "./actionInputs/types";
import { useActionFormDataToSubmit } from "./useActionFormDataToSubmit";

const logger = createFileLogger("ActionContext");

export const PrivateActiveActionContext = createContext<{
  isUploading: boolean;
  setIsUploadingOrDeleting: (isUploadingOrDeleting: boolean) => void;
  uploadPromise: React.MutableRefObject<Promise<void> | null>;
  deletePromise: React.MutableRefObject<Promise<void> | null>;
}>({
  isUploading: false,
  setIsUploadingOrDeleting: noop,
  uploadPromise: { current: null },
  deletePromise: { current: null },
});

export const TOUR_SUBMIT_BUTTON_ID = "tour-submit-button";

export const ActionFormWrapper: React.FC<{
  versionType: IVersionType;
  organizationId: IOrganizationId;
  /**
   * For a create action, there will be an entityId for the draft, but it will not be loaded as an actual entity.
   */
  entityReference: IActionEntityReference | undefined;
  action: ILoadedAction;
  currentUserInfo: IActionCurrentUserInfo;
  emailDraftValues: Partial<Record<IViewFieldId, IDraftEmailExtractedActionInput>> | undefined;
  externalUserUpsertProps: IActionFormExternalUserCreateProps | undefined;
  defaultValues?: Partial<Record<IViewFieldId, IViewFieldValue>>;
  initialDraftValues: Partial<Record<string, IViewFieldValue>> | undefined;
  isUserEditedInDraftValues: Partial<Record<IViewFieldId, boolean>> | undefined;
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>> | undefined;
  allRelations: Partial<Record<IRelationId, IRelationCore>> | undefined;
  className?: string;
  highlightedViewFieldId?: IViewFieldId;
  isNestedAction?: boolean;
  nestedActionByViewFieldId: Partial<Record<IViewFieldId, ILoadedCreateNestedAction>> | undefined;
  getActionRoute: IGetActionRoute;
  getEntityRoute: IGetEntityRoute;
  getEntityTypeRoute: IGetEntityTypeRoute;
  getFullPageActionRoute: IGetActionRoute;
  getLinkedEntityRoute: IGetLinkedEntityRoute;
  getHighlightedViewFieldRoute: IGetHighlightedViewFieldRoute | undefined;
  onActionExecuted: (entity: ILoadedEntity | undefined) => Promise<void>;
}> = ({
  versionType,
  organizationId,
  action,
  currentUserInfo,
  emailDraftValues,
  externalUserUpsertProps,
  entityReference,
  defaultValues,
  initialDraftValues,
  isUserEditedInDraftValues,
  allEntityTypes,
  allRelations,
  className,
  highlightedViewFieldId,
  nestedActionByViewFieldId,
  getActionRoute,
  getEntityRoute,
  getEntityTypeRoute,
  getFullPageActionRoute,
  getLinkedEntityRoute,
  getHighlightedViewFieldRoute,
  onActionExecuted: handleActionExecuted,
}) => {
  const router = useRouter();
  const actionId = action.id;

  const draftEntityId = getActionReferenceEntityId(entityReference);

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const [frontendValidationErrors, setFrontendValidationErrors] = useState<IValidationErrors>({
    generalErrors: [],
    fieldErrors: {},
    nestedActionErrors: {},
  });

  const [backendValidationErrors, setBackendValidationErrors] = useState<IValidationErrors>({
    generalErrors: [],
    fieldErrors: {},
    nestedActionErrors: {},
  });

  const validationErrors: IValidationErrors = useMemoDeepCompare(
    () => mergeFormValidationErrors(frontendValidationErrors, backendValidationErrors),
    [frontendValidationErrors, backendValidationErrors],
  );

  const [isUploading, setIsUploadingOrDeleting] = useState(false);
  const uploadPromise = useRef<Promise<void> | null>(null);
  const deletePromise = useRef<Promise<void> | null>(null);

  // Keeping track of which fieldId is the nested form created for, but it could be used in a further nested form (of the same entity type)
  const [nestedCreateForms, handleSetNestedCreateForms] = useState<Record<IViewFieldId, IEntityId[]>>({});
  const [loadedNestedCreateFormValues, setLoadedNestedCreateFormValues] = useState<ILoadedNestedCreateFormValues>({});

  const form = useForm<Partial<Record<string, IViewFieldValue>>>({
    // Important to have both in default values and not in values
    // 1. Because if they are not in default values then the markdown component is first rendered empty before the useEffect runs
    // and because it is uncontrolled then it keeps showing nothing
    // 2. Because if they are in values then the draft will overwrite the selection when reloaded and have a temporary old value as typing
    defaultValues: merge(defaultValues, initialDraftValues),
  });

  const previousDefaultValues = usePrevious(defaultValues);
  const previousEntityId = usePrevious(draftEntityId);
  const previousActionId = usePrevious(actionId);

  useDeepCompareEffect(() => {
    if (initialDraftValues == null) {
      return;
    }

    if (
      previousEntityId == null ||
      previousEntityId !== draftEntityId ||
      previousActionId == null ||
      previousActionId !== actionId
    ) {
      const actionInputsById = keyByNoUndefined(action.actionDefinition.inputs, (i) => i.viewField.id);

      forEach(initialDraftValues, (value, fieldId) => {
        // shouldTouch marks the field as touched which is important to make sure we dont overwrite the default value
        form.setValue(fieldId, value, {
          shouldTouch:
            value != null &&
            isUserEditedInDraftValues?.[ViewFieldId.parse(fieldId satisfies string)] === true &&
            // however it can be touched if the field is not allowed to be changed by the user
            actionInputsById[ViewFieldId.parse(fieldId satisfies string)]?.allowChangingDefault === true,
        });
      });
    }
  }, [
    draftEntityId,
    previousEntityId,
    form,
    action.actionDefinition.inputs,
    initialDraftValues,
    actionId,
    previousActionId,
    isUserEditedInDraftValues,
  ]);

  // Set the form defaults as values for now
  useDeepCompareEffect(() => {
    const actionInputsById = keyByNoUndefined(action.actionDefinition.inputs, (i) => i.viewField.id);

    forEach(defaultValues, (value, fieldId) => {
      if (value != null && (previousDefaultValues == null || !isEqual(previousDefaultValues[fieldId], value))) {
        // shouldTouch marks the field as touched which is important to make sure we dont overwrite the default value
        form.setValue(fieldId, value, {
          shouldTouch: actionInputsById[fieldId]?.allowChangingDefault === true,
        });
      }
    });
  }, [form, defaultValues, previousDefaultValues, action.actionDefinition.inputs]);

  const nestedDraftEntities = useMemoDeepCompare(
    (): INestedDraftEntities =>
      mapValues(loadedNestedCreateFormValues, (nestedForEntityType) =>
        mapValues(nestedForEntityType, (nestedEntityCreation, nestedDraftEntityId) => {
          if (nestedEntityCreation == null) {
            return undefined;
          }

          const { fieldValues, entityType: nestedEntityType } = nestedEntityCreation;

          const nestedDisplayNameColumnId = nestedEntityType.displayNameColumn;
          const entityTitle = FieldValueParser.toString(
            fieldValues[computeColumnViewFieldId(nestedDisplayNameColumnId)],
          );

          return {
            nestedDraftEntityId,
            entityTitle: entityTitle != null && entityTitle !== "" ? entityTitle : "New item",
          };
        }),
      ),
    [loadedNestedCreateFormValues],
  );

  const discardDraftDisabled = entityReference == null || draftEntityId == null;

  const { mutateAsync: discardDraftForAction, isPending: isDiscardingDraft } =
    trpc.action.discardDraftForAction.useMutation();

  const getDraftForActionQuery = trpc.useUtils().action.getDraftForAction;
  const getCreateDraftIdsForEntityTypeQuery = trpc.useUtils().action.getCreateDraftIdsForEntityType;
  const getHasDraftForActionQuery = trpc.useUtils().action.getHasDraftForAction;

  const handleDiscardDraft = useCallback(() => {
    if (discardDraftDisabled) {
      return;
    }

    const innerDiscardDraft = async (): Promise<void> => {
      await discardDraftForAction({
        organizationId,
        versionType,
        actionId: action.id,
        entityTypeId: action.entityTypeId,
        entityId: draftEntityId,
      });

      getDraftForActionQuery.setData(
        {
          versionType,
          actionId: action.id,
          entityId: draftEntityId,
          entityTypeId: action.entityTypeId,
          organizationId,
        },
        { draft: null },
      );

      if (entityReference.type === "modifyingEntity") {
        getHasDraftForActionQuery.setData(
          {
            organizationId,
            versionType,
            actionId: action.id,
            entityId: draftEntityId,
          },
          { hasDraft: false },
        );

        // TODO drafts - Ideally we should stay on the form, but it cant be cleared from here, so we need to move the components outside of this context before
        await router.push(
          getEntityRoute({ entityTypeId: action.entityTypeId, entityId: entityReference.entity.entityId }),
        );
      } else {
        const currentDraftIds = getCreateDraftIdsForEntityTypeQuery.getData({
          versionType,
          entityTypeId: action.entityTypeId,
          organizationId,
        });
        const newDraftIds = (currentDraftIds?.draftIds ?? []).filter((d) => d.entityId !== draftEntityId);

        getCreateDraftIdsForEntityTypeQuery.setData(
          {
            versionType,
            entityTypeId: action.entityTypeId,
            organizationId,
          },
          { draftIds: newDraftIds },
        );
        await router.push(getEntityTypeRoute({ entityTypeId: action.entityTypeId }));
      }
    };

    void innerDiscardDraft();
  }, [
    discardDraftForAction,
    router,
    getEntityRoute,
    getEntityTypeRoute,
    action,
    draftEntityId,
    discardDraftDisabled,
    entityReference,
    organizationId,
    versionType,
    getDraftForActionQuery,
    getCreateDraftIdsForEntityTypeQuery,
    getHasDraftForActionQuery,
  ]);

  const handleOpenChange = useCallback(
    (isOpen: boolean): void => {
      setIsErrorDialogOpen(isOpen);
    },
    [setIsErrorDialogOpen],
  );

  const otherEntityTypeIdByViewFieldId = useMemoDeepCompare(
    () =>
      keyByAndMapValues(action.actionDefinition.inputs, (input) => {
        if (input.viewField.type !== "directionalRelation") {
          return undefined;
        }

        const { direction, relation } = input.viewField;

        if (direction === "aToB") {
          return {
            key: input.viewField.id,
            value: relation.entityTypeIdB,
          };
        }

        return {
          key: input.viewField.id,
          value: relation.entityTypeIdA,
        };
      }),
    [action.actionDefinition.inputs],
  );

  const handleSyncNestedCreateFormValues = useCallback(
    (args: {
      viewFieldId: IViewFieldId;
      nestedCreateEntityId: IEntityId;
      values: Partial<Record<IViewFieldId, IViewFieldValue>>;
    }) => {
      const nestedActionInfo = nestedActionByViewFieldId?.[args.viewFieldId];

      if (nestedActionInfo == null) {
        return;
      }

      const newValues: ILoadedNestedCreateFormValuesForEntity = {
        createAction: nestedActionInfo.createAction,
        entityType: nestedActionInfo.entityType,
        validationGroups: nestedActionInfo.validationGroups,

        fieldValues: args.values,
      };

      setLoadedNestedCreateFormValues((prev) => ({
        ...prev,
        [nestedActionInfo.entityType.id]: {
          ...prev[nestedActionInfo.entityType.id],
          [args.nestedCreateEntityId]: newValues,
        },
      }));
    },
    [setLoadedNestedCreateFormValues, nestedActionByViewFieldId],
  );

  const handleDeleteNestedCreateForm = useCallback(
    (viewFieldId: IViewFieldId, nestedDraftEntityId: IEntityId) => {
      const otherEntityTypeId = otherEntityTypeIdByViewFieldId[viewFieldId];

      if (otherEntityTypeId == null) {
        return;
      }

      setLoadedNestedCreateFormValues((prev) => ({
        ...prev,
        [otherEntityTypeId]: omit(prev[otherEntityTypeId], nestedDraftEntityId),
      }));
    },
    [setLoadedNestedCreateFormValues, otherEntityTypeIdByViewFieldId],
  );

  if (isErrorDialogOpen) {
    return (
      <AlertDialog open={isErrorDialogOpen} onOpenChange={handleOpenChange}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>We&apos;ve encountered an error</AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>
              <Button>Cancel</Button>
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  return (
    <PrivateActiveActionContext.Provider
      value={{
        isUploading,
        setIsUploadingOrDeleting,
        uploadPromise,
        deletePromise,
      }}
    >
      <ActionDraftServiceProvider
        action={action}
        defaultFieldValuesForCreate={defaultValues}
        entityId={getActionReferenceEntityId(entityReference)}
        form={form}
        versionType={versionType}
      >
        <ActionForm
          action={action}
          allEntityTypes={allEntityTypes}
          allRelations={allRelations}
          className={className}
          currentUserInfo={currentUserInfo}
          emailDraftValues={emailDraftValues}
          externalUserUpsertProps={externalUserUpsertProps}
          form={form}
          getActionRoute={getActionRoute}
          getFullPageActionRoute={getFullPageActionRoute}
          getHighlightedViewFieldRoute={getHighlightedViewFieldRoute}
          getLinkedEntityRoute={getLinkedEntityRoute}
          highlightedViewFieldId={highlightedViewFieldId}
          loadedNestedCreateFormValues={loadedNestedCreateFormValues}
          modifyingEntity={getActionReferenceEntity(entityReference)}
          nestedActionByViewFieldId={nestedActionByViewFieldId}
          nestedCreateForms={nestedCreateForms}
          nestedDraftEntities={nestedDraftEntities}
          organizationId={organizationId}
          setBackendValidationErrors={setBackendValidationErrors}
          setFrontendValidationErrors={setFrontendValidationErrors}
          validationErrors={validationErrors}
          versionType={versionType}
          onDeleteNestedCreateForm={handleDeleteNestedCreateForm}
          onSetNestedCreateForms={handleSetNestedCreateForms}
          onSyncNestedCreateFormValues={handleSyncNestedCreateFormValues}
        />
        <div className="space-x-2">
          <ActionSubmitButton
            action={action}
            currentUserInfo={currentUserInfo}
            deletePromise={deletePromise}
            entityReference={entityReference}
            externalUserUpsertProps={externalUserUpsertProps}
            form={form}
            getActionRoute={getActionRoute}
            getFullPageActionRoute={getFullPageActionRoute}
            loadedNestedCreateFormValues={loadedNestedCreateFormValues}
            setBackendValidationErrors={setBackendValidationErrors}
            setFrontendValidationErrors={setFrontendValidationErrors}
            setIsErrorDialogOpen={setIsErrorDialogOpen}
            uploadPromise={uploadPromise}
            versionType={versionType}
            onActionExecuted={handleActionExecuted}
          />
          {!discardDraftDisabled && (
            <Button isLoading={isDiscardingDraft} size="lg" variant="ghost" onClick={handleDiscardDraft}>
              Discard draft
            </Button>
          )}
        </div>
      </ActionDraftServiceProvider>
    </PrivateActiveActionContext.Provider>
  );
};

export const ActionSubmitButton: React.FC<{
  versionType: IVersionType;
  action: ILoadedAction;
  currentUserInfo: IActionCurrentUserInfo;
  externalUserUpsertProps: IActionFormExternalUserCreateProps | undefined;
  /**
   * Can be undefined if there is no draft entity for a create action
   */
  entityReference: IActionEntityReference | undefined;
  /**
   * @param entity should be undefined for a deletion action only
   */
  onActionExecuted?: (entity: ILoadedEntity | undefined) => Promise<void>;
  isNestedAction?: boolean;
  title?: string;
  getActionRoute: IGetActionRoute;
  getFullPageActionRoute: IGetActionRoute;
  form: UseFormReturn<Partial<Record<string, IViewFieldValue>>>;
  loadedNestedCreateFormValues: ILoadedNestedCreateFormValues;
  setIsErrorDialogOpen: (newIsOpen: boolean) => void;
  setBackendValidationErrors: (setter: IValidationErrorsDispatch) => void;
  setFrontendValidationErrors: (setter: IValidationErrorsDispatch) => void;
  uploadPromise: React.MutableRefObject<Promise<void> | null>;
  deletePromise: React.MutableRefObject<Promise<void> | null>;
}> = ({
  versionType,
  action,
  currentUserInfo,
  externalUserUpsertProps,
  entityReference,
  form,
  isNestedAction,
  loadedNestedCreateFormValues,
  onActionExecuted,
  title,
  getActionRoute,
  setIsErrorDialogOpen,
  setBackendValidationErrors,
  setFrontendValidationErrors,
  uploadPromise,
  deletePromise,
}) => {
  const [isExecutingAction, setIsExecutingAction] = useState(false);

  const entityId = getActionReferenceEntityId(entityReference);

  const { executeAction } = useInvalidatingActionExecution();
  const { data: validationGroupsQuery } = trpc.dataModel.getValidationGroupsByEntityType.useQuery({
    versionType,
    entityTypeId: action.entityTypeId,
  });
  const validationGroups = useMemoDeepCompare(
    () => validationGroupsQuery?.validationGroups ?? [],
    [validationGroupsQuery?.validationGroups],
  );

  const { getDataToSubmit } = useActionFormDataToSubmit({
    form,
    action,
    externalUserUpsertAction: externalUserUpsertProps?.externalUserUpsertAction,
    loadedNestedCreateFormValues,
  });

  const submitAction = useCallback(async () => {
    setIsExecutingAction(true);

    setBackendValidationErrors((prevValidationErrors) => ({
      generalErrors: [],
      fieldErrors: prevValidationErrors.fieldErrors,
      nestedActionErrors: prevValidationErrors.nestedActionErrors,
    }));

    // Wait for file uploads and deletions to complete if there are any
    if (uploadPromise.current) {
      try {
        await uploadPromise.current;
      } catch (error) {
        logger.error({ error }, "File upload failed");
        setIsErrorDialogOpen(true);
        setIsExecutingAction(false);

        return;
      }
    }

    if (deletePromise.current) {
      try {
        await deletePromise.current;
      } catch (error) {
        logger.error({ error }, "File deletion failed");
        setIsErrorDialogOpen(true);
        setIsExecutingAction(false);

        return;
      }
    }

    const {
      entityFieldValues,
      emailEffectOverride,
      externalUserFieldValues,
      loadedNestedCreateFormValues: loadedNestedCreateFormValuesToSubmit,
    } = getDataToSubmit();

    let res: IExecuteActionResponse;

    try {
      res = await executeAction({
        executeActionQuery: {
          versionType,
          actionId: action.id,
          entityId: entityId ?? null,
          fieldValues: entityFieldValues,
          emailEffectOverride,
          externalUserFieldValues,
          externalUserSyntheticEntityId: currentUserInfo.userEntityId,
          loadedNestedCreateFormValues: loadedNestedCreateFormValuesToSubmit,
        },
        invalidationParams: {
          organizationId: action.organizationId,
          actionToState: action.actionDefinition.toState,
          shouldWaitForAsyncExecution: false,
          actionInfo: action,
          validationGroups,
          isCreateActionDraft: entityReference?.type === "createDraftEntity",
          maybeExternalUserCreateProps:
            externalUserUpsertProps == null
              ? undefined
              : {
                  externalUserUpsertLoadedAction: externalUserUpsertProps.externalUserUpsertAction,
                  existingUserEntity: undefined,
                  userEntityTypeValidationGroups: externalUserUpsertProps.userEntityTypeValidationGroups,
                  emailAddress: undefined,
                },
        },
        getActionRouteRedirectOnError: getActionRoute,
      });
    } catch (error) {
      logger.error({ error }, "Action execution failed");
      setIsErrorDialogOpen(true);
      setIsExecutingAction(false);

      return;
    }

    if (!res.success) {
      logger.info({ errors: res.errors }, "Action received validation errors");
      const newValidationErrors: IValidationErrors = {
        generalErrors: res.errors.filter((error) => error.viewFieldId == null).map((error) => error.error),
        fieldErrors: mapValues(
          groupByNoUndefined(res.errors, (error) => error.viewFieldId),
          //temp solution for duplicated errors caused by duplicated validation groups (ENG-1024)
          (errorsForField) => uniqBy(errorsForField, (error) => error.error),
        ),
        nestedActionErrors: mapValues(res.nestedActionErrors, (errors) =>
          mapValues(errors, (errorsForNestedEntity) => ({
            generalErrors: uniq(
              (errorsForNestedEntity ?? []).filter((error) => error.viewFieldId == null).map((error) => error.error),
            ),
            fieldErrors: mapValues(
              groupByNoUndefined(errorsForNestedEntity ?? [], (error) => error.viewFieldId),
              (errorsForField) => errorsForField,
            ),
          })),
        ),
      };

      // Clear all as we set all as backend errors
      setFrontendValidationErrors(() => ({
        generalErrors: [],
        fieldErrors: {},
        nestedActionErrors: {},
      }));

      setBackendValidationErrors(() => newValidationErrors);
      setIsExecutingAction(false);
    } else {
      await onActionExecuted?.(res.entity ?? undefined);
      setIsExecutingAction(false);
    }
  }, [
    currentUserInfo,
    action,
    validationGroups,
    entityReference?.type,
    versionType,
    executeAction,
    getDataToSubmit,
    onActionExecuted,
    externalUserUpsertProps,
    entityId,
    setIsErrorDialogOpen,
    setBackendValidationErrors,
    setFrontendValidationErrors,
    uploadPromise,
    deletePromise,
    getActionRoute,
  ]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();

      void submitAction();
    },
    [submitAction],
  );

  return (
    <Button
      data-tour-id={TOUR_SUBMIT_BUTTON_ID}
      isLoading={isExecutingAction}
      size="lg"
      variant={isNestedAction === true ? "secondary" : "primary"}
      onClick={handleClick}
    >
      {title ?? "Next"}
    </Button>
  );
};
