import { DateTimeInput } from "@archetype/ui";
import { DateTime } from "luxon";
import React, { useCallback } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableEditingContext } from "../../context/DataTableEditingContext";
import { useCellClickInteraction } from "../../hooks/useCellClickInteraction";
import type { IDataTableCommonCellProps, IDataTableDateTimeCell } from "./api";

export function DateTimeCell<TRowId extends string, TColId extends string>({
  cell,
  isCellEditing,
  isCellSelected,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTableDateTimeCell<TRowId, TColId>>): React.ReactNode {
  const { value, onChange, readOnly, rowId, colId } = cell;
  const setEditableCell = useContextSelector(DataTableEditingContext, (state) => state.setEditableCell);

  const { handleCellClick } = useCellClickInteraction({
    isCellEditing,
    isCellSelected,
    readOnly,
    rowId,
    colId,
  });

  const handleChange = useCallback(
    (dateTime: Date | undefined): void => {
      if (readOnly) {
        return;
      }

      setEditableCell(null);

      if (dateTime == null) {
        void onChange?.(null);

        return;
      }

      const luxonDate = DateTime.fromJSDate(dateTime);

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety @sspringer to check if necessary
      if (luxonDate != null && (value == null || luxonDate.toMillis() !== value.toMillis())) {
        void onChange?.(luxonDate);
      }
    },
    [onChange, readOnly, setEditableCell, value],
  );

  return (
    <div className="flex size-full items-center overflow-hidden px-2" onClick={handleCellClick}>
      <DateTimeInput
        disabled={readOnly}
        isOpen={!readOnly && isCellEditing}
        placeholder={isCellSelected ? "Select Date" : ""}
        value={value?.toJSDate() ?? undefined}
        variant="text"
        onChange={handleChange}
      />
    </div>
  );
}
