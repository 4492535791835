import type { ILoadedViewField } from "@archetype/core";
import type { IViewFieldValue } from "@archetype/dsl";
import { FieldValueParser, isRelationFieldValue } from "@archetype/dsl";
import { isRelationViewField } from "@archetype/dsl";
import { assertNever } from "assert-never";

import { getConditionalFormattingClasses } from "../entityType/ConditionalFormattingUtils";
import type { IFieldValueRendererFieldValue } from "./FieldValueRenderer";

export const fieldValueToFieldRendererValue = (
  value: IViewFieldValue | undefined,
  viewField: ILoadedViewField,
): IFieldValueRendererFieldValue => {
  if (value == null || value.type === "null") {
    return {
      type: "null",
    };
  }

  if (isRelationViewField(viewField)) {
    if (isRelationFieldValue(value)) {
      return {
        type: "relations",
        value: value.value,
      };
    }

    return {
      type: "null",
    };
  }
  const columnType = viewField.column.columnType;

  switch (columnType.type) {
    case "statusEnum": {
      const colorName = getConditionalFormattingClasses({
        cell: value,
        conditionalFormatting: viewField.column.conditionalFormatting,
      })?.colorName;

      const strValue = FieldValueParser.toString(value);
      const statusInfo =
        columnType.allowedValues.find((v) => v.id === strValue) ??
        columnType.archivedValues.find((v) => v.id === strValue);

      if (statusInfo == null) {
        return {
          type: "string",
          value: strValue ?? null,
        };
      }

      return {
        type: "status",
        value: statusInfo.readableValue,
        color: colorName ?? "neutral",
      };
    }
    case "shortText":
    case "longText":
    case "email":
    case "geolocation":
    case "boolean":
    case "date":
    case "enum":
    case "timestamp": {
      return {
        type: "string",
        value: FieldValueParser.toString(value) ?? null,
      };
    }

    case "phone": {
      return {
        type: "phone",
        value: FieldValueParser.toString(value) ?? null,
      };
    }

    case "url": {
      return {
        type: "url",
        value: FieldValueParser.toString(value) ?? null,
      };
    }

    case "file": {
      return {
        type: "files",
        value: value.type === "file" ? value.value : null,
      };
    }

    case "number": {
      return {
        type: "number",
        value: FieldValueParser.toNumber(value) ?? null,
      };
    }

    default: {
      assertNever(columnType);
    }
  }
};
