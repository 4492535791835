import { EntityTypeId, RelationId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { RelationDirection } from "../dataModel/RelationBase";

export const LLMRelationAutofill = z.union([
  z.object({
    dynamicValue: z.enum(["currentUser"]),
  }),
  z.object({
    logicDescription: z.string(),
    recomputationType: z.enum(["suggestion", "live"]),
  }),
  z.object({
    aiInstructions: z.string(),
    recomputationType: z.enum(["suggestion", "live"]),
  }),
]);
export type ILLMRelationAutofill = z.infer<typeof LLMRelationAutofill>;

export const LLMRelation = z.object({
  relationId: RelationId,
  titleForBEntitiesWhenOnA: ReadableString,
  titleForAEntitiesWhenOnB: ReadableString,
  entityTypeATitle: ReadableString,
  entityTypeBTitle: ReadableString,
  entityTypeAId: EntityTypeId,
  entityTypeBId: EntityTypeId,
  cardinalityOnSideA: z.enum(["one", "many"]),
  cardinalityOnSideB: z.enum(["one", "many"]),
  autofill: z
    .object({
      direction: RelationDirection,
      autofill: LLMRelationAutofill.nullable(),
    })
    .nullable(),
});
export type ILLMRelation = z.infer<typeof LLMRelation>;
