import { ColumnId, EntityTypeId, RelationId } from "@archetype/ids";
import { z } from "zod";

import { ColumnType } from "./Column";
import { Dependency } from "./Dependency";
import { DerivationStep } from "./Derivation";
import { RelationDirection } from "./RelationBase";

export const DerivedColumnMetadata = z.object({
  type: z.literal("derivedColumn"),
  columnId: ColumnId,
  entityTypeId: EntityTypeId,
  outputType: ColumnType,
  steps: z.array(DerivationStep),
  dependencies: z.array(Dependency),
  instructions: z.string(),
});
export type IDerivedColumnMetadata = z.infer<typeof DerivedColumnMetadata>;

export const AIColumnMetadata = z.object({
  type: z.literal("aiColumn"),
  columnId: ColumnId,
  entityTypeId: EntityTypeId,
  outputType: ColumnType,
  instructions: z.string(),
  dependencies: z.array(Dependency),
});
export type IAIColumnMetadata = z.infer<typeof AIColumnMetadata>;

export const DerivedRelationMetadata = z.object({
  type: z.literal("derivedRelation"),
  relationId: RelationId,
  direction: RelationDirection,
  instructions: z.string(),
  steps: z.array(DerivationStep),
  dependencies: z.array(Dependency),
});
export type IDerivedRelationMetadata = z.infer<typeof DerivedRelationMetadata>;

export const AIRelationMetadata = z.object({
  type: z.literal("aiRelation"),
  relationId: RelationId,
  direction: RelationDirection,
  instructions: z.string(),
  dependencies: z.array(Dependency),
});
export type IAIRelationMetadata = z.infer<typeof AIRelationMetadata>;

export const FieldMetadata = z.discriminatedUnion("type", [
  DerivedColumnMetadata,
  AIColumnMetadata,
  DerivedRelationMetadata,
  AIRelationMetadata,
]);
export type IFieldMetadata = z.infer<typeof FieldMetadata>;
