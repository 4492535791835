import type { IConditionalFormattingDefinition, IStateColor, IViewFieldValue } from "@archetype/dsl";
import { FieldValueParser } from "@archetype/dsl";

export const getConditionalFormattingClasses = (props: {
  cell: IViewFieldValue;
  conditionalFormatting: IConditionalFormattingDefinition | undefined;
}):
  | {
      colorName?: IStateColor;
    }
  | undefined => {
  if (props.conditionalFormatting == null) {
    return undefined;
  }

  const { valueToNamedColor } = props.conditionalFormatting;

  const valueAsString = FieldValueParser.toString(props.cell) ?? "-";

  const colorName = valueToNamedColor[valueAsString];

  return {
    colorName,
  };
};
