import { ColumnId, StateId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { DisplayMetadata } from "../common/DisplayMetadata";
import type { ILiveAutofill, ISnapshotAutofill } from "./Autofill";
import { Autofill, isLiveAutofill } from "./Autofill";
import type { IAIConfig, ILogicConfig, ILookupConfig } from "./AutofillConfig";
import { ColumnValidation } from "./ColumnValidation";
import { ConditionalFormattingDefinition } from "./ConditionalFormatting";

export const EnumColumnType = z.object({
  type: z.literal("enum"),
  enumAllowedValues: z.array(z.string()).optional().describe("The allowed values for the enum"),
  // backwards compatibility for old enum columns will fall back to null meaning they will be multi select
  enumInclusiveMaxValuesToSelect: z
    .number()
    .nullable()
    .default(null)
    .describe("The maximum number of values to select. Null means no maximum."),
});
export type IEnumColumnType = z.infer<typeof EnumColumnType>;

export const StableStatusEnumColumnType = z.object({
  type: z.literal("statusEnum"),
  allowedValues: z.array(
    z.object({
      id: StateId, // Maybe more generic? Probably not necessary
      readableValue: ReadableString,
    }),
  ),
  archivedValues: z.array(
    z.object({
      id: StateId,
      readableValue: ReadableString,
    }),
  ),
});
export type IStableStatusEnumColumnType = z.infer<typeof StableStatusEnumColumnType>;

export const SimpleColumnType = z.discriminatedUnion("type", [
  z.object({ type: z.literal("email") }),
  z.object({ type: z.literal("shortText") }),
  z.object({ type: z.literal("longText") }),
  z.object({ type: z.literal("number") }),
  z.object({ type: z.literal("boolean") }),
  z.object({ type: z.literal("date") }),
  z.object({ type: z.literal("timestamp") }),
  z.object({ type: z.literal("geolocation") }),
  z.object({ type: z.literal("phone") }),
  z.object({ type: z.literal("file") }),
  z.object({ type: z.literal("url") }),
]);
export type ISimpleColumnType = z.infer<typeof SimpleColumnType>;

export const ColumnType = z.discriminatedUnion("type", [
  ...SimpleColumnType.options,
  EnumColumnType,
  StableStatusEnumColumnType,
]);
export type IColumnType = z.infer<typeof ColumnType>;

export const PrimitiveColumnType = z.enum(["string", "number", "boolean", "file"]);
export type IPrimitiveColumnType = z.infer<typeof PrimitiveColumnType>;

export const Column = z.object({
  id: ColumnId,
  displayMetadata: DisplayMetadata,
  columnType: ColumnType,

  nonNullable: z.boolean(),
  unique: z.boolean().default(false),

  autofill: Autofill.optional(),
  validations: z.array(ColumnValidation).optional(),
  conditionalFormatting: ConditionalFormattingDefinition.optional(), // TBD if here or in the column type
});
export type IColumn = z.infer<typeof Column>;

type ILiveAIColumn = Omit<IColumn, "autofill"> & { autofill: Omit<ILiveAutofill, "config"> & { config: IAIConfig } };
type ISnapshotAIColumn = Omit<IColumn, "autofill"> & {
  autofill: Omit<ISnapshotAutofill, "config"> & { config: IAIConfig };
};

export type IAIColumn = ILiveAIColumn | ISnapshotAIColumn;
export const isAIColumn = (column: IColumn): column is IAIColumn =>
  column.autofill != null && column.autofill.config.type === "ai";

type ILiveLogicColumn = Omit<IColumn, "autofill"> & {
  autofill: Omit<ILiveAutofill, "config"> & { config: ILogicConfig };
};
type ISnapshotLogicColumn = Omit<IColumn, "autofill"> & {
  autofill: Omit<ISnapshotAutofill, "config"> & { config: ILogicConfig };
};

export type ILogicColumn = ILiveLogicColumn | ISnapshotLogicColumn;
export const isLogicColumn = (column: IColumn): column is ILogicColumn =>
  column.autofill != null && column.autofill.config.type === "logic";

type ILiveLookupColumn = Omit<IColumn, "autofill"> & {
  autofill: Omit<ILiveAutofill, "config"> & { config: ILookupConfig };
};
type ISnapshotLookupColumn = Omit<IColumn, "autofill"> & {
  autofill: Omit<ISnapshotAutofill, "config"> & { config: ILookupConfig };
};

export type ILookupColumn = ILiveLookupColumn | ISnapshotLookupColumn;
export const isLookupColumn = (column: IColumn): column is ILookupColumn =>
  column.autofill != null && column.autofill.config.type === "lookup";

export type IDerivedColumn = ILogicColumn | ILookupColumn;
export const isDerivedColumn = (column: IColumn): column is IDerivedColumn =>
  isLogicColumn(column) || isLookupColumn(column);

export type ILiveColumn = Omit<IColumn, "autofill"> & {
  autofill: ILiveAutofill;
};
export const isLiveColumn = (column: IColumn): column is ILiveColumn =>
  column.autofill != null && isLiveAutofill(column.autofill);

export type IComputedColumn = ILogicColumn | ILookupColumn | IAIColumn;
export const isComputedColumn = (column: IColumn): column is IComputedColumn =>
  isLogicColumn(column) || isLookupColumn(column) || isAIColumn(column);
