import type {
  IStateMachineMetadataVersionId,
  IVersionIdentifiedConceptsIdBrands,
  IVersionIdentifiedConceptsIds,
  IVersionIdentifiedConceptsVersionIdBrands,
  IVersionIdentifiedConceptsVersionIds,
  IVersionIdentifiedGroupedIdsAndTableName,
  VersionIdentifiedConceptsIds,
  VersionIdentifiedConceptsVersionIds,
} from "@archetype/ids";
import { StateMachineMetadataVersionId } from "@archetype/ids";
import type { BRAND } from "zod";
import { z } from "zod";

import type { IVersion } from "./Version";
import { Version } from "./Version";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, max-params -- zod util annoying to type explicitly, and generic zod util to morre params
export function VersionIdentifiable<
  Id extends (typeof VersionIdentifiedConceptsIds.options)[number],
  VersionId extends (typeof VersionIdentifiedConceptsVersionIds.options)[number],
  ComputedFromId extends
    | (typeof VersionIdentifiedConceptsIds.options)[number]
    | z.ZodNull
    | z.ZodNullable<(typeof VersionIdentifiedConceptsIds.options)[number]>,
  ComputedFromVersionId extends
    | (typeof VersionIdentifiedConceptsVersionIds.options)[number]
    | z.ZodNull
    | z.ZodNullable<(typeof VersionIdentifiedConceptsVersionIds.options)[number]>,
>(id: Id, versionId: VersionId, computedFromId: ComputedFromId, computedFromVersionId: ComputedFromVersionId) {
  return z.object({
    /**
     * Stable across versions
     */
    id: id,
    /**
     * Unique identifier
     */
    versionId: versionId,
    /**
     * Readable version
     */
    version: Version,

    updatedAt: z.date(),

    /** absent for the first creation of an id */
    basedOn: z.union([versionId, z.null()]), // can't use `.nullable()` because of a zod issue with generics

    computedFrom: z.object({
      id: computedFromId,
      versionId: computedFromVersionId,
    }),

    originVersionId: StateMachineMetadataVersionId.nullable(),

    isDeleted: z.boolean().optional(),
  });
}

export type IVersionIdentifiableIds = Omit<IVersionIdentifiedGroupedIdsAndTableName, "tableName"> & {
  computedFromId: IVersionIdentifiedConceptsIds | null;
  computedFromVersionId: IVersionIdentifiedConceptsVersionIds | null;
};

export type IVersionIdentifiableIdsWithBrands = IVersionIdentifiableIds & {
  idBrand: IVersionIdentifiedConceptsIdBrands;
  versionIdBrand: IVersionIdentifiedConceptsVersionIdBrands;
};

export type ICompatibleBrandsOrNever<T extends IVersionIdentifiableIdsWithBrands> = T["id"] extends string &
  BRAND<T["idBrand"]>
  ? T["versionId"] extends string & BRAND<T["versionIdBrand"]>
    ? T
    : never
  : never;

export type IVersionIdentifiable<IDS extends IVersionIdentifiableIds> = {
  /**
   * Stable across versions
   */
  id: IDS["id"];
  /**
   * Unique identifier
   */
  versionId: IDS["versionId"];
  /**
   * Readable version
   */
  version: IVersion;

  updatedAt: Date;

  /** absent for the first creation of an id */
  basedOn: IDS["versionId"] | null;

  computedFrom: {
    id: IDS["computedFromId"];
    versionId: IDS["computedFromVersionId"];
  };

  originVersionId: IStateMachineMetadataVersionId | null;

  isDeleted?: boolean | undefined;
};

export type IVersionIdentifiableCreation<IDS extends IVersionIdentifiableIds> = Pick<
  IVersionIdentifiable<IDS>,
  "computedFrom" | "originVersionId"
>;

export type IVersionIdentifiableModification<IDS extends IVersionIdentifiableIds> = Pick<
  IVersionIdentifiable<IDS>,
  "computedFrom" | "originVersionId" // basedOn should be derived from existing
>;

export type INonIdVersionIdentifiableFields =
  | "versionId"
  | "version"
  | "computedFrom"
  | "basedOn"
  | "updatedAt"
  | "originVersionId";
