import { RelationId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { RelationDirection } from "./RelationBase";
import { StoredViewField } from "./ViewField";

export const FieldDependency = z.object({
  type: z.literal("field"),

  field: StoredViewField,
  displayName: ReadableString,
});
export type IFieldDependency = z.infer<typeof FieldDependency>;

export const RelatedFieldDependency = z.object({
  type: z.literal("relatedField"),
  /**
   * field on the current entity type that it depends on (and then more precisely the related field on the related entity)
   */
  relationId: RelationId,
  direction: RelationDirection,
  relationDisplayName: ReadableString,

  /**
   * Field on the related entity, not the current entity type
   */
  relatedField: StoredViewField,
  displayName: ReadableString,
});
export type IRelatedFieldDependency = z.infer<typeof RelatedFieldDependency>;

export const Dependency = z.discriminatedUnion("type", [FieldDependency, RelatedFieldDependency]);
export type IDependency = z.infer<typeof Dependency>;

export const isFieldDependency = (dependency: IDependency): dependency is IFieldDependency =>
  dependency.type === "field";

export const isRelatedFieldDependency = (dependency: IDependency): dependency is IRelatedFieldDependency =>
  dependency.type === "relatedField";
