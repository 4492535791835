import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { BoardComponentDefinitionId } from "@archetype/ids";

export const BoardComponentIdDeclarations = {
  id: BoardComponentDefinitionId,
  inputs: ["multiEntityQueryDataInput"],
  outputs: ["userSelectedSingleEntity", "userSelectedLinkedEntity", "actionSelected"],
  compatibleSemanticSizes: ["main", "medium", "split-horizontal", "split-vertical"],
  validCosmeticOptionalProps: ["label"],
} as const;

export const BoardComponentDefinition: ITypedComponentDefinitionDeclaration<typeof BoardComponentIdDeclarations> = {
  constIds: BoardComponentIdDeclarations,
  displayMetadata: {
    name: "Board",
    description: "A board component",
  },
  inputs: {
    multiEntityQueryDataInput: {
      displayMetadata: {
        name: "Board data",
        description: "Data to populate the board with",
      },
      optional: false,
    },
  },
  outputs: {
    userSelectedSingleEntity: {
      displayMetadata: {
        name: "Board selection",
        description: "The selected item in the Board",
      },
      optional: false,
      nullable: true,
    },
    userSelectedLinkedEntity: {
      displayMetadata: {
        name: "Side action in the Board to select a linked entity",
        description: "Will not show as the selected item inside the Board",
      },
      optional: false,
      nullable: true,
    },
    actionSelected: {
      displayMetadata: {
        name: "Action callback",
        description: "The callback to show the action",
      },
      optional: false,
    },
  },
};
