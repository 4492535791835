import { ColumnId, EntityId, EntityTypeId, FieldComputationId } from "@archetype/ids";
import { z } from "zod";

import { VersionType } from "../common/Version";
import { ColumnType } from "./Column";
import { ComputationInputValues } from "./ComputationInputValues";
import { FieldComputationHashedInputValues, FieldComputationStatus } from "./FieldComputation";

export const AIColumnComputationKey = z.object({
  versionType: VersionType,

  entityId: EntityId,
  entityTypeId: EntityTypeId,

  columnId: ColumnId,
});
export type IAIColumnComputationKey = z.infer<typeof AIColumnComputationKey>;

export const AIColumnComputationInfo = AIColumnComputationKey.merge(
  z.object({
    computationId: FieldComputationId,
    startedAt: z.date(),

    /**
     * instructions used for computing this result
     * Note the instructions on the data model can change, invalidating this result
     */
    inputInstructions: z.string(),
    /**
     * Values of the entity provided to the model for computing the result, technically can be a subset of the entity (when we know which subset is relevant)
     */
    inputValues: FieldComputationHashedInputValues,

    inputExpectedResultType: ColumnType,

    // When recomputing, should the instructions contain the previous values or the new values? (Before it returns)
    // Or should we track separately the values being computed for and the values of the current result?
    status: FieldComputationStatus,
  }),
);

export const AIColumnComputationExecution = AIColumnComputationInfo.omit({
  startedAt: true,
  inputValues: true,
}).merge(
  z.object({
    inputValues: ComputationInputValues,
  }),
);

export const AIColumnComputationResult = AIColumnComputationInfo;

export type IAIColumnComputationInfo = z.infer<typeof AIColumnComputationInfo>;
export type IAIColumnComputationExecution = z.infer<typeof AIColumnComputationExecution>;
export type IAIColumnComputationResult = z.infer<typeof AIColumnComputationResult>;
