import { cn } from "@archetype/ui";
import React from "react";
import ContentEditable from "react-contenteditable";

import { useCellContentEditable } from "../../hooks/useCellContentEditable";
import { useCellEditOnEnter } from "../../hooks/useCellEditOnEnter";
import { ExpandableCellWrapper } from "../ExpandableCellWrapper";
import type { IDataTableCommonCellProps, IDataTableCurrencyCell } from "./api";

export function CurrencyCell<TRowId extends string, TColId extends string>({
  cell,
  isCellSelected,
  isCellEditing,
  isRowSelected,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTableCurrencyCell<TRowId, TColId>>): React.ReactNode {
  const { value, onChange, colId, rowId, readOnly, currency } = cell;

  const formatValue = (val: number | null): string => `${currency}${val?.toFixed(2) ?? ""}`;
  const parseValue = (val: string): number => parseFloat(val.replace(/[^0-9.-]+/g, ""));

  const { contentEditableRef, localValue, handleChange, handleBlur, handleKeyDown } = useCellContentEditable({
    initialValue: formatValue(value),
    rowId,
    colId,
    onChange: (newValue) => {
      const parsedValue = parseValue(newValue);

      if (!isNaN(parsedValue) && parsedValue !== value) {
        void onChange?.(parsedValue);
      }
    },
    isCellEditing,
    readOnly,
  });

  useCellEditOnEnter({
    rowId,
    colId,
    isCellEditing,
    isCellSelected,
  });

  return (
    <ExpandableCellWrapper
      cell={cell}
      isCellEditing={isCellEditing}
      isCellSelected={isCellSelected}
      isRowSelected={isRowSelected}
    >
      <ContentEditable
        autoFocus
        className={cn("size-full text-base outline-none", (readOnly || !isCellEditing) && "truncate")}
        disabled={readOnly || !isCellEditing}
        html={localValue}
        innerRef={contentEditableRef}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </ExpandableCellWrapper>
  );
}
