import { isLoadedRelationViewField } from "@archetype/core";
import type { IViewFieldValue } from "@archetype/dsl";
import { useCallback } from "react";

import { RelationInput } from "../../../inputs/RelationInput";
import { StringInput } from "../primitive/StringInput";
import type { IActionInputComponent } from "../types";

export const ActionRelationInput: IActionInputComponent = ({
  versionType,
  field,
  onChange,
  input,
  createNewProps,
  readOnly = false,
  entityTypeId,
  organizationId,
  entity,
  currentUserInfo,
  isFieldTouched,
}) => {
  const { viewField } = input;

  const handleChange = useCallback(
    (value: IViewFieldValue) => {
      onChange(value);
    },
    [onChange],
  );

  const handleBlur = useCallback(() => {
    field.onBlur();
  }, [field]);

  if (!isLoadedRelationViewField(viewField)) {
    return (
      <StringInput
        currentUserInfo={currentUserInfo}
        entity={entity}
        entityTypeId={entityTypeId}
        field={field}
        input={input}
        isFieldTouched={isFieldTouched}
        organizationId={organizationId}
        readOnly={readOnly}
        versionType={versionType}
        onChange={handleChange}
      />
    );
  }

  return (
    <RelationInput
      ref={field.ref}
      createNewProps={createNewProps}
      currentUserInfo={currentUserInfo}
      readOnly={readOnly}
      required={input.required}
      versionType={versionType}
      viewField={viewField}
      viewFieldValue={field.value ?? { type: "null" }}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};
