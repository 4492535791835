import {
  MultiSelectDropdown,
  MultiSelectDropdownContent,
  MultiSelectDropdownItem,
  MultiSelectDropdownTrigger,
  MultiSelectDropdownValue,
} from "@archetype/ui";
import { useCallback, useState } from "react";
import React from "react";

import type { IColumnFilterComponent } from "../types";
import { StringFilterInput } from "./StringFilterInput";

export const EnumFilterInput: IColumnFilterComponent = (props) => {
  const { className, value, onChange: handleChange, columnType } = props;

  const [open, handleOpenChange] = useState(false);

  const handleEnumChange = useCallback(
    (itemValue: string) =>
      (checked: boolean): void => {
        const currentValues =
          value?.anyInArray?.type === "value" && value.anyInArray.value.type === "array"
            ? value.anyInArray.value.value
            : [];

        let newValues: string[];

        if (checked) {
          newValues = [...currentValues, itemValue];
        } else {
          newValues = currentValues.filter((v) => v !== itemValue);
        }

        if (newValues.length === 0) {
          handleChange(undefined);
        } else {
          handleChange({
            anyInArray: { type: "value", value: { type: "array", value: newValues } },
          });
        }
      },
    [value, handleChange],
  );

  if (columnType.type !== "enum" || columnType.enumAllowedValues == null || columnType.enumAllowedValues.length === 0) {
    return <StringFilterInput {...props} />;
  }

  const enumValues = columnType.enumAllowedValues;

  const currentValues =
    value?.anyInArray?.type === "value" && value.anyInArray.value.type === "array" ? value.anyInArray.value.value : [];

  return (
    <MultiSelectDropdown open={open} onOpenChange={handleOpenChange}>
      <MultiSelectDropdownTrigger small className={className}>
        <MultiSelectDropdownValue small label="option" selectedItems={currentValues} />
      </MultiSelectDropdownTrigger>
      <MultiSelectDropdownContent>
        {enumValues.map((option) => (
          <MultiSelectDropdownItem
            key={option}
            checked={currentValues.includes(option)}
            onCheckedChange={handleEnumChange(option)}
          >
            {option}
          </MultiSelectDropdownItem>
        ))}
      </MultiSelectDropdownContent>
    </MultiSelectDropdown>
  );
};
