import React, { useCallback, useMemo, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";

import type { IDataTableSelectionMode } from "../api";
import { DataTableInstanceContext } from "./DataTableInstanceContext";

interface IDataTableSelectionContextType<TRowId extends string, TColId extends string> {
  selectedCells: Map<TRowId, Set<TColId>>;
  selectedRowIndices: Set<number>;
  selectionMode: IDataTableSelectionMode<TRowId, TColId>;
  selectCell: (rowId: TRowId, colId: TColId) => void;
  selectRow: (rowIndex: number) => void;
  toggleRow: (rowIndex: number) => void;
  selectAllRows: () => void;
  deselectAllRows: () => void;
  clearRowSelection: () => void;
  lastSelectedCell: { rowId: TRowId; colId: TColId } | null;
  isAllRowsSelected: boolean;
  isSomeRowsSelected: boolean;
}

interface IDataTableSelectionProviderProps<TRowId extends string, TColId extends string> {
  children: React.ReactNode;
  selectionMode: IDataTableSelectionMode<TRowId, TColId>;
}

export const DataTableSelectionContext = createContext<IDataTableSelectionContextType<string, string>>(
  {} as IDataTableSelectionContextType<string, string>,
);

export function DataTableSelectionProvider<TRowId extends string, TColId extends string>({
  children,
  selectionMode,
}: IDataTableSelectionProviderProps<TRowId, TColId>): React.ReactElement {
  const data = useContextSelector(DataTableInstanceContext, (context) => context.data);
  const rowCount = useContextSelector(DataTableInstanceContext, (context) => context.rowCount);

  const [state, setState] = useState<{
    selectedCells: Map<TRowId, Set<TColId>>;
    selectedRowIndices: Set<number>;
    lastSelectedCell: { rowId: TRowId; colId: TColId } | null;
  }>({
    selectedCells: new Map(),
    selectedRowIndices: new Set(),
    lastSelectedCell: null,
  });

  const selectedCells = useMemo(() => {
    if (selectionMode.type === "cell" && selectionMode.controlled) {
      return selectionMode.controlled.selectedCells;
    }

    return state.selectedCells;
  }, [selectionMode, state.selectedCells]);

  const selectedRowIndices = useMemo(() => {
    if (selectionMode.type === "row" && selectionMode.controlled) {
      return selectionMode.controlled.selectedRowIndices;
    }

    return state.selectedRowIndices;
  }, [selectionMode, state.selectedRowIndices]);

  const isAllRowsSelected = useMemo(() => {
    if (selectionMode.type !== "row") return false;

    return rowCount > 0 && selectedRowIndices.size === rowCount;
  }, [rowCount, selectedRowIndices.size, selectionMode.type]);

  const isSomeRowsSelected = useMemo(() => {
    if (selectionMode.type !== "row") return false;

    return selectedRowIndices.size > 0 && selectedRowIndices.size < rowCount;
  }, [rowCount, selectedRowIndices.size, selectionMode.type]);

  const updateRowSelection = useCallback(
    (newSelection: Set<number>) => {
      if (selectionMode.type !== "row") return;

      if (selectionMode.controlled) {
        selectionMode.controlled.onSelectionChange(newSelection);
      } else {
        setState((prev) => ({ ...prev, selectedRowIndices: newSelection }));
      }

      const selectedRowIds = Array.from(newSelection)
        .map((index) => data[index]?.id)
        .filter(Boolean) as TRowId[];

      selectionMode.onRowSelectionChange?.(selectedRowIds);
    },
    [selectionMode, data],
  );

  const handleRowSelection = useCallback(
    (rowIndex: number) => {
      if (selectionMode.type !== "row") return;

      const newSelection = new Set(selectedRowIndices);

      if (!selectionMode.multiselect) {
        newSelection.clear();
      }
      newSelection.add(rowIndex);
      updateRowSelection(newSelection);
    },
    [selectionMode, selectedRowIndices, updateRowSelection],
  );

  const handleToggleRow = useCallback(
    (rowIndex: number) => {
      if (selectionMode.type !== "row") return;

      const newSelection = new Set(selectedRowIndices);

      if (!selectionMode.multiselect) {
        if (newSelection.has(rowIndex)) {
          newSelection.clear();
        } else {
          newSelection.clear();
          newSelection.add(rowIndex);
        }
      } else {
        if (newSelection.has(rowIndex)) {
          newSelection.delete(rowIndex);
        } else {
          newSelection.add(rowIndex);
        }
      }

      updateRowSelection(newSelection);
    },
    [selectionMode, selectedRowIndices, updateRowSelection],
  );

  const handleSelectAllRows = useCallback(() => {
    if (selectionMode.type !== "row" || !selectionMode.multiselect) return;

    const newSelection = new Set<number>();

    for (let i = 0; i < rowCount; i++) {
      newSelection.add(i);
    }
    updateRowSelection(newSelection);
  }, [selectionMode, rowCount, updateRowSelection]);

  const handleDeselectAllRows = useCallback(() => {
    if (selectionMode.type !== "row") return;
    updateRowSelection(new Set());
  }, [selectionMode, updateRowSelection]);

  const handleClearRowSelection = useCallback(() => {
    if (selectionMode.type !== "row") return;
    updateRowSelection(new Set());
  }, [selectionMode, updateRowSelection]);

  const handleCellSelection = useCallback(
    (rowId: TRowId, colId: TColId) => {
      if (selectionMode.type === "cell") {
        const newSelection = new Map(selectedCells);

        if (!selectionMode.multiselect) {
          newSelection.clear();
        }
        newSelection.set(rowId, new Set([colId]));

        if (selectionMode.controlled) {
          selectionMode.controlled.onSelectionChange(newSelection);
        } else {
          setState((prev) => ({
            ...prev,
            selectedCells: newSelection,
            lastSelectedCell: { rowId, colId },
          }));
        }
      } else if (selectionMode.allowCellSelection) {
        const newSelection = new Map<TRowId, Set<TColId>>();
        const rowSelection = new Set<TColId>();

        rowSelection.add(colId);
        newSelection.set(rowId, rowSelection);

        setState((prev) => ({
          ...prev,
          selectedCells: newSelection,
          lastSelectedCell: { rowId, colId },
        }));
      }
    },
    [selectionMode, selectedCells],
  );

  const value = useMemo(
    () => ({
      selectedCells,
      selectedRowIndices,
      selectionMode,
      selectCell: handleCellSelection,
      selectRow: handleRowSelection,
      toggleRow: handleToggleRow,
      selectAllRows: handleSelectAllRows,
      deselectAllRows: handleDeselectAllRows,
      clearRowSelection: handleClearRowSelection,
      lastSelectedCell: state.lastSelectedCell,
      isAllRowsSelected,
      isSomeRowsSelected,
    }),
    [
      selectedCells,
      selectedRowIndices,
      selectionMode,
      handleCellSelection,
      handleRowSelection,
      handleToggleRow,
      handleSelectAllRows,
      handleDeselectAllRows,
      handleClearRowSelection,
      state.lastSelectedCell,
      isAllRowsSelected,
      isSomeRowsSelected,
    ],
  );

  // Since we cannot type contexts with generics we have to cast to any here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment -- functional
  return <DataTableSelectionContext.Provider value={value as any}>{children}</DataTableSelectionContext.Provider>;
}
