import type { IStateMachine } from "@archetype/dsl";
import { isTargetEntityTypeCore, modifiedHappyPath, upgradeUserEntityTypeCoreIfNeeded } from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";
import { forEach, mapValues } from "@archetype/utils";
import { size } from "lodash";

import { buildActionsFromSupportEntityType } from "./buildActionsFromSupportEntityType";
import { correctEntityTypeIfNeeded } from "./correctEntityTypeIfNeeded";
import type { IOperationsEdits, ISaveableOperationsEdits } from "./editOperations/operations";

export const generateEditsFromWorkspace = (operationEdits: IOperationsEdits): ISaveableOperationsEdits => {
  const newOrEditedActions: ISaveableOperationsEdits["newOrEditedActions"] = Object.assign(
    {},
    operationEdits.newOrEditedActions,
  );

  const newOrEditedEntityTypesWithActions: ISaveableOperationsEdits["newOrEditedEntityTypes"] = mapValues(
    operationEdits.newOrEditedEntityTypes,
    (editedEntityType) => {
      if (isTargetEntityTypeCore(editedEntityType)) {
        const stateMachine: IStateMachine | undefined =
          operationEdits.stateMachineEdits[editedEntityType.targetEntityTypeApplicationGroupId];

        return correctEntityTypeIfNeeded(editedEntityType, stateMachine);
      }

      // State machine edit cannot be relevant if not a target entity type
      const entityType = upgradeUserEntityTypeCoreIfNeeded(correctEntityTypeIfNeeded(editedEntityType, undefined));

      const supportActionsInfo = buildActionsFromSupportEntityType(entityType, newOrEditedActions);

      Object.assign(newOrEditedActions, supportActionsInfo.newActions);

      const res: ISaveableOperationsEdits["newOrEditedEntityTypes"][IEntityTypeId] = {
        ...entityType,
        supportActionsInfo: supportActionsInfo.supportActionIds,
        deleteActionId: supportActionsInfo.deleteActionId,
        targetEntityTypeApplicationGroupId: entityType.targetEntityTypeApplicationGroupId as null, // drops less info to recover easier if; something is wrong
      };

      return res;
    },
  );

  if (size(operationEdits.stateMachineEdits) === 0) {
    return {
      ...operationEdits,
      newOrEditedActions,
      newOrEditedEntityTypes: newOrEditedEntityTypesWithActions,
      stateMachineEdits: {},
    };
  }

  const stateMachineEdits = operationEdits.stateMachineEdits;

  forEach(newOrEditedEntityTypesWithActions, (entityType) => {
    if (!isTargetEntityTypeCore(entityType)) {
      return;
    }

    const currentStateMachine = stateMachineEdits[entityType.targetEntityTypeApplicationGroupId];

    if (currentStateMachine == null) {
      return;
    }

    // TODO operations: add the fltering by disabled operations here, and in the operation handling itself
    // so this is just a safe catch, but no need to actually fail of the target entity type is undefined
    // if (disabledOperationsForTargetEntityType[viewField.id]?.has(DATA_MODEL_OPERATIONS.addToForm) === true) {
    //   return;
    // }

    const newStateMachine: IStateMachine = {
      ...currentStateMachine,
    };

    // Correct happy path
    newStateMachine.happyPath = modifiedHappyPath(newStateMachine.happyPath, newStateMachine);

    stateMachineEdits[entityType.targetEntityTypeApplicationGroupId] = {
      ...currentStateMachine,
      ...newStateMachine,
    };
  });

  return {
    organizationId: operationEdits.organizationId,
    stateMachineEdits,
    newOrEditedEntityTypes: newOrEditedEntityTypesWithActions,
    newOrEditedActions,
    newOrEditedRelations: operationEdits.newOrEditedRelations,
    deletedRelations: operationEdits.deletedRelations,
    deletedActions: operationEdits.deletedActions,
  };
};
