import { cn } from "@archetype/ui";
import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { useContextSelector } from "use-context-selector";

import { DataTableEditingContext } from "../context/DataTableEditingContext";
import { DataTableSelectionContext } from "../context/DataTableSelectionContext";
import type { IDataTableCell } from "./cells/api";

const MIN_EXPANDABLE_CELL_WIDTH = 350;
const MAX_EXPANDABLE_CELL_WIDTH = 350;
const VIEWPORT_PADDING = 10;

export interface IExpandableCellWrapperProps<
  TRowId extends string,
  TColumnId extends string,
  TCell extends IDataTableCell<TRowId, TColumnId>,
> {
  cell: TCell;
  isCellEditing: boolean;
  isCellSelected: boolean;
  isRowSelected: boolean;
  minWidth?: number;
  maxWidth?: number;
  children?: React.ReactNode;
}

export function ExpandableCellWrapper<
  TRowId extends string,
  TColumnId extends string,
  TCell extends IDataTableCell<TRowId, TColumnId>,
>({
  cell,
  isCellEditing,
  isCellSelected,
  children,
  minWidth = MIN_EXPANDABLE_CELL_WIDTH,
  maxWidth = MAX_EXPANDABLE_CELL_WIDTH,
}: IExpandableCellWrapperProps<TRowId, TColumnId, TCell>): React.ReactNode {
  const { rowId, colId, readOnly } = cell;
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [borderWidth, setBorderWidth] = useState(0);

  const setEditableCell = useContextSelector(DataTableEditingContext, (ctx) => ctx.setEditableCell);
  const selectCell = useContextSelector(DataTableSelectionContext, (ctx) => ctx.selectCell);

  // Use Popper.js to position the expanded content
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    strategy: "fixed", // Use fixed positioning for more precise control
    modifiers: [
      { name: "preventOverflow", options: { padding: VIEWPORT_PADDING } },
      { name: "flip", options: { padding: VIEWPORT_PADDING } },
      {
        name: "computeStyles",
        options: {
          adaptive: false, // Disable adaptive positioning
          gpuAcceleration: false, // Disable GPU acceleration for more precise positioning
        },
      },
    ],
  });

  useEffect(() => {
    if (referenceElement) {
      const computedStyle = window.getComputedStyle(referenceElement);
      const borderWidthValue = parseFloat(computedStyle.borderWidth) || 1.5;

      setBorderWidth(borderWidthValue);
    }
  }, [referenceElement]);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (!isCellSelected) {
        selectCell(rowId, colId);
        setEditableCell(null);
      } else if (!isCellEditing) {
        setEditableCell({ rowId, colId });
        e.stopPropagation();
      }
    },
    [selectCell, setEditableCell, rowId, colId, isCellSelected, isCellEditing],
  );

  useEffect(() => {
    // Update Popper's position when resizing
    const handleResize = (): void => {
      if (update) {
        void update();
      }
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, [update]);

  useEffect(() => {
    // Update Popper's position when scrolling
    const handleScroll = (): void => {
      if (update) {
        void update();
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return (): void => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [update]);

  // Close the expanded cell if it goes out of view
  useEffect(() => {
    if (!referenceElement || !isCellEditing) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;

        if (entry == null || !entry.isIntersecting) {
          setEditableCell(null);
        }
      },
      { root: null, threshold: 0 },
    );

    observer.observe(referenceElement);

    return (): void => {
      observer.disconnect();
    };
  }, [referenceElement, isCellEditing, setEditableCell]);

  if (!isCellEditing) {
    return (
      <div
        ref={setReferenceElement}
        className={cn("relative flex h-full min-w-0 items-center overflow-hidden px-2")}
        data-testid="cell-wrapper"
        onClick={handleClick}
      >
        <div className="w-full truncate">{children}</div>
      </div>
    );
  }

  const minComputedWidth = Math.max(minWidth, (referenceElement?.offsetWidth ?? 0) + borderWidth * 2);
  const maxComputedWidth = Math.max(
    maxWidth,
    Math.min(maxWidth, (referenceElement?.offsetWidth ?? 0) + borderWidth * 2),
  );

  return (
    <>
      <div
        ref={setReferenceElement}
        className={cn("relative flex h-full min-w-0 items-center overflow-hidden px-2")}
        data-testid="cell-wrapper"
        onClick={handleClick}
      >
        <div className="w-full truncate">{children}</div>
      </div>

      {createPortal(
        <div
          ref={setPopperElement}
          className={cn(
            "bg-paper selected-highlight z-40 flex items-center border-[1.5px] border-blue-600 px-2 shadow-sm outline-none",
            readOnly && "border-gray-500",
          )}
          data-testid="expanded-cell-wrapper"
          style={{
            ...styles.popper,
            minWidth: `${minComputedWidth.toFixed(0)}px`,
            maxWidth: `${maxComputedWidth.toFixed(0)}px`,
            minHeight: `${((referenceElement?.offsetHeight ?? 0) + borderWidth * 2).toFixed(0)}px`,
            position: "fixed",
            top: (referenceElement?.getBoundingClientRect().top ?? 0) - borderWidth,
            left: (referenceElement?.getBoundingClientRect().left ?? 0) - borderWidth,
          }}
          {...attributes.popper}
          tabIndex={0}
        >
          <div className="flex-1" data-testid="expanded-cell-content">
            {children}
          </div>
        </div>,
        document.body,
      )}
    </>
  );
}
