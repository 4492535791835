import { EntityId, EntityTypeId, ViewFieldId } from "@archetype/ids";
import * as z from "zod";

import { ViewFieldValue } from "./ViewFieldValue";

export const MinimalEntityWithFields = z.object({
  entityId: EntityId,
  entityTypeId: EntityTypeId,
  fields: z.record(ViewFieldId, ViewFieldValue.optional()),
});
export type IMinimalEntityWithFields = z.infer<typeof MinimalEntityWithFields>;
