import type { IEntityTypeCore, IRelationCore, IViewField } from "@archetype/dsl";
import {
  createColumnViewField,
  createRelationViewField,
  type IActionChange,
  type IActionCore,
  isActionAddContextualFieldChange,
  isActionAddInputChange,
  isActionDisableEmailConfigChange,
  isActionEditEmailConfigChange,
  isActionEditInputChange,
  isActionNameChange,
  isActionRemoveContextualFieldChange,
  isActionRemoveInputChange,
  isColumnViewField,
  isRelationViewField,
} from "@archetype/dsl";
import type { IActionId, IEntityTypeId, IRelationId } from "@archetype/ids";
import { flatMap, isNonNullable, mapKeysNoUndefined } from "@archetype/utils";
import assertNever from "assert-never";
import { values } from "lodash";

import { generateLoadedViewFieldsMapForEntityType } from "../adapters/generateViewFieldMaps";
import type { ILoadedViewField } from "../apiTypes/LoadedViewField";
import { createFileLogger } from "../logger";
import type {
  IEditActionNameOperation,
  IEditOperation,
  ISetActionOperation,
} from "../stateMachine/editOperations/operations";

const logger = createFileLogger("convertActionChangesToEditOperations");

function loadedViewFieldToViewField(f: ILoadedViewField): IViewField {
  if (isColumnViewField(f)) {
    return createColumnViewField(f.columnId);
  }

  if (isRelationViewField(f)) {
    return createRelationViewField(f.relationId, f.direction);
  }

  assertNever(f);
}

export function convertActionChangesToEditOperations({
  changes,
  actionsById,
  entityTypesById,
  relationsById,
}: {
  changes: IActionChange[];
  actionsById: Record<IActionId, IActionCore>;
  entityTypesById: Record<IEntityTypeId, IEntityTypeCore>;
  relationsById: Record<IRelationId, IRelationCore>;
}): IEditOperation[] {
  const res: Record<
    IActionId,
    {
      editActionName?: IEditActionNameOperation;
      editActionDefinition?: ISetActionOperation;
    }
  > = {};

  changes.forEach((change) => {
    const action = actionsById[change.actionId];

    if (action == null) {
      throw new Error(`Action ${change.actionId} not found`);
    }

    const entityType = entityTypesById[action.entityTypeId];

    if (entityType == null) {
      throw new Error(`Entity type ${action.entityTypeId} not found`);
    }

    if (entityType.targetEntityTypeApplicationGroupId == null) {
      // we only apply action changes to core entity types
      logger.error(`Entity type ${action.entityTypeId} has no application group id`);

      return;
    }

    const viewFieldsById = generateLoadedViewFieldsMapForEntityType(entityType, relationsById);
    const viewFieldsByName = mapKeysNoUndefined(viewFieldsById, (f) => f.displayName);

    const currActionDefinition =
      res[action.id]?.editActionDefinition?.action.actionDefinition ?? action.actionDefinition;

    if (isActionNameChange(change)) {
      res[action.id] = {
        ...res[action.id],
        editActionName: {
          type: "editActionName",
          actionId: action.id,
          newName: change.changeActionNameTo,
        },
      };
    } else if (isActionDisableEmailConfigChange(change)) {
      res[action.id] = {
        ...res[action.id],
        editActionDefinition: {
          type: "setAction",
          action: {
            actionId: action.id,
            actionDefinition: {
              ...currActionDefinition,
              sideEffects: undefined,
            },
          },
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          targetEntityTypeId: action.entityTypeId,
        },
      };
    } else if (isActionEditEmailConfigChange(change)) {
      const toPersonRelation = relationsById[change.newEmailConfig.toPersonRelation.relationId];

      // safety check
      if (toPersonRelation == null) {
        throw new Error(`To person relation ${change.newEmailConfig.toPersonRelation.relationId} not found`);
      }

      res[action.id] = {
        ...res[action.id],
        editActionDefinition: {
          type: "setAction",
          action: {
            actionId: action.id,
            actionDefinition: {
              ...currActionDefinition,
              sideEffects: {
                email: {
                  ...currActionDefinition.sideEffects?.email,
                  isEnabled: change.newEmailConfig.isEnabled,
                  toPersonRelation: {
                    relationId: toPersonRelation.id,
                    direction: change.newEmailConfig.toPersonRelation.direction,
                  },
                  viewFieldsToSend: change.newEmailConfig.viewFieldsToSend
                    .map((viewFieldName) => viewFieldsByName[viewFieldName])
                    .filter(isNonNullable)
                    .map(loadedViewFieldToViewField),
                },
              },
            },
          },
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          targetEntityTypeId: action.entityTypeId,
        },
      };
    } else if (isActionAddContextualFieldChange(change)) {
      const field = viewFieldsByName[change.contextualFieldNameToAdd];

      if (field == null) {
        logger.error(`Contextual field ${change.contextualFieldNameToAdd} not found`);

        return;
      }

      res[action.id] = {
        ...res[action.id],
        editActionDefinition: {
          type: "setAction",
          action: {
            actionId: action.id,
            actionDefinition: {
              ...currActionDefinition,
              contextualFields: [...currActionDefinition.contextualFields, loadedViewFieldToViewField(field)],
            },
          },
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          targetEntityTypeId: action.entityTypeId,
        },
      };
    } else if (isActionRemoveContextualFieldChange(change)) {
      const field = viewFieldsByName[change.contextualFieldNameToRemove];

      if (field == null) {
        logger.error(`Contextual field ${change.contextualFieldNameToRemove} not found`);

        return;
      }

      res[action.id] = {
        ...res[action.id],
        editActionDefinition: {
          type: "setAction",
          action: {
            actionId: action.id,
            actionDefinition: {
              ...currActionDefinition,
              contextualFields: currActionDefinition.contextualFields.filter((f) => f.id !== field.id),
            },
          },
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          targetEntityTypeId: action.entityTypeId,
        },
      };
    } else if (isActionAddInputChange(change)) {
      const field = viewFieldsByName[change.inputFieldToAdd.fieldName];

      if (field == null) {
        logger.error(`Input field ${change.inputFieldToAdd.fieldName} not found`);

        return;
      }

      res[action.id] = {
        ...res[action.id],
        editActionDefinition: {
          type: "setAction",
          action: {
            actionId: action.id,
            actionDefinition: {
              ...currActionDefinition,
              inputs: [
                ...currActionDefinition.inputs,
                {
                  viewField: loadedViewFieldToViewField(field),
                  required: !change.inputFieldToAdd.isOptional,
                  allowChangingDefault: change.inputFieldToAdd.allowChangingDefault,
                },
              ],
            },
          },
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          targetEntityTypeId: action.entityTypeId,
        },
      };
    } else if (isActionRemoveInputChange(change)) {
      const field = viewFieldsByName[change.inputFieldNameToRemove];

      if (field == null) {
        logger.error(`Input field ${change.inputFieldNameToRemove} not found`);

        return;
      }

      res[action.id] = {
        ...res[action.id],
        editActionDefinition: {
          type: "setAction",
          action: {
            actionId: action.id,
            actionDefinition: {
              ...currActionDefinition,
              inputs: currActionDefinition.inputs.filter((i) => i.viewField.id !== field.id),
            },
          },
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          targetEntityTypeId: action.entityTypeId,
        },
      };
    } else if (isActionEditInputChange(change)) {
      const field = viewFieldsByName[change.inputToEdit.fieldName];

      if (field == null) {
        throw new Error(`Input field ${change.inputToEdit.fieldName} not found`);
      }

      res[action.id] = {
        ...res[action.id],
        editActionDefinition: {
          type: "setAction",
          action: {
            actionId: action.id,
            actionDefinition: {
              ...currActionDefinition,
              inputs: currActionDefinition.inputs.map((i) =>
                i.viewField.id === field.id
                  ? {
                      viewField: loadedViewFieldToViewField(field),
                      allowChangingDefault: change.inputToEdit.allowChangingDefault,
                      required: !change.inputToEdit.isOptional,
                    }
                  : i,
              ),
            },
          },
          applicationGroupId: entityType.targetEntityTypeApplicationGroupId,
          targetEntityTypeId: action.entityTypeId,
        },
      };
    } else {
      assertNever(change);
    }
  });

  return flatMap(res, (v) => values(v).filter(isNonNullable));
}
