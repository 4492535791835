import { cn, defaultConfig, editorStateToMarkdown, MarkdownEditor, markdownToEditorState } from "@archetype/ui";
import type { LexicalEditor } from "lexical";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { useCellEditOnEnter } from "../../hooks/useCellEditOnEnter";
import { ExpandableCellWrapper } from "../ExpandableCellWrapper";
import type { IDataTableCommonCellProps, IDataTableMarkdownCell } from "./api";

interface IMarkdownCellProps<TRowId extends string, TColId extends string>
  extends IDataTableCommonCellProps<TRowId, TColId, IDataTableMarkdownCell<TRowId, TColId>> {
  cell: IDataTableMarkdownCell<TRowId, TColId>;
}

function MarkdownCellInner<TRowId extends string, TColId extends string>({
  cell,
  isCellEditing,
  isCellSelected,
  isRowSelected,
}: IMarkdownCellProps<TRowId, TColId>): React.ReactElement {
  const { onChange, transformers, value, nodes, plugins, readOnly } = cell;
  const editorRef = useRef<LexicalEditor | null>(null);
  const lastValueRef = useRef<string | null>(value ?? null);
  const lastEditingRef = useRef(isCellEditing);

  const handleChange = useCallback((editor: LexicalEditor) => {
    editorRef.current = editor;
  }, []);

  useCellEditOnEnter({
    rowId: cell.rowId,
    colId: cell.colId,
    isCellEditing,
    isCellSelected,
  });

  // Save changes when cell stops being edited
  useEffect(() => {
    if (lastEditingRef.current && !isCellEditing && editorRef.current && onChange != null) {
      const editor = editorRef.current;

      editor.read(() => {
        const editorState = editor.getEditorState();
        const markdown = editorStateToMarkdown(editorState, transformers)();
        const newValue = markdown.length === 0 ? null : markdown;

        // Only trigger onChange if the value actually changed
        if (newValue !== lastValueRef.current) {
          lastValueRef.current = newValue;
          void onChange(newValue);
        }
      });
    }
    lastEditingRef.current = isCellEditing;
  }, [isCellEditing, onChange, transformers]);

  const initialContent = useMemo(() => markdownToEditorState(value ?? "", transformers), [value, transformers]);

  const editorConfig = useMemo(
    () => ({
      ...defaultConfig,
      editorState: initialContent,
      nodes,
    }),
    [initialContent, nodes],
  );

  const editorClassName = useMemo(
    () => cn("size-full border-none bg-transparent p-0 text-base outline-none", isCellEditing && "min-h-8"),
    [isCellEditing],
  );

  const innerClassName = useMemo(
    () => cn(!isCellEditing && "line-clamp-1 cursor-pointer pt-0.5", isCellEditing && "ml-[-10px]"),
    [isCellEditing],
  );

  return (
    <ExpandableCellWrapper
      cell={cell}
      isCellEditing={isCellEditing}
      isCellSelected={isCellSelected}
      isRowSelected={isRowSelected}
    >
      <MarkdownEditor
        className={editorClassName}
        enableAnnotations={false}
        initialConfig={editorConfig}
        innerClassName={innerClassName}
        placeholder=""
        readOnly={readOnly || !isCellEditing}
        small={true}
        toolbar="floating"
        onChange={handleChange}
      >
        {plugins}
      </MarkdownEditor>
    </ExpandableCellWrapper>
  );
}

export const MarkdownCell = React.memo(MarkdownCellInner) as typeof MarkdownCellInner;
