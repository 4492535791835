import type { ILoadedViewField } from "@archetype/core";
import type { IEmailExtractedActionInput } from "@archetype/dsl";
import type { IEmailId, IOrganizationId, IViewFieldId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { Skeleton } from "@archetype/ui";
import React from "react";

import { EmailComponent } from "./EmailComponent";

interface ILoadingEmailProps {
  className?: string;
  emailId: IEmailId;
  organizationId: IOrganizationId;
  /**
   * Only used for email annotations so if emailExtractedData is provided
   */
  allFieldsById?: Record<IViewFieldId, ILoadedViewField>;
  emailExtractedData?: Partial<Record<IViewFieldId, IEmailExtractedActionInput>>;
}

export const LoadingEmail: React.FC<ILoadingEmailProps> = ({
  className,
  emailId,
  organizationId,
  emailExtractedData,
  allFieldsById,
}) => {
  const { data: emailQuery, isLoading: isLoadingEmail } = trpc.email.getEmailById.useQuery({
    emailId,
    organizationId,
  });

  if (isLoadingEmail) {
    return <Skeleton className="h-4 w-32" />;
  }

  if (emailQuery == null || !emailQuery.isEmailAuthorized || emailQuery.email == null) {
    // In current context where it is shown there should be another indicator of a private email, so no need to show empty state
    return null;
  }

  return (
    <EmailComponent
      allFieldsById={allFieldsById}
      className={className}
      email={emailQuery.email}
      emailExtractedData={emailExtractedData}
    />
  );
};
