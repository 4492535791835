import type { ILoadedAction } from "@archetype/core";
import type { IViewFieldValue } from "@archetype/dsl";
import type { IViewFieldId } from "@archetype/ids";
import { useMemoDeepCompare } from "@archetype/ui";
import { pickBy } from "@archetype/utils";
import type { UseFormReturn } from "react-hook-form";

export const useExternalUserCreateActionFieldValues = ({
  form,
  externalUserUpsertAction,
}: {
  form: UseFormReturn<Partial<Record<string, IViewFieldValue>>>;
  externalUserUpsertAction: ILoadedAction | undefined;
}): Partial<Record<IViewFieldId, IViewFieldValue>> | undefined => {
  const syntheticUserCreateActionInputsFieldIds = useMemoDeepCompare(
    () => new Set(externalUserUpsertAction?.actionDefinition.inputs.map((input) => input.viewField.id) ?? []),
    [externalUserUpsertAction?.actionDefinition.inputs],
  );

  const formValues = form.getValues();

  const externalUserFieldValues = useMemoDeepCompare(
    () =>
      externalUserUpsertAction == null
        ? undefined
        : pickBy(formValues, (_, fieldId) => syntheticUserCreateActionInputsFieldIds.has(fieldId as IViewFieldId)),
    [formValues, syntheticUserCreateActionInputsFieldIds, externalUserUpsertAction],
  );

  return externalUserFieldValues;
};
