import { EntityTypeId, RelationId } from "@archetype/ids";
import { z } from "zod";

import { PerColumnAndedFilters } from "./dataModel/dataLoading/PerColumnAndedFilters";
import { RelationDirection } from "./dataModel/RelationBase";

export const RelationPathNode = z.object({
  relationId: RelationId,
  direction: RelationDirection,
});

export const AuthorizingRelationPath = z.object({
  /**
   * If provided, ignore the entity that we are authorizing and instead
   * authorize if there exists an entity of the starting entity type with a
   * relation path as specified to the user entity type.
   */
  startingEntityType: z
    .object({
      id: EntityTypeId,
      /**
       * Do not use if startingEntityType is the user entity type.
       */
      filters: PerColumnAndedFilters.optional(),
    })
    .optional(),
  userTypeFilters: PerColumnAndedFilters.optional(),
  path: z.array(RelationPathNode),
});

export const Authorization = z.object({
  authorizedByRelationPath: AuthorizingRelationPath,
  /**
   * If set, this authorization gives access to anyone with a link to the entity
   *
   * This is only relevant and used for authorizations on a state.
   */
  authorizedForAnyoneWithLink: z.boolean().optional(),
});

export type IRelationPathNode = z.infer<typeof RelationPathNode>;
export type IAuthorizingRelationPath = z.infer<typeof AuthorizingRelationPath>;
export type IAuthorization = z.infer<typeof Authorization>;
