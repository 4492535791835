import { ColumnTypeId } from "@archetype/ids";
import { z } from "zod";

export const ColumnStringType = z.object({ type: z.literal("string"), primitiveType: z.literal("string") });
export type IColumnStringType = z.infer<typeof ColumnStringType>;

// String length refinement
export const ColumnStringEqualsRefinement = z
  .object({
    type: z.literal("stringEquals"),
    primitiveType: z.literal("string"),
    value: z.string(),
    child: ColumnTypeId,
  })
  .describe("Refine a string to be within a length range");
export type IColumnStringEqualsRefinement = z.infer<typeof ColumnStringEqualsRefinement>;

// String regex refinement
export const ColumnStringRegexRefinement = z
  .object({
    type: z.literal("stringRegex"),
    primitiveType: z.literal("string"),
    regex: z.string(),
    child: ColumnTypeId,
  })
  .describe("Refine a string to match a regex");
export type IColumnStringRegexRefinement = z.infer<typeof ColumnStringRegexRefinement>;

// String length refinement
export const ColumnStringLengthRefinement = z
  .object({
    type: z.literal("stringLength"),
    primitiveType: z.literal("string"),
    inclusiveMin: z.number().optional(),
    inclusiveMax: z.number().optional(),
    child: ColumnTypeId,
  })
  .describe("Refine a string to be within a length range");
export type IColumnStringLengthRefinement = z.infer<typeof ColumnStringLengthRefinement>;

// String starts with refinement
export const ColumnStringEndsWithRefinement = z
  .object({
    type: z.literal("stringEndsWith"),
    primitiveType: z.literal("string"),
    endsWithString: z.string(),
    child: ColumnTypeId,
  })
  .describe("Refine a string to end with a specific string");
export type IColumnStringEndsWithRefinement = z.infer<typeof ColumnStringEndsWithRefinement>;

// Enum refinement
export const ColumnEnumRefinement = z
  .object({
    type: z.literal("enum"),
    primitiveType: z.literal("string"),
    allowedValues: z.array(z.string()).describe("The allowed values for the enum"),
    child: ColumnTypeId,
  })
  .describe("Refine a string to end with a specific string");
export type IColumnEnumRefinement = z.infer<typeof ColumnEnumRefinement>;

// Makes a non-nullable string
export const ColumnNonNullableStringRefinement = z
  .object({
    type: z.literal("nonNullableString"),
    primitiveType: z.literal("string"),
    child: ColumnTypeId,
  })
  .describe("Refine a string to never be null");
export type IColumnNullableStringRefinement = z.infer<typeof ColumnNonNullableStringRefinement>;

export const ColumnStringRefinements = z.discriminatedUnion("type", [
  ColumnStringType,
  ColumnStringEqualsRefinement,
  ColumnStringLengthRefinement,
  ColumnStringRegexRefinement,
  ColumnStringEndsWithRefinement,
  ColumnEnumRefinement,
  ColumnNonNullableStringRefinement,
]);
export type IColumnStringRefinements = z.infer<typeof ColumnStringRefinements>;
