import type { ILoadedViewField } from "@archetype/core";
import { entityTypeToLoaded, type ILoadedEntityType } from "@archetype/core";
import { Cell, CheckboxHeader, DataTable, DataTableProvider } from "@archetype/data-table";
import type { IDataLoadingQuery, IEntityTypeCore, IRelationCore, IViewFieldValue } from "@archetype/dsl";
import { computeColumnViewFieldId, type IVersionType } from "@archetype/dsl";
import type { IEntityTypeId, IOrganizationId, IRelationId, IViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import type { IUntypedRoute } from "@archetype/ui";
import { Button, ShapeColorIcon, Skeleton, useMemoDeepCompare } from "@archetype/ui";
import { unpartialRecord } from "@archetype/utils";
import { skipToken } from "@tanstack/react-query";
import Link from "next/link";
import pluralize from "pluralize";
import React from "react";

import type { IGetEntityRoute, IGetLinkedEntityRoute } from "../api";
import { useInfiniteLoadedEntities } from "../hooks/entity/useInfiniteLoadedEntities";
import { useEntityTypeFields } from "../hooks/viewFields/useEntityTypeFields";
import { withRefetchInterval } from "../utils/refetchInterval";
import type { IEntityDataTableCellValue, IEntityDataTableColumn, IEntityDataTableRow } from "./api";
import { NEW_ROW_ENTITY_ID } from "./api";
import { EntityTableCellWrapper } from "./cells/EntityTableCellWrapper";
import { EntityHeaderWrapper } from "./EntityHeaderWrapper";
import { InlineEntityTableSkeleton } from "./InlineEntityTableSkeleton";

const COLUMN_SELECTION_ID = "column-selection" as IViewFieldId;

interface IInlineEntityTableWrapper {
  orgId: IOrganizationId;
  entityTypeId: IEntityTypeId;
  versionType: IVersionType;
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>> | undefined;
  allRelationsInOrg: Partial<Record<IRelationId, IRelationCore>>;
  getFullPageEntityRoute: IGetEntityRoute;
  getLinkedEntityRoute: IGetLinkedEntityRoute;

  /**
   * If provided, the query must result entities that are of the entity type provided as well.
   */
  dataLoadingQuery?: IDataLoadingQuery;
  titleOverride?: string;
  /**
   * If true, the table will allow multi-select in a checkbox column. @default false
   */
  allowMultiSelect?: boolean;
  /**
   * If provided, a button to create a new entity will be shown.
   */
  createNewProps?: {
    actionRoute: IUntypedRoute;
    buttonText: string;
  };
  /**
   * If true, the table will only be shown if there is data to display, i.e. hidden when loading and when there is no data or error. @default false
   */
  showOnlyIfData?: boolean;
}

export const InlineEntityTableWrapper: React.FC<IInlineEntityTableWrapper> = ({
  orgId,
  entityTypeId,
  versionType,
  allEntityTypes,
  allRelationsInOrg,
  getFullPageEntityRoute,
  getLinkedEntityRoute,
  dataLoadingQuery,
  titleOverride,
  allowMultiSelect,
  createNewProps,
  showOnlyIfData,
}) => {
  const entityTypeFromAllLoaded = useMemoDeepCompare(() => {
    const entityTypeCoreFromAllLoaded = allEntityTypes?.[entityTypeId];

    if (entityTypeCoreFromAllLoaded == null) {
      return undefined;
    }

    return entityTypeToLoaded(entityTypeCoreFromAllLoaded, allRelationsInOrg);
  }, [allEntityTypes, allRelationsInOrg, entityTypeId]);

  const { data: entityTypeQuery, isLoading: isLoadingEntityType } =
    builderTrpc.dataModel.fullyLoadedEntityType.useQuery(
      entityTypeFromAllLoaded != null
        ? skipToken
        : {
            id: entityTypeId,
            versionType,
          },
    );

  const entityType = entityTypeFromAllLoaded ?? entityTypeQuery?.entityType;

  if (isLoadingEntityType || entityType == null) {
    if (showOnlyIfData === true) {
      return null;
    }

    return <InlineEntityTableSkeleton count={undefined} title={titleOverride} />;
  }

  return (
    <TableContentWrapper
      allEntityTypes={allEntityTypes}
      allowMultiSelect={allowMultiSelect}
      createNewProps={createNewProps}
      dataLoadingQuery={dataLoadingQuery}
      entityType={entityType}
      getFullPageEntityRoute={getFullPageEntityRoute}
      getLinkedEntityRoute={getLinkedEntityRoute}
      orgId={orgId}
      showOnlyIfData={showOnlyIfData ?? false}
      titleOverride={titleOverride}
      versionType={versionType}
    />
  );
};

type ITableContentWrapper = Pick<
  IInlineEntityTableWrapper,
  "dataLoadingQuery" | "titleOverride" | "allowMultiSelect" | "createNewProps" | "allEntityTypes"
> & {
  entityType: ILoadedEntityType;
  versionType: IVersionType;
  orgId: IOrganizationId;
  getFullPageEntityRoute: IGetEntityRoute;
  getLinkedEntityRoute: IGetLinkedEntityRoute;
  showOnlyIfData: boolean;
};

const TableContentWrapper: React.FC<ITableContentWrapper> = ({
  entityType,
  allEntityTypes,
  versionType,
  getFullPageEntityRoute,
  getLinkedEntityRoute,
  dataLoadingQuery,
  titleOverride,
  allowMultiSelect,
  createNewProps,
  showOnlyIfData,
}) => {
  const { fixedRelevantFields, relevantFields, missingRelevantFields, restNonRelevantFields } = useEntityTypeFields({
    entityType,
    entityTypeId: entityType.id,
    versionType,
    stateId: undefined,
  });

  const fields: ILoadedViewField[] = useMemoDeepCompare(() => {
    return fixedRelevantFields.concat(relevantFields).concat(missingRelevantFields).concat(restNonRelevantFields);
  }, [fixedRelevantFields, relevantFields, missingRelevantFields, restNonRelevantFields]);

  // const columnIdsToQuery: IColumnId[] = useMemoDeepCompare(() => {
  //   return fields.filter(isLoadedColumnViewField).map((f) => f.columnId);
  // }, [fields]);

  // const relationsToQuery: { id: IRelationId; direction: IRelationDirection }[] = useMemoDeepCompare(() => {
  //   return fields.filter(isLoadedRelationViewField).map((f) => ({ id: f.relationId, direction: f.direction }));
  // }, [fields]);

  const {
    entitiesPagedData: entitiesPagedQuery,
    isLoadingEntities,
    isErrorLoadingEntities: isError,
  } = useInfiniteLoadedEntities({
    query: {
      organizationId: entityType.organizationId,
      dataLoadingQuery: dataLoadingQuery ?? {
        versionType,
        entityType: {
          type: "entityTypeId",
          entityTypeId: entityType.id,
        },
        filters: undefined,
      },
      dataLoadingConfig: {
        // No actual need to filteron load and makes it sequential with the relevant fields query
        // so it makes it reload once they reload, would be a bad pattern/UX
        specificRelations: null,
        specificColumns: null,
      },
    },
    options: {
      ...withRefetchInterval(10000),
      explicitlyDisabled: dataLoadingQuery == null,
    },
  });

  const entities = useMemoDeepCompare(() => {
    const firstPage = entitiesPagedQuery?.pages[0];

    return (
      entitiesPagedQuery?.pages &&
      firstPage && {
        entities: entitiesPagedQuery.pages.flatMap((p) => p.entities),
        numberOfEntities: firstPage.totalNumberEntities,
      }
    );
  }, [entitiesPagedQuery?.pages]);

  const tableData: IEntityDataTableRow[] | undefined = useMemoDeepCompare(() => {
    const entitiesAsTableData = entities?.entities.map((e) => ({
      ...e,
      id: e.entityId,
    }));

    return entitiesAsTableData;
  }, [entities?.entities]);

  const rowCount = entities?.entities.length ?? 0;

  const columns = useMemoDeepCompare<IEntityDataTableColumn[]>((): IEntityDataTableColumn[] => {
    const displayNameViewFieldId = computeColumnViewFieldId(entityType.displayNameColumn);

    const dataTableColumns = fields.map((field) => {
      const columnDef: IEntityDataTableColumn = {
        id: field.id,
        pinned: field.id === displayNameViewFieldId ? "left" : undefined,
        minWidth: field.id === displayNameViewFieldId ? 220 : 150,
        accessorFn: (row: IEntityDataTableRow): IEntityDataTableCellValue => {
          const fieldValue: IViewFieldValue | undefined = row.fields[field.id];

          if (fieldValue == null) {
            const res: IEntityDataTableCellValue = {
              value: { type: "null" },
              computeStatus: { kind: null, status: "success" },
            };

            return res;
          }

          const res: IEntityDataTableCellValue = {
            value: fieldValue,
          };

          return res;
        },
        cellRenderer: ({
          rowId,
          colId,
          value,
          isCellEditing,
          isCellSelected,
          isRowSelected,
          selectionMode,
        }): React.ReactNode => {
          const entity = entities?.entities.find((e) => e.entityId === rowId);
          const fieldValues = unpartialRecord(entity?.fields ?? {});

          return (
            <EntityTableCellWrapper
              cellValue={value}
              className={rowId === NEW_ROW_ENTITY_ID ? "bg-blue-200" : ""}
              colId={colId}
              entityType={entityType}
              fieldValues={fieldValues}
              getFullPageEntityRoute={getFullPageEntityRoute}
              getLinkedEntityRoute={getLinkedEntityRoute}
              isCellEditing={isCellEditing}
              isCellSelected={isCellSelected}
              isRowSelected={isRowSelected}
              readOnly={true}
              rowId={rowId}
              selectionMode={selectionMode}
              versionType={versionType}
              viewField={field}
            />
          );
        },
        headerRenderer: (): React.ReactNode => (
          <div className="flex h-full items-center justify-between">
            <EntityHeaderWrapper allEntityTypes={allEntityTypes} viewField={field} />
          </div>
        ),
      };

      return columnDef;
    });

    if (allowMultiSelect === true) {
      return [
        {
          id: COLUMN_SELECTION_ID,
          accessorFn: (): IEntityDataTableCellValue => ({
            value: { type: "null" },
          }),
          maxWidth: 60,
          minWidth: 60,
          enableResizing: false,
          pinned: "left",
          cellRenderer: ({ rowId, isCellEditing, isCellSelected, isRowSelected, selectionMode }): React.ReactNode => (
            <Cell
              cell={{ type: "rowSelection", rowId, colId: COLUMN_SELECTION_ID, readOnly: false }}
              isCellEditing={isCellEditing}
              isCellSelected={isCellSelected}
              isRowSelected={isRowSelected}
              selectionMode={selectionMode}
            />
          ),
          headerRenderer: (): React.ReactNode => <CheckboxHeader />,
        },
        ...dataTableColumns.filter((col) => col.id !== computeColumnViewFieldId(entityType.primaryKey)),
      ];
    }

    return dataTableColumns.filter((col) => col.id !== computeColumnViewFieldId(entityType.primaryKey));
  }, [
    fields,
    allowMultiSelect,
    entityType,
    allEntityTypes,
    getFullPageEntityRoute,
    getLinkedEntityRoute,
    versionType,
    entities?.entities,
  ]);

  const isLoading = isLoadingEntities;

  if (showOnlyIfData && (entities == null || entities.entities.length === 0)) {
    return null;
  }

  if (isLoading) {
    return (
      <InlineEntityTableSkeleton
        count={entities?.numberOfEntities}
        title={titleOverride ?? entityType.displayMetadata.name}
      />
    );
  }

  if (isError) {
    return (
      <InlineEntityTableError
        count={entities?.numberOfEntities}
        title={titleOverride ?? entityType.displayMetadata.name}
      />
    );
  }

  if (tableData == null || entities == null) {
    return (
      <InlineEntityTableError
        count={entities?.numberOfEntities}
        title={titleOverride ?? entityType.displayMetadata.name}
      />
    );
  }

  return (
    <div className="flex flex-col" data-testid="entity-table">
      <div className="mb-4 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-x-2">
          <ShapeColorIcon color={entityType.color} shape={entityType.shape} size="md" />
          <span data-testid="entity-table-title">{titleOverride ?? entityType.displayMetadata.name}</span>
          {entities.numberOfEntities > 0 ? <span>({entities.numberOfEntities})</span> : null}
        </div>
        {createNewProps != null && tableData.length > 0 && (
          <Link href={createNewProps.actionRoute}>
            <Button variant="ghost">{createNewProps.buttonText}</Button>
          </Link>
        )}
      </div>
      {tableData.length === 0 ? (
        <div className="border-border text-muted-foreground flex flex-row items-center justify-start space-x-0.5 rounded border border-dashed p-4 text-sm">
          <span>No related {pluralize(entityType.displayMetadata.name.toLowerCase(), 0)}</span>
          {createNewProps != null && (
            <Link href={createNewProps.actionRoute}>
              <Button
                className="text-muted-foreground tracking-normal underline"
                iconRight="chevron-right"
                variant="link"
              >
                {createNewProps.buttonText}
              </Button>
            </Link>
          )}
        </div>
      ) : (
        <DataTableProvider
          columns={columns}
          data={tableData}
          rowCount={rowCount}
          selectionMode={{
            type: "row",
            multiselect: allowMultiSelect ?? false,
            allowCellSelection: false,
          }}
        >
          <DataTable className="h-56" />
        </DataTableProvider>
      )}
    </div>
  );
};

const InlineEntityTableError: React.FC<{
  title: string | undefined;
  count: number | undefined;
}> = ({ title, count }) => {
  return (
    <div className="flex flex-col">
      <div className="mb-4 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-x-2">
          <ShapeColorIcon color="neutral" shape="triangle" size="md" />
          {title == null ? <Skeleton className="h-4 w-24" /> : <span>{title}</span>}
          {count == null ? <Skeleton className="h-5 w-6" /> : <span>({count})</span>}
        </div>
      </div>
      <div className="border-error text-error flex flex-row items-center justify-start space-x-2 rounded border border-dashed p-4 text-sm">
        <span>Error loading entities</span>
      </div>
    </div>
  );
};
