import type { IIconNames, IIconOrShape } from "@archetype/ui";
import {
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Icon,
  ShapeColorIcon,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@archetype/ui";
import { throttle } from "lodash-es";
import React, { useCallback, useState } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableInstanceContext } from "../../context/DataTableInstanceContext";
import { HeaderMenu } from "./HeaderMenu";

const THROTTLE_TIME = 16; // 60fps

interface IHeaderContent {
  label: string;
  leftIcon?: {
    className?: string;
    icon: IIconOrShape;
    tooltip?: string;
  };
  rightIcon?: {
    className?: string;
    icon: IIconNames;
    tooltip?: string;
  };
  isMenuOpen: boolean;
}

function HeaderContent({ label, leftIcon, rightIcon, isMenuOpen }: IHeaderContent): React.ReactNode {
  return (
    <div
      className={cn(
        "text-muted-foreground hover:bg-accent-background active:bg-accent-background flex h-full grow cursor-pointer select-none items-center justify-between overflow-hidden px-2 pr-4 text-base",
        isMenuOpen && "bg-accent-background",
        "relative",
      )}
    >
      <div className="flex min-w-0 flex-1 items-center gap-2 overflow-hidden">
        {leftIcon ? (
          <Tooltip>
            <TooltipTrigger disabled={leftIcon.tooltip == null}>
              {leftIcon.icon.type === "icon" ? (
                <Icon className={cn("size-3 shrink-0", leftIcon.className)} name={leftIcon.icon.value} />
              ) : (
                <ShapeColorIcon
                  className={cn("shrink-0", leftIcon.className)}
                  color={leftIcon.icon.color}
                  shape={leftIcon.icon.value}
                  size="sm"
                />
              )}
            </TooltipTrigger>
            {leftIcon.tooltip != null && <TooltipContent>{leftIcon.tooltip}</TooltipContent>}
          </Tooltip>
        ) : null}
        <span className="truncate">{label}</span>
      </div>
      {rightIcon ? (
        <Tooltip>
          <TooltipTrigger disabled={rightIcon.tooltip == null}>
            <Icon className={cn("size-3 shrink-0", rightIcon.className)} name={rightIcon.icon} />
          </TooltipTrigger>
          {rightIcon.tooltip != null && <TooltipContent>{rightIcon.tooltip}</TooltipContent>}
        </Tooltip>
      ) : null}
    </div>
  );
}

export type IDataTableHeaderCheckboxCell = {
  type: "checkbox";
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export type IDataTableHeaderIconCell = {
  type: "icon";
  icon: IIconNames;
  label: string;
  onClick: (e: React.MouseEvent) => void;
};

export type IDataTableHeaderMenuItem = IDataTableHeaderCheckboxCell | IDataTableHeaderIconCell;

export type IDataTableHeaderMenu =
  | {
      type: "dropdown";
      searchable?: boolean;
      items: IDataTableHeaderMenuItem[];
    }
  | {
      type: "custom";
      renderer: () => React.ReactNode;
    };
interface IHeader {
  label: string;
  allowResize?: boolean;
  leftIcon?: {
    className?: string;
    icon: IIconOrShape;
    tooltip?: string;
  };
  rightIcon?: {
    className?: string;
    icon: IIconNames;
    tooltip?: string;
  };
  menu?: IDataTableHeaderMenu;
  columnId: string;
}

export function Header({ label, leftIcon, rightIcon, menu, allowResize = true, columnId }: IHeader): React.ReactNode {
  const [isHovering, setIsHovering] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const resizingColumnId = useContextSelector(DataTableInstanceContext, (state) => state.resizingColumnId);
  const setIsResizing = useContextSelector(DataTableInstanceContext, (state) => state.setIsResizing);
  const resizeColumn = useContextSelector(DataTableInstanceContext, (state) => state.resizeColumn);

  const isThisColumnResizing = resizingColumnId != null && resizingColumnId === columnId;

  const throttledResize = useCallback(
    (width: number) => {
      throttle(() => {
        resizeColumn(columnId, width);
      }, THROTTLE_TIME)();
    },
    [resizeColumn, columnId],
  );

  const handleResize = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setIsResizing(columnId);

      document.body.style.cursor = "col-resize";

      const startX = "touches" in e ? e.touches[0]?.clientX : e.clientX;
      const headerEl = (e.target as HTMLElement).closest(".group\\/header");
      const startWidth = headerEl?.getBoundingClientRect().width ?? 0;

      const handleMouseMove = (evt: MouseEvent | TouchEvent): void => {
        const currentX = "touches" in evt ? evt.touches[0]?.clientX : evt.clientX;

        if (currentX == null || startX == null) {
          return;
        }

        const difference = currentX - startX;
        const newWidth = Math.max(startWidth + difference, 0);

        throttledResize(newWidth);
      };

      const handleMouseUp = (): void => {
        setIsResizing(null);

        document.body.style.cursor = "";

        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("touchmove", handleMouseMove);
        document.removeEventListener("touchend", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("touchmove", handleMouseMove);
      document.addEventListener("touchend", handleMouseUp);
    },
    [setIsResizing, throttledResize, columnId],
  );

  const handleOpenChange = useCallback((open: boolean): void => {
    setIsOpen(open);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  const maybeRenderResizeHandle = useCallback(() => {
    if (!allowResize) {
      return null;
    }

    return (
      <div
        className={cn(
          "absolute inset-y-0 right-0 z-10 flex w-2 cursor-col-resize items-center justify-end transition-all",
          isHovering || isThisColumnResizing ? "opacity-100" : "opacity-0",
          "group-hover/header:opacity-100",
        )}
        onMouseDown={handleResize}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleResize}
      >
        <div
          className={cn(
            "h-full w-1 transition-all",
            isThisColumnResizing ? "bg-primary w-1" : "bg-border",
            isHovering && !isThisColumnResizing && "bg-muted-foreground",
          )}
        />
      </div>
    );
  }, [allowResize, handleMouseEnter, handleMouseLeave, handleResize, isHovering, isThisColumnResizing]);

  return (
    <div className="group/header relative flex size-full items-center overflow-hidden text-base font-normal">
      <DropdownMenu open={isOpen} onOpenChange={handleOpenChange}>
        <DropdownMenuTrigger className="size-full cursor-pointer">
          <HeaderContent isMenuOpen={isOpen} label={label} leftIcon={leftIcon} rightIcon={rightIcon} />
        </DropdownMenuTrigger>
        {menu?.type === "dropdown" && <HeaderMenu items={menu.items} searchable={menu.searchable} />}
        {menu?.type === "custom" && <DropdownMenuContent className="p-0">{menu.renderer()}</DropdownMenuContent>}
      </DropdownMenu>
      {maybeRenderResizeHandle()}
    </div>
  );
}
