import { ColumnId, EntityTypeVersionId } from "@archetype/ids";
import * as z from "zod";

import type { IEntityColumnValue } from "./EntityValue";
import { EntityColumnValue, IdLikeEntityColumnValue } from "./EntityValue";
import { MinimalEntity } from "./MinimalEntity";

export const RawEntity = MinimalEntity.merge(
  z.object({
    entityTypeVersionId: EntityTypeVersionId,
    /**
     * Primary key converted to a string, we could merge both concepts and not have a primary key as a columnValue here
     */
    primaryKey: IdLikeEntityColumnValue,
    columns: z.record(ColumnId, EntityColumnValue.optional()),
  }),
);
export type IRawEntity = z.infer<typeof RawEntity>;

// Circumvent zod limitation for function arguments
export type IRawEntityWithUntypedIds = Omit<IRawEntity, "entityId" | "entityTypeId" | "columns"> & {
  entityId: string;
  entityTypeId: string;
  columns: {
    [columnId: string]: IEntityColumnValue;
  };
};
