import type { IOrganizationId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { cn, Skeleton } from "@archetype/ui";
import Image from "next/image";

export interface IOrganizationImage {
  orgId: IOrganizationId;
  className?: string;
}

export const OrganizationImage: React.FC<IOrganizationImage> = ({ orgId, className }) => {
  const { data: organizationQuery } = builderTrpc.organization.byOrgId.useQuery({
    orgId: orgId,
  });

  if (organizationQuery === undefined) {
    return <Skeleton className={cn("size-8 rounded-md", className)} />;
  }

  return (
    <div className={cn("bg-paper flex size-8 items-center justify-center overflow-hidden rounded-md", className)}>
      <Image
        alt={organizationQuery.organization.name}
        height={100}
        src={organizationQuery.organization.imageUrl}
        width={100}
      />
    </div>
  );
};
