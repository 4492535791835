import { builderTrpc as trpc } from "@archetype/trpc-react";
import type { Country } from "@archetype/ui";
import { cn, Input, PhoneInput } from "@archetype/ui";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useCallback, useEffect, useState } from "react";

import type { IActionInputComponent } from "../types";

export const ActionPhoneInput: IActionInputComponent = ({ className, field, onChange, readOnly = false }) => {
  const { data: userPreferences } = trpc.users.getUserPreferences.useQuery();
  const { mutateAsync: updateUserPreferences } = trpc.users.upsertUserPreferences.useMutation();

  const handleCountryChange = useCallback(
    async (country: Country): Promise<void> => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
      if (country != null) {
        await updateUserPreferences({ lastSelectedCountry: country });
      }
    },
    [updateUserPreferences],
  );

  const handleChange = useCallback(
    (valueOrEvent: string | React.ChangeEvent<HTMLInputElement>): void => {
      let value: string;

      if (typeof valueOrEvent === "string") {
        value = valueOrEvent;
      } else {
        value = valueOrEvent.target.value;
      }
      if (value !== "") {
        onChange({
          type: "string",
          value: value,
        });
      } else {
        onChange({ type: "null" });
      }
    },
    [onChange],
  );
  //if the value is null, we want to show the international input, otherwise we will set it based on the value format
  const [isInternational, setIsInternational] = useState(true);

  useEffect(() => {
    if (field.value?.type === "string") {
      const parsedNumber = parsePhoneNumberFromString(field.value.value);

      if (parsedNumber && parsedNumber.isValid()) {
        const international = parsedNumber.format("E.164") === field.value.value;

        setIsInternational(international);
      } else {
        setIsInternational(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run on mount
  }, []);
  const handleBlur = field.onBlur;

  return isInternational ? (
    <PhoneInput
      international
      defaultCountry={userPreferences?.preferences?.lastSelectedCountry ?? undefined}
      disabled={readOnly}
      value={field.value?.type === "string" ? field.value.value : undefined}
      onChange={handleChange}
      onCountryChange={handleCountryChange}
    />
  ) : (
    <Input
      ref={field.ref}
      className={cn("w-full", className)}
      defaultValue={field.value?.type === "string" ? field.value.value : undefined}
      disabled={readOnly}
      name={field.name}
      type="string"
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};
