import type {
  IActionCore,
  IColumn,
  INewCoreEntityTypeChange,
  IStateColor,
  IStateMachine,
  IStateMachineState,
} from "@archetype/dsl";
import { ENTITY_TYPE_COLOR_NAMES, ENTITY_TYPE_SHAPE_NAMES, STATE_COLOR_NAMES } from "@archetype/dsl";
import type { IEntityTypeId, IOrganizationId } from "@archetype/ids";
import { ActionId, ApplicationGroupId, EntityTypeId, StateId, TransitionId } from "@archetype/ids";
import type { IReadableString } from "@archetype/utils";
import { isNonNullable, map, mapKeysNoUndefined } from "@archetype/utils";
import { concat, difference, sample, values } from "lodash";

import { createInternalEmployeeAuthorization } from "../auth/createInternalEmployeeAuthorization";
import { createPrimaryKeyColumn, createStatusColumn, createTitleColumn } from "../onboarding/createBaseColumn";
import type { IEditOperation } from "../stateMachine/editOperations/operations";

export function convertCoreEntityTypeCreationToEditOperation({
  change,
  organizationId,
  userEntityTypeId,
}: {
  change: INewCoreEntityTypeChange;
  organizationId: IOrganizationId;
  userEntityTypeId: IEntityTypeId;
}): IEditOperation {
  const entityType = change.coreEntityTypeToCreate;

  const entityTypeId = EntityTypeId.generate();
  const applicationGroupId = ApplicationGroupId.generate();

  const usedStateColors: IStateColor[] = [];

  const states = concat(
    [entityType.stateMachine.initialAction.toState],
    entityType.stateMachine.actions.flatMap((action) => [action.fromState, action.toState]),
  );

  const statesByName: Record<IReadableString, IStateMachineState> = {};

  states.forEach((state) => {
    if (statesByName[state.stateName] == null) {
      const colorsLeft = difference(STATE_COLOR_NAMES, usedStateColors);
      const color = sample(colorsLeft) ?? "neutral";

      statesByName[state.stateName] = {
        id: StateId.generate(),
        color,
        label: state.stateName,
      };
      usedStateColors.push(color);
    }
  });

  const initialActionState = statesByName[entityType.stateMachine.initialAction.toState.stateName];

  if (initialActionState == null) {
    throw new Error(`Initial action state ${entityType.stateMachine.initialAction.toState.stateName} not found`);
  }

  const actionsByName: Record<IReadableString, IActionCore> = {
    [entityType.stateMachine.initialAction.actionName]: {
      id: ActionId.generate(),
      displayMetadata: {
        name: entityType.stateMachine.initialAction.actionName,
      },
      entityTypeId,
      organizationId,
      applicationGroupId,
      actionDefinition: {
        authorizedByAnyOf: [createInternalEmployeeAuthorization({ userEntityTypeId })],
        actionType: "add",
        inputs: [],
        contextualFields: [],
        fromStates: undefined,
        toState: initialActionState.id,
      },
    },
  };

  entityType.stateMachine.actions.forEach((action) => {
    if (actionsByName[action.actionName] == null) {
      const fromState = statesByName[action.fromState.stateName];
      const toState = statesByName[action.toState.stateName];

      if (fromState == null || toState == null) {
        throw new Error(`State ${action.fromState.stateName} or ${action.toState.stateName} not found`);
      }

      actionsByName[action.actionName] = {
        id: ActionId.generate(),
        displayMetadata: {
          name: action.actionName,
        },
        entityTypeId,
        organizationId,
        applicationGroupId,
        actionDefinition: {
          authorizedByAnyOf: [createInternalEmployeeAuthorization({ userEntityTypeId })],
          actionType: "modify",
          inputs: [],
          contextualFields: [],
          fromStates: [fromState.id],
          toState: toState.id,
        },
      };
    }
  });

  const initialState = statesByName[entityType.stateMachine.initialAction.toState.stateName];
  const initialAction = actionsByName[entityType.stateMachine.initialAction.actionName];

  if (initialState == null) {
    throw new Error(`Initial state ${entityType.stateMachine.initialAction.toState.stateName} not found`);
  }

  if (initialAction == null) {
    throw new Error(`Initial action ${entityType.stateMachine.initialAction.actionName} not found`);
  }

  const stateMachine: IStateMachine = {
    states: mapKeysNoUndefined(statesByName, (state) => state.id),
    initialState: {
      state: initialState.id,
      actionId: initialAction.id,
    },
    stateTransitions: entityType.stateMachine.actions.map((action) => {
      const fromState = statesByName[action.fromState.stateName];
      const toState = statesByName[action.toState.stateName];
      const createdAction = actionsByName[action.actionName];

      if (fromState == null || toState == null) {
        throw new Error(`State ${action.fromState.stateName} or ${action.toState.stateName} not found`);
      }

      if (createdAction == null) {
        throw new Error(`Action ${action.actionName} not found`);
      }

      return {
        id: TransitionId.generate(),
        from: fromState.id,
        to: toState.id,
        actionId: createdAction.id,
      };
    }),
    aiAgents: {},
    happyPath: entityType.happyPathStateNames.map((stateName) => statesByName[stateName]?.id).filter(isNonNullable),
    dataModel: {
      targetEntityTypeId: entityTypeId,
    },
  };
  const pkColumn: IColumn = createPrimaryKeyColumn();
  const titleColumn: IColumn = createTitleColumn(entityType.nameOfTitleColumn);
  const statusColumn: IColumn = createStatusColumn(
    map(statesByName, (state) => ({ id: state.id, readableValue: state.label })),
  );

  return {
    type: "createNewProcessEntityType",
    entityType: {
      id: entityTypeId,
      displayMetadata: {
        name: entityType.name,
        pluralName: entityType.pluralName,
        description: entityType.description,
      },
      primaryKey: pkColumn.id,
      displayNameColumn: titleColumn.id,
      statusColumn: statusColumn.id,
      columns: [pkColumn, titleColumn, statusColumn],
      relevantViewFieldsByStateId: {},
      targetEntityTypeApplicationGroupId: applicationGroupId,
      supportActionsInfo: null,
      userEntityTypeInfo: null,
      organizationId,
      color: sample(ENTITY_TYPE_COLOR_NAMES) ?? "neutral",
      shape: sample(ENTITY_TYPE_SHAPE_NAMES) ?? "triangle",
      authorizedByAnyOf: [createInternalEmployeeAuthorization({ userEntityTypeId })],
      authorizedByAnyOfPerStateId: {},
      activityLogAuthorizedByAnyOf: [createInternalEmployeeAuthorization({ userEntityTypeId })],
    },
    stateMachine: stateMachine,
    actions: values(actionsByName),
    applicationGroupId,
  };
}
