import type { IActionCore, IAuthorization, IColumn, IEntityTypeCore } from "@archetype/dsl";
import {
  createColumnViewField,
  getUserInviteActionDefinition,
  isDerivedColumn,
  NON_USER_EDITABLE_USER_COLUMNS,
} from "@archetype/dsl";
import type { IActionId, IApplicationGroupId, IEntityTypeId, IOrganizationId, IViewFieldId } from "@archetype/ids";
import { ActionId } from "@archetype/ids";
import type { IReadableString } from "@archetype/utils";
import { isNonNullable } from "@archetype/utils";

import { DATA_MODEL_OPERATIONS, getDisabledOperationsByViewFieldId } from "./editOperations/disabledOperations";

interface ISupportActionEditInfo {
  actionId: IActionId;
  action: IActionCore | undefined;
}

// supportActionsInfo is already nullable
type IEntityTypeWithNullableActions = Omit<IEntityTypeCore, "deleteActionId"> & {
  deleteActionId: IActionId | null;
};

/**
 * If the support actionIds are defined on the entity type, load in the current actions and apply modifications.
 *     If the actions are not present in the current actions, skip (probably means the edit should not yield edits to the support actions).
 * Otherwise, generate new actions.
 */
export const buildActionsFromSupportEntityType = (
  entityType: IEntityTypeWithNullableActions,
  currentActions: Record<IActionId, IActionCore>,
): {
  supportActionIds: NonNullable<IEntityTypeCore["supportActionsInfo"]>;
  deleteActionId: IActionId;
  newActions: Record<IActionId, IActionCore>;
} => {
  if (entityType.targetEntityTypeApplicationGroupId != null || entityType.statusColumn != null) {
    throw new Error(`Entity type ${entityType.id} ${entityType.displayMetadata.name} cannot have support actions`);
  }

  const nonEditableColumnIds = [entityType.primaryKey, entityType.displayNameColumn];

  if (entityType.userEntityTypeInfo != null) {
    nonEditableColumnIds.push(...NON_USER_EDITABLE_USER_COLUMNS);
  }

  const maybeInviteAction = maybeMakeInviteAction(entityType, currentActions);

  const nonEditableColumnIdsSet = new Set(nonEditableColumnIds);

  const otherEditableColumns = entityType.columns.filter(
    (column) => !nonEditableColumnIdsSet.has(column.id) && !isDerivedColumn(column),
  );

  const disabledOperations = getDisabledOperationsByViewFieldId(entityType);

  const createActionInfo = editMakeOrSkipCreateAction({
    entityType,
    currentActions,
    disabledOperations,
    otherEditableColumns,
  });

  const updateActionInfo = editMakeOrSkipUpdateAction({
    entityType,
    currentActions,
    disabledOperations,
    otherEditableColumns,
  });

  const deleteActionInfo = editMakeOrSkipDeleteAction({
    entityType,
    currentActions,
  });

  const newActions: Record<IActionId, IActionCore> = {};

  if (createActionInfo.action != null) {
    newActions[createActionInfo.actionId] = createActionInfo.action;
  }

  if (updateActionInfo.action != null) {
    newActions[updateActionInfo.actionId] = updateActionInfo.action;
  }

  if (deleteActionInfo.action != null) {
    newActions[deleteActionInfo.actionId] = deleteActionInfo.action;
  }

  if (maybeInviteAction != null && maybeInviteAction.action != null) {
    newActions[maybeInviteAction.actionId] = maybeInviteAction.action;
  }

  return {
    supportActionIds: {
      create: createActionInfo.actionId,
      update: updateActionInfo.actionId,
      otherActions: {
        invite: maybeInviteAction?.actionId ?? null,
      },
    },
    deleteActionId: deleteActionInfo.actionId,
    newActions,
  };
};

const maybeMakeInviteAction = (
  entityType: IEntityTypeWithNullableActions,
  currentActions: Record<IActionId, IActionCore>,
): ISupportActionEditInfo | undefined => {
  if (entityType.userEntityTypeInfo == null) {
    return undefined;
  }

  const existingInviteActionId = entityType.supportActionsInfo?.otherActions.invite;
  const existingInviteAction = existingInviteActionId != null ? currentActions[existingInviteActionId] : null;

  if (existingInviteActionId != null && existingInviteAction == null) {
    return {
      actionId: existingInviteActionId,
      // skip editing
      action: undefined,
    };
  }

  const inviteActionId = existingInviteActionId ?? ActionId.generate();

  const inviteAction: IActionCore = {
    id: inviteActionId,
    displayMetadata: {
      name: "Invite" as IReadableString,
    },
    entityTypeId: entityType.id,
    actionDefinition: {
      ...getUserInviteActionDefinition(),
      authorizedByAnyOf: existingInviteAction?.actionDefinition.authorizedByAnyOf ?? [], // default to no auth
    },
    organizationId: entityType.organizationId,
    applicationGroupId: null,
  };

  return {
    actionId: inviteActionId,
    action: inviteAction,
  };
};

const editMakeOrSkipCreateAction = ({
  entityType,
  currentActions,
  disabledOperations,
  otherEditableColumns,
}: {
  entityType: IEntityTypeWithNullableActions;
  currentActions: Record<IActionId, IActionCore>;
  disabledOperations: Record<IViewFieldId, Set<DATA_MODEL_OPERATIONS>>;
  otherEditableColumns: IColumn[];
}): ISupportActionEditInfo => {
  const existingCreateActionId = entityType.supportActionsInfo?.create;
  const existingCreateAction = existingCreateActionId != null ? currentActions[existingCreateActionId] : null;

  if (existingCreateActionId != null && existingCreateAction == null) {
    // skip editing
    return {
      actionId: existingCreateActionId,
      // skip editing
      action: undefined,
    };
  }

  const createActionId = existingCreateActionId ?? ActionId.generate();

  const createAction: IActionCore = {
    id: createActionId,
    displayMetadata: {
      name: ("Create " + entityType.displayMetadata.name) as IReadableString,
    },
    entityTypeId: entityType.id,
    organizationId: entityType.organizationId,
    applicationGroupId: null,
    actionDefinition: {
      actionType: "add",
      fromStates: undefined,
      toState: undefined,
      inputs: [
        {
          viewField: createColumnViewField(entityType.primaryKey),
          defaultValue: {
            type: "computed",
            value: "randomUUID",
          },
          allowChangingDefault: false,
          required: true,
        },
        {
          viewField: createColumnViewField(entityType.displayNameColumn),
          defaultValue: undefined,
          allowChangingDefault: true,
          required: true,
        },
        ...otherEditableColumns
          .map((column) => {
            const viewField = createColumnViewField(column.id);

            if (disabledOperations[viewField.id]?.has(DATA_MODEL_OPERATIONS.addToForm) === true) {
              return;
            }

            return {
              viewField,
              allowChangingDefault: true,
              defaultValue: undefined,
              required: column.nonNullable,
            };
          })
          .filter(isNonNullable),
      ],
      contextualFields: [],
      authorizedByAnyOf: existingCreateAction?.actionDefinition.authorizedByAnyOf ?? entityType.authorizedByAnyOf,
      // need to define how we permission those actions, for now all allowed?
      sideEffects: {
        email: {
          isEnabled: false,
          toPersonRelation: null,
          viewFieldsToSend: [],
        },
      },
      authorizedForAnyoneWithLink: false,
    },
  };

  return {
    actionId: createActionId,
    action: createAction,
  };
};

const editMakeOrSkipUpdateAction = ({
  entityType,
  currentActions,
  disabledOperations,
  otherEditableColumns,
}: {
  entityType: IEntityTypeWithNullableActions;
  currentActions: Record<IActionId, IActionCore>;
  disabledOperations: Record<IViewFieldId, Set<DATA_MODEL_OPERATIONS>>;
  otherEditableColumns: IColumn[];
}): ISupportActionEditInfo => {
  const existingUpdateActionId = entityType.supportActionsInfo?.update;
  const existingUpdateAction = existingUpdateActionId != null ? currentActions[existingUpdateActionId] : null;

  if (existingUpdateActionId != null && existingUpdateAction == null) {
    // skip editing
    return {
      actionId: existingUpdateActionId,
      // skip editing
      action: undefined,
    };
  }

  const updateActionId = existingUpdateActionId ?? ActionId.generate();

  const updateAction: IActionCore = {
    id: updateActionId,
    displayMetadata: {
      name: "Modify" as IReadableString,
    },
    entityTypeId: entityType.id,
    organizationId: entityType.organizationId,
    applicationGroupId: null,
    actionDefinition: {
      actionType: "modify",
      fromStates: undefined,
      toState: undefined,
      inputs: [
        {
          viewField: createColumnViewField(entityType.displayNameColumn),
          defaultValue: undefined,
          allowChangingDefault: true,
          required: false,
        },
        ...otherEditableColumns
          .map((column) => {
            const viewField = createColumnViewField(column.id);

            if (disabledOperations[viewField.id]?.has(DATA_MODEL_OPERATIONS.addToForm) === true) {
              return;
            }

            return {
              viewField,
              allowChangingDefault: true,
              defaultValue: undefined,
              required: false,
            };
          })
          .filter(isNonNullable),
      ],
      contextualFields: [],
      authorizedByAnyOf: existingUpdateAction?.actionDefinition.authorizedByAnyOf ?? entityType.authorizedByAnyOf,
      // need to define how we permission those actions, for now all allowed?
      sideEffects: {
        email: {
          isEnabled: false,
          toPersonRelation: null,
          viewFieldsToSend: [],
        },
      },
      authorizedForAnyoneWithLink: false,
    },
  };

  return {
    actionId: updateActionId,
    action: updateAction,
  };
};

const editMakeOrSkipDeleteAction = ({
  entityType,
  currentActions,
}: {
  entityType: IEntityTypeWithNullableActions;
  currentActions: Record<IActionId, IActionCore>;
}): ISupportActionEditInfo => {
  const existingDeleteActionId = entityType.deleteActionId;
  const existingDeleteAction = existingDeleteActionId != null ? currentActions[existingDeleteActionId] : null;

  if (existingDeleteActionId != null && existingDeleteAction == null) {
    // skip editing
    return {
      actionId: existingDeleteActionId,
      // skip editing
      action: undefined,
    };
  }

  const deleteActionId = existingDeleteActionId ?? ActionId.generate();

  const deleteAction = makeDefaultDeleteAction({
    deleteActionId,
    entityTypeId: entityType.id,
    organizationId: entityType.organizationId,
    applicationGroupId: null,
    authorizedByAnyOf: existingDeleteAction?.actionDefinition.authorizedByAnyOf ?? [],
  });

  return {
    actionId: deleteActionId,
    action: deleteAction,
  };
};

export const makeDefaultDeleteAction = ({
  deleteActionId,
  entityTypeId,
  organizationId,
  applicationGroupId,
  authorizedByAnyOf,
}: {
  deleteActionId: IActionId;
  entityTypeId: IEntityTypeId;
  organizationId: IOrganizationId;
  applicationGroupId: IApplicationGroupId | null;
  authorizedByAnyOf: IAuthorization[];
}): IActionCore => {
  const deleteAction: IActionCore = {
    id: deleteActionId,
    displayMetadata: {
      name: "Delete" as IReadableString,
    },
    entityTypeId,
    organizationId,
    applicationGroupId,
    actionDefinition: {
      actionType: "delete",
      fromStates: undefined,
      toState: undefined,
      inputs: [],
      contextualFields: [],
      authorizedByAnyOf,
      sideEffects: {
        email: {
          isEnabled: false,
          toPersonRelation: null,
          viewFieldsToSend: [],
        },
      },
      authorizedForAnyoneWithLink: false,
    },
  };

  return deleteAction;
};
