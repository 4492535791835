import { useCallback, useEffect } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableEditingContext } from "../context/DataTableEditingContext";

export function useCellExitOnEsc({
  inputRef,
  isCellSelected,
  isCellEditing,
}: {
  inputRef?: React.RefObject<HTMLElement>;
  isCellSelected: boolean;
  isCellEditing: boolean;
}): void {
  const setEditableCell = useContextSelector(DataTableEditingContext, (state) => state.setEditableCell);

  const handleExitEditing = useCallback(() => {
    if (isCellSelected && isCellEditing) {
      setEditableCell(null);

      inputRef?.current?.blur();
    }
  }, [isCellSelected, isCellEditing, setEditableCell, inputRef]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape" && isCellSelected && isCellEditing) {
        e.preventDefault();
        e.stopPropagation();
        handleExitEditing();
      }
    },
    [isCellSelected, isCellEditing, handleExitEditing],
  );

  useEffect(() => {
    if (isCellSelected && isCellEditing) {
      document.addEventListener("keydown", handleKeyDown);

      return (): void => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isCellSelected, isCellEditing, handleKeyDown]);
}
