import type { IEntityTypeCore, IViewField } from "@archetype/dsl";
import { computeColumnViewFieldId } from "@archetype/dsl";

export function sortFields<T extends IViewField>(
  fields: T[],
  entityType: Pick<IEntityTypeCore, "primaryKey" | "statusColumn" | "displayNameColumn">,
): T[] {
  return fields.sort((a, b) => {
    const aIsPrimaryKey = a.id === computeColumnViewFieldId(entityType.primaryKey);
    const bIsPrimaryKey = b.id === computeColumnViewFieldId(entityType.primaryKey);

    if (aIsPrimaryKey && !bIsPrimaryKey) return -1;
    if (!aIsPrimaryKey && bIsPrimaryKey) return 1;

    if (entityType.statusColumn != null) {
      const aIsStatusColumn = a.id === computeColumnViewFieldId(entityType.statusColumn);
      const bIsStatusColumn = b.id === computeColumnViewFieldId(entityType.statusColumn);

      if (aIsStatusColumn && !bIsStatusColumn) return -1;
      if (!aIsStatusColumn && bIsStatusColumn) return 1;
    }

    const aIsDisplayColumn = a.id === computeColumnViewFieldId(entityType.displayNameColumn);
    const bIsDisplayColumn = b.id === computeColumnViewFieldId(entityType.displayNameColumn);

    if (aIsDisplayColumn && !bIsDisplayColumn) return -1;
    if (!aIsDisplayColumn && bIsDisplayColumn) return 1;

    return 0;
  });
}
