import { FeatureId } from "@archetype/ids";
import { z } from "zod";

export const IdentifiableFeature = z.object({
  /**
   * uuid to identify the feature and potentially have nesting and references
   */
  id: FeatureId,
});
export type IIdentifiableFeature = z.infer<typeof IdentifiableFeature>;
