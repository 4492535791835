import { z } from "zod";

export const PrimitiveStringColumn = z.object({ type: z.string(), primitiveType: z.literal("string") }).passthrough();
export type IPrimitiveStringColumn = z.infer<typeof PrimitiveStringColumn>;
export const PrimitiveNumberColumn = z.object({ type: z.string(), primitiveType: z.literal("number") }).passthrough();
export type IPrimitiveNumberColumn = z.infer<typeof PrimitiveNumberColumn>;
export const PrimitiveBooleanColumn = z.object({ type: z.string(), primitiveType: z.literal("boolean") }).passthrough();
export type IPrimitiveBooleanColumn = z.infer<typeof PrimitiveBooleanColumn>;

/**
 * Used in places where we want to accept a primitive type, but don't care which one.
 */
export const PrimitiveAnyColumn = z.union([PrimitiveStringColumn, PrimitiveNumberColumn, PrimitiveBooleanColumn]);
export type IPrimitiveAnyColumn = z.infer<typeof PrimitiveAnyColumn>;
export type IColumnPrimitiveType = IPrimitiveAnyColumn["primitiveType"];
