import type { IModuleInstance, IModuleInstanceMetadata } from "@archetype/dsl";
import {
  ApplicationId,
  FeatureTransactionId,
  LayoutConfigurationId,
  MetricCardComponentDefinitionId,
  ModuleInstanceId,
  ModuleInstanceMetadataId,
  ModuleInstanceMetadataVersionId,
  ModuleInstanceVersionId,
  SearchableListLayoutId,
  SearchableListWithMetricsLayoutId,
  SearchInputComponentDefinitionId,
  SlotId,
  StateMachineMetadataId,
  StateMachineMetadataVersionId,
  TableComponentDefinitionId,
} from "@archetype/ids";

import { dataViewFeature, metricFeature, MOCK_ENTITY_TYPE, searchFeature } from "./mockFeatures";

export const searchableListModuleInstanceMetadata: IModuleInstanceMetadata = {
  id: ModuleInstanceMetadataId.parse("273d97a7-f529-49c1-bd40-d417dd0146d4"),
  versionId: ModuleInstanceMetadataVersionId.parse("273d97a7-f529-49c1-bd40-d417dd0146d4"),
  basedOn: null,
  computedFrom: {
    id: StateMachineMetadataId.parse("725dc3cc-4511-46d6-9a58-e0d82c4e8c45"),
    versionId: StateMachineMetadataVersionId.parse("46cb195f-e1cb-419e-8053-b87386514225"),
  },
  originVersionId: null,
  applicationId: ApplicationId.parse("f6cf6fbc-f614-4c3d-a398-9943dabcfa2f"),
  documentation: {
    type: "documentationV1",
    strDocumentation: "This is an example of documentation",
  },
  updatedAt: new Date(),
  version: {
    major: 0,
    minor: 1,
    patch: 0,
  },
  name: "Example of a searchable list module instance",
  featureTransaction: {
    id: FeatureTransactionId.parse("f96fbdd7-2e8a-49d3-9130-499579ac2cbf"),
    input: "A searchable table of users",
    output: {
      features: [dataViewFeature, searchFeature],
      summary: "A table of users that is searchable by name",
      response: "A table of users that is searchable by name",
    },
  },
};

export const searchableListModuleInstance: IModuleInstance = {
  id: ModuleInstanceId.parse("646fcf3e-f216-4efd-be04-5dbbefa8ee98"),
  versionId: ModuleInstanceVersionId.parse("42c85d50-c7c2-43e0-b7a0-9e1cab1f3b4a"),
  computedFrom: {
    id: searchableListModuleInstanceMetadata.id,
    versionId: searchableListModuleInstanceMetadata.versionId,
  },
  basedOn: null,
  originVersionId: null,
  updatedAt: new Date(),
  version: {
    major: 0,
    minor: 1,
    patch: 0,
  },
  layoutId: SearchableListLayoutId,
  inputRuntimeParameterConfig: {},
  layoutConfiguration: {
    [LayoutConfigurationId.parse("mainEntityType")]: {
      type: "entityType",
      entityTypeId: MOCK_ENTITY_TYPE.id,
    },
  },
  slots: {
    [SlotId.parse("filteringActionBar")]: {
      componentDefinitionId: SearchInputComponentDefinitionId,
      functionalConfig: {
        columnsToSearch: {
          type: "entityColumnList",
          entityTypeId: MOCK_ENTITY_TYPE.id,
          entityColumnIds: [MOCK_ENTITY_TYPE.firstColumnId],
        },
      },
      cosmeticConfig: {},
    },
    [SlotId.parse("main")]: {
      componentDefinitionId: TableComponentDefinitionId,
      functionalConfig: {
        columnsToDisplay: {
          type: "entityColumnList",
          entityTypeId: MOCK_ENTITY_TYPE.id,
          entityColumnIds: [MOCK_ENTITY_TYPE.firstColumnId, MOCK_ENTITY_TYPE.secondColumnId],
        },
        inlineActions: [],
        entityRelations: [],
      },
      cosmeticConfig: {},
    },
  },
};

export const searchableListWithMetricsModuleInstanceMetadata: IModuleInstanceMetadata = {
  id: ModuleInstanceMetadataId.parse("d875077a-3b29-413a-a439-6c515be2f0c4"),
  versionId: ModuleInstanceMetadataVersionId.parse("d875077a-3b29-413a-a439-6c515be2f0c4"),
  basedOn: null,
  computedFrom: {
    id: StateMachineMetadataId.parse("725dc3cc-4511-46d6-9a58-e0d82c4e8c45"),
    versionId: StateMachineMetadataVersionId.parse("46cb195f-e1cb-419e-8053-b87386514225"),
  },
  originVersionId: null,
  applicationId: ApplicationId.parse("35264bc9-4c9c-4f62-8ba7-0dbb56f09d6e"),
  documentation: {
    type: "documentationV1",
    strDocumentation: "This is an example of documentation",
  },
  updatedAt: new Date(),
  version: {
    major: 0,
    minor: 1,
    patch: 0,
  },
  name: "Example of a searchable list with metrics module instance",
  featureTransaction: {
    id: FeatureTransactionId.parse("f96fbdd7-2e8a-49d3-9130-499579ac2cbf"),
    input: "A searchable table of users, with a metric for the trailing 30 day average of orders",
    output: {
      features: [dataViewFeature, searchFeature, metricFeature],
      summary: "A table of users that is searchable by name, with a metric for the trailing 30 day average of orders",
      response: "A table of users that is searchable by name, with a metric for the trailing 30 day average of orders",
    },
  },
};

export const searchableListWithMetricsModuleInstance: IModuleInstance = {
  id: ModuleInstanceId.parse("39240e2f-930e-4cf9-a014-e92cff5e8165"),
  versionId: ModuleInstanceVersionId.parse("752ec23c-11dc-4b62-8758-c23ea857e78a"),
  layoutId: SearchableListWithMetricsLayoutId,
  computedFrom: {
    id: searchableListWithMetricsModuleInstanceMetadata.id,
    versionId: searchableListWithMetricsModuleInstanceMetadata.versionId,
  },
  basedOn: null,
  originVersionId: null,
  updatedAt: new Date(),
  version: {
    major: 0,
    minor: 1,
    patch: 0,
  },
  inputRuntimeParameterConfig: {},
  layoutConfiguration: {
    [LayoutConfigurationId.parse("mainEntityType")]: {
      type: "entityType",
      entityTypeId: MOCK_ENTITY_TYPE.id,
    },
  },
  slots: {
    [SlotId.parse("summary")]: {
      componentDefinitionId: MetricCardComponentDefinitionId,
      functionalConfig: {},
      cosmeticConfig: {},
    },
    [SlotId.parse("filteringActionBar")]: {
      componentDefinitionId: SearchInputComponentDefinitionId,
      functionalConfig: {
        columnsToSearch: {
          type: "entityColumnList",
          entityTypeId: MOCK_ENTITY_TYPE.id,
          entityColumnIds: [MOCK_ENTITY_TYPE.firstColumnId],
        },
      },
      cosmeticConfig: {},
    },
    [SlotId.parse("main")]: {
      componentDefinitionId: TableComponentDefinitionId,
      functionalConfig: {
        columnsToDisplay: {
          type: "entityColumnList",
          entityTypeId: MOCK_ENTITY_TYPE.id,
          entityColumnIds: [MOCK_ENTITY_TYPE.firstColumnId],
        },
        inlineActions: [],
        entityRelations: [],
      },
      cosmeticConfig: {},
    },
  },
};
