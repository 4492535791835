import { cn, Switch } from "@archetype/ui";
import { useCallback, useMemo } from "react";
import React from "react";

import type { IColumnFilterComponent } from "../types";

export const BooleanFilterInput: IColumnFilterComponent = ({ className, value, onChange }) => {
  const handleChange = useCallback(
    (checked: boolean) => {
      onChange({
        eq: [{ type: "value", value: { type: "boolean", value: checked } }],
      });
    },
    [onChange],
  );

  const currentValue = useMemo(() => {
    const currentWrappedValue = value?.eq?.[0];

    if (currentWrappedValue?.type === "value" && currentWrappedValue.value.type === "boolean") {
      return currentWrappedValue.value.value;
    }

    return undefined;
  }, [value]);

  // TODO filters - add neutral state to Switch component
  return (
    <Switch small checked={currentValue ?? false} className={cn("block", className)} onCheckedChange={handleChange} />
  );
};
