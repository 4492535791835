import type { IEntityTypeCore } from "@archetype/dsl";
import { builderTrpc } from "@archetype/trpc-react";
import { useToast } from "@archetype/ui";
import { useCallback } from "react";

import { useFiltersFromURL } from "./useFiltersFromURL";

const genericFailureReason = "Failed to generate AI filter";

export type IAddAIFiltersFn = (description: string) => void;

export const useAddAIFilters = (entityType: IEntityTypeCore): { addAIFilters: IAddAIFiltersFn; isLoading: boolean } => {
  const { toast } = useToast();

  const [filters, setFilters] = useFiltersFromURL();

  const { mutateAsync: generateAIFilter, isPending: isLoading } =
    builderTrpc.dataLoading.generateAIFilter.useMutation();

  const applyAIFilters = useCallback(
    ({ output }: Awaited<ReturnType<typeof generateAIFilter>>) => {
      if (output.type === "error") {
        toast({ title: "Error", variant: "error", description: output.error ?? genericFailureReason });

        return;
      }

      let newFilters = filters;

      for (const { columnId, operator, value } of output.filters) {
        const existingFilter = newFilters.find((filter) => filter.type === "column" && filter.colId === columnId);

        if (existingFilter) {
          newFilters = newFilters.map((filter) =>
            filter.type === "column" && filter.colId === columnId ? { ...filter, operator, value } : filter,
          );
        } else {
          newFilters = [...newFilters, { type: "column", colId: columnId, op: operator, value }];
        }
      }

      setFilters(newFilters);
    },
    [filters, setFilters, toast],
  );

  const addAIFilters = useCallback(
    (filterDescription: string) => {
      generateAIFilter({
        entityTypeId: entityType.id,
        organizationId: entityType.organizationId,
        currentTimestamp: new Date().toISOString(),
        filterDescription,
      })
        .then(applyAIFilters)
        .catch(() => toast({ title: "Error", variant: "error", description: genericFailureReason }));
    },
    [applyAIFilters, entityType.id, entityType.organizationId, generateAIFilter, toast],
  );

  return { addAIFilters, isLoading };
};
