import React from "react";

import { GroupTableSkeleton } from "../../atoms/grouped-table-skeleton";
import { ScrollArea } from "../../atoms/scroll-area/scroll-area";
import { ScrollAreaProvider } from "../../atoms/scroll-area/scroll-area-provider";
import { SegmentedProgressSkeleton } from "../../atoms/segmented-progress";
import { Skeleton } from "../../atoms/skeleton";

export const DataViewAppLayoutSkeleton: React.FC = () => {
  return (
    <div className="flex size-full flex-col">
      <div className="flex h-0 shrink-0 grow flex-col">
        <div className="border-border flex w-full flex-row items-center justify-between border-b p-2">
          <div className="flex flex-row items-center gap-x-2">
            <div className="flex items-center space-x-2">
              <Skeleton className="h-4 w-24" />
            </div>
          </div>

          <div className="flex flex-row items-center gap-x-2">
            <Skeleton className="h-8 w-24" />
          </div>
        </div>
        <ScrollAreaProvider direction="vertical">
          <ScrollArea>
            <div className="flex flex-col space-y-6 p-6">
              {/* Metrics card */}
              <SegmentedProgressSkeleton />
              {/* Main view skeleton */}
              <GroupTableSkeleton />
            </div>
          </ScrollArea>
        </ScrollAreaProvider>
      </div>
    </div>
  );
};
