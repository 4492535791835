import type { IColumn, IRelationCore } from "@archetype/dsl";
import type { IEntityTypeId, IStateId } from "@archetype/ids";
import { ColumnId, RelationId } from "@archetype/ids";
import type { IReadableString } from "@archetype/utils";
import { humanize } from "@archetype/utils";

export function createTitleColumn(columnName: IReadableString): IColumn {
  const titleColumnName = humanize(columnName);

  return {
    id: ColumnId.generate(),
    displayMetadata: {
      name: titleColumnName === "id" ? ("Title" as IReadableString) : titleColumnName,
    },
    columnType: { type: "shortText" },
    nonNullable: true,
    unique: false,
  };
}

export function createStatusColumn(allowedValues: { id: IStateId; readableValue: IReadableString }[]): IColumn {
  return {
    id: ColumnId.generate(),
    displayMetadata: {
      name: "Status" as IReadableString,
    },
    columnType: { type: "statusEnum", allowedValues, archivedValues: [] },
    nonNullable: true,
    unique: false,
  };
}

export function createPrimaryKeyColumn(): IColumn {
  return {
    id: ColumnId.generate(),
    displayMetadata: {
      name: "ID" as IReadableString,
    },
    columnType: { type: "shortText" },
    nonNullable: true,
    unique: true,
    autofill: {
      type: "snapshot",
      config: { type: "dynamic", value: "randomUUID" },
    },
  };
}

export function createCreatedAtColumn(): IColumn {
  return {
    id: ColumnId.generate(),
    displayMetadata: {
      name: "Created At" as IReadableString,
    },
    columnType: { type: "timestamp" },
    nonNullable: true,
    unique: false,
    autofill: {
      type: "snapshot",
      config: { type: "dynamic", value: "now" },
    },
  };
}

export function createCreatedByRelation({
  entityTypeId,
  userEntityTypeId,
  userEntityTypePluralTitle,
  nameOfCreatedByRelation,
}: {
  entityTypeId: IEntityTypeId;
  userEntityTypeId: IEntityTypeId;
  userEntityTypePluralTitle: IReadableString;
  nameOfCreatedByRelation?: IReadableString;
}): IRelationCore {
  const humanizedNameOfCreatedByRelation = humanize(nameOfCreatedByRelation ?? "Created By");

  return {
    id: RelationId.generate(),
    displayMetadataFromAToB: {
      name:
        humanizedNameOfCreatedByRelation === "" ? ("Created By" as IReadableString) : humanizedNameOfCreatedByRelation,
    },
    displayMetadataFromBToA: {
      name: `Created ${humanize(userEntityTypePluralTitle)}` as IReadableString,
    },
    entityTypeIdA: entityTypeId,
    entityTypeIdB: userEntityTypeId,
    config: {
      cardinalityOnSideA: "many",
      cardinalityOnSideB: "one",
    },
    autofill: {
      direction: "aToB",
      autofill: {
        type: "snapshot",
        config: { type: "dynamic", value: "currentUser" },
      },
    },
  };
}
