import { forEach } from "@archetype/utils";
import { clone } from "lodash";

import type { INestedActionErrors } from "../apiTypes/ActionExecution";

export const mergeNestedActionErrors = (
  nestedActionErrorsA: INestedActionErrors,
  nestedActionErrorsB: INestedActionErrors,
): INestedActionErrors => {
  const result: INestedActionErrors = {};

  // First, copy all entity types and their errors from nestedActionErrorsA
  forEach(nestedActionErrorsA, (errorsForEntityType, entityTypeId) => {
    result[entityTypeId] = {};

    forEach(errorsForEntityType ?? {}, (errorsForEntity, entityId) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- initialized above
      result[entityTypeId]![entityId] = clone(errorsForEntity);
    });
  });

  // Then, merge in errors from nestedActionErrorsB
  forEach(nestedActionErrorsB, (errorsForEntityType, entityTypeId) => {
    if (result[entityTypeId] == null) {
      result[entityTypeId] = {};
    }

    forEach(errorsForEntityType ?? {}, (errorsForEntity, entityId) => {
      if (result[entityTypeId]?.[entityId] == null) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- initialized above
        result[entityTypeId]![entityId] = [];
      }

      // Append the validation errors from B to the existing array
      errorsForEntity?.forEach((error) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- initialized above
        result[entityTypeId]![entityId]!.push(error);
      });
    });
  });

  return result;
};
