// Note: this is a wrapper around all icons from lucide-react
// so we can enforce certain standards on all icons (eg stroke width)
// See https://lucide.dev/guide/packages/lucide-react#nextjs-example

// Ignore eslint errors here because we need to import to wrap the icons

import { mapValues } from "@archetype/utils";
// eslint-disable-next-line no-restricted-imports -- in design system
import type { LucideProps } from "lucide-react";
// eslint-disable-next-line no-restricted-imports -- in design system
import dynamicIconImports from "lucide-react/dynamicIconImports";
import dynamic from "next/dynamic";
import React from "react";

import { cn } from "../../lib/utils";

const sparkles: React.FC<LucideProps> = ({ className, ...props }) => (
  <svg
    fill="currentColor"
    height="24"
    stroke="currentColor"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={className}
  >
    <path
      d="M8.50139066,18.5146368 L4.8646173,20.6117346 C4.57755308,20.7772664 4.2106515,20.6787449 4.04511968,20.3916807 C3.93815397,20.2061815 3.93815397,19.9777362 4.04511968,19.7922369 L6.14221744,16.1554636 C6.67704596,15.2279674 6.67704596,14.0857411 6.14221744,13.1582449 L4.04511968,9.52147155 C3.87958785,9.23440733 3.97810932,8.86750575 4.26517354,8.70197393 C4.45067278,8.59500822 4.67911806,8.59500822 4.8646173,8.70197393 L8.50139066,10.7990717 C9.42888681,11.3339002 10.5711132,11.3339002 11.4986093,10.7990717 L15.1353827,8.70197393 C15.4224469,8.5364421 15.7893485,8.63496357 15.9548803,8.92202779 C16.061846,9.10752703 16.061846,9.33597231 15.9548803,9.52147155 L13.8577826,13.1582449 C13.322954,14.0857411 13.322954,15.2279674 13.8577826,16.1554636 L15.9548803,19.7922369 C16.1204121,20.0793012 16.0218907,20.4462027 15.7348265,20.6117346 C15.5493272,20.7187003 15.3208819,20.7187003 15.1353827,20.6117346 L11.4986093,18.5146368 C10.5711132,17.9798083 9.42888681,17.9798083 8.50139066,18.5146368 Z"
      transform="translate(10, 14.6569) rotate(45) translate(-10, -14.6569)"
    />
    <path
      d="M17.0020993,8.3745273 L15.0448414,9.501391 C14.8533906,9.61161607 14.6088341,9.54576966 14.498609,9.35431892 C14.4274786,9.23077206 14.4274786,9.07870548 14.498609,8.95515862 L15.6254727,6.99790071 C15.9811245,6.38016644 15.9811245,5.61983356 15.6254727,5.00209929 L14.498609,3.04484138 C14.3883839,2.85339063 14.4542303,2.60883407 14.6456811,2.498609 C14.7692279,2.42747863 14.9212945,2.42747863 15.0448414,2.498609 L17.0020993,3.6254727 C17.6198336,3.98112452 18.3801664,3.98112452 18.9979007,3.6254727 L20.9551586,2.498609 C21.1466094,2.38838393 21.3911659,2.45423034 21.501391,2.64568108 C21.5725214,2.76922794 21.5725214,2.92129452 21.501391,3.04484138 L20.3745273,5.00209929 C20.0188755,5.61983356 20.0188755,6.38016644 20.3745273,6.99790071 L21.501391,8.95515862 C21.6116161,9.14660937 21.5457697,9.39116593 21.3543189,9.501391 C21.2307721,9.57252137 21.0787055,9.57252137 20.9551586,9.501391 L18.9979007,8.3745273 C18.3801664,8.01887548 17.6198336,8.01887548 17.0020993,8.3745273 Z"
      transform="translate(18, 6) rotate(45) translate(-18, -6)"
    />
  </svg>
);

type IIconBase = {
  type: "lucide" | "custom";
};

type ILucideIcon = IIconBase & {
  type: "lucide";
  import: (typeof dynamicIconImports)[keyof typeof dynamicIconImports];
};

type ICustomIcon = IIconBase & {
  type: "custom";
  component: React.FC<LucideProps>;
};

type IIconType = ILucideIcon | ICustomIcon;

export const iconRegistry = {
  ...mapValues(
    dynamicIconImports,
    (importFn): ILucideIcon => ({
      type: "lucide",
      import: importFn,
    }),
  ),

  sparkles: {
    type: "custom",
    component: sparkles,
  },
} as const;

export type IIconNames = keyof typeof iconRegistry;

export interface IIconProps extends Omit<LucideProps, "size"> {
  name: IIconNames;
}

const InternalIcon = ({ name, className, ...props }: IIconProps): React.JSX.Element => {
  const icon = iconRegistry[name] as IIconType;

  if (icon.type === "custom") {
    const CustomIcon = icon.component;

    return <CustomIcon absoluteStrokeWidth={true} className={cn("size-4", className)} strokeWidth={0} {...props} />;
  }

  const LucideIcon = dynamic(icon.import);

  return <LucideIcon absoluteStrokeWidth={true} className={cn("size-4", className)} strokeWidth={2} {...props} />;
};

export const Icon = React.memo(InternalIcon);
