import type { ILoadedAction, ILoadedActionLog, ILoadedEntity } from "@archetype/core";
import { isLoadedRelationViewField } from "@archetype/core";
import type { IVersionType } from "@archetype/dsl";
import { computeColumnViewFieldId, FieldValueParser } from "@archetype/dsl";
import type { IEntityId, IEntityTypeId, IOrganizationId, IStateId, IViewFieldId } from "@archetype/ids";
import { EntityCommentId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import type { IBadgeColorVariant, IComment } from "@archetype/ui";
import {
  CommentInput,
  CommentThread,
  CommentThreadSkeleton,
  CommentToast,
  NonIdealState,
  useMemoDeepCompare,
} from "@archetype/ui";
import type { IReadableString } from "@archetype/utils";
import { generateTitle, isNonNullable, keyByNoUndefined, map, pickBy } from "@archetype/utils";
import Head from "next/head";
import { useCallback, useState } from "react";

import { ActionLogItem } from "../action/ActionLogItem";
import { AiAgentUserName } from "../aiAgent/AiAgentUserName";
import { AiAgentUserProfileImage } from "../aiAgent/AiAgentUserProfileImage";
import type { IGetActionRoute, IGetEntityRoute, IGetHighlightedViewFieldRoute, IGetLinkedEntityRoute } from "../api";
import { LoadingEmailsList } from "../email/LoadingEmailsList";
import { InlineEntityTableSkeleton } from "../entityTable/InlineEntityTableSkeleton";
import { InlineEntityTableWrapper } from "../entityTable/InlineEntityTableWrapper";
import { getConditionalFormattingClasses } from "../entityType/ConditionalFormattingUtils";
import { useLoadedEntity } from "../hooks/entity/useLoadedEntity";
import { useEntityTypeFields } from "../hooks/viewFields/useEntityTypeFields";
import { UserNameById } from "../user/UserNameById";
import { UserProfileImage } from "../user/UserProfileImage";
import { withRefetchInterval } from "../utils/refetchInterval";
import type { IEntityViewSecondaryAction } from "./ActionCard";
import { ActionCard } from "./ActionCard";
import { ActionSavingIndicator } from "./ActionSavingIndicator";
import { EntityFields } from "./EntityFields";
import { EntityViewSkeleton } from "./EntityViewSkeleton";

export interface IEntityViewProps {
  orgId: IOrganizationId;
  versionType: IVersionType;
  entityTypeId: IEntityTypeId;
  entityId: IEntityId;
  getEntityRoute: IGetEntityRoute;
  getActionRoute: IGetActionRoute;
  getFullPageEntityRoute: IGetEntityRoute;
  getFullPageActionRoute: IGetActionRoute;
  getLinkedEntityRoute: IGetLinkedEntityRoute;
  getHighlightedViewFieldRoute?: IGetHighlightedViewFieldRoute;
  onActionExecuted: (entity: ILoadedEntity | undefined) => Promise<void>;
  highlightedViewFieldId?: IViewFieldId;
  showTitle?: boolean;
}

export const EntityView: React.FC<IEntityViewProps> = ({
  orgId,
  versionType,
  entityTypeId,
  entityId,
  getActionRoute,
  getFullPageEntityRoute,
  getLinkedEntityRoute,
  getHighlightedViewFieldRoute,
  getFullPageActionRoute,
  highlightedViewFieldId,
  onActionExecuted: handleActionExecuted,
  showTitle = true,
}) => {
  const [showCommentToast, setShowCommentToast] = useState(false);

  const { entityData: entityQuery, isLoadingEntity } = useLoadedEntity({
    query: { organizationId: orgId, versionType, entityTypeId, entityId },
    options: { ...withRefetchInterval(2000) },
  });

  const { data: entityTypeQuery, isLoading: isLoadingEntityType } = trpc.dataModel.fullyLoadedEntityType.useQuery(
    {
      versionType: versionType,
      id: entityTypeId,
    },
    {
      ...withRefetchInterval(2000),
    },
  );

  const entityType = entityTypeQuery?.entityType;

  const { data: accessToEntityTypeQuery, isLoading: isAccessToEntityTypeLoading } =
    trpc.userAccess.getAccessToEntityType.useQuery({
      entityTypeId,
      versionType,
    });
  const { data: accessToActivityQuery, isLoading: isAccessToActivityLoading } =
    trpc.userAccess.canInteractWithActivity.useQuery({
      entityId,
      entityTypeId,
      versionType,
    });

  const entity = entityQuery?.entity;

  const { data: actionsData, isLoading: isLoadingActions } = trpc.dataModel.getFullyLoadedActionsByEntityType.useQuery({
    versionType,
    entityTypeId: entityTypeId,
  });
  const actionsById = useMemoDeepCompare(() => actionsData?.actions ?? {}, [actionsData?.actions]);

  // Used only for rendering should not be blocking with a loader/skeleton
  const { data: allEntityTypesQuery, isLoading: isLoadingAllEntityTypes } =
    trpc.dataModel.allEntityTypesInOrganization.useQuery({
      versionType,
      organizationId: orgId,
    });

  const {
    data: entityCommentsQuery,
    refetch: refetchComments,
    isLoading: isLoadingComments,
  } = trpc.commentRouter.getCommentsForEntity.useQuery(
    {
      entityTypeId: entityTypeId,
      versionType: versionType,
      entityId,
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  );

  /**
   * for all the view fields, if there are validations on that side of the relation, run it agains the current entity
   * if the validation passes, then we can show the connected create actions or even empty section
   * If not then we only show if there is data (we also hide the connected action? could be confusing)
   * We could also hide when there is no connected action
   */

  // TODO personaId - this could be calling a different endpoint because we now have an entityId so we can check for "executable actions by user"
  const { data: contextColumnsByState, isLoading: isLoadingContextColumns } =
    trpc.dataModel.contextColumnsByState.useQuery({
      versionType,
      entityTypeId: entityTypeId,
    });

  const { data: validRelationFieldsQuery, isLoading: isLoadingValidRelationFields } =
    trpc.dataModel.getValidRelationFieldsForEntity.useQuery({
      versionType,
      entityTypeId: entityTypeId,
      entityId,
    });

  const { data: connectedCreateActionsQuery, isLoading: isLoadingConnectedCreateActions } =
    trpc.dataModel.getExecutableConnectedCreateActionsByViewFieldId.useQuery({
      versionType,
      entityTypeId: entityTypeId,
    });

  const validRelationFieldsById = validRelationFieldsQuery?.validRelationFields;

  const connectedCreateActionsByViewFieldId = useMemoDeepCompare(
    () =>
      pickBy(
        connectedCreateActionsQuery?.connectedCreateActionsByViewFieldId ?? {},
        (_, viewFieldId) => validRelationFieldsById?.[viewFieldId] === true,
      ),
    [connectedCreateActionsQuery?.connectedCreateActionsByViewFieldId, validRelationFieldsById],
  );

  const { data: actionLogsQuery, isLoading: isLoadingActionLogs } = trpc.action.getActionLogsForEntityId.useQuery(
    {
      versionType,
      entityTypeId: entityTypeId,
      entityId,
    },
    { ...withRefetchInterval(1000) },
  );

  const { data: currentEntityActionAccessQuery, isLoading: isLoadingCurrentEntityActions } =
    trpc.action.getAccessToActionsByEntityTypeId.useQuery({
      versionType,
      entityTypeId: entityTypeId,
      entityId,
    });

  const allCurrentEntityActionsAccessById = currentEntityActionAccessQuery?.accessByAction;

  const {
    mutateAsync: addComment,
    isPending: isCommentPending,
    isSuccess: isCommentPosted,
  } = trpc.commentRouter.addComment.useMutation();

  const stateId: IStateId | null = useMemoDeepCompare(() => {
    const statusColumnId = entityType?.statusColumn;

    if (statusColumnId != null) {
      const statusFieldId = computeColumnViewFieldId(statusColumnId);
      const statusValue = entity?.fields[statusFieldId];

      if (statusValue != null) {
        const state = FieldValueParser.toString(statusValue);

        return state == null ? null : (state as IStateId);
      }
    }

    return null;
  }, [entity, entityType?.statusColumn]);

  const statusBadge = useMemoDeepCompare(() => {
    const statusColumnId = entityType?.statusColumn;

    if (stateId == null || statusColumnId == null || entityType?.columns == null) {
      return undefined;
    }

    const statusColumn = entityType.columns[statusColumnId];

    const statusInfo =
      statusColumn?.columnType.type === "statusEnum"
        ? (statusColumn.columnType.allowedValues.find((v) => v.id === stateId) ??
          statusColumn.columnType.archivedValues.find((v) => v.id === stateId))
        : undefined;

    if (statusInfo == null) {
      return undefined;
    }

    const rawColor =
      getConditionalFormattingClasses({
        cell: { type: "string", value: stateId },
        conditionalFormatting: entityType.columns[statusColumnId]?.conditionalFormatting,
      })?.colorName ?? "gray";

    const stateColor: IBadgeColorVariant = rawColor === "neutral" ? "gray" : rawColor;

    return {
      color: stateColor,
      displayName: statusInfo.readableValue,
    };
  }, [entityType?.columns, entityType?.statusColumn, stateId]);

  const { relevantFields, missingRelevantFields, restNonRelevantFields, allFields } = useEntityTypeFields({
    entityType,
    entityTypeId,
    versionType,
    stateId: stateId ?? undefined,
  });

  const allFieldsById = useMemoDeepCompare(() => keyByNoUndefined(allFields, (f) => f.id), [allFields]);

  const allRelationViewFields = useMemoDeepCompare(
    () => relevantFields.concat(missingRelevantFields).concat(restNonRelevantFields).filter(isLoadedRelationViewField),
    [relevantFields, missingRelevantFields, restNonRelevantFields],
  );

  const relationViewFieldNamesById = useMemoDeepCompare(
    () =>
      keyByNoUndefined(
        allRelationViewFields.map((rf) => ({
          id: rf.id,
          displayName: rf.displayName,
        })),
        (rf) => rf.id,
      ),
    [allRelationViewFields],
  );

  const primaryAction: ILoadedAction | undefined = useMemoDeepCompare(() => {
    const defaultTransitionInfo = stateId != null ? contextColumnsByState?.contextFields[stateId] : undefined;

    if (defaultTransitionInfo == null || defaultTransitionInfo.primaryTransition == null) {
      return undefined;
    }

    // Permission check
    if (allCurrentEntityActionsAccessById?.[defaultTransitionInfo.primaryTransition.actionId]?.canExecute !== true) {
      return undefined;
    }

    return actionsById[defaultTransitionInfo.primaryTransition.actionId];
  }, [actionsById, contextColumnsByState, stateId, allCurrentEntityActionsAccessById]);

  const stateSpecificConnectedCreateActions: IEntityViewSecondaryAction[] = useMemoDeepCompare(() => {
    const entityDisplayName = entity?.displayName;

    if (entityDisplayName == null) {
      return [];
    }

    return map(connectedCreateActionsByViewFieldId, (connectedCreateAction, currentViewFieldId) => {
      const connectedCurrentStateIds = connectedCreateAction?.currentStateIds ?? [];

      if (connectedCreateAction == null) {
        return undefined;
      }

      // Obviously only show the ones for the current state id of the entity if there is a constraint
      const hasInvalidStateConstraint =
        stateId != null && connectedCurrentStateIds.length > 0 && !connectedCurrentStateIds.includes(stateId);

      // If there is a stateId, only keep connected actions that are specific to a state, otherwise it will be confusing and show them as a CTA always
      const isProcessEntityAndNoStateConstraint = stateId != null && connectedCurrentStateIds.length === 0;

      if (hasInvalidStateConstraint || isProcessEntityAndNoStateConstraint) {
        return undefined;
      }

      const viewFieldDisplayName = relationViewFieldNamesById[currentViewFieldId]?.displayName;
      let actionName = connectedCreateAction.action.displayMetadata.name;

      if (
        viewFieldDisplayName != null &&
        connectedCreateAction.action.displayMetadata.name.toLowerCase() === "create"
      ) {
        actionName = `Add ${viewFieldDisplayName}` as IReadableString;
      }

      return {
        action: {
          ...connectedCreateAction.action,
          displayMetadata: {
            ...connectedCreateAction.action.displayMetadata,
            name: actionName,
          },
        },
        entityId: undefined,
        defaultValues: {
          [connectedCreateAction.actionViewFieldId]: {
            type: "relatedEntities" as const,
            value: [
              {
                entityId,
                entityTypeId,
                displayName: entityDisplayName,
              },
            ],
          },
        },
      };

      return undefined;
    }).filter(isNonNullable);
  }, [
    connectedCreateActionsByViewFieldId,
    stateId,
    relationViewFieldNamesById,
    entity?.displayName,
    entityId,
    entityTypeId,
  ]);

  const otherCurrentEntityTypeActions: IEntityViewSecondaryAction[] = useMemoDeepCompare(() => {
    const defaultTransitionInfo = stateId != null ? contextColumnsByState?.contextFields[stateId] : undefined;

    let currentEntitiesDefaultActions: IEntityViewSecondaryAction[];

    if (defaultTransitionInfo != null) {
      currentEntitiesDefaultActions = defaultTransitionInfo.otherTransitions
        .map((t) => {
          const action = actionsById[t.actionId];

          if (action == null) {
            return undefined;
          }

          return {
            action,
            entityId,
            defaultValues: undefined,
          };
        })
        .filter(isNonNullable);
    } else {
      // Otherwise show all the modify actions for this entity type that don't have a fromState
      currentEntitiesDefaultActions = map(actionsData?.actions, (action) => {
        if (action?.actionDefinition.actionType === "modify" && action.actionDefinition.fromStates == null) {
          return {
            action,
            entityId,
            defaultValues: undefined,
          };
        }

        return undefined;
      }).filter(isNonNullable);
    }

    const deleteAction = entityType?.deleteActionId != null ? actionsById[entityType.deleteActionId] : undefined;

    if (deleteAction != null) {
      currentEntitiesDefaultActions.push({
        action: deleteAction,
        entityId,
        defaultValues: undefined,
      });
    }

    return currentEntitiesDefaultActions;
  }, [actionsById, contextColumnsByState, stateId, entityId, actionsData?.actions, entityType?.deleteActionId]);

  const executableOtherActions: IEntityViewSecondaryAction[] = useMemoDeepCompare(() => {
    return (
      otherCurrentEntityTypeActions
        .filter((a) => allCurrentEntityActionsAccessById?.[a.action.id]?.canExecute === true)
        // The connected actions are already permissioned without a specified entity id because it's a create action
        // and filtered by the entity id being valid for the relation field in that action
        .concat(stateSpecificConnectedCreateActions)
    );
  }, [otherCurrentEntityTypeActions, allCurrentEntityActionsAccessById, stateSpecificConnectedCreateActions]);

  const isLoadingExecutableActions =
    isLoadingActions || isLoadingConnectedCreateActions || isLoadingCurrentEntityActions;

  const handleClickCommentToast = useCallback(() => {
    // contentRef.current?.scrollTo({ top: contentRef.current.scrollHeight, behavior: "smooth" });
    setShowCommentToast(false);
  }, [setShowCommentToast]);

  const handleComment = useCallback(
    async (newComment: string) => {
      setShowCommentToast(true);

      const res = await addComment({
        comment: {
          entityTypeId,
          versionType,
          entityId,
          commentText: newComment,
        },
      });

      void refetchComments();

      return { id: res.comment.id };
    },
    [addComment, entityId, setShowCommentToast, refetchComments, entityTypeId, versionType],
  );

  const [failedComments, setFailedComments] = useState<IComment[]>([]);
  const [sendingComments, setSendingComments] = useState<IComment[]>([]);

  // useEffect(() => {
  //   if (scrollState === "end") {
  //     setShowCommentToast(false);
  //   }
  // }, [scrollState]);

  const maybeRenderCommentToast = useCallback(() => {
    if (!showCommentToast) {
      return null;
    }

    let status: "error" | "loading" | "success" = "error";

    if (isCommentPending) {
      status = "loading";
    } else if (isCommentPosted) {
      status = "success";
    }

    return (
      <CommentToast
        className="sticky left-1/2 top-1 w-fit -translate-x-1/2 cursor-pointer items-center"
        status={status}
        onClick={handleClickCommentToast}
      />
    );
  }, [showCommentToast, isCommentPending, isCommentPosted, handleClickCommentToast]);

  const getConnectedCreateActionProps = useCallback(
    (viewFieldId: IViewFieldId) => {
      const entityDisplayName = entity?.displayName;

      if (entityDisplayName == null) {
        return undefined;
      }

      const connectedCreateAction = connectedCreateActionsByViewFieldId[viewFieldId];

      const hasInvalidStateConstraint =
        stateId != null &&
        connectedCreateAction?.currentStateIds != null &&
        !connectedCreateAction.currentStateIds.includes(stateId);

      if (connectedCreateAction == null || hasInvalidStateConstraint) {
        return undefined;
      }

      let buttonText = connectedCreateAction.action.displayMetadata.name;

      if (buttonText.toLowerCase() === "create" && relationViewFieldNamesById[viewFieldId]?.displayName != null) {
        buttonText = `Add ${relationViewFieldNamesById[viewFieldId]?.displayName ?? "undefined"}` as IReadableString;
      }

      return {
        actionRoute: getFullPageActionRoute({
          actionId: connectedCreateAction.action.id,
          entityTypeId: connectedCreateAction.action.entityTypeId,
          entityId: undefined,
          isCreateActionDraft: false,
          defaultValues: {
            [connectedCreateAction.actionViewFieldId]: {
              type: "relatedEntities" as const,
              value: [
                {
                  entityId: entityId,
                  entityTypeId,
                  displayName: entityDisplayName,
                },
              ],
            },
          },
        }),
        buttonText,
      };
    },
    [
      entityTypeId,
      stateId,
      connectedCreateActionsByViewFieldId,
      entityId,
      entity?.displayName,
      getFullPageActionRoute,
      relationViewFieldNamesById,
    ],
  );

  const renderEvent = useCallback(
    (item: ILoadedActionLog) => {
      return (
        <ActionLogItem
          key={item.actionLogId}
          actionLog={item}
          allFieldsById={allFieldsById}
          organizationId={orgId}
          versionType={versionType}
        />
      );
    },
    [orgId, versionType, allFieldsById],
  );

  const handleGenerateCommentId = useCallback(() => {
    return EntityCommentId.generate();
  }, []);

  const maybeRenderInlineEntityTables = useCallback(() => {
    return allRelationViewFields.map((relationViewField) => {
      if (isLoadingValidRelationFields || isLoadingAllEntityTypes || allEntityTypesQuery == null) {
        return <InlineEntityTableSkeleton key={relationViewField.id} count={undefined} title={undefined} />;
      }

      const isInvalidRelationField = validRelationFieldsById?.[relationViewField.id] !== true;

      return (
        <InlineEntityTableWrapper
          key={relationViewField.id}
          allEntityTypes={allEntityTypesQuery.entityTypes}
          allRelationsInOrg={allEntityTypesQuery.relations}
          createNewProps={getConnectedCreateActionProps(relationViewField.id)}
          dataLoadingQuery={{
            versionType,
            entityType: {
              type: "joinTraversal",
              relationTypeId: relationViewField.relation.id,
              relationDirection: relationViewField.direction,
              startingEntities: {
                type: "entityTypeId",
                entityTypeId,
                entities: [entityId],
              },
            },
            filters: undefined,
          }}
          entityTypeId={
            relationViewField.direction === "aToB"
              ? relationViewField.relation.entityTypeIdB
              : relationViewField.relation.entityTypeIdA
          }
          getFullPageEntityRoute={getFullPageEntityRoute}
          getLinkedEntityRoute={getLinkedEntityRoute}
          orgId={orgId}
          showOnlyIfData={isInvalidRelationField}
          titleOverride={relationViewField.displayName}
          versionType={versionType}
        />
      );
    });
  }, [
    allRelationViewFields,
    entityTypeId,
    orgId,
    entityId,
    isLoadingValidRelationFields,
    allEntityTypesQuery,
    isLoadingAllEntityTypes,
    getConnectedCreateActionProps,
    getFullPageEntityRoute,
    getLinkedEntityRoute,
    validRelationFieldsById,
    versionType,
  ]);

  const renderEmailsFromUserEntity = useCallback(() => {
    if (entityType?.userEntityTypeInfo == null) {
      return null;
    }

    return (
      <LoadingEmailsList
        entityTypeId={entityTypeId}
        organizationId={orgId}
        userEntityId={entityId}
        versionType={versionType}
      />
    );
  }, [entityType?.userEntityTypeInfo, orgId, entityTypeId, entityId, versionType]);

  const renderComments = useCallback(() => {
    if (isLoadingComments || isLoadingActionLogs) {
      return <CommentThreadSkeleton />;
    }

    return (
      <div className="border-border bg-paper text-ink overflow-hidden rounded border">
        <CommentThread
          comments={
            entityCommentsQuery?.entityComments.map((c) => ({
              id: c.id,
              author: c.author.type === "user" ? <UserNameById id={c.author.userId} /> : <AiAgentUserName />,
              authorImage:
                c.author.type === "user" ? (
                  <UserProfileImage className="size-6" id={c.author.userId} />
                ) : (
                  <AiAgentUserProfileImage className="size-6" />
                ),
              at: c.sentAt,
              commentText: c.commentText,
            })) ?? []
          }
          eventRenderer={renderEvent}
          events={
            actionLogsQuery?.actionLogs.map((a) => ({
              id: a.actionLogId,
              author: a.executedBy.type === "user" ? <UserNameById id={a.executedBy.userId} /> : <AiAgentUserName />,
              authorImage:
                a.executedBy.type === "user" ? (
                  <UserProfileImage className="size-6" id={a.executedBy.userId} />
                ) : (
                  <AiAgentUserProfileImage className="size-6" />
                ),
              at: a.executedAt,
              value: a,
            })) ?? []
          }
          failedComments={failedComments}
          isLoading={isLoadingActionLogs}
          sendingComments={sendingComments}
        />
        <CommentInput
          className="grow"
          setFailedComments={setFailedComments}
          setSendingComments={setSendingComments}
          onComment={handleComment}
          onGenerateCommentId={handleGenerateCommentId}
        />
      </div>
    );
  }, [
    isLoadingComments,
    isLoadingActionLogs,
    entityCommentsQuery?.entityComments,
    renderEvent,
    actionLogsQuery?.actionLogs,
    failedComments,
    sendingComments,
    handleComment,
    handleGenerateCommentId,
  ]);

  const titleComputationStatus = useMemoDeepCompare(() => {
    const displayNameColumnId = entityType?.displayNameColumn;

    if (displayNameColumnId == null) {
      return undefined;
    }

    return entity?.fieldsComputationStatuses[computeColumnViewFieldId(displayNameColumnId)]?.status;
  }, [entity?.fieldsComputationStatuses, entityType?.displayNameColumn]);

  const renderTitle = useCallback(() => {
    const displayName = entity?.displayName ?? "";

    if (displayName === "null" && titleComputationStatus === "computing") {
      return <h1 className="animate-pulse italic">Generating title...</h1>;
    }

    return <h1>{displayName}</h1>;
  }, [entity?.displayName, titleComputationStatus]);

  if (isLoadingEntity || isLoadingEntityType || isAccessToEntityTypeLoading || isAccessToActivityLoading) {
    return <EntityViewSkeleton />;
  }

  // Should have a better rendering of a permission error to load the entity
  if (entityType == null || entity == null) {
    return <NonIdealState resourceType="entity" variant="notFound" />;
  }

  if (accessToEntityTypeQuery?.canView !== true) {
    return <NonIdealState resourceType="entity" variant="permissionDenied" />;
  }

  return (
    <div className="flex w-full grow flex-col items-center" data-testid="entity-view">
      <Head>
        <title>
          {generateTitle(`${entity.displayName} on ${entityType.displayMetadata.pluralName}`, versionType === "dev")}
        </title>
      </Head>
      {maybeRenderCommentToast()}
      <div className="text-ink flex w-full max-w-2xl grow flex-col space-y-6 self-center">
        <div className="flex max-w-full grow flex-col space-y-10">
          <div className="flex items-start justify-between gap-2">
            {showTitle ? renderTitle() : null}
            <ActionSavingIndicator entityId={entityId} entityTypeId={entityTypeId} versionType={versionType} />
          </div>
          <ActionCard
            entity={entity}
            getActionRoute={getActionRoute}
            getFullPageActionRoute={getFullPageActionRoute}
            isLoadingExecutableActions={isLoadingExecutableActions}
            primaryAction={primaryAction}
            secondaryActions={executableOtherActions}
            statusBadge={statusBadge}
            versionType={versionType}
            onActionExecuted={handleActionExecuted}
          />
          <EntityFields
            allEntityTypes={allEntityTypesQuery?.entityTypes ?? {}}
            entity={entity}
            entityType={entityType}
            getHighlightedViewFieldRoute={getHighlightedViewFieldRoute}
            getLinkedEntityRoute={getLinkedEntityRoute}
            highlightedViewFieldId={highlightedViewFieldId}
            isLoadingContextActions={isLoadingContextColumns}
            organizationId={orgId}
            validRelationFieldsIds={validRelationFieldsById}
            versionType={versionType}
          />
          {maybeRenderInlineEntityTables()}
          {accessToActivityQuery?.canInteractWithActivity === true && renderEmailsFromUserEntity()}
          {accessToActivityQuery?.canInteractWithActivity === true && renderComments()}
        </div>
      </div>
    </div>
  );
};
