import { cn } from "@archetype/ui";
import { Skeleton } from "@archetype/ui";

import { ActionFormSkeleton } from "./ActionFormSkeleton";

export const ActionViewSkeleton: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={cn("text-ink @container flex w-full max-w-2xl grow flex-col space-y-6 self-center", className)}>
      <div className="flex flex-col gap-2">
        <Skeleton className="h-5 w-24" />
        <Skeleton className="h-9 w-56" />
      </div>
      <ActionFormSkeleton fieldsCount={4} />
      <Skeleton className="h-10 w-32">Button</Skeleton>
    </div>
  );
};
