import type { IActionCore, IEntityTypeCore, IStateMachine, ITargetEntityTypeCore } from "@archetype/dsl";
import { isColumnViewField, isRelationViewField, isTargetEntityTypeCore } from "@archetype/dsl";
import {
  ActionId,
  type IActionId,
  type IApplicationGroupId,
  type IEntityTypeId,
  type IRelationId,
} from "@archetype/ids";
import { keyByNoUndefined } from "@archetype/utils";
import { clone, cloneDeep, difference, isEqual, omit } from "lodash";

import { makeDefaultDeleteAction } from "../buildActionsFromSupportEntityType";
import { correctEntityTypeIfNeeded } from "../correctEntityTypeIfNeeded";
import { removeStateFromStateMachine } from "../removeStateFromStateMachine";
import { removeTransitionFromStateMachine } from "../removeTransitionFromStateMachine";
import { applyCreateOrEditColumnOperation } from "./applyCreateOrEditColumnOperation";
import { applyDeleteColumnOperation } from "./applyDeleteColumnOperation";
import { applyDeleteRelationOperation } from "./applyDeleteRelationOperation";
import { applyEditAutofillOperation } from "./applyEditAutofillOperation";
import { applyReplaceViewFieldOperation } from "./applyReplaceViewFieldOperation";
import { applySetActionOperation } from "./applySetActionOperation";
import { DATA_MODEL_OPERATIONS, getDisabledOperationsByViewFieldId } from "./disabledOperations";
import type { ICollectedOperationsDataIdentifiers, IEditOperation, IOperationsEdits } from "./operations";

interface IOperationDataLoading {
  /**
   * Should be a single unique entry at a time for now, so can fail early otherwise, or batch in multiple edit operations
   */
  applicationGroupId?: IApplicationGroupId;
  allActionsForStateMachine?: IApplicationGroupId;
  entityTypeIds?: IEntityTypeId[];
  supportActionsForEntityTypeIds?: IEntityTypeId[];
  relationIds?: IRelationId[];
  actionIds?: IActionId[];
}

const collectDataToLoad = (operation: IEditOperation): IOperationDataLoading => {
  switch (operation.type) {
    case "setEntityTypeAuthorization": {
      return {
        entityTypeIds: [operation.entityTypeId],
      };
    }
    case "setActivityLogAuthorization": {
      return {
        entityTypeIds: [operation.entityTypeId],
      };
    }
    case "setEntityTypeStateAuthorizations": {
      return {
        entityTypeIds: [operation.entityTypeId],
      };
    }
    case "setActionAuthorization": {
      return {
        actionIds: [operation.actionId],
      };
    }
    case "replaceViewField": {
      return {
        entityTypeIds: [operation.entityTypeId],
        supportActionsForEntityTypeIds: [operation.entityTypeId],
        actionIds: operation.actionIds ?? undefined,
      };
    }
    case "setEntityTypeShapeName": {
      return {
        entityTypeIds: [operation.entityTypeId],
      };
    }
    case "setEntityTypeColor": {
      return {
        entityTypeIds: [operation.entityTypeId],
      };
    }
    case "setEntityTypeDisplayMetadata": {
      return {
        entityTypeIds: [operation.entityTypeId],
      };
    }
    case "createNewSupportEntityType": {
      return {
        applicationGroupId: undefined,
        entityTypeIds: [],
      };
    }
    case "createNewProcessEntityType": {
      return {
        applicationGroupId: undefined,
        entityTypeIds: [],
      };
    }
    case "createNewRelation": {
      return {
        applicationGroupId: undefined,
        entityTypeIds: [operation.relation.entityTypeIdA, operation.relation.entityTypeIdB],
        relationIds: [operation.relation.id],
      };
    }
    case "createOrEditColumn": {
      return {
        entityTypeIds: [operation.entityTypeId],
        supportActionsForEntityTypeIds: [operation.entityTypeId],
      };
    }
    case "deleteColumn": {
      return {
        entityTypeIds: [operation.entityTypeId],
        supportActionsForEntityTypeIds: [operation.entityTypeId],
        actionIds: operation.actionIds ?? undefined,
      };
    }
    case "createOrEditRelation": {
      return {
        entityTypeIds: [operation.relation.entityTypeIdA, operation.relation.entityTypeIdB],
        relationIds: [operation.relation.id],
      };
    }
    case "deleteRelation": {
      return {
        actionIds: operation.actionIds ?? undefined,
      };
    }
    case "setViewFieldsForState": {
      return {
        entityTypeIds: [operation.entityTypeId],
      };
    }
    case "editActionName": {
      return {
        actionIds: [operation.actionId],
      };
    }
    case "setAction": {
      return {
        applicationGroupId: operation.applicationGroupId,
        entityTypeIds: [operation.targetEntityTypeId],
        actionIds: [operation.action.actionId],
      };
    }
    case "editAutofill": {
      return {
        entityTypeIds: isColumnViewField(operation.viewField) ? [operation.entityTypeId] : [],
        relationIds: isRelationViewField(operation.viewField) ? [operation.viewField.relationId] : [],
      };
    }
    case "setAiAgentForState": {
      return {
        applicationGroupId: operation.applicationGroupId,
      };
    }
    case "deleteState": {
      return {
        applicationGroupId: operation.applicationGroupId,
        entityTypeIds: [operation.targetEntityTypeId],
      };
    }
    case "deleteTransition": {
      return {
        applicationGroupId: operation.applicationGroupId,
        entityTypeIds: [],
      };
    }
    case "editState": {
      return {
        applicationGroupId: operation.applicationGroupId,
        entityTypeIds: [operation.targetEntityTypeId],
      };
    }
    case "createNewState": {
      return {
        applicationGroupId: operation.applicationGroupId,
        entityTypeIds: [operation.targetEntityTypeId],
      };
    }
    case "createNewTransition": {
      return {
        applicationGroupId: operation.applicationGroupId,
        entityTypeIds: [operation.targetEntityTypeId],
      };
    }
  }
};

export const collectDataToLoadFromOperations = (operations: IEditOperation[]): ICollectedOperationsDataIdentifiers => {
  const res = {
    applicationGroupIds: new Set<IApplicationGroupId>(),
    allActionsForStateMachine: new Set<IApplicationGroupId>(),
    entityTypeIds: new Set<IEntityTypeId>(),
    relationIds: new Set<IRelationId>(),
    supportActionsForEntityTypeIds: new Set<IEntityTypeId>(),
    actionIds: new Set<IActionId>(),
  };

  operations.forEach((operation) => {
    const data = collectDataToLoad(operation);

    if (data.applicationGroupId != null) {
      res.applicationGroupIds.add(data.applicationGroupId);
    }
    if (data.allActionsForStateMachine != null) {
      res.allActionsForStateMachine.add(data.allActionsForStateMachine);
    }
    data.entityTypeIds?.forEach((entityTypeId) => {
      res.entityTypeIds.add(entityTypeId);
    });
    data.relationIds?.forEach((relationId) => {
      res.relationIds.add(relationId);
    });
    data.supportActionsForEntityTypeIds?.forEach((entityTypeId) => {
      res.supportActionsForEntityTypeIds.add(entityTypeId);
    });
    data.actionIds?.forEach((actionId) => {
      res.actionIds.add(actionId);
    });
  });

  return {
    applicationGroupIds: Array.from(res.applicationGroupIds),
    allActionsForStateMachine: Array.from(res.allActionsForStateMachine),
    entityTypeIds: Array.from(res.entityTypeIds),
    relationIds: Array.from(res.relationIds),
    supportActionsForEntityTypeIds: Array.from(res.supportActionsForEntityTypeIds),
    actionIds: Array.from(res.actionIds),
  };
};

export const applyOperation = (operation: IEditOperation, currentInfo: IOperationsEdits): IOperationsEdits => {
  switch (operation.type) {
    case "setEntityTypeAuthorization": {
      const entityType = currentInfo.newOrEditedEntityTypes[operation.entityTypeId];

      if (entityType == null) {
        throw new Error(`Cannot find entity type ${operation.entityTypeId} to set authorization`);
      }

      const newOrEditedEntityTypes: IOperationsEdits["newOrEditedEntityTypes"] = clone(
        currentInfo.newOrEditedEntityTypes,
      );
      const modifiedEntityType = clone(entityType);

      if (operation.authorizedForAnyoneWithLink != null) {
        modifiedEntityType.authorizedForAnyoneWithLink = operation.authorizedForAnyoneWithLink;
      }

      if (operation.authorizedByAnyOf != null) {
        modifiedEntityType.authorizedByAnyOf = operation.authorizedByAnyOf;
      }

      newOrEditedEntityTypes[entityType.id] = modifiedEntityType;

      return {
        ...currentInfo,
        newOrEditedEntityTypes,
      };
    }
    case "setActivityLogAuthorization": {
      const entityType = currentInfo.newOrEditedEntityTypes[operation.entityTypeId];

      if (entityType == null) {
        throw new Error(`Cannot find entity type ${operation.entityTypeId} to set authorization`);
      }

      const newOrEditedEntityTypes: IOperationsEdits["newOrEditedEntityTypes"] = clone(
        currentInfo.newOrEditedEntityTypes,
      );
      const modifiedEntityType = clone(entityType);

      if (operation.activityLogAuthorizedByAnyOf != null) {
        modifiedEntityType.activityLogAuthorizedByAnyOf = operation.activityLogAuthorizedByAnyOf;
      }

      newOrEditedEntityTypes[entityType.id] = modifiedEntityType;

      return {
        ...currentInfo,
        newOrEditedEntityTypes,
      };
    }

    case "setEntityTypeStateAuthorizations": {
      const entityType = currentInfo.newOrEditedEntityTypes[operation.entityTypeId];

      if (entityType == null) {
        throw new Error(`Cannot find entity type ${operation.entityTypeId} to set state authorizations`);
      }

      const newOrEditedEntityTypes: IOperationsEdits["newOrEditedEntityTypes"] = clone(
        currentInfo.newOrEditedEntityTypes,
      );
      const modifiedEntityType = clone(entityType);

      if (operation.authorizedByAnyOf != null) {
        modifiedEntityType.authorizedByAnyOfPerStateId[operation.stateId] = operation.authorizedByAnyOf;
      }
      if (operation.authorizedForAnyoneWithLink != null) {
        modifiedEntityType.authorizedForAnyoneWithLinkPerStateId ??= {};
        modifiedEntityType.authorizedForAnyoneWithLinkPerStateId[operation.stateId] =
          operation.authorizedForAnyoneWithLink;
      }

      newOrEditedEntityTypes[entityType.id] = modifiedEntityType;

      return {
        ...currentInfo,
        newOrEditedEntityTypes,
      };
    }

    case "setActionAuthorization": {
      const action = currentInfo.newOrEditedActions[operation.actionId];

      if (action == null) {
        throw new Error(`Cannot find action ${operation.actionId} to set authorization`);
      }

      const newOrEditedActions: IOperationsEdits["newOrEditedActions"] = clone(currentInfo.newOrEditedActions);
      const modifiedAction = clone(action);

      modifiedAction.actionDefinition.authorizedByAnyOf = operation.authorizedByAnyOf;
      modifiedAction.actionDefinition.authorizedForAnyoneWithLink = operation.authorizedForAnyoneWithLink;

      newOrEditedActions[action.id] = modifiedAction;

      return {
        ...currentInfo,
        newOrEditedActions,
      };
    }
    case "editActionName": {
      const action = currentInfo.newOrEditedActions[operation.actionId];

      if (action == null) {
        throw new Error(`Cannot find action ${operation.actionId} to set name`);
      }

      const newOrEditedActions: IOperationsEdits["newOrEditedActions"] = clone(currentInfo.newOrEditedActions);
      const modifiedAction = cloneDeep(action);

      modifiedAction.displayMetadata.name = operation.newName;

      newOrEditedActions[action.id] = modifiedAction;

      return {
        ...currentInfo,
        newOrEditedActions,
      };
    }
    case "replaceViewField": {
      return applyReplaceViewFieldOperation(operation, currentInfo);
    }
    case "setEntityTypeShapeName": {
      const entityType = currentInfo.newOrEditedEntityTypes[operation.entityTypeId];

      if (entityType == null) {
        throw new Error(`Cannot find entity type ${operation.entityTypeId} to set authorization`);
      }

      return {
        ...currentInfo,
        newOrEditedEntityTypes: {
          ...currentInfo.newOrEditedEntityTypes,
          [entityType.id]: {
            ...entityType,
            shape: operation.newShape,
          },
        },
      };
    }
    case "setEntityTypeColor": {
      const entityType = currentInfo.newOrEditedEntityTypes[operation.entityTypeId];

      if (entityType == null) {
        throw new Error(`Cannot find entity type ${operation.entityTypeId} to set authorization`);
      }

      return {
        ...currentInfo,
        newOrEditedEntityTypes: {
          ...currentInfo.newOrEditedEntityTypes,
          [entityType.id]: {
            ...entityType,
            color: operation.newColor,
          },
        },
      };
    }
    case "setEntityTypeDisplayMetadata": {
      const entityType = currentInfo.newOrEditedEntityTypes[operation.entityTypeId];

      if (entityType == null) {
        throw new Error(`Cannot find entity type ${operation.entityTypeId} to set authorization`);
      }

      return {
        ...currentInfo,
        newOrEditedEntityTypes: {
          ...currentInfo.newOrEditedEntityTypes,
          [entityType.id]: {
            ...entityType,
            displayMetadata: operation.newDisplayMetadata,
          },
        },
      };
    }
    case "createOrEditColumn": {
      return applyCreateOrEditColumnOperation(operation, currentInfo);
    }
    case "deleteColumn": {
      return applyDeleteColumnOperation(operation, currentInfo);
    }
    case "createOrEditRelation": {
      return {
        ...currentInfo,
        newOrEditedRelations: { ...currentInfo.newOrEditedRelations, [operation.relation.id]: operation.relation },
      };
    }
    case "deleteRelation": {
      return applyDeleteRelationOperation(operation, currentInfo);
    }
    case "setAction": {
      return applySetActionOperation(operation, currentInfo);
    }
    case "editAutofill": {
      return applyEditAutofillOperation(operation, currentInfo);
    }
    case "setViewFieldsForState": {
      const { entityTypeId, stateId, fields } = operation;

      const currentTargetEntityType = currentInfo.newOrEditedEntityTypes[entityTypeId];

      if (currentTargetEntityType == null) {
        throw new Error(`Cannot find target entity type ${entityTypeId} to edit view fields per state`);
      }

      if (!isTargetEntityTypeCore(currentTargetEntityType)) {
        throw new Error(`Cannot edit view fields per state on non target entity type ${entityTypeId}`);
      }

      const disabledOperations = getDisabledOperationsByViewFieldId(currentTargetEntityType);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety because of casting
      const relevantViewFieldsByStateId = cloneDeep(currentTargetEntityType.relevantViewFieldsByStateId) ?? {};
      const newFields = fields.filter(
        (field) => disabledOperations[field.id]?.has(DATA_MODEL_OPERATIONS.addToStateView) !== true,
      );

      if (isEqual(relevantViewFieldsByStateId[stateId], newFields)) {
        return currentInfo;
      }

      relevantViewFieldsByStateId[stateId] = newFields;

      const newTargetEntityType: ITargetEntityTypeCore = {
        ...currentTargetEntityType,
        statusColumn: currentTargetEntityType.statusColumn,
        relevantViewFieldsByStateId,
      };

      return {
        ...currentInfo,
        newOrEditedEntityTypes: {
          ...currentInfo.newOrEditedEntityTypes,
          [entityTypeId]: newTargetEntityType,
        },
      };
    }
    case "createNewSupportEntityType": {
      const { entityType: supportEntityType } = operation;

      const deleteActionId = ActionId.generate();

      const deleteAction = makeDefaultDeleteAction({
        deleteActionId,
        entityTypeId: supportEntityType.id,
        organizationId: supportEntityType.organizationId,
        applicationGroupId: supportEntityType.targetEntityTypeApplicationGroupId,
        authorizedByAnyOf: [],
      });

      const newEntityType: IEntityTypeCore = {
        ...supportEntityType,
        deleteActionId: deleteActionId,
      };

      return {
        ...currentInfo,
        newOrEditedEntityTypes: {
          ...currentInfo.newOrEditedEntityTypes,
          [newEntityType.id]: newEntityType,
        },
        newOrEditedActions: {
          ...currentInfo.newOrEditedActions,
          [deleteActionId]: deleteAction,
        },
      };
    }
    case "createNewProcessEntityType": {
      const { entityType: supportEntityType } = operation;

      const deleteActionId = ActionId.generate();

      const deleteAction = makeDefaultDeleteAction({
        deleteActionId,
        entityTypeId: supportEntityType.id,
        organizationId: supportEntityType.organizationId,
        applicationGroupId: supportEntityType.targetEntityTypeApplicationGroupId,
        authorizedByAnyOf: [],
      });

      const newEntityType: IEntityTypeCore = {
        ...supportEntityType,
        deleteActionId: deleteActionId,
      };

      return {
        ...currentInfo,
        newOrEditedEntityTypes: {
          ...currentInfo.newOrEditedEntityTypes,
          [newEntityType.id]: newEntityType,
        },
        stateMachineEdits: {
          ...currentInfo.stateMachineEdits,
          [operation.applicationGroupId]: operation.stateMachine,
        },
        newOrEditedActions: {
          ...currentInfo.newOrEditedActions,
          ...keyByNoUndefined(operation.actions, (action) => action.id),
          [deleteActionId]: deleteAction,
        },
      };
    }
    case "createNewRelation": {
      const { relation } = operation;

      return {
        ...currentInfo,
        newOrEditedRelations: {
          ...currentInfo.newOrEditedRelations,
          [relation.id]: relation,
        },
      };
    }
    case "setAiAgentForState": {
      const stateMachine = currentInfo.stateMachineEdits[operation.applicationGroupId];

      if (stateMachine == null) {
        throw new Error("State machine not found");
      }

      const { stateId, aiAgentDefinition } = operation;

      const newStateMachine: IStateMachine = {
        ...stateMachine,
        aiAgents: {
          ...stateMachine.aiAgents,
          [stateId]: aiAgentDefinition ?? undefined,
        },
      };

      return {
        ...currentInfo,
        stateMachineEdits: {
          ...currentInfo.stateMachineEdits,
          [operation.applicationGroupId]: newStateMachine,
        },
      };
    }
    case "deleteState": {
      const targetEntityType = currentInfo.newOrEditedEntityTypes[operation.targetEntityTypeId];

      const stateMachine = currentInfo.stateMachineEdits[operation.applicationGroupId];

      if (stateMachine == null) {
        throw new Error("State machine not found");
      }

      if (targetEntityType == null || !isTargetEntityTypeCore(targetEntityType)) {
        throw new Error(`Cannot find target entity type ${operation.targetEntityTypeId} to edit state`);
      }

      if (targetEntityType.targetEntityTypeApplicationGroupId !== operation.applicationGroupId) {
        throw new Error("Wrong target entity type for operation");
      }

      const removeStateEdits = removeStateFromStateMachine({
        stateMachine,
        stateId: operation.stateId,
        allActionsInStateMachine: currentInfo.newOrEditedActions,
        context: {
          organizationId: currentInfo.organizationId,
          applicationGroupId: operation.applicationGroupId,
        },
      });

      const newTargetEntityType: ITargetEntityTypeCore = correctEntityTypeIfNeeded(
        targetEntityType,
        removeStateEdits.newStateMachine,
      );

      return {
        ...currentInfo,
        stateMachineEdits: {
          ...currentInfo.stateMachineEdits,
          [operation.applicationGroupId]: removeStateEdits.newStateMachine,
        },
        newOrEditedEntityTypes: {
          ...currentInfo.newOrEditedEntityTypes,
          [operation.targetEntityTypeId]: newTargetEntityType,
        },
        newOrEditedActions: Object.assign(currentInfo.newOrEditedActions, removeStateEdits.newOrEditedActions),
        deletedActions: currentInfo.deletedActions.concat(removeStateEdits.deletedActionIds),
      };
    }
    case "deleteTransition": {
      const targetEntityType = currentInfo.newOrEditedEntityTypes[operation.targetEntityTypeId];

      const stateMachine = currentInfo.stateMachineEdits[operation.applicationGroupId];

      if (stateMachine == null) {
        throw new Error("State machine not found");
      }

      if (targetEntityType == null || !isTargetEntityTypeCore(targetEntityType)) {
        throw new Error(`Cannot find target entity type ${operation.targetEntityTypeId} to edit state`);
      }

      if (targetEntityType.targetEntityTypeApplicationGroupId !== operation.applicationGroupId) {
        throw new Error("Wrong target entity type for operation");
      }

      const newStateMachine: IStateMachine = removeTransitionFromStateMachine(stateMachine, operation.transitionId);
      const originActionIds = stateMachine.stateTransitions.map((t) => t.actionId);
      const newActionIds = newStateMachine.stateTransitions.map((t) => t.actionId);
      const removedActionIds = difference(originActionIds, newActionIds);

      // Handle deleted states to entity type here too
      const newTargetEntityType: ITargetEntityTypeCore = correctEntityTypeIfNeeded(targetEntityType, newStateMachine);

      return {
        ...currentInfo,
        stateMachineEdits: {
          ...currentInfo.stateMachineEdits,
          [operation.applicationGroupId]: newStateMachine,
        },
        newOrEditedEntityTypes: {
          ...currentInfo.newOrEditedEntityTypes,
          [operation.targetEntityTypeId]: newTargetEntityType,
        },
        newOrEditedActions: omit(currentInfo.newOrEditedActions, removedActionIds),
        deletedActions: removedActionIds,
      };
    }
    case "editState": {
      const targetEntityType = currentInfo.newOrEditedEntityTypes[operation.targetEntityTypeId];

      const stateMachine = currentInfo.stateMachineEdits[operation.applicationGroupId];

      if (stateMachine == null) {
        throw new Error("State machine not found");
      }

      if (targetEntityType == null || !isTargetEntityTypeCore(targetEntityType)) {
        throw new Error(`Cannot find target entity type ${operation.targetEntityTypeId} to edit state`);
      }

      if (targetEntityType.targetEntityTypeApplicationGroupId !== operation.applicationGroupId) {
        throw new Error("Wrong target entity type for operation");
      }

      const { updatedState } = operation;

      const targetState = stateMachine.states[updatedState.id];

      if (targetState == null) {
        throw new Error(`State ${updatedState.id} not found on state machine`);
      }

      const updatedStates: IStateMachine["states"] = {
        ...stateMachine.states,
        [updatedState.id]: updatedState,
      };

      const newTargetEntityType: ITargetEntityTypeCore = correctEntityTypeIfNeeded(targetEntityType, {
        ...stateMachine,
        states: updatedStates,
        archivedStates: stateMachine.archivedStates,
      });

      const newStateMachine: IStateMachine = {
        ...stateMachine,
        states: updatedStates,
      };

      return {
        ...currentInfo,
        newOrEditedEntityTypes: {
          ...currentInfo.newOrEditedEntityTypes,
          [operation.targetEntityTypeId]: newTargetEntityType,
        },
        stateMachineEdits: {
          ...currentInfo.stateMachineEdits,
          [operation.applicationGroupId]: newStateMachine,
        },
      };
    }
    case "createNewState": {
      const targetEntityType = currentInfo.newOrEditedEntityTypes[operation.targetEntityTypeId];

      const stateMachine = cloneDeep(currentInfo.stateMachineEdits[operation.applicationGroupId]);

      if (stateMachine == null) {
        throw new Error("State machine not found");
      }

      if (targetEntityType == null || !isTargetEntityTypeCore(targetEntityType)) {
        throw new Error(`Cannot find target entity type ${operation.targetEntityTypeId} to edit state`);
      }

      if (targetEntityType.targetEntityTypeApplicationGroupId !== operation.applicationGroupId) {
        throw new Error("Wrong target entity type for operation");
      }

      const { newState, newHappyPath, newTransition, newTransitionAction } = operation;

      const updatedStates: IStateMachine["states"] = {
        ...stateMachine.states,
        [newState.id]: newState,
      };

      const updatedArchivedStates =
        stateMachine.archivedStates == null ? undefined : omit(stateMachine.archivedStates, newState.id);

      const actionId = ActionId.generate();

      const newStateMachine: IStateMachine = {
        ...stateMachine,
        archivedStates: updatedArchivedStates,
        happyPath: newHappyPath,
        initialState: operation.asInitialState
          ? {
              actionId: stateMachine.initialState.actionId, // unchanged
              state: newState.id,
            }
          : stateMachine.initialState,
        stateTransitions: stateMachine.stateTransitions.concat({
          ...newTransition,
          actionId,
        }),
        states: updatedStates,
        dataModel: stateMachine.dataModel,
      };

      const newActionWithId: IActionCore = {
        ...newTransitionAction,
        id: actionId,
      };

      const newTargetEntityType: ITargetEntityTypeCore = correctEntityTypeIfNeeded(targetEntityType, newStateMachine);

      return {
        ...currentInfo,
        stateMachineEdits: {
          ...currentInfo.stateMachineEdits,
          [operation.applicationGroupId]: newStateMachine,
        },
        newOrEditedActions: {
          ...currentInfo.newOrEditedActions,
          [actionId]: newActionWithId,
        },
        newOrEditedEntityTypes: {
          ...currentInfo.newOrEditedEntityTypes,
          [operation.targetEntityTypeId]: newTargetEntityType,
        },
      };
    }
    case "createNewTransition": {
      const stateMachine = cloneDeep(currentInfo.stateMachineEdits[operation.applicationGroupId]);

      if (stateMachine == null) {
        throw new Error("State machine not found");
      }

      const { newHappyPath, newTransition, newTransitionAction } = operation;

      const actionId = ActionId.generate();

      const newStateMachine: IStateMachine = {
        ...stateMachine,
        happyPath: newHappyPath,
        stateTransitions: stateMachine.stateTransitions.concat({
          ...newTransition,
          actionId,
        }),
        dataModel: stateMachine.dataModel,
      };

      const newActionWithId: IActionCore = {
        ...newTransitionAction,
        id: actionId,
      };

      return {
        ...currentInfo,
        stateMachineEdits: {
          ...currentInfo.stateMachineEdits,
          [operation.applicationGroupId]: newStateMachine,
        },
        newOrEditedActions: {
          ...currentInfo.newOrEditedActions,
          [actionId]: newActionWithId,
        },
      };
    }
  }
};
