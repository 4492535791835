import type { IEntityTypeCore, IVersionType } from "@archetype/dsl";
import { builderTrpc } from "@archetype/trpc-react";

export interface IUseEntitiesDataProps {
  entityIds: string[];
  entityType: IEntityTypeCore;
  versionType: IVersionType;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- let TS infer the return type
export const useEntitiesData = ({ entityIds, entityType, versionType }: IUseEntitiesDataProps) => {
  return builderTrpc.dataLoading.getLoadedEntities.useQuery(
    {
      organizationId: entityType.organizationId,
      dataLoadingQuery: {
        entityType: { type: "entityTypeId", entityTypeId: entityType.id, entities: entityIds },
        versionType,
      },
      dataLoadingConfig: { specificColumns: [entityType.displayNameColumn], specificRelations: [] },
    },
    {
      trpc: { context: { skipBatch: true } },
      enabled: entityIds.length > 0,
      placeholderData: (prev) => (prev?.entities[0]?.entityTypeId !== entityType.id ? undefined : prev),
    },
  );
};
