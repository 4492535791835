import type {
  IDataLoadingSimpleColumnFilter,
  IDataLoadingSimpleEntityFilter,
  IDataLoadingSimpleGlobalSearchFilter,
  IDataLoadingSimpleRelationFilter,
} from "@archetype/dsl";
import { useCallback } from "react";
import { match } from "ts-pattern";

import type { IFilterColumnId } from "../FilterDropdown/types";
import { useFiltersFromURL } from "./useFiltersFromURL";

export type IAddOrUpdateFilterFn = (filter: IDataLoadingSimpleEntityFilter) => void;

export function useSimpleFiltersActions(): {
  addOrUpdateFilter: IAddOrUpdateFilterFn;
  removeFilterByColumnId: (columnId: IFilterColumnId) => void;
  removeFilterByRelationId: (relationId: IFilterColumnId) => void;
  removeGlobalSearchFilter: () => void;
} {
  const [filters, setFilters] = useFiltersFromURL();

  const addOrUpdateFilter = useCallback<IAddOrUpdateFilterFn>(
    (filter) => {
      match(filter)
        .with({ type: "search" }, (typedFilter) => {
          const existing = filters.find((i) => i.type === "search") as IDataLoadingSimpleGlobalSearchFilter | undefined;

          setFilters(
            existing
              ? filters.map((item) => (item === existing ? { ...item, ...typedFilter } : item))
              : [...filters, typedFilter],
          );
        })

        .with({ type: "relation" }, (typedFilter) => {
          const existing = filters.find((i) => i.type === "relation" && i.relationId === typedFilter.relationId) as
            | IDataLoadingSimpleRelationFilter
            | undefined;

          setFilters(
            existing
              ? filters.map((item) => (item === existing ? { ...item, ...typedFilter } : item))
              : [...filters, typedFilter],
          );
        })

        .with({ type: "column" }, (typedFilter) => {
          const existing = filters.find((item) => item.type === "column" && item.colId === typedFilter.colId) as
            | IDataLoadingSimpleColumnFilter
            | undefined;

          setFilters(
            existing
              ? filters.map((item) => (item === existing ? { ...item, ...typedFilter } : item))
              : [...filters, typedFilter],
          );
        })
        .exhaustive();
    },
    [filters, setFilters],
  );

  const removeFilterByColumnId = useCallback(
    (columnId: IFilterColumnId) => {
      setFilters(filters.filter((filter) => filter.type !== "column" || filter.colId !== columnId));
    },
    [filters, setFilters],
  );

  const removeFilterByRelationId = useCallback(
    (relationId: IFilterColumnId) => {
      setFilters(filters.filter((filter) => filter.type !== "relation" || filter.relationId !== relationId));
    },
    [filters, setFilters],
  );

  const removeGlobalSearchFilter = useCallback(() => {
    setFilters(filters.filter((filter) => filter.type !== "search"));
  }, [filters, setFilters]);

  return { addOrUpdateFilter, removeFilterByColumnId, removeFilterByRelationId, removeGlobalSearchFilter };
}
