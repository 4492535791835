import React, { useCallback } from "react";
import { createContext, useContextSelector } from "use-context-selector";

import { DataTableEditingContext } from "./DataTableEditingContext";
import { DataTableInstanceContext } from "./DataTableInstanceContext";
import { DataTableSelectionContext } from "./DataTableSelectionContext";

interface IDataTableNavigationContextType {
  navigateRow: (direction: "up" | "down" | "first" | "last", rowId: string) => void;
  navigateCell: (
    direction:
      | "up"
      | "down"
      | "left"
      | "right"
      | "pageUp"
      | "pageDown"
      | "firstCol"
      | "lastCol"
      | "firstRow"
      | "lastRow",
    rowId: string,
    colId: string,
  ) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- context cant be null
export const DataTableNavigationContext = createContext<IDataTableNavigationContextType>(null!);

interface IDataTableNavigationProvider {
  children: React.ReactNode;
  keyboardPageSize?: number;
}

export function DataTableNavigationProvider({
  children,
  keyboardPageSize = 10,
}: IDataTableNavigationProvider): React.ReactElement {
  const data = useContextSelector(DataTableInstanceContext, (state) => state.data);
  const columns = useContextSelector(DataTableInstanceContext, (state) => state.columns);
  const selectedRowIndices = useContextSelector(DataTableSelectionContext, (state) => state.selectedRowIndices);
  const selectedCells = useContextSelector(DataTableSelectionContext, (state) => state.selectedCells);
  const selectRow = useContextSelector(DataTableSelectionContext, (state) => state.selectRow);
  const selectCell = useContextSelector(DataTableSelectionContext, (state) => state.selectCell);
  const setEditableCell = useContextSelector(DataTableEditingContext, (state) => state.setEditableCell);

  const navigateRow = useCallback(
    (direction: "up" | "down" | "first" | "last", rowId: string) => {
      if (selectedRowIndices.size === 0) return;

      const currentRowIndex = data.findIndex((row) => row.id === rowId);

      if (currentRowIndex === -1) return;

      let newRowIndex = currentRowIndex;

      switch (direction) {
        case "up": {
          newRowIndex = Math.max(0, currentRowIndex - 1);
          break;
        }
        case "down": {
          newRowIndex = Math.min(data.length - 1, currentRowIndex + 1);
          break;
        }
        case "first": {
          newRowIndex = 0;
          break;
        }
        case "last": {
          newRowIndex = data.length - 1;
          break;
        }
      }

      selectRow(newRowIndex);
    },
    [data, selectRow, selectedRowIndices],
  );

  const navigateCell = useCallback(
    (
      direction:
        | "up"
        | "down"
        | "left"
        | "right"
        | "pageUp"
        | "pageDown"
        | "firstCol"
        | "lastCol"
        | "firstRow"
        | "lastRow",
      rowId: string,
      colId: string,
    ) => {
      if (selectedCells.size === 0) return;

      const currentRowIndex = data.findIndex((row) => row.id === rowId);
      const currentColIndex = columns.findIndex((col) => col.id === colId);

      if (currentRowIndex === -1 || currentColIndex === -1) return;

      let newRowIndex = currentRowIndex;
      let newColIndex = currentColIndex;

      switch (direction) {
        case "up": {
          newRowIndex = Math.max(0, currentRowIndex - 1);
          break;
        }
        case "down": {
          newRowIndex = Math.min(data.length - 1, currentRowIndex + 1);
          break;
        }
        case "left": {
          newColIndex = Math.max(0, currentColIndex - 1);
          break;
        }
        case "right": {
          newColIndex = Math.min(columns.length - 1, currentColIndex + 1);
          break;
        }
        case "pageUp": {
          newRowIndex = Math.max(0, currentRowIndex - keyboardPageSize);
          break;
        }
        case "pageDown": {
          newRowIndex = Math.min(data.length - 1, currentRowIndex + keyboardPageSize);
          break;
        }
        case "firstCol": {
          newColIndex = 0;
          break;
        }
        case "lastCol": {
          newColIndex = columns.length - 1;
          break;
        }
        case "firstRow": {
          newRowIndex = 0;
          break;
        }
        case "lastRow": {
          newRowIndex = data.length - 1;
          break;
        }
      }

      const newRowId = data[newRowIndex]?.id;
      const newColId = columns[newColIndex]?.id;

      if (newRowId != null && newColId != null) {
        setEditableCell(null);
        selectCell(newRowId, newColId);
      }
    },
    [selectedCells.size, data, columns, keyboardPageSize, selectCell, setEditableCell],
  );

  return (
    <DataTableNavigationContext.Provider
      value={{
        navigateRow,
        navigateCell,
      }}
    >
      {children}
    </DataTableNavigationContext.Provider>
  );
}
