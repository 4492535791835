import { useEffect } from "react";

export function useCellFocusOnEdit({
  inputRef,
  isCellSelected,
  isCellEditing,
}: {
  inputRef: React.RefObject<HTMLInputElement>;
  isCellSelected: boolean;
  isCellEditing: boolean;
}): void {
  useEffect(() => {
    if (isCellSelected && isCellEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isCellSelected, isCellEditing, inputRef]);
}
