import type { IAuthorization } from "@archetype/dsl";
import { USER_ACTIVE_EMPLOYEE_COLUMN_ID } from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";

export function createInternalEmployeeAuthorization({
  userEntityTypeId,
}: {
  userEntityTypeId: IEntityTypeId;
}): IAuthorization {
  return {
    authorizedByRelationPath: {
      path: [],
      userTypeFilters: {
        [USER_ACTIVE_EMPLOYEE_COLUMN_ID]: {
          type: "or",
          rawOrConditions: {
            eq: [
              {
                type: "value",
                value: {
                  type: "boolean",
                  value: true,
                },
              },
            ],
          },
          oredAndConditions: [],
        },
      },
      startingEntityType: {
        id: userEntityTypeId,
        filters: {
          [USER_ACTIVE_EMPLOYEE_COLUMN_ID]: {
            type: "or",
            rawOrConditions: {
              eq: [
                {
                  type: "value",
                  value: {
                    type: "boolean",
                    value: true,
                  },
                },
              ],
            },
            oredAndConditions: [],
          },
        },
      },
    },
  };
}
