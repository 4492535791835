import {
  ColumnId,
  ColumnTypeId,
  CompositeTypeId,
  CompositeTypeVersionId,
  DataModelMetadataId,
  DataModelMetadataVersionId,
  EntityTypeId,
} from "@archetype/ids";
import { z } from "zod";

import { VersionIdentifiable } from "../common/VersionIdentifiable";

export const CompositeIntersectionTypeDefinition = z.record(
  // This should be a `CompositeTypeInternalReferenceId`, but that causes
  // inferred types to be Partial<Record<...>>.
  // See also: https://github.com/colinhacks/zod/pull/2287
  z.string(),
  ColumnTypeId,
);
export const CompositeUnionTypesDefinition = z.record(
  // Should be a `CompositeTypeUnionedSetId`
  z.string(),
  CompositeIntersectionTypeDefinition,
);

export const CompositeTypeDefinition = z.object({
  unions: CompositeUnionTypesDefinition,
});

/**
 * This is sort of how CompositeTypes work:
 *
 * unions: {
 *   intersectionIdA: {
 *     internalReferenceIdA: columnTypeIdA
 *     internalReferenceIdB: columnTypeIdB
 *   }
 *   intersectionIdB: {
 *     internalReferenceIdA: columnTypeIdC
 *   }
 *   intersectionIdC: {
 *     internalReferenceIdB: columnTypeIdD
 *   }
 * }
 *
 * intersectionIdA | intersectionIdB | intersectionIdC
 * intersectionIdA = columnTypeIdA & columnTypeIdB
 *
 * columnTypeIdA = { type: "dateGreaterThan", value: { type: dateReference, reference: internalReferenceIdB }, child: { date ... } }
 * columnTypeIdB = { type: "numberRange", min: 0, max: 10 }
 *
 * columnTypeIdC = { type: "stringEquals", value: "bar" }
 *
 * columnTypeIdD = { type: "stringEquals", value: "qux" }
 *
 *
 * entityType: {
 *  columns: {
 *   columnIdA: {},
 *   columnIdB: {},
 *  },
 *  composites: {
 *    compositeIdA: {
 *      type: "composite",
 *     referenceMap: {
 *      internalReferenceIdA: columnIdA,
 *      internalReferenceIdB: columnIdB,
 *     }
 *   }
 *  }
 */
export const CompositeType = VersionIdentifiable(
  CompositeTypeId,
  CompositeTypeVersionId,
  DataModelMetadataId,
  DataModelMetadataVersionId,
).merge(
  z.object({
    displayMetadata: z
      .object({
        name: z.string(),
        description: z.string().optional(),
      })
      .optional()
      .describe("When this is defined, this column type has some semantic, describable meaning"),

    definition: CompositeTypeDefinition.describe("The actual type definition"),
    entityTypeId: EntityTypeId,
    // VersionId as an access pattern?
    referenceToColumnMap: z.record(
      // This should be a `CompositeTypeInternalReferenceId`, but that causes
      // inferred types to be Partial<Record<...>>.
      // See also: https://github.com/colinhacks/zod/pull/2287
      z.string(),
      ColumnId,
    ),
  }),
);

export type ICompositeIntersectionTypeDefinition = z.infer<typeof CompositeUnionTypesDefinition>;
export type ICompositeUnionTypeDefinition = z.infer<typeof CompositeUnionTypesDefinition>;
export type ICompositeTypeDefinition = z.infer<typeof CompositeTypeDefinition>;
export type ICompositeType = z.infer<typeof CompositeType>;
