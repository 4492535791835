import { ActionId, EntityId, ViewFieldId } from "@archetype/ids";
import * as z from "zod";

import { VersionType } from "../common/Version";
import { EmailExtractedActionInput } from "./ActionLog";
import { ViewFieldValue } from "./ViewFieldValue";

export const EntityActionEmailEffectOverride = z.object({
  subject: z.string(),
  body: z.string(),
});

export type IEntityActionEmailEffectOverride = z.infer<typeof EntityActionEmailEffectOverride>;

// We also store the value as the user may have edited it, so users could come back to the email version,
// and we can check if the value actually comes from the email or has changed.
export const DraftEmailExtractedActionInput = EmailExtractedActionInput.merge(
  z.object({
    value: ViewFieldValue,
    extractedAt: z.date(),
  }),
);

export const EntityActionDraft = z.object({
  /**
   * null for non create action as it can be permissioned by the action and entity
   */
  creatorUserEntityId: EntityId.nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  versionType: VersionType,
  actionId: ActionId,
  /**
   * May be a new entityId that has not been created yet before the action would be executed, or an existing entityId that would be edited by the action
   */
  entityId: EntityId,
  // Maybe we should have loaded vs. not loaded for the version closer to storage? e.g. to not store the display name of a linked entity?
  // which may have changed
  fields: z.record(ViewFieldId, ViewFieldValue.optional()),
  email: EntityActionEmailEffectOverride.optional(),
  /**
   * Only relevant for editable fields with a default autofill, so that we can both store the computation value, and keep computing it,
   * but only if it hasnt been manually edited by a user.
   */
  isUserEditedFields: z.record(ViewFieldId, z.boolean().optional()),
  /**
   * Extracted data from emails that was used to fill in the fields, those
   */
  emailExtractedData: z.record(ViewFieldId, DraftEmailExtractedActionInput.optional()).nullable(),
});

export type IDraftEmailExtractedActionInput = z.infer<typeof DraftEmailExtractedActionInput>;
export type IEntityActionDraft = z.infer<typeof EntityActionDraft>;
