import type { TextMatchTransformer } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type { MenuTextMatch } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { LexicalTypeaheadMenuPlugin, MenuOption } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import type { TextNode } from "lexical";
import { $createTextNode, $isTextNode } from "lexical";
import { useCallback, useMemo, useState } from "react";
import React from "react";
import { createPortal } from "react-dom";
import { useDeepCompareEffect } from "react-use";

import { cn } from "../../../../lib/utils";
import { Icon, type IIconNames } from "../../../atoms/icon";
import { $createMentionNode, MentionNode } from "../nodes/MentionNode";

export interface IMentionsConfig {
  punctuation?: string;
  triggers?: string[];
  lengthLimit?: number;
  aliasLengthLimit?: number;
  suggestionLimit?: number;
}

const DEFAULT_CONFIG: Required<IMentionsConfig> = {
  punctuation: "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;",
  triggers: ["@"],
  lengthLimit: 75,
  aliasLengthLimit: 50,
  suggestionLimit: 5,
};

function buildMentionRegexes(config: Required<IMentionsConfig>): {
  mentionsRegex: RegExp;
  aliasRegex: RegExp;
} {
  const TRIGGERS = config.triggers.join("");
  const PUNC = config.punctuation;

  // Chars we expect to see in a mention (non-space, non-punctuation).
  const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";

  // Non-standard series of chars. Each series must be preceded and followed by
  // a valid char.
  const VALID_JOINS =
    "(?:" +
    "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
    " |" + // E.g. " " in "Josh Duck"
    "[" +
    PUNC +
    "]|" + // E.g. "-' in "Salier-Hellendag"
    ")";

  const AtSignMentionsRegex = new RegExp(
    "(^|\\s|\\()(" +
      "[" +
      TRIGGERS +
      "]" +
      "((?:" +
      VALID_CHARS.toString() +
      VALID_JOINS.toString() +
      "){0," +
      config.lengthLimit.toString() +
      "})" +
      ")$",
  );

  const AtSignMentionsRegexAliasRegex = new RegExp(
    "(^|\\s|\\()(" +
      "[" +
      TRIGGERS +
      "]" +
      "((?:" +
      VALID_CHARS.toString() +
      "){0," +
      config.aliasLengthLimit.toString() +
      "})" +
      ")$",
  );

  return {
    mentionsRegex: AtSignMentionsRegex,
    aliasRegex: AtSignMentionsRegexAliasRegex,
  };
}

export interface IMention {
  id: string;
  name: string;
  icon?: IIconNames;
}

export type IMentionLookupService<T = IMention> = {
  search: (query: string) => Promise<Array<T>>;
};

function useMentionLookupService(
  mentionsLookupService: IMentionLookupService,
  mentionString: string | null,
): Array<IMention> {
  const [results, setResults] = useState<Array<IMention>>([]);

  useDeepCompareEffect(() => {
    if (mentionString == null) {
      setResults([]);

      return;
    }

    void mentionsLookupService.search(mentionString).then(setResults);
  }, [mentionString, mentionsLookupService]);

  return results;
}

function checkForAtSignMentions(
  text: string,
  minMatchLength: number,
  regexes: ReturnType<typeof buildMentionRegexes>,
): MenuTextMatch | null {
  let match = regexes.mentionsRegex.exec(text);

  if (match === null) {
    match = regexes.aliasRegex.exec(text);
  }

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];

    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + (maybeLeadingWhitespace?.length ?? 0),
        matchingString,
        replaceableString: match[2] ?? "",
      };
    }
  }

  return null;
}

function getPossibleQueryMatch(text: string, regexes: ReturnType<typeof buildMentionRegexes>): MenuTextMatch | null {
  return checkForAtSignMentions(text, 1, regexes);
}

class MentionTypeaheadOption extends MenuOption {
  id: string;
  name: string;
  icon?: IIconNames;

  constructor(id: string, name: string, icon?: IIconNames) {
    super(name);
    this.id = id;
    this.name = name;
    this.icon = icon;
  }
}

function MentionsTypeaheadMenuItem({
  isSelected,
  onClick: handleClick,
  onMouseEnter: handleMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
}): React.JSX.Element {
  const handleSetRef = useCallback(
    (el: HTMLElement | null) => {
      option.setRefElement(el);
    },
    [option],
  );

  return (
    <li
      key={option.key}
      ref={handleSetRef}
      aria-selected={isSelected}
      className={cn(
        "hover:bg-accent flex cursor-pointer items-center gap-x-2 rounded bg-transparent px-2 py-1",
        isSelected && "bg-accent",
      )}
      id={option.id}
      role="option"
      tabIndex={-1}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
    >
      {option.icon != null && <Icon className="size-3" name={option.icon} />}
      <span className="text-base">{option.name}</span>
    </li>
  );
}

interface IMentionsPluginProps {
  mentionLookupService: IMentionLookupService;
  config?: IMentionsConfig;
}

export const MENTION_REGEX = /@\[([^\]]+)\]\(([^)]+)\)/;

export const MENTION_TRANSFORMER: TextMatchTransformer = {
  type: "text-match",
  dependencies: [MentionNode],
  export: (node): string | null => {
    if (node instanceof MentionNode) {
      return `@[${node.getMentionName()}](${node.getMentionId()})`;
    }

    return null;
  },
  importRegExp: MENTION_REGEX,
  regExp: MENTION_REGEX,
  replace: (textNode: TextNode, match: RegExpMatchArray): void => {
    const [, name, id] = match;

    if (name != null && id != null) {
      const mentionNode = $createMentionNode(id, name);

      textNode.replace(mentionNode);
    }
  },
};

interface IMenuRenderFnProps {
  selectedIndex: number | null;
  selectOptionAndCleanUp: (option: MentionTypeaheadOption) => void;
  setHighlightedIndex: (index: number) => void;
}

function MentionMenuItem({
  index,
  isSelected,
  onHighlight: handleHighlight,
  onSelect: handleSelect,
  option,
}: {
  index: number;
  isSelected: boolean;
  onHighlight: (index: number) => void;
  onSelect: (option: MentionTypeaheadOption, index: number) => void;
  option: MentionTypeaheadOption;
}): React.JSX.Element {
  const handleClick = useCallback(() => {
    handleSelect(option, index);
  }, [handleSelect, index, option]);

  const handleMouseEnter = useCallback(() => {
    handleHighlight(index);
  }, [handleHighlight, index]);

  return (
    <MentionsTypeaheadMenuItem
      key={option.key}
      index={index}
      isSelected={isSelected}
      option={option}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
    />
  );
}

export function MentionsPlugin({ mentionLookupService, config = {} }: IMentionsPluginProps): React.JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState<string | null>(null);
  const results = useMentionLookupService(mentionLookupService, queryString);

  const mergedConfig = useMemo(
    () => ({
      ...DEFAULT_CONFIG,
      ...config,
    }),
    [config],
  );

  const regexes = useMemo(() => buildMentionRegexes(mergedConfig), [mergedConfig]);

  const options = useMemo(
    () =>
      results
        .map((result) => new MentionTypeaheadOption(result.id, result.name, result.icon))
        .slice(0, mergedConfig.suggestionLimit),
    [results, mergedConfig.suggestionLimit],
  );

  const handleSelectOption = useCallback(
    (selectedOption: MentionTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(selectedOption.id, selectedOption.name);

        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }

        // Create an empty text node after the mention if there isn't one
        let nextSibling = mentionNode.getNextSibling();

        if (!nextSibling || !$isTextNode(nextSibling)) {
          nextSibling = $createTextNode(" ");
          mentionNode.insertAfter(nextSibling);
        }

        // Move selection to the next node
        mentionNode.selectNext();
        closeMenu();
      });
    },
    [editor],
  );

  const checkForMentionMatch = useCallback(
    (text: string) => {
      return getPossibleQueryMatch(text, regexes);
    },
    [regexes],
  );

  const handleQueryChange = useCallback((query: string | null) => {
    setQueryString(query);
  }, []);

  const renderMenu = useCallback(
    (
      anchorElementRef: React.RefObject<HTMLElement>,
      { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex: handleHighlight }: IMenuRenderFnProps,
    ): React.JSX.Element | null => {
      if (!anchorElementRef.current || results.length === 0) {
        return null;
      }

      const handleSelect = (option: MentionTypeaheadOption, index: number): void => {
        handleHighlight(index);
        selectOptionAndCleanUp(option);
      };

      return createPortal(
        <div className="border-border bg-paper fixed z-[999] flex flex-wrap gap-1 rounded-md border p-1 shadow">
          <ul className="flex flex-col gap-1">
            {options.map((option, index) => (
              <MentionMenuItem
                key={option.key}
                index={index}
                isSelected={selectedIndex === index}
                option={option}
                onHighlight={handleHighlight}
                // eslint-disable-next-line react/jsx-no-bind -- internal to this fn cant use callback
                onSelect={handleSelect}
              />
            ))}
          </ul>
        </div>,
        anchorElementRef.current,
      );
    },
    [options, results.length],
  );

  // Return null if editor is not editable
  if (!editor.isEditable()) {
    return null;
  }

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      menuRenderFn={renderMenu}
      options={options}
      triggerFn={checkForMentionMatch}
      onQueryChange={handleQueryChange}
      onSelectOption={handleSelectOption}
    />
  );
}
