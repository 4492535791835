import type { ILoadedRelationViewField } from "@archetype/core";
import type { IEntityTypeCore, IVersionType } from "@archetype/dsl";
import { builderTrpc } from "@archetype/trpc-react";

import { useRelationFilterSearch } from "./useRelationFilterSearch";

interface IUseRelationEntitiesProps {
  entityType: IEntityTypeCore;
  otherEntityType: IEntityTypeCore;
  relationView: ILoadedRelationViewField;
  versionType: IVersionType;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- let TS infer the return type
export const useRelationEntities = (props: IUseRelationEntitiesProps) => {
  const { entityType, relationView, otherEntityType, versionType } = props;

  const { filterQueryWithSearch, handleSearch: setDisplayNameSearchTerm } = useRelationFilterSearch(otherEntityType);

  const state = builderTrpc.dataLoading.getLoadedEntities.useInfiniteQuery(
    {
      organizationId: entityType.organizationId,
      dataLoadingQuery: {
        filters: filterQueryWithSearch,
        versionType,
        entityType: {
          type: "joinTraversal",
          relationTypeId: relationView.relation.id,
          relationDirection: relationView.direction,
          startingEntities: { type: "entityTypeId", entityTypeId: entityType.id },
        },
      },
      dataLoadingConfig: { specificColumns: [otherEntityType.displayNameColumn], specificRelations: [] },
    },
    {
      trpc: { abortOnUnmount: true, context: { skipBatch: true } },
      initialCursor: 0,
      placeholderData: (prev) => (prev?.pages[0]?.entities[0]?.entityTypeId !== otherEntityType.id ? undefined : prev),
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    },
  );

  return { state, setDisplayNameSearchTerm };
};
