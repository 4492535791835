import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { EventListComponentDefinitionId } from "@archetype/ids";

export const EventListComponentIdDeclarations = {
  id: EventListComponentDefinitionId,
  inputs: ["multiEntityQueryDataInput"],
  outputs: [],
  compatibleSemanticSizes: ["sidebar", "split-vertical", "main"],
  validCosmeticOptionalProps: ["label"],
} as const;

export const EventListComponentDefinition: ITypedComponentDefinitionDeclaration<
  typeof EventListComponentIdDeclarations
> = {
  constIds: EventListComponentIdDeclarations,
  displayMetadata: {
    name: "Event list",
    description: "A list of sequential events",
  },
  inputs: {
    multiEntityQueryDataInput: {
      displayMetadata: {
        name: "Event data",
        description: "Data to populate the event list with",
      },
      optional: false,
    },
  },
  outputs: {}, // Could add selection
};
