import * as React from "react";
import { useCallback } from "react";

import { cn } from "../../lib/utils";
import { EmailComposer, type IEmailComposer } from "../molecules/email/email-composer";
import { Eyebrow } from "./eyebrow";
import { Skeleton } from "./skeleton";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

type ILiveFieldIndicatorSimple = {
  type: "live" | "ai" | "lookup";
};

type ILiveFieldIndicatorEmail = {
  type: "email";
  emailContent:
    | {
        type: "loading";
      }
    | {
        type: "emailContent";
        email: IEmailComposer;
      };
};

type ILiveFieldIndicator = {
  className?: string;
} & (ILiveFieldIndicatorSimple | ILiveFieldIndicatorEmail);

export const LiveFieldIndicator: React.FC<ILiveFieldIndicator> = ({ className, ...props }) => {
  const renderContent = useCallback(() => {
    switch (props.type) {
      case "ai": {
        return (
          <TooltipContent>
            <p>Computes automatically using AI</p>
          </TooltipContent>
        );
      }
      case "lookup": {
        return (
          <TooltipContent>
            <p>Computes automatically using lookup</p>
          </TooltipContent>
        );
      }

      case "email": {
        return (
          <TooltipContent asChild>
            {props.emailContent.type === "emailContent" ? (
              <EmailComposer className="min-w-96 p-0" {...props.emailContent.email} />
            ) : (
              <div className="min-w-96 p-0">
                <Skeleton className="h-10 w-32" />
              </div>
            )}
          </TooltipContent>
        );
      }

      case "live": {
        return (
          <TooltipContent>
            <p>Computes automatically</p>
          </TooltipContent>
        );
      }
    }
  }, [props]);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Eyebrow className={cn("leading-6", className)} variant="secondary">
          {props.type}
        </Eyebrow>
      </TooltipTrigger>
      {renderContent()}
    </Tooltip>
  );
};
