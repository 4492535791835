import type { IColumn } from "@archetype/dsl";
import { Skeleton } from "@archetype/ui";

export const ColumnName: React.FC<{
  fullColumnInfo: IColumn | undefined;
  isLoading: boolean;
}> = ({ fullColumnInfo, isLoading }) => {
  if (isLoading) {
    return <Skeleton>Column name</Skeleton>;
  }

  if (fullColumnInfo == null) {
    return <div className="italic">Unknown</div>;
  }

  return <div>{fullColumnInfo.displayMetadata.name}</div>;
};
