import { ColumnId, EntityTypeVersionId, ValidationId } from "@archetype/ids";
import { z } from "zod";

export const ColumnValidation = z.object({
  id: ValidationId,
  rawLogic: z
    .string()
    .describe(
      "A natural language description of the validation logic. Do not describe logic that is already implied by the column type.",
    ),
  logic: z.string().describe("The logic of the validation"),
});
export type IColumnValidation = z.infer<typeof ColumnValidation>;

export const ColumnValidationWithIndex = ColumnValidation.merge(
  z.object({
    columnId: ColumnId,
    entityTypeVersionId: EntityTypeVersionId,
  }),
);
export type IColumnValidationWithIndex = z.infer<typeof ColumnValidationWithIndex>;
