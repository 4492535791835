import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { ImageComponentDefinitionId } from "@archetype/ids";

export const ImageComponentIdDeclarations = {
  id: ImageComponentDefinitionId,
  inputs: ["singleEntityDataInput"],
  outputs: [],
  validCosmeticOptionalProps: [],
  compatibleSemanticSizes: ["summary", "medium", "split-vertical", "main"],
} as const;

export const ImageComponentDefinition: ITypedComponentDefinitionDeclaration<typeof ImageComponentIdDeclarations> = {
  constIds: ImageComponentIdDeclarations,
  displayMetadata: {
    name: "Image",
    description: "A card that displays a single image",
  },
  inputs: {
    singleEntityDataInput: {
      displayMetadata: {
        name: "Image data",
        description: "Image data to display",
      },
      optional: false,
      nullable: false,
    },
  },
  outputs: {},
};
