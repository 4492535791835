import type { IActionCore, IActionInput, IEntityTypeCore } from "@archetype/dsl";
import { computeColumnViewFieldId, isTargetEntityTypeCore, transitionsWithCreationTransition } from "@archetype/dsl";
import { keyByNoUndefined } from "@archetype/utils";
import { difference, uniq, uniqBy } from "lodash";

import { isCreateAction } from "../../dataModel/isCreateAction";
import { standardizeActionInStateMachine } from "../standardizeActionInStateMachine";
import { DATA_MODEL_OPERATIONS, getDisabledOperationsByViewFieldId } from "./disabledOperations";
import type { IOperationsEdits, ISetActionOperation } from "./operations";

export const applySetActionOperation = (
  operation: ISetActionOperation,
  currentInfo: IOperationsEdits,
): IOperationsEdits => {
  const currentStateMachine = currentInfo.stateMachineEdits[operation.applicationGroupId];

  if (currentStateMachine == null) {
    throw new Error("State machine not found");
  }

  if (currentStateMachine.dataModel.targetEntityTypeId !== operation.targetEntityTypeId) {
    throw new Error("Incorrect target entity type id provided");
  }

  const currentAction = currentInfo.newOrEditedActions[operation.action.actionId];

  if (currentAction == null) {
    throw new Error("Action to be edited not found");
  }

  const { action: initialActionEdit, targetEntityTypeId } = operation;

  const entityType = currentInfo.newOrEditedEntityTypes[targetEntityTypeId];

  if (entityType == null) {
    throw new Error("Entity type not found");
  }

  if (!isTargetEntityTypeCore(entityType)) {
    throw new Error("Entity type is not process entity type");
  }

  const editedActionInputs: IActionInput[] = initialActionEdit.actionDefinition.inputs;

  const editedActionInputsByFieldId = keyByNoUndefined(editedActionInputs, (input) => input.viewField.id);

  const editedEntityType: IEntityTypeCore = {
    ...entityType,
    columns: entityType.columns.map((c) => {
      const columnFieldId = computeColumnViewFieldId(c.id);
      const actionInput = editedActionInputsByFieldId[columnFieldId];

      // Make entity colum nonNullable if it's a create action
      const nonNullable =
        isCreateAction(initialActionEdit.actionDefinition) && actionInput != null
          ? actionInput.required
          : c.nonNullable;

      return {
        ...c,
        nonNullable,
      };
    }),
  };

  const disabledOperationsForEditedEntityType = getDisabledOperationsByViewFieldId(editedEntityType);

  const modifiedInputs = uniqBy(
    editedActionInputs.filter(
      (input) =>
        disabledOperationsForEditedEntityType[input.viewField.id]?.has(DATA_MODEL_OPERATIONS.addToForm) !== true,
    ),
    (input) => input.viewField.id,
  );

  const transitionsWithCreation = transitionsWithCreationTransition(currentStateMachine);
  const editedTransition = transitionsWithCreation.find(
    (transition) => transition.actionId === initialActionEdit.actionId,
  );

  if (editedTransition == null) {
    throw new Error("Transition not found for action id");
  }

  const editedAction: IActionCore = {
    ...currentAction,
    id: initialActionEdit.actionId,
    actionDefinition: {
      ...initialActionEdit.actionDefinition,
      fromStates: editedTransition.from != null ? [editedTransition.from] : undefined,
      toState: editedTransition.to,
      contextualFields: uniq(
        difference(
          initialActionEdit.actionDefinition.contextualFields,
          initialActionEdit.actionDefinition.inputs.map((input) => input.viewField),
        ),
      ),
      inputs: modifiedInputs,
      authorizedByAnyOf: currentAction.actionDefinition.authorizedByAnyOf,
    },
  };

  const standardizedEditedAction = standardizeActionInStateMachine(editedAction, editedTransition, entityType);

  return {
    ...currentInfo,
    newOrEditedEntityTypes: {
      ...currentInfo.newOrEditedEntityTypes,
      [editedEntityType.id]: editedEntityType,
    },
    newOrEditedActions: {
      ...currentInfo.newOrEditedActions,
      [standardizedEditedAction.id]: standardizedEditedAction,
    },
  };
};
