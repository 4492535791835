import type { ISingleFormValidationErrors, IValidationErrors } from "@archetype/dsl";
import { forEach } from "@archetype/utils";
import { clone, cloneDeep, uniq } from "lodash";

const mergeSingleFormErrors = (
  a: ISingleFormValidationErrors,
  b: ISingleFormValidationErrors,
): ISingleFormValidationErrors => {
  const result: ISingleFormValidationErrors = {
    generalErrors: uniq(a.generalErrors.concat(b.generalErrors)),
    fieldErrors: cloneDeep(a.fieldErrors),
  };

  // Then, merge in errors from b
  forEach(b.fieldErrors, (errors, fieldId) => {
    if (result.fieldErrors[fieldId] == null) {
      result.fieldErrors[fieldId] = clone(errors);
    } else {
      // Append errors from b to existing errors
      result.fieldErrors[fieldId]?.push(...errors);
    }
  });

  return result;
};

export const mergeFormValidationErrors = (a: IValidationErrors, b: IValidationErrors): IValidationErrors => {
  const singleFormResult = mergeSingleFormErrors(a, b);

  const result: IValidationErrors = {
    ...singleFormResult,
    nestedActionErrors: cloneDeep(a.nestedActionErrors),
  };

  // Then, merge in nested action errors from b
  forEach(b.nestedActionErrors, (errorsForEntityType, entityTypeId) => {
    if (result.nestedActionErrors[entityTypeId] == null) {
      result.nestedActionErrors[entityTypeId] = clone(errorsForEntityType);
    } else {
      forEach(errorsForEntityType, (errorsForEntity, entityId) => {
        if (errorsForEntity == null) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above
        result.nestedActionErrors[entityTypeId]![entityId] = mergeSingleFormErrors(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above
          result.nestedActionErrors[entityTypeId]![entityId] ?? {
            generalErrors: [],
            fieldErrors: {},
          },
          errorsForEntity,
        );
      });
    }
  });

  return result;
};

export const __test = {
  mergeSingleFormErrors,
};
