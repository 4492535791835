import { EntityTypeId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { EntityTypeShapeName } from "../dataModel/EntityTypeShapeName";
import { StateColor } from "../stateMachine/stateColors";

export const EntityTypeChangeBase = z.object({
  entityTypeId: EntityTypeId,
});
export type IEntityTypeChangeBase = z.infer<typeof EntityTypeChangeBase>;

export const EntityTypeNameChange = EntityTypeChangeBase.extend({
  changeEntityTypeNameTo: ReadableString,
  changePluralEntityTypeNameTo: ReadableString,
});
export type IEntityTypeNameChange = z.infer<typeof EntityTypeNameChange>;

export const EntityTypeColorChange = EntityTypeChangeBase.extend({
  changeColorTo: StateColor,
});
export type IEntityTypeColorChange = z.infer<typeof EntityTypeColorChange>;

export const EntityTypeShapeChange = EntityTypeChangeBase.extend({
  changeShapeTo: EntityTypeShapeName,
});
export type IEntityTypeShapeChange = z.infer<typeof EntityTypeShapeChange>;

export const EntityTypeChange = z.union([EntityTypeNameChange, EntityTypeColorChange, EntityTypeShapeChange]);
export type IEntityTypeChange = z.infer<typeof EntityTypeChange>;

export function isEntityTypeNameChange(change: IEntityTypeChange): change is IEntityTypeNameChange {
  return "changeEntityTypeNameTo" in change;
}

export function isEntityTypeColorChange(change: IEntityTypeChange): change is IEntityTypeColorChange {
  return "changeColorTo" in change;
}

export function isEntityTypeShapeChange(change: IEntityTypeChange): change is IEntityTypeShapeChange {
  return "changeShapeTo" in change;
}
