import type { ITypedComponentDefinitionDeclaration } from "@archetype/dsl";
import { TableGroupedComponentDefinitionId } from "@archetype/ids";

export const TableGroupedComponentIdDeclarations = {
  id: TableGroupedComponentDefinitionId,
  inputs: ["multiEntityQueryDataInput"],
  outputs: ["userSelectedSingleEntity", "userSelectedLinkedEntity", "actionSelected"],
  compatibleSemanticSizes: ["main", "medium", "split-horizontal", "split-vertical"],
  validCosmeticOptionalProps: ["label"],
} as const;

export const TableGroupedComponentDefinition: ITypedComponentDefinitionDeclaration<
  typeof TableGroupedComponentIdDeclarations
> = {
  constIds: TableGroupedComponentIdDeclarations,
  displayMetadata: {
    name: "Grouped table",
    description: "A grouped table component",
  },
  inputs: {
    multiEntityQueryDataInput: {
      displayMetadata: {
        name: "Table data",
        description: "Data to populate the Table with",
      },
      optional: false,
    },
  },
  outputs: {
    userSelectedSingleEntity: {
      displayMetadata: {
        name: "Table selection",
        description: "The selected item in the Table",
      },
      optional: false,
      nullable: true,
    },
    userSelectedLinkedEntity: {
      displayMetadata: {
        name: "Side action in the table to select a linked entity",
        description: "Will not show as the selected item inside the table",
      },
      optional: false,
      nullable: true,
    },
    actionSelected: {
      displayMetadata: {
        name: "Action callback",
        description: "The callback to show the action",
      },
      optional: false,
    },
  },
};
