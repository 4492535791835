import { EntityId, FieldComputationId, RelationId } from "@archetype/ids";
import { z } from "zod";

import { VersionType } from "../common/Version";
import { ComputationInputValues } from "./ComputationInputValues";
import { FieldComputationHashedInputValues, FieldComputationStatus } from "./FieldComputation";
import { RelationDirection } from "./RelationBase";

export const AIRelationComputationInfo = z.object({
  computationId: FieldComputationId,
  startedAt: z.date(),

  entityId: EntityId,

  relationId: RelationId,
  direction: RelationDirection,

  versionType: VersionType,

  /**
   * instructions used for computing this result
   * Note the instructions on the data model can change, invalidating this result
   */
  inputInstructions: z.string(),
  /**
   * Values of the entity provided to the model for computing the result, technically can be a subset of the entity (when we know which subset is relevant)
   */
  inputValues: FieldComputationHashedInputValues,

  // When recomputing, should the instructions contain the previous values or the new values? (Before it returns)
  // Or should we track separately the values being computed for and the values of the current result?
  status: FieldComputationStatus,
});
export type IAIRelationComputationInfo = z.infer<typeof AIRelationComputationInfo>;

export const AIRelationComputationExecution = AIRelationComputationInfo.omit({
  startedAt: true,
  inputValues: true,
}).merge(
  z.object({
    inputValues: ComputationInputValues,
  }),
);
export type IAIRelationComputationExecution = z.infer<typeof AIRelationComputationExecution>;

export const AIRelationComputationResult = AIRelationComputationInfo;
export type IAIRelationComputationResult = z.infer<typeof AIRelationComputationResult>;
