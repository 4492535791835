"use client";

import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";

import { cn } from "../../lib/utils";
import { ScrollArea } from "./scroll-area/scroll-area";

interface IPanelProps {
  className?: string;
  children: React.ReactNode;
}

export const PanelContainer: React.FC<IPanelProps> = ({ children, className }) => {
  return (
    <AnimatePresence>
      <motion.div
        key="panel"
        animate={{ x: 0 }}
        className={cn(
          "bg-paper shadow-uniform-lg sm:border-border fixed inset-y-0 right-0 z-30 flex w-full overflow-hidden border border-transparent sm:inset-2 sm:left-auto sm:w-fit sm:min-w-[min(900px,calc(100vw-16px))] sm:max-w-[calc(100vw-16px)] sm:rounded-md",
          className,
        )}
        exit={{ x: "100%" }}
        initial={{ x: "100%" }}
        transition={{ type: "tween" }}
      >
        <ScrollArea className={cn("bg-paper @container flex h-full shrink-0 grow flex-col", className)}>
          {children}
        </ScrollArea>
      </motion.div>
    </AnimatePresence>
  );
};
