import type {
  ICrossColumnAndConditions,
  ICrossColumnOrConditions,
  IDataLoadingQuery,
  IDataLoadingQueryEntityTypeReference,
  IRelatedToFilter,
} from "@archetype/dsl";
import type { IEntityTypeId, IRelationId } from "@archetype/ids";

export interface IQueryReference {
  entityTypeIds: IEntityTypeId[];
  relationIds: IRelationId[];
}

// This is a function to avoid mutating the empty object reference
const getEmptyQueryReference = (): IQueryReference => ({ entityTypeIds: [], relationIds: [] });

const mergeQueryReferences = (...references: IQueryReference[]): IQueryReference => {
  const finalEntityTypeIds = new Set<IEntityTypeId>();
  const finalRelationIds = new Set<IRelationId>();

  references.forEach((reference) => {
    reference.entityTypeIds.forEach((entityTypeId) => finalEntityTypeIds.add(entityTypeId));
    reference.relationIds.forEach((relationId) => finalRelationIds.add(relationId));
  });

  return {
    entityTypeIds: Array.from(finalEntityTypeIds),
    relationIds: Array.from(finalRelationIds),
  };
};

const collectEntityTypeReferenceReferences = (entityType: IDataLoadingQueryEntityTypeReference): IQueryReference => {
  switch (entityType.type) {
    case "entityTypeId": {
      return {
        entityTypeIds: [entityType.entityTypeId],
        relationIds: [],
      };
    }
    case "joinTraversal": {
      return {
        entityTypeIds: [entityType.startingEntities.entityTypeId],
        relationIds: [entityType.relationTypeId],
      };
    }
  }
};

const collectRelatedToFiltersReferences = (filters: IRelatedToFilter): IQueryReference => ({
  entityTypeIds: [],
  relationIds: [filters.relationId],
});

const collectCrossColumnOrConditionsReferences = (conditions: ICrossColumnOrConditions): IQueryReference => {
  const references = conditions.oredCrossColumnAndConditions.map(collectCrossColumnAndConditionsReferences);

  return mergeQueryReferences(getEmptyQueryReference(), ...references);
};

const collectCrossColumnAndConditionsReferences = (conditions: ICrossColumnAndConditions): IQueryReference => {
  const references = [
    ...(conditions.andedRelatedToFilters ?? []).map(collectRelatedToFiltersReferences),
    ...conditions.andedCrossColumnOrConditions.map(collectCrossColumnOrConditionsReferences),
  ];

  return mergeQueryReferences(getEmptyQueryReference(), ...references);
};

export const collectQueryReferences = (query: IDataLoadingQuery): IQueryReference => {
  const references = [
    collectEntityTypeReferenceReferences(query.entityType),
    query.filters != null ? collectCrossColumnAndConditionsReferences(query.filters) : getEmptyQueryReference(),
  ];

  return mergeQueryReferences(getEmptyQueryReference(), ...references);
};

export const __collectQueryReferencesTest__ = {
  collectEntityTypeReferenceReferences,
  collectRelatedToFiltersReferences,
  collectCrossColumnOrConditionsReferences,
  collectCrossColumnAndConditionsReferences,
  mergeQueryReferences,
};
