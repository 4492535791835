import type { ILoadedAction, ILoadedEntity } from "@archetype/core";
import type { IVersionType, IViewFieldValue } from "@archetype/dsl";
import type { IEntityId, IViewFieldId } from "@archetype/ids";
import type { IButtonProps } from "@archetype/ui";
import { Button, cn, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@archetype/ui";
import { type BaseSyntheticEvent, useCallback, useState } from "react";

import type { IGetActionRoute } from "../api";
import { ActionMenuItem } from "./ActionMenuItem";

interface IExecutableAction {
  action: ILoadedAction;
  entityId: IEntityId | undefined;
  defaultValues: Record<IViewFieldId, IViewFieldValue> | undefined;
}

interface IMultiActionPopoverButtonProps {
  className?: string;
  variant?: IButtonProps["variant"];
  versionType: IVersionType;
  actions: IExecutableAction[];
  getActionRoute: IGetActionRoute;
  getFullPageActionRoute: IGetActionRoute;
  onActionExecuted?: (entity: ILoadedEntity | undefined) => Promise<void>;
  label?: string;
}

export const MultiActionPopoverButton: React.FC<IMultiActionPopoverButtonProps> = ({
  actions,
  className,
  variant,
  versionType,
  getActionRoute,
  getFullPageActionRoute,
  onActionExecuted: handleActionExecuted,
  label,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTriggerClick = useCallback((e?: BaseSyntheticEvent): void => {
    e?.stopPropagation();
    e?.preventDefault();
  }, []);

  const handleOpenChange = useCallback((open: boolean): void => {
    setIsOpen(open);
  }, []);

  if (actions.length === 0) {
    return null;
  }

  return (
    <DropdownMenu onOpenChange={handleOpenChange}>
      <DropdownMenuTrigger asChild>
        <Button
          className={cn(className, isOpen && "bg-accent-background")}
          iconLeft="more-horizontal"
          size="sm"
          variant={variant}
          onClick={handleTriggerClick}
        >
          {label}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="w-40">
        {actions.map(({ action, entityId, defaultValues }) => (
          <ActionMenuItem
            key={action.id}
            action={action}
            entityId={entityId}
            getActionRoute={getActionRoute}
            getFullPageActionRoute={getFullPageActionRoute}
            presetParameters={{ defaultValues }}
            versionType={versionType}
            onActionExecuted={handleActionExecuted}
          />
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
