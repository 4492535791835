import { cn, Input } from "@archetype/ui";
import { useCallback } from "react";

import type { IActionInputComponent } from "../types";

export const StringInput: IActionInputComponent = ({ className, field, onChange, readOnly = false }) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(
        e.target.value === ""
          ? { type: "null" }
          : {
              type: "string",
              value: e.target.value,
            },
      );
    },
    [onChange],
  );

  const handleBlur = field.onBlur;

  const fieldValue = field.value?.type === "string" ? field.value.value : undefined;

  return (
    <Input
      ref={field.ref}
      className={cn("w-full", className)}
      defaultValue={fieldValue}
      name={field.name}
      readOnly={readOnly}
      type="string"
      value={fieldValue}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};
