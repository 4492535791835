import type {
  IAIColumn,
  IAIRelation,
  IDerivedColumn,
  IDerivedRelation,
  ILogicColumn,
  ILogicRelation,
  ILookupColumn,
  ILookupRelation,
} from "@archetype/dsl";
import {
  Autofill,
  Column,
  ColumnViewField,
  computeColumnViewFieldId,
  computeRelationViewFieldId,
  type IColumn,
  type IRelationCore,
  type IRelationDirection,
  isAIColumn,
  isAIRelation,
  isDerivedColumn,
  isDerivedRelation,
  isLogicColumn,
  isLogicRelation,
  isLookupColumn,
  isLookupRelation,
  RelationCore,
  RelationViewField,
} from "@archetype/dsl";
import { ReadableString } from "@archetype/utils";
import * as z from "zod";

// FE type
export const LoadedColumnViewField = ColumnViewField.merge(
  z.object({
    column: Column,
    displayName: ReadableString,
    autofill: Autofill.optional(),
  }),
);
export type ILoadedColumnViewField = z.infer<typeof LoadedColumnViewField>;

export const LoadedRelationViewField = RelationViewField.merge(
  z.object({
    relation: RelationCore,
    displayName: ReadableString,
    autofill: Autofill.optional(),
  }),
);
export type ILoadedRelationViewField = z.infer<typeof LoadedRelationViewField>;

export const LoadedViewField = z.discriminatedUnion("type", [LoadedColumnViewField, LoadedRelationViewField]);
export type ILoadedViewField = z.infer<typeof LoadedViewField>;

// constructor methods

export const createLoadedColumnViewField = (column: IColumn): ILoadedColumnViewField => {
  return {
    id: computeColumnViewFieldId(column.id),
    type: "column",
    displayName: column.displayMetadata.name,
    columnId: column.id,
    autofill: column.autofill,
    column,
  };
};
export const createLoadedRelationViewField = (
  relation: IRelationCore,
  direction: IRelationDirection,
): ILoadedRelationViewField => {
  return {
    id: computeRelationViewFieldId(relation.id, direction),
    type: "directionalRelation",
    displayName: direction === "aToB" ? relation.displayMetadataFromAToB.name : relation.displayMetadataFromBToA.name,
    relationId: relation.id,
    autofill: relation.autofill?.direction === direction ? relation.autofill.autofill : undefined,
    direction,
    relation,
  };
};

export const isLoadedColumnViewField = (viewField: ILoadedViewField): viewField is ILoadedColumnViewField =>
  viewField.type === "column";

export const isLoadedRelationViewField = (viewField: ILoadedViewField): viewField is ILoadedRelationViewField =>
  viewField.type === "directionalRelation";

type IAIColumnViewField = Omit<ILoadedColumnViewField, "column" | "autofill"> & {
  column: IAIColumn;
  autofill: IAIColumn["autofill"];
};
type IAIRelationViewField = Omit<ILoadedRelationViewField, "relation" | "autofill"> & {
  relation: IAIRelation;
  autofill: IAIRelation["autofill"]["autofill"];
};

export type IAIViewField = IAIColumnViewField | IAIRelationViewField;
export const isAIViewField = (viewField: ILoadedViewField): viewField is IAIViewField =>
  (isLoadedColumnViewField(viewField) && isAIColumn(viewField.column)) ||
  (isLoadedRelationViewField(viewField) && isAIRelation(viewField.relation));

type ILogicColumnViewField = Omit<ILoadedColumnViewField, "column" | "autofill"> & {
  column: ILogicColumn;
  autofill: ILogicColumn["autofill"];
};
type ILogicRelationViewField = Omit<ILoadedRelationViewField, "relation" | "autofill"> & {
  relation: ILogicRelation;
  autofill: ILogicRelation["autofill"]["autofill"];
};

export type ILogicViewField = ILogicColumnViewField | ILogicRelationViewField;
export const isLogicViewField = (viewField: ILoadedViewField): viewField is ILogicViewField =>
  (isLoadedColumnViewField(viewField) && isLogicColumn(viewField.column)) ||
  (isLoadedRelationViewField(viewField) && isLogicRelation(viewField.relation));

type ILookupColumnViewField = Omit<ILoadedColumnViewField, "column" | "autofill"> & {
  column: ILookupColumn;
  autofill: ILookupColumn["autofill"];
};
type ILookupRelationViewField = Omit<ILoadedRelationViewField, "relation" | "autofill"> & {
  relation: ILookupRelation;
  autofill: ILookupRelation["autofill"]["autofill"];
};

export type ILookupViewField = ILookupColumnViewField | ILookupRelationViewField;
export const isLookupViewField = (viewField: ILoadedViewField): viewField is ILookupViewField =>
  (isLoadedColumnViewField(viewField) && isLookupColumn(viewField.column)) ||
  (isLoadedRelationViewField(viewField) && isLookupRelation(viewField.relation));

type IDerivedColumnViewField = Omit<ILoadedColumnViewField, "column" | "autofill"> & {
  column: IDerivedColumn;
  autofill: IDerivedColumn["autofill"];
};
type IDerivedRelationViewField = Omit<ILoadedRelationViewField, "relation" | "autofill"> & {
  relation: IDerivedRelation;
  autofill: IDerivedRelation["autofill"]["autofill"];
};

export type IDerivedViewField = IDerivedColumnViewField | IDerivedRelationViewField;
export const isDerivedViewField = (viewField: ILoadedViewField): viewField is IDerivedViewField =>
  (isLoadedColumnViewField(viewField) && isDerivedColumn(viewField.column)) ||
  (isLoadedRelationViewField(viewField) && isDerivedRelation(viewField.relation));
