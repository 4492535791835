import type { IOrganization } from "@archetype/dsl";

import type { ILoadedAction } from "../apiTypes/LoadedActionType";
import { createFileLogger } from "../logger";
import type { IWorkflowDescription } from "./emailActionArguments";

const logger = createFileLogger("inboundEmailWorkflowMatching");

/**
 * Validates a workflow match against available workflows and organizations
 */
export const validateWorkflowMatch = (input: {
  match: {
    type: string;
    entityName?: string | null;
    actionName?: string | null;
  };
  workflowsByName: Record<string, IWorkflowDescription>;
  organizations: Record<string, IOrganization>;
  orgId: string;
  primaryOrganizationId: string | null;
}):
  | {
      name: string;
      isPrimary: boolean;
      workflow: IWorkflowDescription;
      action: ILoadedAction;
    }
  | undefined => {
  const { match, workflowsByName, organizations, orgId, primaryOrganizationId } = input;

  if (match.type === "no match" || match.entityName == null) {
    logger.debug({ match }, "no match found");

    return undefined;
  }

  const workflow = workflowsByName[match.entityName];

  if (workflow == null) {
    logger.debug({ match }, "no workflow found");

    return undefined;
  }

  const org = organizations[orgId];

  if (org == null) {
    logger.debug({ match }, "no org found");

    return undefined;
  }

  if (match.actionName == null) {
    logger.debug({ match }, "no action name found");

    return undefined;
  }

  const action = workflow.actions.find((a) => a.displayMetadata.name === match.actionName);

  if (action == null) {
    logger.debug({ match }, "no action found");

    return undefined;
  }

  return {
    name: org.name,
    isPrimary: org.id === primaryOrganizationId,
    workflow,
    action,
  };
};

/**
 * Finds a matching workflow and action based on the best match criteria
 */
export const findMatchingWorkflow = (
  bestMatch: {
    type: "match";
    entityName: string;
    actionName: string;
    organization: string;
  },
  allWorkflows: IWorkflowDescription[],
): { action: ILoadedAction; workflow: IWorkflowDescription } | undefined =>
  allWorkflows
    .flatMap((w) => w.actions.map((a) => ({ action: a, workflow: w })))
    .find(
      ({ action, workflow }) =>
        action.displayMetadata.name === bestMatch.actionName &&
        workflow.entityName === bestMatch.entityName &&
        workflow.organizationInfo.organization.name === bestMatch.organization,
    );

/**
 * Validates that a best match contains all required fields
 */
export const validateBestMatch = (bestMatch: {
  type: string;
  entityName?: string | null;
  actionName?: string | null;
  organization?: string | null;
}): bestMatch is {
  type: "match";
  entityName: string;
  actionName: string;
  organization: string;
} =>
  bestMatch.type === "match" &&
  bestMatch.entityName != null &&
  bestMatch.actionName != null &&
  bestMatch.organization != null;
