import { useCallback } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableNavigationContext } from "../..";
import { DataTableEditingContext } from "../context/DataTableEditingContext";
import { DataTableSelectionContext } from "../context/DataTableSelectionContext";

interface IUseCellKeyboardNavigation {
  disabled?: boolean;
}

export function useCellKeyboardNavigation({ disabled }: IUseCellKeyboardNavigation): {
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
} {
  const selectedCells = useContextSelector(DataTableSelectionContext, (state) => state.selectedCells);
  const lastSelectedCell = useContextSelector(DataTableSelectionContext, (state) => state.lastSelectedCell);
  const editableCell = useContextSelector(DataTableEditingContext, (state) => state.editableCell);
  const setEditableCell = useContextSelector(DataTableEditingContext, (state) => state.setEditableCell);
  const navigateCell = useContextSelector(DataTableNavigationContext, (state) => state.navigateCell);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (disabled === true) return;

      if (event.key === "Escape" && editableCell != null) {
        event.preventDefault();
        event.stopPropagation();

        setEditableCell(null);
      }

      // No keyboard nav if no cells are selected
      if (selectedCells.size === 0) {
        return;
      }

      if (selectedCells.size > 1) {
        // TODO: Implement multi-cell selection keyboard navigation
        return;
      }

      if (lastSelectedCell == null) {
        return;
      }

      switch (event.key) {
        case "ArrowUp":
        case "ArrowDown":
        case "ArrowLeft":
        case "ArrowRight": {
          event.preventDefault();
          event.stopPropagation();

          if (event.ctrlKey || event.metaKey) {
            if (event.key === "ArrowLeft") {
              navigateCell("firstCol", lastSelectedCell.rowId, lastSelectedCell.colId);
            } else if (event.key === "ArrowRight") {
              navigateCell("lastCol", lastSelectedCell.rowId, lastSelectedCell.colId);
            } else {
              navigateCell(
                event.key.replace("Arrow", "").toLowerCase() === "up" ? "pageUp" : "pageDown",
                lastSelectedCell.rowId,
                lastSelectedCell.colId,
              );
            }
          } else {
            navigateCell(
              event.key.replace("Arrow", "").toLowerCase() as "up" | "down" | "left" | "right",
              lastSelectedCell.rowId,
              lastSelectedCell.colId,
            );
          }
          break;
        }

        case "Tab": {
          event.preventDefault();
          event.stopPropagation();
          navigateCell(event.shiftKey ? "left" : "right", lastSelectedCell.rowId, lastSelectedCell.colId);
          break;
        }

        case "PageUp": {
          event.preventDefault();
          event.stopPropagation();
          navigateCell("pageUp", lastSelectedCell.rowId, lastSelectedCell.colId);
          break;
        }

        case "PageDown": {
          event.preventDefault();
          event.stopPropagation();
          navigateCell("pageDown", lastSelectedCell.rowId, lastSelectedCell.colId);
          break;
        }

        case "Home": {
          event.preventDefault();
          event.stopPropagation();
          if (event.ctrlKey || event.metaKey) {
            navigateCell("firstRow", lastSelectedCell.rowId, lastSelectedCell.colId);
          } else {
            navigateCell("firstCol", lastSelectedCell.rowId, lastSelectedCell.colId);
          }
          break;
        }

        case "End": {
          event.preventDefault();
          event.stopPropagation();
          if (event.ctrlKey || event.metaKey) {
            navigateCell("lastRow", lastSelectedCell.rowId, lastSelectedCell.colId);
          } else {
            navigateCell("lastCol", lastSelectedCell.rowId, lastSelectedCell.colId);
          }
          break;
        }
      }
    },
    [disabled, editableCell, selectedCells.size, lastSelectedCell, setEditableCell, navigateCell],
  );

  return { onKeyDown: handleKeyDown };
}
