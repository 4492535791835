import type { IEntityTypeCore, ILLMState, IRelationCore, IStateMachine } from "@archetype/dsl";
import type { IRelationId, IStateId } from "@archetype/ids";
import type { IReadableString } from "@archetype/utils";
import { values } from "lodash";

import { generateLoadedViewFieldsMapForEntityType } from "../adapters/generateViewFieldMaps";
import { createFileLogger } from "../logger";

const logger = createFileLogger("convertStateToLLMState");

export function convertStateToLLMState({
  stateId,
  entityType,
  relationsById,
  stateMachine,
}: {
  stateId: IStateId;
  entityType: IEntityTypeCore;
  relationsById: Record<IRelationId, IRelationCore>;
  stateMachine: IStateMachine;
}): ILLMState | undefined {
  const state = stateMachine.states[stateId];

  if (state == null) {
    logger.error(`State ${stateId} not found in state machine`);

    return undefined;
  }

  const aiAgentInstructions = stateMachine.aiAgents[stateId]?.instructions;
  const viewFieldsById = generateLoadedViewFieldsMapForEntityType(entityType, relationsById);

  return {
    stateId: state.id,
    name: state.label,
    fields: values(viewFieldsById).map((f) => f.displayName),
    aiAgentInstructions: aiAgentInstructions == null ? undefined : (aiAgentInstructions as IReadableString),
  };
}
