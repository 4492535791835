import { DateInput } from "@archetype/ui";
import { DateTime } from "luxon";
import React, { useCallback, useRef } from "react";
import { useContextSelector } from "use-context-selector";

import { DataTableEditingContext } from "../../context/DataTableEditingContext";
import { useCellClickInteraction } from "../../hooks/useCellClickInteraction";
import { useCellEditOnEnter } from "../../hooks/useCellEditOnEnter";
import { useCellExitOnEsc } from "../../hooks/useCellExitOnEsc";
import type { IDataTableCommonCellProps, IDataTableDateCell } from "./api";

export function DateCell<TRowId extends string, TColId extends string>({
  cell,
  isCellEditing,
  isCellSelected,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTableDateCell<TRowId, TColId>>): React.ReactNode {
  const { value, onChange, readOnly, rowId, colId } = cell;
  const setEditableCell = useContextSelector(DataTableEditingContext, (state) => state.setEditableCell);
  const inputRef = useRef<HTMLButtonElement>(null);

  const { handleCellClick } = useCellClickInteraction({
    isCellEditing,
    isCellSelected,
    readOnly,
    rowId,
    colId,
  });

  useCellEditOnEnter({ rowId, colId, isCellSelected, isCellEditing });
  useCellExitOnEsc({ inputRef, isCellSelected, isCellEditing });

  const handleChange = useCallback(
    (date: Date | undefined): void => {
      if (readOnly) {
        return;
      }

      if (date == null) {
        void onChange?.(null);
        setEditableCell(null);

        return;
      }

      const luxonDate = DateTime.fromJSDate(date);

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety @sspringer to check if necessary
      if (luxonDate != null && (value == null || !value.hasSame(luxonDate, "day"))) {
        void onChange?.(luxonDate);
        setEditableCell(null);
      }
    },
    [onChange, value, setEditableCell, readOnly],
  );

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setEditableCell(null);
      } else {
        setEditableCell({ rowId, colId });
      }
    },
    [setEditableCell, rowId, colId],
  );

  return (
    <div className="flex h-full flex-1 items-center px-2" onClick={handleCellClick}>
      <DateInput
        ref={inputRef}
        className="no-focus-outline"
        disabled={readOnly}
        isOpen={!readOnly && isCellEditing}
        placeholder={isCellSelected ? "Select Date" : ""}
        small={true}
        value={value?.toJSDate() ?? undefined}
        variant="text"
        onChange={handleChange}
        onOpenChange={handleOpenChange}
      />
    </div>
  );
}
