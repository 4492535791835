import type { IEntityTypeCore, IRelationCore, IStateChange, IStateMachine, IViewField } from "@archetype/dsl";
import {
  createColumnViewField,
  createRelationViewField,
  isColumnViewField,
  isRelationViewField,
  isStateAddViewFieldChange,
  isStateColorChange,
  isStateDisableAIAgentChange,
  isStateEnableOrEditAIAgentChange,
  isStateNameChange,
  isStateRemoveViewFieldChange,
} from "@archetype/dsl";
import type { IApplicationGroupId, IEntityTypeId, IRelationId, IStateId } from "@archetype/ids";
import { flatMap, forEach, groupByNoUndefined, isNonNullable, mapKeysNoUndefined } from "@archetype/utils";
import assertNever from "assert-never";
import { values } from "lodash";

import { generateLoadedViewFieldsMapForEntityType } from "../adapters/generateViewFieldMaps";
import type { ILoadedViewField } from "../apiTypes/LoadedViewField";
import type {
  IAiAgentForStateOperation,
  IEditOperation,
  IEditStateOperation,
  ISetViewFieldsForStateOperation,
} from "../stateMachine/editOperations/operations";

function loadedViewFieldToViewField(f: ILoadedViewField): IViewField {
  if (isColumnViewField(f)) {
    return createColumnViewField(f.columnId);
  }

  if (isRelationViewField(f)) {
    return createRelationViewField(f.relationId, f.direction);
  }

  assertNever(f);
}

export function convertStateChangesToEditOperations({
  changes,
  entityTypesById,
  relationsById,
  stateMachinesByApplicationGroupId,
}: {
  changes: IStateChange[];
  entityTypesById: Record<IEntityTypeId, IEntityTypeCore>;
  relationsById: Record<IRelationId, IRelationCore>;
  stateMachinesByApplicationGroupId: Record<IApplicationGroupId, IStateMachine>;
}): IEditOperation[] {
  const res: Record<
    IStateId,
    {
      editState?: IEditStateOperation;
      editStateViewFields?: ISetViewFieldsForStateOperation;
      editStateAIAgent?: IAiAgentForStateOperation;
    }
  > = {};

  const changesByEntityTypeId = groupByNoUndefined(changes, (c) => c.entityTypeId);

  forEach(changesByEntityTypeId, (entityTypeChanges, entityTypeId) => {
    const entityType = entityTypesById[entityTypeId];

    if (entityType == null) {
      throw new Error(`Entity type ${entityTypeId} not found`);
    }

    const applicationGroupId = entityType.targetEntityTypeApplicationGroupId;

    if (applicationGroupId == null) {
      // we only apply state changes to core entity types
      throw new Error(`Entity type ${entityTypeId} has no application group id`);
    }

    const stateMachineEdits = stateMachinesByApplicationGroupId[applicationGroupId];

    if (stateMachineEdits == null) {
      throw new Error(`State machine edits for application group ${applicationGroupId} not found`);
    }

    const statesById = mapKeysNoUndefined(stateMachineEdits.states, (s) => s.id);
    const viewFieldsById = generateLoadedViewFieldsMapForEntityType(entityType, relationsById);
    const viewFieldsByName = mapKeysNoUndefined(viewFieldsById, (f) => f.displayName);

    entityTypeChanges.forEach((change) => {
      const state = statesById[change.stateId];

      if (state == null) {
        throw new Error(`State ${change.stateId} not found`);
      }

      if (isStateNameChange(change)) {
        res[state.id] = {
          ...res[state.id],
          editState: {
            type: "editState",
            updatedState: {
              id: state.id,
              label: change.changeStateNameTo,
              color: state.color,
            },
            targetEntityTypeId: entityType.id,
            applicationGroupId,
          },
        };
      } else if (isStateColorChange(change)) {
        res[state.id] = {
          ...res[state.id],
          editState: {
            type: "editState",
            updatedState: {
              id: state.id,
              label: state.label,
              color: change.changeStateColorTo,
            },
            targetEntityTypeId: entityType.id,
            applicationGroupId,
          },
        };
      } else if (isStateAddViewFieldChange(change)) {
        const field = viewFieldsByName[change.viewFieldToAdd];

        if (field == null) {
          throw new Error(`View field ${change.viewFieldToAdd} not found`);
        }

        const currFields: IViewField[] =
          res[state.id]?.editStateViewFields?.fields ?? entityType.relevantViewFieldsByStateId?.[state.id] ?? [];

        res[state.id] = {
          ...res[state.id],
          editStateViewFields: {
            type: "setViewFieldsForState",
            stateId: state.id,
            fields: [...currFields, loadedViewFieldToViewField(field)],
            entityTypeId: entityType.id,
          },
        };
      } else if (isStateRemoveViewFieldChange(change)) {
        const field = viewFieldsByName[change.viewFieldToRemove];

        if (field == null) {
          throw new Error(`View field ${change.viewFieldToRemove} not found`);
        }

        const currFields: IViewField[] =
          res[state.id]?.editStateViewFields?.fields ?? entityType.relevantViewFieldsByStateId?.[state.id] ?? [];

        res[state.id] = {
          ...res[state.id],
          editStateViewFields: {
            type: "setViewFieldsForState",
            stateId: state.id,
            fields: currFields.filter((f) => f.id !== field.id),
            entityTypeId: entityType.id,
          },
        };
      } else if (isStateEnableOrEditAIAgentChange(change)) {
        res[state.id] = {
          ...res[state.id],
          editStateAIAgent: {
            type: "setAiAgentForState",
            stateId: state.id,
            aiAgentDefinition: {
              instructions: change.aiAgentInstructionsToAddOrEdit,
              rawInstructions: change.aiAgentInstructionsToAddOrEdit,
              enabled: true,
            },
            applicationGroupId,
          },
        };
      } else if (isStateDisableAIAgentChange(change)) {
        res[state.id] = {
          ...res[state.id],
          editStateAIAgent: {
            type: "setAiAgentForState",
            stateId: state.id,
            aiAgentDefinition: null,
            applicationGroupId,
          },
        };
      } else {
        assertNever(change);
      }
    });
  });

  return flatMap(res, (v) => values(v).filter(isNonNullable));
}
