import type { IUserId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { cn, Skeleton } from "@archetype/ui";
import React from "react";

interface IUserEmailById {
  id: IUserId;
  className?: string;
}

export const UserEmailById: React.FC<IUserEmailById> = ({ id, className }) => {
  const { data: userQuery } = trpc.users.byUserId.useQuery({
    id,
  });

  if (userQuery === undefined) {
    return (
      <span className="inline-block">
        <Skeleton className={cn("h-4 w-16", className)} />
      </span>
    );
  }

  const primaryEmailAddressId = userQuery.clerkUser.primaryEmailAddressId;
  const targetEmail = userQuery.clerkUser.emailAddresses.find((e) => e.id === primaryEmailAddressId);
  const email = targetEmail?.emailAddress;

  if (email == null) {
    return null;
  }

  return <span className={cn("font-medium", className)}>{email}</span>;
};
