import type { ILoadedEntityType, ILoadedViewField } from "@archetype/core";
import { createLoadedColumnViewField, type ILoadedAction } from "@archetype/core";
import type { IDataTableColumn, IDataTableRow, IDataTableSelectionMode } from "@archetype/data-table";
import { Cell, CheckboxHeader, DataTable, DataTableProvider } from "@archetype/data-table";
import type { IEntityTypeCore } from "@archetype/dsl";
import { computeColumnViewFieldId, type IVersionType } from "@archetype/dsl";
import type { IActionId, IColumnId, IEntityId, IEntityTypeId, IOrganizationId, IViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { DottedEntityStateColor, useMemoDeepCompare } from "@archetype/ui";
import { isNonNullable } from "@archetype/utils";
import { uniq, uniqBy } from "lodash";
import { useRouter } from "next/router";
import React, { useCallback, useMemo } from "react";

import type { IGetActionRoute, IGetEntityRoute, IGetLinkedEntityRoute } from "../api";
import type { IEntityDataTableCellValue } from "../entityTable/api";
import { EntityTableDraftCellWrapper } from "../entityTable/cells/EntityTableDraftCellWrapper";
import { EntityHeaderWrapper } from "../entityTable/EntityHeaderWrapper";

const COLUMN_SELECTION_ID = "column-selection" as IViewFieldId;

type IDraftRow = IDataTableRow<IEntityId> & { actionId: IActionId };

type IDraftColumn = IDataTableColumn<IEntityId, IViewFieldId, IDraftRow, IEntityDataTableCellValue>;

interface ICreationDraftsTable {
  orgId: IOrganizationId;
  entityType: ILoadedEntityType;
  versionType: IVersionType;

  allActionsForForEntityType: Partial<Record<IActionId, ILoadedAction>>;
  allEntityTypes: Partial<Record<IEntityTypeId, IEntityTypeCore>> | undefined;
  getFullPageActionRoute: IGetActionRoute;
  getFullPageEntityRoute: IGetEntityRoute;
  getLinkedEntityRoute: IGetLinkedEntityRoute;

  titleOverride?: string;
  /**
   * If true, the table will allow multi-select in a checkbox column. @default false
   */
  allowMultiSelect?: boolean;
}

export const CreationDraftsTable: React.FC<ICreationDraftsTable> = ({
  orgId,
  entityType,
  versionType,
  allActionsForForEntityType,
  allEntityTypes,
  getFullPageActionRoute,
  getFullPageEntityRoute,
  getLinkedEntityRoute,
  titleOverride,
  allowMultiSelect,
}) => {
  const router = useRouter();

  const { data: draftsQuery } = builderTrpc.action.getCreateDraftIdsForEntityType.useQuery({
    versionType,
    organizationId: entityType.organizationId,
    entityTypeId: entityType.id,
  });

  const draftIds = draftsQuery?.draftIds;

  const createActionsInDrafts = useMemoDeepCompare(() => {
    const createActionIds = uniq(draftIds?.map((d) => d.actionId) ?? []);

    const createActions = createActionIds.map((id) => allActionsForForEntityType[id]).filter(isNonNullable);

    return createActions;
  }, [draftIds, allActionsForForEntityType]);

  const fieldsInCreateActions = useMemoDeepCompare(() => {
    const displayNameColumnId = entityType.displayNameColumn;
    const displayNameViewFieldId = computeColumnViewFieldId(displayNameColumnId);
    const displayNameColumn = entityType.columns[displayNameColumnId];
    const displayNameField: ILoadedViewField[] = displayNameColumn
      ? [createLoadedColumnViewField(displayNameColumn)]
      : [];

    const innerFields = displayNameField.concat(
      uniqBy(
        createActionsInDrafts.flatMap((a) =>
          a.actionDefinition.inputs.filter((i) => i.allowChangingDefault).map((i) => i.viewField),
        ),
        (v) => v.id,
      ).filter((v) => v.id !== displayNameViewFieldId),
    );

    return innerFields;
  }, [createActionsInDrafts, entityType.columns, entityType.displayNameColumn]);

  const getFullPageDraftRoute: IGetEntityRoute = useCallback(
    ({ entityTypeId, entityId }) => {
      const fullDraft = draftIds?.find((d) => d.entityId === entityId);

      if (fullDraft == null) {
        return getFullPageEntityRoute({ entityTypeId, entityId });
      }

      return getFullPageActionRoute({
        entityTypeId,
        actionId: fullDraft.actionId,
        entityId,
        isCreateActionDraft: true,
        defaultValues: undefined,
      });
    },
    [getFullPageEntityRoute, getFullPageActionRoute, draftIds],
  );

  const handleRowSelectionChange = useCallback(
    (newSelection: IEntityId[]) => {
      const entityId = newSelection.length > 0 ? newSelection[0] : undefined;

      const fullDraft = draftIds?.find((d) => d.entityId === entityId);

      if (fullDraft == null) {
        return;
      }

      void router.push(
        getFullPageActionRoute({
          entityTypeId: entityType.id,
          entityId: fullDraft.entityId,
          actionId: fullDraft.actionId,
          isCreateActionDraft: true,
          defaultValues: undefined,
        }),
      );
    },
    [getFullPageActionRoute, router, draftIds, entityType.id],
  );

  const selectionState: IDataTableSelectionMode<IEntityId, IColumnId> = useMemo(() => {
    return {
      type: "row",
      multiselect: allowMultiSelect ?? false,
      allowCellSelection: false,
      onRowSelectionChange: handleRowSelectionChange,
    };
  }, [allowMultiSelect, handleRowSelectionChange]);

  const tableData: IDraftRow[] | undefined = useMemoDeepCompare(() => {
    const draftIdsAsTableData = draftIds?.map((draftId) => ({
      ...draftId,
      id: draftId.entityId,
    }));

    return draftIdsAsTableData;
  }, [draftIds]);

  const rowCount = draftIds?.length ?? 0;

  const columns = useMemoDeepCompare((): IDraftColumn[] => {
    const displayNameViewFieldId = computeColumnViewFieldId(entityType.displayNameColumn);

    const dataTableColumns = fieldsInCreateActions.map((field) => {
      const columnDef: IDraftColumn = {
        id: field.id,
        pinned: field.id === displayNameViewFieldId ? "left" : undefined,
        minWidth: field.id === displayNameViewFieldId ? 220 : 150,
        accessorFn: (): IEntityDataTableCellValue => {
          // No value is shown as data in the row because rows are loaded individually
          const res: IEntityDataTableCellValue = {
            value: { type: "null" },
            computeStatus: { kind: null, status: "success" },
          };

          return res;
        },
        cellRenderer: ({
          rowId,
          colId,
          row,
          isCellEditing,
          isCellSelected,
          isRowSelected,
          selectionMode,
        }): React.ReactNode => {
          const fieldValues = {}; // Dont need any field values for drafts

          return (
            <EntityTableDraftCellWrapper
              actionId={row.actionId}
              colId={colId}
              entityType={entityType}
              entityTypeId={entityType.id}
              fieldValues={fieldValues}
              getFullPageEntityRoute={getFullPageDraftRoute}
              getLinkedEntityRoute={getLinkedEntityRoute}
              isCellEditing={isCellEditing}
              isCellSelected={isCellSelected}
              isRowSelected={isRowSelected}
              organizationId={orgId}
              readOnly={true}
              rowId={rowId}
              selectionMode={selectionMode}
              versionType={versionType}
              viewField={field}
            />
          );
        },
        headerRenderer: (): React.ReactNode => (
          <div className="flex h-full items-center justify-between">
            <EntityHeaderWrapper allEntityTypes={allEntityTypes} viewField={field} />
          </div>
        ),
      };

      return columnDef;
    });

    if (allowMultiSelect === true) {
      return [
        {
          id: COLUMN_SELECTION_ID,
          accessorFn: (): IEntityDataTableCellValue => ({
            value: { type: "null" },
          }),
          maxWidth: 60,
          minWidth: 60,
          enableResizing: false,
          pinned: "left",
          cellRenderer: ({ rowId, isCellEditing, isCellSelected, isRowSelected, selectionMode }): React.ReactNode => (
            <Cell
              cell={{ type: "rowSelection", rowId, colId: COLUMN_SELECTION_ID, readOnly: false }}
              isCellEditing={isCellEditing}
              isCellSelected={isCellSelected}
              isRowSelected={isRowSelected}
              selectionMode={selectionMode}
            />
          ),
          headerRenderer: (): React.ReactNode => <CheckboxHeader />,
        },
        ...dataTableColumns.filter((col) => col.id !== computeColumnViewFieldId(entityType.primaryKey)),
      ];
    }

    return dataTableColumns.filter((col) => col.id !== computeColumnViewFieldId(entityType.primaryKey));
  }, [
    entityType,
    fieldsInCreateActions,
    allowMultiSelect,
    getFullPageDraftRoute,
    getLinkedEntityRoute,
    orgId,
    versionType,
    allEntityTypes,
  ]);

  if (draftIds == null || draftIds.length === 0 || tableData == null) {
    return null;
  }

  return (
    <div className="flex flex-col" data-testid="entity-table">
      <div className="mb-4 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-x-2">
          <DottedEntityStateColor color="primary" />
          <span data-testid="entity-table-title">
            {titleOverride ??
              `Drafts ${draftIds.length > 1 ? entityType.displayMetadata.pluralName.toLowerCase() : entityType.displayMetadata.name.toLowerCase()}`}
          </span>
          {draftIds.length > 0 ? <span>({draftIds.length})</span> : null}
        </div>
      </div>
      {tableData.length === 0 ? (
        <div className="border-border text-muted-foreground flex flex-row items-center justify-start space-x-0.5 rounded border border-dashed p-4 text-sm">
          <span>No draft</span>
        </div>
      ) : (
        <DataTableProvider columns={columns} data={tableData} rowCount={rowCount} selectionMode={selectionState}>
          <DataTable className="h-56" />
        </DataTableProvider>
      )}
    </div>
  );
};
