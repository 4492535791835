import type { ILoadedAction, ILoadedEntity } from "@archetype/core";
import type { IVersionType, IViewFieldValue } from "@archetype/dsl";
import type { IEntityId, IViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import type { IUntypedRoute } from "@archetype/ui";
import { useMemoDeepCompare, useToast } from "@archetype/ui";
import { useCallback, useMemo, useState } from "react";

import type { IGetActionRoute } from "../api";
import { createFileLogger } from "../logger";
import { useInvalidatingActionExecution } from "./actionExecution/useInvalidatingActionExecution";

export const logger = createFileLogger("ActionDirectExecutionWrapper");

export interface IActionExecutionComponentWrapperProps {
  versionType: IVersionType;
  action: ILoadedAction;
  entityId: IEntityId | undefined;
  presetParameters?: {
    defaultValues?: Partial<Record<IViewFieldId, IViewFieldValue>> | undefined;
  };
  onActionExecuted?: (entity: ILoadedEntity | undefined) => Promise<void>;
  dataTourId?: string;
  getActionRoute: IGetActionRoute;
  getFullPageActionRoute: IGetActionRoute;
}

export function useActionDirectExecutionWrapper({
  entityId,
  presetParameters,
  versionType,
  action,
  onActionExecuted,
  getActionRoute,
  getFullPageActionRoute,
}: IActionExecutionComponentWrapperProps): {
  executeActionEnabled: boolean;
  actionRouteIfNonDirectExecution: IUntypedRoute | undefined;
  isExecutingAction: boolean;
  handleExecuteAction: (event: Event | React.MouseEvent) => Promise<void>;
} {
  const [isExecutingAction, setIsExecutingAction] = useState(false);
  const { toast } = useToast();

  const { executeAction } = useInvalidatingActionExecution();
  const { data: validationGroupsQuery } = builderTrpc.dataModel.getValidationGroupsByEntityType.useQuery({
    versionType,
    entityTypeId: action.entityTypeId,
  });
  const validationGroups = useMemoDeepCompare(
    () => validationGroupsQuery?.validationGroups ?? [],
    [validationGroupsQuery?.validationGroups],
  );

  const handleExecuteAction = useCallback(
    async (event: Event | React.MouseEvent) => {
      event.stopPropagation();

      if (entityId == null) {
        return;
      }

      setIsExecutingAction(true);

      let res;

      try {
        res = await executeAction({
          executeActionQuery: {
            versionType,
            actionId: action.id,
            entityId: entityId,
            fieldValues: {},
            // TODO: public actions, need to handle this case
            externalUserSyntheticEntityId: null,
            externalUserFieldValues: null,
            loadedNestedCreateFormValues: {},
          },
          invalidationParams: {
            organizationId: action.organizationId,
            actionToState: action.actionDefinition.toState,
            isCreateActionDraft: false, // cant be in a create action draft if executing directly
            shouldWaitForAsyncExecution: false,
            actionInfo: action,
            validationGroups,
            maybeExternalUserCreateProps: undefined,
          },
          getActionRouteRedirectOnError: getActionRoute,
        });
      } catch (e) {
        logger.error("Action execution failed");
        toast({ title: "Error", description: "Action execution failed" });
        logger.error({ error: e }, "Action execution failed");
        setIsExecutingAction(false);

        return;
      }

      if (!res.success) {
        logger.info({ errors: res.errors }, "Action received validation errors");
        toast({ title: "Validation errors", description: "Action execution failed" });
        setIsExecutingAction(false);
        throw new Error("Validation failed");
      } else {
        await onActionExecuted?.(res.entity ?? undefined);

        setIsExecutingAction(false);
      }
    },
    [action, executeAction, versionType, onActionExecuted, entityId, toast, validationGroups, getActionRoute],
  );

  const actionRouteIfNonDirectExecution = useMemo(() => {
    const shouldShowForm =
      // Definitely show form if the action is delete or create
      action.actionDefinition.actionType !== "modify" ||
      action.actionDefinition.inputs.some((input) => input.allowChangingDefault);

    if (!shouldShowForm) {
      return undefined;
    }

    if (action.actionDefinition.actionType === "add") {
      return getFullPageActionRoute({
        entityTypeId: action.entityTypeId,
        actionId: action.id,
        entityId: entityId,
        defaultValues: presetParameters?.defaultValues,
        isCreateActionDraft: false, // no create action draft yet
      });
    }

    return getActionRoute({
      entityTypeId: action.entityTypeId,
      actionId: action.id,
      entityId: entityId,
      defaultValues: presetParameters?.defaultValues,
      isCreateActionDraft: false, // not create action
    });
  }, [action, getActionRoute, entityId, presetParameters?.defaultValues, getFullPageActionRoute]);

  const executeActionEnabled = entityId != null || action.actionDefinition.actionType === "add";

  return { executeActionEnabled, actionRouteIfNonDirectExecution, isExecutingAction, handleExecuteAction };
}
