import type { IEntityTypeChange, IEntityTypeCore } from "@archetype/dsl";
import { isEntityTypeColorChange, isEntityTypeNameChange, isEntityTypeShapeChange } from "@archetype/dsl";
import type { IEntityTypeId } from "@archetype/ids";
import assertNever from "assert-never";

import type { IEditOperation } from "../stateMachine/editOperations/operations";

export function convertEntityTypeChangesToEditOperations({
  changes,
  entityTypesById,
}: {
  changes: IEntityTypeChange[];
  entityTypesById: Record<IEntityTypeId, IEntityTypeCore>;
}): IEditOperation[] {
  const operations: IEditOperation[] = changes.map((change) => {
    const entityType = entityTypesById[change.entityTypeId];

    if (entityType == null) {
      throw new Error(`Entity type ${change.entityTypeId} not found`);
    }

    if (isEntityTypeNameChange(change)) {
      return {
        type: "setEntityTypeDisplayMetadata",
        entityTypeId: entityType.id,
        newDisplayMetadata: { name: change.changeEntityTypeNameTo, pluralName: change.changePluralEntityTypeNameTo },
      };
    }

    if (isEntityTypeColorChange(change)) {
      return {
        type: "setEntityTypeColor",
        entityTypeId: entityType.id,
        newColor: change.changeColorTo,
      };
    }

    if (isEntityTypeShapeChange(change)) {
      return {
        type: "setEntityTypeShapeName",
        entityTypeId: entityType.id,
        newShape: change.changeShapeTo,
      };
    }

    assertNever(change);
  });

  return operations;
}
