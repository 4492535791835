import { z } from "zod";

export const EntityTypeShapeName = z.enum([
  "circle",
  "square",
  "triangle",
  "star",
  "diamond",
  "pentagon",
  "flower",
  "hexagon",
  "oval",
  "trapezoid",
  "bean",
  "butterfly",
  "arch",
  "eye",
  "tear",
  "parallelogram",
]);
export type IEntityTypeShapeName = z.infer<typeof EntityTypeShapeName>;

export const ENTITY_TYPE_SHAPE_NAMES: IEntityTypeShapeName[] = EntityTypeShapeName.options;
