/**
 * Our DSLs are defined by type trees. The ColumnType is any element in that tree: root, node, or leaf.
 *
 * For example, a type could look like (simplified):
 * - type: composite
 *   map:
 *    a:
 *    - type: stringRegexRefinement
 *      regex: "^[a-z]+$"
 *      child:
 *      - type: string
 *    b:
 *    - type: number
 */

import {
  ColumnId,
  ColumnTypeId,
  ColumnTypeVersionId,
  DataModelMetadataId,
  DataModelMetadataVersionId,
  EntityTypeId,
} from "@archetype/ids";
import { z } from "zod";

import { VersionIdentifiable } from "../common/VersionIdentifiable";
import { ColumnBooleanRefinements } from "./columnTypes/ColumnBooleanType";
import { ColumnNumberRefinements } from "./columnTypes/ColumnNumberType";
import { ColumnStringRefinements } from "./columnTypes/ColumnStringType";
import type { IPrimitiveAnyColumn } from "./columnTypes/primitiveColumnTypes";

export const ColumnIntegerType = z.object({ type: z.literal("integer"), primitiveType: z.literal("number") });
export const ColumnDateType = z.object({ type: z.literal("date"), primitiveType: z.literal("string") });
export const ColumnTimestampType = z.object({
  type: z.literal("timestamp"),
  primitiveType: z.literal("string"),
});
export const ColumnTimeseriesType = z.object({
  type: z.literal("timeseries"),
  primitiveType: z.literal("string"),
});
export const ColumnArrayType = z.object({ type: z.literal("array"), primitiveType: z.literal("string") }); // Needs to be different
export const ColumnGeolocationType = z.object({
  type: z.literal("geolocation"),
  primitiveType: z.literal("string"),
}); // Needs to be different

export const ColumnPrimitiveTypeDefinitions = z
  .discriminatedUnion("type", [
    ColumnIntegerType,
    ColumnDateType,
    ColumnTimestampType,
    ColumnTimeseriesType,
    ColumnArrayType,
    ColumnGeolocationType,
  ])
  .describe("Primitive types are unrefined types that live at the leaf ends of the type tree.");

export const ColumnTypeDefinitions = z
  .discriminatedUnion("type", [
    ...ColumnStringRefinements.options,
    ...ColumnNumberRefinements.options,
    ...ColumnBooleanRefinements.options,
    ...ColumnPrimitiveTypeDefinitions.options,
  ])
  .describe(
    "In our DSLs, schemas are defined by type trees. The ColumnType is any element in that tree: root, node, or leaf.",
  ) satisfies z.ZodType<IPrimitiveAnyColumn>;

export type IColumnTypeDefinitions = z.infer<typeof ColumnTypeDefinitions>;

// Can be replaced fully by Validations in Noa's PR
export const ComplexColumnType = VersionIdentifiable(
  ColumnTypeId,
  ColumnTypeVersionId,
  DataModelMetadataId,
  DataModelMetadataVersionId,
)
  .merge(
    z.object({
      displayMetadata: z
        .object({
          name: z.string(),
          description: z.string().optional().nullable(),
        })
        .optional()
        .describe("When this is defined, this column type has some semantic, describable meaning"),

      definition: ColumnTypeDefinitions.describe("The actual type definition"),
      entityTypeId: EntityTypeId.nullable(),
      columnId: ColumnId.nullable(),
    }),
  )
  .describe("A rich wrapper of a type definition");

export type IComplexColumnType = z.infer<typeof ComplexColumnType>;
export type IColumnIntegerType = z.infer<typeof ColumnIntegerType>;
export type IColumnDateType = z.infer<typeof ColumnDateType>;
export type IColumnTimestampType = z.infer<typeof ColumnTimestampType>;
export type IColumnTimeseriesType = z.infer<typeof ColumnTimeseriesType>;
export type IColumnArrayType = z.infer<typeof ColumnArrayType>;
export type IColumnGeolocationType = z.infer<typeof ColumnGeolocationType>;
