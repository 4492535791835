import { EntityTypeId } from "@archetype/ids";
import * as z from "zod";

import { ComparisonRule } from "./ComparisonRule";
import { ColumnElement, ComputedElement, Element, EntityTypeElement, RelationElement, StaticElement } from "./Element";
import { StoredViewField } from "./ViewField";

export const StaticDerivation = z.object({
  type: z.literal("static"),
  element: z.discriminatedUnion("type", [StaticElement, ComputedElement]),
});
export type IStaticDerivation = z.infer<typeof StaticDerivation>;

export const ScalarAddNumbersDerivation = z.object({
  type: z.literal("addNumbers"),
  addends: z.array(Element),
});
export type IScalarAddNumbersDerivation = z.infer<typeof ScalarAddNumbersDerivation>;

export const ScalarSubtractNumbersDerivation = z.object({
  type: z.literal("subtractNumbers"),
  minuend: Element,
  subtrahend: Element,
});
export type IScalarSubtractNumbersDerivation = z.infer<typeof ScalarSubtractNumbersDerivation>;

export const ScalarMultiplyNumbersDerivation = z.object({
  type: z.literal("multiplyNumbers"),
  factors: z.array(Element),
});
export type IScalarMultiplyNumbersDerivation = z.infer<typeof ScalarMultiplyNumbersDerivation>;

export const ScalarDivideNumbersDerivation = z.object({
  type: z.literal("divideNumbers"),
  dividend: Element,
  divisor: Element,
});
export type IScalarDivideNumbersDerivation = z.infer<typeof ScalarDivideNumbersDerivation>;

export const ScalarConcatStringsDerivation = z.object({
  type: z.literal("concatStrings"),
  strings: z.array(Element),
  separator: z.string(),
});
export type IScalarConcatStringsDerivation = z.infer<typeof ScalarConcatStringsDerivation>;

export const ScalarDaysDiffBetweenDatesDerivation = z.object({
  type: z.literal("daysBetweenDates"),
  sinceDate: Element,
  untilDate: Element,
  inclusive: z.boolean(),
});
export type IScalarDaysDiffBetweenDatesDerivation = z.infer<typeof ScalarDaysDiffBetweenDatesDerivation>;

export const ScalarYearsDiffBetweenDatesDerivation = z.object({
  type: z.literal("yearsBetweenDates"),
  sinceDate: Element,
  untilDate: Element,
  inclusive: z.boolean(),
});
export type IScalarYearsDiffBetweenDatesDerivation = z.infer<typeof ScalarYearsDiffBetweenDatesDerivation>;

export const ScalarAddDaysToDateDerivation = z.object({
  type: z.literal("addDaysToDate"),
  date: Element,
  days: Element,
});
export type IScalarAddDaysToDateDerivation = z.infer<typeof ScalarAddDaysToDateDerivation>;

export const ScalarSubtractDaysFromDateDerivation = z.object({
  type: z.literal("subtractDaysFromDate"),
  date: Element,
  days: Element,
});
export type IScalarSubtractDaysFromDateDerivation = z.infer<typeof ScalarSubtractDaysFromDateDerivation>;

export const ScalarExtractYearFromDateDerivation = z.object({
  type: z.literal("extractYearFromDate"),
  date: Element,
});
export type IScalarExtractYearFromDateDerivation = z.infer<typeof ScalarExtractYearFromDateDerivation>;

export const ScalarExtractMonthFromDateDerivation = z.object({
  type: z.literal("extractMonthFromDate"),
  date: Element,
});
export type IScalarExtractMonthFromDateDerivation = z.infer<typeof ScalarExtractMonthFromDateDerivation>;

export const ScalarExtractDayFromDateDerivation = z.object({
  type: z.literal("extractDayFromDate"),
  date: Element,
});
export type IScalarExtractDayFromDateDerivation = z.infer<typeof ScalarExtractDayFromDateDerivation>;

export const ScalarExtractDateFromTimestampDerivation = z.object({
  type: z.literal("extractDateFromTimestamp"),
  timestamp: Element,
});
export type IScalarExtractDateFromTimestampDerivation = z.infer<typeof ScalarExtractDateFromTimestampDerivation>;

export const ScalarDerivation = z.object({
  type: z.literal("scalar"),
  computation: z.discriminatedUnion("type", [
    ScalarAddNumbersDerivation,
    ScalarSubtractNumbersDerivation,
    ScalarMultiplyNumbersDerivation,
    ScalarDivideNumbersDerivation,
    ScalarConcatStringsDerivation,
    ScalarDaysDiffBetweenDatesDerivation,
    ScalarYearsDiffBetweenDatesDerivation,
    ScalarAddDaysToDateDerivation,
    ScalarSubtractDaysFromDateDerivation,
    ScalarExtractYearFromDateDerivation,
    ScalarExtractMonthFromDateDerivation,
    ScalarExtractDayFromDateDerivation,
    ScalarExtractDateFromTimestampDerivation,
  ]),
});
export type IScalarDerivation = z.infer<typeof ScalarDerivation>;

// introduce a new join that is not based on an existing relation
export const JoinDerivation = z.object({
  type: z.literal("join"),
  element: EntityTypeElement,
  filterBy: ComparisonRule.array(),
});
export type IJoinDerivation = z.infer<typeof JoinDerivation>;

export const LookupDerivation = z.object({
  type: z.literal("lookup"),
  element: z.discriminatedUnion("type", [ColumnElement, RelationElement]),
  basedOn: RelationElement,
  filterBy: ComparisonRule.array(),
});
export type ILookupDerivation = z.infer<typeof LookupDerivation>;

export const AggregationDerivation = z.object({
  type: z.literal("aggregation"),
  operation: z.enum(["sum", "count", "min", "max", "average", "minDate", "maxDate", "first"]),
  element: ColumnElement,
  basedOn: RelationElement,
  filterBy: ComparisonRule.array(),
});
export type IAggregationDerivation = z.infer<typeof AggregationDerivation>;

export const PredicateDerivation = z.object({
  type: z.literal("predicate"),
  conditions: z.array(ComparisonRule),
});
export type IPredicateDerivation = z.infer<typeof PredicateDerivation>;

export const Derivation = z.discriminatedUnion("type", [
  StaticDerivation,
  ScalarDerivation,
  AggregationDerivation,
  PredicateDerivation,
  JoinDerivation,
  LookupDerivation,
]);
export type IDerivation = z.infer<typeof Derivation>;

export const DerivationStep = z.object({
  viewField: StoredViewField, // each step creates an intermediate field in the entity and this would be its id
  entityTypeId: EntityTypeId,
  derivationLogic: z.string(),
  derivation: Derivation,
});
export type IDerivationStep = z.infer<typeof DerivationStep>;
