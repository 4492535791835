import { cn, Icon } from "@archetype/ui";
import React from "react";

interface IAiAgentUserProfileImage {
  className?: string;
}

export const AiAgentUserProfileImage: React.FC<IAiAgentUserProfileImage> = ({ className }) => {
  return (
    <div
      className={cn(
        "background-magic background-magic-animated relative flex size-8 items-center justify-around overflow-hidden rounded-md",
        className,
      )}
    >
      <Icon className="text-white" name="sparkles" />
    </div>
  );
};
