import type { IEntityActionDraft } from "@archetype/dsl";
import { FAKE_DATA_VERSION_TYPE, type IVersionType, type IViewFieldValue } from "@archetype/dsl";
import type { IActionId, IEntityId, IEntityTypeId, IOrganizationId, IViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { isEqual } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { usePrevious } from "react-use";

import type { IGetActionRoute } from "../api";
import { createFileLogger } from "../logger";
import { useActionDraftServiceDisabled } from "./useActionDraftServiceDisabled";

export const logger = createFileLogger("ActionDirectExecutionWrapper");

export function useCreateActionDraftOrRedirect({
  actionId,
  entityTypeId,
  organizationId,
  entityId,
  versionType,
  defaultValues,
  getActionRoute,
}: {
  actionId: IActionId;
  entityTypeId: IEntityTypeId;
  organizationId: IOrganizationId;
  entityId: IEntityId | undefined;
  versionType: IVersionType;
  getActionRoute: IGetActionRoute;
  defaultValues?: Partial<Record<IViewFieldId, IViewFieldValue>>;
}): {
  isCreatingDraft: boolean;
} {
  const isDraftCreationDisabled = useActionDraftServiceDisabled();

  const router = useRouter();

  const { mutateAsync: createDraftForCreateAction, isPending: isCreatingDraft } =
    builderTrpc.action.createDraftForCreateAction.useMutation();

  const { mutateAsync: discardDraftForAction } = builderTrpc.action.discardDraftForAction.useMutation();

  const getDraftForActionQuery = builderTrpc.useUtils().action.getDraftForAction;
  const getCreateDraftIdsForEntityTypeQuery = builderTrpc.useUtils().action.getCreateDraftIdsForEntityType;
  const getHasDraftForActionQuery = builderTrpc.useUtils().action.getHasDraftForAction;
  const getAccessToActionQuery = builderTrpc.useUtils().action.getAccessToAction;
  const getExampleEntitiesForActionPreviewsQuery =
    builderTrpc.useUtils().dataLoading.getExampleEntitiesForActionPreviews;

  // Use a ref to reset on any change of the props to avoid race condition of the use effect being called twice in dev mode
  const createdDraftEntityId = useRef<IEntityId | undefined>(undefined);

  const previousActionId = usePrevious(actionId);
  const previousEntityTypeId = usePrevious(entityTypeId);
  const previousOrganizationId = usePrevious(organizationId);
  const previousEntityId = usePrevious(entityId);
  const previousVersionType = usePrevious(versionType);
  const previousDefaultValues = usePrevious(defaultValues);

  useEffect(() => {
    if (
      previousActionId !== actionId ||
      previousEntityTypeId !== entityTypeId ||
      previousOrganizationId !== organizationId ||
      previousEntityId !== entityId ||
      previousVersionType !== versionType ||
      !isEqual(previousDefaultValues, defaultValues)
    ) {
      createdDraftEntityId.current = undefined;
    }
  }, [
    actionId,
    entityTypeId,
    organizationId,
    entityId,
    versionType,
    defaultValues,
    previousActionId,
    previousDefaultValues,
    previousEntityId,
    previousEntityTypeId,
    previousOrganizationId,
    previousVersionType,
  ]);

  useEffect(() => {
    if (isDraftCreationDisabled) {
      return;
    }

    async function createDraftAndRedirect(): Promise<void> {
      if (entityId != null) {
        return;
      }

      if (createdDraftEntityId.current != null) {
        return;
      }

      let draft: IEntityActionDraft | null;
      let newIsCreateActionDraft: boolean;

      try {
        const draftCreationResult = await createDraftForCreateAction({
          versionType,
          actionId,
          entityId: null,
          entityTypeId,
          organizationId,
          defaultFieldValues: defaultValues ?? null,
        });

        draft = draftCreationResult.draft;
        newIsCreateActionDraft = draftCreationResult.isCreateActionDraft;
      } catch (error) {
        // eslint-disable-next-line no-console -- error logging
        console.error("Error creating draft", error);

        return;
      }

      if (draft == null) {
        // Draft not created likely because the action is not a create action
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- actually possible to be non null again because async
      if (createdDraftEntityId.current != null) {
        if (createdDraftEntityId.current !== draft.entityId) {
          // Avoids dev mode race condition of the use effect being called twice
          // But only do the delete if the draft is not the same as it could be for support entities because we enforce a single draft at a time
          try {
            await discardDraftForAction({
              versionType,
              actionId,
              entityId: draft.entityId,
              entityTypeId,
              organizationId,
            });
          } catch (error) {
            // eslint-disable-next-line no-console -- error logging
            console.error("Error discarding potentially duplicated draft", error);
          }
        }

        // Skips everything else as should be handled by the other race condition
        return;
      }

      createdDraftEntityId.current = draft.entityId;

      getDraftForActionQuery.setData(
        {
          versionType,
          actionId,
          entityId: draft.entityId,
          entityTypeId,
          organizationId,
        },
        { draft },
      );

      if (newIsCreateActionDraft) {
        const currentDraftIds = getCreateDraftIdsForEntityTypeQuery.getData({
          versionType,
          entityTypeId,
          organizationId,
        });
        const newDraftIds = (currentDraftIds?.draftIds ?? []).concat({
          entityId: draft.entityId,
          actionId: draft.actionId,
          createdAt: draft.createdAt,
        });

        getCreateDraftIdsForEntityTypeQuery.setData(
          {
            versionType,
            entityTypeId,
            organizationId,
          },
          {
            draftIds: newDraftIds,
          },
        );

        getAccessToActionQuery.setData(
          {
            versionType,
            entityTypeId,
            entityId: draft.entityId,
            actionId,
            isCreateActionDraft: newIsCreateActionDraft,
          },
          {
            canExecute: true,
          },
        );

        if (versionType === FAKE_DATA_VERSION_TYPE) {
          const draftEntityId = draft.entityId;

          getExampleEntitiesForActionPreviewsQuery.setData(
            {
              entityTypeId,
              organizationId,
              versionType,
            },
            (oldData) =>
              oldData == null
                ? undefined
                : {
                    ...oldData,
                    previewEntitiesByAction: {
                      ...oldData.previewEntitiesByAction,
                      [actionId]: draftEntityId,
                    },
                  },
          );
        }
      } else {
        getHasDraftForActionQuery.setData(
          {
            organizationId,
            versionType,
            actionId,
            entityId: draft.entityId,
          },
          {
            hasDraft: true,
          },
        );
      }

      await router.replace(
        getActionRoute({
          entityTypeId,
          actionId,
          defaultValues,
          entityId: draft.entityId,
          isCreateActionDraft: newIsCreateActionDraft,
        }),
      );
    }

    void createDraftAndRedirect();
  }, [
    getActionRoute,
    createDraftForCreateAction,
    getAccessToActionQuery,
    getHasDraftForActionQuery,
    router,
    getDraftForActionQuery,
    getCreateDraftIdsForEntityTypeQuery,
    getExampleEntitiesForActionPreviewsQuery,
    discardDraftForAction,
    actionId,
    entityId,
    entityTypeId,
    organizationId,
    versionType,
    defaultValues,
    isDraftCreationDisabled,
  ]);

  return {
    isCreatingDraft,
  };
}
