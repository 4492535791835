import { keyByNoUndefined, mapValues } from "@archetype/utils";
import { omit } from "lodash";

import type { IActionExecutionSavingState, IClearErrorArgs, ISavingStateArgs, ISetAsErrorArgs } from "./types";

export const incrementSavingCount = (
  args: ISavingStateArgs,
  prevSavingState: IActionExecutionSavingState,
): IActionExecutionSavingState => {
  const prevSavingStateForEntity = prevSavingState[args.versionType]?.[args.entityTypeId]?.[args.entityId];

  const initializedSavingByViewFieldId = mapValues(
    keyByNoUndefined(args.viewFieldIds, (viewFieldId) => viewFieldId),
    () => 1,
  );

  const newSavingStateForEntity =
    prevSavingStateForEntity == null
      ? {
          savingCount: 1,
          savingByViewFieldId: initializedSavingByViewFieldId,
          errorsByOperationId: {},
        }
      : {
          ...prevSavingStateForEntity,
          savingCount: prevSavingStateForEntity.savingCount + 1,
          savingByViewFieldId: {
            ...initializedSavingByViewFieldId,
            ...mapValues(
              prevSavingStateForEntity.savingByViewFieldId,
              (prevSavingCount, viewFieldId) => prevSavingCount + (initializedSavingByViewFieldId[viewFieldId] ?? 0),
            ),
          },
        };

  const newSavingState: IActionExecutionSavingState = {
    ...prevSavingState,
    [args.versionType]: {
      ...prevSavingState[args.versionType],
      [args.entityTypeId]: {
        ...prevSavingState[args.versionType]?.[args.entityTypeId],
        [args.entityId]: newSavingStateForEntity,
      },
    },
  };

  return newSavingState;
};

export const decrementSavingCount = (
  args: ISavingStateArgs,
  prevSavingState: IActionExecutionSavingState,
): IActionExecutionSavingState => {
  const prevSavingStateForEntity = prevSavingState[args.versionType]?.[args.entityTypeId]?.[args.entityId];

  const initializedSavingByViewFieldId = mapValues(
    keyByNoUndefined(args.viewFieldIds, (viewFieldId) => viewFieldId),
    () => 1,
  );

  // Keep as undefined if it was not previously saving
  const newSavingStateForEntity = prevSavingStateForEntity && {
    ...prevSavingStateForEntity,
    savingCount: prevSavingStateForEntity.savingCount - 1,
    savingByViewFieldId: {
      ...mapValues(prevSavingStateForEntity.savingByViewFieldId, (prevSavingCount, viewFieldId) =>
        prevSavingCount <= 1 ? 0 : prevSavingCount - (initializedSavingByViewFieldId[viewFieldId] ?? 0),
      ),
    },
  };

  return {
    ...prevSavingState,
    [args.versionType]: {
      ...prevSavingState[args.versionType],
      [args.entityTypeId]: {
        ...prevSavingState[args.versionType]?.[args.entityTypeId],
        [args.entityId]: newSavingStateForEntity,
      },
    },
  };
};

export const markAsErrorAndDecrementSavingCount = (
  args: ISetAsErrorArgs,
  prevSavingState: IActionExecutionSavingState,
): IActionExecutionSavingState => {
  const prevSavingStateDecremented = decrementSavingCount(args.savingStateArgs, prevSavingState);

  const prevSavingStateForEntity =
    prevSavingStateDecremented[args.savingStateArgs.versionType]?.[args.savingStateArgs.entityTypeId]?.[
      args.savingStateArgs.entityId
    ];

  const newSavingStateForEntity =
    prevSavingStateForEntity == null
      ? {
          savingCount: 0,
          savingByViewFieldId: {},
          errorsByOperationId: {
            [args.operationId]: args.errors,
          },
        }
      : {
          ...prevSavingStateForEntity,
          errorsByOperationId: {
            ...prevSavingStateForEntity.errorsByOperationId,
            [args.operationId]: args.errors,
          },
        };

  return {
    ...prevSavingStateDecremented,
    [args.savingStateArgs.versionType]: {
      ...prevSavingStateDecremented[args.savingStateArgs.versionType],
      [args.savingStateArgs.entityTypeId]: {
        ...prevSavingStateDecremented[args.savingStateArgs.versionType]?.[args.savingStateArgs.entityTypeId],
        [args.savingStateArgs.entityId]: newSavingStateForEntity,
      },
    },
  };
};

export const removeError = (
  args: IClearErrorArgs,
  prevSavingState: IActionExecutionSavingState,
): IActionExecutionSavingState => {
  const prevSavingStateForEntity = prevSavingState[args.versionType]?.[args.entityTypeId]?.[args.entityId];

  // Keep as undefined if it was not previously saving
  const newSavingStateForEntity = prevSavingStateForEntity && {
    ...prevSavingStateForEntity,
    errorsByOperationId: omit(prevSavingStateForEntity.errorsByOperationId, args.operationId),
  };

  return {
    ...prevSavingState,
    [args.versionType]: {
      ...prevSavingState[args.versionType],
      [args.entityTypeId]: {
        ...prevSavingState[args.versionType]?.[args.entityTypeId],
        [args.entityId]: newSavingStateForEntity,
      },
    },
  };
};
