import type { IColumnType } from "@archetype/dsl";
import React from "react";

import { BooleanFilterInput } from "./filterInputs/BooleanFilterInput";
import { EntityFilterInput } from "./filterInputs/EntityFilterInput";
import { EnumFilterInput } from "./filterInputs/EnumFilterInput";
import { NumberFilterInput } from "./filterInputs/NumberFilterInput";
import { StatusEnumFilterInput } from "./filterInputs/StatusEnumFilterInput";
import { StringFilterInput } from "./filterInputs/StringFilterInput";
import type { IColumnFilterComponent } from "./types";

export const ColumnFilterInput: IColumnFilterComponent = (props) => {
  // special case the id column
  if (props.columnId === props.entityType.primaryKey) {
    return <EntityFilterInput {...props} />;
  }

  const FilterInputForColumnType = getColumnFilterInputForColumn(props.columnType);

  if (FilterInputForColumnType == null) {
    return null;
  }

  return <FilterInputForColumnType {...props} />;
};

const getColumnFilterInputForColumn = (columnType: IColumnType): IColumnFilterComponent | undefined => {
  switch (columnType.type) {
    case "date": {
      return StringFilterInput;
    }
    case "timestamp": {
      return StringFilterInput;
    }
    case "file": {
      return StringFilterInput;
    }
    case "phone": {
      return StringFilterInput;
    }
    case "email": {
      return StringFilterInput;
    }
    case "shortText": {
      return StringFilterInput;
    }
    case "number": {
      return NumberFilterInput;
    }
    case "boolean": {
      return BooleanFilterInput;
    }
    case "url": {
      return StringFilterInput;
    }
    case "geolocation": {
      return undefined;
    }
    case "longText": {
      return StringFilterInput;
    }
    case "enum": {
      if (columnType.enumAllowedValues != null && columnType.enumAllowedValues.length > 0) {
        return EnumFilterInput;
      }

      return StringFilterInput;
    }
    case "statusEnum": {
      if (columnType.allowedValues.length > 0) {
        return StatusEnumFilterInput;
      }

      return StringFilterInput;
    }
  }
};
