import { groupByNoUndefined, map } from "@archetype/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClickAway } from "react-use";

import { useFocusWithin } from "../../hooks/useFocusWithin";
import { cn } from "../../lib/utils";
import { Button } from "../atoms/button";
import { Command, CommandGroup, CommandInputAsTextInput, CommandItem, CommandList } from "../atoms/command";
import type { IIconNames } from "../atoms/icon";
import { Icon } from "../atoms/icon";
import { Spinner } from "../atoms/spinner";

export type IMagicInputItemIcon =
  | {
      type: "icon";
      icon: IIconNames;
    }
  | {
      type: "component";
      icon: React.ReactNode;
    };

export type IMagicInputItem = {
  category: string;
  iconLeft?: IMagicInputItemIcon;
  iconRight?: IMagicInputItemIcon;
  title: string;
  cta: {
    title: string;
    icon: IIconNames;
    onClick: () => void;
  };
};

export type IMagicInputSuggestion =
  | ({
      isLoading: false;
    } & IMagicInputItem)
  | {
      isLoading: true;
      category: string;
      title: string;
    };

const DEFAULT_CATEGORY = "default";

interface IMagicInput {
  inputRef: React.Ref<HTMLInputElement>;
  onChange?: (search: string) => void;
  className?: string;
  value: string;
  suggestion?: IMagicInputSuggestion;
  items?: IMagicInputItem[];
  placeholder: string;
  isLoading?: boolean;
  autoFocus?: boolean;
  onSubmit?: () => void;
  onSuggestion?: () => void;
  small?: boolean;
  subtle?: boolean;
  iconLeft?: IIconNames;
  showCategories?: boolean;
}

export const MagicInput: React.FC<IMagicInput> = ({
  className,
  inputRef,
  value,
  placeholder,
  isLoading,
  onChange,
  onSubmit,
  onSuggestion,
  items,
  small,
  subtle,
  suggestion,
  autoFocus,
  iconLeft,
  showCategories = true,
}) => {
  const commandRef = useRef<HTMLDivElement>(null);
  const { hasFocus, resetFocus } = useFocusWithin(commandRef);
  const [isOpen, setIsOpen] = useState(false);

  useClickAway(commandRef, () => {
    setIsOpen(false);
    resetFocus();
  });

  useEffect(() => {
    if (hasFocus) {
      setIsOpen(true);
    }
  }, [hasFocus]);

  const handleSelect = useCallback(
    (item: IMagicInputItem): void => {
      setIsOpen(false);
      resetFocus();
      item.cta.onClick();
    },
    [resetFocus],
  );

  const handleSuggestionSelect = useCallback(
    (item: IMagicInputSuggestion): void => {
      setIsOpen(false);
      resetFocus();

      if (!item.isLoading) {
        item.cta.onClick();
      }
    },
    [resetFocus],
  );

  const handleChange = useCallback(
    (search: string) => {
      onChange?.(search);
    },
    [onChange],
  );

  const handleSubmit = onSubmit;

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        onSubmit?.();
      }
    },
    [onSubmit],
  );

  const handleSuggestion = useCallback(() => {
    onSuggestion?.();
  }, [onSuggestion]);

  const getItems = (): React.ReactNode => {
    if (items == null || items.length === 0) {
      return null;
    }

    const itemsByCategory = groupByNoUndefined(items, (item) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
      const category = item.category ?? DEFAULT_CATEGORY;

      return category;
    });

    if (!showCategories) {
      return iterateItems(items, handleSelect);
    }

    return map(itemsByCategory, (categoryItems, category) => {
      return (
        <CommandGroup key={category} heading={category}>
          {iterateItems(categoryItems, handleSelect)}
        </CommandGroup>
      );
    });
  };

  return (
    <Command ref={commandRef} className={cn("relative overflow-visible", subtle !== true && "border-magic", className)}>
      <div className={cn("relative flex w-full items-center")}>
        <CommandInputAsTextInput
          ref={inputRef}
          autoComplete="off"
          autoFocus={autoFocus}
          className={cn(small === true ? "rounded" : "rounded-md", iconLeft != null && "[&>*]:!pl-8")}
          disabled={isLoading}
          icon={undefined}
          placeholder={placeholder}
          small={small}
          value={value}
          onKeyDown={handleInputKeyDown}
          onValueChange={handleChange}
        />
        {iconLeft != null && (
          <Icon className="absolute left-2 top-1/2 size-4 -translate-y-1/2 text-purple-500" name={iconLeft} />
        )}
        {handleSubmit == null ? null : (
          <Button
            className={cn(
              "absolute right-1.5 top-1/2 !size-8 -translate-y-1/2",
              small === true && "right-1 top-1/2 !h-6 -translate-y-1/2",
              subtle !== true &&
                "text-purple-500 hover:!bg-purple-100 focus:!bg-purple-100 focus-visible:!ring-purple-300 active:!ring-purple-300",
            )}
            data-dd-action-name="Submit"
            disabled={isLoading}
            iconLeft="chevron-right"
            isLoading={isLoading}
            size={small !== true ? "lg" : "xs"}
            title="Submit"
            type="submit"
            variant="ghost"
            onClick={handleSubmit}
          />
        )}
        {onSuggestion == null ? null : (
          <Button
            className={cn(
              "absolute right-1.5 top-1/2 !size-8 -translate-y-1/2 hover:!bg-purple-50 focus:!bg-purple-50 focus-visible:!ring-purple-300 active:!ring-purple-300",
              small === true && "right-1 top-1/2 !h-6 -translate-y-1/2",
            )}
            data-dd-action-name="Generate Suggestion"
            disabled={isLoading}
            isLoading={isLoading}
            size={small !== true ? "lg" : "xs"}
            title="Generate Suggestion"
            type="button"
            variant="ghost"
            onClick={handleSuggestion}
          >
            {isLoading === true ? (
              <Spinner className="mt-1" />
            ) : (
              <Icon className={cn(subtle !== true && "text-purple-500")} name="sparkles" />
            )}
          </Button>
        )}
      </div>

      {isOpen && (suggestion != null || (items != null && items.length > 0)) ? (
        <div className="relative">
          <div className="bg-paper ring-border absolute top-0 z-10 mt-1 w-full rounded-md outline-none ring-1">
            <CommandList>
              {suggestion ? (
                <CommandGroup key="suggestions" heading="Suggestion">
                  <MagicInputSuggestion
                    key={suggestion.title}
                    suggestion={suggestion}
                    value={value}
                    onSelect={handleSuggestionSelect}
                  />
                </CommandGroup>
              ) : null}
              {getItems()}
            </CommandList>
          </div>
        </div>
      ) : null}
    </Command>
  );
};

const MagicInputSuggestion: React.FC<{
  suggestion: IMagicInputSuggestion;
  value: string;
  onSelect: (item: IMagicInputSuggestion) => void;
}> = ({ suggestion, value, onSelect }) => {
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const handleSelect = useCallback(() => {
    onSelect(suggestion);
  }, [onSelect, suggestion]);

  const contents = useMemo(() => {
    if (suggestion.isLoading) {
      return (
        <div className="flex shrink items-center space-x-2">
          <Spinner />
          <div className="truncate text-nowrap">{suggestion.title}</div>
        </div>
      );
    }

    return (
      <>
        <div className="flex w-full shrink items-center justify-between">
          <div className="flex items-center space-x-2 overflow-hidden">
            {suggestion.iconLeft?.type === "icon" && (
              <Icon className="size-3 shrink-0" name={suggestion.iconLeft.icon} />
            )}
            {suggestion.iconLeft?.type === "component" && <div className="shrink-0">{suggestion.iconLeft.icon}</div>}
            <div className="truncate">{suggestion.title}</div>
          </div>
          <div className="flex shrink-0 items-center space-x-2">
            {suggestion.iconRight?.type === "icon" && <Icon className="size-3" name={suggestion.iconRight.icon} />}
            {suggestion.iconRight?.type === "component" && suggestion.iconRight.icon}
          </div>
        </div>
        <Icon name={suggestion.cta.icon} />
      </>
    );
  }, [suggestion]);

  return (
    <CommandItem
      className="flex w-full cursor-pointer items-center justify-between text-sm text-purple-500 aria-selected:bg-purple-100 aria-selected:text-purple-600"
      value={value}
      onMouseDown={handleMouseDown}
      onSelect={handleSelect}
    >
      {contents}
    </CommandItem>
  );
};

const MagicInputListItem: React.FC<{ item: IMagicInputItem; onSelect: (item: IMagicInputItem) => void }> = ({
  item,
  onSelect,
}) => {
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const handleSelect = useCallback(() => {
    onSelect(item);
  }, [onSelect, item]);

  return (
    <CommandItem
      className="flex w-full cursor-pointer items-center justify-between text-sm"
      value={item.title}
      onMouseDown={handleMouseDown}
      onSelect={handleSelect}
    >
      <div className="flex w-full items-center">
        <div className="mr-2 shrink-0">
          {item.iconLeft?.type === "icon" && <Icon className="size-3" name={item.iconLeft.icon} />}
          {item.iconLeft?.type === "component" && item.iconLeft.icon}
        </div>
        <div className="grow truncate">
          <div className="truncate">{item.title}</div>
        </div>
        <div className="ml-2 shrink-0">
          {item.iconRight?.type === "icon" && <Icon className="size-3" name={item.iconRight.icon} />}
          {item.iconRight?.type === "component" && item.iconRight.icon}
        </div>
      </div>
      <Icon name={item.cta.icon} />
    </CommandItem>
  );
};

const iterateItems = (categoryItems: IMagicInputItem[], onSelect: (item: IMagicInputItem) => void): React.ReactNode => {
  const handleSelect = onSelect;

  return categoryItems.map((item) => {
    return <MagicInputListItem key={`${item.title}-${item.category}`} item={item} onSelect={handleSelect} />;
  });
};
