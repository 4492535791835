import type { IVersionType } from "@archetype/dsl";
import type { IActionId, IEntityId, IEntityTypeId } from "@archetype/ids";
import type { IBreadcrumbNavItem } from "@archetype/ui";
import { simpleLinkRenderer } from "@archetype/ui";
import React from "react";

import type { IEntityIdOrCreateActionDraft, IGetActionRoute } from "../api";
import { ActionBreadcrumb } from "./ActionBreadcrumb";

export function generateActionBreadcrumb({
  actionId,
  entityTypeId,
  entityId,
  isCreateActionDraft,
  isCurrentPage,
  versionType,
  getActionRoute,
}: {
  actionId?: IActionId;
  entityTypeId?: IEntityTypeId;
  entityId?: IEntityId;
  isCreateActionDraft: boolean;
  isCurrentPage: boolean;
  versionType: IVersionType;
  getActionRoute: IGetActionRoute;
}): IBreadcrumbNavItem {
  const entityAndCreateActionDraft: IEntityIdOrCreateActionDraft =
    entityId == null
      ? {
          entityId: undefined,
          isCreateActionDraft: false,
        }
      : { entityId, isCreateActionDraft };
  const linkRenderer =
    actionId == null || entityTypeId == null
      ? undefined
      : simpleLinkRenderer(
          getActionRoute({ actionId, entityTypeId, defaultValues: undefined, ...entityAndCreateActionDraft }),
        );

  return {
    text: <ActionBreadcrumb actionId={actionId} versionType={versionType} />,
    isCurrentPage,
    linkRenderer,
  };
}
