import {
  ActionId,
  ActionLogId,
  ActionVersionId,
  EmailId,
  EntityId,
  EntityTypeId,
  EntityTypeVersionId,
  RelationId,
  RelationVersionId,
  UserId,
  ViewFieldId,
} from "@archetype/ids";
import { z } from "zod";

import { ViewFieldValue } from "./ViewFieldValue";

export const EmailExtractedActionInput = z.object({
  emailId: EmailId,
  viewFieldId: ViewFieldId,
  foundInField: z.enum(["from", "subject", "body"]),
  startCharIndexInclusive: z.number(),
  endCharIndexExclusive: z.number(),
});

export const ActionExecutedBy = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("user"),
    userId: UserId,
    /**
     * Emails may not succcessfully execute the action, and create a draft that is then executed by the user, but we can still show emails here.
     */
    emailIds: z.array(EmailId).nullable(),
  }),
  z.object({
    type: z.literal("externalUser"),
    userEntityId: EntityId,
  }),
  z.object({
    type: z.literal("aiAgent"),
    aiAgentId: z.string(),
  }),
  z.object({
    type: z.literal("email"),
    /**
     * Email actually triggering the action execution.
     */
    emailId: EmailId,
    /**
     * Other emails that may have been captured in the draft that then gets executed by the other email.
     */
    otherEmailIds: z.array(EmailId).nullable(),
    userId: UserId,
  }),
]);

export type IActionExecutedBy = z.infer<typeof ActionExecutedBy>;

export const ActionLog = z.object({
  actionLogId: ActionLogId,
  actionId: ActionId,
  actionVersionId: ActionVersionId,
  entityTypeId: EntityTypeId,
  entityTypeVersionId: EntityTypeVersionId,
  relationVersionIds: z.record(RelationId, RelationVersionId.optional()).nullable(), // nullable for backwards compatibility
  entityId: EntityId,
  entitySnapshotBefore: z.record(ViewFieldId, ViewFieldValue.optional()).nullable(),
  entitySnapshotAfter: z.record(ViewFieldId, ViewFieldValue.optional()).nullable(),
  inputs: z.record(ViewFieldId, ViewFieldValue.optional()),
  emailExtractedData: z.record(ViewFieldId, EmailExtractedActionInput.optional()).nullable(),
  executedBy: ActionExecutedBy,
  executedAt: z.date(),
  /**
   * Comment added with the action execution, currently mostly explaining the choice of the ai agent.
   */
  comment: z.string().nullable(),
});
export type IActionLog = z.infer<typeof ActionLog>;
export type IEmailExtractedActionInput = z.infer<typeof EmailExtractedActionInput>;
