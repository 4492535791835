import type {
  IDataLoadingRelationConfig,
  IEntityType,
  IPerColumnAndedFilters,
  IRelation,
  IRelationPathNode,
} from "@archetype/dsl";
import { USER_ENTITY_ID_COLUMN_ID } from "@archetype/dsl";
import type { IEntityId, IEntityTypeId, IRelationId } from "@archetype/ids";
import { isNonNullable } from "@archetype/utils";
import { values } from "lodash";

import type { ILoadedCrossColumnAndConditions, ILoadedRelatedToFilter } from "./loadedQuery";

export interface IResolvedRelationTraversal {
  relation: IRelation;
  toEntityType: IEntityType;
  direction: "aToB" | "bToA";
}

export const pathToRelatedToFilter = ({
  path: _path,
  fromEntityMatchingFilters,
  userEntityMatchingFilters,
  authorizedForUserEntityId,
}: {
  path: IResolvedRelationTraversal[];
  userEntityMatchingFilters: IPerColumnAndedFilters;
  fromEntityMatchingFilters: IPerColumnAndedFilters;
  authorizedForUserEntityId: IEntityId;
}): ILoadedCrossColumnAndConditions => {
  // copy array to avoid mutating the original
  const path = _path.slice();

  if (path.length === 0) {
    return {
      type: "and",
      perColumn: {
        ...fromEntityMatchingFilters,
        ...userEntityMatchingFilters,
        [USER_ENTITY_ID_COLUMN_ID]: {
          type: "and",
          andedOrConditions: [],
          rawAndOperations: {
            eq: {
              type: "value",
              value: {
                type: "string",
                value: authorizedForUserEntityId,
              },
            },
          },
        },
      },
      andedCrossColumnOrConditions: [],
      andedRelatedToFilters: [],
    };
  }

  let andedRelatedToFilter: ILoadedRelatedToFilter | undefined = undefined;

  while (path.length > 0) {
    // grab the last segment
    const segment = path.pop();

    if (segment == null) {
      throw new Error("Developer error: should not be able to get here");
    }

    const { relation, direction, toEntityType } = segment;

    andedRelatedToFilter = {
      type: "relatedTo",
      relation,
      direction,
      toEntityType,
      filters: {
        type: "and",
        perColumn:
          andedRelatedToFilter == null
            ? {
                ...userEntityMatchingFilters,
                [USER_ENTITY_ID_COLUMN_ID]: {
                  type: "and",
                  andedOrConditions: [],
                  rawAndOperations: {
                    eq: {
                      type: "value",
                      value: {
                        type: "string",
                        value: authorizedForUserEntityId,
                      },
                    },
                  },
                },
              }
            : {},
        andedRelatedToFilters: andedRelatedToFilter != null ? [andedRelatedToFilter] : [],
        andedCrossColumnOrConditions: [],
      },
    };
  }

  if (andedRelatedToFilter == null || andedRelatedToFilter.filters == null) {
    throw new Error("Developer error: should not be able to get here");
  }

  return {
    type: "and",
    perColumn: fromEntityMatchingFilters,
    andedCrossColumnOrConditions: [],
    andedRelatedToFilters: [andedRelatedToFilter],
  };
};

export const resolvePathNode = (
  relationsById: Record<IRelationId, IRelation>,
  entityTypesById: Record<IEntityTypeId, IEntityType>,
  node: IRelationPathNode,
): IResolvedRelationTraversal | undefined => {
  const relation = relationsById[node.relationId];

  if (relation == null) {
    return undefined;
  }

  const entityType = entityTypesById[node.direction === "aToB" ? relation.entityTypeIdB : relation.entityTypeIdA];

  if (entityType == null) {
    return undefined;
  }

  return {
    relation,
    direction: node.direction,
    toEntityType: entityType,
  };
};

export const resolveSpecificOrAllRelations = ({
  specificRelations,
  relationsById,
  entityTypesById,
  entityType,
}: {
  specificRelations: IDataLoadingRelationConfig[] | null;
  relationsById: Record<IRelationId, IRelation>;
  entityTypesById: Record<IEntityTypeId, IEntityType>;
  entityType: IEntityType;
}): Array<IResolvedRelationTraversal> => {
  if (specificRelations != null) {
    return specificRelations
      .map(({ id, direction }) =>
        resolvePathNode(relationsById, entityTypesById, {
          relationId: id,
          direction,
        }),
      )
      .filter(isNonNullable);
  }

  // Default to loading all relations, in any possible direction
  return values(relationsById).flatMap((relation): IResolvedRelationTraversal[] => {
    const entityTypeA = entityTypesById[relation.entityTypeIdA];
    const entityTypeB = entityTypesById[relation.entityTypeIdB];

    if (entityTypeA == null || entityTypeB == null) {
      return [];
    }

    const traversals: IResolvedRelationTraversal[] = [];

    if (relation.entityTypeIdA === entityType.id) {
      traversals.push({
        relation,
        direction: "aToB",
        toEntityType: entityTypeB,
      });
    }
    if (relation.entityTypeIdB === entityType.id) {
      traversals.push({
        relation,
        direction: "bToA",
        toEntityType: entityTypeA,
      });
    }

    return traversals;
  });
};
