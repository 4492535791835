import { generateEditsFromWorkspace } from "../generateEditsFromWorkspace";
import type { IEditOperation, IOperationsEdits, ISaveableOperationsEdits } from "./operations";
import { applyOperation } from "./operationsHandling";

export const applyOperations = ({
  operations,
  currentInfo,
}: {
  operations: IEditOperation[];
  currentInfo: IOperationsEdits;
}): ISaveableOperationsEdits => {
  let operationEdits = currentInfo;

  operations.forEach((operation) => {
    operationEdits = applyOperation(operation, operationEdits);
  });

  // Standardize and build actions
  return generateEditsFromWorkspace(operationEdits);
};
