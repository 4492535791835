import type { ILoadedViewField } from "@archetype/core";
import { makeAnnotatedEmailFromExtractedInputs } from "@archetype/core";
import type { IEmail, IEmailExtractedActionInput } from "@archetype/dsl";
import type { IViewFieldId } from "@archetype/ids";
import type { IEmailComposer, IEmailRecipient } from "@archetype/ui";
import { useMemoDeepCompare } from "@archetype/ui";
import { pickBy } from "lodash";

export interface IEmailAsComposerProps {
  email: IEmail;
  /**
   * Only used for email annotations so if emailExtractedData is provided
   */
  allFieldsById?: Record<IViewFieldId, ILoadedViewField>;
  emailExtractedData?: Partial<Record<IViewFieldId, IEmailExtractedActionInput>>;
}

export const useEmailAsComposer = ({
  email,
  allFieldsById,
  emailExtractedData,
}: IEmailAsComposerProps): Pick<IEmailComposer, "bcc" | "body" | "cc" | "from" | "subject" | "title" | "to"> => {
  const ARCHIE_EMAIL_SUFFIXES = ["@mail.archetype.do", "@inbound.postmarkapp.com"];

  const fromRecipient: IEmailRecipient = {
    value: email.fromEmailAddress,
    label: email.fromName,
  };

  const toRecipients: IEmailRecipient[] = email.sentTo.fullInfo.map((recipient) =>
    ARCHIE_EMAIL_SUFFIXES.some((suffix) => recipient.emailAddress.endsWith(suffix))
      ? {
          value: recipient.emailAddress,
          label: "Archie Archetype",
        }
      : {
          value: recipient.emailAddress,
          label: recipient.name !== "" ? recipient.name : recipient.emailAddress,
        },
  );

  const ccRecipients: IEmailRecipient[] = email.sentCc.fullInfo.map((recipient) =>
    ARCHIE_EMAIL_SUFFIXES.some((suffix) => recipient.emailAddress.endsWith(suffix))
      ? {
          value: recipient.emailAddress,
          label: "Archie Archetype",
        }
      : {
          value: recipient.emailAddress,
          label: recipient.name !== "" ? recipient.name : recipient.emailAddress,
        },
  );

  const bccRecipients: IEmailRecipient[] =
    email.sentBcc == null
      ? []
      : [
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- in function but actually checked above and typechecked
          ARCHIE_EMAIL_SUFFIXES.some((suffix) => email.sentBcc!.emailAddress.endsWith(suffix))
            ? {
                value: email.sentBcc.emailAddress,
                label: "Archie Archetype",
              }
            : {
                value: email.sentBcc.emailAddress,
                label: email.sentBcc.name !== "" ? email.sentBcc.name : email.sentBcc.emailAddress,
              },
        ];

  const currentEmailExtractedData = useMemoDeepCompare(
    () =>
      emailExtractedData == null
        ? {}
        : pickBy(emailExtractedData, (extractedData) => extractedData?.emailId === email.id),
    [emailExtractedData, email.id],
  );

  const annotatedEmail = useMemoDeepCompare(
    () =>
      makeAnnotatedEmailFromExtractedInputs({
        email: email.body,
        emailExtractedData: currentEmailExtractedData,
        viewFieldById: allFieldsById ?? {},
      }),
    [email.body, currentEmailExtractedData, allFieldsById],
  );

  return {
    bcc: bccRecipients,
    body: annotatedEmail,
    cc: ccRecipients,
    from: fromRecipient,
    subject: email.subject,
    title: email.subject,
    to: toRecipients,
  };
};
