"use client";

import * as React from "react";
import { useMemo } from "react";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./accordion";
import { FeatureConfigItem, type IFeatureConfigItem } from "./feature-config-item";

interface IFeatureConfigTreeProps {
  config: IFeatureConfigItem;
  status: "building" | "complete";
}

export function FeatureConfigTree({ config, status }: IFeatureConfigTreeProps): React.JSX.Element {
  const processedConfig: IFeatureConfigItem = useMemo(
    () => ({
      ...config,
      status: status === "building" ? "loading" : "complete",
    }),
    [config, status],
  );

  // Check if there are any items with loading status in the tree
  const hasLoadingItems = useMemo((): boolean => {
    // Recursive function to check for loading items
    const checkForLoadingStatus = (item: IFeatureConfigItem): boolean => {
      if (item.status === "loading") {
        return true;
      }

      if (item.children && item.children.length > 0) {
        return item.children.some(checkForLoadingStatus);
      }

      return false;
    };

    return checkForLoadingStatus(processedConfig);
  }, [processedConfig]);

  // Get all config item names to use as default values when loading
  const allConfigItemNames = useMemo((): string[] => {
    const names: string[] = ["root"];

    const collectNames = (items: IFeatureConfigItem[]): void => {
      for (const item of items) {
        names.push(item.configName);
        if (item.children && item.children.length > 0) {
          collectNames(item.children);
        }
      }
    };

    if (processedConfig.children) {
      collectNames(processedConfig.children);
    }

    return names;
  }, [processedConfig]);

  function renderConfigItems(items: IFeatureConfigItem[]): React.ReactNode[] {
    return items.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <AccordionItem key={item.configName} value={item.configName}>
            <AccordionTrigger className="flex items-center gap-2 text-sm">
              <FeatureConfigItem item={item} />
            </AccordionTrigger>
            <AccordionContent>
              <div className="ml-4">
                {hasLoadingItems ? (
                  <Accordion defaultValue={allConfigItemNames} type="multiple">
                    {renderConfigItems(item.children)}
                  </Accordion>
                ) : (
                  <Accordion defaultValue={[item.configName]} type="multiple">
                    {renderConfigItems(item.children)}
                  </Accordion>
                )}
              </div>
            </AccordionContent>
          </AccordionItem>
        );
      }

      return (
        <AccordionItem key={item.configName} value={item.configName}>
          <AccordionTrigger className="flex items-center gap-2 text-sm">
            <FeatureConfigItem item={item} />
          </AccordionTrigger>
        </AccordionItem>
      );
    });
  }

  return (
    <Accordion className="w-full" defaultValue={["root"]} type="multiple">
      <AccordionItem value="root">
        <AccordionTrigger className="flex items-center gap-2 text-sm">
          <FeatureConfigItem isRoot item={processedConfig} />
        </AccordionTrigger>
        <AccordionContent>
          <div className="ml-4">
            {hasLoadingItems ? (
              <Accordion defaultValue={allConfigItemNames} type="multiple">
                {processedConfig.children ? (
                  renderConfigItems(processedConfig.children)
                ) : (
                  <div className="-ml-4">
                    <FeatureConfigItem item={processedConfig} />
                  </div>
                )}
              </Accordion>
            ) : (
              <Accordion collapsible type="single">
                {processedConfig.children ? (
                  renderConfigItems(processedConfig.children)
                ) : (
                  <div className="-ml-4">
                    <FeatureConfigItem item={processedConfig} />
                  </div>
                )}
              </Accordion>
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
