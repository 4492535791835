import type { IActionCore, IActionDefinition, IEntityTypeCore, IViewField } from "@archetype/dsl";
import { isColumnViewField } from "@archetype/dsl";
import type { IActionId, IStateId } from "@archetype/ids";
import { isNonNullable, mapValues } from "@archetype/utils";
import { clone, pickBy } from "lodash";

import type { IOperationsEdits, IReplaceViewFieldOperation } from "./operations";

export const applyReplaceViewFieldOperation = (
  operation: IReplaceViewFieldOperation,
  currentInfo: IOperationsEdits,
): IOperationsEdits => {
  const { oldViewField, newViewField, entityTypeId } = operation;

  const currentEntityType = currentInfo.newOrEditedEntityTypes[entityTypeId];

  if (currentEntityType == null) {
    throw new Error("Entity type not found");
  }

  const editedActions: Record<IActionId, IActionCore> = {};

  operation.actionIds?.forEach((actionId) => {
    const action = currentInfo.newOrEditedActions[actionId];

    if (action == null) {
      throw new Error("Action not found");
    }

    const editedActionDefinition = generateUpdatedActionDefinition(action.actionDefinition, oldViewField, newViewField);

    if (editedActionDefinition == null) {
      // Nothing to update, skip
      return;
    }

    editedActions[actionId] = {
      ...action,
      actionDefinition: editedActionDefinition,
    };
  });

  const newRelevantFields: Record<IStateId, IViewField[]> = pickBy(
    mapValues(currentEntityType.relevantViewFieldsByStateId, (viewFields) => {
      return generateUpdateRelevantFieldsInfo(viewFields, oldViewField, newViewField);
    }),
    isNonNullable,
  );
  // const viewForStateEditInfos: IViewFieldsForStateEditInfo[] = map(
  //   currentTargetEntityType.relevantViewFieldsByStateId,
  //   (viewFields, stateId) => {
  //     const newRelevantFields = generateUpdateRelevantFieldsInfo(viewFields, oldViewField, newViewField);
  //     return newRelevantFields == null
  //       ? undefined
  //       : {
  //           entityTypeId: entityType.id,
  //           stateId: stateId as IStateId,
  //           fields: newRelevantFields,
  //         };
  //   },
  // ).filter(isNonNullable);

  // New targetEntityType

  const editedEntityType: IEntityTypeCore = {
    ...currentEntityType,
    relevantViewFieldsByStateId: newRelevantFields,
  };

  return {
    ...currentInfo,
    newOrEditedEntityTypes: {
      ...currentInfo.newOrEditedEntityTypes,
      [editedEntityType.id]: editedEntityType,
    },
    newOrEditedActions: {
      ...currentInfo.newOrEditedActions,
      ...editedActions,
    },
  };
};

const generateUpdatedActionDefinition = (
  actionDefinition: IActionDefinition,
  oldViewField: IViewField,
  newViewField: IViewField,
): IActionDefinition | undefined => {
  const inputToReplaceIndex = actionDefinition.inputs.findIndex((i) => i.viewField.id === oldViewField.id);
  const contextFieldToReplaceIndex = actionDefinition.contextualFields.findIndex((f) => f.id === oldViewField.id);

  if (inputToReplaceIndex === -1 && contextFieldToReplaceIndex === -1) {
    return undefined;
  }

  const inputs = clone(actionDefinition.inputs);
  const contextualFields = clone(actionDefinition.contextualFields);

  if (inputToReplaceIndex !== -1) {
    inputs[inputToReplaceIndex] = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- safe with logic
      ...inputs[inputToReplaceIndex]!,
      viewField: newViewField,
    };
  }

  if (contextFieldToReplaceIndex !== -1) {
    // for now, we don't support relations in context fields
    if (isColumnViewField(newViewField)) {
      contextualFields[contextFieldToReplaceIndex] = newViewField;
    }
  }

  return {
    ...actionDefinition,
    contextualFields: contextualFields,
    inputs: inputs,
  };
};

const generateUpdateRelevantFieldsInfo = (
  relevantViewFields: IViewField[],
  oldViewField: IViewField,
  newViewField: IViewField,
): IViewField[] => {
  const newViewFields = clone(relevantViewFields);
  const viewFieldToReplaceIndex = newViewFields.findIndex((f) => f.id === oldViewField.id);

  if (viewFieldToReplaceIndex === -1) {
    return newViewFields;
  }

  newViewFields[viewFieldToReplaceIndex] = newViewField;

  return newViewFields;
};
