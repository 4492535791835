import type { IFieldComputationInfo, ILoadedEntity } from "@archetype/core";
import type { IColumnType, IStateColor, IVersionType } from "@archetype/dsl";
import type { IEntityId, IEntityTypeId, IOrganizationId, IViewFieldId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import {
  BadgeWithDot,
  DEFAULT_TRANSFORMERS,
  defaultConfig,
  DefaultPlugins,
  DotPinging,
  FormattedUrl,
  Loader,
  MarkdownEditor,
  markdownToEditorState,
  PhoneInput,
  useMemoDeepCompare,
} from "@archetype/ui";
import { cn } from "@archetype/ui";
import assertNever from "assert-never";
import { concat } from "lodash";
import Link from "next/link";
import * as React from "react";
import { useCallback, useMemo } from "react";

import type { IGetLinkedEntityRoute } from "../api";
import { EntityFieldMentionNode } from "../markdown/EntityFieldMentionNode";
import {
  EntityFieldMentionsPlugin,
  generateEntityFieldMentionTransformer,
} from "../markdown/EntityFieldMentionsPlugin";

type IFieldValueFile = { id: string; vendor: "vercel"; fileName: string; fileUrl: string; fileSizeInBytes: number };
type IFieldValueRelation = { entityId: IEntityId; displayName: string; entityTypeId: IEntityTypeId };

export type IFieldValueRendererFieldValue =
  | {
      type: "null";
    }
  | {
      type: "string";
      value: string | null;
    }
  | {
      type: "status";
      value: string | null;
      color: IStateColor;
    }
  | {
      type: "number";
      value: number | null;
    }
  | {
      type: "phone";
      value: string | null;
    }
  | {
      type: "url";
      value: string | null;
    }
  | {
      type: "relation";
      value: IFieldValueRelation | null;
    }
  | {
      type: "relations";
      value: Array<IFieldValueRelation> | null;
    }
  | {
      type: "files";
      value: Array<IFieldValueFile> | null;
    };

export type IFieldValueRendererField = {
  id: IViewFieldId;
  displayName: string;
  computationStatus: IFieldComputationInfo["status"] | undefined;
  isSavingInAction: boolean;
  value: IFieldValueRendererFieldValue;
  column?: { columnType: IColumnType };
};

export const FieldValueRenderer: React.FC<{
  field: IFieldValueRendererField;
  entity: ILoadedEntity | undefined;
  getLinkedEntityRoute: IGetLinkedEntityRoute | undefined;
  wrapperClass?: string;
  versionType: IVersionType;
  entityTypeId: IEntityTypeId;
  organizationId: IOrganizationId;
}> = ({ field, entity, getLinkedEntityRoute, wrapperClass, versionType, entityTypeId, organizationId }) => {
  const { data: entityTypeQuery } = builderTrpc.dataModel.fullyLoadedEntityType.useQuery({
    id: entityTypeId,
    versionType,
  });

  const transformers = useMemo(
    () =>
      concat(
        generateEntityFieldMentionTransformer({
          fieldValues: entity?.fields ?? {},
          loadedEntityType: entityTypeQuery?.entityType,
          variant: "text",
        }),
        DEFAULT_TRANSFORMERS,
      ),
    [entity?.fields, entityTypeQuery?.entityType],
  );

  const emptyValue = useMemo(() => {
    return <div className={cn({ wrapperClass }, "text-placeholder text-base leading-5")}>Empty</div>;
  }, [wrapperClass]);

  const renderRelation = useCallback(
    (link: IFieldValueRelation) => {
      const content = (
        <div key={link.entityId} role="button">
          {link.displayName}
        </div>
      );

      if (getLinkedEntityRoute != null) {
        return (
          <Link href={getLinkedEntityRoute({ entityTypeId: link.entityTypeId, entityId: link.entityId })}>
            {content}
          </Link>
        );
      }

      return content;
    },
    [getLinkedEntityRoute],
  );

  const renderFieldRelations = useCallback(
    (relationField: IFieldValueRendererFieldValue) => {
      if (relationField.type !== "relations" || relationField.value == null || relationField.value.length === 0) {
        return emptyValue;
      }

      return <div className="flex flex-wrap gap-x-2">{relationField.value.map((link) => renderRelation(link))}</div>;
    },
    [renderRelation, emptyValue],
  );

  const valueContent = useMemoDeepCompare(() => {
    if (field.value.type === "null" || field.value.value == null) {
      return emptyValue;
    }

    switch (field.value.type) {
      case "files": {
        return (
          <div className={wrapperClass}>
            {field.value.value.map((file) => (
              <div key={file.id}>
                <a href={file.fileUrl} rel="noopener noreferrer" target="_blank">
                  {file.fileName}
                </a>
              </div>
            ))}
          </div>
        );
      }

      case "url": {
        return (
          <div className={wrapperClass}>
            <a href={field.value.value} rel="noopener noreferrer" target="_blank">
              <FormattedUrl url={field.value.value} />
            </a>
          </div>
        );
      }

      case "number": {
        return <div className={wrapperClass}>{field.value.value}</div>;
      }

      case "phone": {
        return (
          <div className={wrapperClass}>
            <PhoneInput className="bg-transparent" disabled={true} value={field.value.value} variant="text" />
          </div>
        );
      }

      case "string": {
        if (field.column?.columnType.type === "longText") {
          const editorConfig = {
            ...defaultConfig,
            editorState: markdownToEditorState(field.value.value, transformers),
            nodes: (defaultConfig.nodes ?? []).concat(EntityFieldMentionNode),
          };

          const fieldValues = entity?.fields ?? {};

          return (
            <MarkdownEditor
              className={wrapperClass}
              enableAnnotations={false}
              initialConfig={editorConfig}
              placeholder=""
              readOnly={true}
              small={true}
              toolbar="none"
            >
              <EntityFieldMentionsPlugin
                entityTypeId={entityTypeId}
                fieldValues={fieldValues}
                organizationId={organizationId}
                variant="text"
                versionType={versionType}
              />
              <DefaultPlugins autoFocus={false} transformers={transformers} />
            </MarkdownEditor>
          );
        }

        return <div className={wrapperClass}>{field.value.value}</div>;
      }

      case "relation": {
        return <div className={wrapperClass}>{renderRelation(field.value.value)}</div>;
      }

      case "relations": {
        return <div className={wrapperClass}>{renderFieldRelations(field.value)}</div>;
      }

      case "status": {
        const badgeVariant = field.value.color === "neutral" ? "gray" : field.value.color;

        return (
          <div className={wrapperClass}>
            <BadgeWithDot colorVariant={badgeVariant} label={field.value.value} />
          </div>
        );
      }

      default: {
        assertNever(field.value);
      }
    }
  }, [
    field.value,
    field.column?.columnType.type,
    wrapperClass,
    transformers,
    entityTypeId,
    organizationId,
    versionType,
    renderRelation,
    renderFieldRelations,
    entity?.fields,
    emptyValue,
  ]);

  const statusContent = useMemoDeepCompare(() => {
    if (field.computationStatus === "computing") {
      return <Loader text="Thinking..." variant="pyramid" />;
    }

    if (field.computationStatus === "recomputing") {
      return <Loader size="xs" text="Updating..." variant="pyramid" />;
    }

    if (field.computationStatus === "outOfDate") {
      return <span className="text-muted-foreground text-sm leading-5">· Out of date</span>;
    }

    if (field.computationStatus === "error") {
      return <span className="text-muted-foreground text-sm leading-5">Failed to update</span>;
    }

    if (field.isSavingInAction) {
      return <DotPinging color="gray" size="sm" />;
    }

    return null;
  }, [field.computationStatus, field.isSavingInAction]);

  if (field.computationStatus === "computing") {
    return (
      <div className={cn(wrapperClass, "flex items-center")}>
        <Loader text="Thinking..." variant="pyramid" />
      </div>
    );
  }

  if (statusContent != null) {
    return (
      <div className={cn(wrapperClass, "flex items-center space-x-2")}>
        {valueContent}
        {statusContent}
      </div>
    );
  }

  return valueContent;
};
