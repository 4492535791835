import { isNonNullable } from "@archetype/utils";
import { sortBy } from "lodash";
import { DateTime } from "luxon";

import type { IColumnType } from "../../../schemas/dataModel/Column";
import type {
  IBooleanColumnValue,
  IDateColumnValue,
  IEntityColumnValue,
  IFileEntityColumnValue,
  INullColumnValue,
  INumberColumnValue,
  IStringColumnValue,
} from "../../../schemas/dataModel/EntityValue";
import type { IRelationFieldValue, IViewFieldValue } from "../../../schemas/dataModel/ViewFieldValue";
import { parseValueByColumnType } from "./parseValueByColumnType";

export const DATE_STRING_FORMAT = "dd/MM/yyyy";
export const TIMESTAMP_STRING_FORMAT = "dd/MM/yyyy hh:mm a";

function toBoolean(value: unknown): boolean | undefined {
  if (["yes", "true", "1"].includes(String(value).toLowerCase())) {
    return true;
  }

  if (["no", "false", "0"].includes(String(value).toLowerCase())) {
    return false;
  }

  return undefined;
}

export const FieldValueParser = {
  toReadableString: (
    fieldValue: IViewFieldValue | undefined | null,
    columnType: IColumnType | undefined | null,
  ): string | undefined => {
    if (fieldValue == null || fieldValue.type === "null") {
      return undefined;
    }

    if (columnType?.type === "statusEnum") {
      const statusInfo =
        columnType.allowedValues.find((v) => v.id === fieldValue.value) ??
        columnType.archivedValues.find((v) => v.id === fieldValue.value);

      if (statusInfo != null) {
        return statusInfo.readableValue;
      }
    }

    return FieldValueParser.toString(fieldValue);
  },

  toString: (fieldValue: IViewFieldValue | undefined | null): string | undefined => {
    if (fieldValue == null) {
      return undefined;
    }

    switch (fieldValue.type) {
      case "string":
      case "integer":
      case "number": {
        return String(fieldValue.value);
      }
      case "boolean": {
        return fieldValue.value ? "Yes" : "No";
      }
      case "date": {
        return DateTime.fromISO(fieldValue.value, { setZone: true }).toFormat(DATE_STRING_FORMAT);
      }
      case "timestamp": {
        return DateTime.fromISO(fieldValue.value, { setZone: true }).toFormat(TIMESTAMP_STRING_FORMAT);
      }
      case "relatedEntities": {
        return fieldValue.value.map((e) => e.displayName).join(", ");
      }
      case "file": {
        return fieldValue.value.map((e) => e.fileName).join(", ");
      }
      case "array": {
        return fieldValue.value.join(", ");
      }
      case "null": {
        return undefined;
      }
    }
  },

  toBoolean: (fieldValue: IViewFieldValue | undefined | null): boolean | undefined => {
    if (fieldValue == null) {
      return undefined;
    }

    switch (fieldValue.type) {
      case "string":
      case "integer":
      case "number": {
        return toBoolean(fieldValue.value);
      }
      case "boolean": {
        return fieldValue.value;
      }
      case "array": {
        const booleans = fieldValue.value.map((e) => toBoolean(e)).filter(isNonNullable);

        if (booleans.length === 0) {
          return undefined;
        }

        return booleans.every((e) => e);
      }
      case "date":
      case "timestamp":
      case "relatedEntities":
      case "file":
      case "null": {
        return undefined;
      }
    }
  },

  toNumber: (fieldValue: IViewFieldValue | undefined | null): number | undefined => {
    if (fieldValue == null) {
      return undefined;
    }

    switch (fieldValue.type) {
      case "string": {
        const n = Number(fieldValue.value);

        return isNaN(n) ? undefined : n;
      }
      case "integer":
      case "number":
      case "boolean": {
        return Number(fieldValue.value);
      }
      case "date":
      case "timestamp": {
        return DateTime.fromISO(fieldValue.value).toMillis();
      }
      case "array": {
        if (fieldValue.value.length === 0) {
          return undefined;
        }

        return fieldValue.value.map((e) => Number(e)).find((e) => !isNaN(e));
      }
      case "relatedEntities":
      case "null":
      case "file": {
        return undefined;
      }
    }
  },

  toDateTime: (fieldValue: IViewFieldValue | undefined | null): DateTime | undefined => {
    if (fieldValue == null) {
      return undefined;
    }

    switch (fieldValue.type) {
      case "string": {
        const d = DateTime.fromISO(fieldValue.value, { setZone: true, zone: "utc" });

        if (!d.isValid) {
          return undefined;
        }

        return d.setZone(d.zoneName === "local" ? "utc" : d.zoneName);
      }
      case "integer":
      case "number": {
        const d = DateTime.fromJSDate(new Date(fieldValue.value), { zone: "utc" });

        return d.isValid ? d : undefined;
      }
      case "date": {
        return DateTime.fromISO(fieldValue.value, { setZone: true, zone: "utc" });
      }
      case "timestamp": {
        return DateTime.fromISO(fieldValue.value, { setZone: true, zone: "utc" });
      }
      case "array": {
        return fieldValue.value.map((e) => DateTime.fromISO(e, { setZone: true, zone: "utc" })).find((e) => e.isValid);
      }
      case "boolean":
      case "relatedEntities":
      case "null":
      case "file": {
        return undefined;
      }
    }
  },

  toValue: (
    fieldValue: IViewFieldValue | undefined | null,
  ):
    | IRelationFieldValue["value"]
    | DateTime
    | IFileEntityColumnValue[]
    | string
    | number
    | boolean
    | null
    | undefined => {
    if (fieldValue == null) {
      return undefined;
    }

    switch (fieldValue.type) {
      case "string": {
        return FieldValueParser.toString(fieldValue);
      }
      case "integer":
      case "number": {
        return FieldValueParser.toNumber(fieldValue);
      }
      case "boolean": {
        return FieldValueParser.toBoolean(fieldValue);
      }
      case "date":
      case "timestamp": {
        return FieldValueParser.toDateTime(fieldValue);
      }
      case "relatedEntities": {
        return fieldValue.value;
      }
      case "file": {
        return fieldValue.value;
      }
      case "array": {
        return sortBy(fieldValue.value, (v) => String(v)).join(",");
      }
      case "null": {
        return undefined;
      }
    }
  },

  toStringColumnValue: (fieldValue: IViewFieldValue | undefined | null): IStringColumnValue | INullColumnValue => {
    const value = FieldValueParser.toString(fieldValue);

    return value != null ? { type: "string", value } : { type: "null" as const };
  },

  toNumberColumnValue: (fieldValue: IViewFieldValue | undefined | null): INumberColumnValue | INullColumnValue => {
    const value = FieldValueParser.toNumber(fieldValue);

    return value != null ? { type: "number", value } : { type: "null" as const };
  },

  toBooleanColumnValue: (fieldValue: IViewFieldValue | undefined | null): IBooleanColumnValue | INullColumnValue => {
    const value = FieldValueParser.toBoolean(fieldValue);

    return value != null ? { type: "boolean", value } : { type: "null" as const };
  },

  toDateTimeColumnValue: (fieldValue: IViewFieldValue | undefined | null): IDateColumnValue | INullColumnValue => {
    const value = FieldValueParser.toDateTime(fieldValue);

    return value != null ? { type: "date", value: value.toString() } : { type: "null" as const };
  },

  toExpectedColumnValue: (
    fieldValue: IViewFieldValue | undefined | null,
    expectedColumnType: IColumnType | undefined | null,
  ): IEntityColumnValue | INullColumnValue => {
    if (fieldValue == null || expectedColumnType == null) {
      return { type: "null" as const };
    }

    if (fieldValue.type === "relatedEntities") {
      return { type: "null" as const };
    }

    return parseValueByColumnType(fieldValue, expectedColumnType);
  },
};
