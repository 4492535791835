import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { DynamicValueConfigValues } from "../..";

export const WorkflowComputedColumns = z.object({
  derivedColumns: z.array(
    z.object({
      columnName: z.string(),
      derivationLogic: ReadableString,
      recomputationType: z.enum(["live", "suggestion"]),
    }),
  ),
  aiColumns: z.array(
    z.object({
      columnName: z.string(),
      aiInstructions: ReadableString,
      recomputationType: z.enum(["live", "suggestion"]),
    }),
  ),
  defaultValuesColumns: z.array(
    z.object({
      columnName: z.string(),
      defaultValue: DynamicValueConfigValues,
    }),
  ),
});
export type IWorkflowComputedColumns = z.infer<typeof WorkflowComputedColumns>;
