import type { IStateId, ITransitionId } from "@archetype/ids";
import { forEach, groupByNoUndefined, isNonNullable, keyByNoUndefined } from "@archetype/utils";
import { findLastIndex, minBy, uniq } from "lodash";

import type { IMinimalStateMachine } from "./types";

/**
 * 
start at initial with no pre-path
for each next
  add next to a subpath
  add subpath to collection of full paths
  if next in pre-path, stop this subpath
  else go to next with pre-path=subpath

  @returns all paths that exist, stopping anywhere in the graph
  Only includes paths that have at most most one loop though. (otherwise the number of paths explodes with all combination of loops)
 */
// eslint-disable-next-line max-params -- internal and all different types and all not empty
function computeAllPaths(
  transitionsById: Record<ITransitionId, IMinimalStateMachine["stateTransitions"][number]>,
  transitionsByFromState: Record<IStateId, IMinimalStateMachine["stateTransitions"]>,
  currentState: IStateId,
  pathToCurrent: ITransitionId[],
  allowSingleLoop: boolean,
): ITransitionId[][] {
  const transitionsFromCurrent = transitionsByFromState[currentState];

  const allPathsFromCurrentPath: ITransitionId[][] = [];

  // Can assume the to must be the from of another transition, or the current
  const statesInPathSet = new Set(pathToCurrent.flatMap((transitionId) => transitionsById[transitionId]?.from));

  transitionsFromCurrent?.forEach((transition) => {
    const pathWithNext = pathToCurrent.concat(transition.id);

    const nextState = transition.to;

    if (nextState === currentState || statesInPathSet.has(nextState)) {
      if (allowSingleLoop) {
        allPathsFromCurrentPath.push(pathWithNext);
      }

      return;
    }

    allPathsFromCurrentPath.push(pathWithNext);
    allPathsFromCurrentPath.push(
      ...computeAllPaths(transitionsById, transitionsByFromState, nextState, pathWithNext, allowSingleLoop),
    );
  });

  return allPathsFromCurrentPath;
}

/**
 *
 * @returns For each state, returns list of paths to get there, a path being a list of transitions
 * There is at most one loop and only at the end.
 * i.e. if there is a loop at the beginning/middle, both options (go through loop or continue) are not added to all the subsequent paths.
 *
 * This DOES NOT include the initial creation transition, only transitions from the initial state.
 * Depending on usage, that transition must be added in consumers.
 */
export function pathsToStates(
  stateMachine: IMinimalStateMachine,
  allowSingleLoop: boolean,
): Record<IStateId, ITransitionId[][]> {
  const allStates = uniq(
    stateMachine.stateTransitions
      .flatMap((transition) => [transition.from, transition.to])
      .concat([stateMachine.initialState.state]),
  );

  const transitionsById = keyByNoUndefined(stateMachine.stateTransitions, (transition) => transition.id);
  const transitionsByFromState = groupByNoUndefined(stateMachine.stateTransitions, (transition) => transition.from);

  const allPathsToAllStates = computeAllPaths(
    transitionsById,
    transitionsByFromState,
    stateMachine.initialState.state,
    [],
    allowSingleLoop,
  );

  const allPathsByEndStateId: Record<IStateId, ITransitionId[][]> = groupByNoUndefined(allPathsToAllStates, (path) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe with logic
    path[path.length - 1] != null ? transitionsById[path[path.length - 1]!]?.to : undefined,
  );

  // Add default
  allStates.forEach((stateId) => {
    if (allPathsByEndStateId[stateId] == null) {
      allPathsByEndStateId[stateId] = [];
    }
  });

  return allPathsByEndStateId;
}

/**
 *
 * Could be optimized by not calling the function to compute all paths, actually could treat the origin state as the initial state of state machine to compute the paths
 * TODO it may be an easy simplification (but need a test)
 */
// eslint-disable-next-line max-params -- all different types and all not empty
export function pathsFromOriginStateToTargetPath(
  fromOriginState: IStateId,
  toStatesTargetPath: IStateId[],
  transitionsById: Record<ITransitionId, IMinimalStateMachine["stateTransitions"][number]>,
  pathsByToStates: Record<IStateId, ITransitionId[][]>,
): ITransitionId[] {
  const toStatesTargetPathSet = new Set(toStatesTargetPath);

  if (toStatesTargetPathSet.has(fromOriginState)) {
    return [];
  }

  const pathsToTargetPath: ITransitionId[][] = [];

  forEach(pathsByToStates, (paths, toStateForPath) => {
    if (toStatesTargetPathSet.has(toStateForPath)) {
      pathsToTargetPath.push(...paths);
    }
  });

  const withFromOriginStateIndex = pathsToTargetPath
    .map((path) => {
      const lastFromOriginStateIndex = findLastIndex(
        path,
        (transitionId) =>
          transitionsById[transitionId] != null &&
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe with logic
          transitionsById[transitionId]!.from === fromOriginState,
      );

      if (lastFromOriginStateIndex === -1) {
        return undefined;
      }

      return { path, lastFromOriginStateIndex, pathFromOriginStateLength: path.length - lastFromOriginStateIndex };
    })
    .filter(isNonNullable);

  // Check the shortest length of remain
  const bestPath = minBy(withFromOriginStateIndex, (path) => path.pathFromOriginStateLength);

  if (bestPath == null) {
    return [];
  }

  // return transitions in path from lastHappyPathIndex to end (i.e. includes the state in the target set)
  return bestPath.path.slice(bestPath.lastFromOriginStateIndex);
}
/**
 *
 * Could be optimized by not calling the function to compute all paths, and instead traverse _backwards_ from the target state to happy path.
 */
// eslint-disable-next-line max-params -- all different types and all not empty
export function pathsFromOriginPathToTargetState(
  fromStatesOriginPath: IStateId[],
  toState: IStateId,
  transitionsById: Record<ITransitionId, IMinimalStateMachine["stateTransitions"][number]>,
  pathsByToStates: Record<IStateId, ITransitionId[][]>,
): { path: ITransitionId[]; stateInOriginPath: IStateId | undefined } {
  const fromStatesPathSet = new Set(fromStatesOriginPath);

  if (fromStatesPathSet.has(toState)) {
    return {
      path: [],
      stateInOriginPath: toState,
    };
  }

  const pathsToTargetState: ITransitionId[][] = pathsByToStates[toState] ?? [];

  const withLastOriginPathIndex = pathsToTargetState
    .map((path) => {
      const lastOriginPathIndex = findLastIndex(
        path,
        (transitionId) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe with logic
          transitionsById[transitionId] != null && fromStatesPathSet.has(transitionsById[transitionId]!.from),
      );

      if (lastOriginPathIndex === -1) {
        return undefined;
      }

      return { path, lastOriginPathIndex, pathFromOriginPathLength: path.length - lastOriginPathIndex };
    })
    .filter(isNonNullable);

  // Check the shortest length of remain
  const bestPath = minBy(withLastOriginPathIndex, (path) => path.pathFromOriginPathLength);

  if (bestPath == null) {
    return {
      path: [],
      stateInOriginPath: undefined,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe with logic
  const stateInOriginPath = transitionsById[bestPath.path[bestPath.lastOriginPathIndex]!]!.from;

  return {
    // return transitions in path from lastOriginPathIndex to end
    path: bestPath.path.slice(bestPath.lastOriginPathIndex),
    stateInOriginPath: stateInOriginPath,
  };
}
