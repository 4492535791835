import { useCallback, useEffect, useRef, useState } from "react";
import { usePrevious } from "react-use";
import { useContextSelector } from "use-context-selector";

import { DataTableEditingContext } from "../context/DataTableEditingContext";
import { DataTableInstanceContext } from "../context/DataTableInstanceContext";
import { DataTableNavigationContext } from "../context/DataTableNavigationContext";

interface IUseCellContentEditable<T> {
  initialValue: T;
  rowId: string;
  colId: string;
  isCellEditing: boolean;
  readOnly: boolean;
  onChange?: (value: T) => void | Promise<void>;
  parseValue?: (value: string) => T;
  validateInput?: (event: React.KeyboardEvent<HTMLElement>) => boolean;
}

interface IUseCellContentEditableReturn<T> {
  contentEditableRef: React.RefObject<HTMLElement>;
  localValue: T;
  handleChange: (evt: React.FormEvent<HTMLElement>) => void;
  handleBlur: () => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLElement>) => void;
}

export function useCellContentEditable<T>({
  initialValue,
  rowId,
  colId,
  isCellEditing,
  readOnly,
  onChange,
  parseValue = (value: string): T => value as T,
  validateInput = (): boolean => true,
}: IUseCellContentEditable<T>): IUseCellContentEditableReturn<T> {
  const contentEditableRef = useRef<HTMLElement>(null);
  const [localValue, setLocalValue] = useState<T>(initialValue);
  const tableRef = useContextSelector(DataTableInstanceContext, (state) => state.tableRef);
  const setEditableCell = useContextSelector(DataTableEditingContext, (state) => state.setEditableCell);
  const navigateCell = useContextSelector(DataTableNavigationContext, (state) => state.navigateCell);

  const previousInitial = usePrevious(initialValue);

  useEffect(() => {
    if (previousInitial !== initialValue) {
      setLocalValue(initialValue);
    }
  }, [previousInitial, initialValue]);

  const handleChange = useCallback(
    (evt: React.FormEvent<HTMLElement>) => {
      if (readOnly) {
        return;
      }

      const newValue = parseValue(evt.currentTarget.textContent ?? "");

      setLocalValue(newValue);
    },
    [parseValue, readOnly],
  );

  const handleBlur = useCallback(() => {
    if (readOnly) {
      return;
    }

    const currentValue = parseValue(contentEditableRef.current?.textContent ?? "");

    void onChange?.(currentValue);

    setEditableCell(null);

    tableRef.current?.focus();
  }, [onChange, parseValue, tableRef, readOnly, setEditableCell]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (readOnly) {
        return;
      }

      if (event.key === "Enter" && !event.shiftKey && isCellEditing) {
        event.preventDefault();

        const currentValue = parseValue(contentEditableRef.current?.textContent ?? "");

        void onChange?.(currentValue);

        setEditableCell(null);

        navigateCell("down", rowId, colId);

        return;
      }

      if (event.key === "Escape" && isCellEditing) {
        event.preventDefault();

        setEditableCell(null);

        tableRef.current?.focus();

        return;
      }

      if (!validateInput(event)) {
        event.preventDefault();
      }
    },
    [
      readOnly,
      isCellEditing,
      validateInput,
      parseValue,
      onChange,
      setEditableCell,
      navigateCell,
      rowId,
      colId,
      tableRef,
    ],
  );

  useEffect(() => {
    if (isCellEditing && contentEditableRef.current && !readOnly) {
      contentEditableRef.current.focus();
      const range = document.createRange();
      const sel = window.getSelection();

      range.selectNodeContents(contentEditableRef.current);
      range.collapse(false);
      sel?.removeAllRanges();
      sel?.addRange(range);
    }
  }, [isCellEditing, readOnly]);

  return {
    contentEditableRef,
    localValue,
    handleChange,
    handleBlur,
    handleKeyDown,
  };
}
