import type { ILoadedEntity } from "@archetype/core";
import type { IVersionType } from "@archetype/dsl";
import { computeColumnViewFieldId, FieldValueParser } from "@archetype/dsl";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { cn, EntityStateIndicatorBackground } from "@archetype/ui";
import React from "react";

import { getConditionalFormattingClasses } from "./ConditionalFormattingUtils";

interface IEntityStatusBanner {
  className?: string;
  versionType: IVersionType;
  entity: ILoadedEntity;
}

export const EntityStatusBanner: React.FC<IEntityStatusBanner> = ({ entity, className, versionType }) => {
  const { data: entityTypeQuery, isLoading: isLoadingEntityType } = trpc.dataModel.fullyLoadedEntityType.useQuery({
    versionType,
    id: entity.entityTypeId,
  });
  const entityType = entityTypeQuery?.entityType;

  const statusFieldId =
    entityType?.statusColumn == null ? undefined : computeColumnViewFieldId(entityType.statusColumn);
  const statusValue = statusFieldId == null ? undefined : entity.fields[statusFieldId];
  const stateIdAsString = FieldValueParser.toString(statusValue) ?? "-";
  const statusColumn = entityType?.statusColumn == null ? undefined : entityType.columns[entityType.statusColumn];

  if (entityType == null || isLoadingEntityType) {
    return null;
  }

  if (entityType.statusColumn == null) {
    return null;
  }

  if (statusValue == null) {
    return null;
  }

  const conditionalFormatting = statusColumn?.conditionalFormatting;

  return (
    <div>
      <EntityStateIndicatorBackground
        backgroundColor={
          getConditionalFormattingClasses({
            cell: statusValue,
            conditionalFormatting: conditionalFormatting,
          })?.colorName
        }
        className={cn("border-border flex border-b p-2 pl-6 text-base", className)}
      >
        {stateIdAsString}
      </EntityStateIndicatorBackground>
    </div>
  );
};
