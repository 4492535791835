import * as React from "react";
import { useCallback } from "react";

import { useMemoDeepCompare } from "../../hooks/useMemoDeepCompare";
import { cn } from "../../lib/utils";
import { Card } from "./card";
import { Skeleton } from "./skeleton";

interface IDataGroup<TGroup extends string, TRow extends { id: string }> {
  id: TGroup;
  title: string;
  data: Array<TRow>;
}
export interface IBoardProps<TGroup extends string, TRow extends { id: string }> {
  className?: string;

  groupedData: Array<IDataGroup<TGroup, TRow>>;
  groupHeaderRenderer: (group: IDataGroup<TGroup, TRow>) => React.ReactNode;
  itemTitleRenderer: (row: TRow, group: Omit<IDataGroup<TGroup, TRow>, "data">) => React.ReactNode;
  itemContentRenderer?: (row: TRow, group: Omit<IDataGroup<TGroup, TRow>, "data">) => React.ReactNode | undefined;
  selectState?: {
    selectionState: Record<string, boolean>;
    onToggleSelectedRow: (row: TRow) => void;
  };
}

export function Board<TGroup extends string, TRow extends { id: string }>({
  className,
  groupedData,
  groupHeaderRenderer,
  itemTitleRenderer,
  itemContentRenderer,
  selectState,
}: IBoardProps<TGroup, TRow>): React.JSX.Element {
  const rowSelection = selectState != null ? selectState.selectionState : {};

  const handleToggleSelectedRow = useCallback(
    (row: TRow) => (): void => {
      if (selectState != null) {
        selectState.onToggleSelectedRow(row);
      }
    },
    [selectState],
  );

  return (
    <div className={cn("flex size-full gap-x-2 overflow-auto pb-0", className)}>
      {groupedData.map((group) => (
        <div key={group.id} className="flex w-[320px] shrink-0 grow-0 flex-col">
          <div className="shrink-0 px-2">{groupHeaderRenderer(group)}</div>
          <div className={cn("mt-2 shrink grow space-y-4 overflow-auto p-2")}>
            {group.data.length === 0 && <div className="bg-muted-background size-full rounded-md" />}
            {group.data.map((row) => (
              <Card
                key={row.id}
                className={cn(
                  "bg-paper rounded",
                  selectState != null && "hover:bg-paper/80 cursor-pointer hover:drop-shadow-sm",
                  rowSelection[row.id] === true ? "border-primary bg-secondary-background border" : undefined,
                  selectState != null && rowSelection[row.id] === true ? "hover:bg-secondary-background/80" : undefined,
                )}
                data-tour-id={`row-${row.id}`} // TODO this is a bit fragile
                onClick={handleToggleSelectedRow(row)}
              >
                <div className="truncate px-4 pb-2 pt-3 text-lg">{itemTitleRenderer(row, group)}</div>
                <div className="p-4 pt-0">{itemContentRenderer?.(row, group)}</div>
              </Card>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

interface ISkeletonRow {
  id: string;
  name: string;
}

export const SKELETON_GROUPED_DATA: Array<IDataGroup<string, ISkeletonRow>> = [
  {
    id: "Group Header",
    title: "Group Header",
    data: [
      { id: "1", name: "Item 1" },
      { id: "2", name: "Item 2" },
      { id: "3", name: "Item 3" },
      { id: "4", name: "Item 4" },
    ],
  },
  {
    id: "Header 1.5",
    title: "Header 1.5",
    data: [],
  },
  {
    id: "Header 1",
    title: "Header 1",
    data: [
      { id: "4", name: "Item 4" },
      { id: "5", name: "Item 5" },
      { id: "6", name: "Item 6" },
    ],
  },
  {
    id: "Header 2 —",
    title: "Header 2 —",
    data: [],
  },
  {
    id: "Header 3",
    title: "Header 3",
    data: [
      { id: "4", name: "Item 4" },
      { id: "5", name: "Item 5" },
      { id: "6", name: "Item 6" },
    ],
  },
];

interface IBoardSkeletonProps {
  className?: string;
  interactive?: boolean;
}

export const BoardSkeleton: React.FC<IBoardSkeletonProps> = ({ className, interactive }: IBoardSkeletonProps) => {
  const selectState: IBoardProps<string, ISkeletonRow>["selectState"] = useMemoDeepCompare(
    () =>
      interactive === true
        ? {
            selectionState: {},
            onToggleSelectedRow: (): void => {
              /* no-op */
            },
          }
        : undefined,
    [interactive],
  );

  const renderGroupHeader = useCallback((group: IDataGroup<string, ISkeletonRow>): React.ReactNode => {
    return <Skeleton>{group.title}</Skeleton>;
  }, []);

  const renderItemTitle = useCallback((item: ISkeletonRow): React.ReactNode => {
    return <Skeleton>{item.name}</Skeleton>;
  }, []);

  const renderItemContent = useCallback((item: ISkeletonRow): React.ReactNode => {
    return (
      <dl className="gap-y-1">
        <div key={item.id} className="grid grid-cols-1 gap-y-1">
          <Skeleton className="text-sm leading-6">Inner header</Skeleton>
          <Skeleton className="max-h-20 truncate whitespace-normal break-words text-sm leading-6" />
        </div>
      </dl>
    );
  }, []);

  return (
    <Board<string, ISkeletonRow>
      className={className}
      groupHeaderRenderer={renderGroupHeader}
      groupedData={SKELETON_GROUPED_DATA}
      itemContentRenderer={renderItemContent}
      itemTitleRenderer={renderItemTitle}
      selectState={selectState}
    />
  );
};
