import { ApplicationGroupId, ApplicationId, UserId } from "@archetype/ids";
import { z } from "zod";

export const Application = z.object({
  id: ApplicationId,
  name: z.string(),
  description: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  groupId: ApplicationGroupId,
  userId: UserId,
});

export type IApplication = z.infer<typeof Application>;
