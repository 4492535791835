import { entityTypeCoreFromLoaded } from "@archetype/core";
import type { IDiff } from "@archetype/dsl";
import { FAKE_DATA_VERSION_TYPE, getEntityTypeDiff, REAL_DATA_VERSION_TYPE } from "@archetype/dsl";
import type { IColumnTypeId, IEntityTypeId } from "@archetype/ids";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { skipToken } from "@tanstack/react-query";
import { isEqual, size, some } from "lodash";
import { useCallback } from "react";

export const useEntityTypeDiff = ({
  entityTypeId,
}: {
  /**
   * noop if undefined, but makes props more flexible
   */
  entityTypeId: IEntityTypeId | undefined;
}): {
  columnDiffs: Record<IColumnTypeId, IDiff>;
  hasChanges: boolean;
  hasColumnChanges: boolean;
  isLoading: boolean;
  refetchAll: () => void;
} => {
  const {
    data: prodEntityTypeQuery,
    isLoading: isProdEntityTypeLoading,
    refetch: refetchProdEntityType,
  } = trpc.dataModel.fullyLoadedEntityType.useQuery(
    entityTypeId == null
      ? skipToken
      : {
          id: entityTypeId,
          versionType: REAL_DATA_VERSION_TYPE,
        },
  );

  const {
    data: devEntityTypeQuery,
    isLoading: isDevEntityTypeLoading,
    refetch: refetchDevEntityType,
  } = trpc.dataModel.fullyLoadedEntityType.useQuery(
    entityTypeId == null
      ? skipToken
      : {
          id: entityTypeId,
          versionType: FAKE_DATA_VERSION_TYPE,
        },
  );

  const {
    data: prodActionsQuery,
    isLoading: isProdActionsLoading,
    refetch: refetchProdActions,
  } = trpc.dataModel.getFullyLoadedActionsByEntityType.useQuery(
    entityTypeId == null
      ? skipToken
      : {
          entityTypeId,
          versionType: REAL_DATA_VERSION_TYPE,
        },
  );

  const {
    data: devActionsQuery,
    isLoading: isDevActionsLoading,
    refetch: refetchDevActions,
  } = trpc.dataModel.getFullyLoadedActionsByEntityType.useQuery(
    entityTypeId == null
      ? skipToken
      : {
          entityTypeId,
          versionType: FAKE_DATA_VERSION_TYPE,
        },
  );

  const {
    data: prodRelationsQuery,
    isLoading: isProdRelationsLoading,
    refetch: refetchProdRelations,
  } = trpc.dataModel.getRelationsByEntityTypeId.useQuery(
    entityTypeId == null
      ? skipToken
      : {
          entityTypeId,
          versionType: REAL_DATA_VERSION_TYPE,
        },
  );

  const {
    data: devRelationsQuery,
    isLoading: isDevRelationsLoading,
    refetch: refetchDevRelations,
  } = trpc.dataModel.getRelationsByEntityTypeId.useQuery(
    entityTypeId == null
      ? skipToken
      : {
          entityTypeId,
          versionType: FAKE_DATA_VERSION_TYPE,
        },
  );

  const {
    data: prodStateMachineQuery,
    isLoading: isProdStateMachineLoading,
    refetch: refetchProdStateMachine,
  } = trpc.processStateMachine.getStateMachineMetadataForApplicationGroup.useQuery(
    prodEntityTypeQuery?.entityType.targetEntityTypeApplicationGroupId == null
      ? skipToken
      : {
          applicationGroupId: prodEntityTypeQuery.entityType.targetEntityTypeApplicationGroupId,
          versionType: REAL_DATA_VERSION_TYPE,
        },
  );

  const {
    data: devStateMachineQuery,
    isLoading: isDevStateMachineLoading,
    refetch: refetchDevStateMachine,
  } = trpc.processStateMachine.getStateMachineMetadataForApplicationGroup.useQuery(
    devEntityTypeQuery?.entityType.targetEntityTypeApplicationGroupId == null
      ? skipToken
      : {
          applicationGroupId: devEntityTypeQuery.entityType.targetEntityTypeApplicationGroupId,
          versionType: FAKE_DATA_VERSION_TYPE,
        },
  );

  const isLoading =
    isProdRelationsLoading ||
    isDevRelationsLoading ||
    isDevEntityTypeLoading ||
    isProdEntityTypeLoading ||
    isProdStateMachineLoading ||
    isDevStateMachineLoading ||
    isProdActionsLoading ||
    isDevActionsLoading;

  const refetchAll = useCallback((): void => {
    void refetchProdEntityType();
    void refetchDevEntityType();
    void refetchProdRelations();
    void refetchDevRelations();
    void refetchProdStateMachine();
    void refetchDevStateMachine();
    void refetchProdActions();
    void refetchDevActions();
  }, [
    refetchProdEntityType,
    refetchDevEntityType,
    refetchProdRelations,
    refetchDevRelations,
    refetchProdStateMachine,
    refetchDevStateMachine,
    refetchProdActions,
    refetchDevActions,
  ]);

  if (entityTypeId == null) {
    return {
      columnDiffs: {},
      hasChanges: false,
      hasColumnChanges: false,
      isLoading,
      refetchAll,
    };
  }

  const columnDiffs =
    devEntityTypeQuery == null || prodEntityTypeQuery == null
      ? {}
      : getEntityTypeDiff(
          entityTypeCoreFromLoaded(prodEntityTypeQuery.entityType),
          entityTypeCoreFromLoaded(devEntityTypeQuery.entityType),
        );

  const hasActionsChanges =
    devActionsQuery != null &&
    (prodActionsQuery == null || !isEqual(prodActionsQuery.actions, devActionsQuery.actions));

  const hasRelationsChanges =
    devRelationsQuery != null &&
    (prodRelationsQuery == null || !isEqual(prodRelationsQuery.relations, devRelationsQuery.relations));

  const hasColumnChanges = some(columnDiffs, (d) => d.type === "changed");

  const hasStateMachineChanges =
    devStateMachineQuery != null &&
    (prodStateMachineQuery == null ||
      !isEqual(
        prodStateMachineQuery.stateMachineMetadata.stateMachine,
        devStateMachineQuery.stateMachineMetadata.stateMachine,
      ));

  const hasEntityTypeChanges =
    devEntityTypeQuery != null &&
    (prodEntityTypeQuery == null || !isEqual(prodEntityTypeQuery.entityType, devEntityTypeQuery.entityType));

  return {
    columnDiffs,
    hasChanges:
      hasRelationsChanges ||
      hasStateMachineChanges ||
      hasEntityTypeChanges ||
      hasActionsChanges ||
      size(columnDiffs) > 0,
    hasColumnChanges,
    isLoading,
    refetchAll,
  };
};
