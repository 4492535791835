import { cn } from "@archetype/ui";
import { FormattedUrl } from "@archetype/ui";
import React from "react";
import ContentEditable from "react-contenteditable";

import { useCellContentEditable } from "../../hooks/useCellContentEditable";
import { useCellEditOnEnter } from "../../hooks/useCellEditOnEnter";
import { ExpandableCellWrapper } from "../ExpandableCellWrapper";
import type { IDataTableCommonCellProps, IDataTableLinkCell } from "./api";

export function LinkCell<TRowId extends string, TColumnId extends string>({
  cell,
  isCellSelected,
  isCellEditing,
  isRowSelected,
}: IDataTableCommonCellProps<TRowId, TColumnId, IDataTableLinkCell<TRowId, TColumnId>> & {
  children?: React.ReactNode;
}): React.ReactNode {
  const { value, onChange, colId, rowId, readOnly } = cell;

  const { contentEditableRef, localValue, handleChange, handleBlur, handleKeyDown } = useCellContentEditable<string>({
    initialValue: value?.href ?? "",
    rowId,
    colId,
    onChange: (v: string) => onChange?.({ href: v, text: v }),
    readOnly,
    isCellEditing,
  });

  useCellEditOnEnter({
    rowId,
    colId,
    isCellEditing,
    isCellSelected,
  });

  return (
    <ExpandableCellWrapper
      cell={cell}
      isCellEditing={isCellEditing}
      isCellSelected={isCellSelected}
      isRowSelected={isRowSelected}
    >
      {isCellEditing || value?.href == null ? (
        <ContentEditable
          className={cn("size-full text-base outline-none", (readOnly || !isCellEditing) && "truncate")}
          disabled={readOnly || !isCellEditing}
          html={localValue}
          innerRef={contentEditableRef}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <a className="size-full text-base" href={value.href} target="_blank">
          <FormattedUrl url={value.href} />
        </a>
      )}
    </ExpandableCellWrapper>
  );
}
