import type { ITypedLayoutDefinitionDeclaration } from "@archetype/dsl";
import { LayoutConfigurationId, SearchableListWithFilterLayoutId, SlotId } from "@archetype/ids";

export const SearchableListWithFilterLayoutIds = {
  id: SearchableListWithFilterLayoutId,
  layoutConfigurations: {
    mainEntityType: "entityType",
  },
  moduleParameters: {
    inputs: ["multiEntityQueryDataInput"],
    outputs: ["userSelectedSingleEntity", "userSelectedLinkedEntity", "actionSelected"],
  },
  slots: {
    searchActions: {
      optional: false,
      inputs: ["multiEntityQueryDataInput"],
      outputs: ["filteredMultiEntityQuery"],
    },
    filterActions: {
      optional: false,
      inputs: ["multiEntityQueryDataInput"],
      outputs: ["filteredMultiEntityQuery"],
    },
    main: {
      optional: false,
      inputs: ["multiEntityQueryDataInput"],
      outputs: ["userSelectedSingleEntity", "userSelectedLinkedEntity", "actionSelected"],
    },
    detailView: {
      optional: true,
      inputs: ["singleEntityDataInput"],
      outputs: [],
    },
    action1: {
      optional: true,
      inputs: ["singleEntityDataInput"],
      outputs: ["actionSelected"],
    },
    action2: {
      optional: true,
      inputs: ["singleEntityDataInput"],
      outputs: [],
    },
    action3: {
      optional: true,
      inputs: ["singleEntityDataInput"],
      outputs: [],
    },
    action4: {
      optional: true,
      inputs: ["singleEntityDataInput"],
      outputs: [],
    },
    action5: {
      optional: true,
      inputs: ["singleEntityDataInput"],
      outputs: [],
    },
    action6: {
      optional: true,
      inputs: ["singleEntityDataInput"],
      outputs: [],
    },
    action7: {
      optional: true,
      inputs: ["singleEntityDataInput"],
      outputs: [],
    },
  },
} as const;

export const SearchableListWithFilterLayout: ITypedLayoutDefinitionDeclaration<
  typeof SearchableListWithFilterLayoutIds
> = {
  constIds: SearchableListWithFilterLayoutIds,
  displayMetadata: {
    name: "Searchable list with metrics",
    description: "A list with a search input and metrics",
  },
  tags: ["list", "search", "filter"],
  layoutConfiguration: {
    mainEntityType: {
      type: "entityType",
    },
  },
  slots: {
    searchActions: {
      displayMetadata: {
        name: "Search input",
        description: "A search input",
      },
      constraints: {
        semanticSize: "actions",
        inputs: {
          multiEntityQueryDataInput: {
            ref: {
              type: "config",
              layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
            },
          },
        },
        outputs: {
          filteredMultiEntityQuery: {
            type: "config",
            layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
          },
        },
      },
    },
    filterActions: {
      displayMetadata: {
        name: "Filter acitons bar",
        description: "Filter acitons bar", // TODO this should be multiple, or should the metric card have a wrapper that is multiple?
      },
      constraints: {
        semanticSize: "actions",
        inputs: {
          multiEntityQueryDataInput: {
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("searchActions"),
              outputId: "filteredMultiEntityQuery",
            },
          },
        },
        outputs: {
          filteredMultiEntityQuery: {
            type: "config",
            layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
          },
        },
      },
    },
    main: {
      displayMetadata: {
        name: "Table",
        description: "A table",
      },

      constraints: {
        semanticSize: "main",
        inputs: {
          multiEntityQueryDataInput: {
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("searchActions"),
              outputId: "filteredMultiEntityQuery",
            },
          },
        },
        outputs: {
          userSelectedSingleEntity: {
            type: "config",
            layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
            nullable: true,
          },
          userSelectedLinkedEntity: {
            type: "config",
            layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
            nullable: true,
          },
          actionSelected: {
            type: "actionSelected",
          },
        },
      },
    },
    detailView: {
      displayMetadata: {
        name: "Selection detail",
        description: "Detail",
      },
      constraints: {
        semanticSize: "main",
        inputs: {
          singleEntityDataInput: {
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("main"),
              outputId: "userSelectedSingleEntity",
            },
            nullable: true,
          },
        },
        outputs: {},
      },
    },
    action1: {
      displayMetadata: {
        name: "Action",
        description: "An action",
      },
      constraints: {
        semanticSize: "actions",
        inputs: {
          singleEntityDataInput: {
            nullable: true,
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("main"),
              outputId: "userSelectedSingleEntity",
            },
          },
        },
        outputs: {
          actionSelected: {
            type: "actionSelected",
          },
        },
      },
    },
    action2: {
      displayMetadata: {
        name: "Action",
        description: "An action",
      },
      constraints: {
        semanticSize: "actions",
        inputs: {
          singleEntityDataInput: {
            nullable: true,
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("main"),
              outputId: "userSelectedSingleEntity",
            },
          },
        },
        outputs: {},
      },
    },
    action3: {
      displayMetadata: {
        name: "Action",
        description: "An action",
      },
      constraints: {
        semanticSize: "actions",
        inputs: {
          singleEntityDataInput: {
            nullable: true,
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("main"),
              outputId: "userSelectedSingleEntity",
            },
          },
        },
        outputs: {},
      },
    },
    action4: {
      displayMetadata: {
        name: "Action",
        description: "An action",
      },
      constraints: {
        semanticSize: "actions",
        inputs: {
          singleEntityDataInput: {
            nullable: true,
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("main"),
              outputId: "userSelectedSingleEntity",
            },
          },
        },
        outputs: {},
      },
    },
    action5: {
      displayMetadata: {
        name: "Action",
        description: "An action",
      },
      constraints: {
        semanticSize: "actions",
        inputs: {
          singleEntityDataInput: {
            nullable: true,
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("main"),
              outputId: "userSelectedSingleEntity",
            },
          },
        },
        outputs: {},
      },
    },
    action6: {
      displayMetadata: {
        name: "Action",
        description: "An action",
      },
      constraints: {
        semanticSize: "actions",
        inputs: {
          singleEntityDataInput: {
            nullable: true,
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("main"),
              outputId: "userSelectedSingleEntity",
            },
          },
        },
        outputs: {},
      },
    },
    action7: {
      displayMetadata: {
        name: "Action",
        description: "An action",
      },
      constraints: {
        semanticSize: "actions",
        inputs: {
          singleEntityDataInput: {
            nullable: true,
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("main"),
              outputId: "userSelectedSingleEntity",
            },
          },
        },
        outputs: {},
      },
    },
  },
  moduleParameterInputs: {
    multiEntityQueryDataInput: {
      type: "multiEntityQuery",
      entityType: LayoutConfigurationId.parse("mainEntityType"),
      displayMetadata: {
        name: "Main data shown",
        description: undefined,
      },
    },
  },
  moduleParameterOutputs: {
    userSelectedSingleEntity: {
      type: "singleEntity",
      entityType: LayoutConfigurationId.parse("mainEntityType"),
      displayMetadata: {
        name: "User selection of a list item",
        description: undefined,
      },
    },
    userSelectedLinkedEntity: {
      type: "singleEntity",
      entityType: LayoutConfigurationId.parse("mainEntityType"),
      displayMetadata: {
        name: "User selection of a linked item",
        description: undefined,
      },
    },
    actionSelected: {
      type: "actionSelected",
      displayMetadata: {
        name: "Action selected",
        description: undefined,
      },
    },
  },
  variants: [],
};
