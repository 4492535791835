import { EmailComposer } from "@archetype/ui";
import React from "react";

import type { IEmailAsComposerProps } from "./useEmailAsComposer";
import { useEmailAsComposer } from "./useEmailAsComposer";

export const EmailComponent: React.FC<
  {
    className?: string;
  } & IEmailAsComposerProps
> = ({ className, ...props }) => {
  const emailComposerProps = useEmailAsComposer(props);

  return <EmailComposer {...emailComposerProps} className={className} fromOptions={[]} toOptions={[]} />;
};
