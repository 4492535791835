import type {
  IActionCore,
  IEntityTypeCore,
  IFeatureContext,
  ILLMFeatureContext,
  IRelationCore,
  IStateMachine,
} from "@archetype/dsl";
import { DEFAULT_CREATE_TRANSITION_ID } from "@archetype/dsl";
import type { IActionId, IApplicationGroupId, IEntityTypeId, IRelationId } from "@archetype/ids";
import assertNever from "assert-never";
import { values } from "lodash";

export function convertFeatureContextToLLMFeatureContext({
  featureContext,
  entityTypesById,
  relationsById,
  actionsById,
  stateMachinesByApplicationGroupId,
}: {
  featureContext: IFeatureContext;
  entityTypesById: Record<IEntityTypeId, IEntityTypeCore>;
  relationsById: Record<IRelationId, IRelationCore>;
  actionsById: Record<IActionId, IActionCore>;
  stateMachinesByApplicationGroupId: Record<IApplicationGroupId, IStateMachine>;
}): ILLMFeatureContext {
  switch (featureContext.type) {
    case "workspace": {
      return {
        contextType: "workspace",
      };
    }
    case "workflow": {
      const entityTypeOfWorkflow = values(entityTypesById).find(
        (et) => et.targetEntityTypeApplicationGroupId === featureContext.workflowId,
      );

      if (entityTypeOfWorkflow == null) {
        throw new Error(`Entity type ${featureContext.workflowId} not found`);
      }

      return {
        contextType: "entityType",
        entityTypeName: entityTypeOfWorkflow.displayMetadata.name,
      };
    }
    case "entity":
    case "entityType":
    case "entityTypePermissions": {
      const entityType = entityTypesById[featureContext.entityTypeId];

      if (entityType == null) {
        throw new Error(`Entity type ${featureContext.entityTypeId} not found`);
      }

      return {
        contextType: "entityType",
        entityTypeName: entityType.displayMetadata.name,
      };
    }
    case "state": {
      const entityTypeOfState = entityTypesById[featureContext.entityTypeId];

      if (entityTypeOfState == null) {
        throw new Error(`Entity type ${featureContext.entityTypeId} not found`);
      }

      if (entityTypeOfState.targetEntityTypeApplicationGroupId == null) {
        throw new Error(`Entity type ${featureContext.entityTypeId} is not a core entity type`);
      }

      const stateMachineOfState =
        stateMachinesByApplicationGroupId[entityTypeOfState.targetEntityTypeApplicationGroupId];

      if (stateMachineOfState == null) {
        throw new Error(`State machine ${entityTypeOfState.targetEntityTypeApplicationGroupId} not found`);
      }

      const state = stateMachineOfState.states[featureContext.stateId];

      if (state == null) {
        throw new Error(`State ${featureContext.stateId} not found`);
      }

      return {
        contextType: "state",
        entityTypeName: entityTypeOfState.displayMetadata.name,
        stateName: state.label,
      };
    }
    case "transition": {
      const entityTypeOfTransition = entityTypesById[featureContext.entityTypeId];

      if (entityTypeOfTransition == null) {
        throw new Error(`Entity type ${featureContext.entityTypeId} not found`);
      }

      if (entityTypeOfTransition.targetEntityTypeApplicationGroupId == null) {
        throw new Error(`Entity type ${featureContext.entityTypeId} is not a core entity type`);
      }

      const stateMachineOfTransition =
        stateMachinesByApplicationGroupId[entityTypeOfTransition.targetEntityTypeApplicationGroupId];

      if (stateMachineOfTransition == null) {
        throw new Error(`State machine ${entityTypeOfTransition.targetEntityTypeApplicationGroupId} not found`);
      }

      const transitionActionId =
        featureContext.transitionId === DEFAULT_CREATE_TRANSITION_ID
          ? stateMachineOfTransition.initialState.actionId
          : stateMachineOfTransition.stateTransitions.find((t) => t.id === featureContext.transitionId)?.actionId;

      if (transitionActionId == null) {
        throw new Error(`Transition ${featureContext.transitionId} not found`);
      }

      const transitionAction = actionsById[transitionActionId];

      if (transitionAction == null) {
        throw new Error(`Action ${transitionActionId} not found`);
      }

      return {
        contextType: "action",
        entityTypeName: entityTypeOfTransition.displayMetadata.name,
        actionName: transitionAction.displayMetadata.name,
      };
    }
    case "action": {
      const entityTypeOfAction = entityTypesById[featureContext.entityTypeId];

      if (entityTypeOfAction == null) {
        throw new Error(`Entity type ${featureContext.entityTypeId} not found`);
      }

      const action = actionsById[featureContext.actionId];

      if (action == null) {
        throw new Error(`Action ${featureContext.actionId} not found`);
      }

      return {
        contextType: "action",
        entityTypeName: entityTypeOfAction.displayMetadata.name,
        actionName: action.displayMetadata.name,
      };
    }
    case "relation": {
      const entityTypeOfRelation = entityTypesById[featureContext.entityTypeId];

      if (entityTypeOfRelation == null) {
        throw new Error(`Entity type ${featureContext.entityTypeId} not found`);
      }

      const relation = relationsById[featureContext.relationId];

      if (relation == null) {
        throw new Error(`Relation ${featureContext.relationId} not found`);
      }

      return {
        contextType: "relation",
        entityTypeName: entityTypeOfRelation.displayMetadata.name,
        relationName:
          relation.entityTypeIdA === entityTypeOfRelation.id
            ? relation.displayMetadataFromAToB.name
            : relation.displayMetadataFromBToA.name,
      };
    }
    case "column": {
      const entityTypeOfColumn = entityTypesById[featureContext.entityTypeId];

      if (entityTypeOfColumn == null) {
        throw new Error(`Entity type ${featureContext.entityTypeId} not found`);
      }

      const column = entityTypeOfColumn.columns.find((c) => c.id === featureContext.columnId);

      if (column == null) {
        throw new Error(`Column ${featureContext.columnId} not found`);
      }

      return {
        contextType: "column",
        entityTypeName: entityTypeOfColumn.displayMetadata.name,
        columnName: column.displayMetadata.name,
      };
    }
    default: {
      assertNever(featureContext);
    }
  }
}
