import type { IModuleInstance, IModuleInstanceMetadata, INonIdVersionIdentifiableFields } from "@archetype/dsl";
import { first, size, sortBy } from "lodash";
import type { Result } from "neverthrow";
import { err, ok } from "neverthrow";

import type { IAnnotatedModuleConfig } from "./getCompatibleModuleConfigs";
import { getCompatibleModuleConfigs } from "./getCompatibleModuleConfigs";

export type INewModuleInstance = Omit<IModuleInstance, INonIdVersionIdentifiableFields | "id">;

export const buildModuleInstanceFromRequirements = ({
  metadata,
}: {
  metadata: IModuleInstanceMetadata;
}): Result<INewModuleInstance, "No good layout found"> => {
  const moduleConfigs = getCompatibleModuleConfigs(metadata.featureTransaction);

  const matchingModuleConfigResult = findBestLayout(moduleConfigs);

  if (matchingModuleConfigResult.isErr()) {
    return err("No good layout found");
  }

  const matchingModuleConfig = matchingModuleConfigResult.value;

  const moduleInstance: INewModuleInstance = {
    layoutId: matchingModuleConfig.layoutId,
    slots: matchingModuleConfig.slots,
    layoutConfiguration: matchingModuleConfig.layoutConfiguration,
    inputRuntimeParameterConfig: {}, // TODO (julien): generate from navigation features when added
  };

  return ok(moduleInstance);
};

function findBestLayout(
  moduleConfigs: IAnnotatedModuleConfig[],
): Result<IAnnotatedModuleConfig, "No good layout found"> {
  const sortedModuleConfigs = sortBy(
    moduleConfigs,
    (moduleConfig) => -moduleConfig.requiredSlotsMatchPercentage,
    (moduleConfig) => -size(moduleConfig.slots),
  );

  const bestModuleConfig = first(sortedModuleConfigs);

  if (bestModuleConfig == null || bestModuleConfig.requiredSlotsMatchPercentage === 0) {
    return err("No good layout found");
  }

  return ok(bestModuleConfig);
}
