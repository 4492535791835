import { copy, del } from "@vercel/blob";
import { generateClientTokenFromReadWriteToken, put } from "@vercel/blob/client";

import type {
  IClientUploadFileResponse,
  IClientUploadTokenResponse,
  ICloudStorage,
  ICopyFileResponse,
  IDeleteFilesResponse,
  IFileUploadOptions,
  IMoveFileResponse,
  IOnUploadCompleted,
} from "../../index";
import { createFileLogger } from "../logger";

const logger = createFileLogger("vercel");

export class VercelCloudStorage implements ICloudStorage {
  async getClientUploadToken({
    pathname,
    maximumSizeInBytes,
    allowedContentTypes,
    onUploadCompleted,
    addRandomSuffix,
  }: {
    pathname: string;
    maximumSizeInBytes: number;
    allowedContentTypes: string[];
    onUploadCompleted?: IOnUploadCompleted;
    addRandomSuffix?: boolean;
  }): Promise<IClientUploadTokenResponse> {
    const token = process.env.VERCEL_BLOB_READ_WRITE_TOKEN ?? "";

    if (token.trim() === "") {
      throw new Error("VERCEL_BLOB_READ_WRITE_TOKEN is not defined");
    }

    const clientToken = await generateClientTokenFromReadWriteToken({
      token,
      pathname,
      maximumSizeInBytes,
      allowedContentTypes,
      onUploadCompleted,
      addRandomSuffix,
    });

    return { token: clientToken };
  }

  async clientUploadFile(
    filePath: string,
    file: File,
    options: IFileUploadOptions,
  ): Promise<IClientUploadFileResponse> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- last type condition
    if (options.access != null && options.access !== "public") {
      throw new Error("VercelCloudStorage: access must be public");
    }
    const vercelOptions = {
      ...options,
      access: "public" as const,
    };

    const { url } = await put(filePath, file, vercelOptions);

    return { fileUrl: url };
  }

  async deleteFiles(fileUrls: string[]): Promise<IDeleteFilesResponse> {
    const token = process.env.VERCEL_BLOB_READ_WRITE_TOKEN;

    if (token == null || token.trim() === "") {
      throw new Error("VERCEL_BLOB_READ_WRITE_TOKEN is not defined");
    }

    const results = await Promise.all(
      fileUrls.map(async (fileUrl) => {
        try {
          await del(fileUrl, { token });

          return { [fileUrl]: true };
        } catch (e) {
          logger.error({ fileUrl, error: e }, "Error deleting file");

          return { [fileUrl]: false };
        }
      }),
    );

    return Object.assign({}, ...results) as IDeleteFilesResponse;
  }

  async copyFile(sourceUrl: string, destinationUrl: string): Promise<ICopyFileResponse> {
    const token = process.env.VERCEL_BLOB_READ_WRITE_TOKEN;

    if (token == null || token.trim() === "") {
      throw new Error("VERCEL_BLOB_READ_WRITE_TOKEN is not defined");
    }

    const { url: newUrl } = await copy(sourceUrl, destinationUrl, {
      access: "public",
      token,
    });

    return { success: true, newUrl };
  }

  async moveFile(sourceUrl: string, destinationUrl: string): Promise<IMoveFileResponse> {
    const copyResult = await this.copyFile(sourceUrl, destinationUrl);

    if (copyResult.success) {
      await this.deleteFiles([sourceUrl]);
    }

    return copyResult;
  }
}
