import type {
  IExecuteActionQuery,
  IExternalUserCreateProps,
  ILoadedAction,
  ILoadedEntity,
  ILoadedEntityType,
} from "@archetype/core";
import { parseFieldValuesAndValidateColumns } from "@archetype/core";
import {
  computeColumnViewFieldId,
  FieldValueParser,
  type IActionCurrentUserInfo,
  type IValidationGroup,
} from "@archetype/dsl";
import type { IEntityTypeVersionId } from "@archetype/ids";
import assertNever from "assert-never";

import type { ILoadedExecuteActionQuery, IMakeEntityValueResult, IValueForCache } from "./types";

export const makeEntityToSet = ({
  actionInfo,
  entityType,
  previousLoadedEntity,
  loadedExecuteActionQuery,
  validationGroups,
  currentUserInfo,
  maybeExternalUserCreateProps,
}: {
  actionInfo: ILoadedAction;
  entityType: ILoadedEntityType | undefined;
  previousLoadedEntity: ILoadedEntity | undefined;
  loadedExecuteActionQuery: ILoadedExecuteActionQuery;
  validationGroups: IValidationGroup[];
  currentUserInfo: IActionCurrentUserInfo | undefined;
  maybeExternalUserCreateProps: IExternalUserCreateProps | undefined;
}): IMakeEntityValueResult | undefined => {
  if (currentUserInfo == null) {
    // Skipping as we cannot execute the action without the user info loaded
    return undefined;
  }

  switch (actionInfo.actionDefinition.actionType) {
    case "add": {
      if (entityType == null || loadedExecuteActionQuery.entityId == null) {
        return undefined;
      }

      // TODO nested forms - could set the cache with nested actions, but not for now
      const { success, errors, newEntityFieldValues, editedFieldValues, nestedActionErrors } =
        parseFieldValuesAndValidateColumns({
          currentUserInfo,
          loadedAction: actionInfo,
          fieldValues: loadedExecuteActionQuery.fieldValues ?? {},
          preexistingEntity: previousLoadedEntity,
          validationGroups,
          loadedNestedCreateFormValues: loadedExecuteActionQuery.loadedNestedCreateFormValues,
          maybeExternalUserCreateProps,
          maybeExternalUserFieldValues: loadedExecuteActionQuery.externalUserFieldValues ?? undefined,
        });

      if (!success) {
        return {
          actionExecutionResult: {
            success: false,
            errors,
            nestedActionErrors,
            successReplacementNestedEntityIds: {}, // nothing executed yet
          },
          editedFieldValues: {},
        };
      }

      const displayNameColumnId = entityType.displayNameColumn;
      const displayName =
        FieldValueParser.toString(newEntityFieldValues[computeColumnViewFieldId(displayNameColumnId)]) ?? "";

      return {
        isNewForCache: true,
        actionExecutionResult: {
          success: true,
          entitySnapshotAfter: newEntityFieldValues,
          entitySnapshotBefore: null,
          entityTypeId: actionInfo.entityTypeId,
          entityId: loadedExecuteActionQuery.entityId,
          entity: {
            entityTypeVersionId: "" as IEntityTypeVersionId,
            primaryKey: {
              value: loadedExecuteActionQuery.entityId,
              type: "string",
            },
            entityId: loadedExecuteActionQuery.entityId,
            entityTypeId: actionInfo.entityTypeId,
            displayName,
            fields: newEntityFieldValues,
            fieldsComputationStatuses: {},
          },
        },
        editedFieldValues,
      };
    }
    case "modify": {
      if (previousLoadedEntity == null) {
        return undefined;
      }

      const { success, errors, newEntityFieldValues, editedFieldValues, nestedActionErrors } =
        parseFieldValuesAndValidateColumns({
          currentUserInfo,
          loadedAction: actionInfo,
          fieldValues: loadedExecuteActionQuery.fieldValues ?? {},
          preexistingEntity: previousLoadedEntity,
          validationGroups,
          loadedNestedCreateFormValues: loadedExecuteActionQuery.loadedNestedCreateFormValues,
          maybeExternalUserCreateProps,
          maybeExternalUserFieldValues: loadedExecuteActionQuery.externalUserFieldValues ?? undefined,
        });

      if (!success) {
        return {
          actionExecutionResult: {
            success: false,
            errors,
            nestedActionErrors,
            successReplacementNestedEntityIds: {}, // nothing executed yet
          },
          editedFieldValues: {},
        };
      }

      return {
        isNewForCache: false,
        actionExecutionResult: {
          success: true,
          entitySnapshotAfter: newEntityFieldValues,
          entitySnapshotBefore: previousLoadedEntity.fields,
          entityTypeId: previousLoadedEntity.entityTypeId,
          entityId: previousLoadedEntity.entityId,
          entity: {
            ...previousLoadedEntity,
            fields: newEntityFieldValues,
          },
        },
        editedFieldValues,
      };
    }
    case "delete": {
      if (previousLoadedEntity == null) {
        return undefined;
      }

      return {
        isNewForCache: false,
        actionExecutionResult: {
          success: true,
          entitySnapshotAfter: null,
          entitySnapshotBefore: previousLoadedEntity.fields,
          entityTypeId: previousLoadedEntity.entityTypeId,
          entityId: previousLoadedEntity.entityId,
          entity: null,
        },
        editedFieldValues: {},
      };
    }
    default: {
      assertNever(actionInfo.actionDefinition.actionType);
    }
  }
};

export const makeEntityToRollback = ({
  actionInfo,
  executeActionQuery,
  previousLoadedEntity,
}: {
  actionInfo: ILoadedAction;
  executeActionQuery: IExecuteActionQuery;
  previousLoadedEntity: ILoadedEntity | undefined;
}): IValueForCache | undefined => {
  if (previousLoadedEntity == null) {
    return undefined;
  }

  switch (actionInfo.actionDefinition.actionType) {
    case "add": {
      if (executeActionQuery.entityId == null) {
        return undefined;
      }

      // Delete the entity from the cache if it was a create action
      return {
        isNew: false,
        entityId: executeActionQuery.entityId,
        entityTypeId: actionInfo.entityTypeId,
        entity: undefined,
      };
    }
    case "modify": {
      return {
        entityId: previousLoadedEntity.entityId,
        entityTypeId: previousLoadedEntity.entityTypeId,
        entity: previousLoadedEntity,
      };
    }
    case "delete": {
      return {
        isNew: true,
        entityId: previousLoadedEntity.entityId,
        entityTypeId: previousLoadedEntity.entityTypeId,
        entity: previousLoadedEntity,
      };
    }
    default: {
      assertNever(actionInfo.actionDefinition.actionType);
    }
  }
};
