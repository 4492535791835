import { ActionId, ColumnId, EntityTypeId, EntityTypeVersionId, ValidationId } from "@archetype/ids";
import { z } from "zod";

import { ComparisonRule } from "./ComparisonRule";

/**
 * At the end, I want it to be something like this, (with composites):
 * Scenario A:
 * if type == sick:
 *   if days > 30:
 *     you must specify doctor's note
 *   else:
 *     you must specify doctor's note

 * Translates into:
 * definitions:
 *   - filters: AND([sick, days >30]), validations: AND([doctors note != null])
 *   - filters: AND([sick, NOT(days > 30)]), validations: AND([reason != null])

 * Scenario B:
 * if type == sick:
 *   you must specify doctor's note
 *   and if days > 5:
 *     you must specify doctor's note

 * Translates into:
 * definitions:
 *   - filters: AND([sick, days > 5]), validations: AND([doctors note != null])
 *   - filters: AND([sick]), validations: AND([reason != null])

 * Scenario C:
 * if type == sick:
 *   days > 5 OR there's doctor's not
 *
 * Translates into:
 * definitions:
 *   - filters: AND([sick]), validations: OR([days < 5, doctor's not != null])
 **/

export const Validation = z.object({
  id: ValidationId,
  displayMetadata: z.object({ description: z.string().optional(), errorMessage: z.string().optional() }).optional(), // in order to show indicative error message? might also be constructed from the filters and the error itself (specific validation that has failed)
  filters: z.array(ComparisonRule), // we should only check the validations if the object matches the filters
  checks: z.array(ComparisonRule),
  // both teh filters and the validations should prob enable composites later on
});
export type IValidation = z.infer<typeof Validation>;

export const ColumnValidationIndex = z.object({ type: z.literal("column"), id: ColumnId });
export type IColumnValidationIndex = z.infer<typeof ColumnValidationIndex>;

export const ActionValidationIndex = z.object({ type: z.literal("action"), id: ActionId });
export type IActionValidationIndex = z.infer<typeof ActionValidationIndex>;

// this should prob be indexed by entity type, maybe also by action id
export const ValidationGroup = z.object({
  entityTypeId: EntityTypeId,
  entityTypeVersionId: EntityTypeVersionId.optional().nullable(), // todo: remove optional after migration
  index: z.discriminatedUnion("type", [ColumnValidationIndex, ActionValidationIndex]),
  validations: z.array(Validation).describe("The actual validation definitions"),
});
export type IValidationGroup = z.infer<typeof ValidationGroup>;
