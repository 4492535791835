import Image from "next/image";
import React from "react";

import { cn } from "../../../lib/utils";
import { Spinner } from "../../atoms/spinner";
import sparklesGif from "./animated-sparkles.gif";
import spinnerGif from "./spinning-triangle.gif";

export interface ILoader {
  text?: string | null;
  className?: string;
  variant?: "triangle" | "pyramid" | "sparkles";
  size?: "xs" | "sm";
}

const getLoaderIcon = (variant: ILoader["variant"], size: ILoader["size"]): React.JSX.Element => {
  const iconSize = size === "xs" ? 16 : 24;

  switch (variant) {
    case "pyramid": {
      return <Image alt="Loading" height={iconSize} src={spinnerGif} unoptimized={true} width={iconSize} />;
    }
    case "sparkles": {
      return <Image alt="Loading" height={iconSize} src={sparklesGif} unoptimized={true} width={iconSize} />;
    }
    case "triangle":
    case undefined: {
      return <Spinner />;
    }
  }
};

export const Loader: React.FC<ILoader> = ({ className, text = "Loading...", variant = "triangle", size = "sm" }) => (
  <div
    className={cn("text-muted-foreground flex items-center text-sm", size === "xs" ? "gap-x-1" : "gap-x-2", className)}
  >
    {getLoaderIcon(variant, size)}
    {text != null && text.length > 0 ? <span>{text}</span> : null}
  </div>
);
