"use client";

import * as PortalPrimitive from "@radix-ui/react-portal";
import * as React from "react";

interface IPortalProps extends PortalPrimitive.PortalProps {
  children: React.ReactNode;
}

export const Portal = ({ children, ...props }: IPortalProps): React.JSX.Element => {
  return <PortalPrimitive.Root {...props}>{children}</PortalPrimitive.Root>;
};

Portal.displayName = "Portal";
