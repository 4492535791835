import { z } from "zod";

import { Element } from "./Element";

export const ComparisonOperator = z.enum([
  "equals",
  "notEquals",
  "lessThan",
  "lessThanEqual",
  "greaterThan",
  "greaterThanEqual",
  "stringLengthEquals",
  "stringLengthNotEquals",
  "stringLengthLessThan",
  "stringLengthLessThanEqual",
  "stringLengthGreaterThan",
  "stringLengthGreaterThanEqual",
  "stringContains",
]);
export type IComparisonOperator = z.infer<typeof ComparisonOperator>;

export const ComparisonRule = z.object({
  firstElement: Element,
  secondElement: Element,
  operator: ComparisonOperator,
});
export type IComparisonRule = z.infer<typeof ComparisonRule>;
