import type { IApplicationGroupEventId, IApplicationGroupId, IUserId } from "@archetype/ids";

export enum ApplicationGroupEvent {
  VIEW = "view",
}

export interface IApplicationGroupEvent {
  id: IApplicationGroupEventId;
  userId: IUserId;
  applicationGroupId: IApplicationGroupId;
  event: ApplicationGroupEvent;
  createdAt: Date;
  updatedAt: Date;
}
