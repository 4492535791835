import { EmailId, EmailMessageId, EntityId, OrganizationId, UserId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { EmailRecipient } from "./EmailRecipient";
import { ThreadBranchOfEmailMsg } from "./Thread";

const EmailRecipientList = z.object({
  fullInfo: z.array(EmailRecipient),
});

export const Email = z.object({
  /**
   * Internal id identifying a stored email
   */
  id: EmailId,
  type: z.enum(["inboundAssistant", "outboundAssistant", "inboundScanned", "outboundScanned"]),
  /**
   * External id from email provider and integration, can describe connections between multiple emails
   * Never generated.
   */
  messageId: EmailMessageId,
  fromUserId: UserId,
  fromUserEntityId: EntityId.nullable(),
  organizationId: OrganizationId.nullable(),
  webhookMessageId: z.string(),
  inReplyToMessageId: EmailMessageId.nullable(),
  /**
   * May contain an unparsed list of message ids in a string, describes all the emails in the chain (responded/forwarded)
   */
  references: z.string(),
  fromName: ReadableString,
  fromEmailAddress: ReadableString,
  subject: ReadableString,
  body: ReadableString,
  sentTo: EmailRecipientList,
  sentCc: EmailRecipientList,
  parsedBranch: ThreadBranchOfEmailMsg,

  // Single bcc in the email as should only see yourself (but we store all)
  sentBcc: z
    .object({
      emailAddress: z.string(),
      name: z.string(),
    })
    .nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type IEmail = z.infer<typeof Email>;
