import { cn } from "@archetype/ui";
import React from "react";
import ContentEditable from "react-contenteditable";

import { useCellContentEditable } from "../../hooks/useCellContentEditable";
import { useCellEditOnEnter } from "../../hooks/useCellEditOnEnter";
import { ExpandableCellWrapper } from "../ExpandableCellWrapper";
import type { IDataTableCommonCellProps, IDataTableTextCell } from "./api";

export function TextCell<TRowId extends string, TColId extends string>({
  cell,
  isCellSelected,
  isCellEditing,
  isRowSelected,
}: IDataTableCommonCellProps<TRowId, TColId, IDataTableTextCell<TRowId, TColId>>): React.ReactNode {
  const { value, onChange, colId, rowId, readOnly } = cell;

  const { contentEditableRef, localValue, handleChange, handleBlur, handleKeyDown } = useCellContentEditable({
    initialValue: value,
    rowId,
    colId,
    onChange,
    readOnly,
    isCellEditing,
  });

  useCellEditOnEnter({
    rowId,
    colId,
    isCellEditing,
    isCellSelected,
  });

  return (
    <ExpandableCellWrapper
      cell={cell}
      isCellEditing={isCellEditing}
      isCellSelected={isCellSelected}
      isRowSelected={isRowSelected}
    >
      <ContentEditable
        autoFocus
        className={cn("size-full text-base outline-none", !isCellEditing && "truncate")}
        data-testid={isCellEditing ? "expanded-cell-content-editable" : "cell-content-editable"}
        disabled={readOnly || !isCellEditing}
        html={localValue ?? ""}
        innerRef={contentEditableRef}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </ExpandableCellWrapper>
  );
}
