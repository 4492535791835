import type { IIconNames } from "@archetype/ui";
import {
  cn,
  Command,
  commandDefaultFilter,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  useCommandState,
} from "@archetype/ui";
import { useCallback, useMemo } from "react";
import { match } from "ts-pattern";

import type { ICommandOption } from "./types";

interface ICommandWithAIOption<T extends string, O extends readonly ICommandOption[] = readonly ICommandOption[]> {
  aiOptionId: string;
  aiOptionLabel: string;
  className?: string;
  inputIcon?: IIconNames;
  inputPlaceholder?: string;
  onSelectAIOption: (value: string) => void;
  onSelectOption: (optionId: T) => void;
  options: O;
}

export function CommandWithAIOption<T extends string>({
  aiOptionId,
  aiOptionLabel,
  className,
  inputIcon = "search",
  inputPlaceholder = "Filter...",
  onSelectAIOption,
  onSelectOption,
  options,
}: ICommandWithAIOption<T>): React.JSX.Element {
  const aiOptionRegex = useMemo(() => new RegExp(`^${aiOptionId}\\s+`, "g"), [aiOptionId]);

  const onFilter = useCallback(
    (value: string, search: string, keywords?: string[]) => {
      // Move the AI option to the bottom if we have a search term.
      if (value.startsWith(aiOptionId) && search.length > 0) return 0.000001;

      return commandDefaultFilter(value, search, keywords);
    },
    [aiOptionId],
  );

  const handleOnSelect = useCallback(
    (idOrValue: T) => (): void => {
      if (idOrValue.startsWith(aiOptionId) && idOrValue !== aiOptionId) {
        onSelectAIOption(idOrValue.replace(aiOptionRegex, "").trim());

        return;
      }

      onSelectOption(idOrValue);
    },
    [aiOptionId, aiOptionRegex, onSelectAIOption, onSelectOption],
  );

  return (
    <Command filter={onFilter}>
      <CommandInput autoFocus icon={inputIcon} placeholder={inputPlaceholder} />
      <OptionsList<T>
        aiOptionId={aiOptionId}
        aiOptionLabel={aiOptionLabel}
        className={className}
        options={options}
        onSelect={handleOnSelect}
      />
    </Command>
  );
}

interface IOptionsList<T extends string, O extends readonly ICommandOption[] = readonly ICommandOption[]> {
  options: O;
  onSelect: (value: T) => () => void;
  className?: string;
  aiOptionId: string;
  aiOptionLabel: string;
}

function OptionsList<T extends string>(props: IOptionsList<T>): React.JSX.Element {
  const { className, options, onSelect: handleSelect, aiOptionLabel, aiOptionId } = props;

  const search = useCommandState((state) => state.search) as string;

  // Remount the CommandList, so that the ai option item is consistently at the bottom or at the top,
  // when there is a search term or not, respectively. Without this, cmdk would sometimes keep the ai option
  // item at the bottom, even when the search is empty.
  const listKey = search.length > 0 ? 1 : 0;
  const aiOptionValue = `${aiOptionId} ${search}`.trim();

  return (
    <CommandList key={listKey} className={cn("max-h-[600px]", className)}>
      <CommandItem
        className="text-base"
        icon="sparkles"
        value={aiOptionValue}
        onSelect={handleSelect(aiOptionValue as T)}
      >
        {aiOptionLabel}
        {search.length > 0 ? ` "${search}"` : null}
      </CommandItem>
      {options.map((option, index) =>
        match(option)
          .with({ isSeparator: true }, ({ id }) => {
            if (index === options.length - 1) return null;
            if ("isSeparator" in (options[index - 1] ?? {})) return null;

            return <CommandSeparator key={id} />;
          })
          .otherwise(({ id, value, component, icon }) => (
            <CommandItem key={id} className="text-base" icon={icon} value={value} onSelect={handleSelect(id as T)}>
              {component ?? value}
            </CommandItem>
          )),
      )}
    </CommandList>
  );
}
