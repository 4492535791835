import type { ITypedLayoutDefinitionDeclaration } from "@archetype/dsl";
import { LayoutConfigurationId, SearchableListWithMetricsLayoutId, SlotId } from "@archetype/ids";

export const SearchableListWithMetricsLayoutIds = {
  id: SearchableListWithMetricsLayoutId,
  layoutConfigurations: {
    mainEntityType: "entityType",
  },
  moduleParameters: {
    inputs: ["multiEntityQueryDataInput"],
    outputs: [],
  },
  slots: {
    filteringActionBar: {
      optional: false,
      inputs: ["multiEntityQueryDataInput"],
      outputs: ["filteredMultiEntityQuery"],
    },
    main: {
      optional: false,
      inputs: ["multiEntityQueryDataInput"],
      outputs: [],
    },
    summary: {
      optional: false,
      inputs: ["multiEntityQueryDataInput"],
      outputs: [],
    },
  },
} as const;

export const SearchableListWithMetricsLayout: ITypedLayoutDefinitionDeclaration<
  typeof SearchableListWithMetricsLayoutIds
> = {
  constIds: SearchableListWithMetricsLayoutIds,
  displayMetadata: {
    name: "Searchable list with metrics",
    description: "A list with a search input and metrics",
  },
  tags: ["list", "search", "metrics"],
  layoutConfiguration: {
    mainEntityType: {
      type: "entityType",
    },
  },
  slots: {
    summary: {
      displayMetadata: {
        name: "Metrics card",
        description: "Metrics cards to display", // TODO this should be multiple, or should the metric card have a wrapper that is multiple?
      },
      constraints: {
        semanticSize: "summary",
        inputs: {
          multiEntityQueryDataInput: {
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("filteringActionBar"),
              outputId: "filteredMultiEntityQuery",
            },
          },
        },
        outputs: {},
      },
    },
    main: {
      displayMetadata: {
        name: "List",
        description: "A list",
      },

      constraints: {
        semanticSize: "main",
        inputs: {
          multiEntityQueryDataInput: {
            ref: {
              type: "otherSlotOutput",
              slotId: SlotId.parse("filteringActionBar"),
              outputId: "filteredMultiEntityQuery",
            },
          },
        },
        outputs: {},
      },
    },
    filteringActionBar: {
      displayMetadata: {
        name: "Search input",
        description: "A search input",
      },
      constraints: {
        semanticSize: "actions",
        inputs: {
          multiEntityQueryDataInput: {
            ref: {
              type: "config",
              layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
            },
          },
        },
        outputs: {
          filteredMultiEntityQuery: {
            type: "config",
            layoutConfigurationId: LayoutConfigurationId.parse("mainEntityType"),
          },
        },
      },
    },
  },
  moduleParameterInputs: {
    multiEntityQueryDataInput: {
      type: "multiEntityQuery",
      entityType: LayoutConfigurationId.parse("mainEntityType"),
      displayMetadata: {
        name: "Main data shown",
        description: undefined,
      },
    },
  },
  moduleParameterOutputs: {},
  variants: [],
};
