import { cn, Input } from "@archetype/ui";
import type { ComponentProps, KeyboardEventHandler } from "react";
import { useCallback, useEffect, useRef } from "react";

type IInputProps = Omit<ComponentProps<typeof Input>, "onSubmit">;

interface ISubmittableInput extends IInputProps {
  onSubmit: (value: string) => void;
}

export const SubmittableInput: React.FC<ISubmittableInput> = (props) => {
  const { onSubmit, className, ...rest } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => inputRef.current?.select(), []);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key === "Enter") {
        const value = e.currentTarget.value;

        onSubmit(value);
      }
    },
    [onSubmit],
  );

  return (
    <Input
      {...rest}
      ref={inputRef}
      autoFocus
      className={cn("min-w-[300px] border-0 text-base", className)}
      onKeyDown={handleKeyDown}
    />
  );
};
