import { StateId, ViewFieldId } from "@archetype/ids";
import { z } from "zod";

import { LoadedAction } from "./LoadedActionType";

export const ConnectedCreateActionsByViewFieldId = z.record(
  ViewFieldId,
  z.object({
    action: LoadedAction,
    actionViewFieldId: ViewFieldId,
    currentStateIds: z.array(StateId).nullable(),
  }),
);

export type IConnectedCreateActionsByViewFieldId = z.infer<typeof ConnectedCreateActionsByViewFieldId>;
