import type { IColumn, IColumnChange, IEntityTypeCore } from "@archetype/dsl";
import {
  computeColumnViewFieldId,
  isColumnAddValidationChange,
  isColumnAutofillChange,
  isColumnCreateChange,
  isColumnDeleteChange,
  isColumnDisableAutofillChange,
  isColumnEditValidationChange,
  isColumnNameChange,
  isColumnRemoveValidationChange,
  isColumnTypeChange,
} from "@archetype/dsl";
import type { IColumnId, IEntityTypeId } from "@archetype/ids";
import { ColumnId, ValidationId } from "@archetype/ids";
import { flatMap, isNonNullable, keyByNoUndefined } from "@archetype/utils";
import { assertNever } from "assert-never";
import { values } from "lodash";

import {
  convertLLMColumnAutofillToAutofill,
  convertLLMColumnTypeToColumnType,
} from "../llmTypes/convertLLMColumnToColumn";
import { createFileLogger } from "../logger";
import type {
  ICreateOrEditColumnOperation,
  IDeleteColumnOperation,
  IEditAutofillOperation,
  IEditOperation,
} from "../stateMachine/editOperations/operations";

const logger = createFileLogger("convertColumnChangesToEditOperations");

export function convertColumnChangesToEditOperations({
  entityTypesById,
  changes,
}: {
  entityTypesById: Record<IEntityTypeId, IEntityTypeCore>;
  changes: IColumnChange[];
}): IEditOperation[] {
  const res: Record<
    IColumnId,
    {
      autofillEdit?: IEditAutofillOperation;
      columnCreateOrEdit?: ICreateOrEditColumnOperation;
      columnDelete?: IDeleteColumnOperation;
    }
  > = {};

  const columnsById: Record<IColumnId, IColumn & { entityTypeId: IEntityTypeId }> = keyByNoUndefined(
    flatMap(entityTypesById, (et) => et.columns.map((c) => ({ ...c, entityTypeId: et.id }))),
    (c) => c.id,
  );

  changes.forEach((change) => {
    if (isColumnCreateChange(change)) {
      const newColumn: IColumn = {
        id: ColumnId.generate(),
        displayMetadata: {
          name: change.columnToCreate.name,
        },
        validations: [],
        columnType: convertLLMColumnTypeToColumnType(change.columnToCreate.columnType),
        autofill: undefined,
        nonNullable: false,
        unique: false,
      };

      res[newColumn.id] = {
        columnCreateOrEdit: {
          type: "createOrEditColumn",
          column: newColumn,
          entityTypeId: change.entityTypeId,
        },
      };

      columnsById[newColumn.id] = { ...newColumn, entityTypeId: change.entityTypeId };

      return;
    }

    const column = columnsById[change.columnId];

    if (column == null) {
      logger.error(`Column ${change.columnId} not found`);

      return;
    }

    const prevColumn = res[column.id]?.columnCreateOrEdit?.column ?? column;

    if (isColumnAutofillChange(change)) {
      res[column.id] = {
        ...res[column.id],
        autofillEdit: {
          type: "editAutofill",
          viewField: {
            type: "column",
            columnId: column.id,
            id: computeColumnViewFieldId(column.id),
          },
          autofillConfig: convertLLMColumnAutofillToAutofill(change.changeAutofillTo),
          entityTypeId: column.entityTypeId,
        },
      };
    } else if (isColumnDisableAutofillChange(change)) {
      res[column.id] = {
        ...res[column.id],
        autofillEdit: {
          type: "editAutofill",
          viewField: {
            type: "column",
            columnId: column.id,
            id: computeColumnViewFieldId(column.id),
          },
          autofillConfig: null,
          entityTypeId: column.entityTypeId,
        },
      };
    } else if (isColumnNameChange(change)) {
      res[column.id] = {
        ...res[column.id],
        columnCreateOrEdit: {
          type: "createOrEditColumn",
          column: {
            ...prevColumn,
            displayMetadata: {
              ...prevColumn.displayMetadata,
              name: change.changeColumnNameTo,
            },
          },
          entityTypeId: column.entityTypeId,
        },
      };
    } else if (isColumnTypeChange(change)) {
      res[column.id] = {
        ...res[column.id],
        columnCreateOrEdit: {
          type: "createOrEditColumn",
          column: {
            ...prevColumn,
            columnType: convertLLMColumnTypeToColumnType(change.changeColumnTypeTo),
          },
          entityTypeId: column.entityTypeId,
        },
      };
    } else if (isColumnAddValidationChange(change)) {
      res[column.id] = {
        ...res[column.id],
        columnCreateOrEdit: {
          type: "createOrEditColumn",
          column: {
            ...prevColumn,
            validations: (prevColumn.validations ?? []).concat([
              {
                id: ValidationId.generate(),
                logic: change.validationDescriptionToAdd,
                rawLogic: change.validationDescriptionToAdd,
              },
            ]),
          },
          entityTypeId: column.entityTypeId,
        },
      };
    } else if (isColumnRemoveValidationChange(change)) {
      res[column.id] = {
        ...res[column.id],
        columnCreateOrEdit: {
          type: "createOrEditColumn",
          column: {
            ...prevColumn,
            validations: (prevColumn.validations ?? []).filter(
              (validation) => validation.id !== change.validationIdToRemove,
            ),
          },
          entityTypeId: column.entityTypeId,
        },
      };
    } else if (isColumnEditValidationChange(change)) {
      res[column.id] = {
        ...res[column.id],
        columnCreateOrEdit: {
          type: "createOrEditColumn",
          column: {
            ...prevColumn,
            validations: (prevColumn.validations ?? []).map((validation) =>
              validation.id === change.validationIdToEdit
                ? {
                    id: validation.id,
                    logic: change.changeValidationDescriptionTo,
                    rawLogic: change.changeValidationDescriptionTo,
                  }
                : validation,
            ),
          },
          entityTypeId: column.entityTypeId,
        },
      };
    } else if (isColumnDeleteChange(change)) {
      res[column.id] = {
        ...res[column.id],
        columnDelete: {
          type: "deleteColumn",
          actionIds: null,
          entityTypeId: column.entityTypeId,
          columnIds: [column.id],
        },
      };
    } else {
      assertNever(change);
    }
  });

  return flatMap(res, (v) => values(v).filter(isNonNullable));
}
