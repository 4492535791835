import type { IOrganizationId } from "@archetype/ids";
import { builderTrpc } from "@archetype/trpc-react";
import { Skeleton } from "@archetype/ui";
import { cn } from "@archetype/ui";

interface IActionRequiredPromptProps {
  className?: string;
  shouldTakeAction: boolean;
  orgId: IOrganizationId;
}

export const ActionRequiredPrompt: React.FC<IActionRequiredPromptProps> = ({ shouldTakeAction, orgId, className }) => {
  const { data: orgQuery } = builderTrpc.organization.byOrgId.useQuery({ orgId });

  return (
    <div
      className={cn(
        "px-3 py-4 text-base",
        shouldTakeAction ? "text-primary bg-blue-50" : "bg-muted-background",
        className,
      )}
    >
      {shouldTakeAction ? (
        <>
          {orgQuery?.organization.name ?? <Skeleton>Organization</Skeleton>} is <strong>waiting on you</strong> to
          continue to the next step.
        </>
      ) : (
        "Waiting on someone else to continue."
      )}
    </div>
  );
};
