import { match } from "ts-pattern";
import * as z from "zod";

import { ColumnTypeDefinitions } from "../../schemas/dataModel/ColumnType";
import { ColumnNumberRangeRefinement } from "../../schemas/dataModel/columnTypes/ColumnNumberType";
import type { IColumnTypeTree } from "../../utils/dataModel/resolveTypeTree";

type INumberValidator = z.ZodType<number>;

const createNumberValidator = (): INumberValidator => z.number();
const createIntegerValidator = (): INumberValidator => z.number().int();

const createNumberRangeValidator = (validator: z.ZodType<number>, tree: IColumnTypeTree): INumberValidator => {
  let refinedValidator = validator;
  const { inclusiveMin, inclusiveMax } = ColumnNumberRangeRefinement.parse(tree.columnType.definition);

  if (inclusiveMin != null) {
    refinedValidator = refinedValidator.refine((value) => value >= inclusiveMin, {
      message: `Number must be greater than or equal to ${inclusiveMin.toString()}`,
    });
  }
  if (inclusiveMax != null) {
    refinedValidator = refinedValidator.refine((value) => value <= inclusiveMax, {
      message: `Number must be lower than or equal to ${inclusiveMax.toString()}`,
    });
  }

  return refinedValidator;
};

const createNonNullableNumberValidator = (validator: z.ZodType<number>): INumberValidator => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- validator not typed properly
  return validator.refine((value) => value != null, { message: "Number cannot be null" });
};

export const createNumberTypeValidator = (tree: IColumnTypeTree): INumberValidator => {
  const type = ColumnTypeDefinitions.and(z.object({ primitiveType: z.literal("number") })).parse(
    tree.columnType.definition,
  );

  return match(type)
    .returnType<INumberValidator>()
    .with({ type: "number" }, () => createNumberValidator())
    .with({ type: "numberRange" }, ({ child }) => {
      if (tree.child == null) {
        throw new Error(`Child ${child} does not exist`);
      }

      return createNumberRangeValidator(createNumberTypeValidator(tree.child), tree);
    })
    .with({ type: "nonNullableNumber" }, () => {
      if (tree.child == null) {
        throw new Error(`Child does not exist`);
      }

      return createNonNullableNumberValidator(createNumberTypeValidator(tree.child));
    })
    .with({ type: "integer" }, () => createIntegerValidator())
    .exhaustive();
};
