import { type ReactNode, useCallback } from "react";

import { cn } from "../../../lib/utils";
import { Button } from "../../atoms/button";

interface IOnboardingInnerLayout {
  children: ReactNode;
  /** Set to true to use as an overlay without the beige background wrapper */
  asOverlay?: boolean;
  nextButton?: {
    isLoading?: boolean;
    isDisabled?: boolean;
    text: string;
    onClick: () => void;
  };
  backButton?: {
    isDisabled?: boolean;
    isLoading?: boolean;
    text: string;
    onClick: () => void;
  };
  reloadButton?: {
    isDisabled?: boolean;
    isLoading?: boolean;
    text: string;
    onClick: () => void;
  };
}

export const OnboardingInnerLayout: React.FC<IOnboardingInnerLayout> = ({
  children,
  asOverlay = false,
  nextButton,
  backButton,
  reloadButton,
}) => {
  const hasActions = nextButton || backButton || reloadButton;

  const handleNext = useCallback(() => {
    nextButton?.onClick();
  }, [nextButton]);

  const handleBack = useCallback(() => {
    backButton?.onClick();
  }, [backButton]);

  const handleReload = useCallback(() => {
    reloadButton?.onClick();
  }, [reloadButton]);

  const contentElement = (
    <div
      className={cn(
        "flex size-full items-center justify-center",
        !asOverlay ? "bg-paper-alt p-24 backdrop-blur-xl" : "",
      )}
    >
      <div
        className={cn(
          "bg-paper border-border flex size-full max-h-[800px] max-w-6xl flex-col items-center overflow-hidden rounded-xl border shadow",
        )}
      >
        <div className="flex size-full flex-1 overflow-hidden">
          <div className="flex size-full items-center justify-center">{children}</div>
        </div>
        {hasActions ? (
          <div className="border-border mt-auto flex w-full shrink-0 items-center justify-between gap-2 border-t px-6 py-4">
            <div className="flex">
              {reloadButton ? <Button iconLeft="refresh-ccw" variant="ghost" onClick={handleReload} /> : null}
            </div>
            <div className="flex gap-2">
              {backButton ? (
                <Button variant="ghost" onClick={handleBack}>
                  {backButton.text}
                </Button>
              ) : null}
              {nextButton ? (
                <Button disabled={nextButton.isDisabled} isLoading={nextButton.isLoading} onClick={handleNext}>
                  {nextButton.text}
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );

  // If used as an overlay, the parent will position it
  if (asOverlay) {
    return contentElement;
  }

  // Otherwise, position it absolutely to center on screen
  return <div className="fixed inset-0 z-50 flex items-center justify-center">{contentElement}</div>;
};
