import {
  ActionCore,
  ActionDefinition,
  ActionDefinitionCore,
  ActionInput,
  Authorization,
  RelationDirection,
} from "@archetype/dsl";
import { ActionId, RelationId } from "@archetype/ids";
import { z } from "zod";

import { LoadedViewField } from "./LoadedViewField";

export const LoadedActionInput = ActionInput.omit({ viewField: true }).merge(
  z.object({
    viewField: LoadedViewField,
  }),
);

export const ActionSideEffectEmail = z.object({
  isEnabled: z.boolean(),
  toPersonRelation: z.object({ relationId: RelationId, direction: RelationDirection }).nullable(),
  viewFieldsToSend: z.array(LoadedViewField),
});

export const LoadedActionSideEffects = z.object({
  email: ActionSideEffectEmail,
});

export type IActionSideEffectEmail = z.infer<typeof ActionSideEffectEmail>;

export const LoadedActionDefinition = ActionDefinition.innerType()
  .omit({
    inputs: true,
    contextualFields: true,
    sideEffects: true,
    authorizedByAnyOf: true,
  })
  .merge(
    z.object({
      inputs: z.array(LoadedActionInput),
      contextualFields: z.array(LoadedViewField),
      sideEffects: LoadedActionSideEffects.optional(),
      authorizedByAnyOf: z.array(Authorization),
    }),
  );

export const LoadedAction = ActionCore.omit({ actionDefinition: true }).merge(
  z.object({
    actionDefinition: LoadedActionDefinition,
  }),
);

export const ActionEdit = z.object({
  actionId: ActionId,
  actionDefinition: ActionDefinitionCore.omit({
    authorizedByAnyOf: true,
    authorizedForAnyoneWithLink: true,
  }),
});

export type ILoadedAction = z.infer<typeof LoadedAction>;
export type ILoadedActionInput = z.infer<typeof LoadedActionInput>;
export type ILoadedActionDefinition = z.infer<typeof LoadedActionDefinition>;
export type IActionEdit = z.infer<typeof ActionEdit>;
