import "../wdyr";
import "@archetype/ui/globals.css";

import { builderTrpc as trpc } from "@archetype/trpc-react";
import { Toaster } from "@archetype/ui";
import { generateTitle } from "@archetype/utils";
import { ClerkProvider } from "@clerk/nextjs";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import type { NextPage } from "next";
import localFont from "next/font/local";
import Head from "next/head";

import { AuthenticationProvider } from "../components/auth/AuthenticationProvider";
import { UserMonitoringProvider } from "../components/monitoring/UserMonitoringProvider";
import DatadogInit from "../datadog-init";
import { PostHogProvider } from "../providers";

// TODO @ShaunSpringer make this reusable -- its currently in 4 places (storybooks and apps)
const interFont = localFont({
  src: [
    {
      path: "../../public/fonts/inter/Inter-VariableFont_opsz,wght.ttf",
      style: "normal",
    },
  ],
  variable: "--font-inter",
  display: "swap",
  preload: true,
});

function App<T extends object>({ pageProps, Component }: { pageProps: T; Component: NextPage<T> }): React.ReactNode {
  return (
    <div suppressHydrationWarning className="flex h-max min-h-dvh">
      <Head>
        <title>{generateTitle()}</title>
        <meta content="minimum-scale=1, initial-scale=1, width=device-width" name="viewport" />
      </Head>
      <style global jsx>
        {`
          :root {
            --font-inter: ${interFont.style.fontFamily};
          }

          #__next {
            min-height: 100dvh;
          }
        `}
      </style>
      <DatadogInit />
      <PostHogProvider>
        <ClerkProvider {...pageProps}>
          <UserMonitoringProvider>
            <AuthenticationProvider>
              {typeof window === "undefined" ? null : <Component {...pageProps} />}
            </AuthenticationProvider>
          </UserMonitoringProvider>
        </ClerkProvider>
        <Toaster />
        <Analytics />
        <SpeedInsights />
      </PostHogProvider>
    </div>
  );
}

export default trpc.withTRPC(App);
