import { cn, Icon } from "@archetype/ui";
import React from "react";

interface IExternalUserProfileImage {
  className?: string;
}

export const ExternalUserProfileImage: React.FC<IExternalUserProfileImage> = ({ className }) => {
  return (
    <div
      className={cn("relative flex size-8 items-center justify-around overflow-hidden rounded-md bg-black", className)}
    >
      <Icon className="text-white" name="user" />
    </div>
  );
};
