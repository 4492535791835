import type { IEntityType } from "@archetype/dsl";
import type { IEntityId } from "@archetype/ids";
import { flatMap } from "@archetype/utils";

import { createFileLogger } from "../../../logger";
import type { ILoadedCrossColumnAndConditions } from "../../loadedQuery";
import { pathToRelatedToFilter } from "../../relationPath";
import type { IAuthorizationsByEntityScope, ILoadedAuthorizingPathFromQueried } from "../types";

const logger = createFileLogger("query/authorization/queries/builder");

export const buildFiltersFromResolvedPaths = ({
  startingEntityType,
  paths,
  authorizedForUserEntityId,
}: {
  startingEntityType: IEntityType;
  paths: IAuthorizationsByEntityScope<ILoadedAuthorizingPathFromQueried>;
  authorizedForUserEntityId: IEntityId;
}): ILoadedCrossColumnAndConditions[] => {
  return [
    ...paths.anyState.map(({ path, userTypeFilters }) =>
      pathToRelatedToFilter({
        path,
        userEntityMatchingFilters: userTypeFilters ?? {},
        fromEntityMatchingFilters: {},
        authorizedForUserEntityId,
      }),
    ),
    ...paths.activityLog.map(({ path, userTypeFilters }) =>
      pathToRelatedToFilter({
        path,
        userEntityMatchingFilters: userTypeFilters ?? {},
        fromEntityMatchingFilters: {},
        authorizedForUserEntityId,
      }),
    ),
    ...flatMap(paths.byStateId, (statePaths, stateId) => {
      if (startingEntityType.statusColumn == null) {
        logger.error(
          new Error(
            `Attempting to authorize by state ID for an entity type without a status column, id: ${startingEntityType.id}, versionId: ${startingEntityType.versionId}`,
          ),
        );

        return [];
      }
      const statusColumnId = startingEntityType.statusColumn;

      return statePaths.map(({ path, userTypeFilters }) =>
        pathToRelatedToFilter({
          path,
          fromEntityMatchingFilters: {
            [statusColumnId]: {
              type: "and",
              andedOrConditions: [],
              rawAndOperations: {
                eq: {
                  type: "value",
                  value: { type: "string", value: stateId },
                },
              },
            },
          },
          userEntityMatchingFilters: userTypeFilters ?? {},
          authorizedForUserEntityId,
        }),
      );
    }),
  ];
};
