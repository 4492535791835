import type { IColumn, INewSupportEntityTypeChange } from "@archetype/dsl";
import { ENTITY_TYPE_COLOR_NAMES, ENTITY_TYPE_SHAPE_NAMES } from "@archetype/dsl";
import type { IEntityTypeId, IOrganizationId } from "@archetype/ids";
import { EntityTypeId } from "@archetype/ids";
import { sample } from "lodash";

import { createInternalEmployeeAuthorization } from "../auth/createInternalEmployeeAuthorization";
import { createPrimaryKeyColumn, createTitleColumn } from "../onboarding/createBaseColumn";
import type { IEditOperation } from "../stateMachine/editOperations/operations";

export function convertSupportEntityTypeCreationToEditOperation({
  change,
  organizationId,
  userEntityTypeId,
}: {
  change: INewSupportEntityTypeChange;
  organizationId: IOrganizationId;
  userEntityTypeId: IEntityTypeId;
}): IEditOperation {
  const entityType = change.supportEntityTypeToCreate;

  const pkColumn: IColumn = createPrimaryKeyColumn();
  const titleColumn: IColumn = createTitleColumn(entityType.nameOfTitleColumn);

  return {
    type: "createNewSupportEntityType",
    entityType: {
      id: EntityTypeId.generate(),
      displayMetadata: {
        name: entityType.name,
        pluralName: entityType.pluralName,
        description: entityType.description,
      },
      primaryKey: pkColumn.id,
      displayNameColumn: titleColumn.id,
      statusColumn: null,
      columns: [pkColumn, titleColumn],
      relevantViewFieldsByStateId: null,
      targetEntityTypeApplicationGroupId: null,
      supportActionsInfo: null,
      userEntityTypeInfo: null,
      organizationId,
      color: sample(ENTITY_TYPE_COLOR_NAMES) ?? "neutral",
      shape: sample(ENTITY_TYPE_SHAPE_NAMES) ?? "triangle",
      authorizedByAnyOf: [createInternalEmployeeAuthorization({ userEntityTypeId })],
      authorizedByAnyOfPerStateId: {},
      activityLogAuthorizedByAnyOf: [createInternalEmployeeAuthorization({ userEntityTypeId })],
    },
  };
}
