import { OnboardingSessionId, OrganizationId, UserId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import { WorkspaceWorkflow } from "./onboarding/workspaceSuggestions";
import { StateColor } from "./stateMachine/stateColors";

// Define generation status enum
export const OnboardingGenerationStatus = z.enum([
  "not_started", // Initial state
  "in_progress", // Generation is currently running
  "completed", // Generation completed successfully
  "failed", // Generation failed
]);

export type IOnboardingGenerationStatus = z.infer<typeof OnboardingGenerationStatus>;

export const OnboardingProcessStateMachineState = z.object({
  id: z.string().describe("kebab_case_step_label"),
  label: ReadableString,
  color: StateColor,
});

export type IOnboardingProcessStateMachineState = z.infer<typeof OnboardingProcessStateMachineState>;

export const OnboardingProcessStateMachineStates = z.record(OnboardingProcessStateMachineState);
export const OnboardingProcessStateMachineTransition = z.object({
  id: z.string(),
  label: ReadableString,
  from: ReadableString,
  to: ReadableString,
});

export const OnboardingProcessStateMachineTransitions = z.record(OnboardingProcessStateMachineTransition);
export const OnboardingProcessStateMachineInitialState = z.object({
  state: ReadableString,
  label: ReadableString.describe("Readable name for the action to create that state"),
});

export const OnboardingStateMachineStateConstraints = z.object({
  state: ReadableString,
  columnConstraints: ReadableString.optional().describe("Constraints on the columns that apply only in this state."),
});

export const OnboardingProcessStateMachine = z.object({
  states: OnboardingProcessStateMachineStates,
  stateTransitions: OnboardingProcessStateMachineTransitions,
  initialState: OnboardingProcessStateMachineInitialState,
  stateConstraints: z.array(OnboardingStateMachineStateConstraints),
});

export type IOnboardingProcessStateMachine = z.infer<typeof OnboardingProcessStateMachine>;
export type IOnboardingProcessStateMachineTransition = z.infer<typeof OnboardingProcessStateMachineTransition>;

export const OnboardingProcessPolicy = z.object({
  transitionName: z.string(),
  policy: z.string(),
  // stateConstraints: z.array(OnboardingStateMachineStateConstraints),
});

export type IOnboardingProcessPolicy = z.infer<typeof OnboardingProcessPolicy>;

export const OnboardingDataModelColumnTypeRaw = [
  "status",
  "shortText",
  "longText",
  "number",
  "timestamp",
  "date",
  "boolean",
  "email",
  "geolocation",
  "phone",
  "url",
  "enum",
  "file",
] as const;

export const OnboardingDataModelColumnType = z.enum(OnboardingDataModelColumnTypeRaw);
export type IOnboardingDataModelColumnType = z.infer<typeof OnboardingDataModelColumnType>;

export const OnboardingSessionWorkflowSteps = z.array(z.string());
export type IOnboardingSessionWorkflowSteps = z.infer<typeof OnboardingSessionWorkflowSteps>;

export const OnboardingSessionWorkflowDataModel = z.object({
  columns: z.array(
    z.object({
      id: ReadableString,
      label: ReadableString,
      type: OnboardingDataModelColumnType,
      requiredForTransition: z.array(ReadableString),
    }),
  ),
});
export type IOnboardingSessionWorkflowDataModel = z.infer<typeof OnboardingSessionWorkflowDataModel>;

export const OnboardingSessionBusinessMetadata = z.object({
  description: ReadableString,
});
export type IOnboardingSessionBusinessMetadata = z.infer<typeof OnboardingSessionBusinessMetadata>;

export const OnboardingSessionWorkflowFeatures = z.array(
  z.object({
    title: ReadableString,
    selected: z.boolean().optional(),
  }),
);
export type IOnboardingSessionWorkflowFeatures = z.infer<typeof OnboardingSessionWorkflowFeatures>;

export const OnboardingSessionWorkflowPermissions = z.array(
  z.object({
    title: ReadableString,
    selected: z.boolean(),
  }),
);
export type IOnboardingSessionWorkflowPermissions = z.infer<typeof OnboardingSessionWorkflowPermissions>;

export const OnboardingSessionWorkflowEdges = z.array(
  z.object({
    label: ReadableString,
    source: ReadableString,
    target: ReadableString,
  }),
);
export type IOnboardingSessionWorkflowEdges = z.infer<typeof OnboardingSessionWorkflowEdges>;

export const OnboardingSessionWorkflowSuggestions = z.object({
  workflows: z.array(WorkspaceWorkflow),
});
export type IOnboardingSessionWorkflowSuggestions = z.infer<typeof OnboardingSessionWorkflowSuggestions>;

export const OnboardingSessionState = z.object({
  metadata: OnboardingSessionBusinessMetadata.optional(),
  features: OnboardingSessionWorkflowFeatures.optional(),
  permissions: OnboardingSessionWorkflowPermissions.optional(),
  edges: OnboardingSessionWorkflowEdges.optional(),
  dataModel: OnboardingSessionWorkflowDataModel.optional(),
  steps: OnboardingSessionWorkflowSteps.optional(),
  stepsRefined: OnboardingSessionWorkflowSteps.optional(),
  workflowSuggestions: OnboardingSessionWorkflowSuggestions.optional(),
  status: OnboardingGenerationStatus,
});

export const OnboardingSession = z.object({
  id: OnboardingSessionId,
  state: OnboardingSessionState,
  userId: UserId,
  createdAt: z.date(),
  updatedAt: z.date(),
  organizationId: OrganizationId,
});

export type IOnboardingSessionState = z.infer<typeof OnboardingSessionState>;
export type IOnboardingSession = z.infer<typeof OnboardingSession>;
