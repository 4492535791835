import type { ILoadedAction, ILoadedNestedCreateFormValues } from "@archetype/core";
import type { IEntityActionEmailEffectOverride, IViewFieldValue } from "@archetype/dsl";
import type { IViewFieldId } from "@archetype/ids";
import { useMemoDeepCompare } from "@archetype/ui";
import { mapValues, pickBy } from "@archetype/utils";
import { useCallback } from "react";
import type { UseFormReturn } from "react-hook-form";

import { useActionDraftServiceContext } from "./ActionDraftServiceContext";

type IDataToSubmit = {
  // TODO nested creates need email effect override as well
  emailEffectOverride: IEntityActionEmailEffectOverride | undefined;
  entityFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
  externalUserFieldValues: Partial<Record<IViewFieldId, IViewFieldValue>>;
  loadedNestedCreateFormValues: ILoadedNestedCreateFormValues;
};

export const useActionFormDataToSubmit = ({
  form,
  action,
  externalUserUpsertAction,
  loadedNestedCreateFormValues,
}: {
  form: UseFormReturn<Partial<Record<string, IViewFieldValue>>>;
  action: ILoadedAction;
  externalUserUpsertAction: ILoadedAction | undefined;
  loadedNestedCreateFormValues: ILoadedNestedCreateFormValues;
}): {
  getDataToSubmit: () => IDataToSubmit;
} => {
  const editableActionInputsFieldIds = useMemoDeepCompare(
    () =>
      new Set(
        action.actionDefinition.inputs.filter((input) => input.allowChangingDefault).map((input) => input.viewField.id),
      ),
    [action.actionDefinition.inputs],
  );

  const editableSyntheticUserCreateActionInputsFieldIds = useMemoDeepCompare(
    () =>
      new Set(
        externalUserUpsertAction?.actionDefinition.inputs
          .filter((input) => input.allowChangingDefault)
          .map((input) => input.viewField.id) ?? [],
      ),
    [externalUserUpsertAction?.actionDefinition.inputs],
  );

  const { emailStateRef } = useActionDraftServiceContext();
  const getDataToSubmit = useCallback((): IDataToSubmit => {
    const formValues = form.getValues();

    return {
      emailEffectOverride: emailStateRef.current,
      entityFieldValues: pickBy(formValues, (_, fieldId) => editableActionInputsFieldIds.has(fieldId as IViewFieldId)),
      externalUserFieldValues: pickBy(formValues, (_, fieldId) =>
        editableSyntheticUserCreateActionInputsFieldIds.has(fieldId as IViewFieldId),
      ),
      loadedNestedCreateFormValues: mapValues(loadedNestedCreateFormValues, (loadedNestedFormsForEntityType) =>
        mapValues(loadedNestedFormsForEntityType ?? {}, (loadedNestedFormsForEntity) => {
          if (loadedNestedFormsForEntity == null) {
            return undefined;
          }

          const { createAction } = loadedNestedFormsForEntity;

          const nestedEditableActionInputsFieldIds = new Set(
            createAction.actionDefinition.inputs
              .filter((input) => input.allowChangingDefault)
              .map((input) => input.viewField.id),
          );

          return {
            ...loadedNestedFormsForEntity,
            fieldValues: pickBy(loadedNestedFormsForEntity.fieldValues, (_, fieldId) =>
              nestedEditableActionInputsFieldIds.has(fieldId),
            ),
          };
        }),
      ),
    };
  }, [
    form,
    editableActionInputsFieldIds,
    editableSyntheticUserCreateActionInputsFieldIds,
    loadedNestedCreateFormValues,
    emailStateRef,
  ]);

  return {
    getDataToSubmit,
  };
};
