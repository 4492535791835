import { cn } from "@archetype/ui";
import * as React from "react";
import { useCallback } from "react";
import { useContextSelector } from "use-context-selector";

import type { IDataTableColumnHeader } from "../../api";
import { DataTableInstanceContext } from "../../context/DataTableInstanceContext";
import type { IVirtualColumn, IVirtualRow } from "../../hooks/useGridVirtualization";

interface IDataTableHeaderProps<TRowId extends string, TColId extends string, TCellValue> {
  headers: IDataTableColumnHeader<TRowId, TColId, { id: TRowId }, TCellValue>[];
  enableStickyColumns: boolean;
  visibleColumns: IVirtualColumn[];
  virtualRow: IVirtualRow;
}

export function DataTableHeader<TRowId extends string, TColId extends string, TCellValue>({
  headers,
  enableStickyColumns,
  visibleColumns,
  virtualRow,
}: IDataTableHeaderProps<TRowId, TColId, TCellValue>): React.ReactElement {
  const tableWidth = useContextSelector(DataTableInstanceContext, (state) => state.tableWidth);

  const renderHeaderCell = useCallback(
    (virtualColumn: IVirtualColumn): React.ReactNode => {
      const header = headers[virtualColumn.index];

      if (header == null) {
        return null;
      }

      const nextVirtualColumn = visibleColumns[virtualColumn.index + 1];
      const previousVirtualColumn = visibleColumns[virtualColumn.index - 1];

      const isLastLeftPinned = virtualColumn.pinned === "left" && nextVirtualColumn?.pinned !== "left";
      const isFirstRightPinned = virtualColumn.pinned === "right" && previousVirtualColumn?.pinned !== "right";

      const getHeaderCellStyle = (): React.CSSProperties => {
        const style: React.CSSProperties = {
          width: virtualColumn.width,
          height: virtualRow.height,
        };

        if (virtualColumn.pinned === "right") {
          style.right = virtualColumn.right;
        } else {
          style.left = virtualColumn.left;
        }

        return style;
      };

      const getHeaderCellClassName = (): string => {
        const pinnedPosition = virtualColumn.pinned;

        return cn(
          "group/cell absolute z-0 overflow-hidden bg-paper-alt focus:outline-none",
          enableStickyColumns && pinnedPosition === "left" && "z-10 border-r border-border",
          enableStickyColumns && pinnedPosition === "right" && "z-10 ml-auto border-l border-border",
          enableStickyColumns && isLastLeftPinned && "border-r border-border",
          enableStickyColumns && isFirstRightPinned && "border-l border-border",
          enableStickyColumns && pinnedPosition != null && "sticky",
        );
      };

      return (
        <div
          key={header.index}
          aria-colindex={header.index + 1}
          className={getHeaderCellClassName()}
          style={getHeaderCellStyle()}
        >
          {header.column.headerRenderer({
            index: header.index,
            id: header.id,
            column: header.column,
          })}
        </div>
      );
    },
    [headers, visibleColumns, virtualRow.height, enableStickyColumns],
  );

  return (
    <div
      key="header"
      aria-rowindex={1}
      className="bg-paper-alt sticky z-30 flex w-full items-center"
      role="rowheader"
      style={{
        minWidth: tableWidth,
        height: virtualRow.height,
        top: virtualRow.top,
      }}
    >
      {visibleColumns.map((col) => renderHeaderCell(col))}
    </div>
  );
}
