import { z } from "zod";

export const EntityTypeColorValues = [
  "lilac",
  "purple",
  "pink",
  "red",
  "orange",
  "brown",
  "yellow",
  "lime",
  "sage",
  "green",
  "emerald",
  "teal",
  "blue",
  "neutral",
] as const;

export const EntityTypeColor = z.enum(EntityTypeColorValues);
export type IEntityTypeColor = z.infer<typeof EntityTypeColor>;

export const ENTITY_TYPE_COLOR_NAMES: IEntityTypeColor[] = EntityTypeColor.options;
