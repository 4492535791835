import { ColumnId, EntityTypeId, RelationId } from "@archetype/ids";
import * as z from "zod";

import { RelationDirection } from "./RelationBase";
import { ViewFieldValue } from "./ViewFieldValue";

export const StaticElement = z.object({
  type: z.literal("static"),
  value: ViewFieldValue,
});
export type IStaticElement = z.infer<typeof StaticElement>;

export const ComputedElementValues = z.enum([
  "now",
  "today",
  "currentYear",
  "currentMonth",
  "startOfDay",
  "startOfMonth",
  "startOfYear",
  "endOfDay",
  "endOfMonth",
  "endOfYear",
  "randomUUID",
]);
export type IComputedElementValue = z.infer<typeof ComputedElementValues>;

export const ComputedElement = z.object({
  type: z.literal("computed"),
  value: ComputedElementValues,
});
export type IComputedElement = z.infer<typeof ComputedElement>;

export const AuthElement = z.object({
  type: z.literal("auth"),
  value: z.enum(["currentUser"]),
});
export type IAuthElement = z.infer<typeof AuthElement>;

export const ColumnElement = z.object({
  type: z.literal("column"),
  columnId: ColumnId,
  entityTypeId: EntityTypeId,
});
export type IColumnElement = z.infer<typeof ColumnElement>;

export const RelationElement = z.object({
  type: z.literal("relation"),
  relationId: RelationId,
  direction: RelationDirection,
});
export type IRelationElement = z.infer<typeof RelationElement>;

export const EntityTypeElement = z.object({
  type: z.literal("entityType"),
  entityTypeId: EntityTypeId,
});
export type IEntityTypeElement = z.infer<typeof EntityTypeElement>;

export const Element = z.union([StaticElement, ColumnElement, ComputedElement, RelationElement]);
export type IElement = z.infer<typeof Element>;

export const isColumnElement = (element: IElement | undefined): element is IColumnElement => {
  if (element == null) {
    return false;
  }

  return element.type === "column";
};

export const isRelationElement = (element: IElement | undefined): element is IRelationElement => {
  if (element == null) {
    return false;
  }

  return element.type === "relation";
};
