import { ClerkOrganizationId, type IEntityId, OrganizationId } from "@archetype/ids";
import { ReadableString } from "@archetype/utils";
import { z } from "zod";

import type { IOrganizationRole } from "./OrganizationRole";

export const ClerkOrganization = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string().nullable(),
  imageUrl: z.string(),
  hasImage: z.boolean(),
  createdBy: z.string().optional(),
  createdAt: z.number(),
  updatedAt: z.number(),
  publicMetadata: z.record(z.string(), z.unknown()).nullable(),
  privateMetadata: z.record(z.string(), z.unknown()),
  maxAllowedMemberships: z.number(),
  adminDeleteEnabled: z.boolean(),
  membersCount: z.number().optional(),
});

export type IClerkOrganization = z.infer<typeof ClerkOrganization>;

export const Organization = z.object({
  id: OrganizationId,
  name: ReadableString,
  slug: ReadableString,
  imageUrl: z.string(),
  clerkOrganizationId: ClerkOrganizationId,
  clerkData: ClerkOrganization.optional(),
});

export type IOrganization = z.infer<typeof Organization>;

export interface IOrganizationInfo {
  organization: IOrganization;
  role: IOrganizationRole;
  userEntityIds: {
    prod: IEntityId;
    dev: IEntityId;
  };
}
