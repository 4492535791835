import { forEach } from "./forEach";

/**
 * Takes in 2 record2 of records and returns a deep merge with of the records on 2 levels
 *
 * @param obj The source object
 * @param obj The source object
 * @returns A new object with the groups
 */
export function mergeRecordsRecords<const RootKeys extends string, const InKeys extends string, const InValue>(
  objA: Record<RootKeys, Record<InKeys, InValue>>,
  objB: Record<RootKeys, Record<InKeys, InValue>>,
): Record<RootKeys, Record<InKeys, InValue>> {
  const out = {} as Record<RootKeys, Record<InKeys, InValue>>;

  // Initialize with A, not as a record copy to not modify keys of objA
  forEach(objA, (innerRecord: Record<InKeys, InValue>, key) => {
    out[key] = {} as Record<InKeys, InValue>;

    forEach(innerRecord, (value, innerKey) => {
      out[key][innerKey] = value;
    });
  });

  // Add all values from B
  forEach(objB, (innerRecord: Record<InKeys, InValue>, key) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- actually necessary
    if (out[key] == null) {
      out[key] = {} as Record<InKeys, InValue>;
    }

    forEach(innerRecord, (value, innerKey) => {
      out[key][innerKey] = value;
    });
  });

  return out;
}
