import type { IColumnType, IDataLoadingQueryColumnOrFilters } from "@archetype/dsl";

import { NON_FILTERABLE_COLUMN_TYPES } from "../constants";

const listLikeTypes = ["enum", "statusEnum"] as const;
const textLikeTypes = ["email", "phone", "url", "shortText", "longText"] as const;
const numericLikeTypes = ["date", "number", "timestamp"] as const;

type IListLikeType = (typeof listLikeTypes)[number];
type ITextLikeType = (typeof textLikeTypes)[number];
type INumericLikeType = (typeof numericLikeTypes)[number];

const isListLikeType = (type: IColumnType["type"]): type is IListLikeType => {
  return listLikeTypes.includes(type as IListLikeType);
};

const isTextLikeType = (type: IColumnType["type"]): type is ITextLikeType => {
  return textLikeTypes.includes(type as ITextLikeType);
};

const isNumericLikeType = (type: IColumnType["type"]): type is INumericLikeType => {
  return numericLikeTypes.includes(type as INumericLikeType);
};

export const getFilterOperatorsForColumnType = (
  columnType: IColumnType["type"],
): (keyof IDataLoadingQueryColumnOrFilters)[] => {
  if (NON_FILTERABLE_COLUMN_TYPES.includes(columnType)) return [];
  if (columnType === "boolean") return ["eq"];

  if (isTextLikeType(columnType)) return ["regex"];
  if (isNumericLikeType(columnType)) return ["eq", "neq", "lt", "lte", "gt", "gte"];

  // ToDo(andre): should we add allNotInArray?
  if (isListLikeType(columnType)) return ["anyInArray", "noneInArray", "allInArray"];

  return ["eq", "neq"];
};

export const getFilterOperatorLabel = (
  operator: keyof IDataLoadingQueryColumnOrFilters,
  columnType: IColumnType["type"] = "shortText",
): string => {
  if (operator === "regex") return "matches";
  if (operator.startsWith("neq")) return "is not";

  if (columnType === "boolean") return operator === "neq" ? "is not" : "is";

  if (["lt", "gt", "lte", "gte"].includes(operator) && !isNumericLikeType(columnType)) return "is";

  if (operator === "lt") return columnType === "number" ? "is < than" : "is before";
  if (operator === "gt") return columnType === "number" ? "is > than" : "is after";
  if (operator === "lte") return columnType === "number" ? "is <= than" : "is on or before";
  if (operator === "gte") return columnType === "number" ? "is >= than" : "is on or after";

  if (operator === "anyInArray") return "one of";
  if (operator === "noneInArray") return "none of";
  if (operator === "allInArray") return "all of";

  return "is";
};
