import type { IActionCore } from "@archetype/dsl";
import type { IStateId } from "@archetype/ids";
import { keyByNoUndefined, mapValues } from "@archetype/utils";

import type { IAuthorizationsByEntityScope } from "./types";

/**
 * Determines the authorization paths for an action based on its state requirements.
 *
 * This function maps action definitions to authorization paths that control who can
 * execute the action. It builds a mutually exclusive authorization model:
 *
 * - For actions without state requirements (fromStates is null), it returns only
 *   anyState authorizations (state-independent).
 * - For actions with specific state requirements, it returns only byStateId authorizations
 *   (state-dependent).
 *
 * This design enforces that an action is either available regardless of state,
 * or only available in specific states, but not a combination of both.
 *
 * @returns An IRelevantAuthorizations object containing either anyState or byStateId
 *          authorization paths, but not both populated
 */
export const getActionAuthorizations = (action: IActionCore): IAuthorizationsByEntityScope => {
  // fromStates should only ever be null/empty for create actions (currently)
  if (action.actionDefinition.fromStates == null || action.actionDefinition.fromStates.length === 0) {
    return {
      anyState: action.actionDefinition.authorizedByAnyOf.map(
        (authorization) => authorization.authorizedByRelationPath,
      ),
      byStateId: {},
      activityLog: [],
    };
  }

  return {
    anyState: [],
    byStateId: mapValues(
      keyByNoUndefined(action.actionDefinition.fromStates, (stateId: IStateId) => stateId),
      () => action.actionDefinition.authorizedByAnyOf.map((authorization) => authorization.authorizedByRelationPath),
    ),
    activityLog: [],
  };
};
